import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import { Button } from "reactstrap";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import SystemAnnouncementList from "./SystemAnnouncementList";
import AddAnnouncementModal from "./AddAnnouncementModal";
import DeleteConfirmationModal from "../../CommonComponents/DeleteConfirmationModal/DeleteConfirmationModal";
import {getStringWithoutExtraSpaces} from "../../../Utils/Utility"
import Spinner from "../../Spinner/Spinner";

import { systemAnnouncementAction } from "CONFIG/ActionFactory";

import { getMessage } from "CONFIG/i18n";
import { ERROR } from "UTILS/Constants";

class SystemAnnouncementContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      announcements: [],
      showDeleteAnnouncementConfirmationModal: false,
      deleteAnnouncementId: null,
      showAnnouncementList: false,
      spinnerVisibility: false,
      deleteAnnouncementName: null
    }
  }

  componentDidMount() {
    this.showSpinner();
    this.getAllAnnouncements();
  }

  getAllAnnouncements = () => {
    systemAnnouncementAction.getAllAnnouncementsAction().then(
      (response) => {
        this.setState({
          announcements: response.data,
          showAnnouncementList: true
        })
        this.hideSpinner();
      },
      (error) => {
        console.error(error);
        this.hideSpinner();

        this.setState({
          showAnnouncementList: true
        });

        toastr.error(error.code)
      }
    )
  }

  addAnnouncement = (announcementData, callback) => {

    // While adding announcement we input time in format of HH:mm so seconds and milliseconds are set to zero so that while displaying announcements seconds/milliseconds difference is not considered.
    const modifiedAnnouncementData =  {
      ...announcementData,
      title: getStringWithoutExtraSpaces(announcementData.title),
      message: getStringWithoutExtraSpaces(announcementData.message),
      activateFrom: moment(announcementData.activateFrom).seconds(0).milliseconds(0).valueOf(),
      activateTill: moment(announcementData.activateTill).seconds(0).milliseconds(0).valueOf()
    }

    systemAnnouncementAction.addAnnouncementAction(modifiedAnnouncementData).then(
      (response) => {
        this.props.toggleAddAnnouncementModal(false, null);
        this.getAllAnnouncements();
        toastr.success("ADD_ANNOUCEMENT_SUCCESS")
        callback();
      },
      (error) => {
        callback(error);
      }
    )
  }

  editAnnouncement = (announcementData, callback) => {
    // While updating announcement we input time in format of HH:mm, so seconds and milliseconds are set to zero so that while displaying announcements seconds/milliseconds difference is not considered.

    const modifiedAnnouncementData =  {
      ...announcementData,
      title: getStringWithoutExtraSpaces(announcementData.title),
      message: getStringWithoutExtraSpaces(announcementData.message),
      activateFrom: moment(announcementData.activateFrom).valueOf(),
      activateTill: moment(announcementData.activateTill).valueOf()
    }

    systemAnnouncementAction.updateAnnouncementAction(modifiedAnnouncementData).then(
      (response) => {
        this.props.toggleAddAnnouncementModal(false, null);
        this.getAllAnnouncements();
        toastr.success("EDIT_ANNOUCEMENT_SUCCESS")
        callback();
      },
      (error) => {
        callback(error);
      }
    )
  }

  deleteAnnouncement = () => {
    systemAnnouncementAction.deleteAnnouncementAction(this.state.deleteAnnouncementId).then(
      response => {
        this.getAllAnnouncements();
        this.showDeleteAnnouncementConfirmationModal(false, null, null);
        toastr.success("DELETE_ANNOUCEMENT_SUCCESS");
      },
      error => {
        console.error(error);
        toastr.error(error.code)
        this.showDeleteAnnouncementConfirmationModal(false, null)
      }
    )
  }

  showDeleteAnnouncementConfirmationModal = (showModal, announcementId=null, announcementName=null) => {
    this.setState({
      showDeleteAnnouncementConfirmationModal: showModal,
      deleteAnnouncementId: announcementId,
      deleteAnnouncementName: announcementName
    })
  }

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };
  
  render() {
    return (
      <div className="displayFlex flexDirectionColumn alignItemsCenter">
        <Spinner showSpinner={this.state.spinnerVisibility} />
        {this.props.showModal &&
        <AddAnnouncementModal
          show={this.props.showModal}
          toggleAddAnnouncementModal={this.props.toggleAddAnnouncementModal}
          addUpdateAnnouncement={this.props.announcementData
            ? this.editAnnouncement
            : this.addAnnouncement}
          announcementData={this.props.announcementData}
          saveBtnText={this.props.announcementData
            ? getMessage("SAVE")
            : getMessage("ADD")}
          headerText={this.props.announcementData
            ? getMessage("EDIT_ANNOUNCEMENT")
            : getMessage("ADD_ANNOUNCEMENT")}
        />}

        {this.state.showDeleteAnnouncementConfirmationModal && 
          <DeleteConfirmationModal 
            show={this.state.showDeleteAnnouncementConfirmationModal}
            handleCancelClick={() => 
              this.showDeleteAnnouncementConfirmationModal(false, null)
            }
            handleDeleteClick={this.deleteAnnouncement}
            deleteMessage={getMessage('DELETE_ANNOUNCEMENT_CONFIRMATION_TEXT', {deleteAnnouncementName : this.state.deleteAnnouncementName})}
            headerMessage={getMessage('DELETE_ANNOUNCEMENT')}
          />
        }

        {this.state.showAnnouncementList &&
          <React.Fragment>
            <SystemAnnouncementList
              announcements={this.state.announcements}
              toggleAddAnnouncementModal={this.props.toggleAddAnnouncementModal}
              showDeleteAnnouncementConfirmationModal={this.showDeleteAnnouncementConfirmationModal}
              editAnnouncement={this.editAnnouncement}
            />
          </React.Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {};
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps)
(SystemAnnouncementContainer);