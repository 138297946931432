import React, { Component } from "react";

import html2canvas from "html2canvas";

import L from "leaflet";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

import ic_location from "ASSETS/Images/ic_location.svg";
import ic_share_map from "ASSETS/Images/LocationAssets/ic_share_map.svg";

import ic_share_my_location from "ASSETS/Images/LocationAssets/ic_share_my_location.svg";
import ic_stop_share_my_location from "ASSETS/Images/LocationAssets/ic_stop_share_my_location.svg";
import { getMessage } from "CONFIG/i18n";
import "./Location.less";
import "COMPONENTS/App/App.less";
import { socketMessage } from "WEBSOCKET/constants";
import OpenMap from "./OpenMap";
import { convertBlobToFile, base64ImageToBlob } from "../../Utils/Utility";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { CANVAS_TYPES } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

export default class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      shareMap: false,
      trackUser: false,
      shareLocation: false,
      shareETA: false,
      lat: "",
      lng: "",
      isDownload:false
    };
    this.mapRef = React.createRef();
    this.tooltipBoundaryElement = null;
  }
  toggle = (isDownload) => {
    this.props.handleLocationToggle(isDownload);
  };
  dropdownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  handleShareMap = () => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DOWNLOADING_TO_CANVAS,
        canvasType: CANVAS_TYPES.LOCATION,
        sessionKey: this.props.sessionKey,
        isDownloading: true
      }
    };
    
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    /*this.setState({isDownload:true});*/
    this.props.showSpinner();
    this.setState({ shareMap: !this.state.shareMap });
    var node = document.getElementById("userMap");
    html2canvas(node,{allowTaint: false,
      logging:true,
      useCORS: true}).then(canvas => {
      var myImage = canvas.toDataURL("image/png");
      let file = convertBlobToFile(base64ImageToBlob(myImage), "image/png", "location.png");
      this.props.uploadFile(file).then(filePaths =>{
        if (filePaths.fileUrl) {
          this.props.addCanvas(filePaths.fileUrl, filePaths.relativeFilePath, () => {
            setTimeout(() => {
              // this.props.addImage(APP_CONFIG.baseUrl + fileUrl);
              this.toggle(true);
              this.props.hideSpinner();
            }, 300);
          });
        } else {
          this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {...message, ...{isDownloading: false}});
          this.props.hideSpinner();
        }
      }).catch(error =>{
        this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {...message, ...{isDownloading: false}});
        console.error("oops, something went wrong!", error);
      })
    }).catch(error =>{
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {...message, ...{isDownloading: false}});
      console.error("oops, something went wrong!", error);
    })
  };
  handleTrackUser = () => {
    this.setState({ trackUser: !this.state.trackUser });
  };
  handleShareLocation = () => {
    this.setState({ shareLocation: !this.state.shareLocation });
  };
  handleShareETA = flag => {
    this.setState({ shareETA: flag });
  };
  stopETA = () => {
    var message = {
      data: {
        category: socketMessage.category.LOCATION,
        action: socketMessage.subCategories.LOCATION.STOP_ETA_SHARE,
        sessionKey: this.props.sessionKey
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.setState({ shareETA: false });
    this.props.stopShareETA();
  };
  showPosition = position => {
    this.setState({
      lat: position.coords.latitude,
      lng: position.coords.longitude
    });
  };
  errorToGetLocation = error =>{
    //function get call if navigator.geolocation.getCurrentPosition API fails
    /*1	PERMISSION_DENIED
      2	POSITION_UNAVAILABLE
      3	TIMEOUT
    */
    console.error("errorToGetLocation: ", error);
    if(error.code === 1 && this.props.uniqueId !== this.props.organiser){
      this.props.handleLocationToggle(false, false);
      toastr.error("LOCATION_PERMISSION");
    }
  }
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.errorToGetLocation);
    } else {
      console.log("Geolocation is not supported by this browser.");
      // x.innerHTML = "Geolocation is not supported by this browser.";
    }
  };
  componentDidMount() {
    this.getTooltipBoundaryElement();
    this.getLocation();
    if(this.props.eta.fromUniqueId !== "" && this.props.eta.toUniqueId !== "") {
      this.setState({
        shareETA: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.eta !== this.props.eta &&
       (this.props.eta.fromUniqueId == "" ||
       this.props.eta.toUniqueId == "") &&
      this.state.shareETA) {
      this.stopETA();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("overflowHidden");
  
    if(this.props.eta.fromUniqueId == "" && this.props.eta.toUniqueId == "") {
      this.setState({ shareETA: false });
    }
    
  }
  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("modal-header")[0];
  }
  
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="locationModalContainer modal-lg-12 modal-sm-12"
      >
        <ModalHeader
          className="locationHeading"
        >
          <div className="closeButton">
            <span aria-hidden="true" className="close-icon" id="close" onClick={() => {this.toggle(false)}}>×
              <CustomTooltip 
                tooltipBoundariesElement={this.tooltipBoundaryElement}
                tooltipText={getMessage("STOP_LOCATION")} 
                tooltipId="close" />
            </span>
          </div>
          <img
            src={ic_location}
            alt="pin-location"
            className="img-fluid"
            width="20px"
            height="27px"
          />

          <span>{getMessage("LOCATION_TITLE")}</span>
        </ModalHeader>
        <ModalBody className="pb-lg-0 pb-1">
          {this.state.lat !== "" && (
            <OpenMap
              sessionKey={this.props.sessionKey}
              presenter={this.props.presenter}
              uniqueId={this.props.uniqueId}
              markers={this.props.locationParticipants}
              sendWebsocketMessage={this.props.sendWebsocketMessage}
              lat={this.state.lat}
              lng={this.state.lng}
              shareLocation={this.state.shareLocation}
              eta={this.props.eta}
              shareETA={this.props.shareETA}
              ref={this.mapRef}
              addLocationData={this.props.addLocationData}
              userName={this.props.userName}
              handleShareETA={this.handleShareETA}
              organiser={this.props.organiser}
              graphHopperKey={this.props.graphHopperKey}
            ></OpenMap>
          )}
        </ModalBody>
        <ModalFooter className="displayBlock noTopBorder">

          {this.props.uniqueId === this.props.organiser && (
            <Button
              className={
                this.state.shareMap
                  ? "btnAddOnclick cursorPointer text-center my-1 w-100"
                  : "btnAdd cursorPointer text-center my-1 w-100"
              }
              disabled={this.state.lat == ""}
              onClick={() => {
                this.state.lat !== "" && 
                this.handleShareMap()
              }}
            >
              <span>
                <img
                  src={!this.state.shareMap ? ic_share_map : ic_share_map}
                  alt="share_map"
                  className="pr-lg-2 verticleAlignMiddle"
                />
                {getMessage("DOWNLOAD_TO_EDIT_ON_CANVAS")}
              </span>
            </Button>
          )}
          <Button
            className={
              this.state.shareLocation
                ? "btnAddOnclick cursorPointer text-center my-1 w-100"
                : "btnAdd cursorPointer text-center my-1 w-100"
            }
            onClick={() => {
              this.state.lat !== "" && 
              this.handleShareLocation()
            }}
            disabled={this.state.lat == ""}
          >
            <span>
              <img
                src={
                  !this.state.shareLocation
                    ? ic_share_my_location
                    : ic_stop_share_my_location
                }
                alt="share_map"
                className="pr-lg-2 verticleAlignMiddle"
              />
              {!this.state.shareLocation
                ? getMessage("SHARE_LIVE_LOCATION")
                : getMessage("STOP_LIVE_LOCATION_SHARING")}
            </span>
          </Button>

          {this.state.shareETA && this.props.uniqueId === this.props.organiser && (
            <Button
              className={
                this.state.shareETA
                  ? "btnAddOnclick cursorPointer text-center my-1 w-100"
                  : "btnAdd cursorPointer text-center my-1 w-100"
              }
              onClick={this.stopETA}
            >
              <span>
                <img
                  src={
                    !this.state.shareETA
                      ? ic_share_my_location
                      : ic_stop_share_my_location
                  }
                  alt="share_map"
                  className="pr-lg-2 verticleAlignMiddle"
                />
                {!this.state.shareETA
                  ? getMessage("SHARE_ETA")
                  : getMessage("STOP_ETA_SHARING")}
              </span>
            </Button>
          )}
        </ModalFooter>
        <div id="images"></div>
      </Modal>
    );
  }
}
