import React from "react";
import { func } from "prop-types";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

// Strings
import { getMessage } from "CONFIG/i18n";
import { USER_ROLES } from "UTILS/Constants";

import "../SuperAdmin/Organisations/AddOrganization.less";
import "../App/App.less";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

class ResetPasswordModal extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };

  handleResetClick = () => {
    this.props.handleResetClick(this.props.userData);
  };

  handleCancelClick = () => {
    this.props.handleCancelClick();
  };

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button
          onClick={this.handleResetClick}
          className="buttons customBtn resetButton"
        >
          {getMessage("RESET_PASSWORD")}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Modal isOpen={this.props.show} className="resetPasswordModal" backdrop="static" toggle={ this.props.handleCancelClick}>
        <ModalBody>{ this.props.resetPasswordUserRole === USER_ROLES.ADMIN ? getMessage("RESET_PASSWORD_MSG_ADMIN") : getMessage("RESET_PASSWORD_MSG_AGENT")}</ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modalScrollBar(ResetPasswordModal);
