import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import _ from "lodash";

// Component
import { getModulesNotAllowedErrorMessage } from "UTILS/Utility";
import AddUserModal from "./AddUserModal";
import AdminDashboard from "./AdminDashboard";
import EditUserModal from "./EditUserModal";

import BulkImportUserModal from "./BulkImportUserModal"
import Spinner from "COMPONENTS/Spinner/Spinner";
import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";

import { getCustomerId, getUserId, getSessionFollowUpsCount,
  getSessionListPageSize, getSessionListCurrentPageNo ,
  getUserListPageSize ,getUserListCurrentPageNo ,getUsersCount, getSessionsCount,
  getSessionFollowUpsPageSize, getSessionFollowUpsCurrentPageNo, getSelectedReport } from "../MainLayout/selectors";
import { getIsSSOEnabled } from "../CommonComponents/Header/selectors";
import { selectPDFTronKey } from "../Whiteboard/selectors";
import { getQRCodesCount, getQRCodesListCurrentPageNo, getQRCodesListPageSize, getOrganisationQRCodesLimit, getOrganisationTotalQRCodesCount } from "./QRCodes/selectors";

import { userAction, organisationAction, inviteeAction as actionFactory, adminAction, qrCodesAction } from "CONFIG/ActionFactory";
import { setQRCodeEnabled } from "../QRCode/QRCodeComponent/QRCodeAction";

// Strings
import { getMessage } from "CONFIG/i18n";

import { ERROR, RECORD_NOT_PROCESSED, REPORTS_DROPDOWN_MENU, ADMIN_DASHBOARD_TABS, modulesAvailableConstant, QR_CODES_PAGE_SIZES } from "UTILS/Constants";
import AddUserDropdown from "../SuperAdmin/Header/AddUserDropdown";

class AdminDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.adminDashboardRef = React.createRef();
    this.state = {
      addUserdata: {
        id: "",
        first_name: "",
        last_name: "",
        role: "",
        email: "",
        customerId: this.props.customerId,
        status: ""
      },
      editUserData: {
        id: "",
        first_name: "",
        last_name: "",
        role: "",
        email: "",
        customerId: this.props.customerId,
        status: ""
      },
      showAddUserModalFlag: false,
      showEditUserModalFlag: false,
      showResetPasswordModalFlag: false,
      deleteUserIndex: null,
      showConfirmationModalFlag: false,
      customerId: this.props.customerId,
      deleteUserRole: "",
      isOnlyOneAdmin: "",
      spinnerVisibility: false,
      sessionsList:[],
      sessionFollowUps: [],
      usersList: [],
      sessionDetails: null,
      sessionObjects: [],
      inviteesList:[],
      customerStatistics:{},
      showBulkImportUserModalFlag: false,
      showAddUserDropdownFlag: false,
      qrCodes: [],
      isQRCodeEnabled: false,
      showTable: false,
      deleteUserName: ""
    };
  }

  componentDidMount() {

    switch(this.props.currentActiveTab) {
      case ADMIN_DASHBOARD_TABS.USERS:
        this.getAllUserData();
        break;

      case ADMIN_DASHBOARD_TABS.REPORTS:
        if(this.props.selectedReport) {
          switch(this.props.selectedReport) {
            case REPORTS_DROPDOWN_MENU.SESSION_LIST:
              this.fetchSessionsList();
              break;
      
            case REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS:
              this.fetchSessionFollowUps();
              break;
      
            default:
              console.log("Invalid report");
              return;
          }
        }
        break;

      case ADMIN_DASHBOARD_TABS.QR_CODES:
        this.fetchAllQRCodes();
        break;

      default:
        console.log("Invalid tab"); return;
    }

    this.getCustomerStatistics();
    if(this.props.sessionKey) {
      this.showSpinner();
      this.getSessionInvitiesAndSessionDetails(this.props.sessionKey)
    }
    // this.getSessionInvitiesAndSessionDetails(this.props.sessionKey);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.currentActiveTab != prevProps.currentActiveTab)
      userAction.saveSessionKey(null);
    // check if the QR CODE module present in the customer's selected modules
    if(prevProps.customerData?.modules !== this.props.customerData?.modules){
      let availableModules = this.props.customerData?.modules ? this.props.customerData?.modules : []
      this.props.setQRCodeEnabled(availableModules.indexOf(modulesAvailableConstant.QR_CODE) !== -1 );
    }
  }

  fetchSessionsList = (page=this.props.sessionListCurrentPageNo, sessionListPageSize , searchKey) => {
    this.showSpinner();

    const pageSize = sessionListPageSize ? sessionListPageSize 
      : this.props.sessionListPageSize 
        ? this.props.sessionListPageSize
        : 10;
    let requestBodyData = {
      customerId: this.props.customerId,
      search:searchKey,
      limit:pageSize,
      page
    }
    userAction.getSessionListAction(requestBodyData).then(
      response => {
        this.setState({
          sessionsList: response.data.data.sessionsList,
          showTable: true,
          spinnerVisibility: false
        });

        userAction.saveSessionListPageSize(parseInt(pageSize))
        userAction.saveSessionListCurrentPageNo(page);
      },
      error => {
        console.log(error);

        this.setState({
          showTable: true,
          spinnerVisibility: false
        });

        toastr.error(error.code);
      }
    );
  }

  fetchSessionFollowUps = (page=this.props.sessionFollowUpsCurrentPageNo, sessionFollowUpsPageSize , searchKey) => {
    this.showSpinner();

    const pageSize = sessionFollowUpsPageSize ? sessionFollowUpsPageSize 
      : this.props.sessionFollowUpsPageSize 
        ? this.props.sessionFollowUpsPageSize
        : 10;
    let requestBodyData = {
      customerId: this.props.customerId,
      search:searchKey,
      limit:pageSize,
      page
    }
    adminAction.getAllSessionFollowUps(requestBodyData).then(
      response => {
        this.setState({
          sessionFollowUps: response.data.data.sessionsFollowUps,
          showTable: true,
          spinnerVisibility: false
        });

        adminAction.saveSessionFollowUpsPageSize(parseInt(pageSize))
        adminAction.saveSessionFollowUpsCurrentPageNo(page);
      },
      error => {
        console.log("fetchSessionFollowUps error: ", error);

        this.setState({
          showTable: true,
          spinnerVisibility: false
        });

        toastr.error(error.code);
      }
    );
  }

  fetchAllQRCodes = (currentPageNumber=this.props.qrCodesListCurrentPageNo, pageSize, search) => {
    this.showSpinner();

    const limit = pageSize ? pageSize 
      : this.props.qrCodesListPageSize 
        ? this.props.qrCodesListPageSize
        : QR_CODES_PAGE_SIZES[0].value;

    let request={
      search: search ? search : undefined,
      limit: limit,
      page: currentPageNumber
    };

    this.updateSearchValue(search);

    qrCodesAction.getAllQRCodes(request).then(
      response => {

        this.setState({
          qrCodes: response.data.data.qrCodes,
          showTable: true,
          spinnerVisibility: false
        });
        
        qrCodesAction.saveQRCodesListPageSize(parseInt(limit));
        qrCodesAction.saveQRCodesListCurrentPageNo(currentPageNumber);
      },
      error => {
        toastr.error(error);
        if (error.code == ERROR.MODULES_NOT_ALLOWED) {
          this.props.setQRCodeEnabled(false);
          // if QR Code module not present then switch tab to User List/Details
          this.getAllUserData();
          adminAction.saveCurrentActiveTab(ADMIN_DASHBOARD_TABS.USERS);
        }
        this.setState({
          qrCodes: [],
          showTable: true,
          spinnerVisibility: false
        });
      }
    );
  }

  getAllUserData = (page=this.props.userListCurrentPageNo, userListPageSize, searchKey, showToast = true) => {
    this.showSpinner();

    const pageSize = userListPageSize ? userListPageSize 
      : this.props.userListPageSize 
        ? this.props.userListPageSize
        : 10;
    let requestBodyData = { 
      customerId: this.state.customerId ,
      page,
      limit:pageSize,
      search:searchKey
    }

    this.updateSearchValue(searchKey);

    userAction.getAllUsersAction(requestBodyData).then(
      response => {
        this.setState({
          usersList: response.data.data.users,
          showTable: true,
          spinnerVisibility: false
        });
        userAction.saveUserListPageSize(parseInt(pageSize))
        userAction.saveUserListCurrentPageNo(page);
      },
      error => {

        this.setState({
          showTable: true,
          spinnerVisibility: false
        });

        // this.hideSpinner();
        if (showToast) {
          toastr.error(error.code);
        }
      }
    );
  };

  getCustomerStatistics=() =>{
    organisationAction.getCustomerStatisticsAction(this.props.customerId).then(
      customerStatistics=>{
        this.setState({customerStatistics:customerStatistics.data.data})
      },
      error => {
      }
    );
  };

  renderSprinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  handleAddClick = (userInfo, callBack) => {
    userInfo.customerId = this.state.customerId;
    userAction.addUserAction(userInfo).then(
      response_data => {
        this.getCustomerStatistics();
        toastr.success("USER_CREATED_SUCCESSFULLY");
        this.getAllUserData();
        this.setState({ showAddUserModalFlag: false });
        this.adminDashboardRef.current.resetValuesOnAddUser();
        callBack && callBack();
      },
      error => {
        callBack && callBack(error.message);
      }
    );
  };

  handleSaveClick = (userInfo, callBackForError) => {
    userAction
    .saveUserAction(
      userInfo,
      userInfo.id,
      this.props.loggedUserId
    )
    .then(
      response_data => {
        this.getAllUserData(undefined, undefined, this.state.searchKey);
        this.setState({
          showEditUserModalFlag: false,
          editUserData: {
            id: "",
            first_name: "",
            last_name: "",
            role: "",
            email: "",
            customerId: this.props.customerId,
            status: ""
          },
          deleteUserRole: ""
        });
        toastr.success("USER_UPDATED_SUCCESSFULLY");
      },
      error => {
        callBackForError(error);
      }
    );
  };

  handleDeleteUserClick = disableDeleteBtn => {
    if(disableDeleteBtn){
      disableDeleteBtn(true);
    }
    userAction.deleteUserAction(this.state.deleteUserIndex).then(
      response => {
        let currentPageNo = this.getUpdatedPageNumber();
        this.getCustomerStatistics();       
        this.getAllUserData(currentPageNo, undefined, this.state.searchKey);

        this.setState ({
          showConfirmationModalFlag: false,
          deleteUserIndex: null,
          deleteUserName: null
        });
        toastr.success("USER_DELETED_SUCCESSFULLY");
      },
      error => {
        this.setState({
          showConfirmationModalFlag: false,
          deleteUserIndex: null,
          deleteUserName: null
        });
        toastr.error(error.code);
      }
    );
  };

  deleteQRCode = (qrCode) => {
    this.showSpinner();
    qrCodesAction.deleteQRCodeAction(qrCode.qrId).then(
      (response) => {
        let currentPageNo = this.getUpdatedPageNumber();
        this.fetchAllQRCodes(currentPageNo, undefined, this.state.searchKey);
        toastr.success("QR_CODE_DELETED_SUCCESSFULLY");
        this.hideSpinner();
      },
      (error) => {
        this.hideSpinner();
        toastr.error(error);
      }
    );
  };

  handleResetClick = () => {
    this.setState({ showResetPasswordModalFlag: false });
    let userData = Object.assign(
      {},
      {
        userId: this.state.resetPasswordUserId, 
        language: this.props.language
      }
    );
    userAction.resetUserAction(userData).then(
      response => {
        toastr.success("RESET_PASSWORD_SUCCESS");
      },
      error => {
        toastr.error(error.code);
      }
    );
  };

  resetPasswordClick = (resetPasswordUserId, resetPasswordUserRole) => {
    this.setState({
      showResetPasswordModalFlag: true,
      resetPasswordUserId: resetPasswordUserId,
      resetPasswordUserRole: resetPasswordUserRole
    });
  };

  showDeleteConfirmationModal = (userId, userRole, userName) => {
    this.setState({
      showConfirmationModalFlag: true,
      deleteUserIndex: userId,
      deleteUserRole: userRole,
      deleteUserName: userName
    });
  };

  showAddUserModal = () => {
    this.setState({ showAddUserModalFlag: true });
  };

  showBulkImportUserModal = () =>{
    this.setState({showBulkImportUserModalFlag: true});
  }

  showAddUserDropdown = () =>{
    this.setState({showAddUserDropdownFlag:!this.state.showAddUserDropdownFlag})
  }

  showEditUserModal = userId => {
    let userData = _.find(this.state.usersList, object => {
      return object.id === userId;
    });
    this.setState({
      editUserData: userData,
      showEditUserModalFlag: true
    });
  };

  handleCancelClick = () => {
    this.setState({ showResetPasswordModalFlag: false });

    this.setState({ showConfirmationModalFlag: false });
  };

  handleCancelAddUserModal = () => {
    this.setState({ showAddUserModalFlag: false });
  };

  handleCloseBulkImportUsers = () => {
    this.setState({ showBulkImportUserModalFlag: false });
  };

  handleCancelEditModal = () => {
    this.setState({ showEditUserModalFlag: false });
  };

  getSessionInvitiesAndSessionDetails = (sessionKey) => {
    actionFactory.addInviteeAction(sessionKey).then(response =>{
      this.setState({inviteesList: response.data.invitees})
    },
    error => {
      console.log('error: ***', error);
      toastr.error(error.code);
    })
    //to get sessiondetails
    userAction.getSessionDetail(sessionKey).then(response =>{
      this.setState({sessionDetails: response.data});
      userAction.saveSessionKey(sessionKey);
    },
    error => {
      console.error('getSessionDetail error: ***', error);
      toastr.error(error.code);
    })

    //to get canvas and pdf list
    userAction.getSessionData(sessionKey).then(response => {
      this.setState({sessionObjects : response.data.data.sessionData});
    },
    error => {
      console.error('getSessionData error: ***', error);
      toastr.error(error.code);
    })
  }
  
  onBackClick = () => {
    this.setState({inviteesList: [], sessionDetails:null})
    userAction.saveSessionKey(null);
  }

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };
  
  handleBulkImportUsers = (fileObject, callback) => {
    var formData = new FormData();
    formData.append("file", fileObject);
    formData.append("exportScript", false);
    formData.append("customerId", this.state.customerId);
    this.showSpinner();
    userAction.importBulkUsersAction(formData).then(
      response => {
        this.hideSpinner();
        this.getCustomerStatistics();
        this.adminDashboardRef.current.resetValuesOnAddUser();
        this.getAllUserData();
        if (response.data.data) {
          //Below condition checks if seats limit is reached.
          let isRecordNotProcessed = response.data.data.find(errorRecord => errorRecord.error.code == RECORD_NOT_PROCESSED); // isRecordNotProcessed return 1st record which satisfies given condition
          callback && callback(response.data.data, isRecordNotProcessed ? getMessage("BULK_IMPORT_ERROR_FOR_SEATS_LIMIT_REACHED") : getMessage("BULK_IMPORT_ERROR"), false);
        } else {
          callback && callback(null, getMessage("USER_RECORDS_PROCESSED_SUCCESSFULLY"), true);
        }
      },
      error => {
        this.hideSpinner();
        callback && callback(null, getMessage(error.code), false);
      }
    );
  }; 

  toggleAddUserDropdown = () => {
    this.setState({
      showAddUserDropdownFlag: !this.state.showAddUserDropdownFlag
    });
  }
  
  saveSelectedReport = selectedReport => {
    adminAction.selectReportAction(selectedReport)
  }

  updateQRCode=(qrCodeInfo, callBack)=>{
    this.showSpinner();
    const qrId= qrCodeInfo.qrId;
    const requestBody = {
      description : qrCodeInfo.description,
      label : qrCodeInfo.label
    }
    qrCodesAction.updateQRCode(requestBody, qrId).then(
      response => {
        this.hideSpinner();
        toastr.success("QR_CODE_UPDATED_SUCCESSFULLY");
        this.fetchAllQRCodes(undefined, undefined, this.state.searchKey);
        callBack && callBack();
      },
      error => {
        this.hideSpinner();
        let message = "";
        if (error.code === ERROR.MODULES_NOT_ALLOWED) {
          message = getModulesNotAllowedErrorMessage(error.errDetails)
        }
        else {
          message = getMessage(error.code)
        }
        callBack && callBack(message);  
      }
    );
  }

  getUpdatedPageNumber = () => {
    let propsData = {...this.props};
    let totalCount= 0;
    let pageSize= 10;
    let currentPageNo= 0;

    switch(this.props.currentActiveTab) {

      case ADMIN_DASHBOARD_TABS.USERS:
        totalCount= propsData.usersCount - 1;
        pageSize= propsData.userListPageSize;
        currentPageNo= propsData.userListCurrentPageNo;

        break;

      case ADMIN_DASHBOARD_TABS.QR_CODES:
        totalCount= propsData.qrCodesCount - 1;
        pageSize= propsData.qrCodesListPageSize;
        currentPageNo= propsData.qrCodesListCurrentPageNo;

        break;
      
      default:
        console.log("Invalid tabs");
    }

    // calculate new total page count
    let newPageCount = Math.ceil(totalCount / pageSize);
    
    console.log("currentPageNo: ", currentPageNo, "newPageCount: ", newPageCount, "totalCount: ", totalCount);

    // if total page count is changed and reducer count is more than new page count, set new page count in reducer
    if(currentPageNo > 0 && currentPageNo >= newPageCount) {
      currentPageNo = newPageCount - 1;

      switch(this.props.currentActiveTab) {
        case ADMIN_DASHBOARD_TABS.USERS:
          userAction.saveUserListCurrentPageNo(currentPageNo);
          break;

        case ADMIN_DASHBOARD_TABS.QR_CODES:
          qrCodesAction.saveQRCodesListCurrentPageNo(currentPageNo);
          break;

        default:
          console.log("Invalid tab");
      }
    }

    return currentPageNo;
  }

  updateSearchValue = (search) => {
    // will not set seachKey in state if it is not modified to avoid re-rendering
    this.setState(prevState => {
      if (prevState.searchKey === search) {
        return null;
      } else {
        return { searchKey: search };
      }  
    });
  }

  fetchRecordsOnTabSelect = (selectedTab, selectedReport) => {
    switch(selectedTab) {

      case ADMIN_DASHBOARD_TABS.USERS:
        this.setState({
          usersList: [],
          showTable: false
        }, () => {
          this.getAllUserData(0, this.props.userListPageSize);
        });
        break;

      case ADMIN_DASHBOARD_TABS.QR_CODES:
        this.setState({
          qrCodes: [],
          showTable: false
        }, () => {
          this.fetchAllQRCodes(0);
        });
        break;

      case ADMIN_DASHBOARD_TABS.REPORTS:
        this.setState({
          sessionsList: [],
          sessionFollowUps: [],
          showTable: false
        }, () => {
          switch(selectedReport) {
            case REPORTS_DROPDOWN_MENU.SESSION_LIST:
              this.fetchSessionsList(0);
              break;
      
            case REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS:
              this.fetchSessionFollowUps(0);
              break;
      
            default:
              console.log("Invalid report");
              return;
          }
        });
        break;

      default:
        console.log("Invalid tab");
    }
  }

  render() {
    return (
      <div>
        {this.renderSprinner()}
        <AddUserModal
          show={this.state.showAddUserModalFlag}
          handleAddClick={this.handleAddClick}
          handleCancelClick={this.handleCancelAddUserModal}
          language={this.props.language}
        />
        
        <BulkImportUserModal
          show={this.state.showBulkImportUserModalFlag}
          handleBulkImportUsers={this.handleBulkImportUsers}
          handleCloseBulkImportUsers={this.handleCloseBulkImportUsers}
          language={this.props.language}
          getAllUserData={this.getAllUserData}
          userListPageSize={this.props.userListPageSize}
        />
        
        <AddUserDropdown
          show={this.state.showAddUserDropdownFlag}
          toggleAddUserDropdown={this.toggleAddUserDropdown} 
          showAddUserModal={this.showAddUserModal}
          showBulkImportUserModal={this.showBulkImportUserModal}          
          language={this.props.language}
        />

        <EditUserModal
          show={this.state.showEditUserModalFlag}
          editUserData={this.state.editUserData}
          handleSaveClick={this.handleSaveClick}
          handleCancelClick={this.handleCancelEditModal}
          language={this.props.language}
        />

        <AdminDashboard
          ref={this.adminDashboardRef}
          organisationName={this.props.customerData.name}
          customerStatistics={this.state.customerStatistics}
          showConfirmationModalFlag={this.state.showConfirmationModalFlag}
          showResetPasswordModalFlag={this.state.showResetPasswordModalFlag}
          resetPasswordUserRole={this.state.resetPasswordUserRole}
          showAddUserModal={this.showAddUserModal}
          showBulkImportUserModal={this.showBulkImportUserModal}
          showAddUserDropdown={this.showAddUserDropdown}
          showEditUserModal={this.showEditUserModal}
          resetPasswordClick={this.resetPasswordClick}
          handleCancelClick={this.handleCancelClick}
          handleResetClick={this.handleResetClick}
          handleDeleteUserClick={this.handleDeleteUserClick}
          showDeleteConfirmationModal={this.showDeleteConfirmationModal}
          deleteUserRole={this.state.deleteUserRole}
          deleteUserName={this.state.deleteUserName}
          history={this.props.history}
          getAllUserData={this.getAllUserData}
          showAboutOrgModal={this.showAboutOrgModal}
          handleSaveClick={this.handleSaveClick}
          logo={this.props.logo}
          loggedUserId={this.props.loggedUserId}
          sessionsList={this.state.sessionsList}
          sessionsCount={this.props.sessionsCount}
          sessionFollowUpsCount={this.props.sessionFollowUpsCount}
          sessionFollowUps={this.state.sessionFollowUps}
          fetchSessionsList={this.fetchSessionsList}
          fetchSessionFollowUps={this.fetchSessionFollowUps}
          sessionListCurrentPageNo={this.props.sessionListCurrentPageNo}
          sessionFollowUpsCurrentPageNo={this.props.sessionFollowUpsCurrentPageNo}
          sessionDetails={this.state.sessionDetails}
          sessionKey={this.props.sessionKey}
          inviteesList={this.state.inviteesList}
          getSessionInvitiesAndSessionDetails={this.getSessionInvitiesAndSessionDetails}
          onBackClick={this.onBackClick}
          language={this.props.language}
          currentActiveTab={this.props.currentActiveTab}
          selectedReport={this.props.selectedReport}
          sessionObjects={this.state.sessionObjects}
          showSpinner={this.showSpinner}
          hideSpinner={this.hideSpinner}
          sessionListPageSize={this.props.sessionListPageSize}
          sessionFollowUpsPageSize={this.props.sessionFollowUpsPageSize}
          userListPageSize={this.props.userListPageSize}
          userListCurrentPageNo={this.props.userListCurrentPageNo}
          usersList={this.state.usersList}
          usersCount={this.props.usersCount}
          pdftronKey={this.props.pdftronKey}
          isSSOEnabled={this.props.customerData.SSODetails?.isSSOEnabled}
          modules={this.props.customerData?.modules}
          saveSelectedReport={this.saveSelectedReport}
          qrCodesCount={this.props.qrCodesCount}
          qrCodesListCurrentPageNo={this.props.qrCodesListCurrentPageNo}
          qrCodesListPageSize={this.props.qrCodesListPageSize}
          qrCodes={this.state.qrCodes}
          fetchAllQRCodes={this.fetchAllQRCodes}
          qrLimit={this.props.qrLimit}
          totalQRCodes={this.props.totalQRCodes}
          updateQRCode={this.updateQRCode}
          isQRCodeEnabled={this.props.isQRCodeEnabled}
          deleteQRCode={this.deleteQRCode}
          fetchRecordsOnTabSelect={this.fetchRecordsOnTabSelect}
          showTable={this.state.showTable}
          loggingLevel={this.props.customerData?.loggingLevel}
        />
      </div>
    );
  }

  componentWillUnmount() {
    //toastr.removeByType("success");
    //toastr.removeByType("error");
    toast.dismiss();
  }
}

const mapStateToProps = state => {
  return {
    customerId: getCustomerId(state),
    loggedUserId: getUserId(state),
    logo: state.OrganisationReducer.logo,
    sessionsCount: getSessionsCount(state),
    sessionFollowUpsCount: getSessionFollowUpsCount(state),
    usersCount: getUsersCount(state),
    language: state.LanguageReducer.language,
    currentActiveTab:state.AdminReducer.currentActiveTab,
    selectedReport:getSelectedReport(state),
    sessionKey:state.AdminReducer.sessionKey,
    sessionListPageSize: getSessionListPageSize(state),
    sessionListCurrentPageNo: getSessionListCurrentPageNo(state),
    userListPageSize: getUserListPageSize(state),
    userListCurrentPageNo: getUserListCurrentPageNo(state),
    sessionFollowUpsPageSize: getSessionFollowUpsPageSize(state),
    sessionFollowUpsCurrentPageNo: getSessionFollowUpsCurrentPageNo(state),
    isSSOEnabled: getIsSSOEnabled(state),
    pdftronKey: selectPDFTronKey(state),
    qrCodesCount: getQRCodesCount(state),
    qrCodesListCurrentPageNo: getQRCodesListCurrentPageNo(state),
    qrCodesListPageSize: getQRCodesListPageSize(state),
    qrLimit : getOrganisationQRCodesLimit(state),
    totalQRCodes: getOrganisationTotalQRCodesCount(state),
    customerData: state.OrganisationReducer.customerData,
    isQRCodeEnabled : state.QRCodesReducer.isQRCodeEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setQRCodeEnabled : setQRCodeEnabled
  }, dispatch);
};

export default withErrorHandler(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardContainer));
