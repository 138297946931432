import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class UserProfileAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getUserProfileAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.USER_PROFILE
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SET_USER_PROFILE_DATA,
            userProfileData: response.data.data
          });
          this.dispatch({
            type: ACTIONS.UPDATE_PROFILE,
            userDetails: response.data.data
          });
          resolve(response);          
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateUserProfileAction(userProfileData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: API_URL.USER_PROFILE,
        requestBody:userProfileData
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.UPDATE_PROFILE,
            userDetails: response.data.data
          });
          resolve(response);          
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
