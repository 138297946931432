import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

// Component
import Organisations from "./Organisations";
import DeleteConfirmationModal from "../../CommonComponents/DeleteConfirmationModal/DeleteConfirmationModal";
import Spinner from "../../Spinner/Spinner";

import { getMessage } from "CONFIG/i18n";
import { CONFIG, modulesAvailableConstant, SUPER_ADMIN_TABS } from "UTILS/Constants";

import { displayError } from "UTILS/Utility";
import { validateUserName } from "UTILS/regexValidations";
import {getCurrentDateTimeInLocalTimezone} from "UTILS/DateUtils";

// Images
import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_pdf from "ASSETS/Images/pdf/ic_pdf.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_camera from "ASSETS/Images/ic_camera_header.svg";
import ic_admin_co_browse from "ASSETS/Images/ic_admin_co_browse.svg"
import ic_QR_code from "ASSETS/Images/ic_QR_code.svg";
import download_img from "ASSETS/Images/ic_download_allow.svg";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation_small.svg";

import { ERROR, TIMEOUT } from "UTILS/Constants";
import { convertMinutesToDDhhmm } from "UTILS/DateUtils";

//actions
import { organisationAction, userAction } from "CONFIG/ActionFactory";
const customerObjectInitialState = {
  id: "",
  name: "",
  seats: "",
  modules: [],
  firstName: "",
  lastName: "",
  username: "",
  license_expiry: new Date(),
  license_key: "",
  admin: [],
  logo:"",
  msClusterInfo:[],
  turnClusterInfo:[],
};

class OrganisationsContainer extends Component {
  constructor(props) {
    super(props);
    this.subscribe = null;
    this.addOrgRef = React.createRef();
    this.getStatus = React.createRef();
    this.state = {
      responseObject: { data: [] },
      searchedCustomer: { data: [] },
      showAddOrganizationModal: false,
      showEditOrganizationModal: false,
      editOrganisationData: customerObjectInitialState,
      editOrganisationIndex: -1,
      check: null,
      showConfirmationModalFlag: false,
      deleteCustomerId: null,
      deleteCustomerName: null,
      errorMessage: "",
      customerId: "",
      isSearchResult: false,
      searchString: "",
      sortByKey:true, // if sortByKey == true desc if false asc
      lastRefreshTime:"",
      lastRefreshTimeTurn:"",
      isKurentoServerOn: false,
      summaryData: {
        totalCustomers:{label:getMessage("NUMBER_OF_CUSTOMER_ACCOUNTS"), value: 0},
        usedAndMaxCapacity:{label:`${getMessage("USED_CAPACITY")}/${getMessage("TOTAL_CAPACITY")}`, value: 0},
        totalSessions:{label:getMessage("NUMBER_OF_TOTAL_SESSIONS"), value: 0 },
        activeSessions:{label:getMessage("NUMBER_OF_ACTIVE_SESSIONS"), value: 0 },
        totalTimeToDate:{label:getMessage("TOTAL_USAGE_TO_DATE"), value: 0}
      }
    };
  }

  componentDidMount() {
    this.showSpinner();

    switch(this.props.currentActiveTab) {
      case SUPER_ADMIN_TABS.ORGANIZATIONS:
        this.getAllCustomerData();
        break;

      case SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS:
        // Displaying System Announcements List is handled in SystemAnnouncementContainer.js
        this.hideSpinner();
        break;

      case SUPER_ADMIN_TABS.CLUSTER_INFO:
        if(this.props.usingKmsCluster) {
          this.getClusterInfo();
        } 
        if(this.props.usingTurnCluster) {
          this.getTurnClusterInfo();
        }
        break;

      default:
        console.log("Invalid tab"); return;
    }
    
    this.getServerStatusAndUsersCount();
  }

  getScrollBarWidth() {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();
    return width;
  }

   getClusterInfo =() =>{

     this.showSpinner();

     organisationAction.getMSClusterStatistcsAction().then(
       clusterData =>{
         this.setState({
           msClusterInfo: clusterData.data.data,
           lastRefreshTime: getCurrentDateTimeInLocalTimezone(this.props.language),
           showTable: true
         });

         this.hideSpinner();
       }, 
       error => {
         this.hideSpinner();

         this.setState({
           showTable: true
         });

         if(error.code)
           toastr.error(error.code);
       })
   }

    getTurnClusterInfo = () =>{
      this.showSpinner();

      organisationAction.getTurnClusterStatisticsAction().then(
        turnClusterData =>{
          this.setState({
            turnClusterInfo: turnClusterData.data.data,
            lastRefreshTimeTurn: getCurrentDateTimeInLocalTimezone(this.props.language),
            showTable: true
          });

          this.hideSpinner();
        },
        error => {
          this.hideSpinner();
          
          this.setState({
            showTable: true
          });

          if(error.code)
            toastr.error(error.code);
        }
      )
    }

   retireInstance = (selectedInstances, callback) =>{
     organisationAction.retireMsClusterInstanceAction(selectedInstances).then(
       retireInstance=> {
         toastr.success(retireInstance.data.data);
         if(callback) callback("success")
       },
       error=>{
         if(error.code)
           toastr.error(error.code);
         if(callback) callback();
       }
     ).catch (
       error => {
         if(error.code)
           toastr.error(error.code);
         if(callback) callback();
       }
     )
   } 

  getAllCustomerData = () => {
    organisationAction.getAllCustomersAction().then(
      response_data => {
        let responseObject = this.sortCustomerList(response_data.data);
        this.hideSpinner();
        this.setState({ 
          responseObject: responseObject,
          showTable: true
        }, () => {
          this.state.isSearchResult ? this.handleSearchString() : null;
        });
      },
      error => {
        this.hideSpinner();
        
        this.setState({
          showTable: true
        });

        if(error.code)
          toastr.error(error.code);
      }
    );
  };

  sortCustomerList = (response_data, changeFlag=false) => {
    let sortBy = 'desc'
    if(changeFlag){
      sortBy = this.state.sortByKey ? 'desc' : 'asc' ;
      this.setState({sortByKey: !this.state.sortByKey});
    } 
    let arrayOfCustomer = _.orderBy(response_data.data, [customer => customer.name.toLowerCase()],[sortBy]);
    response_data.data = arrayOfCustomer;
    return response_data;
  }

  handleAddClick = (customerWithAdminData, callBackForError) => {
    organisationAction.addCustomerWithAdminAction(customerWithAdminData).then(
      response_data => {
        toastr.success("ORGANIZATION_CREATED_SUCCESSFULLY");
        let newCustomer = response_data.data.data.customer;
        this.getAllCustomerData();
        this.getServerStatusAndUsersCount();
        this.setState({
          customerId: newCustomer.id,
          showAddOrganizationModal: false
        });
      },
      error => {
        callBackForError(getMessage(error.code));
        return false;
      }
    );
  };

  handleDeleteOrgClick = () => {
    organisationAction.deleteCustomerAction(this.state.deleteCustomerId).then(
      response_data => {
        this.getAllCustomerData();
        this.getServerStatusAndUsersCount();
        this.setState({
          showConfirmationModalFlag: false,
          deleteCustomerId: null,
          deleteCustomerName: null
        });
        toastr.success("ORGANIZATION_DELETED_SUCCESSFULLY");
      },
      error => {
        toastr.error(error.code);
        this.setState({
          showConfirmationModalFlag: false,
          deleteCustomerId: null,
          deleteCustomerName: null
        });
      }
    );
  };

  gotoAddOrganization = () => {
    this.props.history.push(CONFIG.path.addOrganization)
  };

  gotoEditOrganization = (userId, customerId) => {
    this.showSpinner(); 
    let customerData = _.find(this.state.responseObject.data, object => {
      return object.id === customerId;
    });

    organisationAction.getCustomersAction(customerId).then(
      response_data => {
        customerData.logo = response_data.data.data.logo
      },
      error => {
        if(error.code) {
          toastr.error(error.code, null, {timeOut: TIMEOUT.SHORT});
        }
      }
    );  

    userAction.getUsersAction(userId).then(
      response_data => {
        customerData.admin = response_data.data.data
          ? response_data.data.data
          : [];
        this.setState({
          editOrganisationData: customerData,
          editOrganisationIndex: customerId
        });
        
        organisationAction.getCustomerStorageDetails(customerId).then(
          response_data => {
            let customer= this.state.editOrganisationData;
            customer.storageDetails = response_data.data.data
            this.setState({ editOrganisationData: customer })
            organisationAction.setOrganisationAction(customerData);
            this.props.history.push(CONFIG.path.editOrganization); 
          },
          error => {
            toastr.error(error.code);
            customerData.storageDetails = {}
            this.hideSpinner(); 
          }
        );
      },
      error => {
        toastr.error(error.code);
        this.setState({
          editOrganisationData: customerData,
          editOrganisationIndex: customerId
        });
        this.hideSpinner(); 
      }
    );
  };

  showDeleteConfirmationModal = (customerId, deleteCustomerName) => {
    this.setState({
      showConfirmationModalFlag: true,
      deleteCustomerId: customerId,
      deleteCustomerName: deleteCustomerName
    });
  };

  handleCancelClick = () => {
    this.setState({
      showAddOrganizationModal: false,
      showConfirmationModalFlag: false
    });
  };

  handleEditCancelClick = () => {
    this.setState({
      showEditOrganizationModal: false,
      editOrganisationData: customerObjectInitialState
    });
  };

  validateUserData = (data, callBackForError) => {
    this.isValidUsername(data.user.username, callBackForError);
  };

  isValidUsername = (username, callBackForError) => {
    if (username === "") callBackForError(getMessage("INVALID_EMAIL"));
    else if (!validateUserName(username))
      callBackForError(getMessage("INVALID_EMAIL"));
  };

  getAvailableModules = sortedAvailableModules => {
    let updatedAvailableModule = {};
    let loopingArray = Array.from(sortedAvailableModules);
    if (!(modulesAvailableConstant.CANVAS in loopingArray)) {
      loopingArray.push(modulesAvailableConstant.CANVAS);
    }
    _.map(loopingArray, modulesAvailableItem => {
      switch (modulesAvailableItem) {
        case modulesAvailableConstant.AUDIO:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("AUDIO"),
            icon: ic_audio,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.AUDIO_VIDEO:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("VIDEO"),
            icon: ic_video,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.SCREENSHARE:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("SCREENSHARE"),
            icon: ic_share,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.PDF:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("PDF_EDITOR"),
            icon: ic_pdf,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.CANVAS:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("CANVAS"),
            icon: ic_canvas,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.CHAT:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("CHAT"),
            icon: ic_message,
            hasPermission: true
          };
          break;

        case modulesAvailableConstant.LOCATION:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("LOCATION"),
            icon: ic_location,
            hasPermission: true
          };
          break;
        case modulesAvailableConstant.SNAPSHOT:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("SNAP_SHOT"),
            icon: ic_camera,
            hasPermission: true
          };
          break;
        case modulesAvailableConstant.APP_EMULATION:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("APP_EMULATION"),
            icon: ic_app_emulation,
            hasPermission: true
          };
          break;
        case modulesAvailableConstant.QR_CODE:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("QR_CODE"),
            icon: ic_QR_code,
            hasPermission: true
          };
          break;
        case modulesAvailableConstant.ALLOW_DOWNLOAD:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("ALLOW_DOWNLOAD"),
            icon: download_img,
            hasPermission: true
          };
          break;
        case modulesAvailableConstant.CO_BROWSE:
          updatedAvailableModule[modulesAvailableItem] = {
            label: getMessage("CO_BROWSE"),
            icon: ic_admin_co_browse,
            hasPermission: true
          };
          break;
      }

      return null;
    });
    return updatedAvailableModule;
  };

  handleSearchString = (searchString = this.state.searchString) => {
    this.setState({
      isSearchResult: true,
      searchString: searchString
    });
    var items = this.state.responseObject.data;
    let searchStringItem = [];
    if (searchString.length > 0) {
      searchStringItem = items.filter(function(i) {
        return i.name.toLowerCase().match(searchString.toLowerCase());
      });
      this.setState({
        searchedCustomer: { data: searchStringItem }
      });
    } else {
      this.setState({
        searchedCustomer: { data: items }
      });
    }
  };

  renderSprinner() {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }
  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

getServerStatusAndUsersCount = () => {
  organisationAction.userStatisticsAction().then(
    response_data => {   
      let statstics = response_data.data.data;
      let serverCounts = this.state.summaryData;
      serverCounts.totalCustomers.value = statstics.totalCustomers;
      serverCounts.usedAndMaxCapacity.value = `${statstics.usedCapacity ? statstics.usedCapacity : 0}/${statstics.systemMaxCapacity ? statstics.systemMaxCapacity : 0}`;
      serverCounts.totalSessions.value = statstics.totalSessions;
      serverCounts.activeSessions.value = statstics.activeSessions!==null?statstics.activeSessions:0;
      serverCounts.totalTimeToDate.value = convertMinutesToDDhhmm(statstics.totalTimeToDate);
      this.setState({
        summaryData: serverCounts,
        isKurentoServerOn: statstics.kmsStatus
      });
    },
    error => {
      if (error.status === 404)
        displayError("PAGE_NOT_FOUND", null, "/", this);
    }
  );
};

fetchDataOnTabChange = (currentActiveTab) => {
  switch(currentActiveTab) {
    case SUPER_ADMIN_TABS.ORGANIZATIONS:
      this.setState({
        responseObject: { data: [] },
        showTable: false
      }, () => {
        this.getAllCustomerData();
      });
      break;

    case SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS:
      // Displaying System Announcements List is handled in SystemAnnouncementContainer.js
      break;

    case SUPER_ADMIN_TABS.CLUSTER_INFO:
      this.setState({
        msClusterInfo: [],
        turnClusterInfo: [],
        showTable: false
      }, () => {
        if(this.props.usingKmsCluster) {
          this.getClusterInfo();
        } 
        if(this.props.usingTurnCluster) {
          this.getTurnClusterInfo();
        }
      });
      break;

    default:
      console.log("Invalid tab");
  }
}

render() {
  return (
    <div style={this.state.spinnerVisibility ? {marginRight:this.getScrollBarWidth()}: {marginRight:0}}>
      {this.renderSprinner()}
      <DeleteConfirmationModal
        show={this.state.showConfirmationModalFlag}
        handleCancelClick={this.handleCancelClick}
        handleDeleteClick={this.handleDeleteOrgClick}
        deleteMessage={getMessage("DELETE_ORGANIZATION_MSG", {deleteCustomerName: this.state.deleteCustomerName})}
        headerMessage={getMessage("DELETE_ORGANIZATION")}
      />

      <Organisations
        gotoAddOrganization={this.gotoAddOrganization}
        responseObject={
          this.state.isSearchResult
            ? this.state.searchedCustomer
            : this.state.responseObject
        }
        gotoEditOrganization={this.gotoEditOrganization}
        showDeleteConfirmationModal={this.showDeleteConfirmationModal}
        getAvailableModules={this.getAvailableModules}
        history={this.props.history}
        subscribe={this.subscribe}
        ref={this.getStatus}
        handleSearchString={this.handleSearchString}
        sortCustomerList = {this.sortCustomerList}
        showTable = {this.state.showTable}
        currentActiveTab={this.props.currentActiveTab}
        clusterData={this.state.msClusterInfo}
        turnClusterData={this.state.turnClusterInfo}
        getClusterInfo={this.getClusterInfo}
        getTurnClusterInfo={this.getTurnClusterInfo}
        lastRefreshTime={this.state.lastRefreshTime}
        lastRefreshTimeTurn={this.state.lastRefreshTimeTurn}
        retireInstance={this.retireInstance}
        usingKmsCluster={this.props.usingKmsCluster}
        usingTurnCluster={this.props.usingTurnCluster}
        isKurentoServerOn={this.state.isKurentoServerOn}
        summaryData={this.state.summaryData}
        fetchDataOnTabChange={this.fetchDataOnTabChange}
        searchKey={this.state.searchString}
      />
    </div>
  );
}

}

const mapStateToProps = state => {
  return {
    currentActiveTab: state.OrganisationReducer.currentActiveTab,
    usingKmsCluster: state.UserReducer.usingKmsCluster,
    usingTurnCluster: state.UserReducer.usingTurnCluster,
    language: state.LanguageReducer.language
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationsContainer);
