import React, { Component } from "react";
import { isSafari } from "react-device-detect";

import BaseTest from "./BaseTest";
import StatisticsAggregate from "./Stats";
import TestResult from "./TestResult";

export default class VideoBandwidthTest extends BaseTest {
  constructor(props) {
    super(props);
    this.setValues();
    // Open the camera in 720p to get a correct measurement of ramp-up time.
    this.constraints = {
      audio: false,
      video: {
        optional: [
          {minWidth: 1280},
          {minHeight: 720}
        ]
      }
    };
  }

  componentDidMount() {
    if(this.props.startTest) {
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest){
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
    }
  }

  setValues = () => {
    // this.test = test;
    super.output="";
    this.maxVideoBitrateKbps = 2000;
    this.durationMs = 40000;
    this.statStepMs = 100;
    this.bweStats = new StatisticsAggregate(0.75 * this.maxVideoBitrateKbps *
        1000);
    this.rttStats = new StatisticsAggregate();
    this.packetsLost = -1;
    this.nackCount = -1;
    this.pliCount = -1;
    this.qpSum = -1;
    this.packetsSent = -1;
    this.packetsReceived = -1;
    this.framesEncoded = -1;
    this.framesDecoded = -1;
    this.framesSent = -1;
    this.bytesSent = -1;
    this.videoStats = [];
    this.startTime = null;
    this.call = null;
  }

  run = () => {
    this.asyncCreateTurnConfig(this.start.bind(this),
      ()=>{
        this.reportFatal()
        console.error('Video Bandwidth test failed');
      }
    );
  }

  start = (config) => {
    // this.call = new Call(config, this.test);
    this.initialize(config)
    this.setIceCandidateFilter(BaseTest.isRelay);
    // FEC makes it hard to study bandwidth estimation since there seems to be
    // a spike when it is enabled and disabled. Disable it for now. FEC issue
    // tracked on: https://code.google.com/p/webrtc/issues/detail?id=3050
    this.disableVideoFec();
    this.constrainVideoBitrate(this.maxVideoBitrateKbps);
    this.doGetUserMedia(this.constraints, this.gotStream.bind(this));
  }

  gotStream = (stream) => {
    console.log('stream: ', stream);
    stream.getTracks().forEach((track) => {
      this.pc1.addTrack(track, stream);
    });
    // this.pc1.addStream(stream);
    this.establishConnection();
    this.startTime = new Date();
    this.localStream = stream.getVideoTracks()[0];
    setTimeout(this.gatherStats.bind(this), this.statStepMs);
  }

  gatherStats = () => {
    var now = new Date();
    if ((now - this.startTime) > this.durationMs) {
      // this.test.setProgress(100);
      this.hangup();
      return;
    } else if (!this.statsGatheringRunning) {
      super.gatherStats(this.pc1, this.pc2, this.localStream,
        this.gotStats.bind(this));
    }
    // this.test.setProgress((now - this.startTime) * 100 / this.durationMs);
    setTimeout(this.gatherStats.bind(this), this.statStepMs);
  }

  gotStats = (response, time, response2, time2) => {
    // TODO: Remove browser specific stats gathering hack once adapter.js or
    // browsers converge on a standard.
    if (adapter.browserDetails.browser === 'chrome' || isSafari) {
      for (var i in response) {
        if (typeof response[i].connection !== 'undefined') {
          this.bweStats.add(response[i].connection.timestamp,
            parseInt(response[i].connection.availableOutgoingBitrate));
          this.rttStats.add(response[i].connection.timestamp,
            parseInt(response[i].connection.currentRoundTripTime * 1000));
          // Grab the last stats.
          this.videoStats[0] = response[i].video.local.frameWidth;
          this.videoStats[1] = response[i].video.local.frameHeight;
          this.nackCount = response[i].video.local.nackCount;
          this.packetsLost = response2[i].video.remote.packetsLost;
          this.qpSum = response2[i].video.remote.qpSum;
          this.pliCount = response[i].video.local.pliCount;
          this.packetsSent = response[i].video.local.packetsSent;
          this.packetsReceived = response2[i].video.remote.packetsReceived;
          this.framesEncoded = response[i].video.local.framesEncoded;
          this.framesDecoded = response2[i].video.remote.framesDecoded;
        }
      }
    } else if (adapter.browserDetails.browser === 'firefox') {
      for (var j in response) {
        if (response[j].id === 'outbound_rtcp_video_0') {
          this.rttStats.add(Date.parse(response[j].timestamp),
            parseInt(response[j].mozRtt));
          // Grab the last stats.
          this.jitter = response[j].jitter;
          this.packetsLost = response[j].packetsLost;
        } else if (response[j].id === 'outbound_rtp_video_0') {
          // TODO: Get dimensions from getStats when supported in FF.
          this.videoStats[0] = 'Not supported on Firefox';
          this.videoStats[1] = 'Not supported on Firefox';
          this.bitrateMean = response[j].bitrateMean;
          this.bitrateStdDev = response[j].bitrateStdDev;
          this.framerateMean = response[j].framerateMean;
        }
      }
    } else {
      this.reportError('Only Firefox and Chrome getStats implementations' +
        ' are supported.');
      console.error('Only Firefox and Chrome getStats implementations' +
        ' are supported.')
    }
    this.completed();
  }

  hangup() {
    this.pc1.getLocalStreams()[0].getTracks().forEach(function(track) {
      track.stop();
    });
    this.close();
    // this.call = null;
  }

  completed = () => {
    // TODO: Remove browser specific stats gathering hack once adapter.js or
    // browsers converge on a standard.
    if (adapter.browserDetails.browser === 'chrome' || isSafari) {
      // Checking if greater than 2 because Chrome sometimes reports 2x2 when
      // a camera starts but fails to deliver frames.
      if (this.videoStats[0] < 2 && this.videoStats[1] < 2) {
        this.reportError('Camera failure: ' + this.videoStats[0] + 'x' +
            this.videoStats[1] + '. Cannot test bandwidth without a working ' +
            ' camera.');
        console.error('Camera failure: ' + this.videoStats[0] + 'x' +
        this.videoStats[1] + '. Cannot test bandwidth without a working ' +
        ' camera.');
      } else {
        this.reportSuccess('Video resolution: ' + this.videoStats[0] +
            'x' + this.videoStats[1]);
        console.log('Video resolution: ' + this.videoStats[0] +
        'x' + this.videoStats[1]);
        this.reportInfo('Send bandwidth estimate average: ' +
            Math.round(this.bweStats.getAverage() / 1000) + ' kbps');
        console.log('Send bandwidth estimate average: ' +
        Math.round(this.bweStats.getAverage() / 1000) + ' kbps');
        this.reportInfo('Send bandwidth estimate max: ' +
            this.bweStats.getMax() / 1000 + ' kbps');
        console.log('Send bandwidth estimate max: ' +
        this.bweStats.getMax() / 1000 + ' kbps');
        this.reportInfo('Send bandwidth ramp-up time: ' +
            this.bweStats.getRampUpTime() + ' ms');
        console.log('Send bandwidth ramp-up time: ' +
        this.bweStats.getRampUpTime() + ' ms');
        this.reportInfo('Packets sent: ' + this.packetsSent);
        console.log('Packets sent: ' + this.packetsSent);
        this.reportInfo('Packets received: ' + this.packetsReceived);
        console.log('Packets received: ' + this.packetsReceived);
        this.reportInfo('NACK count: ' + this.nackCount);
        console.log('NACK count: ' + this.nackCount);
        this.reportInfo('Picture loss indications: ' + this.pliCount);
        console.log('Picture loss indications: ' + this.pliCount);
        this.reportInfo('Quality predictor sum: ' + this.qpSum);
        console.log('Quality predictor sum: ' + this.qpSum);
        this.reportInfo('Frames encoded: ' + this.framesEncoded);
        console.log('Frames encoded: ' + this.framesEncoded);
        this.reportInfo('Frames decoded: ' + this.framesDecoded);
        console.log('Frames decoded: ' + this.framesDecoded);
      }
    } else if (adapter.browserDetails.browser === 'firefox') {
      if (parseInt(this.framerateMean) > 0) {
        this.reportSuccess('Frame rate mean: ' +
            parseInt(this.framerateMean));
        console.log('Frame rate mean: ' +
        parseInt(this.framerateMean));
      } else {
        this.reportError('Frame rate mean is 0, cannot test bandwidth ' +
            'without a working camera.');
        console.error('Frame rate mean is 0, cannot test bandwidth ' +
        'without a working camera.')
      }
      this.reportInfo('Send bitrate mean: ' +
          parseInt(this.bitrateMean) / 1000 + ' kbps');
      console.log('Send bitrate mean: ' +
      parseInt(this.bitrateMean) / 1000 + ' kbps');
      this.reportInfo('Send bitrate standard deviation: ' +
          parseInt(this.bitrateStdDev) / 1000 + ' kbps');
      console.log('Send bitrate standard deviation: ' +
      parseInt(this.bitrateStdDev) / 1000 + ' kbps');
    }
    this.reportInfo('RTT average: ' + this.rttStats.getAverage() +
            ' ms');
    console.log('RTT average: ' + this.rttStats.getAverage() +
    ' ms');
    this.reportInfo('RTT max: ' + this.rttStats.getMax() + ' ms');
    console.log('RTT max: ' + this.rttStats.getMax() + ' ms');
    this.reportInfo('Packets lost: ' + this.packetsLost);
    console.log('Packets lost: ' + this.packetsLost);
    this.done();
    console.log('Video Bandwidth test completed');
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  componentWillUnmount() {
    this.close();
  }
}