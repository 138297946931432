import { ACTIONS } from "UTILS/Constants";

const initialState = {
  error: "",
  activeQRScreen: null,
  qrId: null,
  userWaitingEndTime: null,
  userWaitingTimeEnded: false,
  isAgentArrivedInMeeting: false,
  userDetails:{},
  isNoThanksMessageClicked:false,
  followUpSuccess: false,
  followUpFailure: false,
  qrSessionJoinType: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_QR_ERROR:
      return { ...state, error: action.value };
    case ACTIONS.SET_QR_ACTIVE_SCREEN:
      return { ...state, activeQRScreen: action.activeQRScreen };
    case ACTIONS.SET_QR_CODE:
      return { ...state, qrId: action.qrId };
    case ACTIONS.SET_USER_WAITING_ENDTIME:
      return { ...state, userWaitingEndTime: action.value };
    case ACTIONS.SET_USER_WATING_TIME_ENDED :
      return { ...state, userWaitingTimeEnded: action.value};
    case ACTIONS.SET_USER_DETAILS :
      return{...state,userDetails:action.value};
    case ACTIONS.SET_NO_THANKS_MESSAGE_SCREEN :
      return{...state,isNoThanksMessageClicked:action.value}
    case ACTIONS.SET_FOLLOW_UP_SUCCESS :
      return{...state,followUpSuccess:action.value}
    case ACTIONS.QR_CODES.SET_QR_SESSION_JOIN_TYPE:
      return {
        ...state,
        qrSessionJoinType: action.value
      }
    case ACTIONS.QR_CODES.CLEAR_QR_CODE_DATA:
      return initialState;
    default:
      return state;
  }
}
