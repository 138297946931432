import { USER_ADMISSION_STATUS, PARTICIPANT_STATUS } from "UTILS/Constants";

export const getCoBrowsePermission = state => state.CoBrowseReducer.coBrowsePermissionRequest
export const getCoBrowsePermissionReply = state => state.CoBrowseReducer.coBrowsePermisssionReply
export const getParticipantLeftCoBrowseUniqueId = state => state.CoBrowseReducer.participantLeftUniqueId
export const getCoBrowseJoinedParticipantUniqueId = state => state.CoBrowseReducer.participantJoinedUniqueId
export const getParticipantDeniedCoBrowseUniqueId = state => state.CoBrowseReducer.participantDeniedPermissionUniqueId
export const getCoBrowseSessionStatus = state => state.CoBrowseReducer.coBrowseSessionStatus
export const getCoBrowseIFrameUrl= state => state.CoBrowseReducer.iframeUrl
export const getShowCoBrowse = state => state.CoBrowseReducer.showCoBrowse
export const getCoBrowseTargetSiteUrl = state => state.CoBrowseReducer.targetSiteUrl
export const getCoBrowseSessionId = state => state.CoBrowseReducer.cobrowseSessionId

export const selectSessionKey = state => {
  return (
    state.SessionReducer.userSessionDetails &&
    state.SessionReducer.userSessionDetails.sessionKey)
  || (state.SessionReducer.sessionDetails &&
    state.SessionReducer.sessionDetails.sessionKey);
};
export const selectUniqueId = state => {
  return state.SessionReducer.uniqueId;
};
export const selectUserName = state => {
  const userDetails = state.UserReducer.userDetails;
  if (userDetails) {
    return (
      (userDetails.firstName +
        " " +
        (userDetails.lastName ? userDetails.lastName : "")).trim()
    );
  }
  
  return null;
};
export const getCustomerId = state => {
  return state.UserReducer.userDetails.customerId;
}; 

export const selectPresenter = state => {
  return state.RoomMetaDataReducer.roomMetaData.presenterId;
};

export const getAdmittedParticipants = state => {
  return state.ParticipantReducer.participants 
    && state.ParticipantReducer.participants.filter(participant => 
      participant.admissionStatus == USER_ADMISSION_STATUS.ADMITTED
      && participant.status === PARTICIPANT_STATUS.ACTIVE);
};

export const isCurrentUserArrivedInCobrowse = state => {
  const participant = state.ParticipantReducer.participants 
    && state.ParticipantReducer.participants.filter(participant => 
      participant.uniqueId === state.SessionReducer.uniqueId);
  return participant.length > 0 && participant[0].cobrowseStatus;  
};