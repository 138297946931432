import React from "react";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { func } from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  DropdownItem
} from "reactstrap";

import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";

import { modulesAvailableConstant, adminModulesToDisplay, BUSINESS_MODELS, ERROR } from "../../../Utils/Constants";
import { checkIfValidImageFile } from "UTILS/Utility";
// Images
import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_camera from "ASSETS/Images/ic_camera_header.svg";
import ic_pdf from "ASSETS/Images/pdf/ic_pdf.svg";
import ic_camera_header from "ASSETS/Images/ic_camera_header.svg";
import ic_admin_co_browse from "ASSETS/Images/ic_admin_co_browse.svg"
import ic_QR_code from "ASSETS/Images/ic_QR_code.svg";
import download_img from "ASSETS/Images/ic_download_allow.svg";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation_small.svg";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";

// Strings
import { getMessage } from "CONFIG/i18n";
import "./AddOrganization.less";
import "../../App/App.less";

class AboutOrganisationModal extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };

  constructor(props) {
    super(props);
    this.state = {
      organizationData: {},
      organizationName: "",
      noOfSeats: "",
      image_data:"",
      logo: this.props.organizationData.logo,
      logoUrl:"",
      is_image_changed: false
    };
    this.uploadImageRef = null;
  }

  componentDidUpdate(preProps, prevState) {
    if(preProps.organizationData.logo !== this.props.organizationData.logo) {
      this.setState({
        logo : this.props.organizationData.logo
      });
    }
  }

  handleSaveClick = () => {
    let formData = new FormData();
    formData.append("file", this.state.image_data);
    formData.append("customerId", this.props.customerId);
    this.props.uploadOrgLogo(formData, errorCode => {
      if(errorCode) {
        toastr.error(errorCode);
      } else {
        this.setState({
          logo: URL.createObjectURL(this.state.image_data)
        });
      }
    });
  };

  toggleModal = () => {
    this.props.handleCancelClick();
  };

  handleOrgLogoChange = event => {
    if (event.target.files) {
      const file = event.target.files[0];
      if (!file) {
        return
      }
      const fileValidationResult = checkIfValidImageFile(file);
      if (!fileValidationResult.valid) {
        toastr.error(fileValidationResult.error);
        return
      }
      
      this.setState({
        logoUrl: file,
        image_data: file,
        is_image_changed: true
      }, ()=>{this.handleSaveClick()});
    }
  };

  handleUploadImage = () =>{
    this.uploadImageRef.click();
  }

  displayAllModuleList = () => {
    let allModuleList = [];

    _.map(adminModulesToDisplay, (key, value) => {

      switch (key) {
        case "CANVAS":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  checked={true}
                  disabled
                />
                <img
                  src={ic_canvas}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "AUDIO":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.AUDIO
                  )}
                />
                <img
                  src={ic_audio}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "AUDIO_VIDEO":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.AUDIO_VIDEO
                  )}
                />
                <img
                  src={ic_video}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "SCREENSHARE":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.SCREENSHARE
                  )}
                />
                <img
                  src={ic_share}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CHAT":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.CHAT
                  )}
                />
                <img
                  src={ic_message}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "LOCATION":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.LOCATION
                  )}
                />
                <img
                  src={ic_location}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "PDF_EDITOR":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.PDF
                  )}
                />
                <img
                  src={ic_pdf}
                  style={{
                    height: "17px",
                    width: "17px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "SNAP_SHOT":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.SNAPSHOT
                  )}
                />
                <img
                  src={ic_camera}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "APP_EMULATION":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={6}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.APP_EMULATION
                  )}
                />
                <img
                  src={ic_app_emulation}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "QR_CODE":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.QR_CODE
                  )}
                />
                <img
                  src={ic_QR_code}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "ALLOW_DOWNLOAD":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.ALLOW_DOWNLOAD
                  )}
                />
                <img
                  src={download_img}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CO_BROWSE":
          allModuleList.push(
            <Col className="paddingR0L3" key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox
                  disabled
                  checked={this.props.organizationData.modules.includes(
                    modulesAvailableConstant.CO_BROWSE
                  )}
                />
                <img
                  src={ic_admin_co_browse}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
      }
    });
    return allModuleList;
  }

  renderButtons = () => {
    return (
      <div>
        <Button className="buttons cancelBtn" onClick={this.handleCancelClick}>
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button onClick={this.handleSaveClick} className="buttons customBtn">
          {getMessage("SAVE")}
        </Button>
      </div>
    );
  };
  
  render() {
    let isMaxConcurrentSessions = this.props.organizationData.businessModel == BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS ;
    return (
      <div>
        <Modal
          isOpen={this.props.show}
          className="addOrganisationModal aboutOrganization"
          style={{maxWidth: "none"}}
          toggle={this.toggleModal}
        >
          <ModalHeader className="popupHeader">
            {getMessage("ABOUT_ORGANIZATION")}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label className="label">
                  {getMessage("ORGANIZATION_LOGO")}
                </Label>
                <Row className="flex5" disabled>
                  <Col md="6" className= {this.state.logo ? "OrganizationLogo profileLogoCss" : "OrganizationLogo profileLogoCss text-center"}>
                    <img
                      className={!this.state.logo ? "cameraAsLogo" :"OrganizationLogoImg"}
                      src={this.state.logo ? this.state.logo : ic_camera_header}
                      onLoad = {() => {
                        this.props.hideSpinner();
                      }}
                    />
                  </Col>
                  <Col md="3" className="uploadImgDivCss">
                    <Button
                      alt="upload_img"
                      className="cursorPointer uploadbtnCss"
                      onClick={this.handleUploadImage}
                    >
                      {getMessage("UPLOAD_PROFILE_IMG")}
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={event => {
                        this.handleOrgLogoChange(event);
                        event.target.value = null;
                      }}
                      className="inputFile displayNone"
                      id="uploadImage"
                      ref={ref => this.uploadImageRef=ref}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <DropdownItem divider />
              <FormGroup>
                <Label className="label">
                  {getMessage("ORGANIZATION_NAME")}
                </Label>
                <Row className="flex5">
                  <Label className="label aboutLabel">
                    {getMessage(this.props.organizationData.name)}
                  </Label>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label className="label">{isMaxConcurrentSessions ? getMessage("MAX_CONCURRENT_CONNECTIONS") : getMessage("NO_OF_SEATS")}</Label>
                <Row className="flex5">
                  <Label className="label aboutLabel">
                    {isMaxConcurrentSessions ? this.props.organizationData.maxConcurrentSessions : this.props.organizationData.seats}
                  </Label>
                </Row>
              </FormGroup>
              {isMaxConcurrentSessions && <div className="mt-4"></div>}
              <FormGroup>
                <Label className="label" style={{ alignSelf: "flex-start" }}>
                  {getMessage("AVAILABLE_MODULE")}
                </Label>
                <Row className="flex5">
                  {this.displayAllModuleList()}
                </Row>
              </FormGroup>
            </Form>
          </ModalBody>
          {/* <ModalFooter className="permissionButtons">
            {this.renderButtons()} 
          </ModalFooter> */}
        </Modal>
      </div>
    );
  }
}

export default modalScrollBar(AboutOrganisationModal);
