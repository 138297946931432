import React, { forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import './GenerateAndDownloadQRCode.less'
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { getMessage } from "CONFIG/i18n";

const GenerateAndDownloadQRCode = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    downloadQRCode (){
      if (props.downloadQRCode) {
        let canvas = document.getElementById(props.id);
        let qrCodeDownload = document.getElementById(("qrCodeDownload" + props?.id));
        let qrFileName = `${props?.fileName}.png`;
        if (qrCodeDownload) {
          qrCodeDownload.href = canvas.toDataURL("image/png");
          qrCodeDownload.download = qrFileName;
          qrCodeDownload.click();
        } else {
          toastr.error("UNABLE_TO_DOWNLOAD_QR_CODE");
          console.error("downloadQRCode : anchor tag not available")
        }
      } else {
        toastr.error("UNABLE_TO_DOWNLOAD_QR_CODE");
        console.error("QR code details not getting from parent component or download feature is not available")
      }
    }
  }));

  return (
    <>
      <QRCode
        includeMargin={true}
        id={props.id}
        value={props.value}
        className="qr-code-image"
      />
      {props.downloadQRCode && <a id={"qrCodeDownload" + props?.id} />}
    </>
  );
});

GenerateAndDownloadQRCode.PropTypes = {
  qrCodeDetails: PropTypes.object.isRequired,
  qrFileName: PropTypes.string,
  downloadQRCode: PropTypes.bool
}

GenerateAndDownloadQRCode.defaultProps = {
  qrFileName: null,
  downloadQRCode: false
}

export default GenerateAndDownloadQRCode;