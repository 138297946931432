import { getBrowserLanguage } from "UTILS/Utility";

export const ACTIONS = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",
  SSO_SUCCESS: "SSO_SUCCESS",
  SET_URI_IDENTIFIER: "SET_URI_IDENTIFIER",
  SET_ROLE_BY_PATH: "SET_ROLE_BY_PATH",
  USER_SESSION_CREATED: "USER_SESSION_CREATED",
  SET_SOCKET: "SET_SOCKET",
  CREATE_SESSION_SUCCESS: "CREATE_SESSION_SUCCESS",
  USER_JOIN: "USER_JOIN",
  JOIN_SESSION: "JOIN_SESSION",
  PARTICIPANT_ADMITTED_OR_REJECTED: "PARTICIPANT_ADMITTED_OR_REJECTED",
  USER_ADMITTED: "USER_ADMITTED",
  SET_JOINING_USER_DETAILS: "SET_JOINING_USER_DETAILS",
  START_SESSION_SUCCESS: "START_SESSION_SUCCESS",
  SAVE_SESSION_TITLE: "SAVE_SESSION_TITLE",
  SAVE_CURRENT_SESSION_INVITEES: "SAVE_CURRENT_SESSION_INVITEES",
  VIEW_INVITEES: "VIEW_INVITIES",
  SET_PARTICIPANT_ID: "SET PARTICIPANT ID",
  SET_USER_NAME: "SET_USER_NAME",
  SET_SESSION_STATUS: "SET_SESSION_STATUS",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  SET_LOGO: "SET_LOGO",
  RESET_LOGO: "RESET_LOGO",
  SET_ALLOWED_DOWNLOAD: "SET_ALLOWED_DOWNLOAD",
  RESET_SHOW_KMS_CONNECTION_STATUS: "RESET_SHOW_KMS_CONNECTION_STATUS",
  SET_LOCAL_STREAM: "SET_LOCAL_STREAM",
  SET_USER_PROFILE_DATA: "SET_USER_PROFILE_DATA",
  SET_SESSIONS_CAPACITY: "SET_SESSIONS_CAPACITY",
  SET_USER_DETAILS:"SET_USER_DETAILS",
  SET_NO_THANKS_MESSAGE_SCREEN :"SET_NO_THANKS_MESSAGE_SCREEN ",
  SET_FOLLOW_UP_SUCCESS: "SET_FOLLOW_UP_SUCCESS",
  CLEAR_STORE: "CLEAR_STORE",
  
  WHITEBOARD: {
    // SET_PDF_WEBVIEWER_INSTANCE: "SET_PDF_WEBVIEWER_INSTANCE",
    SET_CANVAS_COLOR: "SET_CANVAS_COLOR",
    SET_ACTIVE_CANVAS: "SET ACTIVE CANVAS",
    UPLOAD_IMAGE_FLAG: "UPLOAD_IMAGE_FLAG",
    ADD_CANVAS: "ADD_CANVAS",
    DELETE_CANVAS: "DELETE_CANVAS",
    UPDATE_CANVAS: "UPDATE_CANVAS",
    ADD_OBJECT: "ADD_OBJECT",
    DELETE_OBJECT: "DELETE_OBJECT",
    UPDATE_OBJECT: "UPDATE_OBJECT",
    MODIFY_OBJECT: "MODIFY_OBJECT",
    CLEAR_CANVAS: "CLEAR_CANVAS",
    SET_CLEAR_CANVAS_FLAG: "SET_CLEAR_CANVAS_FLAG",
    STORE_CANVASES: "STORE_CANVASES",
    SET_CANVAS_DATA: "SET_CANVAS_DATA",
    SET_CANVAS_THUMBNAIL: "SET_CANVAS_THUMBNAIL",
    SET_DEFAULT_FONT_INFO: "SET_DEFAULT_FONT_INFO",
    SAVE_PDF_FILE: "SAVE_PDF_FILE",
    SET_WHITE_BOARD_REF: "SET_WHITE_BOARD_REF",
    CLEAR_PDF_PAGE_OBJECTS: "CLEAR_PDF_PAGE_OBJECTS",
    SET_SELECTED_ANNOTATION: "SET_SELECTED_ANNOTATION",
    DOWNLOADING_TO_CANVAS: "DOWNLOADING_TO_CANVAS"
  },
  SNAPSHOT: {
    SET_SNAPSHOT_RUNNING: "SET_SNAPSHOT_RUNNING",
    SET_SNAPSHOT_STARTED: "SET_SNAPSHOT_STARTED",
    SHOW_SNAPSHOT: "SHOW_SNAPSHOT",
    SET_SNAPSHOT_CAMERA_PERMISSION: "SET_SNAPSHOT_CAMERA_PERMISSION",
    ADD_CANVAS: "ADD_SNAPSHOT_CANVAS",
    DELETE_CANVAS: "DELETE_SNAPSHOT_CANVAS",
    ADD_OBJECT: "ADD_SNAPSHOT_CANVAS_OBJECT",
    SET_CANVAS_DATA: "SET_SNAPSHOT_CANVAS_DATA",
    CLEAR_CANVAS: "CLEAR_SNAPSHOT_CANVAS",
    IS_DRAWING_MODE_ENABLED: "IS_DRAWING_MODE_ENABLED",
    SET_ANNOTATION_MODE: "SET_ANNOTATION_MODE",
    ADD_STICKY_OBJECT: "ADD_STICKY_OBJECT",
    SNAPSHOT_ANNOTATIONS_CLEARED: "SNAPSHOT_ANNOTATIONS_CLEARED",
    SNAPSHOT_SCAN_CODE_RESULT: "SNAPSHOT_SCAN_CODE_RESULT",
    SNAPSHOT_SCAN_CODE_ERROR: "SNAPSHOT_SCAN_CODE_ERROR",
    SNAPSHOT_SET_ACTIVE_CANVAS: "SNAPSHOT_SET_ACTIVE_CANVAS"
  },
  CHAT: {
    SET_MESSAGES: "SET_MESSAGES",
    ADD_MESSAGE: "ADD_MESSAGE",
    DISPLAY_MESSAGE: "DISPLAY_MESSAGE",
    SET_MESSAGES_READ: "SET_MESSAGES_READ",
    SET_CHAT_POP_OVER_STATE:"SET_CHAT_POP_OVER_STATE",
    SET_CHAT_GPT_VALUE: "SET_CHAT_GPT_VALUE",
    SET_CHAT_FONT_SIZE: "SET_CHAT_FONT_SIZE"
  },
  SET_LANGUAGE: "SET_LANGUAGE",
  LEAVE_SESSION_LOCAL: "LEAVE_SESSION_LOCAL",
  GET_ALL_SESSION_DATA: "GET_ALL_SESSION_DATA",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_ROOM_META_DATA: "SET_ROOM_META_DATA",
  SAVE_SCREENSHARING_PARTICIPANT_ID: "SAVE_SCREENSHARING_PARTICIPANT_ID",
  UPDATE_CURRENT_PARTICIPANT_INFO: "UPDATE_CURRENT_PARTICIPANT_INFO",
  SET_WEBRTC_REQUEST_PERMISSION: "SET_WEBRTC_REQUEST_PERMISSION",
  SAVE_MEDIA_SHARING_STATUS: "SAVE_MEDIA_SHARING_STATUS",
  SET_WEBRTC_REPLY_PERMISSION: "SET_WEBRTC_REPLY_PERMISSION",
  SET_WEBRTC_REPLY_PERMISSION_STATUS: "SET_WEBRTC_REPLY_PERMISSION_STATUS",
  SET_MEDIA_DEVICE_DENIED_DETAILS: "SET_MEDIA_DEVICE_DENIED_DETAILS",
  RESET_MEDIA_DEVICE_DENIED_DETAILS: "RESET_MEDIA_DEVICE_DENIED_DETAILS",
  SAVE_MESSAGES: "SAVE_MESSAGES",
  SET_HEADER_ICONS: "SET_HEADER_ICONS",
  UPDATE_HEADER_ICON_CLASSNAME: "UPDATE_HEADER_ICON_CLASSNAME",
  UPDATE_PARTICIPANTS: "UPDATE_PARTICIPANTS",
  SET_USER_EVICTED_FLAG: "SET_USER_EVICTED_FLAG",
  DELETE_PARTICIPANT: "DELETE_PARTICIPANT",
  SET_EVICTED_PARTICIPANT_DETAILS: "SET_EVICTED_PARTICIPANT_DETAILS",
  SET_END_SESSION_FLAG: "SET_END_SESSION_FLAG",
  SET_END_SESSION_FORCEFULLY_FLAG: "SET_END_SESSION_FORCEFULLY_FLAG",
  UPADTE_PARTICIPANT_MEDIA_TYPE: "UPADTE_PARTICIPANT_MEDIA_TYPE",
  SET_AUDIO_OR_VIDEO_RTC_PEER: "SET_AUDIO_OR_VIDEO_RTC_PEER",
  ADD_SNAPSHOT: "ADD_SNAPSHOT",
  SET_SHOW_SNAPSHOT_POPUP: "SET_SHOW_SNAPSHOT_POPUP",
  SET_SNAPSHOTEE_ID: "SET_SNAPSHOTEE_ID",
  SET_SNAPSHOTER_ID: "SET_SNAPSHOTER_ID",
  SET_SNAPSHOT_PERMISSION_REPLY: "SET_SNAPSHOT_PERMISSION_REPLY",
  SET_SNASHOT_PERMISSION_STATUS: "SET_SNASHOT_PERMISSION_STATUS",
  UPADTE_PARTICIPANT_MEDIA_STATUS: "UPADTE_PARTICIPANT_MEDIA_STATUS",
  SET_ALLOW_EDIT: "SET_ALLOW_EDIT",
  RESET_ALL_PARTICIPANT_MEDIA_STATUS: "RESET_ALL_PARTICIPANT_MEDIA_STATUS",
  SET_SESSION_JOINED_FLAG: "SET_SESSION_JOINED_FLAG",
  RESET_SNAPSHOT_DATA: "RESET_SNAPSHOT_DATA",
  SET_SNAPSHOT_TAKEN: "SET_SNAPSHOT_TAKEN",
  SET_SWITCH_CAMERA: "SET_SWITCH_CAMERA",
  SET_RENEGOTIATE_SCREEN_PARTICIPANT: "SET_RENEGOTIATE_SCREEN_PARTICIPANT",
  PDF: {
    CHANGE_ACTIVE_PAGE: "CHANGE_ACTIVE_PAGE",
    _CHANGE_ACTIVE_PAGE: "_CHANGE_ACTIVE_PAGE",
    SET_PAGE_POSITION: "SET_PAGE_POSITION",
    _SET_PAGE_POSITION: "_SET_PAGE_POSITION",
    _UPDATE_URL: "_UPDATE_URL",
    UPDATE_URL: "UPDATE_URL",
    SET_FULL_SCREEN: "SET_FULL_SCREEN",
    _SET_FULL_SCREEN: "_SET_FULL_SCREEN",
    SET_FIT_SCREEN: "SET_FIT_SCREEN",
    _SET_FIT_SCREEN: "_SET_FIT_SCREEN",
  },
  MAKE_PRESENTER: "MAKE_PRESENTER",
  NOTIFICATION: {
    ADD_ANNOTATION: "ADD_ANNOTATION",
    _ADD_ANNOTATION: "_ADD_ANNOTATION",
    REMOVE_ANNOTATION: "REMOVE_ANNOTATION",
    _REMOVE_ANNOTATION: "_REMOVE_ANNOTATION",
  },
  LOCATION: {
    START_LOCATION_SHARE: "START_LOCATION_SHARE",
    SET_LOCATION_PERMISSION_REPLY: "SET_LOCATION_PERMISSION_REPLY",
    CLEAR_LOCATION: "CLEAR_LOCATION",
    ADD_LOCATION_PARTICIPANT_ID: "ADD_LOCATION_PARTICIPANT_ID",
    SET_LOCATION_REPLY_PERMISSION_STATUS: "SET_LOCATION_REPLY_PERMISSION_STATUS",
    ADD_LOCATION_DATA: "ADD_LOCATION_DATA",
    STOP_LOCATION_SHARE: "STOP_LOCATION_SHARE",
    SHARE_ETA: "SHARE_ETA",
    GET_ALL_LOCATION_DATA: "GET_ALL_LOCATION_DATA",
    STOP_ETA_SHARE: "STOP_ETA_SHARE",
    LOCATION_SHARING_WINDOW_CLOSED: "LOCATION_SHARING_WINDOW_CLOSED",
  },
  APP_EMULATION: {
    APP_EMULATION_REQUEST: "APP_EMULATION_REQUEST",
    APP_EMULATION_REPLY: "APP_EMULATION_REPLY",
    _APP_EMULATION_REPLY: "_APP_EMULATION_REPLY",
    SET_IFRAME_URL: "SET_IFRAME_URL",
    SET_PARTICIPANT: "SET_PARTICIPANT",
    APP_EMULATION_END_SESSION: "APP_EMULATION_END_SESSION",
    APP_EMULATION_LEAVE_SESSION: "APP_EMULATION_LEAVE_SESSION",
    APP_EMULATION_JOIN_SESSION: "APP_EMULATION_JOIN_SESSION",
    PERMISSION_DENIDED: "PERMISSION_DENIDED",
    SET_APP_EMULATION_SESSION_STATUS: "SET_APP_EMULATION_SESSION_STATUS",
    SET_SELECTED_DEVICE_INFO: "SET_SELECTED_DEVICE_INFO",
    SET_APP_EMULATION_SETTINGS: "SET_APP_EMULATION_SETTINGS"
  },
  ERROR: {
    KMS_NOT_CONNECTED: "KMS_NOT_CONNECTED",
    KMS_CONNECTED: "KMS_CONNECTED",
    ERROR_IN_WEBRTC_MEDIA_SHARING: "ERROR_IN_WEBRTC_MEDIA_SHARING",
  },
  CALL_STATUS: "CALL_STATUS",
  SET_TOKEN: "SET_TOKEN",
  SET_USER_ROLE: "SET_USER_ROLE",
  SET_CUSTOMER_ID: "SET_CUSTOMER_ID",
  GET_CUSTOMER_ID: "GET_CUSTOMER_ID",
  CALL_MODAL_PERMISSION: "CALL_MODAL_PERMISSION",
  SET_QR_ERROR: "SET_QR_ERROR",
  SET_WEBRTC_SESSION_MEDIA: "SET_WEBRTC_SESSION_MEDIA",
  SET_QR_ACTIVE_SCREEN: "SET_QR_ACTIVE_SCREEN",
  SET_QR_CODE: "SET_QR_CODE",
  SET_MODULES: "SET_MODULES",
  STOP_VIDEO_CALL: "STOP_VIDEO_CALL",
  START_VIDEO_CALL: "START_VIDEO_CALL",
  SET_CAMERA_BUSY_FLAG: "SET_CAMERA_BUSY_FLAG",
  SET_TURN_SERVER_DATA: "SET_TURN_SERVER_DATA",
  SET_CUSTOMER_DATA: "SET_CUSTOMER_DATA",
  REMOVE_TOKEN_SESSION_KEY: "REMOVE_TOKEN_SESSION_KEY",
  SET_QR_SESSION_STATUS: "SET_QR_SESSION_STATUS",
  SET_SESSIONS_COUNT: "SET_SESSIONS_COUNT",
  START_SNAPSHOT: "START_SNAPSHOT",
  SET_CALL_MEDIA_TYPE: "SET_CALL_MEDIA_TYPE",
  SET_USERS_COUNT: "SET_USERS_COUNT",
  ADMIN_ACTION: {
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SAVE_SESSION_KEY: "SAVE_SESSION_KEY",
    SAVE_SESSION_LIST_PAGE_SIZE: "SAVE_SESSION_LIST_PAGE_SIZE",
    SAVE_SESSION_LIST_CURRENT_PAGE_NO: "SAVE_SESSION_LIST_CURRENT_PAGE_NO",
    SAVE_USER_LIST_PAGE_SIZE: "SAVE_USER_LIST_PAGE_SIZE",
    SAVE_USER_LIST_CURRENT_PAGE_NO: "SAVE_USER_LIST_CURRENT_PAGE_NO",
    SELECT_REPORT: "SELECT_REPORT",
    SET_SESSION_FOLLOW_UPS_COUNT: "SET_SESSION_FOLLOW_UPS_COUNT",
    SAVE_SESSION_FOLLOW_UPS_CURRENT_PAGE_NO: "SAVE_SESSION_FOLLOW_UPS_CURRENT_PAGE_NO",
    SAVE_SESSION_FOLLOW_UPS_PAGE_SIZE: "SAVE_SESSION_FOLLOW_UPS_PAGE_SIZE"
  },
  ORGANISATION_ACTION: {
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_ORGANIZATION_DATA: "SET_ORGANIZATION_DATA",
  },
  TEST_PAGE: {
    CAMERA_SELECTED: "CAMERA_SELECTED",
    MIC_SELECTED: "MIC_SELECTED",
  },
  SET_USING_CLUSTER_STATUS: "SET_USING_CLUSTER_STATUS",
  SET_COMPONENT_IN_FOCUS: "SET_COMPONENT_IN_FOCUS",
  SET_FULL_SCREEN: "SET_FULL_SCREEN",
  SET_SAVE_SESSION_DATA_FLAG: "SET_SAVE_SESSION_DATA_FLAG",
  SCHEDULE_SESSION_SUCCESS: "SCHEDULE_SESSION_SUCCESS",
  DELETE_SESSION_SUCCESS: "DELETE_SESSION_SUCCESS",
  SET_SESSION_SCHEDULE_LIST: "SET_SESSION_SCHEDULE_LIST",
  SET_SORTED_PARTICIPANT: "SET_SORTED_PARTICIPANT",
  SET_SESSION_TYPE: "SET_SESSION_TYPE",
  SET_EDIT_SESSION_DATA: "SET_EDIT_SESSION_DATA",
  SET_PASSCODE: "SET_PASSCODE",
  SET_PASSCODE_STATUS: "SET_PASSCODE_STATUS",
  PASSWORD_CHANGED: "PASSWORD_CHANGED",
  SET_TRAIL_HISTORY_DATA: "SET_TRAIL_HISTORY_DATA",
  CO_BROWSE: {
    CO_BROWSE_REQUEST: "CO_BROWSE_REQUEST",
    CO_BROWSE_REPLY: "CO_BROWSE_REPLY",
    _CO_BROWSE_REPLY: "_CO_BROWSE_REPLY",
    SET_IFRAME_URL: "SET_IFRAME_URL",
    SET_PARTICIPANT: "SET_PARTICIPANT",
    CO_BROWSE_LEAVE_SESSION: "CO_BROWSE_LEAVE_SESSION",
    CO_BROWSE_JOIN_SESSION: "CO_BROWSE_JOIN_SESSION",
    CO_BROWSE_PERMISSION_DENIDED: "CO_BROWSE_PERMISSION_DENIDED",
    SET_CO_BROWSE_SESSION_STATUS: "SET_CO_BROWSE_SESSION_STATUS",
    CO_BROWSE_TOGGLE: "CO_BROWSE_TOGGLE",
    SET_TARGET_URL: "SET_TARGET_URL",
    CO_BROWSE_PARTICIPANT_STATUS_UPDATE: "CO_BROWSE_PARTICIPANT_STATUS_UPDATE",
    RESET_CO_BROWSE_SESSION_DETAILS: "RESET_CO_BROWSE_SESSION_DETAILS",
    SET_CO_BROWSE_DETAILS: "SET_CO_BROWSE_DETAILS"
  },
  CUSTOMER_CO_BROWSE: {
    SET_CUSTOMER_CO_BROWSE_REQUEST: "SET_CO_BROWSE_REQUEST",
    SET_CUSTOMER_CO_BROWSE_REPLY: "SET_CO_BROWSE_REPLY",
    CUSTOMER_CO_BROWSE_JOIN_SESSION: "CUSTOMER_CO_BROWSE_JOIN_SESSION,",
    CUSTOMER_CO_BROWSE_LEAVE_SESSION: "CUSTOMER_CO_BROWSE_LEAVE_SESSION",
    SET_CUSTOMER_CO_BROWSE_DATA: "SET_CUSTOMER_CO_BROWSE_DATA",
    CUSTOMER_CO_BROWSE_PERMISSION_DENIDED: "CUSTOMER_CO_BROWSE_PERMISSION_DENIDED",
    CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE: "CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE",
    CUSTOMER_CO_BROWSE_END_SESSION: "CUSTOMER_CO_BROWSE_END_SESSION",
    SHOW_CUSTOMER_CO_BROWSE: "SHOW_CUSTOMER_CO_BROWSE",
    SHOW_TARGET_SITE_SELECTION_MODAL: "SHOW_TARGET_SITE_SELECTION_MODAL",
  },
  SET_CONFIG: "SET_CONFIG",
  USER_PLUGIN_NOT_INSTALLED: "USER_PLUGIN_NOT_INSTALLED",
  RESET_PARTICIPANT_PLUGIN_INFO: "RESET_PARTICIPANT_PLUGIN_INFO",
  SET_RENEGOTIATE_VIDEO_PARTICIPANT: "SET_RENEGOTIATE_VIDEO_PARTICIPANT",
  SET_RENEGOTIATE_AUDIO_PARTICIPANT: "SET_RENEGOTIATE_AUDIO_PARTICIPANT",
  DELETE_RENEGOTIATE_VIDEO_PARTICIPANT: "DELETE_RENEGOTIATE_VIDEO_PARTICIPANT",
  DELETE_RENEGOTIATE_AUDIO_PARTICIPANT: "DELETE_RENEGOTIATE_AUDIO_PARTICIPANT",
  RESET_AUDIO_VIDEO_RENEGOTIATE: "RESET_AUDIO_VIDEO_RENEGOTIATE",
  SET_WEBSOCKET_ERROR: "SET_WEBSOCKET_ERROR",
  SET_CUSTOM_SETTINGS: "SET_CUSTOM_SETTINGS",
  SET_USER_WAITING_ENDTIME: "SET_USER_WAITING_ENDTIME",
  SET_USER_WATING_TIME_ENDED: "SET_USER_WATING_TIME_ENDED",
  USER_LEFT: "USER_LEFT",
  QR_CODES: {
    SET_QR_CODES_COUNT: "SET_QR_CODES_COUNT",
    SET_QR_CODES_CURRENT_PAGE_NO: "SET_QR_CODES_CURRENT_PAGE_NO",
    SET_QR_CODES_PAGE_SIZE: "SET_QR_CODES_PAGE_SIZE",
    SET_TOTAL_QR_CODES_COUNT: 'SET_TOTAL_QR_CODES_COUNT',
    SET_ORGANIZATION_QR_LIMIT: "SET_ORGANIZATION_QR_LIMIT",
    SET_QR_CODE_ENABLED: "SET_QR_CODE_ENABLED",
    CLEAR_QR_CODE_DATA: "CLEAR_QR_CODE_DATA",
    SAVE_QR_CODE_AGENTS_PAGE_NO: "SAVE_QR_CODE_AGENTS_PAGE_NO",
    SAVE_QR_CODE_AGENTS_PAGE_SIZE: "SAVE_QR_CODE_AGENTS_PAGE_SIZE",
    SAVE_QR_CODE_AGENTS_COUNT:"SAVE_QR_CODE_AGENTS_COUNT",
    SAVE_QR_CODE_DATA:"SAVE_QR_CODE_DATA",
    SAVE_UNASSIGNED_AGENTS_PAGE_NO: "SAVE_UNASSIGNED_AGENTS_PAGE_NO",
    SAVE_UNASSIGNED_AGENTS_PAGE_SIZE: "SAVE_UNASSIGNED_AGENTS_PAGE_SIZE",
    SAVE_UNASSIGNED_AGENTS_COUNT: "SAVE_UNASSIGNED_AGENTS_COUNT",
    SET_QR_SESSION_JOIN_TYPE: "SET_QR_SESSION_JOIN_TYPE",
  },
  SET_SESSION_INVALID: "SET_SESSION_INVALID",
  ALLOW_UPLOAD: "ALLOW_UPLOAD"
};

export const ANNOTATIONS = {
  LINE: "Line",
  CIRCLE: "Circle",
  RECTANGLE: "Rectangle",
  ERASE_OBJECT: "Erase Object",
  TEXT: "Text",
  SELECTION: "Selection",
  IMAGE_UPLOAD: "image_upload",
  IMAGE: "image",
  PDF_UPLOAD: "pdf_upload",
  UPLOAD_PDF_FROM_CLOUD: "upload_pdf_from_cloud",
  SPOTLIGHT: {
    NAME: "Spotlight",
    WIDTH: 40,
    COLOR: "rgba(255, 0, 0, 0.3)",
  },
  ERASER: {
    NAME: "Eraser",
    WIDTH: 35,
    COLOR: "white",
  },
  FREE_HAND: {
    NAME: "Free hand",
    WIDTH: 4,
    COLOR: "black",
  },
  PDF_HIGHLIGHT: {
    NAME: "Pdf Highlight",
    WIDTH: 25,
    COLOR: "rgba(255, 204, 0, 0.4)",
  },
  SNAPSHOT_FREE_HAND: {
    NAME: "Free hand",
    WIDTH: 8,
    COLOR: "black",
  },
  SNAPSHOT_CIRCLE: {
    NAME: "Circle",
    STROKE_WIDTH: 12,
  },
  SNAPSHOT_STICKY_ANNOTATION:{
    STROKE_WIDTH: 12,
  },
  SNAPSHOT_CANVAS_DIMENSIONS:{
    CANVAS_HEIGHT: 1150,
    CANVAS_WIDTH: 2050
  },
  SNAPSHOT_ANNOTATION_MODE: {
    STICKY_ANNOTATION: 'STICKY_ANNOTATION', // Object Tracking
    STATIC_ANNOTATION: 'STATIC_ANNOTATION', // Circle Annotation
    SCAN_CODE: 'SCAN_CODE', // QR-Code, Bar-Code Scanner
  }
};

export const SNAPSHOT_SCAN_CODE = {
  SEARCH_RESULT_QUERY: {
    fontSize: 36,
    left: 50,
    top: 85,
    color: '#4A4E63',
    maxTextLength: 75,
    fontFamily: "Arial"
  },
  SEARCH_RESULT_IMAGE: {
    width: 400,
    objectIdPrefix: 'scanCodeImage_'
  },
  SEARCH_RESULT_TITLE: {
    fontSize: 28,
    left: 50,
    top: 95,
    color: '#1a0dab',
    maxTextLength: 600,
    fontFamily: "Arial"
  },
  SEARCH_RESULT_DESC: {
    fontSize: 24,
    left: 50,
    top: 40,
    color: '#4A4E63',
    maxTextLength: 610,
    fontFamily: "Arial"
  },
  MODE: {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE'
  }
}

export const CONFIG = {
  path: {
    about: "/about",
    login: "/",
    logout: "/logout",
    adminLogin: "/admin/login",
    superAdminLogin: "/superadmin/login",
    ssoLogin: "/sso/login/callback",
    ssoLogout: "/sso/logout/callback",
    createSession: "/createMeeting",
    dashboard: "/dashboard",
    userJoin: "/join",
    userEvicted: "/userEvicted",
    reJoin: "/rejoin",
    admin: "/admin",
    superAdmin: "/superadmin",
    forgotPassword: "/forgotPassword",
    setPassword: "/setPassword",
    sessionEnded: "/sessionEnded",
    startSession: "/startSession",
    qrExpired: "/qrExpired",
    pageNotFound: "/pageNotFound",
    waitingRoom: "/waitingRoom",
    startMeeting: "/startMeeting",
    scheduleMeeting: "/scheduleMeeting",
    trailHistory: "/trailHistory",
    addOrganization: "/superadmin/addOrganization",
    editOrganization: "/superadmin/editOrganization",
    adminSettings: "/admin/settings",
    passcode: "/passcode",
    qrCodeAgents:"/admin/qrCodeAgents",
    assignAgentsToQRCode: "/admin/assignAgentsToQRCode",
    realwearEndCall: "/realwear/realwearEndCall",
  },

  // Partial paths not to be considered in the above path object for PageNotFound routing to work correctly
  partialPath: {
    qr: "/qr", // Part of the QR code scan URL that consists of /qr/<licenseKey>
  }
};

export const URI_IDENTIFIER_MODIFICATION_NOT_ALLOWED_PATH = [
  CONFIG.path.userJoin, CONFIG.path.passcode, CONFIG.path.ssoLogin, CONFIG.path.ssoLogout
]

export const API_URL = {
  LOGIN: "/auth/login",
  SSO_LOGIN: "/auth/sso/login",
  SSO_INFO: "/sso/info",
  LOGOUT: "/auth/logout",
  START_SESSION: "/session",
  JOIN_SESSION: "/session/joinSession",
  CREATE_USER_SESSION: "/session/createSession",
  VIEW_INVITEES: "/session/invitees",
  FORGOT_PASSWORD: "/user/forgotPassword",
  UPLOAD_CANVAS_THUMBNAIL: "/session/uploadThumbnail",
  UPLOAD_FILE: "/session/uploadFile",
  END_SESSION: "/session/end",
  INVITE_USER: "/session/invite",
  DOWNLOAD_CHAT: "/session/messages",
  SET_PASSWORD: "/user/setPassword",
  CUSTOMER: "/customer",
  CUSTOMER_SETTINGS: "/customer/settings",
  CUSTOMER_WITH_ADMIN: "/customer/withAdmin",
  USER: "/user",
  KMS_STATUS: "/session/kmsStatus",
  ALL_USERS_COUNT: "/user/count",
  ACTIVE_SESSION_COUNT: "/session/count",
  RESET_USER_PASSWORD: "/user/resetPassword",
  USER_UPLOAD_FILE: "/user/uploadFile",
  USER_STATISTICS: "/user/statistics",
  USER_PROFILE: "/user/profile/@me",
  APP_EMULATION_DEVICE_INFO: "/appEmulation/deviceInfo/",
  APP_EMULATION_START_SESSION: "/appEmulation/startSession",
  APP_EMULATION_JOIN_SESSION: "/appEmulation/joinSession",
  APP_EMULATION_END_SESSION: "/appEmulation/endSession",
  UPDATE_URL: "/user/profile/updateUrl",
  GET_CUSTOMER: "/customer/getFromQRCode",
  CREATE_SESSION_WITH_QR_CODE: "/session/qrcode",
  CHANGE_PASSWORD: "/user/changePassword",
  SUB_LOGGING_LEVEL: "/customer/subLoggingLevel",
  GET_SESSSION_LIST: "/session/sessionsListing",
  GET_SESSION_DETAIL: "/session/report",
  GET_SESSION_DATA: "/session/data",
  GET_PRESIGNED_OBJECT_URL: "/session/data/{objectId}/presigned",
  SAVE_SESSION_DATA: "/session/data",
  ANNOUNCEMENT: "/announcement",
  MSCLUSTER_STATISTICS: "/mscluster/statistics",
  TURNCLUSTER_STATISTICS: "turncluster/turnstatistics",
  MSCLUSTER_RETIREINSTANCE: "mscluster/retireInstance",
  SCHEDULE_SESSION: "/session/schedule",
  GET_SCHEDULE_SESSION_LIST: "/session/schedule",
  GET_QR_CODE_SESSION_LIST:"session/qrcode",
  VERIFY_STORAGE: "/customer/storage/verify",
  CUSTOMER_STORAGE: "/customer/storage",
  GET_SESSION_KEY_FROM_PASSCODE: "/session/key/",
  GET_FILE_STRUCTURE_FROM_CLOUD: "/session/organizationTemplate/fileStructure",
  GET_FILE_URL_OF_REMOTE_FILE: "/session/organizationTemplate/getFileFromS3",
  CO_BROWSE_START_SESSION: "/cobrowse/startSession",
  CO_BROWSE_JOIN_SESSION: "/cobrowse/joinSession",
  CO_BROWSE_END_SESSION: "/cobrowse/endSession",
  CO_BROWSE_LEAVE_SESSION: "/cobrowse/leaveSession",
  BULK_IMPORT_USERS:"/user/bulkImport",
  CUSTOMER_CO_BROWSE: "/cobrowse/api2/call",
  CUSTOMER_CO_BROWSE_TEST: "/cobrowse/api2/call_test",
  // TODO:if not used, remove this block of code once we able to get data from co browse server
  CUSTOMER_CO_BROWSE_SESSION: "/cobrowse/#/session",
  GET_CO_BROWSE_SITES: "/session/cobrowse/targetSites",
  BULK_IMPORT_USERS: "/user/bulkImport",
  GET_CUSTOMER_SETTINGS: "session/customer",
  JOIN_QR_CODE_SESSION:"/session/qrcode",
  CREATE_QR_FOLLOWUP_REQUEST:"/session/followUps",
  GET_ALL_FOLLOW_UPS: "/session/followUps",
  QR_CODES : '/qrcodes',
};
export const HTTP_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const APP_CONFIG = {
  baseUrl: window._env_.REACT_APP_LOOOKIT_SERVICES_BASE_URL,
};

export const CANVAS_TYPES = {
  REGULAR: "REGULAR",
  PDF: "PDF",
  LOCATION: "LOCATION",
  IMAGE: "IMAGE",
  SEARCH_RESULTS: "SEARCH_RESULTS"
};

export const CANVAS_SOURCE = {
  REGULAR: "REGULAR",
  PDF: "PDF",
  LOCATION: "LOCATION",
  IMAGE: "IMAGE",
  SNAPSHOT: "SNAPSHOT",
};

export const USER_ROLES = {
  AGENT: "AGENT",
  USER: "USER",
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
};

export const LOG_TYPE = {
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
};

export const STORAGE_LOCATION = {
  CUSTOMER: "CUSTOMER",
  LOOOKIT_CUSTOM: "LOOOKIT",
  LOOOKIT_GLOBAL: "LOOOKIT_GLOBAL",
};

export const SESSION_STATUS = {
  CLOSING: "CLOSING",
  ACTIVE: "ACTIVE",
  CREATED: "CREATED",
  WAITING: "WAITING",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
};

export const PARTICIPANT_STATUS = {
  WAITING: "WAITING",
  ACTIVE: "ACTIVE",
  LEFT: "LEFT",
};

export const USER_ADMISSION_STATUS = {
  PENDING: "PENDING",
  ADMITTED: "ADMITTED",
  REJECTED: "REJECTED",
};

export const errorStrings = {
  UserAlreadyExists: "User already exists.",
  UnauthorizedUser: "Unauthorized user",
  Forbidden: "Access denied",
  BadRequest: "Bad request",
  ServiceUnavailable: "Service unavailable",
  InternalServerError: "Internal server error",
  ResourceNotFound: "Requested resource not found",
  URImalformedError: "Uncaught URIError: URI malformed",
  URImalformedErrorFirefox: 'URIError: malformed URI sequence',
};
export const sessionStatus = {
  ACTIVE: "ACTIVE",
  CREATED: "CREATED",
  WAITING: 'WAITING',
  CLOSING: 'CLOSING',
  CLOSED: 'CLOSED',
  CLOSED_ABNORMAL: 'CLOSED_ABNORMAL',
  UNFULFILLED: 'UNFULFILLED',
};
export const loginRegex = {
  USERNAME_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD_REGEX: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15}$/,
  NUMBER_REGEX: /^\+?[0-9]\d{8,14}$/,
};
export const supportedLanguages = {
  en: "English",
  ja: "日本語",
};

export const MODAL_TYPE = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export const OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO =
  window._env_.REACT_APP_OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO;
  
export const LANGUAGE = {
  EN: {
    name: "en",
    value: "English",
  },
  JA: {
    name: "ja",
    value: "Japanese",
  },
};

export const DEFAULT_LANGUAGE = getBrowserLanguage();

export const Modules = {
  AUDIO: "AUDIO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREENSHARE: "SCREENSHARE",
  CHAT: "CHAT",
  LOCATION: "LOCATION",
  CANVAS: "CANVAS",
  SNAPSHOT: "SNAPSHOT",
  PDF: "PDF",
  APP_EMULATION: "APP_EMULATION",
  SNAPSHOT: "SNAPSHOT",
  CO_BROWSE: "CO_BROWSE"
};

export const cobrowseTypes= {
  WEB_CO_BROWSE: "WEB_CO_BROWSE",
  CUSTOMER_CO_BROWSE: "CUSTOMER_CO_BROWSE"
}

export const supportedMIMETypesForImage = [
  "image/png",
  "image/jpeg",
  "image/pjpeg",
  "image/x-png",
  "image/gif",
  "image/heif",
  "image/heic",
  "image/heif-sequence",
  "image/heic-sequence",
];

export const supportedMIMETypesForExcel=[
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
  "application/vnd.ms-excel", 
  "application/xls, application/x-dos_ms_excel", 
  "application/x-ms-excel",
  "application/x-excel", 
  "application/msexcel"
]

export const maxMeetingUploadImageFileSize = 10485760; // 10MB
export const maxOrganizationImageFileSize = 5242880; // 5MB
export const maxPdfFileSize = 10000000;
export const maxBulkImportFileSize = 10000000;
export const pdfTronLibPath = "/lib/PDFTron/lib";

export const IMAGE_TYPE = { 
  MEETING_UPLOAD_IMAGE: "MEETING_UPLOAD_IMAGE",
  ORGANIZATION_IMAGE: "ORGANIZATION_IMAGE"
} 

export const modulesAvailable = {
  AUDIO: "hasAudioModulePermission",
  VIDEO: "hasVideoModulePermission",
  SCREENSHARE: "hasScreenShareModulePermission",
  LOCATION: "hasLocationSharingModulePermission",
  CANVAS: "hasCanvasModulePermission",
  PDF: "hasPdfEditorModulePermission",
  MESSAGE: "hasMessageModulePermission",
};

export const modulesAvailableConstant = {
  CANVAS: "CANVAS",
  AUDIO: "AUDIO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREENSHARE: "SCREENSHARE",
  LOCATION: "LOCATION",
  PDF: "PDF",
  CHAT: "CHAT",
  SNAPSHOT: "SNAPSHOT",
  APP_EMULATION: "APP_EMULATION",
  QR_CODE: "QR_CODE",
  ALLOW_DOWNLOAD: "ALLOW_DOWNLOAD",
  CO_BROWSE: "CO_BROWSE"
};
export const downloadableModules = {
  CANVAS: "CANVAS",
  CHAT: "CHAT",
  SCREENSHARE: "SCREENSHARE",
};

export const listOfAvailableSubModule = ["CANVAS", "CHAT", "SCREENSHARE"];

export const listOfAvailableModule = [
  "CANVAS",
  "AUDIO",
  "AUDIO_VIDEO",
  "SCREENSHARE",
  "CHAT",
  "LOCATION",
  "PDF",
  "SNAPSHOT",
  "APP_EMULATION",
  "QR_CODE",
  "ALLOW_DOWNLOAD",
  "CO_BROWSE"
];

export const modulesToDisplay = {
  CANVAS: "CANVAS",
  // PDF_EDITOR: "PDF_EDITOR",
  // ALLOW_DOWNLOAD: "ALLOW_DOWNLOAD",
  AUDIO: "AUDIO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREENSHARE: "SCREENSHARE",
  SNAP_SHOT: "SNAP_SHOT",
  CHAT: "CHAT",
  LOCATION: "LOCATION",
  QR_CODE: "QR_CODE", 
  APP_EMULATION: "APP_EMULATION",
  CO_BROWSE: "CO_BROWSE",
};

export const adminModulesToDisplay = {
  CANVAS: "CANVAS",
  AUDIO: "AUDIO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREENSHARE: "SCREENSHARE",
  SNAP_SHOT: "SNAP_SHOT",
  CHAT: "CHAT",
  LOCATION: "LOCATION",
  QR_CODE: "QR_CODE", 
  CO_BROWSE: "CO_BROWSE",
  APP_EMULATION: "APP_EMULATION",
}

export const availableFeatures = {
  SINGLE_SIGN_ON: "SINGLE_SIGN_ON",
  SPECIFIED_MEETING_CODE: "SPECIFIED_MEETING_CODE",
  ORGANIZATION_TEMPLATE: "ORGANIZATION_TEMPLATE",
  PDF_EDITOR: "PDF_EDITOR",
  ALLOW_DOWNLOAD: "ALLOW_DOWNLOAD",
  RING_CENTRAL: "RING_CENTRAL",
  SNAPSHOT_SCAN_CODE: "SNAPSHOT_SCAN_CODE",
  CHAT_GPT: "CHAT_GPT",
  ALLOW_UPLOAD: "ALLOW_UPLOAD",
};

export const modulesForSessionContext = {
  CANVAS: "CANVAS",
  AUDIO: "AUDIO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREENSHARE: "SCREENSHARE",
  PDF_EDITOR: "PDF_EDITOR",
  CHAT: "CHAT",
  LOCATION: "LOCATION",
  SNAP_SHOT: "SNAP_SHOT",
  QR_CODE: "QR_CODE",
  CO_BROWSE: "CO_BROWSE",
  APP_EMULATION: "APP_EMULATION",
};

export const TIMEOUT = {
  SHORT: 3000,
  LONG: 5000,
  API_REQUEST_TIMEOUT: 120000,
  AUDIO_VIDEO_FILM_STRIP_TIMEOUT: 5000,
};

export const ERROR = {
  INVALID_SESSION: "INVALID_SESSION",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
  CUSTOMER_DELETED: "CUSTOMER_DELETED",
  QR_CODE_EXPIRED: "QR_CODE_EXPIRED",
  INVALID_REQUEST_DATA: "INVALID_REQUEST_DATA",
  INVALD_DATA_REQUEST: "INVALD_DATA_REQUEST",
  SERVER_UNREACHABLE_ERROR: "SERVER_UNREACHABLE_ERROR",
  REQUEST_TIMEOUT_ERROR: "REQUEST_TIMEOUT_ERROR",
  FILE_SIZE_EXCEEDS_LIMIT : "FILE_SIZE_EXCEEDS_LIMIT",
  PASSCODE_ALREADY_BEING_USED: "PASSCODE_ALREADY_BEING_USED",
  CONNECTION_EXCEEDS_CAPACITY: "CONNECTION_EXCEEDS_CAPACITY",
  REQUEST_FAILED: "REQUEST_FAILED",
  TARGET_URL_NOT_FOUND: "TARGET_URL_NOT_FOUND",
  CO_BROWSE_ORIGIN_NOT_FOUND: "CO_BROWSE_ORIGIN_NOT_FOUND",
  SESSION_KEY_NOT_FOUND: "SESSION_KEY_NOT_FOUND",
  LAYOUT_CODE_NOT_FOUND: "LAYOUT_CODE_NOT_FOUND",
  CO_BROWSE_TOKEN_NOT_FOUND: "CO_BROWSE_TOKEN_NOT_FOUND",
  INVALID_CO_BROWSE_ORIGIN: "INVALID_CO_BROWSE_ORIGIN",
  INVALID_LAYOUT_CODE: "INVALID_LAYOUT_CODE",
  INVALID_CO_BROWSE_TOKEN: "INVALID_CO_BROWSE_TOKEN",
  ACTIVE_CLOSING_SESSION_ALREADY_EXISTS: "ACTIVE_CLOSING_SESSION_ALREADY_EXISTS",
  ROOM_NOT_ACTIVE: "ROOM_NOT_ACTIVE",
  ROOM_NOT_FOUND: "ROOM_NOT_FOUND",
  USER_EVICTED_BY_AGENT: "USER_EVICTED_BY_AGENT",
  USER_DELETED: "USER_DELETED",
  INVALID_USER:"INVALID_USER",
  NOT_ENOUGH_PRIVILEGE:"NOT_ENOUGH_PRIVILEGE",
  CUSTOMER_NOT_FOUND:"CUSTOMER_NOT_FOUND",
  SESSION_CLOSED:"SESSION_CLOSED",
  SESSION_ALREADY_HAS_AN_OWNER:"SESSION_ALREADY_HAS_AN_OWNER",
  INVALID_TOKEN: "INVALID_TOKEN",
  MODULES_NOT_ALLOWED: "MODULES_NOT_ALLOWED",
  QR_CODE_NOT_FOUND: "QR_CODE_NOT_FOUND",
  ACTIVE_AVAILABLE_AGENT_DOES_NOT_EXIST: "ACTIVE_AVAILABLE_AGENT_DOES_NOT_EXIST",
  ANONYMOUS_USER_NOT_ALLOWED: "ANONYMOUS_USER_NOT_ALLOWED",
  AGENT_SESSION_QUEUE_NOTIFICATIONS_DISABLED: "AGENT_SESSION_QUEUE_NOTIFICATIONS_DISABLED",
  LICENSE_KEY_EXPIRED: "LICENSE_KEY_EXPIRED",
  LICENSE_KEY_EXPIRED_ADMIN: "LICENSE_KEY_EXPIRED_ADMIN",
  LICENSE_KEY_EXPIRED_AGENT: "LICENSE_KEY_EXPIRED_AGENT",
  LOGGING_LEVEL_NOT_ALLOWED: "LOGGING_LEVEL_NOT_ALLOWED",
  ERR_NETWORK: "ERR_NETWORK",
  ECONNABORTED: "ECONNABORTED",
  SNAPSHOT_SCAN_CODE_NO_RESULT_ERROR: "SNAPSHOT_SCAN_CODE_NO_RESULT_ERROR",
  SNAPSHOT_SCAN_CODE_ERROR: "SNAPSHOT_SCAN_CODE_ERROR",
  SNAPSHOT_SCAN_CODE_ERROR_IN_SEARCH: "SNAPSHOT_SCAN_CODE_ERROR_IN_SEARCH",
  CHAT_GPT_GENERIC_ERROR: "CHAT_GPT_GENERIC_ERROR",
  CHAT_GPT_CONFIGURATION_ERROR: "CHAT_GPT_CONFIGURATION_ERROR"
};
export const COLOR = {
  RED: "red",
  GREEN: "green",
  BLUE: "blue",
  YELLOW: "yellow",
  ORANGE: "orange",
  PINK: "pink",
  PURPLE: "purple",
  BLACK: "black",
};

export const COLOR_LIST = [
  "red",
  "green",
  "blue",
  "yellow",
  "orange",
  "pink",
  "purple",
  "black",
];

export const CAMERA = {
  FRONT: "front",
  BACK: "back",
};

export const LANGUAGES = {
  en: "English",
  ja: "日本語",
  // chi: "中文"
};

export const supportedLanguagesName = {
  en: "English",
  ja: "Japanese",
  chi: "Chinese",
};

export const FONT_FAMILY = {
  ARIAL: "Arial",
  PACIFICO: "Pacifico",
  IMPACT_REGULAR: "Impact-Regular",
  MONACO: "Monaco",
  COURIER: "Courier",
};

export const DEFAULT_FONT_FAMILY = "Arial";

export const DEFAULT_FONT_SIZE = "40";

export const DEFAULT_FONT_COLOR = "black";

export const FONT_SIZE = [
  "24",
  "26",
  "28",
  "32",
  "36",
  "40",
  "44",
  "48",
  "54",
  "60",
];

export const PAGE_COUNT = [
  {
    name: 10,
    value: 10,
  },
  {
    name: 20,
    value: 20,
  },
  {
    name: 50,
    value: 50,
  },
  {
    name: 100,
    value: 100,
  },
];

export const QR_CODES_PAGE_SIZES = [
  {
    name: 12,
    value: 12
  }, {
    name: 24,
    value: 24
  }, {
    name: 60,
    value: 60
  }, {
    name: 120,
    value: 120
  }
];

export const floatedAnnotationBar = {
  [ANNOTATIONS.TEXT]: {
    title: "TEXT_DECORATION",
    isSelected: false,
  },
  [ANNOTATIONS.CIRCLE]: {
    title: "CIRCLE",
    isSelected: false,
  },
  [ANNOTATIONS.RECTANGLE]: {
    title: "RECTANGLE",
    isSelected: false,
  },

  [ANNOTATIONS.LINE]: {
    title: "LINE",
    isSelected: false,
  },
  [ANNOTATIONS.FREE_HAND.NAME]: {
    title: "DRAW",
    isSelected: false,
  },
  [ANNOTATIONS.ERASER.NAME]: {
    title: "ERASER",
    isSelected: false,
  },
  [ANNOTATIONS.ERASE_OBJECT]: {
    title: "ERASE_OBJECT",
    isSelected: false,
  },
};

export const subLoggingLevels = {
  basic: {
    SESSION_DETAILS: "SESSION_DETAILS",
    INVITEE_LIST: "INVITEE_LIST",
    CONTEXT_EXCHANGE: "CONTEXT_EXCHANGE",
  },
  advanced: {
    CANVAS_OBJECTS: "CANVAS_OBJECTS",
    PDF: "PDF",
  },
};

export const loggingLevel = {
  BASIC: "BASIC",
  ADVANCED: "ADVANCED",
};

export const KMSInstanceInfo = {
  healthStatus: {
    HEALTHY: "HEALTHY",
    UNHEALTHY: "UNHEALTHY",
    RETIRED: "RETIRED",
  },

  instanceStatus: {
    RUNNING: "running",
    STOPPED: "stopped",
  },
};

export const defaultSubLoggingLevels = ["SESSION_DETAILS", "INVITEE_LIST"];

export const PARTICIPANT_LIMIT_SM = 3;
export const PARTICIPANT_LIMIT_LG = 5;

export const TESTS = {
  AUDIO: "Audio",
  CAMERA320: "Camera 320",
  CAMERA640: "Camera 640",
  CAMERA1280: "Camera 1280",
  CAMERA_SUPPORT: "Camera Supported Resolutions",
  RELAY: "Relay",
  HOST: "Host",
  REFLEXIVE: "Reflexive",
  ICE_SERVER_UDP: "Ice Server UDP",
  ICE_SERVER_TCP: "Ice Server TCP",
  UDP: "UDP",
  TCP: "TCP",
  IPv6: "IPv6",
  DATA_THROUGHPUT: "Data",
  VIDEO_BANDWIDTH: "Video Bandwidth",
};

export const COMPONENT_IN_FOCUS = {
  CANVAS: "CANVAS",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  COBROWSE: "COBROWSE",
};

export const classNames = [ 'topLeft0', 'topRight0', 'topRight33', 'topRight35'];

export const REGEX = {
  CO_BROWSE_CUSTOM_URL: /^(http|https)?:\/\/?[a-zA-Z0-9@:%!$._\+~#-=&;]{1,256}\.[a-zA-Z0-9()]{1,50}\b([a-zA-Z0-9.=?\/]*)(:?\d+)?(\/\S*)?$/,
  // URL: /^((https?|ftp|smtp):\/\/)?(www.)?[aA-zZ0-9]+\.[aA-zZ]+(\/[a-zA-Z0-9#]+\/?)*$/,
  // Used this Regular expression as there were issue when url has dots (.)
  URL: /^((https?|ftp|smtp):\/\/)?(www.)?([-a-zA-Z0-9]+\.)?[-a-zA-Z0-9]+\.[a-zA-Z]+(\/[a-zA-Z0-9@:%_\\+~#?&//=]+\/?)*/g,
  TIME_PATTERN: {
    'H': '[0-2]',
    'h':'[0-9]',
    'M': '[0-5]',
    'm': '[0-9]',
  },
  TIME: /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/,
  DATE_TIME_PATTERN: {
    'M': '[0-1]',
    'o': '[0-9]',
    'D': '[0-3]',
    'e': '[0-9]',
    'y': '[0-9]',
    'h': '[0-1]',
    'H': '[0-9]',
    'm': '[0-6]',
    'i': '[0-9]',
    'a': '[AaPp]',
    't': '[mM]'
  },
  INVITEES_REGEX: /,;|;,|(;)\1|(,)\2|\s/,// allow single occurrence of seprator and spaces not allowed
  JOIN_QR_CODE_SESSION:/\/[a-zA-Z0-9]{8}\/\d+\/\b(en|ja)\b/,
  START_QR_CODE_SESSION:/^\/qr\/[a-zA-Z0-9]{6}$/,
  SPECIAL_CHARACTERS: /[ `~!@#$%^&*()\-_+={}[\]|/\\,.<>?:;'"]/g,
  MULTIPLE_SPACES:/\s+/g,
  SINGLE_SPACE:/\s/g,
  CHAT_GPT_PROMPT_IDENTIFIER: /^@chat\b/i, // Chat GPT message starts with @chat
  HTML_BOLD_TAG: /<[/]?(b)>/i
};

export const SESSION_TYPE = {
  INSTANT_MEETING: "INSTANT_MEETING",
  SCHEDULE_MEETING: "SCHEDULE_MEETING",
  UPDATE_SCHEDULE_MEETING: "UPDATE_SCHEDULE_MEETING",
  QR_CODE: "QR_CODE"
};

export const SCHEDULED_SESSION_MESSAGE = {
  CREATED: "USER_SESSION_WAITING_MESSAGE",
  WAITING: "USER_SESSION_WAITING_MESSAGE",
  UPDATED: "USER_SESSION_UPDATED_MESSAGE",
  DELETED: "USER_SESSION_DELETED_MESSAGE",
};

export const USER_ADMISSION_MESSAGE = {
  REJECTED: "USER_REJECTED_MESSAGE",
  PENDING: "USER_SESSION_WAITING_MESSAGE",
};

export const SESSION_SUBTYPES = {
  REGULAR: "REGULAR",
  SCHEDULED: "SCHEDULED",
};

export const TIME_INTERVAL = {
  SESSION_TABLE_REFRESH_TIME_INTERVAL: 1000,
  MAXINTERVALS: 10,
  PASSCODE_CHECK_TIME_INTERVAL: 200
};

export const MEETING_JOIN_TYPE = {
  JOIN_WITH_MEETING_CODE: "JOIN_WITH_MEETING_CODE",
  SET_UP_NEW_MEETING: "SET_UP_NEW_MEETING"
}

export const PASSCODE_LENGTH = 4;

export const BANDWIDTH_MANAGEMENT_LIST = [{
  name: "LOW_BANDWIDTH",
  value: 360,
  bitrate: "400 kbps"
}, {
  name: "MEDIUM_BANDWIDTH",
  value: 720,
  bitrate: "1.5 mbps"
}, {
  name: "HIGH_BANDWIDTH",
  value: 1080,
  bitrate: 0
}];

export const FRAMES_PER_SECOND_LIST = [5,10,15,20,25,30]
export const DEFAULT_FPS = 15;

export const CO_BROWSE_SESSION_STATUS = {
  CO_BROWSE_STARTED: "CO_BROWSE_STARTED",
  CO_BROWSE_RUNNING: "CO_BROWSE_RUNNING",
  CO_BROWSE_NOT_RUNNING: "CO_BROWSE_NOT_RUNNING",
  CO_BROWSE_STOPPED: "CO_BROWSE_STOPPED"
}

export const BUSINESS_MODELS = {
  NUMBER_OF_SEATS : "NUMBER_OF_SEATS",
  MAX_CONCURRENT_CONNECTIONS : "MAX_CONCURRENT_CONNECTION",
}

export const RECORD_NOT_PROCESSED = "RECORD_NOT_PROCESSED";

export const EMAILS_LENGTH = 10;
export const CO_BROWSE_LAYOUT = {
  laptop: "1",
  tablet: "2",
  mobileVertical: "3",
  mobileHorizontal: "4",
}

export const CUSTOMER_CO_BROWSE_RESPONSE = {
  END_SUCCESS: "end-success",
  END_CANCEL: "end-cancel",
  END_FAIL: "end-fail"
}
export const CUSTOMER_CO_BROWSE_ACTIONS = {
  END_SESSION: "End_session",
  CO_BROWSER_END: "cobrowserEnd",
  END_SESSION_WITHOUT_CONFIRMATION: "End_session_without_confirmation"
}
export const CUSTOMER_CO_BROWSE_ERROR = {
  1001: ERROR.REQUEST_FAILED,
  1200: ERROR.INVALD_DATA_REQUEST,
  1010: ERROR.TOKEN_VALIDATION_FAILED,
  1201: ERROR.INVALID_PARAMETER_FORMAT,
  1101: ERROR.TARGET_URL_NOT_FOUND,
  10100: ERROR.CO_BROWSE_ORIGIN_NOT_FOUND,
  10101: ERROR.SESSION_KEY_NOT_FOUND,
  10102: ERROR.LAYOUT_CODE_NOT_FOUND,
  11100: ERROR.CO_BROWSE_TOKEN_NOT_FOUND,
  11101: ERROR.INVALID_CO_BROWSE_ORIGIN,
  10200: ERROR.INVALID_LAYOUT_CODE,
  10201: ERROR.INVALID_CO_BROWSE_TOKEN
}

export const LOCATOR = {
  SSO: "sso",
  STORAGE: "storage",
  CO_BROWSE: "cobrowse",
  CHAT_SETTINGS: "chat_settings"
}

export const PARTICIPANT_UPDATE_REASON = {
  ARRIVED: "arrived",
  LEFT: "left",
  EVICTED: "evicted",
  WAITING: "waiting",
  JOINED_REQUEST: "joined_request",
  JOINED_REPLY: "joined_reply",
  ADMITTED: "admitted",
  REJECTED: "rejected",
  MEDIA_TYPE: "media_type",
  MEDIA_STATUS: "media_status",
  ALLOW_EDIT_ACCESS: "allow_edit_access",
}

export const DATE_FORMAT = "YYYY/MM/DD";
export const TIME_FORMAT ="hh:mm A";

export const REPORTS_DROPDOWN_MENU = {
  SESSION_LIST: 1,
  SESSION_FOLLOW_UPS: 2
};

export const ADMIN_DASHBOARD_TABS = {
  USERS: '1',
  REPORTS: '2',
  QR_CODES: '3'
};

export const SUPER_ADMIN_TABS = {
  ORGANIZATIONS: '1',
  SYSTEM_ANNOUNCEMENTS: '2',
  CLUSTER_INFO: '3'
};

export const FREQUENTLY_USED_TIMEZONES = [
  {
    value: 'America/Los_Angeles',
    name: 'America/Los_Angeles',
  },
  {
    value: 'Asia/Tokyo',
    name: 'Asia/Tokyo',
  }
  ,{
    value: 'Asia/Kolkata',
    name: 'Asia/Kolkata',
  },
  {
    value: 'Europe/London',
    name: 'Europe/London',
  }
];

// With a QR code of length 6, there are 2176782336 possible alphanumeric combinations. Therefore this limit.
export const MAX_QR_LIMIT = 2176782336;

export const QR_CODE_SCREEN_TYPE = {
  QR_SPLASH_SCREEN: "QR_SPLASH_SCREEN",
  QR_WAITING_SCREEN: "QR_WAITING_SCREEN"
}

export const SNAPSHOT_ANNOTATIONS_CLEARED_REASON = {
  ORIENTATION_CHANGE: "ORIENTATION_CHANGE",
  CAMERA_SWITCH: "CAMERA_SWITCH"
}

export const QR_SESSION_JOIN_TYPE = {
  EMAIL: "EMAIL",
  QR_SESSION_LIST: "QR_SESSION_LIST"
}

export const KEY_CODE_ENTER = 13;

export const DEVICE_TYPE = {
  DESKTOP: "DESKTOP",
  TABLET: "TABLET",
  MOBILE: "MOBILE",
  REALWEAR: "REALWEAR"
}

export const INFO_FIELD_MAX_LENGTH = 160;

export const VOICE_COMMAND_SCROLL_TO_VALUE = 300;

export const ADMIN_VALID_URLS = [
  CONFIG.path.adminSettings, CONFIG.path.qrCodeAgents, CONFIG.path.assignAgentsToQRCode, CONFIG.path.admin
];

export const CHAT = {
  FONT_SIZE: {
    SMALL: 1,
    MEDIUM: 2,
    LARGE: 3
  },
  CHAT_GPT_SENDER_PARTICIPANT_ID: 'CHAT_GPT_SENDER_PARTICIPANT_ID'
};

export const TOOLTIP = {
  PLACEMENT: {
    TOP: "top",
    BOTTOM: "bottom",
    LEFT: "left",
    RIGHT: "right"
  },
  TYPE: {
    INFO: "INFO",
    ERROR: "ERROR",
  }
}

export const PDF_FILE_BASE_URL = `${process.env.PUBLIC_URL}/lib/pdfjs_v4.6.82/web/viewer.html?file=`