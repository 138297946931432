import React, { Component } from "react";
import WebViewer from 'webviewer';  
import { withOrientationChange } from "react-device-detect";
import { pdfTronLibPath } from "UTILS/Constants";

import "../../Whiteboard/PdfTron.less";
class PdfViewer extends Component {

  constructor(props) {
    super(props);
    this.instance = null;
    this.loadWebviewerTimeout = null;
  }
  getElementsToDisable = () =>{
    let elementsToDisable = [
      "printButton",
      "viewControlsButton",
      "leftPanel",
      "toggleNotesButton",
      "menuButton",
      "thumbnailControl",
      "selectToolButton",
      "documentControl",
      "searchButton",
      "ribbons", // View/Annotate/Insert
    ];
    return elementsToDisable;
  }
  isPdfPresent = () => {
    return (this.props.fileUrl)
  }

  componentDidMount() {
    this.loadWebviewer();
    window.addEventListener('offline', () => {
    });
    window.addEventListener('online', () => {
      clearTimeout(this.loadWebviewerTimeout);
      this.loadWebviewerTimeout = setTimeout(() => {
        if (!this.instance) {
          console.log('load webviewer: ', this.instance);
          this.loadWebviewer();
        }
      }, 500)
    });
  }

  enableToolbar = () => {
    // issue fix - App crashes with error cannot read property "enableElements" of null
    if (!this.instance) return;
    this.instance.enableElements(['header', 'toolsHeader', 'pageNavOverlay']);
    document.getElementById("webviewer").style.top = 0;

  }

  enableDisableLeftPanel = () => {
    let iframe = document.getElementById("webviewer").getElementsByTagName("iframe")[0];
    let divider = iframe.contentWindow.document.querySelector('[data-element="header"]')
      ? iframe.contentWindow.document.querySelector('[data-element="header"]').getElementsByClassName("divider")[0]
      : null;

    if (!this.instance) return;
    if (this.props.isPresenter) {
      this.instance.enableElements([
        'leftPanelButton'
      ]);
      if (divider)
        divider.style.display = "block";
    } 
    else {
      this.instance.closeElements(['leftPanel']);
      this.instance.disableElements([
        'leftPanelButton'
      ]);
      if (divider)
        divider.style.display = "none";
    }
  }

  loadWebviewer = () => {
    WebViewer(
      {
        licenseKey: this.props.pdftronKey,
        path: pdfTronLibPath ,
        initialDoc: this.props.fileUrl,
        enableReadOnlyMode: this.props.isReadOnlyPDF,
        isReadOnly: true,
        disabledElements: this.getElementsToDisable()
      },
      document.getElementById("webviewer"),
    ).then((instance) => {
      instance.setLanguage(this.props.language);
      this.instance = instance;
      const { Annotations } = this.instance;

      console.log('pdfviewer instance: ', instance);
      instance.iframeWindow.addEventListener('loaderror', (err) => {
        console.error(`Error in loading document : ${JSON.stringify(err)}`);
      });

      this.instance.loadDocument(this.props.fileUrl)
      this.enableToolbar();
      this.enableDisableLeftPanel();
      this.disableHotKeys();

      // This will disable features
      this.instance.disableFeatures([this.instance.Feature.Print,this.instance.Feature.ThumbnailMultiselect, this.instance.Feature.Search]);

      this.instance.setLayoutMode(this.instance.LayoutMode.Single);

      // capture annotation change event (add, modify, delete)
      this.instance.annotManager.on('annotationChanged', (annotations, action, { imported }) => {
        console.log("annotationChanged.............");

        // This will happen when importing the initial annotations from the server / individual changes from other users / fields from inside document
        // Form fields will be readonly
        if (imported) {
          _.forEach(annotations, (annotation) => {
            if (annotation instanceof Annotations.WidgetAnnotation) {
              annotation.fieldFlags.set('ReadOnly', true);
            }
          });
          return;
        }
      });
    }).catch(error => {
      console.error(`Webviewer failed with error: ${error}`);
    });
  }

  disableHotKeys = () => {
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_SHIFT_EQUAL); // Rotate clockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_SHIFT_EQUAL); // Rotate clockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_SHIFT_MINUS); // Rotate counterclockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_SHIFT_MINUS); // Rotate counterclockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_F); // Search
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_F); // Search
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.A); // Create arrow tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.C); // Create callout tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.I); // Create stamp tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.L); // Create line tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.N); // Create sticky tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.O); // Create ellipse tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.R); // Create rectangle tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.G); // Create text squiggly tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.K); // Create text strikeout tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.U); // Create text underline tool
  }

  render() {
    return (
      <React.Fragment>
        <div id="webviewer" style={{ height: "auto", maxHeight: "95%", minWidth: "100%", }}>
        </div>
      </React.Fragment>
    )
  }
}

export default withOrientationChange(PdfViewer); 
