import React, { Component } from "react";
import { isMobileOnly } from 'react-device-detect';
import { Button } from 'reactstrap';

import "./CustomerCoBrowse.less"
import { getMessage } from "CONFIG/i18n";
import { CUSTOMER_CO_BROWSE_ACTIONS, CUSTOMER_CO_BROWSE_RESPONSE, COMPONENT_IN_FOCUS } from "../../Utils/Constants";
import { postMesageToCoBrowseIframeUtility } from "UTILS/Utility";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";

class CustomerCoBrowse extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount = () => {
    this.setCoBrowseElementsLayout();
    if(!isMobileOnly) {
      window.addEventListener("resize", this.setCoBrowseElementsLayout)
    }
    window.addEventListener("message", (event) => { this.getIframeMsg(event); }, false) 
    if(this.props.componentInFocus !== COMPONENT_IN_FOCUS.CANVAS)
      this.props.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.isLandscape !== this.props.isLandscape) {
      setTimeout(()=>this.setCoBrowseElementsLayout(),200)
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setCoBrowseElementsLayout)
  }

  setCoBrowseElementsLayout =() => {
    setTimeout(() => { this.setIframeDimensions() }, 100)
  }

  getIframeMsg = (event) => {
    if (event.origin == this.props.coBrowseBaseUrl
      && event.data
      && event.data.viewAction == CUSTOMER_CO_BROWSE_ACTIONS.CO_BROWSER_END
      && !this.props.endCustomerCBSession) { //dont send END_SESSION ws message if page refreshed as here we are closing session without confirmation
      console.log("End session response:", event.data);

      switch (event.data.result) {
        case CUSTOMER_CO_BROWSE_RESPONSE.END_FAIL:
        case CUSTOMER_CO_BROWSE_RESPONSE.END_SUCCESS:
          console.log("End session success response:", event.data.result);
          if (this.props.isPresenter()) {
            this.props.handleCustomerCoBrowseEndSessionClick()
          } else {
            this.props.handleCustomerCoBrowseLeaveSessionClick();
          }
          break;
        case CUSTOMER_CO_BROWSE_RESPONSE.END_CANCEL:
          console.log("End session cancelled:", event.data);
          break;
        default:
          console.log("End session default call:", event.data);
          break;
      }
    }
  }

  setIframeDimensions = () => {
    let iframe = document.getElementById("customerCBIframe");
    let iframeDiv = document.getElementById("customerCBIframeContainer");
    let cobrowseParentContainer = document.getElementById("customerCoBrowseContainerParent")
    let WhiteboardParentContainer = document.getElementById("WhiteboardParentContainer");
    if (!iframe || !iframeDiv)
      return;
    let iframeTop = iframe.offsetTop;

    // if user is on desktop or mobile landscape iframe should be rendered in portrait dimensions (667x375)
    // and scale to fit container
    // else (mobile portrait) iframe will be rendered according to the mobile screen dimensions 
    if (!window.innerWidth < 767 || (window.innerWidth < 767 && this.props.isLandscape)) { 
      iframeTop = 0;

      let iframeHeightBeforeScale = 667;
      let iframeWidthBeforeScale = 375;

      iframe.style.width = iframeWidthBeforeScale + "px";
      iframe.style.height = iframeHeightBeforeScale + "px";
      iframe.style.left = "50%";
      iframe.style.transform = "translate(-50%)";
      let iframeLeftInPixels = iframe.offsetLeft;

      var scaleFactor = (iframeDiv.offsetHeight - 10) / iframeHeightBeforeScale;
      iframe.style.transform = `scale(${scaleFactor}) translate(-50%)`;
      iframe.style.left = iframeLeftInPixels + (((iframeWidthBeforeScale * scaleFactor) - iframeWidthBeforeScale) / 2) + 'px';
      iframe.style.top = iframeTop + (((iframeHeightBeforeScale * scaleFactor) - iframeHeightBeforeScale) / 2) + 'px';
    } else {
      // When user changes orientation from landscape to portrait mode "left" should be unset for correct placement of iframe
      // fix for issue WAAG-4830
      iframe.style.left = 'unset';
            
      let iframeHeightBeforeScale = screen.height;
      let iframeWidthBeforeScale = screen.width;
      cobrowseParentContainer.style.height = WhiteboardParentContainer.offsetHeight + 'px';
      cobrowseParentContainer.style.paddingBottom = '0.5em';
      iframe.style.width = iframeWidthBeforeScale + 'px';
      iframe.style.height = iframeHeightBeforeScale + 'px';
      iframeTop = 0;
      scaleFactor = iframeDiv.offsetHeight / iframeHeightBeforeScale;
      iframe.style.transform = `scale(${scaleFactor})`;
      iframe.style.top = iframeTop + (((iframeHeightBeforeScale * scaleFactor) - iframeHeightBeforeScale) / 2) + 'px';
    }
  }

  getCoBrowseSessionUrl = () => {
    let sessionUrl = this.props.isPresenter() ?
      this.props.coBrowseBaseUrl + "/cobrowse/#/session?roomId=" + this.props.roomId + "&token=" + this.props.token + "&userName=" + this.props.userName + "&sessionKey=" + this.props.sessionKey:
      this.props.coBrowseBaseUrl + "/cobrowse/#/" + this.props.shareUrl + "&token=" + this.props.token + "&userName=" + this.props.userName + "&sessionKey=" + this.props.sessionKey
    return sessionUrl
  }

  handleEndLeaveCBSessionClick = () => {
    var message = {
      token: this.props.token,
      action: CUSTOMER_CO_BROWSE_ACTIONS.END_SESSION,
    };
    let cobrowseIframe = document.getElementById("customerCBIframe");
    console.log("Mesage sending to co browse server", JSON.stringify(message))
    cobrowseIframe.contentWindow.postMessage(message, this.props.coBrowseBaseUrl);
  }

  // disableEndLeaveBtn =(disabledBtn)=> {
  //   let endSessionBtn = document.getElementById("endSessionBtn");
  //   if(endSessionBtn) {
  //     endSessionBtn.disabled = disabledBtn;
  //   }
  // }

  iframLoaded = () => {
    console.log('Customer Co-browse iframe loaded successfully')
    // TODO: remove setTimeout once CB side issue fixed (issue: user ending session when iframe is loading)
    if (this.props.endCustomerCBSession && this.props.showCustomerCoBrowse && this.props.token && this.props.coBrowseBaseUrl) {
      setTimeout(() => {
        console.log("End_session_without_confirmation on page refresh")
        postMesageToCoBrowseIframeUtility(this.props.token, this.props.coBrowseBaseUrl);
        this.closeCustomerCbContainer()
      }, 1500); //1.5sec delay required untill iframe gets loaded completely... takes 1.5sec to load iframe
    }
  }

  closeCustomerCbContainer = () => {
    setTimeout(() => {
      if (this.props.isPresenter()) {
        customerCoBrowseAction.endCustomerCoBrowse()
      } else {
        customerCoBrowseAction.leaveCustomerCoBrowse(this.props.uniqueId)
      }
    }, 500); //wait untill message gets posted to CB properly then close CB container.... and after 2 sec will have to close cb container
  }

  render() {
    return (
      <div id="customerCoBrowse" className="pt-1 paddingBottomCustomerCoBrowse" >
        <div className="row px-1 m-0 justify-content-end d-flex">
          <div className="displayFlex pb-1">
            {!isMobileOnly ?
              <div>
                <button id="endSessionBtn" 
                  className="btn endSessionBtn py-1" 
                  onClick={() => this.handleEndLeaveCBSessionClick()}> 
                  {this.props.isPresenter() ? getMessage("END") : getMessage("LEAVE")} 
                </button>
              </div>
              :
              <Button id="endSessionBtn" className="btn-close" close aria-label="Cancel"
                onClick={() => this.handleEndLeaveCBSessionClick()} />
            }
          </div>
        </div>
        <div id="customerCBIframeContainer" className="customerCBIframeContainer d-flex align-items-center justify-content-center">
          <iframe
            name="customerCBIframe"
            id="customerCBIframe"
            title="customerCoBrowse"
            src={this.getCoBrowseSessionUrl()}
            height="100%"
            width="100%"
            onLoad={this.iframLoaded}
          />
        </div>
      </div>
    )
  }
}

export default CustomerCoBrowse;
