import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { getMessage } from "CONFIG/i18n";

//websoket
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { socketMessage } from "WEBSOCKET/constants";

//Actions
import  { coBrowseAction } from "CONFIG/ActionFactory";

//UTILS
import {  CO_BROWSE_SESSION_STATUS, ERROR } from "UTILS/Constants";

//SELECTOR
import { 
  selectSessionKey, 
  selectUniqueId, 
  selectUserName, 
  getCustomerId, 
  getCoBrowseIFrameUrl, 
  selectPresenter, 
  getCoBrowseSessionStatus,
  getCoBrowseTargetSiteUrl,
  getAdmittedParticipants,
  getCoBrowseJoinedParticipantUniqueId,
  getCoBrowseSessionId,
  isCurrentUserArrivedInCobrowse,
} from "./CoBrowseSelector";

//Component
import CoBrowse  from "./CoBrowse";
import { isIOS, isSafari } from "react-device-detect";

class CoBrowseContainer extends Component{
  componentDidMount() {
    let event = (isSafari && isIOS)? "pagehide" : "beforeunload";    
    window.addEventListener(event, () => {
      this.endCoBrowseSession() 
    });
  }

  componentDidUpdate(prevProps) {
    // console.log(`Cobrowse componentDidUpdate: current coBrowseSessionStatus: ${this.props.coBrowseSessionStatus}
    //   prev coBrowseSessionStatus: ${prevProps.coBrowseSessionStatus},
    //   prev isCurrentUserArrivedInCobrowse: ${prevProps.isCurrentUserArrivedInCobrowse},
    //   current isCurrentUserArrivedInCobrowse: ${this.props.isCurrentUserArrivedInCobrowse}`);
    if ( this.props.coBrowseSessionStatus ===  CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED &&
        this.props.isCurrentUserArrivedInCobrowse &&
        ((prevProps.coBrowseSessionStatus !== this.props.coBrowseSessionStatus) ||
        (prevProps.isCurrentUserArrivedInCobrowse !== this.props.isCurrentUserArrivedInCobrowse))&&
        !this.isPresenter()){
      this.joinCobrowseSession()
    }

    //end cobrowse when presenter changes and current user is presenter
    if(prevProps.presenter !== this.props.presenter) {
      this.endCoBrowseSession();
    }

    if(!this.isPresenter() 
      && prevProps.coBrowseSessionStatus !== this.props.coBrowseSessionStatus
      && this.props.coBrowseSessionStatus === CO_BROWSE_SESSION_STATUS.CO_BROWSE_STOPPED) {
      this.cleanupCobrowseSessionOnLeave();
    }
  }

  getUserName =() =>{
    return this.props.admittedParticipants.filter(
      participant => participant.uniqueId === this.props.uniqueId
    )[0].userName;
  }

  joinCobrowseSession = () =>{
    let data = {
      sessionKey: this.props.sessionKey, 
      userName: this.getUserName(), 
      uniqueId: this.props.uniqueId
    };
    coBrowseAction.joinCoBrowseSession(data).then(() => { 
      coBrowseAction.setCoBrowseSessionStatus(CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING);
    }, (error) => {
      toastr.error(error.code)
      coBrowseAction.setCoBrowseSessionStatus(CO_BROWSE_SESSION_STATUS.CO_BROWSE_NOT_RUNNING);
    });
  }

  startCoBrowseSession =(targetUrl, errorCallBack)=>{
    let data = {
      userName: this.props.userName,
      sessionKey: this.props.sessionKey,
      uniqueId: this.props.uniqueId,
      customerId: this.props.customerId,
      targetUrl
    };
    coBrowseAction.startCoBrowseSession(data).then((response)=>{
    }, (error) => {
      toastr.error(error.code)
      errorCallBack && errorCallBack()
    });
  }

  sendCoBrowseStartedWSMsg = () => {
    if(this.isPresenter()) {
      const message = {
        data: {
          category: socketMessage.category.CO_BROWSE,
          action: socketMessage.subCategories.CO_BROWSE.CO_BROWSE_STARTED,
          uniqueId: this.props.uniqueId,
          sessionKey: this.props.sessionKey
        }
      };
      // let cobrowseIframe = document.getElementById("iframe");
      // cobrowseIframe.contentWindow.postMessage(JSON.stringify({
      //   sessionDetails: {
      //     userName: this.getUserName(),
      //     sessionId: this.props.cobrowseSessionId
      //   }}), '*');
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
    coBrowseAction.setCoBrowseSessionStatus(CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING);
  }

  endCoBrowseSession = () => {
    let data = {
      uniqueId: this.props.uniqueId,
      sessionKey: this.props.sessionKey,
    }
    let cobrowseIframe = document.getElementById("iframe");
    //cobrowseIframe.src = "about:blank";  TODO: check where we have to set empty url
    cobrowseIframe && cobrowseIframe.contentWindow.postMessage(JSON.stringify({action: 'leaveSession'}), '*');
    // console.log("-------------- in endCoBrowseSession coBrowseSessionStatus", this.props.coBrowseSessionStatus)
    this.deleteCobrowseCookie();
    if (this.isPresenter()) {
      coBrowseAction.endCoBrowseSession(data).then(() => {
        toastr.info("CO_BROWSE_ENDED");
        console.log("Ended cobrowse session successfully");
      }), (error) => {
        console.log("Error ending cobrowse session", error);
      };
    } else {
      coBrowseAction.leaveCoBrowseSession(data).then(() => {
        this.cleanupCobrowseSessionOnLeave();
      }), (error) => {
        console.log("Error leaving cobrowse session", error);
      };
      // }
    }
  }

  cleanupCobrowseSessionOnLeave = () => {
    console.log(" in cleanupCobrowseSessionOnLeave........");
    let cobrowseIframe = document.getElementById("iframe");
    cobrowseIframe && cobrowseIframe.contentWindow.postMessage(JSON.stringify({action: 'leaveSession'}), '*');
    this.deleteCobrowseCookie();
    coBrowseAction.resetCobrowseSessionDetails();
    console.log("Left cobrowse session successfully");
  }

  deleteCobrowseCookie = () => {
    const date = new Date();
    document.cookie = `cobrowseSessionId=; expires=${date}; path=/;`;
    document.cookie = `cobrowseUserId=; expires=${date}; path=/;`;
    document.cookie = `cobrowseUserName=; expires=${date}; path=/;`;
  }

  isPresenter = () => {
    return this.props.uniqueId === this.props.presenter;
  };

  changeUrl = (coBrowseURL) =>{
    const message = {
      data: {
        category: socketMessage.category.CO_BROWSE,
        action: socketMessage.subCategories.CO_BROWSE.CHANGE_CO_BROWSE_URL,
        uniqueId: this.props.uniqueId,
        sessionKey: this.props.sessionKey,
        coBrowseURL
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  render() {
    return(
      <CoBrowse 
        startCoBrowseSession={this.startCoBrowseSession}
        endCoBrowseSession={this.endCoBrowseSession}
        coBrowseURL={this.props.coBrowseURL}
        isPresenter={this.isPresenter()}
        sendCoBrowseStartedWSMsg={this.sendCoBrowseStartedWSMsg}
        targetSiteUrl={this.props.targetSiteUrl}
        setComponentInFocus={this.props.setComponentInFocus}
        componentInFocus={this.props.componentInFocus}
        changeUrl={this.changeUrl}
        newJoinedParticipantId={this.props.newJoinedParticipantId}
        coBrowseSessionStatus={this.props.coBrowseSessionStatus}
        snapshoteeId={this.props.snapshoteeId}
        showSnapshot={this.props.showSnapshot}
        snapshotPermissionReply={this.props.snapshotPermissionReply}
        isSnapshotRunning={this.props.isSnapshotRunning}
      />
    )  
  }
}

const mapStateToProps = state => {
  return {
    sessionKey: selectSessionKey(state),
    uniqueId: selectUniqueId(state),
    userName: selectUserName(state),
    customerId: getCustomerId(state),
    coBrowseURL: getCoBrowseIFrameUrl(state),  
    presenter: selectPresenter(state),
    coBrowseSessionStatus: getCoBrowseSessionStatus(state),
    targetSiteUrl: getCoBrowseTargetSiteUrl(state),
    admittedParticipants: getAdmittedParticipants(state),
    newJoinedParticipantId: getCoBrowseJoinedParticipantUniqueId(state),
    cobrowseSessionId: getCoBrowseSessionId(state),
    isCurrentUserArrivedInCobrowse: isCurrentUserArrivedInCobrowse(state),
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning
  };
};
  
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    sendWebsocketMessage: sendWebsocketMessage,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CoBrowseContainer)