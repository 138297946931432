import { axiosInstance as axios, getAxiosCoBrowseInstance, API_RESPONSE_CODE } from "UTILS/axios.init";

import { API_URL, HTTP_METHODS, USER_ROLES } from "UTILS/Constants";
import store from "REDUX/store.js";
import { log } from "util";
import { getAPIErrorInfo, getCoBrowseAPIErrorInfo } from "UTILS/errorLookup";
import { selectCoBrowseBaseUrl } from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseSelector"

export default class BaseAction {
  constructor() {
    this.anonymousApis = [API_URL.LOGIN, API_URL.FORGOT_PASSWORD];
    this.apiConfig = {};
    this.dispatch = store.dispatch;
    this.getState = store.getState;
    this.axiosCobrowse = null;
  }

  callApi({ url, requestBody, httpMethod, header, params=null, isCoBrowseRequest=false }) {
    let reqBody = { ...requestBody };
    if(httpMethod === HTTP_METHODS.GET) {
      reqBody.timestamp = new Date().getMilliseconds(); //For IE browser, Avoiding GET calls to be fetched from cache
    }
    this.apiConfig.url = url;
    this.apiConfig.data = httpMethod !== HTTP_METHODS.GET ? requestBody : null;
    this.apiConfig.params =
      httpMethod === HTTP_METHODS.GET ? reqBody : params;
    this.apiConfig.method = httpMethod;
    if(isCoBrowseRequest)
      return this.callCoBrowseAPI(header);
    return this.callActualAPI(header);
  }
  isNotAnonymousApi = url => {
    return this.anonymousApis.indexOf(url) === -1;
  };
  callActualAPI(header) {
    if (
      this.isNotAnonymousApi(this.apiConfig.url) &&
      this.getState().UserReducer.userDetails
    ) {
      this.apiConfig.headers = {
        "Content-Type": "application/json",
        Accept: "application/json"
      };

      // const userToken = this.getState().UserReducer.userDetails.token;
      var userToken = "";
      if(this.getState().UserReducer.userDetails.role === USER_ROLES.AGENT
       || this.getState().UserReducer.userDetails.role === USER_ROLES.ADMIN
       || this.getState().UserReducer.userDetails.role === USER_ROLES.SUPER_ADMIN) {
        userToken = this.getState().UserReducer.userDetails.token
      } else if(this.getState().UserReducer.userDetails.role == USER_ROLES.USER) {
        userToken = this.getState().SessionReducer.sessionDetails.token
      }

      if (userToken) {
        this.apiConfig.headers.Authorization = "Bearer " + userToken;
      }
      if (header) {
        this.apiConfig.headers = { ...this.apiConfig.headers, ...header };
      }
    }
    return new Promise((resolve, reject) => {
      return axios(this.apiConfig)
      .then(response => {
        if (response.status === API_RESPONSE_CODE.SUCCESS) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        let errorInfo = getAPIErrorInfo(error.code, error.response);
        reject(errorInfo);
        
      });
    });
  }

  callCoBrowseAPI(header) {
    if(!this.axiosCobrowse)
    
      this.axiosCobrowse = getAxiosCoBrowseInstance(selectCoBrowseBaseUrl(this.getState()))
    if (
      this.isNotAnonymousApi(this.apiConfig.url) &&
      this.getState().UserReducer.userDetails
    ) {
      this.apiConfig.headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json"
      };
      
      const userToken = this.getState().UserReducer.userDetails.token;
      if (userToken) {
        this.apiConfig.headers.Authorization = "Bearer " + userToken;
      }
      if (header) {
        this.apiConfig.headers = { ...this.apiConfig.headers, ...header };
      }
    }
    return new Promise((resolve, reject) => {
      return this.axiosCobrowse(this.apiConfig)
      .then(response => {
        if (response.data.result) {
          resolve(response);          
        } else {
          let errorInfo = getCoBrowseAPIErrorInfo(response);
          reject(errorInfo); 
        }
      })
      .catch(error => {
        // error.code will only be presenter in case of ECONNABORTED
        // in all other cases we have to use error.response to handle CB server error messages
        // error.response = {
        //    "result": false,
        //    "message": "token Params not found",
        //    "code": 11100
        // }
        let errorInfo = getCoBrowseAPIErrorInfo(error.response, error.code);
        reject(errorInfo); 
      });
    });
  }
}
