import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardImg, CardBody } from "reactstrap";
import smoothscroll from 'smoothscroll-polyfill';
import { getMessage } from "CONFIG/i18n";
import { getUnzipped } from "UTILS/Utility";
import DeleteCanvasConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import ic_video_close from "ASSETS/Images/ic_cancel_start_session.svg";
import "./CanvasList.less";
import { CANVAS_TYPES, CANVAS_SOURCE } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

export default class CanvasList extends Component {
  
  static propTypes = {
    canvases: PropTypes.object,
    changeActiveCanvas: PropTypes.func,
    deleteCanvas: PropTypes.func,
    activeCanvas: PropTypes.number,
    enableControls: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmationModal: false,
      canvasIdNumber: '',
      canvasType: ''
    }
    smoothscroll.polyfill();
  }
  componentDidMount() {
    setTimeout(() =>
      this.scrollToActiveCanvas(),500)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.canvases !== prevProps.canvases)
      _.map(this.props.canvases, async (canvas, canvasId) => {
        if (canvas.thumbnailUrl) {
          let thumbnailImg = document.getElementById('thumb_' + canvasId);
          thumbnailImg.src = await getUnzipped(canvas.thumbnailUrl, canvasId)
        }
      })
    if (this.props.activeCanvas != prevProps.activeCanvas) {
      this.scrollToActiveCanvas();
    }
    if (_.size(this.props.canvases) < _.size(prevProps.canvases)
    ) {
      console.log("hidding canvasList spinner after removing deleted canvas");
      this.props.hideCanvasListSpinner();
    }
  }
  scrollToActiveCanvas = () => {
    let canvas = document.getElementById("activeCanvas");
    let scrollY = document.getElementById("scrollY");

    // Use this scroll method to only scroll the canvas list 
    // and not get the canvas list in viewport
    if(canvas && scrollY && (canvas.offsetLeft < scrollY.scrollLeft ||
      canvas.offsetLeft+canvas.clientWidth > scrollY.scrollLeft+scrollY.clientWidth))
      scrollY.scrollLeft = canvas.offsetLeft;

    // Uncomment: to get the canvas list in view and scroll
    // canvas && canvas.scrollIntoView();
  }

  handleNoClick = () => {
    this.setState({ showDeleteConfirmationModal: false })
  }
  render() {
    let imgSrc = null;
    return ( 
      <>
        {/** hide canvas list when co browse or snapshot is in progress instead of unmounting component
         * so that blank thumbnails are not seen when snapshot / cb ends
         */}
        <div className={this.props.hideCanvasList ? "canvasListScrollX d-none" : "canvasListScrollX"}>
          {_.map(this.props.canvases, (canvas, canvasId) => {
            const canvasIdNumber = parseInt(canvasId, 10);
            let canvasStyle = canvas.thumbnailUrl ? "" : "bodyHeight"
            if(canvas.canvasType == CANVAS_TYPES.REGULAR && canvas.canvasSource == CANVAS_SOURCE.SNAPSHOT)
              return null
            return (
              <div 
                key={canvas.canvasType + canvasId}
                id={canvasIdNumber}
                className={
                  this.props.enableControls
                    ? "canvasList paddingPoint2em cursorPointer"
                    : "canvasList paddingPoint2em"
                }>
                <Card
                  onClick={() => {
                    // Execute following code if the card is enabled
                    if(this.props.enableControls) {
                      if(this.props.activeCanvas != canvasIdNumber) {
                        this.props.onCanvasSwitched(canvas.canvasType);
                        //this.props.closePopover();
                        
                        if(canvas.canvasType !== CANVAS_TYPES.PDF) {
                          console.log("Showing spinner in canvaslist on clicking canvas");
                          this.props.showCanvasListSpinner();
                        }else {
                          this.props.showSpinner();
                        }
                        
                        this.props.changeActiveCanvas(
                          canvasIdNumber,
                          canvas.canvasType,
                          true,
                          () => {
                            console.log("[in changeActiveCanvas callback] this.props.activeCanvasFileUrl: ", this.props.activeCanvasFileUrl)
                            if(!this.props.activeCanvasFileUrl) {
                              console.log("hidding canvasList spinner in changeActiveCanvas callback ");
                              this.props.hideCanvasListSpinner();
                            } else {
                              this.props.hideCanvasListSpinner();
                            }
                          }
                        );
                      }
                    }
                  }}
                  className={
                    "card buttonCard " +
                    (this.props.activeCanvas === canvasIdNumber
                      ? " activeCanvas"
                      : "") +
                    (!this.props.enableControls ? " cursorDefault" : " cursorPointer")
                  }
                  id={(this.props.activeCanvas === canvasIdNumber
                    ? "activeCanvas"
                    : "")}
                >
                  <CardBody className={canvasStyle}>
                    {this.props.enableCloseIcon &&
                      _.size(this.props.canvases) > 1 &&
                      (
                        <>
                          <CardImg
                            src={ic_video_close}
                            id={`delete${canvasId}`}
                            className={"closeIcon " + (this.props.enableControls ? "cursorPointer" : "disable")}
                            onClick={event => {
                              event.stopPropagation();
                              this.setState({
                                canvasIdNumber: canvasIdNumber,
                                canvasType: canvas.canvasType,
                                showDeleteConfirmationModal: true
                              })
                            }}
                          />
                        <CustomTooltip tooltipText={getMessage("DELETE_CANVAS")} tooltipId={`delete${canvasId}`}/>
                        </>
                       
                      )}
                    {canvas.thumbnailUrl && canvas.canvasType !== CANVAS_TYPES.PDF? (
                      <CardImg
                        src={imgSrc}
                        className="imageWidth"
                        id={'thumb_'+canvasId}
                        onLoad= {() => {
                          if(this.props.activeCanvas == canvasIdNumber) {
                            console.log("hidding canvasList spinner in image onload: ", canvasIdNumber);
                            this.props.hideCanvasListSpinner();
                          }
                        }}
                      />
                    ) : <div  className="file-name">
                      <span className="file-name-ellipsis" id={`originalFileName${canvasId}`}>{canvas.originalFileName}
                        <CustomTooltip tooltipText={canvas.originalFileName} tooltipId={`originalFileName${canvasId}`} />
                      </span>
                    </div>}
                  </CardBody>
                </Card>
              </div>
            );
          })}
        </div>
        {
          this.state.showDeleteConfirmationModal &&

          <DeleteCanvasConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={this.handleNoClick}
            handleYesClick={() => {
              this.handleNoClick();
              console.log("showing canvasList spinner in delete canvas");
              this.props.showCanvasListSpinner();
              this.props.deleteCanvas(
                this.state.canvasIdNumber,
                this.state.canvasType
              );
            }}
            message={getMessage("DELETE_CANVAS_CONFIRMATION_TEXT")}
          />
        }

      </>

    );
  }
}
