import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class QRCodesAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getAllQRCodes(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.QR_CODES,
        requestBody: data,
      }).then(
        (response) => {
          this.dispatch({
            type: ACTIONS.QR_CODES.SET_QR_CODES_COUNT,
            value: response.data.data.total,
          });
          this.dispatch({
            type: ACTIONS.QR_CODES.SET_TOTAL_QR_CODES_COUNT,
            value: response.data.data.totalQRCodes,
          });
          this.dispatch({
            type: ACTIONS.QR_CODES.SET_ORGANIZATION_QR_LIMIT,
            value: response.data.data.qrLimit,
          });
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveQRCodesListCurrentPageNo(currentPageNo) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SET_QR_CODES_CURRENT_PAGE_NO,
      value: currentPageNo,
    });
  }

  saveQRCodesListPageSize(pageSize) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SET_QR_CODES_PAGE_SIZE,
      value: pageSize,
    });
  }

  addQRCode(qrCodeData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.QR_CODES,
        requestBody: qrCodeData,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteQRCodeAction(qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url: `${API_URL.QR_CODES}/${qrId}`,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getQRCodeDetails(qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.QR_CODES + `/${qrId}`,
      }).then(
        (response) => {
          resolve(response);  
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateQRCode(requestBody, qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: API_URL.QR_CODES + `/${qrId}`,
        requestBody: requestBody,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveQRCodeData(value) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_QR_CODE_DATA,
      value: value,
    });
  }
}
