import { ACTIONS , COMPONENT_IN_FOCUS , SESSION_TYPE } from "UTILS/Constants";

const initialState = {
  sessionTitle: "",
  sessionDetails: {
    sessionKey: "",
    token: "",
    modules: [],   
    bandwidthSettings: [{
      module: "AUDIO_VIDEO",
      bandwidth: 360,
      fps: 15         // frame rate per second
    }, {
      module: "SCREENSHARE",
      bandwidth: 360,
      fps: 15       // frame rate per second
    }] ,
    cobrowseSettings: {cobrowseUrl: "" , cobrowseType:" ", aesKey: ""},
  },
  userSessionDetails: {
    sessionKey: ""
  },
  currentSessionInvitees: [],
  participantId: null,
  uniqueId: null,
  isOrganizer: false,
  
  webRtcPermissionRequest: null,
  webRtcPermissionReply: null,
  localStream: null,
  headerIcons: [],
  isSessionEnded: false,
  isSessionEndedForcefully: false, //This flag is used to show popup message when agent starts new session on diff. browser/tab then will have to end existing session forcefully.
  snapshotPermissionReply: null,
  snapshoteeId: null,
  snapshoterId: null,
  isSnapshotTaken: null,
  isSessionJoined: false, //For refresh and nw handling
  showSnapshot: false,
  isCallEnded: false,
  isPeerDisposed: false,
  showAudioVideoComponent: false,
  webrtcSessionMedia: null,
  isCameraPermissionGranted: null,
  stopVideoCall: null,// handle degrading (stop video call if user accept snapshot request)
  startVideoCall: false, // For iPhone orientation change we have to restart call
  isCameraBusy:null,
  turnServer: {},
  isSnapshotRunning:false, //This flag is set when user has accepted the request and endpoints created at backend, i.e after SNAPSHOT_RESOURSES_INITIALISED
  startSnapshot:false,
  callMediaType:null,
  componentInFocus: COMPONENT_IN_FOCUS.CANVAS,
  sessionsList: null,
  saveSessionDataFlag: false,
  sessionType: SESSION_TYPE.INSTANT_MEETING,
  editSessionData: null,
  passcode: null,
  trailHistoryData: {
    displayPage: 0,
    sessionListPageSize: 10
  },
  renegotiateScreenshare: false,
  renegotiateAudio: [],
  renegotiateVideo: [],
  userLeft: false, // this flag is set when user leaves session or on end session
  isSessionInvalid: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USER_LEFT:
      return {
        ...state,
        userLeft: action.value
      }
    case ACTIONS.JOIN_SESSION:
      if (!_.isEmpty(action.sessionDetails)) {
        let sessionDetailsForLocalStorage = {
          modules: action.sessionDetails.modules,
          subModules: action.sessionDetails.subModules
        };
        localStorage.setItem(action.sessionDetails.token, JSON.stringify(sessionDetailsForLocalStorage));
      }
      return {
        ...state,
        sessionDetails: action.sessionDetails
      };
    case ACTIONS.SET_END_SESSION_FLAG:
      return {
        ...state,
        isSessionEnded: action.flag
      };
      break;
    case ACTIONS.SET_END_SESSION_FORCEFULLY_FLAG:
      return {
        ...state,
        isSessionEnded: action.sessionClosedForcefully,
        isSessionEndedForcefully: action.sessionClosedForcefully
      };
      break;
    case ACTIONS.VIEW_INVITEES:
      return { ...state, invitees: action.invitees };
    case ACTIONS.CREATE_SESSION_SUCCESS:
      return {
        ...state,
        userSessionDetails: action.userSessionDetails
      };
    case ACTIONS.SAVE_SESSION_TITLE:
      return {
        ...state,
        sessionTitle: action.sessionTitle
      };
    case ACTIONS.SAVE_CURRENT_SESSION_INVITEES:
      return {
        ...state,
        currentSessionInvitees: action.currentSessionInvitees
      };
    case ACTIONS.SET_SESSION_STATUS:
      return {
        ...state,
        sessionDetails: {
          ...state.sessionDetails,
          sessionStatus: action.sessionStatus
        }
      };
    case ACTIONS.SET_WEBRTC_REQUEST_PERMISSION:
      return {
        ...state,
        webRtcPermissionRequest: action.webRtcPermissionRequest
      };
    case ACTIONS.SET_WEBRTC_REPLY_PERMISSION:
      return {
        ...state,
        webRtcPermissionReply: action.webRtcPermissionReply
      };
    case ACTIONS.SET_SESSION_JOINED_FLAG:
      return {
        ...state,
        isSessionJoined: action.isSessionJoined
      };
    case ACTIONS.UPDATE_CURRENT_PARTICIPANT_INFO:
      let currentState = _.cloneDeep(state);
      currentState.participantId = action.currentParticipantInfo.participantId;
      currentState.uniqueId = action.currentParticipantInfo.uniqueId;
      currentState.isOrganizer = action.currentParticipantInfo.isOrganizer;
      let sessionDetailsForLocalStorage = JSON.parse(localStorage.getItem(currentState.sessionDetails.token));
      sessionDetailsForLocalStorage.isOrganizer = action.currentParticipantInfo.isOrganizer;
      localStorage.setItem(currentState.sessionDetails.token, JSON.stringify(sessionDetailsForLocalStorage));
      return currentState;
    case ACTIONS.SET_HEADER_ICONS:
      return {
        ...state,
        headerIcons: action.headerIcons
      };
    case ACTIONS.UPDATE_HEADER_ICON_CLASSNAME:
      let cstate = _.cloneDeep(state);
      let moduleName = action.data.moduleName;
      if (cstate.headerIcons[moduleName]) {
        cstate.headerIcons[moduleName].class = action.data.classname;
        cstate.headerIcons[moduleName].textLabelClass = action.data.textLabelClass;
      }
      return cstate;
    case ACTIONS.SET_SNAPSHOTEE_ID:
      return {
        ...state,
        snapshoteeId: action.snapshoteeId
      };
    case ACTIONS.SET_SNAPSHOTER_ID:
      return {
        ...state,
        snapshoterId: action.snapshoterId
      };
    case ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY:
      return {
        ...state,
        snapshotPermissionReply: action.snapshotPermissionReply
      };
    case ACTIONS.RESET_SNAPSHOT_DATA:
      return {
        ...state,
        snapshotPermissionReply: null,
        snapshoteeId: null,
        snapshoterId: null,
        isSnapshotTaken: null,
        isCameraPermissionGranted: null,
        isCameraBusy: null
      };
      break;
    case ACTIONS.SET_SNAPSHOT_TAKEN:
      return {
        ...state,
        isSnapshotTaken: action.isSnapshotTaken
      };
      break;
    case ACTIONS.SET_SWITCH_CAMERA:
      return {
        ...state,
        isPeerDisposed: action.isPeerDisposed
      };
      break;
    case ACTIONS.SNAPSHOT.SHOW_SNAPSHOT:
      return {
        ...state,
        showSnapshot: action.value
      }
    case ACTIONS.RESET_ALL_PARTICIPANT_MEDIA_STATUS:
      return {
        ...state,
        webRtcPermissionRequest: null,
        webRtcPermissionReply: null
      };
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return {
        ...initialState,
        passcode: state.passcode,
        sessionDetails: { sessionKey: state.sessionDetails.sessionKey, 
          token: state.sessionDetails.token, 
          sessionName: state.sessionDetails.sessionName,
          logoUrl: state.sessionDetails.logoUrl
        },
        isSessionEndedForcefully: state.isSessionEndedForcefully, 
      };
    case ACTIONS.CALL_STATUS:
      return {
        ...state,
        isCallEnded: action.value
      };
    case ACTIONS.CALL_MODAL_PERMISSION:
      return {
        ...state,
        showAudioVideoComponent: action.value
      };
    case ACTIONS.SET_WEBRTC_SESSION_MEDIA:
      return {
        ...state,
        webrtcSessionMedia: action.value
      }
    case ACTIONS.SNAPSHOT.SET_SNAPSHOT_CAMERA_PERMISSION:
      return {
        ...state,
        isCameraPermissionGranted: action.value
      }
    case ACTIONS.STOP_VIDEO_CALL:
      return {
        ...state,
        stopVideoCall: action.value
      }
    case ACTIONS.START_VIDEO_CALL:
      return {
        ...state,
        startVideoCall: action.value
      }
    case ACTIONS.SET_CAMERA_BUSY_FLAG:
      return {
        ...state,
        isCameraBusy: action.value
      }
    case ACTIONS.SET_TURN_SERVER_DATA:
      return {
        ...state,
        turnServer: action.turnServer
      }
    case ACTIONS.REMOVE_TOKEN_SESSION_KEY:
      return {
        ...initialState,
        sessionDetails: { sessionKey: "", token: "" }
      }
    case ACTIONS.SNAPSHOT.SET_SNAPSHOT_RUNNING:
      return {
        ...state,
        isSnapshotRunning: action.value
      }
    case ACTIONS.START_SNAPSHOT:
      return {
        ...state,
        startSnapshot: action.value
      }
    case ACTIONS.SET_CALL_MEDIA_TYPE:
      return {
        ...state,
        callMediaType: action.mediaType
      }
    case ACTIONS.SET_COMPONENT_IN_FOCUS:
      return {
        ...state,
        componentInFocus: action.componentInFocus
      };
    case ACTIONS.SET_SESSION_SCHEDULE_LIST:
      return {
        ...state,
        sessionsList : action.value
      }  
    case ACTIONS.SET_SAVE_SESSION_DATA_FLAG:
      return {
        ...state,
        saveSessionDataFlag: action.flag
      };
      break;
    case ACTIONS.SET_SESSION_TYPE:
      return {
        ...state,
        sessionType: action.sessionType
      };
    case ACTIONS.SET_EDIT_SESSION_DATA:
    // invitees set to empty for correct comparision with invitees in ScheduleSession.js state.
      if (action.session && action.session.invitees === null) {
        action.session.invitees = "";
      }
      return{
        ...state,
        editSessionData: action.session
      }
    case ACTIONS.SET_PASSCODE:
      return{
        ...state,
        passcode: action.passcode
      }
    case ACTIONS.SET_LOCAL_STREAM:
      return {
        ...state,
        localStream: action.localStream
      };
    case ACTIONS.SET_TRAIL_HISTORY_DATA:
      return {
        ...state,
        trailHistoryData: action.trailHistoryData
      }
    case ACTIONS.SET_RENEGOTIATE_SCREEN_PARTICIPANT:
      return {
        ...state,
        renegotiateScreenshare: action.renegotiateScreenshare
      }
    case ACTIONS.SET_RENEGOTIATE_VIDEO_PARTICIPANT:
      let renegotiateVideo = _.cloneDeep(state.renegotiateVideo);
      let renegotiateVideoIdIndex = _.findIndex(
        renegotiateVideo,
        participantId =>
          action.participantId === participantId
      );
      renegotiateVideoIdIndex == -1 ? renegotiateVideo.push(action.participantId) 
        : renegotiateVideo[renegotiateVideoIdIndex] = action.participantId;
  
      return {
        ...state,
        renegotiateVideo,
      };
    case ACTIONS.SET_RENEGOTIATE_AUDIO_PARTICIPANT:
      let renegotiateAudio = _.cloneDeep(state.renegotiateAudio);
      let renegotiateAudioIdIndex = _.findIndex(
        renegotiateAudio,
        participantId =>
          action.participantId === participantId
      );
      renegotiateAudioIdIndex == -1 ? renegotiateAudio.push(action.participantId) 
        : renegotiateAudio[renegotiateAudioIdIndex] = action.participantId;
      return {
        ...state,
        renegotiateAudio,
      };
    case ACTIONS.DELETE_RENEGOTIATE_VIDEO_PARTICIPANT:
      renegotiateVideo = _.cloneDeep(state.renegotiateVideo);
      renegotiateVideoIdIndex = _.findIndex(
        renegotiateVideo,
        participantId =>
          action.participantId === participantId
      );
      if(renegotiateVideoIdIndex !== -1){
        renegotiateVideo.splice(renegotiateVideoIdIndex,1)
      } 
      return {
        ...state,
        renegotiateVideo,
      };
    case ACTIONS.DELETE_RENEGOTIATE_AUDIO_PARTICIPANT:
      renegotiateAudio = _.cloneDeep(state.renegotiateAudio);
      renegotiateAudioIdIndex = _.findIndex(
        renegotiateAudio,
        participantId =>
          action.participantId === participantId
      );
      if(renegotiateAudioIdIndex !== -1) {
        renegotiateAudio.splice(renegotiateAudioIdIndex,1)
      } 
      return {
        ...state,
        renegotiateAudio,
      };
    case ACTIONS.RESET_AUDIO_VIDEO_RENEGOTIATE: 
      return({
        ...state,
        renegotiateAudio: [],
        renegotiateVideo: []
      })
    case ACTIONS.LOGOUT_USER: 
      return({
        ...state,
        passcode: null
      })
    case ACTIONS.SET_SESSION_INVALID:
      return {
        ...state,
        isSessionInvalid: action.value
      }
    default:
      return state;
  }
}
