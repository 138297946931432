import React, { Component } from "react";
import PropTypes from "prop-types";
import momentTimezone from "moment-timezone";

// Custom Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";

// Constant
import { FREQUENTLY_USED_TIMEZONES } from "UTILS/Constants";

class TimezoneDropdown extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      timezoneList: [],
    }
  }

  componentDidMount() {
    this.getTimezoneList();
  }

  getTimezoneList = () => {
    // create list of name value pair of all timezones
    const timezoneList = momentTimezone.tz.names().map((name) => ({
      name,
      value: name,
    }));
    
    this.setState({
      timezoneList,
    })
  }

  render() {
    return (
      <CustomDropdown
        options={this.state.timezoneList}
        frequentlyUsedOptions={FREQUENTLY_USED_TIMEZONES}
        value={this.props.value}
        onChange={this.props.onChange}
        isSearchable={true}
        searchInputPlaceholder={"SEARCH_TIMEZONE"}
      />
    );
  }
}

export default TimezoneDropdown;
