import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { array, string, func } from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';

import Popup from "COMPONENTS/CommonComponents/Modal/Modal";
import EndSessionConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import AppEmulationContainer from "COMPONENTS/AppEmulation/AppEmulationContainer";
import AddInviteeContainer from "COMPONENTS/AddInvitee/AddInviteeContainer";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import { getMessage } from "CONFIG/i18n";

import { socketMessage, webRtcMessageId, mediaType } from "WEBSOCKET/constants";
import { isIE } from 'react-device-detect';

import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_audio_disabled from "ASSETS/Images/ic_audio_disabled.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_video_disabled from "ASSETS/Images/ic_video_disabled.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_share_disabled from "ASSETS/Images/ic_share_disabled.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_location_disabled from "ASSETS/Images/ic_location_disabled.svg";
import ic_participants from "ASSETS/Images/ic_participants.svg";
import ic_save_icon from "ASSETS/Images/ic_save_icon.svg";
import ic_save_disabled from "ASSETS/Images/ic_save_disabled.svg";
import ic_canvas_tools_co_browse from "ASSETS/Images/ic_canvas_tools_co_browse.svg";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation.svg"
import ic_snapshot from "ASSETS/Images/ic_snapshot_enabled.svg";
import ic_cobrowse_disabled from "ASSETS/Images/ic_cobrowse_disabled.svg";
import ic_snapshot_disabled from "ASSETS/Images/ic_snapshot_disabled.svg";

import "./Header.less";
import "../../App/App.less";

import ChatContainer from "COMPONENTS/Chat/ChatContainer";
import LocationContainer from "COMPONENTS/Location/LocationContainer";
import Spinner from "COMPONENTS/Spinner/Spinner";
import WaitingArea from "COMPONENTS/WaitingArea/WaitingArea";
import Participants from "./Participants/Participants";

import { appEmulationAction } from "CONFIG/ActionFactory";

// CONSTANTS
import {
  Modules, USER_ROLES, SESSION_STATUS,
  LANGUAGE, USER_ADMISSION_STATUS, CONFIG,
  FONT_FAMILY, CO_BROWSE_SESSION_STATUS, cobrowseTypes, CUSTOMER_CO_BROWSE_ACTIONS,
  PARTICIPANT_UPDATE_REASON
} from "UTILS/Constants";
import {
  isWebRTCSupported, isMobileOriPad, isAndroidOrIos,  getBaseUrl,
  getUserMediaError, isModuleIconToBeDisabled, getUserNameForSelectedLanguage, isWebRTCPluginInstalled, postMesageToCoBrowseIframeUtility,
  isCanvasBlank,
  isRealwearDevice,
} from "UTILS/Utility";

// Actions
import { leaveSessionAction, headerAction, coBrowseAction, customerCoBrowseAction } from "CONFIG/ActionFactory";

class Header extends Component {
  static propTypes = {
    modules: array,
    sessionTitle: string,
    admittedParticipants: array.isRequired,
    allParticipants: array.isRequired,
    sessionKey: string,
    sendWebsocketMessage: func,
    token: string,
    userRole: string,
    handleEndSession: func
  };
  state = {
    popoverOpen: false,
    isMessageArrived: false,
    addInvitee: false,
    makePresenterPopoverOpen: "",
    locationPopOver: false,
    spinnerVisibility: false,
    showDeviceListModal: false,
    showEndSessionConfirmationModal: false,
    showLeaveSessionConfirmationModal: false,
    sortedParticipants: [],
    sessionLink: "",
    isDeviceAttached: null,
    isButtonClicked: false,
    isSnapshotPermissionButtonClicked: false,
    moreParticipantsPopOver: false,
    showWaitingArea: false,
    isAppEmulationStarted: false,
    isSaveEnabled: false,
    isParticipantAvailable: false,
    currentSnapshoteeId:null,
    appEmulationData : {
      osInfoArray: [],
      deviceInfoArray: [],
      appEmulationApplications :[]
    },
  };
  constructor(props) {
    super(props);
    this.headerIconClickActions = {
      handleAudioToggle: this.handleAudioToggle,
      handleAudioVideoToggle: this.handleAudioVideoToggle,
      handleScreenShareToggle: this.handleScreenShareToggle,
      handleChatToggle: this.handleChatToggle,
      handleLocationToggle: this.handleLocationToggle,
      handleAppEmulationToggle: this.handleAppEmulationToggle,
      handleSnapshot: this.handleSnapshot,
      handleCoBrowseToggle: this.handleCoBrowseToggle,
      handleTargetSiteSelectionModalToggle: () => this.handleTargetSiteSelectionModalToggle(true)
    };
  }

  componentDidMount() {
    // commented as code to retrieve modules from localstorage is added to common getModules() function being called from everywhere
    // refresh handled for firefox if icons are not in props it will take it from localStorage
    // let cacheModules = JSON.parse(localStorage.getItem("modules"));
    // let modules = cacheModules || this.props.modules;
    window.addEventListener("resize", this.ClearStateOnResize);
    window.addEventListener("message", this.getIframeMsg , false);
    let modules = this.props.modules;

    this.setSessionLink(getBaseUrl(this.props.customerUriIdentifier, this.props.sessionKey));

    if (modules) {
      let modulesInfo = {
        CANVAS: {
          logo: ic_canvas,
          class: "moduleIconActive cursorDisabled",
          title: getMessage("CANVAS"),
          userAllowed: true,
          textLabelClass: " cursorDisabled"
        }
      };
      if (modules.indexOf(Modules.AUDIO) !== -1) {
        modulesInfo.AUDIO = {
          logo: (this.props.userRole === USER_ROLES.USER) ? ic_audio_disabled : ic_audio,
          class: (this.props.userRole === USER_ROLES.USER) ? "moduleIcon cursorDisabled" : "moduleIcon",
          clickAction: "handleAudioToggle",
          title: getMessage("AUDIO"),
          userAllowed: false,
          textLabelClass: (this.props.userRole === USER_ROLES.USER) ? " cursorDisabled" : " cursorPointer"
        };
      }
      if (modules.indexOf(Modules.AUDIO_VIDEO) !== -1) {
        modulesInfo.AUDIO_VIDEO = {
          logo: (this.props.userRole === USER_ROLES.USER) ? ic_video_disabled : ic_video,
          class: (this.props.userRole === USER_ROLES.USER) ? "moduleIcon cursorDisabled" : "moduleIcon",
          clickAction: "handleAudioVideoToggle",
          title: getMessage("AUDIO_VIDEO"),
          userAllowed: false,
          textLabelClass: (this.props.userRole === USER_ROLES.USER) ? " cursorDisabled" : " cursorPointer"
        };
      }
      if (modules.indexOf(Modules.CHAT) !== -1) {
        modulesInfo.CHAT = {
          logo: ic_message,
          class: this.props.isChatPopOver ? "moduleIconActive" : "moduleIcon",
          clickAction: "handleChatToggle",
          title: getMessage("CHAT"),
          userAllowed: true,
          textLabelClass: " cursorPointer"
        };
      }
      if (modules.indexOf(Modules.LOCATION) !== -1 &&
        this.props.userRole === USER_ROLES.AGENT) {
        modulesInfo.LOCATION = {
          logo: (this.props.userRole === USER_ROLES.USER) ? ic_location_disabled : ic_location,
          class: (this.props.userRole === USER_ROLES.USER) ? "moduleIcon cursorDisabled" : "moduleIcon",
          clickAction: "handleLocationToggle",
          title: getMessage("LOCATION"),
          userAllowed: false,
          textLabelClass: (this.props.userRole === USER_ROLES.USER) ? " cursorDisabled" : " cursorPointer"
        };
      }
      if (modules.indexOf(Modules.SCREENSHARE) !== -1) {
        modulesInfo.SCREENSHARE = {
          logo: isAndroidOrIos() || (this.props.userRole === USER_ROLES.USER && !this.props.isPresenter) ? ic_share_disabled : ic_share,
          class: isAndroidOrIos() || (this.props.userRole === USER_ROLES.USER && !this.props.isPresenter) ? "moduleIcon cursorDisabled" : "moduleIcon",
          clickAction: isAndroidOrIos() || (this.props.userRole === USER_ROLES.USER && !this.props.isPresenter) ? "" : "handleScreenShareToggle",
          title: getMessage("SCREENSHARE"),
          userAllowed: true,
          textLabelClass: isAndroidOrIos() || (this.props.userRole === USER_ROLES.USER && !this.props.isPresenter) ? " cursorDisabled" : " cursorPointer"
        };
      }
      if (modules.indexOf(Modules.SNAPSHOT) !== -1
        && this.props.userRole === USER_ROLES.AGENT) {
        modulesInfo.SNAPSHOT = {
          logo: this.props.customerCoBrowseStarted? ic_snapshot_disabled: ic_snapshot,
          class: this.props.customerCoBrowseStarted? "cursorDisabled moduleIcon": "moduleIcon",
          clickAction: "handleSnapshot",
          title: getMessage("SNAP_SHOT"),
          userAllowed: false,
          textLabelClass: this.props.customerCoBrowseStarted? "cursorDisabled": "cursorPointer"
        };
      }
      if (modules.indexOf(Modules.APP_EMULATION) !== -1
        && this.props.userRole === USER_ROLES.AGENT) {
        modulesInfo.APP_EMULATION = {
          logo: ic_app_emulation,
          class: window._env_.REACT_APP_ALLOW_APP_EMULATION == "true" ? "moduleIcon" : "moduleIcon cursorDisabled",
          clickAction: window._env_.REACT_APP_ALLOW_APP_EMULATION == "true" ? "handleAppEmulationToggle" : "",
          title: getMessage("APP_EMULATION"),
          userAllowed: false,
          textLabelClass: window._env_.REACT_APP_ALLOW_APP_EMULATION == "true" ? " cursorPointer" : " cursorDisabled"
        };
      }
      if (modules.indexOf(Modules.CO_BROWSE) !== -1) {
        modulesInfo.CO_BROWSE = {
          logo: this.props.isPresenter ? ic_canvas_tools_co_browse : ic_cobrowse_disabled,
          class: this.props.isPresenter ? "moduleIcon": "moduleIcon cursorDisabled",
          clickAction: this.props.cobrowseType === cobrowseTypes.CUSTOMER_CO_BROWSE ? "handleTargetSiteSelectionModalToggle" : "handleCoBrowseToggle",
          title: getMessage("CO_BROWSE"),
          userAllowed: true,
          textLabelClass: this.props.isPresenter ? "cursorPointer" : "cursorDisabled"
        };
      }
      this.props.headerAction.setHeaderIcons(modulesInfo);
    }

    if (this.props.locationPermissionReply == true) {
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {
        data: {
          category: socketMessage.category.LOCATION,
          action: socketMessage.subCategories.LOCATION.GET_ALL_LOCATION_DATA,
          sessionKey: this.props.sessionKey
        }
      });
    }
    
    // showChatPopup is an Admin check for Chat window visibility
    // isChatPopOver is for the Agent/User side
    // on session start first we check is chat module is enable from Superadmin then
    // we check is Chat window visibility on sestion start by admin check(showChatPopup)
    // on page refresh, only isChatPopOver is used to maintain Chat window's state
    if(this.props.modules?.indexOf(Modules.CHAT) !== -1 &&
      ((this.props.showChatPopup && this.props.isChatPopOver === null) || this.props.isChatPopOver)) {
      this.props.setChatPopOverStateAction(true);
    }
    else {
      this.props.setChatPopOverStateAction(false);
    }
    if(isRealwearDevice()){
      this.voiceCommandEndCallHandler();
      this.voiceCommandOpenChatHandler();
      this.voiceCommandCloseChatHandler();
    }
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.ClearStateOnResize)
  }

  // Starts --- RealWear End Call voice command block
  voiceCommandEndCallHandler = () => {    
    const endCall = {
      voiceCommand: "VOICE_COMMANDS_END_CALL",
      action: () => {
        this.handleCancelClick();
        this.onOpenCloseLeaveSessionConfirmationModal(true);
      }
    }
    this.props.handleVoiceCommand(endCall)
  }
  // End --- RealWear End Call voice command block

  // Starts --- RealWear Open/Close Chat Popup voice command block
  openCloseChatPopUpHandler = (isChatPopoverVisible) => {
    this.props.setMessagesRead(true);
    this.props.displayMessage(false);
    if (isChatPopoverVisible) {
      let modulesInfo = this.props.headerIcons;
      modulesInfo[Modules.CHAT].logo = ic_message;
      this.props.headerAction.setHeaderIcons(modulesInfo);
      this.props.headerAction.updateHeaderIconClass(
        Modules.CHAT,
        "moduleIconActive",
        " cursorPointer"
      );
    } else {
      this.props.headerAction.updateHeaderIconClass(Modules.CHAT, "moduleIcon", " cursorPointer");
    }
    setTimeout(() => {
      this.props.setChatPopOverStateAction(isChatPopoverVisible);
    }, 100);
  }

  voiceCommandOpenChatHandler = () => {    
    const openChat = {
      voiceCommand: "VOICE_COMMANDS_OPEN_CHAT_POPUP",
      action: () => {
        this.openCloseChatPopUpHandler(true);
      }
    }
    this.props.handleVoiceCommand(openChat)
  }

  voiceCommandCloseChatHandler = () => {    
    const openChat = {
      voiceCommand: "VOICE_COMMANDS_CLOSE_CHAT_POPUP",
      action: () => {
        this.openCloseChatPopUpHandler(false);
      }
    }
    this.props.handleVoiceCommand(openChat)
  }
  // End --- RealWear Open/Close Chat Popup voice command block

  ClearStateOnResize = () => {
    this.hideSideDrawer();
    this.setState({
      moreParticipantsPopOver: false,
      makePresenterPopoverOpen: false
    })
  }

  getIframeMsg = (event) => {
    console.log("Message received:", event.data);
    if (event.origin == this.props.coBrowseBaseUrl
      && event.data
      && event.data.viewAction == CUSTOMER_CO_BROWSE_ACTIONS.CO_BROWSER_END) {
      console.log("CB end session response:", event.data);
    }
  }

  hideSideDrawer = () => {
    if (window.innerWidth > 992 && this.state.popoverOpen) {
      this.setState({ popoverOpen: false }, () => {
        if (isIE)
          document.body.style.overflow = "visible";
        else
          document.body.style.overflow = "overlay";
      })
    }
  }

  setSessionLink = (url) => {
    this.setState({ sessionLink: url });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.organiser === props.uniqueId) {
      if (props.snapshotPermissionReply === false) {
        // for participant - show toast msg for plugin not installed status for snapshot extracted from snapshotPermissionStatus
        let snapshoteeParticipant = props.admittedParticipants.filter(admittedParticipant => admittedParticipant.uniqueId === props.snapshoteeId)[0];
        if (snapshoteeParticipant) {
          if (!props.snapshotPermissionStatus.isPluginInstalled) {
            toastr.error("USER_DONT_HAVE_PLUGIN", [{ key: "userName", value: snapshoteeParticipant.userName }],
              {
                toastId: "USER_DONT_HAVE_PLUGIN_" + snapshoteeParticipant.uniqueId,
                onClose: () =>
                  props.headerAction.clearSnapshotPermissionReplyStatus()
              })
          } else if (!props.snapshotPermissionStatus.isUserWebCamPresent) { //user doesn't have camera
            toastr.error("USER_DONT_HAVE_CAMERA", [{ key: "userName", value: snapshoteeParticipant.userName }],
              {
                toastId: "USER_DONT_HAVE_CAMERA_" + snapshoteeParticipant.uniqueId
              });
          } else { // user have camera and rejected request
            toastr.info("DENIED_SNAPSHOT_PERMISSOIN", [{ key: "userName", value: snapshoteeParticipant.userName }],
              {
                toastId: "DENIED_SNAPSHOT_PERMISSOIN_" + snapshoteeParticipant.uniqueId
              })
          }
        }
      }
    }
    if (
      props.locationPermissionReply === true &&
      !state.locationPopOver
    ) {
      return { locationPopOver: true };
    } else if (
      props.locationPermissionReply !== true &&
      state.locationPopOver
    ) {
      return { locationPopOver: false };
    }
    
    if (props.modules?.indexOf(Modules.CHAT) !== -1 && props.sessionStatus != SESSION_STATUS.CLOSING && props.isMessageArrived && !props.messagesRead) {
      if (props.isChatPopOver === false && props.isMessageArrived) {
        let modulesInfo = props.headerIcons;
        if (modulesInfo[Modules.CHAT])
          modulesInfo[Modules.CHAT].class = "moduleIconActive";
        props.setChatPopOverStateAction(true);
        props.headerAction.setHeaderIcons(modulesInfo);
        return {
          isMessageArrived: props.isMessageArrived,
          // ... other derived state properties
        };
      } else return null;
    } else {
      return null;
    }
  }
  getPresenterName = uniqueId => {
    let userNames = this.props.admittedParticipants.filter(
      participant => participant.uniqueId === uniqueId
    )[0];
    if (userNames) {
      return userNames.userName;
    } else {
      return false;
    }
  };

  sortParticipantToAcronym = () => {
    let participantList = [];
    if (this.props.allParticipants) {
      _.map(this.props.allParticipants, participantObject => {
        // sorting done for participants 
        // organiser, presenter, self and then others
        if (participantObject.role === USER_ROLES.AGENT) {
          participantList.splice(0, 0, participantObject);
        } else {
          if (this.props.presenter === participantObject.uniqueId) {
            participantList.splice(1, 0, participantObject);
          } else if (participantObject.uniqueId === this.props.uniqueId) {
            if (participantList[1] && participantList[1].uniqueId == this.props.presenter) {
              participantList.splice(2, 0, participantObject);
            } else {
              participantList.splice(1, 0, participantObject);
            }
          } else {
            participantList.push(participantObject);
          }
        }
      })
      this.setState({ sortedParticipants: participantList })
    }
    else {
      this.setState({ sortedParticipants: this.props.allParticipants })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Starts --- RealWear End Call voice command block
    // deregister voice commands and clear session storage to avoide clean-up related issue
    if (this.props.isRealwearEndCall != prevProps.isRealwearEndCall && this.props.isRealwearEndCall) {
      this.props.deregisterAllVoiceCommands();
      // navigate to realwear end call page
      this.props.history.push(CONFIG.path.realwearEndCall);
    }
    // End --- RealWear End Call voice command block 

    if(this.props.snapshoteeId !== prevProps.snapshoteeId && this.props.snapshoteeId){
      this.setState({
        currentSnapshoteeId : this.props.snapshoteeId
      })
    }

    //check if file or thumbnail url available in any canvas and set save button enable 
    if (prevProps.canvases !== this.props.canvases) {
      let isSaveEnabled = false;
      const canvases = this.props.canvases;
      for (const index in canvases) {
        /*
         added check for enable save button flag 
         1. if url is available for canvas 
         2. if url is not available and canvas have a data to save 
        */
        if ((canvases[index].fileUrl || canvases[index].thumbnailUrl) ||
          ((!canvases[index].fileUrl || !canvases[index].thumbnailUrl) &&
            !isCanvasBlank(canvases[index].canvasData,
              canvases[index].canvasType,
              canvases[index].activePage))) {
          isSaveEnabled = true
        }
      }
      this.setState({ isSaveEnabled });
    }

    if(prevProps.customerCoBrowseStarted !== this.props.customerCoBrowseStarted) {   
      let modulesInfo = { ...this.props.headerIcons }

      //disable snapshot icon cobrowse running
      if (modulesInfo["SNAPSHOT"]) {
        modulesInfo["SNAPSHOT"] = {
          ...modulesInfo["SNAPSHOT"],
          logo: this.props.customerCoBrowseStarted? ic_snapshot_disabled: ic_snapshot,
          class: this.props.customerCoBrowseStarted? "cursorDisabled moduleIcon": "moduleIcon cursorPointer",
          textLabelClass: this.props.customerCoBrowseStarted? "cursorDisabled": "cursorPointer",
        };
      }     
      this.props.headerAction.setHeaderIcons(modulesInfo);

      //set cobrowse icon to active and click disable 
      if(this.props.customerCoBrowseStarted) {    
        this.props.headerAction.updateHeaderIconClass(
          Modules.CO_BROWSE,
          "moduleIconActive cursorDisabled",
          "cursorDisabled"
        );
      } else {
        if(this.props.isPresenter) {
          this.props.headerAction.updateHeaderIconClass(
            Modules.CO_BROWSE,
            "moduleIcon cursorPointer",
            "cursorPointer"
          );
        } else {
          this.props.headerAction.updateHeaderIconClass(
            Modules.CO_BROWSE,
            "moduleIcon cursorDisabled",
            "cursorDisabled"
          );
        }
      }
    }

    if(this.props.snapshoteeId && 
      prevProps.snapshoteeId !== this.props.snapshoteeId && 
      this.props.uniqueId !== this.props.snapshoteeId &&
      this.props.uniqueId !== this.props.organiser &&
      this.props.isSnapshotRunning) {
      let admittedParticipant = _.find(this.props.admittedParticipants, admittedParticipant => {
        return admittedParticipant.uniqueId === this.props.snapshoteeId
      });
      admittedParticipant?.userName &&
      toastr.info("SNAPSHOT_STARTED_FOR_USER", [{ key: "userName", value: admittedParticipant.userName }], {
        toastId: "SNAPSHOT_STARTED_FOR_USER_" + admittedParticipant.uniqueId // to avoid duplication 
      });
    }

    if((prevProps.showSnapshot !== this.props.showSnapshot || 
      prevProps.snapshotPermissionReply !== this.props.snapshotPermissionReply ||
      prevProps.isSnapshotStarted !== this.props.isSnapshotStarted) &&
      this.props.cobrowseType === cobrowseTypes.CUSTOMER_CO_BROWSE) { 
      let modulesInfo = { ...this.props.headerIcons }
      //set cobrowse icon to active and click disable for presenter only
      if (modulesInfo["CO_BROWSE"]) {
        modulesInfo["CO_BROWSE"] = {
          ...modulesInfo["CO_BROWSE"],
          logo: this.props.isPresenter && !this.isSnapshotStarted() ? ic_canvas_tools_co_browse: ic_cobrowse_disabled,
          class: this.props.isPresenter && !this.isSnapshotStarted() ? "moduleIcon" : "moduleIcon cursorDisabled",
          textLabelClass: this.props.isPresenter && !this.isSnapshotStarted() ? "cursorPointer" : "cursorDisabled",
          clickAction: this.props.isPresenter && (this.props.cobrowseType === cobrowseTypes.CUSTOMER_CO_BROWSE ? "handleTargetSiteSelectionModalToggle" : "handleCoBrowseToggle")
        };
      }
      this.props.headerAction.setHeaderIcons(modulesInfo);
    }

    if(prevProps.customerUriIdentifier !== this.props.customerUriIdentifier) {
      this.setSessionLink( getBaseUrl(this.props.customerUriIdentifier, this.props.sessionKey));
    }

    if(this.props.isSnapshotTaken !== prevProps.isSnapshotTaken) {
      if (this.props.isSnapshotTaken === false) {
        // Agent or User stop/close the snapshot modal then SNAPSHOT_STOPPED message will toast
        toastr.info("SNAPSHOT_STOPPED");
      } else if (this.props.isSnapshotTaken === true) {
        // Agent taken snapshot
        toastr.info("SNAPSHOT_TAKEN");
      }
    }
    
    // for participant - show toast msg for plugin not installed status only for screenshare
    if (this.props.organiser === this.props.uniqueId &&
      this.props.pluginNotInstalledParticipantIds !== prevProps.pluginNotInstalledParticipantIds &&
      this.props.pluginNotInstalledParticipantIds) {
      for (let i = 0; i < this.props.admittedParticipants.length; i++) {
        if (this.props.pluginNotInstalledParticipantIds.indexOf(this.props.admittedParticipants[i].uniqueId) != -1) {
          toastr.error("USER_DONT_HAVE_PLUGIN", [{ key: "userName", value: this.props.admittedParticipants[i].userName }],
            { toastId: "USER_DONT_HAVE_PLUGIN_" + this.props.admittedParticipants[i].uniqueId });
        }
      }
    }

    if (this.props.locationPermissionReply !== prevProps.locationPermissionReply
      && this.props.locationPermissionReply === true
      && this.props.uniqueId != this.props.organiser) { // need to process only for user as organiser is already processing it in _SHARE_LOCATION WS call
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {
        data: {
          category: socketMessage.category.LOCATION,
          action: socketMessage.subCategories.LOCATION.GET_ALL_LOCATION_DATA,
          sessionKey: this.props.sessionKey
        }
      });
    }

    //cursor disable for the co-browser when cobrowser is running
    if (prevProps.showCoBrowse !== this.props.showCoBrowse && this.props.isPresenter) {
      if (this.props.showCoBrowse) {
        this.props.headerAction.updateHeaderIconClass("CO_BROWSE", "moduleIconActive cursorDisabled", " cursorDisabled")
      } else {
        this.props.headerAction.updateHeaderIconClass("CO_BROWSE", "moduleIcon cursorPointer", " cursorPointer")
      }
    }

    // Notify user on edit permission change
    if (this.props.uniqueId !== this.props.organiser && !_.isEqual(this.props.admittedParticipants, prevProps.admittedParticipants)) {
      let index = _.findIndex(prevProps.admittedParticipants,
        participant => participant && participant.uniqueId === this.props.uniqueId);
      let prevParticipant = index && index !== -1 ? prevProps.admittedParticipants[index] : null;

      let participantIndex = _.findIndex(this.props.admittedParticipants,
        participant => participant && participant.uniqueId === this.props.uniqueId);
      let participant = participantIndex && participantIndex !== -1 ? this.props.admittedParticipants[participantIndex] : null;

      if (prevParticipant && participant && prevParticipant.allowEditAccess !== participant.allowEditAccess) {
        if (participant.allowEditAccess) {
          toastr.info("USER_ALLOWED_TO_EDIT_CANVAS_PDF");
        } else {
          toastr.info("REVOKING_EDIT_PERMISSION");
        }
      }
    }

    //denied audio or video call permission (loookit permission modal)
    if (
      this.props.webRtcPermissionReplyStatus !== null &&
      !this.props.webRtcPermissionReplyStatus.isGranted &&
      this.props.webRtcPermissionReplyStatus.uniqueId !==
      prevProps.webRtcPermissionReplyStatus?.uniqueId
    ) {
      let permissionRepliedParticipant = this.props.admittedParticipants.filter(admittedParticipant => admittedParticipant.uniqueId === this.props.webRtcPermissionReplyStatus.uniqueId)[0];
      if (permissionRepliedParticipant.uniqueId !== this.props.organiser) {
        const toastrOptions = {
          onClose: () =>
            this.props.headerAction.clearWebRtcPermissionReplyStatus()
        };
        if (this.props.webRtcPermissionReplyStatus.media === mediaType.AUDIO) {
          toastr.info("DENIED_AUDIO_PERMISSION", [{ key: "userName", value: permissionRepliedParticipant.userName }],
            { ...toastrOptions, toastId: "DENIED_AUDIO_PERMISSION_" + permissionRepliedParticipant.uniqueId })
        } else if (this.props.webRtcPermissionReplyStatus.media === mediaType.AUDIO_VIDEO) {
          toastr.info("DENIED_VIDEO_PERMISSION", [{ key: "userName", value: permissionRepliedParticipant.userName }],
            { ...toastrOptions, toastId: "DENIED_VIDEO_PERMISSION_" + permissionRepliedParticipant.uniqueId })
        }
      }
    }

    // End user denied camera / microphone permission asked by their browser
    if (!_.isEmpty(this.props.mediaDeviceDeniedDetails) &&
      this.props.mediaDeviceDeniedDetails.participantId !== this.props.organiser
    ) {
      const mediaDeviceDeniedParticipant = this.props.admittedParticipants.filter(admittedParticipant => admittedParticipant.uniqueId === this.props.mediaDeviceDeniedDetails.participantId)[0];
      const toastrOptions = {
        onClose: () =>
          this.props.headerAction.resetMediaDeviceDeinedDetails()
      };

      if ((this.props.mediaDeviceDeniedDetails.VIDEO !==
        prevProps.mediaDeviceDeniedDetails.VIDEO ||
        this.props.mediaDeviceDeniedDetails.participantId !==
        prevProps.mediaDeviceDeniedDetails.participantId) &&
        this.props.mediaDeviceDeniedDetails.VIDEO === true) {
        toastr.error("USER_DONT_HAVE_CAMERA", [{ key: "userName", value: mediaDeviceDeniedParticipant.userName }],
          { ...toastrOptions, toastId: "USER_DONT_HAVE_CAMERA_" + mediaDeviceDeniedParticipant.uniqueId })
      }
      if ((this.props.mediaDeviceDeniedDetails.AUDIO !==
        prevProps.mediaDeviceDeniedDetails.AUDIO ||
        this.props.mediaDeviceDeniedDetails.participantId !==
        prevProps.mediaDeviceDeniedDetails.participantId) &&
        this.props.mediaDeviceDeniedDetails.AUDIO === true) {
        toastr.error("USER_DONT_HAVE_MIC", [{ key: "userName", value: mediaDeviceDeniedParticipant.userName }],
          { ...toastrOptions, toastId: "USER_DONT_HAVE_MIC_" + mediaDeviceDeniedParticipant.uniqueId })
      }
    }

    //location permision denied handle
    if (
      this.props.locationPermissionReplyStatus !== null &&
      !this.props.locationPermissionReplyStatus.isGranted &&
      this.props.locationPermissionReplyStatus.uniqueId !==
      prevProps.locationPermissionReplyStatus?.uniqueId
    ) {
      const toastrOptions = {
        onClose: () =>
          this.props.headerAction.clearLocationPermissionReplyStatus()
      };

      for (let i = 0; i < this.props.admittedParticipants.length; i++) {
        if (this.props.admittedParticipants[i].uniqueId !== this.props.organiser) {
          if (
            this.props.locationPermissionReplyStatus.uniqueId ===
            this.props.admittedParticipants[i].uniqueId && this.props.userRole === USER_ROLES.AGENT
          )
            toastr.info("DENIED_LOCATION_PERMISSION", [{ key: "userName", value: this.props.admittedParticipants[i].userName }],
              { ...toastrOptions, toastId: "DENIED_LOCATION_PERMISSION_" + this.props.admittedParticipants[i].uniqueId });
        }
      }
    }

    // send webrtc request after pipeline is created 
    // ie. after SHARE_WEBRTC_MEDIA
    if (this.props.callMediaType !== prevProps.callMediaType &&
      this.props.callMediaType) {
      if (this.props.uniqueId === this.props.organiser) {
        this.sendCallRequestForMedia(this.props.callMediaType)
      }
      this.props.headerAction.setCallMediaType(null);
    }

    // show msg to user when screen share starts
    if (document.getElementById(Modules.SCREENSHARE)) {
      document.getElementById(Modules.SCREENSHARE).className = this.props.isPresenter
        ? this.props.headerIcons[Modules.SCREENSHARE].class
        : this.props.headerIcons[Modules.SCREENSHARE].class + " cursorDisabled";
      
      document.getElementById(Modules.SCREENSHARE).textLabelClass = this.props.isPresenter
        ? this.props.headerIcons[Modules.SCREENSHARE].textLabelClass
        : this.props.headerIcons[Modules.SCREENSHARE].textLabelClass + " cursorDisabled";
    }

    if (this.props.screenSharingParticipantId !== prevProps.screenSharingParticipantId && this.props.screenSharingParticipantId) {
      toastr.info("SCREEN_SHARE_STARTED");
    }

    if(this.props.modules?.indexOf(Modules.CHAT) !== -1 && this.props.isChatPopOver != prevProps.isChatPopOver) {
      this.props.headerAction.updateHeaderIconClass(
        Modules.CHAT,
        `${this.props.isChatPopOver ? "moduleIconActive" : "moduleIcon"} cursorPointer`,
        "cursorPointer"
      );
    }

    if (prevProps.isCallEnded !== this.props.isCallEnded && this.props.isCallEnded && this.props.organiser !== this.props.uniqueId) {
      toastr.info("AGENT_ENDED_CALL")
    }

    if (prevProps.presenter !== this.props.presenter) {
      let presenterName = this.getPresenterName(this.props.presenter);
      if (presenterName) {
        if (this.props.uniqueId !== this.props.presenter) {
          if (this.props.organiser === this.props.presenter) {
            presenterName = getUserNameForSelectedLanguage(this.props.language, presenterName);
          }
          toastr.info("PRESENTER_CHANGED", [{ key: "presenterName", value: presenterName }],
            { toastId: "PRESENTER_CHANGED_" + this.props.presenter });
        } else {
          toastr.info("YOU_ARE_PRESENTER");
        }
        customerCoBrowseAction.showTargetSiteSelectionModalAction(false)
      }
      this.sortParticipantToAcronym();

      let modulesInfo = { ...this.props.headerIcons }
      // change screenshare disable enable icon based on presenter
      if (!isMobileOriPad()) {
        if (modulesInfo["SCREENSHARE"]) {
          modulesInfo["SCREENSHARE"] = {
            ...modulesInfo["SCREENSHARE"],
            logo: this.props.isPresenter ? ic_share : ic_share_disabled,
            class: this.props.isPresenter ? "moduleIcon " : "moduleIcon cursorDisabled",
            textLabelClass: this.props.isPresenter ? " cursorPointer" : " cursorDisabled",
            clickAction: this.props.isPresenter ? "handleScreenShareToggle" : ""
          };
        }
      }

      // change co-browse disable enable icon based on presenter
      if(this.props.cobrowseType === cobrowseTypes.CUSTOMER_CO_BROWSE) {
        if (modulesInfo["CO_BROWSE"]) {
          modulesInfo["CO_BROWSE"] = {
            ...modulesInfo["CO_BROWSE"],
            logo: this.props.isPresenter && !this.isSnapshotStarted() ? ic_canvas_tools_co_browse: ic_cobrowse_disabled,
            class: this.props.isPresenter && !this.isSnapshotStarted() ? "moduleIcon" : "moduleIcon cursorDisabled",
            textLabelClass: this.props.isPresenter && !this.isSnapshotStarted() ? "cursorPointer" : "cursorDisabled",
            clickAction: this.props.isPresenter ? "handleTargetSiteSelectionModalToggle" : ""
          };
        }
      } else {
        if (modulesInfo["CO_BROWSE"]) {
          modulesInfo["CO_BROWSE"] = {
            ...modulesInfo["CO_BROWSE"],
            logo: this.props.isPresenter ? ic_canvas_tools_co_browse: ic_cobrowse_disabled,
            class: this.props.isPresenter ? "moduleIcon" : "moduleIcon cursorDisabled",
            textLabelClass: this.props.isPresenter ? "cursorPointer" : "cursorDisabled",
            clickAction: this.props.isPresenter ? "handleCoBrowseToggle" : ""
          };
        }
      }
      
      this.props.headerAction.setHeaderIcons(modulesInfo);
    }

    if (this.props.organiser !== this.props.uniqueId &&
      this.props.uniqueId &&
      this.props.isSessionEnded) {
      this.props.setSpinnerVisibility(false)
      this.props.history.push(CONFIG.path.sessionEnded);
    } else if ((this.props.organiser == this.props.uniqueId ||
      this.props.userRole === USER_ROLES.AGENT) &&
      this.props.isSessionEnded) {
      leaveSessionAction.localSessionAllDataCleanUp(true);
      this.props.setSpinnerVisibility(false)
      this.props.history.push(CONFIG.path.createSession);
    }

    if (this.props.latestUpdatedParticipant) {
      const prevParticipantCount = prevProps.allParticipants.length;
      const currParticipantCount = this.props.allParticipants.length;

      const { userName, uniqueId, admissionStatus, updateReason } = this.props.latestUpdatedParticipant;
      if (prevParticipantCount < currParticipantCount) {
        // Show waiting area to the agent when app emulation is started and a new participant arrives
        if(this.state.isAppEmulationStarted && this.props.uniqueId === this.props.organiser && this.props.latestUpdatedParticipant.status === "WAITING") {
          this.setState({ showWaitingArea: true })
        }
        if (this.props.uniqueId !== uniqueId) {
          if (admissionStatus === USER_ADMISSION_STATUS.ADMITTED) {
            toastr.info("ARRIVED", [{ key: "userName", value: userName }], { toastId: "ARRIVED_" + uniqueId });
          } else if (admissionStatus == USER_ADMISSION_STATUS.PENDING) {
            toastr.info("ADMIT_USER", [{ key: "userName", value: userName }], { toastId: "ADMIT_USER_" + uniqueId });
          }
        }
      } else if (prevParticipantCount > currParticipantCount) {
        //close wating area popup when agent left the loby 
        if (this.props.latestUpdatedParticipant?.role === "AGENT")
          this.setState({ showWaitingArea: false })

        if (this.props.organiser === this.props.uniqueId && admissionStatus == USER_ADMISSION_STATUS.REJECTED) {
          toastr.info("DISMISSED", [{ key: "userName", value: userName }],
            { toastId: "DISMISSED_" + uniqueId });
        }
        if(admissionStatus == USER_ADMISSION_STATUS.ADMITTED) {
          // If participant has left session show toast msg 
          // and if participant is evicted then we don't have to show participant left message
          // (that is handled below with USER_EVICTED msg)
          if(uniqueId !== this.props.evictedParticipantDetails.uniqueId
            && updateReason === PARTICIPANT_UPDATE_REASON.LEFT) {
            toastr.info("LEFT", [{ key: "userName", value: userName }], { toastId: "LEFT_" + uniqueId });
          }
          let audioElement = document.getElementById(uniqueId + "audio");
          if (audioElement) {
            if (isIE) {
              audioElement.parentNode.removeChild(audioElement);
            } else {
              audioElement.remove();
            }
          }
          if (this.state.locationPopOver) {
            this.props.stopLocationShare(uniqueId, this.props.allParticipants.length);
          }
        }
      }
    }

    if (prevProps.participantJoinedUniqueId !== this.props.participantJoinedUniqueId && this.props.participantJoinedUniqueId) {
      let joinParticipantObject = _.find(this.props.admittedParticipants, { uniqueId: this.props.participantJoinedUniqueId })
      if (joinParticipantObject) {
        toastr.info("JOINED_APP_EMULATION_SESSION", [{ key: "userName", value: joinParticipantObject.userName }],
          { toastId: "JOINED_APP_EMULATION_SESSION_" + joinParticipantObject.uniqueId });
      }
    }
    if (prevProps.participantLeftUniqueId !== this.props.participantLeftUniqueId && this.props.participantLeftUniqueId) {
      let leftParticipantObject = _.find(this.props.admittedParticipants, { uniqueId: this.props.participantLeftUniqueId })
      if (leftParticipantObject) {
        toastr.info("LEFT_APP_EMULATION_SESSION", [{ key: "userName", value: leftParticipantObject.userName }],
          { toastId: "LEFT_APP_EMULATION_SESSION_" + leftParticipantObject.uniqueId });
      }
    }
    if (prevProps.appEmulationIsSessionEnded !== this.props.appEmulationIsSessionEnded && this.props.appEmulationIsSessionEnded) {

      toastr.info("AGENT_HAD_CLOSE_APP_EMULATION");
    }

    if (prevProps.participantDeniedUniqueId !== this.props.participantDeniedUniqueId && this.props.participantDeniedUniqueId) {
      let deniedParticipantObject = _.find(this.props.admittedParticipants, { uniqueId: this.props.participantDeniedUniqueId })
      if (deniedParticipantObject) {
        toastr.info("APP_EMULATION_PERMISSION_DENIED", [{ key: "userName", value: deniedParticipantObject.userName }],
          { toastId: "APP_EMULATION_PERMISSION_DENIED_" + deniedParticipantObject.uniqueId });
      }
    }

    if (!_.isEqual(this.props.allParticipants, prevProps.allParticipants)) {
      this.sortParticipantToAcronym();
    }

    if ( prevProps.showKmsConnectionStatus !== this.props.showKmsConnectionStatus &&
      this.props.showKmsConnectionStatus && !this.props.kmsConnectionStatus) {
      toastr.error("KMS_NOT_CONNECTED");
      this.props.sessionActions.resetShowKmsConnectionStatusAction();
      this.hideSpinner();
    }

    if (this.props.webRtcPermissionRequest !== prevProps.webRtcPermissionRequest && this.props.uniqueId === this.props.organiser) {
      let modulesInfo = { ...this.props.headerIcons }
      if(modulesInfo["AUDIO"]) {
        modulesInfo["AUDIO"] = {
          ...modulesInfo["AUDIO"],
          logo: (this.props.userRole === USER_ROLES.USER || this.props.webRtcPermissionRequest === Modules.AUDIO_VIDEO) ? ic_audio_disabled : ic_audio,
          class: (this.props.userRole === USER_ROLES.USER || this.props.webRtcPermissionRequest === Modules.AUDIO_VIDEO) ? "moduleIcon cursorDisabled" : "moduleIcon",
          textLabelClass: (this.props.userRole === USER_ROLES.USER || this.props.webRtcPermissionRequest === Modules.AUDIO_VIDEO) ? " cursorDisabled" : " cursorPointer",
        };
        this.props.headerAction.setHeaderIcons(modulesInfo);
      }
    }

    // Snapshot agent side Check
    // Is user camera busy or Is user block camera access
    if (((prevProps.isCameraPermissionGranted !== this.props.isCameraPermissionGranted && this.props.isCameraPermissionGranted === false) ||
        (prevProps.isCameraBusy !== this.props.isCameraBusy && this.props.isCameraBusy)) &&
        this.props.uniqueId === this.props.organiser &&
        this.state.currentSnapshoteeId) {      
      let snapshoteeParticipant = this.props.admittedParticipants.filter(admittedParticipant => admittedParticipant.uniqueId === this.state.currentSnapshoteeId)[0];
      toastr.error("USER_DONT_HAVE_CAMERA", [{ key: "userName", value: snapshoteeParticipant?.userName }],
        {
          toastId: "USER_DONT_HAVE_CAMERA_" + snapshoteeParticipant?.uniqueId // to avoid duplication 
        })
      this.props.headerAction.setSnapshotCameraPermissionflag(null);
      this.setState({
        currentSnapshoteeId: null
      })
    }

    if (
      this.props.snapshotPermissionReply && this.props.snapshotPermissionReply != prevProps.snapshotPermissionReply
    ) {
      this.setState({
        isSnapshotPermissionButtonClicked: false,
        spinnerVisibility: false,
        isDeviceAttached: null
      });
    }

    // participant joined the co-browse session
    if (prevProps.coBrowseJoinedParticipantUniqueId !== this.props.coBrowseJoinedParticipantUniqueId
      && this.props.coBrowseJoinedParticipantUniqueId) {
      let joinedParticipant = _.find(this.props.admittedParticipants, { uniqueId: this.props.coBrowseJoinedParticipantUniqueId });
      if (joinedParticipant) {
        toastr.info("JOINED_CO_BROWSE_SESSION", [{ key: "userName", value: joinedParticipant.userName }],
          {
            toastId: "JOINED_CO_BROWSE_SESSION_" + joinedParticipant.uniqueId
          });
      }
    }

    //participant left co-browse session and is not user himself
    if (prevProps.participantLeftCoBrowseUniqueId !== this.props.participantLeftCoBrowseUniqueId &&
      this.props.participantLeftCoBrowseUniqueId &&
      this.props.uniqueId !== this.props.participantLeftCoBrowseUniqueId) {
      let leftParticipant = _.find(this.props.admittedParticipants, { uniqueId: this.props.participantLeftCoBrowseUniqueId })
      if (leftParticipant) {
        toastr.info("LEFT_CO_BROWSE", [{ key: "userName", value: leftParticipant.userName }], {
          toastId: "LEFT_CO_BROWSE_" + leftParticipant.uniqueId
        });
      }
    }

    //presenter closes co-browse
    if (prevProps.coBrowseSessionStatus !== this.props.coBrowseSessionStatus &&
      this.props.coBrowseSessionStatus === CO_BROWSE_SESSION_STATUS.CO_BROWSE_STOPPED) {
      // toastr.info("CO_BROWSE_ENDED"));
      coBrowseAction.resetCobrowseSessionDetails();
    }

    // participant denied co-browse permission
    if (prevProps.participantDeniedCoBrowseUniqueId !== this.props.participantDeniedCoBrowseUniqueId
      && this.props.participantDeniedCoBrowseUniqueId) {
      let deniedParticipant = _.find(this.props.admittedParticipants, { uniqueId: this.props.participantDeniedCoBrowseUniqueId })
      if (deniedParticipant) {
        toastr.info("CO_BROWSE_PERMISSION_DENIED", [{ key: "userName", value: deniedParticipant.userName }],
          { toastId: "CO_BROWSE_PERMISSION_DENIED_" + deniedParticipant.uniqueId });
      }
    }
    //If Cb session ended , page is reloaded
    if (prevProps.showCustomerCoBrowse !== this.props.showCustomerCoBrowse
       && !this.props.showCustomerCoBrowse && !prevProps.endCustomerCBSession) {
      toastr.info("CO_BROWSE_ENDED")
    }

    // Show toastr agent and other users, if any user is evicted from the session.
    let evictedParticipantName = this.props.evictedParticipantDetails.evictedParticipantName;
    if (prevProps.evictedParticipantDetails.uniqueId !== this.props.evictedParticipantDetails.uniqueId) {
      if (this.props.uniqueId === this.props.organiser) {
        toastr.info("USER_EVICTED", [{ key: "userName", value: evictedParticipantName }]),
        { toastId: "USER_EVICTED_" + this.props.evictedParticipantDetails.uniqueId };
      } else {
        toastr.info("LEFT", [{ key: "userName", value: evictedParticipantName }], { toastId: "LEFT_" + this.props.evictedParticipantDetails.uniqueId });
      }
    }
    if(this.props.isUserEvicted === true && prevProps.isUserEvicted !== this.props.isUserEvicted) {
      this.props.history.push(
        {pathname: CONFIG.path.userEvicted, state: { userSessionName: this.props.userSessionName}}
      );
    }
    // If presenter has changed, and user was the previous presenter then post end_without_confirmation message to iframe 
    // This is when we change presenter from waiting area
    if (this.props.presenter !== prevProps.presenter 
      && prevProps.presenter == this.props.uniqueId 
      && this.props.showCustomerCoBrowse 
      && !this.props.endCustomerCBSession) {
      console.log("End_session_without_confirmation on presenter change")
      postMesageToCoBrowseIframeUtility(this.props.endUserToken, this.props.coBrowseBaseUrl);
      const messageToWebsocket = {
        data: {
          category: socketMessage.category.CUSTOMER_CO_BROWSE,
          action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.END_SESSION,
          sessionKey: this.props.sessionKey,
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, messageToWebsocket);
    }
    //  Close Snapshot window at Agent's end if there's only 1 participant/end-user and he denies camera permission or Camera Busy
    if (((prevProps.isCameraPermissionGranted !== this.props.isCameraPermissionGranted && this.props.isCameraPermissionGranted === false) ||
        (prevProps.isCameraBusy !== this.props.isCameraBusy && this.props.isCameraBusy)) && 
        this.props.uniqueId === this.props.organiser &&
        this.props.admittedParticipants?.length<=2) {
      this.props.headerAction.setShowSnapshot(false);
    }
  }

  isSnapshotStarted =()=> {
    return (this.props.showSnapshot || 
      (this.props.snapshoteeId && this.props.snapshotPermissionReply) ||
      this.props.isSnapshotStarted)
  }

  handleEndSession = () => {
    this.props.handleEndSession();
  };

  handleCancelClick = () => {
    this.setState({ popoverOpen: false }, () => {
      if (isIE)
        document.body.style.overflow = "visible";
      else
        document.body.style.overflow = "overlay";
    });
  };
  renderSprinner() {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }
  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

  handleModuleClick = module => {
    this.setState({ popoverOpen: false }, () => {
      // added check to hide scroll on modal/spinner open
      if(!this.state.spinnerVisibility && !this.state.isParticipantAvailable) {
        if (isIE)
          document.body.style.overflow = "visible";
        else
          document.body.style.overflow = "overlay";
      }
    });
    let modulesInfo = this.props.headerIcons;

    if (
      modulesInfo[module].class === "moduleIconActive" &&
      module !== Modules.CHAT
    )
      return;
    if (
      this.props.headerIcons[module] &&
      this.props.headerIcons[module].class === "moduleIconActiveHidden"
    ) {
      this.props.headerAction.updateHeaderIconClass(module, "moduleIconActive", " cursorPointer");
      document.getElementById("AudioVideoContainer").style.display = "block";
      return;
    }
    const method = this.headerIconClickActions[modulesInfo[module].clickAction];
    const userAllowed = modulesInfo[module].userAllowed;
    /**(this.props.userRole === USER_ROLES.AGENT ||
        (this.props.userRole !== USER_ROLES.AGENT && userAllowed)) */
    if (
      typeof method === "function" &&
      (this.props.organiser === this.props.uniqueId ||
        (this.props.organiser !== this.props.uniqueId && userAllowed))
    ) {
      if (module === Modules.APP_EMULATION) {
        method(true);
      } else {
        method();
      }
    }

  };

  handleTargetSiteSelectionModalToggle = (showTargetSiteSelectionModal) => {
    if(this.isSnapshotStarted()){
      toastr.error("CUSTOMER_CO_BROWSE_CANNOT_USE_WITH_SNAPSHOT")
      return
    }
    if (showTargetSiteSelectionModal && (!this.checkParticipantsAvailable() ||
    this.props.showCustomerCoBrowse)) return;
    customerCoBrowseAction.showTargetSiteSelectionModalAction(showTargetSiteSelectionModal)
  }

  handleAudioToggle = async () => {
    let webRtcMediaTypeToBeShared = this.props.webRtcPermissionRequest;
    if (webRtcMediaTypeToBeShared !== null) return;
    if (!this.checkParticipantsAvailable()) return;
    if (!isWebRTCPluginInstalled()) return;       // for self - check and show toast msg for plugin uninstalled
    this.props.headerAction.resetParticipantsPluginInfo();
    //if (!this.checkKMSStatus()) return
    let audioDevices = [];
    this.showSpinner();
    await navigator.mediaDevices.enumerateDevices().then(devices => {
      console.log('[handleAudioToggle] devices list: ', devices);
      audioDevices = devices.filter(device => {
        if (device.kind === "audioinput") {
          return true;
        }
      });
    });
    if(audioDevices.length <= 0) {
      toastr.error("MIC_NOT_CONNECTED");
      this.hideSpinner();
    } else {
      // call getUserMedia to check if audio device is available / not busy
      // call getUserMedia to fix WAAG-4944 because safari only gets default devices until after getUserMedia is called.
      navigator.mediaDevices.getUserMedia({ audio: true })  // getUserMedia will fail if audio is busy / unavailable
      .then(stream => {
        this.stopMediaTracksOfGivenStream(stream);  // stop streams retrieved as Video component will retrieve it
        // send share media request
        this.sendShareMediaRequest(mediaType.AUDIO);
      })
      .catch((error) => {
        let errorMessage = getUserMediaError(error, mediaType.AUDIO);
        if (errorMessage) {
          this.hideSpinner();
          toastr.error(errorMessage)
        }
      });
    }
  };

  handleAudioVideoToggle = async () => {
    // if camera is busy already
    if (this.props.webRtcPermissionRequest == mediaType.AUDIO_VIDEO) return;
    //If there is no more participants then doesn't make sense to call it.
    if (!this.checkParticipantsAvailable()) return;
    if (!isWebRTCPluginInstalled()) return;    // for self - check and show toast msg for plugin uninstalled
    this.props.headerAction.resetParticipantsPluginInfo();
    //if (!this.checkKMSStatus()) return;
    this.checkCameraAndSendVideoCallRequest();
  }

  checkCameraAndSendVideoCallRequest = async () => {
    this.showSpinner();
    let videoDevices = [];
    await navigator.mediaDevices.enumerateDevices().then(devices => {
      videoDevices = devices.filter(device => {
        if (device.kind === "videoinput") {
          return true;
        }
      });
    });
    if (videoDevices.length <= 0) {
      // if camera is not found with Agent, no need to check camera busy so send share media req
      // Agent will join with mike only and user may join with camera and mike if he has
      if (this.props.webRtcPermissionRequest == mediaType.AUDIO){
        this.hideSpinner();
      }

      this.sendShareMediaRequest(mediaType.AUDIO_VIDEO);
      toastr.error("WEBCAM_NOT_CONNECTED");
    } else {
      // call getUserMedia to check if camera is available / not busy
      // call getUserMedia because safari only gets default devices until after getUserMedia is called,
      // due to which stream is not displayed when we start call first time from a tab
      navigator.mediaDevices.getUserMedia({ video: true })  // getUserMedia will fail if camera is busy / unavailable
      .then(stream => {
        this.stopMediaTracksOfGivenStream(stream);  // stop streams retrieved as Video component will retrieve it
        // send share media request
        this.sendShareMediaRequest(mediaType.AUDIO_VIDEO);
      }
      )
      .catch((error) => {
        let errorMessage = getUserMediaError(error, mediaType.VIDEO);
        if (errorMessage) {
          this.hideSpinner();
          toastr.error(errorMessage)
        }
      });
    }
  }

  sendShareMediaRequest = (requestMediaType) => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      //get camera list
      let deviceList = devices.filter(device => {
        if (device.kind === "videoinput") {
          return device;
        }
      });

      // decide media type
      let media =
        requestMediaType === mediaType.AUDIO_VIDEO  // req is for audio_video
          ? ((deviceList && deviceList.length) || isMobileOriPad()) &&  // devicelist is non empty for desktop || user is on mobile
            (this.props.uniqueId === this.props.organiser || !this.props.snapshotPermissionReply) // agent || snapshot  is not runing for user (dont share VIDEO, share only AUDIO)
            ? mediaType.AUDIO_VIDEO
            : mediaType.AUDIO
          : mediaType.AUDIO;
      var message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.SHARE_WEBRTC_MEDIA,
          sessionKey: this.props.sessionKey,
          media: media
        }
      };
      // send SHARE_MEDIA req to create a media pipeline
      this.props.sendWebsocketMessage(
        socketMessage.events.MESSAGE,
        message
      );
    }).catch(function (err) {
      console.error(err.name + ": " + err.message);
    });
  }

  stopMediaTracksOfGivenStream = (stream) => {
    stream && stream.getTracks().forEach(track => {
      track.stop();
    });
  }

  sendCallRequestForMedia = (mediaType) => {
    if (this.props.webRtcPermissionRequest !== mediaType) { // check to avoid multiple audio / video calls
      let switchTimeout = 0;
      let self = this;
      setTimeout(function () {
        var message = {
          data: {
            category: socketMessage.category.WEBRTC,
            action: webRtcMessageId.WEBRTC_PERMISSION_REQUEST,
            sessionKey: self.props.sessionKey,
            media: mediaType,
            token: self.props.token
          }
        };
        self.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      }, switchTimeout);
      // set current media type being shared in gthe reducer
      this.props.sessionActions.updateWebRtcPermissionRequest(mediaType);
      // this flag when set true, will load AudioVideoContainer
      this.props.headerAction.setCallModalPermission(true);
      this.hideSpinner();
    }
  }

  handleScreenShareToggle = () => {
    //If there is no more participants then doesn't make sense to call it.
    this.props.headerAction.resetParticipantsPluginInfo();
    if (!this.checkParticipantsAvailable()) return;
    if (!isWebRTCPluginInstalled()) return;   // for self - check and show toast msg for plugin uninstalled
    //if (!this.checkKMSStatus()) return
    //TODO: get room meta data and check if screenshare is null then send ws message
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SHARE_WEBRTC_MEDIA,
        sessionKey: this.props.sessionKey,
        media: mediaType.SCREEN
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  handleChatToggle = () => {
    let propsObject = {...this.props};
    let isChatPopoverVisible = !propsObject.isChatPopOver;
    this.openCloseChatPopUpHandler(isChatPopoverVisible);
  };

  handleSnapshotToggle = () => { };
  handleLocationToggle = (checkDownload, isLocationPermissionGranted = true) => {
    //isLocationPermissionGranted is set to false if user block location permission
    let isDownloaded = checkDownload || false;
    if (!this.state.locationPopOver && !isDownloaded && !this.checkParticipantsAvailable())
      return;
    //if (!this.checkKMSStatus()) return
    if (
      this.props.uniqueId === this.props.organiser &&
      this.state.locationPopOver === false &&
      !isDownloaded
    ) {
      var message = {
        data: {
          category: socketMessage.category.LOCATION,
          action: socketMessage.subCategories.LOCATION.START_LOCATION_SHARE,
          sessionKey: this.props.sessionKey
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      this.props.addLocationParticipantId(this.props.uniqueId);
    } else {
      if (this.state.locationPopOver === true) {
        var message = {
          data: {
            category: socketMessage.category.LOCATION,
            action: socketMessage.subCategories.LOCATION.STOP_LOCATION_SHARE,
            sessionKey: this.props.sessionKey,
            isDownloaded: isDownloaded,
            isLocationPermissionGranted: isLocationPermissionGranted
          }
        };
        this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
        this.props.stopLocationShare();
      }
      this.props.updateLocationPermissionReply(null);
      this.props.headerAction.setLocationPopup(false);
      this.setState({
        locationPopOver: false
      });
    }
  };
  getSingleParticipant = () => {
    return this.props.admittedParticipants.filter(
      participant => participant.role == USER_ROLES.USER
    );
  };

  handleSnapshot = () => {
    if(this.props.customerCoBrowseStarted) {
      toastr.error("SNAPSHOT_CANNOT_USE_WITH_CUSTOMER_CO_BROWSE")
      return
    }

    if (!isWebRTCPluginInstalled()) return;   // for self - check and show toast msg for plugin uninstalled
    this.props.headerAction.resetParticipantsPluginInfo();
    // If snapshot request is accepted by participant then don't send snapshot request again
    if (this.isSnapshotStarted()) {
      this.props.headerAction.updateHeaderIconClass(
        Modules.SNAPSHOT,
        "moduleIconActive cursorDisabled",
        " cursorDisabled"
      );
      return;
    }

    //if (!this.checkKMSStatus()) return;
    let participant = this.getSingleParticipant()[0];
    if (!this.checkParticipantsAvailable()) return;
    this.props.headerAction.resetSnapshotData();
    // restore canvas to original position when snapshot is started at Agent side
    // this.props.sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    if (this.props.admittedParticipants.length > 2) {
      this.props.headerAction.setShowSnapshot(true);
    } else {
      const { sessionKey } = this.props;
      const message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.SNAPSHOT_PERMISSION_REQUEST,
          sessionKey: sessionKey,
          snapshoteeId: participant.uniqueId,
          token: this.props.token
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      this.props.sessionActions.updateSnapshoteeId(participant.uniqueId);
      this.props.sessionActions.updateSnapshotPermissionReply(null);
      this.props.headerAction.setShowSnapshot(true);
    }
    this.props.headerAction.updateHeaderIconClass(
      Modules.SNAPSHOT,
      "moduleIconActive cursorDisabled",
      " cursorDisabled"
    );
    //this.props.headerAction.setShowSnapshot(true);
  }

  handleAppEmulationToggle = (isModuleClicked = false) => {
    if (
      isModuleClicked &&
      this.props.customerId &&
      this.props.modules.includes(Modules.APP_EMULATION)
    ) {
      if(!this.checkParticipantsAvailable()) {
        return;
      } 
      this.setState({ spinnerVisibility: true });
      appEmulationAction.getDeviceInfo(this.props.customerId).then(
        (response) => {
          //Added time out to hide background scroll bar when App-Emulation modal open
          this.setState(
            { appEmulationData: response.data, spinnerVisibility: false },
            () => {
              setTimeout(() => {
                this.setState({ showDeviceListModal: true });
              }, 100);
            }
          );
        },
        (error) => {
          console.log("error :", error);
          toastr.error(error);
          this.setState({
            spinnerVisibility: false,
            showDeviceListModal: false,
          });
        }
      );
    } else {
      this.setState({ showDeviceListModal: false });
    }
  }

  handleCoBrowseToggle = () => {
    if (this.props.showCoBrowse) return;
    if (!this.checkParticipantsAvailable()) return;
    coBrowseAction.showCoBrowse(!this.props.showCoBrowse);
    this.sendCoBrowseRequest(); //send request to all participant
    coBrowseAction.setCoBrowseParticipantStatus(this.props.uniqueId);
  }

  handleOkClick = () => {
    this.setState({
      isParticipantAvailable: false
    });
  };

  checkParticipantswAvalaiblePopup = () => {
    if (this.state.isParticipantAvailable) {
      return (
        <Popup
          show={this.state.isParticipantAvailable}
          isDoubleButton={false}
          handleOkClick={this.handleOkClick}
          message={getMessage("PARTICIPANT_NOT_AVAILABLE")}
        />
      );
    }
  };

  checkParticipantsAvailable = () => {
    if (this.props.admittedParticipants.length <= 1) {
      this.setState({
        isParticipantAvailable: true
      });
      return false;
    }
    return true;
  };

  openDrawer = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    }, () => {
      if (this.state.popoverOpen) {
        document.body.style.overflow = "hidden";
      } else {
        if (isIE)
          document.body.style.overflow = "visible";
        else
          document.body.style.overflow = "overlay";
      }
    });
  };

  handleYesClick = (isPluginInstalled = true) => {
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.WEBRTC_PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: true,
        media: this.props.webRtcPermissionRequest,
        isPluginInstalled
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.headerAction.setCallStatus(false);
    this.sendShareMediaRequest(this.props.webRtcPermissionRequest);
    // this.props.headerAction.setCallModalPermission(true); // after yes click show Audio Video box
    this.setState({ isButtonClicked: false })
    this.props.sessionActions.updateWebRtcPermissionReply(true);
  };

  /*
  updatePermissionLocally: If true, do not send webrtc permissionn reply message to agent.
  Used in case of audio device check. When user denies microphone permission in browser, audio call is not joined,
  and only webrtc media device permission denied toaster is to be shown to the agent. 
  */
  handleNoClick = (isPluginInstalled = true, updatePermissionLocally = false) => {
    if(!updatePermissionLocally) {
      var message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.WEBRTC_PERMISSION_REPLY,
          sessionKey: this.props.sessionKey,
          isGranted: false,
          media: this.props.webRtcPermissionRequest,
          isPluginInstalled
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
    // this.props.headerAction.setCallModalPermission(false);    
    this.props.sessionActions.updateWebRtcPermissionReply(false);
    this.setState({ isButtonClicked: false })
  };
  handleSnapshotYesClick = async () => {
    //disable operations once snapshot accepted
    document.getElementById('snapshotPermissionAccept').disabled = true;
    document.getElementById('snapshotPermissionReject').disabled = true;
    // for participant - check and show toast msg and send plugin status in snapshotPermissionReply
    let isWebRTCPluginInstalledFlag = isWebRTCPluginInstalled();
    if (!isWebRTCPluginInstalledFlag) {
      this.handleSnapshotNoClick(true, isWebRTCPluginInstalledFlag);
      return;
    }

    // restore canvas to original position when snapshot is started at User side
    // this.props.sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    await navigator.mediaDevices.enumerateDevices().then(devices => {
      let isVideoDevices = devices.filter(device => {
        if (device.kind === "videoinput") {
          return true;
        }
      });
      if (isVideoDevices.length > 0) {
        this.showSpinner();
        var message = {
          data: {
            category: socketMessage.category.WEBRTC,
            action: webRtcMessageId.SNAPSHOT_PERMISSION_REPLY,
            sessionKey: this.props.sessionKey,
            snapshoterId: this.props.snapshoterId,
            isGranted: true,
            isUserWebCamPresent: true
          }
        };
        this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);

        if (this.props.snapshotPermissionReply == false) {
          this.props.sessionActions.updateSnapshotPermissionReply(true);
        }
        this.hideSpinner();
      }
      else {
        // if there isnt device connected then reject snapshot request with flag isDeviceAttached = true
        this.handleSnapshotNoClick(false);//isDeviceAttached=false
        toastr.error("WEBCAM_NOT_CONNECTED")
      }
    });

    //this.props.sessionActions.updateSnapshotPermissionReply(true);
  };
  handleSnapshotNoClick = (isDeviceAttached = true, isPluginInstalled = true) => {
    //disable operations once snapshot rejected
    let snapshotPermissionAcceptElement = document.getElementById("snapshotPermissionAccept");
    let snapshotPermissionRejectElement = document.getElementById("snapshotPermissionReject");
    if (snapshotPermissionAcceptElement) {
      snapshotPermissionAcceptElement.disabled = true;
    }
    if (snapshotPermissionRejectElement) {
      snapshotPermissionRejectElement.disabled = true;
    }
   
    //  we check isDeviceAttached or not only if user accept snapshot request, otherwise isDeviceAttached =true
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SNAPSHOT_PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        snapshoterId: this.props.snapshoterId,
        isGranted: false,
        isUserWebCamPresent: isDeviceAttached,
        isPluginInstalled
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  handleAppEmulationYesClick = () => {
    var message = {
      data: {
        category: socketMessage.category.APP_EMULATION,
        action: webRtcMessageId.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: true
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.appEmulationAction.updateAppEmulationPermissionRequest(false);
  }
  handleAppEmulationNoClick = () => {
    var message = {
      data: {
        category: socketMessage.category.APP_EMULATION,
        action: webRtcMessageId.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: false
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.appEmulationAction.updateAppEmulationPermissionReply(false);
    this.props.appEmulationAction.updateAppEmulationPermissionRequest(false);
  }

  // called when user accepts cobrowse permission
  handleCoBrowseYesClick = () => {
    var message = {
      data: {
        category: socketMessage.category.CO_BROWSE,
        action: socketMessage.subCategories.CO_BROWSE.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: true
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    coBrowseAction.updateCoBrowsePermissionReply(true);
    coBrowseAction.updateCoBrowsePermissionRequest(false);
    coBrowseAction.showCoBrowse(true);
    // coBrowseAction.setCoBrowseParticipantStatus(this.props.uniqueId)
  }

  // called when user denies cobrowse permission
  handleCoBrowseNoClick = () => {
    var message = {
      data: {
        category: socketMessage.category.CO_BROWSE,
        action: socketMessage.subCategories.CO_BROWSE.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: false
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    coBrowseAction.updateCoBrowsePermissionReply(false);
    coBrowseAction.updateCoBrowsePermissionRequest(false);
  }

  // called only by presenter when cobrowse header icon is toggled
  sendCoBrowseRequest = (newParticipantId) => {
    if (this.props.isPresenter) {
      const message = {
        data: {
          category: socketMessage.category.CO_BROWSE,
          action: socketMessage.subCategories.CO_BROWSE.PERMISSION_REQUEST,
          uniqueId: this.props.uniqueId,
          sessionKey: this.props.sessionKey,
          newParticipantId
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
  }
  
  handleLocationYesClick = () => {
    var message = {
      data: {
        category: socketMessage.category.LOCATION,
        action: socketMessage.subCategories.LOCATION.SHARE_LOCATION_PERMISSION,
        sessionKey: this.props.sessionKey,
        isGranted: true
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.headerAction.setLocationPopup(false);
    this.setState({
      locationPopOver: true
    });
  };
  handleLocatioNoClick = () => {
    var message = {
      data: {
        category: socketMessage.category.LOCATION,
        action: socketMessage.subCategories.LOCATION.SHARE_LOCATION_PERMISSION,
        sessionKey: this.props.sessionKey,
        isGranted: false
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.headerAction.setLocationPopup(false);
  };

  getClassForTextLabel(item) {
    const label = this.props.language == LANGUAGE.EN.name
      ? (this.props.headerIcons[item].title === getMessage("SCREENSHARE") ||
        this.props.headerIcons[item].title === getMessage("APP_EMULATION"))
        ? "mb-0 enModuleLabel engLargeModuleLabelWidth pt-1"
        : this.props.headerIcons[item].title === getMessage("CO_BROWSE") ?
          "mb-0 enModuleLabel engCoBrowseModuleLabelWidth pt-1"
          : "mb-0 enModuleLabel engSmallModuleLabelWidth pt-1"
      : this.props.headerIcons[item].title == getMessage("SNAPSHOT")
        ? "mb-0 japModuleLabel japLargeModuleLabelWidth pt-1 font-weight-bold"
        : "mb-0 japModuleLabel japSmallModuleLabelWidth pt-1 font-weight-bold";
    return `${label} ${this.props.headerIcons[item].textLabelClass}`;
  };

  renderModules = (isMobileDevice = false) => {
    if (this.props.headerIcons.length !== 0) {
      if (this.props.sessionStatus !== SESSION_STATUS.ACTIVE) return;

      if (this.props.headerIcons.length <= 0) return;

      return Object.keys(this.props.headerIcons).map(item => {
        if (
          this.props.organiser !== this.props.uniqueId &&
          (item === Modules.LOCATION && item === Modules.APP_EMULATION)
        )
          return;
        if (isMobileDevice) {
          return (
            <Row
              onClick={() => {
                if (item !== Modules.SCREENSHARE ||
                  (item === Modules.SCREENSHARE && !isAndroidOrIos())) this.handleModuleClick(item);
              }}
              key={this.props.headerIcons[item].title}
              className={this.props.headerIcons[item].class + " pt-2 pb-2"}
            >
              <Col md="2" sm="2" xs="2">
                <img
                  src={this.props.headerIcons[item].logo}
                  className="add-img p-0"
                />
              </Col>
              <Col md="10" sm="10" xs="10"
                className={(this.props.userRole === USER_ROLES.USER
                  && !this.props.headerIcons[item].userAllowed || (item===Modules.CO_BROWSE && this.props.uniqueId !== this.props.presenter)
                  || (item === Modules.SCREENSHARE && isAndroidOrIos()))
                  ? "p-left text-left colorGrey"
                  : "p-left text-left cursorPointer"}>
                {_.capitalize(getMessage(this.props.headerIcons[item].title))}
              </Col>
            </Row>
          );
        }
        return (
          <div className="headerIconDiv"
            key={this.props.headerIcons[item].title + "_div"}
          >
            <span
              className={this.props.headerIcons[item].class}
              onClick={() => this.handleModuleClick(item)}
              key={this.props.headerIcons[item].title}
              id={item}
            >
              <img
                src={this.props.headerIcons[item].logo}
                className={
                  isModuleIconToBeDisabled(item, {
                    organiser: this.props.organiser,
                    uniqueId: this.props.uniqueId,
                    webRtcPermissionRequest: this.props.webRtcPermissionRequest,
                    snapshoteeId: this.props.snapshoteeId,
                    screenSharingParticipantId: this.props.screenSharingParticipantId,
                    userRole: this.props.userRole,
                    isPresenter: this.props.isPresenter,
                    showCoBrowse: this.props.showCoBrowse,
                    isSnapshotStarted: this.isSnapshotStarted,
                    cobrowseTypes: this.props.cobrowseType,
                    customerCoBrowseStarted: this.props.customerCoBrowseStarted
                  })
                    ? "" : "blinkImg"
                }
              />
            </span>
            <label
              className={this.getClassForTextLabel(item)}
              onClick={() => this.handleModuleClick(item)}
            >
              {getMessage(this.props.headerIcons[item].title)}
            </label>
          </div>
        );
      });
    }
  };

  showCustomerCoBrowsePermissionModal = () => {
    if (this.props.customerCoBrowsePermissionRequest && this.props.customerCoBrowsePermissionReply === null) {
      return (
        <Popup
          show={this.props.presenter !== this.props.uniqueId}
          isDoubleButton={true}
          handleYesClick={this.handleCustomerCoBrowseYesClick}
          handleNoClick={this.handleCustomerCoBrowseNoClick}
          message={getMessage("CUSTOMER_CO_BROWSE_PERMISSION")}
          isPermissionPopup={true}
        ></Popup>
      );
    }
  };

  handleCustomerCoBrowseYesClick = () => {
    var message = {
      data: {
        category: socketMessage.category.CUSTOMER_CO_BROWSE,
        action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: true
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    customerCoBrowseAction.updateCustomerCoBrowsePermissionReply(true);
    customerCoBrowseAction.showHideCustomerCoBrowse(true);
  }

  handleCustomerCoBrowseNoClick = () => {
    var message = {
      data: {
        category: socketMessage.category.CUSTOMER_CO_BROWSE,
        action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.PERMISSION_REPLY,
        sessionKey: this.props.sessionKey,
        isGranted: false
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    customerCoBrowseAction.updateCustomerCoBrowsePermissionReply(false);
  }

  showLocationPermissionModal = () => {
    if (
      !this.state.locationPopOver &&
      this.props.locationPermissionReply === null &&
      (this.props.isLocation || this.props.isLocationSharing)
    ) {
      return (
        <Popup
          show={this.props.organiser !== this.props.uniqueId} //this.props.userRole !== USER_ROLES.AGENT
          isDoubleButton={true}
          onClose={this.onClose}
          handleYesClick={this.handleLocationYesClick}
          handleNoClick={this.handleLocatioNoClick}
          message={getMessage("AGENT_LOCATION_PERMISSION")}
          isPermissionPopup={true}
        ></Popup>
      );
    } else if (
      !this.state.locationPopOver &&
      this.props.locationPermissionReply &&
      this.props.isLocationSharing
    ) {
      this.setState({
        locationPopOver: true
      });
    }
  };

  showSnapshotPermissionModal = () => {
    if (this.props.snapshoteeId !== this.props.uniqueId) {
      return;
    }
    if (this.props.organiser !== this.props.uniqueId &&
      this.props.snapshoteeId &&
      this.props.uniqueId &&
      this.props.snapshoteeId === this.props.uniqueId &&
      this.props.snapshotPermissionReply === null) {
      if (!isWebRTCSupported()) {
        this.handleSnapshotNoClick()
        return;
      }
    }

    return (
      <Popup 
        permissionAcceptBtnId={"snapshotPermissionAccept"}
        permissionRejectBtnId={"snapshotPermissionReject"}
        show={
          /**this.props.userRole !== USER_ROLES.AGENT */
          this.props.organiser !== this.props.uniqueId &&
          this.props.snapshoteeId &&
          this.props.uniqueId &&
          this.props.snapshoteeId === this.props.uniqueId &&
          this.props.snapshotPermissionReply === null
        }
        isDoubleButton={true}
        onClose={this.onClose}
        handleYesClick={this.handleSnapshotYesClick}
        handleNoClick={this.handleSnapshotNoClick}
        message={getMessage("AGENT_SNAPSHOT_PERMISSION")}
        isPermissionPopup={true}
      />
    );
  };

  sendMediaDevicePermissionDeniedMessage = (media) => {
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.MEDIA_DEVICE_PERMISSION_DENIED,
        sessionKey: this.props.sessionKey,
        media,
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  checkDevicesAreConnectOrNot = () => {
    this.setState({ isButtonClicked: true })
    if (isMobileOriPad()) {
      this.handleYesClick(true);
    } else {
      // for participant - check and show toast msg and send plugin status in webRtcPermissionReply (audio / video)
      let isWebRTCPluginInstalledFlag = isWebRTCPluginInstalled();
      if (!isWebRTCPluginInstalledFlag) {
        this.handleNoClick(isWebRTCPluginInstalledFlag);
        return;
      }

      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        let isVideoDevicesConnected = []
        let isAudioDevicesConnected = devices.filter(device => {
          if (device.kind === "audioinput") {
            return true;
          }
        });
        if (this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO) {
          isVideoDevicesConnected = devices.filter(device => {
            if (device.kind === "videoinput") {
              return true;
            }
          });
        }

        isAudioDevicesConnected = isAudioDevicesConnected.length > 0;
        isVideoDevicesConnected = this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO ?
          isVideoDevicesConnected.length > 0 : true

        if ((this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO && isAudioDevicesConnected) ||
          isVideoDevicesConnected && isAudioDevicesConnected) {

          if (this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO) {
            // call getUserMedia to check if camera is available / not busy
            // call getUserMedia because safari only gets default devices until after getUserMedia is called,
            // due to which stream is not displayed when we start call first time from a tab
            navigator.mediaDevices.getUserMedia({ video: true })  // getUserMedia will fail if camera is busy / unavailable
            .then(stream => {
              this.stopMediaTracksOfGivenStream(stream);  // stop streams retrieved as Video component will retrieve it
              this.handleYesClick(true);
            }).catch((error) => {
              /*
              Even though it's an audio-video call, We only ask for video stream (camera permission) at this point
              Therefore, even on failure, call handleYesClick here so AV Container mounts, 
              and asks for microphone permission from there (End user joins with either only camera or only microphone)  
              */
              console.error('getUserMedia error: ', error);
              this.handleYesClick(true);
            });
          } else {
            // call getUserMedia to check if audio device is available / not busy
            // call getUserMedia to fix WAAG-4944 because safari only gets default devices until after getUserMedia is called.
            navigator.mediaDevices.getUserMedia({ audio: true })  // getUserMedia will fail if audio is busy / unavailable
            .then(stream => {
              this.stopMediaTracksOfGivenStream(stream);  // stop streams retrieved as Video component will retrieve it
              // send share media request
              this.handleYesClick(isWebRTCPluginInstalled());
            })
            .catch((error) => {
              this.handleNoClick(isWebRTCPluginInstalled(), true);
              this.sendMediaDevicePermissionDeniedMessage(mediaType.AUDIO);
              // Display Audio Call window, even if there is an issue with the camera permissions,
              // so it will help user to listen to agent's voice.
              this.sendShareMediaRequest(this.props.webRtcPermissionRequest);

              let errorMessage = getUserMediaError(error, mediaType.AUDIO);
              if (errorMessage) {
                this.hideSpinner();
                toastr.error(errorMessage)
              }
            });          
          }
          //displaying message at user side if camera or mic not found
          if (!isVideoDevicesConnected) {
            toastr.error("WEBCAM_NOT_CONNECTED")
            this.sendMediaDevicePermissionDeniedMessage(mediaType.VIDEO);
          }
          if (!isAudioDevicesConnected) {
            toastr.error("MIC_NOT_CONNECTED")
            this.sendMediaDevicePermissionDeniedMessage(mediaType.AUDIO);
          }
        }
        else {
          //No any devices connected to make call or webRTC plugin not installed 
          toastr.error("DEVICE_NOT_FOUND");
          this.handleNoClick(isWebRTCPluginInstalled());
        }
      });
    }
  }

  showPermissionModal = () => {
    // If webRTC is not supported, reject the request
    if (this.props.webRtcPermissionRequest !== null &&
      this.props.webRtcPermissionReply === null &&
      this.props.organiser !== this.props.uniqueId ||
      (this.props.isSessionEnded &&
        this.props.organiser === this.props.uniqueId))
      if (!isWebRTCSupported()) {
        this.handleNoClick(true)
        return;
      }

    if (!this.state.isButtonClicked && this.props.webRtcPermissionRequest !== null &&
      this.props.webRtcPermissionReply === null &&
      this.props.organiser !== this.props.uniqueId ||
      (this.props.isSessionEnded &&
        this.props.organiser === this.props.uniqueId)) {
      return (
        <Popup
          show={true}
          isDoubleButton={this.props.isSessionEnded ? false : true}
          onClose={this.onClose}
          handleYesClick={this.checkDevicesAreConnectOrNot}
          handleNoClick={this.handleNoClick}
          message={
            this.props.isSessionEnded
              ? (this.props.organiser === this.props.uniqueId || this.props.isOrganizer)
                ? getMessage("AGENT_HAS_LOG_OUT")
                : getMessage("AGENT_HAS_ENDED_THE_SESSION")
              : this.props.webRtcPermissionRequest === mediaType.AUDIO
                ? getMessage("AGENT_PERMISSION_AUDIO")
                : getMessage("AGENT_PERMISSION_VIDEO")
          }
          isPresenter={this.props.isPresenter}
          organiser={this.props.organiser}
          uniqueId={this.props.uniqueId}
          isOrganizer={this.props.isOrganizer}
          cleanUp={() => this.props.setToken(null)}
          isPermissionPopup={true}
          isAudioVideoPermissionPopUp={!this.props.isSessionEnded &&
            (this.props.webRtcPermissionRequest === mediaType.AUDIO
              || this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO)
            ? true
            : false}
        />
      );
    }
  };

  showAppEmulationPermissionPopUp = () => {
    return (
      <Popup
        show={
          this.props.appEmulationPermission && !this.props.appEmulationPermisssionReply && this.props.userRole === USER_ROLES.USER
        }
        isDoubleButton={this.props.isSessionEnded ? false : true}
        handleYesClick={this.handleAppEmulationYesClick}
        handleNoClick={this.handleAppEmulationNoClick}
        message={getMessage("AGENT_PERMISSION_APP_EMULATION")}
        isPresenter={this.props.isPresenter}
        isAppEmulation={true}
        isPermissionPopup={true}
      />
    );
  }

  showCoBrowsePermissionPopUp = () => {
    return (
      <Popup
        show={
          !this.props.isPresenter && this.props.coBrowsePermission && !this.props.coBrowsePermisssionReply
        }
        isDoubleButton={true}
        handleYesClick={this.handleCoBrowseYesClick}
        handleNoClick={this.handleCoBrowseNoClick}
        message={getMessage("PRESENTER_PERMISSION_CO_BROWSE")}
        isPresenter={this.props.isPresenter}
        isAppEmulation={true}
        isPermissionPopup={true}
      />
    );
  }

  addInviteeHandle = () => {
    this.setState({ addInvitee: !this.state.addInvitee });
  };
  addInvitee = () => {
    return (
      <AddInviteeContainer
        show={this.state.addInvitee}
        addInviteeHandle={this.addInviteeHandle}
        sessionLink={this.state.sessionLink}
      />
    );
  };

  showWaitingArea = () => {
    this.setState({ showWaitingArea: !this.state.showWaitingArea })
  }

  showMakePresenter = (participantsId, presenterName) => {
    this.setState({
      makePresenterPopoverOpen:
        participantsId === this.state.makePresenterPopoverOpen
          ? ""
          : participantsId,
      presenterName: presenterName
    });
  };

  makePresenter = participant => {
    const { userName, uniqueId } = participant;
    this.showMakePresenter(this.state.makePresenterPopoverOpen, userName);
    var message = {
      data: {
        category: socketMessage.category.NOTIFICATION,
        action: socketMessage.subCategories.NOTIFICATION.MAKE_PRESENTER,
        sessionKey: this.props.sessionKey,
        newPresenterId: uniqueId
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  showMoreParticipants = () => {
    this.setState({
      moreParticipantsPopOver: !this.state.moreParticipantsPopOver
    })
  }

  onOpenCloseEndSessionConfirmationModal = (modalState) => {
    if (!modalState) {
      this.setState({
        showEndSessionConfirmationModal: modalState
      })
      return;
    }
    this.setState({
      showEndSessionConfirmationModal: modalState
    })
  }

  onOpenCloseLeaveSessionConfirmationModal = (modalState) => {
    // will not check n/w offline for user and will allow user to leave any time
    this.setState({
      showLeaveSessionConfirmationModal: modalState
    })
  }

  admitOrRejectUserInSession = (isAdmitted, uniqueId, userName) => {
    headerAction.setParticipantAdmittedOrRejected({
      uniqueId: uniqueId,
      isAdmitted: isAdmitted,
      userName
    });

    let message = {
      data: {
        category: socketMessage.category.SESSION,
        action:
        socketMessage.subCategories.SESSION.JOIN_SESSION_REPLY,
        isAdmitted: isAdmitted,
        uniqueId: uniqueId,
        sessionKey: this.props.sessionKey
      },
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  handleAllowEdit = (isEditAllowed, participantId) => {
    const message = {
      data: {
        category: socketMessage.category.SESSION,
        action: socketMessage.subCategories.SESSION.ALLOW_EDIT_ACCESS,
        sessionKey: this.props.sessionKey,
        allowEditAccess: isEditAllowed,
        participantId: participantId
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  /**
   * This function is used to resolve following issue when app emulation session is started.
   * Issue - An agent cannot admit new participants unless app emulation session(app emulation popup) is closed.
   * If the value is true, it will show waiting area on app emulation popup when new participant arrives
   * so that agent can admit new participants in the session.
   */
  setAppEmulationStatus = (value) => {
    this.setState({
      isAppEmulationStarted: value
    });
  }

  getHeaderLogo = () => {
    if (this.props.logo.loading) {
      return getMessage("LOADING");
    } else if (this.props.logo.url) {
      return <img className="headerLogo" src={this.props.logo.url} alt="header logo" />
    }
  }

  render() {
    let waitingParticipants = this.props.allParticipants.filter(participant =>
      participant.admissionStatus == USER_ADMISSION_STATUS.PENDING
      && (participant.status === "ACTIVE" || participant.status === "WAITING")
      && participant.uniqueId);
    return (

      <div className="header" id="commonHeader">
        {/* TODO: find some other solution to cache font family
        */}
        {Object.keys(FONT_FAMILY).map(fontFamily => {
          return <div className={FONT_FAMILY[fontFamily]} key={fontFamily}>
            {getMessage(fontFamily)}
          </div>
        })}
        {this.addInvitee()}
        {this.showPermissionModal()}
        {this.showSnapshotPermissionModal()}
        {this.showLocationPermissionModal()}
        {this.checkParticipantswAvalaiblePopup()}
        {this.showAppEmulationPermissionPopUp()}
        {this.showCoBrowsePermissionPopUp()}
        {this.showCustomerCoBrowsePermissionModal()}
        {this.renderSprinner()}
        <Row className="noMargin">
          <Col
            xl="3"
            lg="3"
            sm="12"
            md="12"
            xs="12"
            className="headerLogoContainer displayFlex noPadding pl-3 pr-xl-2"
          >
            {/* <a target="_new" href={sessionLink}> */}
            <div className="headerLogoDiv">
              {this.getHeaderLogo()}
            </div>
            {/* </a> */}
            <div className="sessionTitle w-100">
              <div className="title noPadding col-md-12 col-lg-11 col-9" id="sessionTitle">
                {this.props.sessionTitle}
                <CustomTooltip
                  tooltipText={this.props.sessionTitle}
                  tooltipId="sessionTitle"
                />
              </div>

              <span className="buttonDisplay">
                {this.props.organiser === this.props.uniqueId && this.props.loggingLevel === "ADVANCED" && (
                  <span className="ml-0" id="save">
                    <img
                      src={this.state.isSaveEnabled ? ic_save_icon : ic_save_disabled}
                      className={`button saveButton showSave ${this.state.isSaveEnabled ? "cursorPointer" : "pointerEventsNone"}`}
                      onClick={this.state.isSaveEnabled ? this.props.saveSessionData : () => { }}
                      title={getMessage("SAVE")}
                    />
                    <CustomTooltip
                      tooltipText={getMessage("SAVE")}
                      tooltipId="save"
                    />
                  </span>
                )}
                <span>
                  <img
                    src={ic_participants}
                    className="saveButton showSave participantsButton"
                    onClick={this.showWaitingArea}
                    title={getMessage("PARTICIPANTS")}
                    id="participants"
                  />
                  <CustomTooltip
                    tooltipText={getMessage("PARTICIPANTS")}
                    tooltipId="participants"
                  />
                  {this.props.organiser === this.props.uniqueId
                    && waitingParticipants.length > 0
                    && (<span className="badge showBadge">{waitingParticipants.length}</span>)}
                </span>
                <span className="dots" id="Popover1" onClick={this.openDrawer}></span>
              </span>            
            </div>
          </Col>
          <Col
            className={
              this.state.popoverOpen
                ? "displayInline overlaySideBar"
                : "displayNone"
            }
          >
            {this.state.popoverOpen && (<Participants
              isSnapshot={false}
              mobileView={true}
              popoverOpen={this.state.popoverOpen}
              openDrawer={this.openDrawer}
              userRole={this.props.userRole}
              handleLeaveSession={this.props.handleLeaveSession}
              handleEndSession={this.props.handleEndSession}
              addInviteeHandle={this.addInviteeHandle}
              makePresenter={this.makePresenter}
              presenter={this.props.presenter}
              isAgent={this.props.organiser === this.props.uniqueId} //this.props.userRole === USER_ROLES.AGENT}
              handleModuleClick={this.handleModuleClick}
              headerIcons={this.props.headerIcons}
              renderModules={this.renderModules}
              handleCancelClick={this.handleCancelClick}
              organiser={this.props.organiser}
              uniqueId={this.props.uniqueId}
              loggingLevel={this.props.loggingLevel}
              saveSessionData={this.props.saveSessionData}
              canvasType={this.props.activeCanvas.canvasType}
              onOpenCloseEndSessionConfirmationModal={this.onOpenCloseEndSessionConfirmationModal}
              onOpenCloseLeaveSessionConfirmationModal={this.onOpenCloseLeaveSessionConfirmationModal}
            />)}
          </Col>
          <Col
            xl="6"
            lg="7"
            md="12"
            xs="12"
            className="parentParticipants displayFlex justifyContentFlexCenter flexDirectionColumn align-items-center px-lg-0"
          >
            {this.props.headerIcons && (
              <Row className="modules display-webkit-box">{this.renderModules()}</Row>
            )}
          </Col>
          {/* this will be hidden in case of mobile view */}

          <Col xl="3" lg="2" md="12" xs="12" className="parentParticipants px-lg-0 ml-lg-n2 ml-xl-n3">
            {
              <Row className="participants floatRight alignItemsCenter mx-0">
                {this.props.organiser === this.props.uniqueId &&
                  this.props.loggingLevel === "ADVANCED" && (
                  <span className="ml-0 pt-0 pt-lg-1 pl-lg-1 addButton">
                    <img
                      src={this.state.isSaveEnabled ? ic_save_icon : ic_save_disabled}
                      className={`button ${this.state.isSaveEnabled ? "cursorPointer" : "pointerEventsNone"}`}
                      onClick={this.state.isSaveEnabled ? this.props.saveSessionData : () => { }
                      }
                      id="save"
                    />
                    <CustomTooltip
                      tooltipText={getMessage("SAVE")}
                      tooltipId="save"
                    />
                  </span>
                )}
                <span className="ml-0 pt-0 pt-lg-1 participantsButtonMargin" > 
                  <span className="addButton cursorPointer" >
                    <div>
                      <img
                        src={ic_participants}
                        className="button"
                        onClick={this.showWaitingArea} 
                        id="participants"
                      />
                      {this.props.organiser === this.props.uniqueId
                          && waitingParticipants.length > 0
                          && (<span className="badge">{waitingParticipants.length}</span>)}
                      <CustomTooltip
                        tooltipText={getMessage("PARTICIPANTS")}
                        tooltipId="participants"
                      />
                    </div>                 
                  </span>
                </span>
                <WaitingArea
                  show={this.state.showWaitingArea}
                  isOrganiser={this.props.organiser === this.props.uniqueId}
                  showWaitingArea={this.showWaitingArea}
                  addInviteeHandle={this.addInviteeHandle}
                  allParticipants={this.state.sortedParticipants}
                  showMakePresenter={this.showMakePresenter}
                  makePresenterPopoverOpen={this.state.makePresenterPopoverOpen}
                  presenter={this.props.presenter}
                  makePresenter={this.makePresenter}
                  isAgent={this.props.organiser === this.props.uniqueId}
                  organiser={this.props.organiser}
                  uniqueId={this.props.uniqueId}
                  canvasType={this.props.activeCanvas.canvasType}
                  showMoreParticipants={this.showMoreParticipants}
                  moreParticipantsPopOver={this.state.moreParticipantsPopOver}
                  sessionLink={this.state.sessionLink}
                  sessionKey={this.props.sessionKey}
                  language={this.props.language}
                  admitOrRejectUserInSession={this.admitOrRejectUserInSession}
                  handleAllowEdit={this.handleAllowEdit}
                  admittedParticipants={this.props.admittedParticipants}
                  evictAction={this.props.evictAction}
                />
                {this.props.organiser === this.props.uniqueId ? (
                  <Button
                    className="endSession"
                    onClick={() => {
                      this.onOpenCloseEndSessionConfirmationModal(true)
                    }}
                  >
                    {getMessage("END_SESSION")}
                  </Button>
                ) :
                  (
                    this.props.organiser !== "" &&
                    this.props.uniqueId !== null && (
                      <Button
                        color="danger"
                        className="endSession"
                        onClick={() => {
                          this.onOpenCloseLeaveSessionConfirmationModal(true)
                        }}
                      >
                        {getMessage("LEAVE_SESSION")}
                      </Button>
                    )
                  )}
              </Row>
            }
          </Col>
          {this.props.modules?.indexOf(Modules.CHAT) !== -1 && this.props.isChatPopOver && (
            <ChatContainer
              chatPopOver={this.props.isChatPopOver}
              handleClose={this.handleChatToggle}
              admittedParticipants={this.props.admittedParticipants}
              organiser={this.props.organiser}
            />
          )}
          {this.state.locationPopOver && (
            <LocationContainer
              show={this.state.locationPopOver}
              handleLocationToggle={this.handleLocationToggle}
              showSpinner={this.showSpinner}
              hideSpinner={this.hideSpinner}
              handleLocatioNoClick={this.handleLocatioNoClick}
            />
          )}
        </Row>
        {
          this.props.modules && this.props.modules.includes("APP_EMULATION") ?
            <AppEmulationContainer
              show={this.state.showDeviceListModal}
              appEmulationData={this.state.appEmulationData}
              appEmulationPermisssionReply={this.props.appEmulationPermisssionReply}
              toggle={this.handleAppEmulationToggle}
              closeModal={() => 
                this.setState({ showDeviceListModal: false })
              }
              checkParticipantsAvailable={this.checkParticipantsAvailable}
              modules={this.props.modules}
              setAppEmulationStatus={this.setAppEmulationStatus} /> :
            null
        }

        {
          this.state.showEndSessionConfirmationModal &&

          <EndSessionConfirmationModal
            show={true}
            isDoubleButton={true}
            handleYesClick={() => {
              this.onOpenCloseEndSessionConfirmationModal(false);
              this.handleEndSession();
            }}
            handleNoClick={() => { this.onOpenCloseEndSessionConfirmationModal(false) }}
            message={
              this.props.loggingLevel !== "ADVANCED"
                ? getMessage("END_SESSION_CONFIRMATION_TEXT")
                : getMessage("END_SESSION_CONFIRMATION_TEXT_ADVANCED")
            }
          />
        }
        {
          this.state.showLeaveSessionConfirmationModal &&

          <EndSessionConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={() => { this.onOpenCloseLeaveSessionConfirmationModal(false) }}
            handleYesClick={() => {
              this.onOpenCloseLeaveSessionConfirmationModal(false);
              this.props.handleLeaveSession();
            }}
            message={getMessage("LEAVE_SESSION_CONFIRMATION_TEXT")}
          />
        }
      </div>
    );
  }
}

export default withRouter(Header);
