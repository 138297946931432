import moment from "moment";
import { LANGUAGE } from "UTILS/Constants";

const jap_date_format = "MMM DD YYYY";
const en_date_format = "DD MMM YYYY";

export function getCurrentTime() {
  let today = new Date();
  let time = "10" + ":" + today.getMinutes() + ":" + today.getSeconds();
  return time;
}

export function getTomorrowDate(){
  return moment().startOf('day').add(1,'days');
}

export function getCurrentDate() {
  let currentDate = new Date()
  currentDate.setDate(new Date().getDate()) ;
  return currentDate;
}

export const getFormatedDate = (date) => {
  if(!(date instanceof Date)){
    return false
  }
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = date.getFullYear();

  let formattedDate = yyyy + "-" + mm + "-" + dd;
  return formattedDate;
}

export function formatDateByLocale(dateTimeStr, locale) {
  let date = moment(dateTimeStr);
  switch(locale) {
    case LANGUAGE.JA.name:
      return date.format(jap_date_format);
    default:
      return date.format(en_date_format);
  }
}

export function formatUTCDateByLocale(dateTimeStr, locale) {
  let date = moment.utc(dateTimeStr);
  switch(locale) {
    case LANGUAGE.JA.name:
      return date.format(jap_date_format);
    default:
      return date.format(en_date_format);
  }
}

export function getTimeInLocaleTimezone(dateTimeStr) {
  return moment(dateTimeStr).format('LT');
}

export function getTimeInUTCTimezone(dateTimeStr) {
  return moment.utc(dateTimeStr).format('LT');
}

export function convertMinutesToDDhhmm(minutes){
  let h = Math.floor(minutes / 60);
  let d = Math.floor(h / 24);
  h = h - d * 24
  let m = Math.floor(minutes % 60)

  let ddhhmm = ""
  if(d >= 1)
    ddhhmm += d + "d "
  if(h >= 1)
    ddhhmm += h + "h "
  if(m >= 1)
    ddhhmm += m + "m"

  return(ddhhmm === "" ? " 0 " : ddhhmm);
}

export function getCurrentDateTimeInLocalTimezone(locale) {
  let date = new Date(); 
  let formattedDate = formatDateByLocale(date.getTime(), locale);
  return formattedDate  + " " + getTimeInLocaleTimezone(date.getTime());
}

export function getElapseTimeInHhMm(fromTime){
  var endTime = moment.utc(new Date());
  var fromTime = moment.utc(fromTime);
  var duration = moment.duration((endTime).diff(fromTime));
  let durationInMinutes = parseInt(duration.asMinutes());
  let hh = parseInt((durationInMinutes / 60));
  let mm = parseInt((durationInMinutes % 60));
  hh = ("0" + hh).slice(-2);
  mm = ("0" + mm).slice(-2);
  return hh + ":" + mm;
}

//convert timestamp to human readable format
export const convertMillisecToHHmm = (millisec) => {

  var minutes = (millisec / (1000 * 60));

  var hours = (millisec / (1000 * 60 * 60));

  if (minutes < 60) {
    return minutes + " min";
  } else if (hours < 24) {
    return hours + " hour";
  }
}

export function ConvertMinutes(num){
  let h = Math.floor(num/60);
  let d = Math.floor(h/24);
  h = h - d * 24
  let m = Math.floor(num%60)

  let timeString = ""
  if(d >= 1)
    timeString = timeString + d + "d "
  if(h >= 1)
    timeString = " "+timeString+ h + "h "
  if(m >= 1)
    timeString = timeString+ m + "m"
    
  if(timeString === "")
    timeString = " -- "
  return(timeString)
}

//convert 12 hour time to 24 hour format
export const get24hourTime = (time) => {
  return moment(time, ["h:mm A"]).format("HH:mm:ss");
}

// It is used to check if date is valid e.g. if year contains 5 digits then it should consider date as an invalid date
export function isValidDate(date) {
  return moment(date, "YYYY-MM-DD", true).isValid();
}

export function getTimeInMillisec() {
  return moment().format("YYYY-MM-DD HH:mm:ss:SSS");
}