import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";
import { base64ImageToBlob, convertUrlToBase64 } from "UTILS/Utility";
import logo from "ASSETS/Images/img_header_logo.png";

export class OrganisationAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getAllCustomersAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.CUSTOMER
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addCustomerWithAdminAction(customerWithAdminData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.CUSTOMER_WITH_ADMIN,
        requestBody: customerWithAdminData
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  saveCustomerAction(customerData, customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: `${API_URL.CUSTOMER}/${customerId}`,
        requestBody: customerData
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteCustomerAction(customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url: `${API_URL.CUSTOMER}/${customerId}`,
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getServerStatusAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.KMS_STATUS,
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  uploadOrgLogoStatusAction(formData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.USER_UPLOAD_FILE,
        requestBody: formData
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  userStatisticsAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.USER_STATISTICS
      }).then(
        response => {
          resolve(response);
          this.dispatch({
            type: ACTIONS.SET_SESSIONS_CAPACITY,
            sessionsCapacity: {
              systemMaxCapacity: response.data.data.systemMaxCapacity,
              usedCapacity: response.data.data.usedCapacity
            }
          })
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getMSClusterStatistcsAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.MSCLUSTER_STATISTICS
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  async getTurnClusterStatisticsAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.TURNCLUSTER_STATISTICS
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  retireMsClusterInstanceAction(instanceIds) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: API_URL.MSCLUSTER_RETIREINSTANCE,
        requestBody: {
          "instanceIds": instanceIds
        }
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  saveCurrentActiveTab(activeTab) {
    this.dispatch({
      type: ACTIONS.ORGANISATION_ACTION.SET_ACTIVE_TAB,
      value: activeTab
    });
  }

  getCustomerStatisticsAction(customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.CUSTOMER}/${customerId}/statistics`
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCustomersAction(customerId) {
    return new Promise((resolve, reject) => {

      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.CUSTOMER}/${customerId}`
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SET_MODULES,
            modules: response.data.data.modules
          });
          this.dispatch({
            type: ACTIONS.SET_ALLOWED_DOWNLOAD,
            subModules: response.data.data.subModules
          });
          this.setCustomerData(response.data.data);
          this.dispatch({
            type: ACTIONS.SET_PASSCODE_STATUS,
            isPasscodeEnabled: response.data.data.is_passcode_enabled
          })
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            showLogoOnCanvasStatus: response.data.data.showLogoOnCanvas
          });
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            allowAnonymousParticipants: response.data.data.allowAnonymousUsers
          })
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            showChatPopup: response.data.data.showChatPopup,
          })
          // TODO: Need to remove is_snapshot_scan_code_enabled from custom settings as it does not belong to admin custom settings
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            isSnapshotScanCodeEnabled: response.data.data.is_snapshot_scan_code_enabled
          })
          this.dispatch({
            type: ACTIONS.APP_EMULATION.SET_APP_EMULATION_SETTINGS,
            showDeviceInfo: response.data.data.appEmulationSettings?.showDeviceInfo,
          });
          this.setLogoUrl(response.data.data.logo);
          if (response.data.data.cobrowseSettings) {
            this.dispatch({
              type: ACTIONS.CO_BROWSE.SET_CO_BROWSE_DETAILS,
              cobrowseSettings: response.data.data.cobrowseSettings
            });
          }
          this.dispatch({
            type: ACTIONS.CHAT.SET_CHAT_GPT_VALUE,
            isChatGPTEnabled: response.data.data.customerSettings?.chatgptEnabled,
          });
          this.dispatch({
            type: ACTIONS.ALLOW_UPLOAD,
            allowUpload: response.data.data.customerSettings?.allowUpload,
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setCustomerData = (customerData) => {
    this.dispatch({
      type: ACTIONS.SET_CUSTOMER_DATA,
      customerData
    })
  }

  updateUrl(logoUrl, customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: `${API_URL.UPDATE_URL}/${customerId}`,
        requestBody: { logoUrl: logoUrl }
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  //to get available storage options on settings click 
  getCustomerStorageDetails(customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.CUSTOMER_STORAGE}/${customerId}`,
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  //to verify storage on adding new
  verifyStorage(storageElement) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: `${API_URL.VERIFY_STORAGE}`,
        requestBody: storageElement
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  //updates settings of customer/admin
  updateCustomerSettings(customerId, newSettings) {
    return this.callApi({
      httpMethod: HTTP_METHODS.PUT,
      url: `${API_URL.CUSTOMER_SETTINGS}/${customerId}`,
      requestBody: newSettings,
    })
  }

  setLogoUrl(url) {
    const logoData = { loading: false, url: null };
    if (!url) {
      logoData.url = logo;
      this.dispatch({
        type: ACTIONS.SET_LOGO,
        logoData
      });
      return
    }

    convertUrlToBase64(url).then(base64Image => {
      const blobData = base64ImageToBlob(base64Image);
      const newURL = window.URL.createObjectURL(blobData);
      logoData.url = newURL;
      this.dispatch({
        type: ACTIONS.SET_LOGO,
        logoData
      });
    }).catch((error) => {
      this.dispatch({
        type: ACTIONS.SET_LOGO,
        logoData
      });
    })
  }

  resetLogoUrl() {
    this.dispatch({
      type: ACTIONS.RESET_LOGO
    });
  };

  getCustomerFromQRCode(qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.GET_CUSTOMER}/${qrId}`
      }).then(
        response => {
          resolve(response);
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            allowAnonymousParticipants: response.data.data.allowAnonymousUsers,
          })
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setOrganisationAction(organization) {
    this.dispatch({
      type: ACTIONS.ORGANISATION_ACTION.SET_ORGANIZATION_DATA,
      organization: organization
    })
  }
}
