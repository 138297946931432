import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import validator from "validator";
import { getMessage } from "CONFIG/i18n";
import { validateMobileNumber, validateInvitees } from "../../Utils/regexValidations";
import { separatePhoneNumberAndEmails, getPhoneNumberWithCountryCode, getValidInviteesInput } from "UTILS/Utility";
import _ from "lodash";

import "./AddInvitee.less";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
import { isEmailValid } from "UTILS/Utility";
import { KEY_CODE_ENTER } from "UTILS/Constants";

class AddInvitee extends Component {

  constructor(props) {
    super(props);
    this.isIE = /*@cc_on!@*/false || !!document.documentMode;
    this.inviteeInputRef = React.createRef();
  }
  state = {
    invitees: "",
    errorMessage: ""
  };
  toggle = () => {
    this.props.addInviteeManualHandle();
  };
  isValidEmailsOrPhone = (name, array) => {
    for (let i = 0; i < array.length; i++) {
      if (name === "email") {
        if(!isEmailValid(array[i].email))
          return false;
      } else if (!validateMobileNumber(array[i].phone)) {
        return false;      
      }
    }
    return true;
  };

  getFilteredArrayOfEmailOrPhoneNumbers = value => {
    return value.split(/[,;]/g).filter(el => el !== "");
  };
  createInviteeObjectsArray = (value, array) => {
    let result = [];
    for (let i = 0; i < array.length; i++) {
      if (value === "phone") {
        array[i].phone = getPhoneNumberWithCountryCode(array[i].phone);
      } 
      result.push(array[i]);
    }
    return result;
  };
  getInviteesArray = (emails, numbers) => {
    let emailsArray = this.createInviteeObjectsArray("email", emails);
    let NumbersArray = this.createInviteeObjectsArray("phone", numbers);
    return [...emailsArray, ...NumbersArray];
  };
  handleSendClick = () => {
    if (this.state.invitees) {
      let arrayOfInvitees = this.getFilteredArrayOfEmailOrPhoneNumbers(this.state.invitees)
      let { arrayOfEmails,arrayOfPhone } = separatePhoneNumberAndEmails(arrayOfInvitees);
      arrayOfEmails = _.uniqBy(arrayOfEmails, 'email');
      arrayOfPhone = _.uniqBy(arrayOfPhone, 'phone');
      let isValidEmails = this.isValidEmailsOrPhone("email", arrayOfEmails);
      let isValidNumbers = this.isValidEmailsOrPhone("phone", arrayOfPhone);

      if (arrayOfEmails.length !== 0 && !isValidEmails) {
        this.setState({
          errorMessage: getMessage("INVALID_EMAIL_PHONE")
        });
        return;
      }

      if (arrayOfPhone.length !== 0 && !isValidNumbers) {
        this.setState({
          errorMessage: getMessage("INVALID_EMAIL_PHONE")
        });
        return;
      }
      let invitees = this.getInviteesArray(arrayOfEmails, arrayOfPhone);
      this.props.handleSendClick(invitees, true);
      this.setState({
        invitees: ""
      });
    } else {
      this.setState({
        errorMessage: getMessage("VALID_EMAIL_PHONE")
      });
    }
  };
  handleInputChange = name => event => {
    /*
    * restrict user to enter spaces 
    * restrict user to enter more than 1 seperator 
    * replace multiple , or ,; with single ,
    * replace multiple ; or ;, with single ;
    */
    if(validateInvitees(event.target.value)) {
      this.setState({
        invitees: getValidInviteesInput(event.target.value),
        cursorPositionStart: event.target.selectionStart-1,
        cursorPositionEnd: event.target.selectionEnd-1,
        errorMessage: ""
      },()=>{
        // set the cursor of input to the previous position 
        this.inviteeInputRef.current.selectionStart = this.state.cursorPositionStart;
        this.inviteeInputRef.current.selectionEnd = this.state.cursorPositionEnd;
      });
    } else {
      this.setState({ 
        invitees: event.target.value,
        errorMessage: ""
      });
    }
  };
  
  clearState=()=>{
    this.setState({
      invitees: "",
      errorMessage: ""
    })
  }

  //Handled proceed on enter key press
  enterPressed = event => {
    var code = event.keyCode || event.which;
    //console.log('keyPress: ', event, code, event.target.value.trim());
    if (code === KEY_CODE_ENTER) {
      event.preventDefault();
      this.handleSendClick();
    }
  };

  setFocusForIE = () => {
    this.isIE && setTimeout(() => {
      document.getElementById("addButton").focus();
    }, 300);
  }
  
  detectEsc = (event) => {
    var kDown = event.keyCode || event.which;
    if (kDown === 27) {
      event.preventDefault();
      this.toggle();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="modal-md modalContainer modal-wrapper"
        xs="8"
        onOpened={this.setFocusForIE}
        onKeyDown={this.detectEsc}
        onClosed={this.clearState}
        autoFocus={false}
      >
        <ModalHeader toggle={this.toggle} className="popupHeader addMemberHeader modal-title-center">
          {getMessage("MANUAL_ENTRY")}
        </ModalHeader>
        <ModalBody className="popupBody">
          <div className="AddInvitee">
            <section className="bgColor ">
              <Container className="noPadding">
                <Row>
                  <Col
                    lg="12"
                    md="12"
                    xs="12"
                    className="pr-lg-0"
                    className="inputFormAddInvitee"
                  >
                    <div className="inputForm">
                      <Col>
                        <Form>
                          <FormGroup>
                            <Input
                              type="text"
                              innerRef={this.inviteeInputRef}
                              maxLength="100"
                              className="inputFormControl"
                              onChange={this.handleInputChange()}
                              value={this.state.invitees}
                              placeholder={getMessage("INVITE_INPUT_PLACEHOLDER")}
                              onKeyPress={this.enterPressed}
                              autoFocus={!this.isIE}
                            />
                          </FormGroup>
                        </Form>
                      </Col>
                      <Col className="addBtn text-center justify-content-center">
                        <Button
                          className="btnAdd  w-25"
                          onClick={this.handleSendClick}
                          id="addButton"
                        >
                          {getMessage("SEND")}
                        </Button>
                      </Col>
                      <Col className="errors text-center">
                        {this.state.errorMessage}
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default modalScrollBar(AddInvitee);
