import _ from "lodash";
import { ACTIONS, CHAT} from "UTILS/Constants";

const initialState = {
  messages: [],
  isMessageArrived: false,
  messagesRead: false,
  isChatPopOver: null,
  selectedFontSize: CHAT.FONT_SIZE.MEDIUM
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CHAT.SET_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };
      break;
    case ACTIONS.CHAT.ADD_MESSAGE:
      let messages = _.cloneDeep(state.messages);
      messages.push(action.messageDetails);
      return {
        ...state,
        messages: messages
      };
      break;
    case ACTIONS.CHAT.DISPLAY_MESSAGE:
      return {
        ...state,
        isMessageArrived: action.messageDisplay
      };
      break;
    case ACTIONS.CHAT.SET_MESSAGES_READ:
      return {
        ...state,
        messagesRead: action.value
      };
    case ACTIONS.CHAT.SET_CHAT_POP_OVER_STATE:
      return{
        ...state,
        isChatPopOver:action.value
      }
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return {
        ...state,
        isMessageArrived: initialState.isMessageArrived,
        messagesRead: initialState.messagesRead,
        messages: initialState.messages
      }
    case ACTIONS.LOGOUT_USER:
    case ACTIONS.SET_END_SESSION_FLAG:
      return initialState;

    case ACTIONS.CHAT.SET_CHAT_FONT_SIZE:
      return {
        ...state,
        selectedFontSize: action.value
      };

    default:
      return state;
  }
}
