import React from "react";
import { ERROR, TIMEOUT } from "UTILS/Constants";
import { getModulesNotAllowedErrorMessage } from "UTILS/Utility";
import { getMessage } from "CONFIG/i18n";
import { ToastContainer, toast, Slide } from 'react-toastify';
import _ from "lodash";

import infoIcon from "ASSETS/Images/ic_info.svg"
import errorIcon from "ASSETS/Images/ic_error_toastr.svg"
import successIcon from "ASSETS/Images/ic_success.svg"

import 'react-toastify/dist/ReactToastify.css';

function updateToastOptions(toastOptions = {}, code) {
  let updatedToastOptions = {
    ...toastOptions,
    toastId: toastOptions.toastId ? toastOptions.toastId : code,
    autoClose: toastOptions?.autoClose ? toastOptions.autoClose : TIMEOUT.SHORT
  }
  return updatedToastOptions;
}

class ShowTostr extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('offline', () => {
      toast.dismiss()
      this.constructor.error(ERROR.NETWORK_ERROR);
    });

    window.addEventListener('online', () => {
      toast.dismiss()
      this.constructor.success("YOU_ARE_ONLINE", { timeOut: TIMEOUT.SHORT });
    });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  /**
   * This function shows info toast
   * 
   * @param {string} messageCode 
   * @param {array} placeholders [{ key: "", value: "" }]
   * @param {object} toastOptions 
   * @returns 
   */
  static info(messageCode, placeholders, toastOptions = {}) {
    if (messageCode) {
      let message = "";
      if (Array.isArray(placeholders)) {
        const groupedPlaceholders = placeholders.reduce((accumulator, placeholder) => {
          return { ...accumulator, [placeholder.key]: getMessage(placeholder.value) }
        }, {});
        message = getMessage(messageCode, { ...groupedPlaceholders });
      } else {
        message = getMessage(messageCode);
      }
      const updatedToastOptions = updateToastOptions({ ...toastOptions }, messageCode)
      const infoMessageBody = (
        <div className="d-flex flex-row align-items-center">
          <img src={infoIcon} className="pr-2" />
          <span className="toasterText">{message}</span>
        </div>
      );
      toast.info(infoMessageBody, updatedToastOptions);
    }
  }

  /**
   * This function shows error toast, the first parameter can be an error object or error code string.
     If it's an error object then extract error code from it and check the conditions.
     Do all error code validation and checks in this function only
   * 
   * @param {any} error error_code --OR-- { code: "error_code", message: "error_message", errDetails: "extra details of error" }
   * @param {array} placeholders [{ key: "", value: "" }]
   * @param {object} toastOptions 
   * @returns 
   */
  static error(error, placeholders, toastOptions = {}) {
    let errorCode = error;
    if (!_.isEmpty(error) && typeof error === 'object') {
      errorCode = error.code;
    }  
    if (errorCode) {
      if (errorCode === ERROR.NETWORK_ERROR) {
        toastOptions["closeButton"] = false;
      }

      // Toaster not required for the following error codes because the user is blocked by a popup and forced to logout
      if (errorCode === ERROR.NOT_AUTHORIZED || errorCode === ERROR.USER_DELETED ||
        errorCode === ERROR.LICENSE_KEY_EXPIRED || errorCode === ERROR.LICENSE_KEY_EXPIRED_ADMIN ||
        errorCode === ERROR.LICENSE_KEY_EXPIRED_AGENT) {
        return;
      }

      let message = "";
      if (errorCode === ERROR.MODULES_NOT_ALLOWED) {
        message = getModulesNotAllowedErrorMessage(error.errDetails)
      } else if (errorCode === ERROR.LOGGING_LEVEL_NOT_ALLOWED) {
        message = getMessage(error.code, { loggingLevel: getMessage(error.errDetails) })
      } else {
        if (Array.isArray(placeholders)) {
          const groupedPlaceholders = placeholders.reduce((accumulator, placeholder) => {
            return { ...accumulator, [placeholder.key]: getMessage(placeholder.value) }
          }, {});
          message = getMessage(errorCode, { ...groupedPlaceholders });
        } else {
          message = getMessage(errorCode);
        }
      }
      const updatedToastOptions = updateToastOptions({ ...toastOptions }, errorCode)
      const errorMessageBody = (
        <div className="d-flex flex-row align-items-center">
          {navigator.onLine && <img src={errorIcon} className="pr-2" />}
          <span className="toasterText">{message}</span>
        </div>
      );

      toast.error(errorMessageBody, updatedToastOptions);
    }
  }

  /**
   * This function shows success toast
   * 
   * @param {string} messageCode 
   * @param {array} placeholders [{ key: "", value: "" }]
   * @param {object} toastOptions 
   * @returns 
   */
  static success(messageCode, placeholders, toastOptions = {}) {
    if (messageCode) {

      let message = "";
      if (Array.isArray(placeholders)) {
        const groupedPlaceholders = placeholders.reduce((accumulator, placeholder) => {
          return { ...accumulator, [placeholder.key]: getMessage(placeholder.value) }
        }, {});
        message = getMessage(messageCode, { ...groupedPlaceholders });
      } else {
        message = getMessage(messageCode);
      }
      const updatedToastOptions = updateToastOptions({ ...toastOptions }, messageCode)
      const successMessageBody = (
        <div className="d-flex flex-row align-items-center">
          <img src={successIcon} className="pr-2" />
          <span className="toasterText">{message}</span>
        </div>
      );
      toast.success(successMessageBody, updatedToastOptions);
    }
  }

  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          transition={Slide}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          delay={1000}
        />
      </div>
    );
  }
}

export default ShowTostr;
