import React, { Component } from "react";
import { isIE } from "react-device-detect";
import { Html5Qrcode } from "html5-qrcode";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import "fabric";
// Constants
import { socketMessage } from "WEBSOCKET/constants";
import { CANVAS_TYPES, ANNOTATIONS, SNAPSHOT_SCAN_CODE, TIMEOUT } from "UTILS/Constants";

// String
import { getMessage } from "CONFIG/i18n";

// Styles
import "./Canvas.less";
import { isURL } from "UTILS/regexValidations";

export default class Canvas extends Component {
  constructor(props) {
    super(props);
    this.commonCanvas = null;
    this.state = {
      canvasData: this.props.canvasData || [],
      // intial object marker (x, y, width, height)
      rectangleAnnotation: null,
      // get from opencv, updated co-ordinates of object (x, y, width, height) in updatedStickyMarker
      // and pass this co-ordinates into socket for other users
      updatedStickyMarker: null,
      scanCodeErrorCounter: 0
    }
    this.currentObjectOriginX = null;
    this.currentObjectOriginY = null;
    this.isMouseDown = false;
    this.scanCodeConfig = typeof window._env_.REACT_APP_SNAPSHOT_SCANCODE_CONFIG === 'string' ? JSON.parse(window._env_.REACT_APP_SNAPSHOT_SCANCODE_CONFIG) : window._env_.REACT_APP_SNAPSHOT_SCANCODE_CONFIG;
  }

  componentDidMount() {
    this.commonCanvas = new fabric.Canvas(this.props.canvasName, {
      selection: false,
      backgroundColor: "transparent",
      allowTouchScrolling: !isIE,
      defaultCursor: "auto",
      hoverCursor: "auto",
    });

    // add event listeners
    this.addCanvasEventListeners();

    // set canvas domensions
    this.setCanvasDimensions();
    this.resize(true);

    // draw canvas objects
    this.drawCanvasObjects();

    window.addEventListener("resize", () => {
      this.resize();
    })
  }

  isStickyAnnotationMode(snapshotAnnotationMode = this.props.snapshotAnnotationMode) {
    return snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STICKY_ANNOTATION;
  }

  isScanCodeAnnotationMode(snapshotAnnotationMode = this.props.snapshotAnnotationMode) { 
    return snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.SCAN_CODE;
  }

  componentDidUpdate(prevProps, prevState) {
    // if agent clear the canvas then we set it true in Snapshot.js
    // and agent again start object tracking in that case we need to set it false,
    // so opencv can start object tracking again
    if (this.isStickyAnnotationMode() && !_.isEqual(this.state.rectangleAnnotation, prevState.rectangleAnnotation) && this.props.uniqueId === this.props.organiser && this.state.rectangleAnnotation !== null) {
      this.props.setStopStickyTracking(false)
      this.startTrackingProcess();
    }
    // get new tracking coordinates then broadcast the updated coordinate to other users
    if (!_.isEqual(this.state.updatedStickyMarker, prevState.updatedStickyMarker) && this.isStickyAnnotationMode() && this.props.uniqueId === this.props.organiser) {
      if (!this.props.clearCanvasAnnotations) {
        this.commonCanvas.clear();
        this.sendAddObjectMessage(
          this.currentObject,
          socketMessage.subCategories.WHITEBOARD.subActions.REGULAR,
          this.state.updatedStickyMarker
        )
        this.addRectangle(this.state.updatedStickyMarker.width, this.state.updatedStickyMarker.height, this.state.updatedStickyMarker.x, this.state.updatedStickyMarker.y)
      }
    }
    if (!_.isEqual(this.state.rectangleAnnotation, prevState.rectangleAnnotation) && this.isScanCodeAnnotationMode() && this.props.uniqueId === this.props.organiser && this.state.rectangleAnnotation !== null) {
      this.commonCanvas.clear();
      this.props.setStopStickyTracking(false)
      this.sendAddObjectMessage(
        this.currentObject,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR,
        this.state.rectangleAnnotation
      )
      this.addRectangle(this.state.rectangleAnnotation.width, this.state.rectangleAnnotation.height, this.state.rectangleAnnotation.x, this.state.rectangleAnnotation.y)
      this.startScanCodeProcess();
    }
    // if agent switch the mode or agent clear canvas/take snapshot/change the snapshotee then reset the drawn annotation
    if ((this.props.snapshotAnnotationMode != prevProps.snapshotAnnotationMode)
      || (prevProps.clearCanvasAnnotations != this.props.clearCanvasAnnotations && this.props.clearCanvasAnnotations)) {
      this.setState({ rectangleAnnotation: null })
    }

    if ((this.props.height / this.props.width).toFixed(2)
      !== (prevProps.height / prevProps.width).toFixed(2)) {
      setTimeout(() => {
        this.setCanvasDimensions();
        this.commonCanvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        this.resize(false, () => {
          this.commonCanvas.clear();
          this.drawCanvasObjects(true);
        });
      }, 1000);
    }

    if (this.props.lastDrawnObject !== prevProps.lastDrawnObject
      && this.props.lastDrawnObject) {
      // add last drawn object on canvas
      if (this.props.ownerOfLastAction !== this.props.participantId)
        this.addObjectToCanvas(
          this.props.lastDrawnObject.canvasObject,
          this.props.lastDrawnObject.objectId,
          this.props.lastDrawnObject.objectType,
          (this.props.width / this.props.height > 1) ? ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH : ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT,
          this.props.ownerOfLastAction,
          this.props.rotateY180);

      let lastDrawnObject = this.props.lastDrawnObject;
      lastDrawnObject.rotate = this.props.rotateY180;
      lastDrawnObject.participantId = this.props.ownerOfLastAction;
      let canvasData = this.state.canvasData;
      if (Array.isArray(canvasData)) {
        if (this.isStickyAnnotationMode()) {
          canvasData[0] = lastDrawnObject;
        }
        else {
          canvasData.push(lastDrawnObject)
        }
      }
      this.setState({
        canvasData: canvasData
      })
    }

    // clear canvas
    if (this.props.canvasData !== prevProps.canvasData && (!this.props.canvasData || this.props.canvasData === {})) {
      this.setState({
        canvasData: []
      })
      this.commonCanvas.clear();
    }
  }

  startScanCodeProcess() {
    if (this.state.rectangleAnnotation?.height <= this.scanCodeConfig.scanningRectMinHeight && this.state.rectangleAnnotation?.width <= this.scanCodeConfig.scanningRectMinWidth) {
      setTimeout(() => {
        this.props.clearSnapshotCanvas()
        this.setState({ scanCodeErrorCounter: 0 })
        this.props.hideSpinner();
        toastr.error("SNAPSHOT_SCAN_CODE_APPROPRIATE_AREA_ERROR", null, { toastId: "SNAPSHOT_SCAN_CODE_APPROPRIATE_AREA_ERROR_" + parseInt(Math.random() * 100) });
      }, 200);
      return;
    }
    try {
      let video = document.getElementById('snapshotvideo');
      let cap = new cv.VideoCapture(video);
      // take first frame of the video
      let src = new cv.Mat(video.height, video.width, cv.CV_8UC4);
      cap.read(src);
      let dst = new cv.Mat();
      // You can try more different parameters
      let rect = new cv.Rect(this.state.rectangleAnnotation.x, this.state.rectangleAnnotation.y, this.state.rectangleAnnotation.width, this.state.rectangleAnnotation.height);
      dst = src.roi(rect);
      cv.imshow('canvasOutput', dst);
      src.delete();
      dst.delete();
      setTimeout(() => {
        this.props.showSpinner();
        let canvas = document.getElementById('canvasOutput')
        canvas.toBlob(blob => {
          const file = new File([blob], "image.png")
          const scanner = new Html5Qrcode(this.props.qrcodeRegionId, false);
          scanner.scanFile(file).then((result) => {
            console.log("decoded text", result)
            if (isURL(result)) {
              const url = result.match(/^http[s]?:\/\//) ? result : 'http://' + result;
              window.open(url, '_blank', 'noopener=true'); //WAAG-5806
              this.props.clearSnapshotCanvas()
              this.setState({ scanCodeErrorCounter: 0 })
              this.props.handleRemove();
              this.props.hideSpinner();
              this.props.setSnapshotSearchResult({
                query: url,
                results: [{
                  title: url,
                  link: url,
                  desc: url
                }]
              });
              return;
            }
            const message = {
              data: {
                category: socketMessage.category.WHITEBOARD,
                action: socketMessage.subCategories.WHITEBOARD.SNAPSHOT_SEARCH_CODE,
                mode: SNAPSHOT_SCAN_CODE.MODE.TEXT,
                sessionKey: this.props.sessionKey,
                message: result,
              }
            }
            this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
            this.props.clearSnapshotCanvas()
            this.setState({ scanCodeErrorCounter: 0 })
            toastr.info(getMessage("SNAPSHOT_SCAN_COMPLETE"), null, { autoClose: TIMEOUT.LONG })

          }).catch(error => {
            console.log(error.message || 'No QR code found.', this.state.scanCodeErrorCounter);
            // WAAG-5772
            this.setState((prev) => ({ scanCodeErrorCounter: prev.scanCodeErrorCounter + 1 }))
            if (this.state.scanCodeErrorCounter < this.scanCodeConfig.scanCodeErrorMaxLimit) {
              this.startScanCodeProcess()
              return;
            }
            this.getBase64(file, (result) => {
              const contents = result;
              const message = {
                data: {
                  category: socketMessage.category.WHITEBOARD,
                  action: socketMessage.subCategories.WHITEBOARD.SNAPSHOT_SEARCH_CODE,
                  mode: SNAPSHOT_SCAN_CODE.MODE.IMAGE,
                  sessionKey: this.props.sessionKey,
                  message: contents,
                }
              }
              this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
              this.props.clearSnapshotCanvas();
              this.setState({ scanCodeErrorCounter: 0 });
              toastr.info("SNAPSHOT_SCAN_COMPLETE", null, { autoClose: TIMEOUT.LONG })
            });            
          });
        })
      }, this.scanCodeConfig.timeOut);
    } catch (error) {
      console.error("Error while scaning", error)
      this.commonCanvas.clear();
      this.setState({ rectangleAnnotation: null })
      this.props.hideSpinner()
    }
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let base64str = reader.result;
      let result = base64str.indexOf("base64");
      result = result + 6+ 1;  //finding index after 'base64'
      let substr = base64str.substring(result);
      cb(substr)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  startTrackingProcess() {
    try {
      const self = this;
      let video = document.getElementById('snapshotvideo');
      let cap = new cv.VideoCapture(video);
      // take first frame of the video
      let frame = new cv.Mat(video.height, video.width, cv.CV_8UC4);
      cap.read(frame);
      // hardcode the initial location of window
      let trackWindow = null;
      if (self.state.rectangleAnnotation) {
        trackWindow = new cv.Rect(self.state.rectangleAnnotation.x, self.state.rectangleAnnotation.y, self.state.rectangleAnnotation.width, self.state.rectangleAnnotation.height);
      }
      if (trackWindow) {
        // set up the ROI for tracking
        let roi = frame.roi(trackWindow);
        let hsvRoi = new cv.Mat();
        cv.cvtColor(roi, hsvRoi, cv.COLOR_RGBA2RGB);
        cv.cvtColor(hsvRoi, hsvRoi, cv.COLOR_RGB2HSV);
        let mask = new cv.Mat();
        let lowScalar = new cv.Scalar(30, 30, 0);
        let highScalar = new cv.Scalar(180, 180, 180);
        let low = new cv.Mat(hsvRoi.rows, hsvRoi.cols, hsvRoi.type(), lowScalar);
        let high = new cv.Mat(hsvRoi.rows, hsvRoi.cols, hsvRoi.type(), highScalar);
        cv.inRange(hsvRoi, low, high, mask);
        let roiHist = new cv.Mat();
        let hsvRoiVec = new cv.MatVector();
        hsvRoiVec.push_back(hsvRoi);
        cv.calcHist(hsvRoiVec, [0], mask, roiHist, [180], [0, 180]);
        cv.normalize(roiHist, roiHist, 0, 255, cv.NORM_MINMAX);

        // delete useless mats.
        roi.delete(); hsvRoi.delete(); mask.delete(); low.delete(); high.delete(); hsvRoiVec.delete();

        // Setup the termination criteria, either 10 iteration or move by at least 1 pt
        let termCrit = new cv.TermCriteria(cv.TERM_CRITERIA_EPS | cv.TERM_CRITERIA_COUNT, 10, 1);

        let hsv = new cv.Mat(video.height, video.width, cv.CV_8UC3);
        let dst = new cv.Mat();
        let hsvVec = new cv.MatVector();
        hsvVec.push_back(hsv);
        const frequency = parseInt(window._env_.REACT_APP_SNAPSHOT_OBJECT_TRACKING_FREQUENCY);
        function processVideo() {
          try {
            if (!self.props.streaming || self.props.snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION || self.state.rectangleAnnotation == null || self.props.clearCanvasAnnotations) {
              // clean and stop tracking.
              frame.delete(); dst.delete(); hsvVec.delete(); roiHist.delete(); hsv.delete();
              return;
            }
            // start processing.
            cap.read(frame);
            cv.cvtColor(frame, hsv, cv.COLOR_RGBA2RGB);
            cv.cvtColor(hsv, hsv, cv.COLOR_RGB2HSV);
            cv.calcBackProject(hsvVec, [0], roiHist, dst, [0, 180], 1);
            // Apply meanshift to get the new location
            [, trackWindow] = cv.meanShift(dst, trackWindow, termCrit);
            // Draw it on image
            let [x, y, w, h] = [trackWindow.x, trackWindow.y, trackWindow.width, trackWindow.height];
            // to keep tracking rectangle withen video frame
            let width = w;
            let height = h;
            if (x + w + self.currentObject.strokeWidth > video.width) {
              width = w - ((x + w + self.currentObject.strokeWidth) - video.width)
            }
            if (y + h + self.currentObject.strokeWidth > video.height) {
              height = h - ((y + h + self.currentObject.strokeWidth) - video.height)
            }
            self.setState({ updatedStickyMarker: { x: x, y: y, width: width, height: height } })
            // schedule the next one.
            let delay = 1000 / frequency;
            setTimeout(processVideo, delay);
          } catch (error) {
            console.error("Error while tracking", error)
            self.commonCanvas.clear();
            self.setState({ rectangleAnnotation: null })
          }
        };
        // schedule the first one.
        setTimeout(processVideo, 0);
      }
    }
    catch (error) {
      console.error("Error while tracking", error)
      this.commonCanvas.clear();
      this.setState({ rectangleAnnotation: null })
    }
  }

  setCanvasDimensions = () => {
    if (this.props.width / this.props.height > 1) {
      // for landscape mode
      this.commonCanvas.setWidth(ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH);
      this.commonCanvas.setHeight(ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT);
    } else {
      // for portrait mode
      this.commonCanvas.setWidth(ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT);
      this.commonCanvas.setHeight(ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH);
    }
  }

  drawCanvasObjects = (orientationChange) => {
    if (this.state.canvasData) {
      let objectArray = this.getSortedCanvasData();
      console.log('objectArray: ', objectArray);
      if (objectArray.length < 1)
        this.props.hideSpinner();
      objectArray.forEach((object, index) => {
        if (object.canvasObject)
          object.canvasObject.strokeUniform = true;
        this.addObjectToCanvas(
          object.canvasObject,
          object.objectId,
          object.objectType,
          (this.props.width / this.props.height > 1) ? ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH : ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT,
          object.participantId,
          object.rotate
        );
        if (index == objectArray.length - 1)
          this.props.hideSpinner();
      });
    } else {
      this.props.hideSpinner();
    }
  };

  getSortedCanvasData = () => {
    const activeCanvasData = this.state.canvasData
    if (activeCanvasData) {
      let objectArray;
      objectArray = _.values(activeCanvasData);

      // sort canvas data on basis of updated time
      objectArray = _.orderBy(objectArray, ["updatedTime"], ["asc"]);

      return objectArray;
    }
  };

  addShape = () => {
    let pointer = this.commonCanvas.getPointer();
    //stored initial coordinates of current object
    this.currentObjectOriginX = pointer.x;
    this.currentObjectOriginY = pointer.y;
    if (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()) {
      this.addRectangle(1, 1, pointer.x, pointer.y);
    }
    else {
      this.addCircle(1, 1, pointer.x, pointer.y);
    }
    this.commonCanvas.renderAll();
  };

  addRectangle = (width, height, leftMargin, topMargin) => {
    this.currentObject = new fabric.Rect({
      left: leftMargin,
      top: topMargin,
      originX: this.currentObject?.originX ? this.currentObject?.originX : "left",
      originY: this.currentObject?.originY ? this.currentObject?.originY : "top",
      width: width,
      height: height,
      angle: 0,
      stroke: this.props.inkColor,
      strokeWidth: ANNOTATIONS.SNAPSHOT_STICKY_ANNOTATION.STROKE_WIDTH,
      fill: "rgba(0,0,0,0)",
      selectable: false,
      objectType: ANNOTATIONS.RECTANGLE,
      objectId: new Date().getTime() + this.props.participantId,
      strokeUniform: true,
    });
    this.commonCanvas.add(this.currentObject);
  };

  addCircle = (rx, ry, leftMargin, topMargin) => {
    this.currentObject = new fabric.Ellipse({
      rx: Math.abs(rx),
      ry: Math.abs(ry),
      left: leftMargin,
      top: topMargin,
      originX: "left",
      originY: "top",
      strokeWidth: ANNOTATIONS.SNAPSHOT_CIRCLE.STROKE_WIDTH,
      stroke: this.props.inkColor,
      fill: "rgba(0,0,0,0)",
      selectable: false,
      scaleX: 1,
      scaleY: 1,
      objectType: ANNOTATIONS.SNAPSHOT_CIRCLE.NAME,
      objectId: new Date().getTime() + this.props.participantId,
      strokeUniform: true,
    });
    this.commonCanvas.add(this.currentObject);
  };

  addCanvasEventListeners = () => {
    const snapshotvideo = document.getElementById("snapshotvideo")
    this.commonCanvas.on("mouse:down", event => {
      // isDrawingModeEnabled is for user/agent and snapshotAnnotationMode is only for agent
      // and user can only draw object if object tracking mode is off
      // and Agent can draw only one annotation in sticky mode
      if ((this.props.isDrawingModeEnabled && this.props.snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION)
        || ((this.props.uniqueId === this.props.organiser && this.state.rectangleAnnotation === null) && (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()))) {
        this.isMouseDown = true;
        this.addShape(event);
      }
    });

    this.commonCanvas.on("mouse:move", (event) => {
      if (!this.isMouseDown) return;
      this.currentObject && this.updateShape(event, snapshotvideo);
      this.commonCanvas.renderAll();
    });

    this.commonCanvas.on("mouse:up", event => {
      this.pinchDifference = "";
      this.oldTouchPoints = "";
      this.panDifference = "";
      if (this.currentObject) {
        this.isMouseDown = false;
        if ((this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()) && this.props.uniqueId === this.props.organiser) {
          let currentObject = _.cloneDeep(this.currentObject);
          let left = currentObject.left;
          let top = currentObject.top;
          // to manage the annotation position when drawing from bottom right
          // as opencv only take left-top co-ordinates (x,y)
          if (currentObject.originX == "right") {
            currentObject.set("originX", "left");
            left = currentObject.left - currentObject.width - currentObject.strokeWidth
          }
          if (currentObject.originY == "bottom") {
            currentObject.set("originY", "top");
            top = currentObject.top - currentObject.height - currentObject.strokeWidth
          }
          this.currentObject = currentObject;
          this.setState({
            rectangleAnnotation: { x: Math.round(left), y: Math.round(top), width: Math.round(currentObject.width), height: Math.round(currentObject.height) }
          })
        } else if (this.props.isDrawingModeEnabled && !this.isStickyAnnotationMode() && !this.isScanCodeAnnotationMode()) {
          var center = this.currentObject.getCenterPoint();
          this.currentObject.set("originX", "center");
          this.currentObject.set("originY", "center");
          this.currentObject.set("left", center.x);
          this.currentObject.set("top", center.y);
          this.currentObject.setCoords();
          this.sendAddObjectMessage(
            this.currentObject,
            socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
          );
        } else {
          this.commonCanvas.clear();
        }
      }
    });
  };

  updateShape = (event, snapshotvideo) => {
    let pointer = this.commonCanvas.getPointer(event.e);

    if (this.currentObjectOriginX > pointer.x)
      this.currentObject.set({ originX: "right" });
    else this.currentObject.set({ originX: "left" });

    if (this.currentObjectOriginY > pointer.y)
      this.currentObject.set({ originY: "bottom" });
    else this.currentObject.set({ originY: "top" });

    let height = Math.abs(pointer.y - this.currentObjectOriginY);
    let width = Math.abs(pointer.x - this.currentObjectOriginX);
    if (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()) {
      // restrict user to drawing objects in the video frame
      if (pointer.x > 0 && pointer.x <= snapshotvideo.width) {
        this.currentObject.set({ width: width - this.currentObject.strokeWidth });
      }
      if (pointer.y > 0 && pointer.y <= snapshotvideo.height) {
        this.currentObject.set({ height: height - this.currentObject.strokeWidth });
      } 
    }
    else {
      this.currentObject.set({ rx: width / 2, ry: height / 2 });
    }
    this.commonCanvas.renderAll();
  };

  addObjectToCanvas = (objectToAdd, objectId, objectType, width, participantId, flipX) => {
    if (this.isStickyAnnotationMode()) {
      this.commonCanvas.clear();
    }
    fabric.util.enlivenObjects([objectToAdd], enlivenedObjects => {
      let newObject = enlivenedObjects[0];
      if (newObject) {
        newObject["strokeUniform"] = true;
        newObject["objectId"] = objectId;
        newObject["objectType"] = objectType;
        newObject["lockScalingX"] = true;
        newObject["lockScalingY"] = true;
        newObject["lockRotation"] = true;
        newObject["lockScalingFlip"] = true;
        newObject["lockMovementX"] = true;
        newObject["lockMovementY"] = true;

        if ((this.props.rotateY180 && flipX
          && participantId !== this.props.participantId) ||
          (newObject["flipX"] &&
            participantId !== this.props.participantId
          )) {
          const objectWidth = this.isStickyAnnotationMode() ? newObject['width'] : 0;
          newObject["left"] = width - newObject["left"] - objectWidth;
          newObject["flipX"] = true
        } else {
          newObject["flipX"] = false
        }
        // to keep tracking rectangle withen video frame at user's end
        if(this.isStickyAnnotationMode()){
          let video = document.getElementById('snapshotvideo');
          if(newObject.left + newObject.width + newObject.strokeWidth > video.width){
            const updatedWidth = newObject.width - ((newObject.left + newObject.width + newObject.strokeWidth) - video.width)
            newObject['width']=updatedWidth;
          }
          if (newObject.top + newObject.height + newObject.strokeWidth > video.height) {
            const updatedHeight = newObject.height - ((newObject.top + newObject.height + newObject.strokeWidth) - video.height)
            newObject['height']=updatedHeight;
          }
        }
        newObject.set({ selectable: false });
        this.commonCanvas.add(newObject);
      }
    });
  };

  sendAddObjectMessage = (object, width, updatedStickyMarker) => {
    //Add object to reducer

    let canvasObject = _.cloneDeep(object);
    canvasObject["participantId"] = this.props.participantId;
    if (this.props.rotateY180) {
      // canvasObject["left"] = width - canvasObject["left"];
      canvasObject["flipX"] = true
    }
    if (this.isStickyAnnotationMode()) {
      canvasObject["left"] = updatedStickyMarker?.x;
      canvasObject["top"] = updatedStickyMarker?.y;
      canvasObject["width"] = updatedStickyMarker?.width;
      canvasObject["heigth"] = updatedStickyMarker?.heigth;
      const message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.ADD_STICKY_OBJECT,
          sessionKey: this.props.sessionKey,
          canvasId: this.props.canvasId,
          canvasType: CANVAS_TYPES.REGULAR,
          participantId: this.props.participantId,
          whiteboardData: {
            objectType: canvasObject.objectType,
            objectId: canvasObject.objectId,
            canvasObject: canvasObject,
          },
          canvasSource: this.props.canvasSource,
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      // removed _ADD_STICKY_OBJECT action for Agent
      // to enable clear snapshot button we need to set lastDrawnObject
      // and addStickyObject action is setting lastDrawnObject in reducer for agent
      this.props.addStickyObject(message.data)
    }
    else {
      const message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.ADD_OBJECT,
          sessionKey: this.props.sessionKey,
          canvasId: this.props.canvasId,
          canvasType: CANVAS_TYPES.REGULAR,
          activePage: -1,
          subAction: socketMessage.subCategories.WHITEBOARD.subActions.REGULAR,
          participantId: this.props.participantId,
          whiteboardData: {
            objectType: canvasObject.objectType,
            objectId: canvasObject.objectId,
            canvasObject: canvasObject,
          },
          canvasSource: this.props.canvasSource,
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
  };

  resize = (hideSpinner, call) => {
    let canvasContainer = document.getElementById("commonCanvasContainer");
    if (canvasContainer) {
      var width = canvasContainer.offsetWidth;
      var height = canvasContainer.offsetHeight;
      var ratio = (this.commonCanvas.getWidth() / this.commonCanvas.getHeight()).toFixed(2);

      // find height/width of canvas depending on the containers height/width and
      // original ratio of the canvas (ie. aspect ratio of the video)
      if (width / height > ratio) {
        width = height * ratio;
      } else {
        height = width / ratio;
      }

      var scale = width / this.commonCanvas.getWidth();
      var zoom = this.commonCanvas.getZoom();
      zoom *= scale.toFixed(3);

      this.commonCanvas.setDimensions({ width: width, height: height });

      // set zoom level of canvas so that the contents drawn on canvas maintain their position.
      this.commonCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      this.commonCanvas.renderAll();

      if (hideSpinner) this.props.hideSpinner();
      if (call) call();
    }
  };
  
  render() {
    return (
      <div className="commonCanvasContainer" id="commonCanvasContainer">
        <canvas id={this.props.canvasName} >
          {getMessage("CANVAS_NOT_SUPPORTED")}
        </canvas>
      </div>
    )
  }
}
