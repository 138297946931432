import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { toast } from 'react-toastify';
import { leaveSessionAction } from "CONFIG/ActionFactory";

import * as log from "loglevel";

import MainLayout from "./MainLayout";
import { handleMessage as handleChatMessage } from "WEBSOCKET/Chat/ChatMessageHandler";
import { handleMessage as handleSessionMessage } from "WEBSOCKET/Session/SessionMessageHandler";
import { handleMessage as handleWhiteboardMessage } from "WEBSOCKET/Whiteboard/WhiteboardMessageHandler";
import { handleMessage as handleSharedMediaMessage } from "WEBSOCKET/SharedMedia/SharedMediaMessageHandler";
import { handleMessage as handleParticipantMessage } from "WEBSOCKET/Participant/ParticipantMessageHandler";
import { handleMessage as handleNotificationMessage } from "WEBSOCKET/Notification/NotificationMessageHandler";
import { handleMessage as handleLocationMessage } from "WEBSOCKET/Location/LocationMessageHandler";
import { handleMessage as handleAppEmulationMessage } from "WEBSOCKET/AppEmulation/AppEmulationHandler";
import { handleMessage as handleErrorMessage } from "WEBSOCKET/Error/ErrorMessageHandler";
import { handleMessage as handleCoBrowseMessage } from "WEBSOCKET/CoBrowse/CoBrowseHandler";
import { handleMessage as handleCustomerCoBrowseMessage } from "WEBSOCKET/CustomerCoBrowse/CustomerCoBrowseHandler";

import { socketMessage } from "WEBSOCKET/constants";
import {
  initSocketWorker,
  connectWebsocket,
  disconnectWebsocket,
  sendWebsocketMessage
} from "WEBSOCKET/WebsocketHandler";

import {
  selectUserToken,
  selectUserName,
  selectWebsocketUrl,
  selectSessionKey,
  selectEndUserToken,
  selectUserDetails,
  selectSessionStatus
} from "./selectors";
import { getIsSessionJoined } from "../Sessions/selectors";
import { removeTrailingSlash } from "UTILS/Utility";
import { CONFIG, USER_ROLES, USER_ADMISSION_STATUS, REGEX } from "UTILS/Constants";

// Actions
import { sessionActions } from "CONFIG/ActionFactory";
import { SESSION_STATUS } from "../../Utils/Constants";
import { getLanguage } from "../../Routes/selectors";
// import loadCobrowseScript from "../CommonComponents/LoadScript/LoadCobrowseScript";

export class MainLayoutContainer extends Component {
  constructor(props) {
    super(props);
    log.setLevel("error");
  }

  componentDidMount() {
    console.log("################## MainLayoutContainer componentDidMount location: ", this.props.location.pathname, ",isSessionJoinedthis:", this.props.isSessionJoined);

    //toast.removeByType("error"); //TODO: find use and apropriate solution
    //toast.removeByType("success");
    //toast.removeByType("info");
    toast.dismiss()
    const pathname = removeTrailingSlash(this.props.location.pathname);
    if (pathname !== CONFIG.path.startSession || !REGEX.START_QR_CODE_SESSION.test(pathname)
    || this.props.sessionStatus === SESSION_STATUS.WAITING) {
      if (this.props.currentUserDetails.admissionStatus != USER_ADMISSION_STATUS.REJECTED) {
        if (this.props.isSessionJoined) {
          this.connectWebsocket();
          // send JOIN WS message if User is not on /startSession or /qr/<licenseKey> path or if Session is waiting (User is on QR waiting screen)
          this.sendJoinSessionWSMessage();
        }
      }
    } else if (this.props.isSessionJoined) {
      this.connectWebsocket();
    }

    //this.wsocket.on(socketMessage.events.MESSAGE, this.handleWebsocketMessage);
    // this.wsocket.on("disconnect", reason => {
    //   if (this.props.isSessionJoined && reason !== "io client disconnect") {        
    //     sessionActions.isSessionJoinedAction(false);
    //     this.props.history.push(CONFIG.path.reJoin);
    //   }
    // });

  }

  componentDidUpdate(prevProps) {
    if(prevProps.currentUserDetails.admissionStatus != this.props.currentUserDetails.admissionStatus
      && this.props.currentUserDetails.admissionStatus === USER_ADMISSION_STATUS.ADMITTED) {
      this.sendJoinSessionWSMessage();
    }
    if(prevProps.endUserToken != this.props.endUserToken) {
      this.connectWebsocket();
    }
  }

  connectWebsocket = () => {
    if(this.props.endUserToken) {
      initSocketWorker();
      this.wsocket = connectWebsocket(
        window._env_.REACT_APP_LOOOKIT_WEB_SOCKET_URL,
        this.props.endUserToken,
        this.handleWebsocketMessage,
        this.socketDisconnected
      );
    }
  }

  sendJoinSessionWSMessage = () => {
    const { store, userName, sessionKey, websocketUrl, userToken, endUserToken, currentUserDetails } = this.props;
    let sessionJoinPayload = {
      data: {
        category: socketMessage.category.SESSION,
        action: (currentUserDetails == USER_ROLES.AGENT 
          || currentUserDetails.admissionStatus == USER_ADMISSION_STATUS.ADMITTED 
          ?  socketMessage.subCategories.SESSION.JOIN 
          : socketMessage.subCategories.SESSION.JOIN_SESSION_REQUEST),
        userName: userName,
        sessionKey: sessionKey,
        token: endUserToken,
        language: this.props.language,
      }
    };
    if (userToken) {
      sessionJoinPayload.data.token = userToken;
    }
    this.props.sendWebsocketMessage(
      socketMessage.events.MESSAGE,
      sessionJoinPayload
    );
  }

  // Chrome: control doesn't come here on refresh
  // FF: control comes here on refresh
  // controls comes here when browser is kept in background and sockect is disconnected 
  // So when user comes back to the browser page, need to rejoin   
  socketDisconnected = reason => {
    console.log("################, in MainLayoutContainer socketDisconnected", this.props.isSessionJoined);
    if (this.props.isSessionJoined && reason !== "io client disconnect") {
      sessionActions.isSessionJoinedAction(false);
      console.log("################, in MainLayoutContainer socketDisconnected... rejoining");
      this.props.history.push(CONFIG.path.reJoin);
    }
  }

  componentWillUnmount() {
    console.log("################, in MainLayoutContainer... componentWillUnmount");
    if(this.props.endUserToken) {
      let isUserEvictedPage = window.location.pathname === `${this.props.uriIdentifier}${CONFIG.path.userEvicted}`
      let participantLeftPayload = {
        data: {
          category: socketMessage.category.PARTICIPANT,
          action: socketMessage.subCategories.PARTICIPANT.LEFT,
          sessionKey: this.props.sessionKey,
          isParticipantEvicted: isUserEvictedPage ? true : false
        }
      };
      this.props.sendWebsocketMessage(
        socketMessage.events.MESSAGE,
        participantLeftPayload
      );
    }
  }
  handleWebsocketMessage = data => {
    const { store } = this.props;

    switch (data.data.category) {
      case socketMessage.category.CHAT:
        handleChatMessage(data.data, store);
        break;
      case socketMessage.category.SESSION:
        handleSessionMessage(data.data, store);
        break;
      case socketMessage.category.PDF:
      case socketMessage.category.WHITEBOARD:
        handleWhiteboardMessage(data.data, store);
        break;
      case socketMessage.category.WEBRTC:
        handleSharedMediaMessage(data.data, store);
        break;
      case socketMessage.category.PARTICIPANT:
        handleParticipantMessage(data.data, store);
        break;
      case socketMessage.category.NOTIFICATION:
        handleNotificationMessage(data.data, store);
        break;
      case socketMessage.category.LOCATION:
        handleLocationMessage(data.data, store);
        break;
      case socketMessage.category.APP_EMULATION:
        handleAppEmulationMessage(data.data, store);
        break;
      case socketMessage.category.CO_BROWSE:
        handleCoBrowseMessage(data.data, store);
        break;
      case socketMessage.category.CUSTOMER_CO_BROWSE:
        handleCustomerCoBrowseMessage(data.data, store);
        break;
      case socketMessage.category.ERROR:  
        if(data.data.errorType === socketMessage.subCategories.ERROR.UNAUTHORIZED_ERROR) {
          this.props.history.push(CONFIG.path.sessionEnded);
        }   
        handleErrorMessage(data.data, store)
        break;
    }
  };

  render() {
    return (
      <div>
        <MainLayout
          children={this.props.children}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    SessionReducer: state.SessionReducer,
    userToken: selectUserToken(state),
    userName: selectUserName(state),
    websocketUrl: selectWebsocketUrl(state),
    sessionKey: selectSessionKey(state),
    isSessionJoined: getIsSessionJoined(state),
    endUserToken: selectEndUserToken(state),
    activeCanvas: state.WhiteboardReducer.activeCanvas,
    sessionStatus: selectSessionStatus(state),
    currentUserDetails: selectUserDetails(state),
    language: getLanguage(state),
    uriIdentifier: state.UserReducer.uriIdentifier
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      disconnectWebsocket,
      sendWebsocketMessage
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainLayoutContainer)
);
