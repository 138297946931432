import React, { Component } from "react";
import { Row, Col, Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";

// Components
import HeaderContainer from "../Header/HeaderContainer";
import OrganisationsList from "./OrganisationsList";
import SystemAnnouncement from "../SystemAnnouncements/SystemAnnouncementContainer";
import ClusterInformationList from "./ClusterInformationList";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

// Strings
import { getMessage } from "CONFIG/i18n";

import { SUPER_ADMIN_TABS } from "../../../Utils/Constants";

// Styles
import "./Organisations.less";

// Images
import KurentoServer from "ASSETS/Images/ic_Kurento_Monitoring_Server.svg";
import ServerOn from "ASSETS/Images/ic_server_on.svg";
import ServerOff from "ASSETS/Images/ic_server_off.svg";

//actions
import { organisationAction } from "CONFIG/ActionFactory";

// const API_CALL_INTERVAL = 9000;

class Organisations extends Component {
  constructor(props) {
    super(props);
    this.subscribe = null;
    this.state = {
      organizationsData: this.props.responseObject.data,
      announcementData: null,
      showModal: false,
      // isSearchResult: false,
    };
  }

  componentDidUpdate(prevProp) {
    if (
      !_.isEqual(
        this.props.responseObject.data,
        prevProp.responseObject.data
      ) &&
      !this.state.isSearchResult
    ) {
      this.setState({ organizationsData: this.props.responseObject.data });
    }
  }
  toggleAddAnnouncementModal = (showModal, announcementData = null) => {
    this.setState({
      showModal: showModal,
      announcementData: announcementData,
    });
  };
  sortCustomerList = () => {
    this.props.sortCustomerList(this.props.responseObject, true);
  }

  setTabs = (currentActiveTab) => {
    organisationAction.saveCurrentActiveTab(currentActiveTab);
    this.props.fetchDataOnTabChange(currentActiveTab);
  }

  render() {
    return (
      <div>
        <HeaderContainer
          title={getMessage("ORGANIZATIONS")}
          headerButtonText={getMessage("ADD_ORGANIZATION")}
          showHeaderButton={true}
          history={this.props.history}
          handleSearchString={this.props.handleSearchString}
          isDashboard={true}
          gotoAddOrganization={this.props.gotoAddOrganization}
          currentActiveTab={this.props.currentActiveTab}
          toggleAddAnnouncementModal={this.toggleAddAnnouncementModal}
          showUserDropDownModal={true}
        />

        <Row className="summary">
          <Col lg={10} className="borderRight noPadding">
            <Row className="fitHeaderContent">
              {_.map(this.props.summaryData, (item, index) => {
                return (
                  <Col lg={2} key={index} className="summaryDiv">
                    <div className="summaryValue" id={`summaryValue${index}`}>
                      {item.value}
                      <CustomTooltip
                        tooltipText={item.value}
                        tooltipId={`summaryValue${index}`}
                      />
                    </div>
                    <div key={index + "_val"} className="summaryLabel">
                      {item.label}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col lg={2} className="serverInfo px-lg-0 px-md-0">
            <div className="pt-4 pb-3 displayFlex justifyContentFlexCenter alignItemsCenter">
              {this.props.isKurentoServerOn ? (
                <img className="mr-2" src={ServerOn} />
              ) : (
                <img className="mr-2" src={ServerOff} />
              )}
              <img className="mr-2" src={KurentoServer} />
              <div>{getMessage("KMS")}</div>
            </div>
            {/* <div className="pt-2 pb-2 displayFlex alignItemsCenter">
              {this.props.serverStatus.isApplicationServerOn ? (
                <img className="mr-2" src={ServerOn} />
              ) : (
                <img className="mr-2" src={ServerOff} />
              )}
              <img className="mr-2" src={ApplicationServer} />
              <div>{getMessage("APPLICATION_SERVER")}</div>
            </div> */}
          </Col>
        </Row>

        <Row className="superAdminNavTabMargin">
          <Col className="noPadding">
            <Nav tabs className="superAdminNavTabBackground">
              <NavItem className="navTabItem">
                <NavLink
                  onClick={() => this.setTabs(SUPER_ADMIN_TABS.ORGANIZATIONS)}
                  className = {(this.props.currentActiveTab==SUPER_ADMIN_TABS.ORGANIZATIONS) ? "otherActiveTabs organizationTab" : "otherTabs organizationTab"}
                >
                  {getMessage("ORGANIZATIONS")}
                </NavLink>
              </NavItem>

              <NavItem className="navTabItem">
                <NavLink
                  onClick={() => this.setTabs(SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS)}
                  className = {(this.props.currentActiveTab===SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS) ? "otherActiveTabs organizationTab" : "otherTabs organizationTab"}>
                  {getMessage("SYSTEM_ANNOUNCEMENTS")}
                </NavLink>
              </NavItem>

              {this.props.usingKmsCluster?
                <NavItem className="navTabItem">
                  <NavLink
                    onClick={() => this.setTabs(SUPER_ADMIN_TABS.CLUSTER_INFO)}
                    className = {(this.props.currentActiveTab===SUPER_ADMIN_TABS.CLUSTER_INFO) ? "otherActiveTabs organizationTab" : "otherTabs organizationTab"}
                  >
                    {getMessage("CLUSTER_INFO")}
                  </NavLink>
                </NavItem>
                :null}
            </Nav>
          </Col>
        </Row>

        <TabContent activeTab={this.props.currentActiveTab}>
          {this.props.showTable && 
            <>
              <TabPane tabId={SUPER_ADMIN_TABS.ORGANIZATIONS}>
                <Row className="noMargin">
                  <Col sm="12" className="noPadding">
                    <div>
                      {this.props.currentActiveTab === SUPER_ADMIN_TABS.ORGANIZATIONS ?
                        <OrganisationsList
                          organizationsData={this.props.responseObject.data}
                          gotoEditOrganization={this.props.gotoEditOrganization}
                          getAvailableModules={this.props.getAvailableModules}
                          showDeleteConfirmationModal={this.props.showDeleteConfirmationModal}
                          getAllUserData={this.props.getAllUserData}
                          handleSearchString={this.props.handleSearchString}
                          sortCustomerList={this.sortCustomerList}
                        />: null
                      }
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={SUPER_ADMIN_TABS.CLUSTER_INFO}>
                <div>
                  {this.props.currentActiveTab === SUPER_ADMIN_TABS.CLUSTER_INFO ?
                    <div>
                      <ClusterInformationList
                        clusterData={this.props.clusterData}
                        turnClusterData={this.props.turnClusterData}
                        getClusterInfo={this.props.getClusterInfo}
                        getTurnClusterInfo={this.props.getTurnClusterInfo}
                        lastRefreshTime={this.props.lastRefreshTime}
                        lastRefreshTimeTurn={this.props.lastRefreshTimeTurn}
                        retireInstance={this.props.retireInstance}
                        usingTurnCluster={this.props.usingTurnCluster}
                      /> 
                    </div>
                    :null}  
                </div>
              </TabPane>
            </>}
          <TabPane tabId={SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS}>
            <div>
              {this.props.currentActiveTab === SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS ?
                <div>
                  <SystemAnnouncement
                    announcementData={this.state.announcementData} 
                    showModal={this.state.showModal}
                    toggleAddAnnouncementModal={this.toggleAddAnnouncementModal}/>
                </div> 
                :null}  
            </div>
          </TabPane>
        </TabContent>

        {/* {this.props.showTable && 
        <OrganisationsList
          organizationsData={this.props.responseObject.data}
          showEditOrganizationModal={this.props.showEditOrganizationModal}
          getAvailableModules={this.props.getAvailableModules}
          showDeleteConfirmationModal={this.props.showDeleteConfirmationModal}
          getAllUserData={this.props.getAllUserData}
          handleSearchString={this.props.handleSearchString}
          onCancelButton={this.props.cancelSearchString}
          sortCustomerList={this.sortCustomerList}
        />
        } */}
      </div>
    );
  }

  // componentWillUnmount() {
  //   this.subscribe ? this.subscribe.complete() : null;
  // }
}

export default Organisations;
