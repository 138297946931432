import React from "react";
import { func } from "prop-types";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { isFirefox } from "react-device-detect";

import { USER_ROLES } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import { getMessage } from "CONFIG/i18n";
import { LANGUAGE } from "UTILS/Constants";

import "./AppEmulation.less";
import "COMPONENTS/App/App.less";

class AppEmulationIFrame extends React.Component {
  static propTypes = {
    toggle: func
  };
  
  constructor(props) {
    super(props);
  }

  closeClickHandle = () => {
    this.props.toggle(true);
  }

  errorCallBack = error_code =>{
    this.setState({errorMessage:error_code})
  }

  componentDidUpdate() {
    if (this.props.show) {
      document.body.style.setProperty("overflow-y", "hidden", "important");
    } else {
      if (isFirefox)
        document.body.style.setProperty("overflow-y", "visible", "important");
      else
        document.body.style.setProperty("overflow-y", "overlay", "important");
    };
  }

  postMessageForWindow = () => {
    let iFrameObject = document.getElementById("iframeEmulator")
    if(iFrameObject){
      let action = (this.props.role === USER_ROLES.AGENT ? "endSession" : "leaveSession");
      iFrameObject.contentWindow.postMessage(action, '*');
    }
    return true;
  }

  componentWillUnmount() {
    if (isFirefox) {
      document.body.style.setProperty("overflow-y", "visible", "important");
    } else {
      document.body.style.setProperty("overflow-y", "overlay", "important");
    }
  }

  render() {  

    const selectedDeviceInfo = this.props.selectedDeviceInfo;
    let deviceName =
      this.props.selectedLangauge === LANGUAGE.JA.name
        ? selectedDeviceInfo?.displayNameJA
        : selectedDeviceInfo?.displayNameEN;
    return (
      <div className="selectDevice1">
        <Modal
          isOpen={this.props.show && this.props.appEmulationUrl}
          backdrop="static"
          className="frameEmulator"
        >
          <ModalHeader className={this.props.showDeviceInfo ? "" : "modal-header-height"}>
            <div className="close-button">
              <span
                aria-hidden="true"
                id="close"
                onClick={() => {
                  this.closeClickHandle();
                }}
                className="cursorPointer close-icon"
              >
                ×
                <CustomTooltip
                  tooltipText={
                    this.props.role === USER_ROLES.AGENT
                      ? getMessage("END_APP_EMULATION")
                      : getMessage("LEAVE_APP_EMULATION")
                  }
                  tooltipId="close"
                />
              </span>
            </div>

            { this.props.showDeviceInfo && <div className="select-device-info-header">
              <span className="mr-2">
                {getMessage("DEVICE")}: {deviceName}
              </span>
              <span className="seperator-left">
                <span className="margin-left">
                  {getMessage("OS_VERSION")}: {selectedDeviceInfo?.version}
                </span>
              </span>
            </div>}
          </ModalHeader>
          <ModalBody>
            <iframe
              className="emulatorIFrame"
              src={this.props.appEmulationUrl}
              id="iframeEmulator"
              key={1}
            ></iframe>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AppEmulationIFrame;
