import _ from "lodash";
import { ACTIONS, USER_ROLES } from "UTILS/Constants";

const initialState = {
  isSessionEnded: false,
  appEmulationPermissionRequest: false,
  appEmulationPermisssionReply: false,
  iframeUrl: "",
  participants: [],
  participantLeftUniqueId: "",
  participantJoinedUniqueId: "",
  participantDeniedPermissionUniqueId: "",
  isAppEmulationSessionActive: false,
  selectedDeviceInfo: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.APP_EMULATION.APP_EMULATION_REQUEST:
      return { ...state, appEmulationPermissionRequest: action.value, isSessionEnded: false };

    case ACTIONS.APP_EMULATION._APP_EMULATION_REPLY:
      return { ...state, appEmulationPermisssionReply: action.value };
    
    case ACTIONS.APP_EMULATION.SET_IFRAME_URL:
      return { ...state, iframeUrl: action.value };
    
    case ACTIONS.APP_EMULATION.APP_EMULATION_REPLY:
      return { ...state, appEmulationPermisssionReply: action.value };
    
    case ACTIONS.APP_EMULATION.SET_PARTICIPANT:
      return { ...state, participants: action.value };
    
    case ACTIONS.APP_EMULATION.APP_EMULATION_END_SESSION:
      let endSessionInitialState = _.cloneDeep(initialState);
      if(action.value !== USER_ROLES.AGENT){
        endSessionInitialState.isSessionEnded = true 
        endSessionInitialState.isAppEmulationSessionActive = false
      }                 
      return endSessionInitialState;
    
    case ACTIONS.APP_EMULATION.APP_EMULATION_LEAVE_SESSION:
      return { ...state, participantLeftUniqueId: action.value };
    
    case ACTIONS.APP_EMULATION.APP_EMULATION_JOIN_SESSION:
      return { ...state, participantJoinedUniqueId: action.value };
    
    case ACTIONS.APP_EMULATION.PERMISSION_DENIDED:
      return { ...state, participantDeniedPermissionUniqueId: action.value };
    
    case ACTIONS.APP_EMULATION.SET_APP_EMULATION_SESSION_STATUS:
      return { ...state, isAppEmulationSessionActive: action.value };

    case ACTIONS.APP_EMULATION.SET_SELECTED_DEVICE_INFO:
      return { ...state, selectedDeviceInfo: action.value };

    default:
      return state;
  }
}
