import {
  socketMessage,
  webRtcMessageId,
  mediaType,
  mediaStatus
} from "WEBSOCKET/constants";
import {
  CANVAS_TYPES,
  CANVAS_SOURCE,
  USER_ROLES,
  SESSION_STATUS,
  ACTIONS,
  USER_ADMISSION_STATUS,
  PARTICIPANT_STATUS,
  CO_BROWSE_SESSION_STATUS,
  PARTICIPANT_UPDATE_REASON
} from "UTILS/Constants";
import * as log from "loglevel";
import { makeUserMediaTypeUniform, postMesageToCoBrowseIframeUtility } from "UTILS/Utility";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";

log.setLevel("info");

export const handleMessage = (data, store) => {
  const reducerData = store.getState();
  const userRole = reducerData.UserReducer.userDetails.role;
  const sessionStatus = reducerData.SessionReducer.sessionDetails.sessionStatus;
  const sessionKey = reducerData.SessionReducer.sessionDetails.sessionKey;
  const presenter = reducerData.RoomMetaDataReducer.roomMetaData.presenterId;
  const uniqueId = reducerData.SessionReducer.uniqueId;
  const showCustomerCoBrowse = reducerData.CustomerCoBrowseReducer.showCustomerCoBrowse;
  const coBrowseBaseUrl = reducerData.CustomerCoBrowseReducer.coBrowseBaseUrl;
  const token = reducerData.SessionReducer.sessionDetails.token
  switch (data.action) {
    case socketMessage.subCategories.SESSION._JOIN_SESSION_REQUEST:
      // only received by Agent from server when user wants to join the session
      store.dispatch({
        type: ACTIONS.UPDATE_PARTICIPANTS,
        participant: { 
          userName: data.userName,
          status: data.status,
          admissionStatus: data.admissionStatus,
          uniqueId: data.uniqueId
        },
        updateReason: PARTICIPANT_UPDATE_REASON.JOINED_REQUEST
      });
      break;
    case socketMessage.subCategories.SESSION._JOIN_SESSION_REPLY:
      // only received by User from server after Agent admits the user
      // dispatched to update participant array
      store.dispatch({
        type: ACTIONS.PARTICIPANT_ADMITTED_OR_REJECTED,
        participant: { 
          uniqueId: data.uniqueId,
          isAdmitted: data.isAdmitted,
          userName: data.userName
        },
        updateReason: PARTICIPANT_UPDATE_REASON.JOINED_REPLY
      });
      // dispatched to update user's self admissionStatus
      store.dispatch({
        type: ACTIONS.USER_ADMITTED,
        userAdmissionStatus: data.isAdmitted,
        userName: data.userName
      });
      break;
    
    case socketMessage.subCategories.SESSION._JOIN:
      // set participant Id
      data.color !== null && data.color != undefined &&
        store.dispatch({
          type: ACTIONS.WHITEBOARD.SET_CANVAS_COLOR,
          color: data.color
        })

      store.dispatch({
        type: ACTIONS.UPDATE_CURRENT_PARTICIPANT_INFO,
        currentParticipantInfo: {
          participantId: data.participantId,
          uniqueId: data.uniqueId,
          isOrganizer: data.uniqueId === data.organiser
        }
      });
      //  set session status
      store.dispatch({
        type: ACTIONS.SET_SESSION_STATUS,
        sessionStatus: data.sessionStatus
      });
      if (data.sessionStatus === SESSION_STATUS.ACTIVE || data.sessionStatus === SESSION_STATUS.CLOSING) {
        sendWebsocketMessage(socketMessage.events.MESSAGE, {
          data: {
            category: socketMessage.category.SESSION,
            action: socketMessage.subCategories.SESSION.GET_ALL_SESSION_DATA,
            sessionKey: sessionKey
          }
        })(store.dispatch, store.getState);
      }
      //(userRole === USER_ROLES.AGENT || userRole === USER_ROLES.ADMIN)

      break;
    case socketMessage.subCategories.SESSION._STARTED:
      store.dispatch({
        type: ACTIONS.SET_SESSION_STATUS,
        sessionStatus: SESSION_STATUS.ACTIVE
      });
      sendWebsocketMessage(socketMessage.events.MESSAGE, {
        data: {
          category: socketMessage.category.SESSION,
          action: socketMessage.subCategories.SESSION.GET_ALL_SESSION_DATA,
          sessionKey: sessionKey
        }
      })(store.dispatch, store.getState);
      break;
    case socketMessage.subCategories.SESSION._GET_ALL_SESSION_DATA:

      store.dispatch({
        type: ACTIONS.WHITEBOARD.SET_CANVAS_DATA,
        data: data.canvas
      });
      var participants = makeUserMediaTypeUniform(data.participants);
      store.dispatch({
        type: ACTIONS.SET_PARTICIPANTS,
        participants: participants
      });
      let roomMetaData = _.cloneDeep(data.roomMetaData);
      let currentAnnotation = data.roomMetaData.currentAnnotation
      let activeAnnotationDetails = {};
      if (currentAnnotation) {
        activeAnnotationDetails.annotation = currentAnnotation
        activeAnnotationDetails.isSelected = true;
      } else {
        activeAnnotationDetails.annotation = ""
        activeAnnotationDetails.isSelected = false;
      }
      // if active canvas type is SEARCH_RESULTS then remove selected annotation,
      // So user can not do any changes on SEARCH_RESULTS canvas
      // if active canvas is not SEARCH_RESULTS then set last used annotation
      if (data.canvas && data.canvas.activeCanvas && data.canvas.canvases?.[data.canvas.activeCanvas]?.canvasType === CANVAS_TYPES.SEARCH_RESULTS) {
        let removeAnnotationDetails = {}
        removeAnnotationDetails.isSelected = false
        removeAnnotationDetails.annotation = ""
        roomMetaData.activeAnnotationDetails = removeAnnotationDetails;
      } else {
        roomMetaData.activeAnnotationDetails = activeAnnotationDetails;
      }
      roomMetaData.prevAnnotationDetails = activeAnnotationDetails;
      store.dispatch({
        type: ACTIONS.SET_ROOM_META_DATA,
        roomMetaData: roomMetaData
      });
      store.dispatch({
        type: ACTIONS.CHAT.SET_MESSAGES,
        messages: data.chat
      });
      store.dispatch({
        type: ACTIONS.LOCATION.GET_ALL_LOCATION_DATA,
        eta: data.location.eta,
        participants: data.location.participants
      });
      //For late arrivals show popup same as _WEBRTC_PERMISSION_REQUEST
      if (
        data.roomMetaData.mediaType == mediaType.AUDIO ||
        data.roomMetaData.mediaType == mediaType.AUDIO_VIDEO
      ) {
        store.dispatch({
          type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION,
          webRtcPermissionReply: null
        });
        store.dispatch({
          type: ACTIONS.SET_WEBRTC_REQUEST_PERMISSION,
          webRtcPermissionRequest: data.roomMetaData.mediaType
        });
      }

      store.dispatch({
        type: ACTIONS.APP_EMULATION.APP_EMULATION_REQUEST,
        value: data.roomMetaData.isAppEmulationRunning
      });

      if(presenter !== uniqueId 
         && (roomMetaData.cobrowseState === CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED 
         || roomMetaData.cobrowseState === CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING)) {
        //check if cobrowse session is started set permission request true to join session
        store.dispatch({
          type: ACTIONS.CO_BROWSE.CO_BROWSE_REQUEST,
          value: true
        });
        
        if(roomMetaData.cobrowseState === CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING) {
          //if session is in running state set request flag to ask permission and 
          //status to started for call join api after accept permission
          // CO_BROWSE_SESSION_STATUS will be set to CO_BROWSE_RUNNING when cobrowse iframe gets loaded
          store.dispatch({
            type: ACTIONS.CO_BROWSE.SET_CO_BROWSE_SESSION_STATUS,
            value: CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED
          });
        }
      }
 
      /*
      * if any user in the participant list is on waiting screen before start session or  
      * on refresh at agent side make user latest updated participant
      */ 
      if(userRole === USER_ROLES.AGENT && data.participants.length > 1) {
        let participant = data.participants.filter((user)=>user.role === USER_ROLES.USER && 
        user.admissionStatus == USER_ADMISSION_STATUS.PENDING)
        if(participant.length > 0)
          store.dispatch({
            type: ACTIONS.UPDATE_PARTICIPANTS,
            participant: participant[0],
            updateReason: PARTICIPANT_UPDATE_REASON.WAITING
          });
      }

      // if snapshotee id is set that means snapshot is on so we have to set snapshot status to true 
      // but when agent refreshes snapshotee id will be reset because snapshot has stopped hence 
      // no need to set this flag when agent receives _GET_ALL_SESSION_DATA (refresh)
      if (data.roomMetaData.snapshot.isSnapshotRunning && roomMetaData.organiser !== uniqueId) {
        store.dispatch({
          type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_STARTED,
          value: true
        });

        // Snapshot for all participants: Set appropriate props to handle late arrival
        store.dispatch({
          type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_RUNNING,
          value: true
        });
        store.dispatch({
          type: ACTIONS.SET_SNAPSHOTEE_ID,
          snapshoteeId: data.roomMetaData.snapshot.snapshoteeId
        });
        store.dispatch({
          type: ACTIONS.SNAPSHOT.SET_ANNOTATION_MODE,
          value: data.roomMetaData.snapshot.snapshotAnnotationMode
        });

        let canvases = data.canvas.canvases;
        let canvasObjectKeyArray = _.keys(canvases);

        if(canvasObjectKeyArray && canvasObjectKeyArray.length > 0) {
          for(let i=0; i<canvasObjectKeyArray.length; i++) {
            let canvas = canvases[canvasObjectKeyArray[i]];
            if(canvas.canvasSource === CANVAS_SOURCE.SNAPSHOT) {
              store.dispatch({
                type: ACTIONS.SNAPSHOT.ADD_CANVAS,
                newCanvas: {
                  canvasId: parseInt(canvasObjectKeyArray[i], 10),
                  canvasType: canvas.canvasType,
                  canvasSource: canvas.canvasSource
                }
              });

              store.dispatch({
                type: ACTIONS.SNAPSHOT.SET_CANVAS_DATA,
                canvasData: canvas.canvasData
              });

              break;
            }
          }
        }
      } else if(!data.roomMetaData.snapshot.isSnapshotRunning) {
        // if the user and agent refresh the page simultaneously or user goes offline and the agent refresh the page
        // then reset the isDrawingModeEnabled and snapshot related data
        store.dispatch({
          type: ACTIONS.SNAPSHOT.IS_DRAWING_MODE_ENABLED,
          value: false
        });
        store.dispatch({
          type: ACTIONS.RESET_SNAPSHOT_DATA
        });
      }
      
      // send add canvas messaage if there is no canvas 
      if (!data.canvas.totalCanvas && roomMetaData.organiser === uniqueId) {
        //send 'add regular canvas' msg if agent
        sendWebsocketMessage(socketMessage.events.MESSAGE, {
          data: {
            category: socketMessage.category.WHITEBOARD,
            action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
            sessionKey: sessionKey,
            // token: reducerData.UserReducer.userDetails.token,
            canvasType: CANVAS_TYPES.REGULAR,
            canvasSource: CANVAS_SOURCE.REGULAR
          }
        })(store.dispatch, store.getState);
      }
      break;
      
    case socketMessage.subCategories.SESSION._END_SESSION:
      // Post End_session_without_confirmation from Presenter's end once agent end loookit session
      if (showCustomerCoBrowse && presenter == uniqueId) {
        postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl)
        setTimeout(() => {
          customerCoBrowseAction.endCustomerCoBrowse();
        }, 500);
      }
      store.dispatch({
        type: ACTIONS.SET_END_SESSION_FLAG,
        flag: true
      });
      store.dispatch({
        type: ACTIONS.USER_LEFT,
        value: true
      });
      break;
    case socketMessage.subCategories.SESSION._END_SESSION_FORCEFULLY:
      // Post End_session_without_confirmation from Presenter's end once when new loookit session started by agent on diff browser/tab(Restrict multiple session)
      if (showCustomerCoBrowse && presenter == uniqueId) {
        postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl)
        setTimeout(() => {
          customerCoBrowseAction.endCustomerCoBrowse();
          store.dispatch({
            type: ACTIONS.SET_END_SESSION_FORCEFULLY_FLAG,
            sessionClosedForcefully: true
          });
        }, 500);
      } else {
        store.dispatch({
          type: ACTIONS.SET_END_SESSION_FORCEFULLY_FLAG,
          sessionClosedForcefully: true
        });
      }
      store.dispatch({
        type: ACTIONS.USER_LEFT,
        value: true
      });
      break;
    case socketMessage.subCategories.SESSION._END_SESSION_AND_LOGOUT_FORCEFULLY:
      // Post End_session_without_confirmation from Presenter's end once when new loookit session started by agent on diff browser/tab(Restrict multiple session)
      if (showCustomerCoBrowse && presenter == uniqueId) {
        postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl)
        setTimeout(() => {
          customerCoBrowseAction.endCustomerCoBrowse();
          store.dispatch({
            type: ACTIONS.SET_END_SESSION_FLAG,
            flag: true
          });
        }, 500);
      } else {
        store.dispatch({
          type: ACTIONS.SET_END_SESSION_FLAG,
          flag: true
        });
      }
      store.dispatch({
        type: ACTIONS.USER_LEFT,
        value: true
      });
      break;
    case socketMessage.subCategories.SESSION._SESSION_DELETED:
      store.dispatch({
        type: ACTIONS.SET_SESSION_STATUS,
        sessionStatus: SESSION_STATUS.DELETED
      });
      break;
    case socketMessage.subCategories.SESSION._SESSION_UPDATED:
      store.dispatch({
        type: ACTIONS.SET_SESSION_STATUS,
        sessionStatus: SESSION_STATUS.UPDATED
      });
      break;
    case socketMessage.subCategories.SESSION._ALLOW_EDIT_ACCESS:
      store.dispatch({
        type: ACTIONS.SET_ALLOW_EDIT,
        participant: {
          participantId: data.participantId,
          allowEditAccess: data.allowEditAccess
        },
        updateReason: PARTICIPANT_UPDATE_REASON.ALLOW_EDIT_ACCESS
      });
      break;
    case socketMessage.subCategories.SESSION._AGENT_ARRIVED:
      store.dispatch({
        type: ACTIONS.SET_SESSION_STATUS,
        sessionStatus: SESSION_STATUS.ACTIVE
      })
      break
    default:
      break;
  }
};
