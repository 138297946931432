import React, { Component } from "react";
import { getMessage, changeLanguage as changeLang } from "CONFIG/i18n";
import { func } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Spinner from "COMPONENTS/Spinner/Spinner";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

import {
  getURLParameter,
  setCaretToEnd,
  checkEmpty,
  removeTrailingSlash,
  getStringWithoutExtraSpaces,
  isRealwearDevice,
} from "UTILS/Utility";
import { ERROR, PASSCODE_LENGTH, DEFAULT_LANGUAGE, KEY_CODE_ENTER } from "UTILS/Constants";

import { Row, Col, InputGroup, Input, Button } from "reactstrap";
import session_img from "../../Assets/Images/ic_start_session.svg";
import HeaderContainer from "COMPONENTS/Header/HeaderContainer";
import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";

import { sessionActions } from "CONFIG/ActionFactory";

//ASSETS
import icBar from "ASSETS/Images/ic_bar.svg"

import "./UserJoin.less"
class UserJoin extends Component {
  static propTypes = {
    handleProceed: func
  };
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      passcode: [],
      code0: "",
      code1: "",
      code2: "",
      code3: "",
      errorMessage: "",
      uriIdentifier: removeTrailingSlash(window.location.pathname).split("/")[1]
    };
  }

  componentDidMount() {
    // set if present in url
    const userName = getURLParameter("name");
    this.setState({
      userName: userName ? userName : ""
    });
    // focus the user name field if session key is present - join meeting using session link
    if(this.props.sessionKey) {
      document.getElementById('userName').focus();
    }
    if(DEFAULT_LANGUAGE !== this.props.language) {
      changeLang(DEFAULT_LANGUAGE)
      sessionActions.setLanguage(DEFAULT_LANGUAGE);
    }
    isRealwearDevice() && this.voiceCommandProceedHandler();
  }

  componentDidUpdate (prevProps){
    if(this.props.sessionKey !== prevProps.sessionKey){
      this.setState({errorMessage: ""})
      // focus the user name field if session key is present - join meeting using session link
      if(this.props.sessionKey) {
        document.getElementById('userName').focus();
      }
    }
    if(isRealwearDevice() && prevProps.language !== this.props.language) {
      this.voiceCommandProceedHandler();
    }
  }

  componentWillUnmount(){
    console.log("UserJoin componentWillUnmount")
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_PROCEED")
  }

  handleInputChange = event => {
    this.setState({
      userName: event.target.value,
      errorMessage: ""
    }) 
  }

  handlePasscodeChange = event => { 
    if(event.target.value !== " ") {
      let inputNumber = parseInt(event.target.name)
      if(isNaN(event.target.value) ){
        this.setState({
          errorMessage: "NOT_A_NUMBER_ERROR"
        }) } else {
        let passcode = this.state.passcode;
        passcode[inputNumber] = event.target.value;
        this.setState({
          [event.target.id]: event.target.value.trim(),
          passcode,
          errorMessage: ""
        })

        /*
        * move cursor to the next passcode input column if
        * value is entered in current input column and next input column is empty or previous column contains a value in it 
        */
        let nextInputfield = document.getElementById("code"+(inputNumber+1));
        let previousInputfield =  document.getElementById("code"+(inputNumber-1));
        event.target.value && 
        (!nextInputfield?.value || previousInputfield?.value) && nextInputfield?.focus() 
      }
    } else {
      this.setState({errorMessage: getMessage("SPACE_IS_NOT_ALLOWED")})
    }
  }

  onFocusHandle= (event) =>{
    setCaretToEnd(document.getElementById(event.target.id));
  }

  handleProceed = () => {
    if(this.props.sessionKey){
      if (!this.props.allowAnonymousParticipants && checkEmpty(this.state.userName.trim())) {
        this.setState({
          errorMessage: "ERR_MSG_YOUR_NAME"
        });
      } else {
        this.props.handleProceed(
          getStringWithoutExtraSpaces(this.state.userName),
          this.errorcallBack
        );
      }
    } else if(this.isValidJoinSessionData()) {

      let sessionDetails = {
        passcode: this.state.passcode.join(""),
        uriIdentifier: this.state.uriIdentifier
      };

      this.props.handleJoinWithPasscode(
        sessionDetails,
        getStringWithoutExtraSpaces(this.state.userName),
        this.errorcallBack
      ) 
    }
  };

  isValidJoinSessionData(){
    //check if organization name is present or not
    //check the passcode is not empty
    if(!this.state.passcode.join("").trim().length) {
      this.setState({errorMessage: "PASSCODE_CANNOT_BE_EMPTY"});
      return false;
    }
    //check the length of passcode is valid 
    if(this.state.passcode.length < PASSCODE_LENGTH) {
      this.setState({errorMessage: "PASSCODE_DIGIT_LENGTH_VALIDATION"});
      return false;
    }
    if(!this.props.allowAnonymousParticipants && checkEmpty(this.state.userName.trim())) {
      this.setState({
        errorMessage: "ERR_MSG_YOUR_NAME"
      });
      return false;
    }    
    return true;
  }
  
  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleProceed();
    }
  };

  errorcallBack = errorCode => {
    //If API fails this function get called with error code
    let errorMessage = errorCode;
    // UserJoin is always used for USER, so role would always be USER
    // that's why check is not needed for role to be USER here.
    if (errorCode === ERROR.SERVER_UNREACHABLE_ERROR) {
      errorMessage = "SERVER_UNREACHABLE_ERROR_USER";
    }
    this.setState({ errorMessage });
  }

  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
  };
  renderSprinner =()=> {
    return <Spinner showSpinner={this.props.spinnerVisibility} />;
  }

  keyPressHandle =event=> {
    let inputFieldNumber= parseInt(event.target.name);
    var code = event.keyCode || event.which;
    if(code == 37){
      event.preventDefault();
      document.getElementById("code"+(inputFieldNumber-1))?.focus();
    } else if(code == 39) {
      document.getElementById("code"+(inputFieldNumber+1))?.focus();
    } else if (code === KEY_CODE_ENTER) {
      this.handleProceed();
    }
  }

  voiceCommandProceedHandler = () => {  
    const proceed = {
      voiceCommand: "VOICE_COMMANDS_PROCEED",
      action: () => {
        this.handleProceed();
      }
    }  
    this.props.handleVoiceCommand(proceed)
  }

  passcodeInput =()=> {
    return(
      <div className="d-flex justify-content-center ml-md-5">
        <div className="col-3 col-sm-2 col-md-1 col-lg-1 px-1 px-sm-2 px-md-0 mx-sm-2">
          <input 
            id="code0"
            type="tel"
            className="w-100 text-center py-2 user-join-passcode-digit passcodeDigit form-control"
            name="0" 
            value={this.state.code0}
            onChange={this.handlePasscodeChange}
            onKeyDown={this.keyPressHandle}
            maxLength={1}
            onFocus={this.onFocusHandle}
            autoFocus/>
        </div>
        <div className="col-3 col-sm-2 col-md-1 col-lg-1 px-1 px-sm-2 px-md-0 mx-sm-2">
          <input 
            id="code1"
            type="tel"
            className="w-100 text-center py-2 user-join-passcode-digit passcodeDigit form-control"
            name="1" 
            value={this.state.code1}
            onChange={this.handlePasscodeChange}
            onKeyDown={this.keyPressHandle}
            maxLength={1}
            onFocus={this.onFocusHandle}/>
        </div>
        <div className="col-3 col-sm-2 col-md-1 col-lg-1 px-1 px-sm-2 px-md-0 mx-sm-2">
          <input 
            id="code2"
            type="tel"
            className="w-100 text-center py-2 user-join-passcode-digit passcodeDigit form-control"
            name="2" 
            value={this.state.code2}
            onChange={this.handlePasscodeChange}
            onKeyDown={this.keyPressHandle}
            maxLength={1}
            onFocus={this.onFocusHandle}/>
        </div>
        <div className="col-3 col-sm-2 col-md-1 col-lg-1 px-1 px-sm-2 px-md-0 mx-sm-2">
          <input 
            id="code3"
            type="tel"
            className="w-100 text-center py-2 user-join-passcode-digit passcodeDigit form-control"
            name="3" 
            value={this.state.code3}
            onChange={this.handlePasscodeChange}
            onKeyDown={this.keyPressHandle}
            maxLength={1}
            onFocus={this.onFocusHandle}/>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSprinner()}
        <HeaderContainer/>
        <ChangeLanguage
          onSelectChange={this.onSelectChange}
          language={this.props.language}
        />
        <Row className="joinSession marginAuto">
          <div className="px-2 pb-2">
            <div className="noMargin">
              <Row className="text-center pt-5 justifyContentFlexCenter w-100 noMargin">
                <img
                  src={session_img}
                  className="img-responsive session-img"
                  alt={getMessage("JOIN_SESSION")}
                />
              </Row>
            </div>
            <Row className="noMargin text-center justifyContentFlexCenter w-100">
              <p className="sessionName">{getMessage("JOIN_SESSION")}</p>
            </Row>
            <div className="card-body px-1 px-sm-4 pt-4 offset-xl-2 col-xl-8 flex flex-column pb-3 pb-xl-0">
              { !this.props.sessionKey &&
                <div className="d-flex align-items-center pb-2 title justify-content-start">
                  <img src={icBar} className="mr-2"/>
                  {getMessage("ENTER_PASSCODE_MESSAGE")}
                </div>
              }
              <div className="container">
                <div className="d-sm-flex justify-content-cente">
                  <div className="col-12 col-lg-11">
                    { !this.props.sessionKey &&this.passcodeInput()}
                  </div>
                </div>
                <div className="d-sm-flex justify-content-center mt-4">
                  <InputGroup className={!this.props.sessionKey?"col-12 px-0 px-sm-2 col-sm-9 offset-sm-2 col-md-6 offset-md-2 col-lg-6 offset-lg-1 pl-md-5 col-xl-6 offset-xl-1":"px-sm-2"}>
                    <Input
                      id="userName"
                      type="text"
                      onChange={this.handleInputChange}
                      name="userName"
                      placeholder={getMessage("ENTER_NAME_PLACEHOLDER") }
                      aria-label="Enter name"
                      className="formControlName form-controll rounded-pill border"
                      value={ this.state.userName }
                      maxLength={50}
                      onKeyPress={this.enterPressed}
                    />
                  </InputGroup> 
                  <div className="col-12 px-sm-0 col-sm-2 pt-4 pt-sm-0">
                    <Button
                      className={!this.props.sessionKey ? "proceedBtn customBtn w-100":"proceedBtn customBtn w-100"}
                      onClick={this.handleProceed}
                    >
                      {getMessage("PROCEED")}
                    </Button>
                  </div>
                </div>
              </div> 
              <div className="col-sm-11 pr-sm-4 px-0">
                <div className="errors">{getMessage(this.state.errorMessage,{maxNoOfDigit: PASSCODE_LENGTH})}</div>  
              </div>
            </div>

            <Col xs={12} md={12} className="text-center">
            </Col> 
          </div>
          {/* {!isIE && !isFirefox &&
                <div
                  className="d-flex justify-content-center"
                >
                  <span  
                    className="testPageLink cursorPointer text-center"
                    onClick={() => {
                      window.open('/testPage', '_blank');
                    }}>
                    {getMessage("TEST_PAGE")}
                  </span>
                </div>
            }  */}
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setLanguageAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserJoin);
