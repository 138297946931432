import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getMessage } from "CONFIG/i18n";
import * as log from "loglevel";
import { sessionActions as actionFactory } from "CONFIG/ActionFactory";
import { inviteeAction as actionfactory } from "CONFIG/ActionFactory";
import { audioVideoAction } from "CONFIG/ActionFactory";
import EvictParticipantConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import QRCode from "qrcode.react";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

// import { selectSessionKey } from "../../Routes/selectors";

import "../AddInvitee/AddInvitee.less";
import "./WaitingArea.less";
import AddInvitee from "../AddInvitee/AddInvitee";
import InviteHistory from "../AddInvitee/InviteHistory";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import WaitingAreaModal from "./WaitingAreaModal";

import ic_attendee_audio_on_disabled from "ASSETS/Images/ic_attendee_audio_on_disabled.svg";
import ic_attendee_audio_off_disabled from "ASSETS/Images/ic_attendee_audio_off_disabled.svg";
import ic_attendee_audio_on from "ASSETS/Images/ic_attendee_audio_on.svg";
import ic_attendee_audio_off from "ASSETS/Images/ic_attendee_audio_off.svg";
import ic_attendee_video_on from "ASSETS/Images/ic_attendee_video_on.svg";
import ic_attendee_video_off from "ASSETS/Images/ic_attendee_video_off.svg";
import ic_attendees from "ASSETS/Images/ic_attendees.svg";
import ic_attendee_video_on_disabled from "ASSETS/Images/ic_attendee_video_on_disabled.svg";
import ic_attendee_video_off_disabled from "ASSETS/Images/ic_attendee_video_off_disabled.svg";
import waitingRoom from "ASSETS/Images/Waiting_Room.svg";
import ic_host from "ASSETS/Images/ic_host.svg";
import ic_close_waiting_room from "ASSETS/Images/ic_close_waiting_room.svg";
import ic_presenter from "ASSETS/Images/ic_presenter.svg";
import ic_co_browse from "ASSETS/Images/ic_co_browse_waiting_room.svg";

// CONSTANTS
import { USER_ADMISSION_STATUS, USER_ROLES, PARTICIPANT_STATUS, LANGUAGE } from "UTILS/Constants";
import { mediaType } from "WEBSOCKET/constants";
import { isIE, isMobile } from "react-device-detect";
import { mediaStatus } from "WEBSOCKET/constants";
import { wsocket } from "WEBSOCKET/wsocket";

import { getUserNameForSelectedLanguage } from "UTILS/Utility";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

import Participants from "../CommonComponents/Header/Participants/Participants";

class WaitingArea extends Component {
    static propTypes = {
      show: bool,
      addInviteeHandle: func,
    };
    constructor(props) {
      super(props);
      this.state = {
        addInviteeManual: false,
        addInviteeHistory: false,
        currentSessionInvitees: [],
        buttonIndex: null,
        participantButtonIndex: null,
        showEvictParticipantConfirmationModal: false,
        evictParticipantId: null
      };
    }
  
    toggle = () => {
      this.props.showWaitingArea();
    };

    makePresenter = (participant) => {
      this.props.makePresenter(participant);
    };

    addInviteeManualHandle = () => {
      this.toggle();
      this.setState({ addInviteeManual: !this.state.addInviteeManual });
    };

    addInviteeHistoryHandle = () => {
      this.toggle();
      this.setState({ addInviteeHistory: !this.state.addInviteeHistory });
    };
    handleSendClick = (invitees, value) => {
      if (value) {
        this.setState({ addInviteeManual: !this.state.addInviteeManual });
      } else {
        this.setState({ addInviteeHistory: !this.state.addInviteeHistory });
      }
      actionFactory
      .inviteUser(this.props.sessionKey, invitees, this.props.language)
      .then(
        (data) => {
          toastr.info("INVITATION_SENT");
          log.info("Data:", data);
        },
        (error) => {
          toastr.info("INVITATION_NOT_SENT");
          log.error("ERROR: ", error);
        }
      );
    };

    handleAllowEdit = (allowEditAccess,participantId) => {
      // Only agent can change edit permission
      if(this.props.uniqueId === this.props.organiser){
        this.props.handleAllowEdit(allowEditAccess, participantId)
      }
    }

    handleAddInviteHistory = () => {
      actionfactory.addInviteeAction(this.props.sessionKey).then(
        (data) => {
          if (
            data.data.invitees === undefined ||
                    data.data.invitees.length === 0
          ) {
            toastr.info("NO_INVITEES");
          } else {
            this.addInviteeHistoryHandle();
            this.setState({
              currentSessionInvitees: data.data.invitees,
            });
          }
        },
        (error) => {
          log.error("ERROR: ", error);
        }
      );
    };

    handleManualEntry = () => {
      return (
        <AddInvitee
          show={this.state.addInviteeManual}
          addInviteeManualHandle={this.addInviteeManualHandle}
          handleSendClick={this.handleSendClick}
        />
      );
    };
    handleInviteHistory = () => {
      return (
        <InviteHistory
          show={this.state.addInviteeHistory}
          addInviteeHistoryHandle={this.addInviteeHistoryHandle}
          currentSessionInvitees={this.state.currentSessionInvitees}
          handleSendClick={this.handleSendClick}
        />
      );
    };

    showButton = (e, index) => {
      this.setState({ buttonIndex: index });
    };

    viewParticipantsButtons = (index) => {
      this.setState({ participantButtonIndex: index });
    };

    onOpenEvictParticipantConfirmationModal = (showConfirmationModal, participantId) => {
      this.setState({
        showEvictParticipantConfirmationModal: showConfirmationModal,
        evictParticipantId: participantId
      })
    }

    getAdmittedParticipantRow = (participant, index) => {
      const japaneseMobileView =isMobile && this.props.language === LANGUAGE.JA.name;
      if(participant) {
        try {
          let participantName = participant.role === USER_ROLES.AGENT
            ? getUserNameForSelectedLanguage(this.props.language, participant.userName)
            : participant.userName;
          return (
            <Row
              className="alignParticipants mx-0"
              key={index + "12"}
              onMouseOverCapture = {() =>
                this.viewParticipantsButtons(
                  index
                )
              }
              onMouseLeave = { () =>
                this.viewParticipantsButtons(
                  null
                )
              }
            >
              <Col className={"d-flex flex-row px-0 col-xs-12 col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5"}>
                {/* agent or user icon */}
                <img
                  src={
                    this.props.presenter === participant.uniqueId
                      ? ic_presenter
                      : participant.role === USER_ROLES.AGENT
                        ? ic_host
                        : ic_attendees
                  }
                  alt = ""
                />
                {/* user name */}
                <Col 
                  className={`${ participant.role === USER_ROLES.AGENT  ? "agentText" : "" } userNameText pr-4` }
                >
                  <span className="custom-tooltip" id={`participantName${index}`}>
                    {participantName}
                    <CustomTooltip tooltipText={participantName} tooltipId={`participantName${index}`} />
                  </span>                  
                </Col>                
              </Col>

              <Col className={"col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 flex-row participantsOptions " + (
                this.props.isOrganiser
                  ? " col-12 px-0"
                  : isIE ? "col-12 pl-0" : "col-12 px-0")
              }>
                <Row className="w-100 mx-0 flex-row participantsOptions">
                  
                  <div className={(this.props.isOrganiser || isMobile ? "" : "pl-2 ") + " justify-content-between pl-0 pr-0 col-12 d-flex flex-row"}>
                    <div className={isMobile ? "marginAudioVideoMobile audio-video-buttons" : "audio-video-buttons"}>
                      {
                        (participant.mediaType === mediaType.AUDIO
                          || participant.mediaType === mediaType.AUDIO_VIDEO) && (
                          <div className={"d-flex"}
                          >
                            <img
                              src={participant.audioStatus === mediaStatus.UNMUTED
                                ? this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId
                                  ? ic_attendee_audio_on
                                  : ic_attendee_audio_on_disabled
                                : this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId
                                  ? ic_attendee_audio_off
                                  : ic_attendee_audio_off_disabled
                              }
                              alt=""
                              id={`muteUnmuteAudio${index}`}
                              className={this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId && "cursorPointer waitingAreaIcons"}
                              onClick={(e) => {
                                // restrict click of audio/video box
                                if (this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId) {
                                  e.stopPropagation()
                                  audioVideoAction.toggleMedia(
                                    this.props.sessionKey,
                                    mediaType.AUDIO,
                                    participant.audioStatus === mediaStatus.UNMUTED
                                      ? mediaStatus.MUTED_BY_AGENT
                                      : mediaStatus.UNMUTED,
                                    // participant.audioStatus,
                                    participant.uniqueId
                                  )
                                }
                              }
                              }
                            />
                            {this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId &&
                              <CustomTooltip tooltipText={this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId ?
                                participant.audioStatus === mediaStatus.UNMUTED
                                  ? getMessage("MUTE_AUDIO")
                                  : getMessage("UNMUTE_AUDIO") : ""} tooltipId={`muteUnmuteAudio${index}`} />}
                          </div>
                        )
                      }

                      {
                        this.isVideoBlockShow() &&
                        <div className={"pr-1 pl-1 d-flex "}>
                          {
                            participant.mediaType === mediaType.AUDIO_VIDEO &&
                            <>
                              <img
                                src={participant.videoStatus === mediaStatus.UNMUTED
                                  ? this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId
                                    ? ic_attendee_video_on
                                    : ic_attendee_video_on_disabled
                                  : this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId
                                    ? ic_attendee_video_off
                                    : ic_attendee_video_off_disabled
                                }
                                alt=""
                                id={`muteUnmuteVideo${index}`}
                                className={this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId && "cursorPointer waitingAreaIcons"}
                                onClick={(e) => {
                                  // restrict click of audio/video box
                                  if (this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId) {
                                    e.stopPropagation()
                                    audioVideoAction.toggleMedia(
                                      this.props.sessionKey,
                                      mediaType.AUDIO_VIDEO,
                                      participant.videoStatus === mediaStatus.UNMUTED
                                        ? mediaStatus.MUTED_BY_AGENT
                                        : mediaStatus.UNMUTED,
                                      // participant.videoStatus,
                                      participant.uniqueId
                                    )
                                  }
                                }}
                              />
                              {this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId &&
                                <CustomTooltip tooltipText={this.props.isOrganiser && participant.uniqueId !== this.props.uniqueId ?
                                  participant.videoStatus === mediaStatus.UNMUTED
                                    ? getMessage("MUTE_VIDEO")
                                    : getMessage("UNMUTE_VIDEO") : ""} tooltipId={`muteUnmuteVideo${index}`} />
                              }
                            </>

                          }
                        </div>
                      }
                    </div>
                    <div className="manage-participants-buttons">
                      {(index === this.state.participantButtonIndex || isMobile) && (
                        <div
                          className={(this.props.isOrganiser && this.props.uniqueId === participant.uniqueId ? "makePresenterButtonForAgent" : "mr-2") + " pl-1"}
                        >
                          {this.props.presenter !== participant.uniqueId &&
                            participant.token &&                    // Token check added to avoid make user presenter before user arrived
                            this.props.isOrganiser && (
                            <Button
                              onClick={() => {
                                this.makePresenter(
                                  participant
                                );
                              }}
                              className={japaneseMobileView ? "japanese-admit-button" : "admitButton"}
                            >
                              {getMessage("MAKE_PRESENTER")}
                            </Button>
                          )}
                        </div>
                      )}

                      <div>
                        {(participant.uniqueId !== this.props.organiser &&
                          participant.token &&                              // Token check added to avoid evict user before user arrived 
                          this.props.isOrganiser
                          && (index === this.state.participantButtonIndex || isMobile)) &&
                          <Button
                            className={japaneseMobileView ? "japanese-admit-button" : "admitButton"}
                            onClick={() => {
                              this.onOpenEvictParticipantConfirmationModal(true, participant.participantId)
                            }}
                          >
                            {getMessage("EVICT")}
                          </Button>
                        }
                      </div>
                      {/* mute / unmute  audio / video icons if audio / video call is going on */}
                      {this.isCobrowseBlockShow() &&
                        <div className="pr-1 pl-1 d-flex align-items-center justify-content-center">
                          {
                            (participant.cobrowseStatus || participant.customerCoBrowseStatus) &&
                            <img
                              src={ic_co_browse}
                              alt=""
                              className=""
                            />
                          }
                        </div>
                      }          
                    </div>
                    {((participant.uniqueId !== this.props.organiser
                      && this.props.isOrganiser
                      && participant.allowEditAccess !== undefined)
                      || (this.props.uniqueId === participant.uniqueId
                        && participant.uniqueId !== this.props.organiser
                        && participant.allowEditAccess)) &&
                      <div className="allow-edit-switch d-flex justify-content-center">
                        <Switch
                          switchOn={participant.allowEditAccess}
                          disabled={!this.props.isOrganiser}
                          toggleSwitch={(allowEdit) => this.handleAllowEdit(allowEdit, participant.uniqueId)}
                        />
                      </div>
                    }
                  </div>
                  {/* {index === this.state .participantButtonIndex && (
                  <div
                    className="pr-1"
                  >
                    <Button className="connectButton">
                      {
                        "Connect via phone"
                      }
                    </Button>
                  </div>
                )} */}

                </Row>
              </Col>
            </Row>
          );
        } catch(error) {
        // supressing the error since participants list gets empty abruptly when agent refreshes the page
          console.log("Error while rendering admitted participants..supressing");
          return "";
        }
      }
    }

    //reserv the cobrowse space for all participant when one of the participant shown cobrowse icon
    isCobrowseBlockShow = () => {
      let showCobrowseBlock = false
      this.props.admittedParticipants.forEach(participant => {
        if(participant.cobrowseStatus || participant.customerCoBrowseStatus){
          showCobrowseBlock = true;
          return;
        }
      });
      return showCobrowseBlock;
    }

    //reserv the video icon space for all participant when one of the participant shows video icon
    isVideoBlockShow = () =>{
      let showVideoBlock = false;
      this.props.admittedParticipants.forEach(participant => {
        if(participant.mediaType === mediaType.AUDIO_VIDEO){
          showVideoBlock = true;
        }
      });
      return showVideoBlock;
    }

    getWaitingParticipantRow = (user, index) => {
      try {
        const japaneseMobileView = isMobile && this.props.language === LANGUAGE.JA.name;
        return (
          <Row
            className="alignWaitingUser mx-0"
            key={index}
            onMouseOverCapture={(e) =>
              this.showButton(e, index)
            }
            onMouseLeave={(e) =>
              this.showButton(e, null)
            }
          >
            <Col md={1} lg={1} sm={1} xs={1}
              className="justify-content-left justify-content-md-center px-0 waitingIcon"
            >
              <img
                src={waitingRoom}
                alt=""
                // className="pl-2 pr-2"
              />
            </Col>
            <Col md={7} lg={7} sm={7} xs={11}
              className="userNameText pl-0"
            >
              <span className="custom-tooltip" id= {`userName${index}`}>
                {user.userName}
                <CustomTooltip tooltipText={user.userName} tooltipId={`userName${index}`} />
              </span>             
            </Col>
            <Col
              className="pull-right pr-0 pl-0"
            >
              <div className={`pull-right ${(japaneseMobileView) ? "mr-2" : "" }`}>
                {(this.state.buttonIndex === index || isMobile) && (
                  <Button
                    className={`ml-3 mr-2 ${ (japaneseMobileView) ? "japanese-admit-button" : "admitButton"}`}
                    onClick={() =>
                      this.props.admitOrRejectUserInSession(
                        true,
                        user.uniqueId,
                        user.userName
                      )
                    }
                  >
                    {getMessage("ADMIT")}
                  </Button>
                )}
                {(this.state.buttonIndex === index || isMobile) && (
                  <Button
                    className={`ml-2  ${(japaneseMobileView) ? "dismiss-button-japanese" :"dismissButton"}`} 
                    onClick={() =>
                      this.props.admitOrRejectUserInSession(
                        false,
                        user.uniqueId,
                        user.userName
                      )
                    }
                  >
                    {getMessage("DISMISS")}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        );
      } catch(error) {
        // supressing the error since participants list gets empty abruptly when agent refreshes the page
        console.log("Error while rendering waiting participants..supressing");
        return "";
      }
    }

    render() {
      let participant = null;
      let waitingParticipants = this.props.allParticipants.filter(
        (participant) =>
          participant.admissionStatus == USER_ADMISSION_STATUS.PENDING && participant.userName &&
                (participant.status === PARTICIPANT_STATUS.ACTIVE ||
                    participant.status === PARTICIPANT_STATUS.WAITING) &&
                    participant.userName
      );
      let admittedParticipants = this.props.allParticipants.filter(
        (participant) =>
          participant.admissionStatus == USER_ADMISSION_STATUS.ADMITTED && participant.userName &&
                participant.status === PARTICIPANT_STATUS.ACTIVE 
      );
      // console.log("admittedParticipants before:", admittedParticipants);
      if(admittedParticipants.length > 1) {
        let agentIndex = _.findIndex(admittedParticipants, 
          participant => participant && participant.role === USER_ROLES.AGENT);
        if(agentIndex !== -1) {
          let agentParticipant = admittedParticipants[agentIndex];
          admittedParticipants.splice(agentIndex, 1);
          admittedParticipants.push(agentParticipant);
        }

        // Get participant i.e. user to display 'Allow Edit' title if allowEditAccess = true
        let participantIndex = _.findIndex(admittedParticipants, 
          participant => participant && participant.uniqueId === this.props.uniqueId);
        participant = admittedParticipants[participantIndex];
      }
      // console.log("admittedParticipants after:", admittedParticipants);
      return (
        <div className="popUp waitingArea">
          {this.handleManualEntry()}
          {this.handleInviteHistory()}
          {this.props.show &&
            <WaitingAreaModal
              show={this.props.show}
              waitingParticipants={waitingParticipants}
              isOrganiser={this.props.isOrganiser}
              toggle={this.toggle}
              participant={participant}
              uniqueId={this.props.uniqueId}
              admittedParticipants={admittedParticipants}
              addInviteeManualHandle={this.addInviteeManualHandle}
              getAdmittedParticipantRow={this.getAdmittedParticipantRow}
              handleAddInviteHistory={this.handleAddInviteHistory}
              sessionLink={this.props.sessionLink}
              getWaitingParticipantRow={this.getWaitingParticipantRow}
            />
          }
          {this.state.showEvictParticipantConfirmationModal &&
            <EvictParticipantConfirmationModal
              show={true}
              isDoubleButton={true}
              handleNoClick={() => { this.onOpenEvictParticipantConfirmationModal(false, null) }}
              handleYesClick={() => {
                this.props.evictAction(this.state.evictParticipantId);
                this.onOpenEvictParticipantConfirmationModal(false, null);
              }}
              message={getMessage("EVICT_PARTICIPANT_CONFIRMATION_TEXT")}
            />
          }
        </div>
      );
    }
}
const mapStateToProps = (state) => {
  return {
    // sessionKey: selectSessionKey(state)
    language: state.LanguageReducer.language,
  };
};
export default connect(mapStateToProps)(WaitingArea);
