import React, { Component } from "react";
import { connect } from "react-redux";
import { getMessage } from "CONFIG/i18n";
import { Button, Container, Row, Col } from "reactstrap";
import moment from "moment";
import Spinner from "COMPONENTS/Spinner/Spinner";
import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";
import { CONFIG, ERROR, USER_ROLES, USER_ADMISSION_STATUS, SESSION_STATUS } from "UTILS/Constants";
import { sessionActions as actionFactory, organisationAction } from "CONFIG/ActionFactory";
import { selectSessionKey, selectEndUserToken, selectUserName } from "../MainLayout/selectors";
import { selectQRCode, selectUserWaitingEndTime } from "../QRCode/QRCodeComponent/QRCodeSelector";
import { leaveSessionAction } from "CONFIG/ActionFactory";
import * as log from "loglevel";

class ReJoinContainer extends Component {
  constructor(props) {
    super(props);
    log.setLevel("error");
  }
  state = {
    errorMessage: "",
    spinnerVisibility: false
  };
  componentDidMount() {
    console.log("############# ReJoinContainer componentDidMount");
    // Get sessionKey to Join
    // Clear all endpoint and reducers
    leaveSessionAction.localSessionAllDataCleanUp();

    // this timeout is needed so that axios interceptor is registered in withErrorHandler hoc before API call
    // and it is able to show intercepted errors 
    setTimeout(() => {
      this.handleProceed();
    });
  }

  componentWillUnmount() {
    console.log("############# ReJoinContainer componentWillUnmount");
  }

  renderSprinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  handleProceed = () => {
    console.log("########## RejoinContainer handleProceed");
    let userWaitingTime = this.props.userWaitingEndTime? this.props.userWaitingEndTime - moment().valueOf(): null;
    /*
    * avoid the join session action call 
    * when QR code session end time is expired 
    */
    if(this.props.qrId && userWaitingTime !== null && userWaitingTime <= 0) {
      /*
      * navigate to the screen where 
      * "Sorry, all our staff are currently busy.",
      * "We will contact you using the information provided. Thank you!"
      * message displayed 
      */
      this.props.history.push(`${CONFIG.partialPath.qr}/${this.props.qrId}`);
    } else {
      this.setState({spinnerVisibility: true});
      //TO-DO : have to add username in parameter
      actionFactory.joinSessionAction(this.props.sessionKey, this.props.uriIdentifier, this.props.userName, true ,this.props.endUserToken).then(
        response => {
          console.log("########## RejoinContainer joinSessionAction");
          organisationAction.setLogoUrl(response.data.data.logoUrl);
          this.setState({spinnerVisibility: false});    
          if(response.data.data.admissionStatus === USER_ADMISSION_STATUS.ADMITTED) {
            this.props.history.push(CONFIG.path.dashboard);
          } else {
            if(response.data.data.sessionType === "QR_CODE") {
              if(response.data.data.sessionStatus === SESSION_STATUS.ACTIVE) {
                this.props.history.push(CONFIG.path.waitingRoom);
              } else {
                this.props.history.push(`${CONFIG.partialPath.qr}/${this.props.qrId}`);
              }
            } else {
              this.props.history.push(CONFIG.path.waitingRoom);
            }
          }
        },
        error => {
          let errorMessage = error.code;
          if (error.code === ERROR.SERVER_UNREACHABLE_ERROR && this.props.role === USER_ROLES.USER) {
            errorMessage = "SERVER_UNREACHABLE_ERROR_USER";
          }
          if (error.code === ERROR.NOT_AUTHORIZED && this.props.role === USER_ROLES.AGENT) {
            errorMessage = "SESSION_EXPIRED";
          } 
          if (error.code === ERROR.NOT_AUTHORIZED && this.props.role === USER_ROLES.USER) {
            errorMessage = "SESSION_CLOSED";
          } 
          this.setState({
            errorMessage,
            spinnerVisibility: false
          });
          if(error.code === ERROR.INVALID_SESSION) {
            this.props.history.push(CONFIG.path.sessionEnded)
          }
          if(error.code == ERROR.USER_EVICTED_BY_AGENT) {
            this.props.history.push(CONFIG.path.userEvicted)
          }
        }
      );
    }
  };

  render() {
    return (
      <div>
        {this.renderSprinner()}
        <section className="bgColor">
          <Container>
            <Row>
              <Col md="12" lg="12">
                <h4 className="text-center pt-5">{getMessage("REJOINING")}</h4>
                {/* {this.state.errorMessage ? (
                  <Col className="text-center">
                    <div className="errors ">
                      <label className="pt-lg-2">
                        {/* {this.state.errorMessage} */}
                {/* {getMessage("PLEASE_TRY_AGAIN")} 
                      </label>
                    </div>
                  </Col>
                ) : null} */}
                <Row className="pt-2">
                  <Col className="text-center">
                    <Button
                      className="customBtn rejoinBtn"
                      onClick={this.handleProceed}
                    >
                      {getMessage("TRY_AGAIN")}
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col md="6" className="text-center">
                    <div className="errors">
                      <label className="pt-lg-4 pt-md-4">
                        {getMessage(this.state.errorMessage)}
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    sessionKey: selectSessionKey(state),
    endUserToken: selectEndUserToken(state),
    qrId: selectQRCode(state),
    uriIdentifier: state.UserReducer.uriIdentifier,
    userName: selectUserName(state),
    userWaitingEndTime: selectUserWaitingEndTime(state)
  };
};
export default withErrorHandler(connect(mapStateToProps)(ReJoinContainer));
