import { ACTIONS, DEFAULT_LANGUAGE } from "UTILS/Constants";

const initialState = {
  language: DEFAULT_LANGUAGE
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LANGUAGE:
      return { ...state, language: action.language };

    default:
      return state;
  }
};
