import React, { Component } from "react";
import { Container, Row, Col, Card, CardImg, CardBody } from "reactstrap";
import { isIE, isMobileOnly, withOrientationChange, isMobile as isMobileDevice, isTablet, isIPad13 } from "react-device-detect";

import WhiteboardContainer from "COMPONENTS/Whiteboard/WhiteboardContainer";
import AudioVideoContainer from "COMPONENTS/Video/AudioVideoContainer";
import ScreenShareContainer from "COMPONENTS/ScreenShare/ScreenShareContainer";
import CoBrowseContainer from "COMPONENTS/CoBrowse/CoBrowseContainer";
import CustomerCoBrowseContainer from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseContainer"

import TotalBitRate from "../CommonComponents/BitRate/TotalBitRate";
import { headerAction } from "CONFIG/ActionFactory";
import CanvasList from ".././Whiteboard/CanvasList/CanvasList";
import { COMPONENT_IN_FOCUS, CANVAS_TYPES, USER_ADMISSION_STATUS, PARTICIPANT_STATUS } from "UTILS/Constants";
import { canvasToBlob, isWebRTCPluginInstalled, isMobileOrIpadPortrait, isDesktopAndTabletIpadPortrait } from "UTILS/Utility";
import ic_add_canvas from "ASSETS/Images/ic_add_canvas.svg";

import { mediaType } from "WEBSOCKET/constants";

// Strings
import { getMessage } from "CONFIG/i18n";

import "./Dashboard.less";
import "COMPONENTS/App/App.less";
import "../Whiteboard/Whiteboard";
import "../Whiteboard/CanvasList/CanvasList";
import IconsPositionBar from "../Video/IconsPositionBar";

// Assets 
import audio_off from "ASSETS/Images/ic_audio_off.svg";
import audio_on from "ASSETS/Images/ic_audio_on.svg";
import video_on from "ASSETS/Images/ic_video_on.svg";
import video_off from "ASSETS/Images/ic_video_off.svg";
import sound from "ASSETS/swiftly-610.mp3";
import { postMesageToCoBrowseIframeUtility } from "../../Utils/Utility";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibilityCanvasList: false,
      spinnerVisibility: false,
      participant: null,
      pluginInstalled: isIE ? false : true,
    };
    this.sound=new Audio(sound)
    this.isThumbnailBeingUploaded = false;
    this.stickyScrollInterval = null;
  }

  componentDidMount() {
    //play sound on mute to cache the sound 
    this.sound.muted = true;
    //Check if any participant is in pending state
    if (this.props.latestUpdatedParticipant?.admissionStatus === USER_ADMISSION_STATUS.PENDING) {
      this.playUserArrivalSound();
    } 
    if (isIE) {
      this.setAnnotationBarToBottom();

      window.addEventListener('resize', () => {
        clearTimeout(this.stickyScrollInterval);
        this.stickyScrollInterval = setTimeout(() => {
          this.setAnnotationBarToBottom();
        }, 300);
      });
    }
    this.setScreenShareTopMargin();
    window.addEventListener("resize", () => {
      this.setScreenShareTopMargin();
    });
  }

  renderCanvasList = () => {
    return (
      // Canvas list start 
      <Col
        lg={this.shouldShiftWhiteboardToLeft() ? "9" : { offset: "1", size: "9" }}
        className={
          "order-3 " +
          (this.shouldShiftWhiteboardToLeft()
            ? "noPadding" +
            (this.props.organiser !== this.props.uniqueId
              ? " hideOnMobile"
              : "")
            : "noPadding whiteboardConatinerCol" +
            (this.props.organiser !== this.props.uniqueId
              ? " hideOnMobile"
              : ""))}
      >
        <Col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className={
            "noPadding restoreCanvasListWidth canvasListHandle ml-0" +
            (this.props.organiser !== this.props.uniqueId
              ? " hideOnMobile"
              : "")
          }
        >
          <div sm={9}
            className={this.shouldShiftWhiteboardToLeft()
              ? "scrollY addCanvasDiv canvasDivOnLargeVideo scroll-decoration"
              : "scrollY addCanvasDiv scroll-decoration"
            }
            id="scrollY"
          >
            {this.props.organiser === this.props.uniqueId &&
            !this.props.showCustomerCoBrowse &&
            !this.props.showSnapshot && (
            // add canvas card in Canvas List starts
              <div className="paddingPoint2em">
                <Card
                  onClick={() => {
                    // this.removeSpotLight();
                    // this.removePdfHighLight();
                    !this.props.snapshoteeId && !this.props.showCustomerCoBrowse &&
                    this.props.addCanvas(CANVAS_TYPES.REGULAR)
                  }}
                  className={"buttonCard alignItemsCenter justifyContentFlexCenter "
                    + (this.props.snapshoteeId || this.props.showCustomerCoBrowse? "disable" : "cursorPointer")
                  }
                >
                  <CardBody className="addCanvas noPadding">
                    <CardImg
                      src={ic_add_canvas}
                      alt="add"
                      className="addButton"
                    />
                  </CardBody>
                  <span className="cardText ">
                    {getMessage("ADD_CANVAS")}
                  </span>
                </Card>
              </div>
              // add canvas card in Canvas List ends
            )}
            {
              // Canvas List cards start
              (!isMobileOnly || (this.props.organiser == this.props.uniqueId)) &&
              <CanvasList
                canvases={this.props.canvases}
                changeActiveCanvas={(canvasId, canvasType, uploadThumbnailFlag, callback) => {
                  // this.removeSpotLight();
                  // this.removePdfHighLight();
                  setTimeout(() => {
                    this.changeActiveCanvas(canvasId, canvasType, uploadThumbnailFlag, callback);
                  }, 0)
                }}
                deleteCanvas={this.props.deleteCanvas}
                activeCanvas={this.props.canvasId}
                activeCanvasFileUrl={this.props.activeCanvasData.fileUrl}
                onCanvasSwitched={this.onCanvasSwitched}
                hideCanvasList={this.props.showCustomerCoBrowse}
                enableControls={
                  this.props.organiser === this.props.uniqueId
                    && !this.props.showSnapshot 
                    && !this.props.showCustomerCoBrowse 
                }
                enableCloseIcon={
                  this.props.organiser === this.props.uniqueId && !this.props.showSnapshot
                }
                showCanvasListSpinner={this.showSpinnerForCanvasList}
                showSpinner={this.showSpinner}
                hideCanvasListSpinner={this.hideSpinnerForCanvasList}
                setIsCanvasModified={this.props.setIsCanvasModified}
              //closePopover={this.closePopover}
              />
              // Canvas List cards end
            }
          </div>
        </Col>
      </Col>
      // Canvas list end
    )
  }

  setAnnotationBarToBottom = () => {
    const annotationBar = document.getElementById('annotationBar')
    const scrollY = document.getElementById('scrollY');

    if (!annotationBar) return;
    const annotationBarBound = annotationBar.getBoundingClientRect();

    var setAnnotationStyles = () => {
      let callControls = document.getElementById("iconsPosition" + this.props.uniqueId);
      let canvasSizer = document.getElementById("canvasSizer");
      if ((window.pageYOffset + window.innerHeight) < annotationBarBound.bottom
        && !this.props.showSnapshot) {
        if (canvasSizer) {
          annotationBar.style.width = canvasSizer.offsetWidth + 'px';
        }
        scrollY.style.marginTop = annotationBar.offsetHeight + 'px';
        annotationBar.style.position = 'fixed';
        annotationBar.style.bottom = 0;
        if (!callControls) return;
        // callControls.parentElement.style.position = 'fixed';
        // callControls.parentElement.style.bottom = 0;
        if (this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS)
          this.setIconPosition();
      } else {
        annotationBar.style.position = 'static';
        annotationBar.style.width = '100%';
        annotationBar.style.bottom = '';
        scrollY.style.marginTop = "0px"
        if (!callControls) return;
        // callControls.parentElement.style.position = 'fixed';
        if (this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS)
          this.setIconPosition();
      }
    }
    setAnnotationStyles();
    window.addEventListener('scroll', setAnnotationStyles);
  }

  setIconPosition = () => {
    // find bounds for self icon bar
    let annotationBar = document.getElementById("annotationBar");
    // let videoComponent = document.getElementById("videoComponent");

    let iconBar = document.getElementById("iconsPosition" + this.props.uniqueId);

    if (!iconBar) return;

    // when canvas is in focus set position of A/V icons based on annotation bar position 
    if (this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS) {
      iconBar.style.position = "fixed"
    } else {
      iconBar.style.position = "static"
      iconBar.style.top = iconBar.style.top + "px"
    }

    if (!annotationBar) return;
    let annotationBarBound = annotationBar.getBoundingClientRect();
    iconBar.style.left = ((annotationBarBound.left + annotationBarBound.right) / 2) + "px";
    iconBar.style.top = annotationBarBound.top + 10 + "px"
  }

  setAudioVideoIconsToBottom = () => {
    const iconBar = document.getElementById("iconsPosition" + this.props.uniqueId)
    const scrollY = document.getElementById('scrollY');

    if (!iconBar) return;
    const iconBarBound = iconBar.getBoundingClientRect();

    var setAnnotationStyles = () => {
      if (this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS)
        return;
      if ((window.pageYOffset + window.innerHeight) < iconBarBound.bottom) {
        let videoComponent = document.getElementById("videoComponent")
        iconBar.style.width = videoComponent.offsetWidth + 'px';
        scrollY.style.marginTop = iconBar.offsetHeight + 'px';
        iconBar.style.position = 'fixed';
        iconBar.style.bottom = 0;
      } else {
        iconBar.style.position = 'static';
        iconBar.style.width = '100%';
        iconBar.style.bottom = '';
        scrollY.style.marginTop = "0px"
      }
    }
    setAnnotationStyles();
    window.addEventListener('scroll', setAnnotationStyles);
  }

  playUserArrivalSound = () => {
    let playPromise = this.sound.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // Automatic playback started!
        // Show playing UI.
      })
      .catch(error => {
        // Auto-play was prevented
        // Show paused UI.
      });
    }
  }
  componentDidUpdate(prevProps) {
    /*
    * ring bell when 
    * 1) Agent started session, new participant request to join the session.
    * 2) User waiting to join session before start session and agent start session. 
    * 3) Agent started session, user on waiting screen, agent refresh the screen. 
    * 4) Agent started session, user join session, user on waiting screen, user refresh the screen.
    * 5) Agent started session, agent tab on background user join the session. 
    */
    if (this.props.latestUpdatedParticipant !== prevProps.latestUpdatedParticipant &&
      this.props.latestUpdatedParticipant &&
      this.props.latestUpdatedParticipant.admissionStatus === USER_ADMISSION_STATUS.PENDING &&
      this.props.latestUpdatedParticipant.status !== PARTICIPANT_STATUS.LEFT) {
      this.sound.muted = false;
      this.sound.load();
      this.playUserArrivalSound();
    }

    if (this.props.showAudioVideoComponent !== prevProps.showAudioVideoComponent
      || this.props.showCoBrowse !== prevProps.showCoBrowse) {
      this.selectParticipantObjectForSelf();
      // need to reset screenshare top when audio video component is opened / closed
      this.setScreenShareTopMargin();
    }
    if (!_.isEqual(this.props.admittedParticipants, prevProps.admittedParticipants)) {
      this.selectParticipantObjectForSelf();
    }
    if (this.props.showCoBrowse && this.props.isLandscape !== prevProps.isLandscape) {
      this.changeFocusOnOrientationChange();
      this.coBrowseHeightInMobile();
    }
    if (this.props.showCoBrowse && this.props.showCoBrowse !== prevProps.showCoBrowse && isMobileDevice) {
      this.coBrowseHeightInMobile();
    }
    if (this.props.screenSharingParticipantId !== prevProps.screenSharingParticipantId
      || this.props.componentInFocus !== prevProps.componentInFocus
      || this.props.sortedParticipants !== prevProps.sortedParticipants
      || this.props.isLandscape !== prevProps.isLandscape
      || (isMobileOnly
        && this.props.activeCanvasData.canvasType !== prevProps.activeCanvasData.canvasType)) {
      // need to reset screenshare top when component in focus changes 
      // || participant is added or removed which causes audtio video container height change 
      // || screenshare is started 
      this.setScreenShareTopMargin();
    }
    if (isIE) {
      if (this.props.showAudioVideoComponent !== prevProps.showAudioVideoComponent) {
        let annotationBarEle = document.getElementById('annotationBar');
        let canvasSizer = document.getElementById("canvasSizer");

        if (annotationBarEle && canvasSizer && this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
          annotationBarEle.style.left = canvasSizer.getBoundingClientRect().left + 'px';
        }
        this.setIconPosition();
      }

      if (this.props.componentInFocus !== prevProps.componentInFocus
        || (this.props.showSnapshot !== prevProps.showSnapshot && this.props.showSnapshot)) {
        if (this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
          const iconBar = document.getElementById("iconsPosition" + this.props.uniqueId);

          // reset styles applied
          iconBar.style.position = 'static';
          iconBar.style.left = '';
          iconBar.style.top = '';

          this.setAudioVideoIconsToBottom();
        } else if (this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS) {
          const annotationBar = document.getElementById("annotationBar");

          // reset styles applied
          annotationBar.style.position = 'static';
          annotationBar.style.left = '';
          annotationBar.style.top = '';
          annotationBar.style.bottom = '';

          const iconBar = document.getElementById("iconsPosition" + this.props.uniqueId);

          // reset styles applied
          if (iconBar) {
            iconBar.style.position = 'static';
            iconBar.style.left = '';
            iconBar.style.top = '';
            iconBar.style.width = '';
          }

          this.setAnnotationBarToBottom();
        }
      }
     
    }

    // for self - check and show toast msg and send plugin status only for screenshare
    // for rest of the webrtc features, the plugin status is sent in respective permission reply
    if (this.props.screenSharingParticipantId !== prevProps.screenSharingParticipantId
      && this.props.screenSharingParticipantId
      && isIE) {
      // isIE check added to avoid setState which will cause unnecessary render
      let isPluginInstalled = isWebRTCPluginInstalled();
      this.setState({
        pluginInstalled: isPluginInstalled
      });
      if (!isPluginInstalled) {
        // if plugin is not installed inform agent about it 
        this.props.sendPluginNotInstalled();
      }
    }

    window.addEventListener("resize", () => {
      this.setScreenShareTopMargin();
    });
  }

  uploadThumbnail = (blob, callback) => {
    console.log("in uploadThumbnail....................");

    let thumbnailBlob = blob;
    let backdropPresent = null;
    if (this.props.activeCanvasRef && this.props.activeCanvasRef._objects) {
      backdropPresent = this.props.activeCanvasRef._objects.filter(obj =>
        obj.objectType == 'backgroundImage')[0];
    }

    if (backdropPresent)
      return;

    if (this.props.uniqueId === this.props.organiser
      || this.props.uniqueId === this.props.presenterId) {
      if (this.props.activeCanvasRef || this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
        if (!thumbnailBlob) {
          thumbnailBlob = canvasToBlob(this.props.activeCanvasRef, '#FFFFFF');
        }
        this.isThumbnailBeingUploaded = true;
        this.props.uploadCanvasThumbnail(this.props.activeCanvasData, thumbnailBlob, (error) => {
          this.isThumbnailBeingUploaded = false;
          console.log("thumbnail is uploaded");
          // If co-browse is started hide canvas list spinner becuase canvas list is hidden in that case
          // So the thumbnail image will never be loaded (where the spinner hides)
          // WAAG-4975 App stuck with the spinner when Agent sent snapshot request & User Presenter
          // tries uploads the image at the same time.
          if (error || this.props.showCustomerCoBrowse) {
            this.hideSpinnerForCanvasList();
          }
        })
      }
    } else {
      // when agent uploads a pdf then presenter uploads another
      // agent switches between canvases, spinner is displayed for the pdf uploaded by agent at presenter's end 
      // because the ownerOfLastAction was agent (canvas list spinner will never hide)
      // this.hideSpinnerForCanvasList();
    }
    if (callback) callback();
  }

  shouldShiftWhiteboardToLeft = () => {
    //If audio / video or screenshare is running then shift whiteboard to left

    if (
      (this.props.webRtcPermissionRequest &&
        (this.props.webRtcPermissionReply === true || this.props.showAudioVideoComponent) &&
        this.props.headerIconClass() !== "moduleIconActiveHidden") ||
      this.props.screenSharingParticipantId !== null || this.props.showCoBrowse
    ) {
      return true;
    }
    return false;
  };

  onCanvasSwitched = (switchedCanvasType) => {
    this.setSwitchedCanvasType(switchedCanvasType);
  }

  setSwitchedCanvasType = (switchedCanvasType) => {
    this.setState({
      switchedCanvasType: switchedCanvasType
    })
  }

  showSpinnerForCanvasList = () => {
    // Show spinner when Canvaslist is displayed that is on non mobile devices or for agent
    if ((!isMobileOnly || this.props.organiser == this.props.uniqueId)
      && !this.state.spinnerVisibilityCanvasList) {
      console.log("showing canvaslist spinner");
      this.setState({
        spinnerVisibilityCanvasList: true
      });
    }
    //setTimeout(this.hideSpinnerForCanvasList(), TIMEOUT.SPINNER_TIMEOUT);
  };

  hideSpinnerForCanvasList = () => {
    console.log("hidding canvaslist spinner", this.isThumbnailBeingUploaded);
    !this.isThumbnailBeingUploaded && this.setState({
      spinnerVisibilityCanvasList: false
    });
  };

  showSpinner = () => {
    !this.state.spinnerVisibility && this.setState({
      spinnerVisibility: true
    });
    //setTimeout(this.hideSpinner(), TIMEOUT.SPINNER_TIMEOUT);
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

  changeActiveCanvas = (canvasId, canvasType, uploadThumbnailFlag = true, callback) => {
    console.log("in dashboard.changeActiveCanvas");
    this.isThumbnailBeingUploaded = true;
    this.props.changeActiveCanvas(canvasId, canvasType, uploadThumbnailFlag, () => {
      console.log("in dashboard.changeActiveCanvas callback");
      this.isThumbnailBeingUploaded = false;
      callback && callback();
    });
  }

  selectParticipantObjectForSelf = () => {
    _.map(this.props.admittedParticipants, participantObject => {
      if (participantObject.uniqueId === this.props.uniqueId) {
        //let participant = _.cloneDeep(participantObject);
        this.setState({ participant: participantObject })
      }
    })
  }

  setEndCall = (value) => {
    this.setState({
      endCall: value
    })
  }

  changeFocusOnOrientationChange = () => {
    if (isMobileOrIpadPortrait() || this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply) || 
    (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning)) {
      this.props.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    } else if (!(this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply) || 
    (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning))) {
      this.props.setComponentInFocus(COMPONENT_IN_FOCUS.COBROWSE);
    }
  }

  coBrowseHeightInMobile = () => {
    let CoBrowseContainer = document.getElementById("CoBrowseContainer");
    let header = document.getElementsByClassName("headerLogoContainer")[0];

    let coBrowseIframeHeight = window.screen.height - header.offsetHeight;
    CoBrowseContainer.style.height = coBrowseIframeHeight + "px";
  }

  setScreenShareTopMargin = () => {
    let screenshareContainer = document.getElementById("screenshareContainer");
    if (!screenshareContainer) return;

    let whiteboard = document.getElementById("whiteboard");
    let whiteboardHeight = whiteboard ? whiteboard.offsetHeight : 0;
    let audioVideoContainer = document.getElementById("videoComponent");
    let audioVideoContainerHeight = audioVideoContainer ? audioVideoContainer.offsetHeight : 0;
    let header = document.getElementsByClassName("headerLogoContainer")[0];
    let headerHeight = header ? header.offsetHeight : 0;
    if (window.innerWidth < 767) {
      screenshareContainer.style.position = "relative";
      if (!this.props.isLandscape && 
        (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF || this.props.showCustomerCoBrowse)) {
        // because on mobile device screen share position is relative 
        screenshareContainer.style.top = audioVideoContainerHeight + "px";
      } else {
        screenshareContainer.style.top = "0px";
      }
      return;
    } else {
      screenshareContainer.style.position = "absolute";
    }
    if (this.props.showAudioVideoComponent || this.props.showCoBrowse) {
      let cobrowseContainer = document.getElementById("CoBrowseContainer");
      let cobrowseContainerHeight = cobrowseContainer ? cobrowseContainer.offsetHeight : 0;
      let cobrowseHeight = 0;
      let audioVideoHeight = 0;
      if (this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE) {
        cobrowseHeight = 0;
      } else {
        cobrowseHeight = cobrowseContainerHeight;
      }
      if (this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
        audioVideoHeight = 0;
      } else {
        audioVideoHeight = audioVideoContainerHeight;
      }

      if (this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO
        || this.props.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE) {
        screenshareContainer.style.top = `calc(${whiteboardHeight + cobrowseHeight + audioVideoHeight}px)`;
      } else {
        screenshareContainer.style.top = `calc(${audioVideoHeight + cobrowseHeight}px)`;
      }
      return;
    }
    screenshareContainer.style.top = 0 + "px";
  }

  renderWhiteboardContainer = () => {
    return <WhiteboardContainer
      endCall={this.state.endCall}
      broadcastDownloadAction={this.props.broadcastDownloadAction}
      componentInFocus={this.props.componentInFocus}
      addCanvas={this.props.addCanvas}
      uploadFile={this.props.uploadFile}
      uploadThumbnail={this.uploadThumbnail}
      uploadCanvasThumbnail={this.props.uploadCanvasThumbnail}
      showSpinnerForCanvasList={this.showSpinnerForCanvasList}
      hideSpinnerForCanvasList={this.hideSpinnerForCanvasList}
      showSpinner={this.showSpinner}
      hideSpinner={this.hideSpinner}
      spinnerVisibility={this.state.spinnerVisibility}
      spinnerVisibilityCanvasList={this.state.spinnerVisibilityCanvasList}
      changeActiveCanvas={this.changeActiveCanvas}
      setComponentInFocus={this.props.setComponentInFocus}
      webRtcPermissionReply={this.props.webRtcPermissionReply}
      setSwitchedCanvasType={this.setSwitchedCanvasType}
      switchedCanvasType={this.state.switchedCanvasType}
      removePdfHighLight={this.removePdfHighLight}
      setIsCanvasModified={this.props.setIsCanvasModified}
      showAudioVideoComponent={this.props.showAudioVideoComponent}
      getPdfListFromCloud={this.props.getPdfListFromCloud}
      getCloudFileUrl={this.props.getCloudFileUrl}
      showCoBrowse={this.props.showCoBrowse}
      isLandscape={this.props.isLandscape}
    />
  }

  renderCoBrowseContainer = () => {
    return (
      <CoBrowseContainer
        setComponentInFocus={this.props.setComponentInFocus}
        componentInFocus={this.props.componentInFocus}
        snapshoteeId={this.props.snapshoteeId}
        showSnapshot={this.props.showSnapshot}
        snapshotPermissionReply={this.props.snapshotPermissionReply}
      />
    )
  }

  renderAudioVideoContainer = () => {
    return <AudioVideoContainer
      webRtcPermissionRequest={
        this.props.webRtcPermissionRequest
      }
      headerAction={headerAction}
      componentInFocus={this.props.componentInFocus}
      setComponentInFocus={this.props.setComponentInFocus}
      setEndCall={this.setEndCall}
      endCall={this.state.endCall}
      setScreenShareTopMargin={this.setScreenShareTopMargin}
    />
  }

  renderSelfAudioVideoIconBar = () => {
    if (isDesktopAndTabletIpadPortrait()
      && this.props.showAudioVideoComponent && this.state.participant) {

      return <IconsPositionBar className="callControls"
        isVideo={this.props.webRtcPermissionRequest == mediaType.AUDIO_VIDEO
          ? true
          : false}
        className={this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS
          ? "floatingAudioVideoControls"
          : "displayFlex justifyContentFlexCenter alignItemsCenter annotationBar"
        }
        parentClassname="annotation"
        iconsDivClassname="iconsBackground d-none d-lg-block"
        sessionKey={this.props.sessionKey}
        organiser={this.props.organiser}
        uniqueId={this.props.uniqueId}
        participant={this.state.participant}
        index={0}
        onOpenCloseEndVideoCallConfirmationModal={() => { this.setEndCall(true) }}
        onOpenCloseEndAudioCallConfirmationModal={() => { this.setEndCall(true) }}
        selfIcons={true}
        audioOn={audio_on}
        audioOff={audio_off}
        videoOn={video_on}
        videoOff={video_off}
        tooltipVideoId={"muteUnmuteVideo"}
        tooltipAudioId={"muteUnmuteAudio"}
      />
    }
  }

  render() {
    const { modulesAndClassNames } = this.props
    let fullViewPdfContainer = (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF 
      || this.props.showCustomerCoBrowse) 
    && !this.props.isLandscape
    // Fix for WAAG-5063
    // we need to check inner width also because isMobileDevice returns true for iPad pro (1024 width)
    // where we have desktop view
    && (isMobileDevice && window.innerWidth < 992)
      ? " height100"       //do not remove space this is use to avoid merging classnames 
      : ""
    let mobileContainerHeight = (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF && 
      !this.props.showCustomerCoBrowse) 
    && !this.props.isLandscape
      ? " aligningCenterMobileCanvas"
      : " aligningCenterMobilePdf"
      
    let whiteboardColWidth = (this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO && this.props.showAudioVideoComponent)
      || (this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE && this.props.showCoBrowse) ? "3 setPositionProperty " : "9";

    let audioVideoColWidth = this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO
      && this.props.showAudioVideoComponent ? "9" : "3";

    let CoBrowseColWidth = this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE
      && this.props.showCoBrowse
      ? "9"
      : "3";
    return (
      <div>
        <Container className="dashboard setContainer" id="dashboard">
          <Row className={"positionRelative " + mobileContainerHeight}>
            <Row className={"width100 noMargin" + fullViewPdfContainer }
            >
              {/** 
               * Whiteboard Container
              */}
              <Col
                lg={this.shouldShiftWhiteboardToLeft() ? whiteboardColWidth : { offset: "1", size: "9" }}
                className={
                  this.shouldShiftWhiteboardToLeft()
                    ? "noPadding " + modulesAndClassNames.CANVAS +fullViewPdfContainer
                    : "noPadding whiteboardConatinerCol" + fullViewPdfContainer}
                id="WhiteboardParentContainer"
              >
                <div id="whiteboard" className={(whiteboardColWidth === "3 setPositionProperty " ? "cardBody pr-2" : "")+fullViewPdfContainer}>
                  {this.renderWhiteboardContainer()}
                </div>

                {/* // show self audio / video related icon bar */}
                {this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS 
                  && !this.props.showSnapshot
                  && this.renderSelfAudioVideoIconBar()}
              </Col>

              {/** 
               *  COBROWSE Container 
              */}
              {this.shouldShiftWhiteboardToLeft() &&
                this.props.showCoBrowse &&
                <Col
                  lg={CoBrowseColWidth}
                  className={this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE || isMobileOrIpadPortrait()
                    ? "mb-lg-2 px-2 px-md-0 "// left padding is already added for canvas
                    : "mb-2 container px-2 pt-2 setPositionProperty " + modulesAndClassNames.COBROWSE
                  }
                  id="CoBrowseContainer"
                >
                  {this.renderCoBrowseContainer()}
                  {/* // show self audio / video related icon bar */}
                  {this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE
                    && this.renderSelfAudioVideoIconBar()}
                </Col>
              }
              {this.renderCanvasList()}
              {/**
               * Audio Video Container 
              */}
              {this.shouldShiftWhiteboardToLeft() &&
                this.props.showAudioVideoComponent &&
                <Col
                  lg={audioVideoColWidth}
                  className={this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO
                    ? "mb-2 px-0 " // left padding is already added for canvas
                    : "mb-2 container px-2 setPositionProperty " + modulesAndClassNames.AUDIO_VIDEO
                  }
                  style={{height : isMobileOnly && this.props.isLandscape && this.props.showAudioVideoComponent ? "33em" : ""}}
                  id="AudioVideoContainer"
                >
                  {this.renderAudioVideoContainer()}
                  {/* // show self audio / video related icon bar */}
                  {this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO
                    && this.renderSelfAudioVideoIconBar()}
                </Col>
              }
            </Row>

            {/* Screenshare */}
            {this.shouldShiftWhiteboardToLeft() &&
              <Col
                lg={{ size: "3" }}
                className="justifyContetntCenter screenShare pl-2 pr-2"
                id="screenshareContainer"
              >
                {this.props.screenSharingParticipantId !== null &&
                  this.state.pluginInstalled ? (
                  // screenshare starts
                    <Col
                      lg="12"
                      className={
                        (this.props.screenSharingParticipantId !== null
                          ? "displayInline px-2"
                          : "displayNone") + " noPadding"
                      }
                    >
                      <ScreenShareContainer
                        screenSharingParticipantId={
                          this.props.screenSharingParticipantId
                        }
                        broadcastDownloadAction={this.props.broadcastDownloadAction}
                        admittedParticipants={this.props.admittedParticipants}
                        presenterId={this.props.presenterId}
                        uniqueId={this.props.uniqueId}
                        setComponentInFocus={this.props.setComponentInFocus}
                        isLandscape = {this.props.isLandscape}
                      />
                    </Col>
                  ) : null
                  // screenshare ends
                }
              </Col>
            }
           
            {
              (this.props.showCustomerCoBrowse || this.props.showTargetSiteSelectionModal) &&
              <div
                id="customerCoBrowseContainerParent"
                className={this.props.showCustomerCoBrowse && this.props.endCustomerCBSession 
                  ? "col-lg-9 customerCoBrowseContainerOnReload" 
                  : this.shouldShiftWhiteboardToLeft()
                    ? "customerCoBrowseContainerParent col-lg-9 px-0"
                    : "customerCoBrowseContainerParent col-lg-9 offset-lg-1 px-0"}
              >
                <CustomerCoBrowseContainer
                  showTargetSiteSelectionModal = {this.props.showTargetSiteSelectionModal}
                  showCustomerCoBrowse = {this.props.showCustomerCoBrowse} 
                  setComponentInFocus={this.props.setComponentInFocus}
                  endCustomerCBSession={this.props.endCustomerCBSession}
                  isLandscape={this.props.isLandscape}
                />
              </div>
            }

            {
              // total bit rate
              window._env_.REACT_APP_SHOW_BITRATE == "true" &&
              !isIE &&
              <TotalBitRate
                className="rightCornerPosition"
                uniqueId={this.props.uniqueId}
                parentContainer={document}
                sentLabel={getMessage("TOTAL_SENT")}
                recvLabel={getMessage("TOTAL_RECV")}
              />
            }
          </Row>
        </Container>
      </div>
    );
  }

  componentWillUnmount() {
    this.stickyScrollInterval && clearTimeout(this.stickyScrollInterval);
  }
}
export default withOrientationChange(Dashboard);
