import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Button,
  PopoverBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledPopover
} from "reactstrap";

import uuid from "uuid";
import "./Participents.less";
import "../../../App/App.less";
import { slide as Menu } from "react-burger-menu";
import { isIE } from 'react-device-detect';
import { getMessage } from "CONFIG/i18n";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

// Constants
import { PARTICIPANT_LIMIT_SM, PARTICIPANT_LIMIT_LG } from "UTILS/Constants";

import { getUserNameAcronym, isMobileOriPad } from "UTILS/Utility";

function Participants(props) {
  let makePresenter = participant => {
    props.makePresenter(participant);
  };
  let addInviteeHandle = () => {
    props.openDrawer();
    props.addInviteeHandle();
  };
  let handleEndSession = () => {
    props.handleCancelClick();
    props.onOpenCloseEndSessionConfirmationModal(true);
  };
  let handleLeaveSession = () => {
    props.handleCancelClick();
    props.onOpenCloseLeaveSessionConfirmationModal(true);
  };

  if (!props.mobileView) {
    let PARTICIPANT_LIMIT = window.innerWidth >= 1215
      ? PARTICIPANT_LIMIT_LG
      : PARTICIPANT_LIMIT_SM

    let limit = props.participants.length > PARTICIPANT_LIMIT 
      ? PARTICIPANT_LIMIT-1
      : props.participants.length
    let moreParticipants = props.participants.length > PARTICIPANT_LIMIT 
    let participantList = _.cloneDeep(props.participants);
    let moreParticipantsList = _.cloneDeep(props.participants);
    // let limit = props.participants.length > 3 ? 2 : 
    return (
      <React.Fragment>
        {participantList.slice(0,limit).map(participant => {
          let presenterCSS = props.presenter === participant.uniqueId
            ? "displayFlex alignItemsCenter justifyContentFlexCenter presenter-card-text participants positionRelative"
            : "displayFlex alignItemsCenter justifyContentFlexCenter card-text participants positionRelative"
          return participant ? (
            <div key={uuid.v4()}>
              <div
                id={"participantId" + participant.participantId}
                className={
                  props.uniqueId === props.organiser && participant.uniqueId !== props.presenter ? 
                    "blinkImg cursorPointer " + presenterCSS 
                    : "" + presenterCSS 
                }
                onClick={() => props.showMakePresenter(participant.participantId)}
              >
                <label
                  className={
                    props.uniqueId === props.organiser && participant.uniqueId !== props.presenter 
                      ? "cursorPointer" 
                      : ""
                  }
                  title={participant.userName}
                >
                  {getMessage(getUserNameAcronym(participant.userName))}
                </label>
                {(props.presenter === participant.uniqueId)?<span className="badge">P</span>:null}
              </div>
              {(props.presenter !== participant.uniqueId && props.isAgent) && (
                <UncontrolledPopover
                  placement="bottom"
                  isOpen={
                    props.makePresenterPopoverOpen === participant.participantId
                  }
                  target={"participantId" + participant.participantId}
                  toggle={() =>
                    props.showMakePresenter(participant.participantId)
                  }
                  trigger="legacy"
                >
                  <PopoverBody
                    className="displayFlex cursorPointer blinkImg"
                    onClick={() => makePresenter(participant)}
                  >
                    {/* <img src={ic_make_presenter} className="pl-2 pr-2" /> */}
                    <span>{getMessage("MAKE_PRESENTER")}</span>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </div>
          ) : null;
        })}
        {moreParticipants && 
        <React.Fragment>
          <Dropdown
            placement="bottom"
            isOpen={props.moreParticipantsPopOver}
            toggle={() => {}}
            className="moreParticipants"
            onClick={() => {
              isIE ? props.showMoreParticipants() : null 
              if(props.makePresenterPopoverOpen)
                props.showMakePresenter(null)
            }}
          >
            <DropdownToggle
              tag="div"
            >
              <div
                className="blinkImg cursorPointer displayFlex alignItemsCenter justifyContentFlexCenter card-text participants positionRelative"
                onClick={() => !isIE ? props.showMoreParticipants() : null }
                id="moreParticipants"
              >
                <label
                  className={
                    "cursorPointer" 
                  }
                  title={getMessage("MORE_PARTICIPANTS")}
                >
                  +{props.participants.length-(PARTICIPANT_LIMIT-1)}
                </label>
              </div>
            </DropdownToggle>

            <DropdownMenu 
              toggle={false}
              className={isIE 
                ? moreParticipantsList.slice(limit,moreParticipantsList.length).length == 2
                  ? "minWidth2"
                  : "minWidth4"
                : ""
              }>
              {moreParticipantsList.slice(limit,moreParticipantsList.length).map((participant,index) => {
                let presenterCSS = props.presenter === participant.uniqueId
                  ? "displayFlex alignItemsCenter justifyContentFlexCenter presenter-card-text participants positionRelative"
                  : "displayFlex alignItemsCenter justifyContentFlexCenter card-text participants positionRelative"
                // let gridColumn = "column"+((index%3)+1)
                // let gridRow = parseInt(index/3)
                return participant ? (
                  <DropdownItem
                    tag="div"
                  >
                    <div
                      id={"participantId" + participant.participantId}
                      className={
                        props.uniqueId === props.organiser && participant.uniqueId !== props.presenter ? 
                          "blinkImg cursorPointer " + presenterCSS 
                          : "" + presenterCSS 
                      }
                      onClick={(event) => { 
                        event.stopPropagation();
                        props.showMakePresenter(participant.participantId)}
                      }
                    >
                      <label
                        className={
                          props.uniqueId === props.organiser && participant.uniqueId !== props.presenter 
                            ? "cursorPointer" 
                            : ""
                        }
                        title={participant.userName}
                      >
                        {getMessage(getUserNameAcronym(participant.userName))}
                      </label>
                      {(props.presenter === participant.uniqueId)?<span className="badge">P</span>:null}
                    </div>
                    {(props.presenter !== participant.uniqueId && props.isAgent) && (
                      <UncontrolledPopover
                        placement="bottom"
                        isOpen={
                          props.makePresenterPopoverOpen === participant.participantId
                        }
                        target={"participantId" + participant.participantId}
                        toggle={() =>
                          props.showMakePresenter(participant.participantId)
                        }
                        trigger="legacy"
                      >
                        <PopoverBody
                          className="displayFlex cursorPointer blinkImg"
                          onClick={() => makePresenter(participant)}
                        >
                          {/* <img src={ic_make_presenter} className="pl-2 pr-2" /> */}
                          <span>{getMessage("MAKE_PRESENTER")}</span>
                        </PopoverBody>
                      </UncontrolledPopover>
                    )}
                  </DropdownItem>
                ) : null;
              })}
            </DropdownMenu>
          </Dropdown>
        </React.Fragment>
        }
      </React.Fragment>
    )
  } else if (props.isSnapshot === true) {
    return (
      <span className="m-1">
        {props.organiser === props.uniqueId &&
          props.participants.map((participant,index) => {
            return participant ? (
              <span
                className="displayInline cursorPointer mx-2"
                style={{ display: !props.showParticipant && 'none' }}
                key={participant.uniqueId}
                onClick={() => {
                  /*
                  * allow agent to send snapshot request to the user only when
                  * snapshot request not accepeted by any user  
                  * or the snapshot streaming is started 
                  */
                  (props.snapshoteeId != participant.uniqueId) &&
                  (!props.snapshotPermissionReply || props.isSnapshotRunning) &&
                  props.sendTakeSnapshotMessage(participant.uniqueId);
                  isMobileOriPad() && props.toggleSnapshotParticipantsBar();
                }}
              >

                <button
                  className={
                    props.snapshoteeId !== participant.uniqueId
                      ? "participantInitials noPadding"
                      : "participantInitials selected not-allowed  noPadding selected"
                  }
                  style={props.snapshoteeId === participant.uniqueId || 
                    (props.snapshotPermissionReply && !props.isSnapshotRunning) ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                  disabled={props.snapshoteeId === participant.uniqueId || 
                    (props.snapshotPermissionReply && !props.isSnapshotRunning)}
                >
                  <span id={`snapshotParticipant`+ index}>        
                    {participant.userName && getUserNameAcronym(participant.userName)}
                    <CustomTooltip tooltipText={participant.userName} tooltipId={`snapshotParticipant`+ index} />
                  </span>

                </button>
              </span>
            ) : null;
          })}
      </span>
    );
  } else {
    return (
      <div className="menu-parent">
        <Menu
          slide
          right
          isOpen={props.popoverOpen}
          onStateChange={e => (!e.isOpen ? props.handleCancelClick() : null)}
          disableOverlayClick={() => console.log()}
        >
          {/* displaying modules */}
          <>
            <div className="dropdown-divider dividerLine pt-2" />
            <label className="drawer-title"> {getMessage("MODULES")} </label>
            <div className="dropdown-divider dividerLine" />
            <div className="modules">
              {props.renderModules(true)}
              <DropdownItem divider />
            </div>
          </>
          {/* displaying end session or leave session button */}
          { props.organiser ===
            props.uniqueId ? (
              <>
                <Row className="participants" key={uuid.v4()}>
                  <Button
                    color="danger"
                    className="endSession"
                    onClick={handleEndSession}
                  >
                    {getMessage("END_SESSION")}
                  </Button>
                </Row>
              </>
            ) : (
              <Row className="participants" key={uuid.v4()}>
                <Button
                  color="danger"
                  className="endSession"
                  onClick={handleLeaveSession}
                >
                  {getMessage("LEAVE_SESSION")}
                </Button>
              </Row>
            )}
        </Menu>
      </div>
    );
  }
}

Participants.propTypes = {
  participants: PropTypes.array,
  mobileView: PropTypes.bool,
  popoverOpen: PropTypes.bool
};
export default Participants;
