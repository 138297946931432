import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import _ from "lodash";

import HeaderContainer from "../../SuperAdmin/Header/HeaderContainer";
import Spinner from "COMPONENTS/Spinner/Spinner";
import SettingsPage from "./SettingsPage";
import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";

// Constants
import { CONFIG, ERROR } from "UTILS/Constants";
// selectors
import { selectUserName, selectCustomerId } from "../../MainLayout/selectors";
import { selectUserRole } from "../../Whiteboard/selectors";
import { getModules, getHeaderIcons, selectAllowedDownloads, getIsOrganizer, getSubLoggingLevels, getLoggingLevel, getSSODetails, getQRCodeSettings, getStorageDetails, isDownloadModuleSelected, getIsSSOEnabled, getIsSystemPassword, getURIIdentifier, getIsPasscodeEnabled, getBandwidthSettings, isVideoOrSnapshotSelected, isScreenShareSelected, getConcurrentSessionData } from "../../CommonComponents/Header/selectors";
//Utilities

// Strings
import { getMessage } from "CONFIG/i18n";

//Actions
import { organisationAction } from "CONFIG/ActionFactory";

class SettingsPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false,
    };
  }

  componentDidMount() {
    this.getCustomerInfo();
  }

  getCustomerInfo = () => {
    this.setState({spinnerVisibility: true});
    organisationAction.getCustomersAction(this.props.customerId).then(
      responseObject => {  
        this.setState({spinnerVisibility:false});
      }, error => {
        console.error("Error getting customer info", error);
        this.setState({spinnerVisibility: false});
      });
  };

  renderSpinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  handleCancelClick = () => {
    this.props.history.push(CONFIG.path.admin);
  };

  verifyStorage=(storageElement,callback)=>{
    this.setState({spinnerVisibility: true })
    organisationAction.verifyStorage(storageElement).then(
      data => {
        this.setState({spinnerVisibility: false })
        callback && callback();
      }
    ).catch (
      error => {
        this.setState({spinnerVisibility: false })
        callback && callback(error);
      }
    )
  }

  updateCustomerSettings = (details, callback)=>{
    this.setState({spinnerVisibility: true });
    organisationAction.updateCustomerSettings(this.props.customerId, details).then(
      data => { organisationAction.getCustomersAction(this.props.customerId).then(
        responseObject => {
          callback && callback();
          toastr.success("SETTINGS_SAVED");
          this.setState({spinnerVisibility:false},()=>{ 
            setTimeout(()=>{this.handleCancelClick()},1000)}); 
        });
      }
    ).catch (
      error => {
        this.setState({spinnerVisibility:false})
        toastr.error(error);
        callback && callback(error);
      }
    )
  }

  render() {
    return (
      <>
        {this.renderSpinner()}
        <HeaderContainer
          title={getMessage("SETTINGS")}
          headerButtonText={getMessage("ADD_USER")}
          showModal={false}
          history={this.props.history}
          showAboutOrgModal={false}
          getAllUserData={()=>{}}
          logo={this.props.logo}
          isAdmin={true}
          showQR={false}
          isDashboard={false}
          showUserDropDownModal={true}
        />
        <SettingsPage
          verifyStorage={this.verifyStorage}
          updateCustomerSettings={this.updateCustomerSettings}
          handleCancelClick={this.handleCancelClick} 
          userName={this.props.userName}
          role={this.props.role}
          customerId={this.props.customerId}
          modules={this.props.modules}
          allowedDownloads={this.props.allowedDownloads}
          isOrganizer={this.props.isOrganizer}
          availableSubLoggingLevels={this.props.availableSubLoggingLevels}
          loggingLevel={this.props.loggingLevel}
          storageDetails={this.props.storageDetails}
          SSODetails={this.props.SSODetails}
          qrCodeSettings={this.props.qrCodeSettings}
          bandwidthSettings={this.props.bandwidthSettings}
          isDownloadModuleSelected={this.props.isDownloadModuleSelected}
          isVideoOrSnapshotSelected={this.props.isVideoOrSnapshotSelected}
          isScreenShareSelected={this.props.isScreenShareSelected}
          headerIcons={this.props.headerIcons}
          isSSOEnabled={this.props.isSSOEnabled}
          uriIdentifier={this.props.uriIdentifier}
          isPasscodeEnabled={this.props.isPasscodeEnabled}
          isSystemPassword={this.props.isSystemPassword}
          concurrentSessionData={this.props.concurrentSessionData}
          cobrowseSettings={this.props.cobrowseSettings}
          availableModules={this.props.availableModules}
          allowAnonymousParticipants={this.props.allowAnonymousParticipants}
          showLogoOnCanvasStatus={this.props.showLogoOnCanvasStatus}
          showChatPopup={this.props.showChatPopup}
          appEmulationSettings={this.props.appEmulationSettings}      
          isOrganizationTemplatesEnabled={this.props.isOrganizationTemplatesEnabled}
          chatSettings={this.props.chatSettings}
          isChatGPTEnabled={this.props.isChatGPTEnabled}
        />
       </>
    )
  }
}

const mapStateToProps = state => {
  return {
    userName: selectUserName(state),
    role: selectUserRole(state),
    customerId: selectCustomerId(state),
    modules: getModules(state),
    allowedDownloads: selectAllowedDownloads(state),
    isOrganizer: getIsOrganizer(state),
    availableSubLoggingLevels: getSubLoggingLevels(state),
    loggingLevel: getLoggingLevel(state),
    storageDetails: getStorageDetails(state),  
    SSODetails:getSSODetails(state),
    qrCodeSettings: getQRCodeSettings(state),
    isDownloadModuleSelected: isDownloadModuleSelected(state),
    isScreenShareSelected: isScreenShareSelected(state),
    isVideoOrSnapshotSelected: isVideoOrSnapshotSelected(state),
    headerIcons: getHeaderIcons(state),
    isSSOEnabled: getIsSSOEnabled(state),
    uriIdentifier: getURIIdentifier(state),
    isPasscodeEnabled: getIsPasscodeEnabled(state),
    isSystemPassword: getIsSystemPassword(state),
    bandwidthSettings: getBandwidthSettings(state),
    logo: state.OrganisationReducer.logo,
    concurrentSessionData: getConcurrentSessionData(state),
    cobrowseSettings: state.OrganisationReducer.cobrowseSettings,
    availableModules: state.OrganisationReducer.customerData.modules,
    allowAnonymousParticipants: state.OrganisationReducer.customerSettings.allowAnonymousParticipants,
    showLogoOnCanvasStatus: state.OrganisationReducer.customerSettings.showLogoOnCanvasStatus,
    showChatPopup: state.OrganisationReducer.customerSettings.showChatPopup,
    appEmulationSettings: state.OrganisationReducer.appEmulationSettings,
    isOrganizationTemplatesEnabled: state.OrganisationReducer.customerData.is_organization_templates_enabled,
    chatSettings: state.OrganisationReducer.customerData.chatSettings,
    isChatGPTEnabled: state.OrganisationReducer.isChatGPTEnabled
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withErrorHandler(withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsPageContainer)));
