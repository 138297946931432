import React, { Component } from "react";
import { getMessage, changeLanguage } from "CONFIG/i18n";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isIE } from "react-device-detect";
import _ from "lodash";

import toastr from "COMPONENTS/ShowTostr/ShowTostr";
import Spinner from "COMPONENTS/Spinner/Spinner";
import {
  separatePhoneNumberAndEmails,
  getPhoneNumberWithCountryCode,
  getValidInviteesInput,
  getCommonAPIErrorMessage,
  isEmailValid
} from "UTILS/Utility";
import { validateMobileNumber, validateInvitees } from "UTILS/regexValidations";

import { debounceTime } from "rxjs/operators";

//ASSETS
import icBar from "ASSETS/Images/ic_bar.svg";
import icBack from "ASSETS/Images/ic_backarrow.svg";
import icMeeting from "ASSETS/Images/ic_meeting.svg";

//Stylesheet
import "./startSession.less";

import * as rxjs from "rxjs";

import { Row, Col, InputGroup, FormControl } from "react-bootstrap";

//ASSETS
import ic_success_modal from "ASSETS/Images/ic_session_scheduled_popup.svg";

//Utility
import {
  SESSION_TYPE,
  MEETING_JOIN_TYPE,
  CONFIG,
  KEY_CODE_ENTER,
} from "UTILS/Constants";

import smoothscroll from "smoothscroll-polyfill";

//Utilities
import { checkEmpty, getStringWithoutExtraSpaces } from "UTILS/Utility";

//Actions
import {
  startSessionAction as actionFactory,
  logoutAction,
} from "CONFIG/ActionFactory";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

import { getCurrentSessionInvitees } from "COMPONENTS/Sessions/selectors";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";
import Popup from "COMPONENTS/CommonComponents/Modal/Modal";
class StartSession extends Component {
  constructor(props) {
    super(props);
    this.getInvitees = new rxjs.Subject();
    this.sessionProceed = new rxjs.Subject();
    this.state = {
      sessionKey: "",
      sessionTitle: "",
      pastSessionErrorMessage: "",
      errorMessage: "",
      errorCode: "",
      inviteeErrorMessage: "",
      inviteeErrorCode: "",
      inviteeInvalid: false,
      selectedEmail: "",
      selectedPhone: "",
      inviteesList: [],
      currentSessionInvitees: this.props.currentSessionInvitees,
      invitees: "",
      scheduleDate: "",
      scheduleDateError: "",
      timeError: "",
      sessionType: this.props.sessionType,
      showModal: false,
      modalMessage: "",
      passcodeAPIError: "",
      modalType: "",
      modalIcon: ic_success_modal,
      closeRecentEmail: true,
      closeRecentPhone: false,
      joinType: this.props.isPasscodeEnabled
        ? MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE
        : MEETING_JOIN_TYPE.SET_UP_NEW_MEETING,
    };
    changeLanguage();
    this.inviteeInputRef = React.createRef();
  }

  handleInputChange = (event) => {
    this.setState({
      sessionTitle: event.target.value,
      errorMessage: "",
      pastSessionErrorMessage: "",
    });
  };

  handleInviteeInputChange = () => (event) => {
    /*
     * restrict user to enter spaces
     * restrict user to enter more than 1 seperator
     * replace multiple , or ,; with single ,
     * replace multiple ; or ;, with single ;
     */
    if (validateInvitees(event.target.value)) {
      this.setState(
        {
          invitees: getValidInviteesInput(event.target.value),
          cursorPositionStart: event.target.selectionStart - 1,
          cursorPositionEnd: event.target.selectionEnd - 1,
          inviteeInvalid: false,
          inviteeErrorMessage: "",
          inviteeErrorCode: "",
        },
        () => {
          // set the cursor of input to the previous position
          this.inviteeInputRef.current.selectionStart =
            this.state.cursorPositionStart;
          this.inviteeInputRef.current.selectionEnd =
            this.state.cursorPositionEnd;
        }
      );
    } else {
      this.setState({
        invitees: event.target.value,
        inviteeInvalid: false,
        inviteeErrorMessage: "",
        inviteeErrorCode: "",
      });
    }
  };

  componentDidMount() {
    smoothscroll.polyfill();

    //fetch passcode when get network online
    window.addEventListener("online", this.getPasscodeWhenNetworkReconnected);

    //fetch passcode when get on start meeting tab
    navigator.onLine &&
      this.state.joinType === MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE &&
      this.getPasscode();

    this.sessionProceed.pipe(debounceTime(500)).subscribe((fn) => {
      if (typeof fn === "function") fn();
    });
  }

  getPasscodeWhenNetworkReconnected = () => {
    if (this.state.joinType === MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE) {
      this.getPasscode();
    }
  };

  passcodeSuccessCallback = (response) => {
    this.showHideSpinner(false);
    this.isPasscodeAvailable();
  };

  passcodeErrorCallback = (errorCode) => {
    this.showHideSpinner(false);
    toastr.error(errorCode);
    this.isPasscodeAvailable();
    this.setState({
      passcodeAPIError: getCommonAPIErrorMessage(errorCode)
    })
  };

  isPasscodeAvailable = () => {
    //check if passcode is not available disable the proceed button
    if (
      this.state.joinType === MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE &&
      !document.getElementById("generatedPasscode")?.value
    ) {
      this.enableDisableProceedBtn(true);
    }
  };

  getPasscode = () => {
    let uriIdentifier =
      this.props.uriIdentifier !== "/" ? this.props.uriIdentifier : "";
    if (window.getPasscode) {
      this.showHideSpinner(true);
      window.getPasscode(
        this.props.role,
        window.location.origin + uriIdentifier + CONFIG.path.passcode,
        window._env_.REACT_APP_LOOOKIT_SERVICES_BASE_URL,
        this.props.userToken,
        document.getElementById("lookitPasscode"),
        undefined,
        this.passcodeSuccessCallback,
        this.passcodeErrorCallback
      );
    } else {
      //TODO: change the error message to asking user for refresh the page
      console.error("Passcode Error: window.getPasscode not found..");
      toastr.error("INTERNAL_SERVER_ERROR");
    }
  };

  onInviteesChange = (e) => {
    this.getInvitees.next(e.target.value);
  };

  resetPastSessionError = () => {
    this.setState({ pastSessionErrorMessage: "" });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentSessionInvitees !== this.props.currentSessionInvitees
    ) {
      this.setState({
        currentSessionInvitees: this.props.currentSessionInvitees,
      });
    }
    if (prevProps.isPasscodeEnabled != this.props.isPasscodeEnabled) {
      if (this.props.isPasscodeEnabled) {
        this.setState(
          {
            joinType: MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE,
          },
          () => this.getPasscode()
        );
      } else {
        this.setState({
          joinType: MEETING_JOIN_TYPE.SET_UP_NEW_MEETING,
        });
      }
    }
  }

  componentWillUnmount() {
    console.log("componentWillUnmount StartSession");
    window.removeEventListener(
      "online",
      this.getPasscodeWhenNetworkReconnected
    );  
  }

  isInputValid = () => {
    let passcode = document.getElementById("generatedPasscode")
      ? document.getElementById("generatedPasscode").value
      : undefined;

    // setup meeting validation check
    if (
      this.state.joinType === MEETING_JOIN_TYPE.SET_UP_NEW_MEETING &&
      checkEmpty(this.state.sessionTitle.trim())
    ) {
      this.setState({
        errorMessage: "ERR_MSG_SESSION_TITLE",
      });
      return false;
    }

    return this.validateAndGetInvitees();
  };

  validateAndGetInvitees = () => {
    if (
      this.state.invitees &&
      this.state.invitees.trim().length > 0 &&
      this.state.joinType === MEETING_JOIN_TYPE.SET_UP_NEW_MEETING
    ) {
      var separators = [",", ";"];
      let arrayOfInvitees = this.state.invitees.split(
        new RegExp(separators.join("|"), "g")
      );
      let allInvites =
        this.state.sessionType === SESSION_TYPE.UPDATE_SCHEDULE_MEETING
          ? []
          : [...this.state.currentSessionInvitees];
      let { arrayOfEmails, arrayOfPhone } =
        separatePhoneNumberAndEmails(arrayOfInvitees);
      arrayOfEmails = _.uniqBy(arrayOfEmails, 'email');
      arrayOfPhone = _.uniqBy(arrayOfPhone, 'phone');
      // validate emails - starts
      for (var i = 0; i < arrayOfEmails.length; i++) {
        let email = arrayOfEmails[i].email;
        let isValidEmail = isEmailValid(email);
        if (!isValidEmail) {
          this.setState({
            inviteeInvalid: true,
            inviteeErrorMessage: getMessage("INVALID_EMAIL_PHONE"),
            inviteeErrorCode: "INVALID_EMAIL_PHONE",
          });
          this.showHideSpinner(false);
          return false;
        }
        allInvites.push(arrayOfEmails[i]);
      } // validate emails - ends
      // validate phone no - starts
      for (var i = 0; i < arrayOfPhone.length; i++) {
        if (arrayOfPhone[i].phone) {
          let isValidPhone = validateMobileNumber(arrayOfPhone[i].phone);
          if (!isValidPhone) {
            this.setState({
              inviteeInvalid: true,
              inviteeErrorMessage: getMessage("INVALID_EMAIL_PHONE"),
              inviteeErrorCode: "INVALID_EMAIL_PHONE",
            });
            this.showHideSpinner(false);
            return false;
          }
          arrayOfPhone[i].phone = getPhoneNumberWithCountryCode(
            arrayOfPhone[i].phone
          ).trim();
          allInvites.push(arrayOfPhone[i]);
        }
      }
      // validate phone no - ends
      this.setState({
        currentSessionInvitees: [...allInvites],
        selectedEmail: "",
        selectedPhone: "",
      });
    }
    return true;
  };

  // start instant meeting
  handleProceed = (closeActiveSession = false) => {
    if (this.isInputValid()) {
      this.setState({
        pastSessionErrorMessage: "",
        errorMessage: "",
        errorCode: "",
      });

      let passcode = document.getElementById("generatedPasscode")?.value;

      let sessionDetails = {
        passcode,
      };

      //join_with_passcode
      this.state.joinType === MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE ?
        sessionDetails.sessionTitle = getMessage("PASSCODE") + passcode
        : //setup_new_meeting
        (sessionDetails.sessionTitle = getStringWithoutExtraSpaces(this.state.sessionTitle));

      this.props.startSession(
        this.state.currentSessionInvitees,
        sessionDetails,
        this.instantSessionErrorHandler,
        closeActiveSession
      );
    } else {
      this.enableDisableProceedBtn(false);
    }
  };

  instantSessionErrorHandler = (pastSessionErrorMsg) => {
    this.setState({
      pastSessionErrorMessage: pastSessionErrorMsg,
    });
    this.showHideSpinner(false);
  };

  showHideSpinner = (showSpinner) => {
    this.setState({
      spinnerVisibility: showSpinner,
    });
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    
    if (code === KEY_CODE_ENTER) {
      this.enableDisableProceedBtn(true);
      this.sessionProceed.next(this.handleProceed);
    }
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      modalType: "",
      modalMessage: "",
      modalIcon: "",
    });
  };

  testEmail = () => {
    return this.state.selectedEmail.includes(",")
      ? this.state.selectedEmail
      : "";
  };

  testNumber = () => {
    let isCommaPresent = this.state.selectedPhone.includes(",");
    if (isCommaPresent) {
      return this.state.selectedPhone;
    }
    return "";
  };

  backToMenuHandle = () => {
   
    this.props.backToMenu();
  };

  changeJoinType = (joinType) => {
    this.setState(
      {
        joinType,
        errorMessage: "",
        inviteeErrorCode: "",
      },
      () => {
        //fetch passcode when switch from setup meeting to passcode tab
        if (
          joinType === MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE &&
          navigator.onLine
        ) {
          this.getPasscode();
        }
      }
    );
  };

  //show confirmation popup when agent wants to close session forcefully on existing tab and start new session on current tab/browser.
  showCloseMeetingConfirmationModal = () => {
    return (
      <Popup
        show={this.props.showCloseMeetingConfirmationPopUp}
        isDoubleButton={true}
        message={getMessage("MEETING_ALREADY_ACTIVE_MESSAGE")}
        shortNote={getMessage("MEETING_ALREADY_ACTIVE_NOTE")}
        handleYesClick={this.handleCreateSessionsYesClick}
        handleNoClick={this.handleCreateSessionsNoClick}
        isConfirmationPopUp={true}
      />
    );
  };

  handleCreateSessionsYesClick = () => {
    this.enableDisableProceedBtn(false);
    this.handleProceed(true);
  };

  handleCreateSessionsNoClick = () => {
    this.enableDisableProceedBtn(false);
    this.props.showHideCloseMeetingPopup(false);
  };

  enableDisableProceedBtn = (value) => {
    let proceedBtn = document.getElementById("proceedBtn");
    if (proceedBtn) {
      proceedBtn.disabled = value;
      value ? proceedBtn.classList.add("btn-disabled") : proceedBtn.classList.remove("btn-disabled");
    }
  };

  renderInviteeSection = () => {
    return (
      <div className="px-1">
        <input
          type="text"
          ref={this.inviteeInputRef}
          className="formControlName rounded-pill w-100 form-control"
          onChange={this.handleInviteeInputChange()}
          value={this.state.invitees}
          placeholder={getMessage("INVITE_INPUT_PLACEHOLDER")}
          wrapperstyle={{ width: "100%" }}
          onKeyDown={this.enterPressed}
        />
        <Row className="d-flex justify-content-center errors my-0 py-2">
          {this.state.inviteeErrorCode === "PROPS"
            ? this.state.inviteeErrorMessage
            : getMessage(this.state.inviteeErrorCode)}
        </Row>
      </div>
    );
  };

  setUpMeeting = () => {
    return (
      <>
        <div className="col-12 col-xl-12 px-4 pt-4">
          <span className="title">{getMessage("MEETING_SETTINGS")}</span>
          <span className="px-2 message">{getMessage("MEETING_MESSAGE")}</span>
        </div>

        <div
          className="col-12 col-xl-12 flexDirectionColumn px-4 pt-2"
          id="inviteeScrollView"
        >
          <label className="labelStyle pl-1">
            <img src={icBar} className="mr-2" />
            {getMessage("MEETING_TITLE")}
          </label>
          <InputGroup>
            <FormControl
              type="text"
              onChange={this.handleInputChange}
              value={this.state.sessionTitle}
              placeholder={getMessage("ENTER_SESSION_NAME_PLACEHOLDER")}
              aria-label="Enter name"
              aria-describedby="basic-addon2"
              className="formControlName rounded-pill"
              maxLength={25}
              onKeyPress={this.enterPressed}
              autoFocus={isIE ? false : true}
            />
          </InputGroup>
          <div className="errors autoHeight">
            {getMessage(this.state.errorMessage)}
          </div>
        </div>

        <div className="col-12 col-xl-12 px-4">
          <label className="labelStyle pl-1">
            <img src={icBar} className="mr-2" />
            {getMessage("HOW_TO_CONTACT_CUSTOMERS")}
            <span className="px-2">
              {getMessage("PLEASE_CHOOSE_ANY_GUIDANCE_METHOD")}
            </span>
          </label>
        </div>
        <div
          className="col-12 col-xl-12 flexDirectionColumn px-4 py-2"
          id="inviteeScrollView"
        >
          {this.renderInviteeSection()}
          <div className="d-flex justify-content-center">
            <Col sm={6} xl={4}>
              <button
                id="proceedBtn"
                className="startBtn btn shadow-none rounded-pill w-100 py-2"
                onClick={() => {
                  this.enableDisableProceedBtn(true);
                  this.sessionProceed.next(this.handleProceed);
                }}
              >
                {getMessage("SEND_SMS_OR_EMAIL")}
              </button>
            </Col>
          </div>
        </div>
      </>
    );
  };

  joinWithMeetingCode = () => {
    return (
      <div>
        <div className="col-12 col-xl-12 px-4 pt-4">
          <span className="pl-1 title d-flex align-items-center justify-content-start">
            <img src={icBar} className="mr-2" />
            {getMessage("SPECIFIED_MEETING_CODE")}
          </span>
          <div id="lookitPasscode"></div>
          <div className="d-flex pt-3 text-center justify-content-center">
            {getMessage("CONFERENCE_CODE_MESSAGE")}
          </div>
          <div className="d-flex justify-content-center pt-4 pb-3">
            <div className={"col-sm-10 col-md-4 col-lg-4 col-xl-4"}>
              <button
                id="proceedBtn"
                className="startBtn btn shadow-none rounded-pill py-2 w-100"
                onClick={() => {
                  this.enableDisableProceedBtn(true);
                  this.sessionProceed.next(this.handleProceed);
                }}
              >
                {getMessage("PARTICIPATE_IN_THE_MEETING")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  logoutUser = () => {
    this.setState({ passcodeAPIError: "" });
    logoutAction.logoutUser();
  }

  showPassCodeErrorModal = () => {
    return (
      <Popup
        isDoubleButton={false}
        cleanUp={this.logoutUser}
        show={!!this.state.passcodeAPIError}
        message={this.state.passcodeAPIError}
      />
    );
  };

  render() {
    return (
      <div className="startSession">
        {this.showPassCodeErrorModal()}
        {this.showCloseMeetingConfirmationModal()}
        {/*show spinner when update , schedule and proceed request intiated*/}
        {this.state.spinnerVisibility && (
          <Spinner showSpinner={this.state.spinnerVisibility} />
        )}

        <section>
          <div className="container-fluid">
            <div className="d-flex align-items-center pt-2 backBtn pl-2">
              <img
                src={icBack}
                className="pr-2 cursorPointer"
                onClick={this.backToMenuHandle}
              />
              <span className="cursorPointer" onClick={this.backToMenuHandle}>
                {getMessage("BACK")}
              </span>
            </div>
            <Row>
              <Col md={12} className="text-center">
                <img
                  src={icMeeting}
                  className="img-fluid session-img createSessionImage"
                  alt="Start Session"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center pb-4">
                <span className="sessionMenuTitle" lang={this.props.language}>
                  {getMessage("START_A_MEETING")}
                </span>
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <div className="card-body col-12 col-xl-9 mb-4">
                <div className="row">
                  {this.props.isPasscodeEnabled ? (
                    <div
                      className={
                        this.state.joinType ===
                          MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE
                          ? "col-6 text-center activeJoinType cursorPointer"
                          : "col-6 text-center joinType cursorPointer"
                      }
                      onClick={() =>
                        this.changeJoinType(
                          MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE
                        )
                      }
                    >
                      {getMessage("START_WITH_THE_SPECIFIC_CODE")}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={
                      this.state.joinType !==
                        MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE
                        ? "col-6 text-center activeJoinType cursorPointer"
                        : "col-6 text-center joinType cursorPointer"
                    }
                    onClick={() =>
                      this.changeJoinType(MEETING_JOIN_TYPE.SET_UP_NEW_MEETING)
                    }
                  >
                    {getMessage("SET_UP_NEW_MEETING")}
                  </div>
                </div>
                {this.state.joinType ===
                  MEETING_JOIN_TYPE.JOIN_WITH_MEETING_CODE
                  ? this.joinWithMeetingCode()
                  : this.setUpMeeting()}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.LanguageReducer.language,
    currentSessionInvitees: getCurrentSessionInvitees(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveCurrentSessionInvitees: actionFactory.saveCurrentSessionInvitees,
      setLanguageAction,
    },
    dispatch
  );
};

export default modalScrollBar(connect(mapStateToProps, mapDispatchToProps)(StartSession));
