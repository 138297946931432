import React, { Component } from "react";
import { Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { Label } from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import { validateSetPassword, validatePassword } from "UTILS/regexValidations";

import logo from "../../Assets/Images/img_signin_bg_logo.png";

import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";
import { KEY_CODE_ENTER } from "UTILS/Constants";
class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      view: false,
      confirmPassword: "",
      newPassword: "",
      isDisabled: false,
      networkError: this.props.networkError
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage === "Network Error" &&
      this.state.isDisabled === true
    ) {
      this.setState({ isDisabled: false });
    }
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value.trim(),
      errorMessage: "",
      isDisabled: false
    });
  };
  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleChangePassword();
    }
  };

  ChangePasswordFieldView = () => {
    this.setState({ view: !this.state.view });
  };

  handleChangePassword = () => {
    let errorMessage = "";
    if(!this.state.newPassword) {
      errorMessage = "ENTER_NEW_PASSWORD"
    } else if(!this.state.confirmPassword) {
      errorMessage = "ENTER_CONFIRM_PASSWORD"
    } else {
      errorMessage = validateSetPassword(
        this.state.newPassword,
        this.state.confirmPassword
      );
    }
    if (errorMessage) {
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ isDisabled: true });
      let request_data = {
        confirmPassword: this.state.confirmPassword,
        newPassword: this.state.newPassword
      };
      this.props.handleSetPassword(request_data, (error) => {
        if(error)
          this.setState({
            errorMessage:  error.code
          })
        else 
          this.setState({
            errorMessage: "",
            newPassword: "",
            confirmPassword: "",
          })
      });
    }
  };

  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
  };

  render() {
    return (
      <React.Fragment>
        <div className="mt-3">
          <ChangeLanguage
            onSelectChange={this.onSelectChange}
            language={this.props.language}
          />
        </div>
        <div className="container-fluid SignIn">
          <Row className="setPasswordInner">
            <div className="SignInConatiner card card-body"
            >
              <Row className="justifyContentFlexCenter noMargin w-100">
                <img className=" img-fluid SignInLogo" src={logo} />
              </Row>
              <Row className="justifyContentFlexCenter noMargin w-100">
                <Label className="SignInLabel">
                  {getMessage("SET_PASSWORD")}
                </Label>
              </Row>
              <Row>
                <Label className="SignInPasswordLabel text-left">
                  {getMessage("NEW_PASSWORD")}
                </Label>
              </Row>
              <Row className="noMargin">
                <InputGroup>
                  <FormControl
                    type={this.state.view ? "text" : "password"}
                    onChange={this.handleInputChange}
                    name="newPassword"
                    autoFocus={true}
                    className="SignInPasswordInput"
                    onKeyPress={this.enterPressed}
                    value={this.state.newPassword}
                    placeholder={getMessage("NEW_PASSWORD_PLACEHOLDER")}
                  />
                </InputGroup>
              </Row>
              <Row>
                <Label className="SignInPasswordLabel text-left">
                  {getMessage("CONFIRM_PASSWORD")}
                </Label>
              </Row>
              <Row className="noMargin">
                <InputGroup>
                  <FormControl
                    type={this.state.view ? "text" : "password"}
                    onChange={this.handleInputChange}
                    name="confirmPassword"
                    className="SignInPasswordInput"
                    onKeyPress={this.enterPressed}
                    value={this.state.confirmPassword}
                    placeholder={getMessage("CONFIRM_PASSWORD_PLACEHOLDER")}
                  />
                </InputGroup>
              </Row>

              <Row className="justifyContentFlexCenter noMargin">
                <div className="text-right SignInForgotLabelContainer w-100" />
                <Button
                  className="customBtn btnSignIn"
                  onClick={this.handleChangePassword}
                  disabled={this.state.isDisabled}
                >
                  {getMessage("SUBMIT")}
                </Button>
              </Row>
              {this.state.errorMessage &&
                <div className="errors  d-flex">
                  {getMessage(this.state.errorMessage)}
                </div>
              }
             
            </div>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default SetPassword;
