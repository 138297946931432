import { API_URL } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class ResetPasswordAction extends BaseAction {
  constructor(props) {
    super();
    BaseAction.call(this);
  }
  resetAction(data) {

    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.FORGOT_PASSWORD
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}

export class SetPasswordAction extends BaseAction {
  constructor(props) {
    super(props);
    BaseAction.call(this);
  }
  setPasswordAction(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.SET_PASSWORD
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
