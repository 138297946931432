import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { wsocket } from "WEBSOCKET/wsocket";
import { clearState } from "REDUX/SessionStorage.js";
import { HTTP_METHODS } from "UTILS/Constants";

//never make this function as arrow function
export class LeaveSessionAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  /**
   * User this method to clean up all data except login token
   */

  endSessionAndCleanUp(sessionKey) {
    let self = this;
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: { sessionKey: sessionKey },
        url: API_URL.END_SESSION
      }).then(
        response => {
          // self.localSessionAllDataCleanUp();
          resolve(response);
        },
        error => {
          reject(error);
          //self.localSessionAllDataCleanUp();
        }
      );
    });
  }

  localSessionAllDataCleanUp(cleanLocalFontInfo = false) {

    this.getState().ParticipantReducer.participants.map(participant => {
      if (
        participant &&
        wsocket.audioAndVideoRtcPeer[participant.uniqueId] !== undefined
      ) {
        Object.keys(wsocket.audioAndVideoRtcPeer[participant.uniqueId]).map((key) => {
          wsocket.audioAndVideoRtcPeer[participant.uniqueId][key].dispose();
        });
        wsocket.audioAndVideoRtcPeer[participant.uniqueId] = undefined;
      }
    });

    if (wsocket.screenShareRtcPeer !== null) {
      wsocket.screenShareRtcPeer.dispose();
      wsocket.screenShareRtcPeer = null;
    }

    if (wsocket.snapshotRtcPeer !== null) {
      wsocket.snapshotRtcPeer.dispose();
      wsocket.snapshotRtcPeer = null;
    }

    this.dispatch({
      type: ACTIONS.LEAVE_SESSION_LOCAL,
      cleanLocalFontInfo: cleanLocalFontInfo
    });
  }

  localReducerAllDataCleanUp() {
    this.dispatch({
      type: ACTIONS.LEAVE_SESSION_LOCAL
    });
  }

  logoutUser() {
    wsocket.io = false;
    clearState();
  }
  
  clearStore = () => {
    this.dispatch({
      type: ACTIONS.CLEAR_STORE
    });
  }
}
