import React from "react";
import { func } from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";

// Strings
import { getMessage } from "CONFIG/i18n";
import { validateUserName, validateMobileNumber } from "UTILS/regexValidations";
import {KEY_CODE_ENTER, USER_ROLES} from "UTILS/Constants";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
import {
  getPhoneNumberWithCountryCode,
  getStringWithoutExtraSpaces,
} from "UTILS/Utility";

import "../SuperAdmin/Organisations/AddOrganization.less";
import "../App/App.less";
import "./AdminDashboard.less";

class AddUserModal extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      role: USER_ROLES.AGENT,
      email: "",
      phone: "",
      errorMessage: ""
    };
    this.roleOptions = [
      {
        name: getMessage("ROLE_AGENT"),
        value: USER_ROLES.AGENT,
      },
      {
        name: getMessage("ROLE_ADMIN"),
        value: USER_ROLES.ADMIN,
      },
    ]
  }

  componentWillUnmount() {
    this.disableCreateUserBtn(false);
  }

  setFocus = () => {
    document.getElementById("email").focus();
  };

  isValidData = () => {
    let UserDetail = this.state;
    if(UserDetail.phone && !validateMobileNumber(UserDetail.phone)) {
      this.setState({ errorMessage: getMessage("VALID_PHONE") });
      return false;
    }
    if(!UserDetail.email || !UserDetail.email.trim()){
      this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
      return false;
    } else {
      if (!validateUserName(UserDetail.email)) {
        this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
        return false;
      }
    }
    if(!UserDetail.firstName || !UserDetail.firstName.trim()){
      this.setState({ errorMessage: getMessage("INVALID_FIRST_NAME") });
      return false;
    }
    if(!UserDetail.lastName || !UserDetail.lastName.trim()){
      this.setState({ errorMessage: getMessage("INVALID_LAST_NAME") });
      return false;
    }
    
    return true;
  };

  handleAddClick = () => {
    if (!this.isValidData()) {
      this.disableCreateUserBtn(false);
      return;
    }
    this.setState({ errorMessage: "" });

    let data = Object.assign(
      {},
      {
        firstName: getStringWithoutExtraSpaces(this.state.firstName),
        lastName: getStringWithoutExtraSpaces(this.state.lastName),
        role: this.state.role.toUpperCase(),
        username: this.state.email.trim(),
        phone: this.state.phone ? getPhoneNumberWithCountryCode(this.state.phone) : undefined,
        language: this.props.language
      }
    );

    this.props.handleAddClick(data, errorMessage => {
      this.setState({ 
        errorMessage: errorMessage,
        role: USER_ROLES.AGENT,
      });
      this.disableCreateUserBtn(true);
    });
  };

  handleCancelClick = () => {
    this.resetInputFields();
    this.props.handleCancelClick();
  };

  handleInputChange = (event) => {
    if(event)
    {
      this.disableCreateUserBtn(false);
    }
    if(event.target.name === "phone"){
      this.setState({
        [event.target.name]: event.target.value.trim()
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
    
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }
  };

  handleDropdownChange = (name, value) => {
    this.setState({
      [name]: value,
      errorMessage: "",
    });
  }

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button 
          id = "createUserBtn"
          onClick={() => {
            this.disableCreateUserBtn(true);
            this.handleAddClick();
          } 
          }
          className="buttons customBtn">
          {getMessage("CREATE")}
        </Button>
      </div>
    );
  };

  disableCreateUserBtn = (value) =>{
    let createBtn = document.getElementById("createUserBtn");
    if(createBtn){
      createBtn.disabled = value;
      if(value)
        createBtn.classList.add("pointerEventsNone");
      else
        createBtn.classList.remove("pointerEventsNone");
    }
  }

  resetInputFields =()=> {
    this.setState({
      errorMessage: "",
      firstName: "",
      lastName: "",
      role: USER_ROLES.AGENT,
      email: "",
      phone: ""
    })
  }

  enterPressed = (event) => {
    //check if user pressed enter key
    const code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleAddClick();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="addOrganisationModal userAddModal modal-width-50"
        toggle={this.handleCancelClick}
        onClosed={this.resetInputFields}
        backdrop="static"
        onOpened={this.setFocus}
      >
        <ModalHeader className="popupHeader">
          {getMessage("ADD_USER")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label className="label modal-label">{getMessage("TYPE_OF_USER")}</Label>
              <div className="col-9 pl-5 pr-0">
                <CustomDropdown
                  options={this.roleOptions}
                  onChange={(value) => this.handleDropdownChange("role", value)}
                  value={this.state.role}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("EMAIL")}
                <span className="asterisk">*</span>
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={this.handleInputChange}
                className="inputFormControl"
                maxLength="50"
                placeholder={getMessage("ENTER_EMAIL_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("FIRST_NAME")}
                <span className="asterisk">*</span>
              </Label>
              <Input
                type="text"
                name="firstName"
                onChange={this.handleInputChange}
                className="inputFormControl"
                maxLength="50"
                placeholder={getMessage("FIRST_NAME_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("LAST_NAME")}
                <span className="asterisk">*</span>
              </Label>
              <Input
                type="text"
                name="lastName"
                onChange={this.handleInputChange}
                className="inputFormControl"
                maxLength="50"
                placeholder={getMessage("LAST_NAME_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">{getMessage("PHONE_NUMBER")}</Label>
              <Input
                type="phone"
                name="phone"
                id="phone"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.phone}
                placeholder={getMessage("ENTER_PHONE_NUMBER_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <div className="errors">{this.state.errorMessage}</div>
          </Form>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modalScrollBar(AddUserModal);
