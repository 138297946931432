export const saveState = state => {
  try {
    var serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (error) {}
};

export const loadState = () => {
  try {
    var serializedState = JSON.parse(sessionStorage.getItem("state"));
    if (serializedState) {
      return serializedState;
    }
  } catch (error) {
    return null;
  }
};
export const clearState = () => {
  try {
    sessionStorage.setItem("state", null);
  } catch (error) {}
};
