import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "REDUX/store";
import Router from "../../Routes/Router";
import ShowTostr from "../ShowTostr/ShowTostr";
import "./App.less";
import { onNetworkOfflineHandler, onNetworkOnlineHandler } from "Utils/NetworkUtility";
import { organisationAction } from "CONFIG/ActionFactory";

const App = () => {

  useEffect(() => {

    // add network listeners when the component mounts
    window.addEventListener("online", () => onNetworkOnlineHandler());
    window.addEventListener("offline", () => onNetworkOfflineHandler());
    window.addEventListener("unload", () => resetLogoURLHandler());
    
    // remove the network listeners when the component unmounts
    return () => {
      window.removeEventListener("online", () => onNetworkOnlineHandler());
      window.removeEventListener("offline", () => onNetworkOfflineHandler());
      window.removeEventListener("unload", () => resetLogoURLHandler());
    };

  }, []);

  const resetLogoURLHandler = ()=>{
    organisationAction.resetLogoUrl();
  }

  return (
    <div className="App">
      <Provider store={store}>
        <ShowTostr />
        <Router />
      </Provider>
    </div>
  );
};

export default App;
