import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutAction } from "CONFIG/ActionFactory";

import Logout from "./Logout";
class LogoutContainer extends Component {
  componentDidMount(){
    this.roleByPath=this.props.roleByPath
    logoutAction.logoutUserAction();
  }
  render() {
    return (
      <>
        <Logout
          history={this.props.history}
          roleByPath={this.roleByPath}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    roleByPath: state.UserReducer.userDetails.roleByPath
  };
};

export default connect(
  mapStateToProps,
  {}
)(LogoutContainer);