import React, { Component } from 'react';
import { Input } from "reactstrap";
import ic_checkbox_selected  from "ASSETS/Images/ic_checkbox_selected.svg";
import ic_checkbox_selected_disabled  from  "ASSETS/Images/ic_checkbox_selected_disabled.svg";
//Styles
import "./Checkbox.less";

const Checkbox =(props)=>{
  return (
    <div>
      {/* load checkbox images for displaying in offline mode */}
      <div className='d-none'>      
        <img src={ic_checkbox_selected} alt="ic_checkbox_selected" />
        <img src={ic_checkbox_selected_disabled} alt="ic_checkbox_selected_disabled" />
      </div>
      <div className={'customisedCheckbox ' + (props.className ? props.className : '') + `${props.disabled ? ' btnDisabled' : ' '}`}>
        <Input
          type="checkbox"
          name={props.name}
          onChange={props.onChange}
          checked={props.checked}
        />
        <span></span>      
      </div>
    </div>    
  );
}

export default Checkbox;