import React, { Component } from "react";
import { Input } from "reactstrap";
import ic_checkbox_multi_selected from "ASSETS/Images/ic_checkbox_multi_selected.svg" ;
import ic_checkbox_all_selected from"ASSETS/Images/ic_checkbox_all_selected.svg" ;
import "./HeaderCheckbox.less";

const HeaderCheckbox = (props) => {
  return (
    <div>
      {/* load checkbox images for displaying in offline mode */}
      <div className={'d-none'} >      
        <img src={ic_checkbox_multi_selected} alt="ic_checkbox_multi_selected" />
        <img src={ic_checkbox_all_selected} alt="ic_checkbox_all_selected" />
      </div>
      <div
        className={"customised-header-checkbox" + (props.className ? ' ' + props.className : '')}>
        <Input
          type="checkbox"
          name={props.name}
          onChange={props.onChange}
          disabled={props.disabled}
          checked={props.checked}
        />
        <span></span>
      </div>
    </div>
  );
};

export default HeaderCheckbox;
