import React from "react";
import { func } from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";

// Strings
import { getMessage } from "CONFIG/i18n";
import { validateUserName, validateMobileNumber } from "UTILS/regexValidations";
import {
  getPhoneNumberWithCountryCode,
  getStringWithoutExtraSpaces,
} from "UTILS/Utility";

import "../SuperAdmin/Organisations/AddOrganization.less";
import "../App/App.less";
import "./AdminDashboard.less";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
import { KEY_CODE_ENTER } from "UTILS/Constants";

class EditUserModal extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };
  constructor(props) {
    super(props);
    this.state = {
      customerId: this.props.editUserData.id,
      first_name: this.props.editUserData.first_name,
      last_name: this.props.editUserData.last_name,
      role: this.props.editUserData.role,
      email: this.props.editUserData.email,
      phone: this.props.editUserData.phone
    };
  }

  componentDidUpdate(preProps) {
    if (
      preProps.editUserData.id !== this.props.editUserData.id
    ) {
      this.setState({ customerId: this.props.editUserData.id });
    }
    if (
      preProps.editUserData.first_name !== this.props.editUserData.first_name
    ) {
      this.setState({ first_name: this.props.editUserData.first_name });
    }
    if (preProps.editUserData.last_name !== this.props.editUserData.last_name) {
      this.setState({ last_name: this.props.editUserData.last_name });
    }
    if (preProps.editUserData.role !== this.props.editUserData.role) {
      this.setState({ role: this.props.editUserData.role });
    }
    if (preProps.editUserData.email !== this.props.editUserData.email) {
      this.setState({ email: this.props.editUserData.email });
    }
    if (preProps.editUserData.phone !== this.props.editUserData.phone) {
      this.setState({ phone: this.props.editUserData.phone });
    }
    if (this.props.show != preProps.show && !this.props.show) {
      this.resetStateToPrevState();
    }
  }

  setFocus = () => {
    document.getElementById("firstName").focus();
  };

  isValidData = () => {
    let UserDetail = this.state;
 
    if(UserDetail.phone && !validateMobileNumber(UserDetail.phone)){
      this.setState({ errorMessage: getMessage("VALID_PHONE") });
      return false;
    }

    if(!UserDetail.email || !UserDetail.email.trim()){
      this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
      return false;
    }
    else{
      if (!validateUserName(UserDetail.email)) {
        this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
        return false;
      }
    }
    if(!UserDetail.first_name || !UserDetail.first_name.trim()){
      this.setState({ errorMessage: getMessage("INVALID_FIRST_NAME") });
      return false;
    }
    if(!UserDetail.last_name || !UserDetail.last_name.trim()){
      this.setState({ errorMessage: getMessage("INVALID_LAST_NAME") });
      return false;
    }
    return true;
  };

  handleEditedSave = () => {
    if (
      this.state.first_name !== this.props.editUserData.first_name ||
      this.state.last_name !== this.props.editUserData.last_name ||
      this.state.role !== this.props.editUserData.role ||
      this.state.email !== this.props.editUserData.email ||
      this.state.phone !== this.props.editUserData.phone
    ) {
      return true;
    } else return false;
  };

  handleSaveClick = () => {
    this.setState({ errorMessage: "" });
    if (!this.isValidData()) {
      return;
    }
    let data = Object.assign(
      {},
      {
        id: this.state.customerId,
        firstName: getStringWithoutExtraSpaces(this.state.first_name),
        lastName:getStringWithoutExtraSpaces(this.state.last_name),
        role: this.state.role,
        username: this.state.email.trim(),
        phone: this.state.phone ? getPhoneNumberWithCountryCode(this.state.phone) : undefined,
        language: this.props.language
      }
    );
    this.handleEditedSave()
      ? this.props.handleSaveClick(data, error => {
        this.setState({ errorMessage: error.message });
      })
      : this.props.handleCancelClick();
  };

  handleCancelClick = () => {
    this.resetStateToPrevState();
    this.props.handleCancelClick();
  };

  handleInputChange = event => {
    if(event.target.name === "phone") {
      this.setState({
        [event.target.name]: event.target.value.trim()
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }
  };

  resetStateToPrevState = () => {
    this.setState({
      customerId: this.props.editUserData.id,
      first_name: this.props.editUserData.first_name,
      last_name: this.props.editUserData.last_name,
      role: this.props.editUserData.role,
      email: this.props.editUserData.email,
      phone: this.props.editUserData.phone,
      errorMessage: ""
    });
  };

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button onClick={() => this.handleSaveClick()} className="buttons customBtn">
          {getMessage("SAVE")}
        </Button>
      </div>
    );
  };

  enterPressed = (event) => {
    //check if user pressed enter key
    const code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleSaveClick();
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="addOrganisationModal userAddModal"
        toggle={this.handleCancelClick}
        backdrop="static"
        onOpened={this.setFocus}
      >
        <ModalHeader className="popupHeader">
          {getMessage("EDIT_USER")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label className="label modal-label">{getMessage("TYPE_OF_USER")}</Label>
              <Input
                type="text"
                name="role"
                id="role"
                className="inputFormControl"
                onChange={this.handleInputChange}
                defaultValue={_.capitalize(this.state.role)}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("EMAIL")}
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.email}
                maxLength="50"
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("FIRST_NAME")}  
                <span className="asterisk">*</span>
              </Label>
              <Input
                type="text"
                name="first_name"
                id="firstName"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.first_name}
                maxLength="50"
                placeholder={getMessage("FIRST_NAME_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">
                {getMessage("LAST_NAME")}
                <span className="asterisk">*</span>
              </Label>
              <Input
                type="text"
                name="last_name"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.last_name}
                maxLength="50"
                placeholder={getMessage("LAST_NAME_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup>
              <Label className="label modal-label">{getMessage("PHONE_NUMBER")}</Label>
              <Input
                type="phone"
                name="phone"
                id="phone"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.phone}
                placeholder={getMessage("ENTER_PHONE_NUMBER_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <div className="errors">{this.state.errorMessage}</div>
          </Form>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modalScrollBar(EditUserModal);
