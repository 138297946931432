import React, { useEffect, useRef, useState } from "react";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Row, Col, Label, Button, Input } from "reactstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import Pagination from "react-js-pagination";

//Assest
import icDeleteAgent from "ASSETS/Images/ic_delete.svg";
import icCopyQRCodeLink from "ASSETS/Images/ic_copy_link.svg";
import icDownloadQRCode from "ASSETS/Images/ic_download.svg";
import icDeleteQRCode from "ASSETS/Images/ic_delete_circle.svg";
import icEditQRCode from "ASSETS/Images/ic_edit_circle.svg";
import ic_RealWear from "ASSETS/Images/ic_RealWear.svg";

import { getMessage } from "CONFIG/i18n";
import { PAGE_COUNT, REGEX } from "UTILS/Constants";
import { getQRCodeUrl, getStringWithoutExtraSpaces } from "UTILS/Utility";

//Component
import toastr from "COMPONENTS/ShowTostr/ShowTostr";
import GenerateAndDownloadQRCode from "COMPONENTS/CommonComponents/GenerateAndDownloadQRCode/GenerateAndDownloadQRCode";
import EditQRCodeModal from "./EditQRCodeModal";
import DeleteConfirmationModal from "COMPONENTS/CommonComponents/DeleteConfirmationModal/DeleteConfirmationModal";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import HeaderCheckbox from "COMPONENTS/CommonComponents/Checkbox/HeaderCheckbox";

import { qrCodeAgentsAction } from "CONFIG/ActionFactory";

import "./QRCodeAgents.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import RealWearQRCodeModal from "./RealWearQRCodeModal";

const QRCodeAgents = (props) => {
  //useRef
  const generateAndDownloadQRCodeRef = useRef();
  //useState
  const [showEditQRCodeModal, setShowEditQRCodeModal] = useState(false);
  const [
    showQRCodeDeleteConfirmationModal,
    setShowQRCodeDeleteConfirmationModal,
  ] = useState(false);
  const [showDeleteQRCodeAgentsConfirmationModal,setShowDeleteQRCodeAgentsConfirmationModal]= useState(false);
  const [searchRequest, setSearchRequest] = useState({
    searchTimeout: null,
    searchString: "",
  });
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedAgentsIds, setSelectedAgentsIds] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedQRCode, setSelectedQRCode] = useState({});
  const { searchString, searchTimeout } = searchRequest;
  const [ showRealWearQRCodeModal, setShowRealWearQRCodeModal ] = useState(false);

  useEffect(() => {
    // Fetch unassigned agents on page load 
    // Maintain page size and page no. on refresh
    props.getQRCodeAgents(props.qrCodeAgentsPageSize, props.qrCodeAgentsPageNo);

    // Reset page size and page no. on component unmount so that it starts from initial values next time on component mounts
    return () => {
      qrCodeAgentsAction.saveQRCodeAgentsPageSize(PAGE_COUNT[0].value);
      qrCodeAgentsAction.saveQRCodeAgentsPageNo(0);
    };
  }, []);

  const handleAllAgentsSelection = (event) => {
    const isSelectAllChecked = event.target.checked;
    setIsSelectAllChecked(isSelectAllChecked);

    // If all records are selected then fetch the list of all records and store those records in selectedAgents state
    // otherwise selectedAgents will contain the number of recordsvisible on the page as per provided page size.
    if (isSelectAllChecked) {
      props.getQRCodeAgents(
        props.qrCodeAgentsCount,
        0,
        getStringWithoutExtraSpaces(searchString) ,
        isSelectAllChecked,
        (allAgentsSelected) => {
          setSelectedAgents(allAgentsSelected);
        }
      );
    } else {
      setSelectedAgents([]);
    }
  }

  // Select a single record
  const handleSingleAgentSelection = (selectedAgentObject, e) => {

    if(e.target.checked) {
      let agents = [...selectedAgents];
      agents.push(selectedAgentObject);

      if(agents.length === props.qrCodeAgentsCount) {
        // Check header checkbox if all rows are selected using respective checkbox
        setIsSelectAllChecked(true);
      }
      setSelectedAgents(agents); 
    } else {
      let agents = selectedAgents.filter( (agent) => { 
        return agent.id !== selectedAgentObject.id; 
      });
      setSelectedAgents(agents);
      // Uncheck header checkbox if any row is deselected(unchecked)
      setIsSelectAllChecked(false);
    }
  }

  // Check if the record is present in selectedAgents state and check/uncheck respective checkboxes in the table
  const isAgentSelected = (agentObject) => {
    return selectedAgents.find((agent) => {return agentObject.id === agent.id;}) ? true : false;
  };  

  useEffect(() => {
    // Handled header checkbox on updated of selectedAgents and props.qrCodeAgentsCount.
    if (selectedAgents.length >= props.qrCodeAgentsCount) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
  }, [selectedAgents, props.qrCodeAgentsCount]); 

  const search = (event) => {
    let searchData = event.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    if (searchString !== searchData)
    {
      setSearchRequest({
        searchString: searchData,
        searchTimeout: setTimeout(() => {
          props.getQRCodeAgents(
            props.qrCodeAgentsPageSize,
            0,
            getStringWithoutExtraSpaces(searchData)
          );
        }, 1000),
      });
    }
  };

  const getQRCodeAgents = (page) => {
    props.getQRCodeAgents(
      props.qrCodeAgentsPageSize,
      page - 1,
      searchString ? searchString : undefined
    );
  };

  const downloadQRCode = () => {
    if (generateAndDownloadQRCodeRef.current) {
      generateAndDownloadQRCodeRef.current?.downloadQRCode();
    } else {
      console.error("downloadQRCode():reference is not defined");
    }
  };

  const editQRCode = () => {
    setShowEditQRCodeModal(true);
    setSelectedQRCode(props.selectedQRCode);
  };

  const closeEditQRCodeModal = () => {
    setShowEditQRCodeModal(false);
    setSelectedQRCode({});
  };

  const deleteQRCodeAgent = (agentId) => {
    setSelectedAgentsIds(agentId);
    setShowDeleteQRCodeAgentsConfirmationModal(true);
  }

  const deleteSelectedQRCodeAgents = () => {
    let agentIds = selectedAgents.map(agent => agent.id)
    setSelectedAgentsIds(agentIds)
    setShowDeleteQRCodeAgentsConfirmationModal(true);
  }

  const closeDeleteQRCodeAgentsModal = () => {
    setShowDeleteQRCodeAgentsConfirmationModal(false);
  };

  const deleteQRCode = () => {
    setShowQRCodeDeleteConfirmationModal(true);
  };

  const closeDeleteQRCodeModal = () => {
    setShowQRCodeDeleteConfirmationModal(false);
  };

  const handleDeleteQRCodeClick = () => {
    setShowQRCodeDeleteConfirmationModal(false);
    props.deleteQRCode(props.selectedQRCode);
  };
  
  const handleAddQRCodeAgentsClick = () => {
    props.redirectToAssignAgents();
  }

  const handleDeleteQRCodeAgentsClick = () => {
    setShowDeleteQRCodeAgentsConfirmationModal(false);
    props.deleteQRCodeAgents(selectedAgentsIds);
    setSelectedAgents([]);
    setSelectedAgentsIds([]);
    setSearchRequest({
      searchString: "",
      searchTimeout: null,
    });
  };

  const firstNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`firstName${row.id}`}
        >
          {row.firstName}
          <CustomTooltip
            tooltipText={row.firstName}
            tooltipId={`firstName${row.id}`}
          />
        </label>
      </div>
    );
  };

  const lastNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`lastName${row.id}`}
        >
          {row.lastName}
          <CustomTooltip
            tooltipText={row.lastName}
            tooltipId={`lastName${row.id}`}
          />
        </label>
      </div>
    );
  };

  const emailFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`email${row.id}`}
        >
          {row.email}
          <CustomTooltip tooltipText={row.email} tooltipId={`email${row.id}`} />
        </label>
      </div>
    );
  };

  const actionsFormat = (cell, row) => {
    return (
      <div className="justify-content-center d-flex">
        <img
          src={icDeleteAgent}
          id={`delete${row.id}`}
          className={`cursorPointer delete-action-img ${!selectedAgents.length > 0 ? "d-block" : "d-none"}`}
          onClick={(e) => {
            e.stopPropagation();
            deleteQRCodeAgent([row.id]);
          }}
        />
        <CustomTooltip
          tooltipText={getMessage("DELETE")}
          tooltipId={`delete${row.id}`}
        />
      </div>
    );
  };

  const customMultiSelect = (customMultiSelectProps) => {
    const { rowIndex } = customMultiSelectProps;
    const agent = props.qrCodeAgentsList[rowIndex];
    /*
     * If rowIndex is 'Header', means this rendering is for header selection column.
     */
    if (rowIndex === "Header") {
      return (
        <div >
          <Label className="label noPadding">
            <HeaderCheckbox
              onChange={(event) => handleAllAgentsSelection(event)}
              checked={isSelectAllChecked}
              className={selectedAgents.length > 0 ? selectedAgents.length >=  props.qrCodeAgentsCount 
                ? "customised-header-checkbox-all-selected"
                : "customised-header-checkbox-multi-selected" : ""}
            />
          </Label>
        </div>
      );
    } else {
      return (
        <div>
          <Label className="label noPadding">
            <Checkbox
              key={rowIndex}
              onChange={(e) => {
                handleSingleAgentSelection(agent, e);
              }}
              checked={isAgentSelected(agent)}
            />
          </Label>
        </div>
      );
    }
  };

  const selectRowClass = (row, rowIndex) =>{
    if (rowIndex !== "Header" && isAgentSelected(props.qrCodeAgentsList[rowIndex])) {
      return "selected-bg-color";
    }
    return "";
  };

  const selectRowProp =  {
    mode: "checkbox",
    customComponent: customMultiSelect,
    columnWidth: "0.33rem",
  };

  const renderQRCodeAgentsList = () => {
    return (
      <BootstrapTable
        data={props.qrCodeAgentsList}
        tableHeaderClass="tableHeader"
        tableBodyClass="tableBody"
        trClassName={selectRowClass}
        containerClass="custom-list col-lg-8"
        selectRow={selectRowProp}
        bordered={false}
        printable
        maxHeight="28rem"
      >
        <TableHeaderColumn
          dataField="email"
          width="25"
          dataSort={true}
          isKey={true}
          dataFormat={emailFormat}
        >
          {getMessage("EMAIL")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="firstName"
          dataSort={true}
          width="13"
          className="nameEllipsis"
          headerAlign="center"
          dataAlign="center"
          dataFormat={firstNameFormat}
        >
          {getMessage("FIRST_NAME")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lastName"
          width="13"
          dataSort={true}
          dataFormat={lastNameFormat}
          headerAlign="center"
          dataAlign="center"
        >
          {getMessage("LAST_NAME")}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="10"
          dataFormat={actionsFormat}
          headerAlign="center"
          dataAlign="center"
        >
          {getMessage("ACTIONS")}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  const renderQRCodeInfo = () => {
    let qrCodeDetails = { "qrId": props.selectedQRCode.qrId, "name": props.selectedQRCode.label, "number": props.selectedQRCode.description };
    return (
      <div className="qr-code-info margin">
        {showRealWearQRCodeModal &&
          <RealWearQRCodeModal
            show={showRealWearQRCodeModal}
            onClosed={()=>setShowRealWearQRCodeModal(false)}
            qrCodeDetails={qrCodeDetails}
          />
        }
        <div className="qr-code-img">
          <GenerateAndDownloadQRCode
            ref={generateAndDownloadQRCodeRef}
            downloadQRCode={true}
            fileName={`QRCode-${props.selectedQRCode?.label ? getStringWithoutExtraSpaces(props.selectedQRCode?.label).replace(REGEX.SPECIAL_CHARACTERS, '') : ""}-${props.selectedQRCode.qrId}`}
            value={getQRCodeUrl(props.selectedQRCode.qrId)}
            id={"qrCodeAgents" + props.selectedQRCode.qrId}
          />
        </div>
        <div className="qr-code-title pb-3">
          {<span>{props.selectedQRCode.label}</span>}
        </div>

        <div className="qr-code-description text-wrap columnText">
          <Label>{getMessage("DESCRIPTION")}</Label>
          <br />
          {<span>{props.selectedQRCode.description}</span>}
        </div>
        <div>
          <CopyToClipboard
            text={getQRCodeUrl(props.selectedQRCode.qrId)}
            onCopy={(text, result) => {
              if (result)
                toastr.info("QR_CODE_LINK_COPIED");
              else
                toastr.error("QR_CODE_LINK_NOT_COPIED");
            }}
          >
            <div className="cursorPointer d-flex alignItemsCenter pb-3 pl-3 pt-2">
              <img src={icCopyQRCodeLink} alt="Copy" />
              <br />
              <span className="icon-label">{getMessage("COPY_LINK")}</span>
            </div>
          </CopyToClipboard>
        </div>

        <div className="d-flex alignItemsCenter pb-3 pl-3">
          <img
            src={icDownloadQRCode}
            alt="download"
            onClick={() => downloadQRCode()}
            className="cursorPointer"
          />
          <br />
          <span className="icon-label cursorPointer"  onClick={() => downloadQRCode()}>{getMessage("DOWNLOAD")}</span>
        </div>
        <div className="d-flex alignItemsCenter pb-3 pl-3">
          <img
            src={icEditQRCode}
            alt="edit"
            onClick={() => editQRCode()}
            className="cursorPointer"
          />
          <br />
          <span className="icon-label cursorPointer" onClick={() => editQRCode()} >{getMessage("EDIT")}</span>
        </div>
        <div className="d-flex alignItemsCenter pb-3 pl-3">
          <img
            src={icDeleteQRCode}
            alt="delete"
            onClick={() => deleteQRCode()}
            className="cursorPointer"
          />
          <br />
          <span className="icon-label cursorPointer"  onClick={() => deleteQRCode()}>{getMessage("DELETE")}</span>
        </div>
        <div className="mb-3 ml-3 mr-3 seperator-menu"></div>
        <div className="d-flex alignItemsCenter pb-3 pl-3" >
          <img
            src={ic_RealWear}
            alt="realwear"
            onClick={() => setShowRealWearQRCodeModal(true)}
            className="cursorPointer"
          />
          <br />
          <span className="icon-label cursorPointer" onClick={() => setShowRealWearQRCodeModal(true)}>{getMessage("RW_QR_CODE")}</span>
        </div>
      </div>
    );

  };

  const renderQRCodeAgentsHeader = () => {
    return(
   <>
      <div className="associated-agents-label">
        {getMessage("ASSOCIATED_AGENTS_TITLE", {
          associatedAgents: props.associatedAgentsCount,
        })}
      </div>

      <Row className="button d-flex">
        <Col className="borderRight">
          <Input
            className="formControl form-control form-control-search"
            value={searchString}
            placeholder={getMessage("SEARCH")}
            onChange={search}
          />
        </Col>

        <div className="pl-2">
          <Button
            id="addButton"
            onClick={!selectedAgents.length > 0 ? handleAddQRCodeAgentsClick : false}
            className={`buttons customBtn ${!selectedAgents.length > 0 ? 'btn-enabled': 'btn-disabled'}`}
            autoFocus
          >
            {getMessage("ADD")}
          </Button>
        </div>

        <div className="pl-2">
          <Button
            onClick={selectedAgents.length > 0 ? deleteSelectedQRCodeAgents : false}
            id="deleteButton"
            className={`buttons borderBtn bgwhite ${
              selectedAgents.length > 0 ? "btn-enabled" : "btn-disabled"
            }`}
            autoFocus
          >
            {getMessage("DELETE")}
          </Button>
        </div>
      </Row>
   </>
    );
  };

  const renderQRCodeAgentsListPagination = () => {
    return (
      <>
          <div className="pagination-margin  px-2 pt-2">
            <Row className="paginationRow">
              <Col xs={4} className="paginationEntries">
                {getMessage("PAGINATION_TOTAL_ENTRIES", {
                  from:
                    props.qrCodeAgentsPageNo *
                      props.qrCodeAgentsPageSize +
                    1,
                  to:
                    props.qrCodeAgentsPageNo *
                      props.qrCodeAgentsPageSize +
                      props.qrCodeAgentsPageSize <
                    props.qrCodeAgentsCount
                      ? props.qrCodeAgentsPageNo *
                          props.qrCodeAgentsPageSize +
                        props.qrCodeAgentsPageSize
                      : props.qrCodeAgentsCount,
                  totalCount: props.qrCodeAgentsCount,
                })}
              </Col>
              <Col xs={4} className="pagination">
                {props.qrCodeAgentsCount > props.qrCodeAgentsPageSize ? (
                  <Pagination
                    activePage={props.qrCodeAgentsPageNo + 1}
                    itemsCountPerPage={props.qrCodeAgentsPageSize}
                    totalItemsCount={props.qrCodeAgentsCount}
                    pageRangeDisplayed={3}
                    onChange={getQRCodeAgents}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                ) : null}
              </Col>
              <Col xs={4} className="pageCount">
                {props.qrCodeAgentsCount > PAGE_COUNT[0].value && (
                  <CustomDropdown
                    options={PAGE_COUNT}
                    onChange={(value) => {
                      props.getQRCodeAgents(
                        value,
                        0,
                        searchString ? searchString : undefined
                      );
                    }}
                    value={props.qrCodeAgentsPageSize}
                    dropdownClass="pageSizeDropdown"
                  />
                )}
              </Col>
            </Row>
          </div>
      </>
    );
  };

  const renderNoDataMessage = () => {
    return (
     <>
       <div className="margin-bottom d-flex alignItemsCenter">
         <NoData
           minHeight={"42.7rem"}
           message={
             searchString
               ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                 searchKey: searchString,
               })
               : getMessage("NO_AGENTS_ASSOCIATED")
           }
         />
       </div>
     </>
    );
  };

  const renderSelectedAgentsCount = () => {
    return (
      <div className="selected-agents-label px-2">
        {getMessage("SELECTED_AGENTS", {
          selectedAgents: selectedAgents.length,
        })}
      </div>
    );
  }

  return (
    <>
      <EditQRCodeModal
        show={showEditQRCodeModal}
        onClosed={closeEditQRCodeModal}
        qrCodeDetails={selectedQRCode}
        updateQRCode={props.updateQRCode}
      />

      <DeleteConfirmationModal
        show={showQRCodeDeleteConfirmationModal}
        handleCancelClick={closeDeleteQRCodeModal}
        handleDeleteClick={handleDeleteQRCodeClick}
        deleteMessage={getMessage("DELETE_QR_CODE_CONFIRMATION_TEXT", {
          qrCodeLabel: props.selectedQRCode.label,
        })}
        headerMessage={getMessage("DELETE_QR_CODE")}
      />
      <DeleteConfirmationModal
        show={showDeleteQRCodeAgentsConfirmationModal}
        handleCancelClick={closeDeleteQRCodeAgentsModal}
        handleDeleteClick={handleDeleteQRCodeAgentsClick}
        deleteMessage={
          selectedAgents.length > 1
            ? getMessage("DELETE_SELECTED_QR_CODE_AGENTS_CONFIRMATION_TEXT",{selectedAgentsCount:selectedAgents.length})
            : getMessage("DELETE_QR_CODE_AGENT_CONFIRMATION_TEXT",{
              qrCodeAgent: 
              props.qrCodeAgentsList.filter((obj) => {
                return obj.id === selectedAgentsIds[0];
              }).map((obj) => obj.firstName + " " + obj.lastName),
            })
        }
        headerMessage={getMessage("DELETE_ASSOCIATED_AGENT")}
      />

      <div className="qr-code-agents">

        <div className="qr-code-agents-list d-flex">
          {renderQRCodeInfo()}

          <div className="agents-list w-100">
            <div>{renderQRCodeAgentsHeader()}</div>
            <div className="w-100 d-flex justifyContentFlexCenter">
              {props.qrCodeAgentsList && props.qrCodeAgentsList.length > 0 ? (
                <div className="agent-list-with-pagination">
                  {renderQRCodeAgentsList()}
                  <div className="display-bottom">
                    {renderSelectedAgentsCount()}
                    {renderQRCodeAgentsListPagination()}
                  </div>
                </div>
              ) : (
                <div className="w-100">
                  {renderNoDataMessage()}
                  <div className="display-bottom pb-4">
                    {renderSelectedAgentsCount()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeAgents;
