import React, { Component } from "react";
import { Row, Modal, ModalBody, ModalHeader } from "reactstrap";

import PdfViewer from "./PdfViewer"
import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import "../AdminDashboard.less";
import "./SessionList.less";
import "../../SuperAdmin/Organisations/Organisations.less";

class PreviewDetailsModal extends Component {
  constructor(props) {
    super(props)
  } 
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="previewModal"
        backdrop="static">
        <ModalHeader className="previewModalHeader">
          <div>
            <label className="mb-0 text-left previewModalHeaderText">{this.props.previewName}
            </label>
          </div>
          <div className="closeButton">
            <button type="button" className="close" aria-label="Close" 
              onClick={() => this.props.hidePreviewModal()} >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody className="previewBody">
          <Row className=''>
            {this.props.previewType !== "PDF" ?
              <div className="imgContainer">
                <img src={this.props.previewLink} alt="preview" className="preImg" />
              </div>
              :
              <PdfViewer pdftronKey={this.props.pdftronKey} fileUrl={this.props.fileUrl} isReadOnlyPDF={true}
                language={this.props.language}
              />
            }
          </Row>
        </ModalBody>
      </Modal>
    )
  }
}

export default modalScrollBar(PreviewDetailsModal)