import { ACTIONS } from "UTILS/Constants";

export const setUserName = (userName) => {
  return{
    type: ACTIONS.SET_USER_NAME,
    userName
  }
}

export const updateProfile = (userDetails) => {
  return {
    type: ACTIONS.UPDATE_PROFILE,
    userDetails
  }
}

export const setToken = token => {
  return {
    type: ACTIONS.SET_TOKEN,
    token
  };
};

export const setUserRole = role => {
  return {
    type: ACTIONS.SET_USER_ROLE,
    role
  };
};

export const setCustomerId = customerId => {
  return {
    type: ACTIONS.SET_CUSTOMER_ID,
    customerId
  }
}

