import BaseAction from "CONFIG/BaseAction";
import {
  socketMessage,
  webRtcMessageId,
  mediaStatus
} from "WEBSOCKET/constants";
import { ACTIONS, PARTICIPANT_UPDATE_REASON } from "UTILS/Constants";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";

export class AudioVideoAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  evictParticipant(sessionKey, uniqueId){
    var message = {
      data: {
        category: socketMessage.category.PARTICIPANT,
        action: socketMessage.subCategories.PARTICIPANT.EVICT_PARTICIPANT,
        sessionKey: sessionKey,
        remoteId: uniqueId
      }
    };
    sendWebsocketMessage(socketMessage.events.MESSAGE, message)(
      this.dispatch,
      this.getState
    );
  }
  
  // Just toggle mediaStatus
  toggleMedia(sessionKey, media, mediastatus, uniqueId) {
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.TOGGLE_MEDIA,
        sessionKey: sessionKey,
        mediaStatus: mediastatus,
        media: media,
        remoteId: uniqueId
      }
    };
    sendWebsocketMessage(socketMessage.events.MESSAGE, message)(
      this.dispatch,
      this.getState
    );
  }

  setStopVideoCallFlag = value =>{
    this.dispatch({
      type:ACTIONS.STOP_VIDEO_CALL,
      value:value
    })
  }
  setStartVideoCallFlag = value =>{
    this.dispatch({
      type:ACTIONS.START_VIDEO_CALL,
      value:value
    })
  }

  setLocalStream = localStream =>{
    this.dispatch({
      type: ACTIONS.SET_LOCAL_STREAM,
      localStream: localStream
    })
  }

  setSortedParticipants = sortedParticipants =>{
    this.dispatch({
      type : ACTIONS.SET_SORTED_PARTICIPANT,
      sortedParticipants : sortedParticipants
    })
  }

  setParticipantMediaStatus = participant =>{
    this.dispatch({
      type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_STATUS,
      participant,
      updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_STATUS
    })
  }

  removeRenegotiateVideoParticipant= participantId =>{
    this.dispatch({
      type: ACTIONS.DELETE_RENEGOTIATE_VIDEO_PARTICIPANT,
      participantId
    })
  }

  removeRenegotiateAudioParticipant= participantId =>{
    this.dispatch({
      type: ACTIONS.DELETE_RENEGOTIATE_AUDIO_PARTICIPANT,
      participantId
    })
  }

  resetAudioVideoRenegotiation =()=> {
    this.dispatch ({
      type: ACTIONS.RESET_AUDIO_VIDEO_RENEGOTIATE
    })
  }
}
