import React, { Component } from "react";
import HeaderContainer from "COMPONENTS/Header/HeaderContainer";
import { CONFIG } from "UTILS/Constants";

import ScheduleSession from "COMPONENTS/SessionManagement/ScheduleSession/ScheduleSessionContainer";
import TrailHistory from "COMPONENTS/SessionManagement/TrailHistory/TrailHistoryContainer"
import StartSessionContainer from "COMPONENTS/SessionManagement/StartSession/StartSessionContainer";
import SessionManagementContainer from "COMPONENTS/SessionManagement/SessionManagementContainer";
import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";

class SessionManagementMainlayout extends Component {

  renderComponents = () => {
    switch(this.props.location.pathname) {
      case CONFIG.path.createSession :
        return <SessionManagementContainer {...this.props}/>
      case CONFIG.path.startMeeting:
        return <StartSessionContainer {...this.props}/>
      case CONFIG.path.scheduleMeeting:
        return <ScheduleSession {...this.props}/>
      case CONFIG.path.trailHistory:
        return <TrailHistory {...this.props}/>   
      default:
        return <PageNotFound />
    }
  }

  render() {
    return (
      <>
        <HeaderContainer history={this.props.history} />
        {this.renderComponents()}
      </>
    );
  }
}

export default withErrorHandler(SessionManagementMainlayout);