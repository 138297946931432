import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";

import {
  ERROR
} from "UTILS/Constants";

import { Row, Col } from "reactstrap";
import session_img from "../../Assets/Images/ic_start_session.svg";
import HeaderContainer from "COMPONENTS/Header/HeaderContainer";
import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";

import "./UserEvicted.less";

class UserEvicted extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="userEvicted joinSession">
          <section className="bgColor displayFlex flexDirectionColumn">
            <ChangeLanguage
              onSelectChange={this.props.onSelectChange}
              language={this.props.language}
            />
            <div className="px-2 pb-2">
              <Row className="noMargin pb-4">
                <div className="text-center justifyContentFlexCenter w-100 pt-5">
                  <img
                    src={session_img}
                    className="img-responsive session-img"
                    alt={getMessage("WAITING_ROOM")}
                  />
                </div>
              </Row>
              <div className="flexDirectionColumn noMarginForMobile userEvictedHeadingContainer justifyContentFlexCenter alignItemsCenter mx-5">
                <div className="alignTextCenter">
                  <span className="userEvictedHeading">
                    {getMessage("USER_EVICTED_MESSAGE")}
                  </span>
                  <br />
                  <span className="sessionName">
                    {this.props.userSessionName}
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default UserEvicted;
