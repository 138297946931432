import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { func, string, bool } from "prop-types";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { getMessage } from "CONFIG/i18n";
import "./Popup.less";

// Constants
import { CONFIG } from "UTILS/Constants";
import { wsocket } from "WEBSOCKET/wsocket";
import { isIE } from 'react-device-detect';

// Selectors
import {selectUserRole} from "../../Whiteboard/selectors";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";
import { isRealwearDevice } from "UTILS/Utility";

class ErrorPopup extends React.Component {
  constructor(props) {
    super(props);
    this.yesBtnRef = React.createRef();
    this.noBtnRef = React.createRef();
    this.okBtnRef = React.createRef();
  }
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func,
    message: string,
    isDoubleButton: bool,
    isPresenter: bool
  };

  componentDidMount() {
    if (!wsocket.io) return;
    if (isRealwearDevice() && this.props.show) {
      setTimeout(() => {
        this.registerVoiceCommandsHandler()
      }, 500);
    }
  }

  registerVoiceCommandsHandler(){
    this.voiceCommandYesClickHandler();
    this.voiceCommandNoClickHandler();
    this.voiceCommandOkClickHandler();
  }
  componentDidUpdate(prevProps) {
    if(isRealwearDevice() && this.props.show !== prevProps.show && this.props.show) {
      setTimeout(() => {
        this.registerVoiceCommandsHandler()
      }, 500);
    } else if(isRealwearDevice() && this.props.show === false){
      this.props.deregisterAllVoiceCommands();
    }
    if (prevProps.show !== this.props.show && this.props.show) {
      if (this.props.permissionAcceptBtnId && document.getElementById(this.props.permissionAcceptBtnId)) {
        document.getElementById(this.props.permissionAcceptBtnId).disabled = false;
      } else if (this.props.permissionRejectBtnId && document.getElementById(this.props.permissionRejectBtnId)) {
        document.getElementById(this.props.permissionRejectBtnId).disabled = false;
      }
    }
  }
  handleYesClick = () => {
    this.props.handleYesClick();
  };
  handleNoClick = () => {
    this.props.handleNoClick();
  };
  handleOkClick = () => {
    if(this.props.handleOkClick){
      this.props.handleOkClick()
    } else if (this.props.organiser == this.props.uniqueId || this.props.isOrganizer) {
      // If token is not set to null user is redirected to create session page and not login page
      this.props.cleanUp && this.props.cleanUp();
      this.props.history.push(CONFIG.path.login);
    } else {
      this.props.history.push(CONFIG.path.sessionEnded);
    }
  };

  voiceCommandYesClickHandler = () => {
    if (this.yesBtnRef?.current?.props) {
      const yesClick = {
        voiceCommand: this.yesBtnRef.current.props.children,
        action: () => {
          this.yesBtnRef.current.props.onClick();
        }
      }
      this.props.handleVoiceCommand(yesClick)
    }
  }

  voiceCommandNoClickHandler = () => {
    if (this.noBtnRef?.current?.props) {
      const noClick = {
        voiceCommand: this.noBtnRef.current.props.children,
        action: () => {
          this.noBtnRef.current.props.onClick();
        }
      }
      this.props.handleVoiceCommand(noClick)
    }
  }

  voiceCommandOkClickHandler = () => {
    if (this.okBtnRef?.current?.props) {
      const okClick = {
        voiceCommand: this.okBtnRef.current.props.children,
        action: () => {
          this.okBtnRef.current.props.onClick();
        }
      }
      this.props.handleVoiceCommand(okClick)
    }
  }

  componentWillUnmount() {
    this.props.deregisterAllVoiceCommands()
  }

  renderButtons = () => {
    if (!this.props.isDoubleButton) {
      return (
        <>
          <Button
            ref={this.okBtnRef}
            onClick={this.handleOkClick}
            className="buttons width-25 btn-accept customBtn"
          >
            {getMessage("OK")}
          </Button>
        </>
      );
    } else if (this.props.isDoubleButton) {
      return (
        <>
          <Button
            id={this.props.permissionAcceptBtnId}
            color="#FF8E01"
            onClick={this.handleYesClick}
            className="buttons width-25 btn-accept customBtn"
            ref={this.yesBtnRef}
          >
            {this.props.isConfirmationPopUp ?  getMessage("CONTINUE") : this.props.isPermissionPopup ? getMessage("ACCEPT") : getMessage("YES")}
          </Button>{" "}
          <Button
            id={this.props.permissionRejectBtnId}
            onClick={this.handleNoClick}
            className="buttons width-25 btn-decline "
            ref={this.noBtnRef}
          >
            {this.props.isConfirmationPopUp ?  getMessage("CANCEL") : this.props.isPermissionPopup ? getMessage("DECLINE") : getMessage("NO")}  
          </Button>
        </>
      );
    }
  };
  render() {
    return (
      <div className="text-center">
        <Modal
          isOpen={this.props.show}
          className={ this.props.isAppEmulation ? "modal-md col-xs-6 col-md-6 col-sm-6 left-2" : "modal-md col-xs-6 col-md-6 col-sm-6 popUp"} 
          zIndex={this.props.isPermissionPopup 
            ? this.props.isAudioVideoPermissionPopUp 
              ? 1052
              : 1051
            : !isIE ? 1050 : ""}
        >
          <ModalHeader className="popupHeader text-center">
            {this.props.message}
          </ModalHeader>
          {this.props.shortNote && <ModalBody className="shortNotePopupBody text-center">
            {this.props.shortNote}
          </ModalBody>}
          <ModalFooter className="permissionButtons">
            {this.renderButtons()}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userRole: selectUserRole(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default withRealWear(modalScrollBar(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPopup))));
