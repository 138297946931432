import { socketMessage } from "WEBSOCKET/constants";
import { ACTIONS, USER_ROLES, SESSION_STATUS, USER_ADMISSION_STATUS, PARTICIPANT_STATUS, PARTICIPANT_UPDATE_REASON } from "UTILS/Constants";
import { wsocket } from "WEBSOCKET/wsocket";
import * as log from "loglevel";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";
import { postMesageToCoBrowseIframeUtility } from "UTILS/Utility";

log.setLevel("info");

export const handleMessage = (data, store) => {
  const reducerData = store.getState();
  const showCustomerCoBrowse = reducerData.CustomerCoBrowseReducer.showCustomerCoBrowse;
  const coBrowseBaseUrl = reducerData.CustomerCoBrowseReducer.coBrowseBaseUrl;
  const presenterId = reducerData.RoomMetaDataReducer.roomMetaData.presenterId;
  const uniqueId = reducerData.SessionReducer.uniqueId;
  const token = reducerData.SessionReducer.sessionDetails.token
  const participants=reducerData.ParticipantReducer.participants;

  switch (data.action) {
    case socketMessage.subCategories.PARTICIPANT._PARTICIPANT_ARRIVED:
      store.dispatch({
        type: ACTIONS.UPDATE_PARTICIPANTS,
        participant: data.participant,
        updateReason: PARTICIPANT_UPDATE_REASON.ARRIVED
      });
      break;
    case socketMessage.subCategories.PARTICIPANT._EVICT_PARTICIPANT:
      // Post End_session_without_confirmation from user's end when agent evicts user/paticipant from session
      if (showCustomerCoBrowse) {
        postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl)
        setTimeout(() => {
          if (presenterId == uniqueId) {
            customerCoBrowseAction.endCustomerCoBrowse();
          } else {
            customerCoBrowseAction.leaveCustomerCoBrowse(uniqueId);
          }
          store.dispatch({
            type: ACTIONS.SET_USER_EVICTED_FLAG,
            isUserEvicted: true
          });
        }, 500);
      } else {
        store.dispatch({
          type: ACTIONS.SET_USER_EVICTED_FLAG,
          isUserEvicted: true
        });
      }
      break;
    case socketMessage.subCategories.PARTICIPANT._LEFT:
      if (data.participant.uniqueId === uniqueId) {
        store.dispatch({
          type: ACTIONS.USER_LEFT,
          value: true
        });
      }
      //if agent left the session
      //Post End_session_without_confirmation from presenter's end once agent left session
      if (data.participant.role === USER_ROLES.AGENT && showCustomerCoBrowse && presenterId == uniqueId){
        postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl)
        setTimeout(() => {
          customerCoBrowseAction.endCustomerCoBrowse();
          updatePropsOnUserLeft(data, store);
        }, 500);
      }else{
        updatePropsOnUserLeft(data, store);
      }

      store.dispatch({
        type: ACTIONS.LOCATION.STOP_LOCATION_SHARE,
        participant: data.participant.uniqueId,
        roomParticipantsCount:participants.length
      });
      // Evicted participants details should be set before delete participant
      if(data.isParticipantEvicted === true) {
        store.dispatch({
          type: ACTIONS.SET_EVICTED_PARTICIPANT_DETAILS,
          evictedParticipantDetails: {
            evictedParticipantName: data.participant.userName,
            uniqueId: data.participant.uniqueId
          }
        });
      }
      
      let state = store.getState();
      // clear snapshoteeId when snapshotee user left the session
      if(state.SessionReducer.snapshoteeId === data.participant.uniqueId) {
        store.dispatch({
          type: ACTIONS.SET_SNAPSHOTEE_ID,
          snapshoteeId: null
        });
      }

      let admittedParticipants = state.ParticipantReducer.participants.filter(
        (participant) =>
          participant.admissionStatus == USER_ADMISSION_STATUS.ADMITTED &&
                participant.status === PARTICIPANT_STATUS.ACTIVE 
      );
      // close snapshot window when only less than one user in a session
      if(admittedParticipants
        && admittedParticipants.length < 2) {
        store.dispatch({
          type:ACTIONS.SNAPSHOT.SHOW_SNAPSHOT,
          value: false
        })
      }      
      break;
    default:
      break;
  }
};

function updatePropsOnUserLeft(data, store) {
  store.dispatch({
    type: ACTIONS.DELETE_PARTICIPANT,
    participant: data.participant,
    updateReason: data.isParticipantEvicted === true 
      ? PARTICIPANT_UPDATE_REASON.EVICTED 
      : PARTICIPANT_UPDATE_REASON.LEFT
  });
  if (
    data.participant.uniqueId &&
    wsocket.audioAndVideoRtcPeer[data.participant.uniqueId] !== undefined
  ) {
    Object.keys(wsocket.audioAndVideoRtcPeer[data.participant.uniqueId]).map((key) => {
      wsocket.audioAndVideoRtcPeer[data.participant.uniqueId][key].dispose();
    });
    wsocket.audioAndVideoRtcPeer[data.participant.uniqueId] = undefined;
  }

  if (data.participant.role === USER_ROLES.AGENT) {
    store.dispatch({
      type: ACTIONS.SET_SESSION_STATUS,
      sessionStatus: SESSION_STATUS.CLOSING
    });
    store.dispatch({
      type: ACTIONS.MAKE_PRESENTER,
      newPresenterId: ""
    }); 
  }
}