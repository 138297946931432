import React from 'react'
import { isWebRTCSupported } from '../../../Utils/Utility'
import { getMessage } from "CONFIG/i18n";

import './WebRTCMessage.less'
export const WebRTCMessage = props => {
  return !isWebRTCSupported() ? (
    <div className="WebRTCMessage">
      {getMessage("WEBRTC_NOT_SUPPORTED")}
    </div>
  ) : null
}