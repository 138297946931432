import _ from "lodash";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import moment from "moment";
import {
  supportedMIMETypesForImage, supportedMIMETypesForExcel, maxOrganizationImageFileSize, maxMeetingUploadImageFileSize, IMAGE_TYPE,
  maxPdfFileSize, maxBulkImportFileSize, CANVAS_TYPES, Modules, ERROR, USER_ROLES, CONFIG, LANGUAGE, DEFAULT_FPS,
  cobrowseTypes, CUSTOMER_CO_BROWSE_ACTIONS, REGEX, DEVICE_TYPE, ANNOTATIONS, SNAPSHOT_SCAN_CODE
} from "./Constants";
import { getMessage } from "CONFIG/i18n";
import { mediaType } from "WEBSOCKET/constants";
import { validateMobileNumber, isURL } from "./regexValidations";
import validator from "validator";
import { isMobile as isMobileDevice, isMobileSafari, isIPad13, osVersion, isAndroid, isIOS, isIE, isFirefox, browserVersion, isTablet } from 'react-device-detect';

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
// import { debounceTime } from "rxjs/operators";

// import * as rxjs from "rxjs";

export const checkEmpty = stringToCheck =>
  stringToCheck === null || stringToCheck === "";

let pdfWebviewerInstance = null;

export function setPDFWebviewerInstance(instance) {
  pdfWebviewerInstance = instance;
}

export function getPDFWebviewerInstance() {
  return pdfWebviewerInstance;
}

export function getURLParameter(name) {
  return (
    decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(
        window.location.href
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}
export function getURLParameterForAWS(name) {
  return (
    decodeURIComponent(
      (new RegExp(`[#|?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(
        window.location.href
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
}
export const getUserNameAcronym = name => {
  let Name = name.trim();
  if (Number.isInteger(Name)) return Name.charAt(0) + Name.charAt(1);
  var name = Name.toUpperCase();
  if (name.indexOf(" ") !== -1)
    return name.charAt(0) + name.charAt(name.indexOf(" ") + 1);
  return name.charAt(0) + name.charAt(1);
};

export const checkIfValidImageFile = (file, imageType = IMAGE_TYPE.ORGANIZATION_IMAGE) => {
  //  check for valid type and size

  if (file &&
    !_.includes(supportedMIMETypesForImage, file.type)
    // ||
    // (!_.endsWith(file.name.toUpperCase(), ".HEIC") &&
    //   !_.endsWith(file.name.toUpperCase(), ".HEIF"))
  ) {
    return {
      valid: false,
      error: getMessage("INVALID_IMAGE_TYPE")
    };
  }
  // Image upload in meeting restricted to 10MB 
  if (file && imageType === IMAGE_TYPE.MEETING_UPLOAD_IMAGE && file.size > maxMeetingUploadImageFileSize) {
    return {
      valid: false,
      error: getMessage("FILE_SIZE_EXCEEDS_LIMIT", { size: Math.round((maxMeetingUploadImageFileSize / 1024) / 1024) })
    };
    // Image upload for agent/admin/superadmin restricted to 5MB(other than meeting) 
  } else if (file && imageType === IMAGE_TYPE.ORGANIZATION_IMAGE && file.size > maxOrganizationImageFileSize) {
    return {
      valid: false,
      error: getMessage("FILE_SIZE_EXCEEDS_LIMIT", { size: Math.round((maxOrganizationImageFileSize / 1024) / 1024) })
    };
  }

  return {
    valid: true,
    error: null
  };
};

export const checkIfValidPdfFile = file => {
  //  check for valid type
  //maxPdfFileSize
  console.log("file.type:", file.type, "file.size: ", file.size);
  if (
    (file.type && !_.includes(file.type, "pdf")) ||
    (isIE && !_.endsWith(file.name.toLowerCase(), ".pdf"))
  ) {
    return {
      valid: false,
      error: getMessage("INVALID_FILE_TYPE")
    };
  }
  if (file && file.size > maxPdfFileSize) {
    return {
      valid: false,
      error: getMessage("FILE_SIZE_EXCEEDS_LIMIT", { size: Math.round((maxPdfFileSize / 1000000)) })
    };
  }

  return {
    valid: true,
    error: null
  };
};

export const validateBulkImportUserFile = file => {
  //  check for valid type and size
  let fileExtension = file.name.split('.').pop().toLowerCase();
  if (file && (
    (file.type && !_.includes(supportedMIMETypesForExcel, file.type))
    || (fileExtension && ((!_.includes(('xlsx'), fileExtension) || (fileExtension == 'xls'))))
  )
  ) {
    return {
      valid: false,
      error: getMessage("SELECT_XLS_FILE")
    };
  }

  if (file && file.size > maxBulkImportFileSize) {
    return {
      valid: false,
      error: getMessage("FILE_SIZE_EXCEEDS_LIMIT", { size: Math.round((maxBulkImportFileSize / 1000000)) })
    };
  }

  return {
    valid: true,
    error: null
  };
};

export const convertBlobToFile = (blobData, type, fileName) => {
  if (/NET/.test(navigator.userAgent)) {
    //var blob = new Blob(blobData);
    blobData.lastModified = new Date();
    blobData.name = fileName;
    return blobData;
  } else {
    return new File([blobData], fileName, {
      type: type
    });
  }
};

export const generateZip = async (file, filename, zipFileName) => {
  var zip = new JSZip();
  zip.file(filename, file, { binary: true });
  const zipfile = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  });
  const fileObject = convertBlobToFile(zipfile, "application/zip", zipFileName);
  return fileObject;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export function getMediaConstraints(media, videoBandwidthSettings, isLandscape, isSnapshot) {
  let constraints;
  if (!isMobileSafari) {     // Desktop, Android
    if (isMobileDevice && !isLandscape) {
      // constraints for Android Portrait 
      constraints = {
        audio: true,
        video: {
          frameRate: {
            min: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
            max: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS
          },
          height: videoBandwidthSettings && videoBandwidthSettings.bandwidth ? { exact: videoBandwidthSettings.bandwidth } : null,
          // for snapshot, stream should be displayed as per orientation. For portrait mode, snapshot stream should be of aspect ratio 1.7777777778 for portrait view
          // For regular video call, aspect ratio should be 0.5625 which is for landscape view as we are always mentaining landscape view for audio / video call to avoid black strip
          aspectRatio: { exact: isSnapshot ? 1.7777777778 : 0.5625 },
          // resizeMode: "crop-and-scale"
        }
      }
    } else {
      // constraints for Desktop and Android Landscape  
      if (isIE) {
        constraints = {
          audio: true,
          video: {
            aspectRatio: { exact: 1.7777777778 },
            frameRate: {
              min: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
              max: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS
            }
          }
        }
        if (videoBandwidthSettings && videoBandwidthSettings.bandwidth) {
          constraints.video.height = videoBandwidthSettings.bandwidth;
        }
      } else if (isFirefox) {
        constraints = {
          audio: true,
          video: {
            frameRate: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
            aspectRatio: { exact: 1.7777777778 },
            height: videoBandwidthSettings && videoBandwidthSettings.bandwidth ? videoBandwidthSettings.bandwidth : null,
          }
        }
      } else {
        constraints = {
          audio: true,
          video: {
            frameRate: {
              min: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
              max: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
            },
            aspectRatio: { exact: 1.7777777778 },
            height: videoBandwidthSettings && videoBandwidthSettings.bandwidth ? videoBandwidthSettings.bandwidth : null,
          }
        }
      }
    }
  } else {
    // constraints for iPhone Safari
    constraints = {
      audio: true,
      video: {
        height: videoBandwidthSettings && videoBandwidthSettings.bandwidth ? { max: videoBandwidthSettings.bandwidth, min: 320 } : { min: 320 },
        frameRate: {
          min: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
          max: videoBandwidthSettings && videoBandwidthSettings.fps ? videoBandwidthSettings.fps : DEFAULT_FPS,
        },
        // height:  videoBandwidthSettings && videoBandwidthSettings.bandwidth? {exact: videoBandwidthSettings.bandwidth }: window.innerHeight,
        // aspectRatio: {exact: 1/1.7777777778},
        // resizeMode: "crop-and-scale"
      }
    }
  }

  constraints.echoCancellation = true;

  if (navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
    let supports = navigator.mediaDevices.getSupportedConstraints();
    console.log('constraints supported: ', supports);

    // potential fix for: WAAG-3121-Echo is observed every time at the user's end for a few seconds when the user accepts the audio/video call request.
    // add following constraints to reduce echo
    // Refer : https://www.slideshare.net/MuazKhan/echo-in-webrtc-why (slide 13)

    if (supports["noiseSuppression"]) {
      // Noise suppression automatically filters the audio to remove background noise,
      // hum caused by equipment, and the like from the sound before delivering it to your code.
      constraints.noiseSuppression = true;
    }

    if (supports["autoGainControl"]) {
      // Automatic gain control is a feature in which a sound source automatically manages 
      // changes in the volume of its source media to maintain a steady overall volume level.
      constraints.autoGainControl = true;
    }
  }

  console.log('constraints: ', constraints);

  switch (media) {
    case mediaType.AUDIO:
      constraints.video = false;
      return constraints;
    case mediaType.AUDIO_VIDEO:
      return constraints;
    case mediaType.VIDEO:
      constraints.audio = false;
      return constraints;
  }
  return null;
}

export function displayError(message, placeholder, pathToRedirect, object) {
  toastr.error(message, placeholder);

  pathToRedirect && setTimeout(() => {
    object.props.history.push(pathToRedirect);
  }, 3000);
}

export function isMobile() {
  // console.log("window.matchMedia: ", window.matchMedia);
  if (window.matchMedia("(max-width: 766px").matches) {
    /* The viewport is at least 991.98 pixels wide*/
    return true;
  } else if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else if (window.screen && window.screen.width < 767) {
    return true
  } else {
    /* The viewport is less than 991.98 pixels wide*/
    return false;
  }
}

export function isMobileOrIpadPortrait() {
  return window.innerWidth < 992
}

export function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

export function isDesktopAndTabletIpadPortrait() {
  return window.innerWidth >= 992
}

export const getFilteredArrayOfEmailOrPhoneNumbers = value => {
  return value.split(/[< >,;]/g).filter(el => el !== "");
};

export const isEmailValid = (email) => {
  if ((email.length > 3 && email.length <= 50) && (validator.isEmail(email))) {
    return true;
  }
  return false;
}

export const checkValidEmailsOrPhone = (name, array) => {
  for (let i = 0; i < array.length; i++) {
    if (name === "email") {
      if (isEmailValid(array[i])) {
        return false;
      }
      return true;
    } else {
      if (validateMobileNumber(array[i])) {
        return false;
      }
      return true;
    }
  }
};

export const createInviteeObjectsArray = (value, array) => {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let obj = {};
    if (value === "phone") {
      obj[value] = array[i];
    } else {
      (obj[value] = array[i]), (obj.name = array[i]);
    }
    result.push(obj);
  }
  return result;
};
export const getInviteesArray = (emails, numbers) => {
  let emailsArray = createInviteeObjectsArray("email", emails);
  let NumbersArray = createInviteeObjectsArray("phone", numbers);
  return [...emailsArray, ...NumbersArray];
};

//get unzipped image using S3 or remote link
export const getUnzipped = async (thumbnailUrlZip, canvasId) => {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(thumbnailUrlZip, function (err, data) {
      if (err) {
        console.log('err in getBinaryContent: ', err);
        reject(null);
      } else {
        JSZip.loadAsync(data)
        .then(function (zip) {
          zip.file("canvasThumbnail_" + canvasId + ".png").async("base64").then(function (content) {
            resolve('data:image/png;base64,' + content)
          })
        }).catch((error)=>{
          console.error("getUnzipped(): error", error)
          reject(null);
        })
      }
    });
  })
}

export const base64ImageToBlob = str => {
  // extract content type and base64 payload from original string
  var pos = str.indexOf(';base64,');
  var type = str.substring(5, pos);
  var b64 = str.substr(pos + 8);

  // decode base64
  var imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  var buffer = new ArrayBuffer(imageContent.length);
  var view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (var n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  var blob = new Blob([buffer], { type: type });

  return blob;
}

export const _wrapLine = function (_line, lineIndex, desiredWidthParam, reservedSpaceParam) {
  var lineWidth = 0,
    splitByGrapheme = this.splitByGrapheme,
    graphemeLines = [],
    line = [],
    // spaces in different languges?
    words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
    word = '',
    offset = 0,
    infix = splitByGrapheme ? '' : ' ',
    wordWidth = 0,
    infixWidth = 0,
    largestWordWidth = 0,
    lineJustStarted = true,
    additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

  let reservedSpace = reservedSpaceParam || 0;
  let desiredWidth = desiredWidthParam;
  desiredWidth -= reservedSpace;
  for (var i = 0; i < words.length; i++) {
    // i would avoid resplitting the graphemes
    word = fabric.util.string.graphemeSplit(words[i]);
    wordWidth = this._measureWord(word, lineIndex, offset);
    offset += word.length;

    // Break the line if a word is wider than the set width
    if (this.breakWords && wordWidth >= desiredWidth) {
      if (!lineJustStarted) {
        line.push(infix);
        lineJustStarted = true;
      }

      // Loop through each character in word
      for (var w = 0; w < word.length; w++) {
        var letter = word[w];
        var letterWidth = this.getMeasuringContext().measureText(letter).width * this.fontSize / this.CACHE_FONT_SIZE;
        if (lineWidth + letterWidth > desiredWidth) {
          graphemeLines.push(line);
          line = [];
          lineWidth = 0;
        } else {
          line.push(letter);
          lineWidth += letterWidth;
        }
      }
      word = [];
    } else {
      lineWidth += infixWidth + wordWidth - additionalSpace;
    }

    if (lineWidth >= desiredWidth && !lineJustStarted) {
      graphemeLines.push(line);
      line = [];
      lineWidth = wordWidth;
      lineJustStarted = true;
    } else {
      lineWidth += additionalSpace;
    }

    if (!lineJustStarted) {
      line.push(infix);
    }
    line = line.concat(word);

    infixWidth = this._measureWord([infix], lineIndex, offset);
    offset++;
    lineJustStarted = false;
    // keep track of largest word
    if (wordWidth > largestWordWidth && !this.breakWords) {
      largestWordWidth = wordWidth;
    }
  }

  i && graphemeLines.push(line);
  if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
    this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
  }
  return graphemeLines;
};
// Function to find distance between two GPS coordinates using lat, long.
export const distanceBetweenTwoMapPoints = (lat1 = 0, lon1 = 0, lat2 = 0, lon2 = 0) => {

  let toRad = (num) => {
    return num * Math.PI / 180;
  }
  var R = 6371e3; // Radius of the earth in km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}
/*Function to make media type Uniform in reducer like,
 {audio: true, video: true} --> AUDIO_VIDEO
 {audio: true, video: false} --> AUDIO
 {audio: false, video: true} --> VIDEO
 {audio: false, video: false} --> NONE
  */
export const makeUserMediaTypeUniform = (participants) => {
  if (participants) {
    participants.map(participant => {
      if (participant.mediaType && participant.mediaType.audio && participant.mediaType.video) {
        participant.mediaType = mediaType.AUDIO_VIDEO;
      }
      else if (participant.mediaType && !participant.mediaType.audio && !participant.mediaType.video) {
        participant.mediaType = "NONE";
      }
      else if (participant.mediaType && !participant.mediaType.audio && participant.mediaType.video) {
        participant.mediaType = mediaType.VIDEO;
      }
      else if (participant.mediaType && participant.mediaType.audio && !participant.mediaType.video) {
        participant.mediaType = mediaType.AUDIO;
      }
    })
  }
  return participants;
}

// Function to find the direction of Pan using coordinates of touches
export function panDirection(oldPoint, newPoint) {
  let directions = []
  if (oldPoint.pageX < newPoint.pageX) { directions.push("RIGHT") }
  else { directions.push("LEFT") }
  if (oldPoint.pageY < newPoint.pageY) { directions.push("BOTTOM") }
  else { directions.push("TOP") }
  return directions
}

// Function which decides if an object is inside the restricted canvas
// Function restrict object movement outside canvas
// But only partially
export function shouldPanImage(canvas, directions, objectInViewport) {
  let allowedDirections = []  // An array of allowed pan directions
  if (objectInViewport) {
    let calcCoords = objectInViewport.calcCoords()
    let objectHeightLimit = objectInViewport.height < 200
      ? 20
      : 100
    let objectWidthLimit = objectInViewport.width < 200
      ? 20
      : 100
    if (directions.includes("TOP")) {
      calcCoords.mb.y > objectHeightLimit && allowedDirections.push("TOP")
    }
    if (directions.includes("RIGHT")) {
      calcCoords.ml.x < canvas.getWidth() - objectWidthLimit && allowedDirections.push("RIGHT")
    }
    if (directions.includes("BOTTOM")) {
      calcCoords.mt.y < canvas.getHeight() - objectHeightLimit && allowedDirections.push("BOTTOM")
    }
    if (directions.includes("LEFT")) {
      calcCoords.mr.x > objectWidthLimit && allowedDirections.push("LEFT")
    }
  }
  return allowedDirections;
}

// Function which decides if an object is inside the restricted canvas
// Function restrict object movement outside canvas
export function shouldPanObject(canvas, directions, objectInViewport) {
  let calcCoords = objectInViewport.calcCoords()
  let objectHeightLimit = 1
  let objectWidthLimit = 1
  let allowedDirections = []  // An array of allowed pan directions
  if (directions.includes("TOP")) {
    calcCoords.tl.y > objectHeightLimit && allowedDirections.push("TOP")
  }
  if (directions.includes("RIGHT")) {
    calcCoords.tr.x < canvas.getWidth() - objectWidthLimit && allowedDirections.push("RIGHT")
  }
  if (directions.includes("BOTTOM")) {
    calcCoords.bl.y < canvas.getHeight() - objectHeightLimit && allowedDirections.push("BOTTOM")
  }
  if (directions.includes("LEFT")) {
    calcCoords.tl.x > objectWidthLimit && allowedDirections.push("LEFT")
  }
  return allowedDirections;
}

export function getDeviceOS() {
  var Name = "Unknown OS";
  if (navigator.userAgent.indexOf("Win") != -1) Name = "Win";
  if (navigator.userAgent.indexOf("Mac") != -1) Name = "Mac";
  if (navigator.userAgent.indexOf("Linux") != -1) Name = "Linux";
  if (navigator.userAgent.indexOf("Android") != -1) Name = "Android";
  if (navigator.userAgent.indexOf("like Mac") != -1) Name = "iOS";
  return Name
}

export function getBrowserName() {
  // Reference 
  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  var browserName = ""
  // Opera 8.0+
  if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) browserName = "Opera"
  // Firefox 1.0+
  if (typeof InstallTrigger !== 'undefined') browserName = "Firefox"
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))) browserName = "Safari"
  // Internet Explorer 6-11
  if (/*@cc_on!@*/false || !!document.documentMode) browserName = "IE"
  // Edge 20+
  if (!browserName === "IE" && !!window.StyleMedia) browserName = "Edge"
  // Chrome 1 - 71
  if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) browserName = "Chrome"
  // Edge (based on chromium) detection
  if (browserName === "Chrome" && (navigator.userAgent.indexOf("Edg") != -1)) browserName = "EdgeChromium"
  // // Blink engine detection
  // if((isChrome || isOpera) && !!window.CSS) browserName = "Blink"

  return browserName
}

export function isWebRTCSupported() {
  const browserName = getBrowserName()
  // Detect if system supports WebRTC 1.0 or WebRTC 1.1.
  let isWebRTCSupported = false;
  const webrtcItems = ['RTCPeerConnection', 'webkitRTCPeerConnection', 'mozRTCPeerConnection', 'RTCIceGatherer']
  for (const index in webrtcItems) {
    const item = webrtcItems[index];
    if (item in window) {
      isWebRTCSupported = true;
      break;
    }
  }

  // Check user media stream support
  let isGetUserMediaSupported = false;
  if (navigator.getUserMedia) {
    isGetUserMediaSupported = true;
  } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    isGetUserMediaSupported = true;
  }

  // getUserMedia API is supported only over secure origins for Chrome
  if (browserName == 'Chrome' && browserVersion >= 46 && !/^(https:|chrome-extension:)$/g.test(location.protocol || '')) {
    if (window?.location?.origin?.search(/localhost|127.0./g) === -1) {
      isGetUserMediaSupported = false; // 'Requires HTTPs';
    }
  }
  return isWebRTCSupported && isGetUserMediaSupported;
}

export function isPermissionGranted(error) {
  let permissionError = ["NotAllowedError", "OverconstrainedError"]
  return permissionError.indexOf(error) != -1
}

export function isCameraBusy(error) {
  let permissionError = ["ConstraintNotSatisfiedError", "NotReadableError", "AbortError"]
  return permissionError.indexOf(error) != -1
}

export function getUserMediaError(error, media) {
  console.error("getUserMedia :", error);
  if (isIE) {
    if (error.name == "ConstraintNotSatisfiedError") {
      if (media === mediaType.VIDEO) {
        return error.message.indexOf("No video devices available") > -1 ? "WEBCAM_NOT_CONNECTED" : "CAMERA_BUSY";
      } else {
        return error.message.indexOf("No audio devices available") > -1 ? "MIC_NOT_CONNECTED" : "MICROPHONE_BUSY";
      }
    }
  }
  if (error.name == "NotFoundError" || error.name == "DevicesNotFoundError") {
    return media === mediaType.VIDEO ? "WEBCAM_NOT_CONNECTED" : "MIC_NOT_CONNECTED"; //required track is missing 
  } else if (error.name == "NotReadableError" || error.name == "TrackStartError" || error.name == "AbortError") {
    return media === mediaType.VIDEO ? "CAMERA_BUSY" : "MICROPHONE_BUSY"; //webcam or mic are already in use 
  } else if (error.name == "OverconstrainedError" || error.name == "ConstraintNotSatisfiedError") {
    return "WEBCAM_NOT_CONNECTED";//constraints can not be satisfied by avb. devices 
  } else if (error.name == "NotAllowedError" || error.name == "PermissionDeniedError") {
    return media === mediaType.VIDEO ? "CAMERA_PERMISSION" : "MICROPHONE_PERMISSION"; //permission denied in browser 
  } else if (error.name == "TypeError" || error.name == "TypeError") {
    return "WEBCAM_NOT_CONNECTED"  //empty constraints object 
  } else {
    //other errors
    console.error("Error not matched");
  }
}

export function getTurnServerOptionConfig(turnServers) {
  let iceServers = [];
  turnServers.forEach(turnServer => {
    let turnServerPort = turnServer.port ? `:${turnServer.port}` : "";
    iceServers.push({
      /* STUN MODE */
      url: `stun:${turnServer.host}${turnServerPort}`
      //url: 'stun:stun.l.google.com:19302'
    }, {
      /* TURN UDP MODE */
      'url': `turn:${turnServer.host}${turnServerPort}?transport=udp`,
      'username': turnServer.username,
      'credential': turnServer.password
    },
    {
      /* TURN TCP MODE */
      'url': `turn:${turnServer.host}${turnServerPort}?transport=tcp`,
      'username': turnServer.username,
      'credential': turnServer.password,
    },
    {
      /* TURN TLS MODE */
      'url': `turns:${turnServer.host}${turnServerPort}?transport=tcp`,
      'username': turnServer.username,
      'credential': turnServer.password,
    });
  });
  return iceServers;
}

export function endsWith(searchString, position) {
  //endsWith not works on IE
  let postionPara = position;
  if (!String.prototype.endsWith) {
    var subjectString = this.toString();
    if (typeof position !== 'number' || !isFinite(position)
      || Math.floor(position) !== position || position > subjectString.length) {
      postionPara = subjectString.length;
    }
    postionPara -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, postionPara);
    return lastIndex !== -1 && lastIndex === postionPara;
  }
  else {
    return searchString.endsWith(position);
  }
}

// String.repeat not works on IE, so adding a prototype
// if (!String.prototype.repeat) {
//   alert("adding repeat pollyfill");
//   String.prototype.repeat = function(count1) {
//     'use strict';
//     if (this == null)
//       throw new TypeError('can\'t convert ' + this + ' to object');

//     var str = '' + this;
//     // To convert string to integer.
//     let count = +count1;
//     // Check NaN
//     if (count != count)
//       count = 0;

//     if (count < 0)
//       throw new RangeError('repeat count must be non-negative');

//     if (count == Infinity)
//       throw new RangeError('repeat count must be less than infinity');

//     count = Math.floor(count);
//     if (str.length == 0 || count == 0)
//       return '';

//     // Ensuring count is a 31-bit integer allows us to heavily optimize the
//     // main part. But anyway, most current (August 2014) browsers can't handle
//     // strings 1 << 28 chars or longer, so:
//     if (str.length * count >= 1 << 28)
//       throw new RangeError('repeat count must not overflow maximum string size');

//     var maxCount = str.length * count;
//     count = Math.floor(Math.log(count) / Math.log(2));
//     while (count) {
//       str += str;
//       count--;
//     }
//     str += str.substring(0, maxCount - str.length);
//     return str;
//   }
// }

export function removeTrailingSlash(pathname) {
  // pathname ends with '/' then remove it
  return endsWith(pathname, "/") ? pathname.slice(0, -1) : pathname
}

export function getUrlSearchString(path) {
  let index = path.indexOf("?");
  return index > 0
    ? path.substring(index, path.length)
    : ""
}

export function getUrlPathExcludingSearchString(pathname) {
  if (pathname === "/")
    return "/"

  let path = pathname;
  let index = path.indexOf("?");
  if (index > 0)
    path = path.substring(0, index);
  // pathname ends with '/' then remove it
  return endsWith(path, "/") ? path.slice(0, -1) : path
}

export function isElementInViewport(elem) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function isMobileOriPad() {
  return (isMobileDevice || isIPad13);
}

export function isAndroidOrIos() {
  return (isAndroid || isIOS || isIphone13OrHigher());
}

export function isIphone13OrHigher() {
  return (isMobileSafari && !isOlderVersion(osVersion, "13.0.0"));
}

function isOlderVersion(inputVer, verToCheckAgainst) {
  const inputVerParts = inputVer.split('.')
  const newParts = verToCheckAgainst.split('.')
  for (var i = 0; i < newParts.length; i++) {
    let inputVerPart = parseInt(inputVerParts[i]) || 0;
    let newPart = parseInt(newParts[i]) || 0;
    return (inputVerPart < newPart);
  }
  return false
}

// Enumerates the new standard compliant stats using local and remote track ids.
export function enumerateStats(stats, localTrackIds, remoteTrackIds) {
  // Create an object structure with all the needed stats and types that we care
  // about. This allows to map the getStats stats to other stats names.
  var statsObject = {
    audio: {
      local: {
        audioLevel: 0.0,
        bytesSent: 0,
        clockRate: 0,
        codecId: '',
        mimeType: '',
        packetsSent: 0,
        payloadType: 0,
        timestamp: 0.0,
        trackId: '',
        transportId: '',
      },
      remote: {
        audioLevel: 0.0,
        bytesReceived: 0,
        clockRate: 0,
        codecId: '',
        fractionLost: 0,
        jitter: 0,
        mimeType: '',
        packetsLost: -1,
        packetsReceived: 0,
        payloadType: 0,
        timestamp: 0.0,
        trackId: '',
        transportId: '',
      }
    },
    video: {
      local: {
        bytesSent: 0,
        clockRate: 0,
        codecId: '',
        firCount: 0,
        framesEncoded: 0,
        frameHeight: 0,
        framesSent: -1,
        frameWidth: 0,
        nackCount: 0,
        packetsSent: -1,
        payloadType: 0,
        pliCount: 0,
        qpSum: 0,
        timestamp: 0.0,
        trackId: '',
        transportId: '',
      },
      remote: {
        bytesReceived: -1,
        clockRate: 0,
        codecId: '',
        firCount: -1,
        fractionLost: 0,
        frameHeight: 0,
        framesDecoded: 0,
        framesDropped: 0,
        framesReceived: 0,
        frameWidth: 0,
        nackCount: -1,
        packetsLost: -1,
        packetsReceived: 0,
        payloadType: 0,
        pliCount: -1,
        qpSum: 0,
        timestamp: 0.0,
        trackId: '',
        transportId: '',
      }
    },
    connection: {
      availableOutgoingBitrate: 0,
      bytesReceived: 0,
      bytesSent: 0,
      consentRequestsSent: 0,
      currentRoundTripTime: 0.0,
      localCandidateId: '',
      localCandidateType: '',
      localIp: '',
      localPort: 0,
      localPriority: 0,
      localProtocol: '',
      remoteCandidateId: '',
      remoteCandidateType: '',
      remoteIp: '',
      remotePort: 0,
      remotePriority: 0,
      remoteProtocol: '',
      requestsReceived: 0,
      requestsSent: 0,
      responsesReceived: 0,
      responsesSent: 0,
      timestamp: 0.0,
      totalRoundTripTime: 0.0,
    }
  };

  // Need to find the codec, local and remote ID's first.
  if (stats) {
    stats.forEach(function (report, stat) {
      switch (report.type) {
        case 'outbound-rtp':
          if (report.hasOwnProperty('trackId')) {
            if (report.trackId.indexOf(localTrackIds.audio) !== 1 &
              localTrackIds.audio !== '') {
              statsObject.audio.local.bytesSent = report.bytesSent;
              statsObject.audio.local.codecId = report.codecId;
              statsObject.audio.local.packetsSent = report.packetsSent;
              statsObject.audio.local.timestamp = report.timestamp;
              statsObject.audio.local.trackId = report.trackId;
              statsObject.audio.local.transportId = report.transportId;
            } else if (report.trackId.indexOf(localTrackIds.video) !== 1 &
              localTrackIds.video !== '') {
              statsObject.video.local.bytesSent = report.bytesSent;
              statsObject.video.local.codecId = report.codecId;
              statsObject.video.local.firCount = report.firCount;
              statsObject.video.local.framesEncoded = report.framesEncoded;
              statsObject.video.local.framesSent = report.framesSent;
              statsObject.video.local.packetsSent = report.packetsSent;
              statsObject.video.local.pliCount = report.pliCount;
              statsObject.video.local.qpSum = report.qpSum;
              statsObject.video.local.timestamp = report.timestamp;
              statsObject.video.local.trackId = report.trackId;
              statsObject.video.local.transportId = report.transportId;
            }
          }
          break;
        case 'inbound-rtp':
          if (report.hasOwnProperty('trackId')) {
            if (report.trackId.indexOf(remoteTrackIds.audio) !== 1 &
              remoteTrackIds.audio !== '') {
              statsObject.audio.remote.bytesReceived = report.bytesReceived;
              statsObject.audio.remote.codecId = report.codecId;
              statsObject.audio.remote.fractionLost = report.fractionLost;
              statsObject.audio.remote.jitter = report.jitter;
              statsObject.audio.remote.packetsLost = report.packetsLost;
              statsObject.audio.remote.packetsReceived = report.packetsReceived;
              statsObject.audio.remote.timestamp = report.timestamp;
              statsObject.audio.remote.trackId = report.trackId;
              statsObject.audio.remote.transportId = report.transportId;
            }
            if (report.trackId.indexOf(remoteTrackIds.video) !== 1 &
              remoteTrackIds.video !== '') {
              statsObject.video.remote.bytesReceived = report.bytesReceived;
              statsObject.video.remote.codecId = report.codecId;
              statsObject.video.remote.firCount = report.firCount;
              statsObject.video.remote.fractionLost = report.fractionLost;
              statsObject.video.remote.nackCount = report.nackCount;
              statsObject.video.remote.packetsLost = report.packetsLost;
              statsObject.video.remote.packetsReceived = report.packetsReceived;
              statsObject.video.remote.pliCount = report.pliCount;
              statsObject.video.remote.qpSum = report.qpSum;
              statsObject.video.remote.timestamp = report.timestamp;
              statsObject.video.remote.trackId = report.trackId;
              statsObject.video.remote.transportId = report.transportId;
            }
          }
          break;
        case 'candidate-pair':
          if (report.hasOwnProperty('availableOutgoingBitrate')) {
            statsObject.connection.availableOutgoingBitrate =
              report.availableOutgoingBitrate;
            statsObject.connection.bytesReceived = report.bytesReceived;
            statsObject.connection.bytesSent = report.bytesSent;
            statsObject.connection.consentRequestsSent =
              report.consentRequestsSent;
            statsObject.connection.currentRoundTripTime =
              report.currentRoundTripTime;
            statsObject.connection.localCandidateId = report.localCandidateId;
            statsObject.connection.remoteCandidateId = report.remoteCandidateId;
            statsObject.connection.requestsReceived = report.requestsReceived;
            statsObject.connection.requestsSent = report.requestsSent;
            statsObject.connection.responsesReceived = report.responsesReceived;
            statsObject.connection.responsesSent = report.responsesSent;
            statsObject.connection.timestamp = report.timestamp;
            statsObject.connection.totalRoundTripTime =
              report.totalRoundTripTime;
          }
          break;
        default:
          return;
      }
    }.bind());

    // Using the codec, local and remote candidate ID's to find the rest of the
    // relevant stats.
    stats.forEach(function (report) {
      switch (report.type) {
        case 'track':
          if (report.hasOwnProperty('trackIdentifier')) {
            if (report.trackIdentifier.indexOf(localTrackIds.video) !== 1 &
              localTrackIds.video !== '') {
              statsObject.video.local.frameHeight = report.frameHeight;
              statsObject.video.local.framesSent = report.framesSent;
              statsObject.video.local.frameWidth = report.frameWidth;
            }
            if (report.trackIdentifier.indexOf(remoteTrackIds.video) !== 1 &
              remoteTrackIds.video !== '') {
              statsObject.video.remote.frameHeight = report.frameHeight;
              statsObject.video.remote.framesDecoded = report.framesDecoded;
              statsObject.video.remote.framesDropped = report.framesDropped;
              statsObject.video.remote.framesReceived = report.framesReceived;
              statsObject.video.remote.frameWidth = report.frameWidth;
            }
            if (report.trackIdentifier.indexOf(localTrackIds.audio) !== 1 &
              localTrackIds.audio !== '') {
              statsObject.audio.local.audioLevel = report.audioLevel;
            }
            if (report.trackIdentifier.indexOf(remoteTrackIds.audio) !== 1 &
              remoteTrackIds.audio !== '') {
              statsObject.audio.remote.audioLevel = report.audioLevel;
            }
          }
          break;
        case 'codec':
          if (report.hasOwnProperty('id')) {
            if (report.id.indexOf(statsObject.audio.local.codecId) !== 1 &
              localTrackIds.audio !== '') {
              statsObject.audio.local.clockRate = report.clockRate;
              statsObject.audio.local.mimeType = report.mimeType;
              statsObject.audio.local.payloadType = report.payloadType;
            }
            if (report.id.indexOf(statsObject.audio.remote.codecId) !== 1 &
              remoteTrackIds.audio !== '') {
              statsObject.audio.remote.clockRate = report.clockRate;
              statsObject.audio.remote.mimeType = report.mimeType;
              statsObject.audio.remote.payloadType = report.payloadType;
            }
            if (report.id.indexOf(statsObject.video.local.codecId) !== 1 &
              localTrackIds.video !== '') {
              statsObject.video.local.clockRate = report.clockRate;
              statsObject.video.local.mimeType = report.mimeType;
              statsObject.video.local.payloadType = report.payloadType;
            }
            if (report.id.indexOf(statsObject.video.remote.codecId) !== 1 &
              remoteTrackIds.video !== '') {
              statsObject.video.remote.clockRate = report.clockRate;
              statsObject.video.remote.mimeType = report.mimeType;
              statsObject.video.remote.payloadType = report.payloadType;
            }
          }
          break;
        case 'local-candidate':
          if (report.hasOwnProperty('id')) {
            if (report.id.indexOf(
              statsObject.connection.localCandidateId) !== -1) {
              statsObject.connection.localIp = report.ip;
              statsObject.connection.localPort = report.port;
              statsObject.connection.localPriority = report.priority;
              statsObject.connection.localProtocol = report.protocol;
              statsObject.connection.localType = report.candidateType;
            }
          }
          break;
        case 'remote-candidate':
          if (report.hasOwnProperty('id')) {
            if (report.id.indexOf(
              statsObject.connection.remoteCandidateId) !== -1) {
              statsObject.connection.remoteIp = report.ip;
              statsObject.connection.remotePort = report.port;
              statsObject.connection.remotePriority = report.priority;
              statsObject.connection.remoteProtocol = report.protocol;
              statsObject.connection.remoteType = report.candidateType;
            }
          }
          break;
        default:
          return;
      }
    }.bind());
  }
  return statsObject;
}

export function arrayAverage(array) {
  var cnt = array.length;
  var tot = 0;
  for (var i = 0; i < cnt; i++) {
    tot += array[i];
  }
  return Math.floor(tot / cnt);
}

export function arrayMax(array) {
  if (array.length === 0) {
    return NaN;
  }
  return Math.max.apply(Math, array);
}

export function arrayMin(array) {
  if (array.length === 0) {
    return NaN;
  }
  return Math.min.apply(Math, array);
}

export function isCanvasBlank(canvasData, canvasType, activePage = -1) {
  // console.log("canvasData: ", canvasData)
  if (canvasType === CANVAS_TYPES.PDF) {
    return !canvasData[activePage] ||
      _.isEmpty(canvasData[activePage])
      ? true : false
  } else {
    return !canvasData ||
      _.isEmpty(canvasData) ? true : false
  }
}

export function getAnnouncementMarquee(activeAnnouncements) {
  let announcements = '';
  let announcementSeparator = "\xa0\xa0\xa0\xa0\xa0\xa0*\xa0\xa0\xa0\xa0\xa0\xa0";
  _.map(activeAnnouncements, (ann) => {
    if (new Date(ann.activateTill) > new Date())
      announcements = announcements + announcementSeparator + ann.message
  });
  announcements += announcements ? announcementSeparator : ''
  return announcements;
}

export function isModuleIconToBeDisabled(item, props) {
  let isModuleIconToBeDisabled = false;
  switch (item) {
    case Modules.AUDIO:
    case Modules.AUDIO_VIDEO:
      isModuleIconToBeDisabled = props.userRole === USER_ROLES.USER
        || isModuleBeingUsed(item, props);
      break;
    case Modules.SNAPSHOT:
      isModuleIconToBeDisabled = props.customerCoBrowseStarted || isModuleBeingUsed(item, props);
      break;
    case Modules.SCREENSHARE:
      isModuleIconToBeDisabled = !props.isPresenter || isAndroidOrIos()
        || isModuleBeingUsed(item, props);
      break;
    case Modules.CANVAS:
      isModuleIconToBeDisabled = true;          // will never show blink effect
      break;
    case Modules.CHAT:
      isModuleIconToBeDisabled = false;       // will always show blink effect
      break;
    case Modules.APP_EMULATION:
      isModuleIconToBeDisabled = !window._env_.REACT_APP_ALLOW_APP_EMULATION // appEmulation allowed env variable is absent
        || window._env_.REACT_APP_ALLOW_APP_EMULATION == "false"; // appEmulation allowed is false
      break;
    case Modules.CO_BROWSE:
      props.showCoBrowse || props.customerCoBrowseStarted || !props.isPresenter ||
        (props.isSnapshotStarted() && props.cobrowseTypes === cobrowseTypes.CUSTOMER_CO_BROWSE) ?
        isModuleIconToBeDisabled = true :          // will not show blink when co-browse open
        isModuleIconToBeDisabled = false;         // show blink when co-browse close
      break;
    default:
      isModuleIconToBeDisabled = false;
  }
  return isModuleIconToBeDisabled;
}

export function isModuleBeingUsed(item, props) {
  let isModuleBeingUsed = false;
  switch (item) {
    case Modules.SCREENSHARE:
      isModuleBeingUsed = props.screenSharingParticipantId != null;
      break;
    case Modules.AUDIO:
      isModuleBeingUsed = props.webRtcPermissionRequest == Modules.AUDIO
        || props.webRtcPermissionRequest == Modules.AUDIO_VIDEO;
      break;
    case Modules.AUDIO_VIDEO:
      isModuleBeingUsed = props.webRtcPermissionRequest == Modules.AUDIO_VIDEO;
      break;
    case Modules.SNAPSHOT:
      isModuleBeingUsed = props.isSnapshotStarted();
      break;
    default:
      isModuleBeingUsed = false;
  }
  return isModuleBeingUsed;
}

export function getFirstNamebyParticipantId(participants, participantId, fullName = false) {
  if (participantId) {
    let matchingParticipant = participants.filter((participant) => participant.uniqueId === participantId);
    if (matchingParticipant[0])
      return fullName ? matchingParticipant[0].userName : matchingParticipant[0].userName.split(" ")[0];
  }
  return "";
}

export const replaceProperUrlIfExists = (str) => {
  let strArray = str.split(" ");
  let resultString = "";
  strArray.forEach((subStr) => {
    if (isURL(subStr) && !isEmailValid(subStr) && !subStr.startsWith("http")) {
      resultString += "http://" + subStr
    } else {
      resultString += subStr
    }
    resultString += " ";
  })
  return resultString;
}

//separate the mobile number and email id from invitee array
export const separatePhoneNumberAndEmails = (arrayOfInvitees) => {
  let arrayOfEmails = [];
  let arrayOfPhone = [];
  arrayOfInvitees.forEach((invitee, index) => {
    isNaN(invitee) ? arrayOfEmails.push({ email: invitee, index: index + 1 }) : arrayOfPhone.push({ phone: invitee, index: index + 1 });
  })
  return { arrayOfEmails, arrayOfPhone }
}

//formate session list
export const groupSessionListByDate = (sessionsList) => {
  sessionsList.forEach((sessionObj) => {
    sessionObj.startDate = moment(sessionObj.scheduledStartTime).format('YYYY-MM-DD');
  })
  let sessionListByDate = _.groupBy(sessionsList, (session) => {
    return session.startDate;
  });
  //console.log("---------- sessionListByDate", sessionListByDate);
  return Object.keys(sessionListByDate).map((key) => _.orderBy(sessionListByDate[key], ['scheduledStartTime'], ['asc']));
}

//Returns contents of a canvas as a png based data url, with the specified background color
export const canvasToBlob = (canvas, backgroundColor, generateBlankCanvasImageFlag = false) => {
  try {
    // cache height and width
    var w = canvas.width;
    var h = canvas.height;
    canvas.backgroundColor = backgroundColor;
    var context = canvas.getContext("2d");
    var data;
    let imageData = null;
    
    // in case of blank canvas, generate blank canvas image
    // this scenario is applicable only for download case
    // we cannot remove background image from existing canvas to avoid flicker effect
    if (generateBlankCanvasImageFlag) {
      let blankCanvas = new fabric.Canvas('canvas');
      blankCanvas.setWidth(w);
      blankCanvas.setHeight(h);
      blankCanvas.backgroundColor = backgroundColor;
      imageData = blankCanvas.toDataURL({
        format: "image/png", quality: 1,
        multiplier: 1 / blankCanvas.viewportTransform[0]
      });
      blankCanvas = null;
    } else {
      try {
        if (backgroundColor) {
          //get the current ImageData for the canvas.
          data = context?.getImageData(0, 0, w, h);

          //store the current globalCompositeOperation
          var compositeOperation = context.globalCompositeOperation;

          //set to draw behind current content
          context.globalCompositeOperation = "destination-over";

          //set background color
          // context.backgroundColor = backgroundColor;

          //draw background / rect on entire canvas
          context.fillRect(0, 0, w, h);
        }
      } catch (error) {
        console.error("ERROR : canvasToBlob()", error)
      }

      //get the image data from the canvas
      imageData = canvas.toDataURL({
        format: "image/png", quality: 1,
        multiplier: 1 / canvas.viewportTransform[0]
      });
      try {
        if (backgroundColor) {
          //clear the canvas
          context.clearRect(0, 0, w, h);

          //restore it with original / cached ImageData
          context.putImageData(data, 0, 0);

          //reset the globalCompositeOperation to what it was
          context.globalCompositeOperation = compositeOperation;
        }
      }
      catch (error) {
        console.error("ERROR : canvasToBlob()", error)
      }
    }
    // render canvas again as its background is set 
    canvas.renderAll();
    // Converting imageData to blob to resolve network error issue while downloading with higher multiplier
    // The error comes when an Anchor Element (<a>) with the donwload attribute limit is reached
    // The solution is either to directly get a Blob when available (for canvas), 
    // or to first convert your dataURI to a Blob, and then create an object URL from this Blob.
    return dataURLtoBlob(imageData);
  } catch (error) {
    console.error("ERROR : canvasToBlob()", error)
  }
};

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// get phone number with default country code
export const getPhoneNumberWithCountryCode = (phoneNumber) => {
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  } else if (phoneNumber.startsWith('0')) {
    return window._env_.REACT_APP_DEFAULT_COUNTRY_CODE_PREFIX + phoneNumber.substring(1);
  } else {
    return window._env_.REACT_APP_DEFAULT_COUNTRY_CODE_PREFIX + phoneNumber;
  }
}

export const getPdfThumbnailBlob = (activePage) => {
  return new Promise((resolve, reject) => {
    if (!pdfWebviewerInstance) {
      reject("get thumbnail blob: Pdf webviewer instance not initialized");
      return;
    }
    if (!window.navigator.onLine) {
      reject("get thumbnail blob: Network error");
      return;
    }
    // upload thumbnail with annotations 
    const doc = pdfWebviewerInstance.docViewer.getDocument();
    doc.loadCanvasAsync(({
      pageNumber: activePage,
      zoom: 1,
      drawComplete: async (thumbnail) => {
        // optionally comment out "drawAnnotations" below to exclude annotations
        await pdfWebviewerInstance.docViewer.getAnnotationManager().drawAnnotations(activePage, thumbnail).then(() => {
          console.log("In drawAnnotations");
        }).catch(error => {
          console.log("Error in drawAnnotations ", error);
        });
        // thumbnail is a HTMLCanvasElement or HTMLImageElement
        thumbnail.toBlob(blob => {
          resolve(blob);
        })
      }
    }));
  })
}

//set cursor to perticular position
function setSelectionRange(input, selectionStart, selectionEnd) {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  }
  else if (input.createTextRange) {
    var range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
}

//set cursor position to the end 
export const setCaretToEnd = (input) => {
  setSelectionRange(input, input.value.length, input.value.length);
}
export const getSSOLoginUrl = (ssoDetails) => {
  // https://auth.mydomain.loookit.com/oauth2/authorize?
  // response_type=code&
  // client_id=5fh8j3nbilphr2v4cuukj84fr5&
  // redirect_uri=https://localhost:3000/sso/login/callback&
  // state=STATE&
  // identity_provider=COGNITO&
  // idp_identifier=Meijiyasuda
  return `https://${ssoDetails.cognitoDomain}/oauth2/authorize?`
    + `&response_type=code`
    + `&client_id=${ssoDetails.clientId}`
    + `&redirect_uri=${window.location.origin}${CONFIG.path.ssoLogin}`
    + `&state=STATE`
    + `&identity_provider=${ssoDetails.identityProvider}`;
}

export const getSSOLogoutUrl = (ssoDetails) => {
  // https://docs.amazonaws.cn/en_us/cognito/latest/developerguide/logout-endpoint.html
  // GET https://mydomain.auth.us-east-1.amazoncognito.com/logout?
  // client_id=<client_id>
  // logout_uri=/logout
  return `https://${ssoDetails.cognitoDomain}/logout`
    + `?client_id=${ssoDetails.clientId}`
    + `&logout_uri=${window.location.origin}${CONFIG.path.ssoLogout}`;
}

export const getStringArrayInLowerCase = (array) => {
  return Object.values(array).map(value => value.toLowerCase())
}

export function isAlphaNumeric(input) {
  var letters = /^[0-9a-zA-Z]+$/;
  if (input.match(letters)) {
    return true;
  }
  else {
    return false;
  }
}

export function hasWhiteSpace(input) {
  return input.indexOf(' ') >= 0;
}

export function getUserNameForSelectedLanguage(language, userName = '') {
  let userFullName = userName.split(' ');
  let firstName = userFullName.length > -1 ? userFullName[0].trim() : '';
  let lastName = userFullName.length > 0 ? (userFullName[1] ? userFullName[1].trim() : '') : '';
  let displayName = language === LANGUAGE.EN.name ? `${firstName} ${lastName}` : `${lastName} ${firstName}`;
  return displayName.trim();
}

export const isWebRTCPluginInstalled = () => {
  if (isIE && !AdapterJS.WebRTCPlugin.plugin) {
    toastr.error("WEBRTC_PLUGIN_NOT_INSTALLED")
    return false;
  }
  return true;
}

export function postMesageToCoBrowseIframeUtility(token, coBrowseBaseUrl) {
  var message = {
    token: token,
    action: CUSTOMER_CO_BROWSE_ACTIONS.END_SESSION_WITHOUT_CONFIRMATION,
  };
  let cobrowseIframe = document.getElementById("customerCBIframe");
  console.log("Message sending to co browse server", JSON.stringify(message))
  cobrowseIframe && cobrowseIframe.contentWindow.postMessage(message, coBrowseBaseUrl);
  console.log("cobrowseIframe", cobrowseIframe)
}

export const getValidInviteesInput = (invitees) => {
  return invitees.replace(" ", "").trim().replace(/(,)\1{1,}|,;/g, ",").replace(/(;)\1{1,}|;,/g, ";")
}

export const getBrowserLanguage = () => {
  let browserLanguage = navigator.language || navigator.userLanguage;
  if (browserLanguage.toLowerCase() === LANGUAGE.JA.name) {
    return LANGUAGE.JA.name
  } else {
    return LANGUAGE.EN.name
  }
}

export const convertUrlToBase64 = (fileUrl) => {
  return new Promise((resolve, reject) => {
    fetch(fileUrl).then(response => {
      console.log('fetch background img response: ', response);
      response.blob().then(blob => {
        console.log('fetch background img blob ', blob)
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;
          resolve(base64data);
        }
      })
    }).catch(() => {
      reject();
    })
  })
}

export const getBaseUrl = (customerUriIdentifier, sessionKey) => {
  let baseUrl = window.location.origin;
  if (baseUrl) {
    baseUrl =
      baseUrl + (customerUriIdentifier
        ? '/' + customerUriIdentifier : '') + "/join?sessionKey=" + sessionKey + "&openExternalBrowser=1";
    return baseUrl;
  } else {
    baseUrl =
      window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "");
    baseUrl =
      baseUrl + (customerUriIdentifier
        ? '/' + customerUriIdentifier
        : '') + "/join?sessionKey=" + sessionKey;
    return baseUrl;
  }
}
export const disableBrowseBackBtn = (newLocation, action, history, thisScope) => {
  if (thisScope) {
    if (action === "PUSH") {
      if (
        newLocation.pathname !== thisScope.currentPathname ||
        newLocation.search !== thisScope.currentSearch
      ) {
        thisScope.currentPathname = newLocation.pathname;
        thisScope.currentSearch = newLocation.search;
        history.push({
          pathname: newLocation.pathname,
          search: newLocation.search
        });
      }
    } else {
      history.go(1);
    }
  }
}

export const getIdFromRef = ref => {
  return ref.current ? ref.current.props?.id : null
}
export const getQRCodeUrl = (qrCodeId) => {
  let qrCodeUrl = window.location.origin;

  if (!qrCodeUrl) {
    qrCodeUrl = window.location.protocol + "//"
      + window.location.hostname
      + (window.location.port ? ':' + window.location.port : '');
  }

  qrCodeUrl = qrCodeUrl + CONFIG.partialPath.qr + "/" + qrCodeId;

  return qrCodeUrl;
}

export const getStringWithoutExtraSpaces = (strWithExtraSpaces) => {
  let updatedString = strWithExtraSpaces.trim();
  return updatedString.replace(REGEX.MULTIPLE_SPACES, " ").trim();
};

export const isRealwearDevice = () => {
  return window.journey ? true : false
}

export const getCommonAPIErrorMessage = (errorCode) => {
  if (errorCode === ERROR.NOT_AUTHORIZED) {
    return getMessage("SESSION_EXPIRED")
  }
  if (errorCode === ERROR.USER_DELETED ||
    errorCode === ERROR.LICENSE_KEY_EXPIRED_ADMIN ||
    errorCode === ERROR.LICENSE_KEY_EXPIRED_AGENT) {
    return getMessage(errorCode);
  }
  return null;
}

export const getDeviceType = () => {
  if (isRealwearDevice()) {
    return DEVICE_TYPE.REALWEAR
  }
  if (isMobileDevice) {
    return DEVICE_TYPE.MOBILE
  }
  if (isTablet) {
    return DEVICE_TYPE.TABLET
  }
  return DEVICE_TYPE.DESKTOP;
}

export const getModulesNotAllowedErrorMessage = (errDetails) => {
  if (errDetails) {
    const modulesName = errDetails.reduce((modulesAccumulator, module, index) => {
      return modulesAccumulator + getMessage(module) + `${index < errDetails.length - 1 ? ` ${getMessage('AND')} ` : ' '}`
    }, '');

    return errDetails.length > 1 ? getMessage('MODULES_NOT_ALLOWED', { modulesName }) :
      getMessage('MODULE_NOT_ALLOWED', { moduleName: modulesName })
  }
  return null
}

export const navigateToRealwearDashboard = () => {
  window.journey.system.loadURL(window._env_.REACT_APP_REALWEAR_LOOOKIT_DASHBOARD_URL);
}