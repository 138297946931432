import React, { Component } from "react";
import Autolinker from 'autolinker';
import { func } from "prop-types";
import Draggable from 'react-draggable';
import {Resizable} from 'react-resizable';
import { Row, Col, Button, Input, ButtonGroup, PopoverBody, Popover } from "reactstrap";
import dompurify from 'dompurify';
import uuid from "uuid";

import { isMobileOriPad, replaceProperUrlIfExists, getUserNameForSelectedLanguage, isRealwearDevice, isMobile } from "UTILS/Utility";
import { getMessage } from "CONFIG/i18n";

import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import "./Chat.less";
import "COMPONENTS/App/App.less";
import "../../../node_modules/react-resizable/css/styles.css";

// Assets
import chat_logo from "ASSETS/Images/ic_chat_white.svg";
import img_minimize from "ASSETS/Images/ic_video_minimize.svg";
import download_img from "ASSETS/Images/ic_chat_download.svg";
import loading from "ASSETS/Images/loading.gif";

import { KEY_CODE_ENTER, CHAT, TOOLTIP } from "UTILS/Constants";

const sanitizer = dompurify.sanitize;

const MESSAGE_FONT_SIZE = {
  SMALL: "0.875em",
  MEDIUM: "1.16em",
  LARGE: "1.5428em",
};

const SENDER_NAME_FONT_SIZE = {
  SMALL: "0.75em",
  MEDIUM: "0.9975em",
  LARGE: "1.3266em"
};

const TYPE_MESSAGE_FONT_SIZE = {
  SMALL: "0.875em",
  MEDIUM: "1.16em",
  LARGE: "1.5428em",
};

const SEND_BUTTON_FONT_SIZE = {
  SMALL: "0.875em",
  MEDIUM: "1.16em",
  LARGE: "1.5428em",
};

const TEXT_SIZE_LABELS = {
  SMALL: 'S',
  MEDIUM: 'M',
  LARGE: 'L'
};

const CHAT_WINDOW_DIMENSIONS = {
  DESKTOP_DEVICES: {
    WIDTH: 350,
    HEIGHT: 360
  },
  MOBILE_DEVICES: {
    WIDTH: 298,
    HEIGHT: 300
  }
};

const CHAT_FONT_SIZE = CHAT.FONT_SIZE;
export default class Chat extends Component {
  constructor(props) {
    super(props);
    
    const { WIDTH, HEIGHT } = this.getDefaultChatWindowDimensions();

    this.state = {
      position: {x: 0, y: 0},
      message: "",
      mobileChatBoxBounds: { top: -100, left: -100, right: 10, bottom: 0 },
      desktopChatBoxBounds: { top: -200, left: -900, right: 10, bottom: 0 },
      panning: false,
      showLoadingUI: false,
      showTextSizeOptions: false,
      topMargin: 0,
      leftMargin: 0,

      // We can get min constraints values in render but when we drag or resize chat window, state will get updated and it will call render again and again
      // Instead of getting same values i.e. min constraints with each render, they are stored in the state only once.
      minConstraints: [WIDTH, HEIGHT]
    };
    this.tooltipBoundaryElement = null;
  }
  static propTypes = {
    sendChatMessage: func
  };
  
  componentDidMount() {
    var objDiv = document.getElementById("chatMessages");
    objDiv.scrollTop = objDiv.scrollHeight;
    window.addEventListener("resize", () => {
      this.setState({position: {x: 0, y: 0}},() => {
        setTimeout(() => this.setBoundToChatWindow(), 500)
      });

      this.setDefaultChatWindowDimensions();
    });    
    window.document.body.addEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
      passive: false
    });
    this.setBoundToChatWindow(()=>{
      let chatTextarea = document.getElementById("chatTextarea");
      chatTextarea && !isRealwearDevice() && chatTextarea.focus();
    });
    this.getTooltipBoundaryElement();

    this.setFontSizesOfChatContents();

    this.setDefaultChatWindowDimensions();

    window.addEventListener("orientationchange", this.setDefaultChatWindowDimensions);
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("positioningChat")[0];
  }

  setBoundToChatWindow = (callback) => {
    var chatWindow = document.getElementById("chatWindow");
    if(chatWindow){
      let chatBoxBound = chatWindow.getBoundingClientRect()
      let windowHeight = window.innerHeight;
      let windowWidth = window.innerWidth;
      let desktopChatBoxBounds = {top:-(chatBoxBound.top), left: -(chatBoxBound.left), bottom:windowHeight-chatBoxBound.bottom, right:windowWidth-chatBoxBound.right}
      this.setState({desktopChatBoxBounds: desktopChatBoxBounds},() => {
        if(callback) callback()
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatMessages !== this.props.chatMessages) {
      var objDiv = document.getElementById("chatMessages");
      objDiv.scrollTop = objDiv.scrollHeight;

      // Show loading UI if the user is an agent and chat gpt setting is enabled in Super Admin settings
      if(this.props.isOrganiser && this.props.isChatGPTEnabled && (this.props.lastMessageParticipantId && this.props.organiserParticipantId === this.props.lastMessageParticipantId)) {
        let chatGPTSearchMeassages = [];
        let messagesHavingSenderNameChatGPT = [];

        // TODO: We need to change this code once we refactor the old code where changes are done to display ownMessages.
        // It replaces the chatMessages array with some specific data where some elements in the array contain more data. 
        _.forEach(this.props.chatMessages, (chatMessageObject) => {
          
          if(chatMessageObject.participantId === this.props.organiserParticipantId) {
            const keyword = this.props.getChatGPTMessageKeyword(chatMessageObject.message);
            if(keyword) chatGPTSearchMeassages.push(chatMessageObject);
          }

          if(chatMessageObject.participantId === CHAT.CHAT_GPT_SENDER_PARTICIPANT_ID) {
            messagesHavingSenderNameChatGPT.push(chatMessageObject);
          }
        });

        if(chatGPTSearchMeassages.length > messagesHavingSenderNameChatGPT.length) {
          this.setState({
            showLoadingUI: true
          }, () => {
          // Scroll to show loading UI
            var objDiv = document.getElementById("chatMessages");
            objDiv.scrollTop = objDiv.scrollHeight;
          });
        
        } else {
          this.setState({
            showLoadingUI: false
          });
        }
      }
    }

    if(prevProps.selectedFontSize !== this.props.selectedFontSize) {
      this.setFontSizesOfChatContents();
    }
  }

  // TODO: Refactor code to make font size selection generic.
  setFontSizesOfChatContents = () => {
    switch(this.props.selectedFontSize) {
      case CHAT_FONT_SIZE.SMALL:
        this.setState({
          selectedFontSizeForMessage: MESSAGE_FONT_SIZE.SMALL,
          selectedFontSizeForSenderName: SENDER_NAME_FONT_SIZE.SMALL,
          selectedFontSizeForTypeMessage: TYPE_MESSAGE_FONT_SIZE.SMALL,
          selectedFontSizeForSendButton: SEND_BUTTON_FONT_SIZE.SMALL
        });
        break;
    
      case CHAT_FONT_SIZE.MEDIUM:
        this.setState({
          selectedFontSizeForMessage: MESSAGE_FONT_SIZE.MEDIUM,
          selectedFontSizeForSenderName: SENDER_NAME_FONT_SIZE.MEDIUM,
          selectedFontSizeForTypeMessage: TYPE_MESSAGE_FONT_SIZE.MEDIUM,
          selectedFontSizeForSendButton: SEND_BUTTON_FONT_SIZE.MEDIUM
        });
        break;

      case CHAT_FONT_SIZE.LARGE:
        this.setState({
          selectedFontSizeForMessage: MESSAGE_FONT_SIZE.LARGE,
          selectedFontSizeForSenderName: SENDER_NAME_FONT_SIZE.LARGE,
          selectedFontSizeForTypeMessage: TYPE_MESSAGE_FONT_SIZE.LARGE,
          selectedFontSizeForSendButton: SEND_BUTTON_FONT_SIZE.LARGE
        });
        break;

      default: return;
    }
  }

  sendChatMessage = () => {
    if (this.state.message.trim()) {
      let messageToSend = replaceProperUrlIfExists(this.state.message)
      this.props.sendChatMessage(messageToSend.trim());
    }
    this.setState({
      message: ""
    },() => {
      let chatTextarea = document.getElementById("chatTextarea");
      // If user is on RealWear then don't need to focus text input
      chatTextarea && !isRealwearDevice() && chatTextarea.focus();
    });
  };
  handleClose = () => {
    this.props.handleClose();
  };

  handleInputChange = event => {
    this.setState({
      message: event.target.value
    });
  };

  onKeyPress = event => {
    let chatTextarea = document.getElementById("chatTextarea");
    if (event.ctrlKey && event.keyCode == KEY_CODE_ENTER) {
      // Ctrl-Enter pressed
      chatTextarea.focus();
      chatTextarea.value = chatTextarea.value + "\n";
      chatTextarea.scrollTop = chatTextarea.scrollHeight;
    } else if (event.keyCode === KEY_CODE_ENTER) {
      event.preventDefault();
      this.sendChatMessage();
    }
  };
  
  onDragHandler = (e, ui) =>{
    const {x, y} = this.state.position;
    this.setState({
      position: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
    document.body.style.cursor="grabbing"
  }

  handlePreventTouchmoveWhenPanning = (event) => {
    if (this.state.panning) {
      event.preventDefault();
    }
  };

  toggleTextSizeOptions = () => {
    this.setState({
      showTextSizeOptions: !this.state.showTextSizeOptions
    });
  }

  changeChatTextSize = (textSize) => {
    this.toggleTextSizeOptions();
    this.props.setChatFontSize(textSize);
  }

  getButtonLabel = () => {
    if(this.props.selectedFontSize === CHAT_FONT_SIZE.SMALL) {
      return TEXT_SIZE_LABELS.SMALL;
    } else if(this.props.selectedFontSize === CHAT_FONT_SIZE.MEDIUM) {
      return TEXT_SIZE_LABELS.MEDIUM;
    } else {
      return TEXT_SIZE_LABELS.LARGE;
    }
  }

  getDefaultChatWindowDimensions = () => {
    return isMobile() ? CHAT_WINDOW_DIMENSIONS.MOBILE_DEVICES : CHAT_WINDOW_DIMENSIONS.DESKTOP_DEVICES;
  }

  setDefaultChatWindowDimensions = () => {
    const { WIDTH, HEIGHT } = this.getDefaultChatWindowDimensions();

    this.setState({
      width: WIDTH,
      height: HEIGHT
    });
  }

  renderTextSizeOptions = () => {
    return (
      <div className="chat-text-size-options">
        <Button
          id="chatTextSizeOptions"
          type="button"
          className="chat-header-button"
          onTouchStart={isMobileOriPad() && this.toggleTextSizeOptions}
        >
          {this.getButtonLabel()}
        </Button>
        <CustomTooltip 
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipPlacement={TOOLTIP.PLACEMENT.LEFT}
          tooltipText={getMessage("CHAT_FONT_SIZE_TOOLTIP")} 
          tooltipId="chatTextSizeOptions" />
        <Popover
          placement="bottom" 
          isOpen={this.state.showTextSizeOptions} 
          target="chatTextSizeOptions" 
          toggle={this.toggleTextSizeOptions}
          trigger="legacy" // To check explaination of trigger="legacy" https://reactstrap.github.io/?path=/docs/components-popover--update
        >
          <PopoverBody>
            <ButtonGroup>
              <Button
                id="small"
                className={"chat-text-size-button-option " + (this.props.selectedFontSize === CHAT_FONT_SIZE.SMALL ? "chat-text-size-button-option-active" : "")}
                onClick={() => this.changeChatTextSize(CHAT_FONT_SIZE.SMALL)}
                active={this.props.selectedFontSize === CHAT_FONT_SIZE.SMALL}
              >
                {TEXT_SIZE_LABELS.SMALL}
              </Button>
              <Button
                id="medium"
                className={"chat-text-size-button-option " + (this.props.selectedFontSize === CHAT_FONT_SIZE.MEDIUM ? "chat-text-size-button-option-active" : "")}
                onClick={() => this.changeChatTextSize(CHAT_FONT_SIZE.MEDIUM)}
                active={this.props.selectedFontSize === CHAT_FONT_SIZE.MEDIUM}
              >
                {TEXT_SIZE_LABELS.MEDIUM}
              </Button>
              <Button
                id="large"
                className={"chat-text-size-button-option " + (this.props.selectedFontSize === CHAT_FONT_SIZE.LARGE ? "chat-text-size-button-option-active" : "")}
                onClick={() => this.changeChatTextSize(CHAT_FONT_SIZE.LARGE)}
                active={this.props.selectedFontSize === CHAT_FONT_SIZE.LARGE}
              >
                {TEXT_SIZE_LABELS.LARGE}
              </Button>
            </ButtonGroup>
          </PopoverBody>
        </Popover>
      </div>
    );
  }

  componentWillUnmount () {
    window.document.body.removeEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
      passive: false
    });

    window.removeEventListener("orientationchange", this.setDefaultChatWindowDimensions);
  }

  // Make @chat bold if ChatGPT is enabled
  // (If ChatGPT is enabled then receiverId for that message is CHAT_GPT_SENDER_PARTICIPANT_ID otherwise it will be considered as a normal message)
  getChatGPTMessageFormattedString = (messageDetails) => {
    const {message} = {...messageDetails};
    if(messageDetails.receiverId === CHAT.CHAT_GPT_SENDER_PARTICIPANT_ID) {
      return this.props.getFormattedChatGPTPrompt(message);
    }
    
    return message;
  }

  /* Resize chat window from all directions */
  onResize = (event, {node, size, handle}) => {
    this.setState((prevState) => {
      let newLeft = prevState.leftMargin;
      let newTop = prevState.topMargin;
      const deltaHeight = size.height - prevState.height;
      const deltaWidth = size.width - prevState.width;

      if (handle[0] === 'n') {
        newTop -= deltaHeight;
      } else if (handle[0] === 's') {
        // TODO: Check this example (Resize window from all directions) - https://github.com/react-grid-layout/react-resizable/blob/HEAD/examples/ExampleLayout.js
        // Following code works well in the example but here, we need to comment it out to get resize worked. Otherwise, UI flickers
        // while resizing the window.

        // newTop += deltaHeight;
      }
      if (handle[handle.length - 1] === 'w') {
        newLeft -= deltaWidth;
      } else if (handle[handle.length - 1] === 'e') {
        // TODO: Check this example (Resize window from all directions) - https://github.com/react-grid-layout/react-resizable/blob/HEAD/examples/ExampleLayout.js
        // Following code works well in the example but here, we need to comment it out to get resize worked. Otherwise, UI flickers
        // while resizing the window.
        
        // newLeft += deltaWidth;
      }

      return {
        width: size.width,
        height: size.height,
        leftMargin: newLeft,
        topMargin: newTop,
      };
    });
  }

  render() {
    const { chatMessages } = this.props;
    var autolinker = new Autolinker({stripPrefix: false, phone: false});

    return this.props.chatPopOver ? (
      <Draggable 
        onStart={
          e => {

            let targetComponentId = e.target.id;
            if(targetComponentId !== "chatTextSizeOptions" &&
               targetComponentId !== "small" &&
               targetComponentId !== "medium" &&
               targetComponentId !== "large") {
              this.setState({
                showTextSizeOptions: false
              });
            }
            this.setState({ panning: true })
          }
        } 
        onStop={e => {
          this.setState({ panning: false})
          document.body.style.cursor="auto"
        }} 
        position={{x: this.state.position.x, y: this.state.position.y}}
        onDrag={this.onDragHandler}
        handle=".chatHeader"
        // bounds={this.state.desktopChatBoxBounds} // TODO: Handle this code to set bounds for drag as well as resize feature
      >
        <Resizable
          height={this.state.height}
          width={this.state.width}
          onResize={this.onResize}
          resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']} // create constant
          minConstraints={this.state.minConstraints}
        >
          <div
            className="paddingPoint positioningChat"
            id="chatWindow"
            style={
              {
                width: this.state.width,
                height: this.state.height,
                marginTop: this.state.topMargin,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: this.state.leftMargin
              }
            }>
            <Row className="pt-xs-1 chatHeader d-flex">
              <img src={chat_logo} alt="chat" />
              <label className="chatTitle pl-2 mb-0">
                {getMessage("CHAT").toUpperCase()}
              </label>
              <div className="download-minimize-chat">
                {this.renderTextSizeOptions()}
                {chatMessages && chatMessages.length != 0 && this.props.isChatDownloadAllowed ? (
                  <div>
                    <img
                      src={download_img}
                      alt="download"
                      className={
                        chatMessages && chatMessages.length != 0
                          ? "img-fluid cursorPointer float-right mx-1 blinkImg"
                          : "img-fluid cursorDisabled float-right mx-1 blinkImg"
                      }
                      onClick={
                        chatMessages && chatMessages.length != 0
                          ? !isMobileOriPad() && this.props.downloadChatMessages
                          : () => {}
                      }
                      onTouchStart={
                        chatMessages && chatMessages.length != 0
                          ? isMobileOriPad() && this.props.downloadChatMessages
                          : () => {}
                      }
                      id="download"
                    />
                    <CustomTooltip 
                      tooltipBoundariesElement={this.tooltipBoundaryElement}
                      tooltipText={getMessage("DOWNLOAD")} 
                      tooltipId="download" />
                  </div>

                ) : null}
                <div>
                  <img
                    src={img_minimize}
                    alt="minimize"
                    className="img-fluid cursorPointer float-right blinkImg"
                    onClick={() => {
                      !isMobileOriPad() && this.handleClose()
                    }}
                    onTouchStart={() => {
                      isMobileOriPad() && this.handleClose()
                    }}
                    id="close"
                  />
                <>
                <CustomTooltip 
                  tooltipBoundariesElement={this.tooltipBoundaryElement}
                  tooltipText={getMessage("MINIMIZE_CHAT")} 
                  tooltipId="close" />
                </>
                </div>
              </div>
            </Row>
            <div id="chatMessages" className="chatMessages scroll-decoration px-lg-1 pt-lg-1">
              {_.map(chatMessages, messageDetails => {
                let senderName = messageDetails.sender;
                let isChatGPTSender = messageDetails.participantId === CHAT.CHAT_GPT_SENDER_PARTICIPANT_ID;

                if(isChatGPTSender) {
                  senderName = getMessage("CHAT_GPT_SENDER_NAME");
                } else if(messageDetails.participantId === this.props.organiserParticipantId) {
                  // Show name of agent(sender) to other users in selected language 
                  senderName = getUserNameForSelectedLanguage(this.props.language, messageDetails.sender);
                }

                // Error handling. 'error' is undefined in case of no error.
                if(messageDetails.error) {
                  messageDetails.message = this.props.getErrorMessage(messageDetails.error.code);
                }

                // Show own messages.
                return messageDetails.participantId === this.props.participantId || messageDetails.ownMessage ? (
                  <Row className="w-100 d-inline-block m-0" key={uuid.v4()}>
                    <div className="mx-1 chatMsg">
                      <div className={"chatMsg2 mb-2 float-right"}>
                        <Col className="text-left p-0">
                          <p className="msg2 p-2 m-0" style={{fontSize: this.state.selectedFontSizeForMessage}} 
                            dangerouslySetInnerHTML={{__html:sanitizer(autolinker.link(this.getChatGPTMessageFormattedString(messageDetails)), {ADD_ATTR: ['target']})}} />
                        </Col>
                      </div>
                    </div>
                  </Row>
                ) : (
                  // Show reply from other participants or Chat GPT 
                  // (TODO: On page refresh, we get all the messages including Chat GPT messages at user side in get all session data ws message. Hence, we need to handle below condition at UI side. We can remove below condition once we fix get all session data issue).
                  (!messageDetails.receiverId || messageDetails.receiverId === this.props.participantId) &&
                  <Row className="w-100 d-inline-block m-0" key={uuid.v4()}>
                    <div className="chatMsg">
                      <Col lg="12" className="px-1">
                        <p className="msgName mb-1 mt-1" style={{fontSize: this.state.selectedFontSizeForSenderName}}>
                          { senderName }
                        </p>
                        <p className={"msg text-left float-left p-2 mb-2" + (isChatGPTSender ? " chat-gpt-result" : "") } style={{fontSize: this.state.selectedFontSizeForMessage}}
                          dangerouslySetInnerHTML={{__html:sanitizer(autolinker.link(messageDetails.message), {ADD_ATTR: ['target']})}} />
                      </Col>
                    </div>
                  </Row>
                );
              })}
              {this.state.showLoadingUI && 
                <Row id="chatGPTWaitingMessage" className="m-0">
                  <div className="chatMsg">
                    <div className="msgName w-100 mb-1 mt-1" style={{fontSize: this.state.selectedFontSizeForSenderName}}>
                      { getMessage("CHAT_GPT_SENDER_NAME") }
                    </div>
                    <div>
                      <img src={loading} className="loading" alt="loading"/>
                    </div>
                  </div>
                </Row>
              }
            </div>
            <div id="sendMessageSection" className="sendMessageSection">
              <Row>
                <Col lg="9" xs="8" className="pr-0">
                  <Input
                    id="chatTextarea"
                    type="textarea"
                    rows="1"
                    cols="24"
                    name="msg"
                    style={{fontSize: this.state.selectedFontSizeForTypeMessage}}
                    className="inputMsg "
                    placeholder={getMessage("ENTER_MESSAGE_PLACEHOLDER")}
                    value={this.state.message}
                    onChange={this.handleInputChange}
                    onKeyDown={this.onKeyPress}
                    autoFocus={!isRealwearDevice()}
                    maxLength={4096}
                  />
                </Col>
                <Col lg="3" xs="4" className="text-lg-right text-md-right pl-1">
                  <Button style={{fontSize: this.state.selectedFontSizeForSendButton}} className="sendBtn noPadding" onClick={this.sendChatMessage}>
                    {getMessage("SEND")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Resizable>
      </Draggable>
    ) : null;
  }
}