import React from "react";

import { Row, Col, Label, Input, DropdownItem, Collapse } from "reactstrap";

import { getMessage } from "CONFIG/i18n";

import "./ChatSettings.less"

import ic_arrow_down from "ASSETS/Images/ic_arrow_down.svg";

const ChatSettings = (props) => {

  const onChangeOrganizationName = (event) => { 
    props.onChangeOrganizationName(event.target.value);
  }

  const onChangeAPIKey = (event) => { 
    props.onChangeAPIKey(event.target.value);
  }

  const onChangeContext = (event) => { 
    props.onChangeContext(event.target.value);
  }
  
  return (
    <div className="section chat-settings">
      <Row className="noMargin">
        <Col xs={10} className="mainTitle">
          {getMessage("CHAT_SETTINGS")}
        </Col>
        <Col>
          <img
            style={{ transform: `rotate(${props.chatSettingsRotation}deg)` }}
            className="dropDown cursorPointer floatRight"
            src={ic_arrow_down}
            onClick={() => props.handleChatSettingsArrowClick()}
          />
        </Col> 
      </Row>
      <Collapse isOpen={props.isChatSettingsToggler}>
        <Row className="noMargin">
          <Col xs={5} className="align-self-center pb-4">
            <Label>{getMessage("ORGANIZATION")}</Label> </Col>
          <Col xs={7} className="pl-0 pb-2">
            <Input
              type="text"
              name="organizationName"
              className="inputFormControl"
              required
              onChange={onChangeOrganizationName}
              placeholder={getMessage("ORG_NAME_PLACEHOLDER")}
              value={props.chatSettings.organizationName ? props.chatSettings.organizationName : "" }
            />
            <Row className="d-flex errors my-0 py-2 float-left">
              { !props.chatSettings.isOrganizationNameValid ? getMessage("INVALID_ORG_NAME") : "" }
            </Row>
          </Col>
        </Row>
        <Row className="noMargin">
          <Col xs={5} className="align-self-center pb-4">
            <Label>{getMessage("APIKey")}</Label> </Col>
          <Col xs={7} className="pl-0 pb-2">
            <Input
              type="text"
              name="APIKey"
              className="inputFormControl"
              required
              onChange={onChangeAPIKey}
              placeholder={getMessage("ENTER_API_KEY")}
              value={props.chatSettings.APIKey ? props.chatSettings.APIKey : ""}
            />
            <Row className="d-flex errors my-0 py-2 float-left">
              { !props.chatSettings.isAPIKeyValid ? getMessage("INVALID_API_KEY") : "" }
            </Row>
          </Col>
        </Row>
        <DropdownItem divider />
        <Row className="noMargin">
          <Col xs={12} className="note pt-1">
            <span className="mainTitle">{getMessage("NOTE") + ": "} </span><span>{getMessage("CHAT_SETTINGS_NOTE")}</span>
          </Col>
        </Row>
        <Row className="noMargin">
          <Col xs={12}className="pt-4 pb-2">
            {getMessage("CHAT_CONTEXT_LABEL")}
          </Col>
          <Col>
            <Input
              type="textarea"
              rows={4}
              className="inputFormControl context-textarea"
              onChange={onChangeContext}
              placeholder={getMessage("ENTER_CONTEXT")}
              value={props.chatSettings.context ? props.chatSettings.context : ""}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};
  
export default ChatSettings;