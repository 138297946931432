import React, { Component } from "react";
import { Table, Row, Container, Col, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

// Common Components
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";

// Strings
import { getMessage } from "CONFIG/i18n";

// Utilities
import {
  listOfAvailableModule,
  modulesAvailableConstant,
  BUSINESS_MODELS,
  TOOLTIP
} from "../../../Utils/Constants";
import { formatDateByLocale, getTimeInLocaleTimezone, convertMinutesToDDhhmm } from "UTILS/DateUtils";

import ic_delete from "ASSETS/Images/ic_delete.svg";

import "./Organisations.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

export default class OrganisationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationsData: this.props.organizationsData,
      availableModulesDropdown: false,
      moduleId: '',
      showAvailablemoules: false
    };
    this.errorIconTooltipBoundaryElement = null;
    this.tooltipBoundaryElement =null;
  }

  componentDidMount() {
    let organizationsData = this.props.organizationsData;
    organizationsData = this.mapImageLabelToAvailAbleModule(organizationsData);
    this.setState({
      organizationsData: organizationsData
    });
    this.getErrorIconTooltipBoundaryElement();
    this.getTooltipBoundaryElement();
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("organisations-list")[0];
  }
  componentDidUpdate(prevProp) {
    let changeFlag = _.isEqual(
      this.props.organizationsData,
      prevProp.organizationsData
    );

    if (!changeFlag) {
      let organizationsData = this.props.organizationsData;

      organizationsData = this.mapImageLabelToAvailAbleModule(
        organizationsData
      );

      this.setState({
        organizationsData: organizationsData
      });
    }
  }

  /**
   * Gets Boundary element for info icon tooltip.
   * Tooltip will be rendered inside errorIconTooltipBoundaryElement
   */
  getErrorIconTooltipBoundaryElement = () => {
    this.errorIconTooltipBoundaryElement = document.getElementsByClassName("organisationsListContainer")[0];
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if(!_.isEqual(this.props, nextProps))
  //     return true;
  //   else if(!_.isEqual(this.state, nextState))
  //     return true;
  //   else  
  //     return false
  // }

  mapImageLabelToAvailAbleModule = organizationsData => {
    _.map(organizationsData, (item, index) => {
      let sortedAvailableModules = ["CANVAS"].concat(
        item.modules ? item.modules : []
      );

      let updatedAvailableModule = this.props.getAvailableModules(
        sortedAvailableModules
      );

      item.updatedAvailableModules = Object.assign({}, updatedAvailableModule);
    });
    return organizationsData;
  };

  sortByKeys = object => {
    return _.fromPairs(_.map(listOfAvailableModule, key => [key, object[key]]));
  };

  toggle = (id) => {
    this.setState({
      availableModulesDropdown: id,
      showAvailablemoules: !this.state.showAvailablemoules
    });
  }

  availableModulesFormat = (cell, row) => {
    if (!row.updatedAvailableModules) return;
    let updatedAvailableModulesLength = Object.keys(row.updatedAvailableModules).length;
    let messageModule = (updatedAvailableModulesLength === 1) ? getMessage("MODULE") : getMessage("MODULES");
    let availableModule = (updatedAvailableModulesLength < 10) ? ('0' + updatedAvailableModulesLength) : (updatedAvailableModulesLength);
    let dropDownItemClassName = "paddingThings displayFlex-1 alignItemsCenter flexDirectionColumn presentModule noMargin displayFlex px-0"

    return (
      <Container>
        <Dropdown
          direction="down"
          className="dropdown"
          isOpen={this.state.availableModulesDropdown === row.id && this.state.showAvailablemoules}
          toggle={() => { this.toggle(row.id) }}>
          <DropdownToggle className="availableModulesBox" >
            <div className="availableModulesValue">
              {availableModule}
            </div>
            <div className="availableModulesTitle">
              {messageModule}
            </div>
          </DropdownToggle>
          <Row>
            <DropdownMenu className="setDropDownFlexMaragin" >
              {listOfAvailableModule.map((key, index_key) => {
                return row.updatedAvailableModules && row.updatedAvailableModules[key] ?
                  <DropdownItem className="" key={key}>
                    <Col
                      style={{ textAlign: "center" }}
                      className={dropDownItemClassName}
                      key={index_key + 100}
                      lg={12}
                      cellSpacing={12}
                      cellPadding={4}
                    >

                      <div lg={8} className="moduleImage">
                        <img
                          src={row.updatedAvailableModules[key].icon}
                          style={
                            key === modulesAvailableConstant.PDF
                              ? {
                                height: "1.1em",
                                width: "1.1em"
                              }
                              : {
                                height: "1.3em",
                                width: "1.3em"
                              }
                          }
                        />
                      </div>
                      <div lg={8} className="moduleLabel moduleImage" key={index_key + 200}>
                        {row.updatedAvailableModules[key].label === getMessage('CO_BROWSE') ?
                          getMessage(row?.cobrowseSettings?.cobrowseType) : row.updatedAvailableModules[key].label }
                      </div>
                    </Col>
                  </DropdownItem>
                  :
                  null
              })}
            </DropdownMenu>
          </Row>
        </Dropdown>

      </Container>
    )
  }

  deleteFormat = (cell, row) => {
    return (
      <>
        <img
          src={ic_delete}
          id={`delete${row.id}`}
          className="cursorPointer delete-action-img"
          onClick={(e) => {
            e.stopPropagation();
            this.props.showDeleteConfirmationModal(row.id, row.name);
          }}
        />
        <CustomTooltip
          tooltipId={`delete${row.id}`}
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipText={getMessage("DELETE")}
        />
      </>
    );
  }

  organizationFormat = (cell, row) => {
    return (
      <div className="displayFlex justifyContentFlexCenter">
        <div className="col-3">
          {this.isThresholdValueReached(row) || this.isNoOfSeatsExceeded(row)
            ? (
              <CustomTooltip
                tooltipId={`status${row.id}`}
                tooltipType={TOOLTIP.TYPE.ERROR}
                tooltipBoundariesElement={this.errorIconTooltipBoundaryElement}
                tooltipText={this.isThresholdValueReached(row) ? getMessage("THRESHOLD_VALUE_EXCEEDS") : getMessage("ICON_DESCRIPTION_NUMBER_OF_AGENTS_EXCEEDS_NUMBER_OF_SEATS")}
                showIcon={true}
              />
            )
            : null
          }
        </div>
        <div className="col-9 displayFlex">
          <label
            onClick={() =>
              this.props.gotoEditOrganization(row.admins[0].userId, row.id)
            }
            className={"customerName nameEllipsis custom-tooltip text-decoration" + this.getThresholdClassName(row)}
            id={`organisationName${row.id}`}
          >
            {row.name}
          </label>
          <CustomTooltip
            tooltipId={`organisationName${row.id}`}
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={row.name}
          />
        </div>
      </div>
    )
  }

  agentsFormat = (cell, row) => {
    return (
      <div
        className={this.getThresholdClassName(row)}
        id={`businessModel${row.id}`}
      >
        {row.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS
          ? `${getMessage("S, ")}${row.totalAgents}/${
            row.seats ? row.seats : 0
          }`
          : `${getMessage("C, ")}${row.activeSessions}/${
            row.maxConcurrentSessions ? row.maxConcurrentSessions : 0
          }`}
        <CustomTooltip
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipText={
            row.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS
              ? getMessage("NO_OF_SEATS")
              : getMessage("MAX_CONCURRENT_CONNECTIONS")
          }
          tooltipId={`businessModel${row.id}`}
        />
      </div>
    );
  };

  sessionFormat = (cell, row) => {
    if (row.lastSessionTime || row.lastSessionTime || row.activeSessions || row.totalSessions)
      return (
        <div>
          <div className={this.getThresholdClassName(row)}
          >
            {row.lastSessionTime ? formatDateByLocale(row.lastSessionTime, this.props.language) : `--`}
          </div>
          <div className={this.getThresholdClassName(row)}
          >
            {row.lastSessionTime ? getTimeInLocaleTimezone(row.lastSessionTime) : `--`}, {row.activeSessions}, {row.totalSessions}
          </div>
        </div>
      )
    else
      return (
        <div className={this.getThresholdClassName(row)}
        >
          --
        </div>
      )
  }

  totalMinutesFormat = (cell, row) => {
    return (
      <div className={this.getThresholdClassName(row)}
      >
        {convertMinutesToDDhhmm(row.totalTimeToDate)}
      </div>
    )
  }

  qrCodeStatus = (cell, row) => {
    return (
      <div className={this.getThresholdClassName(row)}
      >
        {row.isQRCodeEnabled ? `${row.totalQRCodes}/${row.qr_limit}` : 0}
      </div>
    )
  }

  delegatedAdminFormat = (cell, row) => {
    return (
      <>
        <div
          className={"nameEllipsis custom-tooltip " +this.getThresholdClassName(row)}
          id={`delegatedAdminFormat${row.id}`}
        >
          {row.admins && row.admins[0].userName ? row.admins[0].userName : `--`}
          <CustomTooltip
            tooltipText={row.admins[0].userName}
            tooltipId={`delegatedAdminFormat${row.id}`}
          />
        </div>
      </>
    );
  }

  sortByLastSessionTime = (a, b, order, field) => {
    const p1 = a[field] ? new Date(a[field]).getTime() : 0;
    const p2 = b[field] ? new Date(b[field]).getTime() : 0;
    if (order == 'desc') {
      return p2 - p1
    }
    return p1 - p2
  }

  sortTotalMinutes = (a, b, order, field) => {
    const p1 = a[field]
    const p2 = b[field]
    if (order == 'desc') {
      return p2 - p1
    }
    return p1 - p2
  }

  isThresholdValueReached = (row) => {
    return (row.businessModel === BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS
      && row.concurrentSessionThreshold && row.concurrentSessionThreshold > 0
      && row.activeSessions >= row.concurrentSessionThreshold)
  }
  isNoOfSeatsExceeded = (row) => {
    return (row.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS
      && row.totalAgents && row.totalAgents > row.seats)
  }

  // if Business Model is Max Concurrent Connections and Threshold value is reached then whole row should be display in red color
  getThresholdClassName = (row) => {
    return this.isThresholdValueReached(row) || this.isNoOfSeatsExceeded(row)
      ? "crossesThresholdValue"
      : ""
  }

  renderOrganisationsList = () => {
    let options = {
      defaultSortName: 'lastSessionTime',  // default sort column name
      defaultSortOrder: 'desc'  // default sort order
    };
    return (
      <div>
        <BootstrapTable
          data={this.state.organizationsData}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='organisations-list custom-list'
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
          options={options}
        >
          <TableHeaderColumn
            dataField='name'
            width="15%"
            dataSort={true}
            dataFormat={this.organizationFormat}
            dataAlign='center'
            isKey
          >
            <div>
              <div>
                {getMessage("ORGANIZATION")}
              </div>
              <div>
                {getMessage("NAME")}
              </div>
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='totalAgents'
            dataFormat={this.agentsFormat}
            width="12%"
            dataAlign='center'
          >
            <div>
              {getMessage("AGENTS")}
            </div>
            <div className="subHeaderName">
              {getMessage("MODEL")}, {getMessage("CURRENT")}/{getMessage("PROVISIONED")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='lastSessionTime'
            dataFormat={this.sessionFormat}
            width="14%"
            dataAlign='center'
            dataSort={true}
            sortFunc={this.sortByLastSessionTime}
          >
            <div>
              {getMessage("SESSION")}
            </div>
            <div className="subHeaderName">
              {getMessage("LAST")}, {getMessage("ACTIVE")}, {getMessage("TOTAL")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='totalTimeToDate'
            dataFormat={this.totalMinutesFormat}
            width="14%"
            dataAlign='center'
            dataSort={true}
            sortFunc={this.sortTotalMinutes}
          >
            <div>
              {getMessage("TOTAL_USAGE")}
            </div>
            <div>
              {getMessage("TO_DATE")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='qrCodeStatus'
            dataFormat={this.qrCodeStatus}
            width="10%"
            dataAlign='center'
          >
            <div>
              {getMessage("QR_CODES")}
            </div>
            <div className="subHeaderName">
              {getMessage("ACTIVE")}/{getMessage("ALLOCATED")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='admins'
            dataFormat={this.delegatedAdminFormat}
            width="15%"
            dataAlign='center'
          >
            <div>
              {getMessage("DELEGATED")}
            </div>
            <div>
              {getMessage("ROLE_ADMIN")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='updatedAvailableModules'
            width="10%"
            dataFormat={this.availableModulesFormat}
            dataAlign='center'
          >
            <div>
              {getMessage("AVAILABLE")}
            </div>
            <div>
              {getMessage("MODULES")}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            width="10%"
            dataFormat={this.deleteFormat}
            dataAlign="center"
          >
            {getMessage("DELETE")}
          </TableHeaderColumn>
        </BootstrapTable>

      </div>
    );
  }
  render() {
    return (
      <div className="organisationsListContainer">
        {this.state.organizationsData &&
        this.state.organizationsData.length > 0 ? (
            this.renderOrganisationsList()
          ) : (
            <div className="margin-bottom">
              <NoData
                minHeight={"60vh"}
                message={
                  this.props.searchKey
                    ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                      searchKey: this.props.searchKey,
                    })
                    : getMessage("NO_ORGANIZATIONS")
                }
              />
            </div>
          )}
      </div>
    );
  }
}
