import React, { Component } from "react";
import { Card, CardImg, Button } from "reactstrap";
import { isIE, isIPad13, isMobileOnly, isTablet } from "react-device-detect";
import * as kurentoUtils from "kurento-utils";

import EndScreenshareConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import BitRateTile from "COMPONENTS/CommonComponents/BitRate/BitRateTile";
import ComponentSpinner from "COMPONENTS/Spinner/ComponentSpinner/ComponentSpinner";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';

import { wsocket } from "WEBSOCKET/wsocket";
import { getMessage } from "CONFIG/i18n";
import { socketMessage, webRtcMessageId, mediaType } from "WEBSOCKET/constants";
import { USER_ROLES, downloadableModules, COMPONENT_IN_FOCUS } from "../../Utils/Constants";
import { isMobile, getTurnServerOptionConfig, getFirstNamebyParticipantId, isMobileOrIpadPortrait } from "UTILS/Utility";
import { Modules } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import ic_screeshare_zoom from "ASSETS/Images/ic_screeshare_zoom.svg";
import ic_screeshare_zoomout from "ASSETS/Images/ic_screeshare_zoomout.svg";
import ic_screensharing_white from "ASSETS/Images/ic_screensharing_white.svg";
import ic_share_big_white from "ASSETS/Images/ic_share_big_white.svg";
import ic_video_close from "ASSETS/Images/ic_video_close.svg";
import ic_screenshare_download from "ASSETS/Images/ic_download_white.svg";

import { headerAction } from "CONFIG/ActionFactory";
import store from "REDUX/store.js";
import { ACTIONS } from "UTILS/Constants";
import "./ScreenShare.less";

export default class ScreenShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenShareId: null,
      isZoomOut: true,
      showButton: false,
      showEndScreenshareConfirmationModal: false,
      loaderSize: 8,
      showSpinner: false,
      streaming: false
    };
    this.constraints = {
      audio: false,
      video: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // screenshare should be closed while refreshing since user's presenter access is revoked on refresh
    if (this.props.presenterId !== this.props.uniqueId &&
      this.props.screenSharingParticipantId === this.props.uniqueId) {
      this.onClose();
    }

    if (this.props.latestUpdatedParticipant && this.props.uniqueId === this.props.presenterId) {
      const { uniqueId } = this.props.latestUpdatedParticipant;
      if (prevProps.admittedParticipants.length > this.props.admittedParticipants.length
        && this.props.screenSharingParticipantId == uniqueId) {
        this.onClose();
      }
    }

    // when screenshare zoom in make it zoom out when co-browse or snapshot started
    // or when orientation changes because zoom in is not allowed in portrait mode
    if(!this.state.isZoomOut && 
      ((this.props.showSnapshot !== prevProps.showSnapshot && this.props.showSnapshot) ||
    (this.props.showCustomerCoBrowse !== prevProps.showCustomerCoBrowse && this.props.showCustomerCoBrowse) ||
    (this.props.snapshotPermissionReply !== prevProps.snapshotPermissionReply &&
      this.props.snapshotPermissionReply && this.props.uniqueId === this.props.snapshoteeId) || 
      (this.props.isLandscape !== prevProps.isLandscape && isMobileOrIpadPortrait()))) {
      this.toggleScreenSharingState();
    }

    if (this.props.renegotiateScreenshare) {
      // reset state before proceeding
      store.dispatch({
        type: ACTIONS.SET_RENEGOTIATE_SCREEN_PARTICIPANT,
        renegotiateScreenshare: false,
      });
      // proceed with renegotiation
      this.renegotiate()
    }
  }

  componentDidMount() {
    console.log('DEBUG :: in Screenshare.js this.props.screenSharingParticipantId: ', this.props.screenSharingParticipantId);
    if (!wsocket.io) return;
    window.addEventListener("resize", () => {
      this.toggleScreenSharingLayout(true);
    });
    if (
      this.props.screenSharingParticipantId ===
      this.props.currentParticipantUniqueId
    ) {
      console.log("ScreenShare-- Send");
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
          // Chrome
          this.invokeGetDisplayMedia();
        } else {
          // Safari
          this.setState({
            showButton: true
          });
        }
      } else {
        this.invokeGetDisplayMedia();
      }
    } else {
      console.log("ScreenShare-- Receive");
      this.invokeReceiveScreenShareMedia();
    }

    headerAction.updateHeaderIconClass(Modules.SCREENSHARE, "moduleIconActive cursorDisabled", " cursorDisabled");

    let mediaElement = document.getElementById(this.props.screenShareId);
    // scroll to screen share video when screen share
    if (!isIE && (isMobileOnly || ((isTablet || isIPad13) && !this.props.isLandscape))) {
      mediaElement.oncanplay = () => {
        this.setState({
          streaming: true
        });
        let screenShareDiv = document.getElementById("screenShareDiv");
        screenShareDiv && screenShareDiv.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
    } else if (isMobileOnly || ((isTablet || isIPad13) && !this.props.isLandscape)) {
      this.interval = setInterval(() => {
        let screenShareDiv = document.getElementById("screenShareDiv");
        if (document.getElementById(this.props.screenShareId)
          && document.getElementById(this.props.screenShareId).videoWidth) {
          this.setState({
            streaming: true
          });
          screenShareDiv && screenShareDiv.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
          clearInterval(this.interval)
        }
      }, 2000)
    } else {
      mediaElement.oncanplay = () => {
        this.setState({
          streaming: true
        });
      }
      let videoElement = document.getElementById(this.props.screenShareId);
      // isIE ? videoElement.width = "100%" : null
      if (videoElement) {
        let videoWidth = videoElement.offsetWidth;
        console.log('videoObject.offsetHeight: ', videoElement.offsetHeight);
        console.log('videoObject.offsetWidth: ', videoElement.offsetWidth);
        isIE ? videoElement.width = videoWidth : null
        isIE ? videoElement.height = videoWidth * 0.5625 : null
      }
    }
  }
  componentWillUnmount() {
    console.log('DEBUG :: in Screenshare.js componentWillUnmount');
    if (!wsocket.io) return;
    clearInterval(this.interval)
    headerAction.updateHeaderIconClass(Modules.SCREENSHARE, "moduleIcon", " cursorPointer");
    console.log('DEBUG :: in Screenshare.js componentWillUnmount updated header icons');
  }

  invokeReceiveScreenShareMedia = () => {
    this.createReceiveOnlyPeer();
  };

  createReceiveOnlyPeer() {
    let video = document.getElementById(this.props.screenShareId);
    let options = {
      remoteVideo: video,
      mediaConstraints: this.constraints,
      onicecandidate: this.onIceCandidate,
      configuration: {
        iceServers: getTurnServerOptionConfig(this.props.turnServer),
        iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
      }
    };
    let self = this;
    wsocket.screenShareRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
      options,
      function (error) {
        if (error) {
          return console.error(error);
        }
        this.generateOffer(self.offerToReceiveVideo);
        self.setState({
          showBitRate: true
        })
      }
    );

    let pc = wsocket.screenShareRtcPeer.peerConnection;

    pc.ontrack = (event) => {
      console.log("WEBRTC screenshare remote stream id for",
        getFirstNamebyParticipantId(this.props.admittedParticipants, this.props.screenSharingParticipantId, true),
        this.props.screenSharingParticipantId,
        event.track.id
      );
    }

    pc.onconnectionstatechange = (event) => {
      const participantName = getFirstNamebyParticipantId(this.props.admittedParticipants,
        this.props.screenSharingParticipantId, true);
      console.log("SCREEN_SHARE", "WEBRTC receive only RTCPeerConnection state for",
        participantName,
        this.props.screenShareId, pc.connectionState, pc.getTransceivers());
      switch (pc.connectionState) {
        case "connected":
        case "completed":
          this.toggleSpinner(false);
          if (isIE) {
            attachMediaStream(video, wsocket.screenShareRtcPeer.getRemoteStream());
          } else {
            video.srcObject = wsocket.screenShareRtcPeer.getRemoteStream();
            video.load();
          }
          break;
        case "failed":
          this.toggleSpinner(true);
          this.triggerRenegotiation(this.props.screenSharingParticipantId, 'receive');
        case "disconnected":
          console.error("SCREEN_SHARE", "WEBRTC receive only RTCPeerConnection state for",
            participantName,
            this.props.uniqueId, pc.connectionState, pc.getTransceivers());
          break;
        case "closed":
          console.error("SCREEN_SHARE", "WEBRTC receive only RTCPeerConnection state for",
            participantName,
            this.props.screenShareId, pc.connectionState, pc.getTransceivers());
          break;
      }
    }
    pc.oniceconnectionstatechange = ev => {
      const participantName = getFirstNamebyParticipantId(this.props.admittedParticipants, this.props.screenSharingParticipantId, true);
      console.log("SCREEN_SHARE", "WEBRTC receive only IceConnection state for",
        participantName,
        this.props.screenShareId, pc.iceConnectionState);
      switch (pc.iceConnectionState) {
        case "disconnected":
        case "failed":
        case "closed":
          console.error("SCREEN_SHARE", "WEBRTC receive only IceConnection state for",
            participantName,
            this.props.screenShareId, pc.iceConnectionState);
          break;
      }
    }

  };
  invokeGetDisplayMedia = () => {
    var videoConstraints = {
      aspectRatio: 1.777,
      height: this.props.screenshareBandwidthSettings.bandwidth ?
        this.props.screenshareBandwidthSettings.bandwidth :
        null,
      frameRate: this.props.screenshareBandwidthSettings.fps,
    }
    let ssConstarints = {
      video: videoConstraints
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      navigator.mediaDevices
      .getDisplayMedia(ssConstarints)
      .then(this.success)
      .catch(e => {
        this.error(e);
      });
    } else if (navigator.getDisplayMedia) {
      navigator
      .getDisplayMedia(ssConstarints)
      .then(this.success)
      .catch(this.error);
    } else if (
      typeof AdapterJS !== "undefined" &&
      AdapterJS.webrtcDetectedBrowser === "IE" &&
      AdapterJS.webrtcDetectedVersion >= 9
    ) {
      videoConstraints = this.props.screenshareBandwidthSettings.bandwidth ? {
        mediaSource: ["screen"],
        aspectRatio: 1.777,
        height: { exact: this.props.screenshareBandwidthSettings.bandwidth },
        frameRate: this.props.screenshareBandwidthSettings.fps
      } : {
        mediaSource: ["screen"],
        aspectRatio: 1.777,
        frameRate: this.props.screenshareBandwidthSettings.fps
      }

      navigator.getUserMedia(
        {
          audio: false,
          video: videoConstraints
        },
        this.success,
        this.error
      );
    } else {
      this.error("No suitable API to get Display Media");
    }
  };

  success = screen => {
    let ssVideo = document.getElementById(this.props.screenShareId);
    if(!ssVideo) {
      // Fixed - 4355, if stream has been started but screenshare video component is not present due to change of presenter, stop the screenshare video tracks  
      const tracks = screen.getTracks();
      tracks.forEach(function(track) {
        track.stop();
      });
      this.stopScreenShare();
      return;
    }
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      ssVideo = attachMediaStream(ssVideo, screen);
    } else {
      ssVideo.srcObject = screen;
    }
    screen.getVideoTracks()[0].onended = () => {
      this.stopScreenShare();
    };
    this.createSendOnlyPeer(screen);
  };

  createSendOnlyPeer(screen) {
    let options = {
      videoStream: screen,
      mediaConstraints: this.constraints,
      onicecandidate: this.onIceCandidate,
      configuration: {
        iceServers: getTurnServerOptionConfig(this.props.turnServer),
        iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
      }
    };
    let self = this;
    wsocket.screenShareRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
      options,
      function (error) {
        if (error) {
          return console.error(error);
        }
        console.log("WEBRTC Screen share self stream id for",
          getFirstNamebyParticipantId(self.props.admittedParticipants, self.props.uniqueId, true),
          self.props.uniqueId,
          screen.id
        );
        this.generateOffer(self.offerToReceiveVideo);
        self.setState({
          showBitRate: true
        })
      }
    );

    let pc = wsocket.screenShareRtcPeer.peerConnection;

    pc.onconnectionstatechange = (event) => {
      const participantName = getFirstNamebyParticipantId(this.props.admittedParticipants,
        this.props.screenSharingParticipantId, true);
      console.log("SCREEN_SHARE", "WEBRTC receive only RTCPeerConnection state for",
        participantName,
        this.props.screenShareId, pc.connectionState, pc.getTransceivers());
      switch (pc.connectionState) {
        case "connected":
        case "completed":
          this.toggleSpinner(false);
          break;
        case "failed":
          if (window.navigator.onLine) {
            this.toggleSpinner(true);
            this.triggerRenegotiation(this.props.screenSharingParticipantId, 'send');
          }
        case "disconnected":
          console.error("SCREEN_SHARE", "WEBRTC send only RTCPeerConnection state for",
            participantName,
            this.props.uniqueId, pc.connectionState, pc.getTransceivers());
          break;
        case "closed":
          console.error("SCREEN_SHARE", "WEBRTC send only RTCPeerConnection state for",
            participantName,
            this.props.uniqueId, pc.connectionState, pc.getTransceivers());
          break;
      }
    }
    pc.oniceconnectionstatechange = ev => {
      const participantName = getFirstNamebyParticipantId(this.props.admittedParticipants, this.props.screenSharingParticipantId, true);
      console.log("SCREEN_SHARE", "WEBRTC send only IceConnection state for",
        participantName,
        this.props.uniqueId, pc.iceConnectionState);
      switch (pc.iceConnectionState) {
        case "disconnected":
        case "failed":
        case "closed":
          console.error("SCREEN_SHARE", "WEBRTC send only IceConnection state for",
            participantName,
            this.props.uniqueId, pc.iceConnectionState);
          break;
      }
    }
  };

  toggleSpinner = (showSpinner) => {
    this.setState({ showSpinner })
  }

  onIceCandidate = (candidate, wp) => {
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.ICE_CANDIDATE,
        sessionKey: this.props.sessionKey,
        iceData: {
          candidate: candidate,
          senderId: this.props.screenSharingParticipantId,
          media: mediaType.SCREEN
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  offerToReceiveVideo = (error, offerSdp, wp) => {
    if (error) return console.error("sdp offer error");
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SDP_OFFER,
        sessionKey: this.props.sessionKey,
        sdp: offerSdp,
        senderId: this.props.screenSharingParticipantId,
        media: mediaType.SCREEN
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  stopScreenShare = () => {
    console.log("stopScreenShare");
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.STOP_SCREEN_SHARING,
        sessionKey: this.props.sessionKey,
        media: mediaType.SCREEN
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  error = (e) => {
    console.log("Screenshare ErrorName & Screenshare Error:", e.name, e.message);
    let errorMessage;
    if (e?.name === 'NotAllowedError' && e?.message === 'The request is not allowed by the user agent or the platform in the current context.') {
      errorMessage = 'SCREENSHARE_PERMISSION';
    } else {
      // When user denies permission for screen share, error should not be displayed (WAAG-4911)
      // Chrome error - NotAllowedError
      // IE error - PermissionDeniedError
      if ((e?.name === 'NotAllowedError' && e?.message === 'Permission denied') ||
        (e?.name === 'PermissionDeniedError' && e?.message === 'User denied permission to use requested devices.')) {
        errorMessage= null;
      } else {
        errorMessage = 'INTERNAL_SERVER_ERROR';
      }
    }

    //Other than NotAllowedError "Something went wrong is displayed" *todos - for any other error handling we need to add cases here
    if (errorMessage) {
      toastr.error(errorMessage);
    }
    this.stopScreenShare();
    console.log('wsocket.screenShareRtcPeer: ', wsocket.screenShareRtcPeer);
    if (wsocket.screenShareRtcPeer !== null) {
      wsocket.screenShareRtcPeer.dispose();
      wsocket.screenShareRtcPeer = null;
    }
  };

  toggleScreenSharingState = () => {
    if (this.state.isZoomOut == true) {
      this.props.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    }
    this.setState({ isZoomOut: !this.state.isZoomOut }, () => {
      this.toggleScreenSharingLayout(false)
      if (this.state.isZoomOut) {
        this.setState({ loaderSize: 8 })  // on focused component is screenshare spinner size is 16
      } else {
        this.setState({ loaderSize: 16 })  // when screenshare is not in focus spinner size is 8
      }
    })
  }

  toggleScreenSharingLayout = (onResize = false) => {
    if (!onResize && !this.state.isZoomOut) {
      window.scrollTo(0, 0);
    }
    let headerDiv = document.getElementById("commonHeader");
    let ssDiv = document.getElementById("cardBody");
    let whiteBoardDiv = document.getElementById("whiteboardContainer");
    let dashboard = document.getElementById("dashboard");
    let whitboardposition = whiteBoardDiv ? whiteBoardDiv.getBoundingClientRect() : {}
    let videoElement = document.getElementById(this.props.screenShareId)
    let screenShareContainer = document.getElementById('screenShareContainer')
    if(!ssDiv || !whiteBoardDiv || !whiteBoardDiv){
      return // issue comes up when we close screen share window
    }
    // if (ssDiv.hasAttribute("style")) {
    //   ssDiv.removeAttribute("style");
    // } else {
    //   ssDiv.style.position = "fixed";
    //   ssDiv.style.width = "75%"; //TODO: Calculate
    //   ssDiv.style.top = "5em";
    //   ssDiv.style.left = "0.5em";
    // }
    let zoomOut = this.state.isZoomOut;
    if (zoomOut) {
      ssDiv.className = "cardBody ssDiv1";
      ssDiv.style.width = null;
      ssDiv.style.height = null;
      ssDiv.style.top = null;
      if(videoElement && screenShareContainer) {
        videoElement.style.width = null;
        videoElement.style.height = null;
        videoElement.style.display = "block";
        screenShareContainer.style.display = "block";
        videoElement.class = ""
        isIE ? videoElement.width = "99%" : null
        let videoWidth = videoElement.offsetWidth;
        isIE ? videoElement.width = videoWidth : null
        isIE ? videoElement.height = videoWidth * 0.5625 : null
      }
    } else {
      if (isIE) {
        ssDiv.className = "cardBody ssDiv2 cardBodyIE alignItemsCenter";
        ssDiv.style.width = whiteBoardDiv.clientWidth + 'px';
        ssDiv.style.height = whiteBoardDiv.clientHeight + 'px';
        ssDiv.style.top = whiteBoardDiv.offsetTop + 'px';
        ssDiv.style.top = whitboardposition.top + "px";
        
        if (videoElement) {
          //left and right margin 50px each subtraction
          videoElement.style.width = whiteBoardDiv.clientWidth - 100 + "px";
          //top and bottom margin subtraction
          videoElement.style.height = whiteBoardDiv.clientHeight - 96  + "px";
          videoElement.style.display = this.props.screenSharingParticipantId === this.props.currentParticipantUniqueId
            ? "none"
            : "block";
          screenShareContainer.style.display = this.props.screenSharingParticipantId === this.props.currentParticipantUniqueId 
            ? "none" 
            : "block";
          videoElement.className = "screenShareVideo horizontalCenter"
        }
      } else {
        ssDiv.className = "cardBody ssDiv2 alignItemsCenter";

        if (!isMobile()) {
          ssDiv.style.width = whitboardposition.width + "px";
          // This height is set when presenter zooms in the shared screen (since videoElement is missing)
          // For the other users, height will be managed through videoElement max-height
          
          if (this.props.screenSharingParticipantId === this.props.uniqueId) {
            let dashboardPosition = dashboard ? dashboard.getBoundingClientRect() : {}
            ssDiv.style.height = dashboardPosition.height + 10 + "px";
          }
          if (videoElement) {
            videoElement.style.width = "100%";
            if (this.props.screenSharingParticipantId !== this.props.uniqueId && videoElement.clientHeight)
              ssDiv.style.maxHeight = videoElement.clientHeight + 200 + "px";
            videoElement.style.display = this.props.screenSharingParticipantId === this.props.currentParticipantUniqueId
              ? "none"
              : "block";
            setTimeout(() => {
              screenShareContainer.style.display = this.props.screenSharingParticipantId === this.props.currentParticipantUniqueId 
                ? "none" 
                : "block";
            }, 100);
          }
        }
        else {
          videoElement.style.width = "100%";
        }
        ssDiv.style.top = headerDiv.offsetHeight + "px"
      }
    }
  };

  download = () => {
    this.props.broadcastDownloadAction(downloadableModules.SCREENSHARE);
    var video = document.getElementById(this.props.screenShareId);
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.height = video.clientHeight;
    canvas.width = video.clientWidth;

    if (
      typeof AdapterJS !== "undefined" &&
      AdapterJS.webrtcDetectedBrowser === "IE" &&
      AdapterJS.webrtcDetectedVersion >= 9
    ) {
      const lastFrame = video.getFrame();
      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        var blob = canvas.msToBlob();
        window.navigator.msSaveBlob(blob, 'dicomimage.png');
      };
      image.onerror = () => {
        console.error("Error Occured");
      }
      if (lastFrame) {
        image.setAttribute("src", "data:image/png;base64," + lastFrame);
      }
    }
    else {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      let url = canvas.toDataURL();
      let downloadElement = document.getElementById("screenShareDownload");
      downloadElement.href = url;
      downloadElement.download = "screen_share.png";
    }
  };

  onClose = () => {
    this.stopScreenShare();
  };
  onOpenCloseEndScreenshareConfirmationModal = (modalState) => {
    this.setState({
      showEndScreenshareConfirmationModal: modalState
    })
  }
  shouldShowZoomIcon = () => {
    return !(this.props.showSnapshot || (this.props.snapshoteeId
      && this.props.snapshotPermissionReply) || 
      (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning)) &&
      !this.props.showCustomerCoBrowse
  }

  triggerRenegotiation = (screenSharingParticipantId, direction) => {
    console.log(`Renegotiating ${direction} only peer for SCREEN_SHARE`)
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.RENEGOTIATE,
        sessionKey: this.props.sessionKey,
        senderId: screenSharingParticipantId,
        media: mediaType.SCREEN
      }
    });
  }

  renegotiate = () => {
    if (window.navigator.onLine) {
      const direction = this.props.screenSharingParticipantId ===
        this.props.currentParticipantUniqueId ? 'send' : 'receive';
      console.log(`Screenshare renegotiate ${direction}`);

      const oldPeer = wsocket.screenShareRtcPeer;
      if (direction === 'send') {
        const peerStream = oldPeer.getLocalStream();
        oldPeer.dispose(false);
        this.createSendOnlyPeer(peerStream);
      } else {
        // we don't need to hold on to the old remote stream 
        oldPeer.dispose(true);
        this.createReceiveOnlyPeer();
      }
    }
  }

  render() {
    return (
      <div id="screenShareDiv" className="screenShareDiv">
        {this.state.showEndScreenshareConfirmationModal ? (
          <EndScreenshareConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={() => { this.onOpenCloseEndScreenshareConfirmationModal(false) }}
            handleYesClick={() => {
              this.onClose();
            }}
            message={getMessage("END_SCREENSHARE_CONFIRMATION_TEXT")}
          />
        ) : null}
        <Card className="cardBody displayFlex ssDiv1" id="cardBody">
          <div className="heading">
            <div className="leftHeading d-flex align-items-center">
              <CardImg src={this.state.isZoomOut ? ic_screensharing_white : ic_share_big_white} className="iconScreenShare" />
              {
                // Screenshare label will always be shown on small window 
                // and to the presenter only when his screenshare window is small 
                ((!this.state.isZoomOut && this.props.screenSharingParticipantId !==
                  this.props.currentParticipantUniqueId) || this.state.isZoomOut)
                && <label className="title"> {getMessage("SCREENSHARE")} </label>
              }
              {
                // - will be shown on big screenshare window only for non-presenter
                (!this.state.isZoomOut && this.props.screenSharingParticipantId !==
                  this.props.currentParticipantUniqueId)
                && <label className="title mx-0"> - </label>
              }
              {
                (this.props.screenSharePresenterName && !this.state.isZoomOut) &&
                <span className="title">
                  {getMessage("PRESENTING", { userName: this.props.screenSharePresenterName })}
                </span>
              }
            </div>
            <div className="rightHeading d-flex">
              {this.props.screenSharingParticipantId ===
                this.props.currentParticipantUniqueId && (
                  <>
                    <CardImg
                      src={ic_video_close}
                      className="iconScreenShareClose cursorPointer"
                      id="stopScreenShare"
                      onClick={() => this.onOpenCloseEndScreenshareConfirmationModal(true)}
                    />
                    <CustomTooltip tooltipText={getMessage("STOP_SCREEN_SHARE")} tooltipId="stopScreenShare" />
                  </>
                               
              )}
              {isMobileOrIpadPortrait() 
                ? null 
                : this.shouldShowZoomIcon() &&
                <>
                  <CardImg
                    src={
                      !this.state.isZoomOut
                        ? ic_screeshare_zoomout
                        : ic_screeshare_zoom
                    }
                    id="zoomInZoomOutScreenShare"
                    className={this.state.streaming
                      ? "iconToggle cursorPointer"
                      : "iconToggle cursorPointer disabledIconOpacity cursorDisabled"}
                    onClick={() => this.state.streaming &&
                      this.toggleScreenSharingState()}
                  />
                  <CustomTooltip tooltipText={!this.state.isZoomOut ? getMessage("ZOOMOUT") : getMessage("ZOOMIN")} tooltipId="zoomInZoomOutScreenShare" />
                </>
                
              }
              {this.props.userRole === USER_ROLES.AGENT &&
                this.props.screenSharingParticipantId !== this.props.uniqueId &&
                !this.state.isZoomOut &&
                this.props.isScreenShareDownloadAllowed && (
                <a id="screenShareDownload">
                  <CardImg
                    src={ic_screenshare_download}
                    id="downloadScreenshare"
                    className="iconToggle cursorPointer"
                    onClick={this.download}
                  />
                  <CustomTooltip tooltipText={getMessage("DOWNLOAD")} tooltipId="downloadScreenshare" />
                </a>
              )}
              {window._env_.REACT_APP_SHOW_BITRATE == "true" &&
                !isIE &&
                this.state.showBitRate &&
                <BitRateTile
                  module={Modules.SCREENSHARE}
                  mediaType={mediaType.VIDEO}
                  participantUniqueID={this.props.screenSharingParticipantId}
                  uniqueId={this.props.uniqueId}
                  wsocketPeer={wsocket.screenShareRtcPeer}
                  hide={this.state.isZoomOut && true}
                />
              }
            </div>
          </div>
          <div className="perfectCenter screenShareContainer" id="screenShareContainer">
            {
              this.state.showSpinner &&
              <ComponentSpinner
                componentId={this.props.screenShareId}
                loaderSize={this.state.loaderSize + 'px'} />
            }
            {this.state.showButton ? (
              <div className="screenSharePerfectCenter">
                <Button
                  className="sendBtn startScreenShareBtn"
                  onClick={() => {
                    this.invokeGetDisplayMedia();
                    this.setState({ showButton: false }, () => {
                      this.toggleScreenSharingLayout(false);
                      let mediaElement = document.getElementById(this.props.screenShareId);
                      mediaElement.oncanplay = () => {
                        this.setState({
                          streaming: true
                        });
                      }
                    });
                  }}
                >
                  {getMessage("START_SCREEN_SHARE")}
                </Button>
              </div>
            ) : (
              <video
                id={this.props.screenShareId}
                width="100%"
                className="screenShareVideo"
                muted={true}
                autoPlay
                playsInline
              />
            )}
          </div>

          {this.props.screenSharingParticipantId ===
            this.props.currentParticipantUniqueId && !this.state.isZoomOut &&
            !this.state.showButton && (
            <div className="screenBeingSharedBtnDiv screenSharePerfectCenter">
              <Button
                className="sendBtn startScreenShareBtn shareScreenbtn"
                disabled>
                {getMessage("SCREEN_BEING_SHARED")}
              </Button>
            </div>)
          }
        </Card>
      </div>
    );
  }
}
