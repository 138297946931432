import * as log from "loglevel";
import { ACTIONS, CO_BROWSE_SESSION_STATUS } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";
log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.CO_BROWSE._PERMISSION_REQUEST:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_REQUEST,
        value: true
      });
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
        uniqueId: store.getState().RoomMetaDataReducer.roomMetaData.presenterId,
        value: true
      });
      break;

    case socketMessage.subCategories.CO_BROWSE._PERMISSION_REPLY:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_REPLY,
        value: true
      });
      if (data && data.uniqueId && !data.isGranted) {
        store.dispatch({
          type: ACTIONS.CO_BROWSE.CO_BROWSE_PERMISSION_DENIDED,
          value: data.uniqueId
        });
      } else if(data && data.uniqueId && data.isGranted) {
        store.dispatch({
          type: ACTIONS.CO_BROWSE.CO_BROWSE_JOIN_SESSION,
          value: data.uniqueId
        });
      }
      break;

    case socketMessage.subCategories.CO_BROWSE._STOP_CO_BROWSE_SESSION:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.SET_CO_BROWSE_SESSION_STATUS,
        value: CO_BROWSE_SESSION_STATUS.CO_BROWSE_STOPPED
      });
      break;
    
    case socketMessage.subCategories.CO_BROWSE._PARTICIPANT_LEFT:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_LEAVE_SESSION,
        value: data.uniqueId
      });
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
        uniqueId: data.uniqueId,
        value: false
      });
      break;
    
    case socketMessage.subCategories.CO_BROWSE._PARTICIPANT_ARRIVED:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
        uniqueId: data.uniqueId,
        value: true
      });
      break;
    
    case socketMessage.subCategories.CO_BROWSE._CHANGE_CO_BROWSE_URL:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.SET_TARGET_URL,
        value: data.coBrowseURL
      });
      break;
    
    case socketMessage.subCategories.CO_BROWSE._CO_BROWSE_STARTED:
      store.dispatch({
        type: ACTIONS.CO_BROWSE.SET_CO_BROWSE_SESSION_STATUS,
        value: CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED,
      });
      break;
  }
};
