import React, { Component } from "react";
import {
  Col,
  Input,
  Button,
  InputGroup
} from "reactstrap";
import * as rxjs from "rxjs";
import { debounceTime } from "rxjs/operators";
import { isIE } from "react-device-detect";

import { getMessage, changeLanguage } from "CONFIG/i18n";
import { ERROR, USER_ROLES, KEY_CODE_ENTER, INFO_FIELD_MAX_LENGTH } from "UTILS/Constants";
import {
  checkValidEmailsOrPhone,
  getDeviceType,
  getStringWithoutExtraSpaces,
  isRealwearDevice
} from "UTILS/Utility";
import "./QRCodeLanding.less";
class QRCodeLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: this.props.selectedLanguage, 
      userName: "",
      userEmail:"",
      userPhone:"",
      errorMessage: "",
      info: "",
      isLandscape: false,
      isRealwearDevice: isRealwearDevice(),
      isCallStarted: false
    };
    this.startSessionObserver = new rxjs.Subject();
  }
  
  handleLanguageChange = event => {
    let selectedLanguage = event.target.value;
    changeLanguage(selectedLanguage);
    this.setState({ selectedLanguage: selectedLanguage });
    this.props.handleChangeLanguage(selectedLanguage);
  };
  componentDidMount() {
    /*
    * changed user QR code laguage to selected language
    */
    changeLanguage(this.state.selectedLanguage)
    this.props.handleChangeLanguage(this.state.selectedLanguage);

    this.startSessionObserver.pipe(debounceTime(500)).subscribe(fn => {
      if (typeof fn === "function") fn();
    });

    this.state.isRealwearDevice && this.voiceCommandStartSessionHandler();
    
    // WAAG-5672
    if (this.state.isRealwearDevice && window.INJECTED_USERNAME && window.INJECTED_PHONE) {
      setTimeout(() => {
        this.setState({
          userName: window.INJECTED_USERNAME,
          info: window.INJECTED_PHONE
        }, () => this.handleStartClick())
      }, window._env_.REACT_APP_REALWEAR_QR_CODE_LANDING_PAGE_AUTO_SUBMIT_WAITING_TIME);
    }
  }

  disableStartBtn = (value) => {
    let startBtn = document.getElementById("startQRSessionBtn");
    if (startBtn)
      startBtn.disabled = value;
  }

  componentWillUnmount() {
    this.disableStartBtn(false);
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_START")
  }

  handleStartClick = event => {
    if (this.state.isCallStarted) return; // WAAG-5709

    const info = getStringWithoutExtraSpaces(this.state.info);
    if (this.state.userEmail && checkValidEmailsOrPhone("email", [this.state.userEmail])) {
      this.setState({ errorMessage: "INVALID_EMAIL"});
      this.disableStartBtn(false);
      return; 
    }
    if (!this.state.isRealwearDevice && this.state.userPhone && checkValidEmailsOrPhone("phone", [this.state.userPhone])) {
      this.setState({ errorMessage: "VALID_PHONE"}); this.disableStartBtn(false);
      return;
    }
    if (!this.state.isRealwearDevice && !this.state.userEmail && !this.state.userPhone) {
      this.setState({ errorMessage: "VALID_EMAIL_PHONE"}); this.disableStartBtn(false);
      return;
    }
    if (this.state.isRealwearDevice && !info) {
      this.setState({ errorMessage: "INVALID_INFO"});
      this.disableStartBtn(false);
      return; 
    }
    if (this.state.isRealwearDevice && info && info.length > INFO_FIELD_MAX_LENGTH) {
      this.setState({ errorMessage: "INVALID_INFO_LENGTH"});
      this.disableStartBtn(false);
      return; 
    }
    this.props.updateProfile({
      first_name: this.state.userName,
      last_name: ""
    });
    
    // WAAG-5709
    this.setState({ isCallStarted: true });

    this.props.handleStartClick(
      this.errorCallBack,
      getStringWithoutExtraSpaces(this.state.userName),
      this.state.userEmail ? this.state.userEmail : undefined,
      this.state.userPhone && !this.state.isRealwearDevice ? this.state.userPhone : undefined,
      info ? info : undefined,
      getDeviceType()
    );
  };

  errorCallBack = (errorCode) => {
    let errorMessage = errorCode;
    if (errorCode === ERROR.SERVER_UNREACHABLE_ERROR && this.props.role === USER_ROLES.USER) {
      errorMessage = "SERVER_UNREACHABLE_ERROR_USER";
    } 
    if (errorMessage) {
      this.setState({
        errorMessage
      });
    }
    this.setState({ isCallStarted: false });
    this.disableStartBtn(false);
  }

  componentDidUpdate(prevProp) {
    // if (this.props.selectedLanguage !== prevProp.selectedLanguage) {
    //   this.setState({ selectedLanguage: this.props.selectedLanguage });
    // }

    if (this.state.isRealwearDevice && prevProp.selectedLanguage !== this.props.selectedLanguage) {
      this.voiceCommandStartSessionHandler()
    }

    if (this.props.errorMessage !== prevProp.errorMessage && this.props.errorMessage) {
      this.setState({ errorMessage: (this.props.errorMessage == ERROR.LICENSE_KEY_EXPIRED) ? ERROR.QR_CODE_EXPIRED : this.props.errorMessage })
    }
  }

  handleInputChange = event => {
    this.setState({ errorMessage: ""});
    this.setState({ [event.target.name]: event.target.name !== "userName" ? event.target.value.trim() : event.target.value });
    if(this.state.isRealwearDevice && event.target.name === "userPhone") {
      this.setState({ info: event.target.value});
    }
    this.disableStartBtn(false);
  };

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleStartClick();
    }
  };
    
  voiceCommandStartSessionHandler = () => {  
    const startSession = {
      voiceCommand: "VOICE_COMMANDS_START",
      action: () => {
        this.handleStartClick();
      }
    }  
    this.props.handleVoiceCommand(startSession)
  }

  render() {
    return (
      <div className={`flexDirectionColumn qr-code-landing ${isRealwearDevice() ? "real-wear-device" : ""}`}>
        <div className="logo-div">
          <div className="logo-image">
            {this.props.isLogoLoading ? (
              getMessage("LOADING")
            ) : this.props.logo ? (
              <img src={this.props.logo} />
            ) : null}
          </div>
        </div>
        <div className="px-3 pt-3 input-card">
          <Col lg xl="12" className="inputForm flexDirectionColumn pt-4">
            <InputGroup className="pb-3">
              <label className="label-style pr-2 d-flex">
                {getMessage("NAME")}
                {this.props.allowAnonymousParticipants === false &&
                  <span className="asterisk">*</span>
                }
              </label>
              <Input
                type="text"
                onChange={this.handleInputChange}
                name="userName"
                placeholder={getMessage("ENTER_NAME_PLACEHOLDER")}
                aria-label="Enter name"
                className="inputFormControl"
                value={this.state.userName}
                maxLength={50}
                onKeyPress={this.enterPressed}
                autoFocus={!isIE}
              />
            </InputGroup>
            <InputGroup className="d-flex alignItemsCenter justifyContentFlexCenter">
              <label className="label-style pr-2 d-flex">
                {getMessage("EMAIL")}
              </label>
              <Input
                type="text"
                onChange={this.handleInputChange}
                name="userEmail"
                placeholder={getMessage("ENTER_EMAIL_PLACEHOLDER")}
                aria-label="Enter email"
                className="inputFormControl"
                value={this.state.userEmail}
                maxLength={50}
                onKeyPress={this.enterPressed}
              />
            </InputGroup>
            {this.state.isRealwearDevice ?
              <InputGroup>
                <label className="label-style pr-2 d-flex pt-3">
                  {getMessage("INFO")}
                  <span className="asterisk">*</span>
                </label>
                <Input
                  type="text"
                  onChange={this.handleInputChange}
                  name="userPhone"
                  placeholder={getMessage("ENTER_INFO_PLACEHOLDER")}
                  aria-label="Enter info"
                  className="inputFormControl mt-3"
                  value={this.state.userPhone}
                  maxLength={INFO_FIELD_MAX_LENGTH}
                  onKeyPress={this.enterPressed}
                />
              </InputGroup>
              :
              <>
                <label className="mt-2">{getMessage("AND_OR")}</label>
                <InputGroup>
                  <label className="label-style pr-2 d-flex">
                    {getMessage("PHONE_NUMBER")}
                  </label>
                  <Input
                    type="text"
                    onChange={this.handleInputChange}
                    name="userPhone"
                    placeholder={getMessage("ENTER_PHONE_NUMBER_PLACEHOLDER")}
                    aria-label="Enter phone number"
                    className="inputFormControl"
                    value={this.state.userPhone}
                    maxLength={16}
                    onKeyPress={this.enterPressed}
                  />
                </InputGroup>
              </>
            }
            <div className="py-3">
              <Button
                id="startQRSessionBtn"
                className="customBtn"
                onClick={() => {
                  let startBtn = document.getElementById("startQRSessionBtn");
                  if (startBtn) startBtn.disabled = true;
                  this.startSessionObserver.next(this.handleStartClick);
                }}
              >
                {getMessage("START")}
              </Button>
              {this.state.errorMessage && (
                <div className="errors d-flex">
                  {getMessage(this.state.errorMessage)}
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default QRCodeLanding;
