import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMessage } from "CONFIG/i18n";
import { CO_BROWSE_LAYOUT } from "UTILS/Constants";
import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

//websoket
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { socketMessage, webRtcMessageId, mediaType } from "WEBSOCKET/constants";
import { ERROR } from "UTILS/Constants";

//components
import TargetSiteSelectionModal from "./TargetSiteSelectionModal";
import CustomerCoBrowse from "./CustomerCoBrowse";
import Spinner from "COMPONENTS/Spinner/Spinner";

//Actions 
import { customerCoBrowseAction } from "CONFIG/ActionFactory";

//selector
import {
  getAdmittedParticipants, getParticipantLeftCustomerCoBrowse, getParticipantJoinedCustomerCoBrowse,
  getParticipantDeniedCustomerCoBrowse, getRoomId, getShareUrl, selectPresenter, getAgentEmailFromParticipants,
  selectCoBrowseBaseUrl, getShowCustomerCoBrowse
} from "./CustomerCoBrowseSelector"
import { selectIsSnapshotStarted } from "REDUX/Selector/SessionSelector";
class CustomerCoBrowseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false,
      cobrowseDetails: {
        allowCustomUrl:false,
        allowCustomUrl:[],
        targetSites:[],
        coBrowseType: ""
      }
    }
  }

  componentDidMount() {
    this.getCobrowseTargetSites();
  }

  componentDidUpdate(prevProps, prevState) {
    if( prevState.cobrowseDetails.targetSites !== this.state.cobrowseDetails.targetSites) {
      this.setState({cobrowseDetails: this.state.cobrowseDetails})
    }
    if (this.props.participantLeftCustomerCoBrowseUniqueId &&
      this.props.participantLeftCustomerCoBrowseUniqueId !== this.props.uniqueId &&
      prevProps.participantLeftCustomerCoBrowseUniqueId !== this.props.participantLeftCustomerCoBrowseUniqueId) {
      toastr.info("LEFT_CO_BROWSE", [{ key: "userName", value: this.getParticipantName(this.props.participantLeftCustomerCoBrowseUniqueId) }],{
        toastId: "LEFT_CO_BROWSE_" + this.props.participantLeftCustomerCoBrowseUniqueId
      })
    }
    if (this.props.participantJoinedCustomerCoBrowseUniqueId &&
      this.props.participantJoinedCustomerCoBrowseUniqueId !== this.props.uniqueId &&
      prevProps.participantJoinedCustomerCoBrowseUniqueId !== this.props.participantJoinedCustomerCoBrowseUniqueId) {
      toastr.info("JOINED_CO_BROWSE_SESSION", [{ key: "userName", value: this.getParticipantName(this.props.participantJoinedCustomerCoBrowseUniqueId) }], {
        toastId: "JOINED_CO_BROWSE_SESSION_" + this.props.participantJoinedCustomerCoBrowseUniqueId
      })
    }
    if (this.props.participantDeniedCustomerCoBrowseUniqueId &&
      prevProps.participantDeniedCustomerCoBrowseUniqueId !== this.props.participantDeniedCustomerCoBrowseUniqueId) {
      toastr.info("CO_BROWSE_PERMISSION_DENIED", [{ key: "userName", value: this.getParticipantName(this.props.participantDeniedCustomerCoBrowseUniqueId) }],
        { toastId: "CO_BROWSE_PERMISSION_DENIED_" + this.props.participantDeniedCustomerCoBrowseUniqueId })
    }
  }

  getCobrowseTargetSites = () => {
    customerCoBrowseAction.getCobrowseTargetSites(this.props.sessionKey).then(
      (response) => {
        response.data.data &&
        this.setState({
          cobrowseDetails: response.data.data
        });
      },
      (error) => {

      }
    )
  }

  getParticipantName =(uniqueId)=>{
    if(uniqueId) {
      let participant = this.props.admittedParticipants.filter(participant => participant.uniqueId === uniqueId)[0];
      return participant ? participant.userName : null;
    }
    else  
      return null
  }

  handleBookCoBrowseSession =(targetURL)=> {
    this.setState({spinnerVisibility: true})
    let requestData = {
      targetURL,
      originId_1: window._env_.CUSTOMER_CO_BROWSE_ORIGIN,
      originId_2: this.props.customerUriIdentifier,
      originId_3: this.props.sessionKey,
      originId_4: this.props.sessionName,
      originId_5: this.props.email,
      layoutCode: CO_BROWSE_LAYOUT.mobileVertical,
      token: this.props.token
    }
    customerCoBrowseAction.handleBookCoBrowseSession(requestData).then(
      (response)=>{
        this.setState({spinnerVisibility: false})
        const message = {
          data : {
            category: socketMessage.category.CUSTOMER_CO_BROWSE,
            action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.PERMISSION_REQUEST,
            sessionKey: this.props.sessionKey,
            roomId: response.data.roomId,
            shareUrl: response.data.shareUrl,   
            layoutDetails: CO_BROWSE_LAYOUT.mobileVertical
          }
        }
        this.props.sendWebsocketMessage(socketMessage.events.MESSAGE,message);
        customerCoBrowseAction.updateParticipantCoBrowseStatus(this.props.uniqueId,true);   
        customerCoBrowseAction.showHideCustomerCoBrowse(true);
        customerCoBrowseAction.showTargetSiteSelectionModalAction(false);
      },
      (error)=>{
        this.setState({spinnerVisibility: false})
        console.error(error)
        toastr.error(error.code);
        customerCoBrowseAction.showTargetSiteSelectionModalAction(false);
      }
    )
  }

  isPresenter =()=>{
    return this.props.uniqueId === this.props.presenterId
  }

  handleCustomerCoBrowseEndSessionClick = () => {
    const message = {
      data: {
        category: socketMessage.category.CUSTOMER_CO_BROWSE,
        action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.END_SESSION,
        sessionKey: this.props.sessionKey,
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  handleCustomerCoBrowseLeaveSessionClick = () => {
    const message = {
      data: {
        category: socketMessage.category.CUSTOMER_CO_BROWSE,
        action: socketMessage.subCategories.CUSTOMER_CO_BROWSE.LEAVE_SESSION,
        sessionKey: this.props.sessionKey,
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  } 

  render(){
    return(
      <>
      <Spinner showSpinner={this.state.spinnerVisibility} />
      {
        this.props.showTargetSiteSelectionModal ?
          <TargetSiteSelectionModal
            show={this.props.showTargetSiteSelectionModal}
            handleBookCoBrowseSession={this.handleBookCoBrowseSession}
            handleTargetSiteSelectionModalToggle={this.props.handleTargetSiteSelectionModalToggle}
            cobrowseDetails={this.state.cobrowseDetails}
            getCobrowseTargetSites={this.getCobrowseTargetSites}
            isSnapshotStarted={this.props.isSnapshotStarted}
          /> :
          <CustomerCoBrowse
            roomId={this.props.roomId}
            shareUrl={this.props.shareUrl}
            userName={this.getParticipantName(this.props.uniqueId)}
            role={this.props.role}
            token={this.props.token}
            isPresenter={this.isPresenter}
            coBrowseBaseUrl={this.props.coBrowseBaseUrl}          
            handleCustomerCoBrowseEndSessionClick={this.handleCustomerCoBrowseEndSessionClick}
            handleCustomerCoBrowseLeaveSessionClick={this.handleCustomerCoBrowseLeaveSessionClick}
            uniqueId={this.props.uniqueId}
            sessionKey={this.props.sessionKey}
            endCustomerCBSession={this.props.endCustomerCBSession}
            showCustomerCoBrowse ={this.props.showCustomerCoBrowse}
            componentInFocus={this.props.componentInFocus}
            setComponentInFocus={this.props.setComponentInFocus}
            isLandscape={this.props.isLandscape}
          />
      }
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.SessionReducer.sessionDetails.token,
    customerUriIdentifier: state.OrganisationReducer.customerData.uri_identifier,
    sessionName: state.SessionReducer.sessionDetails.sessionName,
    sessionKey: state.SessionReducer.sessionDetails.sessionKey,
    email: getAgentEmailFromParticipants(state),
    uniqueId: state.SessionReducer.uniqueId,
    role: state.UserReducer.userDetails.role,
    participantLeftCustomerCoBrowseUniqueId: getParticipantLeftCustomerCoBrowse(state),
    participantJoinedCustomerCoBrowseUniqueId: getParticipantJoinedCustomerCoBrowse(state),
    participantDeniedCustomerCoBrowseUniqueId: getParticipantDeniedCustomerCoBrowse(state),
    admittedParticipants: getAdmittedParticipants(state),
    roomId: getRoomId(state),
    shareUrl: getShareUrl(state),
    presenterId: selectPresenter(state),
    coBrowseBaseUrl: selectCoBrowseBaseUrl(state),
    showCustomerCoBrowse: getShowCustomerCoBrowse(state),
    isSnapshotStarted: selectIsSnapshotStarted(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    sendWebsocketMessage: sendWebsocketMessage,
  }, dispatch);
};

export default withErrorHandler(connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerCoBrowseContainer));