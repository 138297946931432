import { USER_ADMISSION_STATUS, PARTICIPANT_STATUS, Modules } from "UTILS/Constants";
import { getUserNameForSelectedLanguage } from "UTILS/Utility";

export const getModules = state => {
  // Fixed WAAG-1010 - Sometimes upload PDF buttons disappears at Agents end by referring modules from localstorage
  return ((state.SessionReducer.sessionDetails && state.SessionReducer.sessionDetails.modules && state.SessionReducer.sessionDetails.modules.length && state.SessionReducer.sessionDetails.modules)
    || (state.UserReducer.userDetails && state.UserReducer.userDetails.modules)
    || (JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)) && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).modules));
};

export const selectAllowedDownloads = state => {
  return ((state.UserReducer.userDetails.subModules && state.UserReducer.userDetails.subModules["ALLOW_DOWNLOAD"])
  || (state.SessionReducer.sessionDetails.subModules && state.SessionReducer.sessionDetails.subModules["ALLOW_DOWNLOAD"])
  || (JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token))
  && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).subModules
  && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).subModules["ALLOW_DOWNLOAD"]));
};

export const getIsOrganizer = state => {
  return (state.SessionReducer && state.SessionReducer.isOrganizer
    || (JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)) && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).isOrganizer));
};

export const getAllParticipants = state => {
  return state.ParticipantReducer.participants;
};

export const getAdmittedParticipants = state => {
  return state.ParticipantReducer.participants 
    && state.ParticipantReducer.participants.filter(participant => 
      participant.admissionStatus == USER_ADMISSION_STATUS.ADMITTED
      && participant.status === PARTICIPANT_STATUS.ACTIVE);
};

export const getWebRtcPermissionRequest = state => {
  return state.SessionReducer.webRtcPermissionRequest;
};
export const getWebRtcPermissionReply = state => {
  return state.SessionReducer.webRtcPermissionReply;
};

export const getHeaderIcons = state => {
  return state.SessionReducer.headerIcons;
};
export const selectSessionEndedFlag = state => {
  return state.SessionReducer.isSessionEnded;
};

export const selectLatestUpdatedParticipant = state => {
  return state.ParticipantReducer.latestUpdatedParticipant;
};
export const getEvictedParticipantPermissionRequest = state => {
  return state.ParticipantReducer.isUserEvicted;
};
export const getEvictedParticipantDetails = state => {
  return state.ParticipantReducer.evictedParticipantDetails;
};
export const selectWebRtcPermissionReplyStatus = state => {
  return state.TostMessageReducer.webRtcPermissionReplyStatus;
};
export const getSnpshotPermissionRequest = state => {
  return state.SessionReducer.snapshotPermissionRequest;
};
export const getSnapshotPermissionReply = state => {
  return state.SessionReducer.snapshotPermissionReply;
};
export const selectPresenter = state => {
  return state.RoomMetaDataReducer.roomMetaData.presenterId;
};

export const selectLocation = state => {
  return state.RoomMetaDataReducer.roomMetaData.isLocationPopup;
};

export const selectLocationSharing = state => {
  return state.RoomMetaDataReducer.roomMetaData.isLocationSharing;
};

export const getKmsStatus = state => {
  return state.ErrorReducer.kmsConnectionStatus;
}

export const getShowKmsStatus = state => {
  return state.ErrorReducer.showKmsConnectionStatus;
}

export const getCallStatus = state => {
  return state.SessionReducer.isCallEnded
}

export const getSnapshotCameraPermissionflag = state => {
  return state.SessionReducer.isCameraPermissionGranted
}
export const getSnapshotCameraBusyflag = state => {
  return state.SessionReducer.isCameraBusy
}

export const getSubLoggingLevels = state =>{
  const data = state.OrganisationReducer.customerData.subLoggingLevels;
  // return a flattened array
  return data && Object.keys(data).reduce((acc,val)=> acc.concat(data[val]), [])
}

export const getLoggingLevel = state =>{
  const data = state.OrganisationReducer.customerData;
  return data && data.loggingLevel;
}

export const getStorageDetails = state =>{
  const data = state.OrganisationReducer.customerData;
  return data && data.storageDetails;
}
export const getSSODetails= state =>{
  const data = state.OrganisationReducer.customerData;
  return data && data.SSODetails;
}

export const getQRCodeSettings = state => {
  return state.OrganisationReducer.customerData?.qrCodeSettings;
}

export const isDownloadModuleSelected = state => {
  let modules = getModules(state);
  return modules && modules.indexOf("ALLOW_DOWNLOAD") != -1;
}

export const isVideoOrSnapshotSelected = state =>{
  let modules = getModules(state);
  return modules && ( modules.indexOf(Modules.AUDIO_VIDEO) !== -1 || 
    modules.indexOf(Modules.SNAPSHOT) !== -1 )
}

export const isScreenShareSelected = state =>{
  let modules = getModules(state);
  return modules && modules.indexOf(Modules.SCREENSHARE) != -1;
}

export const getIsSSOEnabled = state => {
  return state.UserReducer.ssoDetails.isSSOEnabled
}

//only for admin and super admin
export const getURIIdentifier = state => {
  const data = state.OrganisationReducer.customerData;
  return data && data.uri_identifier;
}

//only for admin and super admin
export const getIsPasscodeEnabled = state => {
  const data = state.OrganisationReducer.customerData;
  return data && data.is_passcode_enabled;
}

export const getIsSystemPassword = state =>{
  return state.UserReducer.userDetails.isSystemPassword
}

export const getBandwidthSettings = state =>{
  return state.OrganisationReducer.customerData.bandwidthSettings;
}

export const getConcurrentSessionData = state =>{
  let concurrentSessionData = state.OrganisationReducer.customerData;
  return concurrentSessionData;
}

export const getIsOrganizationTemplateEnabled = state => {
  return (state.SessionReducer && state.SessionReducer.sessionDetails && state.SessionReducer.sessionDetails.isOrganizationTemplatesEnabled)
}

export const selectUserName = state => {
  const userDetails = state.UserReducer.userDetails;
  if (userDetails) {
    return getUserNameForSelectedLanguage(state.LanguageReducer.language,
      `${userDetails.firstName} ${userDetails.lastName}`);
  }
  return null;
};

export const getCustomerId = state => {
  return state.UserReducer.userDetails.customerId;
};

export const userSessionName = (state) =>
  state.SessionReducer.sessionDetails.sessionName;

export const getMediaDeviceDeniedDetails = (state) => 
  state.TostMessageReducer.mediaDeviceDeniedDetails;

export const getQRCodeWaitingTime = (state) => 
  state.OrganisationReducer.customerSettings.qrCodeWaitingTime;