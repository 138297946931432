import React, { Fragment } from "react";
import { func } from "prop-types";
import {
  Button,
  Input,
  Card,
  CardBody,
  Table,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  DropdownItem
} from "reactstrap";
import NumericInput from 'react-numeric-input';
import Spinner from "COMPONENTS/Spinner/Spinner";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import TimezoneDropdown from "COMPONENTS/SuperAdmin/TimezoneDropdown/TimezoneDropdown";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";

//Daterangepicker
import DateTime from 'react-datetime';

import moment from 'moment';

//Constant
import { modulesAvailableConstant, availableFeatures, cobrowseTypes , modulesToDisplay, LOG_TYPE, STORAGE_LOCATION, BUSINESS_MODELS, LANGUAGE, FREQUENTLY_USED_TIMEZONES, MAX_QR_LIMIT } from "../../../Utils/Constants";
import { getTomorrowDate, isValidDate } from "UTILS/DateUtils";

// Images
import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_pdf from "ASSETS/Images/pdf/ic_pdf.svg";
import ic_camera from "ASSETS/Images/ic_camera_header.svg";
import ic_admin_co_browse from "ASSETS/Images/ic_admin_co_browse.svg"
import ic_QR_code from "ASSETS/Images/ic_QR_code.svg";
import download_img from "ASSETS/Images/ic_download_allow.svg";
import ic_error from "ASSETS/Images/ic_error.svg";
import ic_edit from "ASSETS/Images/ic_edit.svg";
import ic_add_storage from "ASSETS/Images/ic_add_storage.svg";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation_small.svg";

//actions
// Strings
import { getMessage } from "CONFIG/i18n";
import {
  validateUserName,
  validateNumber,
  validateMobileNumber 
} from "UTILS/regexValidations";
import { isAlphaNumeric ,hasWhiteSpace, getPhoneNumberWithCountryCode } from "UTILS/Utility";
import {getStringWithoutExtraSpaces} from "../../../Utils/Utility"
import { DATE_FORMAT, ERROR } from "UTILS/Constants";

import "../Organisations/AddOrganization.less";
import "../../App/App.less";
import "./../Organisations/Organisations.less";
import "react-datetime/css/react-datetime.css";

class AddOrganisation extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };

  constructor(props) {
    super(props);
    this.state = {
      OrgDetail: {
        name: "",
        seats: "",
        availableModules: [],
        firstName: "",
        lastName: "",
        username: "",
        phoneNumber: "",
        uriIdentifier: null,
        defaultLanguage: LANGUAGE.EN.name,
        timezone: FREQUENTLY_USED_TIMEZONES[0].value,
        licenseExpiryDate: getTomorrowDate(),
        currentDate: "",
        qrLimit: "",
        loggingLevel: LOG_TYPE.BASIC,
        businessModel: BUSINESS_MODELS.NUMBER_OF_SEATS,
        maxConcurrentConnections: "",
        cobrowseType: cobrowseTypes.CUSTOMER_CO_BROWSE,
      },
      isSSOEnabled: false,
      role: "ADMIN",
      errorMessage: "",
      storageChanged: false,
      currentBucket: "",
      storageDetails: {
        location: STORAGE_LOCATION.CUSTOMER,
        type: "S3",
        list: []
      },
      focused: null,
      isPasscodeEnabled: false,
      isOrganizationTemplatesEnabled: false,
      showSystemCapacity: false,
      isRingCentralEnabled: false,
      isSnapshotScanCodeEnabled: false,
      allowUpload: false,
      errorCode: null,
      systemCapacity : this.getSystemCapacity()
    };
    this.tooltipBoundaryElement = null;
    this.coBrowseOptions = [
      {
        name: getMessage("CUSTOMER_CO_BROWSE"),
        value: cobrowseTypes.CUSTOMER_CO_BROWSE,
      },
      {
        name: getMessage("WEB_CO_BROWSE"),
        value: cobrowseTypes.WEB_CO_BROWSE,
      },
    ];
    this.businessModelsOptions = [
      {
        name: getMessage("NO_OF_SEATS"),
        value: BUSINESS_MODELS.NUMBER_OF_SEATS,
      },
      {
        name: getMessage("MAX_CONCURRENT_CONNECTIONS"),
        value: BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS,
      },
    ];
    this.defaultLanguageOptions = [
      {
        name: getMessage("ENGLISH"),
        value: LANGUAGE.EN.name,
      },
      {
        name: getMessage("JAPANESE"),
        value: LANGUAGE.JA.name,
      },
    ];
    this.loggingLevelOptions = [
      {
        name: getMessage("BASIC"),
        value: LOG_TYPE.BASIC,
      },
      {
        name: getMessage("ADVANCED"),
        value: LOG_TYPE.ADVANCED,
      },
    ];
    this.storageOptions = [
      {
        name: getMessage("LOOOKIT_GLOBAL"),
        value: STORAGE_LOCATION.LOOOKIT_GLOBAL,
      },
      {
        name: getMessage("LOOOKIT_CUSTOM"),
        value: STORAGE_LOCATION.LOOOKIT_CUSTOM,
      },
      {
        name: getMessage("CUSTOMER"),
        value: STORAGE_LOCATION.CUSTOMER,
      },
    ];
  }

  componentDidMount() {
    this.selectDefaultStorage(-1);
    this.resetAddOrgState();
    this.getTooltipBoundaryElement();
  }

  componentDidUpdate(preProps) {
    if (this.props.errorMessage !== preProps.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
    if (this.props.sessionsCapacity.systemMaxCapacity != preProps.sessionsCapacity.systemMaxCapacity ||
      this.props.sessionsCapacity.usedCapacity != preProps.sessionsCapacity.usedCapacity) {
      const systemCapacity = this.getSystemCapacity();
      this.setState({
        systemCapacity : systemCapacity
      })
    }
  }
  
  /**
   * Gets Boundary element for info icon tooltip.
   * Tooltip will be rendered inside tooltipBoundaryElement
   */
  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("addOrganisationModal")[0];
  }

  handleAddClick = () => {
    if (!this.isValidData()) {
      return;
    }
    let qrLimit = this.state.OrgDetail.availableModules.indexOf("QR_CODE") != -1
      && this.state.OrgDetail.qrLimit && this.state.OrgDetail.qrLimit != ""
      ? this.state.OrgDetail.qrLimit : undefined;
    let data = Object.assign(
      {},
      {
        customerName: getStringWithoutExtraSpaces(this.state.OrgDetail.name),
        customerSeats: this.state.OrgDetail.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS 
          ? this.state.OrgDetail.seats 
          : undefined,
        maxConcurrentSessions: this.state.OrgDetail.businessModel === BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS
          ? this.state.OrgDetail.maxConcurrentConnections
          : undefined,
        businessModel: this.state.OrgDetail.businessModel,
        customerAvailableModules: this.state.OrgDetail.availableModules,
        customerLicenseExpiryDate: this.state.OrgDetail.licenseExpiryDate.format("YYYY-MM-DD"),
        customerQrLimit: qrLimit,
        customerLoggingLevel: this.state.OrgDetail.loggingLevel,
        adminFirstName: getStringWithoutExtraSpaces(this.state.OrgDetail.firstName),
        adminLastName: getStringWithoutExtraSpaces(this.state.OrgDetail.lastName),
        adminUsername: this.state.OrgDetail.username,
        adminPhone: this.state.OrgDetail.phoneNumber ? getPhoneNumberWithCountryCode(this.state.OrgDetail.phoneNumber): undefined,
        storageDetails: this.state.storageDetails,
        isSSOEnabled:this.state.isSSOEnabled,
        language: this.props.language,
        isPasscodeEnabled: this.state.isPasscodeEnabled,
        isOrganizationTemplatesEnabled: (this.state.storageDetails.location == STORAGE_LOCATION.LOOOKIT_GLOBAL) ? false : this.state.isOrganizationTemplatesEnabled,
        uriIdentifier: this.state.OrgDetail.uriIdentifier,
        cobrowseType: this.state.OrgDetail.availableModules.includes(modulesAvailableConstant.CO_BROWSE) ? this.state.OrgDetail.cobrowseType : null,
        isRingCentralEnabled: this.state.isRingCentralEnabled,
        isSnapshotScanCodeEnabled: this.state.isSnapshotScanCodeEnabled,
        defaultLanguage: this.state.OrgDetail.defaultLanguage,
        timezone: this.state.OrgDetail.timezone,
        customerSettings: {
          chatgptEnabled: this.state.OrgDetail.availableModules.includes(modulesAvailableConstant.CHAT) ? this.state.isChatGPTEnabled : false,
          allowUpload: this.state.allowUpload,
        }
      }
    );
    this.props.handleAddOrganization(data, errorCode => {
      this.setState({
        errorCode: errorCode,
        errorMessage: getMessage(errorCode)
      });
    });
  };

  isValidData = () => {
    let OrgDetail = this.state.OrgDetail;
    let licenseExpiryDate = OrgDetail.licenseExpiryDate;

    if (!OrgDetail.name || !OrgDetail.name.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_ORG_NAME") });
      return false;
    }
    if (OrgDetail.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS && 
       (!OrgDetail.seats || OrgDetail.seats < 1 || !validateNumber(OrgDetail.seats)) ) {
      OrgDetail['seats'] = "";
      this.setState({
        errorMessage: getMessage("SEAT_ERROR_MSG"),
        OrgDetail: OrgDetail
      });
      return false;
    } else if (OrgDetail.businessModel === BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS &&
       (!OrgDetail.maxConcurrentConnections || OrgDetail.maxConcurrentConnections < 1 || !validateNumber(OrgDetail.maxConcurrentConnections)) ) {
      OrgDetail['maxConcurrentConnections'] = "";
      this.setState({
        errorMessage: getMessage("MAX_CONCURRENT_CONNECTIONS_ERROR_MSG"),
        OrgDetail: OrgDetail
      });
      return false;
    }
    if((this.state.isPasscodeEnabled || this.state.isSSOEnabled) 
      && _.isEmpty(OrgDetail.uriIdentifier)) {
      this.setState({ errorMessage: getMessage("EMPTY_URI_IDENTIFIER") });
      return false;
    }
    if (this.state.OrgDetail.uriIdentifier) {
      if (!isAlphaNumeric(this.state.OrgDetail.uriIdentifier)) {
        this.setState({ errorMessage: getMessage("SPECIAL_CHARACTERS_ERROR") });
        return false;
      }
    }
    if (this.state.OrgDetail.availableModules.indexOf("QR_CODE") !== -1
      && (OrgDetail.qrLimit <= 0 || OrgDetail.qrLimit > MAX_QR_LIMIT  || !OrgDetail.qrLimit || !validateNumber(OrgDetail.qrLimit))) {
      let OrgDetail = this.state.OrgDetail; 
      OrgDetail['qrLimit'] = "";
      this.setState({
        errorMessage: getMessage("QR_LIMIT_ERROR_MSG", { maxQRLimit:MAX_QR_LIMIT }),
        OrgDetail: OrgDetail
      });
      return false;
    }
    if (!licenseExpiryDate || !isValidDate(licenseExpiryDate) || moment(licenseExpiryDate).isSameOrBefore()) {
      this.setState({ errorMessage: getMessage("INVALID_DATE") });
      return false;
    }
    if (!OrgDetail.username || !OrgDetail.username.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
      return false;
    } else {
      if (!validateUserName(OrgDetail.username)) {
        this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
        return false;
      }
    }

    if(OrgDetail.phoneNumber.trim() && !validateMobileNumber(OrgDetail.phoneNumber)) {
      this.setState({ errorMessage: getMessage("VALID_PHONE") });
      return false;
    }

    if (!OrgDetail.firstName || !OrgDetail.firstName.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_FIRST_NAME") });
      return false;
    }
    if (!OrgDetail.lastName || !OrgDetail.lastName.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_LAST_NAME") });
      return false;
    }
    // if (this.state.OrgDetail.loggingLevel == LOG_TYPE.ADVANCED	
    //   && (this.state.storageDetails.location===STORAGE_LOCATION.LOOOKIT_CUSTOM && !this.state.isStorageSelected)) {	
    //   this.setState({ errorMessage: getMessage("INVALID_STORAGE_OPTION") });	
    //   return false;	
    // }
    return true;
  };

  handleCancelClick = () => {
    this.resetAddOrgState();
    this.props.handleCancelClick();
  };

  handleNumberInputChange = (name, value, maxLimit) => {
    let OrgDetail = this.state.OrgDetail;
    let ele = document.getElementsByName(name)[0];
    let inputVallength = ele.value.length;
    if(!Number.isInteger(value) || value > maxLimit) {
      OrgDetail[name] = this.state.OrgDetail[name];
      this.setState({
        OrgDetail: OrgDetail
      });
      return;
    }
    if (value !== 0) {
      OrgDetail[name] = value;
    } else {
      OrgDetail[name] = "";
    }

    this.setState({ OrgDetail: OrgDetail,
      errorMessage: "" ,
      showSystemCapacity : name == 'seats' || name == 'maxConcurrentConnections' ? true : false 
    }, () => {
      ele.setSelectionRange(inputVallength, inputVallength);
    });
  }

  handleInputChange = event => {
    let OrgDetail = { ...this.state.OrgDetail };
    if (event.target.name === "uriIdentifier" && event.target.value === "") {
      OrgDetail[event.target.name] = null;
    } else if (
      (event.target.name === "uriIdentifier" ||
        event.target.name === "phoneNumber") &&
      hasWhiteSpace(event.target.value)
    ) {
      OrgDetail[event.target.name] = event.target.value.trim();
    } else {
      OrgDetail[event.target.name] = event.target.value;
    }
    this.setState({ OrgDetail: OrgDetail, errorMessage: "" });
  };

  handleDateChange = (date) => {
    let stateObject = {...this.state};
    let OrgDetail = stateObject.OrgDetail;
    OrgDetail.licenseExpiryDate = date;
    this.setState({ OrgDetail: OrgDetail, errorMessage: "" });
  }

  handleDropdownChange = (name, value) => {
    this.setState((previousState) => {
      let OrgDetail = {
        ...previousState.OrgDetail,
        [name]: value,
      }
      if(name === "businessModel") {
        OrgDetail.seats = "";
        OrgDetail.maxConcurrentConnections = "";
      }
      return {
        OrgDetail,
        errorMessage: "",
      }
    });
  }

  changeModulePermission = event => {
    let state_element = this.state.OrgDetail.availableModules;

    if (event.target.checked && !(event.target.name in state_element)) {

      state_element.push(event.target.name);
    } else if (!event.target.checked) {
      state_element = _.remove(state_element, item => {
        if (item !== event.target.name) return item;
      });
    }
    let OrgDetail = this.state.OrgDetail;
    OrgDetail.availableModules = state_element;
    this.setState({ OrgDetail: OrgDetail, errorMessage: "" });
  };

  resetAddOrgState = () => {
    this.setState({
      OrgDetail: {
        name: "",
        seats: "",
        availableModules: [],
        firstName: "",
        lastName: "",
        phoneNumber: "",
        username: "",
        uriIdentifier: null,
        licenseExpiryDate: getTomorrowDate(),
        currentDate: this.getCurrentDate(),
        qrLimit: "",
        loggingLevel: LOG_TYPE.BASIC,
        storageLocation: STORAGE_LOCATION.CUSTOMER,
        businessModel: BUSINESS_MODELS.NUMBER_OF_SEATS,
        maxConcurrentConnections: "",
        cobrowseType: cobrowseTypes.CUSTOMER_CO_BROWSE,
        defaultLanguage: LANGUAGE.EN.name,
        timezone: FREQUENTLY_USED_TIMEZONES[0].value,
      },
      errorMessage: "",
      addOrgErrorMessage: "",
      storageErrorMessage: "",
      isSSOEnabled: false,
      isVerified: false,
      isAddStorage: false,
      isEditStorage: false,
      bucketName: "",
      id: "",
      secret: "",
      storageDetails: {
        location: STORAGE_LOCATION.CUSTOMER,
        type: "S3"
      },
      storageChanged: false,
      isPasscodeEnabled: false,
      isOrganizationTemplatesEnabled:false,
      isRingCentralEnabled: false,
      isSnapshotScanCodeEnabled: false,
      allowUpload: false
    });
  };

  handleS3InputChange = (event) => {
    if(!hasWhiteSpace(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value, isVerified: false });
      this.resetError();
    }
  }

  getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  selectDefaultStorage = (selectedStorageDetailIndex) => {
    let storageDetailsList = this.state.storageDetails?.list ? this.state.storageDetails?.list.slice() : [];
    let valid = false;
    if (!this.state.isStorageSelected && this.state.errorMessage)
      this.setState({ errorMessage: "" })
    if (selectedStorageDetailIndex != -1) {
      storageDetailsList.forEach((storageDetails, index) => {
        if (index === selectedStorageDetailIndex) {
          storageDetails.isSelected = true;
          valid = true;
          this.setState({
            storageChanged: true
          });
        } else {
          storageDetails.isSelected = false;
        }
      });
    }
    else {
      storageDetailsList.forEach((storageDetails, index) => {
        if (storageDetails.isSelected) {
          valid = true;
        }
      });
    }
    this.setState({
      storageErrorMessage: "",
      isStorageSelected: valid
    });
  }
  
  resetStorageDetailsFields = () => {
    this.setState({
      isVerified: false,
      isAddStorage: false,
      isEditStorage: false,
      bucketName: "",
      id: "",
      secret: "",
      storageChanged: false,
      currentBucket: ""
    });
  }
    
    resetError = () => {
      this.setState({
        errorMessage: "",
        addOrgErrorMessage: "",
        storageErrorMessage: "",
      });
    }
    
    handleEditStorageClick(bucketName) {
      let bucket = null;
      if (!_.isEmpty(bucketName)) {
        bucket = this.state.storageDetails.list?.filter((item => item.credential.bucketName === bucketName))[0];
        this.setState({
          isEditStorage: true,
          bucketName: bucket.credential.bucketName,
          id: bucket.credential.id,
          secret: bucket.credential.secret,
          currentBucket: bucket.credential.bucketName
        });
      }
    }
    
    addStorage = () => {
      if (!this.state.bucketName && !this.state.id && !this.state.secret) {
        this.setState({ addOrgErrorMessage: getMessage("EMPTY_MESSAGE") });
      }
      else if (this.state.isVerified) {
        let bucket = this.state.storageDetails;
        bucket.type = bucket.type ? bucket.type : "S3";
        bucket.list = bucket.list ? bucket.list : [];
        bucket.location = this.state.storageDetails?.location ? this.state.storageDetails?.location : STORAGE_LOCATION.LOOOKIT_CUSTOM;
            
        let isDuplicateStorageDetails =false;
        bucket.list.map((content, i) =>{
          if(content.credential.bucketName === this.state.bucketName
                    && content.credential.id === this.state.id
                    && content.credential.secret === this.state.secret) {
            //Duplicate Storage Details
            isDuplicateStorageDetails=true;
          }
        });
                
        if(!isDuplicateStorageDetails) {
          if (!this.state.isEditStorage) {
            let cred = {
              credential: {
                bucketName: this.state.bucketName,
                id: this.state.id,
                secret: this.state.secret
              },
              validation: { status: true },
              isSelected: this.state.isSelected,
              id: null
            }
            bucket.list.push(cred)
          }
          else {
            let cred = {
                bucketName: this.state.bucketName,
                id: this.state.id,
                secret: this.state.secret
              },
              validation = { status: true }
            bucket.list = bucket.list.map(
              (content, i) => content.credential.bucketName === this.state.bucketName ? {
                ...content, credential: cred, validation: validation
              } : content)
          }
          this.resetStorageDetailsFields();
          this.setState({ storageDetails: bucket })
        }
        else {
          this.setState({ addOrgErrorMessage: getMessage("DUPLICATE_STORAGE_CREDENTIALS") });
        }
      }
      else this.setState({ addOrgErrorMessage: getMessage("BEFORE_VERIFICATION_ERROR") });
    }

handleVerifyStorageDetailsClick = () => {
  this.resetError();
  if (this.state.bucketName && this.state.id && this.state.secret) {
    this.setState({ spinnerVisibility: true });
    let storage = {
      type: 'S3',
      credentials: {
        id: this.state.id,
        secret: this.state.secret,
        bucketName: this.state.bucketName
      }
    }
    this.props.verifyStorage(storage, (error) => {
      if (error.code)
        this.setState({ spinnerVisibility: false, isVerified: false, addOrgErrorMessage: getMessage(error.code) })
      else {
        this.setState({ spinnerVisibility: false, isVerified: true, addOrgErrorMessage: "" })
      }
    })
  }
  else {
    this.setState({ addOrgErrorMessage: getMessage("EMPTY_MESSAGE") });
  }
}

onChangeStorageOption = (selectedStorage) => {
  this.resetError();
  this.resetStorageDetailsFields();
  let obj = this.state.storageDetails ? this.state.storageDetails : {};
  if (obj) {
    obj.location = selectedStorage;
    obj.list = obj.list && selectedStorage === STORAGE_LOCATION.LOOOKIT_CUSTOM ? obj.list : [];
    this.setState({
      storageDetails: obj,
    });
  }
}
cancelStorage = () => {
  this.resetStorageDetailsFields();
  this.resetError();
}

isOutsideRange = (day) => {
  let dayIsBlocked = false;
  //Block past dates
  if(getTomorrowDate().diff(day.startOf('day'), 'days') > 0) {
    dayIsBlocked = true;
  }
    
  return dayIsBlocked;
}

handleSwitches=(toggleFlag,variable)=>{
  if(variable !== "ALLOW_DOWNLOAD" && variable !== "PDF"){ 
    this.setState((prevState) => ({ [variable]: !prevState[variable] }));
  }
  else{
    let state_element = this.state.OrgDetail.availableModules;
    if (toggleFlag && !(variable in state_element)) {
           
      state_element.push(variable);
    } else if (!toggleFlag) {
      state_element = _.remove(state_element, item => {
        if (item !== variable) return item;
      });
    }
    let OrgDetail = this.state.OrgDetail;
    OrgDetail.availableModules = state_element;
    this.setState({ OrgDetail: OrgDetail, errorMessage: "" });
  }
}

// TODO: Change/optimize below code.
displayAvailableFeaturesList = () => {
  let allFeatureList = [];

  _.map(availableFeatures, (key, value) => {
    switch (key) {
      case "SINGLE_SIGN_ON":
        allFeatureList.push(
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label modal-label">
              {getMessage(value)}
            </Label> 
            {this.renderSwitch(this.state.isSSOEnabled,"isSSOEnabled")}
          </Col>
        );
        break;
      case "SPECIFIED_MEETING_CODE": 
        allFeatureList.push(
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 mr-5 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.isPasscodeEnabled,"isPasscodeEnabled")}
          </Col>
        );
        break;
      case "ORGANIZATION_TEMPLATE":    
        this.state.storageDetails.location !== STORAGE_LOCATION.LOOOKIT_GLOBAL && 
        allFeatureList.push( 
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.isOrganizationTemplatesEnabled,"isOrganizationTemplatesEnabled")}
          </Col>
        );
        break;  
      case "PDF_EDITOR":    
        allFeatureList.push(  
          <Col key={key} xs={5} className= "d-flex modal-label" >
            <Label className="label p-0 mr-5 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.OrgDetail &&
            this.state.OrgDetail.availableModules &&
            this.state.OrgDetail.availableModules.includes("PDF"),"PDF")}
          </Col>
        );
        break;
      case "ALLOW_DOWNLOAD":
        allFeatureList.push(  
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.OrgDetail.availableModules && this.state.OrgDetail.availableModules.includes("ALLOW_DOWNLOAD"),"ALLOW_DOWNLOAD")}
          </Col>
        );
        break;

      case "RING_CENTRAL":
        allFeatureList.push(  
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.isRingCentralEnabled,"isRingCentralEnabled")}
          </Col>
        );
        break;
      case "SNAPSHOT_SCAN_CODE":
        allFeatureList.push(  
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.isSnapshotScanCodeEnabled,"isSnapshotScanCodeEnabled", !this.state.OrgDetail.availableModules.includes(modulesAvailableConstant.SNAPSHOT))}
          </Col>
        );
        break;
      case "CHAT_GPT":
        allFeatureList.push(  
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage("CHAT_GPT_LABEL")}
            </Label>
            {this.renderSwitch(this.state.isChatGPTEnabled,"isChatGPTEnabled", !this.state.OrgDetail.availableModules.includes(modulesAvailableConstant.CHAT))}
          </Col>
        );
        break;

      case "ALLOW_UPLOAD":
        allFeatureList.push(  
          <Col key={key} xs={5} className="d-flex modal-label">
            <Label className="label p-0 modal-label">
              {getMessage(value)}
            </Label>
            {this.renderSwitch(this.state.allowUpload,"allowUpload")}
          </Col>
        );
        break;
    }
  });
  return allFeatureList;
}

displayAllModuleList = () => {
  let allModuleList = [];

  _.map(modulesToDisplay, (key, value) => {

    switch (key) {
      case "CANVAS":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox 
                name="CANVAS"  
                checked={true}
                disabled 
              />
              <img
                src={ic_canvas}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "AUDIO":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding d-flex">
              <Checkbox name="AUDIO" onChange={this.changeModulePermission}/>
              <img
                src={ic_audio}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage("AUDIO")}
            </Label>
          </Col>
        );
        break;
      case "AUDIO_VIDEO":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="AUDIO_VIDEO" onChange={this.changeModulePermission}/>
              <img
                src={ic_video}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage("VIDEO")}
            </Label>
          </Col>
        );
        break;
      case "SCREENSHARE":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="SCREENSHARE" onChange={this.changeModulePermission}/>
              <img
                src={ic_share}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "CHAT":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="CHAT" onChange={this.changeModulePermission}/>
              <img
                src={ic_message}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "LOCATION":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="LOCATION" onChange={this.changeModulePermission}/>
              <img
                src={ic_location}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "PDF_EDITOR":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="PDF" onChange={this.changeModulePermission}/>
              <img
                src={ic_pdf}
                style={{
                  height: "17px",
                  width: "17px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "SNAP_SHOT":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="SNAPSHOT" onChange={this.changeModulePermission}/>
              <img
                src={ic_camera}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "APP_EMULATION":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox 
                name="APP_EMULATION"  
                disabled={window._env_.REACT_APP_ALLOW_APP_EMULATION == 'false'}
                onChange={this.changeModulePermission}/>
              <img
                src={ic_app_emulation}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage("APP_EMULATION")}
            </Label>
          </Col>
        );
        break;
      case "QR_CODE":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="QR_CODE" onChange={this.changeModulePermission}/>
              <img
                src={ic_QR_code}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage("QR_CODE")}
            </Label>
          </Col>
        );
        break;
      case "ALLOW_DOWNLOAD":
        allModuleList.push(
          <Col key={key} xs={12} lg={4}>
            <Label className="label noPadding">
              <Checkbox name="ALLOW_DOWNLOAD" onChange={this.changeModulePermission}/>
              <img
                src={download_img}
                style={{
                  height: "20px",
                  width: "20px"
                }}
              />{" "}
              {getMessage(value)}
            </Label>
          </Col>
        );
        break;
      case "CO_BROWSE":
        allModuleList.push(
          <Fragment key={key}>
            <Col key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox name="CO_BROWSE" onChange={this.changeModulePermission}/>
                <img
                  src={ic_admin_co_browse}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage("CO_BROWSE")}
              </Label>
            </Col>
            {this.state.OrgDetail.availableModules.includes(modulesAvailableConstant.CO_BROWSE) && 
            <Col xs={12} lg={6} className="noPadding">
              <CustomDropdown
                options={this.coBrowseOptions}
                onChange={(value) => this.handleDropdownChange("cobrowseType", value)}
                value={this.state.OrgDetail.cobrowseType}
              />
            </Col>
            }
          </Fragment>
        );
        break;
    }
  });

  return allModuleList;
};

  renderButtons = () => {
    return (
      <div>
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button
          onClick={this.handleAddClick}
          className="buttons customBtn"
        >
          {getMessage("ADD")}
        </Button>
      </div>
    );
  };
renderSprinner = () => {
  return <Spinner showSpinner={this.state.spinnerVisibility} />;
}

renderSwitch = (flag,variable,disabled=false)=>{
  return (
    <Switch
      switchOn={flag}
      toggleSwitch={(event)=>{this.handleSwitches(event,variable)}}
      disabled = {disabled}
    />
  )
}

renderStorageTableBody() {
  return (
    <tbody>
      {this.state.storageDetails.list?.map((item, index) =>
        <tr className="cursorPointer">
          <td className="storageRow">
            <Input
              type="radio"
              className='oldRadio'
              checked={item.isSelected}
              onChange={() => this.selectDefaultStorage(index)}
            />
            <span className="checkmark" value={item.isSelected}
              onClick={() => this.selectDefaultStorage(index)}>
            </span>
          </td>
          <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
            colSpan="2"
            onClick={() => this.selectDefaultStorage(index)}>
            {item.credential.bucketName}
          </td>
          <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
            colSpan="3"
            onClick={() => this.selectDefaultStorage(index)}>
            {item.credential.id}
          </td>
          <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
            colSpan="3"
            onClick={() => this.selectDefaultStorage(index)}>
            {item.credential.secret}
          </td>
          <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
            {!item.validation.status ?
            <>
              <img src={ic_error} alt="Status" className="ml-2 cursorPointer"
                id="bucketError"/>
                  <CustomTooltip
                    tooltipBoundariesElement={this.tooltipBoundaryElement}
                    tooltipText={item.validation.error.message}
                    tooltipId="bucketError"
                  />
            </>
            
              : null
            }
          </td>
          <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
            <img src={ic_edit} alt="Edit" className="cursorPointer" id="edit"
              onClick={() => { this.handleEditStorageClick(item.credential.bucketName) }} />
            <CustomTooltip
              tooltipBoundariesElement={this.tooltipBoundaryElement}
              tooltipText={getMessage("EDIT")}
              tooltipId="edit"
            />
          </td>
        </tr>)
      }
    </tbody>
  )
}
    
getSystemCapacity = () => {
  return (this.props.sessionsCapacity.systemMaxCapacity - this.props.sessionsCapacity.usedCapacity);
}

displaySystemCapacityUI = () => {
  return (
    <Col xs={3} className="d-flex modal-label">
      <span  className="p-0 modal-label systemCapacityLabel"> {getMessage("SYSTEM_CAPACITY")}: </span>
      <span className="modal-label ml-1 systemCapacityValue"> {this.state.systemCapacity}</span>
    </Col>
  )
}

showOrHideSystemCapacity = (showSystemCapacity) =>{
  this.setState({
    showSystemCapacity: showSystemCapacity,
  });
}

renderNoOfSeats = () => {
  return (
    <Row className="mb-2">
      <Col xs={3} className="pr-0">
        <Label className="label modal-label">{getMessage("NO_OF_SEATS")}</Label>
      </Col>
      <Col xs={6} className="parentOfNumericInput">
        <NumericInput
          className="ml-1 inputFormControl"
          name="seats"
          value={this.state.OrgDetail.seats}
          min={0}
          strict={this.state.OrgDetail.seats <= this.state.systemCapacity}
          onChange={(value) => this.handleNumberInputChange("seats", value, this.state.systemCapacity)}
          // onFocus={() => this.showOrHideSystemCapacity(true)}
          onClick={() => this.showOrHideSystemCapacity(true)}
          onBlur={() => this.showOrHideSystemCapacity(false)}
          placeholder={getMessage("NUMBER_OF_SEATS_PLACEHOLDER")}
          required
        />
      </Col>
      {this.state.showSystemCapacity && this.displaySystemCapacityUI()}
    </Row>
  )
}

renderMaxConcurrentConnections = () => {
  return (
    <Row className="mb-2">
      <Col xs={3} className="pr-0">
        <Label className="label modal-label">{getMessage("MAX_CONCURRENT_CONNECTIONS")}</Label>
      </Col>
      <Col xs={6} className="parentOfNumericInput">
        <NumericInput
          className="ml-1 inputFormControl"
          value={this.state.OrgDetail.maxConcurrentConnections}
          min={0}
          strict={this.state.OrgDetail.maxConcurrentConnections <= this.state.systemCapacity}
          name="maxConcurrentConnections"
          onChange={(value) => this.handleNumberInputChange("maxConcurrentConnections", value, this.state.systemCapacity)}
          // onFocus={() => this.showOrHideSystemCapacity(true)}
          onClick={() => this.showOrHideSystemCapacity(true)}
          onBlur={() => this.showOrHideSystemCapacity(false)}
          placeholder={getMessage("MAX_CONCURRENT_MEETINGS_PLACEHOLDER")}
          required />
      </Col>
      {this.state.showSystemCapacity && this.displaySystemCapacityUI()}
    </Row>
  )
}
    
render() {
  const dateFormatInputProps = {
    readOnly: true,
  };

  return (

    <div>
      {this.renderSprinner()}
      <div
        backdrop="static"
        className="addOrganisationModal p-3 whiteBackground"
      >
        {/* <ModalBody> */}
        <Form>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("ORGANIZATION_NAME")}
            </Label>
            <Input
              type="text"
              name="name"
              onChange={this.handleInputChange}
              className="inputFormControl"
              maxLength="50"
              required
              placeholder={getMessage("ORG_NAME_PLACEHOLDER")}
              autoFocus 
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">{getMessage("BUSINESS_MODEL")}</Label>
            <div className="col-9 pl-5 pr-0">
              <CustomDropdown
                options={this.businessModelsOptions}
                onChange={(value) => this.handleDropdownChange("businessModel", value)}
                value={this.state.OrgDetail.businessModel}
              />
            </div>
          </FormGroup>
          {
            this.state.OrgDetail.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS 
              ? this.renderNoOfSeats()
              : this.renderMaxConcurrentConnections()
          }
          <FormGroup>
            <Label className="label modal-label">{getMessage("ORGANIZATION_URI_IDENTIFIER")}</Label>
            <Input
              type="text"
              maxLength="50"
              name="uriIdentifier"
              className="inputFormControl"
              placeholder={getMessage("URI_PLACEHOLDER")}
              onChange={this.handleInputChange}
              value={this.state.OrgDetail.uriIdentifier ?? null}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="label" style={{ alignSelf: "flex-start" }}>
              {getMessage("AVAILABLE_FEATURE")}
            </Label>
            <Row className="flex5 moduleRow"> {this.displayAvailableFeaturesList()} </Row>
          </FormGroup>
          <FormGroup>
            <Label className="label" style={{ alignSelf: "flex-start" }}>
              {getMessage("AVAILABLE_MODULE")}
            </Label>
            <Row className="flex5 moduleRow">{this.displayAllModuleList()}</Row>
          </FormGroup>
          {
            this.state.OrgDetail.availableModules &&
            this.state.OrgDetail.availableModules.indexOf("QR_CODE") !== -1 ?
              <FormGroup>
                <Label className="label modal-label">{getMessage("QR_CODE_LIMIT")}</Label>
                <NumericInput
                  name="qrLimit"
                  value={this.state.OrgDetail.qrLimit}
                  min={0}
                  strict={this.state.OrgDetail.qrLimit <= MAX_QR_LIMIT}
                  onChange={(value) => this.handleNumberInputChange("qrLimit", value, MAX_QR_LIMIT)}
                  className="ml-1 inputFormControl"
                  placeholder={getMessage("QR_CODE_LIMIT_PLACEHOLDER")}
                  required />
              </FormGroup> : null
          }
          <FormGroup className="d-flex justify-content-between">
            <Label className="label modal-label">{getMessage("EXPIRY_DATE")}</Label>
            <div className="col-9 pl-5 pr-0" onClick={(e) => this.setState({ focused:true })}>
              <DateTime
                id="licenseExpiryDate"
                className="dateTimePicker"
                dateFormat={DATE_FORMAT}
                inputProps={dateFormatInputProps}
                value={this.state.OrgDetail.licenseExpiryDate}
                onChange={(value) => this.handleDateChange(value)}
                closeOnSelect
                closeOnClickOutside
                timeFormat={false}
                isValidDate={(date)=>{return date.isSameOrAfter(moment())}}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("DEFAULT_LANGUAGE")}
              <div className="ml-2">
                <CustomTooltip
                  tooltipId="language"
                  tooltipBoundariesElement={this.tooltipBoundaryElement}
                  tooltipText={getMessage("ICON_DESCRIPTION_DEFAULT_LANGUAGE")}
                  showIcon={true}
                />
              </div>
            </Label>
            <div className="col-9 pl-5 pr-0">
              <CustomDropdown
                options={this.defaultLanguageOptions}
                onChange={(value) => this.handleDropdownChange("defaultLanguage", value)}
                value={this.state.OrgDetail.defaultLanguage}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("TIMEZONE")}
              <div className="ml-2">
                <CustomTooltip
                  tooltipId="timezone"
                  tooltipBoundariesElement={this.tooltipBoundaryElement}
                  tooltipText={getMessage("ICON_DESCRIPTION_TIMEZONE")}
                  showIcon={true}
                />
              </div>
            </Label>
            <div className="col-9 pl-5 pr-0">
              <TimezoneDropdown
                value={this.state.OrgDetail.timezone}
                onChange={(value) => this.handleDropdownChange("timezone", value)}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">{getMessage("LOGGING_LEVEL")}</Label>
            <div className="col-9 pl-5 pr-0">
              <CustomDropdown
                options={this.loggingLevelOptions}
                onChange={(value) => this.handleDropdownChange("loggingLevel", value)}
                value={this.state.OrgDetail.loggingLevel}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">{getMessage("STORAGE_OPTION")}</Label>
            <div className="col-9 pl-5 pr-0">
              <CustomDropdown
                options={this.storageOptions}
                onChange={this.onChangeStorageOption}
                value={this.state.storageDetails.location}
              />
            </div>
          </FormGroup>
          {
            this.state.storageDetails.location === STORAGE_LOCATION.LOOOKIT_CUSTOM ?
              <div className="section">
                <Row className="noMargin">
                  <Col className="mainTitleStorage pl-0 label">{getMessage("STORAGE")}
                  </Col>
                  {(!this.state.isAddStorage && !this.state.isEditStorage) && (this.state.storageDetails.location === STORAGE_LOCATION.LOOOKIT_CUSTOM) ?
                    <Col className="mainTitleStorage pr-0">
                      <img src={ic_add_storage} className="cursorPointer floatRight" id="addStorage" onClick={() => this.setState({ isAddStorage: true })} />
                      <CustomTooltip
                        tooltipText={getMessage("ADD_STORAGE")}
                        tooltipId="addStorage"
                        tooltipBoundariesElement={this.tooltipBoundaryElement}
                      />
                    </Col>
                    : null
                  }
                </Row>
                <div>
                  {this.state.storageDetails.list ?
                    <Row className="storageCard">
                      <CardBody className="pt-0 pb-0">
                        <Row className="interCardContent">
                          <Table bordered size="sm" className="ml-2 mr-2 storageTableWidth">
                            <thead>
                              <tr className="storageHead">
                                <th className="storageRow border-bottom rounded"> </th>
                                <th className="storageRow border-bottom rounded" colSpan="2">{getMessage("BUCKET_NAME")}</th>
                                <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("ID")}</th>
                                <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("SECRET")}</th>
                                <th className="storageRow border-bottom rounded"> </th>
                                <th className="storageRow border-bottom rounded"> </th>
                              </tr>
                            </thead>
                            {this.renderStorageTableBody()}
                          </Table>
                        </Row>
                      </CardBody>
                    </Row>
                    : null}
                  {this.state.isEditStorage || this.state.isAddStorage ?
                    <Row>
                      <Col className="storageCard">
                        <Card>
                          <CardBody>
                            <Row className="radio">
                              <Col>
                                <Label className="label">{getMessage("AWS_S3")}:</Label>
                              </Col>
                              <Col>
                                <Button close aria-label="Cancel" onClick={this.cancelStorage}>
                                </Button>
                              </Col>
                            </Row>
                            <Row className="interCardContent">
                              <Col xs={4} className="pt-4 align-self-center">
                                <Label>{getMessage("BUCKET_NAME")}</Label> </Col>
                              <Col xs={8}>
                                <Input
                                  type="text"
                                  name="bucketName"
                                  className="inputFormControl mt-3"
                                  maxLength="50"
                                  required
                                  disabled={this.state.currentBucket}
                                  onChange={this.handleS3InputChange}
                                  value={this.state.bucketName}
                                  placeholder={getMessage("BUCKET_NAME_PLACEHOLDER")}
                                />
                              </Col>
                            </Row>
                            <Row className="interCardContent">
                              <Col xs={4} className="pt-4 align-self-center">
                                <Label>
                                  {getMessage("ID")}
                                </Label>
                              </Col>
                              <Col xs={8}>
                                <Input
                                  type="text"
                                  name="id"
                                  className="inputFormControl mt-3"
                                  maxLength="50"
                                  required
                                  onChange={this.handleS3InputChange}
                                  value={this.state.id}
                                  placeholder={getMessage("AWS_ID_PLACEHOLDER")}
                                />
                              </Col>
                            </Row>
                            <Row className="interCardContent">
                              <Col xs={4} className="pt-4 align-self-center">
                                <Label>
                                  {getMessage("SECRET")}
                                </Label>
                              </Col>
                              <Col xs={8}>
                                <Input
                                  type="text"
                                  name="secret"
                                  className="inputFormControl mt-3"
                                  maxLength="50"
                                  required
                                  onChange={this.handleS3InputChange}
                                  value={this.state.secret}
                                  placeholder={getMessage("AWS_SECRET_PLACEHOLDER")}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col  className="errors autoHeight col-md-12">
                                {getMessage(this.state.addOrgErrorMessage)}
                              </Col>
                            </Row>
                            <Row className="interCardContent floatRight">
                              <Button onClick={this.handleVerifyStorageDetailsClick}
                                disabled={!this.state.isVerified ? false : true}
                                className={this.state.isVerified ? "verifiedBtn mt-2 " : "verifyBtn mt-2"}>
                                {this.state.isVerified ? getMessage("VERIFIED") : getMessage("VERIFY")}
                              </Button>
                              <Button className={this.state.isVerified ? "borderBtn cursorPointer bgwhite ml-3 mt-2" : "disabledBtn ml-3 mt-2"}
                                disabled={this.state.isVerified ? false : true}
                                onClick={this.addStorage}>
                                {this.state.isEditStorage ? getMessage("UPDATE") : getMessage("ADD")}
                              </Button>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    : null}
                </div>
                {this.state.storageErrorMessage ? <Row><Col md={{span:8,offset:4}} className="errors autoHeight col-md-12">{getMessage(this.state.storageErrorMessage)}</Col> </Row> : null}
              </div>
              : null
          }
          {this.state.storageDetails.location === STORAGE_LOCATION.LOOOKIT_CUSTOM && (!this.state.isEditStorage && !this.state.isAddStorage) ?
            <Row><Col  className="warning autoHeight col-md-12">{getMessage("FIRST_LOOOKIT_CUSTOM_WARNING")}</Col> </Row>
            : null}
          {((this.state.storageDetails.location === STORAGE_LOCATION.CUSTOMER) && (!this.state.storageDetails.otherStorageValid)) ?
            <Row><Col  className="warning autoHeight col-md-12">{getMessage("CUSTOMER_WARNING")}</Col> </Row>
            : null}
          {this.state.storageChanged ?
            <Row><Col  className="text-muted text-center autoHeight col-md-12">{getMessage("SECOND_LOOOKIT_CUSTOM_WARNING")}</Col> </Row>
            : null
          }
          <DropdownItem divider />
          <FormGroup className="pt-2">
            <Label className="label modal-label">{getMessage("TYPE_OF_USER")}</Label>
            <Label  className="label ml-4 modal-label" style={{ flex: 5.2 }}>
              {getMessage("ADMIN")}
            </Label>
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("EMAIL")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="email"
              name="username"
              onChange={this.handleInputChange}
              className="inputFormControl"
              required
              placeholder={getMessage("ENTER_EMAIL_PLACEHOLDER")}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("FIRST_NAME")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="text"
              name="firstName"
              onChange={this.handleInputChange}
              className="inputFormControl"
              maxLength="50"
              required
              placeholder={getMessage("FIRST_NAME_PLACEHOLDER")}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("LAST_NAME")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="text"
              name="lastName"
              onChange={this.handleInputChange}
              className="inputFormControl"
              maxLength="50"
              required
              placeholder={getMessage("LAST_NAME_PLACEHOLDER")}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">{getMessage("PHONE_NUMBER")}</Label>
            <Input
              type="phone"
              name="phoneNumber"
              onChange={this.handleInputChange}
              className="inputFormControl"
              value={this.state.OrgDetail.phoneNumber}
              required
              placeholder={getMessage("ENTER_PHONE_NUMBER_PLACEHOLDER")}
            />
          </FormGroup>
        </Form>
        {this.state.errorMessage ? <span className=" errors ">{this.state.errorMessage}</span> : null}
        {/* </ModalBody> */}
        <Row className="permissionButtons">
          {this.renderButtons()}
        </Row>
      </div>
    </div>
  );
}

}

export default AddOrganisation;
