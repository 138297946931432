import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class UserAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getAllUsersAction(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.USER,
        requestBody: data
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SET_USERS_COUNT,
            usersCount: response.data.data.total,
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getUsersAction(userId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.USER}/${userId}`,
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addUserAction(userData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        //url: `${API_URL.USER}/${customerId}`,
        url: API_URL.USER,
        requestBody: userData
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  importBulkUsersAction(formData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.BULK_IMPORT_USERS,
        requestBody: formData
      
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }
 
  saveUserAction(userData, userId, loggedUserId=0) {
    let userIdParam = userId;
    if(!userIdParam){
      userIdParam = userData.id
    }
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: `${API_URL.USER}/${userIdParam}`,

        requestBody: userData
      }).then(
        response => {
          if(loggedUserId === userId){
            this.dispatch({
              type: ACTIONS.UPDATE_PROFILE,
              userDetails: response.data.data
            });
          }
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSessionListAction(requestBody) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.GET_SESSSION_LIST}`,

        requestBody: requestBody
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SET_SESSIONS_COUNT,
            sessionsCount: response.data.data.total,
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteUserAction(userId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url: `${API_URL.USER}/${userId}`
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  resetUserAction(userData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: API_URL.RESET_USER_PASSWORD,
        requestBody:userData
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSessionDetail(sessionKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.GET_SESSION_DETAIL}/${sessionKey}`
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getSessionData(sessionKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        requestBody: { sessionKey: sessionKey },
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.GET_SESSION_DATA,
      }).then(
        response => {
          resolve(response);
        },
        error=>{
          reject(error);
        }
      );
    });
  }

  getPresignedObjectUrl(objectId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        requestBody: { objectId: objectId },
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.GET_PRESIGNED_OBJECT_URL.replace('{objectId}', `${objectId}`),
      }).then(
        response => {
          resolve (response);
        },
        error=>{
          reject(error);
        });
    }); 
  }
  
  saveSessionKey(sessionKey){
    this.dispatch({ 
      type: ACTIONS.ADMIN_ACTION.SAVE_SESSION_KEY,
      value: sessionKey
    });
  }

  saveSessionListPageSize(sessionListPageSize){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_SESSION_LIST_PAGE_SIZE, 
      value: sessionListPageSize
    });
  }

  saveSessionListCurrentPageNo(sessionListCurrentPageNo){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_SESSION_LIST_CURRENT_PAGE_NO,
      value: sessionListCurrentPageNo
    })
  }

  saveUserListPageSize(sessionListPageSize){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_USER_LIST_PAGE_SIZE, 
      value: sessionListPageSize
    });
  }

  saveUserListCurrentPageNo(sessionListCurrentPageNo){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_USER_LIST_CURRENT_PAGE_NO,
      value: sessionListCurrentPageNo
    })
  }
}
