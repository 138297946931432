import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Row, Col, Button,Label } from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import {getTimeInLocaleTimezone, formatDateByLocale, getElapseTimeInHhMm} from "UTILS/DateUtils";

import RetireInstanceConfirmationModal from"./RetireInstanceConfirmationModal";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";

import { KMSInstanceInfo } from "UTILS/Constants";

import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import "./ClusterInformationList.less"
import "../../App/App.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

export default class ClusterInformationList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isAnyInstanceSelected: false,
      showRetireInstanceConfirmationModal: false,
      selectedInstances: []
    };
    // this.clusterData =[
    //   {"instanceId":"i-abc","ipAddress":"10.0.2.252","status":"running","isRetired":false,"createdAt":"2022-04-21T11:58:01.000Z","updatedAt":"2022-06-07T07:59:17.000Z","kmsInfo":{"cpuUsage":2,"ramUsage":40.5032,"activePipelineCount":0,"diskUsage":null,"isHealthy":true,"recoveryAttempts":0,"lastStartedAt":"2022-06-07T07:59:11.000Z","lastServedAt":"2022-05-19T13:35:41.000Z","updatedAt":"2022-06-10T13:07:34.000Z","createdAt":"2022-04-21T11:59:23.000Z","capacity":100,"healthStatus":"HEALTHY"}},
    //   {"instanceId":"i-def","ipAddress":"10.0.2.201","status":"stopped","isRetired":false,"createdAt":"2022-04-21T12:04:31.000Z","updatedAt":"2022-06-10T11:30:00.000Z","kmsInfo":{"cpuUsage":25.7576,"ramUsage":32.184,"activePipelineCount":0,"diskUsage":null,"isHealthy":true,"recoveryAttempts":0,"lastStartedAt":"2022-06-10T11:12:04.000Z","lastServedAt":"2022-05-31T12:56:56.000Z","updatedAt":"2022-06-10T11:30:02.000Z","createdAt":"2022-04-21T12:05:29.000Z","capacity":100,"healthStatus":"HEALTHY"}},
    //   {"instanceId":"i-ghi","ipAddress":"10.0.3.35","status":"stopped","isRetired":false,"createdAt":"2022-04-21T12:05:31.000Z","updatedAt":"2022-04-21T13:00:01.000Z","kmsInfo":{"cpuUsage":100,"ramUsage":32.8793,"activePipelineCount":0,"diskUsage":null,"isHealthy":true,"recoveryAttempts":0,"lastStartedAt":"2022-04-21T12:06:13.000Z","lastServedAt":null,"updatedAt":"2022-04-21T13:00:07.000Z","createdAt":"2022-04-21T12:06:19.000Z","capacity":100,"healthStatus":"HEALTHY"}}
    // ];
  }

  openModal=()=>{
    this.setState({showRetireInstanceConfirmationModal:true})
  }

  handleRetireInstanceCancelClick=()=>{
    this.setState(
      {showRetireInstanceConfirmationModal: !this.state.showRetireInstanceConfirmationModal})
  }

  handleRetireInstanceOkClick = () => {
    this.props.retireInstance((this.state.selectedInstances),(message)=>{
      this.handleRetireInstanceCancelClick();
      this.props.getClusterInfo();
      document.getElementsByClassName('checkRetireInstance').forEach(chkbox => {
        // class checkRetireInstance is applied to the <Checkbox> element which is a customised <div>
        // childNodes[0] represents the <input type=checkbox>
        chkbox.childNodes[0].checked = false
      });
      this.setState({isAnyInstanceSelected:!this.state.isAnyInstanceSelected})
    });
  }

  setRetireInstance = (event, instanceId) => {
    let selectedInstances = this.state.selectedInstances;
    if(event.currentTarget.checked) {
      selectedInstances.push(instanceId);
    } else {
      const index = selectedInstances.indexOf(instanceId);
      if (index > -1) {
        selectedInstances.splice(index, 1);
      }
    }
    this.setState({
      selectedInstances: selectedInstances,
      isAnyInstanceSelected: selectedInstances.length > 0
    });
  }

  serialNumberFormat = (cell, row, enumObject, index) =>{
    return(
      <div> {index+1} </div>
    )
  }

  selectionFormat = (cell, row) => {
    return (
      <Label className="label noPadding">
        <Checkbox className="checkRetireInstance"
          onChange={(e) => this.setRetireInstance(e, row.instanceId)}
          disabled={row.isRetired}
        />
      </Label>
    );
  }
  
  ipAddressFormat = (cell,row) =>{
    return (
      <div>
        {row.ipAddress}
      </div>
    )
  }

  cpuUsageFormat = (cell,row) =>{
    return(
      <div>
        { (row.kmsInfo && row.status === KMSInstanceInfo.instanceStatus.RUNNING)
          ? row.kmsInfo.cpuUsage
          : "--"
        }
      </div>
    )
  }

  healthFormat = (cell,row) =>{
    return(
      <div>
        <label className="clusterDataFont">
          { row.isRetired && row.kmsInfo?.healthStatus
            ? getMessage(row.kmsInfo.healthStatus)
            : (row.kmsInfo && row.kmsInfo.healthStatus && row.status != KMSInstanceInfo.instanceStatus.STOPPED)
              ? getMessage(row.kmsInfo.healthStatus)
              : "--"
          }
        </label>
      </div>
    )
  }

  statusFormat = (cell,row) =>{
    return(
      <div>
        {row.status ? getMessage(row.status) : "--"}
      </div>
    )
  }

  memoryUsageFormat = (cell,row) =>{
    return(
      <div>
        { (row.kmsInfo && row.status === KMSInstanceInfo.instanceStatus.RUNNING)
          ? row.kmsInfo.ramUsage
          : "--"
        }
      </div>
    )
  }

  sortByUsage = (a, b, order, field) => {
    if(a.kmsInfo && b.kmsInfo) {
      if (order === 'desc') {
        return (a.kmsInfo[field] < b.kmsInfo[field]) ? 1 : -1;
      }
      return (a.kmsInfo[field] > b.kmsInfo[field]) ? 1 : -1;
    }
    return 0;
  }

  pipelineFormat = (cell, row) =>{
    return(
      <div>
        { (row.kmsInfo && row.status === KMSInstanceInfo.instanceStatus.RUNNING)
          ? row.kmsInfo.activePipelineCount
          : "--"
        }
      </div>
    )
  }

  createdTimeFormat = (cell, row) =>{
    return(
      <>
        {row.kmsInfo && row.kmsInfo.createdAt ?
          <>
            <div className="nameEllipsis">
              {formatDateByLocale(row.kmsInfo.createdAt, this.props.language)}
            </div>
            <div>
              {getTimeInLocaleTimezone(row.kmsInfo.createdAt)}
            </div>
          </>
          : "--" }
      </>
    )
  }

  totalRunningHrsFormat = (cell, row) =>{
    return(
      
      <div>
        { (row.kmsInfo && row.status === KMSInstanceInfo.instanceStatus.RUNNING)
          ? getElapseTimeInHhMm(row.kmsInfo.lastStartedAt)
          : "--"
        }
      </div>
    )
  }  

  lastServedAtFormat = (cell, row) =>{
    return (
      <>
        {row.kmsInfo && row.kmsInfo.lastServedAt ?
          <>
            <div className="nameEllipsis">
              {formatDateByLocale(row.kmsInfo.lastServedAt, this.props.language)}
            </div>
            <div>
              {getTimeInLocaleTimezone(row.kmsInfo.lastServedAt)}
            </div>
          </>
          : "--"}
      </>
    )
  }

  remainingCapacityFormat = (cell, row) =>{
    return (
      <div>
        { (row.kmsInfo && row.status === KMSInstanceInfo.instanceStatus.RUNNING)
          ? row.kmsInfo.capacity
          : "--"
        }
      </div>
    )
  }

  ///**********************************************************************************/////////
  instanceId = (cell,row) =>{
    return (
      <div>
        {row.instanceId ? row.instanceId : "--"}
      </div>
    )
  }

  cpuUsage = (cell,row) =>{
    return(
      <div>
        {row.cpuUsage ? row.cpuUsage : "--"}
      </div>
    )
  }

  ramUsage = (cell,row) =>{
    return(
      <div>
        {row.ramUsage ? row.ramUsage : "--"}
      </div>
    )
  }

  activeSessionsCount = (cell, row) =>{
    return(
      <div>
        {row.activeSessionsCount >= 0 ? row.activeSessionsCount : "--"}
      </div>
    )
  }

  isProtected = (cell,row) =>{
    return(
      <div>
        <label className="clusterDataFont">
          { row.isProtected ? getMessage("ON") : row.isProtected === false ? getMessage("OFF") : "--" }
        </label>
      </div>
    )
  }
  
  statusCount = (cell,row) =>{
    return(
      <div>
        {row.statusCount >= 0 ? row.statusCount : "--"}
      </div>
    )
  }

  updatedAt = (cell, row) =>{
    return(
      <div className="nameEllipsis">
        {row.updatedAt ? formatDateByLocale(row.updatedAt, this.props.language) 
          + " " + getTimeInLocaleTimezone(row.updatedAt) : "--"}
      </div>
    )
  }

  renderKmsStats = () => {
    return(
      <BootstrapTable
        data={this.props.clusterData}
        tableHeaderClass="tableHeader"
        tableBodyClass='tableBody'
        containerClass='cluster-info-list custom-list'
        tableContainerClass=''
        headerContainerClass=''
        bodyContainerClass=''
        bordered={false}
      >

        <TableHeaderColumn
          dataField='select'
          width="8%"
          dataFormat={this.selectionFormat}
          dataAlign='center'
        >
          <div> 
            {getMessage("SELECTION")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='any'
          dataFormat={this.serialNumberFormat}
          width="5%"
          dataAlign='center'
          isKey
        >
          <div>
            {getMessage("SR_NO")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='ip'
          width="15%"
          dataAlign='center'
          dataFormat={this.ipAddressFormat}
        >
          <div>
            {getMessage("IP")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='cpuUsage'
          width="13%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.cpuUsageFormat}
        >
          <div>
            {getMessage("CPU")} 
          </div>
          <div>
              (%) 
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='ramUsage'
          width="13%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.memoryUsageFormat}
        >
          <div>
            {getMessage("MEMORY")}
          </div>
          <div>
              (%) 
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='activePipelineCount'
          width="15%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.pipelineFormat}
        >
          <div>
            {getMessage("MEETINGS")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='remainingCapacity'
          width="13%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.remainingCapacityFormat}
        >
          <div>
            {getMessage("REMAINING_CAPACITY")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='healthcheck'
          width="13%"
          dataAlign='center'
          dataFormat={this.healthFormat}
        >
          <div>
            {getMessage("HEALTH_CHECK")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='status'
          width="13%"
          dataAlign='center'
          dataFormat={this.statusFormat}
        >
          <div>
            {getMessage("STATUS")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='totalRunningHrs'
          width="15%"
          dataAlign='center'
          dataFormat={this.totalRunningHrsFormat}
        >
          <div>
            {getMessage("TOTAL_RUNNING_HOURS")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='creationTime'
          width="15%"
          dataAlign='center'
          dataFormat={this.createdTimeFormat}
        >
          <div>
            {getMessage("CREATION_TIME")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='lastServedAt'
          width="15%"
          dataAlign='center'
          dataFormat={this.lastServedAtFormat}
        >
          <div>
            {getMessage("LAST_SERVED_AT")}
          </div>
        </TableHeaderColumn>

      </BootstrapTable>
    )
  }

  renderTurnStats = () => {
    console.log("------------------------", this.props.usingTurnCluster, this.props.turnClusterData);
    return(
      <BootstrapTable
        data={this.props.turnClusterData}
        tableHeaderClass="tableHeader"
        tableBodyClass='tableBody'
        containerClass='cluster-info-list custom-list'
        tableContainerClass=''
        headerContainerClass=''
        bodyContainerClass=''
        bordered={false}
      >

        <TableHeaderColumn
          dataField='any'
          dataFormat={this.serialNumberFormat}
          width="8%"
          dataAlign='center'
          isKey
        >
          <div>
            {getMessage("SR_NO")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='ip'
          width="15%"
          dataAlign='center'
          dataFormat={this.instanceId}
        >
          <div>
            {getMessage("INSTANCE_ID")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='cpuUsage'
          width="15%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.cpuUsage}
        >
          <div>
            {getMessage("CPU")} 
          </div>
          <div>
              (%) 
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='ramUsage'
          width="15%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.ramUsage}
        >
          <div>
            {getMessage("MEMORY")}
          </div>
          <div>
              (%) 
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='activeSessionsCount'
          width="15%"
          dataAlign='center'
          dataSort={true}
          sortFunc={this.sortByUsage}
          dataFormat={this.activeSessionsCount}
        >
          <div>
            {getMessage("TURN_SESSIONS")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='isProtected'
          width="15%"
          dataAlign='center'
          dataFormat={this.isProtected}
        >
          <div>
            {getMessage("SCALE_IN_PROTECTION")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='status'
          width="15%"
          dataAlign='center'
          dataFormat={this.statusCount}
        >
          <div>
            {getMessage("STATUS_COUNT")}
          </div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField='creationTime'
          width="23%"
          dataAlign='center'
          dataFormat={this.updatedAt}
        >
          <div>
            {getMessage("UPDATED_TIME")}
          </div>
        </TableHeaderColumn>

      </BootstrapTable>
    )
  }
  
  render(){
    return (
      <div className="clusterInfoListContainer">
        <RetireInstanceConfirmationModal
          showRetireInstanceConfirmationModal={
            this.state.showRetireInstanceConfirmationModal
          }
          handleRetireInstanceCancelClick={this.handleRetireInstanceCancelClick}
          handleRetireInstanceOkClick={this.handleRetireInstanceOkClick}
        />
        <React.Fragment>
          <div className="overflow-auto">
            <Row className="mainTitle flex5">{getMessage("KMS_TITLE")}</Row>

            <hr />

            {this.props.clusterData && this.props.clusterData.length > 0 ? (
              this.renderKmsStats()
            ) : (
              <NoData
                minHeight={"15vh"}
                message={getMessage("NO_KMS_SERVERS")}
              />
            )}
          </div>

          <div className="permissionButtons clusterInfoBtnsDiv">
            <Col className="clusterInfoBtns">
              <Button
                className="buttons customBtn retireInstanceBtn"
                disabled={!this.state.isAnyInstanceSelected}
                onClick={this.openModal}
              >
                {getMessage("RETIRE_INSTANCE")}
              </Button>
              <Button
                className="buttons customBtn refreshBtn"
                onClick={this.props.getClusterInfo}
              >
                {getMessage("REFRESH")}
              </Button>
            </Col>
            <Col sm={4} className="refreshLabelDiv">
              <label className="refreshLabel">
                {" "}
                {getMessage("LAST_REFRESH_TIME", {
                  refreshTime: this.props.lastRefreshTime,
                })}{" "}
              </label>
            </Col>
          </div>
        </React.Fragment>

        <hr />
        <React.Fragment>
          {this.props.usingTurnCluster && (
            <div>
              <div className="overflow-auto">
                <Row className="mainTitle flex5">{getMessage("TURN")}</Row>
                <hr />
                {this.props.turnClusterData &&
                this.props.turnClusterData.length > 0 ? (
                    this.renderTurnStats()
                  ) : (
                    <NoData
                      minHeight={"15vh"}
                      message={getMessage("NO_TURN_SERVERS")}
                    />
                  )}
              </div>
              <div className="permissionButtons clusterInfoBtnsDiv">
                <Col className="clusterInfoBtns">
                  <Button
                    className="buttons customBtn refreshBtnTurn"
                    onClick={this.props.getTurnClusterInfo}
                  >
                    {getMessage("REFRESH")}
                  </Button>
                </Col>
                <Col sm={4} className="refreshLabelDiv">
                  <label className="refreshLabel">
                    {" "}
                    {getMessage("LAST_REFRESH_TIME", {
                      refreshTime: this.props.lastRefreshTimeTurn,
                    })}{" "}
                  </label>
                </Col>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}