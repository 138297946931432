import { API_URL, ACTIONS, USER_ROLES } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class CoBrowseAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  startCoBrowseSession(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.CO_BROWSE_START_SESSION
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.CO_BROWSE.SET_IFRAME_URL,
            value: response.data.data
          })
          this.dispatch({
            type: ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
            uniqueId: data.uniqueId,
            value: true
          });
          resolve(response)
        },
        error => {
          reject(error);
        }
      );
    });
  }

  joinCoBrowseSession(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.CO_BROWSE_JOIN_SESSION
      }).then(
        response => {
          resolve(response.data);
          this.dispatch({
            type: ACTIONS.CO_BROWSE.SET_IFRAME_URL,
            value: response.data.data
          })
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  endCoBrowseSession(data) {    
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.CO_BROWSE_END_SESSION
      }).then(
        response => {
          resolve(response.data);
          this.endCoBrowseSessionAction();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  leaveCoBrowseSession(data) {    
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.CO_BROWSE_LEAVE_SESSION
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  updateCoBrowsePermissionRequest(flag) {
    this.dispatch({ 
      type: ACTIONS.CO_BROWSE.CO_BROWSE_REQUEST,
      value: flag
    });
  }

  setCoBrowseIFrameUrl(url){
    this.dispatch({ 
      type: ACTIONS.CO_BROWSE.SET_IFRAME_URL,
      value: url
    });
  }

  updateCoBrowsePermissionReply(flag) {
    this.dispatch({
      type: ACTIONS.CO_BROWSE.CO_BROWSE_REPLY,
      value: flag
    });
  }

  updateCoBrowseParticipants(list) {
    this.dispatch({
      type: ACTIONS.CO_BROWSE.SET_PARTICIPANT,
      value: list
    });
  }

  endCoBrowseSessionAction() {
    console.log("..................... endCoBrowseSessionAction calling RESET_CO_BROWSE_SESSION_DETAILS")
    this.dispatch({
      type: ACTIONS.CO_BROWSE.RESET_CO_BROWSE_SESSION_DETAILS,
      value: USER_ROLES.AGENT
    });
  }

  showCoBrowse(flag) {
    this.dispatch({
      type: ACTIONS.CO_BROWSE.CO_BROWSE_TOGGLE,
      value: flag
    })
  }
  
  resetCobrowseSessionDetails() {
    console.log("..................... resetCobrowseSessionDetails calling RESET_CO_BROWSE_SESSION_DETAILS")
    this.dispatch({
      type: ACTIONS.CO_BROWSE.RESET_CO_BROWSE_SESSION_DETAILS
    });
  }

  setCoBrowseSessionStatus(flag){
    this.dispatch({
      type: ACTIONS.CO_BROWSE.SET_CO_BROWSE_SESSION_STATUS,
      value: flag
    })
  }

  setCoBrowseParticipantStatus(uniqueId){
    this.dispatch({
      type: ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
      uniqueId,
      value: true
    });
  }
}
