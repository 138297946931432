import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func } from "prop-types";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { getMessage } from "CONFIG/i18n";
import * as log from "loglevel";
import { sessionActions as actionFactory } from "CONFIG/ActionFactory";
import { inviteeAction as actionfactory } from "CONFIG/ActionFactory";

import { selectSessionKey } from "../../Routes/selectors";

import "./AddInvitee.less";
import "../App/App.less";
import AddInvitee from "./AddInvitee";
import InviteHistory from "./InviteHistory";

// CONSTANTS
import { isIE } from "react-device-detect";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

class AddInviteeContainer extends Component {
  static propTypes = {
    show: bool,
    addInviteeHandle: func,
  };
  state = {
    addInviteeManual: false,
    addInviteeHistory: false,
    currentSessionInvitees: []
  };
  toggle = () => {
    this.props.addInviteeHandle();
  };
  addInviteeManualHandle = () => {
    this.toggle();
    this.setState({ addInviteeManual: !this.state.addInviteeManual });
  };
  addInviteeHistoryHandle = () => {
    this.toggle();
    this.setState({ addInviteeHistory: !this.state.addInviteeHistory });
  };
  handleSendClick = (invitees, value) => {
    if (value) {
      this.setState({ addInviteeManual: !this.state.addInviteeManual });
    } else {
      this.setState({ addInviteeHistory: !this.state.addInviteeHistory });
    }
    actionFactory.inviteUser(this.props.sessionKey, invitees, this.props.language).then(
      data => {
        toastr.info("INVITATION_SENT");
        log.info("Data:", data);
      },
      error => {
        toastr.info("INVITATION_NOT_SENT");
        log.error("ERROR: ", error);
      }
    );
  };
  handleAddInviteHistory = () => {
    actionfactory.addInviteeAction(this.props.sessionKey).then(
      data => {
        if (
          data.data.invitees === undefined ||
          data.data.invitees.length === 0
        ) {
          toastr.info("NO_INVITEES");
        } else {
          this.addInviteeHistoryHandle();
          this.setState({
            currentSessionInvitees: data.data.invitees
          });
        }
      },
      error => {
        log.error("ERROR: ", error);
      }
    );
  };

  handleManualEntry = () => {
    return (
      <AddInvitee
        show={this.state.addInviteeManual}
        addInviteeManualHandle={this.addInviteeManualHandle}
        handleSendClick={this.handleSendClick}
      />
    );
  };
  handleInviteHistory = () => {
    return (
      <InviteHistory
        show={this.state.addInviteeHistory}
        addInviteeHistoryHandle={this.addInviteeHistoryHandle}
        currentSessionInvitees={this.state.currentSessionInvitees}
        handleSendClick={this.handleSendClick}
      />
    );
  };
  render() {
    return (
      <div className="popUp">
        {this.handleManualEntry()}
        {this.handleInviteHistory()}
        <Modal
          isOpen={this.props.show}
          className="modal-md modalContainer modal-wrapper"
          xs="8"
        >
          <ModalHeader toggle={this.toggle} className="popupHeader addMemberHeader">
            {getMessage("ADD_MEMBER")}
          </ModalHeader>
          <ModalBody className="popupBody text-center">
            <div className="col-12 offset-sm-3 col-sm-6">
              <Button
                className="customBtn w-100"
                onClick={this.addInviteeManualHandle}
              >
                {getMessage("MANUAL_ENTRY")}
              </Button>
            </div>

            <br />
            <div className="col-12 offset-sm-3 col-sm-6">
              <Button
                className="customBtn w-100"
                onClick={this.handleAddInviteHistory}
              >
                {getMessage("INVITE_HISTORY")}
              </Button>
            </div>
            <br />
            <div className="col-12 offset-sm-3 col-sm-6">
              {isIE ? <Button 
                disabled={this.props.sessionLink ? false : true}
                className="customBtn w-100"
                onClick={()=>{
                  window.clipboardData && 
                  window.clipboardData.setData("Text", this.props.sessionLink);
                  if(window.clipboardData && window.clipboardData.getData("Text"))
                    toastr.info("SESSION_LINK_COPIED")
                  else
                    toastr.error("SESSION_LINK_NOT_COPIED")
                }}
              >
                {getMessage("COPY_SESSION_LINK")}
              </Button>
                : 
                <CopyToClipboard text={this.props.sessionLink}
                  onCopy={(text,result) =>  {
                    if(result)
                      toastr.info("SESSION_LINK_COPIED")
                    else
                      toastr.error("SESSION_LINK_NOT_COPIED")
                  }}>
                  <Button disabled={this.props.sessionLink ? false : true}
                    className="customBtn w-100"
                  >
                    {getMessage("COPY_SESSION_LINK")}
                  </Button>
                </CopyToClipboard>
              }
              
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    sessionKey: selectSessionKey(state),
    language: state.LanguageReducer.language,
  };
};
export default modalScrollBar(connect(mapStateToProps)(AddInviteeContainer));