import React, {Component} from "react";
import { getMessage } from "CONFIG/i18n";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from 'moment'
import Pagination from "react-js-pagination";
import { withOrientationChange, isMobileOnly } from "react-device-detect";
import _ from "lodash";

// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import { getStringWithoutExtraSpaces } from "UTILS/Utility";

//ASSETS
import icBack from "ASSETS/Images/ic_backarrow.svg";
import icClosedRow from "ASSETS/Images/ic_closed _row.svg";
import icExpandedRow from "ASSETS/Images/ic_expanded_row.svg";
import icHistory from "ASSETS/Images/ic_history.svg"

//UTILS
import { PAGE_COUNT } from "UTILS/Constants";

//STYLESHEET
import "./TrailHistory.less"
import "COMPONENTS/App/App.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

class TrailHistory extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedSessionId: "",
      searchTimeout: null,
      searchString:""
    };
  }

  backToMenuHandle = () =>{
   
    this.props.setTrailHistoryData(0, 10);
    this.props.backToMenu();
  }

  selectRow = (cell, row) => {
    return (
      <div className="selection-arrow">
        <img src={row.sessionKey === this.state.selectedSessionId ? icExpandedRow : icClosedRow} /> 
      </div>
    );
  };

  holdingDate = (cell, row) =>{
    let date = moment(row.startTime).format("YYYY/MM/DD");
    let time = moment(row.startTime).format("HH:mm")+" - "+moment(row.endTime).format("HH:mm")
    return (
      <>
        {this.state.selectedSessionId !== row.sessionKey ? (
          <div className="d-flex flex-column">
            <span
              className="text-wrap text-truncate custom-tooltip"
              id={`startDate${row.sessionKey}`}
            >
              {date}
              <CustomTooltip
                tooltipText={date}
                tooltipId={`startDate${row.sessionKey}`}
              />
            </span>
            <span
              className="text-wrap text-truncate custom-tooltip"
              id={`startTime${row.sessionKey}`}
            >
              {time}
              <CustomTooltip
                tooltipText={time}
                tooltipId={`startTime${row.sessionKey}`}
              />
            </span>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <span className="text-wrap columnText">{date}</span>
            <span className="text-wrap columnText">{time}</span>
          </div>
        )}
      </>
    );
  }

  onRowSelect =(row, isSelected, e) =>{
    this.setState({
      selectedSessionId: this.state.selectedSessionId !== row.sessionKey? 
        row.sessionKey : ""
    })
  }

  inviteeFormat = (cell, row) =>{
    const uniqeInvitees = _.uniq(row.invitees);
    return(
      this.state.selectedSessionId !== row.sessionKey ?
        <span className="text-wrap text-truncate w-100 custom-tooltip"  id={`invitee${row.sessionKey}`}>
          {uniqeInvitees[0] &&  uniqeInvitees[0] }
          <CustomTooltip  
            tooltipText={uniqeInvitees.join(", ")} 
            tooltipId={`invitee${row.sessionKey}`}
          />
        </span> :
        <div className="d-flex flex-column"> {
          uniqeInvitees.map((invitee,i)=>(
            <span className="text-wrap columnText w-100">
              {invitee}      
            </span>
          ))
        }
        </div>
    )
  }

  sessionName = (cell, row) => {
    if (this.state.selectedSessionId === row.sessionKey) {
      return (
        <span className="text-wrap columnText">
          {row.sessionName}
        </span>
      );
    }
    return (
      <span
        className="text-wrap text-truncate custom-tooltip"
        id={`sessionName${row.sessionKey}`}
      >
        {row.sessionName}
        <CustomTooltip
          tooltipText={row.sessionName}
          tooltipId={`sessionName${row.sessionKey}`}
        />
      </span>
    );
  };

  changePage =(displayPage)=>{
    this.getPastSessionsList(
      this.props.sessionListPageSize,
      displayPage - 1,
      this.state.searchString ? this.state.searchString : undefined
    );
  }
  
  changePageCount = (value) =>{
    this.getPastSessionsList(
      value,
      0,
      this.state.searchString ? this.state.searchString : undefined
    );
  }

  search = (event) => {
    let searchData = event.target.value;
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    if (this.state.searchString !== searchData)
      this.setState({
        searchString: searchData,
        searchTimeout: setTimeout(() => {
          this.getPastSessionsList(
            this.props.sessionListPageSize,
            0,
            getStringWithoutExtraSpaces(searchData)
          );
        }, 1000),
      });
  };

  getPastSessionsList = (pageSize, displayPage, searchKey)=>{
    this.props.getPastSessionsList(
      pageSize,
      displayPage,
      searchKey ? searchKey : undefined
    )
    var trailHistory = document.getElementById("trailHistory");  
    const tableData = trailHistory.querySelector(".react-bs-container-body")
    
    if(tableData){
      tableData.scrollTop = 0;
    }
  }   

  inviteeAndTitleFormat = (cell, row) => {
    const uniqeInvitees = _.uniq(row.invitees);
    return (
      <>
        {this.state.selectedSessionId !== row.sessionKey ? (
          <>
            <span
              className="text-wrap text-truncate w-100 custom-tooltip"
              id={`invitees${row.sessionKey}`}
            >
              {uniqeInvitees[0] && uniqeInvitees[0]}
              <CustomTooltip
                tooltipText={uniqeInvitees.join(", ")}
                tooltipId={`invitees${row.sessionKey}`}
              />
            </span>
            <span
              className="text-wrap text-truncate custom-tooltip"
              id={`sessionName${row.sessionKey}`}
            >
              {row.sessionName}
              <CustomTooltip
                tooltipText={row.sessionName}
                tooltipId={`sessionName${row.sessionKey}`}
              />
            </span>
          </>
        ) : (
          <>
            <div className="d-flex flex-column">
              {uniqeInvitees.map((invitee, i) => (
                <span
                  className="text-wrap columnText w-100"
                >
                  {invitee}
                </span>
              ))}
            </div>

            <br />
            <span className="text-wrap columnText ">{row.sessionName}</span>
          </>
        )}
      </>
    )
  }

  allFormat = (cell, row) =>{
    const uniqeInvitees = _.uniq(row.invitees);
    return(
      <>
      {this.state.selectedSessionId !== row.sessionKey ? (
          <>
            <div className="d-flex flex-column">
              <span className="text-wrap text-truncate">{moment(row.startTime).format("YYYY/MM/DD")}</span>
              <span className="text-wrap text-truncate">
                {moment(row.startTime).format("HH:mm") +
                  " - " +
                  moment(row.endTime).format("HH:mm")}
              </span>
            </div>
            <span
              className="text-wrap text-truncate w-100 custom-tooltip"
              id={`invitee${row.sessionKey}`}
            >
              {uniqeInvitees[0] && uniqeInvitees[0]}
              <CustomTooltip
                tooltipText={uniqeInvitees.join(", ")}
                tooltipId={`invitee${row.sessionKey}`}
              />
            </span>
            <span
              className="text-wrap text-truncate custom-tooltip"
              id={`sessionName${row.sessionKey}`}
            >
              {row.sessionName}
              <CustomTooltip
                tooltipText={row.sessionName}
                tooltipId={`sessionName${row.sessionKey}`}
              />
            </span>
          </>
      ) : (
          <>
          <div className="d-flex flex-column">
            <span className="text-wrap columnText" id={`startDate${row.sessionKey}`}>
              {moment(row.startTime).format("YYYY/MM/DD")}
              <CustomTooltip
                tooltipText= {moment(row.startTime).format("YYYY/MM/DD")}
                tooltipId={`startDate${row.sessionKey}`}
              />
            </span>
            <span className="text-wrap columnText" id={`startTime${row.sessionKey}`}>
              {moment(row.startTime).format("HH:mm") +
                " - " +
                moment(row.endTime).format("HH:mm")}
              <CustomTooltip
                tooltipText={
                  moment(row.startTime).format("HH:mm") +
                  " - " +
                  moment(row.endTime).format("HH:mm")
                }
                tooltipId={`startTime${row.sessionKey}`}
              />
            </span>
          </div>
            <div className="d-flex flex-column">
              {uniqeInvitees.map((invitee, i) => (
                <span
                  className="text-wrap columnText w-100"
                  id={`invitees${i}`}
                >
                  {invitee}
                </span>
              ))}
            </div>

            <br />
            <span className="text-wrap columnText">{row.sessionName}</span>
          </>
      )}
      </>
    )
  }
  renderTrailHistory = () =>{
    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: this.onRowSelect,
      bgColor: "#FFF5E6",
    };
    return (
      <>
        <BootstrapTable
          data={this.props.pastSessionsDetails.sessions}
          id="trailHistoryTable"
          containerClass="trailHistoryContainer custom-list px-3"
          bordered={false}
          selectRow={selectRowProp}
          printable
        >
          {/* column hidden always */}
          <TableHeaderColumn
            dataField="sessionKey"
            searchable={false}
            hidden={true}
            isKey
          >
            {getMessage("SESSION_KEY")}
          </TableHeaderColumn>

          {/* column visible for all devices */}
          <TableHeaderColumn
            searchable={false}
            dataFormat={this.selectRow}
            width={isMobileOnly ? "11%" : "8%"}
            dataAlign="right"
            columnClassName="hide-ellipsis"
          ></TableHeaderColumn>

          {/* column visible for desktop, tablet and horizontal mobile */}
          <TableHeaderColumn
            dataField="holding_date"
            dataFormat={this.holdingDate}
            width={this.props.isLandscape ? "25%" : "20%"}
            hidden={isMobileOnly && !this.props.isLandscape}
            dataAlign="left"
          >
            {getMessage("HOLDING_DATE")}
          </TableHeaderColumn>

          {/* column hidden only for mobile */}
          <TableHeaderColumn
            dataFormat={this.inviteeFormat}
            dataField="invitees"
            dataAlign="left"
            hidden={isMobileOnly}
          >
            {getMessage("GUEST_CONTACT")}
          </TableHeaderColumn>

          {/* column hidden only for mobile */}
          <TableHeaderColumn
            className="p-0"
            dataFormat={this.sessionName}
            dataField="sessionName"
            hidden={isMobileOnly}
          >
            {getMessage("MEETING_TITLE")}
          </TableHeaderColumn>

          {/* Column is visible for horizontal mobile */}
          <TableHeaderColumn
            dataField="inviteesAndTitle"
            dataAlign="left"
            hidden={!this.props.isLandscape || !isMobileOnly}
            width={this.props.isLandscape ? "67%" : "0%"}
            dataFormat={this.inviteeAndTitleFormat}
          >
            {getMessage("CUSTOMER_NAME")}
            <br />
            {getMessage("MEETING_TITLE")}
          </TableHeaderColumn>

          {/* Column is hidden for desktop and tablet and horizontal mobile */}
          <TableHeaderColumn
            dataField="all"
            dataAlign="left"
            hidden={!isMobileOnly}
            dataFormat={this.allFormat}
          >
            {getMessage("HOLDING_DATE")}
            <br />
            {getMessage("CUSTOMER_NAME")}
            <br />
            {getMessage("MEETING_TITLE")}
          </TableHeaderColumn>
        </BootstrapTable>
      </>
    );
  }

  render(){
    
    return (
      <div className="trailHistory">
        <div className="col-xl-2 col-12 d-flex align-items-center backBtn pl-4 pt-2">
          <img
            src={icBack}
            className="pr-2 cursorPointer"
            onClick={this.backToMenuHandle}
          />
          <span className="cursorPointer" onClick={this.backToMenuHandle}>
            {getMessage("BACK")}
          </span>
        </div>
        <div className="container-fluid trailHistory">
          <div className="row">
            <div className="col-sm-12 text-center">
              <img
                src={icHistory}
                className="img-fluid session-img createSessionImage"
                alt="Start Session"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center pb-4">
              <span className="sessionMenuTitle" lang={this.props.language}>
                {getMessage("TRAIL_HISTORY")}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="trailHistoryContainer py-2 col-xl-8 px-0">
              <div className="d-flex justify-content-center">
                <div className="col-9 col-xl-7 d-flex justify-content-center">
                  <input
                    className="formControl form-control"
                    value={this.props.searchKey}
                    placeholder={getMessage("SEARCH_TRAIL_HISTORY_PLACEHOLDER")}
                    onChange={this.search}/>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-8 trail-history-table-margin px-0"
            id="trailHistory"
          >
            <div className="d-flex justify-content-center margin-bottom">
              {this.props.pastSessionsDetails.sessions &&
              this.props.pastSessionsDetails.sessions.length > 0 ? (
                  this.renderTrailHistory()
                ) : (
                  <NoData minHeight={"49vh"}
                    message={this.state.searchString ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                      searchKey: this.state.searchString,
                    }):getMessage("NO_HISTORY")}/>
                )}
            </div>
          </div>

          { this.props.pastSessionsDetails.sessions && this.props.totalSessions > 0 &&
          <div className={"pagination-margin " + (this.props.isLandscape ? "col-xl-8 offset-xl-2 d-flex px-md-auto px-0 pt-2 pb-auto pb-2 justify-content-between" : "col-xl-8 offset-xl-2 d-md-flex px-md-auto px-0 pt-2 pb-auto pb-2 justify-content-between")}>
            <div className="paginationEntries">
              {getMessage("PAGINATION_TOTAL_ENTRIES",
                {from: (this.props.displayPage) * this.props.sessionListPageSize +1,
                  to: (this.props.displayPage) * this.props.sessionListPageSize + 
                  this.props.pastSessionsDetails.sessions.length,
                  totalCount: this.props.totalSessions})}
            </div>
            {this.props.totalSessions > this.props.sessionListPageSize &&
            <Pagination
              activePage={this.props.displayPage + 1}
              itemsCountPerPage={this.props.sessionListPageSize}
              totalItemsCount={this.props.totalSessions}
              pageRangeDisplayed={3}
              onChange={this.changePage}
              itemClass="page-item"
              linkClass="page-link"
              innerClass={this.props.isLandscape? "pagination pr-4": "pagination"}
            />
            }
            {/* when in mobile vertical width needs to be reduced to 25% from 100% */}
            <div className={isMobileOnly && !this.props.isLandscape ? "sessionCount w-25":"sessionCount"} >
              <CustomDropdown
                options={PAGE_COUNT}
                onChange={this.changePageCount}
                value={this.props.sessionListPageSize}
                dropdownClass="pageSizeDropdown"
              />
            </div>
          </div>
          }
        </div>
      </div>
    )
  }
}

export default withOrientationChange(TrailHistory);