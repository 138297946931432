export const selectCurrentParticipantUniqueId = state =>
  state.SessionReducer.uniqueId;
export const selectSessionKey = state =>
  state.SessionReducer.sessionDetails.sessionKey;
export const selectSnapshoteeId = state => state.SessionReducer.snapshoteeId;
export const selectSnapshoterId = state => state.SessionReducer.snapshoterId;
export const selectSnapshotPermissionReply = state =>
  state.SessionReducer.snapshotPermissionReply;
export const selectIsSnapshotTaken = state =>
  state.SessionReducer.isSnapshotTaken;
export const selectOrganiser = state => {
  return state.RoomMetaDataReducer.roomMetaData.organiser;
};
export const selectShowSnapshot = state => {
  return state.SessionReducer.showSnapshot;
};
export const getComponentInFocus = state =>{
  return state.SessionReducer.componentInFocus;
};
export const getSaveSessionDataFlag = state => {
  return state.SessionReducer.saveSessionDataFlag;
};
export const getSessionType = state => {
  return state.SessionReducer.sessionType;
};
export const selectOrganiserParticipantId = state => {
  let organiser = state.ParticipantReducer.participants.filter(participant => participant.role === "AGENT")[0];
  return organiser ? organiser.participantId : null;
}
export const selectIsSnapshotStarted = state => {
  return state.SnapshotReducer.isSnapshotStarted
}

export const getSessionInvalidFlag = state => {
  return state.SessionReducer.isSessionInvalid;
}
export const getSessionDetails = state => {
  return state.SessionReducer.sessionDetails;
}