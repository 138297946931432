import React, { Component } from "react";
import { isIE } from "react-device-detect";
import { Row } from "react-bootstrap";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";
import toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import {
  mediaType,
  mediaStatus
} from "WEBSOCKET/constants";
import { wsocket } from "WEBSOCKET/wsocket";
import { getMessage } from "CONFIG/i18n";
import { audioVideoAction } from "CONFIG/ActionFactory";
import { isRealwearDevice } from "UTILS/Utility";

import ic_call_cut from "ASSETS/Images/ic_call_cut_filled.svg";	

class IconsPositionBar extends Component {
  componentDidMount() {
    if (isRealwearDevice() && this.props.participant.uniqueId === this.props.uniqueId) {
      if ((this.props.participant.audioStatus === mediaStatus.MUTED
        || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT)) {
        this.voiceCommandUnmuteHandler();
      } else {
        this.voiceCommandMuteHandler();
      }
    }
  }
  
  componentDidUpdate(prevProp) {
    if (isRealwearDevice()
      && this.props.participant.uniqueId === this.props.uniqueId
      && this.props.participant.audioStatus !== prevProp.participant.audioStatus) {
      if (this.props.participant.audioStatus === mediaStatus.MUTED
        || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT) {
        this.voiceCommandUnmuteHandler();
      } else {
        this.voiceCommandMuteHandler();
      }
    }
  }

  voiceCommandMuteHandler(){
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_MUTE_AUDIO")
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_UNMUTE_AUDIO");
    const muteAudio = {
      voiceCommand: "VOICE_COMMANDS_MUTE_AUDIO",
      action: () => {
        this.muteUnmuteAudioHandler(mediaStatus.MUTED);
      }
    }  
    this.props.handleVoiceCommand(muteAudio);
  }

  voiceCommandUnmuteHandler(){
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_MUTE_AUDIO");
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_UNMUTE_AUDIO");
    const unmuteAudio = {
      voiceCommand: "VOICE_COMMANDS_UNMUTE_AUDIO",
      action: () => {
        this.muteUnmuteAudioHandler(mediaStatus.UNMUTED);
      }
    }  
    this.props.handleVoiceCommand(unmuteAudio);
  }
    
  muteUnmuteAudioHandler(audioStatus){
    if(this.props.participant.uniqueId === this.props.uniqueId
      && this.props.participant.audioStatus !== mediaStatus.MUTED_BY_AGENT) {
      if(!isIE) {
        let sender = wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["AUDIO"].peerConnection.getSenders().find(function (s) {
          return s.track.kind == 'audio';
        });
        if(sender && sender.track) {
        // if audioStatus is muted enable audio for unmuting
          sender.track.enabled = this.props.participant.audioStatus === mediaStatus.MUTED
            || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT ? true : false;
          console.log('AUDIO sender.track.enabled: ', sender.track.enabled);
        }
      }
      audioVideoAction.setParticipantMediaStatus({
        uniqueId: this.props.uniqueId,
        audioStatus: audioStatus
      });
      audioVideoAction.toggleMedia(
        this.props.sessionKey,
        mediaType.AUDIO,
        audioStatus,
        this.props.participant.uniqueId
      );
    } else if(this.props.participant.uniqueId === this.props.uniqueId
      && this.props.participant.audioStatus == mediaStatus.MUTED_BY_AGENT) {
      toastr.info("REQUEST_AGENT_TO_UNMUTE_AUDIO");
    }
  }

  render(){
    return(
      <div
        className={this.props.parentClassname}>
        <div
          id={"iconsPosition" + this.props.participant.uniqueId}
          className={"positionIcons audioVideoCallIcons " + this.props.className}
          // {this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO
          //   ? "annotationBar setBackground"
          //   :"positionIcons"}
        >
          <div className={this.props.iconsDivClassname}>
            <Row className="marginAuto">
            <>
              <img
                className={this.props.participant.uniqueId === this.props.uniqueId
                  ? "audioIcon cursorPointer pl-1" 
                  : "audioIcon cursorDisabled pl-1"
                }
                onClick={(e) => {
                  // restrict click of audio/video box
                  e.stopPropagation();
                  this.props.participant.audioStatus === mediaStatus.MUTED
                    || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT ?
                    this.muteUnmuteAudioHandler(mediaStatus.UNMUTED)
                    :
                    this.muteUnmuteAudioHandler(mediaStatus.MUTED)
                }
                }
                src={(this.props.participant.audioStatus === mediaStatus.MUTED
                  || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT)
                  ? this.props.audioOff
                  : this.props.audioOn
                }
                
                id={this.props.tooltipAudioId}
              />
                {this.props.participant.uniqueId === this.props.uniqueId &&
                  <CustomTooltip
                    tooltipText={
                      this.props.participant.uniqueId === this.props.uniqueId
                        ? this.props.participant.audioStatus ===
                            mediaStatus.MUTED ||
                          this.props.participant.audioStatus ===
                            mediaStatus.MUTED_BY_AGENT
                          ? getMessage("UNMUTE_AUDIO")
                          : getMessage("MUTE_AUDIO")
                        : ""
                    }
                    tooltipId={this.props.tooltipAudioId}
                  />
                }
            </>
            {
              this.props.participant.mediaType === mediaType.AUDIO_VIDEO && (
                <>
                <img
                  className=
                    {this.props.participant.uniqueId === this.props.uniqueId 
                      ? "videoIcon cursorPointer pl-1"
                      : "videoIcon cursorDisabled pl-1"
                    }
                  onClick={(e) => {
                    // restrict click of audio/video box
                    e.stopPropagation()
                    if (this.props.participant.uniqueId === this.props.uniqueId
                      && this.props.participant.videoStatus !== mediaStatus.MUTED_BY_AGENT) {
                      if(!isIE) {
                        let sender = wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["VIDEO"].peerConnection.getSenders().find(function (s) {
                          return s.track.kind == 'video';
                        });
                        if(sender && sender.track) {
                        // if videoStatus is muted enable video for unmuting
                          sender.track.enabled = this.props.participant.videoStatus === mediaStatus.MUTED
                            || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT ? true : false;
                          console.log('VIDEO sender.track.enabled: ', sender.track.enabled);
                        }
                      }
                      audioVideoAction.setParticipantMediaStatus({
                        uniqueId: this.props.uniqueId,
                        videoStatus: this.props.participant.videoStatus === mediaStatus.MUTED
                        || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT
                          ? mediaStatus.UNMUTED
                          : mediaStatus.MUTED
                      });
                      audioVideoAction.toggleMedia(
                        this.props.sessionKey,
                        mediaType.AUDIO_VIDEO,
                        (this.props.participant.videoStatus === mediaStatus.MUTED
                          || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
                          ? mediaStatus.UNMUTED 
                          : mediaStatus.MUTED,
                        this.props.participant.uniqueId
                      )
                    } else if(this.props.participant.uniqueId === this.props.uniqueId
                      && this.props.participant.videoStatus == mediaStatus.MUTED_BY_AGENT) {
                      toastr.info("REQUEST_AGENT_TO_UNMUTE_VIDEO");
                    }             
                  }}
                  src={
                    (this.props.participant.videoStatus === mediaStatus.MUTED
                    || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT)
                      ? this.props.videoOff
                      : this.props.videoOn
                  }
                  id={this.props.tooltipVideoId}
                />
                  {this.props.participant.uniqueId === this.props.uniqueId &&
                  <CustomTooltip
                    tooltipText={this.props.participant.uniqueId === this.props.uniqueId
                      ? (this.props.participant.videoStatus === mediaStatus.MUTED
                      || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT)
                        ? getMessage("UNMUTE_VIDEO")
                        : getMessage("MUTE_VIDEO")
                      : ""
                    }
                    tooltipId={this.props.tooltipVideoId}

                  />}
                </>

              )
            }
            {this.props.organiser === this.props.uniqueId && this.props.selfIcons &&
              (
                <>
                  <img
                    src={ic_call_cut}
                    id="endCall"
                    className="videoIcon cursorPointer px-md-1 px-0 m-0"
                    onClick={() => {
                      this.props.isVideo ?
                        this.props.onOpenCloseEndVideoCallConfirmationModal(true) :
                        this.props.onOpenCloseEndAudioCallConfirmationModal(true);
                    }}
                  />
                  <CustomTooltip
                    tooltipText={getMessage("END_CALL")}
                    tooltipId="endCall"
                  />
                </> 
              )}
            </Row>
           
          </div>
        </div>
      </div>
    );
  }
}

export default withRealWear(IconsPositionBar);