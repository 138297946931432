import React from "react";

import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CONFIG, USER_ROLES } from "UTILS/Constants";

import ResetPassword from "./ResetPassword";

import { resetPasswordAction } from "CONFIG/ActionFactory";

// Strings
import { getMessage } from "CONFIG/i18n";
import "./ResetPassword.less";
import { ERROR } from "../../Utils/Constants";

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      errorMessage: "",
      isMailSend: false,
      isPasswordSet: false
    };
  }

  componentDidMount() {
    //TODO:check event.state value 
    //TODO: check if we can remove this listener

    window.addEventListener('popstate', this.returnSignIn);
  }

  handleSendEmailClick = (data, errorCallback) => {
    this.setState({ errorMessage: "" });

    resetPasswordAction.resetAction(data).then(
      response_data => {
        this.setState({ isMailSend: true });
      },
      error => {
        if(error.code === ERROR.INVALID_USER) {
          error.code = 'EMAIL_NOT_FOUND';
        }
        this.setState({ 
          errorMessage: getMessage(error.code) ? getMessage(error.code) : error.message 
        });
        errorCallback && errorCallback();
      }
    );
  };

  returnSignIn =()=>{
    if(this.props.userRole == USER_ROLES.SUPER_ADMIN)
      this.props.history.push(CONFIG.path.superAdminLogin);
    else if(this.props.userRole == USER_ROLES.ADMIN)
      this.props.history.push(CONFIG.path.adminLogin);
    else 
      this.props.history.push(CONFIG.path.login);
  }

  render() {
    return (
      <div>
        <ResetPassword
          handleSendEmailClick={this.handleSendEmailClick}
          errorMessage={this.state.errorMessage}
          username={this.state.username}
          isMailSend={this.state.isMailSend}
          language={this.props.language}
          returnSignIn={this.returnSignIn}
        />
      </div>
    );
  }

  componentWillUnmount() {
    //toast.removeByType("success");
    //toast.removeByType("error");
    toast.dismiss()
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    userRole: state.UserReducer.userDetails.roleByPath,
  };
};

export default  withRouter(connect(
  mapStateToProps
)(ResetPasswordContainer));
