import isOnline from "is-online";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import { ERROR } from "UTILS/Constants";

/*
* This function checks if user is offline and displays network error 
* (If navigator.onLine returns true, re-verify network state using isOnline)
*/
export const isNetworkAvailable = async (onlineCallBack) => {
  if (!navigator.onLine) {
    //If User is offline ,he must see the network Error
    toastr.error(ERROR.NETWORK_ERROR);
    return;
  }
  // You cannot assume that a true value necessarily means that the browser can access the internet.
  isOnline().then(
    onlineStatus => {
      if (onlineStatus) {
        onlineCallBack();
      } else {
        //If User is offline ,he must see the network Error
        toastr.error(ERROR.NETWORK_ERROR);
      }
    }
  )
}

/**
 * Network online handler to remove event listeners
 */
export const onNetworkOnlineHandler = () => {

  // Remove autocomplete attribute from all input fields
  $(document).ready(function () {
    $(document).on('focus', ':input', function () {
      $(this).removeAttr('autocomplete');
    });
  });

  // Allow user to click on increment/decrement arrows when online
  // Find all numeric inputs
  var numericInputs = document.getElementsByClassName("react-numeric-input no-click");
  // Remove css class for allowing pointer events
  for(let index = 0; index < numericInputs.length; index++) {
    let child = numericInputs[index];
    child.classList.remove("no-click");
  }

  document.removeEventListener("click", onClickHandler, true);
  document.removeEventListener("keypress", onKeyPressHandler, true);
  document.removeEventListener("keydown", onKeyDownHandler, true);
  document.removeEventListener("change", onKeyChangeHandler, true);
}

/**
 * Network offline handler to add event listeners
 */
export const onNetworkOfflineHandler = () => {

  // Set autocomplete attribute to random string 'nope' for all input fields as chrome ignores autocomplete=off
  $(document).ready(function () {
    $(document).on('focus', ':input', function () {
      $(this).attr('autocomplete', 'nope');
    });
  });

  // Prevent user from clicking on increment/decrement arrows when offline
  // Find all numeric inputs
  var numericInputs = document.getElementsByClassName("react-numeric-input");

  // Add css class for preventing pointer events
  for(let index = 0; index < numericInputs.length; index++) {
    let child = numericInputs[index];
    child?.classList?.add("no-click");
  }

  document.addEventListener("click", onClickHandler, true);
  document.addEventListener("keypress", onKeyPressHandler, true);
  document.addEventListener("keydown", onKeyDownHandler, true);
  document.addEventListener("change", onKeyChangeHandler, true);
}

/**
 * Handler for keypress events when user goes offline
 * @param {*} e event object
 */
export const onKeyPressHandler = (e) => {
  stopEventPropagation(e);
}  

/**
 * Handler for key down events when user goes offline
 * @param {*} e event object
 */
export const onKeyDownHandler = (e) => {
  stopEventPropagation(e);
}  

/**
 * Handler for key change events when user goes offline
 * @param {*} e event object
 */
export const onKeyChangeHandler = (e) => {
  stopEventPropagation(e);
}  

/**
 * Handler for click event when user goes offline
 * @param {*} e event object
 */
export const onClickHandler = (e) => {  
  var cursored = $(e.target).css('cursor');

  // Get all children of clicked element to check if clicked element is react numeric input
  let children = e.target?.children;
  let clickedNumericInput = false;

  // Check if clicked element is react numeric input
  for(let index = 0; index < children.length; index++) {
    let child = children[index];
    if (child?.classList?.contains("react-numeric-input")) {
      clickedNumericInput = true;
    }
  }
  
  if (cursored == 'pointer' || clickedNumericInput) {
    stopEventPropagation(e);
  }
}

/**
 * Function to stop event propagation and display network error toast
 * @param {*} e event object
 */
export const stopEventPropagation = (e) => {
  toastr.error(ERROR.NETWORK_ERROR);
  e.stopPropagation();
  e.preventDefault();
}  
