import React, { Component } from "react";
import { connect } from "react-redux";
import { getMessage } from "CONFIG/i18n";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import * as log from "loglevel";

// Components
import Spinner from "../Spinner/Spinner";

// Utilities
import { CONFIG, ERROR } from "UTILS/Constants";

// Actions
import { logoutAction } from "CONFIG/ActionFactory";

class SSOLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false
    }
  }

  componentDidMount() {
    this.setState({
      spinnerVisibility: true
    });
    logoutAction.logoutAction().then((response) => {
      this.setState({
        spinnerVisibility: false
      })
      this.props.history.push(CONFIG.path.login);
    }, (error) => {
      console.error('logoutWithSSOAction error: ', error);
      this.setState({
        spinnerVisibility: false
      });
      toastr.error(error.code);
      log.error("Error:", error);
      // TODO: handle failure case
    })
  }
  render() {
    return (
      <Spinner showSpinner={this.state.spinnerVisibility}/>
    )
  }
}

const mapStateToProps = state => {
  return {
    roleByPath: state.UserReducer.userDetails.roleByPath
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SSOLogout);
