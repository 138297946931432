//upload xlsx file only
import React from "react";
import './BulkImportUserModal.less'
import { func } from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

// Strings
import { getMessage } from "CONFIG/i18n";
import { supportedMIMETypesForExcel } from "../../Utils/Constants"
import "../App/App.less";
import "./AdminDashboard.less";
import BulkImportUserErrorTable from "./BulkImportUserErrorTable";
import { validateBulkImportUserFile } from "UTILS/Utility";

class BulkImportUserModal extends React.Component {
  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };
  
  constructor(props) {
    super(props);
    this.state = {
      errorData: null,
      errorMessage: '',
      fileName: '',
      fileSelected: false,
      isBulkImportSuccess: false,
      isImportStarted: false 
    };
       
  }

  handleBulkImportClick = () => {
    this.setState({
      isImportStarted: true
    });
    const fileInput = document.getElementById("fileUpload"); 
    const file = fileInput.files[0];
    this.props.handleBulkImportUsers(file, (errorData, errorMessage, isBulkImportSuccess) => {  
      if(errorMessage) {
        this.setState({ 
          errorData,
          errorMessage ,
          isBulkImportSuccess
        });
      }
      
      this.setState({ 
        isImportStarted: false
      });
    });
    //showToast: false to not display toastr, since form error is already handled by the bulk import API.
    this.props.getAllUserData(0, this.props.userListPageSize, undefined, false);
  };

  handleCloseClick = () => {
    this.setState ({ 
      errorMessage: "",
      fileName: "",
      isBulkImportSuccess: false,
      errorData: null,
    });
    this.props.handleCloseBulkImportUsers();
  };

  onInputFileClick = (event) => {
    event.target.value = '';
    this.setState ({
      errorMessage: "",
      isBulkImportSuccess: false,
      errorData: null,
      isImportStarted: false,
      fileName:"",
      fileSelected:false
    });
  }

  onFileSelect = (e) => {
    const fileValidationResult = validateBulkImportUserFile(e.target.files[0]);
    
    if( fileValidationResult.valid ) {
      this.setState ({
        errorMessage: "",
        fileSelected: true,
        isBulkImportSuccess: false,
        errorData: null,
        fileName: e.target.files[0].name,
      });
     
    }else{
      this.setState ({
        errorMessage: fileValidationResult.error,
        fileName: e.target.files[0].name
      });
    }
  }
  
  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCloseClick}
        >
          {getMessage("CLOSE")}
        </Button>
        { this.state.fileSelected && !this.state.errorMessage &&
        <Button 
          id="importUserBtn"
          onClick={this.handleBulkImportClick}
          className="buttons customBtn"
        >
          {getMessage("IMPORT")}
        </Button>
        }
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="addOrganisationModal userAddModal bulkImport"
        toggle={this.handleCloseClick}
        onClosed={() => this.setState ({
          errorMessage: '',
          fileName: '',
          fileSelected: false,
          isBulkImportSuccess: false,
          errorData: null,
          isImportStarted: false
        })}
        backdrop="static"
      >
        <ModalHeader className="popupHeader">
          {getMessage("BULK_IMPORT")}
        </ModalHeader>
        <ModalBody>
          { !this.state.errorData && 
          <Form>
            {!this.state.isImportStarted && !this.state.isBulkImportSuccess &&
            <FormGroup className="bulkImportAlignment">
              <Input
                name="fileName"
                id="fileName"
                className="inputFormControl bulkInputFileName"
                disabled
                value={this.state.fileName}
                placeholder={getMessage("SELECT_FILE_PLACEHOLDER")}
              />
              <Label className = 'buttons borderBtn bgwhite bulkTextCenter'> { getMessage("CHOOSE_FILE" ) }
                <Input
                  type="file"
                  name="fileUpload"
                  id="fileUpload"
                  className=""
                  hidden
                  accept= {supportedMIMETypesForExcel}
                  onChange={this.onFileSelect}
                  onClick={this.onInputFileClick}
                >
                </Input>
              </Label>
            </FormGroup>
            }
            
            {
              this.state.isImportStarted && 
              <Label className='fileLabel'>
                {`${getMessage("IMPORT_FROM")} ${this.state.fileName}....`}
              </Label>
            }           
            
            {
              this.state.errorMessage &&
              <div className={this.state.isBulkImportSuccess ? "bulkSuccess" : "bulkErrors"}>
                {this.state.errorMessage}
              </div>
            }
          </Form> 
          }
          {
            this.state.errorData &&
            <>
              <h3 className="errors"> {this.state.errorMessage}</h3>
              <BulkImportUserErrorTable 
                className="errorTable"
                errorRecordList={this.state.errorData}
              />
            </>
          }
        </ModalBody>
        
        <ModalFooter className="permissionButtons">
          { this.renderButtons() }
        </ModalFooter>
      
      </Modal>
    );
  }
}

export default modalScrollBar(BulkImportUserModal);
