exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../../Assets/Images/ic_checkbox_unselected.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../../Assets/Images/ic_checkbox_multi_selected.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../../Assets/Images/ic_checkbox_all_selected.svg"));

// Module
exports.push([module.id, ".customised-header-checkbox input[type=\"checkbox\"] {\n  display: none;\n}\n.customised-header-checkbox span {\n  content: url(" + ___CSS_LOADER_URL___0___ + ");\n  display: inline-block;\n  vertical-align: middle;\n  height: 1rem;\n  width: 1rem;\n  margin: 0.375rem;\n  cursor: pointer;\n}\n.customised-header-checkbox-multi-selected span {\n  content: url(" + ___CSS_LOADER_URL___1___ + ") !important;\n  cursor: pointer;\n}\n.customised-header-checkbox-all-selected :checked + span {\n  content: url(" + ___CSS_LOADER_URL___2___ + ") !important;\n}\n", ""]);

