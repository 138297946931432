import React, { Component } from "react";
import { Button, Input} from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import { socketMessage } from "WEBSOCKET/constants";

import { ANNOTATIONS, ACTIONS, USER_ROLES, LANGUAGE, FONT_SIZE } from "UTILS/Constants";
import { CANVAS_TYPES, FONT_FAMILY } from "UTILS/Constants";
import "./FloatedAnnotations.less";
import { func } from "prop-types";

import ic_text from "ASSETS/Images/FloatedAnnotations/ic_text.svg";
import ic_text_selected from "ASSETS/Images/FloatedAnnotations/ic_text_selected.svg";
import ic_ellipse from "ASSETS/Images/FloatedAnnotations/ic_ellipse.svg";
import ic_ellipse_selected from "ASSETS/Images/FloatedAnnotations/ic_ellipse_selected.svg";
import ic_freehand from "ASSETS/Images/FloatedAnnotations/ic_freeehand.svg";
import ic_freehand_selected from "ASSETS/Images/FloatedAnnotations/ic_freeehand_selected.svg";
import ic_line from "ASSETS/Images/FloatedAnnotations/ic_line.svg";
import ic_line_selected from "ASSETS/Images/FloatedAnnotations/ic_line_selected.svg";
import ic_rectangle from "ASSETS/Images/FloatedAnnotations/ic_rectangle.svg";
import ic_rectangle_selected from "ASSETS/Images/FloatedAnnotations/ic_rectangle_selected.svg";
import ic_rubber from "ASSETS/Images/FloatedAnnotations/ic_rubber.svg";
import ic_rubber_selected from "ASSETS/Images/FloatedAnnotations/ic_rubber_selected.svg";
import ic_rubber_disabled from "ASSETS/Images/FloatedAnnotations/ic_erazer_disabled.svg";

import delete_object from "ASSETS/Images/ic_dustbin_unselected.svg";
import delete_object_selected from "ASSETS/Images/ic_dustbin_selected.svg";
import delete_object_disabled from "ASSETS/Images/FloatedAnnotations/ic_dustbin_disabled.svg";

import eraser_img from "ASSETS/Images/ic_erazer.svg";
import eraser_img_selected from "ASSETS/Images/ic_erazer_selected.svg";
import eraser_img_disabled from "ASSETS/Images/FloatedAnnotations/ic_eraser_disabled1_white.svg";

import increment from "ASSETS/Images/ic_font_size_plus.svg";
import decrement from "ASSETS/Images/ic_font_size_minus.svg";
import { isMobileOnly } from "react-device-detect";

// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
export default class FloatedAnnotationBar extends Component {
  constructor(props) {
    super(props);
    this.fontFamilyOptions = Object.keys(FONT_FAMILY).map(fontFamily => ({
      name: getMessage(fontFamily),
      value: FONT_FAMILY[fontFamily],
    }));
    this.fontSizeOptions = FONT_SIZE.map(fontSize => ({
      name: fontSize,
      value: fontSize,
    }));
  }
  static propTypes = {
    handleFloatedAnnotation: func
  };
  state = {
    activeAnnotation:this.props.selectedAnnotationByAgent&&this.props.selectedAnnotationByAgent.annotation,
    shapes: {
      [ANNOTATIONS.TEXT]: {
        title: "TEXT_DECORATION",
        unselected: ic_text_selected,
        selected: ic_text,
        isSelected: false
      },
      [ANNOTATIONS.CIRCLE]: {
        title: "CIRCLE",
        unselected: ic_ellipse_selected,
        selected: ic_ellipse,
        isSelected: false
      },
      [ANNOTATIONS.RECTANGLE]: {
        title: "RECTANGLE",
        unselected: ic_rectangle_selected,
        selected: ic_rectangle,
        isSelected: false
      },

      [ANNOTATIONS.LINE]: {
        title: "LINE",
        unselected: ic_line_selected,
        selected: ic_line,
        isSelected: false
      },
      [ANNOTATIONS.FREE_HAND.NAME]: {
        title: "DRAW",
        unselected: ic_freehand_selected,
        selected: ic_freehand,
        isSelected: false
      },
      [ANNOTATIONS.ERASER.NAME]: {
        title: "ERASER",
        unselected: ic_rubber_selected,
        selected: ic_rubber,
        isSelected: false
      },
      [ANNOTATIONS.ERASE_OBJECT]: {
        title: "ERASE_OBJECT",
        unselected: ic_rubber_selected,
        selected: ic_rubber,
        isSelected: false
      }
    }
  };
  updateState=()=>{
    const {annotation,isSelected}=this.props.selectedAnnotationByAgent;
    if(annotation){let shapes=this.state.shapes;
      shapes[annotation].isSelected=isSelected
      this.setState({
        shapes,
        activeAnnotation:annotation
      })
    }
  }
  componentDidMount() {
    if(this.props.selectedAnnotationByAgent){
      this.updateState();
    }
  }
  
  componentDidUpdate(prevProps) {
    const {annotation}=this.props.selectedAnnotationByAgent;
    if(prevProps.selectedAnnotationByAgent.annotation!==annotation){
      this.updateState();
    }
    if(prevProps.disableClearCanvas !== this.props.disableClearCanvas &&
      this.props.disableClearCanvas &&
      this.props.organiser === this.props.uniqueId &&
      (this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME ||
        this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT)) {
      this.handleChangeAnnotation(this.props.selectedAnnotation);
    }
  }

  handleChangeAnnotation = (annotation, isEraserClick) => {
    let shapes = this.state.shapes;
    shapes[annotation] = this.state.shapes[annotation];
    Object.keys(this.state.shapes).map(shape=>{
      if(shape===annotation){
        // FIXME:Uncomment to avoid default selection of eraser when delete object is selected
        // shapes[shape].isSelected = isEraserClick ? true : !this.state.shapes[annotation].isSelected
        shapes[shape].isSelected = !this.state.shapes[annotation].isSelected
      }else{
        shapes[shape].isSelected=false;
      }
    })
    this.setState({
      shapes: shapes,
      activeAnnotation:annotation
    });

    // FIXME:Uncomment to avoid default selection of eraser when delete object is selected
    // if(annotation != ANNOTATIONS.ERASER.NAME || 
    //   (annotation == ANNOTATIONS.ERASER.NAME && this.props.selectedAnnotation != ANNOTATIONS.ERASE_OBJECT) ||
    //   isEraserClick)
    this.handleFloatedAnnotationWSMessage(annotation,shapes);
  };

  handleFloatedAnnotationWSMessage=(annotation,shapes)=>{
    const {
      ADD_ANNOTATION,
      REMOVE_ANNOTATION
    } = socketMessage.subCategories.NOTIFICATION;
    const message = {
      data: {
        category: socketMessage.category.NOTIFICATION,
        action: shapes[annotation].isSelected
          ? ADD_ANNOTATION
          : REMOVE_ANNOTATION,
        annotation: annotation
      }
    };
    this.props.handleFloatedAnnotation(socketMessage.events.MESSAGE, message);
  }
  
  handleChangeFontSize = value => {
    this.props.handleChangeFontSize(value);
  };
  eraseOnCanvas = () => {
    this.props.eraseOnCanvas();
  };
  clearCanvas = () => {
    this.props.clearCanvas();
  };

  incrementFont = () => {
    let index = FONT_SIZE.indexOf(this.props.selectedFontSize)
    this.props.handleChangeFontSize(FONT_SIZE[index===(FONT_SIZE.length - 1) ? index : index+1]);
  }

  decrementFont = () => {
    let index = FONT_SIZE.indexOf(this.props.selectedFontSize)
    this.props.handleChangeFontSize(FONT_SIZE[index<=0 ? 0 : index-1]);
  }

  renderAnnotations = () => {
    return Object.keys(this.state.shapes).filter(a=>a!==ANNOTATIONS.ERASE_OBJECT).map(shape => {
      let shapeObject = this.state.shapes[shape];
      const { title, selected, unselected, isSelected } = shapeObject;
      return (
        <div key={shape + title} className={isSelected && this.state.activeAnnotation === ANNOTATIONS.TEXT && isMobileOnly && !this.props.isLandscape? "floatingAnnotation col-12 mb-2" : "floatingAnnotation"}>
         <>
          <img
            src={title == "ERASER" && this.props.disableClearCanvas? 
              eraser_img_disabled :
              isSelected ? unselected : selected}
            alt={title}
            id={shapeObject.title}
            className={title == "ERASER" && this.props.disableClearCanvas 
              ? "imageSize isDisabled disablederaser"
              : "imageSize blinkImg cursorPointer"}
            onClick={() => {
              this.handleChangeAnnotation(shape);
            }}
          />
          <CustomTooltip
            tooltipText={getMessage(title)}
            tooltipId={shapeObject.title}
          />
         </>  
         {isSelected && this.state.activeAnnotation === ANNOTATIONS.TEXT&&
          <span id="divIdToggle" className=" cursorPStyle">
            {(this.props.canvasType !== CANVAS_TYPES.PDF ||
              this.props.isPresenter ||
              this.props.organiser === this.props.uniqueId ||
              !this.props.isInkLocked) && (
              <div id="DIVErase" className="d-md-flex">
                <div className="d-flex align-items-center mb-2 mb-md-0">
                  {this.props.language !== LANGUAGE.JA.name?
                    <React.Fragment>
                      <span className="invitee mr-2">
                        <CustomDropdown
                          options={this.fontFamilyOptions}
                          onChange={this.props.handleChangeFontFamily}
                          value={this.props.selectedFontFamily}
                          dropdownPositionFixed={true}
                          dropdownClass="font-family-dropdown"
                        />
                      </span>
                      {/* <hr className=" displayInline" /> */}
                    </React.Fragment>
                    : null
                  }
                  <img 
                    className="fontSizeIcon cursorPointer"
                    src={decrement}
                    onClick={this.decrementFont}
                  />
                  <span>
                    <CustomDropdown
                      options={this.fontSizeOptions}
                      onChange={this.handleChangeFontSize}
                      value={this.props.selectedFontSize}
                      dropdownPositionFixed={true}
                      dropdownClass="font-size-dropdown"
                    />
                  </span>
                  <img 
                    className="fontSizeIcon cursorPointer"
                    src={increment}
                    onClick={this.incrementFont}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  {this.props.organiser === this.props.uniqueId && (
                    <Button
                      className="textSetDefault cursorPointer ml-2"
                      onClick={()=>{this.props.setDefaultFontInfo(null,false)}}
                    >
                      {getMessage("SET_AS_DEFAULT")}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </span>}{isSelected && this.state.activeAnnotation==="Eraser"&&
            <span id="divIdToggle2" className="d-flex cursorPStyle">
              <>
              <img
                src={
                  this.props.disableClearCanvas ?
                    ic_rubber_disabled:
                    this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME
                      ? eraser_img_selected
                      : eraser_img
                }
                alt="eraser"
                id="eraser"
                className={this.props.disableClearCanvas 
                  ? "img-fluid isDisabled cursorPointer mr-2" 
                  : "img-fluid cursorPointer mr-2"}
                onClick={() => {
                  this.handleChangeAnnotation(ANNOTATIONS.ERASER.NAME, true)
                  this.eraseOnCanvas()
                }}
              />
              <CustomTooltip
                tooltipText={getMessage("ERASER")}
                tooltipId="eraser"
              />      
              </> 
              <>
                <img
                  src={
                    this.props.disableClearCanvas ?
                      delete_object_disabled :
                      this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT
                        ? delete_object_selected
                        : delete_object
                  }
                  alt="eraser"
                  id="deleteObject"
                  className={this.props.disableClearCanvas 
                    ? "img-fluid isDisabled cursorPointer mr-2"
                    : "img-fluid cursorPointer mr-2"
                  }
                  onClick={() =>
                    this.handleChangeAnnotation(ANNOTATIONS.ERASE_OBJECT)
                  }
                />
                <CustomTooltip
                  tooltipText={getMessage("DELETE_OBJECT")}
                  tooltipId="deleteObject"
                />  
              </>                    
              {this.props.userRole!==USER_ROLES.USER && <Button
                className={this.props.disableClearCanvas 
                  ? "textSetDefault isDisabled" 
                  : "textSetDefault cursorPointer"}
                onClick={this.clearCanvas}
              >
                {getMessage("CLEAR_CANVAS")}
              </Button>}
            </span>}
        </div>
      );
    });
  };
  render() {
    return <div className="floatedAnnotations row m-0">{this.renderAnnotations()}</div>;
  }
}
