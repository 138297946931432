import { ACTIONS } from "UTILS/Constants";

export const setSnapshotDrawingModeValue = (value) => {
  return {
    type: ACTIONS.SNAPSHOT.IS_DRAWING_MODE_ENABLED,
    value: value
  };
}

export const clearSnapshotCanvasAction = () => {
  return {
    type: ACTIONS.SNAPSHOT.CLEAR_CANVAS
  };
}

export const setSnapshotAnnotationMode = (value) => {
  return {
    type: ACTIONS.SNAPSHOT.SET_ANNOTATION_MODE,
    value: value
  };
}

export const setSnapshotScanCodeError = (value) => {
  return {
    type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_ERROR,
    value: value
  };
}

export const addStickyObject = (value) => {
  return {
    type: ACTIONS.SNAPSHOT.ADD_STICKY_OBJECT,
    data: {
      participantId: value.participantId,
      whiteboardData: value.whiteboardData,
      canvasType: value.canvasType,
      canvasId: value.canvasId
    }
  };
}

export const setSnapshotSearchResult = (snapshotScanCodeResult) => {
  return {
    type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_RESULT,
    value: snapshotScanCodeResult
  };
}

export const setSnapshotCurrentCanvas = (activeCanvas) => {
  return {
    type: ACTIONS.SNAPSHOT.SNAPSHOT_SET_ACTIVE_CANVAS,
    activeCanvas: {
      canvasId: activeCanvas.canvasId,
      canvasType: activeCanvas.canvasType
    }
  };
}