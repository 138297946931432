import * as log from "loglevel";
import { ACTIONS } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";
log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.APP_EMULATION._PERMISSION_REQUEST:
      store.dispatch({
        type: ACTIONS.APP_EMULATION.APP_EMULATION_REQUEST,
        value: true
      });
      break;
      
    case socketMessage.subCategories.APP_EMULATION._PERMISSION_REPLY:
      store.dispatch({
        type: ACTIONS.APP_EMULATION._APP_EMULATION_REPLY,
        value: true
      });

      //Dispatched this action to show device Info on user side on App-Emulation Module
      
      if (data.selectedDeviceInfo) {
        store.dispatch({
          type: ACTIONS.APP_EMULATION.SET_SELECTED_DEVICE_INFO,
          value: {
            version: data.selectedDeviceInfo.version,
            displayNameJA: data.selectedDeviceInfo.displayNameJA,
            displayNameEN: data.selectedDeviceInfo.displayNameEN,
          },
        });
      }

      if (data && data.uniqueId && !data.isGranted) {
        store.dispatch({
          type: ACTIONS.APP_EMULATION.PERMISSION_DENIDED,
          value: data.uniqueId
        });
      }
      break;

    case socketMessage.subCategories.APP_EMULATION._STOP_APP_EMULATION_SESSION:
      let appEmulationState = store.getState().AppEmulationReducer;

      if (
        appEmulationState.appEmulationPermisssionReply ||
        appEmulationState.appEmulationPermissionRequest
      ) {
        store.dispatch({
          type: ACTIONS.APP_EMULATION.APP_EMULATION_END_SESSION
        });
      }
      break;
    
    case socketMessage.subCategories.APP_EMULATION._PARTICIPANT_LEFT:
      store.dispatch({
        type: ACTIONS.APP_EMULATION.APP_EMULATION_LEAVE_SESSION,
        value: data.uniqueId
      });
      break;
    
    case socketMessage.subCategories.APP_EMULATION._PARTICIPANT_ARRIVED:
      store.dispatch({
        type: ACTIONS.APP_EMULATION.APP_EMULATION_JOIN_SESSION,
        value: data.uniqueId
      });
      break;
  }
};
