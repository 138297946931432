import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { mediaType } from "WEBSOCKET/constants";
import { withOrientationChange, isMobileSafari } from 'react-device-detect';

import SnapshotComponent from "./Snapshot";
import { sessionActions,headerAction, audioVideoAction } from "CONFIG/ActionFactory";
import { getAdmittedParticipants } from "COMPONENTS/CommonComponents/Header/selectors";
import {
  selectSnapshoteeId,
  selectSnapshotPermissionReply
} from "REDUX/Selector/SessionSelector";
import { selectTurnServerData, getVideoBandwidthSettings } from "../../Video/selectors";
import { selectUserRole, selectActiveCanvas } from "../selectors";
import { selectSessionKey, selectUserToken } from "../../MainLayout/selectors";
import { selectCurrentParticipantUniqueId, selectSnapshoterId } from "../../../Redux/Selector/SessionSelector";
import { getKmsStatus, getWebRtcPermissionRequest, getModules } from "COMPONENTS/CommonComponents/Header/selectors.js"
import { getCustomerCoBrowseStarted } from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseSelector"
import { clearSnapshotCanvasAction } from "./SnapshotAction";
import { getShowAudioVideoComponentFlag } from "../../Dashboard/selectors";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";
class SnapshotContainer extends Component {
  render() {
    return (
      <SnapshotComponent
        uniqueId={this.props.uniqueId}
        sessionKey={this.props.sessionKey}
        admittedParticipants={this.props.admittedParticipants}
        snapshoteeId={this.props.snapshoteeId}
        snapshoter={this.props.snapshoter}
        snapshotPermissionReply={this.props.snapshotPermissionReply}
        userRole={this.props.userRole}
        token={this.props.token}
        sessionActions={sessionActions}
        sendWebsocketMessage={this.props.sendWebsocketMessage}
        snapShotModelClose={this.props.snapShotModelClose}
        addSnapshotImage={this.props.addSnapshotImage}
        uploadFile={this.props.uploadFile}
        addCanvas={this.props.addCanvas}
        showSpinner={this.props.showSpinner}
        hideSpinner={this.props.hideSpinner}
        uniqueId={this.props.uniqueId}
        organiser={this.props.organiser}
        headerAction={headerAction}
        isSnapshotTaken={this.props.isSnapshotTaken}
        isLandscape={this.props.isLandscape}
        isPortrait={this.props.isPortrait}
        isMobileSafari={isMobileSafari}
        turnServer={this.props.turnServer}
        webRtcPermissionRequest={this.props.webRtcPermissionRequest}
        audioVideoAction={audioVideoAction}
        modules={this.props.modules}
        startSnapshot={this.props.startSnapshot}
        isSnapshotRunning={this.props.isSnapshotRunning}
        videoBandwidthSettings={this.props.videoBandwidthSettings}
        customerCoBrowseStarted={this.props.customerCoBrowseStarted}
        sendDownloadToCanvasMessage={this.props.sendDownloadToCanvasMessage}
        handleChangeAnnotation={this.props.handleChangeAnnotation}
        selectedAnnotation={this.props.selectedAnnotation}
        clearSnapshotCanvasAction={this.props.clearSnapshotCanvasAction}
        showAudioVideoComponent={this.props.showAudioVideoComponent}
        handleVoiceCommand={this.props.handleVoiceCommand}
        deregisterVoiceCommand={this.props.deregisterVoiceCommand}
        deregisterAllVoiceCommands={this.props.deregisterAllVoiceCommands}
        isSnapshotScanCodeEnabled={this.props.isSnapshotScanCodeEnabled}
        snapshotScanCodeResult= {this.props.snapshotScanCodeResult}
        setSnapshotSearchResult={this.props.setSnapshotSearchResult}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    modules: getModules(state),
    uniqueId: selectCurrentParticipantUniqueId(state),
    sessionKey: selectSessionKey(state),
    admittedParticipants: getAdmittedParticipants(state),
    snapshoteeId: selectSnapshoteeId(state),
    snapshoter:selectSnapshoterId(state),
    snapshotPermissionReply: selectSnapshotPermissionReply(state),
    userRole: selectUserRole(state),
    token: selectUserToken(state),
    activeCanvas: selectActiveCanvas(state),
    isSnapshotTaken: state.SessionReducer.isSnapshotTaken,
    turnServer:selectTurnServerData(state),
    webRtcPermissionRequest: getWebRtcPermissionRequest(state),
    startSnapshot: state.SessionReducer.startSnapshot,
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning,
    videoBandwidthSettings: getVideoBandwidthSettings(state),
    customerCoBrowseStarted: getCustomerCoBrowseStarted(state),
    showAudioVideoComponent: getShowAudioVideoComponentFlag(state),
    isSnapshotScanCodeEnabled: state.OrganisationReducer.customerSettings?.isSnapshotScanCodeEnabled,
    snapshotScanCodeResult: state.SnapshotReducer.snapshotScanCodeResult,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage,
      clearSnapshotCanvasAction: clearSnapshotCanvasAction
    },
    dispatch
  );
};

export default withRealWear(withOrientationChange(connect(
  mapStateToProps,
  mapDispatchToProps
)(SnapshotContainer)));