import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as log from "loglevel";
import Spinner from "COMPONENTS/Spinner/Spinner";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';

import StartSession from "./StartSession";

//Actions
import { startSessionAction as actionFactory, organisationAction } from "CONFIG/ActionFactory";
import { sessionActions } from "CONFIG/ActionFactory";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

import { leaveSessionAction } from "CONFIG/ActionFactory";
import { getCurrentSessionInvitees } from "../../Sessions/selectors";
import { selectSessionKey } from "../../../Routes/selectors";
import { getSessionType } from "REDUX/Selector/SessionSelector"

//Utilities
import { CONFIG, ERROR } from "UTILS/Constants";

// Assets
import { disableBrowseBackBtn } from "UTILS/Utility";

export class StartSessionContainer extends Component {
  constructor(props) {
    super(props);
    this.SCHEDULE_SESSION_PAGE_SIZE = 50;
    this.state = {
      showResults: false,
      pastSessionsDetails: {},
      showCloseMeetingConfirmationPopUp: false,
    };
    this.startSessionRef = React.createRef();
  }
  componentDidMount() {
    leaveSessionAction.localSessionAllDataCleanUp();
    // to avoid back click on create session 
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
  }

  showHideSpinner = showSpinner => {
    this.setState({
      spinnerVisibility: showSpinner
    })
  }

  startSession = (invitees = [], sessionDetail, errorCallback, closeActiveSession) => {
    invitees && this.props.saveCurrentSessionInvitees(invitees);
    let invite = invitees && Array.from(invitees);
    this.removeByAttr(invite, "isSelected", "false");
    this.showHideSpinner(true);
    sessionActions.createSessionAction(sessionDetail.sessionTitle, sessionDetail.passcode, this.props.language, closeActiveSession).then(
      data => {
        if (invite.length > 0 && !sessionDetail.passcode) {
          sessionActions.inviteUser(this.props.sessionKey, invite, this.props.language).then(
            data => {
              log.info("Data:", data);
            },
            error => {
              let proceedBtn = document.getElementById("proceedBtn");
              if (proceedBtn)
                proceedBtn.disabled = false;
            }
          );
        }
        sessionActions.joinSessionAction().then(
          response => {
            organisationAction.setLogoUrl(response.data.data.logoUrl);
            this.props.history.push(CONFIG.path.dashboard);
          },
          error => {
            this.showHideSpinner(false);
            log.error("ERROR: ", error);
            if (error.code == ERROR.ACTIVE_CLOSING_SESSION_ALREADY_EXISTS) {
              this.showHideCloseMeetingPopup(true);
            } else {
              let proceedBtn = document.getElementById("proceedBtn");
              if (proceedBtn)
                proceedBtn.disabled = false;
              errorCallback && errorCallback(error.code);
            }
          }
        );
      },
      error => {
        this.showHideSpinner(false);
        if (error.code == ERROR.ACTIVE_CLOSING_SESSION_ALREADY_EXISTS) {
          this.showHideCloseMeetingPopup(true);
        } else {
          let proceedBtn = document.getElementById("proceedBtn");
          if (proceedBtn)
            proceedBtn.disabled = false;
          toastr.error(error.code);
          let uriIdentifier = this.props.uriIdentifier !== "/"
            ? this.props.uriIdentifier
            : ""
          error.code === ERROR.PASSCODE_ALREADY_BEING_USED &&
            window.getPasscode(
              this.props.role,
              window.location.origin + uriIdentifier + CONFIG.path.passcode,
              window._env_.REACT_APP_LOOOKIT_SERVICES_BASE_URL,
              this.props.userToken,
              document.getElementById("lookitPasscode"));

          errorCallback && errorCallback(error.code);
        }
      }
    );
  };

  removeByAttr = (arr, attr, value) => {
    var i = 0;
    for (; i < arr.length; i++) {
      if (arr[i].name === "" || arr[i].name == null) {
        delete arr[i].name;
      }
      if (arr[i].email === "" || arr[i].email == null) {
        delete arr[i].email;
      }
      if (arr[i].phone === "" || arr[i].phone == null) {
        delete arr[i].phone;
      }
      if (arr[i][attr] === false) {
        arr.splice(i, 1);
        i--;
        continue;
      } else {
        delete arr[i].id;
        delete arr[i].isSelected;
      }
    }
    return arr;
  };

  //PLESE CHECK: This method is not used anywhere
  joinSession = (sessionKey, uriIdentifier) => {
    sessionActions.joinSessionAction(sessionKey, uriIdentifier).then(
      response => {
        organisationAction.setLogoUrl(response.data.data.logoUrl);
        this.props.history.push(CONFIG.path.dashboard);
      },
      error => {
        this.showHideSpinner(false);
        toastr.error(error.code);
      }
    );
  }

  backToMenu = () => {
    this.props.history.push(CONFIG.path.createSession)
  }

  showHideCloseMeetingPopup = (value) => {
    this.setState({ showCloseMeetingConfirmationPopUp: value });
  }

  render() {
    return (
      <React.Fragment>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        <StartSession
          ref={this.startSessionRef}
          showResults={this.state.showResults}
          pastSessionDetails={this.state.pastSessionsDetails}
          history={this.props.history}
          language={this.props.language}
          setLanguageAction={this.props.setLanguageAction}
          saveCurrentSessionInvitees={this.props.saveCurrentSessionInvitees}
          currentSessionInvitees={this.props.currentSessionInvitees}
          startSession={this.startSession}
          scheduleSession={this.scheduleSession}
          joinSession={this.joinSession}
          updateSession={this.updateSession}
          sessionType={this.props.sessionType}
          backToMenu={this.backToMenu}
          userToken={this.props.userToken}
          userId={this.props.userId}
          role={this.props.role}
          uriIdentifier={this.props.uriIdentifier}
          isPasscodeEnabled={this.props.isPasscodeEnabled}
          showCloseMeetingConfirmationPopUp={this.state.showCloseMeetingConfirmationPopUp}
          showHideCloseMeetingPopup={this.showHideCloseMeetingPopup}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    currentSessionInvitees: getCurrentSessionInvitees(state),
    sessionKey: selectSessionKey(state),
    sessionType: getSessionType(state),
    userToken: state.UserReducer.userDetails.token,
    userId: state.UserReducer.userDetails.userId,
    role: state.UserReducer.userDetails.role,
    isPasscodeEnabled: state.UserReducer.isPasscodeEnabled,
    uriIdentifier: state.UserReducer.uriIdentifier
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveCurrentSessionInvitees: actionFactory.saveCurrentSessionInvitees,
      setLanguageAction
    },
    dispatch
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(StartSessionContainer);
