import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import { disconnectWebsocket } from "WEBSOCKET/WebsocketHandler";

import * as log from "loglevel";

import store from "REDUX/store";

//import CreateSessionContainer from "COMPONENTS/Sessions/SessionsContainer";
import LoginContainer from "COMPONENTS/Login/LoginContainer";
import LogoutContainer from "COMPONENTS/Logout/LogoutContainer";
import DashboardContainer from "COMPONENTS/Dashboard/DashboardContainer";
import MainLayoutContainer from "COMPONENTS/MainLayout/MainLayoutContainer";
import SessionManagementMainlayout from "COMPONENTS/SessionManagement/SessionManagementMainlayout";
import UserJoinContainer from "COMPONENTS/UserJoin/UserJoinContainer";
import UserEvictedContainer from "../Components/UserEvicted/UserEvictedContainer";
import TestPage from "COMPONENTS/TestPage/TestPage";
import ReJoinContainer from "COMPONENTS/ReJoin/ReJoinContainer";
import SetPasswordContainer from "COMPONENTS/SetPassword/SetPasswordContainer";
import ResetPasswordContainer from "COMPONENTS/ResetPassword/ResetPasswordContainer";
import QRCodeContainer from "COMPONENTS/QRCode/QRCodeComponent/QRCodeContainer";
import QrExpired from "COMPONENTS/QRCode/UIComponents/StaticScreen/QrExpired";
import AgentLandingPage from "COMPONENTS/QRCode/UIComponents/AgentLandingPage/AgentLandingPage";
import SSOLogin from "COMPONENTS/Login/SSOLogin";
import SSOLogout from "COMPONENTS/Login/SSOLogout";
import About from "COMPONENTS/About/About";
import QRCodeAgentsContainer from "../Components/Admin/QRCodes/QRCodeAgentsContainer";
import OrganisationsContainer from "COMPONENTS/SuperAdmin/Organisations/OrganisationsContainer";
import AdminDashboardContainer from "COMPONENTS/Admin/AdminDashboardContainer";
import WaitingScreen from "COMPONENTS/WaitingScreen/WaitingScreen";
import PageNotFound from "COMPONENTS/ErrorComponents/PageNotFound";
import UserWaitingRoom from "COMPONENTS/UserWaiting/UserWaitingContainer";
import AddOrganizationContainer from "COMPONENTS/SuperAdmin/AddOrganization/AddOrganizationContainer";
import EditOrganizationContainer from "COMPONENTS/SuperAdmin/EditOrganization/EditOrganizationContainer";
import AdminSettngsContainer from "COMPONENTS/Admin/SettingsPage/SettingsPageContainer";
import RealWearEndCall from "Components/RealWearEndCall/RealWearEndCall";
import { WebRTCMessage } from "COMPONENTS/CommonComponents/WebRTCMessage/WebRTCMessage";
import RealWearHelpPopUp from "Components/CommonComponents/RealWearHelpPopUp/RealWearHelpPopUp";

import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";

import {
  removeTrailingSlash,
  getUrlPathExcludingSearchString,
  getStringArrayInLowerCase,
  getURLParameter,
  isRealwearDevice,
  getUrlSearchString
} from "UTILS/Utility";

import { CONFIG, REGEX, QR_CODE_SCREEN_TYPE, ADMIN_VALID_URLS, VOICE_COMMAND_SCROLL_TO_VALUE } from "UTILS/Constants";
import { URI_IDENTIFIER_MODIFICATION_NOT_ALLOWED_PATH, USER_ROLES } from "UTILS/Constants";

import { leaveSessionAction } from "CONFIG/ActionFactory";
import { setQrActiveScreen, clearQRCodeData } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeAction";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

import { selectQrActiveScreen, selectQRCode, selectIsUserWaitingTimeEnded } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeSelector";
import { selectUserToken, selectSessionKey, getUserRole } from "./selectors";
import { getLanguage } from "./selectors";

import { changeLanguage } from "CONFIG/i18n";

const PrivateRoute = ({ ...rest }) => (
  <Route
    render={(props) => {
      switch (rest.role) {
        case USER_ROLES.AGENT:
          return rest.isAuthenticated &&
            rest.path.toLowerCase() !== CONFIG.path.admin &&
            rest.path.toLowerCase() !== CONFIG.path.superAdmin ? (
              <RouteWithBaseName {...props} { ...rest }/>
            ) : (
              <Redirect
                push
                to={{
                  pathname: CONFIG.path.login,
                }}
              />
            );
          break;
        case USER_ROLES.SUPER_ADMIN:
          if (
            rest.isAuthenticated &&(
              rest.path === CONFIG.path.superAdmin ||
            rest.path === CONFIG.path.addOrganization ||
            rest.path === CONFIG.path.editOrganization
            )
          ) {
            return <RouteWithBaseName {...props} { ...rest }/>;
          } else {
            return (
              <Redirect
                push
                to={{
                  pathname: CONFIG.path.superAdminLogin,
                }}
              />
            );
          }

        case USER_ROLES.ADMIN:
          if (
            rest.isAuthenticated &&
            rest.path.toLowerCase() === CONFIG.path.admin ||
            ADMIN_VALID_URLS.some(url => url === rest.path)
          ) {
            return <RouteWithBaseName {...props} { ...rest }/>;
          } else {
            return (
              <Redirect
                push
                to={{
                  pathname: CONFIG.path.adminLogin,
                }}
              />
            );
          }
          break;
        case USER_ROLES.USER:
          return rest.path.toLowerCase() !== CONFIG.path.admin &&
            rest.path.toLowerCase() !== CONFIG.path.superAdmin &&
            rest.path.toLowerCase() !== CONFIG.path.createSession.toLowerCase() ? (
              <RouteWithBaseName {...props} { ...rest }/>
            ) : (
              <Redirect
                push
                to={{
                  pathname: CONFIG.path.login,
                }}
              />
            );
          break;
        default:
          return (
            <Redirect
              push
              to={{
                pathname: CONFIG.path.login,
              }}
            />
          );
          break;
      }
    }}
  />
);

// if uriIdentifier is set and we are trying to remove it from url, it will redirect to the url by again adding the uri path
const RouteWithBaseName = ({ component: ComponentToRender, ...rest }) => {
  let checkPathContainsValidUri = () => {
    return !rest.uriIdentifier        // uri identifier is not set
    || rest.uriIdentifier === "/"     // uri identifier is /
    || (removeTrailingSlash(window.location.pathname).split("/")[1]
     && "/"+removeTrailingSlash(window.location.pathname).split("/")[1].toLowerCase() === 
     rest.uriIdentifier.toLowerCase())  // uri identifier is already there in  url
  }

  return <Route 
    render={(props) => {
      console.log("Routing:  rest.uriIdentifier-  "+ rest.uriIdentifier+" rest.path- "+ rest.path+ "  rest.location- "+ rest.location.pathname);

      // if path has # we should redirect to a page without #
      if(rest.location.hash && rest.location.hash.startsWith("#")) {
        let searchString = getUrlSearchString(rest.location.hash);
        let redirectToPath = getUrlPathExcludingSearchString(rest.location.hash.replace('#', ''));
        
        // here we have 3 scenarios 
        // 1. /#/path: uri_dentifier not set by user
        // 2. /uri_identifer/#/path: uri is not part of hash path so we have to add it 
        // 3. /#/uri_identifer/path: here uri is a part of hash path so no need to add 
        if(rest.uriIdentifier !== '/'
        && !redirectToPath.toLowerCase().startsWith(rest.uriIdentifier.toLowerCase())) {
          window.location.replace(window.location.origin
            +rest.uriIdentifier
            +removeTrailingSlash(rest.location.pathname)
            +removeTrailingSlash(redirectToPath)
            +searchString);
        } else {
          window.location.replace(window.location.origin
            +removeTrailingSlash(rest.location.pathname)
            +removeTrailingSlash(redirectToPath)
            +searchString);
        }
        return;
      }

      // TODO: check conditions for QR code pages

      // load component if any one of the following conditions is met else redirect to path with uri_identifer
      // 1. check if uri_identifier (if present) is valid 
      // 2. no need to redirect to uri path for superadmin page 
      // 3. once user is in the session (which is determined by his role before login/join it is empty string) 
      // he should not be able to change uri 
      // for USER: on some paths like join user should not be able to change uri_identifier (if present)
      return checkPathContainsValidUri()
      || (rest.path === CONFIG.path.superAdminLogin && rest.uriIdentifier === "/")
      || (rest.path === CONFIG.path.adminLogin && rest.uriIdentifier === "/")
      || (!Object.values(USER_ROLES).includes(rest.role)
        && !URI_IDENTIFIER_MODIFICATION_NOT_ALLOWED_PATH.includes(rest.path))
        ? <ComponentToRender {...props} {...rest}/>
        : <Redirect
          replace
          to={{
            pathname: rest.path,
            search: rest.location && rest.location.search
          }}
        />
    }}
  />
}

log.setLevel("info");
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state={
      showHelpPopUp: false
    }
    changeLanguage();
  }

  componentDidMount() {   
    document.addEventListener("dragstart", function (e) {
      if (e.target.nodeName.toUpperCase() == "IMG") {
        e.preventDefault();
        return false;
      }
    });

    document.addEventListener("contextmenu", (e) => {
      if (e.target.nodeName.toUpperCase() == "IMG") {
        e.preventDefault();
      }
    });
    isRealwearDevice() && this.registerVoiceCommandsHandler();
  }

  registerVoiceCommandsHandler(){
    this.voiceCommandEndCallHandler();
    this.voiceCommandScrollUpHandler();
    this.voiceCommandScrollDownHandler();
    this.voiceCommandShowHelpHandler();
    this.voiceCommandHideHelpHandler();
  }

  componentDidUpdate(prevProps) {
    // bugfix 4618 - Evicted user entry does not get removed from waiting area
    // disconnect web socket when user leaves session or on end session
    if(prevProps.userLeft !== this.props.userLeft && this.props.userLeft) {
      console.log('disconnecting socket after _LEFT');
      this.props.disconnectWebsocket();
      leaveSessionAction.localSessionAllDataCleanUp();
    }
    if(isRealwearDevice() && prevProps.language !== this.props.language) {
      this.registerVoiceCommandsHandler();
    }
  }

  /**
   * Handle voice command on realwear device
   */
  voiceCommandEndCallHandler = () => {
    const endCall = {
      voiceCommand: "VOICE_COMMANDS_END_CALL",
      action: async() => {
        if (window.location.pathname === `${CONFIG.partialPath.qr}/${this.props.qrId}`
          && this.props.qrActiveScreen === QR_CODE_SCREEN_TYPE.QR_WAITING_SCREEN) {
          try {
            await leaveSessionAction.endSessionAndCleanUp(this.props.sessionKey);
          } catch (error) {
            console.log("voiceCommandEndCallHandler() error: ", error)
          }
        }
        // navigate to realwear end call page
        this.props.history.push(CONFIG.path.realwearEndCall)
      }
    }  
    this.props.handleVoiceCommand(endCall)
  }

  scrollToHandler = (scrollValue) => {
    let scrollTo = document.documentElement.scrollTop + scrollValue;
    scrollTo = scrollTo < 0 ? 0 : scrollTo
    window.scrollTo({top: scrollTo, behavior: 'smooth'});
    setTimeout(() => {
      document.documentElement.scrollTop = scrollTo;
    }, 250);
  }

  voiceCommandScrollUpHandler = () => {
    const scrollUp = {
      voiceCommand: "VOICE_COMMANDS_SCROLL_UP",
      action: () => {
        this.scrollToHandler(-VOICE_COMMAND_SCROLL_TO_VALUE)
      }
    }
    this.props.handleVoiceCommand(scrollUp)
  }

  voiceCommandScrollDownHandler = () => {
    const scrollDown = {
      voiceCommand: "VOICE_COMMANDS_SCROLL_DOWN",
      action: () => {
        this.scrollToHandler(VOICE_COMMAND_SCROLL_TO_VALUE)
      }
    }  
    this.props.handleVoiceCommand(scrollDown)
  }

  voiceCommandShowHelpHandler = () => {
    const showHelp = {
      voiceCommand: "VOICE_COMMANDS_SHOW_HELP",
      action: () => {
        this.openCloseShowHelpPopUp(true)
      }
    }  
    this.props.handleVoiceCommand(showHelp)
  }

  voiceCommandHideHelpHandler = () => {
    const hideHelp = {
      voiceCommand: "VOICE_COMMANDS_HIDE_HELP",
      action: () => {
        this.openCloseShowHelpPopUp(false)
      }
    }  
    this.props.handleVoiceCommand(hideHelp)
  }

  openCloseShowHelpPopUp = (showHelpPopUp) => {
    this.setState({
      showHelpPopUp
    })
  }

  renderRoutes = () => {
    var uriIdentifierCaseInsensitive = new RegExp(this.props.uriIdentifier, 'gi');

    const { userToken, sessionKey } = this.props;
    return (
      <div>
        <Route 
          component={WebRTCMessage}
        />
        <Switch>
          {/* display version from package.json */}
          <Route exact path={CONFIG.path.about} component={About}/>
          <Route
            exact 
            path={CONFIG.path.realwearEndCall} 
            component={RealWearEndCall} 
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.login}
            component={LoginContainer}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.adminLogin}
            component={LoginContainer}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.sessionEnded}
            component={WaitingScreen}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.superAdminLogin}
            component={LoginContainer}
            uriIdentifier={this.props.uriIdentifier}
          />
          {/* <RouteWithBaseName
            exact
            path={CONFIG.path.logout}
            component={LogoutContainer}
            uriIdentifier={this.props.uriIdentifier}
          /> */}
          <RouteWithBaseName
            exact
            path={CONFIG.path.userJoin}
            component={UserJoinContainer}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.userEvicted}
            component={UserEvictedContainer}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.reJoin}
            component={ReJoinContainer}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName 
            exact 
            path="/testPage" 
            component={TestPage} 
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.forgotPassword}
            component={ResetPasswordContainer}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.setPassword}
            component={getURLParameter("token") && getURLParameter("language") ? SetPasswordContainer : PageNotFound}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.qrExpired}
            component={QrExpired}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={REGEX.JOIN_QR_CODE_SESSION}
            component={AgentLandingPage}
            uriIdentifier={this.props.uriIdentifier}
            isAuthenticated={userToken}
          />

          <Route
            exact 
            path={CONFIG.path.ssoLogin} 
            component={SSOLogin} 
            uriIdentifier={this.props.uriIdentifier}
          />

          <Route
            exact 
            path={CONFIG.path.ssoLogout} 
            component={SSOLogout} 
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.createSession}
            component={SessionManagementMainlayout}
            isAuthenticated={userToken}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.startMeeting}
            component={SessionManagementMainlayout}
            isAuthenticated={userToken || sessionKey}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.scheduleMeeting}
            component={SessionManagementMainlayout}
            isAuthenticated={userToken || sessionKey}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.trailHistory}
            component={SessionManagementMainlayout}
            isAuthenticated={userToken || sessionKey}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <RouteWithBaseName
            exact
            path={CONFIG.path.passcode}
            component={this.props.uriIdentifier && this.props.uriIdentifier !== "/" ? UserJoinContainer : PageNotFound}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.superAdmin}
            component={OrganisationsContainer}
            isAuthenticated={userToken}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.addOrganization}
            component={AddOrganizationContainer}
            isAuthenticated={userToken}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.editOrganization}
            component={EditOrganizationContainer}
            isAuthenticated={userToken}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          <PrivateRoute
            exact
            path={CONFIG.path.admin}
            component={AdminDashboardContainer}
            isAuthenticated={userToken}
            role={this.props.role}
            uriIdentifier={this.props.uriIdentifier}
          />
          
          <PrivateRoute
            exact
            role={this.props.role}
            isAuthenticated={userToken || sessionKey}
            path={CONFIG.path.adminSettings}
            component={AdminSettngsContainer}
            uriIdentifier={this.props.uriIdentifier}
          />

          <PrivateRoute
            exact
            path={CONFIG.path.qrCodeAgents}
            component={QRCodeAgentsContainer}
            uriIdentifier={this.props.uriIdentifier}
            role={this.props.role}
            isAuthenticated={userToken}
          />

          <PrivateRoute
            exact
            path={CONFIG.path.assignAgentsToQRCode}
            component={QRCodeAgentsContainer}
            uriIdentifier={this.props.uriIdentifier}
            role={this.props.role}
            isAuthenticated={userToken}
          />

          {/* if pathname did not found in config.path[] then push pageNotFound */}
          {<Route
            exact
            path={((!getStringArrayInLowerCase(CONFIG.path).includes(getUrlPathExcludingSearchString(
              this.props.uriIdentifier !== '/'
                ? (window.location.pathname.replace(uriIdentifierCaseInsensitive, ''))
                : window.location.pathname).toLowerCase()))
                && !(REGEX.JOIN_QR_CODE_SESSION.test(window.location.pathname))
                && !(REGEX.START_QR_CODE_SESSION.test(window.location.pathname))
              ?  "*" : CONFIG.path.pageNotFound)}
            component={PageNotFound}
            uriIdentifier={this.props.uriIdentifier}
          />
          }
          <MainLayoutContainer store={store}>
            <Switch>
              <RouteWithBaseName
                exact
                path={CONFIG.path.startSession}
                component={QRCodeContainer}
                role={this.props.role}
                uriIdentifier={this.props.uriIdentifier}
              />
              <RouteWithBaseName
                exact
                path={REGEX.START_QR_CODE_SESSION}
                component={QRCodeContainer}
                role={this.props.role}
                uriIdentifier={this.props.uriIdentifier}
              />
              <RouteWithBaseName
                exact
                path={CONFIG.path.waitingRoom}
                component={UserWaitingRoom}
                uriIdentifier={this.props.uriIdentifier}
                role={this.props.role}
              />
              <PrivateRoute
                exact
                path={CONFIG.path.dashboard}
                component={DashboardContainer}
                isAuthenticated={userToken || sessionKey}
                role={this.props.role}
                uriIdentifier={this.props.uriIdentifier}
              />
            </Switch>
          </MainLayoutContainer>
        </Switch>
      </div>
    )
  }
  
  render() {
    return (
      <>
        {
          this.state.showHelpPopUp && isRealwearDevice() &&
          <RealWearHelpPopUp
            show={this.state.showHelpPopUp}
            openCloseShowHelpPopUp={this.openCloseShowHelpPopUp}
            handleVoiceCommand={this.props.handleVoiceCommand}
            deregisterVoiceCommand={this.props.deregisterVoiceCommand}
          />
        }
        {this.renderRoutes()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userToken: selectUserToken(state),
    sessionKey: selectSessionKey(state),
    role: getUserRole(state),
    userLeft: state.SessionReducer.userLeft,
    qrActiveScreen: selectQrActiveScreen(state),
    qrId: selectQRCode(state),
    userWaitingTimeEnded: selectIsUserWaitingTimeEnded(state),
    language: getLanguage(state),
    uriIdentifier: state.UserReducer.uriIdentifier
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      disconnectWebsocket,
      setQrActiveScreen,
      clearQRCodeData,
      setLanguageAction
    },
    dispatch
  );
};

export default withRealWear(withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes)));
