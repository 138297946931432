import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class StartSessionAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  //never make this function as arrow function
  getPastSessionAction(status, limit, page, search) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          status,
          limit,
          page,
          search
        },
        url: API_URL.START_SESSION
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  saveCurrentSessionInvitees(currentSessionInvitees) {
    return {
      type: ACTIONS.SAVE_CURRENT_SESSION_INVITEES,
      currentSessionInvitees: currentSessionInvitees
    };
  }

  setTrailHistoryData(trailHistoryData) {
    this.dispatch({
      type: ACTIONS.SET_TRAIL_HISTORY_DATA,
      trailHistoryData
    })
  }
}
