import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, Row, Col } from "reactstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

// Constants
import { getMessage } from "CONFIG/i18n";

import "../Modal/Popup.less";
import "./RealWearHelpPopUp.less"

const voiceCommandList = [
  [
    {
      title: 'VOICE_COMMANDS_SHOW_HELP',
      description: 'VOICE_COMMANDS_SHOW_HELP_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_HIDE_HELP',
      description: 'VOICE_COMMANDS_HIDE_HELP_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_NEXT',
      description: 'VOICE_COMMANDS_NEXT_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_PREV',
      description: 'VOICE_COMMANDS_PREV_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_PROCEED',
      description: 'VOICE_COMMANDS_PROCEED_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_SCROLL_UP',
      description: 'VOICE_COMMANDS_SCROLL_UP_DESCRIPTION',
    }
  ],
  [
    {
      title: 'VOICE_COMMANDS_SCROLL_DOWN',
      description: 'VOICE_COMMANDS_SCROLL_DOWN_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_OPEN_CHAT_POPUP',
      description: 'VOICE_COMMANDS_OPEN_CHAT_POPUP_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_CLOSE_CHAT_POPUP',
      description: 'VOICE_COMMANDS_CLOSE_CHAT_POPUP_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_ACCEPT',
      description: 'VOICE_COMMANDS_ACCEPT_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_DECLINE',
      description: 'VOICE_COMMANDS_DECLINE_DESCRIPTION',
    }
  ],
  [
    {
      title: 'VOICE_COMMANDS_YES_NO_CANCEL',
      description: 'VOICE_COMMANDS_YES_NO_CANCEL_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_MUTE_AUDIO',
      description: 'VOICE_COMMANDS_MUTE_AUDIO_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_UNMUTE_AUDIO',
      description: 'VOICE_COMMANDS_UNMUTE_AUDIO_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_1',
      description: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_1_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_2',
      description: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_2_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_3',
      description: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_3_DESCRIPTION',
    }
  ],
  [
    {
      title: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_4',
      description: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_4_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_5',
      description: 'VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_5_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_FLASH_ON',
      description: 'VOICE_COMMANDS_FLASH_ON_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_FLASH_OFF',
      description: 'VOICE_COMMANDS_FLASH_OFF_DESCRIPTION',
    },
    {
      title: 'VOICE_COMMANDS_END_CALL',
      description: 'VOICE_COMMANDS_END_CALL_DESCRIPTION',
    }
  ]
]
class RealWearHelpPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0
    }
    this.maxPages = voiceCommandList.length;
  }

  voiceCommandNextHandler = () => {
    const next = {
      voiceCommand: "VOICE_COMMANDS_NEXT",
      action: () => {
        this.handlePageNumber(true)
      }
    }
    this.props.handleVoiceCommand(next)
  }

  voiceCommandPrevHandler = () => {
    const prev = {
      voiceCommand: "VOICE_COMMANDS_PREV",
      action: () => {
        this.handlePageNumber(false)
      }
    }
    this.props.handleVoiceCommand(prev)
  }

  componentDidMount() {
    this.voiceCommandNextHandler();
    this.voiceCommandPrevHandler();
  }

  handlePageNumber = (isNext) => {
    if (isNext) {
      this.setState((prevState) => ({
        page: prevState.page === this.maxPages - 1 ? prevState.page : prevState.page + 1
      }));
    } else {
      this.setState((prevState) => ({
        page: prevState.page === 0 ? 0 : prevState.page - 1
      }))
    }
  }

  componentWillUnmount() {
    this.props.deregisterVoiceCommand('VOICE_COMMANDS_NEXT');
    this.props.deregisterVoiceCommand('VOICE_COMMANDS_PREV')
  }

  render() {
    return (

      <Modal
        isOpen={this.props.show}
        className={"modal-lg col-xs-6 col-md-6 col-sm-6 popUp voice-commands"}
      >
        <ModalHeader className="popupHeader" toggle={() => this.props.openCloseShowHelpPopUp(false)}>
          {getMessage("VOICE_COMMANDS")}
        </ModalHeader>
        <ModalBody className="shortNotePopupBody ml-3 mr-3">
          <div className="voice-commands-list">
            {
              voiceCommandList[this.state.page]?.map((command, i) => {
                return (
                  <Row className="voice-command" key={command.title + i}>
                    <Col sm="12" md='4' style={{ textAlign: "right" }}>
                      <span className="title">
                        {getMessage(command.title)}:
                      </span>
                    </Col>
                    <Col sm="12" md='8'>
                      <p className="description">
                        {getMessage(command.description)}
                      </p>
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          <Button
            className="buttons btn-accept customBtn"
            disabled={this.state.page === 0}
            onClick={() => this.handlePageNumber(false)}
          >
            {getMessage("VOICE_COMMANDS_PREV")}
          </Button>
          <Button
            className="buttons btn-accept customBtn"
            disabled={this.state.page === this.maxPages - 1}
            onClick={() => this.handlePageNumber(true)}
          >
            {getMessage("VOICE_COMMANDS_NEXT")}
          </Button>
        </ModalFooter>
      </Modal>

    );
  }
}

export default modalScrollBar(withRouter((RealWearHelpPopUp)));
