import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";
import VideoFrameChecker from "./VideoFrameChecker";

import { arrayAverage, arrayMax, arrayMin } from "../../Utils/Utility";

export default class CameraTest extends BaseTest {
  constructor(props) {
    super(props)
    // this.test = test;
    this.setValues();
  }

  setValues = () => {
    this.resolutions = this.props.resolutions;
    this.currentResolution = 0;
    this.isMuted = false;
    this.isShuttingDown = false;
    super.output="";
  }

  componentDidMount() {
    this.test(this);
    if(this.props.startTest) {
      this.setState({
        testFinalStatus: "Running"
      })
      this.setValues();
      this.run()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest !== prevProps.startTest && this.props.startTest) {
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
    }
  }

  run() {
    console.log('*** Camera test run ***', this.props.resolutions, this.currentResolution, this.props.resolutions[0]);
    this.props.resolutions[this.currentResolution] &&
    this.startGetUserMedia(this.props.resolutions[this.currentResolution]);
  }

  startGetUserMedia = (resolution) => {
    var constraints = {
      audio: false,
      video: {
        width: {exact: resolution[0]},
        height: {exact: resolution[1]}
      }
    };
    let self = this;
    console.log('get user media for camera test: ', constraints);
    if(!navigator.mediaDevices)return
    navigator.mediaDevices.getUserMedia(constraints)
    .then(function(stream) {
      self.cam = self.getDeviceName_(stream.getVideoTracks());
      self.props.setCameraSelected &&
      self.props.setCameraSelected(self.cam);
      // Do not check actual video frames when more than one resolution is
      // provided.
      if (self.resolutions.length > 1) {
        self.reportSuccess('Supported: ' + resolution[0] + 'x' +
        resolution[1]);
        console.log('Supported: ' + resolution[0] + 'x' +
            resolution[1]);
        stream.getTracks().forEach(function(track) {
          track.stop();
        });
        self.maybeContinueGetUserMedia();
      } else {
        self.collectAndAnalyzeStats(stream, resolution);
      }
    })
    .catch(function(error) {
      if (self.resolutions.length > 1) {
        self.reportInfo(resolution[0] + 'x' + resolution[1] +
        ' not supported');
        console.log(resolution[0] + 'x' + resolution[1] +
            ' not supported');
      } else {
        self.reportError('getUserMedia failed with error: ' +
            error.name);
        console.error('getUserMedia failed with error: ' +
            error);
      }
      self.maybeContinueGetUserMedia();
    });
  }

  maybeContinueGetUserMedia() {
    if (this.currentResolution === this.props.resolutions.length) {
      this.done();
      console.log('Camera test pass');
      return;
    }
    this.startGetUserMedia(this.props.resolutions[this.currentResolution++]);
  }

  collectAndAnalyzeStats = (stream, resolution) => {
    var tracks = stream.getVideoTracks();
    let self = this;
    if (tracks.length < 1) {
      this.reportError('No video track in returned stream.');
      console.error('No video track in returned stream.');
      this.maybeContinueGetUserMedia();
      return;
    }

    // Firefox does not support event handlers on mediaStreamTrack yet.
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack
    // TODO: remove if (...) when event handlers are supported by Firefox.
    var videoTrack = tracks[0];
    if (typeof videoTrack.addEventListener === 'function') {
      // Register events.
      videoTrack.addEventListener('ended', function() {
        // Ignore events when shutting down the test.
        if (self.isShuttingDown) {
          return;
        }
        self.reportError('Video track ended, camera stopped working');
        console.error('Video track ended, camera stopped working');
      });
      videoTrack.addEventListener('mute', function() {
        // Ignore events when shutting down the test.
        if (self.isShuttingDown) {
          return;
        }
        self.reportWarning('Your camera reported itself as muted.');
        console.warn('Your camera reported itself as muted.');
        // MediaStreamTrack.muted property is not wired up in Chrome yet,
        // checking isMuted local state.
        self.isMuted = true;
      });
      videoTrack.addEventListener('unmute', function() {
        // Ignore events when shutting down the test.
        if (self.isShuttingDown) {
          return;
        }
        self.reportInfo('Your camera reported itself as unmuted.');
        console.log('Your camera reported itself as unmuted.');
        self.isMuted = false;
      });
    }

    var video = document.createElement('video');
    video.setAttribute('autoplay', 'true');
    video.setAttribute('playsInline', 'true');
    video.setAttribute('muted', '');
    video.width = resolution[0];
    video.height = resolution[1];
    video.srcObject = stream;
    // document.body.appendChild(video);
    var frameChecker = new VideoFrameChecker(video);
    // var call = new Call(null, this.test);
    this.initialize();
    stream.getTracks().forEach((track) => {
      this.pc1.addTrack(track, stream);
    });
    // this.pc1.addStream(stream);
    this.establishConnection();
    this.gatherStats(this.pc1, null, stream,
      this.onCallEnded_.bind(this, resolution, video,
        stream, frameChecker),
      100);

    setTimeout(() => {
      this.endCall_(this, stream)
    }, 8000)
    // setTimeoutWithProgressBar(this.endCall_.bind(this, call, stream), 8000);
  }

  onCallEnded_ = (resolution, videoElement, stream, frameChecker,
    stats, statsTime) => {
    this.analyzeStats_(resolution, videoElement, stream, frameChecker,
      stats, statsTime);

    frameChecker.stop();

    this.done();
    console.log('Camera test completed');
  }

  analyzeStats_ = (resolution, videoElement, stream,
    frameChecker, stats, statsTime) => {
    var googAvgEncodeTime = [];
    var googAvgFrameRateInput = [];
    var googAvgFrameRateSent = [];
    var statsReport = {};
    var frameStats = frameChecker.frameStats;

    for (var index in stats) {
      if (stats[index].type === 'ssrc') {
        // Make sure to only capture stats after the encoder is setup.
        if (parseInt(stats[index].googFrameRateInput) > 0) {
          googAvgEncodeTime.push(
            parseInt(stats[index].googAvgEncodeMs));
          googAvgFrameRateInput.push(
            parseInt(stats[index].googFrameRateInput));
          googAvgFrameRateSent.push(
            parseInt(stats[index].googFrameRateSent));
        }
      }
    }

    statsReport.cameraName = stream.getVideoTracks()[0].label || NaN;
    statsReport.actualVideoWidth = videoElement.videoWidth || videoElement.width;
    statsReport.actualVideoHeight = videoElement.videoHeight || videoElement.height;
    statsReport.mandatoryWidth = resolution[0];
    statsReport.mandatoryHeight = resolution[1];
    statsReport.encodeSetupTimeMs =
        this.extractEncoderSetupTime_(stats, statsTime);
    statsReport.avgEncodeTimeMs = arrayAverage(googAvgEncodeTime);
    statsReport.minEncodeTimeMs = arrayMin(googAvgEncodeTime);
    statsReport.maxEncodeTimeMs = arrayMax(googAvgEncodeTime);
    statsReport.avgInputFps = arrayAverage(googAvgFrameRateInput);
    statsReport.minInputFps = arrayMin(googAvgFrameRateInput);
    statsReport.maxInputFps = arrayMax(googAvgFrameRateInput);
    statsReport.avgSentFps = arrayAverage(googAvgFrameRateSent);
    statsReport.minSentFps = arrayMin(googAvgFrameRateSent);
    statsReport.maxSentFps = arrayMax(googAvgFrameRateSent);
    statsReport.isMuted = this.isMuted;
    statsReport.testedFrames = frameStats.numFrames;
    statsReport.blackFrames = frameStats.numBlackFrames;
    statsReport.frozenFrames = frameStats.numFrozenFrames;

    // TODO: Add a reportInfo() function with a table format to display
    // values clearer.
    // report.traceEventInstant('video-stats', statsReport);

    this.testExpectations_(statsReport);
  }

  endCall_ = (callObject, stream) => {
    this.isShuttingDown = true;
    stream.getTracks().forEach((track) => {
      track.stop();
    });
    this.close();
  }

  extractEncoderSetupTime_ = (stats, statsTime) => {
    for (var index = 0; index !== stats.length; index++) {
      if (stats[index].type === 'ssrc') {
        if (parseInt(stats[index].googFrameRateInput) > 0) {
          return JSON.stringify(statsTime[index] - statsTime[0]);
        }
      }
    }
    return NaN;
  }

  resolutionMatchesIndependentOfRotationOrCrop_ = (aWidth, aHeight, bWidth, bHeight) => {
    var minRes = Math.min(bWidth, bHeight);
    return (aWidth === bWidth && aHeight === bHeight) ||
           (aWidth === bHeight && aHeight === bWidth) ||
           (aWidth === minRes && bHeight === minRes);
  }

  testExpectations_ = (info) => {
    var notAvailableStats = [];
    for (var key in info) {
      if (info.hasOwnProperty(key)) {
        if (typeof info[key] === 'number' && isNaN(info[key])) {
          notAvailableStats.push(key);
        } else {
          this.reportInfo(key + ': ' + info[key]);
          console.log(key + ': ' + info[key]);
        }
      }
    }
    if (notAvailableStats.length !== 0) {
      this.reportInfo('Not available: ' + notAvailableStats.join(', '));
      console.log('Not available: ' + notAvailableStats.join(', '));
    }

    if (isNaN(info.avgSentFps)) {
      this.reportInfo('Cannot verify sent FPS.');
      console.log('Cannot verify sent FPS.');
    } else if (info.avgSentFps < 5) {
      this.reportError('Low average sent FPS: ' + info.avgSentFps);
      console.error('Low average sent FPS: ' + info.avgSentFps);
    } else {
      this.reportSuccess('Average FPS above threshold');
      console.log('Average FPS above threshold');
    }
    if (!this.resolutionMatchesIndependentOfRotationOrCrop_(
      info.actualVideoWidth, info.actualVideoHeight, info.mandatoryWidth,
      info.mandatoryHeight)) {
      this.reportError('Incorrect captured resolution.');
      console.error('Incorrect captured resolution.');
    } else {
      this.reportSuccess('Captured video using expected resolution.');
      console.log('Captured video using expected resolution.');
    }
    if (info.testedFrames === 0) {
      this.reportError('Could not analyze any video frame.');
      console.error('Could not analyze any video frame.');
    } else {
      if (info.blackFrames > info.testedFrames / 3) {
        this.reportError('Camera delivering lots of black frames.');
        console.error('Camera delivering lots of black frames.');
      }
      if (info.frozenFrames > info.testedFrames / 3) {
        this.reportError('Camera delivering lots of frozen frames.');
        console.error('Camera delivering lots of frozen frames.');
      }
    }
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  componentWillUnmount() {
    this.close();
  }
}