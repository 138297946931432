import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

import "./Spinner.less";

class Spinner extends React.Component {
  render() {
    return (
      <Modal
        isOpen={this.props.showSpinner}
        className="modal-md modalContainer spinner"
        xs="8"
        centered={true}
        style={{ background: "transparent" }}
        // z-index order (Refer Modal.js)
        // Audio/Video request modal = 1052
        // Spinner and other request modals = 1051
        // Other modals = 1050

        // WAAG-5466
        // Spinner should be over all request popups except audio/video
        zIndex={1051}
      >
        <ModalBody>
          {" "}
          <div className="loader"></div>{" "}
        </ModalBody>
      </Modal>
    );
  }
}

Spinner.propTypes = {
  aString: PropTypes.string,
  anObject: PropTypes.object,
  aFunc: PropTypes.func,
  aBool: PropTypes.bool,
  anArray: PropTypes.array,
  showSpinner: PropTypes.bool
};

Spinner.defaultProps = {
  showSpinner: false,
}

export default modalScrollBar(Spinner);
