import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import 'lrm-graphhopper';
import { withLeaflet } from "react-leaflet";
import { getMessage } from "CONFIG/i18n";

class Routing extends MapLayer {
  createLeafletElement() {
    let flag =
      this.props.uniqueId === this.props.organiser &&
      !this.props.shareLiveLocation;
    const { map, from, to, names, markerIcons } = this.props;
    
    let waypoints = [L.latLng(from[0], from[1]), L.latLng(to[0], to[1])];
    let router = window._env_.REACT_APP_USE_GRAPHHOPPER === 'true' ? new L.Routing.GraphHopper(this.props.graphHopperKey) : null;
    let leafletElement = L.Routing.control({
      router,
      plan: L.Routing.plan(waypoints, {
        createMarker: (i, wp) => {
          let fromId = this.props.eta.fromUniqueId
          let toId = this.props.eta.toUniqueId
          if (waypoints[i]) {
            let currentUserIndex = (fromId === this.props.uniqueId ? 0 : toId === this.props.uniqueId ? 1 : null)
            return L.marker(wp.latLng, {
              draggable: flag,
              icon: i == currentUserIndex ? markerIcons[0] : markerIcons[1]
            })
            .bindPopup("<label className='fromTo'>" + names[i] + "</label>")
            .openPopup();
          }
        }
      })
    }).addTo(map.leafletElement);
    this.props.routing(leafletElement);
    leafletElement.on("routesfound", e => {
      var leafletContainer = document.getElementsByClassName(
        "leaflet-routing-alternatives-container"
      );
      if (leafletContainer !== undefined) {
        leafletContainer[0].style.display = "block";
        this.addButton();
      }
      if (this.props.uniqueId === this.props.organiser) {
        this.props.shareETA();
        this.props.handleShareETA(true);
      }
    });
    leafletElement.on("routingerror", e => {
      this.props.routeError(e.error);
    });
    var plan = leafletElement.getPlan();
    plan.options.draggableWaypoints = flag;

    return plan;
  }

  addButton = () => {
    let container = document.getElementsByClassName(
      "leaflet-routing-container"
    );
    let toggleArrow = document.getElementById("toggleArrow")
    if(toggleArrow){
      return;
    }
    let div = document.createElement("div");
    let lab = document.createElement("label");
    let p = document.createElement("p");
    let ic = document.createElement("i");

    div.setAttribute("class", "arrowButton");
    div.setAttribute("id", "arrowButton");

    lab.innerText = getMessage("ROUTE");
    lab.style.display = "none";

    lab.setAttribute("class", "routeLabel");
    lab.setAttribute("id", "routeLabel");

    ic.setAttribute("class", "up");
    ic.setAttribute("id","toggleArrow");

    p.onclick = this.toggleWindow;
    p.appendChild(ic);

    div.appendChild(lab);
    div.appendChild(p);

    if(container){
      container[0].appendChild(div);
    }

  }

  toggleWindow = () => {
    var leafletContainer = document.getElementsByClassName(
      "leaflet-routing-alternatives-container"
    );
    var toggleArrow = document.getElementById("toggleArrow");
    var arrowButton = document.getElementById("arrowButton");
    var routeLabel = document.getElementById("routeLabel");

    if (leafletContainer !== undefined) {
      if(leafletContainer[0].style.display === "block"){
        leafletContainer[0].style.display = "none";
        toggleArrow.setAttribute("class","down");
        arrowButton.style.width = "6em";
        routeLabel.style.display="block"
      }
      else{
        leafletContainer[0].style.display = "block";
        toggleArrow.setAttribute("class","up")
        arrowButton.style.width = "1.7em";
        routeLabel.style.display="none"
      }
    }

  }
}
export default withLeaflet(Routing);
