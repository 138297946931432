import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";

import PageNotFound from "COMPONENTS/ErrorComponents/PageNotFound";

import UserJoin from "./UserJoin";

import {
  sessionActions as actionFactory,
  organisationAction,
  logoutAction,
  loginAction
} from "CONFIG/ActionFactory";
import { setUserName } from "./UserJoinAction";
import { CONFIG, USER_ROLES, USER_ADMISSION_STATUS, ERROR } from "UTILS/Constants";
import { checkEmpty, getURLParameter, removeTrailingSlash ,getUrlPathExcludingSearchString, getStringArrayInLowerCase } from "UTILS/Utility";

import { selectToken, selectUserRole } from "../Whiteboard/selectors";
import { disableBrowseBackBtn } from "UTILS/Utility";

export class UserJoinContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionKey: "",
      spinnerVisibility: false,
      isSSOInfoCalled: false
    };
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
    // if logged in user role is one of SUPER_ADMIN / ADMIN / AGENT
    if (
      this.props.token
      && (
        this.props.role === USER_ROLES.SUPER_ADMIN
        || this.props.role === USER_ROLES.ADMIN
        || this.props.role === USER_ROLES.AGENT
      )
    ) {
      // call logout api
      logoutAction.logoutAction();
    }
    else {
      // clear user details from reducer without calling logout api
      logoutAction.logoutUserAction();
    }
    this.getSessionKey();
    if((this.props.role !== USER_ROLES.SUPER_ADMIN ||
       this.props.role !== USER_ROLES.ADMIN) &&
       !this.state.isSSOInfoCalled) {
      let isPathPresentInConstants = getStringArrayInLowerCase(CONFIG.path).includes(
        getUrlPathExcludingSearchString(window.location.pathname).toLowerCase());
  
      console.log(`Routing: pathExists ${isPathPresentInConstants}`);
      if (isPathPresentInConstants) {
        if(this.props.uriIdentifier !== '/') {
          this.setURIidentifier("/");
        } else {
          // Calling this method in case of organisation without URI-Identifier
          // to get information about user can participate in session anonymously or not
          this.getCustomerSettings();
        }
      } else {
        this.getSSOInfo();
      }
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getSessionKey();
  }

  getCustomerSettings = () => {
    const sessionKey = getURLParameter("sessionKey");
    actionFactory.getCustomerSettings(sessionKey).then(
      responseObject => {  
        this.setState({spinnerVisibility:false});
      }, error => {
        console.error("Error getting allow anonymous Participants information", error);
        this.setState({spinnerVisibility: false});
      });
  }

  getSSOInfo = () => {
    this.setState({
      isSSOInfoCalled: true
    })
    let urlPath = removeTrailingSlash(window.location.pathname).split("/");

    // get sso info api should not get called for superadmin path
    if((urlPath[1] && urlPath[1].toLowerCase() === 'superadmin') 
    || (urlPath[1] && urlPath[1].toLowerCase() === 'admin')) {
      return;
    }

    if(urlPath[1] != (this.props.uriIdentifier.replace("/"), '')) {
      loginAction.getSSOInfo(urlPath[1]).then((response) => {
        loginAction.setURIidentifier('/' + response.customerSettings.uriIdentifier);
        organisationAction.setLogoUrl(response.customerSettings.logoUrl);
      }).catch(() => {
        loginAction.setURIidentifier('/');
      });
    }
  }

  getSessionKey = () => {
    // get session key from query parameter
    const sessionKey = getURLParameter("sessionKey");
    if(sessionKey) {
      this.setState({
        sessionKey,
      });
    }
  };

  handleProceed = (userName, errorcallBackFun) => {
    //errorcallBackFun to pass error code
    this.setState({
      spinnerVisibility: true,
    });

    if (checkEmpty(this.state.sessionKey.trim())) {
      errorcallBackFun("ERR_MSG_SESSION_KEY");
      this.setState({
        spinnerVisibility: false,
      });
    } else {
      actionFactory.joinSessionAction(this.state.sessionKey, this.props.uriIdentifier, userName).then(
        (response) => {
          this.props.setUserName(userName);
          organisationAction.setLogoUrl(response.data.data.logoUrl);
          this.setState({
            spinnerVisibility: false,
          });
          errorcallBackFun("");
          if (response.data.data.role == USER_ROLES.USER
              && response.data.data.admissionStatus != USER_ADMISSION_STATUS.ADMITTED
          ) {
            this.props.history.push(CONFIG.path.waitingRoom);
          } else {
            this.props.history.push(CONFIG.path.dashboard);
          }
        },
        (error) => {
          errorcallBackFun(
            error.code === "INVALID_SESSION"
              ? "SESSION_CLOSED"
              : error.code === ERROR.ANONYMOUS_USER_NOT_ALLOWED
                ? "ERR_MSG_YOUR_NAME"
                : error.code
          );
          this.setState({
            spinnerVisibility: false,
          });
        }
      );
    }
  };

  handleJoinWithPasscode=(sessionDetails, userName, errorcallBackFun) => {
    this.setState({
      spinnerVisibility: true,
    });
    actionFactory.getSessionKeyFromPasscode(sessionDetails).then((response)=>{
      actionFactory.joinSessionAction(response.data.sessionKey, this.props.uriIdentifier, userName).then(
        (response) => {
          this.props.setUserName(userName);
          organisationAction.setLogoUrl(response.data.data.logoUrl);
          errorcallBackFun("");
          if (response.data.data.role == USER_ROLES.USER
              && response.data.data.admissionStatus != USER_ADMISSION_STATUS.ADMITTED
          ) {
            this.props.history.push(CONFIG.path.waitingRoom);
          } else {
            this.props.history.push(CONFIG.path.dashboard);
          }
          this.setState({
            spinnerVisibility: true,
          });
        },
        (error) => {
          errorcallBackFun(
            error.code === "INVALID_SESSION"
              ? "SESSION_CLOSED"
              : error.code === ERROR.ANONYMOUS_USER_NOT_ALLOWED
                ? "ERR_MSG_YOUR_NAME"
                : error.code
          );
          this.setState({
            spinnerVisibility: false,
          });
        }
      );
    },
    (error)=>{
      errorcallBackFun("INVALID_PASSCODE");
      this.setState({
        spinnerVisibility: false,
      });
    })
  }
  
  isPageURLValid() {
    const sessionKey = getURLParameter("sessionKey");
    const isPasscodeMeeting = this.props.location.pathname.includes(CONFIG.path.passcode);
    return isPasscodeMeeting || sessionKey
  }
  
  render() {
    return (
      <React.Fragment>
        {this.isPageURLValid() ? 
          <UserJoin
            handleProceed={this.handleProceed}
            spinnerVisibility={this.state.spinnerVisibility}
            sessionKey={this.state.sessionKey}
            handleJoinWithPasscode={this.handleJoinWithPasscode}
            allowAnonymousParticipants={this.props.allowAnonymousParticipants}
            handleVoiceCommand={this.props.handleVoiceCommand}
            deregisterVoiceCommand={this.props.deregisterVoiceCommand}
          />
          :
          <PageNotFound/>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { 
    token: selectToken(state),
    role: selectUserRole(state),
    uriIdentifier: state.UserReducer.uriIdentifier,
    allowAnonymousParticipants: state.OrganisationReducer.customerSettings.allowAnonymousParticipants
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ 
    setUserName: setUserName
  }, dispatch);
};

export default withRealWear(withErrorHandler(
  connect(mapStateToProps, mapDispatchToProps)(UserJoinContainer)
));
