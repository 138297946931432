import React from "react";
import {Row, Col} from 'reactstrap';
import { isMobile } from "react-device-detect";

function TestResult(props) {
  return (
    <Row className="testRow">
      <Col 
        md={isMobile ? 8 : 9} xs={8}
        className={props.output ? "testLabel cursorPointer" : "testLabel"}
        onClick={props.setShowOutput}>
        {props.label}
      </Col> 
      <Col 
        md={isMobile ? 4 : 3} xs={4} 
        className={props.output ? "testStatus cursorPointer": "testStatus"}
        onClick={props.setShowOutput}>
        {props.status}
      </Col>
      <div className={props.showOutput 
        ? "testOutput " + props.outputPadding
        : "testOutput displayNone"}>
        {props.output}
      </div>
    </Row>
  )
}

export default TestResult;