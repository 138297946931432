import { ACTIONS } from "UTILS/Constants";

export const addLocationParticipantId = participantId => {
  console.log("addLocationParticipantId: ");
  return {
    type: ACTIONS.LOCATION.ADD_LOCATION_PARTICIPANT_ID,
    locationParticipantId: participantId
  };
};
export const addLocationData = participant => {
  return {
    type: ACTIONS.LOCATION.ADD_LOCATION_DATA,
    participantId: participant.key,
    participants: participant
  };
};
export const locationSharingWindowClosed =() => {
  return {
    type: ACTIONS.LOCATION.LOCATION_SHARING_WINDOW_CLOSED,
    locationWindowClosed: {
      locationWindowClosed: false
    },
  };
};
export const updateLocationPermissionReply = value => {
  return {
    type: ACTIONS.LOCATION.SET_LOCATION_PERMISSION_REPLY,
    isGranted: value
  };
};
export const shareETA = data => {
  return {
    type: ACTIONS.LOCATION.SHARE_ETA,
    fromUniqueId: data.fromUniqueId,
    toUniqueId: data.toUniqueId,
    fromParticipantData: data.fromParticipantData,
    toParticipantData: data.toParticipantData
  };
};
export const stopShareETA = () => {
  return {
    type: ACTIONS.LOCATION.STOP_ETA_SHARE
  };
};
export const stopLocationShare = (participant, roomParticipantsCount) => {
  return {
    type: ACTIONS.LOCATION.STOP_LOCATION_SHARE,
    participant,
    roomParticipantsCount
  };
};
export const clearLocation = () => {
  return {
    type: ACTIONS.LOCATION.CLEAR_LOCATION
  };
};