import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getMessage } from "CONFIG/i18n";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Spinner from "COMPONENTS/Spinner/Spinner";
import QRCodeSessionList from "./QRCodeSessionList";

//Actions
import { sessionActions, organisationAction } from "CONFIG/ActionFactory";

//constants
import { ERROR, sessionStatus } from "UTILS/Constants";

import { getCommonAPIErrorMessage } from "UTILS/Utility";

export class QRCodeSessionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false,
      qrCodeSessionList: {},
      qrCodeSessionListInterval: 0,
    };
  }

  componentDidMount() {
    // fixed WAAG-5605, show spinner on component mount
    this.showSpinner();
    this.getQRCodeSessionList();
    // function to fetch qrcode session list at regular interval
    this.setQRCodeSessionListInterval();

    // added listeners for handling offline online mode for QR code session list interval
    window.addEventListener("offline", this.onNetworkOfflineHandler);
    window.addEventListener("online", this.onNetworkOnlineHandler);

  }

  onNetworkOnlineHandler = () => {
    this.getQRCodeSessionList();
    this.setQRCodeSessionListInterval();
  }

  onNetworkOfflineHandler = () => {
    clearInterval(this.state.qrCodeSessionListInterval);
  }
  
  setQRCodeSessionListInterval=()=>{
    const qrCodeSessionListInterval = setInterval(() => {
      this.getQRCodeSessionList();
    }, window._env_.REACT_APP_FETCH_QR_CODE_SESSIONS_INTERVAL);
    this.setState({ qrCodeSessionListInterval: qrCodeSessionListInterval });
  }

  componentWillUnmount() {
    clearInterval(this.state.qrCodeSessionListInterval);
    // removing listeners for handling offline online mode for QR code session list interval
    window.removeEventListener("online", this.onNetworkOnlineHandler);
    window.removeEventListener("offline", this.onNetworkOfflineHandler);
  }

  getQRCodeSessionList = () => {
    sessionActions.getQRCodeSessionList(sessionStatus.WAITING).then(
      (response) => {
        this.setState({
          qrCodeSessionList: response.data.data.qrCodeSessionsList,
        });
        this.hideSpinner();
      },
      (error) => {
        this.hideSpinner();
        const isCommonError = !!getCommonAPIErrorMessage(error.code);
        isCommonError && clearInterval(this.state.qrCodeSessionListInterval);
        toastr.error(error);
        if (error.code === ERROR.MODULES_NOT_ALLOWED) {
          clearInterval(this.state.qrCodeSessionListInterval);
          this.props.setQRCodeEnabled(false)
        } else if (error.code === ERROR.AGENT_SESSION_QUEUE_NOTIFICATIONS_DISABLED) {
          clearInterval(this.state.qrCodeSessionListInterval);
          organisationAction.setCustomerData({
            ...this.props.customerData,
            isAgentSessionQueueEnabled: false
          });
        }
      }
    );
  };

  joinQRCodeSession = (sessionKey) => {
    this.showSpinner();
    let userId = this.props.userId;
    let language = this.props.language;
    this.props.history.push(`${sessionKey}/${userId}/${language}`);
  };

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

  render() {
    return (
      <>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        <QRCodeSessionList
          joinQRCodeSession={this.joinQRCodeSession}
          qrCodeSessionList={this.state.qrCodeSessionList}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    userId: state.UserReducer.userDetails.userId
  };
};

export default withRouter(
  connect(
    mapStateToProps
  )(QRCodeSessionListContainer)
);
