import React, { Component } from "react";
import { connect } from "react-redux"
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';

//COMPONENTS
import TrailHistory from "./TrailHistory"
import Spinner from "COMPONENTS/Spinner/Spinner";

//Action
import { startSessionAction as actionFactory } from "CONFIG/ActionFactory";

//UTILS
import { CONFIG, sessionStatus } from "UTILS/Constants";
import { disableBrowseBackBtn } from "UTILS/Utility";

class TrailHistoryContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pastSessionsDetails: [],
      totalSessions: 0,
      spinnerVisibility: false,
      showTrailHistory: false
    }
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });

    this.getPastSessionsList(
      this.props.trailHistoryData.sessionListPageSize,
      this.props.trailHistoryData.displayPage,
      this.props.trailHistoryData.searchKey)
  }

  setTrailHistoryData = (page, limit) => {
    actionFactory.setTrailHistoryData({
      displayPage: page,
      sessionListPageSize: limit,
    });
  };
  getPastSessionsList = (limit = 10, page = 0, search) => {
    actionFactory.getPastSessionAction(sessionStatus.CLOSED, limit, page, search).then(
      data => {
        this.setState({
          pastSessionsDetails: data.data,
          totalSessions: data.data.total,
          showTrailHistory: true
        });
        this.setTrailHistoryData(page, limit);
      },
      error => {
        toastr.error(error.code);
        this.setState({
          showTrailHistory: true
        });
      }
    );
  };

  backToMenu = () => {
    this.props.history.push(CONFIG.path.createSession);
    this.setState({
      showTrailHistory: false
    });
  }

  render() {
    return (
      <>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        {this.state.showTrailHistory &&
          <TrailHistory
            setTrailHistoryData={this.setTrailHistoryData}
            backToMenu={this.backToMenu}
            pastSessionsDetails={this.state.pastSessionsDetails}
            getPastSessionsList={this.getPastSessionsList}
            totalSessions={this.state.totalSessions}
            trailHistoryData={this.props.trailHistoryData}
            sessionListPageSize={this.props.trailHistoryData.sessionListPageSize}
            displayPage={this.props.trailHistoryData.displayPage}
          />
        }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    trailHistoryData: state.SessionReducer.trailHistoryData
  };
};

export default connect(mapStateToProps)(TrailHistoryContainer);