import React, { Component } from "react";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import {
  getUserName,
  getChatData,
  selectSessionKey,
  selectUniqueId,
  selectParticipantId
} from "../../Routes/selectors";
import * as log from "loglevel";

import { selectOrganiserParticipantId } from "REDUX/Selector/SessionSelector";

import Chat from "./Chat";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addMessage,setChatFontSize } from "./ChatAction";
import { sessionActions as actionFactory } from "CONFIG/ActionFactory";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";

import { socketMessage } from "WEBSOCKET/constants";
import { ERROR, CHAT, REGEX } from "UTILS/Constants";

import { selectChatMessages, getSelectedFontSize } from "./selectors";

import { getUserNameForSelectedLanguage } from "UTILS/Utility";

import { getMessage } from "CONFIG/i18n";

// CONSTANTS
import { downloadableModules} from "UTILS/Constants";
import { selectAllowedDownloads } from "../CommonComponents/Header/selectors";

class ChatContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastMessageParticipantId: null
    }
  }

  sendChatMessage = chatMessage => {
    const { userName, sessionKey } = this.props;

    // If the message starts with @Chat (Case insensitive), then the '@Chat' will be shown as bold
    // to distinguish it from other messages.
    // While sending the message to websocket, normal message will be sent as server has logic 
    // to read messages starting with @Chat.  
    let messageText = chatMessage;

    // Only agent can use Chat GPT if chat GPT is enabled in Super Admin settings. If chat GPT is disabled then
    // messages starting with @chat will be considered as normal messages.
    // @chat will be seen in bold if the user is an agent as
    // messages start with @chat will be considered normal messages for
    // the users other than agents.
    if(this.props.isChatGPTEnabled && this.props.organiser === this.props.uniqueId){
      messageText = this.getFormattedChatGPTPrompt(chatMessage);
    }

    const message = {
      data: {
        category: socketMessage.category.CHAT,
        action: socketMessage.subCategories.CHAT.SEND_MESSAGE,
        sender: userName,
        sessionKey: sessionKey,
        message: chatMessage,
        participantId: this.props.participantId
      }
    };
    this.props.addMessage({
      sender: userName,
      message: messageText,
      ownMessage: true,
      participantId: this.props.participantId
    });
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);

    this.setState({
      lastMessageParticipantId: this.props.participantId
    });
  };

  minimumTwoDigits = value => {
    return ("0" + value).slice(-2);
  };

  broadcastDownloadAction = (downloadType) => {
    const message = {
      data: {
        category: socketMessage.category.DOWNLOAD,
        action: socketMessage.subCategories.WHITEBOARD.OBJECT_DOWNLOADED,
        sessionKey: this.props.sessionKey,
        downloadType: downloadType
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  downloadChatMessages = () => {
    this.broadcastDownloadAction(downloadableModules.CHAT);
    let timestamp = new Date().getMilliseconds();
    actionFactory.downloadChatMessages(this.props.sessionKey, timestamp).then(
      data => {
        var textFile = null;
        var text = "";

        data.data.forEach(object => {
          if(!(object.receiverId && (this.props.organiserParticipantId !== this.props.participantId))) {
            let senderName = object.sender;
            let message = object.message;

            if (object.error) {
              message = this.getErrorMessage(object.error.code);
            }

            if(object.participantId === this.props.organiserParticipantId) {
              senderName = getUserNameForSelectedLanguage(this.props.language, object.sender);
            } else if(object.participantId === CHAT.CHAT_GPT_SENDER_PARTICIPANT_ID){
              senderName = getMessage("CHAT_GPT_SENDER_NAME")
            }

            let d = new Date(object.timestamp);
            let time =
            this.minimumTwoDigits(d.getDate()) +
            "/" +
            this.minimumTwoDigits(d.getMonth() + 1) +
            "/" +
            d.getFullYear() +
            " " +
            this.minimumTwoDigits(d.getHours()) +
            ":" +
            this.minimumTwoDigits(d.getMinutes()) +
            ":" +
            this.minimumTwoDigits(d.getSeconds());
            text =
            text +
            senderName +
            " @ " +
            time +
            " : " +
            message +
            "\r\n";
          }
        });
        let fileText = new Blob([text], { type: "text/plain" });
        var isIE = /*@cc_on!@*/ false || !!document.documentMode;
        if (isIE) {
          navigator.msSaveBlob(fileText, "messages.txt");
        } else {
          textFile = window.URL.createObjectURL(fileText);

          let downloadElement = document.createElement("a");
          downloadElement.href = textFile;
          downloadElement.download = "messages.txt";
          downloadElement.click();
        }
      },
      error => {
        toastr.error(error.code);
        log.error("ERROR: ", error);
      }
    );
  };

  isChatDownloadAllowed = () => {
    return this.props.allowedDownloads && this.props.allowedDownloads.hasOwnProperty(downloadableModules.CHAT)
  }

  getErrorMessage = (errorCode) => {
    switch(errorCode) {

      case ERROR.CHAT_GPT_CONFIGURATION_ERROR: 
        return getMessage("CHAT_GPT_CONFIGURATION_ERROR");

      default: 
        return getMessage("CHAT_GPT_GENERIC_ERROR");
    }
  }

  // Get @chat keyword
  getChatGPTMessageKeyword = (message) => {
    // Remove bold tag if the keyword is "<b>@chat</b>".
    // TODO: 1. Check the logic of saving ownMessage in reducer and if we can store ownMessage i.e.
    // the prompt message with keyword @chat without bold tag.
    // 2. Check functionality of exec to check regex match
    const messageWithoutBoldTag = message.replace(REGEX.HTML_BOLD_TAG, "");
    return messageWithoutBoldTag.match(REGEX.CHAT_GPT_PROMPT_IDENTIFIER)?.[0];
  }

  getFormattedChatGPTPrompt = (message) => {
    const keyword = this.getChatGPTMessageKeyword(message);

    // Make @chat bold
    if(keyword) {
      return message.replace(REGEX.CHAT_GPT_PROMPT_IDENTIFIER, `<b>${keyword}</b>`);
    }
    return message;
  }

  render() {
    const isOrganiserIn = this.props.admittedParticipants?.filter(data => data.uniqueId == this.props.organiser).length > 0;
    return (
      <React.Fragment>
        {isOrganiserIn &&
          <Chat
            sendChatMessage={this.sendChatMessage}
            userName={this.props.userName}
            chatPopOver={this.props.chatPopOver}
            chatMessages={this.props.chatMessages}
            handleClose={this.props.handleClose}
            downloadChatMessages={this.downloadChatMessages}
            isChatDownloadAllowed={this.isChatDownloadAllowed()}
            participantId={this.props.participantId}
            language={this.props.language}
            organiserParticipantId={this.props.organiserParticipantId}
            isChatGPTEnabled={this.props.isChatGPTEnabled}
            isOrganiser={this.props.organiser === this.props.uniqueId}
            lastMessageParticipantId={this.state.lastMessageParticipantId}
            setChatFontSize={this.props.setChatFontSize}
            selectedFontSize={this.props.selectedFontSize}
            getErrorMessage={this.getErrorMessage}
            getChatGPTMessageKeyword={this.getChatGPTMessageKeyword}
            getFormattedChatGPTPrompt={this.getFormattedChatGPTPrompt}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: getUserName(state),
    sessionKey: selectSessionKey(state),
    uniqueId: selectUniqueId(state),
    chatMessages: selectChatMessages(state),
    allowedDownloads: selectAllowedDownloads(state),
    participantId: selectParticipantId(state),
    language: state.LanguageReducer.language,
    organiserParticipantId: selectOrganiserParticipantId(state),
    isChatGPTEnabled: state.OrganisationReducer.isChatGPTEnabled,
    selectedFontSize: getSelectedFontSize(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage,
      addMessage: addMessage,
      setChatFontSize: setChatFontSize
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatContainer);
