import React from "react";

import "../Spinner.less";

class ComponentSpinner extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 spinnerBackground">
        <div className="componentLoader"
          role="status"
          id={this.props.componentId+"componentLoader"}
          style={{ fontSize: this.props.loaderSize ? this.props.loaderSize : "8px" }}
        >
        </div>
      </div>
    );
  }
}

export default ComponentSpinner;
