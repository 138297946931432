import _ from "lodash";
import { isIE } from 'react-device-detect';

import { ACTIONS, CANVAS_TYPES, DEFAULT_FONT_SIZE, DEFAULT_FONT_FAMILY, DEFAULT_FONT_COLOR } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";
import { CANVAS_SOURCE, COLOR_LIST } from "../../Utils/Constants";

const initialState = {
  activeCanvas: {
    canvasId: null,
    canvasType: null
  },
  canvases: {},
  canvasHistory: [], // TODO: remove if not used
  defaultFontInfo: {
    fontSize: DEFAULT_FONT_SIZE,
    fontFamily: DEFAULT_FONT_FAMILY,
    fontColor: DEFAULT_FONT_COLOR
  },
  localFontInfo: null,
  uploadImage: false, // TODO: remove if not used
  activeCanvasRef: null,
  isFitScreen: false,
  clearCanvas: false,
  frameSize: {},
  selectedAnnotation: null,
  /**
  isDownloadingOnCanvas: null -- initial value
  isDownloadingOnCanvas: true -- upload image, pdf OR download location, snapshot started on canvas
  isDownloadingOnCanvas: false -- error while upload/download operation
   */
  isDownloadingOnCanvas: null
};

export default function (state = initialState, action) {
  let canvases;
  let currentCanvas = null;
  let newCanvasObject = {};
  switch (action.type) {
    case ACTIONS.WHITEBOARD.SET_CANVAS_COLOR:
      let defaultFontInfo = _.cloneDeep(state.defaultFontInfo);
      defaultFontInfo.fontColor = COLOR_LIST[action.color]
      return {
        ...state,
        defaultFontInfo: defaultFontInfo
      };
    case ACTIONS.WHITEBOARD.CLEAR_PDF_PAGE_OBJECTS:
      canvases = _.cloneDeep(state.canvases);
      if(canvases[action.activeCanvasID] && canvases[action.activeCanvasID].canvasData)
        canvases[action.activeCanvasID].canvasData[action.activePage] = {};
      return {
        ...state,
        canvases
      };
      break;
    case ACTIONS.WHITEBOARD.SET_ACTIVE_CANVAS:
      return {
        ...state,
        activeCanvas: action.activeCanvas,
        uploadImage: action.activeCanvas.uploadImage
          ? action.activeCanvas.uploadImage
          : false
      };
    case "_FRAME_SIZE":
      return {
        ...state,
        frameSize: action.data
      }
    case ACTIONS.WHITEBOARD.SAVE_PDF_FILE:
      return {
        ...state,
        pdf: action.pdf
      };
    case ACTIONS.WHITEBOARD.SET_WHITE_BOARD_REF:
      return {
        ...state,
        activeCanvasRef: action.activeCanvasRef
      };
    case ACTIONS.WHITEBOARD.ADD_CANVAS:
      newCanvasObject = {};
      newCanvasObject[action.newCanvas.canvasId] = {
        canvasType: action.newCanvas.canvasType,
        canvasSource: action.newCanvas.canvasSource,
        canvasData: {},
        lastUpdatedObjectId: null,
        ownerOfLastAction: null,
        actionObject:
          action.newCanvas.canvasType === CANVAS_TYPES.PDF ? {} : [],
        redoObject: [],
        thumbnailUrl: null,
        frameDetails: {},
        fileUrl: action.newCanvas.fileUrl ? action.newCanvas.fileUrl : null,
        originalFileName: action.newCanvas.originalFileName,
        activePage: action.newCanvas.activePage
          ? action.newCanvas.activePage
          : null,
        resolution: action.newCanvas.resolution
      };
      return {
        ...state,
        canvases: {
          ...state.canvases,
          ...newCanvasObject
        }
      };

    case ACTIONS.WHITEBOARD.DELETE_CANVAS:
      canvases = _.cloneDeep(state.canvases);
      let canvasHistory = state.canvasHistory;
      let canvasToBeDeleted = (function () {
        for (let i = 0; i < canvasHistory.length; i++) {
          if (canvasHistory[i].canvasId === action.canvasId) {
            return i;
          }
        }
      })();
      canvasToBeDeleted && canvasHistory.splice(canvasToBeDeleted, 1);
      delete canvases[action.canvasId];
      // delete entry of canvas id from history data
      return {
        ...state,
        canvases: canvases,
        canvasHistory: canvasHistory
      };
      break;
    case ACTIONS.WHITEBOARD.ADD_OBJECT:
      canvases = _.cloneDeep(state.canvases);
      currentCanvas = canvases[action.data.canvasId];
      if (currentCanvas) {
        let obj = {};
        if (action.data.canvasType == CANVAS_TYPES.PDF) {
          let key = action.data.whiteboardData.objectId;
          if (
            currentCanvas.canvasData[action.data.activePage] === undefined
          ) {
            currentCanvas.canvasData[action.data.activePage] = {};
          }
          currentCanvas.canvasData[action.data.activePage][key] =
            action.data.whiteboardData;
        } else {
          currentCanvas.canvasData[action.data.whiteboardData.objectId] =
            action.data.whiteboardData;
        }
        currentCanvas.lastUpdatedObjectId = action.data.whiteboardData.objectId;
        currentCanvas.ownerOfLastAction = action.data.participantId;
        updateActionOrUndoObject(currentCanvas, action.data, action.type);
        return {
          ...state,
          canvases: canvases
        };
      }
      return state;
      break;
    case ACTIONS.WHITEBOARD.DELETE_OBJECT:
      canvases = _.cloneDeep(state.canvases);
      currentCanvas = canvases[action.data.canvasId];
      if (currentCanvas) {
        if (action.data.canvasType === CANVAS_TYPES.PDF) {
          action.data.whiteboardData.objectId.length > 0 &&
          action.data.whiteboardData.objectId.forEach((id) => {
            canvases[action.data.canvasId].canvasData[currentCanvas.activePage].hasOwnProperty(id) &&
            delete currentCanvas.canvasData[currentCanvas.activePage][id];
          })
        } else if (
          currentCanvas.canvasData.hasOwnProperty(
            action.data.whiteboardData.objectId
          )
        ) {
          delete currentCanvas.canvasData[action.data.whiteboardData.objectId];
        }
        currentCanvas.lastUpdatedObjectId = action.data.whiteboardData.objectId;
        currentCanvas.ownerOfLastAction = action.data.participantId;
        updateActionOrUndoObject(currentCanvas, action.data, action.type);
        return {
          ...state,
          canvases: canvases
        };
      }
      return state;
      break;
    case ACTIONS.WHITEBOARD.UPDATE_OBJECT:
    case ACTIONS.WHITEBOARD.MODIFY_OBJECT:
      canvases = _.cloneDeep(state.canvases);
      currentCanvas = canvases[action.data.canvasId];
      if (currentCanvas) {
        if (
          action.data.canvasType == CANVAS_TYPES.PDF &&
          canvases[action.data.canvasId].canvasData[currentCanvas.activePage
          ].hasOwnProperty(action.data.whiteboardData.objectId)
        ) {
          currentCanvas.canvasData[currentCanvas.activePage][action.data.whiteboardData.objectId
          ] = action.data.whiteboardData;
        } else if (
          canvases[action.data.canvasId].canvasData.hasOwnProperty(
            action.data.whiteboardData.objectId
          )
        ) {
          currentCanvas.canvasData[action.data.whiteboardData.objectId] =
            action.data.whiteboardData;
        }
        currentCanvas.lastUpdatedObjectId = action.data.whiteboardData.objectId;
        currentCanvas.ownerOfLastAction = action.data.participantId;
        updateActionOrUndoObject(currentCanvas, action.data, action.type);
        return {
          ...state,
          canvases: canvases
        };
      }
      return state;
      break;
    // commented as moved to ACTIONS.WHITEBOARD.UPDATE_OBJECT case since steps are same 
    // case ACTIONS.WHITEBOARD.MODIFY_OBJECT:
    //   console.log('whiteboardReducer: ', state.canvases);
    //   canvases = _.cloneDeep(state.canvases);
    //   currentCanvas = canvases[action.data.canvasId];
    //   updateActionOrUndoObject(currentCanvas, action.data, action.type);
    //   return {
    //     ...state,
    //     canvases: canvases
    //   };
    //   break;
    case ACTIONS.WHITEBOARD.CLEAR_CANVAS: {
      canvases = _.cloneDeep(state.canvases);
      if (canvases) {
        let keys = Object.keys(canvases);

        let canvasIdExists = false;

        if (isIE) {
          keys.forEach(element => {
            if (element == state.activeCanvas.canvasId) {
              canvasIdExists = true;
              return;
            }
          });
        }

        if (
          canvases.hasOwnProperty(state.activeCanvas.canvasId) ||
          canvasIdExists
        ) {
          const canvas = canvases[state.activeCanvas.canvasId];
          if (canvas.canvasType !== CANVAS_TYPES.PDF) {
            canvas.canvasData = {};
            // canvas.fileUrl = null;
          } else {
            canvas.canvasData[action.activePage] = {};
          }
          canvas.lastUpdatedObjectId = canvas.lastUpdatedObjectId;
          canvas.ownerOfLastAction = canvas.ownerOfLastAction;
          canvas.actionObject = [];
          canvas.redoObject = [];
        }
        return {
          ...state,
          canvases: canvases,
          clearCanvas: true
        };
      }
      return state;
    }
    case ACTIONS.WHITEBOARD.SET_CLEAR_CANVAS_FLAG: 
      return {
        ...state,
        clearCanvas: action.value
      };
    case ACTIONS.WHITEBOARD.STORE_CANVASES:
      return {
        ...state,
        canvasHistory: action.data
      };
    case ACTIONS.WHITEBOARD.SET_CANVAS_DATA:
      defaultFontInfo = action.data.defaultFontInfo ?
        action.data.defaultFontInfo :
        state.localFontInfo ? state.localFontInfo : state.defaultFontInfo
      let activeCanvas = {
        canvasId: null,
        canvasType: null
      };
      if (action.data && action.data.activeCanvas && action.data.canvases[action.data.activeCanvas]?.canvasType &&
        (!(action.data.canvases[action.data.activeCanvas].canvasType == CANVAS_TYPES.REGULAR &&
        action.data.canvases[action.data.activeCanvas].canvasSource == CANVAS_SOURCE.SNAPSHOT))) {
        (activeCanvas.canvasId = action.data.activeCanvas), (activeCanvas.canvasType =
          action.data.canvases[action.data.activeCanvas].canvasType);
      } else if (action.data.canvases[action.data.activeCanvas] && action.data.canvases[action.data.activeCanvas]?.canvasType
        && action.data.canvases[action.data.activeCanvas].canvasType == CANVAS_TYPES.REGULAR 
        && action.data.canvases[action.data.activeCanvas].canvasSource == CANVAS_SOURCE.SNAPSHOT) {
        let keys = Object.keys(action.data.canvases)
        activeCanvas.canvasId = parseInt(keys[0]);
        activeCanvas.canvasType =
          action.data.canvases[keys[0]].canvasType;
      }
      _.map(action.data.canvases, canvas => {
        canvas.canvasData = _.cloneDeep(canvas.objects);
        delete canvas.objects;
      });
      return {
        ...state,
        activeCanvas: activeCanvas,
        canvases: action.data.canvases,
        defaultFontInfo: defaultFontInfo
      };
    case ACTIONS.WHITEBOARD.SET_CANVAS_THUMBNAIL:
      canvases = _.cloneDeep(state.canvases);
      currentCanvas = canvases[action.data.canvasId];
      if (currentCanvas) {
        currentCanvas.thumbnailUrl = action.data.thumbnailUrl;
      }
      return {
        ...state,
        canvases: canvases
      };
    case ACTIONS.WHITEBOARD.SET_DEFAULT_FONT_INFO:
      return {
        ...state,
        localFontInfo: action.data.isLocally ? action.data : null, //if isLocally true then store data in localFontInfo, which will broadcast at agent's end only
        defaultFontInfo: action.data.isLocally ? state.defaultFontInfo : action.data //if not isLocally false then store data in defaultFontInfo, which will broadcast in entire session
      };
    case ACTIONS.PDF.SET_PAGE_POSITION:
      newCanvasObject = _.cloneDeep(state.canvases);
      newCanvasObject[action.data.canvasId].frameDetails =
        action.data.frameDetails;
      return {
        ...state,
        canvases: {
          ...state.canvases,
          ...newCanvasObject
        }
      };
    case ACTIONS.PDF.SET_FIT_SCREEN:
      return {
        ...state,
        isFitScreen: action.isFitScreen
      };
    case ACTIONS.PDF.CHANGE_ACTIVE_PAGE:
      newCanvasObject = _.cloneDeep(state.canvases);
      newCanvasObject[action.data.canvasId].activePage = action.data.activePage;
      return {
        ...state,
        canvases: {
          ...state.canvases,
          ...newCanvasObject
        }
      };
    case ACTIONS.PDF.UPDATE_URL:
      newCanvasObject = _.cloneDeep(state.canvases);
      newCanvasObject[action.data.canvasId].fileUrl = action.data.newUrl;
      newCanvasObject[action.data.canvasId].activePage = action.data.pageNumber;
      return {
        ...state,
        canvases: {
          ...state.canvases,
          ...newCanvasObject
        }
      };
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return {
        ...initialState,
        // defaultFontInfo: { fontColor: state.defaultFontInfo.fontColor },
        localFontInfo: action.cleanLocalFontInfo ? initialState.localFontInfo : state.localFontInfo
      };
    case ACTIONS.WHITEBOARD.SET_SELECTED_ANNOTATION:
      return {
        ...state,
        selectedAnnotation: action.annotation
      }
    case ACTIONS.WHITEBOARD.DOWNLOADING_TO_CANVAS:
      return {
        ...state,
        isDownloadingOnCanvas: action.value
      }
    default:
      return state;
  }
}

function updateActionOrUndoObject(canvas, data, action) {
  switch (data.subAction) {
    case socketMessage.subCategories.WHITEBOARD.subActions.REGULAR: {
      if (!canvas.actionObject) canvas.actionObject = [];
      if (!canvas.redoObject) canvas.redoObject = [];
      switch (action) {
        case ACTIONS.WHITEBOARD.ADD_OBJECT:
          if (canvas.canvasType !== "PDF") {
            if(data.whiteboardData.objectType != "Text" ||
              data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject.push({
                action: ACTIONS.WHITEBOARD.ADD_OBJECT,
                prevObject: null,
                nextObject: data.whiteboardData.canvasObject,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          } else {
            if (canvas.actionObject[canvas.activePage] === undefined) {
              canvas.actionObject[canvas.activePage] = [];
            }
            if(data.whiteboardData.objectType != "Text" ||
            data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject[canvas.activePage].push({
                action: ACTIONS.WHITEBOARD.ADD_OBJECT,
                prevObject: null,
                nextObject: data.whiteboardData.canvasObject,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          }
          break;
        case ACTIONS.WHITEBOARD.DELETE_OBJECT:
          if (canvas.canvasType !== "PDF") {
            if(data.whiteboardData.objectType != "Text" ||
              data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject.push({
                action: ACTIONS.WHITEBOARD.DELETE_OBJECT,
                prevObject: data.whiteboardData.canvasObject,
                nextObject: null,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          } else {
            if (canvas.actionObject[canvas.activePage] === undefined) {
              canvas.actionObject[canvas.activePage] = [];
            }
            if(data.whiteboardData.objectType != "Text" ||
              data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject[canvas.activePage].push({
                action: ACTIONS.WHITEBOARD.DELETE_OBJECT,
                prevObject: data.whiteboardData.canvasObject,
                nextObject: null,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          }
          break;
        case ACTIONS.WHITEBOARD.MODIFY_OBJECT:
          if (canvas.canvasType !== "PDF") {
            if(data.whiteboardData.objectType != "Text" ||
              data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject.push({
                action: ACTIONS.WHITEBOARD.UPDATE_OBJECT,
                prevObject: data.whiteboardData.oldCanvasObject,
                nextObject: data.whiteboardData.canvasObject,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          } else {
            if (canvas.actionObject[canvas.activePage] === undefined) {
              canvas.actionObject[canvas.activePage] = [];
            }
            if(data.whiteboardData.objectType != "Text" ||
              data.whiteboardData.canvasObject.text.length >= 1) {
              canvas.actionObject[canvas.activePage].push({
                action: ACTIONS.WHITEBOARD.UPDATE_OBJECT,
                prevObject: data.whiteboardData.oldCanvasObject,
                nextObject: data.whiteboardData.canvasObject,
                objectId: data.whiteboardData.objectId,
                objectType: data.whiteboardData.objectType
              });
            }
          }
          break;
        default:
          break;
      }
      break;
    }

    case socketMessage.subCategories.WHITEBOARD.subActions.UNDO:
      if (canvas.actionObject)
        if (canvas.canvasType !== CANVAS_TYPES.PDF) {
          canvas.redoObject.push(
            canvas.actionObject[canvas.actionObject.length - 1]
          );
          canvas.actionObject.pop();
        } else {
          if (canvas.redoObject[canvas.activePage] === undefined) {
            canvas.redoObject[canvas.activePage] = [];
          }
          canvas.redoObject[canvas.activePage].push(
            canvas.actionObject[canvas.activePage][
            canvas.actionObject[canvas.activePage].length - 1
            ]
          );
          canvas.actionObject[canvas.activePage].pop();
        }
      break;
    case socketMessage.subCategories.WHITEBOARD.subActions.REDO:
      if (canvas.redoObject)
        if (canvas.canvasType !== CANVAS_TYPES.PDF) {
          canvas.actionObject.push(
            canvas.redoObject[canvas.redoObject.length - 1]
          );
          canvas.redoObject.pop();
        } else {
          if (canvas.actionObject[canvas.activePage] === undefined) {
            canvas.actionObject[canvas.activePage] = [];
          }
          canvas.actionObject[canvas.activePage].push(
            canvas.redoObject[canvas.activePage][
            canvas.redoObject[canvas.activePage].length - 1
            ]
          );
          canvas.redoObject[canvas.activePage].pop();
        }
      break;
    default:
      break;
  }
}
