import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";

export default class MicTest extends BaseTest {
  constructor(props) {
    super(props)
    console.log("Mic Test constructor");
    this.setValues();
    var AudioContext = window.AudioContext || window.webkitAudioContext;
    // The AudioContext interface represents an audio-processing graph built
    // from audio modules linked together, each represented by an AudioNode.
    this.audioContext = new AudioContext();
  }

  setValues = () => {
    // this.test = test;
    super.output=""
    this.inputChannelCount = 6;
    this.outputChannelCount = 2;
    // Buffer size set to 0 to let Chrome choose based on the platform.
    this.bufferSize = 0;
    // Turning off echoCancellation constraint enables stereo input.
    this.constraints = {
      audio: {
        optional: [
          {echoCancellation: false}
        ]
      }
    };

    this.collectSeconds = 2.0;
    // At least one LSB 16-bit data (compare is on absolute value).
    this.silentThreshold = 1.0 / 32767;
    this.lowVolumeThreshold = -60;
    // Data must be identical within one LSB 16-bit to be identified as mono.
    this.monoDetectThreshold = 1.0 / 65536;
    // Number of consequtive clipThreshold level samples that indicate clipping.
    this.clipCountThreshold = 6;
    this.clipThreshold = 1.0;

    // Populated with audio as a 3-dimensional array:
    //   collectedAudio[channels][buffers][samples]
    this.collectedAudio = [];
    this.collectedSampleCount = 0;
    for (var i = 0; i < this.inputChannelCount; ++i) {
      this.collectedAudio[i] = [];
    }
  }

  componentDidMount() {
    this.test(this);
    // if(this.props.startTest) {
    //   this.setState({
    //     testFinalStatus: "Running"
    //   })
    //   this.run()
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest) {
      this.setValues();
      this.resetValues();
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
  }

  run() {
    let self = this;
    // Resuming as per new spec after user interaction.
    this.audioContext.resume().then(() => {
      this.doGetUserMedia(this.constraints, this.gotStream.bind(this))
    })
    .catch(function(error) {
      self.reportError('WebAudio run failure: ' + error);
      self.done();
      console.error('WebAudio run failure: ' + error);
    }.bind(this));
  }

  gotStream(stream) {
    console.log('got stream');
    if (!this.checkAudioTracks(stream)) {
      this.done();
      console.log('Mic test completed');
      return;
    }
    this.createAudioBuffer(stream);
  }

  checkAudioTracks(stream) {
    this.stream = stream;
    var audioTracks = stream.getAudioTracks();
    if (audioTracks.length < 1) {
      this.reportError('No audio track in returned stream.');
      console.error('No audio track in returned stream.');
      return false;
    }
    this.reportSuccess('Audio track created using device=' +
    audioTracks[0].label);
    console.log('Audio track created using device=',
      audioTracks[0].label);
    return true;
  }

  createAudioBuffer() {
    console.log('createAudioBuffer');
    this.audioSource = this.audioContext.createMediaStreamSource(this.stream);
    this.scriptNode = this.audioContext.createScriptProcessor(this.bufferSize,
      this.inputChannelCount, this.outputChannelCount);
    this.audioSource.connect(this.scriptNode);
    this.scriptNode.connect(this.audioContext.destination);
    this.scriptNode.onaudioprocess = this.collectAudio.bind(this);
    // this.stopCollectingAudio = setTimeoutWithProgressBar(
    //   this.onStopCollectingAudio.bind(this), 5000);
    this.timeout = setTimeout(() => {
      this.onStopCollectingAudio()
    }, 5000);
  }

  collectAudio(event) {
    // Simple silence detection: check first and last sample of each channel in
    // the buffer. If both are below a threshold, the buffer is considered
    // silent.
    var sampleCount = event.inputBuffer.length;
    var allSilent = true;
    for (var c = 0; c < event.inputBuffer.numberOfChannels; c++) {
      var data = event.inputBuffer.getChannelData(c);
      var first = Math.abs(data[0]);
      var last = Math.abs(data[sampleCount - 1]);
      var newBuffer;
      if (first > this.silentThreshold || last > this.silentThreshold) {
        // Non-silent buffers are copied for analysis. Note that the silent
        // detection will likely cause the stored stream to contain discontinu-
        // ities, but that is ok for our needs here (just looking at levels).
        newBuffer = new Float32Array(sampleCount);
        newBuffer.set(data);
        allSilent = false;
      } else {
        // Silent buffers are not copied, but we store empty buffers so that the
        // analysis doesn't have to care.
        newBuffer = new Float32Array();
      }
      this.collectedAudio[c].push(newBuffer);
    }

    // sample cpunt increment only when the input buffer is not completely silent
    if (!allSilent) {
      this.collectedSampleCount += sampleCount;
      if ((this.collectedSampleCount / event.inputBuffer.sampleRate) >=
          this.collectSeconds) {
        this.onStopCollectingAudio();
      }
    }
  }

  onStopCollectingAudio = () => {
    console.log('onStopCollectingAudio: ',this.audioSource, this.scriptNode);
    if(!this.stream)
      return;
    this.stream.getAudioTracks()[0].stop();
    try {
      this.audioSource.disconnect(this.scriptNode);
      this.scriptNode.disconnect(this.audioContext.destination);
    } catch (error) {
      console.error("onStopCollectingAudio error: ", error);
    }
    this.analyzeAudio(this.collectedAudio);
    this.done();
  }

  analyzeAudio(channels) {
    var activeChannels = [];
    for (var c = 0; c < channels.length; c++) {
      if (this.channelStats(c, channels[c])) {
        activeChannels.push(c);
      }
    }
    if (activeChannels.length === 0) {
      console.error('No active input channels detected. Microphone ' +
      'is most likely muted or broken, please check if muted in the ' +
      'sound settings or physically on the device. Then rerun the test.');
      this.reportError('No active input channels detected. Microphone ' +
          'is most likely muted or broken, please check if muted in the ' +
          'sound settings or physically on the device. Then rerun the test.');
    } else {
      console.log('Active audio input channels: ' +
      activeChannels.length);
      this.reportSuccess('Active audio input channels: ' +
          activeChannels.length);
    }
    if (activeChannels.length === 2) {
      this.detectMono(channels[activeChannels[0]], channels[activeChannels[1]]);
    }
  }

  channelStats(channelNumber, buffers) {
    var maxPeak = 0.0;
    var maxRms = 0.0;
    var clipCount = 0;
    var maxClipCount = 0;
    for (var j = 0; j < buffers.length; j++) {
      var samples = buffers[j];
      if (samples.length > 0) {
        var s = 0;
        var rms = 0.0;
        for (var i = 0; i < samples.length; i++) {
          s = Math.abs(samples[i]);
          maxPeak = Math.max(maxPeak, s);
          rms += s * s;
          if (maxPeak >= this.clipThreshold) {
            clipCount++;
            maxClipCount = Math.max(maxClipCount, clipCount);
          } else {
            clipCount = 0;
          }
        }
        // RMS is calculated over each buffer, meaning the integration time will
        // be different depending on sample rate and buffer size. In practise
        // this should be a small problem.
        rms = Math.sqrt(rms / samples.length);
        maxRms = Math.max(maxRms, rms);
      }
    }

    if (maxPeak > this.silentThreshold) {
      var dBPeak = this.dBFS(maxPeak);
      var dBRms = this.dBFS(maxRms);
      this.reportInfo('Channel ' + channelNumber + ' levels: ' +
          dBPeak.toFixed(1) + ' dB (peak), ' + dBRms.toFixed(1) + ' dB (RMS)');
      console.log('Channel ' + channelNumber + ' levels: ' +
          dBPeak.toFixed(1) + ' dB (peak), ' + dBRms.toFixed(1) + ' dB (RMS)')
      if (dBRms < this.lowVolumeThreshold) {
        this.reportError('Microphone input level is low, increase input ' +
            'volume or move closer to the microphone.');
        console.error('Microphone input level is low, increase input ' +
        'volume or move closer to the microphone.')
      }
      if (maxClipCount > this.clipCountThreshold) {
        this.reportWarning('Clipping detected! Microphone input level ' +
            'is high. Decrease input volume or move away from the microphone.');
        console.warn('Clipping detected! Microphone input level ' +
        'is high. Decrease input volume or move away from the microphone.')
      }
      return true;
    }
    return false;
  }

  detectMono(buffersL, buffersR) {
    var diffSamples = 0;
    for (var j = 0; j < buffersL.length; j++) {
      var l = buffersL[j];
      var r = buffersR[j];
      if (l.length === r.length) {
        var d = 0.0;
        for (var i = 0; i < l.length; i++) {
          d = Math.abs(l[i] - r[i]);
          if (d > this.monoDetectThreshold) {
            diffSamples++;
          }
        }
      } else {
        diffSamples++;
      }
    }
    if (diffSamples > 0) {
      console.log('Stereo microphone detected.');
      this.reportInfo('Stereo microphone detected.');
    } else {
      console.log('Mono microphone detected.');
      this.reportInfo('Mono microphone detected.');
    }
  }

  dBFS(gain) {
    var dB = 20 * Math.log(gain) / Math.log(10);
    // Use Math.round to display up to one decimal place.
    return Math.round(dB * 10) / 10;
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  cleanup = () => {
    clearTimeout(this.timeout);
    this.onStopCollectingAudio();
    this.close();
  }

  componentWillUnmount() {
    this.cleanup();
  }
}