import { getMessage } from "CONFIG/i18n";
import React, { Component } from "react";

const withRealWear = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.deregisterVoiceCommand = this.deregisterVoiceCommand.bind(this);
      this.deregisterAllVoiceCommands = this.deregisterAllVoiceCommands.bind(this);
      this.handleVoiceCommand = this.handleVoiceCommand.bind(this);
      // TODO: deregister voice command callback on language change
      this.deregisterCallbacks=[];
    }
    
    handleVoiceCommand = (options) => {
      if (window.journey && window.journey.voice && options?.voiceCommand && options.action) {
        const voiceCommand = getMessage(options.voiceCommand);
        try {
          window.journey.voice.registerVoiceCommand(voiceCommand, () => {
            options.action();
          }).then(deregister => {
            console.log("Successfully registered voice command:", voiceCommand)
            this.deregisterCallbacks.push({
              voiceCommand,
              deregister
            });
            console.log(`deregisterCallbacks stack: ${JSON.stringify(this.deregisterCallbacks)}`)
          });
        } catch (error) {
          console.log("Realwear voice command error: ", error);
        }
      }
    }

    deregisterAllVoiceCommands() {
      for (let i = 0; i < this.deregisterCallbacks?.length; i++) {
        const deregisterFunc = this.deregisterCallbacks[i]?.deregister;
        deregisterFunc?.();
        console.log("deregisterAllVoiceCommands() Deregister voice command:", this.deregisterCallbacks[i]?.voiceCommand);
        this.deregisterCallbacks?.splice(i, 1);
      }
    }

    deregisterVoiceCommand(command){
      const voiceCommand = getMessage(command);
      for (let i = 0; i < this.deregisterCallbacks?.length; i++) {
        if (voiceCommand === this.deregisterCallbacks[i].voiceCommand) {
          const deregisterFunc = this.deregisterCallbacks[i]?.deregister;
          deregisterFunc?.();
          console.log("deregisterVoiceCommand() Deregister voice command:", this.deregisterCallbacks[i]?.voiceCommand);
          this.deregisterCallbacks?.splice(i, 1);
        }
      }
    }

    componentWillUnmount() {
      console.log("Deregister voice command when component unmounts ");
      this.deregisterAllVoiceCommands()
    }

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            handleVoiceCommand={this.handleVoiceCommand}
            deregisterVoiceCommand={this.deregisterVoiceCommand}
            deregisterAllVoiceCommands={this.deregisterAllVoiceCommands}
          />
        </React.Fragment>
      );
    }
  };
};
export default withRealWear;