
import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class QRCodeAgentsAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
 
  getQRCodeAgents(request, qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.QR_CODES + `/${qrId}/agents?`,
        requestBody: request,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getUnassignedAgents(request, qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.QR_CODES + `/${qrId}/unassignedAgents?`,
        requestBody: request,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  addQRCodeAgents(requestBody, qrId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.QR_CODES + `/${qrId}/agents`,
        requestBody: requestBody,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteQRCodeAgents(qrId ,agentIds) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url:  `${API_URL.QR_CODES}/${qrId}/agents`,
        requestBody: {"agentIds": agentIds},
      }).then(
        (response) => {
          resolve(response); 
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  saveQRCodeAgentsPageNo(value) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_PAGE_NO,
      value: value,
    });
  }

  saveQRCodeAgentsPageSize = (value) => {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_PAGE_SIZE,
      value: value,
    });
  };

  saveQRCodeAgentsCount(value) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_COUNT,
      value: value,
    });
  }

  saveUnassignedAgentsPageNo(value) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_PAGE_NO,
      value: value,
    });
  }

  saveUnassignedAgentsPageSize = (value) => {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_PAGE_SIZE,
      value: value,
    });
  };

  saveUnassignedAgentsCount(value) {
    this.dispatch({
      type: ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_COUNT,
      value: value,
    });
  }
}