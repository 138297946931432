import React, { Component } from "react";
import { axiosInstance as axios } from "UTILS/axios.init";
import { getAPIErrorInfo } from "UTILS/errorLookup";
import * as log from "loglevel";

// Custome Components
import Popup from "COMPONENTS/CommonComponents/Modal/Modal";

// Action
import { logoutAction } from "CONFIG/ActionFactory";

import { USER_ROLES } from "UTILS/Constants";
import { getCommonAPIErrorMessage } from "UTILS/Utility";

const withErrorHandler = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.responseInterceptor = "";
      log.setLevel("error");
    }

    state = {
      errorMessage: "",
    };

    componentDidMount() {
      this.responseInterceptor = axios.interceptors.response.use(
        res => {
          this.setState({ errorMessage: "" })
          return res;
        },

        async error => {
          let errorInfo = getAPIErrorInfo(error.code, error.response);
          if (!errorInfo) {
            errorInfo = error;
          }
          this.setState({
            errorMessage: getCommonAPIErrorMessage(errorInfo.code)
          });
          return errorInfo;
        }
      );
    }
    componentWillUnmount() {
      axios.interceptors.response.eject(this.responseInterceptor);
    }

    showModal = () => {
      return (
        <Popup
          isDoubleButton={false}
          cleanUp={this.logout}
          /* here role prop is expected to be there for a component which is used for both
          agent and user and wrapped with hoc withErrorHandler */
          show={!!this.state.errorMessage && this.props.role !== USER_ROLES.USER}
          message={this.state.errorMessage}
        />
      );
    };

    logout = () => {
      this.setState({errorMessage : ""});
      logoutAction.logoutUser();
    }

    render() {
      return (
        <React.Fragment>
          {this.showModal()}
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
};
export default withErrorHandler;
