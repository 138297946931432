import React, { Component } from "react";

import "fabric";
import 'abortcontroller-polyfill';
import _ from "lodash";
import * as rxjs from "rxjs";
import { debounceTime } from "rxjs/operators";
import { Row, Col, CardImg } from "reactstrap";
import { withOrientationChange, isIE, isMobileOnly, isMobile as isMobileDevice} from "react-device-detect";

import { getMessage } from "CONFIG/i18n";

import AnnotationBar from "./AnnotationBar/AnnotationBar";
import PDFTronErrorHandler from "./PDFTronErrorHandler";
import PDFJS from "./PDFJS";

import { ANNOTATIONS, ACTIONS, USER_ROLES, DEFAULT_FONT_SIZE, SNAPSHOT_SCAN_CODE, TIMEOUT } from "UTILS/Constants";
import { socketMessage, mediaType } from "WEBSOCKET/constants";
import { CANVAS_TYPES, COMPONENT_IN_FOCUS } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import Spinner from "../Spinner/Spinner";

import "./whiteboard.less";

import ic_canvas_small from "ASSETS/Images/ic_canvas_small.svg";
import ic_swap from "ASSETS/Images/ic_swap.svg";
import ic_share_big_blue from "ASSETS/Images/ic_share_big_blue.svg";

import {
  isMobile, _wrapLine,
  shouldPanImage, shouldPanObject, panDirection,
  canvasToBlob, getPDFWebviewerInstance,
  isCanvasBlank,
  isDesktopAndTabletIpadPortrait
} from "../../Utils/Utility";
import FloatedAnnotationBar from "./AnnotationBar/FloatedAnnotationBar";
import SnapshotContainer from "./AnnotationBar/SnapshotContainer";
import PdfTron from "./PdfTron";

import { headerAction } from "CONFIG/ActionFactory";
import { downloadableModules } from "../../Utils/Constants";

// Added for transformation
var multiply = fabric.util.multiplyTransformMatrices;
var invert = fabric.util.invertTransform;
//Added this snippet to be able to serialize and deserialize custom property to object for transformation
fabric.Object.prototype.toObject = (function (toObject) {
  return function (properties) {
    return fabric.util.object.extend(toObject.call(this, properties), {
      relationship: this.relationship,
      pCoords: this.pCoords,
      webPageURL: this.webPageURL,
      hoverCursor: this.hoverCursor
    });
  };
})(fabric.Object.prototype.toObject);

fabric.util.object.extend(fabric.Textbox.prototype, {
  _wrapLine: _wrapLine,
});

const CANVAS_HEIGHT = 1150;
const CANVAS_WIDTH = 2050;

class Whiteboard extends Component {
  constructor(props) {
    super(props);
    let { fontSize, fontFamily, fontColor } = props.defaultFontInfo;
    this.state = {
      selectedColor: fontColor,
      selectedLineWidth: "4",
      fillColor: "rgba(0,0,0,0)",
      selectedFontSize: fontSize,
      selectedFontFamily: fontFamily,
      activeObject: [],
      redoObject: [],
      actionObject: [],
      showGroupedAnnotations: false,
      showSnapshotModal: false,
      currentPage: 0,
      limit: 10,
      totalCount: 10,
      canvasHeight:0
    };

    this.undoOrRedoObserver = new rxjs.Subject();
    this.currentObjectOriginX = null;
    this.currentObjectOriginY = null;
    this.isMouseDown = false;
    this.canvas = null;
    this.isSelectedAnnotationShape = false;
    this.selectedObject = null;
    this.zoomFactor = 0;
    this.zoomLevel = 1;
    this.pdfAllObjectPosition = null;
    this.zoom = null;
    this.canvasOrigDimension = null;
    this.intervals = [];
    this.isMobile = screen.width < 767;
    this.prevActiveCanvasId = 0;
    this.thumbnailUploadRequired = true;
    this.tooltipBoundaryElement = null;
  }

  componentWillUnmount() {
    console.log("In whiteboard componentWillUnmount");
    for (let i = 0; i < this.intervals.length; i++) {
      clearTimeout(this.intervals[i]);
    }
    window.removeEventListener("orientationchange", this.onOrientationChange,);
    window.removeEventListener("resize", this.onResize);
  }

  updateCanvasSizeOnType = () => {
    if (this.canvas) {
      this.canvas.setWidth(CANVAS_WIDTH);
      this.canvas.setHeight(CANVAS_HEIGHT);
      this.canvas.setViewportTransform([0.99, 0, 0, 0.99, 0, 0]);
      this.resize(true);
    }
  };

  getOrientation = () => {
    if (this.props.isLandscape) {
      return 90;
    }
    else if (this.props.isPortrait) {
      return 0;
    }
    else {
      return null;
    }
  }

  onOrientationChange = () => {
    let interval = setTimeout(async () => {
      await this.updateCanvasSizeOnType();
      console.log("clearing canvas in componentDidMount");
      if (!(this.props.activeCanvasData.canvasType === CANVAS_TYPES.REGULAR
        && this.isCanvasBlank())
        && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
        this.canvas.clear();
      }
      this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
        this.drawWhiteBoard().then(
          data => {
            window.scrollTo(0, 0);
            var interval6 = setTimeout(() => {
              if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE
                && !_.isEmpty(this.props.activeCanvasData.frameDetails)) {
                this.setImageObjectPositions(this.props.activeCanvasData.frameDetails);
              }
              clearInterval(interval);
            }, 2000);
            this.intervals.push(interval6);
          },
          error => {
            console.log("error : ", error);
            this.props.hideSpinner();
          }
        );
    }, 300);
    this.intervals.push(interval);
  }

  onResize = () => {
    // do not resize canvas for mobile devies 
    // because when keyboard gets opened canvas area reduces 
    if (!isMobileDevice) {
      this.updateCanvasSizeOnType();
    }
  }

  componentDidMount() {
    this.getTooltipBoundaryElement();
    this.setState({
      showSnapshotModal: this.props.showSnapshot
    });

    window.addEventListener(
      "orientationchange",
      this.onOrientationChange,
      false
    );

    window.addEventListener("resize", this.onResize);
    
    if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {      
      this.props.setIsDownloadToCanvas(null);
      this.props.setActiveCanvasRef(null);
    } else {
      this.getNewCanvas();
      this.canvas.freeDrawingBrush.color = this.state.selectedColor;
      this.canvas.clear();

      // draw objects on canvas
      this.drawWhiteBoard().then(
        data => {
          var interval2 = setTimeout(() => {
            if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE
              && !_.isEmpty(this.props.activeCanvasData.frameDetails)) {
              this.setImageObjectPositions(this.props.activeCanvasData.frameDetails);
            }
          }, 1000);
          this.intervals.push(interval2);
          if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.REGULAR) {
            // for pdf and image, thumbnail will be uploaded on canvas:addObject
            if (this.isCanvasBlank()) {
              this.addImage(this.props.logo.url, true)
            } else {
              console.log("uploadThumbnail in componentDidMount");
              this.generateBlobAndUploadThumbnail();
            }
          } else if (
            this.props.activeCanvasData.canvasType === CANVAS_TYPES.IMAGE &&
            !this.props.activeCanvasData.fileUrl
          ) {
            // TODO: remove this block of code because we cannot delete image object from canvas 
            // after we clear image canvas fileurl is set to null only in that case add background image
            if (this.isCanvasBlank()) {
              this.addImage(this.props.logo.url, true)
            }
          }
        },
        error => {
          console.log("error : ", error)
        }
      );
    }

    if ((this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
      || this.props.activeCanvasData.canvasType === CANVAS_TYPES.IMAGE) &&
      this.props.activeCanvasData.fileUrl) {
      console.log("showing spinner in componentDidMount");
      this.props.showSpinner();
    }

    // Multiple clicks on undo or redo button for a second will be treated as just one click 
    this.undoOrRedoObserver.pipe(debounceTime(1000)).subscribe(fn => {
      if (typeof fn === "function") fn();
    });

    // set selected annotation to the one selected by agent on mount 
    if (this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS && this.props.selectedAnnotation) {
      if (this.props.selectedAnnotation === ANNOTATIONS.FREE_HAND.NAME) {
        this.handleChangeAnnotation({
          value: ANNOTATIONS.FREE_HAND.NAME,
          color: this.state.selectedColor,
          width: ANNOTATIONS.FREE_HAND.WIDTH
        })
      } else {
        this.handleChangeAnnotation(this.props.selectedAnnotation)
      }
    }
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("whiteboard")[0];
  }

  addCanvasEventListeners = () => {
    this.canvas.on("mouse:down", event => {
      // if active canvas is SEARCH_RESULTS then only click event should be work
      if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS) {
        if (event.target && event.target.webPageURL) {
          window.open(event.target.webPageURL, '_blank', 'noopener=true'); // WAAG-5835
        } else {
          console.log('not a target');
        }
      } else {
        if (!isIE) {
          if (this.props.selectedAnnotation === ANNOTATIONS.TEXT &&
            (this.props.allowEdit || this.props.uniqueId === this.props.organiser)) {
            this.addText(event);
          } else if (this.props.selectedAnnotation === ANNOTATIONS.SELECTION) {
            this.canvas.forEachObject(object => {
              object.set({ selectable: true });
            });
          }
        }
        // hide floating annotation 
        if (this.state.showGroupedAnnotations && this.props.uniqueId === this.props.organiser) {
          this.setState({
            showGroupedAnnotations: false
          })
        }
        if (this.isSelectedAnnotationShape) {
          this.isMouseDown = true;
          this.addShape(event);
          this.removeBackgroundImage();
        }
        if (this.props.selectedAnnotation && (this.props.allowEdit || this.props.uniqueId === this.props.organiser) &&
          this.props.selectedAnnotation != ANNOTATIONS.SELECTION) {
          this.removeBackgroundImage();
        }
      }
    });

    this.canvas.on("mouse:move", event => {
      //Pinch to Zoom and Panning
      // if (event.e && event.e.touches
      //   &&
      //   //For user if inklock is on, or no annotation is selected
      //   ((this.props.uniqueId !== this.props.organiser && (this.props.selectedAnnotation === null || this.props.selectedAnnotation === ""))
      //     // For Agent if no annotations are selected
      //     || (this.props.uniqueId === this.props.organiser && (this.props.selectedAnnotation === null || this.props.selectedAnnotation === "")))
      // ) {
      //   // Only for PDF canvas
      //   // TODO: remove this code if panning is not required for image canvas 
      //   if ((this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF)
      //     || (this.props.uniqueId === this.props.organiser)
      //   ) {
      //     let touches = [...event.e.touches];
      //     let oldPinchDifference = this.pinchDifference;  // Old pinch difference to calculate the change of distance between fingers  
      //     let oldTouchPoints = this.oldTouchPoints; // Old touch points to calculate the change of distance between fingers  

      //     // Zoom using pinching
      //     if (event.e.touches && event.e.touches.length == 2) {
      //       let canvasRect = this.canvas.getElement().getClientRects() && this.canvas.getElement().getClientRects()[0]

      //       // Change of origin of touch points X and Y according to the position of canvas in the DOM
      //       /* 
      //       * This is made because pageX and pageY are corresponding to the document element and we 
      //       * need points corresponding to the Canvas 
      //       */
      //       touches[0] = { ...touches[0], pageX: touches[0].pageX - canvasRect.left, pageY: touches[0].pageY - canvasRect.top }
      //       touches[1] = { ...touches[1], pageX: touches[1].pageX - canvasRect.left, pageY: touches[1].pageY - canvasRect.top }

      //       let maxZoom = this.zoom * Math.pow(1.15, 20);

      //       // Finding the difference between two coordinates using Euclidean Formula 
      //       // https://stackoverflow.com/questions/20916953/get-distance-between-two-points-in-canvas
      //       let newPinchDifference = parseInt(Math.hypot(touches[0].pageX - touches[1].pageX, touches[0].pageY - touches[1].pageY));
      //       if (oldPinchDifference && oldTouchPoints) {
      //         var zoom = this.canvas.getZoom();

      //         // Adding change in zoom using pinchDifference
      //         zoom += zoom * ((newPinchDifference - oldPinchDifference) / oldPinchDifference)
      //         // Zoom bounds 
      //         if (zoom > this.zoom && zoom < maxZoom) {
      //           let zoomPoint = new fabric.Point((touches[0].pageX + touches[1].pageX) / 2, (touches[0].pageY + touches[1].pageY) / 2)
      //           this.zoomFactor = zoom;
      //           // this.zoomPoint = zoomPoint;
      //           this.canvas.zoomToPoint(zoomPoint, zoom);

      //           // Pan with two fingers
      //           this.panDifference = this.applyPan(oldTouchPoints[0], touches[0], event)
      //         }
      //       }
      //       this.pinchDifference = newPinchDifference;
      //     }
      //     // Panning using single touch
      //     else if (event.e.touches && event.e.touches.length == 1 && oldTouchPoints) {
      //       this.panDifference = this.applyPan(oldTouchPoints[0], touches[0], event)
      //     }
      //     this.oldTouchPoints = touches;
      //   }
      //   return;
      // }
      if (
        this.isSelectedAnnotationShape &&
        (this.props.allowEdit || this.props.uniqueId === this.props.organiser)) {
        if (!this.isMouseDown) return;
        this.currentObject && this.updateShape(event);
        this.canvas.renderAll();
      }
    });

    this.canvas.on("mouse:up", event => {
      if (this.isSelectedAnnotationShape && this.state.selectedAnnotation !== ANNOTATIONS.SPOTLIGHT.NAME) {
        this.props.setIsCanvasModified(true, "mouse:up");
      }

      // issue: on IE text box does not get added on single click we had to double click to add a text box
      // for IE addText needs to get triggered on mouse up and for other browsers on mouse down
      if(isIE) {
        if (this.props.selectedAnnotation === ANNOTATIONS.TEXT &&
          (this.props.allowEdit || this.props.uniqueId === this.props.organiser)) {
          this.addText(event);
        } else if (this.props.selectedAnnotation === ANNOTATIONS.SELECTION) {
          this.canvas.forEachObject(object => {
            object.set({ selectable: true });
          });
        }
      }
      // Clearing invalid objects 
      this.canvas?.getObjects()?.filter(object => isNaN(object.top)).map(object => {
        this.canvas.remove(object)
      })
      this.pinchDifference = "";
      this.oldTouchPoints = "";
      this.panDifference = "";
      if (this.isSelectedAnnotationShape && this.currentObject) {
        this.isMouseDown = false;
        var center = this.currentObject.getCenterPoint();
        this.currentObject.set("originX", "center");
        this.currentObject.set("originY", "center");
        this.currentObject.set("left", center.x);
        this.currentObject.set("top", center.y);
        this.currentObject.setCoords();
        this.calcShapeTransformationsWrtImg(this.currentObject, "canvas:mouse up");
        if (this.currentObject.objectType !== ANNOTATIONS.SPOTLIGHT.NAME) {
          this.sendAddObjectMessage(
            this.currentObject,
            socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
          );
        }
      }
    });

    // object:selection event is triggered for objects only if one object is selected and then we click on empty canvas and 
    // then other object is selected. If we switch from one object to other object, this event doesn't get triggered
    // To overcome this we are replacing it with two events  
    this.canvas.on("selection:created", this.onCanvasObjectSelection)
    this.canvas.on("selection:updated", this.onCanvasObjectSelection);

    // event triggered when free hand is drawn
    this.canvas.on("path:created", async event => {
      if (this.state.selectedAnnotation !== ANNOTATIONS.PDF_HIGHLIGHT.NAME) {
        this.props.setIsCanvasModified(true, "path:created");
      }

      // issue: double objects of last drawn free hand get added on canvas
      // disable drawing mode
      await this.canvas.set({
        isDrawingMode: false
      });

      this.currentObject = event.path;
      this.currentObject["objectId"] =
        new Date().getTime() + this.props.participantId;
      this.currentObject["objectType"] = this.props.selectedAnnotation;

      if (this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME){
        this.currentObject["lockScalingX"] =  true
        this.currentObject["lockScalingY"] =  true
        this.currentObject["lockRotation"] =  true
        this.currentObject["lockUniScaling"] =  true
        this.currentObject["lockScalingFlip"] =  true
        this.currentObject["lockMovementX"] = true
        this.currentObject["lockMovementY"] = true
      }

      if (this.currentObject["objectType"] === ANNOTATIONS.ERASER.NAME) {
        this.currentObject["evented"] = false
      }

      // if(this.currentObject["objectType"] === ANNOTATIONS.FREE_HAND.NAME)
      //   this.currentObject["strokeWidth"] = 2
      // else if(this.currentObject["objectType"] === ANNOTATIONS.ERASER.NAME)
      //   this.currentObject["strokeWidth"] = ANNOTATIONS.ERASER.WIDTH

      this.currentObject.set({ selectable: false });

      this.calcShapeTransformationsWrtImg(this.currentObject, 'freehand');
      this.sendAddObjectMessage(
        this.currentObject,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );

      // issue: double objects of last drawn free hand get added on canvas
      // enable drawing mode
      this.handleChangeAnnotation({
        value: this.props.selectedAnnotation,
        color:
          this.props.selectedAnnotation === ANNOTATIONS.PDF_HIGHLIGHT.NAME
            ? ANNOTATIONS.PDF_HIGHLIGHT.COLOR
            : this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME
              ? ANNOTATIONS.ERASER.COLOR
              : this.state.selectedColor,
        width:
          this.props.selectedAnnotation === ANNOTATIONS.PDF_HIGHLIGHT.NAME
            ? ANNOTATIONS.PDF_HIGHLIGHT.WIDTH
            : this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME
              ? ANNOTATIONS.ERASER.WIDTH
              : ANNOTATIONS.FREE_HAND.WIDTH
      });
    });

    // added event to hide the spinner when image is completely rendered as bigger images take time to load
    this.canvas.on('object:added', event => {
      // remove background image if any object is drawn before image is rendered 
      // or image is redered before ongoing object draw is complete
      if(this.props.activeCanvasData.canvasType == CANVAS_TYPES.REGULAR 
        && this.canvas.getObjects().length > 1
        && (event.target.objectType == "backgroundImage"
          || this.canvas.getObjects().filter(obj => obj.objectType == "backgroundImage").length > 0)
      ) {
        this.removeBackgroundImage();
      }
    });

    this.canvas.on("object:moving", async event => {
      this.props.setIsCanvasModified(true, "object:moving");
      // event.target && this.props.activeCanvasData.canvasType != CANVAS_TYPES.IMAGE && event.target.bringToFront();
      await this.restrictMovingOutsideCanvas(event)
      // send update object message
      if (event.target.objectType === "image") {
        return;
      }
      // var interval1 = setTimeout(() => {
      this.sendModifyObjectMessage(
        event.target,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
      // }, 200);
      // this.intervals.push(interval1);
    });

    this.canvas.on("object:scaling", event => {
      if (event.target.objectType === "image") return;
      this.props.setIsCanvasModified(true, "object:scaling");
      event.target && event.target.bringToFront();
      //if(event.target.objectType == ANNOTATIONS.TEXT) {
      //  if(event.transform.action == "scaleX") {
      //    debugger;
      //    event.target.charSpacing = event.target.charSpacing + event.transform.scaleX * 100;
      //  }
      //}
      this.calcShapeTransformationsWrtImg(event.target, 'object:scaling');
      //send update object message
      this.sendModifyObjectMessage(
        event.target,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
    });

    this.canvas.on("object:rotating", event => {
      if (event.target.objectType === "image") return;
      this.props.setIsCanvasModified(true, "object:rotating");
      event.target && event.target.bringToFront();
      this.calcShapeTransformationsWrtImg(event.target, 'object:rotating');
      //send update object message
      this.sendModifyObjectMessage(
        event.target,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
    });

    this.canvas.on("object:modified", event => {
      // update object adds the final object in reducer 
      // modify object is just for  other users to see the object movement effect 
      // this object should not get added on server 
      this.props.setIsCanvasModified(true, "object:modified");
      // send modify object message
      if (event.target.objectType !== "image") {
        this.calcShapeTransformationsWrtImg(event.target, 'object:modified');
        this.sendUpdateObjectMessage(event.target,
          socketMessage.subCategories.WHITEBOARD.subActions.REGULAR);
      }

      // if (event.target.objectType === "image") {
      //   this.sendUpdateObjectMessage(
      //     event.target,
      //     socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      //   );
      //   this.sendModifyObjectMessage(event.target);
      // }
      // Update previous state of object
      this.updateSelectedObject(event.target);

      if (this.props.selectedAnnotation !== ANNOTATIONS.TEXT) {
        this.changeSelectable(false);
        this.changeSelectable(true);
      }
    });
  }

  // Function to restrict the object from getting outside canvas completely(Actual position of object, aCoords)
  restrictMovingOutsideCanvas(event) {
    let objectInViewPort = event.target

    let newTouch = { pageX: event.pointer.x, pageY: event.pointer.y }
    let oldTouch = { pageX: event.transform.lastX, pageY: event.transform.lastY }

    let panDirections = panDirection(oldTouch, newTouch)
    let shouldPan = objectInViewPort.objectType == "image"
      ? shouldPanImage
      : shouldPanObject
    let allowedDirections = shouldPan(this.canvas, panDirections, objectInViewPort)
    if (objectInViewPort && allowedDirections && this.lastObjectInViewPort) {
      objectInViewPort.top = allowedDirections.includes("TOP") || allowedDirections.includes("BOTTOM") ? objectInViewPort.top : this.lastObjectInViewPort.top
      objectInViewPort.left = allowedDirections.includes("LEFT") || allowedDirections.includes("RIGHT") ? objectInViewPort.left : this.lastObjectInViewPort.left
    }
    this.lastObjectInViewPort = { top: objectInViewPort.top, left: objectInViewPort.left }
  }

  // FIXME: remove pan method if this is not required for image canvas
  // Function to restrict the object from getting outside canvas completely(Actual position of object, oCoords)
  applyPan(oldTouchPoints, newTouchPoints) {
    let pdfObject = this.canvas.getObjects("image") && this.canvas.getObjects("image")[0];
    let objectInViewPort = event.target

    let panDifference = {
      xDirection: newTouchPoints && oldTouchPoints ? newTouchPoints.pageX - oldTouchPoints.pageX : 0,
      yDirection: newTouchPoints && oldTouchPoints ? newTouchPoints.pageY - oldTouchPoints.pageY : 0
    }
    // let shouldPan = objectInViewPort.objectType == "image"
    //   ? shouldPanImage
    //   : shouldPanObject
    let panDirections = panDirection(oldTouchPoints, newTouchPoints)
    let allowedDirections = shouldPanImage(this.canvas, panDirections, pdfObject)
    if (pdfObject && allowedDirections !== []) {
      panDifference.xDirection = allowedDirections.includes("LEFT") || allowedDirections.includes("RIGHT") ? panDifference.xDirection : 0
      panDifference.yDirection = allowedDirections.includes("TOP") || allowedDirections.includes("BOTTOM") ? panDifference.yDirection : 0
      this.canvas.relativePan(new fabric.Point(panDifference.xDirection, panDifference.yDirection))
    }
    return panDifference
  }

  onCanvasObjectSelection = (event) => {
    if (this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT && this.props.allowEdit) {
      if (event.target.objectType !== ANNOTATIONS.ERASER.NAME
        && event.target.objectType !== "image") {
        this.canvas.remove(event.target);
        this.props.sendDeleteObjectMessage(
          event.target.objectId,
          socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
        );
      } else {
        // do not select image or eraser object if selected annotation is delete object
        this.canvas.discardActiveObject();
      }
      return;
    }
    this.updateSelectedObject(event.target);
    if (event.target.objectType === ANNOTATIONS.TEXT && this.props.allowEdit) {
      event.target.on("editing:entered", () => {
        event.target.on("changed", this.textOnChange)
      })
      event.target.on("editing:exited", () => {
        let textLines = event.target.textLines;
        let actualText = textLines.join('\n')
        event.target.set('text', actualText)

        //Erasing cached line width used for textWidth
        this.lineWidths = []
        this.isEditing = false;
        if (event.target.text.length < 1) {
          this.props.sendDeleteObjectMessage(
            event.target.objectId,
            socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
          );
          this.canvas.remove(event.target);
          return;
        }
        // send update object message
        this.sendUpdateObjectMessage(
          event.target,
          socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
        );
        this.canvas.renderAll();
      });
    }
    //Added onchange event on selection using the selector annotation
  }

  renderImageOnCanvas = file => {
    this.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE);
    console.log("showing spinner in renderImageOnCanvas")
    // show spinner when user selects an image to add on canvas which will be hidden in addImage after image is rendered
    this.props.showSpinner();
    console.log("uploading image in renderImageOnCanvas");
    this.props.uploadFile(file).then(fileUrl => {
      if (fileUrl) {
        // this.props.removeSpotLight();
        // this.props.removePdfHighLight();
        this.props.addCanvas(CANVAS_TYPES.IMAGE, () => {
          this.thumbnailUploadRequired = true;
          var interval3 = setTimeout(() => {
            this.addImage(fileUrl, false, this.props.activeCanvasData.canvasId);
          }, 500);
          this.intervals.push(interval3);
        });
      } else {
        this.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE, false);
      }
    }).catch(error => {
      this.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE, false);
      console.error("in renderImageOnCanvas: ", error);
      this.props.hideSpinner();
      this.props.hideSpinnerForCanvasList();
    });
  };

  getSortedCanvasData = () => {
    const { activeCanvasData } = this.props;
    if (activeCanvasData) {
      let objectArray;
      objectArray = _.values(activeCanvasData.canvasData);
      // sort canvas data on basis of updated time
      objectArray = _.orderBy(objectArray, ["updatedTime"], ["asc"]);

      return objectArray;
    }
  };

  drawWhiteBoard = () => {
    //console.log("in draw whiteboard");
    return new Promise((resolve, reject) => {
      if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
        let objectArray = this.getSortedCanvasData();
        let isImagePresentOnCanvas = false;
        if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.IMAGE) {
          let imageObject = objectArray.filter(object => object.objectType === "image")[0];
          if (imageObject) {
            isImagePresentOnCanvas = true;
            let canvasImageObject = this.canvas.getObjects().filter(obj => obj.objectType == 'image')[0];
            // in case of image canvas, show spinner if image is to be added which will be hidden in addObjectToCanvas after image is completely rendered
            //console.log("showing spinner in draw whiteboard image");
            if (!canvasImageObject) {
              this.props.showSpinner();
              this.addObjectToCanvas(
                imageObject.canvasObject,
                imageObject.objectId,
                imageObject.objectType,
                "",
                this.props.activeCanvasData.canvasId
              );
            }
          } else if (!imageObject && this.props.activeCanvasData.fileUrl) {
            this.props.showSpinner();
            let interval = setTimeout(() =>
              this.addImage(this.props.activeCanvasData.fileUrl, false, this.props.activeCanvasData.canvasId),
            1000
            );
            this.intervals.push(interval);
          }
        }

        let objectsDrawnOtherThanImage = objectArray.filter(object => object.objectType !== "image");
        if (objectsDrawnOtherThanImage.length > 0) {
          // in case of only normal canvas with shapes, show spinner which will be hidden after all objects are drwan in this same function
          console.log("showing spinner in draw whiteboard image + objects");
          // this.props.showSpinner();
          objectArray.forEach((object, index) => {

            object.canvasObject.strokeUniform = true;

            this.addObjectToCanvas(
              object.canvasObject,
              object.objectId,
              object.objectType,
              "",
              this.props.activeCanvasData.canvasId
            );
          });
          if (!isImagePresentOnCanvas && this.props.activeCanvasData.canvasType != CANVAS_TYPES.IMAGE) {
            // hide spinner only when canvas type is image; 
            // for image canvas, spinner will be hidden in addObjectToCanvas after image is completely rendered
            console.log("hidding both spinners in drawWhiteBoard for normal canvas when all objects are drawn");
            this.props.hideSpinner();
            this.props.hideSpinnerForCanvasList();
          }
        }
        resolve("success");
      } else {
        resolve("draw whiteboard not required since it is pdf canvas");
      }
    });
  };

  getFontDetails = (fontSize, isLocally = false) => {
    let updatedFontDetails = {};
    updatedFontDetails.fontColor = this.state.selectedColor;
    updatedFontDetails.fontFamily = this.state.selectedFontFamily;
    updatedFontDetails.isLocally = isLocally;
    if (!fontSize) {
      updatedFontDetails.fontSize = this.state.selectedFontSize;
    } else {
      updatedFontDetails.fontSize = fontSize;
    }
    return updatedFontDetails;
  };

  setDefaultFontInfo = (fontSize, isLocally) => {
    if (!isLocally) {
      this.props.setDefaultFontInfoLocally(this.getFontDetails(fontSize));
      this.props.sendSetDefaultFontInfoMessage(this.getFontDetails(fontSize));
    } else {
      this.props.setDefaultFontInfoLocally(this.getFontDetails(fontSize, isLocally));
    }
  };

  getNewCanvas = () => {
    if (this.canvas || this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) return;
    this.canvas = new fabric.Canvas("loookitCanvas", {
      selection: false,
      defaultCursor: "default",
      hoverCursor: "default",
      backgroundColor: "#FFFFFF",
      allowTouchScrolling: !isIE
    });
    this.props.setActiveCanvasRef(this.canvas);
    this.updateCanvasSizeOnType();
    this.handleChangeAnnotation(this.props.selectedAnnotation);
    this.addCanvasEventListeners();
  }

  componentDidUpdate(prevProps) {
    // if active canvas type is SEARCH_RESULTS then remove selected annotation,
    // So user can not do any changes on SEARCH_RESULTS canvas
    // if active canvas is not SEARCH_RESULTS then restore last annotation
    if ((this.props.activeCanvasData.canvasId !== prevProps.activeCanvasData.canvasId || this.props.isSnapshotRunning !== prevProps.isSnapshotRunning)
      && this.props.isSnapshotRunning === false) {
      this.props.setSnapshotCurrentCanvas(this.props.activeCanvasData)
    }
    if(this.props.activeCanvasData.canvasId !== prevProps.activeCanvasData.canvasId){
      let removeAnnotationDetails={}
      removeAnnotationDetails.isSelected=false
      removeAnnotationDetails.annotation=""
      if(this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS){
        this.props.setOrRevokeActiveAnnotationDetails(removeAnnotationDetails);
        this.props.setSelectedAnnotation(null)
        this.handleChangeAnnotation(null)
      } else if(this.props.prevSelectedAnnotationByAgent){
        this.props.setActiveAnnotationDetails(this.props.prevSelectedAnnotationByAgent)
        this.props.setSelectedAnnotation(this.props.prevSelectedAnnotationByAgent.annotation)
        this.handleChangeAnnotation(this.props.prevSelectedAnnotationByAgent.annotation)
      }
    }
    // if snapshot scan code is successful then add new canvas of type SEARCH_RESULTS and display toast message
    // TO-DO: Need to find solution for snapshotCurrentCanvas
    //     We are maintained active canvas in WhiteboardReducer also, So need to maintain it again
    //     But we are not maintained '-1' in WhiteboadReducer
    if (this.props.snapshotCurrentCanvas?.canvasId !== prevProps.snapshotCurrentCanvas.canvasId
      && prevProps.snapshotCurrentCanvas.canvasId == -1
      && this.props.snapshotCurrentCanvas?.canvasId
      && this.props.snapshotScanCodeResult) {
      if (this.props.uniqueId === this.props.organiser) {
        setTimeout(() => {
          this.props.addCanvas(CANVAS_TYPES.SEARCH_RESULTS);
        }, 500);
      }
      this.props.showSpinner()
    }
    // if snapshot scan code is successful and new SEARCH_RESULTS canvas added
    // then render search result on canvas and remove search results for reducer
    if (this.props.snapshotScanCodeResult
      && this.props.activeCanvasData.canvasId !== prevProps.activeCanvasData.canvasId
      && this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS) {
      const snapshotScanCodeResult = this.props.snapshotScanCodeResult;
      if (this.props.uniqueId === this.props.organiser) {
        setTimeout(() => {
          this.addScanCodeSearchResultQuery(`${getMessage("SNAPSHOT_SCAN_CODE_SEARCH_RESULTS_FOR")}: ` + snapshotScanCodeResult?.query)
          for (let i = 0; i < snapshotScanCodeResult?.results?.length; i++) {
            let element = snapshotScanCodeResult?.results[i]
            this.addScanCodeSearchResultTitle(element, i + 1)
            this.addScanCodeSearchResultDesc(element, i + 1)
            if (i === snapshotScanCodeResult?.results?.length - 1) {
              if (snapshotScanCodeResult?.url) {
                this.addScanCodeSearchResultImage(snapshotScanCodeResult?.url)
              } else {
                this.generateBlobAndUploadThumbnail()
              }
            }
          }
        }, 1000);
      }
      this.props.hideSpinner()
      this.props.setSnapshotSearchResult(null)
    }
    // if there error in the scan code then toast it
    if(this.props.snapshotScanCodeError !== prevProps.snapshotScanCodeError && this.props.snapshotScanCodeError){
      toastr.error(this.props.snapshotScanCodeError, null, { toastId: this.props.snapshotScanCodeError + parseInt(Math.random() * 100) });
      this.props.setSnapshotScanCodeError(null);
      this.props.hideSpinner();
    }
    
    //Presenter taken snapshot
    if(this.props.isSnapshotTaken !== prevProps.isSnapshotTaken) {
      if (this.props.isSnapshotTaken === false) {
        if (this.props.admittedParticipants.length <= 2) {
          headerAction.setShowSnapshot(false);
        }
        headerAction.setIsSnapshotTaken(null);
      } else if (this.props.isSnapshotTaken === true) {
        this.props.showSpinner();
        headerAction.setIsSnapshotTaken(null);
      }
    }
    /*
    * on component swap check if focused component is canvas 
    * and check allow edit permission and user is not organizer
    * if user don't have allow edit permission remove the annotation access
    */
    if(prevProps.componentInFocus !==this.props.componentInFocus && 
      !this.props.allowEdit &&
      !this.props.organiser !== this.props.uniqueId &&
      this.props.selectedAnnotation &&
      this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS) {
      this.handleChangeAnnotation(null)
    }

    // Resize canvas on swapping with Audio/Video Layout
    // Or
    // Resize canvas on end call
    if(this.props.componentInFocus !== prevProps.componentInFocus
      && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF){
      this.updateCanvasSizeOnType()
    }
    if (prevProps.activeCanvasData.canvasType !== this.props.activeCanvasData.canvasType) {
      if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
        this.canvas = null;
        this.props.setActiveCanvasRef(null);
      } else if (!this.canvas) {
        this.getNewCanvas();
      }
    }

    //For snapshot to be enabled via props
    if (this.props.showSnapshot !== prevProps.showSnapshot && this.props.showSnapshot) {
      this.setState({ showSnapshotModal: true });
    }

    if (this.props.showSnapshot !== prevProps.showSnapshot && this.props.showSnapshot === false) {
      this.setState({ showSnapshotModal: false }); // stop spinner and close snapshot modal
    }

    if (this.props.switchedCanvasType != prevProps.switchedCanvasType && this.props.switchedCanvasType) {
      this.onCanvasSwitched(this.props.switchedCanvasType);
      this.props.setSwitchedCanvasType(null);
    }

    // change font size to default when we change canvas 
    // logo.url change check is needed to draw org logo on canvas as this data is updated a little late due to involvement of API call 
    if (this.props.canvasId !== prevProps.canvasId || prevProps.logo.url !== this.props.logo.url) {
      // TODO: remove this method if not required 
      // initially canvas aspect ratio used to change depending on the canvas type 
      // when we have regular canvas aspect ratio is 16:9 but 
      // for pdf canvas in portrait mode aspect ratio would be decided on the basis of device dimensions 
      // now with pdftron in place we would not need to update canvas size 
      this.updateCanvasSizeOnType();
      
      // show spinner so that user don't interact with canvas till organization logo is rendered on blank canvas
      this.props.showLogoOnCanvasStatus && this.isCanvasBlank() && this.props.showSpinner();

      this.prevActiveCanvasId = prevProps.canvasId;
      // if a new canvas has been added for pdf or image then show the spinner which will be hidden in addObjectToCanvas after image has been rendered
      if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.IMAGE) {
        // no need to show spinner for location download as its already handled in LocationContainer
        if (this.props.activeCanvasData.fileUrl) {
          // if(!(this.props.activeCanvasData.canvasSource == CANVAS_SOURCE.LOCATION
          //   && (this.props.uniqueId === this.props.organiser
          //     || this.props.uniqueId === this.props.presenterId))) {
          //   this.showSpinner();
          // }
          console.log('Show spinner when canvas id changes');
          this.props.showSpinner();
          let canvasThumbnailImageDiv = document.getElementById(this.props.canvasId);
          let canvasThumbnailImage = null;
          if (canvasThumbnailImageDiv && canvasThumbnailImageDiv.getElementsByTagName('img').length > 1) {
            canvasThumbnailImage = canvasThumbnailImageDiv.getElementsByTagName('img')[1].src;
          }
          // do not show canvas list spinner if thumbnail is already uploaded
          if (!this.props.activeCanvasData.thumbnailUrl || 
            (this.props.activeCanvasData.thumbnailUrl) !== canvasThumbnailImage) {
            this.thumbnailUploadRequired = true;
            console.log("Showing canvas list spinner in componentDidUpdate as thubmnail is changed");
            this.props.showSpinnerForCanvasList();
          } else {
            console.log("Hiding canvas list spinner in componentDidUpdate");
            this.props.hideSpinnerForCanvasList();
          }
        }
      }
      // this.setDefaultFontInfo(DEFAULT_FONT_SIZE, false);
      // console.log('this.props.defaultFontInfo: ', this.props.defaultFontInfo);
      // if(this.props.organiser === this.props.uniqueId)
      //   this.props.sendSetDefaultFontInfoMessage({
      //     fontSize: this.props.defaultFontInfo.fontSize,
      //     fontFamily: this.props.defaultFontInfo.fontFamily,
      //     fontColor: this.props.defaultFontInfo.fontColor
      //   })
      // this.handleChangeFontSize(this.props.defaultFontInfo.fontSize)
      // Add default image on blank canvas 
      // TODO: check if all these conditions are required because now we cannot remove image from a canvas
      if(((this.props.activeCanvasData.canvasId !== prevProps.activeCanvasData.canvasId &&
        this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
        this.props.activeCanvasData.canvasType !== CANVAS_TYPES.IMAGE)
        ||
        (this.props.activeCanvasData.fileUrl !== prevProps.activeCanvasData.fileUrl &&
          !this.props.activeCanvasData.fileUrl)
        ||
        (!_.isEqual(this.props.activeCanvasData, prevProps.activeCanvasData) &&
          ((this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE &&
            this.props.activeCanvasData.fileUrl == null)
            ||
            (this.props.activeCanvasData.canvasType == CANVAS_TYPES.REGULAR &&
              !this.props.activeCanvasData.canvasData || this.props.activeCanvasData.canvasData == {}
            )))
        || (prevProps.logo.url !== this.props.logo.url))
      ) {
        //this.hideSpinnerForCanvasList();
        this.removeBackgroundImage();
        if (this.isCanvasBlank() && this.props.activeCanvasData.canvasType && this.props.logo.url) {
          this.addImage(this.props.logo.url, true)
        }
      } else {
        if(this.props.activeCanvasData.fileUrl === null) {
          console.log('hide spinner on canvas change');
          this.props.hideSpinner();
        }
      }
    }

    // TODO: remove this code after frame lock implementation
    // Set appropriate size of the frame
    if ((!_.isEqual(this.props.frameSize, prevProps.frameSize)
      || (this.props.activeCanvasData.isFrameLocked !== prevProps.activeCanvasData.isFrameLocked
        && this.props.activeCanvasData.isFrameLocked))
      && this.props.organiser === this.props.uniqueId) {
      if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {

        let loookitFrame = document.getElementById("loookitFrame")

        if (loookitFrame && this.props.activeCanvasData.resolution && this.props.activeCanvasData.resolution.width < this.props.activeCanvasData.resolution.height) {
          // for portrait pdf
          if (this.props.frameSize.orientation === 0) {
            // when user is in portrait mode
            // set height same as height of canvas as pdf height will be of height of canvas
            loookitFrame.style.height = this.canvas.getHeight() + "px";
            // set width depending on the canvas ratio of the user
            loookitFrame.style.width = this.canvas.getHeight() * this.props.frameSize.canvasRatio + "px"

            this.loookitFrameLeft = (this.canvas.getWidth() - (this.canvas.getHeight() * this.props.frameSize.canvasRatio)) / 2
            this.loookitFrameTop = 0;

            this.updatePdfCanvasObjectPositions();

          } else {
            // when user is in landscape mode 
            // height and width will be same as canvas height width as both have same view
            loookitFrame.style.height = this.canvas.getHeight() + "px";
            loookitFrame.style.width = this.canvas.getWidth() + "px"
            this.loookitFrameLeft = 0
            this.loookitFrameTop = 0
            this.updatePdfCanvasObjectPositions();
          }
        } else if (loookitFrame && this.props.activeCanvasData.resolution && this.props.activeCanvasData.resolution.width > this.props.activeCanvasData.resolution.height) {
          // for landscape pdf 
          // height and width will be same as canvas height width as both have same view
          loookitFrame.style.height = this.canvas.getHeight() + "px";
          loookitFrame.style.width = this.canvas.getWidth() + "px"
          this.loookitFrameLeft = 0
          this.loookitFrameTop = 0
          this.updatePdfCanvasObjectPositions();
        }
      }
    }

    // TODO: remove this code if fit screen is not going tpo be broadcasted
    if (this.props.isFitScreen !== prevProps.isFitScreen && this.props.isFitScreen && this.props.userRole !== USER_ROLES.AGENT) {
      this.onFitScreenClick();
      this.props.setFitScreenFlag(false);
    }

    const { activeCanvasData } = this.props;
    const currentDefaultFontInfo = _.cloneDeep(this.props.defaultFontInfo);
    // for clear canvas
    if (
      !(_.isEqual(this.props.activeCanvasData.canvasData,
        prevProps.activeCanvasData.canvasData)) &&
      _.isEmpty(this.props.activeCanvasData.canvasData)
      &&
      this.props.activeCanvasData.canvasType !== CANVAS_TYPES.IMAGE
    ) {
      if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
        && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.IMAGE
      ) {
        console.log("clearing canvas in componentDidUpdate 1");
        this.canvas.clear();
        // if(this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE &&
        //   this.props.activeCanvasData.fileUrl) {
        //   return;
        // }
        // need to upload only if current canvas is cleared
        if (this.props.activeCanvasData.canvasType != CANVAS_TYPES.IMAGE &&
          !this.props.activeCanvasData.fileUrl &&
          this.props.activeCanvasData.canvasId == prevProps.activeCanvasData.canvasId &&
          this.props.organiser === this.props.uniqueId) {
          console.log("################### uploadThumbnail: in componentDidUpdate ");
          this.generateBlobAndUploadThumbnail(() => {
            this.props.activeCanvasData.canvasType && this.props.logo.url &&
              this.addImage(this.props.logo.url, true)
          });
        } else if (this.props.activeCanvasData.canvasType &&
          this.props.activeCanvasData.canvasType != CANVAS_TYPES.IMAGE &&
          !this.props.activeCanvasData.fileUrl && this.props.logo.url) {
          this.addImage(this.props.logo.url, true)
        }
      }
      this.props.setClearCanvasFlag(false)
    } else if (
      !_.isEmpty(this.props.activeCanvasData) &&
      ((this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
        this.props.activeCanvasData.canvasData[
        this.props.activeCanvasData.activePage
        ] !==
        (prevProps.activeCanvasData.canvasData &&
          prevProps.activeCanvasData.canvasData[
          this.props.activeCanvasData.activePage
          ]) &&
        _.isEmpty(
          this.props.activeCanvasData.canvasData[
          this.props.activeCanvasData.activePage
          ]
        ) && this.props.clearCanvasFlag) ||
        (this.props.activeCanvasData.canvasType === CANVAS_TYPES.IMAGE
          && !(_.isEqual(this.props.activeCanvasData.canvasData,
            prevProps.activeCanvasData.canvasData))
          && _.isEmpty(this.props.activeCanvasData.canvasData)
          && this.props.clearCanvasFlag))
    ) {
      var objects = this.canvas.getObjects();
      var objectsLength = this.canvas.getObjects().length;
      console.log('remove all objects');
      objects.forEach((object, index) => {
        if (object.objectType !== "image") {
          this.canvas.remove(object);
        }
        if (objectsLength == index + 1) {
          let timeout = setTimeout(() => {
            console.log("uploading Thumbnail");
            this.generateBlobAndUploadThumbnail()
          }, 1000)
          this.intervals.push(timeout);
        }
      });
      this.props.setClearCanvasFlag(false)
    }

    if (currentDefaultFontInfo) {
      if (
        !_.isEqual(prevProps.defaultFontInfo, currentDefaultFontInfo) &&
        this.checkIfFontInfoNotSame()
      ) {
        this.setState({
          selectedColor: currentDefaultFontInfo.fontColor,
          selectedFontSize: currentDefaultFontInfo.fontSize,
          selectedFontFamily: currentDefaultFontInfo.fontFamily
        });
      }
    }
    if (
      this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
      (prevProps.canvasId !== this.props.canvasId ||
        prevProps.activeCanvasData.fileUrl !== this.props.activeCanvasData.fileUrl)
    ) {
      console.log("clearing canvas in componentDidUpdate 2");
      this.canvas.clear();
      //console.log("showing spinner in componentDidUpdate");
      this.drawWhiteBoard().then(
        data => {
          var interval6 = setTimeout(() => {
            if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE
              && !_.isEmpty(this.props.activeCanvasData.frameDetails)) {
              this.setImageObjectPositions(this.props.activeCanvasData.frameDetails);
            }
          }, 2000);
          this.intervals.push(interval6);
        },
        error => {
          console.log("error : ", error);
        }
      );
    } else if (
      !_.isEqual(
        prevProps.activeCanvasData.canvasData,
        this.props.activeCanvasData.canvasData
      )
    ) {
      function isPDFCanvasProperty() {
        if (!_.isEmpty(activeCanvasData.canvasData)) {
          return (
            activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
            activeCanvasData.canvasData[activeCanvasData.activePage] &&
            activeCanvasData.canvasData[activeCanvasData.activePage].hasOwnProperty(activeCanvasData.lastUpdatedObjectId)
          );
        }
        return false;
      }
      if (activeCanvasData.ownerOfLastAction !== this.props.participantId) {
        const canvasObject = this.getCanvasObjectById(
          activeCanvasData.lastUpdatedObjectId
        );
        // object is added in reducer so we need to add it on canvas 
        if (
          isPDFCanvasProperty() ||
          activeCanvasData.canvasData.hasOwnProperty(
            activeCanvasData.lastUpdatedObjectId
          )
        ) {
          const updatedObject =
            activeCanvasData.canvasType === CANVAS_TYPES.PDF
              ? activeCanvasData.canvasData[activeCanvasData.activePage][
              activeCanvasData.lastUpdatedObjectId
              ]
              : activeCanvasData.canvasData[
              activeCanvasData.lastUpdatedObjectId
              ];
          this.addObjectToCanvas(
            updatedObject.canvasObject,
            updatedObject.objectId,
            updatedObject.objectType,
            activeCanvasData.lastUpdatedObjectId,
            activeCanvasData.canvasId
          );
        } else if ( // object has been deleted from reducer so we need to delete from canvas 
          (canvasObject ||
            this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) &&
          !activeCanvasData.canvasData.hasOwnProperty(
            activeCanvasData.lastUpdatedObjectId
          )
        ) {
          if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
            let pdfWebviewerInstance = getPDFWebviewerInstance();

            pdfWebviewerInstance && activeCanvasData.lastUpdatedObjectId &&
              pdfWebviewerInstance.annotManager.trigger('deleteAnnotation', JSON.stringify(activeCanvasData.lastUpdatedObjectId));
          } else {
            this.canvas.remove(canvasObject);
          }
        }
      } else {
        // object is updated by self 
        if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
          const canvasObject = this.getCanvasObjectById(
            activeCanvasData.lastUpdatedObjectId
          );
          // set selectable true for image if it is false 
          if (canvasObject && canvasObject.type == "image" && canvasObject.selectable == false
            && (this.props.selectedAnnotation === ANNOTATIONS.SELECTION || this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT)) {
            this.changeSelectable(true);
          }
        }
      }
    }

    if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE &&
      !_.isEmpty(this.props.activeCanvasData.frameDetails) &&
      !_.isEqual(this.props.activeCanvasData.frameDetails, prevProps.activeCanvasData.frameDetails)) {
      this.setImageObjectPositions(this.props.activeCanvasData.frameDetails);
    }

    // fixed WAAG-1203 - extra space added below the snapshot in landscape mode
    let annotationBarElement = document.getElementById("annotationBar");
    let whiteboardElement = document.getElementById("whiteboardContainer");

    if (this.props.organiser !== this.props.uniqueId &&
      this.props.uniqueId === this.props.snapshoteeId &&
      this.props.snapshotPermissionReply === true) {
      if (this.getOrientation() === 0) {
        annotationBarElement ? annotationBarElement.style.opacity = "1" : "";
        whiteboardElement ? whiteboardElement.style.height = "100%" : "";
      } else {
        annotationBarElement ? annotationBarElement.style.opacity = "0" : "";
        whiteboardElement ? whiteboardElement.style.height = "auto" : "";
      }
    } else if (this.props.organiser !== this.props.uniqueId) {
      annotationBarElement ? annotationBarElement.style.opacity = "1" : "";
      whiteboardElement ? whiteboardElement.style.height = "100%" : "";
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let actionObject = prevState.actionObject;
    let redoObject = prevState.redoObject;
    let fontSize = prevState.fontSize;
    if (fontSize !== nextProps.defaultFontInfo.fontSize) {
      fontSize = nextProps.defaultFontInfo.fontSize;
    }
    if (
      !_.isEqual(
        nextProps.activeCanvasData.actionObject,
        prevState.actionObject
      )
    ) {
      actionObject = nextProps.activeCanvasData.actionObject;
    }
    if (
      !_.isEqual(nextProps.activeCanvasData.redoObject, prevState.redoObject)
    ) {
      redoObject = nextProps.activeCanvasData.redoObject;
    }

    return {
      actionObject: actionObject,
      redoObject: redoObject,
      fontSize: fontSize
    };
  }

  onCanvasSwitched = (switchedCanvasId, switchedCanvasType) => {
    console.log("Switching to canvas: ", switchedCanvasId, switchedCanvasType);
    this.thumbnailUploadRequired = false;
  }

  checkIfFontInfoNotSame = () => {
    const { defaultFontInfo } = this.props;
    if (defaultFontInfo) {
      if (
        defaultFontInfo.fontColor !== this.state.selectedColor ||
        defaultFontInfo.fontSize !== this.state.selectedFontSize ||
        defaultFontInfo.fontFamily !== this.state.selectedFontFamily
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  updateSelectedObject = selectedObject => {
    this.selectedObject = _.cloneDeep(selectedObject);
  };

  getCanvasObjectById = objectId => {
    let canvasObject = null;
    // TODO: handle objectId is an array in case of pdf 
    if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
      for (let i = 0; i < this.canvas.getObjects().length; ++i) {
        if (this.canvas.item(i).objectId == objectId) {
          canvasObject = this.canvas.item(i);
        }
      }
    }
    return canvasObject;
  };

  //Returning array of elements having same object id on canvas.
  getCanvasObjectsById = objectId => {
    let canvasObjects = []
    for (let i = 0; i < this.canvas.getObjects().length; ++i) {
      if (this.canvas.item(i).objectId == objectId) {
        canvasObjects.push(this.canvas.item(i));
      }
    }
    return canvasObjects
  };

  addShape = () => {
    let pointer = this.canvas.getPointer();
    //stored initial coordinates of current object
    this.currentObjectOriginX = pointer.x;
    this.currentObjectOriginY = pointer.y;

    switch (this.props.selectedAnnotation) {
      case ANNOTATIONS.LINE:
        this.addLine(1, 1, pointer.x, pointer.y);
        break;
      case ANNOTATIONS.RECTANGLE:
        this.addRectangle(1, 1, pointer.x, pointer.y);
        break;
      case ANNOTATIONS.CIRCLE:
        this.addCircle(1, 1, pointer.x, pointer.y);
        break;
    }
  };

  addLine = (width, height, leftMargin, topMargin) => {
    const points = [leftMargin, topMargin, leftMargin, topMargin];
    this.currentObject = new fabric.Line(points, {
      strokeWidth: this.state.selectedLineWidth,
      stroke: this.state.selectedColor,
      originX: "center",
      originY: "center",
      selectable: false,
      objectType: this.props.selectedAnnotation,
      objectId: new Date().getTime() + this.props.participantId,
      strokeUniform: true,
      // padding: 10
    });
    this.canvas.add(this.currentObject);
  };

  addRectangle = (width, height, leftMargin, topMargin) => {
    this.currentObject = new fabric.Rect({
      left: leftMargin,
      top: topMargin,
      originX: "left",
      originY: "top",
      width: width,
      height: height,
      angle: 0,
      stroke: this.state.selectedColor,
      strokeWidth: this.state.selectedLineWidth,
      fill: this.state.fillColor,
      selectable: false,
      objectType: this.props.selectedAnnotation,
      objectId: new Date().getTime() + this.props.participantId,
      strokeUniform: true,
      // padding: 10
    });
    this.canvas.add(this.currentObject);
  };

  addCircle = (rx, ry, leftMargin, topMargin) => {
    this.currentObject = new fabric.Ellipse({
      rx: Math.abs(rx),
      ry: Math.abs(ry),
      left: leftMargin,
      top: topMargin,
      originX: "left",
      originY: "top",
      strokeWidth: this.state.selectedLineWidth,
      stroke: this.state.selectedColor,
      fill: this.state.fillColor,
      selectable: false,
      scaleX: 1,
      scaleY: 1,
      objectType: this.props.selectedAnnotation,
      objectId: new Date().getTime() + this.props.participantId,
      strokeUniform: true,
      // padding: 10
    });
    this.canvas.add(this.currentObject);
  };

  addScanCodeSearchResultImage = (url) => {
    const timeStamp = new Date().getTime();
    let imgEl = new Image();
    // Ref URL: https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome#solution
    imgEl.src = url + "?not-from-cache-please";
    imgEl.onload = () => {
      // adjust the image withen SNAPSHOT_SCAN_CODE.SEARCH_RESULT_IMAGE.width and highet
      fabric.Image.fromURL(url, function (img) {
        if (imgEl.naturalWidth >= imgEl.naturalHeight) {
          img.scaleToWidth(SNAPSHOT_SCAN_CODE.SEARCH_RESULT_IMAGE.width, false);
        } else if (imgEl.naturalHeight >= imgEl.naturalWidth) {
          img.scaleToHeight(SNAPSHOT_SCAN_CODE.SEARCH_RESULT_IMAGE.width, false);
        }
        img.set({
          left: CANVAS_WIDTH - (SNAPSHOT_SCAN_CODE.SEARCH_RESULT_IMAGE.width + 40),
          // added extra 40 to move down the search queary name, so screen share 'Presenting' text display correctly on small/mobile devices
          top: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.top + 40,
          objectType: ANNOTATIONS.IMAGE,
          objectId: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_IMAGE.objectIdPrefix + timeStamp + this.props.participantId,
          selectable: false
        })
        this.canvas.add(img);
        this.canvas.renderAll();
        this.sendAddObjectMessage(
          img,
          socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
        );
        this.generateBlobAndUploadThumbnail()
      }.bind(this), {
        crossOrigin: 'anonymous'
      });
      imgEl.remove();
    }
  };

  addScanCodeSearchResultQuery(text) {
    let strokeWidth = (this.canvas.getHeight() * 0.5) / CANVAS_HEIGHT;
    const timeStamp = new Date().getTime();
    const searchQuery = text.length > SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.maxTextLength ? 
      text.slice(0, SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.maxTextLength)+'...' : text;
    let textbox = this.currentObject = new fabric.Text(searchQuery, {
      left: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.left,
      // added extra 40 to move down the search queary name, so screen share 'Presenting' text display correctly on small/mobile devices
      top: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.top + 40,
      fill: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.color,
      textAlign: "left",
      fontSize: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.fontSize,
      fontFamily: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      lockScalingY: true,
      objectType: ANNOTATIONS.TEXT,
      objectId: 'searchQuery_' + timeStamp + this.props.participantId,
      strokeWidth: strokeWidth,
      strokeUniform: true,
      isWrapping: true,
      breakWords: true,
      underline: true,
      hasBorders: false
    });
    textbox.hasRotatingPoint = false;
    textbox.lockMovementX= true;
    textbox.lockMovementY= true;

    textbox.setControlsVisibility({
      mt: false,
      mb: false,
      tr: false,
      tl: false,
      br: false,
      bl: false,
      ml: false,
      mr: false,
      mtr: false
    });
    this.sendAddObjectMessage(
      textbox,
      socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
    );
    this.canvas.add(textbox);
    this.canvas.renderAll();
  }

  getTrimedText(text, font, maxLength) {
    // calulate the length/width of text if its grether the maxLength then reduce the text length by 10 call recursively
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.fontSize = font;
    const currentLength = context.measureText(text).width;
    canvas.remove();
    if(currentLength > maxLength){
      return this.getTrimedText(text.slice(0, text.length - 10 ), font, maxLength)
    } else {
      return text;
    }
  }

  addScanCodeSearchResultTitle(element, index) {
    let strokeWidth = (this.canvas.getHeight() * 0.5) / CANVAS_HEIGHT;
    const timeStamp = new Date().getTime();
    let text = this.getTrimedText(element.title, SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.fontSize, SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.maxTextLength);
    text = text === element.title ? element.title : text + '...'
    let textbox = this.currentObject = new fabric.Text(text, {
      left: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.left,
      top: (index * SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.top) + SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.top,
      fill: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.color,
      textAlign: "left",
      fontSize: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.fontSize,
      fontFamily: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      objectType: ANNOTATIONS.TEXT,
      objectId: 'title_' + timeStamp + index,
      strokeWidth: strokeWidth,
      strokeUniform: true,
      isWrapping: true,
      breakWords: true,
      webPageURL: element.link,
      hasBorders: false,
      hoverCursor: "pointer"
    });
    textbox.hasRotatingPoint = false;
    textbox.lockMovementX= true;
    textbox.lockMovementY= true;

    textbox.setControlsVisibility({
      mt: false,
      mb: false,
      tr: false,
      tl: false,
      br: false,
      bl: false,
      ml: false,
      mr: false,
      mtr: false
    });
    this.sendAddObjectMessage(
      textbox,
      socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
    );
    this.canvas.add(textbox);
    this.canvas.renderAll();
  }

  addScanCodeSearchResultDesc(element, index) {
    let strokeWidth = (this.canvas.getHeight() * 0.5) / CANVAS_HEIGHT;
    const timeStamp = new Date().getTime();
    const top = (index * SNAPSHOT_SCAN_CODE.SEARCH_RESULT_TITLE.top) + SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.top + SNAPSHOT_SCAN_CODE.SEARCH_RESULT_QUERY.top;
    let text = this.getTrimedText(element.desc, SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.fontSize, SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.maxTextLength);
    text = text === element.desc ? element.desc : text + '...'
    let textboxDesc = this.currentObject = new fabric.Text(text, {
      left: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.left,
      top: top,
      fill: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.color,
      textAlign: "left",
      fontSize: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.fontSize,
      fontFamily: SNAPSHOT_SCAN_CODE.SEARCH_RESULT_DESC.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      lockScalingY: true,
      objectType: ANNOTATIONS.TEXT,
      objectId: 'desc_' + timeStamp + index,
      strokeWidth: strokeWidth,
      strokeUniform: true,
      isWrapping: true,
      breakWords: true,
      hasBorders: false
    });
    textboxDesc.hasRotatingPoint = false;
    textboxDesc.lockMovementX= true;
    textboxDesc.lockMovementY= true;

    textboxDesc.setControlsVisibility({
      mt: false,
      mb: false,
      tr: false,
      tl: false,
      br: false,
      bl: false,
      ml: false,
      mr: false,
      mtr: false
    });
    this.sendAddObjectMessage(
      textboxDesc,
      socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
    );
    this.canvas.add(textboxDesc);
    this.canvas.renderAll();
  }

  addText(event) {
    let fontSize = this.state.selectedFontSize;
    let strokeWidth = (this.canvas.getHeight() * 0.5) / CANVAS_HEIGHT;
    const pointer = this.canvas.getPointer(event.e);
    let textbox = this.currentObject = new fabric.Textbox("", {
      left: pointer.x,
      top: pointer.y,
      fill: this.state.selectedColor,
      width: 100,
      textAlign: "left",
      fontSize: fontSize,
      editable: true,
      selectable: false,
      fontFamily: this.state.selectedFontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fixedWidth: "40",
      lockScalingY: true,
      objectType: this.props.selectedAnnotation,
      objectId: new Date().getTime() + this.props.participantId,
      stroke: this.state.selectedColor,
      strokeWidth: strokeWidth,
      strokeUniform: true,
      isWrapping: true,
      breakWords: true,
    });

    textbox.hasRotatingPoint = true;
    textbox.setControlsVisibility({
      mt: false,
      mb: false,
      tr: false,
      tl: false,
      br: false,
      bl: false
    });
    this.sendAddObjectMessage(
      textbox,
      socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
    );
    this.canvas.setActiveObject(textbox);
    this.canvas.add(textbox);
    textbox.enterEditing();
  }

  textOnChange = () => {
    let textbox = this.canvas.getActiveObject();
    var actualWidth = textbox.getBoundingRect().width;

    // Cached linewidths for getting maximum linewidths if there is a multiline text.
    for (let i = 0; i < textbox.__lineWidths.length; i++) {
      if (!this.lineWidths)
        this.lineWidths = [];
      this.lineWidths[i] = !isNaN(textbox.__lineWidths[i]) ? textbox.__lineWidths[i] : this.lineWidths[i]
    }
    // A ratio of actual width of textbox to the width from textbox w.r.t. viewport 
    let widthScale = textbox.width / textbox.getBoundingRect().width;
    let finalTextboxWidth = actualWidth;

    // To avoid unnecessary multiline if we copy paste a text.
    var maxLineLength = Math.max(...textbox.textLines.map(line => line.length))
    var maxWordWidth = (actualWidth + maxLineLength * 15) / widthScale;
    var largest = Math.max(...this.lineWidths, maxWordWidth);
    var tryWidth = (largest + 75) / widthScale;

    // Setting final width, and checking if it lies inside canvas Boundary or not.
    finalTextboxWidth = (textbox.getBoundingRect().left + tryWidth < this.canvas.width) ? (tryWidth * widthScale) : ((this.canvas.width - textbox.getBoundingRect().left - 5) * widthScale);

    textbox.set("width", finalTextboxWidth);
    textbox.set('text', textbox.textLines.join('\n'))

    if (textbox.text && textbox.text.length < 1) {
      this.props.sendDeleteObjectMessage(
        event.target.objectId,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
      this.canvas.remove(textbox);
      return;
    }
    this.calcShapeTransformationsWrtImg(textbox, 'text:onchange');
    this.sendUpdateObjectMessage(
      textbox,
      socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
    );
    this.canvas.renderAll();
  }

  updateShape = event => {
    let pointer = this.canvas.getPointer(event.e);

    if (this.currentObjectOriginX > pointer.x)
      this.currentObject.set({ originX: "right" });
    else this.currentObject.set({ originX: "left" });

    if (this.currentObjectOriginY > pointer.y)
      this.currentObject.set({ originY: "bottom" });
    else this.currentObject.set({ originY: "top" });

    let height = Math.abs(pointer.y - this.currentObjectOriginY);
    let width = Math.abs(pointer.x - this.currentObjectOriginX);

    switch (this.props.selectedAnnotation) {
      case ANNOTATIONS.LINE:
        this.currentObject.set({ x2: pointer.x, y2: pointer.y });
        break;
      case ANNOTATIONS.DIAMOND:
        if (width >= height)
          this.currentObject.set({ width: width, height: width });
        else this.currentObject.set({ width: height, height: height });
        break;
      case ANNOTATIONS.TRIANGLE:
        this.currentObject.set({ height: height, width: width });
        break;
      case ANNOTATIONS.RECTANGLE:
        this.currentObject.set({ height: height, width: width });
        break;
      case ANNOTATIONS.CIRCLE:
        this.currentObject.set({ rx: width / 2, ry: height / 2 });
        break;
    }
  };

  changeSelectable = value => {
    this.canvas.discardActiveObject();
    this.canvas.forEachObject(object => {
      object.set({ selectable: value });
    });
    this.canvas.renderAll();
  };

  handleChangeAnnotation = value => {
    if (!this.canvas) {
      this.props.setSelectedAnnotation(value);
      return;
    }

    let notationType = _.isObject(value) ? value.value : value;
    this.canvas.discardActiveObject();
    this.canvas.renderAll();
    this.changeSelectable(false);

    switch (notationType) {
      case ANNOTATIONS.LINE:
        this.isSelectedAnnotationShape = true;
        this.canvas.set({
          defaultCursor: "auto",
          hoverCursor: "auto",
          isDrawingMode: false
        });
        break;
      case ANNOTATIONS.RECTANGLE:
        this.isSelectedAnnotationShape = true;
        this.canvas.set({
          defaultCursor: "auto",
          hoverCursor: "auto",
          isDrawingMode: false
        });
        break;
      case ANNOTATIONS.CIRCLE:
        this.isSelectedAnnotationShape = true;
        this.canvas.set({
          defaultCursor: "auto",
          hoverCursor: "auto",
          isDrawingMode: false
        });
        break;
      case ANNOTATIONS.TEXT:
        this.isSelectedAnnotationShape = false;
        this.canvas.set({
          defaultCursor: "auto",
          hoverCursor: "auto",
          isDrawingMode: false
        });
        break;
      case ANNOTATIONS.FREE_HAND.NAME:
        let width = value.width || ANNOTATIONS.FREE_HAND.WIDTH;
        this.isSelectedAnnotationShape = false;
        this.canvas.freeDrawingBrush.width = width;
        this.canvas.freeDrawingBrush.color = value.color || this.state.selectedColor;
        this.canvas.set({
          defaultCursor: "auto",
          hoverCursor: "auto",
          isDrawingMode: this.props.uniqueId === this.props.organiser || this.props.allowEdit // User can draw annotations if user role is agent or edit is allowed for user/customer
        });
        break;
      case ANNOTATIONS.ERASER.NAME:
        let eraserWidth = ANNOTATIONS.ERASER.WIDTH;
        this.isSelectedAnnotationShape = false;
        this.canvas.freeDrawingBrush.width = eraserWidth;
        this.canvas.freeDrawingBrush.color = "white";
        // User can erase objects if user role is agent or edit is allowed for user/customer
        if(this.props.uniqueId === this.props.organiser || this.props.allowEdit) {
          this.canvas.set({
            defaultCursor: "all-scroll",
            hoverCursor: "all-scroll",
            isDrawingMode: true 
          });
        }
        break;
      case ANNOTATIONS.ERASE_OBJECT:
        if(this.props.uniqueId === this.props.organiser || this.props.allowEdit) {
          this.changeSelectable(true);
          this.isSelectedAnnotationShape = false;
          this.canvas.set({
            defaultCursor: "all-scroll",
            hoverCursor: "all-scroll",
            isDrawingMode: false
          });
        } 
        break;
      case ANNOTATIONS.SELECTION:
        this.changeSelectable(true);
        this.isSelectedAnnotationShape = false;
        this.canvas.set({
          defaultCursor: "all-scroll",
          hoverCursor: "all-scroll",
          isDrawingMode: false
        });
        break;
      case "remove":
        this.isSelectedAnnotationShape = false;
        this.canvas.set({
          defaultCursor: "default",
          hoverCursor: "default",
          isDrawingMode: false
        });
        break;
      default:
        this.isSelectedAnnotationShape = false;
        this.canvas.set({
          isDrawingMode: false,
          defaultCursor: "default",
          hoverCursor: "default"
        });
        break;
    }

    // (notationType !== ANNOTATIONS.SPOTLIGHT.NAME && this.props.uniqueId == this.props.organiser) &&
    //   this.props.removeSpotLight();

    this.props.setSelectedAnnotation(notationType === "remove" ? "" : notationType)
    
  };

  handleChangeColor = selectedColor => {
    if (!this.canvas) return;

    let activeObject = this.canvas.getActiveObject();
    if (this.props.selectedAnnotation !== ANNOTATIONS.PDF_HIGHLIGHT.NAME
      && this.props.selectedAnnotation !== ANNOTATIONS.ERASER.NAME) {
      this.canvas.freeDrawingBrush.color = selectedColor;
    }

    if (
      activeObject &&
      (this.props.selectedAnnotation === ANNOTATIONS.SELECTION || activeObject.objectType===ANNOTATIONS.TEXT)
    ) {
      this.updateSelectedObject(activeObject);
      switch (activeObject.objectType) {
        case ANNOTATIONS.TEXT:
          activeObject.set({
            fill: selectedColor,
            stroke: selectedColor
          });
          this.canvas.renderAll();
          break;
        case ANNOTATIONS.CIRCLE:
        case ANNOTATIONS.RECTANGLE:
        case ANNOTATIONS.LINE:
        case ANNOTATIONS.FREE_HAND.NAME:
          // case ANNOTATIONS.PDF_HIGHLIGHT.NAME:
          activeObject.set({
            stroke: selectedColor
          });
          this.canvas.renderAll();
          break;
      }
      this.props.setIsCanvasModified(true, "handlechangecolor");
      // this.sendModifyObjectMessage(activeObject);
      this.sendUpdateObjectMessage(
        activeObject,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
    }
    this.setState(
      {
        selectedColor: selectedColor
      },
      () => {
        if (activeObject && activeObject.hiddenTextarea) {
          activeObject.hiddenTextarea.focus();
        }
        this.setDefaultFontInfo(null, true)
      }
    );
  };

  handleChangeFontSize = (value = DEFAULT_FONT_SIZE) => {

    if (!this.canvas) return;

    let activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.objectType === ANNOTATIONS.TEXT) {
      this.updateSelectedObject(activeObject);
      activeObject.set({
        fontSize: value
      });
      this.canvas.renderAll();
      // this.sendModifyObjectMessage(activeObject);
      this.sendUpdateObjectMessage(
        activeObject,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
    }
    this.setState({
      selectedFontSize: value
    }, () => {
      if (activeObject && activeObject.hiddenTextarea) {
        activeObject.hiddenTextarea.focus();
      }
    });
    this.setDefaultFontInfo(value, true);
  };

  handleChangeFontFamily = value => {
    if (!this.canvas) return;

    let activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.objectType === ANNOTATIONS.TEXT) {
      this.updateSelectedObject(activeObject);
      activeObject.set({
        fontFamily: value,
      });
      this.canvas.renderAll();
      // this.sendModifyObjectMessage(activeObject);
      this.sendUpdateObjectMessage(
        activeObject,
        socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
      );
    }
    this.setState(
      {
        selectedFontFamily: value,
      },
      () => {
        if (activeObject && activeObject.hiddenTextarea) {
          activeObject.hiddenTextarea.focus();
        }
        this.setDefaultFontInfo(null, true)
      }
    );
  };

  undo = () => {
    const { actionObject } = this.state;
    if (
      (actionObject && actionObject.length > 0) ||
      (actionObject &&
        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
        actionObject[this.props.activeCanvasData.activePage] &&
        actionObject[this.props.activeCanvasData.activePage][
        actionObject[this.props.activeCanvasData.activePage].length - 1
        ])
    ) {
      let object;
      let lastDrawnObject =
        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
          ? actionObject[this.props.activeCanvasData.activePage][
          actionObject[this.props.activeCanvasData.activePage].length - 1
          ]
          : actionObject[actionObject.length - 1];
      if (lastDrawnObject) {
        switch (lastDrawnObject.action) {
          case ACTIONS.WHITEBOARD.ADD_OBJECT:
            object = this.getCanvasObjectById(lastDrawnObject.objectId);
            this.canvas.remove(object);
            this.props.sendDeleteObjectMessage(
              lastDrawnObject.objectId,
              socketMessage.subCategories.WHITEBOARD.subActions.UNDO
            );
            break;
          case ACTIONS.WHITEBOARD.UPDATE_OBJECT:
            // remove existing and add updated
            object = this.getCanvasObjectById(lastDrawnObject.objectId);
            this.canvas.remove(object);
            this.addObjectToCanvas(
              lastDrawnObject.prevObject,
              lastDrawnObject.objectId,
              lastDrawnObject.objectType
            );
            this.props.sendUpdateObjectMessage(
              {
                objectId: lastDrawnObject.objectId,
                objectType: lastDrawnObject.objectType,
                ...lastDrawnObject.prevObject
              },
              socketMessage.subCategories.WHITEBOARD.subActions.UNDO
            );
            break;
          case ACTIONS.WHITEBOARD.DELETE_OBJECT:
            // add previous object
            this.addObjectToCanvas(
              lastDrawnObject.prevObject,
              lastDrawnObject.objectId,
              lastDrawnObject.objectType
            );
            this.sendAddObjectMessage(
              {
                objectId: lastDrawnObject.objectId,
                objectType: lastDrawnObject.objectType,
                ...lastDrawnObject.prevObject
              },
              socketMessage.subCategories.WHITEBOARD.subActions.UNDO
            );
            break;
          default:
        }
      }
    }
  };

  onRedo = () => {
    this.undoOrRedoObserver.next(this.redo);
  };

  onUndo = () => {
    this.undoOrRedoObserver.next(this.undo);
  };

  redo = () => {
    const { redoObject } = this.state;
    if (
      (redoObject && redoObject.length > 0) ||
      (redoObject &&
        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
        redoObject[this.props.activeCanvasData.activePage] &&
        redoObject[this.props.activeCanvasData.activePage][
        redoObject[this.props.activeCanvasData.activePage].length - 1
        ])
    ) {
      let object;
      let objectToDraw =
        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
          ? redoObject[this.props.activeCanvasData.activePage][
          redoObject[this.props.activeCanvasData.activePage].length - 1
          ]
          : redoObject[redoObject.length - 1];
      if (objectToDraw) {
        switch (objectToDraw.action) {
          case ACTIONS.WHITEBOARD.ADD_OBJECT:
            this.addObjectToCanvas(
              objectToDraw.nextObject,
              objectToDraw.objectId,
              objectToDraw.objectType
            );
            this.sendAddObjectMessage(
              {
                objectId: objectToDraw.objectId,
                objectType: objectToDraw.objectType,
                ...objectToDraw.nextObject
              },
              socketMessage.subCategories.WHITEBOARD.subActions.REDO
            );
            break;
          case ACTIONS.WHITEBOARD.UPDATE_OBJECT:
            // remove existing and add updated
            // object = this.getCanvasObjectsById(objectToDraw.objectId);
            // this.canvas.remove(object);
            this.addObjectToCanvas(
              objectToDraw.nextObject,
              objectToDraw.objectId,
              objectToDraw.objectType,
              objectToDraw.objectId //for removing all instances with same object id.
            );
            this.props.sendUpdateObjectMessage(
              {
                objectId: objectToDraw.objectId,
                objectType: objectToDraw.objectType,
                ...objectToDraw.nextObject
              },
              socketMessage.subCategories.WHITEBOARD.subActions.REDO
            );
            break;
          case ACTIONS.WHITEBOARD.DELETE_OBJECT:
            object = this.getCanvasObjectById(objectToDraw.objectId);
            this.canvas.remove(object);
            this.props.sendDeleteObjectMessage(
              objectToDraw.objectId,
              socketMessage.subCategories.WHITEBOARD.subActions.REDO
            );
            break;
          default:
            break;
        }
      }
    }
  };

  imageScaleFactor = (oImg) => {
    if (!this.canvas) return;
    var width = this.canvas.width;
    var height = this.canvas.height;
    var ratio = (oImg.width / oImg.height).toFixed(2);
    if (width / height > ratio) {
      width = height * ratio;
    } else {
      height = width / ratio;
    }
    var scale = width / oImg.width
    var scaleY = height / oImg.height

    return { scale: scale, scaleY: scaleY, height: height, width: width }
  };

  addImage = (fileUrl, isBackground, canvasId) => {  
    console.log('fileUrl: ', fileUrl);
    this.getNewCanvas();

    if (!this.canvas) return;
       
    if(isBackground && !this.props.showLogoOnCanvasStatus) {
      return;
    }
    // if active canvas is SEARCH_RESULTS then do not add watermark on canvas
    if(this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS) return;
    
    fabric.Image.fromURL(
      fileUrl ,
      myImg => {
        console.log('[addImage] url: ', myImg);
        this.props.showSpinner();
        let scaleFactor = this.imageScaleFactor(myImg);
        let scale = scaleFactor.scale / this.zoom;
        myImg.scale(scale)
        let finalWidth = myImg.width * scale * this.zoom;
        let finalHeight = myImg.height * scale * this.zoom;

        myImg.set({
          left: (this.canvas.width - finalWidth) / 2 / this.zoom,
          top: (this.canvas.height - finalHeight) / 2 / this.zoom,
          selectable: !isBackground && this.props.selectedAnnotation === ANNOTATIONS.SELECTION
            ? true
            : false,
          opacity: isBackground ? 0.3 : 1,
          lockScalingX: isBackground,
          lockScalingY: isBackground,
          lockUniScaling: isBackground,
          lockRotation: isBackground,
          lockScalingFlip: isBackground,
          lockMovementX: isBackground,
          lockMovementY: isBackground,
          hasControls: !isBackground,
          hasBorders: !isBackground
        })
        let imgObj = this.canvas.getObjects().filter(obj => obj.objectType == 'backgroundImage')[0];
        if ((!canvasId || canvasId == this.props.canvasId)) {
          if (isBackground) {
            if (imgObj) {
              console.log('hide both spinners in addImage isBackground');
              this.props.hideSpinner();
              this.props.hideSpinnerForCanvasList();
              return;
            }
          } else {
            if (imgObj) this.canvas.remove(imgObj);
          }

          myImg["objectType"] = isBackground ? "backgroundImage" : "image";
          myImg["objectId"] = "image"

          let canvasImageObject = this.canvas.getObjects().filter(obj => obj.objectType == 'image')[0];
          if (!canvasImageObject) {
            this.canvas.add(myImg);
            console.log('[addImage] image added on canvas');
            // set image position 
            if (!_.isEmpty(this.props.activeCanvasData.frameDetails))
              this.setImageObjectPositions(this.props.activeCanvasData.frameDetails, true);

            console.log('added image on canvas');

            this.canvas.sendToBack(myImg);

            if(!isBackground) {
              this.generateBlobAndUploadThumbnail();
              // this.sendAddObjectMessage(
              //   myImg,
              //   socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
              // );
              myImg.on("modified", (evt) => {
                this.canvas.sendToBack(myImg);
                this.updateImageObjectPosition(true);
                this.applyShapeTransformationsWrtImg(null, "addImage: modified");
              });
            }
            this.addMouseUpEventToImage(myImg);
            this.applyShapeTransformationsWrtImg(null, 'image:rendered');

            // upload thumbnail after image has been added on canvas
            // commenting it as we already upload thumbnail in canvas.on('object:added') event
            // setTimeout(() => {
            //   console.log("uploading Thumbnail");
            //   this.props.uploadThumbnail()
            // }, 100);
            // Fix for - Safari: background logo not displayed on canvas unless user resizes browser window
            setTimeout(() => {
              console.log('[addImage] render canvas');              
              this.canvas.renderAll();
            }, 500)
          }
        }
        // hide spinner after image is rendered in the scenario when user has selected an image to add on canvas
        console.log("Hidding spinner after image has been added on canvas");
        this.props.hideSpinner();

        isBackground && this.props.hideSpinnerForCanvasList();
      },
      {
        crossOrigin: "anonymous"
      }
    );
  };

  updateImageObjectPosition = (sendScaleAndAngle) => {
    /**
     * sendScaleAndAngle: if this flag is true we send scale and rotation angle of object, 
     * because user can resize and rotate images and this should reflect for other users in session
     */
    let tmpImageAllObjectPosition = {};
    if (this.canvas) {
      this.canvas.forEachObject(obj => {
        console.log('obj: ', obj);
        if (obj.objectType === "image") {
          obj.objectId = "image";
        }
        // let frameWidth = document.getElementById("loookitFrame") ? document.getElementById("loookitFrame").style.width : 0;

        tmpImageAllObjectPosition[obj.objectId] = {};
        tmpImageAllObjectPosition[obj.objectId].top = obj.top;
        tmpImageAllObjectPosition[obj.objectId].left = obj.left;
        if (sendScaleAndAngle) {
          tmpImageAllObjectPosition[obj.objectId].scaleX = obj.scaleX;
          tmpImageAllObjectPosition[obj.objectId].scaleY = obj.scaleY;
          tmpImageAllObjectPosition[obj.objectId].angle = obj.angle;
        }
      });
    }
    let imageObjectPositions = {};
    imageObjectPositions.imageAllObjectsPosition = tmpImageAllObjectPosition
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: socketMessage.subCategories.PDF.SET_PAGE_POSITION,
        canvasId: this.props.activeCanvasData.canvasId,
        frameDetails: imageObjectPositions,
        sessionKey: this.props.sessionKey,
        participantId: ""
      }
    };
    let timeout = setTimeout(() => {
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }, 500);
    this.intervals.push(timeout);
  }

  removeBackgroundImage = () => {
    let imgObj = this.canvas?.getObjects().filter(obj => obj.objectType == 'backgroundImage');
    imgObj &&
      imgObj.forEach(obj =>
        this.canvas.remove(obj)
      )
  }

  addMouseUpEventToImage = (imgObj) => {
    imgObj.on("moving", () => {
      this.applyShapeTransformationsWrtImg(null, 'image:mouse moving');
    });
  }

  calcShapeTransformationsWrtImg = (obj, userAction) => {
    if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE) {
      let imgObj = this.canvas.getObjects().filter(obj => obj.type == 'image')[0];
      if (imgObj && obj) {
        //console.log('Calculating transformations for ', userAction);
        var imgTransform = imgObj.calcTransformMatrix();
        var invertedImgTransform = invert(imgTransform);
        var desiredTransform = multiply(
          invertedImgTransform,
          obj.calcTransformMatrix()
        );
        // save the desired relation here.
        obj.relationship = desiredTransform;
        //console.log('obj after calculating transformations: ', obj.relationship);  
      }
    }
  }

  applyShapeTransformationsWrtImg = (objectToTransform, userAction) => {
    if (this.props.activeCanvasData.canvasType == CANVAS_TYPES.IMAGE) {
      let imgObj = null;
      imgObj = this.canvas._objects.filter(obj => obj.type == 'image')[0];

      if (objectToTransform && imgObj) {
        //console.log(`applyShapeTransformationsWrtImg1 to ${objectToTransform.type} shape for userAction ${userAction}`);
        this.applyTransformationToObject(objectToTransform, imgObj);
      } else {
        let shapesOnImages = this.canvas._objects.filter(obj => obj.type != 'image');
        if (imgObj && shapesOnImages.length > 0) {
          //console.log(`applyShapeTransformationsWrtImg2 to ${shapesOnImages.length} shapes for userAction ${userAction}`);
          shapesOnImages.forEach(obj => {
            //console.log(`applyShapeTransformationsWrtImg2 to shape ${obj.relationship}`);
            this.applyTransformationToObject(obj, imgObj);
          });
        }
      }
    }
  }

  applyTransformationToObject = (obj, imgObj) => {
    let relationship = obj.relationship;
    if (!relationship) {
      return;
    }
    let newTransform = multiply(
      imgObj.calcTransformMatrix(),
      relationship
    );
    let opt = fabric.util.qrDecompose(newTransform);
    obj.set({
      flipX: false,
      flipY: false,
    });
    obj.setPositionByOrigin(
      { x: opt.translateX, y: opt.translateY },
      'center',
      'center'
    );
    obj.set(opt);
    obj.setCoords();
    obj.bringToFront();
    imgObj.sendToBack();
    // console.log('Applied transformation to ', obj);
  }

  downloadCanvas = () => {
    this.props.broadcastDownloadAction(downloadableModules.CANVAS);
    let canvas = document.getElementById("loookitCanvas");
    if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
      if (isIE) {
        if ("msToBlob" in canvas) {
          // IE10+
          let blob = canvasToBlob(this.canvas, "#FFFFFF", this.isCanvasBlank());
          navigator.msSaveBlob(blob, "canvas.png");
        }
      } else {
        let blob = canvasToBlob(this.canvas, "#FFFFFF", this.isCanvasBlank());
        let downloadElement = document.getElementById("downloadAnnotation");
        //downloadElement.href = data;
        downloadElement.href = URL.createObjectURL(blob);
        downloadElement.download = this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS ?
          `scan_code_canvas_${this.props.activeCanvasData.canvasId}.png` : `Canvas_${this.props.activeCanvasData.canvasId}.png`;
      }
    }
  };

  sendAddObjectMessage = (canvasObject, actionType, pageNumber) => {
    // TODO: remove this block of code if not required after custome annotation implementation on pdftron
    if (canvasObject.objectType === ANNOTATIONS.SPOTLIGHT.NAME) {
      // TODO: remove spotlight when new annotation gets added in case of pdf canvas 
    }

    let canvasType = this.props.activeCanvasData.canvasType;
    let activePage =
      canvasType === CANVAS_TYPES.PDF
        ? pageNumber || this.props.activeCanvasData.activePage
        : -1;
    this.props.sendAddObjectMessage(
      canvasObject,
      actionType,
      canvasType,
      activePage
    );
  };

  sendUpdateObjectMessage = (canvasObject, actionType) => {
    this.props.sendUpdateObjectMessage(canvasObject, actionType);
  };

  sendDeleteObjectMessage = (objectId, actionType) => {
    this.props.sendDeleteObjectMessage(objectId, actionType);
  };

  sendModifyObjectMessage = (canvasObject, actionType) => {
    this.props.sendModifyObjectMessage(
      canvasObject,
      this.selectedObject,
      actionType
    );
  };

  addObjectToCanvas = (
    objectToAdd,
    objectId,
    objectType,
    oldObjectId = "" /*for removing all instances with same object id.*/,
    canvasId
  ) => {
    let that = this;
    if (canvasId && canvasId != this.props.canvasId) {
      return;
    }

    // trigger event to add object on webviewer
    if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
      let pdfWebviewerInstance = getPDFWebviewerInstance();

      if (pdfWebviewerInstance) {
        try {
          objectToAdd.uniqueId !== this.props.uniqueId &&
          pdfWebviewerInstance.annotManager.trigger('addAnnotation', 
            objectToAdd.canvasData.xfdfString,
            objectToAdd.objectType);
        } catch(error) {
          console.log("Supressing error while canvas switch:", error);
        }
      }
      return;
    }
    //console.log("in addObjectToCanvas objectType: ", objectType);
    fabric.util.enlivenObjects([objectToAdd], enlivenedObjects => {
      let newObject = enlivenedObjects[0];
      if (newObject) {
        newObject["strokeUniform"] = true;
        newObject["objectId"] = objectId;
        newObject["objectType"] = objectType;

        if (newObject["objectType"] === ANNOTATIONS.ERASER.NAME) {
          newObject["lockScalingX"] = true
          newObject["lockScalingY"] = true
          newObject["lockRotation"] = true
          newObject["lockUniScaling"] = true
          newObject["lockScalingFlip"] = true
          newObject["lockMovementX"] = true
          newObject["lockMovementY"] = true
        }

        if (newObject["objectType"] === ANNOTATIONS.ERASER.NAME) {
          newObject["evented"] = false
        }

        if (
          this.props.selectedAnnotation !== ANNOTATIONS.SELECTION &&
          this.props.selectedAnnotation !== ANNOTATIONS.ERASE_OBJECT
        ) {
          newObject.selectable = false;
        } else {
          newObject.selectable = true;
        }

        switch (newObject.objectType) {
          case ANNOTATIONS.TEXT:
            newObject.lockScalingY = true;
            newObject.hasRotatingPoint = true;
            newObject.setControlsVisibility({
              mt: false,
              mb: false,
              tr: false,
              tl: false,
              br: false,
              bl: false
            });
            break;
          case ANNOTATIONS.FREE_HAND.NAME:
          case ANNOTATIONS.PDF_HIGHLIGHT.NAME:
            newObject.fill = null;
            break;
        }
        var setActiveObject = false;
        //Removing All objects with same object ID to avoid ambiguous objects on canvas.
        let canvasObjectsWithSameID = oldObjectId !== "" ? this.getCanvasObjectsById(oldObjectId) : [];
        canvasObjectsWithSameID.length > 0 &&
          canvasObjectsWithSameID.map(canvasObject => {
            if (canvasObject === this.canvas.getActiveObject())
              setActiveObject = true
            this.canvas.remove(canvasObject)
            return;
          })
        this.removeBackgroundImage();
        if (!canvasId || canvasId == this.props.canvasId) {
          if (newObject.type != 'image'){
            this.canvas.add(newObject);
          } else if(newObject.type == 'image' && this.props.activeCanvasData.canvasType === CANVAS_TYPES.SEARCH_RESULTS) {
            this.canvas.add(newObject);
          }
          console.log("added object: ", newObject.objectType);
        } else {
          console.log('hide spinner in add object to canvas');
          this.props.hideSpinner();
          return;
        }
        if (setActiveObject) this.canvas.setActiveObject(newObject)
        if (newObject.type == 'image') {
          newObject.sendToBack();
          this.addMouseUpEventToImage(newObject);
          this.applyShapeTransformationsWrtImg(null, 'addObjectToCanvas');
        } else {
          let imgObj = this.canvas._objects.filter(obj => obj.objectType == "image")[0];

          imgObj && imgObj.trigger("moving");

          this.applyShapeTransformationsWrtImg(newObject, 'addObjectToCanvas');
        }
      }
      if (objectType == "image") {
        console.log("hidding spinner in addObjectToCanvas");
        this.props.hideSpinner();
      }
      this.props.setIsCanvasModified(true, "addObjectToCanvas");
    });
  };

  clearCanvas = () => {
    this.props.setIsCanvasModified(true, "clearCanvas");
    this.props.clearCanvas();
  };

  resize(storeZoomFactor) {
    var canvasSizer = document.getElementById("canvasElement");
    var scrollY = document.getElementById("scrollY");
    let whiteBoardDiv = document.getElementById("canvasSizer");
    if (canvasSizer && isMobileOnly) {
      if (window.innerHeight < window.innerWidth) {
        canvasSizer.style.width = "100%"
        canvasSizer.style.height = "100%"
      } else if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
        canvasSizer.style.width = "100%"
        canvasSizer.style.height = "fit-content"
      }
      document.getElementById("whiteboardContainer").style.height = "100%";
    }

    if (canvasSizer) {
      var width = canvasSizer.offsetWidth;
      var height = canvasSizer.offsetHeight;
      if (this.canvas) {
        var ratio = (this.canvas.getWidth() / this.canvas.getHeight()).toFixed(2);
        if (width / height > ratio) {
          width = height * ratio;
        } else {
          height = width / ratio;
        }
        var scale = this.canvasScale = width / this.canvas.getWidth();
        var zoom = this.canvas.getZoom();
        zoom *= scale.toFixed(3);
        if (storeZoomFactor) this.zoomFactor = this.zoom = zoom;
        this.canvasOrigDimension = { width: width, height: height, zoom: zoom };
        this.canvas.setDimensions({ width: width, height: height });
        this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
        this.setState({
          canvasHeight: height
        })
        this.canvas.renderAll();
      }
    }
    if (whiteBoardDiv) {// for tabs
      scrollY ? scrollY.style.height = "auto" : null
    }
  }

  //this function is always going to return array with single object
  getSingleParticipant = () => {
    return this.props.admittedParticipants.filter(
      participant => participant.role == USER_ROLES.USER
    );
  };

  setImageObjectPositions = (frameDetails, uploadCanvasThumbnail) => {
    if (frameDetails.imageAllObjectsPosition) {
      let obj = this.canvas._objects.filter(obj => obj.objectType === "image")[0];
      if (obj) {
        if (!frameDetails.imageAllObjectsPosition[obj.objectId]) return;
        obj.set({
          left: frameDetails.imageAllObjectsPosition[obj.objectId].left,
          top: frameDetails.imageAllObjectsPosition[obj.objectId].top,
          scaleX: frameDetails.imageAllObjectsPosition[obj.objectId].scaleX
            ? frameDetails.imageAllObjectsPosition[obj.objectId].scaleX
            : obj.scaleX,
          scaleY: frameDetails.imageAllObjectsPosition[obj.objectId].scaleY
            ? frameDetails.imageAllObjectsPosition[obj.objectId].scaleY
            : obj.scaleY,
          angle: frameDetails.imageAllObjectsPosition[obj.objectId].angle
            ? frameDetails.imageAllObjectsPosition[obj.objectId].angle
            : obj.angle
        });
        obj.setCoords();
        this.canvas.renderAll();
        this.applyShapeTransformationsWrtImg(null, 'setImageObjectPositions');
        if (uploadCanvasThumbnail) {
          let timeout = setTimeout(() => {
            console.log("uploading Thumbnail");
            this.generateBlobAndUploadThumbnail()
          }, 100);
          this.intervals.push(timeout);
        }
      };
    }
  }

  eraseOnCanvas = () => {
    this.handleChangeAnnotation({
      value: ANNOTATIONS.ERASER.NAME,
      color: ANNOTATIONS.ERASER.COLOR,
      width: ANNOTATIONS.ERASER.WIDTH
    });
  };

  onFitScreenClick = () => {
    // TODO: remove this after we handle this on pdftron
    // set websocket message to broadcast fit screen
    if (this.props.userRole === USER_ROLES.AGENT) this.props.setFitScreen(true);
    // set isFitScreen flag
    this.props.setFitScreenFlag(false);
  };

  handleFloatedAnnotation = (message, messageObject) => {
    messageObject.data.sessionKey = this.props.sessionKey, this.props.sendWebsocketMessage(message, messageObject)
  }

  showFloatedAnnotationBar = () => {
    this.setState(prevState => ({
      showGroupedAnnotations: !prevState.showGroupedAnnotations
    }))
  }

  //Close snapshot
  snapShotModelClose = () => {
    headerAction.setShowSnapshot(false);
  };

  handleFloatedAnnotationWSMessage = () => {
    const {
      ADD_ANNOTATION
    } = socketMessage.subCategories.NOTIFICATION;
    const message = {
      data: {
        category: socketMessage.category.NOTIFICATION,
        action: ADD_ANNOTATION,
        annotation: ANNOTATIONS.FREE_HAND.NAME
      }
    };
    this.handleFloatedAnnotation(socketMessage.events.MESSAGE, message);
  }

  isCanvasBlank = () => {
    if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
      return !this.props.activeCanvasData.canvasData[this.props.activeCanvasData.activePage] ||
        _.isEmpty(this.props.activeCanvasData.canvasData[this.props.activeCanvasData.activePage])
        ? true : false
    } else {
      return this.props.activeCanvasData.canvasType === CANVAS_TYPES.REGULAR
        && (!this.props.activeCanvasData.canvasData ||
          _.isEmpty(this.props.activeCanvasData.canvasData)) ? true : false
    }
  }

  renderPdfOnCanvas = file => {
    this.sendDownloadToCanvasMessage(CANVAS_TYPES.PDF);
    console.log("pdf loading issue: render pdf on canvas");
    this.setState({
      spinnerVisibility: true,
      popoverOpenInkLock: false,
      currentPdfImagePage: -1
    });
    console.log("uploading pdf in renderPdfOnCanvas");
    this.props.uploadFile(file).then(fileUrl => {
      this.props.addCanvas(CANVAS_TYPES.PDF);
      this.props.hideSpinner();
      this.props.hideSpinnerForCanvasList();
    }).catch(error => {
      this.sendDownloadToCanvasMessage(CANVAS_TYPES.PDF, false);
      console.log('hide spinner in renderPdfOnCanvas catch block');
      this.props.hideSpinner();
      this.props.hideSpinnerForCanvasList();
    });
  };

  generateBlobAndUploadThumbnail = (callback) => {
    // TODO: Remove below comment once testing is done - Display file name instead of thumbnail in canvas list for pdf
    // if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
    //   // getPdfThumbnailBlob(this.props.activeCanvasData.activePage).then((blob) => {
    //   //   this.props.uploadThumbnail(blob, callback);
    //   // }).catch((error) => {
    //   //   console.warn(error);
    //   // });
    // } else {
    //   let blob = canvasToBlob(this.canvas, '#FFFFFF', this.isCanvasBlank());
    //   this.props.uploadThumbnail(blob, callback);
    // }

    if (this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
      let blob = canvasToBlob(this.canvas, '#FFFFFF', this.isCanvasBlank());
      this.props.uploadThumbnail(blob, callback);
    }
  }

  getPdfListFromCloud = (limit = 10, offset = 0, annotationCallback) => {
    this.props.showSpinnerForCanvasList();
    this.props.showSpinner();
    this.props.getPdfListFromCloud(limit, offset, responseData => {
      this.props.hideSpinner();
      this.props.hideSpinnerForCanvasList();
      annotationCallback && annotationCallback(responseData);
    });
  }

  handlePDFTronError = () => {
    console.log("Handle PDFTron Error");
    this.props.hideSpinner();
  }
  
  renderCloudPdfOnCanvas = request => {
    this.sendDownloadToCanvasMessage(CANVAS_TYPES.PDF);
    this.props.showSpinner();
    this.props.showSpinnerForCanvasList();
    this.props.getCloudFileUrl(request, fileUrl => {
      this.props.hideSpinner();
      this.props.hideSpinnerForCanvasList();
      if(fileUrl) {
        this.props.addCanvas(CANVAS_TYPES.PDF);
      } else {
        this.sendDownloadToCanvasMessage(CANVAS_TYPES.PDF, false);
      }
    });
  };

  sendDownloadToCanvasMessage = (canvasType, isDownloading = true) => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DOWNLOADING_TO_CANVAS,
        canvasType,
        sessionKey: this.props.sessionKey,
        isDownloading
      }
    };
    
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  render() {
    let isAudioVideoInFocus = this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO &&
      (this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO ||
        this.props.webRtcPermissionRequest === mediaType.AUDIO);
    let whiteBoardDivHeight = this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF && isMobile()
      ? " height100"
      : "";

    let showSnapshotContainer = (this.state.showSnapshotModal &&  
      this.props.organiser === this.props.uniqueId) ||
        (this.props.organiser !== this.props.uniqueId &&
          this.props.isSnapshotRunning === true)
    let heightForSnapshot = showSnapshotContainer
      ? isMobile() && this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF ? ' height100' : ' fullViewDiv'
      : isIE ? '' : ' height100'

    return (
      <div id="whiteboardContainer">
        <Row className={`whiteboard noMargin ${isAudioVideoInFocus ? "alignContainer" : ""}`}>
          <Col
            xl="12"
            lg={"12"}
            md="12"
            sm="12"
            xs="12"
            className="noPadding"
            id="whiteboardArea"
          >
            {/* <Spinner
              showSpinner={
                this.props.spinnerVisibility ||
                this.props.spinnerVisibilityCanvasList
              }
            /> */}
            <div
              id="canvasSizer"
              className={isMobileOnly
                ? "canvasSizer heightFitContent displayFlex flexDirectionColumn justifyContentFlexCenter alignContainer"
                : "canvasSizer displayFlex flexDirectionColumn justifyContentFlexCenter"}>
              <div
                className={
                  // isAudioVideoInFocus &&
                  //   this.props.showAudioVideoComponent
                  `${whiteBoardDivHeight}
                  ${this.props.componentInFocus != COMPONENT_IN_FOCUS.CANVAS ? "darkGrayCanvasDiv" : "whiteCanvasDiv"}
                  ${this.props.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE ? "ml-2" : ""}`
                }>
                {/* {isAudioVideoInFocus &&
                  this.props.showAudioVideoComponent && */}
                {this.props.componentInFocus != COMPONENT_IN_FOCUS.CANVAS &&
                  <div className="setHeight py-1" >
                    <CardImg
                      src={ic_canvas_small}
                      className="iconCanvas"
                    />
                    <label className="title whiteTitle" >
                      {getMessage("CANVAS")}
                    </label>
                    {isDesktopAndTabletIpadPortrait() &&
                    <>
                      <CardImg
                        src={ic_swap}
                        id="videoSwap" 
                        className="iconSwap"
                        onClick={() => { this.props.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS) }}
                      />
                      <CustomTooltip 
                        tooltipBoundariesElement={this.tooltipBoundaryElement}
                        tooltipText={getMessage("SWAP")} 
                        tooltipId="videoSwap" 
                      />
                    </>
                      
                    }
                  </div>}
                <div
                  className={
                    // isAudioVideoInFocus &&
                    //   this.props.showAudioVideoComponent
                    (`${this.props.componentInFocus != COMPONENT_IN_FOCUS.CANVAS
                      ? "setCanvasBorder"
                      : "w-100"} ${heightForSnapshot}`)
                  }
                  style={isMobileDevice || isAudioVideoInFocus
                    ? { minHeight: 0 }
                    : { minHeight: this.state.canvasHeight }}
                >
                  {
                    // screenshare presenting text
                    this.props.screenSharePresenterName
                      && !isAudioVideoInFocus
                      && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
                      ? <div className="pl-2 d-flex align-items-center screenShareTitle">
                        <CardImg src={ic_share_big_blue} className="iconScreenShare" />
                        <span className="screenSharePresenterName">
                          <span className="presenterName">{this.props.screenSharePresenterName}</span>
                          <span className="presentingText">{getMessage("PRESENTING", { userName: "" })}</span>
                        </span>
                      </div>
                      : ""
                  }  
                 
                  {this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF && this.props.activeCanvasData.fileUrl &&
                    <PDFTronErrorHandler handleError={this.handlePDFTronError}>
                      {/* <PdfTron
                        language={this.props.language}
                        sendAddObjectMessage={this.sendAddObjectMessage}
                        sendModifyObjectMessage={this.sendModifyObjectMessage}
                        sendUpdateObjectMessage={this.sendUpdateObjectMessage}
                        sendDeleteObjectMessage={this.sendDeleteObjectMessage}
                        generateBlobAndUploadThumbnail={this.generateBlobAndUploadThumbnail}
                        changePdfPage={this.props.changePdfPage}
                        uniqueId={this.props.uniqueId}
                        organiser={this.props.organiser}
                        isPresenter={this.props.isPresenter}
                        componentInFocus={this.props.componentInFocus}
                        showAudioVideoComponent={this.props.showAudioVideoComponent}
                        activeCanvasData={this.props.activeCanvasData}
                        hideSpinner={this.props.hideSpinner}
                        showSpinner={this.props.showSpinner}
                        isDownloadModuleSelected={this.props.isDownloadModuleSelected}
                        allowEdit={this.props.allowEdit}
                        pdftronKey={this.props.pdftronKey}
                      /> */}
                      <PDFJS
                        fileUrl={this.props.activeCanvasData.fileUrl}
                        hideSpinner={this.props.hideSpinner}
                        componentInFocus={this.props.componentInFocus}
                      />
                    </PDFTronErrorHandler>}
                  {/* } */}
                  {this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
                    <div
                      className={
                        this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS
                          ? "canvasElement"
                          : "canvasElement pointerEventsNone"
                      }
                      id="canvasElement">
                      <canvas id="loookitCanvas">
                        {getMessage("CANVAS_NOT_SUPPORTED")}
                      </canvas>
                    </div>
                  }
                </div>
              </div>
              <div id="annotationBarContainer" className="annotation">
                {this.state.showGroupedAnnotations &&
                  this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
                  this.props.activeCanvasData.canvasType !== CANVAS_TYPES.SEARCH_RESULTS &&
                  this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS &&
                  <FloatedAnnotationBar
                    handleFloatedAnnotation={this.handleFloatedAnnotation}
                    handleChangeAnnotation={this.handleChangeAnnotation}
                    handleChangeFontFamily={this.handleChangeFontFamily}
                    handleChangeFontSize={this.handleChangeFontSize}
                    selectedFontSize={this.state.selectedFontSize}
                    selectedFontFamily={this.state.selectedFontFamily}
                    setDefaultFontInfo={this.setDefaultFontInfo}
                    eraseOnCanvas={this.eraseOnCanvas}
                    selectedAnnotation={this.props.selectedAnnotation}
                    clearCanvas={this.clearCanvas}
                    disableClearCanvas={isCanvasBlank(
                      this.props.activeCanvasData.canvasData, 
                      this.props.activeCanvasData.canvasType)
                    }
                    setOrRevokeActiveAnnotationDetails={this.props.setOrRevokeActiveAnnotationDetails}
                    selectedAnnotationByAgent={this.props.selectedAnnotationByAgent}
                    language={this.props.language}
                    isLandscape = {this.props.isLandscape}
                  />}

                {this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS &&                
                 !this.props.showCustomerCoBrowse &&
                  <AnnotationBar
                    handleChangeColor={this.handleChangeColor}
                    handleChangeAnnotation={this.handleChangeAnnotation}
                    undo={() => {
                      // Uncomment: to delete active text object
                      // this.canvas.discardActiveObject();
                      this.onUndo();
                    }}
                    redo={() => {
                      this.onRedo();
                    }}

                    undoEnabled={(this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF 
                      && this.state.actionObject && this.state.actionObject.length > 0) ||
                      (this.state.actionObject &&
                        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
                        this.state.actionObject[this.props.activeCanvasData.activePage] &&
                        this.state.actionObject[this.props.activeCanvasData.activePage][
                        this.state.actionObject[this.props.activeCanvasData.activePage].length - 1
                        ])}
                    redoEnabled={(this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
                      && this.state.redoObject && this.state.redoObject.length > 0) ||
                      (this.state.redoObject &&
                        this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF &&
                        this.state.redoObject[this.props.activeCanvasData.activePage] &&
                        this.state.redoObject[this.props.activeCanvasData.activePage][
                        this.state.redoObject[this.props.activeCanvasData.activePage].length - 1
                        ])}
                    clearCanvas={this.clearCanvas}
                    activeCanvasData={this.props.activeCanvasData}
                    downloadCanvas={this.downloadCanvas}
                    selectedAnnotation={this.props.selectedAnnotation}
                    uploadFile={this.props.uploadFile}
                    userRole={this.props.userRole}
                    setDefaultFontInfo={this.setDefaultFontInfo}
                    selectedColor={this.state.selectedColor}
                    renderPdfOnCanvas={this.renderPdfOnCanvas}
                    renderImageOnCanvas={this.renderImageOnCanvas}
                    addCanvas={this.props.addCanvas}
                    isPresenter={this.props.isPresenter}
                    allowEdit={this.props.allowEdit}
                    canvasType={this.props.activeCanvasData.canvasType}
                    modules={this.props.modules}
                    organiser={this.props.organiser}
                    showFloatedAnnotationBar={this.showFloatedAnnotationBar}
                    showGroupedAnnotations={this.state.showGroupedAnnotations}
                    selectedAnnotationByAgent={this.props.selectedAnnotationByAgent}
                    isCanvasDownloadAllowed={this.props.isCanvasDownloadAllowed}
                    disableClearCanvas={isCanvasBlank(
                      this.props.activeCanvasData.canvasData, 
                      this.props.activeCanvasData.canvasType)
                    }
                    handleFloatedAnnotation={this.handleFloatedAnnotation}
                    showSpinner={this.props.showSpinner}
                    showAudioVideoComponent={this.props.showAudioVideoComponent}
                    getPdfListFromCloud={this.getPdfListFromCloud}
                    renderCloudPdfOnCanvas={this.renderCloudPdfOnCanvas}
                    isOrganizationTemplateEnabled={this.props.isOrganizationTemplateEnabled}
                    allowUpload={this.props.allowUpload}
                    showSnapshotContainer={showSnapshotContainer}
                  />
                }
              </div>
            </div>
            <div id="pdfcanvascontainer">
              <canvas id="pdfcanvas">
                {getMessage("CANVAS_NOT_SUPPORTED")}
              </canvas>
            </div>
            {showSnapshotContainer ? (
              <SnapshotContainer
                snapShotModelClose={this.snapShotModelClose}
                addSnapshotImage={this.addImage}
                uploadFile={this.props.uploadFile}
                addCanvas={(type, callback, resolution) => {
                  // this.props.removeSpotLight();
                  // this.props.removePdfHighLight();
                  this.props.addCanvas(type, callback, resolution);
                }}
                showSpinner={this.props.showSpinner}
                hideSpinner={this.props.hideSpinner}
                uniqueId={this.props.uniqueId}
                organiser={this.props.organiser}
                sendDownloadToCanvasMessage={this.sendDownloadToCanvasMessage}
                handleChangeAnnotation={this.handleChangeAnnotation}
                selectedAnnotation={this.props.selectedAnnotation}
                userRole={this.props.userRole}
                setSnapshotSearchResult={this.props.setSnapshotSearchResult}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}
export default withOrientationChange(Whiteboard);
