import isOnline from "is-online";

// Constants
import { API_RESPONSE_CODE } from "UTILS/axios.init";
import { errorStrings, ERROR, maxPdfFileSize, CUSTOMER_CO_BROWSE_ERROR } from "UTILS/Constants";
import { getModulesNotAllowedErrorMessage } from "./Utility";

import { getMessage } from "CONFIG/i18n";

// Error look-up function for error returned from APIs
export function getAPIErrorInfo(errorCode = null, errorObject) {
  console.log("navigator.onLine:", navigator.onLine);
  isOnline().then((online) => {
    console.log("is-online:", online);
  });

  if (errorCode === ERROR.ERR_NETWORK) {
    return {
      status: ERROR.NETWORK_ERROR,
      message: getMessage(ERROR.NETWORK_ERROR),
      code: ERROR.NETWORK_ERROR
    };
  }

  let errorInfo = {};
  if (errorObject) {
    errorInfo.status = errorObject.status;
    switch (errorInfo.status) {
      case API_RESPONSE_CODE.BAD_REQUEST:
        errorInfo = getErrorInfoFromCode(errorObject)
        break;
      case API_RESPONSE_CODE.UNAUTHORIZED_USER:
        errorInfo.message = getMessage(errorObject.data.error.code);
        errorInfo.code = errorObject.data.error.code;  
        break;
      case API_RESPONSE_CODE.FORBIDDEN:
        errorInfo.message = getMessage(errorObject.data.error.code);
        errorInfo.code = errorObject.data.error.code;  
        break;
      case API_RESPONSE_CODE.INTERNAL_SERVER_ERROR:
        errorInfo.message = getMessage(errorObject.data.error.code);
        errorInfo.code = errorObject.data.error.code;  
        break;
      case API_RESPONSE_CODE.NOT_FOUND:
        errorInfo.message = getMessage(errorStrings.ResourceNotFound);
        errorInfo.code = errorObject.data.error.code;   
        break;
      case API_RESPONSE_CODE.PAYLOAD_TOO_LARGE:
        errorInfo.message = getMessage(ERROR.FILE_SIZE_EXCEEDS_LIMIT , {size:Math.round((maxPdfFileSize/1000000))});
        errorInfo.code = ERROR.FILE_SIZE_EXCEEDS_LIMIT;
        break;
      case API_RESPONSE_CODE.SERVICE_UNAVAILABLE:
        errorInfo.message = getMessage(ERROR.SERVER_UNREACHABLE_ERROR);
        errorInfo.code = ERROR.SERVER_UNREACHABLE_ERROR;
        break;
      case API_RESPONSE_CODE.BAD_GATEWAY:
        errorInfo.message = getMessage(ERROR.SERVER_UNREACHABLE_ERROR);
        errorInfo.code = ERROR.SERVER_UNREACHABLE_ERROR;
        break;
      default:
        errorInfo.message = getMessage(ERROR.INTERNAL_SERVER_ERROR);
        errorInfo.code = ERROR.INTERNAL_SERVER_ERROR;
    }

    return errorInfo;
    
  } else {
    if(errorCode === ERROR.ECONNABORTED) {
      errorInfo.message = getMessage(ERROR.REQUEST_TIMEOUT_ERROR);
      errorInfo.code = ERROR.REQUEST_TIMEOUT_ERROR;   
      return errorInfo;
    }
    return {
      status: ERROR.NETWORK_ERROR,
      message: getMessage(ERROR.NETWORK_ERROR),
      code: ERROR.NETWORK_ERROR
    };
  }
}

export function getCoBrowseAPIErrorInfo(errorObject, errorCode) {
  console.log("navigator.onLine:", navigator.onLine);
  isOnline().then((online) => {
    console.log("is-online:", online);
  });

  if (errorCode === ERROR.ERR_NETWORK) {
    return {
      status: ERROR.NETWORK_ERROR,
      message: getMessage(ERROR.NETWORK_ERROR),
      code: ERROR.NETWORK_ERROR
    };
  }
  
  let errorInfo = {};
  if(errorObject) {
    errorInfo.status = errorObject.status;
    switch (errorInfo.status) {
      case API_RESPONSE_CODE.BAD_REQUEST:
        errorInfo.message = errorObject.data.message;
        errorInfo.code = CUSTOMER_CO_BROWSE_ERROR[errorObject.data.code];        
        break;
      default:
        errorInfo.message = getMessage(ERROR.INTERNAL_SERVER_ERROR);
        errorInfo.code = ERROR.INTERNAL_SERVER_ERROR;
    }
    return errorInfo;
  } else {
    if(errorCode === ERROR.ECONNABORTED) {
      errorInfo.message = getMessage(ERROR.REQUEST_TIMEOUT_ERROR);
      errorInfo.code = ERROR.REQUEST_TIMEOUT_ERROR;   
      return errorInfo;
    }
    return {
      status: ERROR.NETWORK_ERROR,
      message: getMessage(ERROR.NETWORK_ERROR),
      code: ERROR.NETWORK_ERROR
    };
  }
}

function getErrorInfoFromCode (errorObject) {
  const errorCode = errorObject.data.error.code
  let errorInfo = {
    code: errorCode,
    errDetails: errorObject.data.error.errDetails,
  };
  switch (errorCode) {
    case ERROR.MODULES_NOT_ALLOWED:
      errorInfo.message = getModulesNotAllowedErrorMessage(errorObject.data.error.errDetails);
      break;
    case ERROR.LOGGING_LEVEL_NOT_ALLOWED:
      errorInfo.message = getMessage(errorCode, { loggingLevel: getMessage(errorObject.data.error.errDetails) });
      break;
    default:
      errorInfo.message = getMessage(errorCode);
      break;
  }
  return errorInfo;
}
