import React, { Component } from "react";
import { getMessage, changeLanguage } from "CONFIG/i18n";
import { isIE } from "react-device-detect";
import smoothscroll from 'smoothscroll-polyfill';

import moment from 'moment';
import 'moment/min/locales';

//Daterangepicker
import DateTime from 'react-datetime';

//components
import Spinner from "COMPONENTS/Spinner/Spinner";
import TimePicker from "COMPONENTS/CommonComponents/TimePicker/TimePicker";
import ModalWithIcon from "COMPONENTS/CommonComponents/ModalWithIcon/ModalWithIcon";

import { getStringWithoutExtraSpaces } from "../../../Utils/Utility";
//Stylesheet
import "./ScheduleSession.less"
import "react-datetime/css/react-datetime.css";

import * as rxjs from "rxjs";
import _ from "lodash";

import {
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";

//ASSETS
import ic_success_modal from "ASSETS/Images/ic_session_scheduled_popup.svg";
import ic_failed_modal from "ASSETS/Images/ic_session_scheduled_popup_Failed.svg";
import icBar from "ASSETS/Images/ic_bar.svg"
import icBack from "ASSETS/Images/ic_backarrow.svg";
import icCalendar from "ASSETS/Images/ic_calendar.svg"

//Utility
import { ERROR, SESSION_TYPE, MODAL_TYPE, LANGUAGE, DATE_FORMAT, KEY_CODE_ENTER } from "UTILS/Constants";
import { checkEmpty, separatePhoneNumberAndEmails, getPhoneNumberWithCountryCode, getValidInviteesInput } from "UTILS/Utility";
import { validateMobileNumber, validateInvitees } from "UTILS/regexValidations";

//Actions
import { isEmailValid } from "../../../Utils/Utility";

class ScheduleSession extends Component {
  constructor(props) {
    super(props);
    this.getInvitees = new rxjs.Subject();
    this.sessionProceed = new rxjs.Subject();
    const editSessionData = this.props.editSessionData;
    this.state = {
      sessionKey: editSessionData? editSessionData.sessionKey : "",
      sessionTitle:  editSessionData? editSessionData.sessionName : "",
      errorMessage: "",
      errorCode: "",
      inviteeErrorMessage: "",
      inviteeErrorCode: "",
      inviteeInvalid: false,
      selectedEmail: "",
      selectedPhone: "",
      inviteesList: [],
      currentSessionInvitees: this.props.currentSessionInvitees,
      invitees: editSessionData && editSessionData.invitees? editSessionData.invitees.join(',') : "",
      scheduleDate : editSessionData? moment(editSessionData.scheduledStartTime) : moment(),
      startTime : editSessionData? moment(editSessionData.scheduledStartTime).format("HH:mm") : "",
      startTimeError : "", 
      // when end time is 00:00 setting it to 24:00
      endTime : editSessionData
        ? moment(editSessionData.scheduledEndTime).format("HH:mm") == '00:00'
          ? '24:00'
          :  moment(editSessionData.scheduledEndTime).format("HH:mm")
        : "",
      endTimeError : "",
      scheduleDateError : "",
      sessionType: editSessionData ? SESSION_TYPE.UPDATE_SCHEDULE_MEETING: SESSION_TYPE.SCHEDULE_MEETING,
      showModal: false,
      modalMessage: "",
      modalType: "",
      modalIcon: ic_success_modal,
      closeRecentEmail: true,
      closeRecentPhone: false,
      focused: null,
      dataNotUpdatedErrorMessage:"",
      spinnerVisibility: false
    };
    changeLanguage();
    this.inviteeInputRef = React.createRef()
  }
  
  handleInputChange = event => {
    this.setState({
      sessionTitle: event.target.value,
      errorMessage:"",
      dataNotUpdatedErrorMessage:""
    }); 
  };

  handleInviteeInputChange = () => event => { 
    /*
    * restrict user to enter spaces 
    * restrict user to enter more than 1 seperator 
    * replace multiple , or ,; with single ,
    * replace multiple ; or ;, with single ;
    */
    if(validateInvitees(event.target.value)) {  
      this.setState({
        invitees: getValidInviteesInput(event.target.value),
        cursorPositionStart: event.target.selectionStart-1,
        cursorPositionEnd: event.target.selectionEnd-1,
        inviteeInvalid: false,
        inviteeErrorMessage: "",
        inviteeErrorCode: "",
        dataNotUpdatedErrorMessage:""
      },()=>{
        // set the cursor of input to the previous position 
        this.inviteeInputRef.current.selectionStart = this.state.cursorPositionStart;
        this.inviteeInputRef.current.selectionEnd = this.state.cursorPositionEnd;
      });
    } else {
      this.setState({ 
        invitees: event.target.value,
        inviteeInvalid: false,
        inviteeErrorMessage: "",
        inviteeErrorCode: "",
        dataNotUpdatedErrorMessage:""
      });
    }

    this.setState({
    })
  };

  componentDidMount() {

    smoothscroll.polyfill();
 
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSessionInvitees !== this.props.currentSessionInvitees) {
      this.setState({
        currentSessionInvitees: this.props.currentSessionInvitees
      });
    }
  }

  isInputValid = () => {
    let startTime = moment(moment(this.state.scheduleDate).format('L') + " " + this.state.startTime).valueOf();
    let endTime= moment(moment(this.state.scheduleDate).format('L') + " " + this.state.endTime).valueOf();
    let currentTime= moment().valueOf();
    if (checkEmpty(this.state.sessionTitle.trim())) {
      this.setState({
        errorMessage: "ERR_MSG_SESSION_TITLE"
      });
      return false;
    }
    if(checkEmpty(this.state.scheduleDate)) {
      this.setState({scheduleDateError: "ERR_MSG_SCHEDULER_DATE"});
      return false;
    } else if(this.state.scheduleDate.diff(moment().subtract(1, 'days').startOf('day'),"days") <= 0){
      this.setState({scheduleDateError: "ERR_MSG_SCHEDULE_INVALID_DATE"});
      return false;
    } else if(currentTime >= (startTime)) {
      this.setState({startTimeError: "ERR_MSG_SCHEDULE_INVALID_TIME" });
      return false;
    } else if(currentTime >= endTime || startTime >= endTime){
      this.setState({endTimeError: "ERR_MSG_SCHEDULE_INVALID_TIME" });
      return false;
    }
    return this.validateAndGetInvitees();
  }

  validateAndGetInvitees = () => {
    if(this.state.invitees && this.state.invitees.trim().length > 0) {
      var separators = [",", ";"];
      let arrayOfInvitees = this.state.invitees.split(
        new RegExp(separators.join("|"), "g")
      );
      let allInvites = [];
      let { arrayOfEmails, arrayOfPhone } = separatePhoneNumberAndEmails(arrayOfInvitees);
      arrayOfEmails = _.uniqBy(arrayOfEmails, 'email');
      arrayOfPhone = _.uniqBy(arrayOfPhone, 'phone');
      // validate emails - starts
      for (var i = 0; i < arrayOfEmails.length; i++) {
        let email = arrayOfEmails[i].email;
        let isValidEmail = isEmailValid(email);
        if(!isValidEmail) {
          this.setState({
            inviteeInvalid: true,
            inviteeErrorMessage: getMessage("INVALID_EMAIL_PHONE"),
            inviteeErrorCode: "INVALID_EMAIL_PHONE"
          });
          this.showHideSpinner(false);
          return false;
        }
        allInvites.push(arrayOfEmails[i]);
      }   // validate emails - ends

      // validate phone no - starts
      for (var i = 0; i < arrayOfPhone.length; i++) {
        if (arrayOfPhone[i].phone) {
          let isValidPhone = validateMobileNumber(arrayOfPhone[i].phone);
          if(!isValidPhone) {
            this.setState({
              inviteeInvalid: true,
              inviteeErrorMessage: getMessage("INVALID_EMAIL_PHONE"),
              inviteeErrorCode: "INVALID_EMAIL_PHONE"
            });
            this.showHideSpinner(false);
            return false;
          }
          arrayOfPhone[i].phone = getPhoneNumberWithCountryCode(arrayOfPhone[i].phone).trim()
          allInvites.push(arrayOfPhone[i]);
        }
      }
      // validate phone no - ends
      this.setState({
        currentSessionInvitees: [...allInvites],
        selectedEmail: "",
        selectedPhone: "",
      });
    }
    return true;
  }

  showHideSpinner = showSpinner => {
    this.setState({
      spinnerVisibility: showSpinner
    })
  }

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.scheduleMeeting();
    }
  };

  handleDateChange = (date) => {
    this.setState({
      scheduleDate: date,
      scheduleDateError : ""
    });
  }

  setStartTime = (startTime) => {
    const endTime = moment(startTime, "HH:mm").isSameOrAfter(moment("23:30", "HH:mm"))
      ? "24:00"
      : moment(startTime, "HH:mm").add(window._env_.REACT_APP_SCHEDULE_SESSION_DEFAULT_INTERVAL_IN_MINS, "minute").format("HH:mm");

    this.setState({
      startTime,
      endTime,
      startTimeError: "",
      dataNotUpdatedErrorMessage:""
    });
  }

  setEndTime = (endTime) => {
    this.setState({
      endTime,
      endTimeError: "",
      dataNotUpdatedErrorMessage:""
    });
  }

  scheduleMeeting = () => {
    const { sessionKey, sessionTitle } = this.state;
    let startTime = moment(moment(this.state.scheduleDate).format('L') + " " + this.state.startTime).valueOf();
    let endTime= moment(moment(this.state.scheduleDate).format('L') + " " + this.state.endTime).valueOf();
    const sessionDetails = {
      sessionKey,
      sessionTitle:getStringWithoutExtraSpaces(sessionTitle),
      startTime,
      endTime
    }
    
    if (this.isInputValid()) {
      this.setState({
        errorMessage: ""
      }, () => {
        this.showHideSpinner(true);
        if(this.state.sessionType === SESSION_TYPE.SCHEDULE_MEETING) {
          this.props.scheduleMeeting(this.state.currentSessionInvitees, sessionDetails, 
            this.onSessionScheduleSuccessHandler, this.onSessionScheduleFailureHandler);
        } else {
          if(this.props.editSessionData.sessionKey != sessionDetails.sessionKey
            || this.props.editSessionData.sessionName != sessionDetails.sessionTitle
            || this.props.editSessionData.scheduledStartTime != sessionDetails.startTime
            || this.props.editSessionData.scheduledEndTime != sessionDetails.endTime
            || (this.state.invitees != this.props.editSessionData.invitees)) {
            this.props.updateMeeting(this.state.currentSessionInvitees, sessionDetails, 
              this.onSessionScheduleSuccessHandler, this.onSessionScheduleFailureHandler);
          } else {
            this.setState({dataNotUpdatedErrorMessage:"DETAILS_NOT_UPDATED"})
            this.showHideSpinner(false);
            // this.resetStateData();
          }
        }
      });
    }
  }

  resetStateData = () => {
    this.showHideSpinner(false);
    this.setState({ 
      sessionType: SESSION_TYPE.SCHEDULE_MEETING,
      sessionTitle: "",
      inviteesList: [],
      scheduleDate: moment(),
      currentSessionInvitees: [],
      startTime: "",
      endTime: "",
      invitees: "",
      displayInvities: false,
      endTimeError: ""
    });
  }

  onSessionScheduleSuccessHandler = (res) => {
    this.showHideSpinner(false);
    this.setState({ 
      modalType: MODAL_TYPE.SUCCESS, 
      modalMessage: this.state.sessionType === SESSION_TYPE.SCHEDULE_MEETING?
        getMessage("SCHEDULE_SUCCESS_MESSAGE") : getMessage("UPDATE_SESSION_SUCCESS_MESSAGE") , 
      modalIcon: ic_success_modal,
      sessionType: SESSION_TYPE.SCHEDULE_MEETING,
      sessionTitle: "",
      inviteesList: [],
      scheduleDate: moment(),
      currentSessionInvitees: [],
      startTime: "",
      endTime: "",
      invitees: "",
      scheduleDateError: "",
      startTimeError: "",
      endTimeError: "",
      displayInvities: false
    },()=>{
      this.setState({showModal: true})
    })
  }

  onSessionScheduleFailureHandler = (errorCode) =>{
    this.showHideSpinner(false);
    errorCode &&
    this.setState({
      modalType: MODAL_TYPE.FAILURE, 
      modalMessage: getMessage(errorCode),
      modalIcon: ic_failed_modal, 
    },()=>{
      this.setState({showModal: true})
    })
  }

  toggleModal = () => {
    this.setState({
      showModal : !this.state.showModal,
      modalType: "", 
      modalMessage: "",
      modalIcon: "",
    })
  }

  componentWillUnmount() {
    let proceedBtn = document.getElementById("proceedBtn");
    proceedBtn.disabled = false;
  }

  testEmail = () => {
    return this.state.selectedEmail.includes(',') ? this.state.selectedEmail : "";
  }

  testNumber = () => {
    let isCommaPresent = this.state.selectedPhone.includes(',');
    if(isCommaPresent) {
      return this.state.selectedPhone;
    }
    return "";
  }

  backToMenuHandle = () => {
    this.props.backToMenu();
  }
  
  focusElement = (event) => {
    //Focus added explicitly
    //WAAG-4150-IE :Cursor is placed in front of 'Enter meeting name' helper text on schedule a meeting page.
    document.getElementById(event.target.id).focus();
  }

  onDateChange = (date) => {
    this.setState({
      scheduleDate: date
    });
  }

  render() {
    const dateFormatInputProps = {
      readOnly: true,
    };

    return (
      <div className="scheduleSession">
        {/*show spinner when update , schedule and proceed request intiated*/}
        {this.state.spinnerVisibility && <Spinner showSpinner={this.state.spinnerVisibility} />}
        
        {/*modal popup for the success and failuer*/}
        <ModalWithIcon 
          show={this.state.showModal}
          modalIcon={this.state.modalIcon}
          modalType={this.state.modalType}
          modalMessage={this.state.modalMessage}
          toggleModal={this.toggleModal}
        />
        <section>
          <div className="container-fluid">
            <div className="col-xl-2 col-12 d-flex align-items-center pt-2 backBtn pl-2" >
              <img src={icBack} className="pr-2 cursorPointer" onClick={this.backToMenuHandle}/>
              <span className="cursorPointer" onClick={this.backToMenuHandle}>{getMessage("BACK")}</span>
            </div>
            <Row>
              <Col md={12} className="text-center">
                <img
                  src={icCalendar}
                  className="img-fluid session-img createSessionImage"
                  alt="Start Session"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center pb-4">
                <span className="sessionMenuTitle" lang={this.props.language}>
                  {this.state.sessionType === SESSION_TYPE.SCHEDULE_MEETING ?
                    getMessage("CREATE_MEETING_APPOINTMENT") 
                    : getMessage("UPDATE_MEETING_APPOINTMENT")}
                </span>
              </Col>
            </Row>
            <div className="d-flex justify-content-center pt-2">
              <div className="col-12 col-xl-9 px-0">
                <span className="title">{getMessage("MEETING_SETTINGS")}</span> 
                <span className="px-3 message">{getMessage("SCHEDULE_MEETING_MESSAGE")}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center pt-2 startSession">
              <div className="col-12 col-xl-9 inputForm flexDirectionColumn px-4 pt-4" id="inviteeScrollView">
                <label className="labelStyle">
                  <img src={icBar} className="mr-2 pb-2"/>
                  {getMessage("MEETING_TITLE")}
                </label>
                <InputGroup>
                  <FormControl
                    type="text"
                    id="session-name"
                    onChange={this.handleInputChange}
                    value={this.state.sessionTitle}
                    placeholder={getMessage("ENTER_SESSION_NAME_PLACEHOLDER")}
                    aria-label="Enter name"
                    aria-describedby="basic-addon2"
                    className="formControlName rounded-pill"
                    maxLength={25}                    
                    onKeyPress={this.enterPressed}
                    autoFocus={isIE ? false : true}
                    onClick={isIE ? this.focusElement :false}
                  />
                </InputGroup>
                <div className="errors autoHeight">{getMessage(this.state.errorMessage)}</div>
                <div className="row px-3 mt-2">
                  <label className="labelStyle">
                    <img src={icBar} className="pr-2"/>
                    {getMessage("SCHEDULE_MESSAGE")}
                  </label>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 iconContainer" onClick={(e) => this.setState({ focused:true })}>
                    <div className="datePicker">
                      <DateTime
                        id="scheduleDate" 
                        className="reactDateTimePicker"
                        dateFormat={DATE_FORMAT} 
                        onChange={(value) => this.onDateChange(value)}
                        value={this.state.scheduleDate}
                        closeOnSelect
                        closeOnClickOutside
                        inputProps={dateFormatInputProps}
                        timeFormat={false}
                        isValidDate={(date)=>{return date.isAfter(moment()) || date.isSame(moment(),'day')}}
                        locale={this.props.language === LANGUAGE.JA.name ? LANGUAGE.JA.name : LANGUAGE.EN.name}
                      />
                      <div className="errors autoHeight">{getMessage(this.state.scheduleDateError)}</div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-5 col-xs-5 timePickerDiv">
                    <TimePicker
                      setTime={this.setStartTime}
                      value={this.state.startTime}
                      placeholder={getMessage("START_TIME")}
                      onKeyPress={this.enterPressed}
                    />
                    <div className="errors autoHeight">{getMessage(this.state.startTimeError)}</div>
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-2 col-xs-2  text-center pt-2 justify-content-center labelStyle totext">
                    {getMessage("~")}
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-5 col-xs-5 timePickerDiv">
                    <TimePicker
                      setTime={this.setEndTime}
                      value={this.state.endTime}
                      placeholder={getMessage("END_TIME")}
                      onKeyPress={this.enterPressed}
                    />
                    <div className="errors autoHeight pb-2">{getMessage(this.state.endTimeError)}</div>
                  </div>
                </div>
              </div>
            </div> 

            <div className="d-flex justify-content-center">
              <div className="col-12 col-xl-9 px-0">
                <span className="title">{getMessage("HOW_TO_CONTACT_CUSTOMERS")}</span> 
                <span className="px-3 message">{getMessage("PLEASE_CHOOSE_ANY_GUIDANCE_METHOD")}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center startSession">           
              <div className="col-12 col-xl-9 inputForm flexDirectionColumn p-4" id="inviteeScrollView">
                <input
                  type="text"
                  id="invitee"
                  ref={this.inviteeInputRef}
                  className="formControlName rounded-pill w-100 form-control"
                  onChange={this.handleInviteeInputChange()}
                  value={this.state.invitees}
                  placeholder= {getMessage("INVITE_INPUT_PLACEHOLDER")}
                  wrapperstyle={{ width: "100%" }}
                  onClick={isIE ? this.focusElement : false}
                  onKeyDown={this.enterPressed}
                />
                <Row className="d-flex justify-content-center errors my-0 py-2">
                  {this.state.inviteeErrorCode === "PROPS"
                    ? this.state.inviteeErrorMessage
                    : getMessage(this.state.inviteeErrorCode)}
                </Row>
                <div className="d-flex justify-content-center">
                  <Col sm={6} xl={4}>
                    <div className="errors autoHeight">{getMessage(this.state.dataNotUpdatedErrorMessage)}</div>
                    <button
                      id="proceedBtn"
                      className="startBtn btn shadow-none rounded-pill w-100  py-2"
                      onClick={
                        this.scheduleMeeting}
                    >
                      {getMessage("SCHEDULE_MEETING_BUTTON")}
                    </button> 
                  </Col>
                </div>
              </div>
            </div> 
          </div>
        </section>
      </div>
    );
  }
}

export default ScheduleSession;
