import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";
import { selectSessionKey } from "COMPONENTS/MainLayout/selectors";

export class CreateSessionAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  //never make this function as arrow function
  createSessionAction(sessionName, passcode, language, closeActiveSession) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: {
          sessionName:
            sessionName || this.getState().SessionReducer.sessionTitle,
          passcode,
          language,
          closeActiveSession,
        },
        url: API_URL.CREATE_USER_SESSION
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.CREATE_SESSION_SUCCESS,
            userSessionDetails: response.data.data
          });
          resolve(response);
        },
        error => {
          reject(error);
          this.dispatch({
            type: ACTIONS.CREATE_SESSION_SUCCESS,
            userSessionDetails: {sessionKey: ""}
          });
        }
      );
    });
  }

  scheduleSessionAction(sessionDetails) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: sessionDetails,
        url: API_URL.SCHEDULE_SESSION
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SCHEDULE_SESSION_SUCCESS,
            userSessionDetails: response.data.data
          });
          resolve(response);
        },
        error => {
          reject(error);
          this.dispatch({
            type: ACTIONS.SCHEDULE_SESSION_SUCCESS,
            userSessionDetails: null
          });
        }
      );
    });
  }

  cancelSessionAction(sessionKey, language){
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url: `${API_URL.SCHEDULE_SESSION}/${sessionKey}`,
        requestBody: {language}
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.DELETE_SESSION_SUCCESS,
            userSessionDetails: response.data.data
          });
          resolve(response);
        },
        error => {
          reject(error);
          this.dispatch({
            type: ACTIONS.DELETE_SESSION_SUCCESS,
            userSessionDetails: null
          });
        }
      );
    });
  }

  getSessionListActions(filter) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: filter,
        url: API_URL.GET_SCHEDULE_SESSION_LIST
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SET_SESSION_SCHEDULE_LIST,
            value : response.data.data.sessionsList
          })
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getQRCodeSessionList(status) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          status: status
        },
        url: API_URL.GET_QR_CODE_SESSION_LIST,
      }).then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateSessionAction = (sessionKey, sessionDetails) =>{
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        requestBody: sessionDetails,
        url: `${API_URL.SCHEDULE_SESSION}/${sessionKey}`
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.SCHEDULE_SESSION_SUCCESS,
            userSessionDetails: response.data.data
          });
          resolve(response);
        },
        error => {
          reject(error);
          this.dispatch({
            type: ACTIONS.SCHEDULE_SESSION_SUCCESS,
            userSessionDetails: null
          });
        }
      );
    });
  }

  joinSessionAction(sessionKey, uriIdentifier, userName = "", reJoinFlag = false, endUserToken = "", closeActiveSession = false) {
    let uriWithoutSlash = uriIdentifier ? uriIdentifier.slice(1) : undefined; // Remove first Slash in uriIdentifier
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: {
          sessionKey: sessionKey
            ? sessionKey
            : selectSessionKey(this.getState()),
          token: endUserToken ? endUserToken : undefined,
          uriIdentifier: uriWithoutSlash,
          closeActiveSession: closeActiveSession,
          userName: userName
        },
        url: API_URL.JOIN_SESSION
      }).then(
        response => {

          this.dispatch({
            type: ACTIONS.SET_TURN_SERVER_DATA,
            turnServer: response.data.data.turnServer
          });
          this.dispatch({
            type: ACTIONS.JOIN_SESSION,
            sessionDetails: response.data.data
          });
          this.dispatch({
            type: ACTIONS.SET_JOINING_USER_DETAILS,
            joiningUserDetails: response.data.data            
          });
          this.dispatch({
            type: ACTIONS.SET_SESSION_JOINED_FLAG,
            isSessionJoined: true
          });
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            showLogoOnCanvasStatus: response.data.data.showLogoOnCanvas
          });
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            showChatPopup: response.data.data.showChatPopup === true ? true : false
          })
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            isSnapshotScanCodeEnabled: response.data.data.isSnapshotScanCodeEnabled
          })
          this.dispatch({
            type: ACTIONS.APP_EMULATION.SET_APP_EMULATION_SETTINGS,
            showDeviceInfo: response.data.data.appEmulationSettings?.showDeviceInfo,
          }) 
          if(response.data.data.config) {
            this.dispatch({
              type: ACTIONS.SET_CONFIG,
              config: response.data.data.config,
            });
          }
          this.dispatch({
            type: ACTIONS.CHAT.SET_CHAT_GPT_VALUE,
            isChatGPTEnabled: response.data.data.customerSettings?.chatgptEnabled           
          });
          this.dispatch({
            type: ACTIONS.ALLOW_UPLOAD,
            allowUpload: response.data.data.customerSettings?.allowUpload           
          });
          resolve(response);
        },
        error => {
          reject(error);
          if (!reJoinFlag) {
            this.dispatch({
              type: ACTIONS.JOIN_SESSION,
              sessionDetails: {sessionKey:'',token:'',modules:[]}
            });
          }
        }
      );
    });
  }
  viewInviteesAction(searchKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          searchKey: searchKey
        },
        url: API_URL.VIEW_INVITEES
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  updateWebRtcPermissionReply(value) {
    this.dispatch({
      type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION,
      webRtcPermissionReply: value
    });
  }
  updateWebRtcPermissionRequest(media) {
    this.dispatch({
      type: ACTIONS.SET_WEBRTC_REQUEST_PERMISSION,
      webRtcPermissionRequest: media
    });
  }
  updateSnapshotPermissionStatus(value) {
    this.dispatch({
      type: ACTIONS.SET_SNASHOT_PERMISSION_STATUS,
      snapshotPermissionStatus: value
    });
  }
  updateSnapshoteeId(uniqueId) {
    this.dispatch({
      type: ACTIONS.SET_SNAPSHOTEE_ID,
      snapshoteeId: uniqueId
    });
  }

  updateEndSessionForcefullyFlag(sessionClosedForcefully){
    this.dispatch({
      type: ACTIONS.SET_END_SESSION_FORCEFULLY_FLAG,
      sessionClosedForcefully: sessionClosedForcefully,
    });
  }

  updateSnapshotPermissionReply(flag) {
    this.dispatch({
      type: ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY,
      snapshotPermissionReply: flag
    });
  }
  setPeerDisposed(isDisposed) {
    this.dispatch({
      type: ACTIONS.SET_SWITCH_CAMERA,
      isPeerDisposed: isDisposed,
    });
  }

  inviteUser(sessionKey, participants, language) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: {
          sessionKey: sessionKey,
          participants: participants,
          language
        },
        url: API_URL.INVITE_USER
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  isSessionJoinedAction(flag) {
    this.dispatch({
      type: ACTIONS.SET_SESSION_JOINED_FLAG,
      isSessionJoined: flag
    });
  }
  downloadChatMessages(sessionKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          sessionKey: sessionKey
        },
        url: API_URL.DOWNLOAD_CHAT
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  createSessionWithQRCode(qrId, selectedLanguage, userName, email, phone, info, deviceType) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: {
          qrId: qrId,
          language: selectedLanguage,
          userName: userName,
          email:  email,
          phone: phone,
          info: info,
          deviceType: deviceType,
        },
        url: API_URL.CREATE_SESSION_WITH_QR_CODE
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.CREATE_SESSION_SUCCESS,
            userSessionDetails: response.data.data
          });
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            qrCodeWaitingTime: response.data.data?.qrCodeWaitingTime
          })
          resolve(response);
        },
        error => {
          reject(error);
          this.dispatch({
            type: ACTIONS.CREATE_SESSION_SUCCESS,
            userSessionDetails: {sessionKey:'',url:''}
          });
        }
      );
    });
  }

  createQRFollowUpRequestAction(userData) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: {
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          description:  userData.description,
          fromTime: userData.fromTime,
          toTime: userData.toTime,
          sessionKey: userData.sessionKey,
          customerId: userData.customerId,
          language: userData.language,
          qrId: userData.qrId,
          info: userData.info,
          deviceType: userData.deviceType
        },
        url: API_URL.CREATE_QR_FOLLOWUP_REQUEST
      }).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  resetShowKmsConnectionStatusAction() {
    this.dispatch({
      type: ACTIONS.RESET_SHOW_KMS_CONNECTION_STATUS
    });
  }
  setComponentInFocus(componentInFocus) {
    this.dispatch({
      type: ACTIONS.SET_COMPONENT_IN_FOCUS,
      componentInFocus: componentInFocus
    });
  }

  setSaveSessionDataFlag(flag) {
    this.dispatch({
      type: ACTIONS.SET_SAVE_SESSION_DATA_FLAG,
      flag
    });
  }

  saveSessionData(sessionKey) {
    return this.callApi({
      httpMethod: HTTP_METHODS.POST,
      url: API_URL.SAVE_SESSION_DATA,
      params: {
        sessionKey
      }
    })
    .then(() => {
      this.setSaveSessionDataFlag(false);
      return null; // response doesn't contain anything meaningful
    })
  }

  setSessionType(sessionType) {
    this.dispatch({
      type: ACTIONS.SET_SESSION_TYPE,
      sessionType
    });
  }

  editSessionAction(session) {
    this.dispatch({
      type: ACTIONS.SET_EDIT_SESSION_DATA,
      session
    })
  }

  setIsSnapshotRunning(value) {
    this.dispatch({
      type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_RUNNING,
      value
    })
  }

  getSessionKeyFromPasscode(sessionDetails){
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.GET_SESSION_KEY_FROM_PASSCODE,
        requestBody: sessionDetails
      }).then(response => {
        resolve(response.data);
        this.dispatch({
          type: ACTIONS.SET_PASSCODE,
          passcode: sessionDetails.passcode
        });
      },
      error => {
        reject(error);
      }
      );
    });
  }

  getCustomerSettings(sessionKey){
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          sessionKey: sessionKey
        },
        url: API_URL.GET_CUSTOMER_SETTINGS,
      }).then(
        response => {
          resolve(response);
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            allowAnonymousParticipants: response.data.data.allowAnonymousUsers,
          }) 
        },
        error => {
          reject(error);
        }
      );
    });
  }

  endSessionAction(){
    this.dispatch({
      type: ACTIONS.SET_END_SESSION_FLAG,
      flag: true
    })
  }

  setSessionStatus(sessionStatus) {
    this.dispatch({
      type: ACTIONS.SET_SESSION_STATUS,
      sessionStatus
    })
  }

  setLanguage(language) {
    this.dispatch({
      type: ACTIONS.SET_LANGUAGE,
      language: language
    })
  }

  getQRCodeLandingPageDetails(sessionKey,agentId){
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        requestBody:{
          sessionKey:sessionKey,
          agentId:agentId
        },
        url: `${API_URL.JOIN_QR_CODE_SESSION}`,
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  setSessionInvalidFlag(flag) {
    this.dispatch({
      type: ACTIONS.SET_SESSION_INVALID,
      value: flag
    })
  }
}
