import { Map, TileLayer, Marker, Popup, LeafletConsumer } from "react-leaflet";
import React, { Component, createRef } from "react";
import { Button } from "reactstrap";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { withOrientationChange } from "react-device-detect";
import "./Location.less";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import greenIcon from 'ASSETS/Images/map/marker-icon-2x-green.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet-control-geocoder/dist/Control.Geocoder.js";

// CONSTANTS
import { socketMessage } from "WEBSOCKET/constants";

import Routing from "./Routing";
import { getMessage } from "CONFIG/i18n";
import { distanceBetweenTwoMapPoints } from "../../Utils/Utility";

class OpenMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: "",
      lng: "",
      zoom: 18,
      address: "",
      draggable: false,
      marker: {
        lat: props.lat,
        lng: props.lng
      },
      fromLat: "",
      fromLng: "",
      fromFlag: false,
      toLat: "",
      toLng: "",
      toFlag: false,
      fromUniqueId: "",
      toUniqueId: "",
      fromName: "",
      toName: "",
      markers: {},
      firstView: false
    };
    this.route = null;
    this.routeLiveLocation = null;
    this.timerId;
    this.greenIcon = new L.Icon({
      iconUrl:greenIcon,
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    // default map icon of react-leaflet
    this.defaultMapIcon = new L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
    });
    this.sendLatLong(this.props.lat, this.props.lng);
  }

  // onMapClick = e => {
  //   if (!this.props.shareLocation) {
  //     const map = this.leafletMap.leafletElement;
  //     const geocoder = L.Control.Geocoder.nominatim();
  //     geocoder.reverse(
  //       e.latlng,
  //       map.options.crs.scale(map.getZoom()),
  //       results => {
  //         if (results[0]) {
  //           this.setState({ address: results[0].name, marker: e.latlng });
  //         }
  //       }
  //     );
  //     this.sendLatLong(e.latlng.lat, e.latlng.lng);
  //   }
  // };

  refmarker = createRef();
  toggleDraggable = () => {
    this.setState({ draggable: !this.state.draggable });
  };

  updatePosition = () => {
    const marker = this.refmarker.current;
    if (marker != null) {
      this.setState({
        zoom: this.leafletMap.leafletElement.getZoom(),
        marker: marker.leafletElement.getLatLng()
      });
      this.sendLatLong(
        marker.leafletElement.getLatLng().lat,
        marker.leafletElement.getLatLng().lng
      );
    }
    if (this.props.uniqueId === this.props.organiser) {
      this.setState({ firstView: true });
    }
  };
  showPosition = position => {

    if (this.props.shareLocation 
    //For iOS, GPS on wifi changes frequently, added a check to avoid this
    //If the distance between old coordinate and new coordinate is less than 25 we don't change the locaiton on map.
      && distanceBetweenTwoMapPoints(this.state.lat, this.state.lng, position.coords.latitude, position.coords.longitude) > 25 
    ) {
      this.sendLatLong(position.coords.latitude, position.coords.longitude);
      if (this.props.uniqueId === this.props.organiser) {
        if (this.state.fromFlag && this.state.toFlag) {
          if (this.state.fromUniqueId === this.state.uniqueId) {
            if (this.routeLiveLocation) {
              let waypoints = [
                L.latLng(position.coords.latitude, position.coords.longitude),
                L.latLng(this.state.toLat, this.state.toLng)
              ];
              this.routeLiveLocation.setWaypoints(waypoints);
            }
          } else if (this.state.toUniqueId === this.state.uniqueId) {
            if (this.routeLiveLocation) {
              let waypoints = [
                L.latLng(this.state.fromLat, this.state.fromLng),
                L.latLng(position.coords.latitude, position.coords.longitude)
              ];
              this.routeLiveLocation.setWaypoints(waypoints);
            }
          }
          this.shareETA();
        }
      }
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      if (this.props.uniqueId === this.props.organiser) {
        this.setState({ firstView: true });
      }
    }
  };
  getLocation = () => {
    if (navigator.geolocation && this.props.shareLocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      // x.innerHTML = "Geolocation is not supported by this browser.";
    }
  };
  sendLatLong = (latitude, longitude) => {
    let key = this.props.uniqueId;
    let shareLiveLocation = this.props.shareLocation;
    let userName = this.props.userName;
    let address = this.state.address;
    let participantLocationData = {
      latitude,
      longitude,
      address,
      shareLiveLocation,
      userName,
      key
    };
    var message = {
      data: {
        category: socketMessage.category.LOCATION,
        action: socketMessage.subCategories.LOCATION.SHARE_LOCATION,
        sessionKey: this.props.sessionKey,
        participantLocationData: participantLocationData
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    this.props.addLocationData(participantLocationData);
  };

  componentDidMount() {
    var zoom = document.getElementsByClassName("leaflet-control-zoom-in");
    if (zoom[0] !== undefined) {
      zoom[0].title = getMessage("ZOOMIN");
    }
    zoom = document.getElementsByClassName("leaflet-control-zoom-out");
    if (zoom[0] !== undefined) {
      zoom[0].title = getMessage("ZOOMOUT");
    }
    if (this.props.markers) {
      this.setState({
        markers: this.props.markers
      });
    }
    this.timerId = setInterval(() => this.getLocation(), 2000);
    if(this.props.eta.fromUniqueId && this.props.eta.toUniqueId) { this.addExistingRoute() }
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.shareLocation !== this.props.shareLocation) {
      if (!this.props.shareLocation)
        this.sendLatLong(this.state.marker.lat, this.state.marker.lng);
    }

    //share ETA when live location is on for any user
    if (
      this.props.uniqueId === this.props.organiser &&
      _.isEqual(prevProps.markers, this.props.markers) === false
    ) {
      if (
        this.state.fromFlag &&
        this.state.toFlag &&
        this.props.markers.hasOwnProperty(this.props.eta.fromUniqueId) &&
        this.props.markers.hasOwnProperty(this.props.eta.toUniqueId)
      ) {
        var fromLat, fromLng, toLat, toLng;
        if (
          (this.props.markers[this.props.eta.fromUniqueId].shareLiveLocation ||
            this.props.shareLocation) &&
          this.props.markers[this.props.eta.toUniqueId].shareLiveLocation
        ) {
          fromLat = this.props.markers[this.props.eta.fromUniqueId].latitude;
          fromLng = this.props.markers[this.props.eta.fromUniqueId].longitude;
          toLat = this.props.markers[this.props.eta.toUniqueId].latitude;
          toLng = this.props.markers[this.props.eta.toUniqueId].longitude;
        } else if (
          this.props.markers[this.props.eta.fromUniqueId].shareLiveLocation ||
          this.props.shareLocation
        ) {
          fromLat = this.props.markers[this.props.eta.fromUniqueId].latitude;
          fromLng = this.props.markers[this.props.eta.fromUniqueId].longitude;
          toLat = this.props.eta.toParticipantData.latitude;
          toLng = this.props.eta.toParticipantData.longitude;
        } else if (
          this.props.markers[this.props.eta.toUniqueId].shareLiveLocation ||
          this.props.shareLocation
        ) {
          toLat = this.props.markers[this.props.eta.toUniqueId].latitude;
          toLng = this.props.markers[this.props.eta.toUniqueId].longitude;
          fromLat = this.props.eta.fromParticipantData.latitude;
          fromLng = this.props.eta.fromParticipantData.longitude;
        }
        if (
          (this.props.markers[this.props.eta.fromUniqueId].shareLiveLocation ||
            this.props.markers[this.props.eta.toUniqueId].shareLiveLocation ||
            this.props.shareLocation) &&
          this.routeLiveLocation
        ) {
          let waypoints = [L.latLng(fromLat, fromLng), L.latLng(toLat, toLng)];
          this.routeLiveLocation.setWaypoints(waypoints);
          this.shareETA();
          this.setState({
            fromLat: fromLat,
            fromLng: fromLng,
            toLat: toLat,
            toLng: toLng
          });
        }
      }
    }
    if (_.isEqual(prevProps.markers, this.props.markers) === false) {
      this.setState({
        markers: this.props.markers
      });
    }

    if (
      this.props.eta.toUniqueId !== "" &&
      this.props.eta.fromUniqueId !== ""
    ) {
      if (_.isEqual(prevProps.eta, this.props.eta) === false) {
        this.setState({
          fromFlag: true,
          toFlag: true,
          fromUniqueId: this.props.eta.fromUniqueId,
          toUniqueId: this.props.eta.toUniqueId,
          fromLat: this.props.eta.fromParticipantData.latitude,
          fromLng: this.props.eta.fromParticipantData.longitude,
          toLat: this.props.eta.toParticipantData.latitude,
          toLng: this.props.eta.toParticipantData.longitude,
          toName: this.props.eta.toParticipantData.userName,
          fromName: this.props.eta.fromParticipantData.userName
        });
        if (
          !this.props.markers[this.props.eta.fromUniqueId].shareLiveLocation &&
          !this.props.markers[this.props.eta.toUniqueId].shareLiveLocation &&
          !this.props.shareLocation
        ) {
          if (this.route) {
            let waypoints = [
              L.latLng(
                this.props.eta.fromParticipantData.latitude,
                this.props.eta.fromParticipantData.longitude
              ),
              L.latLng(
                this.props.eta.toParticipantData.latitude,
                this.props.eta.toParticipantData.longitude
              )
            ];
            this.route.setWaypoints(waypoints);
          }
        } else {
          //if live location is on for any user use router of Live location

          if (this.routeLiveLocation) {
            let waypoints = [
              L.latLng(
                this.props.eta.fromParticipantData.latitude,
                this.props.eta.fromParticipantData.longitude
              ),
              L.latLng(
                this.props.eta.toParticipantData.latitude,
                this.props.eta.toParticipantData.longitude
              )
            ]; // create waypoints
            this.routeLiveLocation.setWaypoints(waypoints); // set new waypoint to rouing
          }
        }
      }
    } else if (
      this.props.eta.toUniqueId === "" &&
      this.props.eta.fromUniqueId === "" &&
      _.isEqual(prevProps.eta, this.props.eta) === false
    ) {
      //when ETA is closed by presenter or any user from eta refresh page eta is closed.
      if (this.routeLiveLocation) {
        //clear router
        this.map.leafletElement.removeControl(this.routeLiveLocation);
      }
      if (this.route) {
        this.map.leafletElement.removeControl(this.route);
      }

      this.setState({
        fromLat: "",
        fromLng: "",
        fromFlag: false,
        toLat: "",
        toLng: "",
        toFlag: false,
        fromUniqueId: "",
        toUniqueId: "",
        toName: "",
        fromName: ""
      });
    }
  }
  markerClick = e => {
    //on marker click get address on that lat long
    const map = this.leafletMap.leafletElement;
    const geocoder = L.Control.Geocoder.nominatim();
    geocoder.reverse(
      e.latlng,
      map.options.crs.scale(map.getZoom()),
      results => {
        if (results[0]) {
          this.setState({ address: results[0].name });
        }
      }
    );
  };
  userMarkerClick = (e, key) => {
    let participants = _.cloneDeep(this.state.markers);
    const map = this.leafletMap.leafletElement;
    const geocoder = L.Control.Geocoder.nominatim();
    
    geocoder.reverse(
      e.latlng,
      map.options.crs.scale(map.getZoom()),
      results => {
        if (results[0]) {
          participants[key].address = results[0].name;
          this.setState({ markers: participants });
        }
      }
    );
  };

  addExistingRoute = () => {
    let from = {...this.props.eta.fromParticipantData};
    let to = {...this.props.eta.toParticipantData};
    this.setState({
      fromFlag: true,
      toFlag: true,
      fromUniqueId: this.props.eta.fromUniqueId,
      toUniqueId: this.props.eta.toUniqueId,
      fromName: from.userName,
      toName: to.userName,
      fromLat: from.latitude,
      fromLng: from.longitude,
      toLat: to.latitude,
      toLng: to.longitude,
    },() => {
      this.props.handleShareETA(true);
      this.shareRoutes();
    });
  }

  shareETA = () => {
    var wayPoints = null; // get waypoints for sharing with other users
    if (
      this.state.markers[this.state.fromUniqueId].shareLiveLocation ||
      this.state.markers[this.state.toUniqueId].shareLiveLocation ||
      this.props.shareLocation
    ) {
      if (this.routeLiveLocation)
        wayPoints = this.routeLiveLocation.getWaypoints();
    } else {
      if (this.route) wayPoints = this.route.getWaypoints();
    }
    if (wayPoints) {
      let fromParticipantData = {
        latitude: wayPoints[0].latLng.lat,
        longitude: wayPoints[0].latLng.lng,
        userName: this.state.fromName
      };
      let toParticipantData = {
        latitude: wayPoints[1].latLng.lat,
        longitude: wayPoints[1].latLng.lng,
        userName: this.state.toName
      };
      var message = {
        data: {
          category: socketMessage.category.LOCATION,
          action: socketMessage.subCategories.LOCATION.SHARE_ETA,
          sessionKey: this.props.sessionKey,
          fromUniqueId: this.state.fromUniqueId,
          toUniqueId: this.state.toUniqueId,
          fromParticipantData: fromParticipantData,
          toParticipantData: toParticipantData
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      let eta = {
        fromUniqueId: this.state.fromUniqueId,
        toUniqueId: this.state.toUniqueId,
        fromParticipantData: fromParticipantData,
        toParticipantData: toParticipantData
      };
      this.props.shareETA(eta);
    }
  };

  from = (markerPosition, key, name) => {
    if (key === this.state.toUniqueId) {
      toastr.info("ROUTE_ERROR");
      return;
    }
    this.setState(
      {
        fromLat: markerPosition[0],
        fromLng: markerPosition[1],
        fromFlag: true,
        fromUniqueId: key,
        fromName: name
      },
      () => {
        if (this.state.fromFlag && this.state.toFlag) {
          this.props.handleShareETA(true);
          this.shareETA();
        }
      }
    );
    if (this.state.fromFlag && this.state.toFlag) {
      this.props.handleShareETA(true);
      if (
        this.state.markers[this.state.fromUniqueId].shareLiveLocation ||
        this.state.markers[this.state.toUniqueId].shareLiveLocation ||
        this.props.shareLocation
      ) {
        if (this.routeLiveLocation) {
          let waypoints = [
            L.latLng(markerPosition[0], markerPosition[1]),
            L.latLng(this.state.toLat, this.state.toLng)
          ];
          this.routeLiveLocation.setWaypoints(waypoints);
        }
      } else {
        if (this.route) {
          let waypoints = [
            L.latLng(markerPosition[0], markerPosition[1]),
            L.latLng(this.state.toLat, this.state.toLng)
          ];
          this.route.setWaypoints(waypoints);
        }
      }

      this.shareETA();
    }
  };

  to = (markerPosition, key, name) => {
    if (key === this.state.fromUniqueId) {
      toastr.info("ROUTE_ERROR");
      return;
    }
    this.setState(
      {
        toLat: markerPosition[0],
        toLng: markerPosition[1],
        toFlag: true,
        toUniqueId: key,
        toName: name
      },
      () => {
        if (this.state.fromFlag && this.state.toFlag) {
          this.props.handleShareETA(true);
          this.shareETA();
        }
      }
    );
    if (this.state.fromFlag && this.state.toFlag) {
      this.props.handleShareETA(true);
      if (
        this.state.markers[this.state.fromUniqueId].shareLiveLocation ||
        this.state.markers[this.state.toUniqueId].shareLiveLocation ||
        this.props.shareLocation
      ) {
        if (this.routeLiveLocation) {
          let waypoints = [
            L.latLng(this.state.fromLat, this.state.fromLng),
            L.latLng(markerPosition[0], markerPosition[1])
          ];
          this.routeLiveLocation.setWaypoints(waypoints);
        }
      } else {
        if (this.route) {
          let waypoints = [
            L.latLng(this.state.fromLat, this.state.fromLng),
            L.latLng(markerPosition[0], markerPosition[1])
          ];
          this.route.setWaypoints(waypoints);
        }
      }
      this.shareETA();
    }
  };

  generateMarker = () => {
    var arr = [];
    let key;
    for (key in this.state.markers) {
      if (
        this.state.markers[key].hasOwnProperty("latitude") &&
        key !== this.props.uniqueId
      )
        arr.push(Object.assign(this.state.markers[key], { name: key })); // copies each property to the objCopy object
    }
    return arr.map(user => {
      let position = [user.latitude, user.longitude];
      let flag =
        this.state.fromUniqueId === user.key ||
        this.state.toUniqueId === user.key;
      return (
        !(this.state.fromFlag && this.state.toFlag && flag) && (
          <Marker
            position={position}
            onClick={e => this.userMarkerClick(e, user.key)}
            key={user.key}
          >
            <Popup>
              <span>
                <label className="fromTo">{user.userName}</label>
                <br></br>
                <label>{user.address}</label>
                {this.props.uniqueId === this.props.organiser && (
                  <div>
                    <label
                      className={this.state.fromFlag ? "fromTo" : ""}
                      onClick={() =>
                        this.from(position, user.key, user.userName)
                      }
                    >
                      {getMessage("DIRECTION_FROM")}
                    </label>
                    <br></br>
                    <label
                      className={this.state.toFlag ? "fromTo" : ""}
                      onClick={() => this.to(position, user.key, user.userName)}
                    >
                      {getMessage("DIRECTION_TO")}
                    </label>
                  </div>
                )}
              </span>
            </Popup>
          </Marker>
        )
      );
    });
  };

  routing = route => {
    if (
      !this.state.markers[this.state.fromUniqueId].shareLiveLocation &&
      !this.state.markers[this.state.toUniqueId].shareLiveLocation &&
      !this.props.shareLocation
    ) {
      this.route = route;
      if (this.routeLiveLocation)
        this.map.leafletElement.removeControl(this.routeLiveLocation);
    } else {
      if (this.route) this.map.leafletElement.removeControl(this.route);
      this.routeLiveLocation = route;
    }
  };

  shareRoutes = () => {
    const names = [this.state.fromName, this.state.toName];
    const from = [this.state.fromLat, this.state.fromLng];
    const to = [this.state.toLat, this.state.toLng];
    return this.state.markers[this.state.fromUniqueId].shareLiveLocation ||
      this.state.markers[this.state.toUniqueId].shareLiveLocation ||
      this.props.shareLocation ? (
        <Routing
          key="shareLiveLocation=true"
          presenter={this.props.presenter}
          uniqueId={this.props.uniqueId}
          routing={this.routing}
          shareETA={this.shareETA}
          map={this.map}
          from={from}
          to={to}
          removeRoutes={this.removeRoutes}
          shareLiveLocation={true}
          names={names}
          routeError={this.routeError}
          handleShareETA={this.props.handleShareETA}
          organiser={this.props.organiser}
          markerIcons={[this.greenIcon, this.defaultMapIcon]}
          graphHopperKey={this.props.graphHopperKey}
          eta={this.props.eta}
        />
      ) : (
        <Routing
          key="shareLiveLocation=false"
          presenter={this.props.presenter}
          uniqueId={this.props.uniqueId}
          routing={this.routing}
          shareETA={this.shareETA}
          map={this.map}
          from={from}
          to={to}
          removeRoutes={this.removeRoutes}
          shareLiveLocation={false}
          names={names}
          routeError={this.routeError}
          handleShareETA={this.props.handleShareETA}
          organiser={this.props.organiser}
          markerIcons={[this.greenIcon, this.defaultMapIcon]}
          graphHopperKey={this.props.graphHopperKey}
          eta={this.props.eta}
        />
      );
  };
  routeError(iError) {
    console.error("Error while finding routes: ", iError);
    var leafletContainer = document.getElementsByClassName("leaflet-routing-alternatives-container");
    if (leafletContainer !== undefined) {
      leafletContainer[0].style.display = "none"
    }
    toastr.info("ROUTING_ERROR");
    this.shareETA();
  }
  zoomChange = e => {
    this.leafletMap && this.setState({
      zoom: this.leafletMap.leafletElement.getZoom()
    });
  };
  render() {
    const position = [this.state.lat, this.state.lng];
    const markerPosition = [this.state.marker.lat, this.state.marker.lng];
    var userMarker = [];
    if (this.props.uniqueId === this.props.organiser && !this.state.firstView) {
      //for agent map view set at first end-user marker.
      if (this.state.markers) {
        let key;
        for (key in this.state.markers) {
          if (key !== this.props.uniqueId) {
            userMarker = [];
            userMarker[0] = this.state.markers[key].latitude;
            userMarker[1] = this.state.markers[key].longitude;
            break;
          }
        }
      }
    }
    return (
      <div id="userMap">
        <Map
          center={
            this.props.shareLocation
              ? position
              : !this.state.firstView &&
                this.props.uniqueId === this.props.organiser &&
                userMarker[0] !== undefined
                ? userMarker
                : markerPosition
          }
          renderer={L.canvas()}
          zoom={this.state.zoom}
          ref={m => {
            this.leafletMap = m;
            this.map = m;
          }}
          onClick={this.onMapClick}
          onzoomlevelschange={this.zoomChange}
          style={!this.props.isLandscape ? {height:window.innerHeight-135} : {height:window.innerHeight-170}}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            maxNativeZoom = {18}
            maxZoom = {22}
            noWrap = {true}
          />
          {!(
            this.state.fromFlag &&
            this.state.toFlag &&
            (this.state.fromUniqueId === this.props.uniqueId ||
              this.state.toUniqueId === this.props.uniqueId)
          ) && (
            <Marker
              draggable={!this.props.shareLocation}
              onDragend={this.updatePosition}
              ref={this.refmarker}
              icon={this.greenIcon}
              position={this.props.shareLocation ? position : markerPosition}
              ref={this.refmarker}
              onClick={this.markerClick}
            >
              <Popup minWidth={90}>
                <span>
                  <label className="fromTo">{this.props.userName}</label>{" "}
                  <br></br>
                  <label>{this.state.address}</label>
                  {this.props.uniqueId === this.props.organiser && (
                    <div>
                      <label
                        className={this.state.fromFlag ? "fromTo" : ""}
                        onClick={() =>
                          this.from(
                            markerPosition,
                            this.props.uniqueId,
                            this.props.userName
                          )
                        }
                      >
                        {getMessage("DIRECTION_FROM")}
                      </label>
                      <br></br>
                      <label
                        className={this.state.toFlag ? "fromTo" : ""}
                        onClick={() =>
                          this.to(
                            markerPosition,
                            this.props.uniqueId,
                            this.props.userName
                          )
                        }
                      >
                        {getMessage("DIRECTION_TO")}
                      </label>
                    </div>
                  )}
                </span>
              </Popup>
            </Marker>
          )}
          {this.state.markers && this.generateMarker()}
          {this.state.fromFlag && this.state.toFlag && this.shareRoutes()}
        </Map>
      </div>
    );
  }
}

export default withOrientationChange(OpenMap);
