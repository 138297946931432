import React, { Component } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { getMessage } from "CONFIG/i18n";
import Spinner from "COMPONENTS/Spinner/Spinner";
import EndSessionConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import { isMobile, isMobileOnly, withOrientationChange } from 'react-device-detect';

import { CardImg } from "reactstrap";
import ic_swap from "ASSETS/Images/ic_swap.svg";
import icCancelMeeting from "ASSETS/Images/ic_video_close.svg";
import { COMPONENT_IN_FOCUS, KEY_CODE_ENTER } from "../../Utils/Constants"
import { isMobileOrIpadPortrait } from "../../Utils/Utility";

//style
import "./CoBrowse.less"

//UTILS
import {  CO_BROWSE_SESSION_STATUS  } from "UTILS/Constants";

class CoBrowse extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      targetUrl: "",
      spinnerVisibility: false,
      endCoBrowseSession: false
    }
    this.changeUrlObserver = new Subject();
  }

  componentDidMount() {

    // handle post message and perform relevant operations
    window.addEventListener("message", (event) => { 
      this.handleMessageFromCobrowseIframe(event);
    });

    // send websocket message for user side URL after 1 sec 
    this.changeUrlObserver.pipe(debounceTime(1000)).subscribe(fn => {
      if (typeof fn === "function")
        fn(this.state.targetUrl)
    });
    
    //when session started but URL is loading and 
    //after agent loaded session user accept the permissions 
    this.props.coBrowseSessionStatus === CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED &&
      this.setState({spinnerVisibility: true})
    
    setTimeout(() => {this.setIframeDimensions()}, 100)
    window.addEventListener("resize", () => {
      setTimeout(() => {this.setIframeDimensions()}, 100)
    })
  }

  setIframeDimensions = () => {
    let iframe = document.getElementById("iframe");
    let iframeDiv = document.getElementById("iframeDiv");
    if(!iframe || !iframeDiv)
      return;
    let iframeTop = iframe.offsetTop;

    if(!isMobileOnly){
      iframeTop = 0;

      let iframeHeightBeforeScale = 667;
      let iframeWidthBeforeScale = 375;

      iframe.style.width = iframeWidthBeforeScale+"px";
      iframe.style.height = iframeHeightBeforeScale+"px";
      iframe.style.left = "50%";
      iframe.style.transform = "translate(-50%)";
      let iframeLeftInPixels = iframe.offsetLeft;

      var scaleFactor = iframeDiv.offsetHeight/iframeHeightBeforeScale;
      iframe.style.transform = `scale(${scaleFactor}) translate(-50%)`;
      // top : topBeforeScaling + (difference between height of iframe / 2)
      iframe.style.left = iframeLeftInPixels + (((iframeWidthBeforeScale*scaleFactor) - iframeWidthBeforeScale) / 2) + 'px';
      iframe.style.top = iframeTop + (((iframeHeightBeforeScale*scaleFactor) - iframeHeightBeforeScale) / 2) + 'px';
    } else {
      let iframeHeightBeforeScale = screen.height;
      let iframeWidthBeforeScale = screen.width;

      iframe.style.width = iframeWidthBeforeScale+'px';
      iframe.style.height = iframeHeightBeforeScale+'px';
      iframeTop = 0;
      scaleFactor = iframeDiv.offsetHeight/iframeHeightBeforeScale;
      iframe.style.transform = `scale(${scaleFactor})`;
      iframe.style.top = iframeTop + (((iframeHeightBeforeScale*scaleFactor) - iframeHeightBeforeScale) / 2) + 'px';
    }
  }

  handleMessageFromCobrowseIframe = (event) => {
    if(event.data && typeof event.data === 'string') {
      // console.log(`handleMessageFromCobrowseIframe: ${JSON.stringify(event.data)} ${typeof event.data}`)
      var postMessageObj = JSON.parse(event.data);
      if(postMessageObj.category === 'COBROWSE') {
        //if message posted from cobrowse then only handle the operation
        switch (postMessageObj.action) {
          case 'cobrowseURLChange':
            //Show coBrowse changed URL into IFrame input box
            let currURL = (new URL(this.props.targetSiteUrl && !this.props.isPresenter ? this.props.targetSiteUrl: this.state.targetUrl));
            let newURL = currURL.href.replace(currURL.pathname, new URL(postMessageObj.value).pathname );
            this.setState({
              targetUrl: newURL
            }, () => {
              this.props.changeUrl(this.state.targetUrl)
            });
            break;
          case 'showSpinner':
            // Show spinner once message is received on handle click from togetherjs
            this.setState({spinnerVisibility: true});
            break;
          case 'hideSpinner':
            // Hide spinner once message is received on handle click from togetherjs
            this.setState({spinnerVisibility: false});
            break;
          case 'endCobrowseSession':
            // End coBrowse session after click on logout button from MY site 
            this.setState({endCoBrowseSession: false});
            this.props.endCoBrowseSession();
            break;
          default:
            break;
        }
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('message', (event) => { this.handlePostMessageOperation(event)});
  }

  handleUrlChange =(event)=>{
    this.setState({
      targetUrl: event.target.value
    })
    this.changeUrlObserver.next(this.props.changeUrl);
  }

  iframLoaded = () => {
    if(this.props.coBrowseURL) {
      this.setState({spinnerVisibility: false});
      if(this.props.coBrowseSessionStatus !== CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING) {
        this.props.sendCoBrowseStartedWSMsg();
      }
    }
  }

  startSessionClick =()=>{
    this.props.startCoBrowseSession(
      this.state.targetUrl, () => {
        this.setState({spinnerVisibility: false});
      }
    ); 
    this.setState({spinnerVisibility: true});
  }

  onEndOrLeaveSessionClick = () => {
    this.setState({endCoBrowseSession: false});
    this.props.endCoBrowseSession();
  }

  renderSpinner() {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.startSessionClick();
    }
  };

  componentDidUpdate(prevProps){
    // send url to new join participant when co browse session not active 
    if(prevProps.newJoinedParticipantId !== this.props.newJoinedParticipantId && 
     this.state.targetUrl &&
     this.props.coBrowseSessionStatus !== CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING) {
      this.props.changeUrl(this.state.targetUrl)
    }
    //added spinner when url start loading on user end
    if(prevProps.coBrowseSessionStatus !== this.props.coBrowseSessionStatus && 
     this.props.coBrowseSessionStatus === CO_BROWSE_SESSION_STATUS.CO_BROWSE_STARTED){
      this.setState({spinnerVisibility: true})
    }

    if((this.props.componentInFocus !== prevProps.componentInFocus 
       && (this.props.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE
       || prevProps.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE))
      || this.props.isLandscape !== prevProps.isLandscape) {
      setTimeout(() => {this.setIframeDimensions()}, 100)
    }
  }

  showEndCoBrowseConfirmation =()=>{
    return(
      <EndSessionConfirmationModal
        show={this.state.endCoBrowseSession}
        isDoubleButton={true}
        handleYesClick={this.onEndOrLeaveSessionClick}
        handleNoClick={() => {this.setState({endCoBrowseSession: false})}}
        message={
          this.props.isPresenter
            ? getMessage("END_CO_BROWSE_SESSION_CONFIRMATION_TEXT")
            : getMessage("LEAVE_CO_BROWSE_SESSION_CONFIRMATION_TEXT")
        }
      />
    )
  }

  shouldShowSwapIcon = () => {
    return !(this.props.showSnapshot || (this.props.snapshoteeId
      && this.props.snapshotPermissionReply)|| 
      (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning))
  }

  render(){
    // for smooth effect 
    let fullScreenCoBrowseContainer = this.props.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE 
      ? {minHeight: "calc(75vw * 0.56 + 1em)"} :{}

    // TODO: to check why iframe not take height of parent container
    let fullScreenCoBrowseIframe = this.props.componentInFocus !== COMPONENT_IN_FOCUS.COBROWSE 
      ? {height: "calc(25vw * 0.56 - 1.55rem)"} : {};

    let showCobrowseInPrimaryWindow = (this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE) 
      && !(this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply)) 
      || isMobileOrIpadPortrait()
    return(
      <div id="CoBrowse"
        className={showCobrowseInPrimaryWindow 
          ? "mt-2 pt-2 paddingButtomCoBrowse" // left padding is already added for canvas
          : "secondaryCobrowseContainer" 
        }
        style={fullScreenCoBrowseContainer}
      >
        {this.showEndCoBrowseConfirmation()}
        {!showCobrowseInPrimaryWindow && this.shouldShowSwapIcon() && 
              <CardImg
                src={ic_swap}
                className="iconSwap iconMargin"
                onClick={() => { this.props.setComponentInFocus(COMPONENT_IN_FOCUS.COBROWSE) }}
              />
        }

        <div className={showCobrowseInPrimaryWindow 
          ? "row pr-0 pr-md-3 m-0" 
          : "row px-1 m-0"}>
          <div className={!showCobrowseInPrimaryWindow ?
            "col-12 pt-2 pb-1 px-0":
            "col pb-1 pl-0"}>
            <input
              type="text" 
              className={showCobrowseInPrimaryWindow
                ? "form-control inputFormControl"
                : "form-control inputFormControl cursorDisabled"
              }
              value={this.props.targetSiteUrl && !this.props.isPresenter ? this.props.targetSiteUrl: this.state.targetUrl}
              placeholder={this.props.isPresenter ? getMessage("URL_VALUE_PLACEHOLDER"): getMessage("COBROWSE_URL")}
              onChange={this.handleUrlChange}
              disabled={
                !this.props.isPresenter || 
                this.props.coBrowseSessionStatus === CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING || !showCobrowseInPrimaryWindow
              }
              onKeyPress={this.enterPressed}
              autoFocus
            />
          </div>
          <div className={!showCobrowseInPrimaryWindow ?
            "col-12 displayNone ":
            "displayFlex pb-1"}>
            {
              this.props.isPresenter && this.props.coBrowseSessionStatus !== CO_BROWSE_SESSION_STATUS.CO_BROWSE_RUNNING
                ? <button 
                  className={this.state.targetUrl ? "btn startSessionBtn mr-1 mr-md-3" :"btn startSessionBDisabledBtn mr-1 mr-md-3"}
                  disabled={!this.state.targetUrl}
                  onClick={this.startSessionClick}
                >
                  {getMessage("GO")}
                </button>
                : ""
            }
            <div
              onClick={()=>{this.setState({endCoBrowseSession: true})}}
            >
              { this.props.isPresenter 
                ? !isMobileOnly 
                  ? <button className="btn endSessionBtn"> {getMessage("END")} </button>
                  : <img src={icCancelMeeting} className="cursorPointer mt-1" />
                : !isMobileOnly 
                  ? <button className="btn endSessionBtn"> {getMessage("LEAVE")} </button>
                  : <img src={icCancelMeeting} className="cursorPointer mt-1" />
              }
            </div>
          </div>
        </div>
        <div id="iframeDiv" className="IframeContainer pt-1">
          <iframe 
            name="iframe" 
            id="iframe"  
            onLoad={this.iframLoaded} 
            src={this.props.coBrowseURL} 
            title="co-browse" 
            //className={showCobrowseInPrimaryWindow ? "h-100 w-100" : "w-100"}
            style={fullScreenCoBrowseIframe}
          >
          </iframe>
          {
            //added overlay over the iframe when iframe not in a focus so it is not operational
            this.props.componentInFocus !== COMPONENT_IN_FOCUS.COBROWSE &&
           <div className="overlay">
           </div>
          }
          {this.renderSpinner()}
        </div>
      </div>
    )
  }
}

export default withOrientationChange(CoBrowse);
