import { socketMessage } from "WEBSOCKET/constants";
import * as log from "loglevel";
import { ACTIONS, ERROR } from "UTILS/Constants";

log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.CHAT.NOTIFY_ERROR:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_ERROR,
        value: ERROR.SNAPSHOT_SCAN_CODE_ERROR
      });
      break;
    case socketMessage.subCategories.CHAT._SEND_MESSAGE:
      store.dispatch({
        type: ACTIONS.CHAT.ADD_MESSAGE,
        messageDetails: {
          sender: data.sender,
          message: data.message,
          participantId: data.participantId,
          receiverId: data.receiverId,
          error: data.error
        }
      });
      store.dispatch({
        type: ACTIONS.CHAT.DISPLAY_MESSAGE,
        messageDisplay: true
      });
      store.dispatch({
        type: ACTIONS.CHAT.SET_MESSAGES_READ,
        value: false
      });
      break;
  }
};
