import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isFirefox } from "react-device-detect";

import * as log from "loglevel";

import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

import { loginAction, logoutAction } from "CONFIG/ActionFactory";

// Utilities
import { removeTrailingSlash, getUrlPathExcludingSearchString, getStringArrayInLowerCase } from "../Utils/Utility";
import { 
  USER_ROLES,
  CONFIG,
  URI_IDENTIFIER_MODIFICATION_NOT_ALLOWED_PATH,
  REGEX, 
  errorStrings
} from "../Utils/Constants";

log.setLevel("info");
class Router extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(`Routing: In Router.js componentDidMount uri this.props.uriIdentifier - ${this.props.uriIdentifier}`);
    this.updateURIIdentifier();
    window.addEventListener('error', (event) => {
      console.log('Error: ', event.message);
      if (event && event.message == (isFirefox ? errorStrings.URImalformedErrorFirefox : errorStrings.URImalformedError)) {
        window.location.replace(window.location.origin + ( this.props.uriIdentifier !== '/'
          ? this.props.uriIdentifier : '') + CONFIG.path.pageNotFound);
      }
    })
  }

  updateURIIdentifier = () => {
    let urlPath = removeTrailingSlash(window.location.pathname).split("/");

    console.log(`Routing: urlPath is ${urlPath}`);
    if (urlPath[2]?.toLowerCase() === 'join') {
      logoutAction.logoutUserAction();
      loginAction.setURIidentifier(null);
      this.getSSOInfo(urlPath[1], urlPath[2])
    } else if ((urlPath[1]?.toLowerCase() === 'superadmin') ||
      (urlPath[2]?.toLowerCase() === 'superadmin')) {
      // only in case of superadmin url should not have uri identifier
      this.setURIidentifier('/');
    } else if (!Object.values(USER_ROLES).includes(this.props.role)) {
      // once user is in the session he should not be able to change uri
      // whether user has joined/logged in is determined by his role before login/join it is empty string
      if (!this.isUriModificationAllowedForCurrentPath()) {
        return;
      }

      // check if pathname matches some session path then we need to set basename to "/"
      let isPathPresentInConstants = getStringArrayInLowerCase(CONFIG.path).includes(
        getUrlPathExcludingSearchString(window.location.pathname).toLowerCase())
        || REGEX.JOIN_QR_CODE_SESSION.test(window.location.pathname)
        || REGEX.START_QR_CODE_SESSION.test(window.location.pathname);

      console.log(`Routing: pathExists ${isPathPresentInConstants}`);
      if (isPathPresentInConstants) {
        if (this.props.uriIdentifier !== '/') {
          this.setURIidentifier("/");
        }
      } else {
        // issue -> on changing uri identifer 404 page is displayed for fraction of second
        loginAction.setURIidentifier(null);
        this.getSSOInfo(urlPath[1], urlPath[2])
      }
    }
  }

  isUriModificationAllowedForCurrentPath = () => {
    let pathName = getUrlPathExcludingSearchString(window.location.pathname).toLowerCase();

    if (this.props.uriIdentifier !== '/') {
      let uriIdentifierCaseInsensitive = new RegExp(this.props.uriIdentifier, 'gi');
      pathName = pathName.replace(uriIdentifierCaseInsensitive, '')
    }

    // return if path is the one where uri identifer modification is not allowed 
    if (getStringArrayInLowerCase(URI_IDENTIFIER_MODIFICATION_NOT_ALLOWED_PATH).includes(pathName)) {
      console.log(`Routing: do not uri since path is ${pathName}`);
      return false;
    }
    return true
  }

  getSSOInfo = (uriIdentifier, roleByPath) => {
    let urlPath = removeTrailingSlash(window.location.pathname).split("/");
    if (urlPath[1] !== 'superadmin') {
      loginAction.getSSOInfo(uriIdentifier).then((response) => {
        this.setURIidentifier('/' + response.customerSettings.uriIdentifier);
        loginAction.setRoleByPath(roleByPath === 'admin' ? USER_ROLES.ADMIN : USER_ROLES.AGENT)
      }).catch((error) => {
        console.error('getSSOInfo error: ', error);
        this.setURIidentifier('/');
      })
    }
  }

  setURIidentifier = async (uriIdentifier) => {
    if (uriIdentifier !== this.props.uriIdentifier) {
      // IMP: before setting domain to any other value we need to set it to null 
      // so that the browserRouter unmounts and history is cleared 
      await loginAction.setURIidentifier(null);
      console.log(`set uri identifer to ${uriIdentifier}`);
      loginAction.setURIidentifier(uriIdentifier);
    }
  }

  renderRouter = () => {
    return (
      <BrowserRouter basename={this.props.uriIdentifier}>
        <Routes
          uriIdentifier={this.props.uriIdentifier}
        />
      </BrowserRouter>
    )
  }

  render() {
    return (
      this.props.uriIdentifier
      && this.renderRouter()
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.UserReducer.userDetails.token,
    role: state.UserReducer.userDetails.role,
    uriIdentifier: state.UserReducer.uriIdentifier,
    ssoDetails: state.UserReducer.ssoDetails
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Router);
