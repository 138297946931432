import crypto from 'crypto'
import store from "REDUX/store";

/**
* aes encryption
* @param data data to be encrypted
* @returns {string}
*/
const encryption = (data) => {
  let clearEncoding = 'utf8'
  let cipherEncoding = 'hex'
  let cipherChunks = []
  let cipher = crypto.createCipheriv('aes-128-ecb', AESKey(), '')
  cipher.setAutoPadding(true)
  cipherChunks.push(cipher.update(data, clearEncoding, cipherEncoding))
  cipherChunks.push(cipher.final(cipherEncoding))
  return cipherChunks.join('')
}

/**
* aes decryption
* @param data  data to be decrypted
* @returns {string}
*/
const decryption = (data) => {
  let clearEncoding = 'utf8'
  let cipherEncoding = 'hex'
  let cipherChunks = []
  let decipher = crypto.createDecipheriv('aes-128-ecb', AESKey(), '')
  decipher.setAutoPadding(true)
  cipherChunks.push(decipher.update(data, cipherEncoding, clearEncoding))
  cipherChunks.push(decipher.final(clearEncoding))
  return cipherChunks.join('')
}

const AESKey = () => {
  let encryptKey = store.getState().SessionReducer.sessionDetails.cobrowseSettings.aesKey
  let key = Buffer.from(encryptKey, 'base64').toString()
  return key
}

export default {
  encryption,
  decryption
}

