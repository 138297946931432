import React, { Fragment } from "react";
import moment from "moment";
import {
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Card,
  CardBody,
  Table,
  DropdownItem
} from "reactstrap";
import NumericInput from 'react-numeric-input';
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Spinner from "COMPONENTS/Spinner/Spinner";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import TimezoneDropdown from "COMPONENTS/SuperAdmin/TimezoneDropdown/TimezoneDropdown";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";

//Utilities
import { checkIfValidImageFile,isAlphaNumeric,hasWhiteSpace  } from "UTILS/Utility";
import { getTomorrowDate, isValidDate } from "UTILS/DateUtils";
import { DATE_FORMAT, ERROR } from "UTILS/Constants";

//Daterangepicker
import DateTime from 'react-datetime';

// Images
import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_pdf from "ASSETS/Images/pdf/ic_pdf.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_camera from "ASSETS/Images/ic_camera_header.svg";
import ic_admin_co_browse from "ASSETS/Images/ic_admin_co_browse.svg"

import ic_QR_code from "ASSETS/Images/ic_QR_code.svg";
import download_img from "ASSETS/Images/ic_download_allow.svg";
import ic_error from "ASSETS/Images/ic_error.svg";
import ic_edit from "ASSETS/Images/ic_edit.svg";
import ic_add_storage from "ASSETS/Images/ic_add_storage.svg";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation_small.svg";

// Strings
import { getMessage } from "CONFIG/i18n";
import { modulesAvailableConstant, cobrowseTypes , modulesToDisplay, LOG_TYPE, STORAGE_LOCATION, BUSINESS_MODELS, availableFeatures, LANGUAGE, MAX_QR_LIMIT } from "../../../Utils/Constants";
import { validateNumber } from "UTILS/regexValidations";
import { getStringWithoutExtraSpaces } from "../../../Utils/Utility";

import "../Organisations/AddOrganization.less";
import "../../App/App.less";
import "./../Organisations/Organisations.less";
import "react-datetime/css/react-datetime.css";

class EditOrganization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgid: this.props.editOrganisationData.id,
      name: this.props.editOrganisationData.name,
      seats: this.props.editOrganisationData.seats,
      businessModel: this.props.editOrganisationData.businessModel,
      maxConcurrentConnections: this.props.editOrganisationData.maxConcurrentSessions,
      qr_limit: this.props.editOrganisationData.qr_limit == 0 ? "" :this.props.editOrganisationData.qr_limit,
      availableModules: this.props.editOrganisationData.modules,
      firstName: this.props.editOrganisationData.firstName,
      lastName: this.props.editOrganisationData.lastName,
      username: this.props.editOrganisationData.username,
      phone: this.props.editOrganisationData.phone,
      uriIdentifier: this.props.editOrganisationData.uriIdentifier, 
      licenseExpiryDate: moment(this.props.editOrganisationData.licenseExpiryDate),
      loggingLevel: this.props.editOrganisationData.loggingLevel,
      storageDetails: this.props.editOrganisationData?.storageDetails ? _.cloneDeep(this.props.editOrganisationData.storageDetails) : {},
      errorMessage: "",
      licenseExpiryDate: moment(this.props.editOrganisationData.license_expiry),
      previousDate: moment(this.props.editOrganisationData.license_expiry),
      minDate: getTomorrowDate(),
      image_file: "",
      logo: this.props.editOrganisationData.logo,
      image_data: "",
      is_image_changed: false,
      spinnerVisibility: false,
      isDataEdited: false,
      currentBucket: "",
      isSSOEnabled: this.props.editOrganisationData?.isSSOEnabled,
      isPasscodeEnabled: this.props.editOrganisationData?.isPasscodeEnabled,
      isOrganizationTemplatesEnabled: this.props.editOrganisationData?.isOrganizationTemplatesEnabled,
      showSystemCapacity: false,
      cobrowseType: this.props.editOrganisationData.cobrowseSettings?.cobrowseType ? this.props.editOrganisationData.cobrowseSettings.cobrowseType : cobrowseTypes.CUSTOMER_CO_BROWSE,
      isRingCentralEnabled: this.props.editOrganisationData?.isRingCentralEnabled,
      isSnapshotScanCodeEnabled: this.props.editOrganisationData?.isSnapshotScanCodeEnabled,
      defaultLanguage: this.props.editOrganisationData.defaultLanguage,
      timezone: this.props.editOrganisationData.timezone,
      systemCapacity : this.getSystemCapacity(),
      isChatGPTEnabled: this.props.customerSettings.chatgptEnabled,
      allowUpload: this.props.customerSettings.allowUpload,
    };
    this.uploadImageRef = null;
    this.tooltipBoundaryElement = null;
    this.coBrowseOptions = [
      {
        name: getMessage("CUSTOMER_CO_BROWSE"),
        value: cobrowseTypes.CUSTOMER_CO_BROWSE,
      },
      {
        name: getMessage("WEB_CO_BROWSE"),
        value: cobrowseTypes.WEB_CO_BROWSE,
      },
    ];
    this.businessModelsOptions = [
      {
        name: getMessage("NO_OF_SEATS"),
        value: BUSINESS_MODELS.NUMBER_OF_SEATS,
      },
      {
        name: getMessage("MAX_CONCURRENT_CONNECTIONS"),
        value: BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS,
      },
    ];
    this.defaultLanguageOptions = [
      {
        name: getMessage("ENGLISH"),
        value: LANGUAGE.EN.name,
      },
      {
        name: getMessage("JAPANESE"),
        value: LANGUAGE.JA.name,
      },
    ];
    this.loggingLevelOptions = [
      {
        name: getMessage("BASIC"),
        value: LOG_TYPE.BASIC,
      },
      {
        name: getMessage("ADVANCED"),
        value: LOG_TYPE.ADVANCED,
      },
    ];
    this.storageOptions = [
      {
        name: getMessage("LOOOKIT_GLOBAL"),
        value: STORAGE_LOCATION.LOOOKIT_GLOBAL,
      },
      {
        name: getMessage("LOOOKIT_CUSTOM"),
        value: STORAGE_LOCATION.LOOOKIT_CUSTOM,
      },
      {
        name: getMessage("CUSTOMER"),
        value: STORAGE_LOCATION.CUSTOMER,
      },
    ];
  }
  
  componentDidMount() {
    this.selectDefaultStorage(-1);
    this.getTooltipBoundaryElement();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.editOrganisationData !== this.props.editOrganisationData) {
      this.setState({
        logo : this.props.editOrganisationData.logo
      });
    }
    if (this.props.sessionsCapacity.systemMaxCapacity != prevProps.sessionsCapacity.systemMaxCapacity ||
      this.props.sessionsCapacity.usedCapacity != prevProps.sessionsCapacity.usedCapacity) {
      const systemCapacity = this.getSystemCapacity();
      this.setState({
        systemCapacity : systemCapacity
      })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editOrganisationData?.storageDetails !== prevState.storageDetails)
      return {
        storageDetails: nextProps.editOrganisationData.storageDetails,
      };
    return null
  }

  /**
   * Gets Boundary element for info icon tooltip.
   * Tooltip will be rendered inside tooltipBoundaryElement
   */
  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("addOrganisationModal")[0];
  }

  renderSprinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  handleSaveClick = () => {
    let modules = this.state.availableModules; 
    if (!this.isValidData()) {
      return;
    }
    let formData = new FormData();
    formData.append("file", this.state.image_data);
    formData.append("customerId", this.props.editOrganisationData.id);
    let qrLimit = this.state.availableModules.indexOf("QR_CODE") != -1
      && this.state.qr_limit && this.state.qr_limit != ""
      ? this.state.qr_limit : undefined;
    let data = Object.assign(
      {},
      {
        name: getStringWithoutExtraSpaces(this.state.name),
        seats: this.state.seats,
        maxConcurrentSessions: this.state.maxConcurrentConnections,
        businessModel: this.state.businessModel,
        availableModules: modules,
        licenseExpiryDate: this.state.licenseExpiryDate.format("YYYY-MM-DD"),
        qrLimit: qrLimit,
        status: "ACTIVE",
        loggingLevel: this.state.loggingLevel,
        uriIdentifier: !!this.state.uriIdentifier ? this.state.uriIdentifier : null,
        storageDetails: this.state.storageDetails,
        isSSOEnabled: this.state.isSSOEnabled,
        language: this.props.language,
        isPasscodeEnabled: this.state.isPasscodeEnabled,
        isOrganizationTemplatesEnabled: (this.state.storageDetails.location == STORAGE_LOCATION.LOOOKIT_GLOBAL) ? false : this.state.isOrganizationTemplatesEnabled,
        cobrowseType: this.state.availableModules.includes(modulesAvailableConstant.CO_BROWSE) ? this.state.cobrowseType : null,
        isRingCentralEnabled: this.state.isRingCentralEnabled,
        isSnapshotScanCodeEnabled: this.state.isSnapshotScanCodeEnabled,
        defaultLanguage: this.state.defaultLanguage,
        timezone: this.state.timezone,
        customerSettings: {
          chatgptEnabled: this.state.availableModules.includes(modulesAvailableConstant.CHAT) ? this.state.isChatGPTEnabled : false,
          allowUpload: this.state.allowUpload,
        }
      }
    );

    if (this.state.is_image_changed) {
      this.props.uploadImage(formData, data, errorCode => {
        this.setState({
          errorMessage: getMessage(errorCode),
          errorCode: errorCode
        });
      });
    } else {
      if (this.state.isDataEdited) {
        this.props.handleSaveOrgClick(data, errorCode => {
          this.setState({
            errorMessage: getMessage(errorCode),
            errorCode: errorCode
          });
        });
      } else {
        this.resetStorageDetailsFields();
        this.resetError();
        this.props.handleCancelClick();
      }
    }
  };

  isValidData = () => {
    let OrgDetail = this.state;
    let licenseExpiryDate = OrgDetail.licenseExpiryDate;

    if (!OrgDetail.name || !OrgDetail.name.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_ORG_NAME") });
      return false;
    }
    if (OrgDetail.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS) {
      if (!OrgDetail.seats || OrgDetail.seats < 1 || !validateNumber(OrgDetail.seats)) {
        OrgDetail['seats'] = "";
        this.setState({
          errorMessage: getMessage("SEAT_ERROR_MSG"),
          OrgDetail: OrgDetail
        });
        return false;
      }
    } else if (OrgDetail.businessModel === BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS) {
      if (!OrgDetail.maxConcurrentConnections || OrgDetail.maxConcurrentConnections < 1 || !validateNumber(OrgDetail.maxConcurrentConnections)) {
        OrgDetail['maxConcurrentConnections'] = "";
        this.setState({
          errorMessage: getMessage("MAX_CONCURRENT_CONNECTIONS_ERROR_MSG"),
          OrgDetail: OrgDetail
        });
        return false;
      }
    }
    if ((this.state.isPasscodeEnabled || this.state.isSSOEnabled) && _.isEmpty(OrgDetail.uriIdentifier)) {
      this.setState({ errorMessage: getMessage("EMPTY_URI_IDENTIFIER") });
      return false;
    }
    if (this.state.uriIdentifier) {
      if (!isAlphaNumeric(this.state.uriIdentifier)) {
        this.setState({ errorMessage: getMessage("SPECIAL_CHARACTERS_ERROR") });
        return false;
      }
    }
    if (this.state.availableModules.indexOf("QR_CODE") !== -1
      && (OrgDetail.qr_limit <= 0 || OrgDetail.qr_limit > MAX_QR_LIMIT || !OrgDetail.qr_limit || !validateNumber(OrgDetail.qr_limit))) {
      this.setState({
        errorMessage: getMessage("QR_LIMIT_ERROR_MSG", { maxQRLimit:MAX_QR_LIMIT }),
        qr_limit: ""
      });
      return false;
    }
    if (!licenseExpiryDate || !isValidDate(licenseExpiryDate) || moment(licenseExpiryDate).isBefore(new moment(), 'date')) {
      this.setState({ errorMessage: getMessage("INVALID_DATE") });
      return false;
    }
    // if (this.state.loggingLevel == LOG_TYPE.ADVANCED
    //   && (this.state.storageDetails.location === STORAGE_LOCATION.LOOOKIT_CUSTOM && !this.state.isStorageSelected)) {
    //   this.setState({ errorMessage: getMessage("INVALID_STORAGE_OPTION") });
    //   return false;
    // }
    // if(OrgDetail.qr_limit < this.props.editOrganisationData.qr_limit){
    //   this.setState({errorMessage: getMessage("OR_LIMIT_SHOULD_GREATER_OR_EQUAL")})
    //   return false
    // }

    //remove storage credentials list if storae location is not loookit custom  
    if (this.state.storageDetails.location !== STORAGE_LOCATION.LOOOKIT_CUSTOM) {
      let storageDetails = this.state.storageDetails;
      storageDetails.list = [];
      this.setState({ storageDetails })
    }

    return true;
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value.trim(),
      errorMessage: "",
      isDataEdited: true,
    });

    if (event.target.name == "licenseExpiryDate") {
      this.setState({ previousDate: event.target.value.trim() });
    }
  };

  handleNumberInputChange = (name, value, maxLimit) => {
    let validInput;
    let ele = document.getElementsByName(name)[0];
    let inputVallength = ele.value.length;
    if(!Number.isInteger(value) || value > maxLimit) {
      this.setState({
        [name]: this.state[name]
      });
      return;
    }

    if (value !== 0) {
      validInput = value;
    } else {
      validInput = "";
    }
    
    this.setState({
      [name]: validInput,
      errorMessage: "",
      isDataEdited: true,
      showSystemCapacity : name == 'seats' || name == 'maxConcurrentConnections' ? true : false
    }, () => {
      ele.setSelectionRange(inputVallength, inputVallength);
    });
  }

  handleDateChange = (date) => {
    this.setState({
      licenseExpiryDate: date,
      errorMessage: "",
      isDataEdited: true
    });
  }

  changeModulePermission = event => {
    let state_element = this.state.availableModules;

    // if (event.target.name === modulesAvailableConstant.CAVAS) return;
    // console.log(event.target)
    if (event.target.checked && !(event.target.name in state_element)) {
      state_element.push(event.target.name);
    } else if (!event.target.checked) {
      state_element = _.remove(state_element, item => {
        if (item !== event.target.name) return item;
      });
    }
    this.setState(
      {
        availableModules: state_element,
        errorMessage: "",
        isDataEdited: true
      }
    );
  };

  handleOrgLogoChange = event => {
    if (event.target.files) {
      const file = event.target.files[0];
      const fileValidationResult = checkIfValidImageFile(file);
      if (!file) {
        return
      }
      if (!fileValidationResult.valid) {
        toastr.error(fileValidationResult.error);
        return
      }
      this.setState({
        logo: URL.createObjectURL(file),
        logoUrl: file,
        image_data: file,
        is_image_changed: true
      });
    }
  };

  handleDropdownChange = (name, value) => {
    this.setState({
      [name]: value,
      isDataEdited: true,
      errorMessage: "",
    })
  }

  isDataEdited = () => {
    if (this.props.editOrganisationData.name !== this.state.name) return true;
    if (this.props.editOrganisationData.seats !== this.state.seats) return true;
    if (this.props.editOrganisationData.maxConcurrentSessions !== this.state.maxConcurrentConnections) return true;
    if (
      this.props.editOrganisationData.license_expiry !==
      this.state.licenseExpiryDate
    )
      return true;
    if (
      !_.isEqual(
        this.state.availableModules.sort(),
        this.props.editOrganisationData.modules.sort()
      )
    )
      return true;

    return false;
  };

  // TODO: Change/optimize below code.
  displayAvailableFeatureList = () => {
    let allFeatureList = [];

    _.map(availableFeatures, (key, value) => {
      switch (key) {
        case "SINGLE_SIGN_ON":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.isSSOEnabled,"isSSOEnabled")}
            </Col>
          );
          break;
        case "SPECIFIED_MEETING_CODE":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0 mr-5">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.isPasscodeEnabled,"isPasscodeEnabled")}
            </Col>
          );
          break;
        case "ORGANIZATION_TEMPLATE":
          this.state.storageDetails.location !== STORAGE_LOCATION.LOOOKIT_GLOBAL && 
          allFeatureList.push(  
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.isOrganizationTemplatesEnabled,"isOrganizationTemplatesEnabled")}
            </Col>
          );
          break;
        case "PDF_EDITOR":     
          allFeatureList.push(
            <Col key={key} xs={5} className= "d-flex modal-label" >
              <Label className="label modal-label p-0 mr-5">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.availableModules && this.state.availableModules.includes("PDF"),"PDF")}
            </Col>
          );
          break; 
        case "ALLOW_DOWNLOAD":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.availableModules && this.state.availableModules.includes("ALLOW_DOWNLOAD"),"ALLOW_DOWNLOAD")}
            </Col>
          );
          break;
        case "RING_CENTRAL":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.isRingCentralEnabled,"isRingCentralEnabled")}
            </Col>
          );
          break;
        case "SNAPSHOT_SCAN_CODE":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.isSnapshotScanCodeEnabled, "isSnapshotScanCodeEnabled", !this.state.availableModules.includes(modulesAvailableConstant.SNAPSHOT))}
            </Col>
          );
          break;
        case "CHAT_GPT":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage("CHAT_GPT_LABEL")}
              </Label>
              {this.renderSwitch(this.state.isChatGPTEnabled, "isChatGPTEnabled", !this.state.availableModules.includes(modulesAvailableConstant.CHAT) )}
            </Col>
          );
          break;

        case "ALLOW_UPLOAD":
          allFeatureList.push(
            <Col key={key} xs={5} className="d-flex modal-label">
              <Label className="label modal-label p-0">
                {getMessage(value)}
              </Label>
              {this.renderSwitch(this.state.allowUpload,"allowUpload")}
            </Col>
          );
          break;
      }
    });
    return allFeatureList;
  }

  displayAllModuleList = () => {
    let allModuleList = [];

    _.map(modulesToDisplay, (key, value) => {

      switch (key) {
        case "CANVAS":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label noPadding">
                <Checkbox 
                  name="CANVAS"  
                  checked={true}
                  disabled 
                />
                <img
                  src={ic_canvas}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "AUDIO":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="AUDIO"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.AUDIO
                  )}
                />
                <img
                  src={ic_audio}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage("AUDIO")}
              </Label>
            </Col>
          );
          break;
        case "AUDIO_VIDEO":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="AUDIO_VIDEO"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.AUDIO_VIDEO
                  )}
                />
                <img
                  src={ic_video}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage("VIDEO")}
              </Label>
            </Col>
          );
          break;
        case "SCREENSHARE":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="SCREENSHARE"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.SCREENSHARE
                  )}
                />
                <img
                  src={ic_share}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CHAT":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="CHAT"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.CHAT
                  )}
                />
                <img
                  src={ic_message}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "LOCATION":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="LOCATION"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.LOCATION
                  )}
                />
                <img
                  src={ic_location}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "PDF_EDITOR":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="PDF"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.PDF
                  )}
                />
                <img
                  src={ic_pdf}
                  style={{
                    height: "17px",
                    width: "17px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "SNAP_SHOT":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="SNAPSHOT"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.SNAPSHOT
                  )}
                />
                <img
                  src={ic_camera}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "APP_EMULATION":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="APP_EMULATION"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.APP_EMULATION
                  )}
                  disabled={window._env_.REACT_APP_ALLOW_APP_EMULATION == 'false'}
                />
                <img
                  src={ic_app_emulation}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage("APP_EMULATION")}
              </Label>
            </Col>
          );
          break;
        case "QR_CODE":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="QR_CODE"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.QR_CODE
                  )}
                />
                <img
                  src={ic_QR_code}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage("QR_CODE")}
              </Label>
            </Col>
          );
          break;
        case "ALLOW_DOWNLOAD":
          allModuleList.push(
            <Col key={key} xs={12} lg={4}>
              <Label className="label  noPadding">
                <Checkbox
                  name="ALLOW_DOWNLOAD"
                  onChange={this.changeModulePermission}
                  checked={this.state.availableModules.includes(
                    modulesAvailableConstant.ALLOW_DOWNLOAD
                  )}
                />
                <img
                  src={download_img}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CO_BROWSE":
          allModuleList.push(
            <Fragment key={key}>
              <Col key={key} xs={12} lg={4}>
                <Label className="label noPadding">
                  <Checkbox
                    name="CO_BROWSE"
                    onChange={this.changeModulePermission}
                    checked={this.state.availableModules.includes(
                      modulesAvailableConstant.CO_BROWSE
                    )}
                  />
                  <img
                    src={ic_admin_co_browse}
                    style={{
                      height: "20px",
                      width: "20px"
                    }}
                  />{" "}
                  {getMessage("CO_BROWSE")}
                </Label>
              </Col>
              {this.state.availableModules.includes(modulesAvailableConstant.CO_BROWSE) && 
              <Col xs={12} lg={6} className="noPadding">
                <CustomDropdown
                  options={this.coBrowseOptions}
                  onChange={(value) => this.handleDropdownChange("cobrowseType", value)}
                  value={this.state.cobrowseType}
                />
              </Col>
              }
            </Fragment>
          );
          break;
      }
    });
    return allModuleList;
  }

  resetEditState = () => {
    this.setState({
      id: this.props.editOrganisationData.id,
      name: this.props.editOrganisationData.name,
      seats: this.props.editOrganisationData.seats,
      maxConcurrentConnections: this.props.editOrganisationData.maxConcurrentSessions,
      availableModules: this.props.editOrganisationData.modules,
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      licenseExpiryDate: moment(this.props.editOrganisationData.license_expiry),
      previousDate: moment(this.props.editOrganisationData.license_expiry),
      minDate: getTomorrowDate(),
      image_file: this.props.editOrganisationData.logoUrl,
      logo: "",
      image_data: "",
      is_image_changed: false,
      errorMessage: "",
      errorCode: null,
      editOrgerrorMessage: "",
      storageErrorMessage: "",
      qr_limit: this.props.editOrganisationData.qr_limit,
      isDataEdited: false,
      isVerified: false,
      isAddStorage: false,
      isEditStorage: false,
      bucketName: "",
      secret: "",
      storageChanged: false,
      currentBucket: "",
      cobrowseType: this.props.editOrganisationData?.cobrowseSettings?.cobrowseType,
      isRingCentralEnabled: this.props.editOrganisationData?.is_ring_central_enabled,
      isSnapshotScanCodeEnabled: this.props.editOrganisationData?.isSnapshotScanCodeEnabled,
      defaultLanguage: this.props.editOrganisationData.defaultLanguage,
      timezone: this.props.editOrganisationData.timezone,
      isChatGPTEnabled: this.props.customerSettings.chatgptEnabled,
      allowUpload: this.props.customerSettings.allowUpload,
    });
  };

  handleUploadImage = () => {
    this.uploadImageRef.click();
  }

  renderButtons = () => {
    return (
      <div>
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.props.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button onClick={this.handleSaveClick} className="buttons customBtn">
          {getMessage("SAVE")}
        </Button>
      </div>
    );
  };

  handleS3InputChange = (event) => {
    if(!hasWhiteSpace(event.target.value)) {
      this.setState({
        [event.target.name]: event.target.value, isVerified: false
      });
      this.resetError();
    }
  }

  selectDefaultStorage = (selectedStorageDetailIndex) => {
    let storageDetailsList = this.state.storageDetails?.list ? this.state.storageDetails?.list.slice() : [];
    let valid = false;
    if (!this.state.isStorageSelected && this.state.errorMessage)
      this.setState({ errorMessage: "" })
    if (selectedStorageDetailIndex != -1) {
      storageDetailsList = storageDetailsList.forEach((storageDetails, index) => {
        if (index === selectedStorageDetailIndex) {
          storageDetails.isSelected = true;
          valid = true;
          this.setState({
            storageChanged: true,
            isDataEdited: true
          })
        } else {
          storageDetails.isSelected = false;
        }
      });
    }
    else {
      storageDetailsList = storageDetailsList.forEach((storageDetails, index) => {
        if (storageDetails.isSelected) {
          valid = true;
        }
      });
    }
    this.setState({
      storageErrorMessage: "",
      isStorageSelected: valid
    });//storageDetails: obj
  }

  renderStorageTableBody() {
    return (
      <tbody>
        {this.state.storageDetails.list?.map((item, index) =>
          <tr className="cursorPointer" key={item.credential.id}>
            <td className="storageRow">
              <Input
                type="radio"
                className='oldRadio'
                checked={item.isSelected}
                onChange={() => this.selectDefaultStorage(index)}
              />
              <span className="checkmark" value={item.isSelected}
                onClick={() => this.selectDefaultStorage(index)}>
              </span>
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="2"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential.bucketName}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="3"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential.id}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="3"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential.secret}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
              {!item.validation.status ?
              <>
                <img src={ic_error} alt="Status" className="ml-2 cursorPointer"
                  id="bucketError"
                />
                    <CustomTooltip
                      tooltipBoundariesElement={this.tooltipBoundaryElement}
                      tooltipText={item.validation.error.message}
                      tooltipId="bucketError"
                    />
                </>
                : null
              }
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
              <img src={ic_edit} alt="Edit" className="cursorPointer" id="edit"
                onClick={() => { this.handleEditStorageClick(item.credential.bucketName) }} />
              <CustomTooltip
                tooltipBoundariesElement={this.tooltipBoundaryElement}
                tooltipText={getMessage("EDIT")}
                tooltipId="edit"
              />
            </td>
          </tr>)
        }
      </tbody>
    )
  }

  renderSprinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  resetStorageDetailsFields = () => {
    this.setState({
      isVerified: false,
      isAddStorage: false,
      isEditStorage: false,
      bucketName: "",
      id: "",
      secret: "",
      storageChanged: false,
      currentBucket: ""
    });
  }

  resetError = () => {
    this.setState({
      errorMessage: "",
      storageErrorMessage: "",
      editOrgerrorMessage: ""
    });
  }

  handleEditStorageClick(bucketName) {
    let bucket = null;
    if (!_.isEmpty(bucketName)) {
      bucket = this.state.storageDetails.list?.filter((item => item.credential.bucketName === bucketName))[0];
      this.setState({
        isEditStorage: true,
        bucketName: bucket.credential.bucketName,
        id: bucket.credential.id,
        secret: bucket.credential.secret,
        currentBucket: bucket.credential.bucketName
      });
    }
  }

  addStorage = () => {
    if (!this.state.bucketName && !this.state.id && !this.state.secret) {
      this.setState({ editOrgerrorMessage: getMessage("EMPTY_MESSAGE") });
    }
    else if (this.state.isVerified) {
      let bucket = this.state.storageDetails;
      bucket.type = bucket.type ? bucket.type : "S3";
      bucket.list = bucket.list ? bucket.list : [];
      bucket.location = this.state.storageDetails?.location;

      let isDuplicateStorageDetails =false;
      bucket.list.map((content, i) =>{
        if(content.credential.bucketName === this.state.bucketName
          && content.credential.id === this.state.id
          && content.credential.secret === this.state.secret) {
          //Duplicate Storage Details
          isDuplicateStorageDetails=true;
        }
      });

      if(!isDuplicateStorageDetails) {
        if (!this.state.isEditStorage) {
          let cred = {
            credential: {
              bucketName: this.state.bucketName,
              id: this.state.id,
              secret: this.state.secret
            },
            validation: { status: true },
            isSelected: this.state.isSelected,
            id: null
          }
          bucket.list.push(cred)
        }
        else {
          let cred = {
              bucketName: this.state.bucketName,
              id: this.state.id,
              secret: this.state.secret
            },
            validation = { status: true }
          bucket.list = bucket.list.map(
            (content, i) => content.credential.bucketName === this.state.bucketName ? {
              ...content, credential: cred, validation: validation
            } : content)
        }
        this.resetStorageDetailsFields();
        this.setState({ storageDetails: bucket, isDataEdited: true })
      }
      else {
        this.setState({ editOrgerrorMessage: getMessage("DUPLICATE_STORAGE_CREDENTIALS") });
      }
    }
    else this.setState({ editOrgerrorMessage: getMessage("BEFORE_VERIFICATION_ERROR") });
  }

  handleVerifyStorageDetailsClick = () => {
    this.resetError();
    if (this.state.bucketName && this.state.id && this.state.secret) {
      this.setState({ spinnerVisibility: true });
      let storage = {
        type: 'S3',
        credentials: {
          id: this.state.id,
          secret: this.state.secret,
          bucketName: this.state.bucketName
        }
      }
      this.props.verifyStorage(storage, (error) => {
        if (error.code)
          this.setState({ spinnerVisibility: false, isVerified: false, storageErrorMessage: getMessage(error.code) })
        else {
          this.setState({ spinnerVisibility: false, isVerified: true, storageErrorMessage: "" })
        }
      })
    }
    else {
      this.setState({ storageErrorMessage: getMessage("EMPTY_MESSAGE") });
    }
  }

  onChangeStorageOption = (selectedStorage) => {
    this.resetStorageDetailsFields();
    this.resetError();
    let obj = this.state.storageDetails ? this.state.storageDetails : {};
    if (obj) {
      obj.location = selectedStorage;
      obj.list = obj.list ? obj.list : [];
      this.setState({
        storageDetails: obj,
        isDataEdited: true
      });
    }
  }
  cancelStorage = () => {
    this.resetStorageDetailsFields();
    this.resetError();
  }
  isOutsideRange=(day)=>{
    //
    let dayIsBlocked = false;
    //Block past dates
    //if(moment(this.state.previousDate).add(1,'days').diff(day, 'days') > 0) {
    if(getTomorrowDate().diff(day.startOf('day'), 'days') > 0) {
      dayIsBlocked = true;
    }
    
    return dayIsBlocked;
  }

  handleSwitches=(toggleFlag,variable)=>{
    if(variable !== "ALLOW_DOWNLOAD" && variable !== "PDF"){ 
      this.setState((prevState) => ({ [variable]: !prevState[variable] ,isDataEdited:true}));
    }
    else{
      let state_element = this.state.availableModules;
      if (toggleFlag && !(variable in state_element)) {
 
        state_element.push(variable);
      } else if (!toggleFlag) {
        state_element = _.remove(state_element, item => {
          if (item !== variable) return item;
        });
      }
      this.setState({ availableModules: state_element, errorMessage: "" ,isDataEdited:true});
    }
  }
 
   renderSwitch = (flag,variable,disabled=false)=>{
     return (
       <Switch
         switchOn={flag}
         toggleSwitch={(event)=>{this.handleSwitches(event,variable)}}
         className="modal-label"
         disabled = {disabled}
       />
     )
   }
 
   getSystemCapacity = () => {
     return (this.props.sessionsCapacity.systemMaxCapacity - this.props.sessionsCapacity.usedCapacity);
   }

   displaySystemCapacityUI = () => {
     return (
       <Col xs={3} className="d-flex modal-label">
         <span  className="p-0 modal-label systemCapacityLabel"> {getMessage("SYSTEM_CAPACITY")}: </span>
         <span className="modal-label ml-1 systemCapacityValue"> {this.state.systemCapacity}</span>
       </Col>
     )
   }

   showOrHideSystemCapacity = (showSystemCapacity) =>{
     this.setState({
       showSystemCapacity: showSystemCapacity,
     });
   }

   renderNoOfSeats = () => {
     return (
       <Row className="mb-2">
         <Col xs={3} className="pr-0">
           <Label className="label modal-label">{getMessage("NO_OF_SEATS")}</Label>
         </Col>
         <Col xs={6} className="parentOfNumericInput">
           <NumericInput
             className="ml-1 inputFormControl"
             name="seats"
             value={this.state.seats}
             min={0}
             strict={this.state.seats <= this.state.systemCapacity}
             onChange={(value) => this.handleNumberInputChange("seats", value, this.state.systemCapacity)}
             //  onFocus={() => this.showOrHideSystemCapacity(true)}
             onClick={() => this.showOrHideSystemCapacity(true)}
             onBlur={() => this.showOrHideSystemCapacity(false)}
             required
             placeholder={getMessage("NUMBER_OF_SEATS_PLACEHOLDER")}
           />
         </Col>
         {this.state.showSystemCapacity && this.displaySystemCapacityUI()}
       </Row>
     )
   }

   renderMaxConcurrentConnections = () => {
     return (
       <Row className="mb-2">
         <Col xs={3} className="pr-0">
           <Label className="label modal-label">{getMessage("MAX_CONCURRENT_CONNECTIONS")}</Label>
         </Col>
         <Col xs={6} className="parentOfNumericInput">
           <NumericInput
             className="ml-1 inputFormControl"
             value={this.state.maxConcurrentConnections}
             min={0}
             strict={this.state.maxConcurrentConnections <= this.state.systemCapacity}
             name="maxConcurrentConnections"
             onChange={(value) => this.handleNumberInputChange("maxConcurrentConnections", value, this.state.systemCapacity)}
             // onFocus={() => this.showOrHideSystemCapacity(true)}
             onClick={() => this.showOrHideSystemCapacity(true)}
             onBlur={() => this.showOrHideSystemCapacity(false)}
             placeholder={getMessage("MAX_CONCURRENT_MEETINGS_PLACEHOLDER")}
             required />
         </Col>
         {this.state.showSystemCapacity && this.displaySystemCapacityUI()}
       </Row>
     )
   }
 
   render() {
     const dateFormatInputProps = {
       readOnly: true,
     };
    
     return (
       <div>
         {this.renderSprinner()}
         <div
           className="addOrganisationModal p-3 whiteBackground"
           backdrop="static"
         >
           <Form className="editModal">
             <FormGroup className="OrgLogoForm">
               <Label className="label modal-label">{getMessage("ORGANIZATION_LOGO")}</Label>
               <Row className="flex5" disabled>
                 <Col md="6" className="OrganizationLogo">
                   {this.state.logo ? (
                     <img className="OrganizationLogoImg" src={this.state.logo} />
                   ) : null}
                 </Col>
                 <Col md="3" className="uploadImgDivCss">
                   <Button
                     alt="upload_img"
                     className="cursorPointer uploadbtnCss"
                     onClick={this.handleUploadImage}
                   >
                     {getMessage("UPLOAD_PROFILE_IMG")}
                   </Button>
                   <input
                     type="file"
                     accept="image/*"
                     onChange={event => {
                       this.handleOrgLogoChange(event);
                       event.target.value = null;
                     }}
                     className="inputFile displayNone"
                     id="uploadImage"
                     ref={ref => this.uploadImageRef = ref}
                   />
                 </Col>
               </Row>
             </FormGroup>
             <DropdownItem divider className="divider" />
             <FormGroup >
               <Label className="label modal-label">
                 {getMessage("ORGANIZATION_NAME")}
               </Label>
               <Input
                 type="text"
                 name="name"
                 onChange={this.handleInputChange}
                 className="inputFormControl"
                 value={this.state.name}
                 maxLength="50"
                 required
                 placeholder={getMessage("ORG_NAME_PLACEHOLDER")}
                 autoFocus
               />
             </FormGroup>
             <FormGroup>
               <Label className="label modal-label">{getMessage("BUSINESS_MODEL")}</Label>
               <div className="col-9 pl-5 pr-0">
                 <CustomDropdown
                   options={this.businessModelsOptions}
                   onChange={(value) => this.handleDropdownChange("businessModel", value)}
                   value={this.state.businessModel}
                   disabled={true}
                 />
               </div>
             </FormGroup>
             {
               this.state.businessModel === BUSINESS_MODELS.NUMBER_OF_SEATS 
                 ? this.renderNoOfSeats()
                 : this.renderMaxConcurrentConnections()
             }
             <FormGroup>
               <Label className="label modal-label">{getMessage("ORGANIZATION_URI_IDENTIFIER")}</Label>
               <Input
                 type="text"
                 maxLength="50"
                 name="uriIdentifier"
                 className="inputFormControl"
                 placeholder={getMessage("URI_PLACEHOLDER")}
                 onChange={this.handleInputChange}
                 value={this.state.uriIdentifier}
                 required
               />
             </FormGroup>
             <FormGroup >
               <Label className="label modal-label" style={{ alignSelf: "flex-start" }}>
                 {getMessage("AVAILABLE_FEATURE")}
               </Label>
               <Row className="flex5 ml-3">
                 {this.displayAvailableFeatureList()}
               </Row>
             </FormGroup>         
             <FormGroup >
               <Label className="label modal-label" style={{ alignSelf: "flex-start" }}>
                 {getMessage("AVAILABLE_MODULE")}
               </Label>
               <Row className="flex5 ml-3">
                 {this.displayAllModuleList()}
               </Row>
             </FormGroup>
             {
               this.state.availableModules.indexOf("QR_CODE") !== -1 ?
                 <FormGroup>
                   <Label className="label modal-label">{getMessage("QR_CODE_LIMIT")}</Label>
                   <NumericInput
                     name="qr_limit"
                     value={this.state.qr_limit}
                     min={0}
                     strict={this.state.qr_limit <= MAX_QR_LIMIT}
                     onChange={(value) => this.handleNumberInputChange("qr_limit", value, MAX_QR_LIMIT)}
                     className="ml-1 inputFormControl"
                     required
                     placeholder={getMessage("QR_CODE_LIMIT_PLACEHOLDER")}
                   />
                 </FormGroup> : null
             }
             <FormGroup >
               <Label className="label modal-label">{getMessage("EXPIRY_DATE")}</Label>
               <div className="col-9 pl-5 pr-0" onClick={(e) => this.setState({ focused:true })}>
                 <DateTime
                   id="licenseExpiryDate"
                   className="dateTimePicker"
                   dateFormat={DATE_FORMAT}
                   value={this.state.licenseExpiryDate}
                   inputProps={dateFormatInputProps}
                   onChange={(value) => this.handleDateChange(value)}
                   closeOnSelect
                   closeOnClickOutside
                   timeFormat={false}
                   isValidDate={(date)=>{return date.isSameOrAfter(moment())}}
                 />
               </div>
               <div>{this.props.licenseExpiryDate}</div>
             </FormGroup>
             <FormGroup>
               <Label className="label modal-label">
                 {getMessage("DEFAULT_LANGUAGE")}
                 <div className="ml-2">
                   <CustomTooltip
                     tooltipId="language"
                     tooltipBoundariesElement={this.tooltipBoundaryElement}
                     tooltipText={getMessage("ICON_DESCRIPTION_DEFAULT_LANGUAGE")}
                     showIcon={true}
                   />
                 </div>
               </Label>
               <div className="col-9 pl-5 pr-0">
                 <CustomDropdown
                   options={this.defaultLanguageOptions}
                   onChange={(value) => this.handleDropdownChange("defaultLanguage", value)}
                   value={this.state.defaultLanguage}
                 />
               </div>
             </FormGroup>
             <FormGroup>
               <Label className="label modal-label">
                 {getMessage("TIMEZONE")}
                 <div className="ml-2">
                   <CustomTooltip
                     tooltipId="timezone"
                     tooltipBoundariesElement={this.tooltipBoundaryElement}
                     tooltipText={getMessage("ICON_DESCRIPTION_TIMEZONE")}
                     showIcon={true}
                   />
                 </div>
               </Label>
               <div className="col-9 pl-5 pr-0">
                 <TimezoneDropdown
                   value={this.state.timezone}
                   onChange={(value) => this.handleDropdownChange("timezone", value)}
                 />
               </div>
             </FormGroup>
             <FormGroup>
               <Label className="label modal-label">{getMessage("LOGGING_LEVEL")}</Label>
               <div className="col-9 pl-5 pr-0">
                 <CustomDropdown
                   options={this.loggingLevelOptions}
                   onChange={(value) => this.handleDropdownChange("loggingLevel", value)}
                   value={this.state.loggingLevel}
                 />
               </div>
             </FormGroup>
             <FormGroup>
               <Label className="label modal-label">{getMessage("STORAGE_OPTION")}</Label>
               <div className="col-9 pl-5 pr-0">
                 <CustomDropdown
                   options={this.storageOptions}
                   onChange={this.onChangeStorageOption}
                   value={this.state.storageDetails.location}
                 />
               </div>
             </FormGroup>
             {
                this.props.editOrganisationData.storageDetails?.location === STORAGE_LOCATION.LOOOKIT_CUSTOM ?
                  <div className="section">
                    <Row className="noMargin">
                      <Col className="mainTitleStorage pl-0 label">{getMessage("STORAGE")}
                      </Col>
                      {(!this.state.isAddStorage && !this.state.isEditStorage) && (this.props.editOrganisationData.storageDetails.location === STORAGE_LOCATION.LOOOKIT_CUSTOM) ?
                        <Col className="mainTitleStorage pr-0">
                          <img src={ic_add_storage} className="cursorPointer floatRight" id="addStorage" onClick={() => this.setState({ isAddStorage: true })} />
                          <CustomTooltip
                            tooltipBoundariesElement={this.tooltipBoundaryElement}
                            tooltipText={getMessage("ADD_STORAGE")}
                            tooltipId="addStorage"
                          />
                        </Col>
                        : null
                      }
                    </Row>
                    <div>
                      {this.state.storageDetails?.list ?
                        <Row className="storageCard">
                          <CardBody className="pt-0 pb-0">
                            <Row className="interCardContent">
                              <Table bordered size="sm" className="ml-2 mr-2 storageTableWidth">
                                <thead>
                                  <tr className="storageHead">
                                    <th className="storageRow border-bottom rounded"> </th>
                                    <th className="storageRow border-bottom rounded" colSpan="2">{getMessage("BUCKET_NAME")}</th>
                                    <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("ID")}</th>
                                    <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("SECRET")}</th>
                                    <th className="storageRow border-bottom rounded"> </th>
                                    <th className="storageRow border-bottom rounded"> </th>
                                  </tr>
                                </thead>
                                {this.renderStorageTableBody()}
                              </Table>
                            </Row>
                          </CardBody>
                        </Row>
                        : null}
                      {this.state.isEditStorage || this.state.isAddStorage ?
                        <Row>
                          <Col className="storageCard">
                            <Card>
                              <CardBody>
                                <Row className="radio">
                                  <Col>
                                    <Label className="label modal-label">{getMessage("AWS_S3")}:</Label>
                                  </Col>
                                  <Col>
                                    <Button close aria-label="Cancel" onClick={this.cancelStorage}>
                                    </Button>
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-4 align-self-center">
                                    <Label>{getMessage("BUCKET_NAME")}</Label> </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="bucketName"
                                      className="inputFormControl mt-3"
                                      maxLength="50"
                                      required
                                      onChange={this.handleS3InputChange}
                                      disabled={this.state.currentBucket}
                                      value={this.state.bucketName}
                                    />
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-4 align-self-center">
                                    <Label>
                                      {getMessage("ID")}
                                    </Label>
                                  </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="id"
                                      className="inputFormControl mt-3"
                                      maxLength="50"
                                      required
                                      onChange={this.handleS3InputChange}
                                      value={this.state.id}
                                      placeholder={getMessage("AWS_ID_PLACEHOLDER")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-4 align-self-center">
                                    <Label>
                                      {getMessage("SECRET")}
                                    </Label>
                                  </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="secret"
                                      className="inputFormControl mt-3"
                                      maxLength="50"
                                      required
                                      onChange={this.handleS3InputChange}
                                      value={this.state.secret}
                                      placeholder={getMessage("AWS_SECRET_PLACEHOLDER")}
                                    />
                                  </Col>
                                </Row>
                                {this.state.storageErrorMessage ?
                                  <Row>
                                    <Col md={12} className="errors autoHeight">
                                      {getMessage(this.state.storageErrorMessage)}
                                    </Col>
                                  </Row>
                                  : null
                                }
                                <Row className="interCardContent floatRight">
                                  <Button onClick={this.handleVerifyStorageDetailsClick}
                                    disabled={!this.state.isVerified ? false : true}
                                    className={this.state.isVerified ? "verifiedBtn mt-2" : "verifyBtn mt-2"}>
                                    {this.state.isVerified ? getMessage("VERIFIED") : getMessage("VERIFY")}
                                  </Button>
                                  <Button className={this.state.isVerified ? "borderBtn cursorPointer bgwhite ml-3 mt-2" : "disabledBtn ml-3 mt-2"}
                                    disabled={this.state.isVerified ? false : true}
                                    onClick={this.addStorage}>
                                    {this.state.isEditStorage ? getMessage("UPDATE") : getMessage("ADD")}
                                  </Button>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        : null}
                    </div>
                    {this.state.editOrgerrorMessage ? <Row><Col md={12} className="errors autoHeight">{getMessage(this.state.editOrgerrorMessage)}</Col> </Row> : null}
                  </div>
                  : null
             }
             {this.props.editOrganisationData.storageDetails?.location === STORAGE_LOCATION.LOOOKIT_CUSTOM && !this.state.isStorageSelected && (!this.state.isEditStorage && !this.state.isAddStorage) ?
               <Row><Col md={12} className="warning autoHeight">{getMessage("FIRST_LOOOKIT_CUSTOM_WARNING")}</Col> </Row>
               : null}
             {((this.props.editOrganisationData.storageDetails?.location === STORAGE_LOCATION.CUSTOMER) && (!this.props.editOrganisationData.storageDetails?.otherStorageValid)) ?
               <Row><Col md={12} className="warning autoHeight">{getMessage("CUSTOMER_WARNING")}</Col> </Row>
               : null}
             {this.state.storageChanged ?
               <Row><Col md={12} className="text-muted text-center autoHeight">{getMessage("SECOND_LOOOKIT_CUSTOM_WARNING")}</Col> </Row>
               : null
             }
             <DropdownItem divider className="divider" />
             {this.props.editOrganisationData.admin ? <>
                <FormGroup  >
                  <Label className="label modal-label">
                    {getMessage("EMAIL")}
                  </Label>
                  <Input
                    type="email"
                    name="username"
                    className="inputFormControl"
                    value={this.props.editOrganisationData.admin.email}
                    maxLength="50"
                    required
                    disabled
                  />
                </FormGroup>
                <FormGroup className="pt-0">
                  <Label className="label modal-label">
                    {getMessage("FIRST_NAME")}
                  </Label>
                  <Input
                    type="text"
                    name="firstName"
                    className="inputFormControl"
                    value={this.props.editOrganisationData.admin.first_name}
                    maxLength="50"
                    required
                    disabled
                  />
                </FormGroup>
                <FormGroup >
                  <Label className="label modal-label">
                    {getMessage("LAST_NAME")}
                  </Label>
                  <Input
                    type="text"
                    name="lastName"
                    className="inputFormControl"
                    value={this.props.editOrganisationData.admin.last_name}
                    maxLength="50"
                    required
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="label modal-label">{getMessage("PHONE_NUMBER")}</Label>
                  <Input
                    type="phone"
                    name="phone"
                    className="inputFormControl"
                    value={this.props.editOrganisationData.admin.phone ?? ""}
                    required
                    disabled
                  />
                </FormGroup>
              </> : null}

             {this.state.errorMessage ? <div className="errors">{this.state.errorMessage}</div> : null}
           </Form>
           <Row className="permissionButtons">
             {this.renderButtons()}
           </Row>
         </div>
       </div>
     );
   }

   componentWillUnmount() {
     if (this.state.logo) {
       URL.revokeObjectURL(this.state.logo);
     }
   }
}

export default EditOrganization;
