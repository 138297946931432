import React, { Component } from "react";
import { connect } from "react-redux";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

// Components
import Spinner from "../Spinner/Spinner";

// Utilities
import {  getURLParameter } from "UTILS/Utility";
import { CONFIG, USER_ROLES } from "UTILS/Constants";
import { getMessage } from "CONFIG/i18n";
import { getTimeInMillisec } from "UTILS/DateUtils";

// Actions
import { loginAction } from "CONFIG/ActionFactory";

class SSOLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false
    }
  }
  componentDidMount() {
    console.log(`timestamp: Login callback received from Cognito at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
    this.setState({ spinnerVisibility: true });
    console.log('SSO debug: Cognito response:', window.location.href);
    console.log("SSO debug: uriIdentifier", this.props.uriIdentifier);
    console.log("SSO debug: ssoDetails", JSON.stringify(this.props.ssoDetails));
    console.log("SSO debug: roleByPath", this.props.roleByPath);
    
    let uriIdentifier = this.props.uriIdentifier.split("/");    
    let code = getURLParameter("code")
    let error = getURLParameter("error")
    let error_description = getURLParameter("error_description")
    const verifier = window.sessionStorage.getItem('ouath_pkce_key');
    
    console.log('SSO debug: params required to call Loookit SSO Login - code:', code, 
      ", uriIdentifier[1]:", uriIdentifier[1], ", verifier:", verifier);
    
    if (error || error_description) {
      console.log('SSO debug: error from Cognito SSO Login:', error, error_description);
      this.setState({ spinnerVisibility: false })
      toastr.error("INTERNAL_SERVER_ERROR");
      this.redirectToLoginByRole(true);

    } else if (code && uriIdentifier[1] && verifier) {
      console.log(`timestamp: Calling LoookIt Login API at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
      
      loginAction.loginWithSSOAction(code, verifier, this.props.roleByPath, uriIdentifier[1]).then((response) => {
        console.log(`timestamp: LoookIt Login API response received at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
        console.log('SSO debug: LoookIt SSO Login successful: ', response);
        window.sessionStorage.removeItem('ouath_pkce_key');
        this.setState({ spinnerVisibility: false, });
        this.redirectToLoginByRole();
      
      }, (error) => {
        console.log('SSO debug: Lookit SSO Login failed with error: ', error);
        window.sessionStorage.removeItem('ouath_pkce_key');
        this.setState({ spinnerVisibility: false })
        toastr.error(error.code);
       
        console.log('SSO debug: redirecting to Login due to LoookIt SSO login error');
        this.redirectToLoginByRole(true);
      })
    } else {
      console.log('SSO debug: redirecting to Login due to one of the missing fields code or uriIdentifier[1] or verifier');
      this.redirectToLoginByRole(true);
    }
  }

  redirectToLoginByRole(isError =false ) {
    let error = isError ? '?error='+isError : '';
    console.log('SSO debug: redirectToLoginByRole, role:', this.props.roleByPath);
    if (this.props.roleByPath === USER_ROLES.ADMIN) {
      this.props.history.push(CONFIG.path.adminLogin + error);
    } else {
      this.props.history.push(CONFIG.path.login + error);
    }
  }
  render() {
    return (
      <>
        <Spinner showSpinner={this.state.spinnerVisibility} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    roleByPath: state.UserReducer.userDetails.roleByPath,
    uriIdentifier: state.UserReducer.uriIdentifier,
    ssoDetails: state.UserReducer.ssoDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SSOLogin);
