import { ACTIONS, QR_CODES_PAGE_SIZES } from "UTILS/Constants";

const initialState = {
  qrCodesCount: 0,
  qrCodesListCurrentPageNo: 0,
  qrCodesListPageSize: QR_CODES_PAGE_SIZES[0].value,
  totalQRCodes: 0,
  isQRCodeEnabled: false,
  qrCodeData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.QR_CODES.SET_QR_CODES_CURRENT_PAGE_NO:
      return { ...state, qrCodesListCurrentPageNo: action.value };

    case ACTIONS.QR_CODES.SET_QR_CODES_PAGE_SIZE:
      return { ...state, qrCodesListPageSize: action.value };

    case ACTIONS.QR_CODES.SET_QR_CODES_COUNT:
      return { ...state, qrCodesCount: action.value };

    case ACTIONS.QR_CODES.SET_TOTAL_QR_CODES_COUNT:
      return { ...state, totalQRCodes: action.value };

    case ACTIONS.QR_CODES.SET_QR_CODE_ENABLED:
      return { ...state, isQRCodeEnabled: action.value };

    case ACTIONS.QR_CODES.SAVE_QR_CODE_DATA:
      return { ...state, qrCodeData: action.value };

    default:
      return state;
  }
}
