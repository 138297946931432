import * as log from "loglevel";
import { ACTIONS } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";
log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.LOCATION._START_LOCATION_SHARE:
      store.dispatch({
        type: ACTIONS.LOCATION.START_LOCATION_SHARE,
        value: true
      });
      break;
    case socketMessage.subCategories.LOCATION._SHARE_LOCATION_PERMISSION:
      let locationPermissionReply = store.getState().LocationReducer
      .locationPermissionReply;
      if (data.isGranted && locationPermissionReply === null) {
        store.dispatch({
          type: ACTIONS.LOCATION.SET_LOCATION_PERMISSION_REPLY,
          isGranted: true
        });
        store.dispatch({
          type: ACTIONS.LOCATION.ADD_LOCATION_PARTICIPANT_ID,
          locationParticipantId: data.participantId
        });
      } else {
        const permissionStatus = {
          isGranted: data.isGranted,
          uniqueId: data.participantId
        };
        store.dispatch({
          type: ACTIONS.LOCATION.SET_LOCATION_REPLY_PERMISSION_STATUS,
          locationPermissionReplyStatus: permissionStatus
        });
      }
      break;
    case socketMessage.subCategories.LOCATION._SHARE_LOCATION:
      store.dispatch({
        type: ACTIONS.LOCATION.ADD_LOCATION_DATA,
        participantId: data.participantId,
        participants: data.participantLocationData
      });
      break;
    case socketMessage.subCategories.LOCATION._STOP_LOCATION_SHARE:
      const participants=store.getState().ParticipantReducer.participants;
      store.dispatch({
        type: ACTIONS.LOCATION.LOCATION_SHARING_WINDOW_CLOSED,
        locationWindowClosed:{ locationWindowClosed:true, 
          participantId:data.participant,
          isDownloaded:data.isDownloaded, 
          isLocationPermissionGranted: data.isLocationPermissionGranted
        }
      })
      // participant is present when particular user denies location permission || user denies location access 
      // when agent performs any action like close / download map, we have flags and not participant
      // whereas when user performs any action, then participant data is sent to know who performed the action 
      if (data.hasOwnProperty("participant")) {
        store.dispatch({
          type: ACTIONS.LOCATION.STOP_LOCATION_SHARE,
          value: null,
          participant: data.participant,
          roomParticipantsCount:participants.length
        });
      } else {
        store.dispatch({
          type: ACTIONS.LOCATION.START_LOCATION_SHARE,
          value: false,
          isLocationSharing: false
        });
        store.dispatch({
          type: ACTIONS.LOCATION.STOP_LOCATION_SHARE,
        });
      }
      break;
    case socketMessage.subCategories.LOCATION._SHARE_ETA:
      store.dispatch({
        type: ACTIONS.LOCATION.SHARE_ETA,
        fromUniqueId: data.fromUniqueId,
        toUniqueId: data.toUniqueId,
        fromParticipantData: data.fromParticipantData,
        toParticipantData: data.toParticipantData
      });
      break;
    case socketMessage.subCategories.LOCATION._GET_ALL_LOCATION_DATA:
      store.dispatch({
        type: ACTIONS.LOCATION.GET_ALL_LOCATION_DATA,
        eta: data.eta,
        participants: data.participants
      });
      break;
    case socketMessage.subCategories.LOCATION._STOP_ETA_SHARE:
      store.dispatch({
        type: ACTIONS.LOCATION.STOP_ETA_SHARE
      });
  }
};
