import { ACTIONS } from "UTILS/Constants";

export const setSharingFlag = (value) => {
  return {
    type: ACTIONS.SET_MEDIA_SHARING_STOP,
    value,
  };
};

export const setSdpFlag = (value) => {
  return {
    type: "SET_START_SDP_OFFER",
    value,
  };
};

export const setIncomingCallFlag = (value) => {
  return {
    type: "SHOW_INCOMING_CALL",
    value,
  };
};

export const setQrError = (value) => {
  return {
    type: ACTIONS.SET_QR_ERROR,
    value,
  };
};

export const setQrActiveScreen = (activeQRScreen) => {
  return {
    type: ACTIONS.SET_QR_ACTIVE_SCREEN,
    activeQRScreen,
  };
};

export const setQRCode = (qrId) => {
  return {
    type: ACTIONS.SET_QR_CODE,
    qrId,
  };
};

export const removeSessionKeyAndToken = () => {
  return {
    type: ACTIONS.REMOVE_TOKEN_SESSION_KEY,
  };
};

export const updateLatestParticipant = () => {
  return {
    type: ACTIONS.UPDATE_PARTICIPANTS,
    participant: null,
  }
}
export const setQRNoThanksMessageScreen = (value) => {
  return {
    type: ACTIONS.SET_NO_THANKS_MESSAGE_SCREEN  ,
    value
  }
}

export const setFollowUpSuccess = (value) => {
  return {
    type: ACTIONS.SET_FOLLOW_UP_SUCCESS  ,
    value
  }
}

export const setSessionStatus = (sessionStatus) => {
  return {
    type: ACTIONS.SET_SESSION_STATUS,
    sessionStatus,
  }
}

export const setUserDetails = (value) => {
  return {
    type: ACTIONS.SET_USER_DETAILS,
    value
    
  }
}

export const removeSessionDetails = () =>{
  return {
    type: ACTIONS.JOIN_SESSION,
    sessionDetails: {sessionKey:'',token:'',modules:[]},
  }
}

// set the time at the user waiting period is exipired 
export const setUserWaitingEndTime = (endTime) => {
  return {
    type: ACTIONS.SET_USER_WAITING_ENDTIME,
    value: endTime
  }
} 

//set the flag of is user waiting time expired or not
export const setIsWaitingTimeOver = (userWaitingTimeEnded) => {
  return {
    type: ACTIONS.SET_USER_WATING_TIME_ENDED,
    value: userWaitingTimeEnded
  }
}

export const setQRCodeEnabled = (isQRCodeEnabled) => {
  return {
    type: ACTIONS.QR_CODES.SET_QR_CODE_ENABLED,
    value: isQRCodeEnabled
  }
}

export const clearQRCodeData = () => {
  return {
    type: ACTIONS.QR_CODES.CLEAR_QR_CODE_DATA
  }
}

export const setQRSessionJoinType = (value) => {
  return {
    type: ACTIONS.QR_CODES.SET_QR_SESSION_JOIN_TYPE,
    value,
  };
};