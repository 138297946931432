import React, { Component } from "react";
import { bindActionCreators } from "redux";

import ScreenShare from "./ScreenShare";
import { connect } from "react-redux";
import {
  selectSessionKey,
  selectCurrentParticipantUniqueId
} from "REDUX/Selector/SessionSelector";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";

// Constants
import {downloadableModules} from "UTILS/Constants";

import { sessionActions } from "CONFIG/ActionFactory";

import { getFirstNamebyParticipantId } from "UTILS/Utility";

import { selectUniqueId, getUserRole } from "../../Routes/selectors";
import { selectAllowedDownloads } from "../CommonComponents/Header/selectors";
import { selectTurnServerData, getScreenshareBandwidthSettings } from "../Video/selectors";
import { getAdmittedParticipants, selectLatestUpdatedParticipant } from "../CommonComponents/Header/selectors"
import { selectShowSnapshot, selectSnapshoteeId, selectSnapshotPermissionReply } from "REDUX/Selector/SessionSelector";
import { getShowCustomerCoBrowse } from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseSelector";

export class ScreenShareContainer extends Component {

  constructor(props) {
    super(props);
  }

  isScreenShareDownloadAllowed = () => {
    return this.props.allowedDownloads && this.props.allowedDownloads.hasOwnProperty(downloadableModules.SCREENSHARE)
  }
  
  render() {
    return (
      <div>
        <ScreenShare
          renegotiateScreenshare={this.props.renegotiateScreenshare}
          screenSharingParticipantId={this.props.screenSharingParticipantId}
          currentParticipantUniqueId={this.props.currentParticipantUniqueId}
          screenShareId="screenshare"
          sessionKey={this.props.sessionKey}
          sendWebsocketMessage={this.props.sendWebsocketMessage}
          uniqueId={this.props.uniqueId}
          userRole={this.props.userRole}
          isScreenShareDownloadAllowed={this.isScreenShareDownloadAllowed()}
          broadcastDownloadAction={this.props.broadcastDownloadAction}
          turnServer={this.props.turnServer}
          sessionActions={sessionActions}
          screenSharePresenterName={getFirstNamebyParticipantId(this.props.admittedParticipants, this.props.screenSharingParticipantId)}
          presenterId={this.props.presenterId}
          uniqueId={this.props.uniqueId}
          latestUpdatedParticipant={this.props.latestUpdatedParticipant}
          admittedParticipants={this.props.admittedParticipants}
          setComponentInFocus={this.props.setComponentInFocus}
          isLandscape = {this.props.isLandscape}
          screenshareBandwidthSettings = {this.props.screenshareBandwidthSettings}
          showCustomerCoBrowse = {this.props.showCustomerCoBrowse}
          showSnapshot = {this.props.showSnapshot}
          snapshoteeId = {this.props.snapshoteeId}
          snapshotPermissionReply = {this.props.snapshotPermissionReply}
          isSnapshotRunning={this.props.isSnapshotRunning}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentParticipantUniqueId: selectCurrentParticipantUniqueId(state),    
    sessionKey: selectSessionKey(state),
    screenSharingParticipantId: state.RoomMetaDataReducer.roomMetaData.screenSharingParticipantId,
    uniqueId: selectUniqueId(state),
    userRole: getUserRole(state),
    allowedDownloads: selectAllowedDownloads(state),
    turnServer:selectTurnServerData(state),
    admittedParticipants: getAdmittedParticipants(state),
    latestUpdatedParticipant: selectLatestUpdatedParticipant(state),
    screenshareBandwidthSettings: getScreenshareBandwidthSettings(state),
    showCustomerCoBrowse: getShowCustomerCoBrowse(state),
    showSnapshot: selectShowSnapshot(state),
    snapshoteeId: selectSnapshoteeId(state),
    snapshotPermissionReply: selectSnapshotPermissionReply(state),
    renegotiateScreenshare: state.SessionReducer.renegotiateScreenshare,
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenShareContainer);
