import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { getMessage } from "CONFIG/i18n";
import { getStringWithoutExtraSpaces } from "../../../Utils/Utility";

import './AddEditQRCodeModal.less'
import { INFO_FIELD_MAX_LENGTH, KEY_CODE_ENTER } from "UTILS/Constants";

const AddQRCodeModal = (props) => {
  const [qrCodeData, setQRCodeData] = useState({
    label: "",
    description: ""
  })
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(false)

  const handleInputChange = (event) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    const newQRCodeData = { ...qrCodeData };
    newQRCodeData[event.target.name] = event.target.value
    setQRCodeData(newQRCodeData)
  };

  const setFocus = () => {
    document.getElementById("qr-label").focus();
  };

  const isValidData = () => {
    if (qrCodeData.label.trim() == '') {
      setErrorMessage(getMessage("INVALID_QR_LABEL"));
      return false;
    }
    if (qrCodeData.description.trim() == '') {
      setErrorMessage(getMessage("INVALID_DESCRIPTION"));
      return false;
    }
    return true;
  };

  const handleAddClick = (e) => {
    // preventing the page from reloading on form submit
    e.preventDefault();

    if (isValidData()) {
      setIsAddBtnDisabled(true)
      const body = {
        label: getStringWithoutExtraSpaces(qrCodeData.label),
        description: qrCodeData.description == "" ? undefined : getStringWithoutExtraSpaces(qrCodeData.description)
      }
      props.handleAddClick(body, errorMessage => {
        setErrorMessage(getMessage(errorMessage))
        setIsAddBtnDisabled(false)
      });
    }
  };

  const renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={() => props.onClosed()}
          disable={isAddBtnDisabled}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button
          onClick={(e) => handleAddClick(e)}
          disable={isAddBtnDisabled}
          className="buttons customBtn">
          {getMessage("ADD")}
        </Button>
      </div>
    );
  };

  const enterPressed = (event) => {
    //check if user pressed enter key
    const code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      handleAddClick(event);
    }
  }

  return (
    <Modal
      isOpen={props.show}
      className="addOrganisationModal userAddModal add-edit-qr-code"
      onClosed={() => props.onClosed()}
      onOpened={setFocus}
    >
      <ModalHeader className="popupHeader">
        {getMessage("ADD_QR_CODE")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("QR_LABEL")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="text"
              name="label"
              id="qr-label"
              className="inputFormControl"
              maxLength="25"
              placeholder={getMessage("QR_LABEL_PLACEHOLDER")}
              onChange={(e) => handleInputChange(e)}
              onKeyDown={(e) => enterPressed(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("DESCRIPTION")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="textarea"
              name="description"
              id="qr-description"
              className="inputFormControl"
              maxLength={INFO_FIELD_MAX_LENGTH}
              placeholder={getMessage("QR_DESCRIPTION_PLACEHOLDER")}
              onChange={(e) => handleInputChange(e)}
              rows={4}
              onKeyDown={(e) => enterPressed(e)}
            />
          </FormGroup>
          {
            errorMessage ?
              <div className="errors">{errorMessage}</div>
              :
              <div className="mt-3 mb-3">
                <span>
                  {getMessage("QR_CODE_WILL_BE_AUTO_GENERATED")}
                </span>
              </div>
          }
        </Form>
      </ModalBody>
      <ModalFooter className="permissionButtons">
        {renderButtons()}
      </ModalFooter>
    </Modal>
  );
}

export default modalScrollBar(AddQRCodeModal);