import {USER_ROLES} from "UTILS/Constants";

export const getLocationParticipants = state => {
  return state.LocationReducer.participants;
};
export const selectLocationPermissionReply = state =>
  state.LocationReducer.locationPermissionReply;

export const selectLocationPermissionReplyStatus = state => {
  return state.LocationReducer.locationPermissionReplyStatus;
};
export const selectLocationSharingWindowClosed = state => {
  return state.LocationReducer.locationWindowClosed;
};

export const selectETA = state => {
  return state.LocationReducer.eta;
};

export const selectActiveCanvasRef = state =>
  state.WhiteboardReducer.activeCanvasRef;

export const selectParticipantId = state => state.SessionReducer.participantId;

export const selectCloseLocationWindowFlag = state => state.LocationReducer.closeLocationWindow