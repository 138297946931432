import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

// Strings
import { getMessage } from "CONFIG/i18n";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";
import "./ClusterInformationList.less"
import "../../App/App.less";

class RetireInstanceConfirmationModal extends React.Component {
  
  constructor() {
    super();
  }

  componentWillUnmount() {
    this.disableRetireInstanceBtn(false);
  }

  disableRetireInstanceBtn = (value) => {
    let retireInstanceBtn = document.getElementById("retireInstanceBtn");
    if(retireInstanceBtn){
      retireInstanceBtn.disabled = value;
      if(value)
        retireInstanceBtn.classList.add("pointerEventsNone");
      else
        retireInstanceBtn.classList.remove("pointerEventsNone");
    }
  }

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.props.handleRetireInstanceCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button
          id="retireInstanceBtn"
          className="buttons borderBtn customBtn resetButton"
          onClick={() => {
            this.disableRetireInstanceBtn(true);
            this.props.handleRetireInstanceOkClick();
          }
          }
          autoFocus
        >
          {getMessage("RETIRE")}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showRetireInstanceConfirmationModal}
        className="retireInstanceModal"
        toggle={this.props.handleRetireInstanceCancelClick}
        backdrop="static"
      >
        <ModalBody>
          {getMessage("RETIRE_INSTANCE_CONFIRMATION_TEXT")}
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}
export default modalScrollBar(RetireInstanceConfirmationModal); 
