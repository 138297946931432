import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import * as reduxThunk from "redux-thunk";
import { createBrowserHistory as createHistory } from "history";
import allReducers from "REDUX/allReducer";

import { loadState, saveState } from "REDUX/SessionStorage.js";
export const history = createHistory();

const persistedState = loadState();
const enhancers = [];
const middleware = [reduxThunk.default, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(allReducers, persistedState, composedEnhancers);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
