import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Row
} from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import { validateUserName, validateMobileNumber } from "UTILS/regexValidations";
import {
  getPhoneNumberWithCountryCode,
  getStringWithoutExtraSpaces,
} from "UTILS/Utility";

// Actions
import { organisationAction } from "CONFIG/ActionFactory";

import "../SuperAdmin/Organisations/AddOrganization.less";
import "../App/App.less";

import { ERROR, KEY_CODE_ENTER } from "UTILS/Constants";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

class UpdateProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      userProfileData: {
        first_name: this.props.userProfileData.first_name,
        last_name: this.props.userProfileData.last_name,
        role: this.props.userProfileData.role,
        email: this.props.userProfileData.email,
        phone: this.props.userProfileData.phone,
        status: this.props.userProfileData.status,
        id: this.props.userProfileData.id
      },
      image_file: "",
      logo: this.props.logo,
      image_data: "",
      is_image_changed: false,
      screenWidth: window.innerWidth,
    };
    window.addEventListener('resize', this.updateSize);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.userProfileData, prevProps.userProfileData)) {
      let userProfileData = Object.assign({}, this.props.userProfileData);
      this.setState({ userProfileData: userProfileData });
    }
    if (this.props.logo !== prevProps.logo) {
      this.setState({
        logo: this.props.logo
      });
    }
  }
  
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize);
  }

  setFocus = () => {
    document.getElementById("firstName").focus();
  };
  
  updateSize = (event) =>{
    this.setState({screenWidth: window.innerWidth});
  }

  // Handled proceed on enter key press
  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      event.preventDefault();
      this.handleSaveClick();
    }
  };

  handleSaveClick = () => {
    if (!this.isEdited()) {
      this.props.toggle();
      return;
    }

    if (!this.isValidData()) {
      return;
    }
    
    let formData = new FormData();
    formData.append("file", this.state.image_data);
    formData.append("customerId", this.props.customerId);
    let data = Object.assign(
      {},
      {
        firstName: getStringWithoutExtraSpaces(this.state.userProfileData.first_name),
        lastName: getStringWithoutExtraSpaces(this.state.userProfileData.last_name),
        role: this.state.userProfileData.role,
        username: this.state.userProfileData.email,
        phone: this.state.userProfileData.phone ? getPhoneNumberWithCountryCode(this.state.userProfileData.phone) : undefined
      }
    );
    this.setState({ errorMessage: "" });
    if (this.state.is_image_changed) {
      this.uploadImage(formData, data);
    } else {
      this.props.handleSaveClick(data, errorMessage => {
        this.setState({ errorMessage: errorMessage });
      });
    }
  };

  handleCancelClick = () => {
    this.setStateToProps();
    this.props.toggle();
  };

  handleInputChange = event => {
    let userProfileData = this.state.userProfileData;
    if(event.target.name === "phone") {
      userProfileData[event.target.name] = event.target.value.trim();
    }else{
      userProfileData[event.target.name] = event.target.value;
    }
    this.setState({ userProfileData: userProfileData,errorMessage:"" });
  };

  renderButtons = () => {
    if(this.props.isSSOLogin) {
      return(
        <div className="permissionButtons">
          <Button
            className="buttons borderBtn bgwhite"
            onClick={this.handleCancelClick}
          >
            {getMessage("CLOSE")}
          </Button>
        </div>
      );
    } else { 
      return (
        <div className="permissionButtons">
          <Button
            className="buttons borderBtn bgwhite mr-2"
            onClick={this.handleCancelClick}
          >
            {getMessage("CANCEL")}
          </Button>
          <Button
            onClick={this.handleSaveClick}
            className="buttons customBtn ml-2"
          >
            {getMessage("SAVE")}
          </Button>
        </div>
      );
    }
  }

  isValidData = () => {
    let UserDetail = this.state.userProfileData;
    if (!UserDetail.email) {
      this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
      return false;
    } else {
      if (!validateUserName(UserDetail.email)) {
        this.setState({ errorMessage: getMessage("INVALID_EMAIL") });
        return false;
      }
    }
    if(UserDetail.phone && !validateMobileNumber(UserDetail.phone)) {
      this.setState({ errorMessage: getMessage("VALID_PHONE") });
      return false;
    }
    if (!UserDetail.first_name) {
      this.setState({ errorMessage: getMessage("INVALID_FIRST_NAME") });
      return false;
    }
    if (!UserDetail.last_name) {
      this.setState({ errorMessage: getMessage("INVALID_LAST_NAME") });
      return false;
    }
    
    return true;
  };

  isEdited = () => {
    if (
      this.state.userProfileData.first_name !==
        this.props.userProfileData.first_name ||
      this.state.userProfileData.last_name !==
        this.props.userProfileData.last_name ||
      this.state.userProfileData.role !== this.props.userProfileData.role ||
      this.state.userProfileData.email !== this.props.userProfileData.email ||
      this.state.is_image_changed ||
      this.state.userProfileData.phone !== this.props.userProfileData.phone
    ) {
      return true;
    } else return false;
  };

  setStateToProps = () => {
    this.setState({
      userProfileData: {
        first_name: this.props.userProfileData.first_name,
        last_name: this.props.userProfileData.last_name,
        role: this.props.userProfileData.role,
        email: this.props.userProfileData.email,   
        phone: this.props.userProfileData.phone,
        status: this.props.userProfileData.status,
        id: this.props.userProfileData.id
      },
      errorMessage: "",
      image_file: "",
      logo: this.props.logo,
      image_data: "",
      is_image_changed: false
    });
  };

  handleOrgLogoChange = event => {
    if (event.target.files) {
      this.setState({
        logo: URL.createObjectURL(event.target.files[0]),
        logoUrl: event.target.files[0],
        image_data: event.target.files[0],
        is_image_changed: true
      });
    }
  };

  uploadImage = (formData, data) => {
    organisationAction.uploadOrgLogoStatusAction(formData).then(
      response_data => {
        data.logoUrl = response_data.data.data.fileUrl;
        organisationAction
        .updateUrl(response_data.data.data.fileUrl, this.props.customerId)
        .then(response_data => {});
        organisationAction.setLogoUrl(response_data.data.data.fileUrl);
        this.props.handleSaveClick(data, errorMessage => {
          this.setState({ errorMessage: errorMessage });
        });
        this.setState({ is_image_changed: false });
      },
      error => {
        this.setState({ errorMessage: error.message });
      }
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="modalMainDiv modal-width-50"
        toggle={this.handleCancelClick}
        backdrop="static"
        centered
        onOpened={this.setFocus}
      >
        <ModalHeader className="popupHeader p-2">
          {getMessage("MY_PROFILE")}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="d-flex mb-2">
              <Label className="label modal-label col-md-3 col-4">{getMessage("TYPE_OF_USER")}</Label>
              <Input
                type="text"
                name="role"
                id="role"
                className="inputFormControl col-md-9 col-7"
                onChange={this.handleInputChange}
                value={_.capitalize(getMessage(this.state.userProfileData.role))}
                disabled
              ></Input>
            </FormGroup>

            <FormGroup className="d-flex mb-2">
              <Label className="label modal-label col-md-3 col-4">
                {getMessage("EMAIL")}
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={this.handleInputChange}
                className="inputFormControl col-md-9 col-7"
                value={this.state.userProfileData.email}
                disabled
              />
            </FormGroup>
            <FormGroup className="d-flex mb-2">
              <Label className="label modal-label col-md-3 col-4">
                {getMessage("FIRST_NAME")}
                {
                  !this.props.isSSOLogin
                  && (<span className="asterisk">*</span>)
                }
              </Label>
              <Input
                type="text"
                name="first_name"
                id="firstName"
                onChange={this.handleInputChange}
                className="inputFormControl col-md-9 col-7"
                maxLength="50"
                value={this.state.userProfileData.first_name}
                disabled={this.props.isSSOLogin}
                placeholder={getMessage("FIRST_NAME_PLACEHOLDER")}
                onKeyDown={this.enterPressed}
              />
            </FormGroup>
            <FormGroup className="d-flex mb-2">
              <Label className="label modal-label col-md-3 col-4">
                {getMessage("LAST_NAME")}
                {
                  !this.props.isSSOLogin
                  && (<span className="asterisk">*</span>)
                }
              </Label>
              <Input
                type="text"
                name="last_name"
                onChange={this.handleInputChange}
                className="inputFormControl col-md-9 col-7"
                maxLength="50"
                value={this.state.userProfileData.last_name}
                disabled={this.props.isSSOLogin}
                placeholder={getMessage("LAST_NAME_PLACEHOLDER")}
                onKeyDown={this.enterPressed}
              />
            </FormGroup>
            <FormGroup className="d-flex mb-2">
              <Label className="label modal-label col-md-3 col-4">{getMessage("PHONE_NUMBER")}</Label>
              <Input
                type="phone"
                name="phone"
                id="phone"
                onChange={this.handleInputChange}
                className="inputFormControl col-md-9 col-7"
                value={this.state.userProfileData.phone}
                disabled={this.props.isSSOLogin}
                placeholder={getMessage("ENTER_PHONE_NUMBER_PLACEHOLDER")}
                onKeyDown={this.enterPressed}
              />
            </FormGroup>
            <div className="modal-errors">{this.state.errorMessage}</div>
          </Form>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}
export default modalScrollBar(UpdateProfileModal); 

