import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';

import Spinner from "COMPONENTS/Spinner/Spinner";
import toastr from "COMPONENTS/ShowTostr/ShowTostr";
import QRCodeAgents from "./QRCodeAgents";
import HeaderContainer from "COMPONENTS/SuperAdmin/Header/HeaderContainer";
import AssignAgentsToQRCode from "./AssignAgentsToQRCode";
import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";

import { getMessage } from "CONFIG/i18n";
import {  getModulesNotAllowedErrorMessage } from "UTILS/Utility";
import { CONFIG } from "UTILS/Constants";
import { ERROR } from "UTILS/Constants";

import {
  getQRCodeAgentsPageNo,
  getQRCodeAgentsPageSize, 
  getQRCodeAgentsCount,
  getQRCodeData,
  getUnassignedAgentsPageNo,
  getUnassignedAgentsPageSize, 
  getUnassignedAgentsCount,
} from "./selectors";

import { qrCodesAction, qrCodeAgentsAction} from "CONFIG/ActionFactory";

import icBack from "ASSETS/Images/ic_backarrow.svg";

const QRCodeAgentsContainer = (props) => {

  const qrCodeAgentsPageNo = useSelector(
    getQRCodeAgentsPageNo
  );
  const qrCodeAgentsCount = useSelector(getQRCodeAgentsCount);
  const qrCodeData = useSelector(getQRCodeData);
  const qrCodeAgentsPageSize = useSelector(
    getQRCodeAgentsPageSize
  );
  let unassignedAgentsPageNo = useSelector(getUnassignedAgentsPageNo);
  const unassignedAgentsCount = useSelector(getUnassignedAgentsCount);
  const unassignedAgentsPageSize = useSelector(getUnassignedAgentsPageSize);
  
  const logo = useSelector((state) => state.OrganisationReducer.logo);
  const organizationName = useSelector(
    (state) => state.OrganisationReducer.customerData.name
  );

  const [qrCodeAgentsList, setQRCodeAgentsList] = useState([]);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const [unassignedAgentsList, setUnassignedAgentsList] = useState([]);
  const [totalAgents,setTotalAgents] = useState(0);

  const location = useLocation();
  const history = useHistory();
  const [associatedAgentsCount,setAssociatedAgentsCount] = useState(0);

  const showSpinner = () => {
    setSpinnerVisibility(true);
  };

  const hideSpinner = () => {
    setSpinnerVisibility(false);
  };

  const getQRCodeAgents = (pageSize, page, search, allCheckboxesSelected  =false, callback) => {
    const limit = pageSize
      ? pageSize
      : qrCodeAgentsPageSize
        ? qrCodeAgentsPageSize
        : PAGE_COUNT[0].value;

    let request = {  
      limit: limit,
      search: search ? search : undefined,
      page: page,
    };
    showSpinner();
    qrCodeAgentsAction.getQRCodeAgents(request, qrCodeData.qrId).then(
      (response) => {
        const qrCodeAgentsList = response.data.data.qrCodeAgents;

        // Don't update reducer if all records have been fetched on selection of header checkbox
        // because we need to fetch all the records for checkbox selected and UI purpose but
        // we need to show the records in the table as per the page size 
        if (!allCheckboxesSelected ) {

          const associatedAgentsCount = response.data.data.associatedAgents;
          setAssociatedAgentsCount(associatedAgentsCount);

          const totalAgents = response.data.data.total;
          setQRCodeAgentsList(qrCodeAgentsList),

          qrCodeAgentsAction.saveQRCodeAgentsPageSize(parseInt(limit));
          qrCodeAgentsAction.saveQRCodeAgentsPageNo(page);
          qrCodeAgentsAction.saveQRCodeAgentsCount(totalAgents);
        }
        hideSpinner();

        callback && callback(qrCodeAgentsList);
      },
      (error) => {
        setQRCodeAgentsList([]);
        setAssociatedAgentsCount(0);
        toastr.error(error);
        hideSpinner();
      }
    );
  };

  const getUnassignedAgents = (pageSize, page, search, allCheckboxesSelected = false, callback) => {
    const limit = pageSize
      ? pageSize
      : unassignedAgentsPageSize
        ? unassignedAgentsPageSize
        : PAGE_COUNT[0].value;

    let request = {
      limit: limit,
      search: search ? search : undefined,
      page: page,
    };

    showSpinner();

    qrCodeAgentsAction.getUnassignedAgents(request,qrCodeData.qrId).then(
      (response) => {

        const unassignedAgents = response.data.data.unassignedAgents;

        // Don't update reducer if all records have been fetched on selection of header checkbox
        // because we need to fetch all the records for checkbox selected and UI purpose but
        // we need to show the records in the table as per the page size 
        if(!allCheckboxesSelected) {
          const totalUnassignedAgents = response.data.data.total;
          const totalAgents =response.data.data.totalAgents;
          setTotalAgents(totalAgents);

          setUnassignedAgentsList(unassignedAgents)

          qrCodeAgentsAction.saveUnassignedAgentsPageSize(parseInt(limit));
          qrCodeAgentsAction.saveUnassignedAgentsPageNo(page);
          qrCodeAgentsAction.saveUnassignedAgentsCount(totalUnassignedAgents);
        }

        hideSpinner();

        callback && callback(unassignedAgents);
      },
      (error) => {
        setUnassignedAgentsList([]);
        setTotalAgents(0);
        toastr.error(error);
        hideSpinner();
        callback && callback([]);
      }
    );
  };

  const deleteQRCode = (qrCode) => {
    showSpinner();
    qrCodesAction.deleteQRCodeAction(qrCode.qrId).then(
      (response) => {
        hideSpinner();
        handleBack();
        toastr.success("QR_CODE_DELETED_SUCCESSFULLY");
      },
      (error) => {
        hideSpinner();
        toastr.error(error);
      }
    );
  };

  const deleteQRCodeAgents = (agentIds) => {
    showSpinner();
    qrCodeAgentsAction.deleteQRCodeAgents(qrCodeData.qrId, agentIds).then(
      (response) => {
        toastr.success("QR_CODE_AGENTS_DELETED_SUCCESSFULLY");
        getQRCodeAgents(qrCodeAgentsPageSize, 0);
        hideSpinner();
      },
      (error) => {
        hideSpinner();
        toastr.error(error);
      }
    );
  };

  const getQRCodeDetails = (qrId) => {
    showSpinner();
    qrCodesAction.getQRCodeDetails(qrId).then(
      (response) => {
        hideSpinner();
        const qrCodeDetails = response.data.data.qrCode;
        qrCodesAction.saveQRCodeData(qrCodeDetails);
      },
      (error) => {
        hideSpinner();
        toastr.error(error);
      }
    );
  };

  const updateQRCode = (qrCodeInfo, callBack) => {
    showSpinner();
    const qrId = qrCodeInfo.qrId;
    const requestBody = {
      description: qrCodeInfo.description,
      label: qrCodeInfo.label,
    };
    qrCodesAction.updateQRCode(requestBody, qrId).then(
      (response) => {
        hideSpinner();
        getQRCodeDetails(qrId);
        toastr.success("QR_CODE_UPDATED_SUCCESSFULLY");
        callBack && callBack();
      },
      (error) => {
        hideSpinner();
        if (error.code == ERROR.MODULES_NOT_ALLOWED) {
          callBack && callBack(getModulesNotAllowedErrorMessage(error.errDetails));
        } else {
          callBack && callBack(getMessage(error.code));
        }
      }
    );
  };

  const addQRCodeAgents = (selectedAgents, isSelectAllCheckboxChecked) => {
    showSpinner();
    const qrId = qrCodeData.qrId;
    const agentIds = _.map(selectedAgents, 'id');
    const requestBody = {
      agentIds: agentIds
    };

    qrCodeAgentsAction.addQRCodeAgents(requestBody, qrId).then(
      (response) => {
        hideSpinner();
        handleBack();
        toastr.success("AGENTS_ASSOCIATED_SUCCESSFULLY");
      },
      (error) => {
        hideSpinner();
        toastr.error(error);
      }
    );
  }

  const redirectToAssignAgents = () => {
    history.push(CONFIG.path.assignAgentsToQRCode);
  }

  const handleBack = () => {
    history.push(CONFIG.path.admin);
  }

  const renderSpinner = () => {
    return <Spinner showSpinner={spinnerVisibility} />;
  };

  const renderComponents = () => {
    switch(location.pathname) {
      case CONFIG.path.qrCodeAgents:
        return (
          <QRCodeAgents
            selectedQRCode={qrCodeData}
            updateQRCode={updateQRCode}
            deleteQRCode={deleteQRCode}
            getQRCodeAgents={getQRCodeAgents}
            qrCodeAgentsList={qrCodeAgentsList}
            qrCodeAgentsPageSize={qrCodeAgentsPageSize}
            qrCodeAgentsPageNo={qrCodeAgentsPageNo}
            qrCodeAgentsCount={qrCodeAgentsCount}
            deleteQRCodeAgents={deleteQRCodeAgents}
            associatedAgentsCount={associatedAgentsCount}
            redirectToAssignAgents={redirectToAssignAgents}
          />
        );

      case CONFIG.path.assignAgentsToQRCode:
        return (
          <AssignAgentsToQRCode
            selectedQRCode={qrCodeData}
            unassignedAgents={unassignedAgentsList}
            unassignedAgentsPageSize={unassignedAgentsPageSize}
            unassignedAgentsPageNo={unassignedAgentsPageNo}
            unassignedAgentsCount={unassignedAgentsCount}
            addQRCodeAgents={addQRCodeAgents}
            getUnassignedAgents={getUnassignedAgents}
            redirectToAssignAgents={redirectToAssignAgents}
            handleBack={handleBack}
            totalAgents={totalAgents}
          />
        );

      default: 
        console.log("Invalid path");
        return;
    }
  }

  return (
    <>
      {renderSpinner()}
      <HeaderContainer
        title={organizationName}
        history={props.history}
        logo={logo}
        isAdmin={true}
        isDashboard={false}
        showUserDropDownModal={true}
        showHeaderButton={false}
      />
      <div className="qr-code-agents">
        <div className="margin-left backBtn mt-4 mb-2">
          <img
            src={icBack}
            className="pr-2 cursorPointer"
            onClick={handleBack}
          />
          <span className="cursorPointer" onClick={handleBack}>
            {getMessage("BACK")}
          </span>
        </div>
        {renderComponents()}
      </div>
    </>
  );
};

export default withErrorHandler(QRCodeAgentsContainer);