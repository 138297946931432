import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "LANGUAGES/en/translation.json";
import translationJA from "LANGUAGES/ja/translation.json";
import store from "REDUX/store";

import { DEFAULT_LANGUAGE } from "UTILS/Constants";

//The translations here for the Japnies and english
const resources = {
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJA
  }
};

//function that will trigger when user want to change the language
export const changeLanguage = lang => {
  let language = lang || store.getState().LanguageReducer.language || DEFAULT_LANGUAGE;
  i18n.changeLanguage(language);
};

//function to convert message in expected language
export const getMessage = (key, interpolation) => {
  return i18n.t(key, interpolation);
};

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  nsSeparator: false,
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
