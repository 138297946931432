import { ACTIONS, PARTICIPANT_STATUS, USER_ADMISSION_STATUS } from "UTILS/Constants";
import { mediaType, mediaStatus } from "WEBSOCKET/constants";
import { getMessage } from "CONFIG/i18n";

const initialState = {
  participants: [],
  latestUpdatedParticipant: null,
  sortedParticipants: [],
  currentParticipantMediaStatus: {},
  isUserEvicted: false,
  evictedParticipantDetails: {}
};
export default function(state = initialState, action) {
  let allParticipants = null;
  let currentParticipantIndex = null;
  switch (action.type) {
    case ACTIONS.UPDATE_PARTICIPANTS:
      let currentParticipants = _.cloneDeep(state.participants);
      let participantIndex = _.findIndex(
        currentParticipants,
        participant =>
          action.participant &&
          participant &&
          action.participant.uniqueId === participant.uniqueId
      );
      participantIndex == -1 ? currentParticipants.push(action.participant) 
        : currentParticipants[participantIndex] = action.participant;

      currentParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      action.participant.userName= getMessage(action.participant.userName);

      return {
        ...state,
        participants: currentParticipants,
        latestUpdatedParticipant: { ...action.participant, ...{updateReason: action.updateReason} }
      };
      // TODO: only update participant instead of adding
    case ACTIONS.SET_PARTICIPANTS:
      action.participants && action.participants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      return {
        ...state,
        participants: action.participants
      };
    case ACTIONS.DELETE_PARTICIPANT:
      currentParticipants = _.cloneDeep(state.participants);
     
      participantIndex = _.findIndex(
        currentParticipants,
        participant =>
          action.participant &&
          participant &&
          action.participant.uniqueId === participant.uniqueId
      );

      if (participantIndex !== -1) {
        currentParticipants.splice(participantIndex, 1);
      }

      currentParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      action.participant.userName= getMessage(action.participant.userName);

      return {
        ...state,
        participants: currentParticipants,
        latestUpdatedParticipant: { ...action.participant, ...{updateReason: action.updateReason} }
      };
    case ACTIONS.SET_USER_EVICTED_FLAG:
      return {
        ...state,
        isUserEvicted: action.isUserEvicted
      };
    case ACTIONS.SET_EVICTED_PARTICIPANT_DETAILS:
      return {
        ...state,
        evictedParticipantDetails: action.evictedParticipantDetails
      };
    case ACTIONS.PARTICIPANT_ADMITTED_OR_REJECTED:
      currentParticipants = _.cloneDeep(state.participants);
     
      participantIndex = _.findIndex(
        currentParticipants,
        participant =>
          action.participant &&
          participant &&
          action.participant.uniqueId === participant.uniqueId
      );

      if (participantIndex != -1) {
        if(action.participant.isAdmitted) {
          currentParticipants[participantIndex].status = PARTICIPANT_STATUS.ACTIVE;
          currentParticipants[participantIndex].admissionStatus = USER_ADMISSION_STATUS.ADMITTED;
          currentParticipants[participantIndex].userName = action.participant.userName;
        } else {
          currentParticipants[participantIndex].status = PARTICIPANT_STATUS.LEFT;
          currentParticipants[participantIndex].admissionStatus = USER_ADMISSION_STATUS.REJECTED;
          currentParticipants[participantIndex].userName = action.participant.userName;
          // currentParticipants.splice(participantIndex, 1);
        }
      }

      currentParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      action.participant.userName= getMessage(action.participant.userName);

      return {
        ...state,
        participants: currentParticipants,
        latestUpdatedParticipant: currentParticipants[participantIndex]
      };
    case ACTIONS.UPADTE_PARTICIPANT_MEDIA_TYPE:
      allParticipants = _.cloneDeep(state.participants);

      currentParticipantIndex = _.findIndex(
        allParticipants,
        participant => action.participant.uniqueId === participant.uniqueId
      );
      if (currentParticipantIndex !== -1) {
        allParticipants[currentParticipantIndex].mediaType =
          action.participant.mediaType;
      }
      
      allParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      action.participant.userName= getMessage(action.participant.userName);

      return {
        ...state,
        participants: allParticipants,
        latestUpdatedParticipant: { ...action.participant, ...{updateReason: action.updateReason} }
      };
    case ACTIONS.UPADTE_PARTICIPANT_MEDIA_STATUS:
      allParticipants = _.cloneDeep(state.participants);

      currentParticipantIndex = _.findIndex(
        allParticipants,
        participant => action.participant.uniqueId === participant.uniqueId
      );
      if (currentParticipantIndex !== -1) {
        if (action.participant.audioStatus) {
          allParticipants[currentParticipantIndex].audioStatus =
            action.participant.audioStatus;
        }
        if (action.participant.videoStatus) {
          allParticipants[currentParticipantIndex].videoStatus =
            action.participant.videoStatus;
        }
      }

      allParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });
      action.participant.userName= getMessage(action.participant.userName);

      return {
        ...state,
        participants: allParticipants,
        latestUpdatedParticipant: {...action.participant, ...{updateReason: action.updateReason}},
        currentParticipantMediaStatus: action.participant.uniqueId === action.uniqueId 
          ? {...state.currentParticipantMediaStatus, ...action.participant} 
          : state.currentParticipantMediaStatus
      };
    case ACTIONS.SET_ALLOW_EDIT:
      allParticipants = _.cloneDeep(state.participants);

      currentParticipantIndex = _.findIndex(
        allParticipants,
        participant => action.participant.participantId === participant.uniqueId
      );
      if (currentParticipantIndex !== -1) {
        allParticipants[currentParticipantIndex].allowEditAccess =
            action.participant.allowEditAccess;
      }
      return {
        ...state,
        participants: allParticipants,
        latestUpdatedParticipant: {...action.participant, ...{updateReason: action.updateReason}}
      };
    case ACTIONS.RESET_ALL_PARTICIPANT_MEDIA_STATUS:
      allParticipants = _.cloneDeep(state.participants);
      for (let index = 0; index < allParticipants.length; index++) {
        allParticipants[index].audioStatus = mediaStatus.UNMUTED;
        allParticipants[index].videoStatus = mediaStatus.UNMUTED;
        allParticipants[index].mediaType = mediaType.NONE;
      }

      allParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });

      return {
        ...state,
        participants: allParticipants,
        currentParticipantMediaStatus: initialState.currentParticipantMediaStatus
      };
    case  ACTIONS.SET_SORTED_PARTICIPANT:

      action.sortedParticipants && action.sortedParticipants.forEach(participant => {
        participant.userName= getMessage(participant.userName)
      });

      return {
        ...state,
        sortedParticipants: action.sortedParticipants,
      };
    case ACTIONS.CO_BROWSE.CO_BROWSE_PARTICIPANT_STATUS_UPDATE:
      allParticipants = _.cloneDeep(state.participants);

      currentParticipantIndex = _.findIndex(
        allParticipants,
        participant => action.uniqueId === participant.uniqueId
      );
      if (currentParticipantIndex !== -1) {
        allParticipants[currentParticipantIndex].cobrowseStatus =
          action.value;
      }
      return {
        ...state,
        participants: allParticipants
      };
    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE:
      allParticipants = _.cloneDeep(state.participants);

      currentParticipantIndex = _.findIndex(
        allParticipants,
        participant => action.uniqueId === participant.uniqueId
      );
      if (currentParticipantIndex !== -1) {
        allParticipants[currentParticipantIndex].customerCoBrowseStatus =
          action.value;
      }
      return {
        ...state,
        participants: allParticipants
      };
    case ACTIONS.CO_BROWSE.RESET_CO_BROWSE_SESSION_DETAILS :  
      allParticipants = _.cloneDeep(state.participants);
      for (let index = 0; index < allParticipants.length; index++) {
        allParticipants[index].cobrowseStatus = false;
      }
      return {
        ...state,
        participants: allParticipants
      };
    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_END_SESSION:
      allParticipants = _.cloneDeep(state.participants);
      for (let index = 0; index < allParticipants.length; index++) {
        allParticipants[index].customerCoBrowseStatus = false;
      }
      return {
        ...state,
        participants: allParticipants
      };
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return initialState;
    default:
      return state;
  }
}