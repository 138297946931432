import React from "react";
import { getMessage } from "CONFIG/i18n"
import "./NoData.less"
import propTypes  from "prop-types";

const NoData = (props) => {
  return (
    <div className="d-flex justifyContentFlexCenter flexDirectionColumn alignItemsCenter no-data-msg overflow-hidden" style={{minHeight:props.minHeight }}>
      <span className=" d-flex justifyContentFlexCenter pt-2">{props.message ? props.message : getMessage("NO_INFORMATION") }</span>
    </div>
  );
};
NoData.propTypes  = {
  minHeight: propTypes.string,
  message: propTypes.string
};

NoData.defaultProps  = {
  minHeight: "100vh",
  message: null

};
export default NoData;