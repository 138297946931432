import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";
import { isIE, isFirefox } from "react-device-detect";

import {
  ERROR,
  SCHEDULED_SESSION_MESSAGE,
  USER_ADMISSION_MESSAGE,
  SESSION_STATUS,
  USER_ADMISSION_STATUS,
} from "UTILS/Constants";

import { Row, Col } from "reactstrap";
import session_img from "../../Assets/Images/ic_start_session.svg";
import HeaderContainer from "COMPONENTS/Header/HeaderContainer";
import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";

import "./UserWaiting.less";

class UserWaiting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  componentDidMount() {
    // set if present in url
  }
  
  componentWillUnmount() {
    console.log("UserWaiting componentWillUnmount");
  }
  
  getUserMessageToDisplay = (sessionStatus, userStatus) => {
    switch (userStatus) {
      case USER_ADMISSION_STATUS.PENDING:
        if(sessionStatus === SESSION_STATUS.ACTIVE ||
          sessionStatus === SESSION_STATUS.WAITING ||
          sessionStatus === SESSION_STATUS.CREATED ||
          sessionStatus === SESSION_STATUS.CLOSING ||
          sessionStatus === undefined){
          return getMessage(SCHEDULED_SESSION_MESSAGE[SESSION_STATUS.WAITING])
        }
        if(sessionStatus === SESSION_STATUS.DELETED){
          return getMessage(SCHEDULED_SESSION_MESSAGE[SESSION_STATUS.DELETED])
        }
        if(sessionStatus === SESSION_STATUS.UPDATED){
          return getMessage(SCHEDULED_SESSION_MESSAGE[SESSION_STATUS.UPDATED])
        }
        break;
      case USER_ADMISSION_STATUS.REJECTED:
        return (sessionStatus === SESSION_STATUS.ACTIVE 
        ||   sessionStatus === SESSION_STATUS.CREATED ||
        (this.props.sessionType === "QR_CODE" && sessionStatus === SESSION_STATUS.WAITING)
          ? getMessage(USER_ADMISSION_MESSAGE[USER_ADMISSION_STATUS.REJECTED])
          : "")
      default: 
        return ""
    }
  }

  shouldShowLoader = () => {
    return (
      (this.props.sessionStatus === undefined ||
      this.props.sessionStatus === SESSION_STATUS.WAITING ||
      this.props.sessionStatus === SESSION_STATUS.CREATED ||
      this.props.sessionStatus === SESSION_STATUS.ACTIVE ||
      this.props.sessionStatus === SESSION_STATUS.CLOSING)
      && this.props.userAdmissionStatus === USER_ADMISSION_STATUS.PENDING
    );
  }

  render() {
    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="userWaiting joinSession">
          <section className="bgColor displayFlex flexDirectionColumn">     

            <ChangeLanguage
              onSelectChange={this.props.onSelectChange}
              language={this.props.language}
            />

            <div className="px-2 pb-2">
              <Row className="noMargin text-center pt-5 justifyContentFlexCenter w-100">
                <img
                  src={session_img}
                  className="img-responsive session-img"
                  alt={getMessage("WAITING_ROOM")}
                />
              </Row>
              <Row className="noMargin justifyContentFlexCenter w-100 text-center">
                <p className="sessionName">{/* {getMessage("WAITING_ROOM")} */}</p>
              </Row>
              <div className="flexDirectionColumn noMarginForMobile inputForm inputFormForWaiting justifyContentFlexCenter alignItemsCenter mx-5">
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <span className="userWaitingHeading">
                    {this.getUserMessageToDisplay(this.props.sessionStatus, this.props.userAdmissionStatus) }
                  </span>
                  <span className="roundLoaderContainer">
                    { this.shouldShowLoader() && (
                      <div className="roundLoader"></div>
                    )}
                  </span>
                  <br />
                  <span className="sessionName">
                    {this.props.userSessionName}
                  </span>
                </div>

                <Col xs={12} md={12} className="text-center">
                  <div className="errors">
                    {getMessage(this.state.errorMessage)}
                  </div>
                </Col>
              </div>
            </div>  
            {/* {!isIE && !isFirefox && (
              <div
                className="testPageLink cursorPointer text-center"
                onClick={() => {
                  window.open("/testPage", "_blank");
                }}
              >
                {getMessage("TEST_PAGE")}
              </div>
            )} */}
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default UserWaiting;
