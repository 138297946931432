import * as log from "loglevel";
import { ACTIONS, CO_BROWSE_SESSION_STATUS } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";
log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.CUSTOMER_CO_BROWSE._PERMISSION_REQUEST:
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_REQUEST,
        value: true
      });
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_DATA,
        roomId: data.roomId,
        shareUrl: data.shareUrl
      })
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
        uniqueId: store.getState().RoomMetaDataReducer.roomMetaData.presenterId,
        value: true
      })
      break;

    case socketMessage.subCategories.CUSTOMER_CO_BROWSE._PERMISSION_REPLY:
      if (data && data.uniqueId && !data.isGranted) {
        store.dispatch({
          type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PERMISSION_DENIDED,
          value: data.uniqueId
        });
      }
      break;

    case socketMessage.subCategories.CUSTOMER_CO_BROWSE._PARTICIPANT_ARRIVED:
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_JOIN_SESSION,
        value: data.uniqueId
      });
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
        uniqueId: data.uniqueId,
        value: true
      });
      break;
    case socketMessage.subCategories.CUSTOMER_CO_BROWSE._END_SESSION:
      store.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_END_SESSION
      });
      break;

    //is PARTICIPANT_LEFT call
    case socketMessage.subCategories.CUSTOMER_CO_BROWSE._LEAVE_SESSION:
      customerCoBrowseAction.leaveCustomerCoBrowse(data.uniqueId)
      break;
  }
};
