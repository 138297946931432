import validator from "validator";

import { loginRegex, REGEX } from "./Constants";
import { getMessage } from "CONFIG/i18n";
import { isEmailValid } from "UTILS/Utility";

export const validateUserName = userName => {
  /* older username validation code
  var re = loginRegex.USERNAME_REGEX;
  if (!re.test(userName)) {
    return false;
  }
  return true;*/
  return isEmailValid(userName) ? true : false;
};

export const validatePassword = password => {
  var re = loginRegex.PASSWORD_REGEX;
  if (!re.test(password)) {
    return false;
  }
  return true;
};

export const validateMobileNumber = mobileNumber => {
  var re = loginRegex.NUMBER_REGEX;
  if (!re.test(mobileNumber)) {
    return false;
  }
  return true;
};

export const validateSetPassword = (newPassword, confirmPassword) => {
  let regx = loginRegex.PASSWORD_REGEX;
  // "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,15}$";

  if (newPassword.length < 6 || newPassword.length > 15) {
    return "PASSWORD_LENGTH_MSG";
  }
  if (newPassword !== confirmPassword) {
    return "NEW_PASSWORD_CONFIRM_PASSWORD_MISMATCH";
  }
  if (!newPassword.match(regx)) {
    return "PASSWORD_RULE_MSG";
  }
};

export const validateString = str => {
  /* if string contains numbers then return false otherwise true
  params str:
  returns true/false: 
  */
  if (validator.isAlpha(str)) {
    return true;
  }
  return false
};

export const validateNumber = number => {
  /* function checks input parameter is number or not
  params number:
  returns true/false:
  */
  let strnumber = number !== undefined ? number.toString() : "";
  if(validator.isNumeric(strnumber)){
    return true;
  }
  return true;
}

export const isURL = (str) => {
  let string = str.toLowerCase();
  return string.match(REGEX.URL)
} 
 
export const validateSpecialCharacters = (string) => {
  return (!string.includes("{") && !string.includes("}") && !string.includes("\'") && !string.includes("\\") &&
  !string.includes(">") && !string.includes("<") && !string.includes("[") && !string.includes("]") &&
   !string.includes(")") && !string.includes("(") && !string.includes("|") && !string.includes("`") && !string.includes("^")) 
}

export const validateCobrowseCustomURL = (string) => {  
  var isvalidURL = validator.isURL(string, { protocols : ['http','https'],
    require_protocol : true,
    allow_trailing_dot : false,
    allow_underscores: false
  })
  var specialCharacters = "', \", >, <, }, {, \, [, ], (, ), |, `, ^";

  return (string.match(REGEX.CO_BROWSE_CUSTOM_URL) && isvalidURL && validateSpecialCharacters(string)) ? true : getMessage("ERR_MSG_CUSTOM_URL",{specialCharacters: specialCharacters})
}

export const validateInvitees =(string) => {
  if(REGEX.INVITEES_REGEX.test(string)){
    return true;
  }
  return false;
}