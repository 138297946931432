import _ from "lodash";
import { ACTIONS, CO_BROWSE_SESSION_STATUS } from "UTILS/Constants";

const initialState = {
  customerCoBrowsePermissionRequest: null,
  customerCoBrowsePermissionReply: null,
  participantLeftCustomerCoBrowseUniqueId: "",
  participantJoinedCustomerCoBrowseUniqueId: "",
  participantDeniedCustomerCoBrowseUniqueId: "",
  roomId: null,
  shareUrl: "",
  // this flag is used to show CB container 
  // It is set when user accepts CB request and on success of call api on presenter side
  showCustomerCoBrowse: false,
  showTargetSiteModal: false,
  // customerCoBrowseStarted is used to determine if co browse is started in the session
  // this flag is set to true as soon as user receives CB request
  // so this flag will be set even if user declines permission
  // It is used to disable snapshot icon when CB is running
  customerCoBrowseStarted: false
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_REQUEST:
      return { 
        ...state, 
        customerCoBrowsePermissionRequest: action.value, 
        customerCoBrowsePermissionReply: null
      };
    case ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_REPLY:
      return { ...state, customerCoBrowsePermissionReply: action.value };

    case ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_DATA:
      return{ ...state, roomId: action.roomId, shareUrl: action.shareUrl, customerCoBrowseStarted: true }

    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PERMISSION_DENIDED:
      return{ ...state, participantDeniedCustomerCoBrowseUniqueId: action.value }

    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_LEAVE_SESSION:
      return{ ...state,
        participantLeftCustomerCoBrowseUniqueId: action.value ,
        endCustomerCBSession: false ,
        roomId: null,
        shareUrl: ""
      }

    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_JOIN_SESSION:
      return{ ...state, participantJoinedCustomerCoBrowseUniqueId: action.value }

    case ACTIONS.CUSTOMER_CO_BROWSE.SHOW_CUSTOMER_CO_BROWSE:
      return{...state, showCustomerCoBrowse: action.value}
    case ACTIONS.LEAVE_SESSION_LOCAL:
      let resetState = _.cloneDeep(initialState);
      if(state.showCustomerCoBrowse){
        resetState.endCustomerCBSession = true
        resetState.showCustomerCoBrowse = state.showCustomerCoBrowse,
        resetState.roomId = state.roomId,
        resetState.shareUrl = state.shareUrl
      }
      return resetState
    case ACTIONS.CUSTOMER_CO_BROWSE.SHOW_TARGET_SITE_SELECTION_MODAL:
      return { ...state, showTargetSiteSelectionModal: action.value }
    case ACTIONS.SET_END_SESSION_FLAG:
    case ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_END_SESSION:
      return initialState
    default:
      return state;
  }
}
