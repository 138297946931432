export const getAppEmulationPermission = state => state.AppEmulationReducer.appEmulationPermissionRequest
export const getAppEmulationPermissionReply = state => state.AppEmulationReducer.appEmulationPermisssionReply
export const getAppEmulationParticipants = state => state.AppEmulationReducer.participants
export const getparticipantLeftUniqueId = state => state.AppEmulationReducer.participantLeftUniqueId
export const getparticipantJoinedUniqueId = state => state.AppEmulationReducer.participantJoinedUniqueId
export const getisSessionEnded = state => state.AppEmulationReducer.isSessionEnded
export const getparticipantDeniedUniqueId = state => state.AppEmulationReducer.participantDeniedPermissionUniqueId
export const getAppEmulationSessionActive = state => state.AppEmulationReducer.isAppEmulationSessionActive
export const getAppEmulationIFrameUrl= state => state.AppEmulationReducer.iframeUrl
export const getAppEmulationSelectedDeviceInfo = state => state.AppEmulationReducer.selectedDeviceInfo
