import React, { Component } from "react";
import {Button} from 'reactstrap';
import { connect } from "react-redux";

import TestCases from "./TestCases";
import TurnServerForm from "./TurnServerForm";

import { selectTurnServerData } from "../Video/selectors";

class TestPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startTest: false,
      username: null,
      password: null,
      urls: null,
      transport: "udp"
    }
    const turnServer = this.props.turnServer[0];
    console.log("Turn server details - ",turnServer.password,turnServer.username,turnServer.host,turnServer.port );
  }

  startTestBtnClick = () => {
    let transport='udp';
    document.getElementsByName('transportProtocol').
    forEach((ele)=>{
      if(ele.checked) 
        transport=ele.value; 
    });
    this.setState({
      startTest: true,
      // username: document.getElementById('username').value,
      // password: document.getElementById('password').value,
      // urls: document.getElementById('url').value,
      // transport: transport
    })
  }

  render() {
    const turnServer = this.props.turnServer[0];
    return (
      <div className="testPage">
        <div className="turnServer">
          {/* <TurnServerForm 
            disabled={this.state.startTest}
          />

          <div className="turnIP">
            turn:{window._env_.REACT_APP_TURN_HOST}:{window._env_.REACT_APP_TURN_PORT}?transport=tcp
          </div> */}
          <Button 
            onClick={this.startTestBtnClick}
            className="customBtn startTestBtn"
            disabled={this.state.startTest}
          > 
            Start Test 
          </Button>
        </div>
        <TestCases
          startTest={this.state.startTest}
          settings={
            {
              turnUsername: turnServer.username,
              turnCredential: turnServer.password,
              turnURI: turnServer.host+":"+turnServer.port,
            }
          }
          testsCompleted={() => {
            this.setState({startTest: false})
          }}
          turnIP={"turn"+":"+turnServer.host+":"+turnServer.port}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    turnServer: selectTurnServerData(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(TestPage);