import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { getMessage } from "CONFIG/i18n";
import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";

// Component
import UpdateProfileModal from "./UpdateProfileModal";

//Actions
import { userProfileAction } from "CONFIG/ActionFactory";

// Selectors
import { getCustomerId } from "../MainLayout/selectors";

class UpadteProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userProfileData: ""
    };
  }

  componentDidMount() {
    this.setState({ userProfileData: this.props.userProfileData })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.userProfileData !== this.props.userProfileData) {
      this.setState({ userProfileData: this.props.userProfileData })
    }
  }

  handleSaveClick = (userInfo, callBackForError) => {
    userProfileAction.updateUserProfileAction(userInfo).then(
      response => {
        this.setState({ userProfileData: response.data.data });
        this.props.toggle();
        this.props.getAllUserData ? this.props.getAllUserData() : null;
        this.props.getUserProfile();
        toastr.success("PROFILE_UPDATED_SUCCESSFULLY");
      },
      error => {
        callBackForError(error.message);
      }
    );
  };

  render() {
    return (
      <>
        <UpdateProfileModal
          show={this.props.show}
          userProfileData={this.state.userProfileData}
          toggle={this.props.toggle}
          handleSaveClick={this.handleSaveClick}
          customerId={this.props.customerId}
          logo={this.props.logo}
          isSSOLogin={this.props.isSSOLogin}
        />
      </>
    );
  }

  componentWillUnmount() {
    //toastr.removeByType("success");
    //toastr.removeByType("error");
    toast.dismiss();
  }
}

const mapStateToProps = state => {
  return {
    customerId: getCustomerId(state),
    logo: state.OrganisationReducer.logo, 
    isSSOLogin: state.UserReducer.userDetails.isSSOLogin
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withErrorHandler(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpadteProfileContainer));
