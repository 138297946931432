import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Header from "./Header";

import UpdateProfileContainer from "../UpdateProfile/UpdateProfileContainer";
import ChangePasswordContainer from "../ChangePassword/ChangePasswordContainer";

import * as log from "loglevel";
import { getMessage } from "CONFIG/i18n";

import { getUserName, getUserRole } from "../../Routes/selectors";

// CONSTANTS
import { CONFIG } from "UTILS/Constants";
import { ERROR, USER_ROLES } from "UTILS/Constants";
import { getSSOLogoutUrl } from "UTILS/Utility";

import { logoutAction, organisationAction, userProfileAction } from "CONFIG/ActionFactory";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

// Selectors
import { getCustomerId } from "../MainLayout/selectors";

import Spinner from "COMPONENTS/Spinner/Spinner";

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserProfileModal: false,
      userProfileData: {},
      showChangePasswordModal: false,
      spinnerVisibility: false
    };
  }
  componentDidMount() {
    this.props.userRole === USER_ROLES.AGENT && this.getCustomerInfo();
    this.props.setLanguageAction(this.props.language);
    if (this.props.userRole === USER_ROLES.AGENT ||
      this.props.userRole === USER_ROLES.SUPER_ADMIN ||
      this.props.userRole === USER_ROLES.ADMIN) {
      this.getUserProfile();
    }
    if(this.props.isSystemPassword){
      this.toggleChangePasswordModal();
    }
  }

  getCustomerInfo = () => {
    this.setState({spinnerVisibility: true});
    organisationAction.getCustomersAction(this.props.customerId).then(
      responseObject => {  
        this.setState({spinnerVisibility:false});
      }, error => {
        console.error("Error getting customer info", error);
        this.setState({spinnerVisibility: false});
      });
  };

  handleLogout = () => {
    if (this.props.isSSOLogin) {
      let ssoLogoutUrl = getSSOLogoutUrl(this.props.ssoDetails);
      window.location.assign(ssoLogoutUrl);
    } 
    else{
      logoutAction.logoutAction().then(
        data => {  
          this.props.history.push(CONFIG.path.login);     
        },
        err => {
          toastr.error(err.code);
          log.error("Error:", err);
        }
      );
    };
  }

  getUserProfile = () => {
    userProfileAction.getUserProfileAction().then(
      response => { },
      error => {
        console.error('Error in getUserProfile', error);
      }
    );
  };

  handleUpdateProfileClick = () => {
    this.setState({ showUserProfileModal: !this.state.showUserProfileModal });
  };

  toggleChangePasswordModal = () => {
    this.setState({ showChangePasswordModal: !this.state.showChangePasswordModal });
  }

  render() {
    return (
      <>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        <Header
          userProfileData={this.props.userProfileData}
          handleLogout={this.handleLogout}
          userName={this.props.userName}
          userRole={this.props.userRole}
          logo={this.props.logo}
          toggleChangePasswordModal={this.toggleChangePasswordModal}
          handleUpdateProfileClick={this.handleUpdateProfileClick}
          isSSOLogin={this.props.isSSOLogin}
          language={this.props.language}
        />

        <UpdateProfileContainer
          show={this.state.showUserProfileModal}
          toggle={this.handleUpdateProfileClick}
          getUserProfile={this.getUserProfile}
          userRole={this.props.userRole}
          userProfileData={this.props.userProfileData}
        />

        <ChangePasswordContainer
          show={this.state.showChangePasswordModal}
          toggleModal={this.toggleChangePasswordModal}
          isSystemPassword={this.props.isSystemPassword}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: getUserName(state),
    userRole: getUserRole(state),
    language: state.LanguageReducer.language,
    logo: state.OrganisationReducer.logo,
    ssoDetails: state.UserReducer.ssoDetails,
    isSSOLogin: state.UserReducer.userDetails.isSSOLogin,
    isSystemPassword: state.UserReducer.userDetails.isSystemPassword,
    customerId: getCustomerId(state),
    userProfileData: state.UserReducer.userProfileData,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setLanguageAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
