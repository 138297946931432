import axios from 'axios'
import { TIMEOUT } from "UTILS/Constants";

export const axiosInstance = axios.create({
  baseURL: window._env_.REACT_APP_LOOOKIT_SERVICES_BASE_URL,
  timeout: TIMEOUT.API_REQUEST_TIMEOUT
})

export const getAxiosCoBrowseInstance =(baseURL)=>
  axios.create({
    baseURL,
    timeout: TIMEOUT.API_REQUEST_TIMEOUT
  })

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED_USER: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  PAYLOAD_TOO_LARGE: 413,
  BAD_GATEWAY: 502,
}
