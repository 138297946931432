import React from "react";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { toast } from 'react-toastify';

import SetPassword from "./SetPassword";

import { getURLParameter } from "UTILS/Utility";
import { getMessage, changeLanguage} from "CONFIG/i18n";

import { setPasswordAction } from "CONFIG/ActionFactory";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

// CONSTANTS
import { TIMEOUT, ERROR } from "UTILS/Constants";

import "../ResetPassword/ResetPassword.less";
class SetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      errorMessage: "",
      networkError: "",
      token: null,
      isPasswordSet: false
    };
  }

  componentDidMount() {
    let token = getURLParameter("token");
    if (token) {
      this.setState({ token: token });
    }
    let language = getURLParameter("language");
    if(language) {
      this.props.setLanguageAction(language);
      changeLanguage(language);
    }
  }

  handleSetPassword = (data, callback) => {
    data.token = this.state.token;
    setPasswordAction.setPasswordAction(data).then(
      () => {
        toastr.success("PASSWORD_SET");
        if (callback) callback("success");
        setTimeout(() => {
          this.props.history.push("/");
        }, TIMEOUT.SHORT);
      },
      (error) => {
        if (callback) {
          if (error.code === ERROR.INVALID_TOKEN)
            error.code = "INVALID_LINK";
          callback(error);
        }
      }
    );
  };

  displayError = message => {
    toastr.error(message);

    setTimeout(() => {
      this.props.history.push("/");
    }, TIMEOUT.SHORT);
  };

  render() {
    return (
      <div>
        {this.state.token ? (
          <SetPassword
            handleSetPassword={this.handleSetPassword}
            errorMessage={this.state.errorMessage}
            username={this.state.username}
            setLanguageAction={this.props.setLanguageAction}
            language={this.props.language}
          />
        ) : null}
      </div>
    );
  }

  componentWillUnmount() {
    //toast.removeByType("success");
    //toast.removeByType("error");
    toast.dismiss()
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ setLanguageAction }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetPasswordContainer);

