import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"

//component
import SessionManagement from "./SessionManagement";
import Spinner from "COMPONENTS/Spinner/Spinner";

//Actions
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";
import { CONFIG } from "UTILS/Constants";
import { getTimeInMillisec } from "UTILS/DateUtils";
import { sessionActions } from "CONFIG/ActionFactory";
import { disableBrowseBackBtn } from "UTILS/Utility";

export class SessionManagementContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false
    }
  }

  componentDidMount() {
    console.log(`timestamp: Landed on Agent Dashboard at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
    // // to avoid back click on create session 
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
  }

  showStartMeeting = () => {
    this.props.history.push(CONFIG.path.startMeeting)
  }

  showScheduleMeeting = () => {
    this.props.history.push(CONFIG.path.scheduleMeeting)
  }

  showTrailHistory = () => {
    this.props.history.push(CONFIG.path.trailHistory)
  }

  render() {
    return (
      <React.Fragment>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        <SessionManagement
          setLanguageAction={this.props.setLanguageAction}
          language={this.props.language}
          showStartMeeting={this.showStartMeeting}
          showScheduleMeeting={this.showScheduleMeeting}
          showTrailHistory={this.showTrailHistory}
          isSessionEndedForcefully={this.props.isSessionEndedForcefully}
          sessionActions={sessionActions}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    isSessionEndedForcefully: state.SessionReducer.isSessionEndedForcefully,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLanguageAction
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionManagementContainer)
