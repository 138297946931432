import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Utilities
import { getModulesNotAllowedErrorMessage, removeTrailingSlash } from "UTILS/Utility";
import { ERROR, CONFIG, QR_SESSION_JOIN_TYPE } from "UTILS/Constants";

// Components
import ErrorPage from "COMPONENTS/ErrorComponents/ErrorPage";
import Spinner from "COMPONENTS/Spinner/Spinner";

//Actions
import { setQRSessionJoinType} from "COMPONENTS/QRCode/QRCodeComponent/QRCodeAction"
import { setQRCodeEnabled } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeAction";

import { sessionActions } from "CONFIG/ActionFactory";
import { getMessage, changeLanguage } from "CONFIG/i18n";

class AgentLandingPage extends Component {

  constructor(props){
    super(props);

    this.state={
      error:"",
      spinnerVisibility: false
    };
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.setQRSessionJoinType(QR_SESSION_JOIN_TYPE.QR_SESSION_LIST)
    }
    else {
      this.props.setQRSessionJoinType(QR_SESSION_JOIN_TYPE.EMAIL)
    }
    let urlPath = removeTrailingSlash(window.location.pathname).split("/");
    let qrCodeJoinUrl=CONFIG.path.startSession;
    console.log(`Agent Landing Page Url Path ${urlPath}`);

    let sessionKey = urlPath[1];
    let agentId = urlPath[2];
    let language=urlPath[3];

    // To display messages in that language which is added in URL
    sessionActions.setLanguage(language);
    changeLanguage(language);

    this.setState({spinnerVisibility: true});

    sessionActions.getQRCodeLandingPageDetails(sessionKey,agentId).then(response=>{
      let token=response.data.token;
      let firstName=response.data.firstName;
      let lastName=response.data.lastName;

      qrCodeJoinUrl=qrCodeJoinUrl+"?sessionKey="+ sessionKey+"&token="+ token + "&language="+ language + "&firstname=" +firstName + "&lastname=" +lastName;
      
      this.props.history.push(qrCodeJoinUrl);
      this.setState({
        spinnerVisibility:false
      })
    },
    error=>{
      this.setState({
        error: error,
        spinnerVisibility: false,
      })
    }
    );   
  }
  
  goBack=()=>{
    this.props.history.push(CONFIG.path.createSession)
  }

  renderErrorPage = () => {
    const errorCode = this.state.error?.code;
    let errorMessage;
    const isBackButtonEnabled = this.props.isAuthenticated &&
      errorCode !== ERROR.USER_DELETED &&
      errorCode !== ERROR.CUSTOMER_NOT_FOUND &&
      errorCode !== ERROR.LICENSE_KEY_EXPIRED_AGENT;
    if (errorCode === ERROR.MODULES_NOT_ALLOWED) {
      this.props.setQRCodeEnabled(false)
      errorMessage = getModulesNotAllowedErrorMessage(this.state.error.errDetails);
    } else {
      errorMessage = getMessage(errorCode)
    }
    return (
      errorMessage &&
      <ErrorPage
        isBackButtonEnabled={isBackButtonEnabled}
        goBack={this.goBack}
        message={errorMessage} />
    );
  }

  render() {
    return (
      <div>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        {this.state.error?.code && this.renderErrorPage()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setQRSessionJoinType,
      setQRCodeEnabled
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(AgentLandingPage);