import { ACTIONS, SUPER_ADMIN_TABS } from "UTILS/Constants";

const initialState = {
  logo: {
    loading: true,
    url: null
  },
  customerData:{},
  currentActiveTab: SUPER_ADMIN_TABS.ORGANIZATIONS,
  sessionKey: null,
  Organization:null,
  sessionsCapacity: {
    systemMaxCapacity: 0,
    usedCapacity: 0
  },
  cobrowseSettings: {
    cobrowseType: "",
    // Default true when target sites are absent
    allowCustomUrl: true,
    customUrlLabels: [], 
    targetSites: [],
  }, 
  customerSettings: {
    showLogoOnCanvasStatus: true,
    allowAnonymousParticipants: false,
    showChatPopup: false,
    qrCodeWaitingTime: 0,
    isSnapshotScanCodeEnabled: false
  },
  appEmulationSettings: {
    showDeviceInfo: false,
  },
  isChatGPTEnabled: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_LOGO:
      window.URL.revokeObjectURL(state.logo.url);
      return {
        ...state,
        logo: action.logoData
      };
    case ACTIONS.RESET_LOGO:
      window.URL.revokeObjectURL(state.logo.url);
      return {
        ...state,
        logo: initialState.logo
      };
    case ACTIONS.SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.customerData
      };
    case ACTIONS.ORGANISATION_ACTION.SET_ACTIVE_TAB:
      return{
        ...state,
        currentActiveTab:action.value
      };
    case ACTIONS.ORGANISATION_ACTION.SET_ORGANIZATION_DATA:
      return{
        ...state,
        Organization:action.organization
      };
    case ACTIONS.SET_SESSIONS_CAPACITY:
      return{
        ...state,
        sessionsCapacity:action.sessionsCapacity
      };
    case ACTIONS.CO_BROWSE.SET_CO_BROWSE_DETAILS:
      return{
        ...state,
        cobrowseSettings: _.isEmpty(action.cobrowseSettings) ? initialState.cobrowseSettings : action.cobrowseSettings
      }
    case ACTIONS.APP_EMULATION.SET_APP_EMULATION_SETTINGS:
      return {
        ...state,
        appEmulationSettings: {
          ...state.appEmulationSettings,
          showDeviceInfo: action.showDeviceInfo
        }
      };  
    case ACTIONS.SET_CUSTOM_SETTINGS:
      let customerSettings = state.customerSettings;
      if(action.showLogoOnCanvasStatus != undefined) {
        customerSettings.showLogoOnCanvasStatus = action.showLogoOnCanvasStatus;
      }
      if(action.allowAnonymousParticipants != undefined) {
        customerSettings.allowAnonymousParticipants = action.allowAnonymousParticipants;
      }
      if(action.showChatPopup !== undefined) {
        customerSettings.showChatPopup = action.showChatPopup;
      }
      if (action.qrCodeWaitingTime !== undefined) {
        customerSettings.qrCodeWaitingTime = action.qrCodeWaitingTime;
      }
      if (action.isSnapshotScanCodeEnabled !== undefined) {
        customerSettings.isSnapshotScanCodeEnabled = action.isSnapshotScanCodeEnabled;
      }
      return{
        ...state,
        customerSettings : customerSettings
      };
    case ACTIONS.QR_CODES.SET_ORGANIZATION_QR_LIMIT:
      return {
        ...state,
        customerData: {
          ...state.customerData,
          qr_limit: action.value
        }
      };

    case ACTIONS.CHAT.SET_CHAT_GPT_VALUE:
      return {
        ...state,
        isChatGPTEnabled: action.isChatGPTEnabled
      }

    case ACTIONS.ALLOW_UPLOAD:
      return {
        ...state,
        allowUpload: action.allowUpload
      }

    default:
      return state;
  }}