import React from "react";
import { getMessage, changeLanguage } from "CONFIG/i18n";
import { supportedLanguages, DEFAULT_LANGUAGE, LANGUAGE } from "UTILS/Constants";
import { Row } from "react-bootstrap";
import { FormGroup, Input, Label } from "reactstrap";
import "./ChangeLanguage.less";

import icRadioButtonSelected from "ASSETS/Images/ic_radio_button_selected.svg";
import icRadioButtonUnselected from "ASSETS/Images/ic_radio_button_unselected.svg";

function ChangeLanguage(props) {
  const onSelectChange = event => {
    let selectedLanguage = event.target.value.startsWith(LANGUAGE.JA.name)
      ? LANGUAGE.JA.name : event.target.value;
    changeLanguage(selectedLanguage);
    props.onSelectChange(selectedLanguage);

    document.getElementById("lang-id").setAttribute("content", selectedLanguage);
    document.documentElement.setAttribute('lang', selectedLanguage);
  };

  return (
    <Row className="noMargin languageSelection mt-2">
      {Object.keys(supportedLanguages).map(lang => {
        return <FormGroup check key={lang}>
          <Label check className="cursorPointer">
            <Input
              className="cursorPointer"
              type="radio"
              // Workaround for WAAG-5056 Language selected on the radio button remains unchanged till
              // the page is refreshed when Agent/Admin tries to change the browser language on Login page.

              // Ideally, the radio group must share the same 'name' (the value of the name attribute) to be treated as a group. 
              // Once the radio group is created, selecting any radio button in that group automatically deselects any other selected radio button in the same group.

              // But in case of changing language from the browser settings, 
              // some browsers get relaunched and at that point even though the html for the radio buttons gets generated correctly, the UI shown is incorrect.
              // E.g. current language is English, change to Japanese from browser settings, browser gets relaunched, the page is rendered in Japanese,
              // but radio button for English is selected although the html shows 'checked' for Japanese.

              // Currently the only workaround observed is to use math.random or timestamp for the radio button name. Other different names like ja/en, 1/2.. didn't work.

              // TODO: Investigate the reason why math.random is working and what is the correct fix.
              name={Math.random()}
              value={lang}
              onChange={onSelectChange}
              checked={
                props.language ? props.language === lang : lang === DEFAULT_LANGUAGE
              }
            />
            <img src={props.language === lang ? icRadioButtonSelected :icRadioButtonUnselected} alt="option"/>
            {supportedLanguages[lang]}
          </Label>
        </FormGroup>
      })}
    </Row>

  );
}
export default ChangeLanguage;
