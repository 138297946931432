import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";
import { Modal, ModalHeader, ModalBody, Col } from "reactstrap";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { customerCoBrowseAction } from "CONFIG/ActionFactory";

import icRadioButtonSelected from "ASSETS/Images/ic_radio_button_selected.svg";
import icRadioButtonUnselected from "ASSETS/Images/ic_radio_button_unselected.svg";
import { validateCobrowseCustomURL } from "UTILS/regexValidations";

import { checkEmpty } from "UTILS/Utility";

import "./CustomerCoBrowse.less"

class TargetSiteSelectionModal extends Component {
  constructor(props){
    super(props)
    this.state={
      targetUrl: "",
      isCustomUrl: false,
      errorMsg: ""
    }
  }

  changeURLSelection=(targetUrl, isCustomUrl)=> {
    this.setState({
      targetUrl,
      isCustomUrl,
      errorMsg: ""
    })
  }
  changeURL=(event)=>{
    this.setState({
      errorMsg: "",
      targetUrl: event.target.value.trim()
    })
  }
  cancelCoBrowse =()=> {
    customerCoBrowseAction.showTargetSiteSelectionModalAction(false);
  }

  handleBookCoBrowse =()=>{
    if(this.props.isSnapshotStarted) {
      toastr.error("CUSTOMER_CO_BROWSE_CANNOT_USE_WITH_SNAPSHOT")
      customerCoBrowseAction.showTargetSiteSelectionModalAction(false);
      return;
    }

    if(!checkEmpty(this.state.targetUrl)){ 
      var isCustomURLValid = validateCobrowseCustomURL(this.state.targetUrl);     
      if(isCustomURLValid === true) {
        this.props.handleBookCoBrowseSession(this.state.targetUrl);
      } else {
        this.setState({errorMsg: isCustomURLValid})
      }
    } else {
      this.setState({errorMsg: getMessage("ERROR_EMPTY_CO_BROWSE_URL")})
    }
  }
  
  renderTargetSiteSelector =()=> {
    return(
      <Modal
        isOpen={this.props.show}
        id="targetSiteModal"
      >
        <ModalHeader>
          <div className="headerText">
            {getMessage("COBROWSE_URL_SELECTION")}
          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <div className="container">
            {
              this.props.cobrowseDetails.targetSites && this.props.cobrowseDetails.targetSites.map((targetSite)=>(
                <div className="row align-items-center pt-3" key={targetSite.id}>
                  { targetSite.url === this.state.targetUrl && !this.state.isCustomUrl
                    ? <img className="cursorPointer" src={icRadioButtonSelected} onClick={()=>this.changeURLSelection("",false)}/>
                    : <img className="cursorPointer" src={icRadioButtonUnselected} onClick={()=>this.changeURLSelection(targetSite.url,false)}/> 
                  }
                  <span 
                    className="px-2 cursorPointer targetSiteLabel"
                    onClick={
                      targetSite.url === this.state.targetUrl ?
                        ()=>this.changeURLSelection("",false) :
                        ()=>this.changeURLSelection(targetSite.url,false)
                    }
                  >{targetSite.label}</span>
                </div>
              ))
            }
            {this.props.cobrowseDetails.allowCustomUrl &&
            <div className="row pt-3 align-items-center">
              {
                this.state.isCustomUrl 
                  ? <img className="cursorPointer" src={icRadioButtonSelected} onClick={()=>this.changeURLSelection("",false)}/>
                  : <img className="cursorPointer" src={icRadioButtonUnselected} onClick={()=>this.changeURLSelection("",true)}/>
              }
              <span className="px-2 cursorPointer" onClick={()=>this.changeURLSelection("",true)}>
                {this.props.cobrowseDetails.customUrlLabels &&
                  this.props.cobrowseDetails.customUrlLabels[0] &&
                   this.props.cobrowseDetails.customUrlLabels[0].label!==""
                  ? this.props.cobrowseDetails.customUrlLabels[0].label
                  : getMessage("CUSTOM_URL")}
              </span>
            </div>
            }
            {this.state.isCustomUrl && this.props.cobrowseDetails.allowCustomUrl &&
            <div className="row align-items-center py-3">
              <Col>
                <input
                  type="text"
                  className="inputFormControl form-control rounded-pill w-100"
                  placeholder={getMessage("URL_VALUE_PLACEHOLDER")}
                  onChange={this.changeURL}
                  value={this.state.targetUrl}
                  maxLength={500}
                />
              </Col>
            </div>}
            {this.state.errorMsg &&
            <div className="d-flex justify-content-center">
              <span className="errors mt-0 h-100">
                {getMessage(this.state.errorMsg)}
              </span>
            </div>}
            <div className="d-flex justify-content-center mt-3">
              <div className="col-md-4">
                <button 
                  className="rounded-pill w-100 btn customBtn"
                  onClick={this.handleBookCoBrowse}
                >
                  {getMessage("GO")}
                </button>
              </div>
              <div className="col-md-4">
                <button 
                  className="rounded-pill w-100 btn btn-decline"
                  onClick={this.cancelCoBrowse}
                >
                  {getMessage("CANCEL")}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
  render(){
    return(
      this.renderTargetSiteSelector()
    )
  }
}

export default modalScrollBar(TargetSiteSelectionModal);