import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { getMessage } from "CONFIG/i18n";

import { leaveSessionAction } from "CONFIG/ActionFactory";
import QRCodeFollowUp from "../QRCodeFollowUp/QRCodeFollowUp";
//less files
import "./QRCodeLoading.less";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CONFIG, SESSION_STATUS } from "UTILS/Constants";
import { isRealwearDevice } from "UTILS/Utility";

class QRCodeLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: this.props.selectedLanguage,
      offline: null,
    };
  }
  
  componentDidMount() {
    window.addEventListener('offline', () => { this.setState({offline: true}) });
    window.addEventListener('online', this.joinAgain); 
    /*
    * check if session is already ended 
    */
    if(!this.props.userWaitingTimeEnded) {
      // if session is active on refresh navigate user to waiting screen 
      if(this.props.sessionStatus === SESSION_STATUS.ACTIVE) {
        this.props.history.push(CONFIG.path.waitingRoom);  
      } else
      /*
      * end session after configured time is expired 
     */ 
      if(!this.props.userWaitingEndTime) {  
        //convert in miliseconds
        const qrCodeWaitingTime = parseInt(this.props.qrCodeWaitingTime * 1000);
        this.props.setUserWaitingEndTime(moment().valueOf() + qrCodeWaitingTime)
        this.userWaitingSetTimeoutEvent = setTimeout(this.userWaitingTimeExpired, qrCodeWaitingTime)
      } else {
      /*
      * after refresh calculate the remaining time from saved endtime
      */
        let userWaitingTime = this.props.userWaitingEndTime - moment().valueOf()
        if(userWaitingTime > 0) {    
          this.userWaitingTimeout= setTimeout(this.userWaitingTimeExpired, userWaitingTime)
        } else {
          /* 
          * set the end session flag only
          * when user back online and timer is expired already 
          * avoid endsession api call
          * in this condition server will end the session after 15 min
          */ 
          this.props.setIsWaitingTimeOver(true)
        }
      }
    }
  }

  joinAgain =()=> {
    /*
    * avoid join again api call if user waiting time is expired 
    * or the userWaitingTimeEnded is true 
    */
    let userWaitingTime = this.props.userWaitingEndTime - moment().valueOf()
    if(!this.props.userWaitingTimeEnded || userWaitingTime > 0) {
      this.props.joinAgain(this.state.offline); 
      this.setState({offline: false});
    }
  }

  userWaitingTimeExpired =()=> {  
    leaveSessionAction.endSessionAndCleanUp(this.props.sessionKey).then(
      (response)=>{},
      (error)=>{
        console.error(error)
      });
    this.props.setIsWaitingTimeOver(true)
  }
  
  componentWillUnmount() {
    if(this.userWaitingSetTimeoutEvent) {     
      clearTimeout(this.userWaitingSetTimeoutEvent);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="w-100 d-flex justify-content-center">
          {this.props.userWaitingTimeEnded ? (
            <QRCodeFollowUp
              userDetails={this.props.userDetails}
              handleFollowUpClick={this.props.handleFollowUpClick}
              setQRNoThanksMessageScreen={this.props.setQRNoThanksMessageScreen}
              isNoThanksMessageClicked={this.props.isNoThanksMessageClicked}
              followUpSuccess={this.props.followUpSuccess}
              logo={this.props.logo}
              role={this.props.role}
            />
          ) : (
            <div className="qr-code-loading">
              <div className={`${isRealwearDevice() ? "real-wear-device" : ""}`}>
                <Row>
                  <Col className="logo-image flex-for-image py-3">
                    {this.props.logo 
                      ? <img src={this.props.logo} />
                      : null}
                  </Col>
                </Row>
                <Row className="logo-image flex-for-image pt-2 thank-you-label">
                  {getMessage("THANK_YOU")}
                </Row>
                <Row className="logo-image flex-for-image pt-2 agent-join-label">
                  {getMessage("AGENT_WILL_JOIN")}
                </Row>
                <Row>
                  <Col className="logo-image flex-for-image pt-2">
                    <div className="roundLoader"></div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(QRCodeLoading);
