import { ACTIONS, ANNOTATIONS } from "UTILS/Constants";

const initialState = {
  snapshotCanvas: {},
  snapshotCanvasData: null,
  lastDrawnObject: null,
  ownerOfLastAction: null,
  // determine if snapshot is started in a session irrespective of the user selecetd for snapshot
  // it is used to disable CB when snapshot is running
  isSnapshotStarted: false,
  snapshotAnnotationMode: ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION,
  // if object tracking started and snapshotee is on mobile/tab and agent has drawn annotation
  // if snapshotee flip camera or change orientation then clear the canvas
  // because we can't track an annotated object on camera flip and orientation change
  snapshotAnnotationsClearedReason: null,
  isDrawingModeEnabled: false,
  snapshotScanCodeResult: null,
  snapshotCurrentCanvas: {
    canvasId: null,
    canvasType: null
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SNAPSHOT.SNAPSHOT_SET_ACTIVE_CANVAS:
      return {
        ...state,
        snapshotCurrentCanvas: action.activeCanvas
      }
    case ACTIONS.SNAPSHOT.SET_SNAPSHOT_STARTED:
      return {
        ...initialState,
        // to maintain DrawingMode on page refresh or network switch - WAAG-5095
        isDrawingModeEnabled: state.isDrawingModeEnabled,
        isSnapshotStarted: action.value,
        snapshotScanCodeResult: state.snapshotScanCodeResult,
        snapshotCurrentCanvas:state.snapshotCurrentCanvas
      }
    case ACTIONS.SNAPSHOT.ADD_CANVAS:
      return {...state, snapshotCanvas: action.newCanvas}

    case ACTIONS.LEAVE_SESSION_LOCAL:
    case ACTIONS.SNAPSHOT.DELETE_CANVAS:
      return {
        snapshotCanvas: {},
        snapshotCanvasData: null,
        lastDrawnObject: null,
        ownerOfLastAction: null,
        // determine if snapshot is started in a session irrespective of the user selecetd for snapshot
        // it is used to disable CB when snapshot is running
        isSnapshotRunning: false,
        isDrawingModeEnabled: state.isDrawingModeEnabled,
        snapshotAnnotationMode: initialState.snapshotAnnotationMode,
        snapshotAnnotationsClearedReason: null,
        snapshotScanCodeResult: state.snapshotScanCodeResult,
        snapshotCurrentCanvas:state.snapshotCurrentCanvas
      }

    case ACTIONS.SNAPSHOT.CLEAR_CANVAS:
      return {
        ...state,
        lastDrawnObject: null,
        snapshotCanvasData: null,
        ownerOfLastAction: null,
        snapshotAnnotationsClearedReason: null
      }

    case ACTIONS.SNAPSHOT.ADD_OBJECT:
      let canvasData = _.cloneDeep(state.snapshotCanvasData || {});
      canvasData[action.data.whiteboardData.objectId] = {}
      canvasData[action.data.whiteboardData.objectId].participantId = action.data.participantId
      canvasData[action.data.whiteboardData.objectId] = action.data.whiteboardData;

      return {
        ...state,
        snapshotCanvasData: canvasData,
        lastDrawnObject: action.data.whiteboardData,
        ownerOfLastAction: action.data.participantId
      };
    
    case ACTIONS.SNAPSHOT.ADD_STICKY_OBJECT:
      let snapshotCanvasData = {};
      snapshotCanvasData[action.data.whiteboardData.objectId] = {}
      snapshotCanvasData[action.data.whiteboardData.objectId] = action.data.whiteboardData;
      return {
        ...state,
        snapshotCanvasData: snapshotCanvasData,
        lastDrawnObject: action.data.whiteboardData,
        ownerOfLastAction: action.data.participantId
      };

    case ACTIONS.SNAPSHOT.SET_CANVAS_DATA:
      return {
        ...state,
        snapshotCanvasData: action.canvasData
      };

    case ACTIONS.SNAPSHOT.IS_DRAWING_MODE_ENABLED:
      return {
        ...state,
        isDrawingModeEnabled: action.value
      };
    case ACTIONS.SNAPSHOT.SET_ANNOTATION_MODE:
      return {
        ...state,
        snapshotAnnotationMode: action.value
      };    
    case ACTIONS.SNAPSHOT.SNAPSHOT_ANNOTATIONS_CLEARED:
      return {
        ...state,
        snapshotAnnotationsClearedReason: action.value
      };
    case ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_RESULT:
      return {
        ...state,
        snapshotScanCodeResult: action.value
      };
    case ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_ERROR:
      return {
        ...state,
        snapshotScanCodeError: action.value
      };
    // user left the meeting then reset the state
    case ACTIONS.SET_END_SESSION_FLAG:
    case ACTIONS.LOGOUT_USER:
      return initialState
    default:
      return state;
  }
}
