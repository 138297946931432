import React, { Component } from "react";
import { Row, Col, Nav, NavLink, NavItem, TabContent, TabPane, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Button } from "reactstrap";
import classnames from 'classnames';
import { isSafari } from "react-device-detect";

// Components
import HeaderContainer from "../SuperAdmin/Header/HeaderContainer";
import ResetPasswordModal from "./ResetPasswordModal";
import DeleteConfirmationModal from "../CommonComponents/DeleteConfirmationModal/DeleteConfirmationModal";
import SessionDetails from "./ReportComponent/SessionDetails";
import SessionFollowUps from "./ReportComponent/SessionFollowUps";
import { convertMinutesToDDhhmm } from "UTILS/DateUtils";
import {
  getAnnouncementMarquee,
  getStringWithoutExtraSpaces,
} from "UTILS/Utility";

// Strings
import { getMessage } from "CONFIG/i18n";

// Styles
import "../SuperAdmin/Organisations/Organisations.less";
import "../App/App.less";
import "./AdminDashboard.less";
import UsersList from "./UserList";
import SessionList from "./ReportComponent/SessionList";

//Actions
import { adminAction } from "CONFIG/ActionFactory";
import { systemAnnouncementAction } from "CONFIG/ActionFactory";
import { BUSINESS_MODELS, REPORTS_DROPDOWN_MENU, USER_ROLES, ADMIN_DASHBOARD_TABS, TOOLTIP } from "UTILS/Constants";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import QRCodesList from "./QRCodes/QRCodesList";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPasswordModalFlag: this.props.showResetPasswordModalFlag,
      resetPasswordUserData: {},
      showAboutOrgModalFlag: false,
      dropdownOpen: false,
      activeAnnouncements: null,
      searchKey: "",
      searchTimeout: null,
    };
    this.tooltipBoundaryElement = null;
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("searchUserList")[0];
  }

  componentDidMount() {
    this.getTooltipBoundaryElement();
    window.addEventListener('resize', () => {
      this.setHeaderPadding();
    })
    // get active announcements
    systemAnnouncementAction.getActiveAnnouncementsAction().then(
      (response) => {
        this.setState({
          activeAnnouncements: response.data
        })
      },
      error => {
        console.error(error);
      }
    )
  }

  componentDidUpdate(prevProp) {

    if (
      prevProp.showResetPasswordModalFlag !==
      this.props.showResetPasswordModalFlag
    ) {
      this.setState({
        showResetPasswordModalFlag: this.props.showResetPasswordModalFlag
      });
    }
    if (
      prevProp.showConfirmationModalFlag !== this.props.showConfirmationModalFlag
    ) {
      this.setState({
        showConfirmationModalFlag: this.props.showConfirmationModalFlag
      });
    }
    if (this.props.customerStatistics.concurrentSessionThreshold !== prevProp.customerStatistics.concurrentSessionThreshold &&
      ((this.props.customerStatistics.concurrentSessionThreshold && this.props.customerStatistics.concurrentSessionThreshold > 0) && this.props.customerStatistics.activeSessions >= this.props.customerStatistics.concurrentSessionThreshold) &&
      BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS == this.props.customerStatistics.businessModel) {
      toastr.error("THRESHOLD_VALUE_EXCEEDS");
    }
  }

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  setTabs = (currentActiveTab, selectedReport) => {
    adminAction.saveCurrentActiveTab(currentActiveTab);
    this.props.saveSelectedReport(selectedReport);

    this.setState({ searchKey: "" });

    // Call method to clear data and display search box on click of tabs
    this.props.onBackClick();

    this.props.fetchRecordsOnTabSelect(currentActiveTab, selectedReport);
  }

  search = (event) => {
    const searchData = event.target.value;
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    if (this.state.searchKey !== searchData)
      this.setState({
        searchKey: searchData,
        searchTimeout: setTimeout(() => {
          switch (this.props.currentActiveTab) {
            case ADMIN_DASHBOARD_TABS.USERS:
              this.props.getAllUserData(
                0,
                this.props.userListPageSize,
                getStringWithoutExtraSpaces(searchData)
              );

              break;

            case ADMIN_DASHBOARD_TABS.REPORTS:
              if (!this.props.sessionDetails && !this.props.sessionKey) {
                this.fetchRecordsOfSelectedReport(
                  this.props.selectedReport,
                  true,
                  getStringWithoutExtraSpaces(searchData)
                );
              }
              break;

            case ADMIN_DASHBOARD_TABS.QR_CODES:
              this.props.fetchAllQRCodes(
                0,
                this.props.qrCodesListPageSize,
                getStringWithoutExtraSpaces(searchData)
              );

              break;

            default:
              console.log(
                "Search records not found as an invalid tab is selected."
              );
          }
        }, 1000)
      });
  }

  resetValuesOnAddUser = () => {
    this.setState({
      searchKey: ""
    });
    // Show Users tab as selected
    adminAction.saveCurrentActiveTab(ADMIN_DASHBOARD_TABS.USERS);
  }

  setHeaderPadding = () => {
    if (isSafari) {
      // if table body has scroll bar then set padding for table header
      // because on safari over flow overlay is not supported and the scroll bar takes space of content
      var div = document.getElementsByClassName('react-bs-container-body')?.[0];
      if(div) {
        var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
        if(hasVerticalScrollbar) {
          document.getElementsByClassName("react-bs-container-header")?.[0]?.style.setProperty("padding-right", "1em", "important")
        } else {
          document.getElementsByClassName("react-bs-container-header")?.[0]?.style.setProperty("padding-right", "0", "important")
        }
      }
    }
  }

  fetchRecordsOfSelectedReport = (selectedReport, isDataSearched=false, searchData) => {

    switch(selectedReport) {
      case REPORTS_DROPDOWN_MENU.SESSION_LIST:
        {
          let pageSize = isDataSearched ? this.props.sessionListPageSize : undefined;
          this.props.fetchSessionsList(0, pageSize, searchData);
        }
        break;

      case REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS:
        {
          let pageSize = isDataSearched ? this.props.sessionFollowUpsPageSize : undefined;
          this.props.fetchSessionFollowUps(0, pageSize, searchData);
        }
        break;

      default:
        console.log("Invalid report");
        return;
    }
  }

  renderReportsComponent = () => {
    switch(this.props.selectedReport) {
      case REPORTS_DROPDOWN_MENU.SESSION_LIST:
        return(
          <SessionList
            sessionsList={this.props.sessionsList}
            sessionsCount={this.props.sessionsCount}
            fetchSessionsList={this.props.fetchSessionsList}
            sessionListCurrentPageNo={this.props.sessionListCurrentPageNo}
            activeTab={this.props.currentActiveTab}
            getSessionInvitiesAndSessionDetails={this.props.getSessionInvitiesAndSessionDetails}
            language={this.props.language}
            sessionListPageSize={this.props.sessionListPageSize}
            searchKey={this.state.searchKey}
            setHeaderPadding={this.setHeaderPadding}
          />
        );

      case REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS:
        return (
          <SessionFollowUps
            sessionFollowUps={this.props.sessionFollowUps}
            sessionFollowUpsCount={this.props.sessionFollowUpsCount}
            fetchSessionFollowUps={this.props.fetchSessionFollowUps}
            sessionFollowUpsCurrentPageNo={this.props.sessionFollowUpsCurrentPageNo}
            activeTab={this.props.currentActiveTab}
            language={this.props.language}
            sessionFollowUpsPageSize={this.props.sessionFollowUpsPageSize}
            searchKey={this.state.searchKey}
            setHeaderPadding={this.setHeaderPadding}
          />
        );

      default: return null;
    }
  }

  /**
   * It returns tooltip text of search info icon as per selected tab 
   */
  getTooltipText = () => {
    let tooltipText = `${getMessage("SEARCH")} ${getMessage("BY")}`;

    switch(this.props.currentActiveTab) {
      case ADMIN_DASHBOARD_TABS.USERS: 
        return  `${tooltipText} ${getMessage("EMAIL")}, ${getMessage("FIRST_NAME")}, ${getMessage("LAST_NAME")}, ${getMessage("TYPE_OF_USER")}`;

      case ADMIN_DASHBOARD_TABS.REPORTS:
        if(this.props.selectedReport === REPORTS_DROPDOWN_MENU.SESSION_LIST) {
          return  `${tooltipText} ${getMessage('SESSION_NAME')}, ${getMessage("AGENT")}, ${getMessage("DURATION")}, ${getMessage("STATUS")}`;
        } else if(this.props.selectedReport === REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS) {
          return `${tooltipText} ${getMessage("QR_LABEL")}, ${getMessage("CUSTOMER_NAME_TITLE")}, ${getMessage("EMAIL")}, ${getMessage("PHONE_NUMBER")}, ${getMessage("DESCRIPTION")}`;
        }

      case ADMIN_DASHBOARD_TABS.QR_CODES:
        return `${tooltipText} ${getMessage("QR_LABEL")}, ${getMessage("DESCRIPTION")} ${getMessage("OR")} ${getMessage('SEARCH_BY_QR_ID')}`;

      default:
        console.log("Invalid tab");
        return;
    }
  }

  render() {
    let isThresholdReached = ((this.props.customerStatistics.concurrentSessionThreshold && this.props.customerStatistics.concurrentSessionThreshold > 0) && this.props.customerStatistics.activeSessions >= this.props.customerStatistics.concurrentSessionThreshold) ? true : false;
    let isBusinessModelIsMaxConcurrentConn = BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS == this.props.customerStatistics.businessModel;
    const isQRCodeLimitReached = this.props.qrLimit <= this.props.totalQRCodes;
    return (
      <div>
        <HeaderContainer
          title={this.props.organisationName}
          headerButtonText={getMessage("ADD_USER")}
          showAddUserModal={this.props.showAddUserModal}
          showBulkImportUserModal={this.props.showBulkUserUploadModal}
          showAddUserDropdown={this.props.showAddUserDropdown}
          history={this.props.history}
          getAllUserData={this.props.getAllUserData}
          logo={this.props.logo}
          isAdmin={true}
          isDashboard={true}
          fetchAllQRCodes={this.props.fetchAllQRCodes}
          totalQRCodes ={this.props.totalQRCodes}
          qrLimit={this.props.qrLimit}
          isQRCodeEnabled={this.props.isQRCodeEnabled}
          showHeaderButton={true}
          showUserDropDownModal= {true}
        />
        {this.state.activeAnnouncements && this.state.activeAnnouncements.length >= 1 &&
          <marquee>
            <label className="announcementLabel">
              {getAnnouncementMarquee(this.state.activeAnnouncements)}
            </label>
          </marquee>
        }
        <Row className={this.state.activeAnnouncements && this.state.activeAnnouncements.length >= 1
          ? "organisationHeader mt-0"
          : "organisationHeader"}>
          <Col lg={2} className="setHeaderMargin">
            <label className="summaryValue nameEllipsis" id="totalAgents">
              {this.props.customerStatistics.totalAgents}
              <CustomTooltip
                tooltipText={this.props.customerStatistics.totalAgents}
                tooltipId="totalAgents"
              />
            </label>
            <label className="summaryLabel">{getMessage("NUMBER_OF_AGENTS")}</label>
          </Col>
          <Col lg={2} className="setHeaderMargin">
            <label className="summaryValue nameEllipsis" id="totalSessions">
              {this.props.customerStatistics.totalSessions}
              <CustomTooltip
                tooltipText={this.props.customerStatistics.totalSessions}
                tooltipId="totalSessions"
              />
            </label>
            <label className="summaryLabel">{getMessage("NUMBER_OF_TOTAL_SESSIONS")}</label>
          </Col>
          {/* If threshold is reached and business model is max concurrent session show contents in red with tooltip on hover else show content with regular color (black) without tooltip on hover */}
          {isThresholdReached && isBusinessModelIsMaxConcurrentConn ? <Col lg={2} className="setHeaderMargin" id="thresholdError">
            <CustomTooltip
              tooltipText={getMessage("THRESHOLD_VALUE_EXCEEDS")}
              tooltipId="thresholdError"              
            />
            <label className={"summaryValue nameEllipsis maxConcurrentDataLabel"}>{this.props.customerStatistics.activeSessions + ' / ' + this.props.customerStatistics.maxConcurrentSessions}</label>
            <label className={"summaryLabel maxConcurrentDataLabel"}>{getMessage("NUMBER_OF_ACTIVE_SESSIONS") + ' / ' + getMessage("MAX_CONCURRENT_CONNECTIONS")}</label>
          </Col> : !isThresholdReached && isBusinessModelIsMaxConcurrentConn ?
            <Col lg={2} className="setHeaderMargin">
              <label className={"summaryValue nameEllipsis"} id="maxConcurrentData">
                {this.props.customerStatistics.activeSessions + ' / ' + this.props.customerStatistics.maxConcurrentSessions}
                <CustomTooltip
                  tooltipText={this.props.customerStatistics.activeSessions + ' / ' + this.props.customerStatistics.maxConcurrentSessions}
                  tooltipId="maxConcurrentData"              
                />
              </label>
              <label className={"summaryLabel"}>{getMessage("NUMBER_OF_ACTIVE_SESSIONS") + ' / ' + getMessage("MAX_CONCURRENT_CONNECTIONS")}</label>
            </Col> :
            <Col lg={2} className="setHeaderMargin">
              <label className="summaryValue nameEllipsis" id="activeSessions">
                {this.props.customerStatistics.activeSessions}
                <CustomTooltip
                  tooltipText={this.props.customerStatistics.activeSessions}
                  tooltipId="activeSessions"              
                />
              </label>
              <label className="summaryLabel">{getMessage("NUMBER_OF_ACTIVE_SESSIONS")}</label>
            </Col>}

          <Col lg={2} className="setHeaderMargin">
            <label className="summaryValue nameEllipsis" id="totalTimeToDate">
              {convertMinutesToDDhhmm(this.props.customerStatistics.totalTimeToDate)}
              <CustomTooltip
                tooltipText={convertMinutesToDDhhmm(this.props.customerStatistics.totalTimeToDate)}
                tooltipId="totalTimeToDate"              
              />
            </label>
            <label className="summaryLabel">{getMessage("TOTAL_USAGE_TO_DATE")}</label>
          </Col>
        </Row>
        <div className="userList">
          <ResetPasswordModal
            userData={this.state.resetPasswordUserData}
            show={this.state.showResetPasswordModalFlag}
            resetPasswordUserRole={this.props.resetPasswordUserRole}
            handleCancelClick={this.props.handleCancelClick}
            handleResetClick={this.props.handleResetClick}
          />
          {/* <AboutOrganisationModal
            show={this.state.showAboutOrgModalFlag}
            handleCancelClick={this.handleCancelAboutOrgModal}
            handleSaveClick={this.handleSaveClick}
            organizationData ={this.state.organizationData}
          /> */}
          <DeleteConfirmationModal
            data={this.state.resetPasswordUserData}
            show={this.state.showConfirmationModalFlag}
            handleCancelClick={this.props.handleCancelClick}
            handleDeleteClick={this.props.handleDeleteUserClick}
            deleteMessage={this.props.deleteUserRole === USER_ROLES.ADMIN
              ? getMessage("DELETE_MSG_ADMIN", {userName: this.props.deleteUserName})
              : getMessage("DELETE_MSG_AGENT", {userName: this.props.deleteUserName})}
            headerMessage={getMessage("DELETE_USER")}
          />
          
          <div className='searchUserList'>
            <Row className="navTabMargin navTabBackground align-items-center">
              <Col md={5} sm={12} className="noPadding adminDashboard">
                <Nav tabs className="navTab">
                  <NavItem className="navTabItem">
                    <NavLink
                      onClick={() => this.setTabs(ADMIN_DASHBOARD_TABS.USERS)}
                      className={(this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.USERS) ? "otherActiveTabs agentTab" : "otherTabs agentTab"}
                    >
                      {getMessage("USERS")}
                    </NavLink>
                  </NavItem>

                  {this.props.isQRCodeEnabled &&
                    <NavItem className="navTabItem">
                      <NavLink
                        onClick={() => this.setTabs(ADMIN_DASHBOARD_TABS.QR_CODES)}
                        className={(this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.QR_CODES) ? "otherActiveTabs agentTab" : "otherTabs agentTab"}
                      >
                        {getMessage("QR_CODES")}
                      </NavLink>
                    </NavItem>
                  }
                  <NavItem className="navTabItem">
                    <NavLink>
                      <Dropdown group isOpen={this.state.dropdownOpen} size="sm" toggle={this.toggleDropdown}>
                        <DropdownToggle caret className={(this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.REPORTS) ? "reportsDropdownTab" : "reportsDropdownActiveTab navTabItem"}>
                          {getMessage("REPORTS")}
                        </DropdownToggle>
                        <DropdownMenu >
                          <DropdownItem 
                            className={classnames({ active: this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.REPORTS })}
                            onClick={() => this.setTabs(ADMIN_DASHBOARD_TABS.REPORTS, REPORTS_DROPDOWN_MENU.SESSION_LIST)} >{getMessage("SESSION_LIST")}</DropdownItem>
                          <DropdownItem 
                            className={classnames({ active: this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.REPORTS })}
                            onClick={() => this.setTabs(ADMIN_DASHBOARD_TABS.REPORTS, REPORTS_DROPDOWN_MENU.SESSION_FOLLOW_UPS)} >{getMessage("SESSION_FOLLOW_UPS")}</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md={4} sm={12} className='borderRight'>
                {
                  this.props.isQRCodeEnabled && this.props.currentActiveTab == ADMIN_DASHBOARD_TABS.QR_CODES &&
                  <div className="qr-code-stats">
                    <label>{getMessage("QR_CODES_USED")} : </label>
                    <span className={`${isQRCodeLimitReached && 'qr-limit-reached'} ml-1`}> {this.props.totalQRCodes} / {this.props.qrLimit}</span>                    
                    {isQRCodeLimitReached &&
                      <div className="ml-2 qr-limit-reached-tooltip">
                        <CustomTooltip
                          tooltipId='qrLimitReachedInfo'
                          tooltipBoundariesElement={this.tooltipBoundaryElement}
                          tooltipText={getMessage("ICON_DESCRIPTION_QR_LIMIT_REACHED", {maxQRLimit: this.props.qrLimit})} 
                          tooltipType={TOOLTIP.TYPE.ERROR}
                          showIcon={true}
                        />
                      </div>
                    }
                  </div>
                }
              </Col>
              {!this.props.sessionDetails && !this.props.sessionKey ?
                <Col md={3} sm={12} className="d-flex">
                  <Input
                    className="formControl form-control"
                    value={this.state.searchKey}
                    placeholder={getMessage("SEARCH")}
                    onChange={this.search}
                  />
                  <div className= "d-flex alignItemsCenter px-2">
                    <CustomTooltip
                      tooltipId='searchInfo'
                      tooltipBoundariesElement={this.tooltipBoundaryElement}
                      tooltipText={this.getTooltipText()} 
                      showIcon={true}
                    />
                  </div>                    
                </Col>
                : null}

            </Row>
          </div>
          {
            this.props.showTable &&
            <TabContent activeTab={this.props.currentActiveTab} className='adminDashboadTable'>
              <TabPane tabId={ADMIN_DASHBOARD_TABS.USERS}>
                <Row className="noMargin">
                  <Col sm="12" className="noPadding">
                    <div>
                      {this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.USERS ?
                        <UsersList
                          showEditUserModal={this.props.showEditUserModal}
                          resetPasswordClick={this.props.resetPasswordClick}
                          showDeleteConfirmationModal={this.props.showDeleteConfirmationModal}
                          loggedUserId={this.props.loggedUserId}
                          handleSaveClick={this.props.handleSaveClick}
                          activeTab={this.props.currentActiveTab}
                          userListPageSize={this.props.userListPageSize}
                          userListCurrentPageNo={this.props.userListCurrentPageNo}
                          usersList={this.props.usersList}
                          usersCount={this.props.usersCount}
                          getAllUserData={this.props.getAllUserData}
                          searchKey={this.state.searchKey}
                          isSSOEnabled={this.props.isSSOEnabled}
                          modules={this.props.modules}
                          setHeaderPadding={this.setHeaderPadding}
                        /> : null
                      }
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={ADMIN_DASHBOARD_TABS.REPORTS}>
                {!this.props.sessionDetails && !this.props.sessionKey ?
                  this.props.currentActiveTab === ADMIN_DASHBOARD_TABS.REPORTS &&
                  this.renderReportsComponent()
                  :
                  this.props.sessionDetails &&
                  <SessionDetails
                    pdftronKey={this.props.pdftronKey}
                    sessionDetails={this.props.sessionDetails}
                    inviteesList={this.props.inviteesList}
                    onBackClick={this.props.onBackClick}
                    getSessionInvitiesAndSessionDetails={this.props.getSessionInvitiesAndSessionDetails}
                    sessionObjects={this.props.sessionObjects}
                    showSpinner={this.props.showSpinner}
                    hideSpinner={this.props.hideSpinner}
                    language={this.props.language}
                    loggingLevel={this.props.loggingLevel}
                  />
                }
              </TabPane>
              {this.props.isQRCodeEnabled &&
                <TabPane tabId={ADMIN_DASHBOARD_TABS.QR_CODES}>
                  {
                    <QRCodesList
                      qrCodes={this.props.qrCodes}
                      qrCodesListPageSize={this.props.qrCodesListPageSize}
                      qrCodesListCurrentPageNo={this.props.qrCodesListCurrentPageNo}
                      getQRCodesList={this.props.fetchAllQRCodes}
                      qrCodesCount={this.props.qrCodesCount}
                      updateQRCode={this.props.updateQRCode}
                      deleteQRCode={this.props.deleteQRCode}
                      searchKey={this.state.searchKey}
                      history={this.props.history}
                    />
                  }
                </TabPane>
              }
            </TabContent>
          }
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
