import React, { Component, createRef } from "react";

import {
  Input,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import {
  Col,
  Row,
  InputGroup,

} from "react-bootstrap";
import * as rxjs from "rxjs";
import 'moment/min/locales';
import { debounceTime } from "rxjs/operators";
import DateTime from "react-datetime";
import { USER_ROLES, LANGUAGE, ERROR, DATE_FORMAT, TIME_FORMAT, DEVICE_TYPE } from "UTILS/Constants";
import moment from 'moment';
import { getMessage } from "CONFIG/i18n";
import { getStringWithoutExtraSpaces, isRealwearDevice } from "UTILS/Utility";

// Common Components
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import "./QRCodeFollowUp.less";
import "react-datetime/css/react-datetime.css";
import "COMPONENTS/App/App.less";

const minHours = parseInt(window._env_.REACT_APP_CAL_MIN_HOURS) ;
const maxHours = parseInt(window._env_.REACT_APP_CAL_MAX_HOURS);
const minutesStep = parseInt(window._env_.REACT_APP_FOLLOW_UP_MINS_STEP);

// created validPreferredTime moment object using env variables
// eg. if minHours = 9 then moment object will contain current day with 9:00 AM as time
const validPreferredStartTime = moment(minHours, TIME_FORMAT);
// eg. if maxHours = 17 and minutesStep = 30 then moment object will contain current day with 4:30 PM as time
const validPreferredEndTime = moment(maxHours, TIME_FORMAT).subtract(minutesStep, "minutes");

class QRCodeFollowUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: this.props.userDetails.language,
      errorMessage: "",
      description: "",
      isFollowUp: false,
      preferredFromTime: null,
      preferredToTime: null,
      QRCodeFollowUpData: null,
      maximizeCardHeight: false,
      isLandscape: false,
      isButtonDisable:false
    };
    this.QRCodeFollowUpObserver = new rxjs.Subject();
    this.followUpViewRef = createRef();
  }

  componentDidMount() {
    this.QRCodeFollowUpObserver.pipe(debounceTime(500)).subscribe((fn) => {
      if (typeof fn === "function") fn();
    });
  }

  scrollToBottom = () => {
    this.followUpViewRef.current.scrollTo({
      top: this.followUpViewRef.current?.scrollHeight,
      behavior: 'smooth',
    })
  }

  handleInputChange = (event) => {
    // On mobile devices user is able to input more than max length characters issue WAAG-5243
    // trim value manually to restrict user to add maxLength characters only
    const value = event.target.value.substr(0, event.target.maxLength);
    this.setState({ [event.target.name]: value });
  };

  /**
   * Round up preferred From Time in slot of 30 Minutes
   * E.g if current time 09:15 AM it will show default preferred From Time 9.30 AM
   * @param date moment date object
   * @returns moment object
   */
  roundUpToNearestSlot(date = moment()) {
    const minutesStepInMillis = 1000 * 60 * minutesStep;
    // Math.ceil to always round UP
    let nextTimeSlot = moment(Math.ceil(date.valueOf()/ minutesStepInMillis) * minutesStepInMillis);
    // if next time slot is out of working hours then rest it to 1st slot
    if (nextTimeSlot.isBefore(validPreferredStartTime) || nextTimeSlot.isAfter(validPreferredEndTime)) {
      nextTimeSlot.set('hour', minHours);
      nextTimeSlot.set('minute', 0);
    }
    return nextTimeSlot
  }

  onChange = (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  setInputDate = () => {
    this.setState({
      preferredFromTime: this.state.preferredFromTime
        ? moment(this.state.preferredFromTime).format(DATE_FORMAT + " " + TIME_FORMAT)
        : "",
      preferredToTime: this.state.preferredToTime
        ? moment(this.state.preferredToTime).format( DATE_FORMAT + " " + TIME_FORMAT)
        : "",
    });
  };

  disableBtns = (value) => {
    this.setState({ isButtonDisable: value });
  };

  maximizeCardHeight = () => {
    this.setState({ maximizeCardHeight: true });
  };

  componentWillUnmount() {
    window.removeEventListener('online', this.handleNetworkStateChange);
    this.disableBtns(false);
  }

  handleNoThanksMessageClick = () => {
    this.props.setQRNoThanksMessageScreen(true);
  };

  /**
   * Returns minutes from start of day
   * @param date moment date object
   * @returns number of minutes
   */
  getMinutesOfDay = (date) => {
    // moment at midnight
    var midnight = date.clone().startOf('day');

    // difference in minutes
    return date.diff(midnight, 'minutes');
  }

  // Validation Code
  validateEntries = () => {
    const preferredFromDateTime = moment(this.state.preferredFromTime);

    // Validation for Date time Picker
    if (!this.state.preferredFromTime) {
      this.setState({
        errorMessage: getMessage("INVALID_PREFERRED_TIME_SLOT"),
      });
      return false;
    }

    // 1. Preferred from time should not be before validPreferredStartTime
    // 2. Preferred from time should not be after validPreferredEndTime
    if (
      this.getMinutesOfDay(preferredFromDateTime) < this.getMinutesOfDay(validPreferredStartTime)
      || this.getMinutesOfDay(preferredFromDateTime) > this.getMinutesOfDay(validPreferredEndTime)
    ) {
      this.setState({
        errorMessage: getMessage("PREFERRED_TIME_NOT_IN_RANGE", {
          startTime: validPreferredStartTime.format(TIME_FORMAT),
          endTime: validPreferredEndTime.format(TIME_FORMAT),
        }),
      });
      return false;
    }

    // From time should be greater than current time
    if (preferredFromDateTime.isBefore()){
      this.setState({
        errorMessage: getMessage("PREFERRED_TIME_BEFORE_CURRENT_TIME_ERROR"),
      });
      return false;
    }
    
    // Validation for description
    if (!this.state.description || !this.state.description.trim()) {
      this.setState({ 
        errorMessage: getMessage("INVALID_DESCRIPTION") 
      });
      return false;
    }

    // reset error message if all ok
    this.setState({
      errorMessage: "",
    });
    return true;
  };

  handleFollowUpClick = (event) => {
    if (!this.validateEntries()) {
      this.disableBtns(false);
      return;
    }
    let startTime = moment(this.state.preferredFromTime).valueOf();
    // create endTime by adding difference of slot time in start time
    let endTime = moment(this.state.preferredFromTime).add(minutesStep, 'minutes').valueOf();

    this.props.handleFollowUpClick(
      this.errorCallBack,
      this.props.userDetails.userName,
      this.props.userDetails.email ? this.props.userDetails.email : undefined,
      this.props.userDetails.phone ? this.props.userDetails.phone : undefined,
      getStringWithoutExtraSpaces(this.state.description),
      startTime,
      endTime,
      this.props.userDetails.info ? this.props.userDetails.info : undefined,
      this.props.userDetails.deviceType ? this.props.userDetails.deviceType : undefined,
    );
  };

  errorCallBack = (errorCode) => {
    let errorMessage = errorCode;
    if (errorCode === ERROR.SERVER_UNREACHABLE_ERROR && this.props.role === USER_ROLES.USER) {
      errorMessage = "SERVER_UNREACHABLE_ERROR_USER";
    } 
    if (errorMessage) {
      this.setState({
        errorMessage: errorMessage,
      });
    }
    this.disableBtns(false);
  };

  isWeekend = (date) => {
    return date.day() == 0 || date.day() == 6;
  };

  /**
   * Function to validate from dates in date picker
   * From date should not be a weekend and should be after current date or same as current date
   * if current time if before preferred max time
   * @param {*} date 
   * @returns true if date is valid, false otherwise
   */
  isValidPreferredFromTime = (date) => {
    return (
      !this.isWeekend(date) && (date.isAfter() || (date.isSame(moment(), "day") && moment().isSameOrBefore(validPreferredEndTime)))
    );
  };

  renderQrFollowUpScreen = () => {
    // Add current moment to selected time if preferredFromTime is not set
    const selectedTime = this.state.preferredFromTime ? moment(this.state.preferredFromTime) : moment();
    // Calculate min hours if curent date is selected available hours will be after current time else min hours
    const fromTimeMinHours = (selectedTime.isSame(moment(), "day") && moment().isSameOrBefore(validPreferredEndTime)) ? this.roundUpToNearestSlot().hours() : minHours;
    const fromTimeConstraints = {
      hours: {
        min: fromTimeMinHours,
        max: validPreferredEndTime.hours(),
      },
      minutes: { step: minutesStep },
    };
    const dateFormatInputProps = {
      placeholder: "YYYY/MM/DD hh:mm AM",
      readOnly: true,
    };
    
    return (
      <div className={`qr-follow-up-screen ${isRealwearDevice() ? "real-wear-device" : ""}`}>
        <>
          <Row>
            <Col className="logo-image flex-for-image pt-3 pb-3">
              {this.props.logo ? <img src={this.props.logo} /> : null}
            </Col>
          </Row>
          <Row className="px-3 logo-image flex-for-image follow-up-preference-label">
            {getMessage("STAFF_BUSY_MESSAGE")}
          </Row>
          <Row className="px-3 logo-image flex-for-image follow-up-preference-label">
            {getMessage("QR_CODE_FOLLOW_UP_MESSAGE")}
          </Row>
        </>

        <div className="d-flex justify-content-center pt-2">
          <Col lg="12" xl="9" className="px-0"></Col>
        </div>
        <div className="d-flex justify-content-center pt-2 input-card px-2">
          <Col
            lg
            xl="12"
            className={
              " inputForm flexDirectionColumn px-2 pt-4 " +
              (this.state.maximizeCardHeight ? "max-card-height" : "card-height")
            }
            id="inviteeScrollView"
          >
            <div className="scroll scroll-decoration" ref={this.followUpViewRef}>
              <div className="d-flex">
                <Row className="px-3 w-55">
                  <label className="label-style">{getMessage("NAME")}</label>
                </Row>
                <InputGroup>
                  <Input
                    type="text"
                    name="Name"
                    className="inputFormControl cursorDisabled "
                    value={this.props.userDetails.userName}
                    maxLength="50"
                    required
                    disabled
                  />
                </InputGroup>
              </div>
              {this.props.userDetails.email && (
                <div className="d-flex mt-3">
                  <Row className="px-3 w-55">
                    <label className="label-style">{getMessage("EMAIL")}</label>
                  </Row>
                  <InputGroup>
                    <Input
                      type="email"
                      name="email"
                      className="inputFormControl cursorDisabled pr-3"
                      value={this.props.userDetails.email}
                      maxLength="50"
                      required
                      disabled
                    />
                  </InputGroup>
                </div>
              )}

              {this.props.userDetails.deviceType !== DEVICE_TYPE.REALWEAR && this.props.userDetails.phone && (
                <div className="d-flex mt-3">
                  <Row className="px-3 w-55">
                    <label className="label-style">
                      {getMessage("PHONE_NUMBER")}
                    </label>
                  </Row>
                  <InputGroup>
                    <Input
                      type="phone"
                      name="phone"
                      className="inputFormControl cursorDisabled pr-3"
                      value={this.props.userDetails.phone}
                      required
                      disabled
                    />
                  </InputGroup>
                </div>
              )}
              {this.props.userDetails.deviceType === DEVICE_TYPE.REALWEAR &&this.props.userDetails.info && (
                <div className="d-flex mt-3">
                  <Row className="px-3 w-55">
                    <label className="labelStyle">
                      {getMessage("INFO")}
                    </label>
                  </Row>
                  <InputGroup>
                    <Input
                      type="text"
                      name="phone"
                      className="inputFormControl cursorDisabled pr-3"
                      value={this.props.userDetails.info}
                      required
                      disabled
                    />
                  </InputGroup>
                </div>
              )}
              <div className="d-flex alignItemsCenter justifyContentFlexCenter mt-3">
                <Row className="px-3 w-55 ">
                  <label className="label-style">
                    {getMessage("PREFERRED_TIME")}
                    <span className="asterisk">*</span>
                    <span className="ml-2 mr-1 mb-1">
                      <CustomTooltip
                        tooltipId="prefferedTimeSlot"
                        tooltipBoundariesElement="scrollParent"
                        tooltipText={getMessage("ICON_DESCRIPTION_PREFERRED_TIME_SLOT", {
                          slotTime: minutesStep,
                          startTime: validPreferredStartTime.format(TIME_FORMAT),
                          endTime: validPreferredEndTime.format(TIME_FORMAT),
                        })}
                        showIcon={true}
                      />
                    </span>
                  </label>
                </Row>
                <div
                  className="iconContainer p-0 w-100"
                  onClick={() => {
                    this.maximizeCardHeight();
                    this.disableBtns(true)
                    // get date time picker in scroll view
                    // added setTimeout to resolve mac safari automatically scrolled issue
                    setTimeout(() => {
                      this.scrollToBottom();
                    }, 100);
                  }}
                >
                  <Label className="p-0 col-lg col-md col-sm col-12">
                    <div className="datePicker">
                      <DateTime
                        id="preferredFromTime"
                        className="reactDateTimePicker"
                        dateFormat={DATE_FORMAT}
                        timeFormat={TIME_FORMAT}
                        inputProps={dateFormatInputProps}
                        onChange={(value) =>
                          this.onChange(value, "preferredFromTime")
                        }
                        value={this.state.preferredFromTime}
                        initialViewDate={
                          this.state.preferredFromTime
                            ? moment(this.state.preferredFromTime)
                            : moment(this.roundUpToNearestSlot())
                        }
                        closeOnClickOutside
                        onClose={() => {
                          this.setInputDate();
                          this.setState({ maximizeCardHeight: false });
                          setTimeout(() => {
                            this.disableBtns(false)
                          }, 300);
                        }}
                        isValidDate={this.isValidPreferredFromTime}
                        locale={
                          this.props.language === LANGUAGE.JA.name
                            ? LANGUAGE.JA.name
                            : LANGUAGE.EN.name
                        }
                        timeConstraints={fromTimeConstraints}
                      />
                    </div>
                  </Label>
                </div>
              </div>

              <div className="d-flex mt-2">
                <div className="row px-3 w-55">
                  <label className="label-style">
                    {getMessage("DESCRIPTION")}
                    <span className="asterisk">*</span>
                  </label>
                </div>

                <FormGroup
                  className="displayFlex alignItemsCenter w-100"
                  onClick={() => {
                    // get description in scroll view on focus
                    this.scrollToBottom();
                  }}
                >
                  <Input
                    id="description"
                    name="description"
                    className="inputFormControl"
                    type="textarea"
                    rows={4}
                    placeholder={getMessage("ENTER_DESCRIPTION")}
                    onChange={this.handleInputChange}
                    value={this.state.description}
                    maxLength="255"
                  />
                </FormGroup>
              </div>
            </div>

            <Row className="footer">
              <div className="w-100">
                <Button
                  className="customBtn"
                  disabled={this.state.isButtonDisable}
                  onClick={() => {
                    this.disableBtns(true);
                    this.QRCodeFollowUpObserver.next(this.handleFollowUpClick);
                  }}
                >
                  {getMessage("FOLLOW_UP")}
                </Button>
              </div>
              {this.state.errorMessage && (
                <div className="errors  w-100">
                  {getMessage(this.state.errorMessage)}
                </div>
              )}
            </Row>
          </Col>
        </div>
        <div>
          <Row className="logo-image flex-for-image no-thanks-label text-decoration margin-bottom ">
            <div
              onClick={this.state.isButtonDisable ? () => null : this.handleNoThanksMessageClick}>
              {getMessage("NO_THANKS")}
            </div>
          </Row>
        </div>
      </div>
    );
  };

  renderFollowUpSuccessMsgScreen = () => {
    return (
      <div>
        <Row>
          <Col className="logo-image flex-for-image pt-3 pb-3">
            {this.props.logo 
              ? <img src={this.props.logo} />
              : null}
          </Col>
        </Row>
        <Row className="logo-image flex-for-image pt-2 follow-up-success-msg">
          {getMessage("FOLLOW_UP_SUCCESS")}
        </Row>
        <Row className="logo-image flex-for-image pt-2 thank-you-label">
          {getMessage("THANK_YOU")}
        </Row>
      </div>
    );
  };

  renderThankYouMsgScreen = () => {
    return(
      <div>
        <Row>
          <Col className="logo-image flex-for-image pt-3 pb-3">
            {this.props.logo 
              ? <img src={this.props.logo} />
              : null}
          </Col>
        </Row> 
        <Row className="logo-image flex-for-image pt-2 thank-you-label">
          {getMessage("THANK_YOU")}
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="qr-follow-up-screen">
        {!this.props.followUpSuccess &&
          !this.props.isNoThanksMessageClicked &&
          this.renderQrFollowUpScreen()}
        {this.props.followUpSuccess && this.renderFollowUpSuccessMsgScreen()}
        {this.props.isNoThanksMessageClicked && this.renderThankYouMsgScreen()}
      </div>
    );
  }
}

export default QRCodeFollowUp;
