import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getQRSessionJoinType } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeSelector";
import { QR_SESSION_JOIN_TYPE } from "UTILS/Constants";

import logo from "ASSETS/Images/img_header_logo.png";
import icBack from "ASSETS/Images/ic_backarrow.svg";

import { getMessage } from "CONFIG/i18n";

export class ErrorPage extends Component {
  
  constructor(props){
    super(props);
  }

  getHeader = () => {
    return (
      <Row className="waitingHeader">
        <Col lg="8" md="8" xs="9" className="headerLogoContainer pl-0">
          <img className="logoHeader" src={logo} />
        </Col>
      </Row>
    );
  };

  goBack = () =>{
    this.props.goBack()
  }

  render() {
    return (
      <div>
        <section className="bgColor">
          {this.getHeader()}
          {this.props.isBackButtonEnabled && this.props.qrSessionJoinType === QR_SESSION_JOIN_TYPE.QR_SESSION_LIST &&
            <div className="d-flex align-items-center pt-3 backBtn pl-4">
              <img
                src={icBack}
                className="pr-2 cursorPointer"
                onClick={() => this.goBack()}
              />
              <span
                className="cursorPointer"
                onClick={() => this.goBack()}>
                {getMessage("BACK")}
              </span>
            </div>
          }
          <Container>
            <Row>
              <Col
                md="12"
                lg="12"
                className={"text-center sessionEnded"}
              >
                <h4 className="topMargin text-center">
                  {this.props.message}
                </h4>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    qrSessionJoinType: getQRSessionJoinType(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(ErrorPage);
