import React from "react";
import { Dropdown, DropdownMenu, DropdownItem, Button } from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import "../../App/App.less";
import "./Header.less";

import { USER_ROLES } from "UTILS/Constants";

import { getUserNameForSelectedLanguage } from "UTILS/Utility";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import Package from "../../../../package.json";

class UserDropdownModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false
    };
  }

  handleLogoutClick = event => {
    this.props.handleLogoutClick();
  };

  handleAboutClick = () => {
    this.props.showAboutOrgModal();
  };

  handleSettings = () => {
    console.log("Settings");
  };

  toggleChangePasswordModal = () => {
    this.props.toggleChangePasswordModal();
  }

  handleEditClick = () => {
    this.setState({
      isEditable: true
    });
  };

  toggle = () => {
    this.props.toggle();
  }

  render() {
    let userName = getUserNameForSelectedLanguage(this.props.language, 
      this.props.userProfileData.first_name + ' ' + (this.props.userProfileData.last_name ? this.props.userProfileData.last_name :''));
    return (
      <Dropdown isOpen={this.props.show} toggle={this.toggle} direction="down">
        {this.props.userIcon}
        <DropdownMenu className="userInfoMenu cursorPointer" right>
          <DropdownItem
            disabled
            className="userInfo pb-0"
          >
            <div>
              <div className="setWidth mt-1">
                <div className="nameEllipsis" id="userName">
                  {userName}
                  <CustomTooltip
                    tooltipText={userName}
                    tooltipId={"userName"}
                  />
                </div>
              </div>
              <div className="mt-2">
                <label className="roleUser">{_.startCase(_.toLower(getMessage(this.props.role)))}</label>
              </div>
            </div>
          </DropdownItem>
          <div className="editProfileBtnContainer mt-1 dropdown-item d-flex justify-content-center">
            <DropdownItem onClick={this.props.handleUpdateProfileClick} className="btn editProfilebtn">
              {this.props.isSSOLogin ? getMessage("VIEW") : getMessage("EDIT")}
            </DropdownItem>
          </div>
          {/*hiding password if single  sign on is enable  */}
          { !this.props.isSSOLogin &&
          <>
            <DropdownItem divider />
            <DropdownItem onClick={this.toggleChangePasswordModal}>
              <label className="userInfoMenuItem">
                {getMessage("CHANGE_PASSWORD")}
              </label>
            </DropdownItem>
          </>
          }

          {this.props.role === USER_ROLES.ADMIN && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={this.handleAboutClick}>
                <label className="userInfoMenuItem">
                  {getMessage("ABOUT_ORGANIZATION")}
                </label>
              </DropdownItem>
            </>
          )}
          {/*<DropdownItem divider />
             <DropdownItem onClick={this.handleSettings}>
              <label className="userInfoMenuItem">{getMessage("SETTINGS")}</label>
            </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem onClick={this.handleLogoutClick}>
            <label className="userInfoMenuItem">{getMessage("LOGOUT")}</label>
          </DropdownItem>
          <div className="versionInfoDiv">
            <label className="versionInfoItem">{`Version: ${Package.description}`}</label>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default UserDropdownModal;
