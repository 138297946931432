export const selectUserToken = state => {
  return state.UserReducer.userDetails && state.UserReducer.userDetails.token;
};

export const selectUserName = state => {
  const userDetails = state.UserReducer.userDetails;
  if (userDetails) {
    return (
      (userDetails.firstName +
      " " +
      (userDetails.lastName ? userDetails.lastName : "")).trim()
    );
  }

  return null;
};

export const selectCustomerId = state => state.UserReducer.userDetails.customerId;

export const selectSessionKey = state => {
  return (
    state.SessionReducer.userSessionDetails &&
    state.SessionReducer.userSessionDetails.sessionKey)
  || (state.SessionReducer.sessionDetails &&
    state.SessionReducer.sessionDetails.sessionKey);
};

export const selectWebsocketUrl = state => {
  return state.SessionReducer.sessionDetails.socketUrl;
};

export const getCustomerId = state => {
  return state.UserReducer.userDetails.customerId;
};

export const getUserId = state => {
  return state.UserReducer.userDetails.userId;
};

export const selectEndUserToken = state =>{
  return state.SessionReducer.sessionDetails.token
}

export const getSessionsCount = state => {
  return state.AdminReducer.sessionsCount
}

export const getSessionListPageSize = state => {
  return state.AdminReducer.sessionListPageSize
}

export const getSessionListCurrentPageNo = state =>{
  return state.AdminReducer.sessionListCurrentPageNo
}

export const getUsersCount = state => {
  return state.AdminReducer.usersCount
}

export const getUserListPageSize = state => {
  return state.AdminReducer.userListPageSize
}

export const getUserListCurrentPageNo = state =>{
  return state.AdminReducer.userListCurrentPageNo
}

export const selectUserDetails = state => {
  return state.UserReducer.userDetails;
};

export const selectSessionStatus = state => {
  return state.SessionReducer.sessionDetails.sessionStatus;
};

export const selectSessionType = state => {
  return state.SessionReducer.sessionDetails.sessionType;
};

export const getSessionFollowUpsCount = state => {
  return state.AdminReducer.sessionFollowUpsCount
}

export const getSessionFollowUpsPageSize = state => {
  return state.AdminReducer.sessionFollowUpsPageSize
}

export const getSessionFollowUpsCurrentPageNo = state =>{
  return state.AdminReducer.sessionFollowUpsCurrentPageNo
}

export const getSelectedReport = state =>{
  return state.AdminReducer.selectedReport
}