import React, { Component } from "react";
import _ from 'lodash';
import { Table, Row, Col , Input} from "reactstrap";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { getMessage } from "CONFIG/i18n";

import Pagination from "react-js-pagination";

import "../AdminDashboard.less";
import "./SessionList.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import { LANGUAGE, PAGE_COUNT } from "UTILS/Constants";
import { formatDateByLocale, getTimeInLocaleTimezone } from "UTILS/DateUtils";

import { userAction } from "CONFIG/ActionFactory";

// Common Components
import CustomDropdown from "../../CommonComponents/CustomDropdown/CustomDropdown";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

export default class SessionList extends Component{

  constructor(props){
    super(props);
    this.state={
      showSessionDetailsFlag: 1,
    };
  }

  componentDidMount() {
    // timeout is added because it takes some time for the 
    // contents of the table to be loaded completely 
    setTimeout(() => {
      this.props.setHeaderPadding();
    },10)
  }

  fetchTableData=(page)=>{
    this.props.fetchSessionsList(
      page - 1,
      this.props.sessionListPageSize,
      this.props.searchKey ? this.props.searchKey : undefined
    );
    const tableData = document.querySelector(".reportListContainer .react-bs-container-body");
    tableData.scrollTop = 0;
    this.props.setHeaderPadding();
  }

  showSessionDetails = () =>{
    this.setState({ showSessionDetailsFlag : 0 });
  }

  sessionNameFormat = (cell, row) => {
    return (
      <div>
        <label
          className="nameEllipsis cursorPointer custom-tooltip text-decoration "
          onClick={() => {
            this.props.getSessionInvitiesAndSessionDetails(row.session_key);
          }}
          id={`sessionName${row.session_key}`}
        >
          <CustomTooltip
            tooltipText={row.session_name}
            tooltipId={`sessionName${row.session_key}`}
          />
          {row.session_name}
        </label>
      </div>
    )
  }

  agentNameFormat = (cell, row) => {
    return (
      <div>
        <label
          className="nameEllipsis custom-tooltip"
          id={`agentName${row.session_key}`}
        >
          <CustomTooltip
            tooltipText={row.owner}
            tooltipId={`agentName${row.session_key}`}
          />
          {row.owner}
        </label>
      </div>
    );
  };

  dateFormat = (cell, row) => {
    return (
      <div className="nameEllipsis">
        {row.start_time ?` ${formatDateByLocale(row.start_time, this.props.language)} ${getTimeInLocaleTimezone(row.start_time)}` : "--"}
      </div>
    )
  }

  statusFormat = (cell, row) => {
    return (
      <label className="nameEllipsis">
        {getMessage(row.status, { lng: LANGUAGE.EN.name })}
      </label>
    );
  };

  renderSessionList = (sessionsList) => {
    return(
      <div>
        <div className="reportsTitle">
          { getMessage("SESSION_LIST") }
        </div>
        <BootstrapTable
          data={sessionsList}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='custom-list report-list'
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
          printable
        >
          <TableHeaderColumn
            dataField='session_key'
            hidden={true}
            isKey>
            {getMessage("SESSION_KEY")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.sessionNameFormat}
            dataField='session_name'
            // width="25"
            dataSort={true}
          >
            {getMessage("SESSION_NAME")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.agentNameFormat}
            dataField='owner'
            dataSort={true}
            headerAlign="center"
            dataAlign="center"
            // width="15"
          >
            {getMessage("ROLE_AGENT")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.dateFormat}
            dataField='start_time'
            dataSort={true}
            headerAlign="center"
            dataAlign="center"
            // width="20"
          >
            {getMessage("DATE")}
          </TableHeaderColumn>
          <TableHeaderColumn
            // width="15"
            dataSort={true}
            dataField='duration'
            dataFormat={(cell, row) => {
              return (
                <div> {row.status === "INVALID"
                  ? 0 
                  : row.duration
                    ? row.duration
                    : "--"}
                </div>
              )
            }}
            headerAlign="center"
            dataAlign="center"
          >
            {getMessage("DURATION")} ({getMessage("MINUTES")})
          </TableHeaderColumn>
          <TableHeaderColumn
            // width="15"
            dataField='status'
            dataFormat={this.statusFormat}
            headerAlign="center"
            dataAlign="center"
            dataSort={true}
          >
            {getMessage("STATUS")}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )

  }
  render() {
    const { sessionListCurrentPageNo, sessionsList, sessionsCount } = this.props;
    const displayPage = sessionListCurrentPageNo + 1;
    return (
      <div className="reportListContainer">
        {this.props.sessionsList && this.props.sessionsList.length > 0 ? (
          (this.state.showSessionDetailsFlag) ?
            <React.Fragment>
              <div className="overflow-auto ">
                {this.renderSessionList(sessionsList)}
              </div>
              <div className="pagination-margin">
                <Row className="paginationRow"> 
                  <Col xs={4} className="paginationEntries">
                    {getMessage("PAGINATION_TOTAL_ENTRIES",
                      {from: sessionListCurrentPageNo * this.props.sessionListPageSize + 1,
                        to: sessionListCurrentPageNo * this.props.sessionListPageSize + this.props.sessionListPageSize < sessionsCount ? sessionListCurrentPageNo * this.props.sessionListPageSize + this.props.sessionListPageSize : sessionsCount,
                        totalCount: sessionsCount})}
                  </Col> 
                  <Col xs={4} className="pagination">
                    {sessionsCount > this.props.sessionListPageSize ?
                      <Pagination
                        activePage={displayPage}
                        itemsCountPerPage={this.props.sessionListPageSize}
                        totalItemsCount={sessionsCount}
                        pageRangeDisplayed={3}
                        onChange={this.fetchTableData}
                        itemClass="page-item"
                        linkClass="page-link"
                      /> : null
                    }
                  </Col>
                  <Col xs={4} className="pageCount">
                    {sessionsCount > PAGE_COUNT[0].value &&
                  <CustomDropdown
                    options={PAGE_COUNT}
                    onChange={(value)=>{
                      this.props.fetchSessionsList(0, value);
                    }}
                    value={this.props.sessionListPageSize}
                    dropdownClass="pageSizeDropdown"
                  />
                    }
                  </Col>
                </Row>
              </div>
            </React.Fragment>:null
        ) : (
          <div className="margin-bottom" >
            <NoData minHeight={"60vh"}
              message={this.props.searchKey ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                searchKey: this.props.searchKey,
              }):getMessage("NO_MEETING_REPORTS")}  />
          </div>
        )}
      </div>
    );
  }

}

