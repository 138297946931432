import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";

export default class DataThroughputTest extends BaseTest {
  constructor(props) {
    super(props)
    // this.test = test;
    this.setValues();
    this.timeout=null;
  }

  setValues = () => {
    super.output=""
    this.testDurationSeconds = 5.0;
    this.startTime = null;
    this.sentPayloadBytes = 0;
    this.receivedPayloadBytes = 0;
    this.stopSending = false;
    this.samplePacket = '';

    for (var i = 0; i !== 1024; ++i) {
      this.samplePacket += 'h';
    }

    this.maxNumberOfPacketsToSend = 1;
    this.bytesToKeepBuffered = 1024 * this.maxNumberOfPacketsToSend;
    this.lastBitrateMeasureTime = null;
    this.lastReceivedPayloadBytes = 0;

    this.call = null;
    this.senderChannel = null;
    this.receiveChannel = null;
  }

  componentDidMount() {
    if(this.props.startTest) {
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest){
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
    }
  }

  run = () =>  {
    this.asyncCreateTurnConfig(this.start,
      () => {
        this.reportFatal();
        console.error('Throughput test for ', this.props.name, ' failed');
      }
    )
  }

  start = (config) => {
    // this.call = new Call(config, this.test);
    try {
      this.initialize(config, this)
      this.setIceCandidateFilter(BaseTest.isRelay);
      this.senderChannel = this.pc1.createDataChannel("Data");
      this.senderChannel.addEventListener('open', this.sendingStep);
      this.pc2.addEventListener('datachannel', this.onReceiverChannel);
      this.establishConnection();

      this.fataltimeout = setTimeout(() => {
        this.reportFatal('Channel not created');
        this.close();
      },20000)

    } catch(error) {
      console.error('error: ', error.message);
      this.reportError(error);
      this.done();
    }
  }

  onReceiverChannel = (event) => {
    this.receiveChannel = event.channel;
    this.receiveChannel.addEventListener('message',
      this.onMessageReceived.bind(this));
  }

  sendingStep = () => {
    this.fataltimeout && clearTimeout(this.fataltimeout);
    var now = new Date();
    if (!this.startTime) {
      this.startTime = now;
      this.lastBitrateMeasureTime = now;
    }
    console.log('send data');
    for (var i = 0; i !== this.maxNumberOfPacketsToSend; ++i) {
      if (this.senderChannel.bufferedAmount >= this.bytesToKeepBuffered) {
        break;
      }
      this.sentPayloadBytes += this.samplePacket.length;
      this.senderChannel.send(this.samplePacket);
    }

    if (now - this.startTime >= 1000 * this.testDurationSeconds) {
      // this.test.setProgress(100);
      this.stopSending = true;
    } else {
      // this.test.setProgress((now - this.startTime) /
      //     (10 * this.testDurationSeconds));
      this.timeout = setTimeout(this.sendingStep.bind(this), 1);
    }
  }

  onMessageReceived = (event) => {
    this.receivedPayloadBytes += event.data.length;
    var now = new Date();
    if (now - this.lastBitrateMeasureTime >= 1000) {
      var bitrate = (this.receivedPayloadBytes -
          this.lastReceivedPayloadBytes) / (now - this.lastBitrateMeasureTime);
      bitrate = Math.round(bitrate * 1000 * 8) / 1000;
      this.reportSuccess('Transmitting at ' + bitrate + ' kbps.');
      console.log('Transmitting at ' + bitrate + ' kbps.');
      this.lastReceivedPayloadBytes = this.receivedPayloadBytes;
      this.lastBitrateMeasureTime = now;
    }
    if (this.stopSending &&
        this.sentPayloadBytes === this.receivedPayloadBytes) {
      this.close();
      // this.call = null;

      var elapsedTime = Math.round((now - this.startTime) * 10) / 10000.0;
      var receivedKBits = this.receivedPayloadBytes * 8 / 1000;
      this.reportSuccess('Total transmitted: ' + receivedKBits +
          ' kilo-bits in ' + elapsedTime + ' seconds.');
      console.log('Total transmitted: ' + receivedKBits +
      ' kilo-bits in ' + elapsedTime + ' seconds.');
      console.log('Throughput test for ', this.props.name, ' passed');
      this.done();
    }
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  componentWillUnmount() {
    this.close();
    clearTimeout(this.timeout);
  }
}