import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import { DOMElement } from "reactstrap/lib/utils";

// Images
import ic_info_grey from "ASSETS/Images/info/ic_info_grey.svg";
import ic_error from "ASSETS/Images/ic_error.svg";

// Constants
import { TOOLTIP } from "UTILS/Constants";

import { isTouchDevice } from "UTILS/Utility";

// Styles
import "./CustomTooltip.less";

class CustomTooltip extends Component {
  static propTypes = {
    tooltipId: PropTypes.string.isRequired,
    tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tooltipType: PropTypes.string,
    tooltipPlacement: PropTypes.string,
    tooltipTrigger: PropTypes.string,
    tooltipBoundariesElement: PropTypes.oneOfType([PropTypes.string, DOMElement]),
    tooltipOffset: PropTypes.number,
    showIcon   : PropTypes.bool
  };

  static defaultProps = {
    tooltipType: TOOLTIP.TYPE.INFO,
    tooltipPlacement: "bottom",
    tooltipTrigger: "hover",
    tooltipBoundariesElement: "viewport",
    tooltipOffset: 0,
    showIcon   : false,
    tooltipText:"Tooltip"
  };

  constructor(props) {
    super(props);
    this.iconSrc = this.getIconSrcFromType();
  }

  getIconSrcFromType = () => {
    switch (this.props.tooltipType) {
      case TOOLTIP.TYPE.ERROR : return ic_error;
      case TOOLTIP.TYPE.INFO :
      default :
        return ic_info_grey;
    }
  }

  render() {
    const tooltipId = this.props.showIcon ? `icon-${this.props.tooltipId}` : this.props.tooltipId;
    return (
      <>     
        {(this.props.showIcon || !isTouchDevice()) && (
          //Currently not supporting Tooltips (except info icon ones) on touce devices due to library limitations
          <div className="icon-with-tooltip-container">
            {this.props.showIcon && (
              <img
                id={tooltipId}
                src={this.iconSrc}
                alt="Icon"
                className="cursorPointer"
              />
            )}
            <UncontrolledTooltip
              trigger={this.props.tooltipTrigger}
              target={tooltipId}
              placement={this.props.tooltipPlacement}
              className="icon-with-tooltip"
              boundariesElement={this.props.tooltipBoundariesElement}
              offset={this.props.tooltipOffset}
            >
              {this.props.tooltipText}
            </UncontrolledTooltip>
          </div>
        )}
      </>
    );
  }
}

export default CustomTooltip;
