import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { getMessage } from "CONFIG/i18n";
import { Row, Col, Label, Button,} from "reactstrap";

import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import PreviewDetailsModal from "./PreviewDetailsModal";

import { getUnzipped} from "UTILS/Utility";
import { ERROR } from "UTILS/Constants";

import { modulesAvailableConstant, modulesForSessionContext ,subLoggingLevels ,CANVAS_TYPES} from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import { formatDateByLocale , getTimeInLocaleTimezone } from "UTILS/DateUtils";
import "../AdminDashboard.less";
import "./SessionList.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import icBack from "ASSETS/Images/ic_backarrow.svg";
import ic_audio from "ASSETS/Images/ic_audio.svg";
import ic_canvas from "ASSETS/Images/ic_canvas.svg";
import ic_message from "ASSETS/Images/ic_message.svg";
import ic_video from "ASSETS/Images/ic_video.svg";
import ic_share from "ASSETS/Images/ic_share.svg";
import ic_location from "ASSETS/Images/ic_location.svg";
import ic_camera from "ASSETS/Images/ic_camera_header.svg";
import ic_pdf from "ASSETS/Images/pdf/ic_pdf.svg";
import ic_admin_co_browse from "ASSETS/Images/ic_admin_co_browse.svg"
import ic_QR_code from "ASSETS/Images/ic_QR_code.svg";
import download_img from "ASSETS/Images/ic_download_allow.svg";
import { userAction } from "CONFIG/ActionFactory";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Spinner from "COMPONENTS/Spinner/Spinner";
import ic_app_emulation from "ASSETS/Images/ic_app_emulation_small.svg";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
export default class SessionDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      currentSubloggingLevel: null,
      spinnerVisible: false
    }

  }
  componentWillUnmount() {
    this.props.onBackClick();
  }

  getScrollBarWidth() {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
    document.body.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    console.log('width :', width);
    el.remove();
    return width;
  }

  displayDownloadIcon = (cell, row) => {
    return (<a>
      <img
        className="downloadIcon"
        src={download_img}
        id="downloadIcon"
        onClick={() => { this.downloadObjectURL(row.id, row.storageLink) }}
      />
    </a>)
  }

  renderExportCheckboxes = () => {
    return (<label>
      <Checkbox/>
    
    </label>)
  }

  getNameOfObject = (storageLink) => {
    let previewName = storageLink.substring(storageLink.lastIndexOf('/') + 1);
    //Replacing extension zip to png for display purpose as Zip feature is added for better performance
    previewName = previewName.replace('zip', 'png');
    return previewName;
  }
  getCanvasIdFromLink = (url) => {
    //get canvasId from thumbnail link
    if(url.includes("canvas_")){
      return url.split(".zip")[0].split('canvas_')[1]
    } else if(url.includes("scan_code_canvas_")){
      return url.split(".zip")[0].split('scan_code_canvas_')[1]
    }
    return null
  }
  getPreviewModalDetails = (row) => {
    let previewObj = row;
    if (previewObj.subLoggingLevel !== CANVAS_TYPES.PDF) {
      this.setState({ spinnerVisible: true })
    }
    userAction.getPresignedObjectUrl(row.id).then(async response => {
      let fileUrl = {}
      this.setState({
        spinnerVisible: false, 
        previewLink: (previewObj.storageLink.includes('.zip') && previewObj.subLoggingLevel !== CANVAS_TYPES.PDF) ?
          await getUnzipped(response.data.data.presignedUrl, this.getCanvasIdFromLink(previewObj.storageLink)) : response.data.data.presignedUrl,
        previewType: previewObj.subLoggingLevel,
        previewName: this.getNameOfObject(previewObj.storageLink)
      },
      () => {
        fileUrl = response.data.data.presignedUrl;
        this.setState({ showPreview: true, fileUrl: fileUrl})
      })
    },
    error => {
      console.log('error: ***', error);
      this.setState({ showPreview: false, previewLink: "", previewType: '', previewName: '', spinnerVisible: false })
      if (error.code === ERROR.INVALID_REQUEST_DATA) {
        toastr.error("ERROR_IN_PREVIEW");
      } else {
        toastr.error(error.code);
      }
    })
  }
  displayLink = (cell, row) => {
    return (
      <label>
        <Button color="link" className="nameEllipsis pl-0 noMargin" onClick={() => this.getPreviewModalDetails(row)}>
          {this.getNameOfObject(row.storageLink)}
        </Button>
      </label>
    )
  }

  //to get presigned URL
  downloadObjectURL = (objectId, storageLink) => {
    this.props.showSpinner();
    userAction.getPresignedObjectUrl(objectId).then(async response => {
      this.downloadURL(storageLink.includes('zip') ?
        await getUnzipped(response.data.data.presignedUrl, this.getCanvasIdFromLink(storageLink)) : response.data.data.presignedUrl, storageLink)
    },
    error => {
      this.props.hideSpinner();
      console.log('error: ***', error);
      toastr.error(error.code)
    })
  }

  //to download Object on URL
  downloadURL = (url, storageLink) => {
    fetch(url).then(resp => resp.blob())
    .then(blob => {
      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      let downloadFileName = this.getNameOfObject(storageLink);
      if (isIE) {
        // IE10+
        navigator.msSaveBlob(blob, downloadFileName);
      } else {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.style = "display: none";
        link.href = window.URL.createObjectURL(blob);
        link.download = downloadFileName;
        link.click();
      }
      this.props.hideSpinner();
    },
    error => {
      console.error("Error downloading url" + url, error);
      this.props.hideSpinner();
    });
  };

  displayAllModuleList = () => {
    let allModuleList = [];

    _.map(modulesForSessionContext, (key, value) => {

      switch (key) {
        case "CANVAS":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={true}
                />
                <img
                  src={ic_canvas}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "AUDIO":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.AUDIO)}
                />
                <img
                  src={ic_audio}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "AUDIO_VIDEO":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.AUDIO_VIDEO)}
                />
                <img
                  src={ic_video}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "SCREENSHARE":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.SCREENSHARE)}
                />
                <img
                  src={ic_share}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CHAT":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.CHAT)}
                />
                <img
                  src={ic_message}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "LOCATION":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.LOCATION)}
                />
                <img
                  src={ic_location}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "PDF_EDITOR":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.PDF)}
                />
                <img
                  src={ic_pdf}
                  style={{
                    height: "17px",
                    width: "17px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "SNAP_SHOT":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.SNAPSHOT)}
                />
                <img
                  src={ic_camera}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "APP_EMULATION":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.APP_EMULATION)}
                />
                <img
                  src={ic_app_emulation}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "QR_CODE":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.QR_CODE)}
                />
                <img
                  src={ic_QR_code}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "ALLOW_DOWNLOAD":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.ALLOW_DOWNLOAD)}
                />
                <img
                  src={download_img}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
        case "CO_BROWSE":
          allModuleList.push(
            <Col className="paddingR0L3 pointerEventsNone" key={key} xs={12} lg={3}>
              <Label className="label noPadding">
                <Checkbox
                  checked={this.props.sessionDetails.modules.includes(
                    modulesAvailableConstant.CO_BROWSE)}
                />
                <img
                  src={ic_admin_co_browse}
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                />{" "}
                {getMessage(value)}
              </Label>
            </Col>
          );
          break;
      }
    });
    return allModuleList;
  }

  indexN = (cell, row, enumObject, index) => {
    return (<div>{index + 1}</div>)
  }

  getSessionDataForObjectType = (objectType) => {
    return this.props.sessionObjects
      ? this.props.sessionObjects.filter(sessionObject => sessionObject.subLoggingLevel === objectType)
      : [];
  }

renderPDFLinks = (pdfLinks) => {
  return(
    <BootstrapTable
      data={pdfLinks}
      tableHeaderClass="tableHeader pdfTableHeader"
      tableBodyClass="tableBody pdfTableBody"
      containerClass='session-detail-list'
      bordered={false}
      maxHeight="270px"
      className="mb-5"
    >
      {/* <TableHeaderColumn
      dataField="select"
      width="10%"
      dataFormat={this.renderExportCheckboxes}
    >
      {getMessage("SELECT")}
    </TableHeaderColumn> */}
      <TableHeaderColumn
        dataField="storageLink"
        width="50%"
        dataFormat={this.displayLink}
      >
        {getMessage("FILE_NAME")}
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="id"
        width="40%"
        dataFormat={this.displayDownloadIcon}
        className="floatRight"
        isKey
      >
        {getMessage("DOWNLOAD")}
      </TableHeaderColumn>
    </BootstrapTable>
  )
}

renderCanvasLink = (canvasLinks) => {
  return (
    <BootstrapTable
      data={canvasLinks}
      tableHeaderClass="tableHeader canvasHeader"
      tableBodyClass="tableBody canvasTableBody"
      containerClass='session-detail-list'
      bordered={false}
      maxHeight="270px"
    >
      {/* <TableHeaderColumn
      dataField="select"
      width="10%"
      dataFormat={this.renderExportCheckboxes}
    >
      {getMessage("SELECT")}
    </TableHeaderColumn> */}
      <TableHeaderColumn
        dataField="storageLink"
        width="50%"
        isKey
        dataFormat={this.displayLink}
      >
        {getMessage("FILE_NAME")}
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="id"
        width="40%"
        dataFormat={this.displayDownloadIcon}
        className="floatRight"
      >
        {getMessage("DOWNLOAD")}
      </TableHeaderColumn>
    </BootstrapTable>
  )
}
render() {
  let canvasLinks = this.getSessionDataForObjectType(subLoggingLevels.advanced.CANVAS_OBJECTS);
  let pdfLinks = this.getSessionDataForObjectType(subLoggingLevels.advanced.PDF);
  return (
    <div style={this.state.spinnerVisible ? {marginRight:this.getScrollBarWidth()}: {}}>
      <Col className="backBtn p-0 pb-3 d-flex marginAuto">
        <img
          src={icBack}
          className="pr-2 cursorPointer d-flex"
          onClick={this.props.onBackClick}
        />
        <div className="cursorPointer" onClick={this.props.onBackClick}>
          {getMessage("BACK")}
        </div>
      </Col>
      <div className="SessionDetailContainer mb-3">
        <Spinner showSpinner={this.state.spinnerVisible} />
        {this.state.showPreview &&
          <PreviewDetailsModal
            show={this.state.showPreview}
            previewName={this.state.previewName}
            previewType={this.state.previewType}
            previewLink={this.state.previewLink}
            pdftronKey={this.props.pdftronKey}
            fileUrl={this.state.fileUrl}
            language={this.props.language}
            hidePreviewModal={() => {
              this.setState({ showPreview: false })
            }}
          />
        }
        <Row className="mainTitle flex5">
          <Col className="mainLabel pl-0">{getMessage("SESSION_DETAILS_TITILE")}</Col>

        </Row>
        <Row className="sessionDetailHeader">
          <Col xs={3} className="noPadding displayFlex">
            <label
              className="nameEllipsis m-0 custom-tooltip"
              id="sessionName">
              {getMessage("SESSION_NAME")}: {this.props.sessionDetails.session_name}
              <CustomTooltip tooltipText={this.props.sessionDetails.session_name} tooltipId="sessionName" />
            </label>
          </Col>
          <Col xs={3} className="noPadding displayFlex">
            <label
              className="nameEllipsis m-0 custom-tooltip"
              id="agent">
              {getMessage("ROLE_AGENT")}: {this.props.sessionDetails.owner}
              <CustomTooltip tooltipText={this.props.sessionDetails.owner} tooltipId="agent" />
            </label>
          </Col>
          <Col xs={3} className="noPadding">
            {getMessage("DATE")}: {formatDateByLocale(this.props.sessionDetails.start_time)} {getTimeInLocaleTimezone(this.props.sessionDetails.start_time)}
          </Col>
          <Col xs={3} className="noPadding">
            {getMessage("DURATION")}: {this.props.sessionDetails.status === "INVALID"
              ? 0
              : this.props.sessionDetails.duration
                ? `${this.props.sessionDetails.duration} ${getMessage("MINUTES")}`
                : "--"}
          </Col>
        </Row>
        <Row className="mainTitle flex5">
          {getMessage("INVITEES")}
        </Row>
        {this.props.inviteesList && this.props.inviteesList.length > 0 &&
        <BootstrapTable
          data={this.props.inviteesList}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='custom-list session-detail-list'
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
        >
          <TableHeaderColumn
            dataField="any"
            width="3%"
            dataFormat={this.indexN}
            dataAlign='center'
            isKey
          >
            {getMessage("SR_NO")}
          </TableHeaderColumn>
          {/*<TableHeaderColumn
            dataField='name'
            width="15%"
            dataFormat={this.organizationFormat}
            dataAlign='center'
            isKey
          >
            {getMessage("NAME")}
          </TableHeaderColumn>*/}
          <TableHeaderColumn
            dataField='email'
            width="15%"
            dataFormat={this.organizationFormat}
            dataAlign='center'
          >
            {getMessage("EMAIL")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='phone'
            width="15%"
            dataFormat={this.organizationFormat}
            dataAlign='center'
          >
            {getMessage("PHONE_NUMBER")}
          </TableHeaderColumn>
        </BootstrapTable>
        }
        <hr />
        <Row className="mainTitle flex5">
          {getMessage("FEATURE_USED")}
        </Row>
        <hr />
        <Row className="flex5 moduleList">
          {this.displayAllModuleList()}
        </Row>

        <hr />

        {(this.props.loggingLevel === "ADVANCED" ||
          (canvasLinks && canvasLinks.length > 0)) && (
        <>
          <Row className="mainTitle flex5">
            {getMessage("CANVAS_OBJECTS")}
          </Row>
          <hr />
          {canvasLinks && canvasLinks.length > 0 ? (
            this.renderCanvasLink(canvasLinks)
          ) : (
            <NoData
              minHeight={"13vh"}
              message={getMessage("NO_CANVAS_OBJECTS")}
            />
          )}
        </>
        )}
        <hr />
        {(this.props.loggingLevel === "ADVANCED" ||
          (pdfLinks && pdfLinks.length > 0)) && (
        <>
          <Row className="mainTitle flex5">
            {getMessage("PDF")}
          </Row>
          <hr />
          {pdfLinks && pdfLinks.length > 0 ? (
            this.renderPDFLinks(pdfLinks)
          ) : (
            <NoData 
              minHeight={"13vh"} 
              message={getMessage("NO_PDF_FILES")}
            />
          )}
        </>
        )}
        {/* {(canvasLinks.length > 0 || pdfLinks.length > 0) ?
      <div>
        <hr />
        <div className="text-center mt-4 mb-5">
          <Button className="exportBtn buttons">
            {getMessage("EXPORT")}
          </Button>
        </div>
      </div>
      : ""} */}
      </div>
    </div>
  )

}
}
