import { sessionActions } from "CONFIG/ActionFactory";
import * as log from "loglevel";
import { ACTIONS, SESSION_STATUS } from "UTILS/Constants";
import { socketMessage, webRtcMessageId} from "WEBSOCKET/constants";
log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.ERROR._ERROR:
      switch (data.code) {
        case webRtcMessageId.KMS_NOT_CONNECTED:
          store.dispatch({
            type: ACTIONS.ERROR.KMS_NOT_CONNECTED,
            value: false
          });
          break;
        case webRtcMessageId.ERROR_IN_WEBRTC_MEDIA_SHARING:
          store.dispatch({
            type: ACTIONS.ERROR.ERROR_IN_WEBRTC_MEDIA_SHARING
          });
          store.dispatch({
            type: ACTIONS.RESET_SHOW_KMS_CONNECTION_STATUS
          });
          store.dispatch({
            type: ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY,
            snapshotPermissionReply: null
          });
          store.dispatch({
            type: ACTIONS.SET_SNAPSHOTER_ID,
            snapshoterId: ""
          });
          store.dispatch({
            type: ACTIONS.SET_SNAPSHOTEE_ID,
            snapshoteeId: ""
          });
          if (store.getState().ParticipantReducer.participants.length < 3) {
            store.dispatch({
              type: ACTIONS.SNAPSHOT.SHOW_SNAPSHOT,
              value: false
            });
          }
          break;
        case socketMessage.subCategories.ERROR.INVALID_REQUEST_DATA:  
          store.dispatch({
            type: ACTIONS.SET_WEBSOCKET_ERROR,
            websocketError: data.code
          });
          break;
        case webRtcMessageId.ROOM_NOT_FOUND:
          store.dispatch({
            type: ACTIONS.SET_END_SESSION_FLAG,
            flag: true
          });
          break;
        case webRtcMessageId.ROOM_NOT_ACTIVE:
          store.dispatch({
            type: ACTIONS.SET_SESSION_STATUS,
            sessionStatus: SESSION_STATUS.CLOSING
          })
          break
        case socketMessage.subCategories.ERROR.INVALID_SESSION:
          store.dispatch({
            type: ACTIONS.SET_END_SESSION_FLAG,
            flag: true
          });
          sessionActions.setSessionInvalidFlag(true);
          break;
        default:
          // console.log("data :", data);
          break;
      }
      break;
  }
};
