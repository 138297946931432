import React, { Component } from "react";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobileOnly } from 'react-device-detect';

import { setSnapshotDrawingModeValue,setSnapshotAnnotationMode, addStickyObject} from "./SnapshotAction";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import _ from "lodash";

import * as kurentoUtils from "kurento-utils";

// components
import ShowSnapOptionsModal from "COMPONENTS/CommonComponents/NegativeActionConfirmationModal/NegativeActionConfirmationModal";
import ConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import Participants from "COMPONENTS/CommonComponents/Header/Participants/Participants";
import Canvas from "COMPONENTS/CommonComponents/Canvas/Canvas";
import BitRateTile from "COMPONENTS/CommonComponents/BitRate/BitRateTile";

// Assets 
import ic_snapshot_ellipse from "ASSETS/Images/Snapshot/ic_snapshot_ellipse.svg";
import ic_snapshot_ellipse_selected from "ASSETS/Images/Snapshot/ic_snapshot_ellipse_selected.svg";
import ic_clear_canvas from "ASSETS/Images/Snapshot/ic_clear_canvas.svg";
import ic_clear_canvas_selected from "ASSETS/Images/Snapshot/ic_clear_canvas_selected.svg";
import ic_take_snapshot from "ASSETS/Images/Snapshot/ic_take_snapshot.svg";
import ic_take_snapshot_selected from "ASSETS/Images/Snapshot/ic_take_snapshot_selected.svg";
import ic_close from "ASSETS/Images/Snapshot/ic_close.svg";
import ic_close_selected from "ASSETS/Images/Snapshot/ic_close_selected.svg";
import ic_camera_flip from "ASSETS/Images/Snapshot/ic_camera_flip.svg";
import ic_camera_flip_selected from "ASSETS/Images/Snapshot/ic_camera_flip_selected.svg";
import ic_select_member from "ASSETS/Images/Snapshot/ic_select_member.svg";
import ic_select_member_selected from "ASSETS/Images/Snapshot/ic_select_member_selected.svg";
import ic_object_tracking from "ASSETS/Images/Snapshot/ic_object_tracking.svg";
import ic_object_tracking_selected from "ASSETS/Images/Snapshot/ic_object_tracking_selected.svg";
import ic_scan_code from "ASSETS/Images/Snapshot/ic_scan_code.svg";
import ic_scan_code_selected from "ASSETS/Images/Snapshot/ic_scan_code_selected.svg";
// Constants
import { socketMessage, webRtcMessageId, mediaType } from "WEBSOCKET/constants";
import { USER_ROLES, Modules, TIMEOUT } from "UTILS/Constants";
import { getMediaConstraints,
  convertBlobToFile, 
  getUserMediaError,
  getTurnServerOptionConfig,
  isMobileOriPad,
  isIphone13OrHigher,
  getUserNameAcronym,
  isRealwearDevice
} from "UTILS/Utility";

import { isIOS, isIPad13, isSafari } from 'react-device-detect'
import { CANVAS_TYPES, CANVAS_SOURCE, ANNOTATIONS, SNAPSHOT_ANNOTATIONS_CLEARED_REASON } from "UTILS/Constants";
import { wsocket } from "WEBSOCKET/wsocket";

import { selectDefaultFontInfo } from "../selectors";

// String
import { getMessage } from "CONFIG/i18n";

// Styles
import "./AnnotationBar.less";
import "./Snapshot.less";
import "./../../Header/Header.less";
import "../../App/App.less";
import { getUserName } from "Routes/selectors";

export class Snapshot extends Component {
  constructor(props) {
    super(props);
    this.currentStream = null;
    this.isAndroidMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent)
    this.isIE = /*@cc_on!@*/false || !!document.documentMode;
    this.videoStreaming = null;
    this.qrcodeRegionId = "html5qr-code-full-region";

  }
  state = {
    deviceList: {},
    selectedDevice: null,
    facingMode: "environment",
    streaming: false,
    isCanvas: false,
    showEndSnapshotConfirmationModal:false,
    showParticipant: false,
    enableCamera: false,
    videoWidth: 0,
    videoHeight: 0,
    showSnapshotParticipantsBar: false,
    // to clean up opencv ref and stop the object tracking on clear canvas/change the snapshotee/take snapshot
    clearCanvasAnnotations: false,
    showSwitchAnnotationModeWarning: false,
    showStopStickyModeWarning: false,
    annotationClickedMode: ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION
  };
  
  componentDidMount() {
    // if user refreshes browser while snapshot is running 
    // we have to delete snapshot canvas    
    let event = (isSafari && isIOS)? "pagehide" : "beforeunload";
    window.addEventListener(event, () => {
      if (this.props.organiser === this.props.uniqueId && this.props.canvasId) {
        // before deleting snapshot canvas change active canvas 
        // because deleting active canvas will set active canvas to 1
        // set active canvas which is active in canvas list 
        //this.changeActiveCanvas();
        this.deleteSnapshotCanvas();
      }
    });
    
    if (!wsocket.io) return;

    // interval used to determine if video streaming has started on IE 
    // TODO: this can also be used to hide spinner, instead of timeout of 2sec 
    if (this.isIE) {
      this.videoStreaming = setInterval(() => {
        var video = document.getElementById("snapshotvideo");
        // add snapshot canvas for IE as canPlay does not work on IE
        if(video && video.videoHeight !== this.state.videoHeight && video.videoWidth !== this.state.videoWidth)
        {
          if(this.props.organiser === this.props.uniqueId && !this.props.canvasId){
            this.addSnapshotCanvas();
          }
          this.setVideoDimensionsAndScroll()
        }
      }, 2000);
    }
    
    var mediaElement = document.getElementById("snapshotvideo");
    mediaElement.oncanplay = () => {
      // add snapshot canvas 
      if (this.props.organiser === this.props.uniqueId && !this.isIE) {
        this.addSnapshotCanvas();
      }
      this.setVideoDimensionsAndScroll();
    }
    
    // reset video dimensions when stream resizes (orientation change)
    mediaElement.onresize = () => {
      var video = document.getElementById("snapshotvideo");
      if(video && (video.videoHeight/video.videoWidth).toFixed(2) !== 
        (this.state.videoHeight/this.state.videoWidth).toFixed(2)) {
      // if(video.videoHeight !== this.state.videoHeight ||
      //   video.videoWidth !== this.state.videoWidth) {
        this.props.showSpinner();
        this.setVideoDimensions();
      }
    }

    // if (this.props.snapshotPermissionReply !== true) {
    //   return;
    // }
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return;
    } 
    
    let isVideoCallRunning = false;
      
    if(this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO &&
       this.props.organiser !== this.props.uniqueId){
      this.props.admittedParticipants.forEach(participant => {
        if (participant.uniqueId === this.props.snapshoteeId && !isVideoCallRunning) {
          isVideoCallRunning = participant.mediaType === mediaType.AUDIO_VIDEO
        }
      })
    }
    if(isVideoCallRunning) {
      this.props.audioVideoAction.setStopVideoCallFlag(true);
    } else if(this.props.uniqueId == this.props.snapshoteeId) {
      this.startSnapshot();
    }

    var mediaElement = document.getElementById("snapshotvideo");
    mediaElement.oncanplaythrough = () => {
      this.setVideoDimensionsAndScroll();
    }

    if (this.props.isSnapshotRunning && this.props.uniqueId !== this.props.snapshoteeId) {
      // Snapshot for all participants: Create remote RTC Peer after we get _SNAPSHOT_RESOURCES_INITIALIZED
      var mediaElement = document.getElementById("snapshotvideo");
      mediaElement.style.display = "block"
      setTimeout(() => {
        if (this.props.uniqueId !== this.props.snapshoteeId) {
          this.generateRemoteSnapShotVideo();
        }
      }, 200);
    }

    window.addEventListener("orientationchange", () => {
      this.onOrientationChange()
    });
  }

  onOrientationChange() {
    if (this.isAndroidMobile || this.isIphone || isRealwearDevice()) {
      this.handleSnapshotAnnotationsCleared(SNAPSHOT_ANNOTATIONS_CLEARED_REASON.ORIENTATION_CHANGE)
    }
  }

  setVideoDimensionsAndScroll = () => {
    if(!this.state.streaming){
      this.setVideoDimensions();
      this.setState({
        streaming: true
      });
      let snapshot = document.getElementById("snapshot");
      isMobileOriPad() && snapshot && snapshot.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  startSnapshot = () => {
    // In case of iphone we do not get deviceId until we accept camera request from browser.
    // so by default it start front camera stream so we stop that stream in handleSuccess fun
    if(this.isIphone || isIPad13 || isSafari) { 
      navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        this.stopMediaTracksOfGivenStream(stream);
        this.enumerateDevices();
      })
      .catch((error) => {
        console.error('getUserMedia() error: ', error);
        let errorMessage = getUserMediaError(error, mediaType.VIDEO)
        if(errorMessage){
          if(!this.props.showAudioVideoComponent){ // to avoid message duplication on user end when video and snapshot started simultaneously
            toastr.error(errorMessage)
          }
          this.props.hideSpinner(true);
          if(errorMessage === "CAMERA_BUSY")
            this.handleRemove(true, true); 	
          else if(errorMessage === "CAMERA_PERMISSION" || errorMessage === 'WEBCAM_NOT_CONNECTED')
            this.handleRemove(false);// false parameter is for user block camera permission	
        }
      });
    } else {
      this.enumerateDevices();
    }
    if(!isMobileOriPad()) {
      navigator.mediaDevices.ondevicechange = (event) => {
        this.enumerateDevices(true);
      }
    }
  }
  
  setVideoDimensions = () => {
    var video = document.getElementById("snapshotvideo");
    if(video && video.videoHeight && video.videoWidth) {
      this.setState({
        enableCamera: true, 
        videoHeight: video.videoHeight,
        videoWidth: video.videoWidth
      })
      this.props.hideSpinner();
    }
  }

  addSnapshotCanvas = () => { 
    let message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasType: CANVAS_TYPES.REGULAR,
        canvasSource: CANVAS_SOURCE.SNAPSHOT
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  changeAnnotationMode = (mode) => {
    this.props.setSnapshotAnnotationMode(mode); 
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SNAPSHOT_ANNOTATIONS_MODE,
        sessionKey: this.props.sessionKey,
        mode,
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  isStickyAnnotationMode(snapshotAnnotationMode = this.props.snapshotAnnotationMode) {
    return snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STICKY_ANNOTATION;
  }

  isScanCodeAnnotationMode(snapshotAnnotationMode = this.props.snapshotAnnotationMode) {
    return snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.SCAN_CODE;
  }

  isStaticAnnotationMode(snapshotAnnotationMode = this.props.snapshotAnnotationMode) {
    return snapshotAnnotationMode === ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION;
  }

  handleSnapshotAnnotationsCleared = (reason) => {
    if (this.props.uniqueId === this.props.snapshoteeId &&
      this.isStickyAnnotationMode() &&
      this.props.lastDrawnObject !== null) {
      let message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.SNAPSHOT_ANNOTATIONS_CLEARED,
          sessionKey: this.props.sessionKey,
          reason,
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
  }

  clearSnapshotCanvas = () => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.CLEAR_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.canvasId,
        participantId: this.props.participantId,
        canvasType: CANVAS_TYPES.REGULAR,
        activePage: -1,
        canvasSource: CANVAS_SOURCE.SNAPSHOT
      }
    };
    this.setState({ clearCanvasAnnotations: true })
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  deleteSnapshotCanvas = () => {
    if(this.props.canvasId) {
      const message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.DELETE_CANVAS,
          sessionKey: this.props.sessionKey,
          canvasId: this.props.canvasId,
          canvasType: CANVAS_TYPES.REGULAR,
          activePage: -1,
          participantId: this.props.participantId
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }
  };

  changeActiveCanvas = () => {
    let message = {};
    message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.CHANGE_ACTIVE_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.whiteboardActiveCanvas.canvasId,
        canvasType: this.props.whiteboardActiveCanvas.canvasType,
        activePage: -1
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  enumerateDevices = (onDeviceChange = false) => {
    if (this.props.uniqueId === this.props.snapshoteeId) {
      if(isMobileOriPad() && this.state.facingMode) {
        // (Iphone Mobile): Change facingMode only once if faceingMode is null
        // this.setState({
        //   facingMode: this.state.facingMode && this.state.facingMode == 'environment' ? 'user' : 'environment'
        // }, () => {
        this.displayCameraStream();
        //});
      } else if(!isMobileOriPad()){
        //in desktop case execute this every time if device changes and first time to get device list
        navigator.mediaDevices.enumerateDevices().then(devices => {
          // this.props.showSpinner();
          //get camera list
          let deviceList = devices.filter(device => device.kind === "videoinput");
          this.setState({
            deviceList: deviceList
          });
          let currentDeviceId = this.state.selectedDevice && this.state.selectedDevice.deviceId;
          let isCurrentlyStreamingDeviceInNewDeviceList = devices.filter(device => device.deviceId === currentDeviceId).length > 0;
          // onDeviceChange, if same device is being used for streaming, just hode spinner and return
          if(onDeviceChange && isCurrentlyStreamingDeviceInNewDeviceList) {
            this.props.hideSpinner();
            return;
          }
          let deviceToBeSelected = deviceList[0];
          if(deviceToBeSelected && currentDeviceId !== deviceToBeSelected.deviceId) {
            this.setState({
              selectedDevice:deviceToBeSelected,  //we are selecting back camera by deafult if it is mobile
            }, () => {
              this.displayCameraStream();
            });
          } else {
            // When there is only one webcam connected and we reconnect that webcam 
            // selectedDevice value is not set to null 
            // due to which stream does not start again 
            // Reason: deviceToBeSelected is undefined
            if(!deviceToBeSelected && onDeviceChange) {
              this.setState({
                selectedDevice: null
              })
            }
            this.props.hideSpinner();
          }
        });
      }
    }
  }

  displayCameraStream = () => {
    // if user_role == user
    if (this.props.uniqueId === this.props.snapshoteeId) {
      //if (this.state.selectedDevice !== null || this.state.facingMode !== null) {
      let videoConstraints = getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings, null, true);
      if(!isMobileOriPad() && this.state.selectedDevice && this.state.selectedDevice.deviceId) {
        videoConstraints.deviceId = this.state.selectedDevice.deviceId;
      }
      if(isMobileOriPad() && this.state.facingMode) {
        videoConstraints.video.facingMode = { exact: this.state.facingMode };
      }
      this.stopMediaTracksOfGivenStream(this.currentStream);
      navigator.mediaDevices.getUserMedia(videoConstraints)
      .then(stream => this.generateLocalSnapShotVideo(stream))
      .catch((error) => {
        let errorMessage = getUserMediaError(error, mediaType.VIDEO)
        if(errorMessage){
          toastr.error(errorMessage)
          this.props.hideSpinner(true);
          if(errorMessage === "CAMERA_BUSY")
            this.handleRemove(true, true); 	
          else if(errorMessage === "CAMERA_PERMISSION" || errorMessage === 'WEBCAM_NOT_CONNECTED')
            this.handleRemove(false);// false parameter is for user block camera permission	
          
          return;
        } else{
          if(!isMobileOriPad() && this.deviceList && this.deviceList.length > 1) {
            this.switchCamera();
          }
          else {
            this.props.hideSpinner(true);
          }
        }          
      });
      //}
    }
  }

  componentWillUnmount() {
    this.props.headerAction.updateHeaderIconClass(Modules.SNAPSHOT, "moduleIcon", " cursorPointer");
    if (this.props.uniqueId === this.props.snapshoteeId) {
      this.stopMediaTracksOfGivenStream(this.currentStream);//we are stopping stream on component unmount
    }
    if(this.videoStreaming) 
      clearInterval(this.videoStreaming);

    if (this.props.organiser === this.props.uniqueId && this.props.canvasId) {
      // this.changeActiveCanvas();
      this.deleteSnapshotCanvas();
    }
    // Reset isDrawingModeValue in reducer for agent
    if (this.props.uniqueId === this.props.organiser) {
      this.props.setSnapshotDrawingModeValue(false);
    }
    this.props.deregisterAllVoiceCommands()
  }

  voiceCommandSetZoomLevelHandler = () => {
    const cameraZoomLevels = JSON.parse(window._env_.REACT_APP_REALWEAR_ZOOM_LEVELS);
    cameraZoomLevels.forEach(cameraZoomLevel => {
      const setZoomLevel = {
        voiceCommand: `VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_${cameraZoomLevel.zoomLevel}`,
        action: () => {
          journey.camera.setCameraZoom(cameraZoomLevel.zoomValue);
        }
      }
      this.props.handleVoiceCommand(setZoomLevel)
    });
  }

  voiceCommandFlashOnHandler = () => {
    const flashOn = {
      voiceCommand: "VOICE_COMMANDS_FLASH_ON",
      action: () => {
        journey.camera.enableCameraFlash(true);
      }
    }
    this.props.handleVoiceCommand(flashOn)
  }

  voiceCommandFlashOffHandler = () => {
    const flashOff = {
      voiceCommand: "VOICE_COMMANDS_FLASH_OFF",
      action: () => {
        journey.camera.enableCameraFlash(false);
      }
    }
    this.props.handleVoiceCommand(flashOff)
  }

  deregisterFlashOnOffVoiceCommandsHandler = () => {
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_FLASH_ON");
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_FLASH_OFF");
  }

  deregisterSetZoomLevelVoiceCommandsHandler = () => {
    const cameraZoomLevels = JSON.parse(window._env_.REACT_APP_REALWEAR_ZOOM_LEVELS);
    cameraZoomLevels.forEach(cameraZoomLevel => {
      this.props.deregisterVoiceCommand(`VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_${cameraZoomLevel.zoomLevel}`)
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (isRealwearDevice() && prevState.streaming !== this.state.streaming) {
      if (this.state.streaming) {
        this.voiceCommandSetZoomLevelHandler();
        this.voiceCommandFlashOnHandler();
        this.voiceCommandFlashOffHandler();
      } else {
        this.deregisterSetZoomLevelVoiceCommandsHandler();
        this.deregisterFlashOnOffVoiceCommandsHandler();
      }
    }

    if (this.props.snapshotAnnotationMode != prevProps.snapshotAnnotationMode) {
      this.props.uniqueId === this.props.organiser && 
      !this.isStaticAnnotationMode() && this.props.setSnapshotDrawingModeValue(false);
      setTimeout(() => {
        this.props.hideSpinner();
      }, 100);
    }
    // if object tracking started and snapshotee is on mobile/tab
    // if snapshotee flip camera or change orientation then clear the canvas
    // and toast canvas clear reason
    if (this.props.snapshotAnnotationsClearedReason != prevProps.snapshotAnnotationsClearedReason &&
      this.props.snapshotAnnotationsClearedReason !== null &&
      this.props.lastDrawnObject !== null &&
      this.isStickyAnnotationMode()) {
      if (this.props.uniqueId === this.props.organiser) {
        this.clearSnapshotCanvas();
      }
      if (this.props.snapshotAnnotationsClearedReason === SNAPSHOT_ANNOTATIONS_CLEARED_REASON.CAMERA_SWITCH) {
        toastr.info("SNAPSHOT_CAMERA_SWITCHED_ANNOTATIONS_CLEARED_NOTIFICATION", null, { autoClose: TIMEOUT.LONG })
      } else if (this.props.snapshotAnnotationsClearedReason === SNAPSHOT_ANNOTATIONS_CLEARED_REASON.ORIENTATION_CHANGE) {
        toastr.info("SNAPSHOT_ORIENTATION_CHANGED_ANNOTATIONS_CLEARED_NOTIFICATION", null, { autoClose: TIMEOUT.LONG })
      }
    }
    // user get notification when agent change snapshotAnnotationMode
    if (this.props.snapshotAnnotationMode !== prevProps.snapshotAnnotationMode && this.props.uniqueId !== this.props.organiser) {
      if (this.props.lastDrawnObject !== null) {
        toastr.info(this.isStickyAnnotationMode() ?
          "SNAPSHOT_OBJECT_TRACKING_STARTED_ANNOTATIONS_CLEARED_NOTIFICATION" :
          this.isScanCodeAnnotationMode() ? 
            "SNAPSHOT_SCAN_CODE_STARTED_ANNOTATIONS_CLEARED_NOTIFICATION" :
            "SNAPSHOT_OBJECT_TRACKING_STOPPED_ANNOTATIONS_CLEARED_NOTIFICATION", null, { autoClose: TIMEOUT.LONG })
      } else {
        toastr.info(this.isStickyAnnotationMode() ?
          "SNAPSHOT_OBJECT_TRACKING_STARTED_NOTIFICATION" :
          this.isScanCodeAnnotationMode() ?
            "SNAPSHOT_SCAN_CODE_STARTED_NOTIFICATION"
            : null, null, { autoClose: TIMEOUT.LONG })
        toastr.info(this.isStickyAnnotationMode(prevProps.snapshotAnnotationMode) ?
          "SNAPSHOT_OBJECT_TRACKING_STOPPED_NOTIFICATION" :
          this.isScanCodeAnnotationMode(prevProps.snapshotAnnotationMode) ?
            "SNAPSHOT_SCAN_CODE_STOPPED_NOTIFICATION" : null, null, { autoClose: TIMEOUT.LONG })
      } 
      if (this.props.uniqueId === this.props.snapshoteeId && (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode())) {
        toastr.info("SNAPSHOT_ANNOTATIONS_CLEAR_WARNING", null, { autoClose: TIMEOUT.LONG });
      }
    }
    if (!_.isEqual(this.props.snapshotScanCodeResult, prevProps.snapshotScanCodeResult) && this.props.snapshotScanCodeResult && this.props.organiser === this.props.uniqueId) {
      this.handleRemove();
    }
    // When Agent ink is unlock and snapshotee closes  the snapshot then set Streaming to false
    if(this.props.snapshoteeId !== prevProps.snapshoteeId && !this.props.snapshoteeId) {
      this.setState({
        streaming: false
      })
    }
    // when there is no user selected for snapshot but canvas exists, delete the existing canvas 
    // so that data of old canvas does not get drawn on new canvas
    if(this.props.snapshoteeId !== prevProps.snapshoteeId && this.props.canvasId ) {
      this.changeActiveCanvas();
      this.deleteSnapshotCanvas();
    }

    // usecase: switch camrea on IE.
    if(this.props.isPeerDisposed) {
      // if user
      if (this.props.uniqueId == this.props.snapshoteeId) {
        this.displayCameraStream();
      } else {  // if agent 
        if(!this.isIE)
          this.props.showSpinner();
        this.generateRemoteSnapShotVideo();
      }
      this.props.sessionActions.setPeerDisposed(false);
    }
    // if (
    //   this.props.snapshotPermissionReply !==
    //   prevProps.snapshotPermissionReply &&
    //   this.props.snapshotPermissionReply === true
    // ) {
    //   var mediaElement = document.getElementById("snapshotvideo");
    //   mediaElement.style.display = "block"
    //   setTimeout(() => {
    //     if (this.props.uniqueId !== this.props.snapshoteeId) {
    //       this.generateRemoteSnapShotVideo();
    //     }
    //   }, 200);
    // }
    if (
      this.props.snapshotPermissionReply !==
      prevProps.snapshotPermissionReply &&
      this.props.snapshotPermissionReply !== true
    ) {
      var mediaElement = document.getElementById("snapshotvideo");
      mediaElement.style.display = "none"
      this.props.sessionActions.updateSnapshotPermissionReply(null);
      this.props.sessionActions.updateSnapshotPermissionStatus({});
      
      // snapshotPermissionReply = false It means snapshotee has declined snapshot permission.
      // snapshotPermissionReply = null It means snapshotee has refreshed the page.
      if((this.props.snapshotPermissionReply === false && this.props.admittedParticipants.length <= 2) || 
      this.props.admittedParticipants.length < 2){
        this.props.snapShotModelClose();
      }
    }
    if(this.props.isSnapshotRunning !== prevProps.isSnapshotRunning && 
      this.props.isSnapshotRunning && 
      this.props.snapshoteeId === this.props.uniqueId && this.currentStream){
      /*  create user side rtc peer after we get _SNAPSHOT_PERMISSION_REPLY */
      this.createRtcPeer(this.currentStream);
    }
    if(this.props.isSnapshotRunning !== prevProps.isSnapshotRunning && 
      this.props.isSnapshotRunning){
      /*  create agent side (remote) rtc peer after we get _SNAPSHOT_PERMISSION_REPLY */
      var mediaElement = document.getElementById("snapshotvideo");
      mediaElement.style.display = "block"
      setTimeout(() => {
        if (this.props.uniqueId !== this.props.snapshoteeId) {
          this.generateRemoteSnapShotVideo();
        }
      }, 200);
    }
    if (prevProps.admittedParticipants && !_.isEqual(this.props.admittedParticipants.length, prevProps.admittedParticipants.length) && !this.state.showParticipant) {
      this.setState({showParticipant: true});
    }
    if(this.props.isLandscape !== prevProps.isLandscape && 
        this.props.isMobileSafari && !isIphone13OrHigher() && !isIPad13 &&
        (prevProps.isLandscape !== null && this.props.isLandscape !== null) &&
        this.props.isSnapshotRunning
    ){
      const { sessionKey } = this.props;
      //In case of IPhone iOS12 (not for iOS13) on orientation change stream get paused, so we are desposing stream and starting again.
      const message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.SWITCH_SNAPSHOT_CAMERA,
          sessionKey: sessionKey,
          snapshoterId: this.props.snapshoter
        }
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      this.props.showSpinner();
      // this.displayCameraStream();
    }

    if(this.props.startSnapshot && 
      this.props.startSnapshot !== prevProps.startSnapshot && 
      this.props.uniqueId === this.props.snapshoteeId){
      this.startSnapshot();
    }
    // stop snapshot window on resetting startSnapshot flag especially when KMS is down in between and _RELEASE_ALL_REMOTE_MEDIA ws call is received
    if(this.props.startSnapshot != prevProps.startSnapshot 
      && !this.props.startSnapshot) {
      this.props.snapShotModelClose();
    }
  }

  // gets called at Agent end
  generateRemoteSnapShotVideo() {
    var mediaElement = document.getElementById("snapshotvideo");
    let self = this;
    
    var options = {
      remoteVideo: mediaElement,
      mediaConstraints: getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings),
      onicecandidate: this.onIceCandidate,
      configuration: {
        iceServers: getTurnServerOptionConfig(this.props.turnServer),
        iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
      }
    };
    if (wsocket.snapshotRtcPeer === null) {
      wsocket.snapshotRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
        options,
        function (error) {
          if (error) {
            
            return console.error(error);
          }
          this.generateOffer(self.offerToReceiveVideo);
          self.setState({
            showBitrate: true
          })
        }
      );
    } else {
      if (this.isIE) {
        attachMediaStream(
          mediaElement,
          wsocket.snapshotRtcPeer.getRemoteStream()
        );
      } else {
        mediaElement.srcObject = wsocket.snapshotRtcPeer.getRemoteStream();
        mediaElement.load()
      }
    }
  }

  stopMediaTracksOfGivenStream = (stream) => {
    stream && stream.getTracks().forEach(track => {
      track.stop();
    });
    this.currentStream = null;
    let snapshotvideoElement = document.getElementById("snapshotvideo");
    if(snapshotvideoElement){
      snapshotvideoElement.srcObject = null;
    }
  }

  createRtcPeer = stream => {
    let self = this
    var options = {
      videoStream: stream,
      mediaConstraints: getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings),
      onicecandidate: this.onIceCandidate,
      configuration: {
        iceServers: getTurnServerOptionConfig(this.props.turnServer),
        iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
      }
    };
    wsocket.snapshotRtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
      options,
      function (error) {
        if (error) {
          return console.error("Error in kurentoUtils.WebRtcPeer.WebRtcPeerSendonly:", error);
        }
        this.generateOffer(self.offerToReceiveVideo);
        self.setState({
          showBitrate: true
        })
      }
    );
  }

  // gets called user end
  generateLocalSnapShotVideo = stream => {
    this.currentStream = stream;
    var mediaElement = document.getElementById("snapshotvideo");
    let self = this;

    // If snapshot video element is not present, stop track
    // because it indicates that snapshot component has unmounted
    if(!mediaElement) {
      console.log('Stop track since media element is not present');
      this.stopMediaTracksOfGivenStream(stream);
      return;
    }

    if(this.isIE) {
      attachMediaStream(mediaElement, stream);
      setTimeout(() => {
        this.props.hideSpinner()
        this.setState({enableCamera: true})
      }, 2000);
    } else {
      mediaElement.srcObject = stream;
      mediaElement.play()
    }

    // let rtcPeerConnection = participants[name].rtcPeer.peerConnection;
    if(!this.props.isSnapshotRunning){
      /* if we didn't get _SNAPSHOT_PERMISSION_REPLY here then we'll create rtc peer later.
      after we get _SNAPSHOT_PERMISSION_REPLY we set `this.props.isSnapshotRunning` as true
      then in componentDidUpdate we call createRtcPeer() function with this.currentStream to create create RtcPeer      
      */
      return;
    }
    if (wsocket.snapshotRtcPeer === null) {
      this.createRtcPeer(stream)
    
    } else {
      if (this.isIE) {
        const { sessionKey } = this.props;
        //In case of IE replaceTrack method does not work , so we are handeling it by recreating peers.
        const message = {
          data: {
            category: socketMessage.category.WEBRTC,
            action: webRtcMessageId.SWITCH_SNAPSHOT_CAMERA,
            sessionKey: sessionKey,
            snapshoterId: this.props.snapshoter
          }
        };
        this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      } else {
        let videoTrack = stream.getVideoTracks()[0];
        // snapshotRtcPeer.peerConnection.getSenders() not supported in IE
        let sender = wsocket.snapshotRtcPeer.peerConnection.getSenders().find(function (s) {
          return s.track.kind == videoTrack.kind;
        });
        sender.replaceTrack(videoTrack)
        .catch((error)=>{
          console.log("Error while replaceTrack: ", error);
          //this done if replaceTrack fails when there is resolution difference between two devices
          const { sessionKey } = this.props;
          const message = {
            data: {
              category: socketMessage.category.WEBRTC,
              action: webRtcMessageId.SWITCH_SNAPSHOT_CAMERA,
              sessionKey: sessionKey,
              snapshoterId: this.props.snapshoter
            }
          };
          this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
          this.props.showSpinner();
        });
        mediaElement.play();
      }
    }
  };

  onIceCandidate = (candidate, wp) => {
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.ICE_CANDIDATE,
        sessionKey: this.props.sessionKey,
        iceData: {
          candidate: candidate,
          senderId: this.props.snapshoteeId,
          media: mediaType.SNAPSHOT
        },
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  offerToReceiveVideo = (error, offerSdp, wp) => {
    if (error) return console.error("sdp offer error");
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SDP_OFFER,
        sessionKey: this.props.sessionKey,
        sdp: offerSdp,
        senderId: this.props.snapshoteeId,
        media: mediaType.SNAPSHOT
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  sendTakeSnapshotMessage = snapshoteeId => {
    this.setState({ clearCanvasAnnotations: true })
    if(this.props.customerCoBrowseStarted) {
      toastr.error("SNAPSHOT_CANNOT_USE_WITH_CUSTOMER_CO_BROWSE")
      return;
    }
    const { sessionKey } = this.props;
    const message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SNAPSHOT_PERMISSION_REQUEST,
        sessionKey: sessionKey,
        snapshoteeId: snapshoteeId,
        token: this.props.token
      }
    };
    this.setState({
      streaming: false
    });
    setTimeout(() => {
      this.props.clearSnapshotCanvasAction()            
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      this.props.sessionActions.updateSnapshoteeId(snapshoteeId);
      this.props.sessionActions.updateSnapshotPermissionReply(null);
    }, 10);
  };

  handleRemove = (isCameraPermissionGranted=true, isCameraBusy=false) => {
    //isCameraPermissionGranted is use to send permission related flag to Agent
    if (this.props.snapshoteeId) {
      this.snapshotTakenMessage(false, isCameraPermissionGranted, isCameraBusy);
    }
    // clear snapshotee id and snapshotPermissionReply
    this.props.sessionActions.updateSnapshoteeId(null);
    this.props.sessionActions.updateSnapshotPermissionReply(null);
    this.props.snapShotModelClose();
  };

  snapshotParticipant = () => {
    let participantArray = this.props.admittedParticipants.filter(function (obj) {
      return obj.role !== USER_ROLES.AGENT;
    });
    return participantArray;
  };

  takepicture = () => {
    this.props.showSpinner();
    this.props.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE);
    this.snapshotTakenMessage(true);
    var video = document.getElementById("snapshotvideo");
    var canvas = document.createElement("canvas");
    var snapshotCanvas = document.getElementById("snapshotCanvas");
    var context = canvas.getContext("2d");
    // Ratio of the video's intrisic dimensions
    var videoRatio = video && video.videoWidth / video.videoHeight;
    // The width and height of the video element
    var width = video && video.offsetWidth, height = video.offsetHeight;
    // The ratio of the element's width to its height
    var elementRatio = width / height;
    // If the video element is short and wide
    if (elementRatio > videoRatio) width = height * videoRatio;
    // It must be tall and thin, or exactly equal to the original ratio
    else height = width / videoRatio;
    canvas.height = height;
    canvas.width = width;
    // canvas.height = video.clientHeight;
    // canvas.width = video.clientWidth;

    const addImageToCanvas = () => {
      canvas.toBlob(blob => {
        let fileObject = convertBlobToFile(blob, "image/jpeg", "canvas.png");
        this.props.uploadFile(fileObject).then(fileUrl => {
          if (fileUrl) {
            this.props.addCanvas(CANVAS_TYPES.IMAGE, () => {
              // true parameter is indication for addImage() that that will indicate call is for
              // adding image of snap shot so height and width will be static.
              this.props.addSnapshotImage(fileUrl, false, this.props.canvasId);
              this.clearSnapshotCanvas()
              // delete snapshot canvas after the snapshot image is added on canvas
              // so added canvas will be the active canvas
              // this will not set active canvas to 1 
              // this.deleteSnapshotCanvas();

              // this.props.hideSpinner();
            });
          } else {
            this.props.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE, false);
            this.props.hideSpinner();
          }
        })
        .catch((error)=>{
          this.props.hideSpinner();
        })
      });
    };

    if (
      typeof AdapterJS !== "undefined" &&
      AdapterJS.webrtcDetectedBrowser === "IE" &&
      AdapterJS.webrtcDetectedVersion >= 9
    ) {
      /* video element is replaced by plugin object
      http://support.temasys.com.sg/support/solutions/articles/12000053612-how-can-i-use-drawimage-to-draw-onto-a-canvas-with-the-plugin */
      const lastFrame = video.getFrame();
      const image = new Image();
      image.onload = () => {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        context.drawImage(snapshotCanvas, 0, 0, canvas.width, canvas.height);

        addImageToCanvas();
      };
      image.onerror = () => {
        this.props.sendDownloadToCanvasMessage(CANVAS_TYPES.IMAGE, false);
        this.props.hideSpinner();
      }
      image.setAttribute("src", "data:image/png;base64," + lastFrame);
    } else {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      context.drawImage(snapshotCanvas, 0, 0, canvas.width, canvas.height);

      addImageToCanvas();
    }
    this.props.hideSpinner();
  };

  snapshotTakenMessage = (flag, isCameraPermissionGranted=true, isCameraBusy=false) => {
    const { sessionKey } = this.props;
    const message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SNAPSHOT_TAKEN,
        sessionKey: sessionKey,
        snapshoteeId: this.props.snapshoteeId,
        isSnapshotTaken: flag,
        isCameraPermissionGranted:isCameraPermissionGranted, // send camera permission flag to agent
        isCameraBusy:isCameraBusy// if camera stream is used by other browsers
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  showCloseSnapshotConfirmationModal = (modalState) => {
    this.setState({
      showEndSnapshotConfirmationModal: modalState
    })
    // this.props.sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
  }
  
  showCloseButton = () => {
    if(this.props.organiser === this.props.uniqueId || this.props.snapshoteeId === this.props.uniqueId){
      return (
        <>
          <img
            onClick={() => {
              this.setCloseIcon(ic_close_selected);
              this.showCloseSnapshotConfirmationModal(true);
            }}
            src={ic_close}
            id="closeSnapshotBtn"
            alt="closeButton"
            className="snapShotBtn cursorPointer m-2 float-right img-fluid"
          />
          <CustomTooltip
            tooltipText={getMessage("STOP_SNAPSHOT")}
            tooltipId="closeSnapshotBtn"
          />
        </>
      );
    }
  }

  switchCamera = () => {
    this.handleSnapshotAnnotationsCleared(SNAPSHOT_ANNOTATIONS_CLEARED_REASON.CAMERA_SWITCH)
    this.props.showSpinner();
    this.setState({
      enableCamera: false,
      streaming:false
    })

    if(isMobileOriPad()) {
      this.setState({
        facingMode: this.state.facingMode == 'environment' ? 'user' : 'environment'
      }, () => {
        this.displayCameraStream();
      });
    } else {
      let currentIndex = this.state.deviceList.findIndex(device => device.deviceId == this.state.selectedDevice.deviceId);
      currentIndex = currentIndex == this.state.deviceList.length - 1 ? 0 : currentIndex + 1;
      this.setState({
        selectedDevice: this.state.deviceList[currentIndex]
      }, () => {
        if (this.isIE) {
          const { sessionKey } = this.props;
          //In case of IE replaceTrack method does not work , so we send SWITCH_SNAPSHOT_CAMERA ws call to server.
          // On receiving _SWITCH_SNAPSHOT_CAMERA, we dispose the rtcpeer
          const message = {
            data: {
              category: socketMessage.category.WEBRTC,
              action: webRtcMessageId.SWITCH_SNAPSHOT_CAMERA,
              sessionKey: sessionKey,
              snapshoterId: this.props.snapshoter
            }
          };
          this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
        } else {
          this.displayCameraStream();
        }
      });
    }
  }

  setClearCanvasIcon = (Icon) => {
    document.getElementById("clearCanvas").src = Icon ;
  }
 
  setTakeSnapshotIcon = (Icon) => {
    document.getElementById("takeSnapshot").src = Icon ; 
  }

  setSwitchCameraIcon = (Icon) => {
    document.getElementById("switchCamera").src = Icon;
  }

   setCloseIcon = (Icon) => {
     document.getElementById("closeSnapshotBtn").src =  Icon;
   }

   toggleSnapshotParticipantsBar = () => {
     this.setState({ showSnapshotParticipantsBar: !this.state.showSnapshotParticipantsBar})
   }

  onClickAnnotationIcon = (takePictureFlag) => {
    // If user is not agent and video streaming not started or object tracking or Scan code started then disable annotation icon 
    if (takePictureFlag || (this.props.uniqueId !== this.props.organiser && (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()))) {
      return
    }
    this.checkIfShowSwitchAnnotationModeWarningModalOpen(ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION);
    this.props.lastDrawnObject === null && this.props.setSnapshotDrawingModeValue(!this.props.isDrawingModeEnabled);
  }

  showAnnotationIcon = (takePictureFlag) => {
    const className = `${takePictureFlag || (this.props.uniqueId !== this.props.organiser && (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()))
      ? "isDisabled "
      : ""}snapShotBtn cursorPointer my-2 mx-1 img-fluid`
    const tooltipText = (this.props.uniqueId !== this.props.organiser && (this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode())) ? getMessage("SNAPSHOT_OBJECT_TRACKING_RESTRICTED_TO_AGENT") : getMessage("ANNOTATE");
    return (
      <>
        <img
          src={
            this.props.isDrawingModeEnabled
              ? ic_snapshot_ellipse_selected
              : ic_snapshot_ellipse
          }
          id="annotate"
          alt="draw"
          className={className}
          // to fix tooltip position issue on object tracking mode switch
          style={this.isStaticAnnotationMode() ? { display: "block" } : { display: 'none' }}
          onClick={() => this.onClickAnnotationIcon(takePictureFlag)}
        />
        <img
          src={
            this.props.isDrawingModeEnabled
              ? ic_snapshot_ellipse_selected
              : ic_snapshot_ellipse
          }
          id="annotate-disabled"
          alt="draw"
          className={className}
          // to fix tooltip position issue on object tracking mode switch
          style={!this.isStaticAnnotationMode() ? { display: "block" } : { display: 'none' }}
          onClick={() => this.onClickAnnotationIcon(takePictureFlag)}
        />
        {this.isStaticAnnotationMode() &&
          <CustomTooltip
            tooltipText={tooltipText}
            tooltipId="annotate"
          />
        }
        {(this.isStickyAnnotationMode() || this.isScanCodeAnnotationMode()) &&
          <CustomTooltip
            tooltipText={tooltipText}
            tooltipId="annotate-disabled"
          />
        }
      </>
    );
  }

  showStickyAnnotation = (takePictureFlag) => {
    return (
      <>
        <img
          src={
            this.isStickyAnnotationMode()
              ? ic_object_tracking_selected
              : ic_object_tracking
          }
          id="stickyAnnotation"
          alt="draw"
          className={`${takePictureFlag ? "isDisabled " : ""}snapShotBtn cursorPointer my-2 mx-1 img-fluid`}
          onClick={() => {
            const isOpenCVLoaded = sessionStorage.getItem("isOpenCVLoaded");
            // if video stream not started then disable on click event 
            if (takePictureFlag || isOpenCVLoaded === 'false') {
              if (isOpenCVLoaded === 'false') {
                toastr.error("OBJECT_TRACKING_IS_CURRENTLY_UNAVAILABLE")
              }
              return
            }
            this.checkIfShowSwitchAnnotationModeWarningModalOpen(ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STICKY_ANNOTATION);
          }}
        />
        <CustomTooltip
          tooltipText={this.isStickyAnnotationMode() ? getMessage("STOP_OBJECT_TRACKING") : getMessage("START_OBJECT_TRACKING")}
          tooltipId="stickyAnnotation"
        />
      </>
    );
  }

  checkIfShowSwitchAnnotationModeWarningModalOpen(clickedMode) {
    this.setState({
      annotationClickedMode: clickedMode
    })
    if (this.props.lastDrawnObject !== null) {
      if (this.props.snapshotAnnotationMode === clickedMode && this.isStaticAnnotationMode(clickedMode)) {
        this.props.setSnapshotDrawingModeValue(!this.props.isDrawingModeEnabled);
      } else {
        this.showSwitchAnnotationModeWarningModal(true)
      }
    } else {
      this.changeAnnotationMode(this.props.snapshotAnnotationMode === clickedMode ? ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION : clickedMode);
    }
  }

  showScanCodeAnnotation = (takePictureFlag) => {
    return (
      <>
        <img
          src={
            this.isScanCodeAnnotationMode()
              ? ic_scan_code_selected
              : ic_scan_code
          }
          id="scanCodeAnnotation"
          alt="draw"
          className={`${takePictureFlag ? "isDisabled " : ""}snapShotBtn cursorPointer my-2 mx-1 img-fluid`}
          onClick={() => {
            const isOpenCVLoaded = sessionStorage.getItem("isOpenCVLoaded");
            // if video stream not started then disable on click event 
            if (takePictureFlag || isOpenCVLoaded === 'false') {
              if (isOpenCVLoaded === 'false') {
                toastr.error("SCAN_CODE_IS_CURRENTLY_UNAVAILABLE")
              }
              return
            }
            this.checkIfShowSwitchAnnotationModeWarningModalOpen(ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.SCAN_CODE);
          }}
        />
        <CustomTooltip
          tooltipText={this.isScanCodeAnnotationMode() ? getMessage("STOP_SCAN_CODE") : getMessage("START_SCAN_CODE")}
          tooltipId="scanCodeAnnotation"
        />
      </>
    );
  }
  
  showParticipantsComponent = () => {
    return(
      <Participants
        userRole={this.props.userRole}
        participants={this.snapshotParticipant()}
        mobileView={true}
        isSnapshot={true}
        sendTakeSnapshotMessage={this.sendTakeSnapshotMessage}
        snapshoteeId={this.props.snapshoteeId}
        uniqueId={this.props.uniqueId}
        organiser={this.props.organiser}
        showParticipant={this.state.showParticipant || this.props.admittedParticipants.length > 2}
        isSnapshotRunning={this.props.isSnapshotRunning}
        snapshotPermissionReply={this.props.snapshotPermissionReply}
        toggleSnapshotParticipantsBar={this.toggleSnapshotParticipantsBar}
      />
    );
  }

  shouldShowParticipantSelection = () => {
    if (this.props.userRole === USER_ROLES.USER) {
      return false
    } else {
      return (this.props.admittedParticipants &&
        (this.state.showParticipant || this.props.admittedParticipants.length > 2))
    }
  }
   
  showSwitchAnnotationModeWarningModal = (showSwitchAnnotationModeWarning) => {
    this.setState({ showSwitchAnnotationModeWarning })
  }

  showStopStickyModeWarningModal = (showStopStickyModeWarning) => {
    this.setState({ showStopStickyModeWarning: showStopStickyModeWarning })
  }

  setStopStickyTracking = (clearCanvasAnnotations) => {
    this.setState({ clearCanvasAnnotations: clearCanvasAnnotations })
  }

  switchAnnotationModeConfirmationModal = () => {
    if (this.state.showSwitchAnnotationModeWarning) {
      return (
        <ConfirmationModal
          show={true}
          isDoubleButton={true}
          handleNoClick={() => {
            this.showSwitchAnnotationModeWarningModal(false)
          }}
          handleYesClick={() => {
            this.changeAnnotationMode(
              this.props.snapshotAnnotationMode === this.state.annotationClickedMode ? ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION : this.state.annotationClickedMode); 
            this.props.setSnapshotDrawingModeValue(
              this.isStaticAnnotationMode(this.state.annotationClickedMode)
            );
            this.clearSnapshotCanvas();
            this.showSwitchAnnotationModeWarningModal(false)
            this.props.showSpinner();
          }}
          message={
            this.state.annotationClickedMode === this.props.snapshotAnnotationMode ?
              getMessage("SNAPSHOT_SWITCH_TO_SIMPLE_ANNOTATIONS_WARNING") :
              this.isStickyAnnotationMode(this.state.annotationClickedMode)  ?
                getMessage("SNAPSHOT_SWITCH_TO_OBJECT_TRACKING_WARNING") :
                this.isScanCodeAnnotationMode(this.state.annotationClickedMode) ?
                  getMessage("SNAPSHOT_SWITCH_TO_SCAN_CODE_ANNOTATIONS_WARNING") :
                  getMessage("SNAPSHOT_SWITCH_TO_SIMPLE_ANNOTATIONS_WARNING")}
        />
      );
    }
    return null;
  }

  stopStickyAnnotationModeConfirmationModal = () => {
    if (this.state.showStopStickyModeWarning) {
      return (
        <ConfirmationModal
          show={true}
          isDoubleButton={true}
          handleNoClick={() => {
            this.showStopStickyModeWarningModal(false)
          }}
          handleYesClick={() => {
            this.changeAnnotationMode(ANNOTATIONS.SNAPSHOT_ANNOTATION_MODE.STATIC_ANNOTATION)
            this.clearSnapshotCanvas();
            this.showStopStickyModeWarningModal(false)
          }}
          message={getMessage("SNAPSHOT_STOP_OBJECT_TRACKING_WARNING")}
        />
      );
    }
    return null;
  }
   
   renderEndSnapshotConfirmationModal = () => {
     if (this.state.showEndSnapshotConfirmationModal) {
       return (
         <ConfirmationModal
           show={true}
           isDoubleButton={true}
           handleNoClick={() => {
             this.setCloseIcon(ic_close);
             this.showCloseSnapshotConfirmationModal(false)
           }}
           handleYesClick={() => {
             this.handleRemove();
           }}
           message={getMessage("END_SNAPSHOT_CONFIRMATION_TEXT")}
         /> 
       );
     }
     return null;
   }

   renderBitRateTile = () => {
     if (
       window._env_.REACT_APP_SHOW_BITRATE == "true" &&
       !this.isIE &&
       this.state.showBitrate
     ) {
       return (
         <BitRateTile 
           module={Modules.SNAPSHOT}
           mediaType = {mediaType.VIDEO}
           participantUniqueID = {this.props.snapshoteeId}
           uniqueId = {this.props.uniqueId}
           wsocketPeer = {wsocket.snapshotRtcPeer}
           hide={true}
         /> 
       );
     }
     return null;
   }

   renderSnapshotVideo = () => {
     return (
       <video
         id="snapshotvideo"
         style={{ display:this.props.snapshotPermissionReply === true ? "block" : "none"}}
         className={(this.props.uniqueId === this.props.snapshoteeId && 
          (this.state.facingMode === null || this.state.facingMode === 'user')) ? "flipVideo snapshotVideoDimensions" : "snapshotVideoDimensions"}
         autoPlay
         muted
         playsInline
         // to maintain the aspect ratio of the video and canvas element,
         // added the same width and height as canvas
         width={this.state.videoWidth / this.state.videoHeight > 1  ? ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH : ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT}
         height={this.state.videoWidth / this.state.videoHeight > 1 ? ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_HEIGHT : ANNOTATIONS.SNAPSHOT_CANVAS_DIMENSIONS.CANVAS_WIDTH}
       />
     )
   }

   renderSnaphotParticipantBar = () => {
     //TODO: Use find
     let selectedParticipant = this.snapshotParticipant().filter((participant) => { return this.props.snapshoteeId == participant.uniqueId});

     //If any snapshotee has selected by snapshoter,button with snapshotee initials should display
     //else select snapshotee Icon should display
     return  <div className="d-flex pl-1">
       {selectedParticipant.length !== 0 && this.state.showSnapshotParticipantsBar == false 
         ?<>
          <button
            className={"participantInitials selected not-allowed noPadding snapShotBtn m-0 mx-2"}
            onClick={() => this.toggleSnapshotParticipantsBar()}>
            <span>{getUserNameAcronym(selectedParticipant[0].userName)}</span>
          </button>
          <div class="Seperator mx-2" />
         </>
         : <>
         <img
           className="snapShotBtn cursorPointer m-2 img-fluid"
           src={this.state.showSnapshotParticipantsBar
             ? ic_select_member_selected
             : ic_select_member}
           id="selectParticipant"
           onClick={() => this.toggleSnapshotParticipantsBar()}
         />
         <div class="Seperator m-2" />
         </>}
     </div>
   }

   renderSnapshotCanvas = () => {
     if (
       this.state.videoHeight && this.state.videoWidth && this.props.canvasId
     ) {
       return (
         <Canvas 
           canvasName="snapshotCanvas"
           canvasId={this.props.canvasId}
           height={this.state.videoHeight}
           width={this.state.videoWidth}
           canvasSource={CANVAS_SOURCE.SNAPSHOT}
           sessionKey={this.props.sessionKey}
           participantId={this.props.participantId}
           sendWebsocketMessage={this.props.sendWebsocketMessage}
           canvasData={this.props.canvasData}
           lastDrawnObject={this.props.lastDrawnObject}
           ownerOfLastAction={this.props.ownerOfLastAction}
           rotateY180={(this.props.uniqueId === this.props.snapshoteeId &&
            ((this.state.facingMode === null || this.state.facingMode === 'user') && !this.isIE))
             ? true
             : false}
           showSpinner={this.props.showSpinner}
           hideSpinner={this.props.hideSpinner}
           inkColor={this.props.inkColor}
           isDrawingModeEnabled={this.props.isDrawingModeEnabled}
           setStopStickyTracking={this.setStopStickyTracking}
           uniqueId={this.props.uniqueId}
           snapshoteeId={this.props.snapshoteeId}
           snapshotAnnotationMode={this.props.snapshotAnnotationMode}
           clearCanvasAnnotations={this.state.clearCanvasAnnotations}
           streaming={this.state.streaming}
           organiser={this.props.organiser}
           videoBandwidthSettings={this.props.videoBandwidthSettings}
           addStickyObject={this.props.addStickyObject}
           userName= {this.props.userName}
           qrcodeRegionId={this.qrcodeRegionId}
           clearSnapshotCanvas={this.clearSnapshotCanvas}
           handleRemove={this.handleRemove}
           setSnapshotSearchResult={this.props.setSnapshotSearchResult}
         />
       );
     }
     return null;
   }

   renderOrganizerFooter = (takePictureFlag) => {
     return (
       <div className={`d-flex${!isMobileOnly ? " justify-content-center" : ""}`}>          
         {this.props.organiser === this.props.uniqueId && this.props.isSnapshotScanCodeEnabled &&
           <div className="m-1">
             {this.showScanCodeAnnotation(takePictureFlag)}
           </div>
         } 
         {this.props.organiser === this.props.uniqueId &&
           <div className="m-1">
             {this.showStickyAnnotation(takePictureFlag)}
           </div>
         }     
         <div className="m-1">
           {this.showAnnotationIcon(takePictureFlag)}
         </div>
         <div className={takePictureFlag || !this.props.canvasData || this.props.canvasData == {} || this.isScanCodeAnnotationMode()
           ? "isDisabled m-1" : "cursorPointer m-1"} id="eraseCanvas" >
           <img
             className="snapShotBtn my-2 mx-1 img-fluid"
             src={ic_clear_canvas}
             id="clearCanvas"
             onClick={() => {
               // if video stream not started and no object drawn on canvas then disable on click event 
               if(takePictureFlag || !this.props.canvasData || this.props.canvasData == {} || this.isScanCodeAnnotationMode()){
                 return;
               }
               this.setClearCanvasIcon(ic_clear_canvas_selected);
               this.clearSnapshotCanvas();
             }}
             onMouseOut={() => this.setClearCanvasIcon(ic_clear_canvas)}
           />
           <CustomTooltip tooltipText={getMessage("CLEAR_SNAPSHOT")} tooltipId="eraseCanvas" />
         </div>
         <div className="m-1">
           <img
             className={`${takePictureFlag || this.isScanCodeAnnotationMode() ? 'isDisabled ' : ''}snapShotBtn cursorPointer mx-1 my-2 img-fluid`}
             src={ic_take_snapshot}
             id="takeSnapshot"
             onClick={() => {
               // if video stream not started
               if (takePictureFlag || this.isScanCodeAnnotationMode()) {
                 return;
               }
               this.setTakeSnapshotIcon(ic_take_snapshot_selected)
               this.setState({ clearCanvasAnnotations: true })
               setTimeout(() => {
                 this.takepicture()
               }, 10);
             }}
             onMouseOut={() => this.setTakeSnapshotIcon(ic_take_snapshot)}
             alt="takeSnapshot"
           />
           <CustomTooltip tooltipText={getMessage("TAKE_SNAPSHOT")} tooltipId="takeSnapshot" />
         </div>
       </div>
     );
   }

   renderNonOrganizerFooter = (takePictureFlag) => {
     return (
       <div className={`p-1 d-flex${!isMobileOnly ? " justify-content-center" : ""}`}>
         <div className="m-1">
           {this.showAnnotationIcon(takePictureFlag)}
         </div>
         {this.props.snapshoteeId === this.props.uniqueId &&
         <div className={!(this.state.enableCamera && (this.state.deviceList.length > 1 || (isMobileOriPad()))) ? "isDisabled mt-2" : "cursorPointer mt-2"}>
           <img
             className="switchCamera m-1 img-fluid"
             id="switchCamera"
             onClick={() => {
               // if video stream not started and front cam not available then disbale on click event
               if(!(this.state.enableCamera && (this.state.deviceList.length > 1 || (isMobileOriPad())))){
                 return;
               }
               this.switchCamera();
             }}
             onTouchStart={()=>{
               this.setSwitchCameraIcon(ic_camera_flip_selected);
             }}
             onTouchEnd={()=>{
               this.setSwitchCameraIcon(ic_camera_flip);
             }}
             onMouseDown={()=>{
               this.setSwitchCameraIcon(ic_camera_flip_selected);
             }}
             onMouseUp={()=>{
               this.setSwitchCameraIcon(ic_camera_flip);
             }}
             src={ic_camera_flip}
             alt="switchCameraIcon"
           />
           <CustomTooltip tooltipText={getMessage("SWITCH_CAMERA")} tooltipId="switchCamera" />
         </div>}
       </div>
     );
   }

   renderSnaphotFooter = () => {     
     const takePictureFlag = !this.state.streaming;

     return (
       <div className="snapshotFooter d-flex justify-content-between m-0 row">
         {<div className={isMobileOnly
           ? "float-left selectParticipant"
           : "col-4 px-0" }>
           { this.shouldShowParticipantSelection() && ((isMobileOnly)
             ? this.renderSnaphotParticipantBar()
             : this.showParticipantsComponent())
           }
           {this.state.showSnapshotParticipantsBar &&
           <>
             {this.shouldShowParticipantSelection() && isMobileOnly &&
                <div className="floatedSnapshotTools d-flex">
                  {this.showParticipantsComponent()}
                </div>}
             </>}
         
         </div>}
         <div className={isMobileOnly ? "snapshotTools" : "col-4"}>
           {this.props.organiser === this.props.uniqueId
             ? this.renderOrganizerFooter(takePictureFlag)
             : this.renderNonOrganizerFooter(takePictureFlag)
           }
         </div>
         <div className="col-4 pr-1">
           {this.showCloseButton()}
         </div>
       </div>
     );
   }

   render() {
     this.props.modules
      && this.props.modules.map(
        (module, index) => {
          if (this.props.modules[index] === "CANVAS") {
            this.setState({
              isCanvas: true
            })
          }
        }
      )
     return (
       <div
         id="snapshot"
         className={
           this.props.organiser ===
            this.props.uniqueId /*this.props.userRole === USER_ROLES.AGENT*/
             ? "positioningSnapshot positioningSnapshotAgent"
             : "positioningSnapshot positioningSnapshotUser"
         }
       >
         {this.renderEndSnapshotConfirmationModal()}
         {this.switchAnnotationModeConfirmationModal()}
         {this.stopStickyAnnotationModeConfirmationModal()}
         <div className="snapshotBody">
           <div className="camera">   
             {this.renderBitRateTile()}
             {this.renderSnapshotVideo()}
             <div style={{ display: this.props.snapshotPermissionReply !== true ? "block" : "none"}}
               className="text">
               {this.props.admittedParticipants && !this.props.snapshoteeId && (this.state.showParticipant || this.props.admittedParticipants.length > 2) ? 
                 getMessage("SELECT_PARTICIPANT_TO_TAKE_SNAPSHOT") : null}
             </div>
             {this.renderSnapshotCanvas()}
           </div>
           {this.renderSnaphotFooter()}
         </div>
         {this.props.isSnapshotScanCodeEnabled && this.props.organiser === this.props.uniqueId &&
           <div style={{ width: '100%', display: 'none' }}>
             <canvas id="canvasOutput" width='100%' height='100%' >
               {getMessage("CANVAS_NOT_SUPPORTED")}
             </canvas>
             <div id={this.qrcodeRegionId} />
           </div>
         }
       </div >
     );
   }
}
const mapStateToProps = state => {
  return {
    isPeerDisposed: state.SessionReducer.isPeerDisposed,
    participantId: state.SessionReducer.participantId,
    canvasId: state.SnapshotReducer.snapshotCanvas && state.SnapshotReducer.snapshotCanvas.canvasId,
    canvasData: state.SnapshotReducer.snapshotCanvasData,
    lastDrawnObject: state.SnapshotReducer.lastDrawnObject,
    ownerOfLastAction: state.SnapshotReducer.ownerOfLastAction,
    whiteboardActiveCanvas: state.WhiteboardReducer.activeCanvas,
    inkColor: selectDefaultFontInfo(state).fontColor,
    isDrawingModeEnabled: state.SnapshotReducer.isDrawingModeEnabled,
    snapshotAnnotationMode: state.SnapshotReducer.snapshotAnnotationMode,
    snapshotAnnotationsClearedReason: state.SnapshotReducer.snapshotAnnotationsClearedReason,
    userName: getUserName(state)
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setSnapshotDrawingModeValue: setSnapshotDrawingModeValue,
    setSnapshotAnnotationMode: setSnapshotAnnotationMode,
    addStickyObject: addStickyObject
  },dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snapshot);
