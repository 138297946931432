import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class AdminAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  selectReportAction(selectedReport) {
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SELECT_REPORT,
      value: selectedReport
    });
  };
  
  saveCurrentActiveTab(activeTab) {
    this.dispatch({ 
      type: ACTIONS.ADMIN_ACTION.SET_ACTIVE_TAB,
      value: activeTab
    });
  }

  getAllSessionFollowUps(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.GET_ALL_FOLLOW_UPS,
        requestBody: data
      }).then(
        response => {
          this.dispatch({
            type: ACTIONS.ADMIN_ACTION.SET_SESSION_FOLLOW_UPS_COUNT,
            sessionFollowUpsCount: response.data.data.total,
          });
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  saveSessionFollowUpsCurrentPageNo(sessionFollowUpsCurrentPageNo){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_SESSION_FOLLOW_UPS_CURRENT_PAGE_NO,
      value: sessionFollowUpsCurrentPageNo
    })
  }

  saveSessionFollowUpsPageSize(sessionFollowUpsPageSize){
    this.dispatch({
      type: ACTIONS.ADMIN_ACTION.SAVE_SESSION_FOLLOW_UPS_PAGE_SIZE, 
      value: sessionFollowUpsPageSize
    });
  }
}