import React from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Collapse,
  Card,
  Input,
  Label,
  Table,
  DropdownItem
} from "reactstrap";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import CobrowseSettingsSection from "./CobrowseSettingsSection";
import ChatSettings from "./ChatSettings";

// Images
import ic_edit from "ASSETS/Images/ic_edit.svg";
import ic_add from "ASSETS/Images/ic_add_storage.svg";
import ic_error from "ASSETS/Images/ic_error.svg";
import ic_arrow_down from "ASSETS/Images/ic_arrow_down.svg";

// Constants
import {
  listOfAvailableSubModule,
  subLoggingLevels, loggingLevel, 
  defaultSubLoggingLevels,
  STORAGE_LOCATION, CONFIG,
  BANDWIDTH_MANAGEMENT_LIST, Modules,
  FRAMES_PER_SECOND_LIST, DEFAULT_FPS,
  BUSINESS_MODELS, cobrowseTypes, LOCATOR, EMAILS_LENGTH, modulesAvailableConstant
} from "UTILS/Constants";

import { isEmailValid } from "UTILS/Utility";

// Strings
import { getMessage } from "CONFIG/i18n";
import { separatePhoneNumberAndEmails, checkEmpty, hasWhiteSpace } from "UTILS/Utility";
import validator from "validator";
import "./SettingsPage.less"
import "../../SuperAdmin/Organisations/Organisations.less";
import NumericInput from 'react-numeric-input';

import { validateCobrowseCustomURL } from "UTILS/regexValidations";
class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.subModules={}
    const userSubModule = Object.keys(_.cloneDeep(this.props.allowedDownloads) || {});
    listOfAvailableSubModule.forEach(subModule => this.subModules[subModule] = userSubModule.includes(subModule) ? true : false)
    this.state = {
      allowedDownloads: this.subModules,
      autoRecordSession: false,
      allowAgentToRecordSession: false,
      allowAwsRadioBtn: false,
      allowFtpRadioBtn: false,
      contextExchangeCheckBox: false,
      sessionDetailsCheckBox: true,
      inviteeListCheckBox: true,
      storageErrorMessage: "",
      subLoggingLevels: this.props.availableSubLoggingLevels ? _.cloneDeep(this.props.availableSubLoggingLevels) : [...defaultSubLoggingLevels],
      storageDetails: this.props.storageDetails ? _.cloneDeep(this.props.storageDetails) : {},
      SSODetails: this.props.SSODetails ? _.cloneDeep(this.props.SSODetails) : {},
      bandwidthSettings: this.props.bandwidthSettings ? _.cloneDeep(this.props.bandwidthSettings) : {}, 
      spinnerVisibility: false,
      clientId: "",
      cognitoDomain: "",
      identityProvider: "",
      clientSecret :"",
      userPoolId:"",
      bucketName: "",
      id: "",
      secret: "",
      videoStreamBandwidth: "0",
      screenShareBandwidth: "0",
      videoStreamFPS: DEFAULT_FPS,
      screenShareFPS: DEFAULT_FPS,
      emailIds:(this.props.concurrentSessionData.concurrentSessionNotifyEmails && this.props.concurrentSessionData.concurrentSessionNotifyEmails.length > 0) ? this.props.concurrentSessionData.concurrentSessionNotifyEmails.join() : "",
      emailErrorMessage:'',
      concurrentSessionThreshold:this.props.concurrentSessionData.concurrentSessionThreshold,
      showAddTargetSiteSection: false,
      targetSiteLabel: "",
      targetSiteValue: "",
      targetSites: _.cloneDeep(this.props.cobrowseSettings.targetSites),
      cobrowseSettings:this.props.cobrowseSettings ? _.cloneDeep(this.props.cobrowseSettings) : {},
      allowCustomUrl: _.cloneDeep(this.props.cobrowseSettings.allowCustomUrl),
      customUrlLabels: _.cloneDeep(this.props.cobrowseSettings.customUrlLabels),
      showUpdateTargetSiteSection: false,
      targetSiteLabelToEdit:"",
      cobrowseURLError:"",
      customUrlLabelError:"",
      allowAnonymousParticipants: this.props.allowAnonymousParticipants,
      showLogoOnCanvasStatus: this.props.showLogoOnCanvasStatus,
      showChatPopup: this.props.showChatPopup,
      qrCodeWaitingTime: this.props.qrCodeSettings?.qrCodeWaitingTime,
      qrCodeWaitingTimeError: "",
      qrCodeNotificationSettings: {
        ...this.props.qrCodeSettings?.qrCodeNotificationSettings
      },
      showDeviceInfo: this.props.appEmulationSettings.showDeviceInfo,
      chatSettings: {
        organizationName: '',
        APIKey: '',
        context: null,
        isOrganizationNameValid: true,
        isAPIKeyValid: true
      }
    };
    this.allEmails = [];
    this.allEmailsToCompare = [];
    this.tooltipBoundaryElement = null;
    this.framesPerSecondsOptions = FRAMES_PER_SECOND_LIST.map(element => ({
      name: element,
      value: element,
    }));
    this.bandwidthManagementOptions = BANDWIDTH_MANAGEMENT_LIST.map(element => ({
      name: getMessage(element.name, {bandwidth: element.value, bitrate: element.bitrate}),
      value: element.value,
    }));
  }

  componentDidMount() {
    this.selectDefaultStorage(-1);
    this.initSSODetails();
    this.initBandWidthSettings();
    this.getTooltipBoundaryElement();
  }

  componentDidUpdate(prevProps) {
    if (this.props.allowedDownloads !== prevProps.allowedDownloads) {
      const userSubModule = Object.keys(_.cloneDeep(this.props.allowedDownloads) || {});
      listOfAvailableSubModule.forEach(subModule => this.subModules[subModule] = userSubModule.includes(subModule) ? true : false)
      this.setState({
        allowedDownloads: this.subModules
      })
    }

    if (this.props.showChatPopup !== prevProps.showChatPopup) {
      this.setState({
        showChatPopup:this.props.showChatPopup
      })
    }
    if (
      this.props.appEmulationSettings !==
      prevProps.appEmulationSettings
    ) {
      this.setState({
        showDeviceInfo: this.props.appEmulationSettings.showDeviceInfo,
      });
    }
    if (this.props.cobrowseSettings !== prevProps.cobrowseSettings) {
      // Update CobrowseSettings in state when props are updated
      this.setState({
        cobrowseSettings: _.cloneDeep(this.props.cobrowseSettings),
        targetSites: _.cloneDeep(this.props.cobrowseSettings.targetSites),
        allowCustomUrl: _.cloneDeep(this.props.cobrowseSettings.allowCustomUrl),
        customUrlLabels: _.cloneDeep(this.props.cobrowseSettings.customUrlLabels),
      });
    }
    if (this.props.concurrentSessionData.concurrentSessionThreshold !== prevProps.concurrentSessionData.concurrentSessionThreshold) {
      // Update concurrent session threshold in state when props are updated
      this.setState({
        concurrentSessionThreshold: this.props.concurrentSessionData.concurrentSessionThreshold,        
      });
    }

    if(this.props.bandwidthSettings !== prevProps.bandwidthSettings) {
      this.setState({
        bandwidthSettings: this.props.bandwidthSettings ? _.cloneDeep(this.props.bandwidthSettings) : {}
      }, () => {
        this.initBandWidthSettings();
      });    
    }
    if (this.props.qrCodeSettings !== prevProps.qrCodeSettings) {
      this.setState({
        qrCodeWaitingTime: this.props.qrCodeSettings?.qrCodeWaitingTime,
        qrCodeNotificationSettings: {
          ...this.props.qrCodeSettings?.qrCodeNotificationSettings
        }
      })
    }
    if (this.props.SSODetails !== prevProps.SSODetails) {
      this.setState({
        SSODetails: _.cloneDeep(this.props.SSODetails)
      })
    }1
    if(this.props.chatSettings !== prevProps.chatSettings) {

      let chatSettings = {...this.state.chatSettings};
      chatSettings.organizationName = this.props.chatSettings.chatgptOrg;
      chatSettings.APIKey = this.props.chatSettings.chatgptKey;
      chatSettings.context = this.props.chatSettings.chatgptContext?.[0]; //TODO: We will have to change this implementation once UI is changed.
      chatSettings.isOrganizationNameValid = true;
      chatSettings.isAPIKeyValid = true;

      this.setState({
        chatSettings: chatSettings
      });
    }
  }
  
  resetStateToPrevState = () => {
    this.resetStorageDetailsFields();
    this.initSSODetails();
    this.initBandWidthSettings();
    this.resetError();
    if(this.ssoLocator) {  this.onDownArrowClicked(LOCATOR.SSO) }
    if(this.storageLocator) {  this.onDownArrowClicked(LOCATOR.STORAGE) }
    if(this.state.isCobrowseToggler) { this.onDownArrowClicked(LOCATOR.CO_BROWSE) }
    if(this.state.isChatSettingsToggler) { this.onDownArrowClicked(LOCATOR.CHAT_SETTINGS) }
    this.setState({
      allowedDownloads: _.cloneDeep(this.props.allowedDownloads) || {},
      subLoggingLevels: this.props.availableSubLoggingLevels ? _.cloneDeep(this.props.availableSubLoggingLevels) : [...defaultSubLoggingLevels],
      SSODetails: this.props.SSODetails ? _.cloneDeep(this.props.SSODetails) : {},
      storageDetails: this.props.storageDetails ? _.cloneDeep(this.props.storageDetails) : {},
    });
  };

  initBandWidthSettings = () => {
    if(this.state.bandwidthSettings && this.state.bandwidthSettings.length > 0 ) {
      this.state.bandwidthSettings.forEach(bandwidthElement => {
        if(bandwidthElement.module === Modules.AUDIO_VIDEO) {
          this.setState({
            videoStreamBandwidth: bandwidthElement.bandwidth ? bandwidthElement.bandwidth : "0", 
            videoStreamFPS: bandwidthElement.fps ? bandwidthElement.fps : DEFAULT_FPS 
          });
        } else if(bandwidthElement.module === Modules.SCREENSHARE) {
          this.setState({
            screenShareBandwidth: bandwidthElement.bandwidth ? bandwidthElement.bandwidth : "0",
            screenShareFPS: bandwidthElement.fps ? bandwidthElement.fps : DEFAULT_FPS 
          });
        }
      })
    }
  }

  initSSODetails = () => {
    if (this.state.SSODetails.isSSOEnabled) {
      this.setState({
        clientId: this.state.SSODetails.clientId ? this.state.SSODetails.clientId : "",
        cognitoDomain: this.state.SSODetails.cognitoDomain ? this.state.SSODetails.cognitoDomain : "",
        identityProvider: this.state.SSODetails.identityProvider ? this.state.SSODetails.identityProvider : "",
        clientSecret :this.state.SSODetails.clientSecret ? this.state.SSODetails.clientSecret : "" ,        
        userPoolId :this.state.SSODetails.userPoolId ? this.state.SSODetails.userPoolId : ""
      });
    }
  }

  /**
   * Gets Boundary element for info icon tooltip.
   * Tooltip will be rendered inside tooltipBoundaryElement
   */
  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("settingsBody")[0];
  }

  //collapsible state maintainer / Accordion
  onDownArrowClicked = (locator) => {
    this.setState(
      {
        isArrowDownClicked: !this.state.isArrowDownClicked,
      },
      () => {
        if (locator === LOCATOR.SSO) {
          this.ssoLocator = !this.ssoLocator;
          setTimeout(() =>
            this.setState({
              isSSOToggler: !this.state.isSSOToggler,
              ssoRotation: this.ssoLocator ? 180 : 0,
            }))
        } else if(locator === LOCATOR.CO_BROWSE) {
          setTimeout(() =>
            this.setState((prevState) => ({
              isCobrowseToggler: !prevState.isCobrowseToggler,
              cobrowseRotation: !prevState.isCobrowseToggler ? 180 : 0,
            })))
        } else if(locator === LOCATOR.CHAT_SETTINGS) {
          setTimeout(() =>
            this.setState((prevState) => ({
              isChatSettingsToggler: !this.state.isChatSettingsToggler,
              chatSettingsRotation: !prevState.isChatSettingsToggler ? 180 : 0,
            })))
        } else {
          this.storageLocator = !this.storageLocator;
          setTimeout(() =>
            this.setState({
              isStorageToggler: !this.state.isStorageToggler,
              storageRotation: this.storageLocator ? 180 : 0,
            }))
        }
      }
    );
  };

  resetStorageDetailsFields = () => {
    this.setState({
      isVerified: false,
      isAddStorage: false,
      isEditStorage: false,
      bucketName: "",
      id: "",
      secret: "",
      storageChanged: false,
      currentBucket: ""
    });
  }

  isValidDetails = () => {
    let valid = true;    
    if ((!this.state.cognitoDomain && !this.state.clientId && !this.state.identityProvider && !this.state.clientSecret && !this.state.userPoolId) || 
    (this.state.cognitoDomain && this.state.clientId && this.state.identityProvider && this.state.clientSecret && this.state.userPoolId)) {
      valid = true;
    } else {
      this.setState({ ssoErrorMessage: getMessage("EMPTY_MESSAGE") });
      valid = false;
    }

    if (this.state.storageErrorMessage && !this.state.isStorageToggler) {
      this.onDownArrowClicked(LOCATOR.STORAGE)
      valid = false;
    }

    if (!valid) {
      if (!this.state.ssoRotation) {
        this.onDownArrowClicked(LOCATOR.SSO)
      }
    }
    
    if (this.state.customUrlLabels && this.state.customUrlLabels[0] &&
      checkEmpty(this.state.customUrlLabels[0].label.trim())) {
      this.setState({
        customUrlLabelError: getMessage("ERR_EMPTY_CUSTOM_URL_LABEL")
      })
      if (!this.state.cobrowseRotation) {
        this.onDownArrowClicked(LOCATOR.CO_BROWSE)
      }
      valid = false
    }

    // Chat Settings
    if(this.props.isChatGPTEnabled) {
      let chatSettings = {...this.state.chatSettings};

      if(!chatSettings.organizationName && !chatSettings.APIKey) {
        chatSettings.isOrganizationNameValid = true;
        chatSettings.isAPIKeyValid = true;
      } else if (chatSettings.organizationName && !chatSettings.APIKey) {
        chatSettings.isOrganizationNameValid = true;
        chatSettings.isAPIKeyValid = false;
        
        valid = false;
      } else if(!chatSettings.organizationName && chatSettings.APIKey) {
        chatSettings.isOrganizationNameValid = false;
        chatSettings.isAPIKeyValid = true;

        valid = false;
      }

      if (!this.state.chatSettingsRotation) {
        this.onDownArrowClicked(LOCATOR.CHAT_SETTINGS)
      }

      this.setState({
        chatSettings: chatSettings
      });
    } 

    return valid;
  }

  isValidQRCodeWaitingTime = () => {
    let valid = true;
    if (this.props.availableModules?.includes(modulesAvailableConstant.QR_CODE)) {
      let qrCodeWaitingTime = this.state.qrCodeWaitingTime;
      if (qrCodeWaitingTime < window._env_.REACT_APP_QR_CODE_WAITING_TIME_MIN_IN_SEC ||
        qrCodeWaitingTime > window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC) {
        valid = false
        this.setState({ qrCodeWaitingTimeError: getMessage("QR_CODE_WAITING_TIME_ERROR", { min: window._env_.REACT_APP_QR_CODE_WAITING_TIME_MIN_IN_SEC, max: window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC }) });
      } else {
        this.setState({ qrCodeWaitingTimeError: "" });
      }
    }
    return valid;
  }

  concurrentSessionDetailsValidation = () => {
    //if threshold greater than 0 then emails should not empty
    if (this.state.concurrentSessionThreshold > 0 && checkEmpty(this.state.emailIds.trim())) {
      this.setState({ emailErrorMessage: getMessage("EMAIL_REQUIRED") });
      this.scrollToTop();
      return false;
    } else if (this.state.emailIds && this.state.emailIds.trim().length > 0  && this.state.concurrentSessionThreshold > 0) {
      let separators = [",", ";"];
      let arrayOfEmailIds = this.state.emailIds.split(new RegExp(separators.join("|"), "g"));
      let { arrayOfEmails, arrayOfPhone } = separatePhoneNumberAndEmails(arrayOfEmailIds);
      // check if email field has any number value
      if (arrayOfPhone.length > 0 && arrayOfPhone[0].phone !== "") {
        this.setState({ emailErrorMessage: getMessage("INVALID_EMAIL"), });
        this.scrollToTop();
        return false;
      }
      //More tha 10 emails not allowed
      if (arrayOfEmails.length > EMAILS_LENGTH) {
        this.setState({ emailErrorMessage: getMessage("EMAIL_LIMIT_VALIDATION"), });
        this.scrollToTop();
        return false;
      }
      // check if all emails within arrayIfEmails are valid or not
      for (let i = 0; i < arrayOfEmails.length; i++) {
        let email = arrayOfEmails[i].email;
        let isValidEmail = isEmailValid(email);
        if (!isValidEmail) {
          this.setState({ emailErrorMessage: getMessage("INVALID_EMAIL"), });
          this.scrollToTop();
          return false;
        }
        this.allEmails.push({ email });
        // TODO: received response and required request format are different for emails, request: concurrentSessionNotifyEmails: [] , response : concurrentSessionNotifyEmails: [{email: ""}]
        this.allEmailsToCompare.push(email);
      }
      return true;
    }
    return true;
  }

  handleSaveClick = () => {
    let isValidConcurrentSessionDetails = this.concurrentSessionDetailsValidation();
    if (isValidConcurrentSessionDetails && this.isValidDetails() && this.isValidQRCodeWaitingTime()) {
      let SSODetails = {
        ...this.state.SSODetails,
        cognitoDomain: this.state.cognitoDomain ? this.state.cognitoDomain : "",
        clientId: this.state.clientId ? this.state.clientId : "",
        identityProvider: this.state.identityProvider ? this.state.identityProvider : "",
        clientSecret: this.state.clientSecret ? this.state.clientSecret : "",
        userPoolId: this.state.userPoolId ? this.state.userPoolId : ""
      }

      // need to set blank sso details to null to compare with props as props has null values
      // TODO: server should accept null SSO details as its sending null values in get API
      const ssoDetailsToCompare = {
        ...this.state.SSODetails,
        cognitoDomain: this.state.cognitoDomain ? this.state.cognitoDomain : null,
        clientId: this.state.clientId ? this.state.clientId : null,
        identityProvider: this.state.identityProvider ? this.state.identityProvider : null,
        clientSecret: this.state.clientSecret ? this.state.clientSecret : null,
        userPoolId: this.state.userPoolId ? this.state.userPoolId : null
      }

      let qrCodeSettings;
      if (this.props.availableModules?.includes(modulesAvailableConstant.QR_CODE)) {
        qrCodeSettings = {
          qrCodeNotificationSettings: {
            ...this.state.qrCodeNotificationSettings
          },
          qrCodeWaitingTime: this.state.qrCodeWaitingTime
        }
      }

      let appEmulationSettings;
      if (
        this.props.availableModules?.includes(
          modulesAvailableConstant.APP_EMULATION
        )
      ) {
        appEmulationSettings = {
          showDeviceInfo: this.state.showDeviceInfo,
        };
      } 
      let bandwidthSettings = [];
      if (this.props.isVideoOrSnapshotSelected) {
        bandwidthSettings.push({
          module: Modules.AUDIO_VIDEO,
          bandwidth: this.state.videoStreamBandwidth,
          fps: this.state.videoStreamFPS,
        });
      }

      if (this.props.isScreenShareSelected) {
        bandwidthSettings.push({
          module: Modules.SCREENSHARE,
          bandwidth: this.state.screenShareBandwidth,
          fps: this.state.screenShareFPS,
        });
      }
      let allowDownloads = this.state.allowedDownloads;
      let storageDetails = this.state.storageDetails;
      let cobrowseSettings = this.state.cobrowseSettings;
      cobrowseSettings.customUrlLabels = this.state.allowCustomUrl === false ? [] : this.state.customUrlLabels;
      cobrowseSettings.allowCustomUrl = this.state.allowCustomUrl;
      cobrowseSettings.targetSites = this.state.targetSites;
      delete cobrowseSettings.cobrowseType;
      storageDetails.list = storageDetails.location === STORAGE_LOCATION.CUSTOMER ? storageDetails?.list.slice() : [];

      let chatSettings = undefined;
      let chatSettingsObjectToBeCompared = undefined;

      if(this.props.isChatGPTEnabled) {

        let stateChatSettings = {...this.state.chatSettings}
        const context = stateChatSettings.context?.trim();

        chatSettings = {

          chatgpt: {
            organization: stateChatSettings.organizationName?.trim(),
            apiKey: stateChatSettings.APIKey?.trim(),
            context: context ? [context] : null, // TODO: This array needs to be changed when new UI is handled 
          }
  
        };

        const propsChatSettingsObject = {...this.props.chatSettings};

        chatSettingsObjectToBeCompared = {
          organization: propsChatSettingsObject.chatgptOrg,
          apiKey: propsChatSettingsObject.chatgptKey,
          context: propsChatSettingsObject.chatgptContext
        };
      }

      let details = {
        storageDetails: storageDetails,
        SSODetails: SSODetails,
        qrCodeSettings,
        subLoggingLevels: this.state.subLoggingLevels,
        concurrentSessionThreshold: this.state.concurrentSessionThreshold ? this.state.concurrentSessionThreshold : 0,
        businessModel: this.props.concurrentSessionData.businessModel,
        concurrentSessionNotifyEmails: this.allEmails,
        cobrowseSettings: cobrowseSettings,
        allowAnonymousUsers: this.state.allowAnonymousParticipants,
        showLogoOnCanvas: this.state.showLogoOnCanvasStatus,
        appEmulationSettings: appEmulationSettings,
        chatSettings: chatSettings
      }
      if (this.props.availableModules?.includes(modulesAvailableConstant.CHAT)) {
        details.showChatPopup = this.state.showChatPopup;
      } else {
        delete allowDownloads[modulesAvailableConstant.CHAT];
      }
      if (!this.props.availableModules?.includes(modulesAvailableConstant.SCREENSHARE)) {
        delete allowDownloads[modulesAvailableConstant.SCREENSHARE];
      }
      details.subModules = {
        allowDownloads: this.props.availableModules?.includes(modulesAvailableConstant.ALLOW_DOWNLOAD) ? allowDownloads : {}
      }
      if (bandwidthSettings.length > 0) {
        details.bandwidthSettings = bandwidthSettings;
      }
      // TODO: received response and required request format are different for emails
      this.allEmailsToCompare = this.allEmailsToCompare?.length > 0 ? this.allEmailsToCompare : null;
      if (!_.isEqual(this.state.allowedDownloads, this.subModules)
        || !_.isEqual(this.state.subLoggingLevels, this.props.availableSubLoggingLevels)
        || !_.isEqual(this.state.storageDetails, this.props.storageDetails)
        || !_.isEqual(ssoDetailsToCompare, this.props.SSODetails)
        || !_.isEqual(bandwidthSettings, this.props.bandwidthSettings)
        || !_.isEqual(this.state.showLogoOnCanvasStatus, this.props.showLogoOnCanvasStatus)
        || !_.isEqual( this.state.concurrentSessionThreshold, this.props.concurrentSessionData.concurrentSessionThreshold)
        || (this.props.concurrentSessionData.businessModel == BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS && !_.isEqual( this.allEmailsToCompare, this.props.concurrentSessionData.concurrentSessionNotifyEmails))
        || !_.isEqual(this.state.allowCustomUrl, this.props.cobrowseSettings.allowCustomUrl)
        || !_.isEqual(this.state.targetSites, this.props.cobrowseSettings.targetSites)
        || !_.isEqual(this.state.customUrlLabels, this.props.cobrowseSettings.customUrlLabels)
        || !_.isEqual(this.state.allowAnonymousParticipants, this.props.allowAnonymousParticipants)
        || !_.isEqual(this.state.showLogoOnCanvasStatus, this.props.showLogoOnCanvasStatus)
        || !_.isEqual(this.state.showChatPopup, this.props.showChatPopup)
        || !_.isEqual(this.state.qrCodeNotificationSettings, this.props.qrCodeSettings?.qrCodeNotificationSettings)
        || !_.isEqual(this.state.qrCodeWaitingTime, this.props.qrCodeSettings?.qrCodeWaitingTime)
        || !_.isEqual(this.state.showDeviceInfo, this.props.appEmulationSettings?.showDeviceInfo)
        || (this.props.isChatGPTEnabled && (!_.isEqual(chatSettingsObjectToBeCompared, chatSettings.chatgpt)))
      ) {
        this.props.updateCustomerSettings(details, (error) => {
          if (!error) {
            this.resetStorageDetailsFields();
            this.resetError();
            if (this.ssoLocator) { this.onDownArrowClicked(LOCATOR.SSO) }
            if (this.storageLocator) { this.onDownArrowClicked(LOCATOR.STORAGE) }
            if (this.state.isCobrowseToggler) { this.onDownArrowClicked(LOCATOR.CO_BROWSE)  }
            if (this.state.isChatSettingsToggler) { this.onDownArrowClicked(LOCATOR.CHAT_SETTINGS) }
          }
        })
      } else if (this.state.storageErrorMessage || this.state.ssoErrorMessage) {
        if (this.ssoLocator) {
          this.onDownArrowClicked(LOCATOR.SSO);
        }
        if (this.storageLocator) {
          this.onDownArrowClicked(LOCATOR.STORAGE);
        }
      } else {
        this.resetStorageDetailsFields();
        this.resetError();
        this.props.handleCancelClick();
      }
    }
  }

  // scrollToTop method use to scroll page on top when user is getting error for email or threshold
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  updateAvailableDownloads = (allow, downloadableSubModule ) => {
    let allowedDownloads = { ...this.state.allowedDownloads }
    allowedDownloads[downloadableSubModule] = allow
    this.setState({
      allowedDownloads: allowedDownloads
    })
  }

  handleSubLoggingLevelsChanged = (event) => {
    let currentSubloggingLevels = this.state.subLoggingLevels
    if (event.target.checked && currentSubloggingLevels.indexOf(event.target.name) === -1) {
      currentSubloggingLevels.push(event.target.name)
    }
    else if (!event.target.checked && currentSubloggingLevels.includes(event.target.name) >= 0) {
      currentSubloggingLevels = _.remove(currentSubloggingLevels, item => {
        if (item !== event.target.name) return item;
      });
    }
    this.setState({ subLoggingLevels: currentSubloggingLevels });
  }

  resetError = () => {
    this.setState({
      storageErrorMessage: "",
      ssoErrorMessage: "",
      cobrowseURLError: "",
      qrCodeWaitingTimeError: ""
    });
  }

  handleInputChange = (event) => {
    if(!hasWhiteSpace(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value.trim()});
      this.resetError();     
    }
  }

  handleCoBrowseInputChange = (event) => {
    if(event.target.name === "targetSiteValue") {
      this.setState({ [event.target.name]: event.target.value.trim() });
    } else {
      this.setState({ [event.target.name]: event.target.value});
    }
    this.resetError();
  }

  handleCustomUrlInputChange = (event) => {
    let customUrlLabels =  this.state.customUrlLabels;
    if(event.target.value=="") {
      this.setState({ customUrlLabels: []})
    } else {
      if(!customUrlLabels.length) {
        customUrlLabels.push({label: event.target.value} );
        this.setState({
          customUrlLabels: customUrlLabels,
          customUrlLabelError: ""
        });
      } else {      
        customUrlLabels[0].label= event.target.value;
        this.setState({
          customUrlLabels : customUrlLabels,
          customUrlLabelError: ""
        });
      }    
    }
  }

  handleThresholdInputChange = (name, value, maxLimit) => {
    let ele = document.getElementsByName(name)[0];
    let inputVallength = ele.value.length;
    if (!Number.isInteger(value) || value > maxLimit) {
      this.setState({
        [name]: this.state[name]
      });
      this.resetError();
      return;
    }

    this.setState({
      [name]: value,
    }, () => {
      ele.setSelectionRange(inputVallength, inputVallength);
    });
    this.resetError();
  }

  handleS3InputChange = (event) => {
    if (!hasWhiteSpace(event.target.value)) {
      this.setState({ [event.target.name]: event.target.value, isVerified: false });
      this.resetError();
    }
  }

  handleQRCodeWaitingTimeInputChange = (value) => {
    if (value !== 0 && value <= window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC) {
      this.setState({
        qrCodeWaitingTime: value
      });
      this.resetError();
    } else if (value > window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC) {
      this.setState({
        qrCodeWaitingTimeError: getMessage("QR_CODE_WAITING_TIME_ERROR", { min: window._env_.REACT_APP_QR_CODE_WAITING_TIME_MIN_IN_SEC, max: window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC })
      });
    } else {
      this.setState({
        qrCodeWaitingTime: null
      });
    }
  }

  handleEmailInputChange = () => event => {
    this.setState({
      emailIds : event.target.value.replace(" ", "").trim(),
      emailErrorMessage: "",
    });
  };

  selectDefaultStorage = (selectedStorageDetailIndex) => {
    let storageDetailsList = this.state.storageDetails?.list ? this.state.storageDetails?.list.slice() : [];
    let valid = false
    if (selectedStorageDetailIndex != -1) {
      storageDetailsList = storageDetailsList.forEach((storageDetails, index) => {
        if (index === selectedStorageDetailIndex) {
          storageDetails.isSelected = true;
          valid = true;
          this.setState({
            storageChanged: true,
          });
        } else {
          storageDetails.isSelected = false;
        }
      });
    } else {
      storageDetailsList = storageDetailsList.forEach((storageDetails, index) => {
        if (storageDetails.isSelected) {
          valid = true;
        }
      })
    }
    this.setState({
      isStorageSelected: valid
    });
  }

  handleBandwidthSettings = (name, value) => {
    this.setState({
      [name]: isNaN(parseInt(value)) ? 360 : parseInt(value)
    });
  }

  handleEditStorageClick(bucketName) {
    let bucket = null;
    if (!_.isEmpty(bucketName)) {
      bucket = this.state.storageDetails.list?.filter((item => item.credential.bucketName === bucketName))[0];
      this.setState({
        isEditStorage: true,
        bucketName: bucket.credential.bucketName,
        id: bucket.credential.id,
        secret: bucket.credential.secret,
        currentBucket: bucket.credential.bucketName
      });
    }
  }

  addStorage = () => {
    if (!this.state.bucketName && !this.state.id && !this.state.secret) {
      if (!this.state.isStorageToggler) {
        this.onDownArrowClicked(LOCATOR.STORAGE)
      } this.setState({ storageErrorMessage: getMessage("EMPTY_MESSAGE"), });
    }
    else if (this.state.isVerified) {
      let bucket = this.state.storageDetails;
      bucket.type = bucket.type ? bucket.type : "S3";
      bucket.list = bucket.list && this.props.storageDetails.location === STORAGE_LOCATION.CUSTOMER ? bucket.list : [];
      bucket.location = this.props.storageDetails.location;

      let isDuplicateStorageDetails =false;
      bucket.list.map((content, i) =>{
        if(content.credential.bucketName === this.state.bucketName
          && content.credential.id === this.state.id
          && content.credential.secret === this.state.secret) {
          //Duplicate Storage Details
          isDuplicateStorageDetails=true;
        }
      });

      if(!isDuplicateStorageDetails) {
        if (!this.state.isEditStorage) {
          let cred = {
            credential: {
              bucketName: this.state.bucketName,
              id: this.state.id,
              secret: this.state.secret
            },
            validation: { status: true },
            isSelected: this.state.isSelected,
          }
          bucket.list.push(cred)
        }
        else {
          let cred = {
            credential: {
              bucketName: this.state.bucketName,
              id: this.state.id,
              secret: this.state.secret
            },
            validation: { status: true }
          };
          bucket.list = bucket.list.map((content, i) =>
            content.credential.bucketName === this.state.bucketName ? {
              ...content, ...cred
            } : content);
        }
        this.resetStorageDetailsFields();
        this.setState({ storageDetails: bucket })
      }
      else{
        this.setState({ storageErrorMessage: getMessage("DUPLICATE_STORAGE_CREDENTIALS") });
      }
    }
    else {
      if (!this.state.isStorageToggler) {
        this.onDownArrowClicked(LOCATOR.STORAGE)
      } this.setState({ storageErrorMessage: getMessage("BEFORE_VERIFICATION_ERROR") });
    }
  }

  handleVerifyStorageDetailsClick = () => {
    this.resetError();
    if (this.state.bucketName && this.state.id && this.state.secret) {
      this.setState({ spinnerVisibility: true });
      let storage = {
        type: 'S3',
        credentials: {
          id: this.state.id,
          secret: this.state.secret,
          bucketName: this.state.bucketName
        }
      }
      this.props.verifyStorage(storage, (error) => {
        if (error.code) {
          this.setState({
            spinnerVisibility: false,
            isVerified: false,
            storageErrorMessage: getMessage(error.code)
          });
          if (!this.state.isStorageToggler) {
            this.onDownArrowClicked(LOCATOR.STORAGE)
          }
        }
        else {
          this.setState({
            spinnerVisibility: false,
            isVerified: true
          });
        }
      })
    }
    else {
      if (!this.state.isStorageToggler) {
        this.onDownArrowClicked(LOCATOR.STORAGE)
      }
      this.setState({ storageErrorMessage: getMessage("EMPTY_MESSAGE") });
    }
  }

  cancelStorage = () => {
    this.resetStorageDetailsFields();
    this.resetError();
  }

  renderStorageTableBody() {
    return (
      <tbody>
        {this.state.storageDetails.list?.map((item, index) =>
          <tr className="cursorPointer">
            <td className="storageRow">
              <Input
                type="radio"
                className='oldRadio'
                checked={item.isSelected}
                onChange={() => this.selectDefaultStorage(index)}
              />
              <span className="checkmark" value={item.isSelected}
                onClick={() => this.selectDefaultStorage(index)}>
              </span>
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="2"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential?.bucketName}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="3"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential?.id}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}
              colSpan="3"
              onClick={() => this.selectDefaultStorage(index)}>
              {item.credential?.secret}
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
              {!item.validation?.status
                ? <>
                  <img src={ic_error} alt="Status" className="ml-2 cursorPointer"
                    id="bucketError" 
                  />
                      <CustomTooltip
                        tooltipText={item.validation.error.message}
                        tooltipId="bucketError"
                        tooltipBoundariesElement={this.tooltipBoundaryElement}
                      />
                </>
                : null
              }
            </td>
            <td className={item.validation?.error?.message ? "storageRowError" : "storageRow"}>
              <img src={ic_edit} alt="Edit" className="cursorPointer" id="edit"
                onClick={() => { this.handleEditStorageClick(item.credential.bucketName) }} />
              <CustomTooltip
                tooltipText={getMessage("EDIT")}
                tooltipId="edit"
              />
            </td>
          </tr>)}
      </tbody>
    )
  }

  renderButtons = () => {
    return (
      <React.Fragment>
        <Button
          className="buttons borderBtn bgwhite borderRound mr-2"
          onClick={this.props.handleCancelClick}>
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button
          className="buttons customBtn"
          onClick={this.handleSaveClick}>
          {getMessage("SAVE")}
        </Button>
      </React.Fragment>
    );
  };

  renderSwitch = (downloadableSubModule, isAvailable) => {
    return (
      <Switch
        switchOn={isAvailable}
        toggleSwitch={(isDownloadAllowed) => this.updateAvailableDownloads(isDownloadAllowed, downloadableSubModule)}
      />
    )
  }

  setAllowCustomUrl = () => {
    if(this.state.targetSites.length == 0) {
      this.enableCustomURL();
    } else {
      this.setState((prevState) => ({ allowCustomUrl: !prevState.allowCustomUrl }))
    }
  }

  handleAddTargetSiteClick = () => {
    let targetSites = _.cloneDeep(this.state.targetSites);
    let isCustomURLValid = validateCobrowseCustomURL(this.state.targetSiteValue);
    let isDuplicate = false ;
    let indexToEdit = targetSites.findIndex(element => element.label === this.state.targetSiteLabelToEdit);
    
    if(isCustomURLValid === true) {
      targetSites.map((targetsite, index) => {
        // In case of add check all target sites to find duplicate
        // For update we should skip the target site that is being updated -> index !== this.state.targetSiteIdToEdit
        if((index !== indexToEdit
              || this.state.showAddTargetSiteSection) 
              && (targetsite.label===this.state.targetSiteLabel
              || (targetsite.url===this.state.targetSiteValue))) {
          isDuplicate = true;
        }
      })
      if (isDuplicate) {
        this.setState({cobrowseURLError: getMessage("DUPLICATE_PRE_CONFIGURED_URL_DETAILS")})
        return;
      }
      if(this.state.showUpdateTargetSiteSection) {
        targetSites[indexToEdit].label= this.state.targetSiteLabel.trim();
        targetSites[indexToEdit].url= this.state.targetSiteValue;
        this.setState({ targetSites });
        this.onCancelCobrowseClick();
      } else if(this.state.showAddTargetSiteSection) {
        let targetSite = {
          label: this.state.targetSiteLabel.trim(),
          url: this.state.targetSiteValue
        };
        targetSites.push(targetSite);
        this.setState({ targetSites : targetSites });
        this.onCancelCobrowseClick();
      };
     
    } else {
      this.setState({ cobrowseURLError : isCustomURLValid })
    }
  };

  enableCustomURL = () => {
    this.setState({
      allowCustomUrl : true
    })
  }

  handleDeleteUrlClick = (urlLabel) => {
    let targetSites = this.state.targetSites.filter((element, index ) => element.label !== urlLabel);
    this.setState({
      targetSites: targetSites
    });
    if(targetSites.length == 0) {
      this.enableCustomURL();
    }
  }

  onCancelCobrowseClick = () => {
    this.setState({
      showAddTargetSiteSection: false,
      showUpdateTargetSiteSection: false,
      targetSiteLabelToEdit: "",
      targetSiteLabel: "",
      targetSiteValue: "",
      cobrowseURLError:""
    });
  }

  handleCobrowseArrowClick = () => {
    this.setState({
      showAddTargetSiteSection: false,
      showUpdateTargetSiteSection: false
    });
    this.onDownArrowClicked(LOCATOR.CO_BROWSE);
  }

  handleChatSettingsArrowClick = () => {
    this.setState({
      
    });
    this.onDownArrowClicked(LOCATOR.CHAT_SETTINGS);
  }

  handleAddTargetSiteIconClick = () => {
    this.setState({ 
      targetSiteLabel: "",
      targetSiteValue: "",
      showUpdateTargetSiteSection: false,
      showAddTargetSiteSection: true })
  }

  handleUpdateTargetSiteClick = (index, element) => {
    this.setState({
      showAddTargetSiteSection: false,
      showUpdateTargetSiteSection: true,
      targetSiteLabelToEdit: element.label,
      targetSiteLabel: element.label,
      targetSiteValue: element.url
    })
  }

  setShowLogoOnCanvasStatus =  () => {
    this.setState({
      showLogoOnCanvasStatus: !this.state.showLogoOnCanvasStatus 
    })
  }

  setShowDeviceInfoOnModalStatus =  () => {
    this.setState((prevState) => ({
      showDeviceInfo: !prevState.showDeviceInfo,
    }));
  }

  isAllowAnonymousParticipants = () => {
    this.setState((prevState) => ({
      allowAnonymousParticipants: !prevState.allowAnonymousParticipants,
    }));
  }

  toggleShowChatPopup = () => {
    this.setState({
      showChatPopup: !this.state.showChatPopup
    })
  }

  toggleQRCodeNotificationSettings = (settingType) => {
    this.setState((prevState) => {
      const qrCodeNotificationSettings = { ...prevState.qrCodeNotificationSettings };
      qrCodeNotificationSettings[settingType] = !prevState.qrCodeNotificationSettings[settingType]
      return {
        qrCodeNotificationSettings
      }
    })
  }

  handleKeyDown = (event) => {
    //bugfix-WAAG-4132-https://waagunew.atlassian.net/browse/WAAG-4132
    var code = event.keyCode || event.which;
    //backspace keypress clear default 0 value
    if (code == 8 && event.target.value.length == 1) {
      if (event.target?.name === "concurrentSessionThreshold") {
        this.setState({
          concurrentSessionThreshold: 0
        })
      } else if (event.target?.name === "qrCodeWaitingTime") {
        this.setState({
          qrCodeWaitingTime: null
        })
      }

      //On left keypress restrict navigation if value is zero
      if (code == 37 && event.target.value == 0) {
        event.preventDefault();
      }
    }
  }

  onChangeOrganizationName = (organizationName) => {
    let chatSettings = {...this.state.chatSettings};
    chatSettings.organizationName = organizationName;

    this.setState({
      chatSettings: chatSettings
    });
  }

  onChangeAPIKey = (APIKey) => { 
    let chatSettings = {...this.state.chatSettings};
    chatSettings.APIKey = APIKey;
    
    this.setState({
      chatSettings: chatSettings
    });
  }

  onChangeContext = (context) => { 
    let chatSettings = {...this.state.chatSettings};
    chatSettings.context = context;
    
    this.setState({
      chatSettings: chatSettings
    });
  }

  render() {
    
    let loginURL = "https://" + this.state.cognitoDomain + '/oauth2/authorize?' + '&response_type=code'+'&client_id=' + this.state.clientId + '&redirect_uri=' + window.location.origin + CONFIG.path.ssoLogin + "&state=STATE" + '&identity_provider=' + this.state.identityProvider;
    let passcodeURL = window.location.origin + "/" + this.props.uriIdentifier + CONFIG.path.passcode;
    let emailError = getMessage('EMAIL_NOT_SENT_ERROR');
    let { seats, maxConcurrentSessions, businessModel,concurrentSessionEmailInterval } = { ...this.props.concurrentSessionData }
    concurrentSessionEmailInterval = concurrentSessionEmailInterval ? concurrentSessionEmailInterval : 0;
    return (
      <div
        className="settingsModal px-md-5"
        backdrop="static">
        <div className="settingsBody">

          <div className="section">
            <Row className="noMargin">
              <Col md={11} className="mainTitle">
                {getMessage("BUSINESS_MODEL")}
              </Col>
            </Row>
            <Row className="noMargin mt-1">
              <Col xs={5}> {getMessage("SELECTED_BUSINESS_MODEL")}:
              </Col>
              <Col xs={7} className="pl-0">
                {businessModel == BUSINESS_MODELS.NUMBER_OF_SEATS ? getMessage("NO_OF_SEATS") : getMessage("MAX_CONCURRENT_CONNECTIONS")}
              </Col>
            </Row>
            {businessModel == BUSINESS_MODELS.NUMBER_OF_SEATS && <Row className="noMargin mt-4 mb-4">
              <Col xs={5} className="pr-0"> {getMessage("NO_OF_SEATS")}:
              </Col>
              <Col xs={7} className="pl-0">{seats}</Col>
            </Row>}
            {businessModel == BUSINESS_MODELS.MAX_CONCURRENT_CONNECTIONS && <div>
              <Row className="max-concurrent-connections-container">
                <Col xs={5} className="pr-0"> {getMessage("MAX_CONCURRENT_CONNECTIONS")}:
                </Col>
                <Col xs={7} className="pl-0">{maxConcurrentSessions}</Col>
              </Row>
              <Row className="noMargin mt-2">
                <Col xs={5} className="pr-0 d-flex align-items-center">
                  {getMessage("THRESHOLD_VALUE")}:
                  {
                    this.state.concurrentSessionThreshold > 0
                    && (
                      <div className="ml-2 mb-1">
                        <CustomTooltip
                          tooltipId="thresholdError"
                          tooltipBoundariesElement={this.tooltipBoundaryElement}
                          tooltipText={getMessage('ICON_DESCRIPTION_THRESHOLD', { minutes: (Math.floor((concurrentSessionEmailInterval) / 60000)) })}
                          showIcon={true}
                        />
                      </div>
                    )
                  }
                </Col>
                <Col xs={7} className="pl-0 d-flex thresholdCol">
                  <NumericInput
                    className="inputFormControl"
                    name="concurrentSessionThreshold"
                    value={this.state.concurrentSessionThreshold}
                    min={0}
                    placeholder={getMessage("THRESHOLD_VALUE_PLACEHOLDER")}
                    strict={this.state.concurrentSessionThreshold <= maxConcurrentSessions}
                    onChange={(value) => this.handleThresholdInputChange("concurrentSessionThreshold", value, maxConcurrentSessions)}
                    required
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    autoFocus
                  />
                </Col>
               
              </Row>
              <Row className="noMargin mt-2">
                <Col xs={5} className="pr-0 threshold-notification-email-label"> {getMessage("THRESHOLD_EMAIL")}:
                </Col>
                <Col xs={7} className="pl-0">
                  <Input
                    disabled = {this.state.concurrentSessionThreshold <= 0 ? true: false}
                    type="text"
                    name="emailIds"
                    className={"inputFormControl threshold-email" + (this.state.concurrentSessionThreshold <= 0 ? "cursorDisabled":"cursorDefault")}
                    required
                    placeholder={getMessage("EMAILS_INPUT_PLACEHOLDER")}
                    onChange={this.handleEmailInputChange()}
                    value={this.state.emailIds}
                  />
                  <Row className="d-flex errors my-0 py-2 float-left">
                    {this.state.concurrentSessionThreshold <= 0 ? emailError : this.state.emailErrorMessage ? this.state.emailErrorMessage
                      : ""}
                  </Row>
                </Col>                
              </Row>
            </div>}
          </div>

          <div className="section">
            {this.props.uriIdentifier && (
              <React.Fragment>
                <DropdownItem divider />
                <Col xs={10} className="mainTitle">
                  {getMessage("ORGANIZATION_URI_IDENTIFIER")}
                </Col>
                <Row className="noMargin mt-1">
                  <Col xs={5}> {getMessage("URI_IDENTIFIER")}:</Col>
                  <Col xs={7} className="pl-0">
                    {this.props.uriIdentifier}
                  </Col>
                </Row>
                {this.props.isPasscodeEnabled && (
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="mt-1">
                      {getMessage("PASSCODE_URL")}:
                    </Col>
                    <Col xs={7} className="pl-0 mt-1">
                      {passcodeURL}
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="section">
            {this.state.SSODetails.isSSOEnabled ?
              <React.Fragment>
                <DropdownItem divider />
                <Row className="noMargin">
                  <Col xs={10} className="mainTitle">
                    {getMessage("SINGLE_SIGN_ON")}
                  </Col>
                  <Col>
                    <img
                      style={{ transform: `rotate(${this.state.ssoRotation}deg)` }}
                      className="dropDown cursorPointer floatRight"
                      src={ic_arrow_down}
                      onClick={() => this.onDownArrowClicked(LOCATOR.SSO)} />
                  </Col> 
                </Row>
                <Collapse isOpen={this.state.isSSOToggler}>
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0"> {getMessage("CLIENT_ID")}:
                    </Col>
                    <Col xs={7} className="pl-0">
                      <Input
                        type="text"
                        name="clientId"
                        className="inputFormControl"
                        required
                        placeholder={getMessage("CLIENT_ID_PLACEHOLDER")}
                        onChange={this.handleInputChange}
                        value={this.state.clientId}
                      />
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0"> {getMessage("COGNITO_DOMAIN")}:
                    </Col>
                    <Col xs={7} className="pl-0">
                      <Input
                        type="text"
                        name="cognitoDomain"
                        className="inputFormControl"
                        required
                        placeholder={getMessage("COGNITO_DOMAIN_PLACEHOLDER")}
                        onChange={this.handleInputChange}
                        value={this.state.cognitoDomain}
                      />
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0"> {getMessage("IDENTITY_PROVIDER")}:
                    </Col>
                    <Col xs={7} className="pl-0">
                      <Input
                        type="text"
                        name="identityProvider"
                        className="inputFormControl"
                        required
                        placeholder={getMessage("IDENTITY_PROVIDER_PLACEHOLDER")}
                        onChange={this.handleInputChange}
                        value={this.state.identityProvider}
                      />
                    </Col>
                  </Row> 
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0"> {getMessage("CLIENT_SECRET")}:
                    </Col>
                    <Col xs={7} className="pl-0">
                      <Input
                        type="text"
                        name="clientSecret"
                        className="inputFormControl"
                        required
                        placeholder={getMessage("CLIENT_SECRET_PLACEHOLDER")}
                        onChange={this.handleInputChange}
                        value={this.state.clientSecret}
                      />
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0"> {getMessage("USER_POOL_ID")}:
                    </Col>
                    <Col xs={7} className="pl-0">
                      <Input
                        type="text"
                        name="userPoolId"
                        className="inputFormControl"
                        required
                        placeholder={getMessage("USER_POOL_ID_PLACEHOLDER")}
                        onChange={this.handleInputChange}
                        value={this.state.userPoolId}
                      />
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col xs={5} className="pr-0">
                      {getMessage("LOGIN_URL")}:
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col md={12} className="text-muted autoHeight loginURL">
                      {loginURL}
                    </Col>
                  </Row>
                  {this.state.ssoErrorMessage ?
                    <Row>
                      <Col md={12} className="errors autoHeight">
                        {getMessage(this.state.ssoErrorMessage)}
                      </Col>
                    </Row>
                    : null
                  }
                </Collapse>
              </React.Fragment> : null}
            
          </div>
          <div className="section">
            <DropdownItem divider />
            <Row className="noMargin">
              <Col className="mainTitle">
                {getMessage("CUSTOM SETTINGS")}
              </Col>
            </Row>
            <Row className="noMargin">
              <Col xs={5}>{getMessage("SHOW_LOGO_ON_CANVAS")}:</Col>
              <Col xs={7}>
                <Row className="justify-content-center pl-2">
                  <Col xs={4} className="offset-8">
                    <Switch
                      switchOn={this.state.showLogoOnCanvasStatus}
                      toggleSwitch={() => { this.setShowLogoOnCanvasStatus() }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="noMargin mt-2">
              <Col xs={5}>{getMessage("ALLOW_ANONYMOUS_PARTICIPANTS")}:</Col>
              <Col xs={7}>
                <Row className="justify-content-center pl-2">
                  <Col xs={4} className="offset-8">
                    <Switch
                      switchOn={this.state.allowAnonymousParticipants}
                      toggleSwitch={() => { this.isAllowAnonymousParticipants() }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.props.availableModules?.includes(Modules.CHAT) &&            
              <Row className="noMargin mt-2">
                <Col xs={5}>{getMessage("SHOW_CHAT_POPUP")}:</Col>
                <Col xs={7}>
                  <Row className="justify-content-center pl-2">
                    <Col xs={4} className="offset-8">
                      <Switch
                        switchOn={this.state.showChatPopup}
                        toggleSwitch={() => { this.toggleShowChatPopup() }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            }            
          </div>
          {this.props.availableModules?.includes(modulesAvailableConstant.QR_CODE) &&     
            <div className="section">
              <DropdownItem divider />
              <Row className="noMargin">
                <Col className="mainTitle">
                  {getMessage("QR_CODE_SETTINGS")}
                </Col>
              </Row>
              <Row className="noMargin mt-2">
                <Col xs={5} className="pr-0 qr-code-waiting-time-label">
                  {getMessage("QR_CODE_WAITING_TIME")}:
                </Col>
                <Col xs={7} className="pl-0 thresholdCol">
                  <NumericInput
                    className="inputFormControl"
                    name="qrCodeWaitingTime"
                    value={this.state.qrCodeWaitingTime}
                    strict={this.state.qrCodeWaitingTime <= window._env_.REACT_APP_QR_CODE_WAITING_TIME_MAX_IN_SEC}
                    placeholder={getMessage("QR_CODE_WAITING_TIME_PLACEHOLDER")}
                    onChange={(value) => this.handleQRCodeWaitingTimeInputChange(value)}
                    required
                    onKeyDown={(event) => this.handleKeyDown(event)}
                    min={0}
                    onBlur={() => this.isValidQRCodeWaitingTime() }
                  />
                  <Row className="d-flex errors my-0 py-2 float-left">
                    {this.state.qrCodeWaitingTimeError ? this.state.qrCodeWaitingTimeError : ""}  
                  </Row>
                </Col>
              </Row>
              <Row className="noMargin">
                <Col xs={5}>{getMessage("QR_CODE_MEETING_NOTIFICATIONS")}:</Col>
                <Col xs={7} className="px-0">
                  <Row className="noMargin mt-2">
                    <Col xs={8} className="px-0">{getMessage("EMAIL")}:</Col>
                    <Col xs={4}>
                      <Switch
                        switchOn={this.state.qrCodeNotificationSettings.email}
                        toggleSwitch={() => { this.toggleQRCodeNotificationSettings("email") }}
                      />
                    </Col>
                  </Row>
                  <Row className="noMargin mt-2">
                    <Col xs={8} className="px-0">{getMessage("SMS")}:</Col>
                    <Col xs={4}>
                      <Switch
                        switchOn={this.state.qrCodeNotificationSettings.sms}
                        toggleSwitch={() => { this.toggleQRCodeNotificationSettings("sms") }}
                      />
                    </Col>
                  </Row>
                  {this.props.concurrentSessionData?.is_ring_central_enabled && <Row className="noMargin mt-2">
                    <Col xs={8} className="px-0">{getMessage("RING_CENTRAL")}:</Col>
                    <Col xs={4}>
                      <Switch
                        switchOn={this.state.qrCodeNotificationSettings.ringCentral}
                        toggleSwitch={() => { this.toggleQRCodeNotificationSettings("ringCentral") }}
                      />
                    </Col>
                  </Row>}
                  <Row className="noMargin mt-2">
                    <Col xs={8} className="px-0">{getMessage("AGENT_MEETING_QUEUE")}:</Col>
                    <Col xs={4}>
                      <Switch
                        switchOn={this.state.qrCodeNotificationSettings.agentSessionQueue}
                        toggleSwitch={() => { this.toggleQRCodeNotificationSettings("agentSessionQueue") }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
          {this.props.availableModules?.includes(modulesAvailableConstant.APP_EMULATION) && <div className="section">
            <DropdownItem divider />
            <Row className="noMargin">
              <Col className="mainTitle">
                {getMessage("APP_EMULATION_SETTINGS")}
              </Col>
            </Row>
            <Row className="noMargin">
              <Col xs={5}>{getMessage("SHOW_DEVICE_INFO_ON_MODAL")}:</Col>
              <Col xs={7}>
                <Row className="justify-content-center pl-2">
                  <Col xs={4} className="offset-8">
                    <Switch
                      switchOn={this.state.showDeviceInfo}
                      toggleSwitch={() => this.setShowDeviceInfoOnModalStatus()}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>}

          {this.props.isDownloadModuleSelected &&
              <div className="section">
                <DropdownItem divider />
                <Row className="noMargin">
                  <Col className="mainTitle">
                    {getMessage("DOWNLOADS")}
                  </Col>
                </Row>
                <Row className="noMargin">
                  <Col xs={5}>{getMessage("ALLOW_DOWNLOAD_FOR")}:</Col>
                  <Col xs={7} className="px-0">
                    {listOfAvailableSubModule.map((downloadableSubModule) => {
                      if (this.props.modules.includes(downloadableSubModule) || downloadableSubModule == "CANVAS") {
                        return (
                          <Row className="pb-2 noMargin" key={downloadableSubModule}>
                            <Col xs={8} className="px-0"><span className="subtitle">
                              {getMessage(downloadableSubModule)}</span>
                            </Col>
                            <Col xs={4} className="switchBtnsMargin">
                              {this.renderSwitch(downloadableSubModule,
                                this.state.allowedDownloads && this.state.allowedDownloads[downloadableSubModule])}
                            </Col>
                          </Row>
                        )
                      }
                    }
                    )}
                  </Col>
                </Row>
              </div>
          }
          <div className="section">
            <DropdownItem divider />
            <Row className="noMargin">
              <Col className="mainTitle">
                {getMessage("LOGGING")}
              </Col>
            </Row>
            <Row className="noMargin">
              <Col xs={5}>{getMessage("BASIC")}:</Col>
              <Col xs={7}>
                {Object.keys(subLoggingLevels.basic).map((key) => {
                  return (
                    <Row className="noMargin" key={key}>
                      <Label className="label noPadding">
                        <Checkbox
                          name={subLoggingLevels.basic[key]}
                          onChange={this.handleSubLoggingLevelsChanged}
                          disabled={defaultSubLoggingLevels && defaultSubLoggingLevels.indexOf(subLoggingLevels.basic[key]) >= 0}
                          checked={defaultSubLoggingLevels && defaultSubLoggingLevels.indexOf(subLoggingLevels.basic[key]) > -1
                              || this.state.subLoggingLevels.indexOf(subLoggingLevels.basic[key]) > -1}
                        />
                        {getMessage(key)}
                      </Label>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          </div>
          {this.props.loggingLevel == loggingLevel.ADVANCED &&
            <Row className="noMargin">
              <Col xs={5}>{getMessage("ADVANCED")}:</Col>
              <Col xs={7}>
                <Row>
                  {Object.keys(subLoggingLevels.advanced).map((key) => {
                    if (key === "PDF") {
                      return (
                        (this.props.availableModules?.includes(
                          modulesAvailableConstant.PDF
                        ) ||
                          this.props.isOrganizationTemplatesEnabled) && (
                          <Col md={6} xs={12} key={key}>
                            <Row className="advancedLogging">
                              <Label className="label noPadding">
                                <Checkbox
                                  name={subLoggingLevels.advanced[key]}
                                  onChange={this.handleSubLoggingLevelsChanged}
                                  disabled={
                                    this.props.loggingLevel !==
                                    loggingLevel.ADVANCED
                                  }
                                  checked={
                                    this.state.subLoggingLevels.indexOf(
                                      subLoggingLevels.advanced[key]
                                    ) > -1
                                  }
                                />
                                {getMessage(key)}
                              </Label>
                            </Row>
                          </Col>
                        )
                      );
                    } else {
                      return (
                        <Col md={6} xs={12} key={key}>
                          <Row className="advancedLogging">
                            <Label className="label noPadding">
                              <Checkbox
                                name={subLoggingLevels.advanced[key]}
                                onChange={this.handleSubLoggingLevelsChanged}
                                disabled={
                                  this.props.loggingLevel !==
                                  loggingLevel.ADVANCED
                                }
                                checked={
                                  this.state.subLoggingLevels.indexOf(
                                    subLoggingLevels.advanced[key]
                                  ) > -1
                                }
                              />
                              {getMessage(key)}
                            </Label>
                          </Row>
                        </Col>
                      );
                    }})}                    
                </Row>
              </Col>
            </Row>
          }
          <DropdownItem divider />

          { (this.props.isVideoOrSnapshotSelected || this.props.isScreenShareSelected) &&
          <>
          <div className="section">
            <Row className="noMargin">
              <Col md={11} className="mainTitle col">{getMessage("BANDWIDTH_MANAGEMENT")}
              </Col>
            </Row>
            {this.props.isVideoOrSnapshotSelected && <Row className="noMargin pb-3">
              <Col xs={5} className="pr-0 pb-2 pb-lg-auto video-stream-label">{getMessage("VIDEO_STREAM")}:</Col>
              <Col xs={7}>
                <Row>
                  <Col className="px-0 pb-2 col-lg-7 col-12 d-flex align-items-center ">
                    <CustomDropdown
                      options={this.bandwidthManagementOptions}
                      onChange={(value) => this.handleBandwidthSettings("videoStreamBandwidth", value)}
                      value={this.state.videoStreamBandwidth}
                    />
                    <label className="px-2 pt-2">@</label>
                  </Col>

                  <Col className="col-xl-3 col-lg-4 px-0 pb-2 col-12 d-flex align-items-center">
                    <CustomDropdown
                      options={this.framesPerSecondsOptions}
                      onChange={(value) => this.handleBandwidthSettings("videoStreamFPS", value)}
                      value={this.state.videoStreamFPS}
                    />
                    <label className="pl-3 pt-2">{getMessage("FPS")}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            }
            {this.props.isScreenShareSelected && <Row className="noMargin">
              <Col xs={5} className="pr-0 pb-2 pb-lg-auto screen-share-label">{getMessage("SCREEN_SHARE")}:</Col>
              <Col xs={7}>
                <Row>
                  <Col className="px-0 pb-2 col-lg-7 col-12 d-flex align-items-center">
                    <CustomDropdown
                      options={this.bandwidthManagementOptions}
                      onChange={(value) => this.handleBandwidthSettings("screenShareBandwidth", value)}
                      value={this.state.screenShareBandwidth}
                    />
                    <label className="pt-2 px-2">@</label>
                  </Col>

                  <Col className="col-xl-3 col-lg-4 px-0 pb-2 col-12 d-flex align-items-center">
                    <CustomDropdown
                      options={this.framesPerSecondsOptions}
                      onChange={(value) => this.handleBandwidthSettings("screenShareFPS", value)}
                      value={this.state.screenShareFPS}
                    />
                    <label className="pt-2 pl-3">{getMessage("FPS")}</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            }
          </div>
              
          <DropdownItem divider />
          </>}
          <div className="section">
            <Row className="noMargin">
              <Col md={11} className="mainTitleStorage">{getMessage("STORAGE")}
              </Col>
              {
                  this.props.storageDetails?.location === STORAGE_LOCATION.CUSTOMER ?
                    <Col md={1}>
                      <img
                        style={{ transform: `rotate(${this.state.storageRotation}deg)` }}
                        className="dropDown cursorPointer floatRight"
                        src={ic_arrow_down} alt="storagetoggler" id="storagetoggler"
                        onClick={() => this.onDownArrowClicked(LOCATOR.STORAGE)} />
                    </Col> : null}
            </Row>
            {
                this.props.storageDetails?.location === STORAGE_LOCATION.CUSTOMER ?
                  <Collapse isOpen={this.state.isStorageToggler}>
                    <Row>
                      {(!this.state.isAddStorage && !this.state.isEditStorage)
                        && (this.props.storageDetails?.location === STORAGE_LOCATION.CUSTOMER) ?
                        <Col xs={12} className="mainTitleStorage">
                          <img src={ic_add} className="cursorPointer floatRight mr-3"
                            onClick={() => this.setState({ isAddStorage: true })}
                            id="addStorage"
                          />
                          <CustomTooltip
                            tooltipText={getMessage("ADD_STORAGE")} 
                            tooltipId="addStorage"
                            tooltipBoundariesElement={this.tooltipBoundaryElement}
                          />
                        </Col>
                        : null
                      }
                    </Row>
                    {this.state.storageDetails.list ?
                      <Row className="storageCard">
                        <CardBody className="pt-0 pb-0">
                          <Row className="interCardContent">
                            <Table bordered size="sm" className="storageTableWidth">
                              <thead>
                                <tr className="storageHead">
                                  <th className="storageRow border-bottom rounded"> </th>
                                  <th className="storageRow border-bottom rounded" colSpan="2">{getMessage("BUCKET_NAME")}</th>
                                  <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("ID")}</th>
                                  <th className="storageRow border-bottom rounded" colSpan="3">{getMessage("SECRET")}</th>
                                  <th className="storageRow border-bottom rounded"> </th>
                                  <th className="storageRow border-bottom rounded"> </th>
                                </tr>
                              </thead>
                              {this.renderStorageTableBody()}
                            </Table>
                          </Row>
                        </CardBody>
                      </Row>
                      : null}
                    {
                      this.state.isEditStorage || this.state.isAddStorage ?
                        <Row>
                          <Col className="storageCard">
                            <Card>
                              <CardBody>
                                <Row className="radio"> <Col className="mainTitleStorage">{getMessage("AWS_S3")}:</Col>
                                  <Col>
                                    <Button close aria-label="Cancel" onClick={this.cancelStorage}>
                                    </Button>
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-2 align-self-center">
                                    <Label>{getMessage("BUCKET_NAME")}</Label> </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="bucketName"
                                      className="inputFormControl"
                                      maxLength="50"
                                      required
                                      disabled={this.state.currentBucket}
                                      onChange={this.handleS3InputChange}
                                      value={this.state.bucketName}
                                      placeholder={getMessage("BUCKET_NAME_PLACEHOLDER")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-2 align-self-center">
                                    <Label>
                                      {getMessage("ID")}
                                    </Label>
                                  </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="id"
                                      className="inputFormControl"
                                      maxLength="50"
                                      required
                                      onChange={this.handleS3InputChange}
                                      value={this.state.id}
                                      placeholder={getMessage("AWS_ID_PLACEHOLDER")}
                                    />
                                  </Col>
                                </Row>
                                <Row className="interCardContent">
                                  <Col xs={4} className="pt-2 align-self-center">
                                    <Label>
                                      {getMessage("SECRET")}
                                    </Label>
                                  </Col>
                                  <Col xs={8}>
                                    <Input
                                      type="text"
                                      name="secret"
                                      className="inputFormControl"
                                      maxLength="50"
                                      required
                                      onChange={this.handleS3InputChange}
                                      value={this.state.secret}
                                      placeholder={getMessage("AWS_SECRET_PLACEHOLDER")}
                                    />
                                  </Col>
                                </Row>
                                {this.state.storageErrorMessage ?
                                  <Row>
                                    <Col md={12} className="errors autoHeight">
                                      {getMessage(this.state.storageErrorMessage)}
                                    </Col>
                                  </Row>
                                  : null
                                }
                                <Row className="interCardContent floatRight mx-2">
                                  <Button onClick={this.handleVerifyStorageDetailsClick}
                                    disabled={!this.state.isVerified ? false : true}
                                    className={this.state.isVerified ? "verifiedBtn mt-2 " : "verifyBtn mt-2"} >
                                    {this.state.isVerified ? getMessage("VERIFIED") : getMessage("VERIFY")}
                                  </Button>
                                  <Button className={this.state.isVerified ? "borderBtn cursorPointer bgwhite ml-3 mt-2" : "disabledBtn ml-3 mt-2"}
                                    disabled={this.state.isVerified ? false : true}
                                    onClick={this.addStorage}>
                                    {this.state.isEditStorage ? getMessage("UPDATE") : getMessage("ADD")}
                                  </Button>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        : null
                    }
                    {this.state.storageChanged ?
                      <Row>
                        <Col md={12} className="text-muted text-center autoHeight">
                          {getMessage("SECOND_LOOOKIT_CUSTOM_WARNING")}
                        </Col>
                      </Row>
                      : null
                    }
                  </Collapse> :
                  <Row className="pb-2">
                    <Col xs={12}><span className="ml-3">
                      {getMessage("MANAGED_BY_LOOOKIT")}</span>
                    </Col>
                  </Row>
            }
          </div>

          {(this.props.availableModules.includes(Modules.CO_BROWSE) &&
          this.props.cobrowseSettings.cobrowseType == cobrowseTypes.CUSTOMER_CO_BROWSE) &&
          <>
            <DropdownItem divider />
              <CobrowseSettingsSection
                cobrowseRotation={this.state.cobrowseRotation}
                handleCobrowseArrowClick={this.handleCobrowseArrowClick}  
                isCobrowseToggler={this.state.isCobrowseToggler}
                allowCustomUrl={this.state.allowCustomUrl}
                setAllowCustomUrl={this.setAllowCustomUrl}
                handleCustomUrlInputChange={this.handleCustomUrlInputChange}
                customUrlLabels={this.state.customUrlLabels}
                showAddTargetSiteSection={this.state.showAddTargetSiteSection}
                targetSites={this.state.targetSites}
                handleAddTargetSiteIconClick={this.handleAddTargetSiteIconClick}
                handleDeleteUrlClick={this.handleDeleteUrlClick}
                handleUpdateTargetSiteClick={this.handleUpdateTargetSiteClick}
                showUpdateTargetSiteSection={this.state.showUpdateTargetSiteSection}
                onCancelCobrowseClick={this.onCancelCobrowseClick}
                handleCoBrowseInputChange={this.handleCoBrowseInputChange}
                targetSiteLabel={this.state.targetSiteLabel}
                targetSiteValue={this.state.targetSiteValue}
                cobrowseURLError={this.state.cobrowseURLError}
                handleAddTargetSiteClick={this.handleAddTargetSiteClick}
                customUrlLabelError={this.state.customUrlLabelError}
                targetSiteLabelToEdit={this.state.targetSiteLabelToEdit}
              />
            </>
          }
          {
            this.props.isChatGPTEnabled ?
            <>
              <DropdownItem divider />
              <ChatSettings
                chatSettingsRotation={this.state.chatSettingsRotation}
                handleChatSettingsArrowClick={this.handleChatSettingsArrowClick}  
                isChatSettingsToggler={this.state.isChatSettingsToggler}
                onChangeOrganizationName={this.onChangeOrganizationName}
                onChangeAPIKey={this.onChangeAPIKey}
                onChangeContext={this.onChangeContext}
                chatSettings={this.state.chatSettings} />
            </> : null
          }

          <Row className="permissionButtons w-100">
            {this.renderButtons()}
          </Row>
        </div>
      </div>
    )
  }
}

export default SettingsPage;
