import Auth from '@aws-amplify/auth';

import BaseAction from "CONFIG/BaseAction";
import { leaveSessionAction } from "CONFIG/ActionFactory";
import { changeLanguage } from "CONFIG/i18n";

import { API_URL, ACTIONS } from "UTILS/Constants";
import { HTTP_METHODS } from "UTILS/Constants";
import { getBrowserLanguage } from "UTILS/Utility";

export class LogoutAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  logoutAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: API_URL.LOGOUT
      }).then(
        response => {
          resolve(response);
          leaveSessionAction.clearStore();
          console.log("Logged Out");
          changeLanguage(getBrowserLanguage())
        },
        error => {
          reject(error);
        }
      );
    });
  }
  // async cognitoSignOut(ssoLogoutUrl) {
  //   try {
  //     Auth.signOut().then(
  //       ()=>console.log("Cognito Signout Success")
  //     ).catch(
  //       ()=>console.log("Cognito Signout failure")
  //     );
  //   } catch (error) {
  //     console.log('error signing out: ', error);
  //   }
  //   const response = await fetch(ssoLogoutUrl, {method: "GET"});
  //   console.log('response: ', response);
  //   if (!response.ok) {
  //     throw Error();
  //   }
  //   return new Promise((resolve, reject) => {
  //     Auth.signOut().then(
  //       response => {
  //         console.log("Cognito Signout Success",response)
  //         resolve(response);
  //       },
  //       error => {
  //         console.log("Cognito Signout failure")
  //         reject(error);
  //       }
  //     );
  //   });
  // }

  logoutUserAction(){
    this.dispatch({
      type: ACTIONS.LOGOUT_USER
    });
  }
  
  logoutUser() {
    this.dispatch({
      type: ACTIONS.SET_TOKEN,
      token: null
    });
  }
}
