import React, { useEffect, useState } from "react";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Row, Col, Label, Button, Input } from "reactstrap";

//Components
import Pagination from "react-js-pagination";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";
import HeaderCheckbox from "COMPONENTS/CommonComponents/Checkbox/HeaderCheckbox";

import { getMessage } from "CONFIG/i18n";
import { PAGE_COUNT } from "UTILS/Constants";
import { getStringWithoutExtraSpaces } from "UTILS/Utility";

import { qrCodeAgentsAction} from "CONFIG/ActionFactory";

import "./QRCodeAgents.less";
import "./AssignAgentsToQRCode.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

const AssignAgentsToQRCode = (props) => {
  const [searchRequest, setSearchRequest] = useState({
    searchTimeout: null,
    searchString: "",
  });
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const { searchString, searchTimeout } = searchRequest;

  // Fetch unassigned agents on page load 
  // Maintain page size and page no. on refresh
  useEffect(() => {
    props.getUnassignedAgents(
      props.unassignedAgentsPageSize,
      props.unassignedAgentsPageNo
    );
    // Reset page size and page no. on component unmount so that it starts from initial values next time on component mounts
    return () => {
      qrCodeAgentsAction.saveUnassignedAgentsPageSize(PAGE_COUNT[0].value);
      qrCodeAgentsAction.saveUnassignedAgentsPageNo(0);
    };
  }, []);
  
  // Header checkbox(check all) is selected/deselected
  const handleAllAgentsSelection = (event) => {
    const isSelectAllChecked = event.target.checked;

    setIsSelectAllChecked(isSelectAllChecked);

    // If all records are selected then fetch the list of all records and store those records in selectedAgents state
    // otherwise selectedAgents will contain the number of recordsvisible on the page as per provided page size.
    if(isSelectAllChecked) {
      props.getUnassignedAgents(props.unassignedAgentsCount, 0,  getStringWithoutExtraSpaces(searchString), isSelectAllChecked, (allUnassignedAgents) => {
        setSelectedAgents(allUnassignedAgents);
      });
    } else {
      setSelectedAgents([]);
    }
  }

  // Select a single record
  const handleSingleAgentSelection = (selectedAgentObject, e) => {

    if(e.target.checked) {
      let agents = [...selectedAgents];
      agents.push(selectedAgentObject);

      // If all records are selected then check header checkbox
      if(agents.length === props.unassignedAgentsCount) {
        // Check header checkbox if all rows are selected using respective checkbox
        setIsSelectAllChecked(true);
      }

      setSelectedAgents(agents);
    }else {
      let agents = selectedAgents.filter((agent) => { 
        return agent.id !== selectedAgentObject.id; 
      });

      setSelectedAgents(agents);

      // Uncheck header checkbox if any row is deselected(unchecked)
      setIsSelectAllChecked(false);
    }
  }

  // Check if the record is present in selectedAgents state and check/uncheck respective checkboxes in the table
  const isAgentSelected = (agentObject) => {
    return selectedAgents.find((agent) => {return agentObject.id === agent.id;}) ? true : false;
  };
      
  useEffect(() => {
    // Handled header checkbox on updated of selectedAgents and props.unassignedAgentsCount.
    if (selectedAgents.length >= props.unassignedAgentsCount) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }

  }, [selectedAgents, props.unassignedAgentsCount]); 

  const search = (event) => {
    let searchData = event.target.value;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (searchString !== searchData){
      setSearchRequest({
        searchString: searchData,
        searchTimeout: setTimeout (() => {
          props.getUnassignedAgents(
            props.unassignedAgentsPageSize,
            0,
            getStringWithoutExtraSpaces(searchData),            
          );
        }, 1000),
      }); 
    }
  };

  const getUnassignedAgents = (page) => {
    props.getUnassignedAgents(
      props.unassignedAgentsPageSize,
      page - 1,
      searchString ? searchString : undefined
    );
  };

  // Call container method to add selected agents to QR code
  const addQRCodeAgents = () => {
    props.addQRCodeAgents(selectedAgents);
  };

  const firstNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`firstName${row.id}`}
        >
          {row.firstName}
          <CustomTooltip
            tooltipText={row.firstName}
            tooltipId={`firstName${row.id}`}
          />
        </label>
      </div>
    );
  };

  const lastNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`lastName${row.id}`}
        >
          {row.lastName}
          <CustomTooltip
            tooltipText={row.lastName}
            tooltipId={`lastName${row.id}`}
          />
        </label>
      </div>
    );
  };

  const emailFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`email${row.id}`}
        >
          {row.email}
          <CustomTooltip tooltipText={row.email} tooltipId={`email${row.id}`} />
        </label>
      </div>
    );
  };

  const customMultiSelect = (customMultiSelectProps) => {
    const { rowIndex } = customMultiSelectProps;
    const agent = props.unassignedAgents[rowIndex];
    /*
     * If rowIndex is 'Header', means this rendering is for header selection column.
     */
    if (rowIndex === "Header") {
      return (
        <div>
          <Label className="label noPadding">
            <HeaderCheckbox
              onChange={(event) => handleAllAgentsSelection(event)}
              checked={isSelectAllChecked}
              className={selectedAgents.length > 0 ? selectedAgents.length >=  props.unassignedAgentsCount
                ? "customised-header-checkbox-all-selected"
                : "customised-header-checkbox-multi-selected" : ""}
            />
          </Label>
        </div>
      );
    } else {
      return (
        <div className="checkbox-personalized">
          <Label className="label noPadding">
            <Checkbox
              key={rowIndex}
              onChange={(e) => {handleSingleAgentSelection(agent, e)}}
              // checked={checked} // TODO: Check if customMultiSelectProps.checked can be used
              checked={isAgentSelected(agent)}
            />
          </Label>
        </div>
      );
    }
  };

  const selectRowClass = (row, rowIndex) =>{
    if (rowIndex !== "Header" && isAgentSelected(props.unassignedAgents[rowIndex])) {
      return "selected-bg-color";
    }
    return "";
  };

  const selectRowProp = {
    mode: "checkbox",
    customComponent: customMultiSelect,
    columnWidth: "0.11rem"
  };

  const renderListOfUnassignedAgents = () => {
    return (
      <BootstrapTable
        data={props.unassignedAgents}
        tableHeaderClass="tableHeader"
        tableBodyClass="tableBody"
        containerClass="custom-list col-lg-8"
        trClassName={selectRowClass}
        selectRow={selectRowProp}
        bordered={false}
        printable
        maxHeight="28rem"
      >
        <TableHeaderColumn
          dataField="email"
          width="8"
          dataSort={true}
          isKey={true}
          dataFormat={emailFormat}
        >
          {getMessage("EMAIL")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="firstName"
          dataSort={true}
          width="13"
          className="nameEllipsis"
          headerAlign="center"
          dataAlign="center"
          dataFormat={firstNameFormat}
        >
          {getMessage("FIRST_NAME")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lastName"
          width="13"
          dataSort={true}
          dataFormat={lastNameFormat}
          headerAlign="center"
          dataAlign="center"
        >
          {getMessage("LAST_NAME")}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  };

  const renderUnassignedAgentsHeader = () => {
    return (
      <Row className="w-100 noMargin unassigned-agents-header">
        <div className="seperator-right col-lg-1 pr-3 pl-0">
          <span className="qr-code-label nameEllipsis custom-tooltip" id="qrCodeLabel">
            {props.selectedQRCode.label}
            <CustomTooltip
              tooltipText= {props.selectedQRCode.label}
              tooltipId="qrCodeLabel"
            />
          </span>
        </div>
        <div className="seperator-right">
          <div className="add-agents-label px-3">
            {getMessage("ADD_AGENTS")}
          </div>
        </div>      
        <div className="select-agents-label pl-3">
          {getMessage("SELECT_AGENTS_TO_ASSOCIATE")}
        </div>
        <Row className="d-flex search-box">
          <div className="border-right alignItemsCenter d-flex">
            <div className="total-agents pr-3">
              <span className="total-agents-label">
                {getMessage("TOTAL_AGENTS")} :
              </span>
              <span className="total-agents-count pl-1">
                {props.totalAgents}
              </span>
            </div>
          </div>

          <Col className="pl-3">
            <Input
              className="formControl form-control"
              value={searchString}
              placeholder={getMessage("SEARCH")}
              onChange={search}
            />
          </Col>
        </Row>
      </Row>
    );
  };

  const renderUnassignedAgentsListPagination = () => {
    return (
      <div className="pagination-margin">
        <Row className="paginationRow unassigned-agents-pagination">
          <Col xs={4} className="paginationEntries">
            {getMessage("PAGINATION_TOTAL_ENTRIES", {
              from:
                props.unassignedAgentsPageNo *
                  props.unassignedAgentsPageSize +
                1,
              to:
                props.unassignedAgentsPageNo *
                  props.unassignedAgentsPageSize +
                  props.unassignedAgentsPageSize <
                props.unassignedAgentsCount
                  ? props.unassignedAgentsPageNo *
                      props.unassignedAgentsPageSize +
                    props.unassignedAgentsPageSize
                  : props.unassignedAgentsCount,
              totalCount: props.unassignedAgentsCount,
            })}
          </Col>
          <Col xs={4} className="pagination">
            {props.unassignedAgentsCount >
            props.unassignedAgentsPageSize ? (
                <Pagination
                  activePage={props.unassignedAgentsPageNo + 1}
                  itemsCountPerPage={props.unassignedAgentsPageSize}
                  totalItemsCount={props.unassignedAgentsCount}
                  pageRangeDisplayed={3}
                  onChange={getUnassignedAgents}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              ) : null}
          </Col>
          <Col xs={4} className="pageCount">
            {props.unassignedAgentsCount > PAGE_COUNT[0].value && (
              <CustomDropdown
                options={PAGE_COUNT}
                onChange={(value) => {
                  props.getUnassignedAgents(
                    value,
                    0,
                    searchString ? searchString : undefined
                  );
                }}
                value={props.unassignedAgentsPageSize}
                dropdownClass="pageSizeDropdown"
              />
            )}
          </Col>
        </Row>
      </div>
    );

  }
  const renderSelectedAgentsCount = () => {
    return (
      <>
        <div className="selected-agents-label px-2">
          {getMessage("SELECTED_AGENTS", {
            selectedAgents:  selectedAgents.length,
          })}
        </div>
      </>
    );
  };

  const renderNoDataMessage = () => {
    return (
      <div className="margin-bottom d-flex alignItemsCenter">
        <NoData
          minHeight={"28rem"}
          message={
            searchString
              ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                searchKey: searchString,
              })
              : getMessage("NO_AGENTS_TO_ASSOCIATE")
          }
        />
      </div>
    );
  };

  const renderUnassignedAgentsFooter = () => {
    return (
      <div className="bottom-buttons">
        <Button
          className={`buttons cursorPointer customBtn ${!selectedAgents.length > 0 ? "btn-disabled" : "btn-enabled"}`}
          onClick={!selectedAgents.length > 0 ? false : addQRCodeAgents}
          autoFocus
        >
          {getMessage("ADD")}
        </Button>
        <Button
          className="buttons cursorPointer borderBtn cancel"
          onClick={() => props.handleBack()}
        >
          {getMessage("CANCEL")}
        </Button>
      </div>
    );
  }

  return (
    <div className="qr-code-agents">
      <div className="qr-code-agents-list assign-agents-to-qr-code">
        {renderUnassignedAgentsHeader()}
        <Row className="unassigned-agents-list d-flex">
          <div className="w-100 d-flex justifyContentFlexCenter">
            {props.unassignedAgents && props.unassignedAgents.length > 0 ? (
              <div>
                {renderListOfUnassignedAgents()}
                <div>
                  <div className="padding-top">{renderSelectedAgentsCount()}</div>     
                  <div>{renderUnassignedAgentsListPagination()}</div>
                </div>                 
              </div>
            ) : (
              <div className="w-100">
                {renderNoDataMessage()}
                {renderSelectedAgentsCount()}
              </div>
            )}
          </div>
        </Row>
      </div>
      {renderUnassignedAgentsFooter()}
    </div>
  );
};

export default AssignAgentsToQRCode;