import { USER_ADMISSION_STATUS, PARTICIPANT_STATUS } from "UTILS/Constants";

export const selectUserId = state => {
  return state.UserReducer.userDetails.userId;
};

export const selectSessionStatus = state => {
  return state.SessionReducer.sessionDetails.sessionStatus;
};

export const selectUserRole = state => {
  return state.UserReducer.userDetails.role;
};

export const selectScreenSharingParticipantId = state => {
  return state.RoomMetaDataReducer.roomMetaData.screenSharingParticipantId;
};

export const getClassOfHeaderIcon = (state, moduleName) => {
  return state.SessionReducer.headerIcons[moduleName].class;
};

export const getShowAudioVideoComponentFlag = (state) => {
  return state.SessionReducer.showAudioVideoComponent;
};

export const selectPresenterId = state =>
  state.RoomMetaDataReducer.roomMetaData.presenterId;

export const selectUserAdmissionStatus = state => {
  return state.UserReducer.userDetails.admissionStatus;
};

export const getModules = state => {
  // Fixed WAAG-1010 - Sometimes upload PDF buttons disappears at Agents end by referring modules from localstorage
  return ((state.SessionReducer.sessionDetails && state.SessionReducer.sessionDetails.modules && state.SessionReducer.sessionDetails.modules.length && state.SessionReducer.sessionDetails.modules)
    || (state.UserReducer.userDetails && state.UserReducer.userDetails.modules)
    || (JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)) && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).modules));
};

