
import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";
import { Row, Col, Button} from "react-bootstrap";
import { CONFIG } from "UTILS/Constants";

import "./Logout.less";
import logo from "../../Assets/Images/img_signin_bg_logo.png";

class Logout extends Component {
  handleLoginClick=()=>{
    this.props.history.push(CONFIG.path.login);
  }
  render() {
    return (
      <div className="container-fluid Logout">
        <Col md={5} xs={12} lg={5} xl={5} className="LogoutContainer">
          <Row>
            <Col md={12} className="text-center">
              <img className="img-responsive LogoutLogo" src={logo} />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <label className="LogoutLabel"> {getMessage("LOGOUT_SUCCESS_MESSAGE")}</label>
              <Col md={12} className="text-center">
                <Button
                  className="customBtn btnLogout"
                  onClick={()=>this.handleLoginClick()}
                >
                  {getMessage("BACK_TO_LOGIN")}
                </Button>

              </Col>
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}
export default Logout;
    