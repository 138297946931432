import { ACTIONS, ADMIN_DASHBOARD_TABS } from "UTILS/Constants";

const initialState = {
  currentActiveTab: ADMIN_DASHBOARD_TABS.USERS,
  sessionKey: null,
  sessionListPageSize: 10,
  sessionListCurrentPageNo: 0,
  userListPageSize: 10,
  userListCurrentPageNo: 0,
  selectedReport: null,
  sessionFollowUpsCurrentPageNo: 0,
  sessionFollowUpsPageSize: 10
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_SESSIONS_COUNT:
      return {
        ...state,
        sessionsCount: action.sessionsCount,
      };
    case ACTIONS.ADMIN_ACTION.SET_ACTIVE_TAB:
      return{
        ...state,
        currentActiveTab:action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_SESSION_KEY:
      return{
        ...state,
        sessionKey:action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_SESSION_LIST_PAGE_SIZE:
      return{
        ...state,
        sessionListPageSize:action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_SESSION_LIST_CURRENT_PAGE_NO:
      return{
        ...state,
        sessionListCurrentPageNo:action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_USER_LIST_PAGE_SIZE:
      return{
        ...state,
        userListPageSize:action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_USER_LIST_CURRENT_PAGE_NO:
      return{
        ...state,
        userListCurrentPageNo:action.value
      };  
    case ACTIONS.SET_USERS_COUNT:
      return {
        ...state,
        usersCount: action.usersCount,
      };
    case ACTIONS.ADMIN_ACTION.SET_SESSION_FOLLOW_UPS_COUNT:
      return {
        ...state,
        sessionFollowUpsCount: action.sessionFollowUpsCount,
      };
    case ACTIONS.ADMIN_ACTION.SELECT_REPORT:
      return {
        ...state,
        selectedReport: action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_SESSION_FOLLOW_UPS_CURRENT_PAGE_NO:
      return {
        ...state,
        sessionFollowUpsCurrentPageNo: action.value
      };
    case ACTIONS.ADMIN_ACTION.SAVE_SESSION_FOLLOW_UPS_PAGE_SIZE:
      return {
        ...state,
        sessionFollowUpsPageSize: action.value
      };
    case ACTIONS.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }}
