import { socketMessage } from "WEBSOCKET/constants";
import * as log from "loglevel";
import { ACTIONS } from "UTILS/Constants";

log.setLevel("info");

export const handleMessage = (data, store) => {
  switch (data.action) {
    case socketMessage.subCategories.NOTIFICATION._MAKE_PRESENTER:
      //to enable pdf toolbar
      const reducerData = store.getState();
      let canvas = reducerData.WhiteboardReducer.canvases[
      reducerData.WhiteboardReducer.activeCanvas.canvasId];
      store.dispatch({
        type: ACTIONS.MAKE_PRESENTER,
        newPresenterId: data.newPresenterId
      });

      // console.log("..................... _MAKE_PRESENTER calling RESET_CO_BROWSE_SESSION_DETAILS")
      store.dispatch({
        type: ACTIONS.CO_BROWSE.RESET_CO_BROWSE_SESSION_DETAILS,
      });
      break;
    case socketMessage.subCategories.NOTIFICATION._ADD_ANNOTATION:
      let addAnnotationDetails={}
      addAnnotationDetails.isSelected=true
      addAnnotationDetails.annotation=data.annotation
      store.dispatch({
        type: ACTIONS.NOTIFICATION.ADD_ANNOTATION,
        activeAnnotationDetails: addAnnotationDetails
      });
      break;
    case socketMessage.subCategories.NOTIFICATION._REMOVE_ANNOTATION:
      let removeAnnotationDetails={}
      removeAnnotationDetails.isSelected=false
      removeAnnotationDetails.annotation=""
      store.dispatch({
        type: ACTIONS.NOTIFICATION.REMOVE_ANNOTATION,
        activeAnnotationDetails: removeAnnotationDetails
      });
      break;
  }
};
