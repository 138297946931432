import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { getMessage } from "CONFIG/i18n";
import { getStringWithoutExtraSpaces } from "../../../Utils/Utility";

import './AddEditQRCodeModal.less'
import { INFO_FIELD_MAX_LENGTH, KEY_CODE_ENTER } from "UTILS/Constants";

const EditQRCodeModal = (props) => {
  const [qrCodeData, setQRCodeData] = useState({
    label: '',
    description: ''
  })
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState(false)

  const handleInputChange = (event) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    const newQRCodeData = { ...qrCodeData };
    newQRCodeData[event.target.name] = event.target.value
    setQRCodeData(newQRCodeData)
  };

  const setFocus = () => {
    document.getElementById("edit-qr-label").focus();
  }

  const isValidData = () => {
    if (qrCodeData.label.trim() == '') {
      setErrorMessage(getMessage("INVALID_QR_LABEL"));
      return false;
    }
    if (qrCodeData.description.trim() == '') {
      setErrorMessage(getMessage("INVALID_DESCRIPTION"));
      return false;
    }
    return true;
  };

  const handleUpdateClick = (e) => {
    // preventing the page from reloading on form submit
    e.preventDefault();
    const oldDescription = props.qrCodeDetails.description ? props.qrCodeDetails.description :'';
    if (props.qrCodeDetails.label !== qrCodeData.label || oldDescription !== qrCodeData.description) {
      if (isValidData()) {
        setIsUpdateBtnDisabled(true)
        const qrCodeDetails = {
          label: getStringWithoutExtraSpaces(qrCodeData.label),
          description: getStringWithoutExtraSpaces(qrCodeData.description),
          qrId: props.qrCodeDetails.qrId
        }
        props.updateQRCode(qrCodeDetails, error => {
          if (error) {
            setErrorMessage(error)
          }
          else {
            props.onClosed()
          }
          setIsUpdateBtnDisabled(false)
        });
      }
    }
    else{
      props.onClosed()
    }
  };

  useEffect(() => {
    setQRCodeData({
      label: props.qrCodeDetails.label,
      description: props.qrCodeDetails.description ? props.qrCodeDetails.description : ''
    })
  }, [props.qrCodeDetails])

  const renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={() => {
            setErrorMessage('')
            props.onClosed();
          }}
          disable={isUpdateBtnDisabled}
          onOpened={setFocus}
        >
          {getMessage("CANCEL")}
        </Button>{" "}
        <Button
          onClick={(e) => handleUpdateClick(e)}
          disable={isUpdateBtnDisabled}
          className="buttons customBtn">
          {getMessage("SAVE")}
        </Button>
      </div>
    );
  };

  const enterPressed = (event) => {
    //check if user pressed enter key
    const code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      handleUpdateClick(event);
    }
  }

  return (
    <Modal
      isOpen={props.show}
      className="addOrganisationModal userAddModal add-edit-qr-code"
      onClosed={() => props.onClosed()}
      onOpened={setFocus}
    >
      <ModalHeader className="popupHeader">
        {getMessage("EDIT_QR_DETAILS")}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("QR_LABEL")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="text"
              name="label"
              id="edit-qr-label"
              className="inputFormControl"
              maxLength="25"
              placeholder={getMessage("QR_LABEL_PLACEHOLDER")}
              onChange={(e) => handleInputChange(e)}
              value={qrCodeData.label}
              onKeyDown={(e) => enterPressed(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label className="label modal-label">
              {getMessage("DESCRIPTION")}
              <span className="asterisk">*</span>
            </Label>
            <Input
              type="textarea"
              name="description"
              id='edit-qr-description'
              className="inputFormControl"
              maxLength={INFO_FIELD_MAX_LENGTH}
              placeholder={getMessage("QR_DESCRIPTION_PLACEHOLDER")}
              onChange={(e) => handleInputChange(e)}
              value={qrCodeData.description}
              rows={4}
              onKeyDown={(e) => enterPressed(e)}
            />
          </FormGroup>
          {
            errorMessage &&
            <div className="errors">{errorMessage}</div>
          }
        </Form>
      </ModalBody>
      <ModalFooter className="permissionButtons">
        {renderButtons()}
      </ModalFooter>
    </Modal>
  );
}

export default modalScrollBar(EditQRCodeModal);