import React, { Component } from "react";

import { isSafari } from "react-device-detect";

import { getMessage } from "CONFIG/i18n";

import {enumerateStats} from "../../Utils/Utility";

import './TestPage.less';

export default class BaseTest extends Component {
  constructor(props) {
    super(props)

    // Store the ICE server response from the network traversal server.
    this.cachedIceServers_ = null;
    // Keep track of when the request was made.
    this.cachedIceConfigFetchTime_ = null;
    this.errorCount = 0;
    this.warningCount = 0;
    this.successCount = 0;
    this.output = "";
    this.state = {
      testFinalStatus: "--"
    }
  }

  resetValues = () => {
    this.cachedIceServers_ = null;
    // Keep track of when the request was made.
    this.cachedIceConfigFetchTime_ = null;
    this.errorCount = 0;
    this.warningCount = 0;
    this.successCount = 0;
    this.output = "";
    this.mic = "";
    this.camera = "";
    this.setState({
      testFinalStatus: "--",
      showOutput: false
    })
  }

  test = (test) => {
    this.test = test;
    // this.test.setState({
    //   testState: "start"
    // })
  }

  reportFatal = (str) => {
    this.reportError(str);
    this.done();
  }

  reportError = (str) => {
    if(str)
      this.output = this.output +"[FAILED] "+str+ "\n"
    this.errorCount++;
    // this.test.setState({
    //   testState: "error"
    // })
  }

  reportWarning = (str) => {
    if(str)
      this.output = this.output +"[WARN] "+str+ "\n"
    this.warningCount++;
    // this.test.setState({
    //   testState: "warning"
    // })
  }

  reportInfo = (str) => {
    if(str)
      this.output = this.output +"[INFO] "+str+ "\n"
  }

  reportSuccess = (str) => {
    if(str)
      this.output = this.output +"[OK] "+str+ "\n"
    this.successCount++;
    // this.test.setState({
    //   testState: "success"
    // })
  }

  getStatus = () => {
    let color = this.state.testFinalStatus == "Success" ?
      "green":this.state.testFinalStatus == "Failure"?
        "red":"orange"
    return <div style={{color: color}}>
      {getMessage(this.state.testFinalStatus)}
    </div>
  }

  getOutput = () => {
    return this.output;
  }

  done = () => {
    // this.setProgress(null);
    var success =
      (this.errorCount + this.warningCount == 0 && this.successCount > 0);

    if (success) {
      this.setState({
        testFinalStatus: 'Success'
      });
    } else if (this.warningCount > 0 && this.errorCount === 0) {
      this.setState({
        testFinalStatus: 'Warning'
      });
    } else if (this.isDisabled) {
      this.setState({
        testFinalStatus: 'Disabled'
      });
    } else {
      this.setState({
        testFinalStatus: 'Failure'
      });
    }
    if(this.props.next) {
      this.props.next()
    }
  }

  doGetUserMedia = (constraints, onSuccess, onFail) => {
    console.log('do get user media');
    var self = this;
    // var traceGumEvent = report.traceEventAsync('getusermedia');

    try {
      // Append the constraints with the getSource constraints.
      // this.appendSourceId(this.$.audioSource.value, 'audio', constraints);
      // this.appendSourceId(this.$.videoSource.value, 'video', constraints);

      // traceGumEvent({'status': 'pending', 'constraints': constraints});
      // Call into getUserMedia via the polyfill (adapter.js).
      navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
        console.log('get user media success');
        self.mic = self.getDeviceName_(stream.getAudioTracks());
        self.props.setMicSelected &&
        self.props.setMicSelected(self.mic)
        // traceGumEvent({'status': 'success', 'camera': cam,
        //   'microphone': mic});
        onSuccess(stream);
      }).catch(function(error) {
        console.error('get user media error', error);
        // traceGumEvent({'status': 'fail', 'error': error});
        if (onFail) {
          onFail(error);
        } else {
          self.reportFatal('Failed to get access to local media due to ' +
              'error: ' + error);
          console.error('Failed to get access to local media due to ' +
              'error: ' + error);
          // this.test.setState({
          //   testState: "fail"
          // })
        }
      });
    } catch (e) {
      console.log(e);
      // traceGumEvent({'status': 'exception', 'error': e.message});
      // return 
      self.reportFatal('getUserMedia failed with exception: ' +
          e.message);
      // this.test.setState({
      //   testState: "fail"
      // })
      console.error('getUserMedia failed with exception: ' +
          e.message);
    }
  }

  getDeviceName_(tracks) {
    if (tracks.length === 0) {
      return null;
    }
    return tracks[0].label;
  }

  initialize(config, test) {
    this.test = test;
    // this.traceEvent = report.traceEventAsync('call');
    // this.traceEvent({config: config});
    this.statsGatheringRunning = false;
    try {
      this.pc1 = new RTCPeerConnection(config);
      this.pc2 = new RTCPeerConnection(config);
  
      this.pc1.addEventListener('icecandidate', this.onIceCandidate_.bind(this,
        this.pc2));
      this.pc2.addEventListener('icecandidate', this.onIceCandidate_.bind(this,
        this.pc1));
  
      this.iceCandidateFilter_ = BaseTest.noFilter;
    } catch(error) {
      console.error('error in BaseTest: ', error.message);
      this.reportError('Failed to create peer connection: ' + error);
      this.done();
    }
  }

  establishConnection() {
    // this.traceEvent({state: 'start'});
    this.pc1.createOffer().then(
      this.gotOffer_.bind(this),
      () => {
        this.reportFatal();
        console.error('Test failed');
        // this.test.setState({
        //   testState: "fail"
        // })
      }
    );
  }

  close() {
    // this.traceEvent({state: 'end'});
    this.pc1 && this.pc1.close();
    this.pc2 && this.pc2.close();
  }

  setIceCandidateFilter(filter) {
    this.iceCandidateFilter_ = filter;
  }

  // Constraint max video bitrate by modifying the SDP when creating an answer.
  constrainVideoBitrate(maxVideoBitrateKbps) {
    this.constrainVideoBitrateKbps_ = maxVideoBitrateKbps;
  }

  // Remove video FEC if available on the offer.
  disableVideoFec() {
    this.constrainOfferToRemoveVideoFec_ = true;
  }

  // When the peerConnection is closed the statsCb is called once with an array
  // of gathered stats.
  gatherStats(peerConnection,peerConnection2, localStream, statsCb) {
    var stats = [];
    var stats2 = [];
    var statsCollectTime = [];
    var statsCollectTime2 = [];
    var self = this;
    var statStepMs = 100;
    self.localTrackIds = {
      audio: '',
      video: ''
    };
    self.remoteTrackIds = {
      audio: '',
      video: ''
    };

    peerConnection.getSenders().forEach(function(sender) {
      if (sender.track.kind === 'audio') {
        self.localTrackIds.audio = sender.track.id;
      } else if (sender.track.kind === 'video') {
        self.localTrackIds.video = sender.track.id;
      }
    }.bind(self));

    if (peerConnection2) {
      peerConnection2.getReceivers().forEach(function(receiver) {
        if (receiver.track.kind === 'audio') {
          self.remoteTrackIds.audio = receiver.track.id;
        } else if (receiver.track.kind === 'video') {
          self.remoteTrackIds.video = receiver.track.id;
        }
      }.bind(self));
    }

    self.statsGatheringRunning = true;
    getStats_();

    function getStats_() {
      if (peerConnection.signalingState === 'closed' || peerConnection.connectionState == 'closed') {
        self.statsGatheringRunning = false;
        statsCb(stats, statsCollectTime, stats2, statsCollectTime2);
        return;
      }
      peerConnection.getStats()
      .then(gotStats_)
      .catch(function(error) {
        self.reportError('Could not gather stats: ' + error);
        console.error('Could not gather stats: ' + error);
        self.statsGatheringRunning = false;
        statsCb(stats, statsCollectTime);
      }.bind(self));
      if (peerConnection2) {
        peerConnection2.getStats()
        .then(gotStats2_);
      }
    }
    // Stats for pc2, some stats are only available on the receiving end of a
    // peerconnection.
    function gotStats2_(response) {
      if (adapter.browserDetails.browser === 'chrome' || isSafari) {
        var enumeratedStats = enumerateStats(response, self.localTrackIds,
          self.remoteTrackIds);
        stats2.push(enumeratedStats);
        statsCollectTime2.push(Date.now());
      } else if (adapter.browserDetails.browser === 'firefox') {
        for (var h in response) {
          var stat = response[h];
          stats2.push(stat);
          statsCollectTime2.push(Date.now());
        }
      } else {
        self.reportError('Only Firefox and Chrome getStats ' +
            'implementations are supported.');
        console.error('Only Firefox and Chrome getStats ' +
            'implementations are supported.');
      }
    }

    function gotStats_(response) {
      // TODO: Remove browser specific stats gathering hack once adapter.js or
      // browsers converge on a standard.
      if (adapter.browserDetails.browser === 'chrome' || isSafari) {
        var enumeratedStats = enumerateStats(response, self.localTrackIds,
          self.remoteTrackIds);
        stats.push(enumeratedStats);
        statsCollectTime.push(Date.now());
      } else if (adapter.browserDetails.browser === 'firefox') {
        for (var j in response) {
          var stat = response[j];
          stats.push(stat);
          statsCollectTime.push(Date.now());
        }
      } else {
        self.reportError('Only Firefox and Chrome getStats ' +
            'implementations are supported.');
        console.error('Only Firefox and Chrome getStats ' +
            'implementations are supported.');
      }
      setTimeout(getStats_, statStepMs);
    }
  }

  gotOffer_(offer) {
    if (this.constrainOfferToRemoveVideoFec_) {
      offer.sdp = offer.sdp.replace(/(m=video 1 [^\r]+)(116 117)(\r\n)/g,
        '$1\r\n');
      offer.sdp = offer.sdp.replace(/a=rtpmap:116 red\/90000\r\n/g, '');
      offer.sdp = offer.sdp.replace(/a=rtpmap:117 ulpfec\/90000\r\n/g, '');
      offer.sdp = offer.sdp.replace(/a=rtpmap:98 rtx\/90000\r\n/g, '');
      offer.sdp = offer.sdp.replace(/a=fmtp:98 apt=116\r\n/g, '');
    }
    this.pc1.setLocalDescription(offer);
    this.pc2.setRemoteDescription(offer);
    this.pc2.createAnswer().then(
      this.gotAnswer_.bind(this),
      ()=>{
        this.reportFatal(this.test)
        // this.test.setState({
        //   testState: "fail"
        // })
        console.error('Mic test failed');
      }
    );
  }

  gotAnswer_(answer) {
    if (this.constrainVideoBitrateKbps_) {
      answer.sdp = answer.sdp.replace(
        /a=mid:video\r\n/g,
        'a=mid:video\r\nb=AS:' + this.constrainVideoBitrateKbps_ + '\r\n');
    }
    this.pc2.setLocalDescription(answer);
    this.pc1.setRemoteDescription(answer);
  }

  onIceCandidate_(otherPeer, event) {
    if (event.candidate) {
      var parsed = this.parseCandidate(event.candidate.candidate);
      if (this.iceCandidateFilter_(parsed)) {
        otherPeer.addIceCandidate(event.candidate);
      }
    }
  }
  
  // Parse a 'candidate:' line into a JSON object.
  parseCandidate(text) {
    var candidateStr = 'candidate:';
    var pos = text.indexOf(candidateStr) + candidateStr.length;
    var fields = text.substr(pos).split(' ');
    return {
      'type': fields[7],
      'protocol': fields[2],
      'address': fields[4]
    };
  };
  
  // Get a TURN config, either from settings or from network traversal server.
  asyncCreateTurnConfig = function(onSuccess, onError) {
    var settings = this.props.settings;
    if (typeof(settings.turnURI) === 'string' && settings.turnURI !== '') {
      var iceServer = {
        'username': settings.turnUsername || '',
        'credential': settings.turnCredential || '',
        'urls': [`turn:${settings.turnURI}`]
      };
      var config = {'iceServers': [iceServer]};
      // report.traceEventInstant('turn-config', config);
      setTimeout(onSuccess.bind(null, config), 0);
    } else {
      this.fetchTurnConfig_(function(response) {
        var config = {'iceServers': response.iceServers};
        // report.traceEventInstant('turn-config', config);
        onSuccess(config);
      }, onError);
    }
  };
  
  // Get a STUN config, either from settings or from network traversal server.
  asyncCreateStunConfig(onSuccess, onError) {
    var settings = this.props.settings;
    if (typeof(settings.stunURI) === 'string' && settings.stunURI !== '') {
      var iceServer = {
        'urls': settings.stunURI.split(',')
      };
      var config = {'iceServers': [iceServer]};
      // report.traceEventInstant('stun-config', config);
      setTimeout(onSuccess.bind(null, config), 0);
    } else {
      this.fetchTurnConfig_(function(response) {
        var config = {'iceServers': response.iceServers.urls};
        // report.traceEventInstant('stun-config', config);
        onSuccess(config);
      }, onError);
    }
  };
  
  // Ask network traversal API to give us TURN server credentials and URLs.
  fetchTurnConfig_ = (onSuccess, onError) => {
    // Check if credentials exist or have expired (and subtract testRuntTIme so
    // that the test can finish if near the end of the lifetime duration).
    // lifetimeDuration is in seconds.
    var testRunTime = 240; // Time in seconds to allow a test run to complete.
    if (this.cachedIceServers_) {
      var isCachedIceConfigExpired =
        ((Date.now() - cachedIceConfigFetchTime_) / 1000 >
        parseInt(this.cachedIceServers_.lifetimeDuration) - testRunTime);
      if (!isCachedIceConfigExpired) {
        // report.traceEventInstant('fetch-ice-config', 'Using cached credentials.');
        onSuccess(this.getCachedIceCredentials_());
        return;
      }
    }
  
    var xhr = new XMLHttpRequest();
    function onResult() {
      if (xhr.readyState !== 4) {
        return;
      }
  
      if (xhr.status !== 200) {
        onError('TURN request failed');
        return;
      }
  
      var response = JSON.parse(xhr.responseText);
      this.cachedIceServers_ = response;
      this.getCachedIceCredentials_ = function() {
        // Make a new object due to tests modifying the original response object.
        return JSON.parse(JSON.stringify(this.cachedIceServers_));
      };
      this.cachedIceConfigFetchTime_ = Date.now();
      // report.traceEventInstant('fetch-ice-config', 'Fetching new credentials.');
      onSuccess(this.getCachedIceCredentials_());
    }
  
    xhr.onreadystatechange = onResult;
    // API_KEY and TURN_URL is replaced with API_KEY environment variable via
    // Gruntfile.js during build time by uglifyJS.
    xhr.open('POST', TURN_URL + API_KEY, true);
    xhr.send();
  };  
}

BaseTest.isIpv6 = function(candidate) {
  return candidate.address.indexOf(':') !== -1;
}

BaseTest.noFilter = function() {
  return true;
};

BaseTest.isRelay = function(candidate) {
  return candidate.type === 'relay';
};

BaseTest.isNotHostCandidate = function(candidate) {
  return candidate.type !== 'host';
};

BaseTest.isReflexive = function(candidate) {
  return candidate.type === 'srflx';
};

BaseTest.isHost = function(candidate) {
  return candidate.type === 'host';
};
