import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getMessage } from "CONFIG/i18n";
import moment from 'moment';
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Spinner from "COMPONENTS/Spinner/Spinner";
import ScheduleSessionTable from "./ScheduleSessionTable";

//Actions
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";
import { sessionActions, organisationAction } from "CONFIG/ActionFactory";

//constants
import { CONFIG } from "UTILS/Constants";
import { ERROR } from "../../../Utils/Constants";
import { toSafeInteger } from "lodash";

export class ScheduleSessionTableContainer extends Component {

  constructor(props) {
    super(props);  
    this.SCHEDULE_SESSION_PAGE_SIZE = 10;
    this.state={
      sessionCount: 0,
      spinnerVisibility: false,
      showCloseMeetingConfirmationPopUp: false,
    }
  }
  
  componentDidMount() {
    this.getSessionList();
  }

  cancelMeeting = (sessionKey, successCallback, errorCallback) => {
    this.showHideSpinner(true);
    sessionActions.cancelSessionAction(sessionKey, this.props.language).then(
      (response) => {
        this.showHideSpinner(false)
        successCallback();
      },
      (error) => {
        this.showHideSpinner(false)
        error.code === ERROR.INVALID_SESSION ?
          errorCallback("SESSION_IS_CANCELLED") :
          toastr.error(error.code);
      }
    )
  }

  getSessionList = (fromDate, toDate, pageParam, limitParam, callback) => {
    let from = fromDate ? moment(fromDate).startOf('day').valueOf() : moment().startOf('day').valueOf();
    let to = toDate ? moment(toDate).startOf('day').endOf('day').valueOf() : moment().add(1,'days').startOf('day').endOf('day').valueOf();
    let page = pageParam || 0;
    let limit = limitParam || this.SCHEDULE_SESSION_PAGE_SIZE;
    this.showHideSpinner(true);

    sessionActions.getSessionListActions({
      from, to, page, limit 
    }).then((response)=>{
      this.setState({sessionCount: response.data.data.count});
      this.showHideSpinner(false);

      // callback method parameter is isError which is set to false as the response is success
      callback && callback(false);
    },
    (error)=>{
      this.showHideSpinner(false);
      toastr.error(error.code);

      // callback method parameter is isError which is set to true as the response is failure
      callback && callback(true);
    });
  }

  joinSession=(sessionKey, uriIdentifier, errorCallback, closeActiveSession)=>{
    this.showHideSpinner(true)
    sessionActions.joinSessionAction(sessionKey, uriIdentifier, "", false, "", closeActiveSession).then(
      response => {
        organisationAction.setLogoUrl(response.data.data.logoUrl);
        this.props.history.push(CONFIG.path.dashboard);
      },
      error => {
        this.showHideSpinner(false);
        if (error.code == ERROR.ACTIVE_CLOSING_SESSION_ALREADY_EXISTS) {
          this.showHideCloseMeetingPopup(true);
        } else {
          error.code === ERROR.INVALID_SESSION ?
            errorCallback("SESSION_IS_CANCELLED") :
            toastr.error(error.code);
        }
      }
    );
  }

  editSessionRequest = (editSessionData) => {
    sessionActions.editSessionAction(editSessionData);
    this.props.history.push(CONFIG.path.scheduleMeeting)
  }

  showHideSpinner = showSpinner => {
    this.setState({
      spinnerVisibility: showSpinner
    })
  }

  showHideCloseMeetingPopup = (value) =>{
    this.setState({ showCloseMeetingConfirmationPopUp: value });
  }
  
  render() {
    return (
      <>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        {
          this.props.sessionsList &&
        <ScheduleSessionTable 
          scheduleDataList={this.props.sessionsList}
          cancelMeeting={this.cancelMeeting}
          joinSession={this.joinSession}
          editSessionRequest={this.editSessionRequest}
          language={this.props.language}
          getSessionList={this.getSessionList}
          sessionCount={this.state.sessionCount}
          customerUriIdentifier={this.props.customerUriIdentifier}
          uriIdentifier={this.props.uriIdentifier}
          showCloseMeetingConfirmationPopUp={this.state.showCloseMeetingConfirmationPopUp}
          showHideCloseMeetingPopup={this.showHideCloseMeetingPopup}    
          modules={this.props.modules}   
        />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    sessionsList: state.SessionReducer.sessionsList,
    customerUriIdentifier: state.OrganisationReducer.customerData.uri_identifier,
    uriIdentifier: state.UserReducer.uriIdentifier
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLanguageAction
    },
    dispatch
  ); 
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScheduleSessionTableContainer)
);
