import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";

import logo from "../../Assets/Images/img_signin_bg_logo.png";

import "./style.less"
export default class PageNotFound extends Component {
  render() {
    return (
      <div className="pagenotfound">
        <div className="logoImage">
          <img src={logo} />
        </div>
        <div className="wrap">
          <div className="logo">
            <h2>{getMessage("404")}</h2>
            <p> {getMessage("PAGE_NOT_FOUND")}</p>
            <div className="sub">
              <p><a href="/">{getMessage("BACK_TO_SIGN_IN")}</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}