import { ACTIONS, PAGE_COUNT } from "UTILS/Constants";

const initialState = {
  qrCodeAgentsPageSize: PAGE_COUNT[0].value,
  qrCodeAgentsPageNo: 0,
  qrCodeAgentsCount: 0,
  unassignedAgentsCount: 0,
  unassignedAgentsPageNo: 0,
  unassignedAgentsPageSize: PAGE_COUNT[0].value
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_PAGE_SIZE:
      return { ...state, qrCodeAgentsPageSize: action.value };

    case ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_PAGE_NO:
      return { ...state, qrCodeAgentsPageNo: action.value };

    case ACTIONS.QR_CODES.SAVE_QR_CODE_AGENTS_COUNT:
      return { ...state, qrCodeAgentsCount: action.value };

    case ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_PAGE_SIZE:
      return { ...state, unassignedAgentsPageSize: action.value };
      
    case ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_PAGE_NO:
      return { ...state, unassignedAgentsPageNo: action.value };
      
    case ACTIONS.QR_CODES.SAVE_UNASSIGNED_AGENTS_COUNT:
      return { ...state, unassignedAgentsCount: action.value };

    default:
      return state;
  }
}