import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import { getMessage } from "CONFIG/i18n";

//websoket
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";

//components
import Header from "./Header";
import Spinner from "COMPONENTS/Spinner/Spinner";

//SELECTORS
import { selectSessionTitle } from "COMPONENTS/Sessions/selectors";
import { isMessageArrived } from "COMPONENTS/Chat/selectors";
import { selectSessionKey, selectUserToken, selectEndUserToken } from "COMPONENTS/MainLayout/selectors";
import {
  selectCurrentParticipantUniqueId,
  selectSnapshoteeId,
  selectSnapshoterId,
  selectSnapshotPermissionReply,
  selectIsSnapshotTaken,
  selectOrganiser,
  selectShowSnapshot,
  selectIsSnapshotStarted
} from "REDUX/Selector/SessionSelector";
import {
  selectUserRole,
  selectParticipantId,
  selectActiveCanvas,
} from "COMPONENTS/Whiteboard/selectors";
import {
  selectETA,
  getLocationParticipants,
  selectLocationPermissionReply,
  selectLocationPermissionReplyStatus
} from "COMPONENTS/Location/selectors";
import { selectSessionStatus, getShowAudioVideoComponentFlag } from "COMPONENTS/Dashboard/selectors";
import {
  getAppEmulationPermission,
  getAppEmulationPermissionReply,
  getparticipantLeftUniqueId,
  getparticipantJoinedUniqueId,
  getisSessionEnded,
  getparticipantDeniedUniqueId
} from "COMPONENTS/AppEmulation/AppEmulationSelector";
import {
  getCoBrowsePermission,
  getCoBrowsePermissionReply,
  getCoBrowseJoinedParticipantUniqueId,
  getParticipantLeftCoBrowseUniqueId,
  getCoBrowseSessionStatus,
  getParticipantDeniedCoBrowseUniqueId,
  getShowCoBrowse
} from "COMPONENTS/CoBrowse/CoBrowseSelector"
import {
  getModules,
  getAdmittedParticipants,
  getAllParticipants,
  getWebRtcPermissionRequest,
  getWebRtcPermissionReply,
  getHeaderIcons,
  selectLatestUpdatedParticipant,
  getEvictedParticipantPermissionRequest,
  selectWebRtcPermissionReplyStatus,
  selectSessionEndedFlag,
  selectPresenter,
  selectLocation,
  selectLocationSharing,
  getKmsStatus,
  getShowKmsStatus,
  getCallStatus,
  getIsOrganizer,
  getSnapshotCameraPermissionflag,
  getSnapshotCameraBusyflag,
  selectUserName,
  getCustomerId,
  getEvictedParticipantDetails,
  userSessionName,
  getMediaDeviceDeniedDetails
} from "./selectors";
import {
  getCustomerCoBrowsePermissionRequest,
  getCustomerCoBrowsePermissionReply,
  selectCoBrowseType,
  getShowCustomerCoBrowse,
  getRoomId,
  getCustomerCoBrowseStarted
} from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseSelector"

//ACTIONS
import {
  addLocationParticipantId,
  updateLocationPermissionReply,
  stopLocationShare
} from "COMPONENTS/Location/LocationAction";
import { setToken } from "COMPONENTS/UserJoin/UserJoinAction";
import { leaveSessionAction, sessionActions, headerAction, appEmulationAction, audioVideoAction } from "CONFIG/ActionFactory";
import {
  displayMessage,
  setMessagesRead,
  setChatPopOverStateAction
} from "COMPONENTS/Chat/ChatAction";

// UTILS
import { CONFIG, ERROR, } from "UTILS/Constants";
import { mediaStatus, mediaType } from "WEBSOCKET/constants";
import { removeTrailingSlash, postMesageToCoBrowseIframeUtility, isRealwearDevice } from "UTILS/Utility";
import { selectCoBrowseBaseUrl, getEndCustomerCBSession } from "../../CustomerCoBrowse/CustomerCoBrowseSelector";
import { customerCoBrowseAction } from "CONFIG/ActionFactory";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";

export class HeaderContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      spinnerVisibility: false,
      isRealwearEndCall: false,
    }
  }
  handleEndSession = () => {
    this.setSpinnerVisibility(true)
    //Post End_session_without_confirmation to iframe on end loookit session
    if (this.props.showCustomerCoBrowse) {
      postMesageToCoBrowseIframeUtility(this.props.endUserToken, this.props.coBrowseBaseUrl);
      console.log("Post End_session_without_confirmation on end loookit session")
      setTimeout(() => {
        customerCoBrowseAction.endCustomerCoBrowse()
      }, 500);//wait untill message gets posted to CB server(iframe) properly then close CB container
    }

    leaveSessionAction.endSessionAndCleanUp(this.props.sessionKey).then(
      data => {
        // do this in _END_SESSION
        // this.props.history.push(CONFIG.path.createSession);
      },
      err => {
        this.setSpinnerVisibility(false)
        toastr.error(err.code);
      }
    );
  };

  handleLeaveSession = () => {
    let isPasscodeSession = this.props.passcode;
    const isRealwear = isRealwearDevice()
    //Post End_session_without_confirmation to iframe on leave loookit session
    if (this.props.showCustomerCoBrowse) {
      postMesageToCoBrowseIframeUtility(this.props.endUserToken, this.props.coBrowseBaseUrl);
      console.log("Post End_session_without_confirmation on leave loookit session")
      setTimeout(() => {
        customerCoBrowseAction.leaveCustomerCoBrowse(this.props.uniqueId)
        isRealwear ? this.setState({ isRealwearEndCall: true }) :
          isPasscodeSession ?
            this.props.history.push(CONFIG.path.passcode)
            :
            this.props.history.push(
              `${CONFIG.path.userJoin}?sessionKey=${this.props.sessionKey}`
            );
      }, 500);//wait untill message gets posted to CB server(iframe) properly then close CB container
    } else {
      isRealwear ? this.setState({ isRealwearEndCall: true }) :
        isPasscodeSession ?
          this.props.history.push(CONFIG.path.passcode)
          :
          this.props.history.push(
            `${CONFIG.path.userJoin}?sessionKey=${this.props.sessionKey}`
          );
    }
  };

  evictAction = (participantId) => {
    // fix added for WAAG-4652 : if participant refreshes then, will have to get changed/latest uniqueId of participant and send evict call to server.
    this.props.admittedParticipants.map((participant) => {
      if (participant.participantId === participantId) {
        audioVideoAction.evictParticipant(
          this.props.sessionKey,
          participant.uniqueId
        )
      }
    })    
  }

  setLocationPopup = value => {
    this.props.setLocationPopup(value);
  };
  isPresenter = () => {
    return this.props.uniqueId === this.props.presenter;
  };

  setSpinnerVisibility = (value) => {
    this.setState({
      spinnerVisibility: value
    })
  }

  renderSpinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  saveSessionData = () => {
    this.setSpinnerVisibility(true);
    sessionActions.setSaveSessionDataFlag(true);
    setTimeout(() => {
      sessionActions.saveSessionData(this.props.sessionKey).then(() => {
        this.setSpinnerVisibility(false);
        toastr.success("SESSION_SAVE_SUCCESS");
      },
      error => {
        this.setSpinnerVisibility(false);
        toastr.error("SESSION_SAVE_ERROR");
      });
    }, 10000);
  }
  componentDidUpdate(prevProps) {
    // Toastr msg for agent mute/unmute users by clicking on waiting area Audio/Video icons.
    if(prevProps.currentParticipantMediaStatus.audioStatus !== this.props.currentParticipantMediaStatus.audioStatus) {
      if(this.props.currentParticipantMediaStatus.audioStatus === mediaStatus.MUTED_BY_AGENT){
        toastr.info("AUDIO_MUTED_BY_AGENT");
      } else if(this.props.currentParticipantMediaStatus.audioStatus === mediaStatus.UNMUTED
        && this.props.currentParticipantMediaStatus.mediaStatusChangeByAgent){
        toastr.info("AUDIO_UNMUTED_BY_AGENT");
      }
    }

    if(prevProps.currentParticipantMediaStatus.videoStatus !== this.props.currentParticipantMediaStatus.videoStatus) {
      if(this.props.currentParticipantMediaStatus.videoStatus === mediaStatus.MUTED_BY_AGENT){
        toastr.info("VIDEO_MUTED_BY_AGENT");
      } else if(this.props.currentParticipantMediaStatus.videoStatus === mediaStatus.UNMUTED
        && this.props.currentParticipantMediaStatus.mediaStatusChangeByAgent){
        toastr.info("VIDEO_UNMUTED_BY_AGENT");
      }
    } 
  }

  render() {
    // let splithash = window.location.hash ? window.location.hash.split("/") : [];
    // let splitpath = splithash.length > 1 ? splithash[1].split('?') : [];
    // let isDashboard = splitpath.length > 0 ? splitpath[0] === "dashboard" ? true : false : false;
    let isDashboard = removeTrailingSlash(this.props.history.location.pathname) === CONFIG.path.dashboard;
    return (
      <>
        {isDashboard ?
          <React.Fragment>
            {this.renderSpinner()}
            <Header
              isRealwearEndCall={this.state.isRealwearEndCall}
              eta={this.props.eta}
              messagesRead={this.props.messagesRead}
              setMessagesRead={this.props.setMessagesRead}
              setChatPopOverStateAction={this.props.setChatPopOverStateAction}
              isChatPopOver={this.props.isChatPopOver}
              modules={this.props.modules}
              sessionTitle={this.props.sessionTitle}
              admittedParticipants={this.props.admittedParticipants}
              allParticipants={this.props.allParticipants}
              sessionKey={this.props.sessionKey}
              sendWebsocketMessage={this.props.sendWebsocketMessage}
              token={this.props.token}
              webRtcPermissionRequest={this.props.webRtcPermissionRequest}
              webRtcPermissionReply={this.props.webRtcPermissionReply}
              webRtcPermissionReplyStatus={this.props.webRtcPermissionReplyStatus}
              sessionActions={sessionActions}
              headerAction={headerAction}
              uniqueId={this.props.uniqueId}
              userRole={this.props.userRole}
              handleEndSession={this.handleEndSession}
              headerIcons={this.props.headerIcons}
              handleLeaveSession={this.handleLeaveSession}
              latestUpdatedParticipant={this.props.latestUpdatedParticipant}
              isSessionEnded={this.props.isSessionEnded}
              displayMessage={this.props.displayMessage}
              isMessageArrived={this.props.isMessageArrived}
              snapshoteeId={this.props.snapshoteeId}
              snapshoterId={this.props.snapshoterId}
              snapshotPermissionReply={this.props.snapshotPermissionReply}
              sessionStatus={this.props.sessionStatus}
              presenter={this.props.presenter}
              isSnapshotTaken={this.props.isSnapshotTaken}
              isLocation={this.props.isLocation}
              locationPermissionReply={this.props.locationPermissionReply}
              setLocationPopup={this.setLocationPopup}
              locationPermissionReplyStatus={
                this.props.locationPermissionReplyStatus
              }
              locationParticipants={this.props.locationParticipants}
              addLocationParticipantId={this.props.addLocationParticipantId}
              updateLocationPermissionReply={
                this.props.updateLocationPermissionReply
              }
              updateLocationParticipantId={this.props.updateLocationParticipantId}
              isLocationSharing={this.props.isLocationSharing}
              stopLocationShare={this.props.stopLocationShare}
              isPresenter={this.isPresenter()}
              organiser={this.props.organiser}
              activeCanvas={this.props.activeCanvas}
              participantLeftUniqueId= {this.props.participantLeftUniqueId}
              participantJoinedUniqueId={ this.props.participantJoinedUniqueId }
              participantDeniedUniqueId={this.props.participantDeniedUniqueId}
              screenSharingParticipantId={this.props.screenSharingParticipantId}
              kmsConnectionStatus={this.props.kmsConnectionStatus}
              showKmsConnectionStatus={this.props.showKmsConnectionStatus}
              logo={this.props.logo}
              isCallEnded={this.props.isCallEnded}
              isOrganizer={this.props.isOrganizer}
              setToken={this.props.setToken}
              isCameraPermissionGranted={this.props.isCameraPermissionGranted}
              isCameraBusy={this.props.isCameraBusy}
              setSpinnerVisibility={this.setSpinnerVisibility}
              callMediaType={this.props.callMediaType}
              loggingLevel={this.props.loggingLevel}
              saveSessionData={this.saveSessionData}
              language={this.props.language}
              uriIdentifier={this.props.uriIdentifier}
              appEmulationPermission={this.props.appEmulationPermission}
              appEmulationPermisssionReply={this.props.appEmulationPermisssionReply}
              appEmulationAction={appEmulationAction}
              appEmulationIsSessionEnded={this.props.appEmulationIsSessionEnded}
              coBrowsePermission={this.props.coBrowsePermission}
              coBrowsePermisssionReply={this.props.coBrowsePermisssionReply}
              userName={this.props.userName}
              customerId={this.props.customerId}
              coBrowseJoinedParticipantUniqueId={this.props.coBrowseJoinedParticipantUniqueId}
              participantDeniedCoBrowseUniqueId={this.props.participantDeniedCoBrowseUniqueId}
              participantLeftCoBrowseUniqueId={this.props.participantLeftCoBrowseUniqueId}
              showCoBrowse={this.props.showCoBrowse}
              coBrowseSessionStatus={this.props.coBrowseSessionStatus}
              snapshotPermissionStatus={this.props.snapshotPermissionStatus}
              pluginNotInstalledParticipantIds={this.props.pluginNotInstalledParticipantIds}
              customerUriIdentifier={this.props.customerUriIdentifier}
              customerCoBrowsePermissionRequest={this.props.customerCoBrowsePermissionRequest}
              customerCoBrowsePermissionReply={this.props.customerCoBrowsePermissionReply}
              cobrowseType={this.props.cobrowseType}
              showCustomerCoBrowse={this.props.showCustomerCoBrowse}
              roomId={this.props.roomId}
              endUserToken={this.props.endUserToken}
              coBrowseBaseUrl={this.props.coBrowseBaseUrl}
              endCustomerCBSession={this.props.endCustomerCBSession}
              showSnapshot={this.props.showSnapshot}
              isSnapshotStarted={this.props.isSnapshotStarted}
              customerCoBrowseStarted={this.props.customerCoBrowseStarted}
              evictAction={this.evictAction}
              evictedParticipantDetails={this.props.evictedParticipantDetails}
              isUserEvicted={this.props.isUserEvicted}
              userSessionName={this.props.userSessionName}
              isSnapshotRunning={this.props.isSnapshotRunning}
              mediaDeviceDeniedDetails={this.props.mediaDeviceDeniedDetails}
              canvases={this.props.canvases}
              showAudioVideoComponent={this.props.showAudioVideoComponent}
              showChatPopup={this.props.showChatPopup}
              handleVoiceCommand= {this.props.handleVoiceCommand}
              deregisterVoiceCommand={this.props.deregisterVoiceCommand}
              deregisterAllVoiceCommands={this.props.deregisterAllVoiceCommands}
            /> 
          </React.Fragment>
          : null
        }

      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    modules: getModules(state),
    sessionTitle: selectSessionTitle(state),
    admittedParticipants: getAdmittedParticipants(state),
    allParticipants: getAllParticipants(state),
    sessionKey: selectSessionKey(state),
    token: selectUserToken(state),
    webRtcPermissionRequest: getWebRtcPermissionRequest(state),
    webRtcPermissionReply: getWebRtcPermissionReply(state),
    uniqueId: selectCurrentParticipantUniqueId(state),
    userRole: selectUserRole(state),
    headerIcons: getHeaderIcons(state),
    latestUpdatedParticipant: selectLatestUpdatedParticipant(state),
    webRtcPermissionReplyStatus: selectWebRtcPermissionReplyStatus(state),
    isSessionEnded: selectSessionEndedFlag(state),
    isMessageArrived: isMessageArrived(state),
    messagesRead: state.ChatReducer.messagesRead,
    isChatPopOver:state.ChatReducer.isChatPopOver,
    participantId: selectParticipantId(state),
    snapshoteeId: selectSnapshoteeId(state),
    snapshoterId: selectSnapshoterId(state),
    snapshotPermissionReply: selectSnapshotPermissionReply(state),
    isSnapshotTaken: selectIsSnapshotTaken(state),
    sessionStatus: selectSessionStatus(state),
    presenter: selectPresenter(state),
    isLocation: selectLocation(state),
    isLocationSharing: selectLocationSharing(state),
    locationPermissionReply: selectLocationPermissionReply(state),
    locationPermissionReplyStatus: selectLocationPermissionReplyStatus(state),
    locationParticipants: getLocationParticipants(state),
    organiser: selectOrganiser(state),
    activeCanvas: selectActiveCanvas(state),
    participantLeftUniqueId:getparticipantLeftUniqueId(state),
    participantJoinedUniqueId:getparticipantJoinedUniqueId(state),
    participantDeniedUniqueId:getparticipantDeniedUniqueId(state),
    screenSharingParticipantId: state.RoomMetaDataReducer.roomMetaData.screenSharingParticipantId,
    kmsConnectionStatus: getKmsStatus(state),
    showKmsConnectionStatus: getShowKmsStatus(state),
    logo: state.OrganisationReducer.logo,
    isCallEnded: getCallStatus(state),
    isUserWebCamPresent: state.SessionReducer.isUserWebCamPresent,
    isOrganizer: getIsOrganizer(state),
    isCameraPermissionGranted: getSnapshotCameraPermissionflag(state),
    isCameraBusy: getSnapshotCameraBusyflag(state),
    messages: state.ChatReducer.messages,
    callMediaType: state.SessionReducer.callMediaType,
    loggingLevel: state.SessionReducer.sessionDetails.loggingLevel,
    language: state.LanguageReducer.language,
    passcode: state.SessionReducer.passcode,
    uriIdentifier: state.UserReducer.uriIdentifier,
    customerUriIdentifier: state.OrganisationReducer.customerData.uri_identifier,
    appEmulationPermission: getAppEmulationPermission(state),
    appEmulationPermisssionReply: getAppEmulationPermissionReply(state),
    appEmulationIsSessionEnded: getisSessionEnded(state),
    coBrowsePermission: getCoBrowsePermission(state),
    coBrowsePermisssionReply: getCoBrowsePermissionReply(state),
    userName: selectUserName(state),
    customerId: getCustomerId(state),
    coBrowseJoinedParticipantUniqueId: getCoBrowseJoinedParticipantUniqueId(state),
    participantLeftCoBrowseUniqueId: getParticipantLeftCoBrowseUniqueId(state),
    coBrowseSessionStatus: getCoBrowseSessionStatus(state),
    participantDeniedCoBrowseUniqueId: getParticipantDeniedCoBrowseUniqueId(state),
    showCoBrowse: getShowCoBrowse(state),
    snapshotPermissionStatus: state.TostMessageReducer.snapshotPermissionStatus,
    pluginNotInstalledParticipantIds: state.TostMessageReducer.pluginNotInstalledParticipantIds,
    customerCoBrowsePermissionRequest: getCustomerCoBrowsePermissionRequest(state),
    customerCoBrowsePermissionReply: getCustomerCoBrowsePermissionReply(state),
    cobrowseType: selectCoBrowseType(state),
    showCustomerCoBrowse: getShowCustomerCoBrowse(state),
    roomId: getRoomId(state),
    showSnapshot: selectShowSnapshot(state),
    isSnapshotStarted: selectIsSnapshotStarted(state),
    customerCoBrowseStarted: getCustomerCoBrowseStarted(state),
    currentParticipantMediaStatus: state.ParticipantReducer.currentParticipantMediaStatus,
    eta: selectETA(state),
    isUserEvicted: getEvictedParticipantPermissionRequest(state),
    evictedParticipantDetails: getEvictedParticipantDetails(state),
    userSessionName: userSessionName(state),
    endUserToken: selectEndUserToken(state),
    coBrowseBaseUrl: selectCoBrowseBaseUrl(state),
    endCustomerCBSession: getEndCustomerCBSession(state),
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning,
    mediaDeviceDeniedDetails: getMediaDeviceDeniedDetails(state),
    canvases: state.WhiteboardReducer.canvases,
    showAudioVideoComponent: getShowAudioVideoComponentFlag(state),
    showChatPopup: state.OrganisationReducer.customerSettings.showChatPopup,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage,
      displayMessage: displayMessage,
      addLocationParticipantId: addLocationParticipantId,
      updateLocationPermissionReply: updateLocationPermissionReply,
      stopLocationShare: stopLocationShare,
      setMessagesRead: setMessagesRead,
      setChatPopOverStateAction:setChatPopOverStateAction,

      setToken,
    },
    dispatch
  );
};

export default withRealWear(connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer));
