import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";

// Component
import Header from "./Header";
import AboutOrganisationModal from "../Organisations/AboutOrganisationModal";
import UpdateProfileContainer from "../../UpdateProfile/UpdateProfileContainer";
import ChangePasswordContainer from "../../ChangePassword/ChangePasswordContainer";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import Spinner from "COMPONENTS/Spinner/Spinner";

//Utility
import { getMessage } from "CONFIG/i18n";
import { CONFIG } from "UTILS/Constants";
import { USER_ROLES, ADMIN_DASHBOARD_TABS } from "UTILS/Constants";
import { getModulesNotAllowedErrorMessage, getSSOLogoutUrl } from "UTILS/Utility";

// Selectors
import { selectUserName, selectCustomerId } from "../../MainLayout/selectors";
import { selectUserRole } from "../../Whiteboard/selectors";
import { getModules,getHeaderIcons, selectAllowedDownloads, 
  getIsOrganizer, getSubLoggingLevels, getLoggingLevel, 
  getSSODetails, getStorageDetails, isDownloadModuleSelected, 
  getIsSSOEnabled, getIsSystemPassword, getURIIdentifier, 
  getIsPasscodeEnabled 
} from "../../CommonComponents/Header/selectors";

// Constants
import { ERROR } from "UTILS/Constants";

//actions
import { organisationAction, userProfileAction, logoutAction, userAction, qrCodesAction, adminAction, leaveSessionAction } from "CONFIG/ActionFactory";

class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuperAdmin: false,
      showUserProfileModal: false,
      userProfileData: {},
      showChangePasswordModal: false,
      showAboutOrgModalFlag: false,
      organizationData: { modules: [] },
      showAddQRCodeModal: false,
      showSuccessModal: false,
      newQRDetails: {
        label: '',
        description: '',
        qrId: '',
        spinnerVisibility: false,
      },
    };
  }

  componentDidMount() {
    this.getUserProfile(this.props.isSystemPassword);
    if(this.props.isSystemPassword){
      this.toggleChangePasswordModal();
    }
    if (this.props.role === USER_ROLES.ADMIN) {
      this.getCustomerInfo();
    }  
  }

  getUserProfile = () => {
    userProfileAction.getUserProfileAction().then(
      respone => {
        this.setState({ userProfileData: respone.data.data });
      },
      error => {}
    );
  };

  handleLogoutClick = () => {
    if (this.props.isSSOLogin) {
      let ssoLogoutUrl = getSSOLogoutUrl(this.props.ssoDetails);
      window.location.assign(ssoLogoutUrl);
    } else {
      logoutAction.logoutAction().then(
        data => {          
          const role = this.props.role;

          // Redirect login
          this.props.history.push(CONFIG.path.login);
        },
        error => {
          toastr.error(error.code);
        }
      );
    }
  };

  handleUpdateProfileClick = () => {
    this.setState({ showUserProfileModal: !this.state.showUserProfileModal });
  };

  verifyStorage=(storageElement,callback)=>{
    organisationAction.verifyStorage(storageElement).then(
      data => {
        callback && callback();
      }
    ).catch (
      error => {
        callback && callback(error);
      }
    )
  }

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

  getCustomerInfo = (callBack) => {
    this.showSpinner();
    organisationAction.getCustomersAction(this.props.customerId).then(
      responseObject => {
        let organizationData = responseObject.data.data;
        this.setState({ organizationData: organizationData });
        this.hideSpinner();
        callBack && callBack()
      },
      error => {
        this.hideSpinner();
        toastr.error(error.code);
      }
    );
  };

  uploadOrgLogo = (formData, callBack) => {
    this.showSpinner();
    organisationAction.resetLogoUrl();
    organisationAction.uploadOrgLogoStatusAction(formData).then(
      response_data => {
        this.getCustomerInfo();
        this.hideSpinner(); 
        callBack();
      },
      error => {
        this.hideSpinner();  
        callBack(error.code);
      }
    );
  };

  updateCustomerSettings = (details, callback)=>{
    organisationAction.updateCustomerSettings(this.props.customerId, details).then(
      data => { organisationAction.getCustomersAction(this.props.customerId).then(
        responseObject => {
          callback && callback();
          toastr.success("SETTINGS_SAVED");
        });
      }
    ).catch (
      error => {
        toastr.error(error.code);
        callback && callback(error);
      }
    )
  }
  toggleChangePasswordModal = () => {
    this.setState({ showChangePasswordModal: ! this.state.showChangePasswordModal });
  }

  gotoAdminSettings=()=>{
    this.props.history.push(CONFIG.path.adminSettings);
  }

  gotoAddOrganization=()=>{
    this.props.history.push(CONFIG.path.addOrganization);
  }

  openAddQRCodeModal = () => {
    this.setState({
      showAddQRCodeModal: true
    })
  }

  closeAddQRCodeModal = () => {
    this.setState({
      showAddQRCodeModal: false
    })
  }

  handleCancelAboutOrgModal = () => {
    this.setState({
      showAboutOrgModalFlag: false
    });
  };

  showAboutOrgModal = () => {
    this.getCustomerInfo(()=>{
      this.setState({
        showAboutOrgModalFlag: true
      });
    });    
  };

  handleAddClick = (qrCodeInfo, callBack) => {
    qrCodesAction.addQRCode(qrCodeInfo).then(
      response => {
        this.closeAddQRCodeModal()
        this.setState({
          newQRDetails: {
            label: qrCodeInfo.label,
            description: qrCodeInfo.description,
            qrId: response.data.data.qrId
          },
          showSuccessModal: true
        });
        callBack && callBack();
      },
      error => {
        let message = "";
        if (error.code == ERROR.MODULES_NOT_ALLOWED) {
          message = getModulesNotAllowedErrorMessage(error.errDetails)
        }
        else {
          message = getMessage(error.code)
        }
        callBack && callBack(message);
      }
    );
  };

  closeSuccessModal=()=>{
    this.setState({
      showSuccessModal: false
    })
    this.props.fetchAllQRCodes();
    if(this.props.adminCurrentActiveTab != ADMIN_DASHBOARD_TABS.QR_CODES){
      adminAction.saveCurrentActiveTab(ADMIN_DASHBOARD_TABS.QR_CODES)
    }
  }

  renderSpinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  render() {
    return (
      <>
      {this.renderSpinner()}
        <Header
          title={this.props.title}
          headerButtonText={this.props.headerButtonText}
          showAddUserModal={this.props.showAddUserModal}
          handleLogoutClick={this.handleLogoutClick}
          showAboutOrgModal={this.showAboutOrgModal}
          showAddUserDropdown={this.props.showAddUserDropdown}
          isSuperAdmin={this.props.role === USER_ROLES.SUPER_ADMIN}
          isAdmin ={this.props.role===USER_ROLES.ADMIN}
          handleUpdateProfileClick={this.handleUpdateProfileClick}
          username={this.props.userName}
          userProfileData={this.state.userProfileData}
          logo={this.props.logo}
          showSettingsOption = {this.props.role === USER_ROLES.ADMIN && this.props.isDashboard}
          isDashboard={this.props.isDashboard}
          showHeaderButton={this.props.showHeaderButton}
          allowedDownloads={this.props.allowedDownloads}
          availableSubLoggingLevels={this.props.availableSubLoggingLevels}
          loggingLevel={this.props.loggingLevel}
          storageDetails={this.props.storageDetails}
          SSODetails={this.props.ssoDetails}
          isOrganizer={this.props.isOrganizer}
          toggleChangePasswordModal={this.toggleChangePasswordModal}
          showChangePasswordModal={this.state.showChangePasswordModal}
          role={this.props.role}
          updateCustomerSettings={this.updateCustomerSettings}
          verifyStorage={this.verifyStorage}
          isDownloadModuleSelected={this.props.isDownloadModuleSelected}
          modules={this.props.modules}
          isSSOEnabled={this.props.isSSOEnabled}
          handleSearchString={this.props.handleSearchString}
          uriIdentifier={this.props.uriIdentifier}
          isPasscodeEnabled={this.props.isPasscodeEnabled}
          gotoAdminSettings={this.gotoAdminSettings}
          gotoAddOrganization={this.props.gotoAddOrganization}
          isSSOLogin={this.props.isSSOLogin}
          language={this.props.language}
          currentActiveTab={this.props.currentActiveTab}
          closeAddQRCodeModal={this.closeAddQRCodeModal}
          showAddQRCodeModal={this.state.showAddQRCodeModal}
          handleAddClick={this.handleAddClick}
          openAddQRCodeModal={this.openAddQRCodeModal}
          showSuccessModal={this.state.showSuccessModal}
          newQRDetails={this.state.newQRDetails}
          closeSuccessModal={this.closeSuccessModal}
          totalQRCodes={this.props.totalQRCodes }
          qrLimit={this.props.qrLimit}
          isQRCodeEnabled={this.props.isQRCodeEnabled}
          toggleAddAnnouncementModal={this.props.toggleAddAnnouncementModal}
          showUserDropDownModal={this.props.showUserDropDownModal}
        />
        <UpdateProfileContainer
          show={this.state.showUserProfileModal}
          toggle={this.handleUpdateProfileClick}
          getAllUserData={this.props.getAllUserData}
          getUserProfile={this.getUserProfile}
          userRole={this.props.role}
          userProfileData={this.state.userProfileData}
        />
          <AboutOrganisationModal
            show={this.state.showAboutOrgModalFlag}
            handleCancelClick={this.handleCancelAboutOrgModal}
            organizationData={this.state.organizationData}
            customerId={this.props.customerId}
            uploadOrgLogo={this.uploadOrgLogo}
            hideSpinner={this.hideSpinner}
          />
        <ChangePasswordContainer
          show={this.state.showChangePasswordModal}
          toggleModal={this.toggleChangePasswordModal}
          isSystemPassword= {this.props.isSystemPassword}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: selectUserName(state),
    role: selectUserRole(state),
    customerId: selectCustomerId(state),
    modules: getModules(state),
    allowedDownloads: selectAllowedDownloads(state),
    isOrganizer: getIsOrganizer(state),
    availableSubLoggingLevels: getSubLoggingLevels(state),
    loggingLevel: getLoggingLevel(state),
    storageDetails: getStorageDetails(state),  
    ssoDetails:getSSODetails(state),
    isDownloadModuleSelected: isDownloadModuleSelected(state),
    headerIcons: getHeaderIcons(state),
    isSSOEnabled: getIsSSOEnabled(state),
    uriIdentifier: getURIIdentifier(state),
    isPasscodeEnabled: getIsPasscodeEnabled(state),
    isSystemPassword: getIsSystemPassword(state),
    isSSOLogin: state.UserReducer.userDetails.isSSOLogin,
    language: state.LanguageReducer.language,
    adminCurrentActiveTab: state.AdminReducer.currentActiveTab
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withErrorHandler(connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer));
