import React, { Component } from "react";
import { getMessage, changeLanguage } from "CONFIG/i18n";
import { isIE, isFirefox } from "react-device-detect";
import { Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Actions
import { systemAnnouncementAction } from "CONFIG/ActionFactory";

//Components
import ScheduleSessionTable from "COMPONENTS/SessionManagement/ScheduleSessionTable/ScheduleSessionTableContainer";
import QRCodeSessionListContainer from "COMPONENTS/SessionManagement/QRCodeSessionList/QRCodeSessionListContainer";
import Popup from "COMPONENTS/CommonComponents/Modal/Modal";
import { setQRCodeEnabled } from "../QRCode/QRCodeComponent/QRCodeAction";

//Change language 
import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";

//ASSETS
import icCalendar from "ASSETS/Images/ic_calendar.svg"
import icMeeting from "ASSETS/Images/ic_meeting.svg"
import icHistory from "ASSETS/Images/ic_history.svg"
// import icCustomerManagement from "ASSETS/Images/ic_customer_management.svg"

//UTILS
import { modulesAvailableConstant } from "UTILS/Constants";
import { getAnnouncementMarquee, isMobileOriPad } from "UTILS/Utility";

//stylesheet
import "./SessionManagement.less"

class SessionManagement extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeAnnouncements: "",
      isSessionClosedForcefully: false,
      classColumn : "col-4",
    }
  }
  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
  };

  componentDidMount() {
    // get active announcements
    systemAnnouncementAction.getActiveAnnouncementsAction().then(
      (response) => {
        this.setState({
          activeAnnouncements: response.data
        })
      },
      error => {
        console.error(error);
      }
    )
    
    //call orientation check on page mounts
    this.updateOrientation();

    //check on orientation change
    addEventListener("orientationchange", (event) => this.updateOrientation());
  
  }

  componentWillUnmount() {
  
    //remove orientation change listener
    removeEventListener("orientationchange", (event) => this.updateOrientation());
  
  }

  updateOrientation = () => {    

    setTimeout(() => {
      
      let width = window.innerWidth;

      //to check orientation of device
      let mediaCheck = window.matchMedia("(orientation: portrait)"); 
      let isPortrait = mediaCheck.matches ? true : false;

      //to check device is mobile or ipad and set column class accordingly    
      let classColumn = isMobileOriPad() ? isPortrait ? width < 567 ? "col-4" : "col-3" : width < 1023 ? "col-3 align-items-center" : "mr-4 col-2 align-items-center" : "col-4 ";
      
      //set state variable for class
      this.setState({classColumn});

    }, 100);  

  }

  componentDidUpdate(prevProps) {
    if (this.props.isSessionEndedForcefully && this.props.isSessionEndedForcefully === prevProps.isSessionEndedForcefully) {
      this.setState({isSessionClosedForcefully: true})
      this.props.sessionActions.updateEndSessionForcefullyFlag(false)
    }
    // check if the QR CODE module present in the customer's selected modules
    if(prevProps.customerData?.modules !== this.props.customerData?.modules){
      let availableModules = this.props.customerData?.modules ? this.props.customerData?.modules : []
      this.props.setQRCodeEnabled(availableModules.indexOf(modulesAvailableConstant.QR_CODE) !== -1 );
    }
  }

  sessionClosedForcefullyPopup = () => {
    return (
      <Popup
        show={this.state.isSessionClosedForcefully}
        isDoubleButton={false}
        message={getMessage("CLOSING_MEETING_FORCEFULLY_MESSAGE")}
        handleOkClick={this.handleOkClick}
      />
    );
  };

  handleOkClick = () => {
    this.setState({ isSessionClosedForcefully: false })
  }

  render() {  
    
    let { classColumn } = this.state;

    return (
      <React.Fragment>
        {this.sessionClosedForcefullyPopup()}
        <div>
          <ChangeLanguage
            onSelectChange={this.onSelectChange}
            language={this.props.language}
          />
          {this.state.activeAnnouncements && this.state.activeAnnouncements.length >= 1 &&
            <marquee>
              <Label className="announcementLabel mt-2">
                {getAnnouncementMarquee(this.state.activeAnnouncements)}
              </Label>
            </marquee>
          }
        </div>
        <Row className="container-fluid sessionManagement">
          
          <div className={`col-12 d-flex justify-content-center ${isMobileOriPad() ? "col-xl-12" : "col-xl-6 offset-xl-3" }`}>
            <div className={`${classColumn} divMaxWidth p-0 d-flex flex-column text-center cursorPointer`}
              onClick={this.props.showStartMeeting}>
              <img src={icMeeting} className={isIE ? "sessionImages" : "sessionMgmtImage"} />
              <span className="sessionOptions">{getMessage("START_A_MEETING")}</span>
            </div>
            <div className={`${classColumn} divMaxWidth p-0 d-flex flex-column text-center cursorPointer`}
              onClick={this.props.showScheduleMeeting}>
              <img src={icCalendar} className={isIE ? "sessionImages" : "sessionMgmtImage"} />
              <span className="sessionOptions">{getMessage("CREATE_A_MEETING_APPOINTMENT")}</span>
            </div>
            <div className={`${classColumn} divMaxWidth p-0 d-flex flex-column text-center cursorPointer`}
              onClick={this.props.showTrailHistory}>
              <img src={icHistory} className={isIE ? "sessionImages" : "sessionMgmtImage"} />
              <span className="sessionOptions">{getMessage("TRAIL_HISTORY")}</span>
            </div>
            {/* <div className="col-3 p-0 d-flex flex-column text-center cursorPointer">
            <img src={icCustomerManagement} className={isIE ? "sessionImages" : ""} />
            <span className="sessionOptions cursorDisabled">{getMessage("CUSTOMER_MANAGEMENT")}</span>
          </div> */}
          </div>
          {this.props.isQRCodeEnabled && this.props.customerData?.isAgentSessionQueueEnabled &&
            <div className="col-xl-12 pt-4 d-flex justify-content-center sessionTable">
              <div className="col-xl-9 sessionTable">
                <QRCodeSessionListContainer
                  setQRCodeEnabled={this.props.setQRCodeEnabled}
                  customerData={this.props.customerData}
                />
              </div>
            </div>
          }
          <div className="col-xl-12 pt-4 d-flex justify-content-center sessionTable">
            <div className="col-xl-9 sessionTable">
              < ScheduleSessionTable
                joinSession={this.props.joinSession}
                editSessionRequest={this.editSessionRequest}
                clearInputData={this.clearInputData}
                modules={this.props.customerData?.modules}
              />
            </div>
          </div>
          {/* {!isIE && !isFirefox &&
              <div
                className="d-flex justify-content-center py-3"
              >
                <span  
                  className="testPageLink cursorPointer text-center"
                  onClick={() => {
                    window.open('/testPage', '_blank');
                  }}>
                  {getMessage("TEST_PAGE")}
                </span>
              </div>
          } */}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    customerData: state.OrganisationReducer.customerData,
    isQRCodeEnabled : state.QRCodesReducer.isQRCodeEnabled
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setQRCodeEnabled : setQRCodeEnabled
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionManagement);
