import React from "react";
import { func } from "prop-types";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

// Strings
import { getMessage } from "CONFIG/i18n";

import "../../App/App.less";
import "./NegativeActionConfirmationModal.less";
import cancelIcon from "ASSETS/Images/ic_cancel_popup.svg";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";

class NegativeActionConfirmationModal extends React.Component {

  constructor() {
    super();
  }

  componentWillUnmount() {
    this.disableDeleteBtn(false);
  }

  disableDeleteBtn = (value) => {
    let deleteBtn = document.getElementById("deleteAnnouncementBtn");
    if(deleteBtn){
      deleteBtn.disabled = value;
      if(value)
        deleteBtn.classList.add("pointerEventsNone");
      else
        deleteBtn.classList.remove("pointerEventsNone");
    }
  }

  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <a id="snapDownload">
          <Button
            className="buttons borderBtn bgwhite"
            onClick={() => this.props.handleNoClick()}
          >
            {this.props.noButtonText ? this.props.noButtonText : getMessage("CANCEL")}
          </Button></a>
        <Button
          onClick={() => {
            this.disableDeleteBtn(true);
            this.props.handleYesClick();
          }
          }        
          id="deleteAnnouncementBtn"
          className="buttons customBtn resetButton"
        >
          {this.props.yesButtonText ? this.props.yesButtonText : getMessage("DELETE")}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={true}
        className="NegativeActionConfirmationModal marginAuto"
        toggle={this.props.handleNoClick}
        backdrop="static"
      >
        <ModalBody className="displayFlex justifyContentFlexCenter alignItemsCenter">
          {this.props.confirmationText}
          {this.props.showCrossIcon ? <span className="pointer" onClick={this.props.handleCrossClick}>
            <img
              src={cancelIcon}
              alt="takeSnapshot"
              className="img-fluid float-right"
            />
          </span> : null}

        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modalScrollBar(NegativeActionConfirmationModal);
