import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import Switch from "COMPONENTS/CommonComponents/Switch/Switch";

import { getMessage } from "CONFIG/i18n";

import { formatDateByLocale, getTimeInLocaleTimezone } from "UTILS/DateUtils";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import ic_edit from "ASSETS/Images/qr_icons/ic_edit.png";
import ic_delete from "ASSETS/Images/ic_delete.svg";

import "./SystemAnnouncements.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

export default class SystemAnnouncementList extends Component {

  constructor(props) {
    super(props)
    this.tooltipBoundaryElement = null;
  }

  handleChange = (announcement) => {
    let data = Object.assign(
      {},
      {
        id: announcement.id,
        title: announcement.title,
        message: announcement.message,
        activateFrom: announcement.activateFrom,
        activateTill: announcement.activateTill,
        isActive: !announcement.isActive
      }
    );
    this.props.editAnnouncement(data, error => {
      if(error) {
        console.error(error);
        toastr.error(error.code)
      }
    })
  }
  componentDidMount(){
    this.getTooltipBoundaryElement();
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("organisations-list")[0];
  }

  activeAnnouncement = (cell, row) => {
    return (
      <Switch
        switchOn={row.isActive}
        toggleSwitch={() => this.handleChange(row)}
      />
    )
  }

  actionsFormat = (cell, row) => {
    return (
      <React.Fragment>
        <div>
          <img
            src={ic_edit}
            className="px-2 cursorPointer"
            id={`edit${row.id}`}
            onClick={(e) => {
              e.stopPropagation();
              this.props.toggleAddAnnouncementModal(true, row)
            }}
          />
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={getMessage("EDIT")}
            tooltipId={`edit${row.id}`}
          />
        </div>
        <div>
          <img
            src={ic_delete}
            className=" pl-2 cursorPointer delete-action-img"
            id={`delete${row.id}`}
            onClick={e => {
              e.stopPropagation();
              console.log('[AnnouncementTable] Actions row.id: ', row.id);
              this.props.showDeleteAnnouncementConfirmationModal(true, row.id, row.title);
            }}
          />
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={getMessage("DELETE")}
            tooltipId={`delete${row.id}`}
          />
        </div>
      </React.Fragment>
    )
  }

  activateTillFormat = (cell, row) => {
    return(
      <div className="nameEllipsis">
        {formatDateByLocale(row.activateTill)} &nbsp;
        {getTimeInLocaleTimezone(row.activateTill)}
      </div>
    )
  }

  activateFromFormat = (cell, row) => {
    return(
      <div className="nameEllipsis">
        {formatDateByLocale(row.activateFrom)} &nbsp;
        {getTimeInLocaleTimezone(row.activateFrom)}
      </div>
    )
  }

  titleFormat = (cell, row) => {
    return (
      <>
        <span className="nameEllipsis mb-0 custom-tooltip" id={`title${row.id}`}>
          {row.title}
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={row.title}
            tooltipId={`title${row.id}`}
          />
        </span>
      </>
    )
  }

  messageFormat = (cell, row) => {
    return (
      <>
      <span className="nameEllipsis mb-0 custom-tooltip" id={`message${row.id}`}>
        {row.message}
        <CustomTooltip
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipText={row.message}
          tooltipId={`message${row.id}`}
        />
      </span>
      
      </>
      
    )
  }

  renderAnnouncementsList = () => {
    return (
      <div>
        <BootstrapTable
          data={this.props.announcements}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='organisations-list custom-list'
          bordered={false}
        >
          <TableHeaderColumn
            dataField='id'
            hidden={true}
            isKey
          >
            {getMessage("id")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='title'
            dataFormat={this.titleFormat}
            columnClassName='nameEllipsis'
            width="17%"
            // dataSort={true}
            dataAlign='center'
          >
            {getMessage("TITLE")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='message'
            dataFormat={this.messageFormat}
            columnClassName='nameEllipsis'
            width="31%"
            // dataSort={true}
            headerAlign='center'
            dataAlign='left'
          >
            {getMessage("MESSAGE")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='activate_from'
            dataFormat={this.activateFromFormat}
            width="16%"
            // dataSort={true}
            dataAlign='center'
          >
            {getMessage("ACTIVATE_FROM")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='activateTill'
           
            dataFormat={this.activateTillFormat}
            width="16%"
            // dataSort={true}
            dataAlign='center'
          >
            {getMessage("ACTIVATE_TILL")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.activeAnnouncement}
            dataField='isActive'
            width="10%"
            // dataSort={true}
            dataAlign='center'
          >
            {getMessage("STATUS")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.actionsFormat}
            columnClassName='d-flex justifyContentFlexCenter'
            width="10%"
            // dataSort={true}
            dataAlign='center'
          >
            {getMessage("ACTIONS")}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }

  render() {
    return (
      <div className="systemAnnouncements">
        {this.props.announcements && this.props.announcements.length > 0 ? (
          this.renderAnnouncementsList()
        ) : (
          <div className="margin-bottom">
            <NoData
              minHeight={"59vh"}
              message={getMessage("NO_ANNOUNCEMENTS")}
            />
          </div>
        )}
      </div>
    );
  }
}