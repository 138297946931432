import React, { Component } from "react";
import { bindActionCreators } from "redux";
import "fabric";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { getMessage } from "CONFIG/i18n";
import Location from "./Location";
import {
  getLocationParticipants,
  selectETA,
  selectActiveCanvasRef,
  selectLocationSharingWindowClosed,
  selectCloseLocationWindowFlag
} from "./selectors";
import { selectActiveCanvasData, selectGraphHopperKey } from "../Whiteboard/selectors";
import { connect } from "react-redux";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { selectSessionKey } from "../MainLayout/selectors";
import { selectCurrentParticipantUniqueId } from "REDUX/Selector/SessionSelector";
import { selectPresenter, getAdmittedParticipants } from "../CommonComponents/Header/selectors";
import { whiteboardAction as actionFactory } from "CONFIG/ActionFactory";
import {
  selectActiveCanvas,
  selectParticipantId
} from "../Whiteboard/selectors";
import {selectOrganiser
} from "REDUX/Selector/SessionSelector";
import { socketMessage } from "WEBSOCKET/constants";
import { CANVAS_TYPES, CANVAS_SOURCE, ERROR } from "../../Utils/Constants";
import { convertBlobToFile, isCanvasBlank, canvasToBlob , generateZip } from "UTILS/Utility";
import { getUserName } from "../../Routes/selectors";
import { selectUserRole } from "../Whiteboard/selectors";
import { addLocationData, stopShareETA, shareETA,locationSharingWindowClosed, clearLocation } from "./LocationAction";
import { setIsDownloadToCanvas } from "COMPONENTS/Whiteboard/WhiteboardAction";    
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
export class LocationContainer extends Component {
  handleLocationToggle = (isDownload,isLocationPermissionGranted=true) => {
    /*console.log("isDownloaded1",isDownload);*/
    this.props.handleLocationToggle(isDownload,isLocationPermissionGranted);
  };
  uploadFile = file => {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("canvasId", this.props.activeCanvas.canvasId);
      formData.append("file", file);
      formData.append("sessionKey", this.props.sessionKey);
      formData.append("uniqueId", this.props.uniqueId);
      actionFactory.uploadFileAction(formData).then(
        data => {
          if (data) {
            const filePaths = {
              fileUrl: data.data.fileUrl,
              relativeFilePath: data.data.relativeFilePath
            }
            resolve(filePaths);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };

  uploadCanvasThumbnail = async (activeCanvas, activeCanvasImage, callback) => {
    const fileName = "canvasThumbnail_" + activeCanvas.canvasId + ".png";
    const zipFileName = "canvasThumbnail.zip";
    const fileObject = await generateZip(
      activeCanvasImage,
      fileName,
      zipFileName
    );
    var formData = new FormData();
    formData.append("canvasId", activeCanvas.canvasId);
    formData.append("thumbnail", fileObject);
    formData.append("sessionKey", this.props.sessionKey);
    formData.append("uniqueId", this.props.uniqueId);
    actionFactory
    .uploadCanvasThumbnailAction(formData)
    .then((data) => {
      const message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.SET_CANVAS_THUMBNAIL,
          sessionKey: this.props.sessionKey,
          canvasId: data.data.canvasId,
          canvasType: activeCanvas.canvasType,
          activePage:
            this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
              ? this.props.activeCanvasData.activePage
              : -1,
          thumbnailUrl: data.data.thumbnailUrl,
        },
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      if (callback) callback();
    })
    .catch((error) => {
      toastr.error(error.code);
      console.log("uploadCanvasThumbnailAction error : ", error);
      callback && callback(error);
    });
  };

  addCanvas = (fileUrl, relativeFilePath, callback) => {
    // this.removeSpotLight();
    // this.removePdfHighLight();
    let message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasType: CANVAS_TYPES.IMAGE,
        canvasSource: CANVAS_SOURCE.LOCATION,
        fileUrl: fileUrl,
        relativeFilePath: relativeFilePath,
      }
    };

    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    if (
      this.props.activeCanvas.canvasType == CANVAS_TYPES.REGULAR &&
      isCanvasBlank(
        this.props.activeCanvasData.canvasData,
        this.props.activeCanvas.canvasType,
        this.props.activeCanvasData.activePage
      )
    ) {
      callback && callback();
      return;
    } else if (this.props.activeCanvasRef) {
      let blob = canvasToBlob(this.props.activeCanvasRef, '#FFFFFF');
      this.uploadCanvasThumbnail(this.props.activeCanvas, blob, callback);
    }
    if (callback) callback();
  };
  sendAddObjectMessage = (object, subAction) => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.ADD_OBJECT,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: CANVAS_TYPES.IMAGE,
        activePage: -1,
        subAction: subAction,
        participantId: this.props.participantId,
        whiteboardData: {
          objectType: object.objectType,
          objectId: object.objectId,
          canvasObject: object
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  sendDeleteObjectMessage = (objectId, subAction) => {
    // this.props.deleteObject(objectId)
    const message = {
      data: {
        sessionKey: this.props.sessionKey,
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DELETE_OBJECT,
        subAction: subAction,
        participantId: this.props.participantId,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        whiteboardData: {
          objectId: objectId
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  imageScaleFactor = (oImg) => {
    var width = this.props.activeCanvasRef.width;
    var height = this.props.activeCanvasRef.height;
    var ratio = (oImg.width / oImg.height).toFixed(2);
    if (width / height > ratio) {
      width = height * ratio;
    } 
    else {
      height = width / ratio;
    }
    var scale = width / oImg.width
    var scaleY = height / oImg.height

    return {scale: scale,scaleY:scaleY, height: height, width: width}
  };

  addImageToCanvas = (fileUrl) => {
    let zoom = this.props.activeCanvasRef.getZoom();
    fabric.Image.fromURL(
      fileUrl,
      myImg => {
        let scaleFactor = this.imageScaleFactor(myImg);
        let scale = scaleFactor.scale/zoom;
        myImg.scale(scale)
        let finalWidth = myImg.width * scale * zoom; 
        let finalHeight = myImg.height * scale * zoom; 

        myImg.set({left:(this.props.activeCanvasRef.width-finalWidth)/2/zoom,
          top:(this.props.activeCanvasRef.height-finalHeight)/2/zoom,
          selectable: false})
        myImg["objectType"] = "image";
        myImg["objectId"] = "image";
        this.props.activeCanvasRef.add(myImg);
        this.props.activeCanvasRef.renderAll();
        // this.sendAddObjectMessage(
        //   myImg,
        //   socketMessage.subCategories.WHITEBOARD.subActions.REGULAR
        // );
      },
      {
        crossOrigin: "anonymous"
      }
    );
  };

  getParticipantName = (id) => {
    if(id) {
      let participant = this.props.admittedParticipants.filter(participant => participant.uniqueId === id)[0];
      return participant ? participant.userName : null;
    }
    else  
      return null
  }
  componentDidUpdate(prevProps) {
    const{locationWindowClosed,participantId,isDownloaded, isLocationPermissionGranted}=this.props.locationWindowClosed
    let userName = this.getParticipantName(participantId);
    if (prevProps.locationWindowClosed.locationWindowClosed !== locationWindowClosed) {
      if (locationWindowClosed && userName) {
        if (isLocationPermissionGranted === false && this.props.uniqueId === this.props.organiser) {
          toastr.error("USER_BLOCK_LOCATION_PERMISSION", [{ key: "userName", value: userName }],
            {
              toastId: "USER_BLOCK_LOCATION_PERMISSION_" + participantId
            });
        }
      }
      else{
        if(isDownloaded){
          toastr.info("AGENT_DOWNLOADED_LOCATION_WINDOW_TO_EDIT_ON_CANVAS");
        }
        else if(locationWindowClosed){
          toastr.info("AGENT_LOCATION_WINDOW_CLOSED");
        }
      }
    }
    //this.props.locationSharingWindowClosed();// Fixed 1651
    if(this.props.closeLocationWindow !== prevProps.closeLocationWindow && 
        this.props.closeLocationWindow &&
        this.props.organiser === this.props.uniqueId){
      // Closing location window if all users closes their window
      this.props.clearLocation();
      this.props.handleLocationToggle(false,true);

    }

    //display toaster when any user click on close
    if(locationWindowClosed && isLocationPermissionGranted &&
      participantId !== prevProps.locationWindowClosed.participantId &&
      userName) {   
      toastr.info("LOCATION_WINDOW_CLOSED", [{ key: "userName", value: userName }], { toastId: "LOCATION_WINDOW_CLOSED_" + participantId});
      this.props.locationSharingWindowClosed();
    }
  }

  componentWillUnmount() {
    this.props.clearLocation();
    this.props.setIsDownloadToCanvas(null);
  }
  
  render() {
    return (
      <div id="locationContainer">
        <Location
          show={this.props.show && Object.keys(this.props.locationParticipants).length > 1}
          sessionKey={this.props.sessionKey}
          presenter={this.props.presenter}
          uniqueId={this.props.uniqueId}
          handleLocationToggle={this.handleLocationToggle}
          sendWebsocketMessage={this.props.sendWebsocketMessage}
          locationParticipants={this.props.locationParticipants}
          eta={this.props.eta}
          shareETA={this.props.shareETA}
          uploadFile={this.uploadFile}
          addCanvas={this.addCanvas}
          activeCanvasRef={this.props.activeCanvasRef}
          addImage={this.addImageToCanvas}
          addLocationData={this.props.addLocationData}
          userName={this.props.userName}
          stopShareETA={this.props.stopShareETA}
          showSpinner={this.props.showSpinner}
          hideSpinner={this.props.hideSpinner}
          role={this.props.role}
          organiser={this.props.organiser}
          handleLocatioNoClick={this.props.handleLocatioNoClick}
          graphHopperKey={this.props.graphHopperKey}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userName: getUserName(state),
    presenter: selectPresenter(state),
    uniqueId: selectCurrentParticipantUniqueId(state),
    sessionKey: selectSessionKey(state),
    locationParticipants: getLocationParticipants(state),
    eta: selectETA(state),
    activeCanvas: selectActiveCanvas(state),
    activeCanvasRef: selectActiveCanvasRef(state),
    participantId: selectParticipantId(state),
    locationWindowClosed: selectLocationSharingWindowClosed(state),
    admittedParticipants: getAdmittedParticipants(state),
    role: selectUserRole(state),
    organiser: selectOrganiser(state),
    closeLocationWindow: selectCloseLocationWindowFlag(state),
    activeCanvasData: selectActiveCanvasData(state),    
    graphHopperKey: selectGraphHopperKey(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage,
      shareETA,
      addLocationData: addLocationData,
      stopShareETA: stopShareETA,
      locationSharingWindowClosed,
      clearLocation,
      setIsDownloadToCanvas
    },
    dispatch
  );
};
export default modalScrollBar(connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationContainer));
