import React from "react";
import { getMessage } from "CONFIG/i18n";

export default class BitRateTile extends React.Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.repeatInterval = 1000; // 1000 ms == 1 seconds
    this.state = {
      totalSentBitRate: 0,
      totalReceivedBitRate: 0
    }
  }

  componentDidMount() {
    this.getTotalBitRate();
  }

  getTotalBitRate = () => {
    this.interval = setInterval(() => {
      let bitrates = this.props.parentContainer?.getElementsByClassName("bitrate");
      let totalReceivedBitRate = 0;
      let totalSentBitRate = 0;
      _.map(bitrates, (bitrate) => {
        if(bitrate.id == this.props.uniqueId+'bitrate')
          totalSentBitRate += parseInt(bitrate.innerText);
        else
          totalReceivedBitRate += parseInt(bitrate.innerText);
      })
      this.setState({
        totalReceivedBitRate: totalReceivedBitRate,
        totalSentBitRate: totalSentBitRate
      })
    }, this.repeatInterval)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className={this.props.className
        ? this.props.className
        : "totalBitrateCont"
      }>
        <span className={"totalBitrate " + this.props.labelClassname}>
          {this.props.sentLabel}: {(this.state.totalSentBitRate || 0) + ' kbps'}
        </span>
        <span className={"totalBitrate " + this.props.labelClassname}>
          {this.props.recvLabel}: {(this.state.totalReceivedBitRate || 0) + ' kbps'}
        </span>
      </div>
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }
}