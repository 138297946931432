import React from "react";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { toast } from 'react-toastify';
import ChangePassword from "./ChangePassword";
import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";

import { getMessage } from "CONFIG/i18n";

import { changePasswordAction } from "CONFIG/ActionFactory";

import "../ResetPassword/ResetPassword.less";

class ChangePasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  handleChangePassword = (data, callback) => {
    changePasswordAction.changePasswordAction(data).then(
      () => {
        toastr.success("PASSWORD_SET");
        if(callback) callback()
        this.props.toggleModal();
      },
      error => {
        if(callback) callback(error)
      }
    );
  };

  displayError = message => {
    toastr.error(message);
  };

  render() {
    return (
      <div>
        <ChangePassword
          handleChangePassword={this.handleChangePassword}
          toggleModal={this.props.toggleModal}
          show={this.props.show}
          isSystemPassword={this.props.isSystemPassword}
        />
      </div>
    );
  }

  componentWillUnmount() {
    toast.dismiss()
    //toast.removeByType("success");
    //toast.removeByType("error");
  }
}

export default withErrorHandler(ChangePasswordContainer);
