import { API_URL } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";
import { ACTIONS } from "UTILS/Constants";

export class ChangePasswordAction extends BaseAction {
  constructor(props) {
    super(props);
    BaseAction.call(this);
  }
  changePasswordAction(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        requestBody: data,
        url: API_URL.CHANGE_PASSWORD
      }).then(
        response => {
          resolve(response);
          this.dispatch({
            type: ACTIONS.PASSWORD_CHANGED
          });
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
