import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import HeaderContainer from "../Header/HeaderContainer";
import AddOrganization from "./AddOrganization";
//Constant
import { TIMEOUT, CONFIG } from "../../../Utils/Constants";
// Strings
import { getMessage } from "CONFIG/i18n";
//Actions
import { organisationAction } from "CONFIG/ActionFactory";
import Spinner from "COMPONENTS/Spinner/Spinner";

class AddOrganizationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      spinnerVisibility: false
    };
  }

  handleCancelClick = () => {
    this.props.history.push(CONFIG.path.superAdmin);
  };

  getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  verifyStorage = (storageElement,callback) => {
    organisationAction.verifyStorage(storageElement).then(data => {
      callback && callback();
    }).catch (error => {
      callback && callback(error);
    });
  }

  handleAddOrganization = (customerWithAdminData, callBackForError) => {
    this.setState({spinnerVisibility:true})
    organisationAction.addCustomerWithAdminAction(customerWithAdminData).then(
      response_data => {
        toastr.success("ORGANIZATION_CREATED_SUCCESSFULLY");
        setTimeout(()=>{ 
          this.setState({spinnerVisibility:false})
          this.handleCancelClick();}, TIMEOUT.SHORT); 
      },
      error => {
        this.setState({spinnerVisibility:false});
        callBackForError(error.code)
      }
    );
  };

  render() {
    return (
        <>
      <HeaderContainer
        title={getMessage("ADD_ORGANIZATION")}
        showModal={false}
        history={this.props.history}
        handleSearchString={""}
        isDashboard={false}
      />
      <AddOrganization
        language={this.props.language}
        verifyStorage={this.verifyStorage}
        handleAddOrganization={this.handleAddOrganization}
        handleCancelClick={this.handleCancelClick}
        sessionsCapacity={this.props.sessionsCapacity}
      />
      <Spinner showSpinner={this.state.spinnerVisibility} />
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    sessionsCapacity:state.OrganisationReducer.sessionsCapacity
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrganizationContainer));
