import React, { Component } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import { validateSetPassword, validatePassword } from "UTILS/regexValidations";

import "../SuperAdmin/Organisations/AddOrganization.less";
import "../Admin/AdminDashboard.less";
import "../App/App.less";
import "./ChangePassword.less";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
import { KEY_CODE_ENTER } from "UTILS/Constants";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.isIE = /*@cc_on!@*/false || !!document.documentMode;
    this.state = {
      errorMessage: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isChangeDisabled: false,
      networkError: this.props.networkError,
      screenWidth: window.innerWidth,
    };
    window.addEventListener('resize', this.updateSize);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errorMessage === "Network Error" &&
      this.state.isChangeDisabled === true
    ) {
      this.setState({ isChangeDisabled: false });
    }
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = (event) =>{
    this.setState({screenWidth: window.innerWidth});
  }

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value.trim(),
      errorMessage: "",
      isChangeDisabled: false
    });
  };

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleChangePasswordClick();
    }
  };

  handleChangePasswordClick = () => {
    let errorMessage="";
    if(!this.state.oldPassword)
      errorMessage = "ENTER_OLD_PASSWORD"
    else if(!this.state.newPassword)
      errorMessage = "ENTER_NEW_PASSWORD"
    else if(!this.state.confirmPassword)
      errorMessage = "ENTER_CONFIRM_PASSWORD"
    else 
      errorMessage = validateSetPassword(
        this.state.newPassword,
        this.state.confirmPassword
      );

    // errorMessage = this.state.oldPassword === this.state.newPassword
    //   ? getMessage("OLD_NEW_PASSWORD_MATCH_MEG") : errorMessage;

    if (errorMessage) {
      this.setState({ errorMessage: errorMessage });
    } else {
      this.setState({ isChangeDisabled: true });
      let request_data = {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword,
      };
      this.props.handleChangePassword(request_data, (error) => {
        if(error)
          this.setState({
            errorMessage: error.code
          })
        else 
          this.setState({
            errorMessage: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          })
      });
    }
  };

  toggleModal = () => {
    this.setState({ errorMessage: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    this.props.toggleModal();
  };

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        { !this.props.isSystemPassword ?
          <Button
            className="buttons borderBtn bgwhite"
            onClick={this.toggleModal}
          >
            {getMessage("CANCEL")}
          </Button> :
          ""
        }
        {" "}
        <Button onClick={this.handleChangePasswordClick} className="buttons customBtn">
          {getMessage("CHANGE")}
        </Button>
      </div>
    );
  };

  setFocus = () => {
    document.getElementById("oldPassword").focus();
  }

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="passwordChangeModal modalMainDiv modal-width-50"
        toggle={this.toggleModal}
        backdrop="static"
        onOpened={this.setFocus}
        centered
      >
        <ModalHeader className="popupHeader p-2">
          <div className="d-flex justify-content-center">{getMessage("CHANGE_PASSWORD")}</div>
          { this.props.isSystemPassword ?
            <div className="d-flex justify-content-center py-2">{getMessage("CHANGE_SYSTEM_PASSWORD_MESSAGE")}</div> :
            ""
          }
        </ModalHeader>
        <ModalBody>
          <Form>

            <FormGroup className="d-flex">
              <Label className="label modal-label col-md-3 col-4">{getMessage("OLD_PASSWORD")}</Label>
              <Input
                type="password"
                name="oldPassword"
                id="oldPassword"
                className="inputFormControl col-md-9 col-7"
                onChange={this.handleInputChange}
                onKeyPress={this.enterPressed}
                required
                value={this.state.oldPassword}
                placeholder={getMessage("OLD_PASSWORD_PLACEHOLDER")}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label className="label modal-label col-md-3 col-4">{getMessage("NEW_PASSWORD")}</Label>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                className="inputFormControl col-md-9 col-7"
                onChange={this.handleInputChange}
                onKeyPress={this.enterPressed}
                required
                value={this.state.newPassword}
                placeholder={getMessage("NEW_PASSWORD_PLACEHOLDER")}
              />
            </FormGroup>

            <FormGroup className="d-flex">
              <Label className="label modal-label col-md-3 col-4">{getMessage("CONFIRM_PASSWORD")}</Label>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="inputFormControl col-md-9 col-7"
                onChange={this.handleInputChange}
                onKeyPress={this.enterPressed}
                required
                value={this.state.confirmPassword}
                placeholder={getMessage("CONFIRM_PASSWORD_PLACEHOLDER")}
              />
            </FormGroup>
            {this.state.errorMessage && (
              <div className="modal-errors">
                {getMessage(this.state.errorMessage)}
              </div>
            )}
          </Form>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}
export default modalScrollBar(ChangePassword);
