import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { getMessage } from "CONFIG/i18n";

import { MODAL_TYPE } from "UTILS/Constants";

import ic_success_modal from "ASSETS/Images/ic_session_scheduled_popup.svg";

//Stylesheet
import "./ModalWithIcon.less"

class ModalWithIcon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal show={this.props.show} className="modal-with-icon" centered>
        <div className="p-3">
          <div className="d-flex justify-content-center">
            <img src={this.props.modalIcon}/>
          </div>
          <div className = "d-flex justify-content-center p-3 text-center">
            {this.props.modalMessage}
          </div>
          {this.props.toggleModal &&
            <div className="d-flex justify-content-center p-3">
              <div className="col-md-5 text-center">
                <button 
                  onClick={this.props.toggleModal}
                  className = {
                    this.props.modalType === MODAL_TYPE.SUCCESS ?
                      "btn customBtn" : 
                      this.props.modalType === MODAL_TYPE.FAILURE ?
                        "btn customBtn modalBtnFailure" : null}>
                  {getMessage("OK")}
                </button>
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  }
}

ModalWithIcon.PropTypes = {
  modalIcon: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  toggleModal: PropTypes.func,
  modalMessage: PropTypes.string
}
  
ModalWithIcon.defaultProps = {
  modalIcon: ic_success_modal,
  modalType: MODAL_TYPE.SUCCESS
}

export default modalScrollBar(ModalWithIcon);
