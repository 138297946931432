import React, { Component } from "react";

import { wsocket } from "WEBSOCKET/wsocket";
import { isIE } from "react-device-detect";
import ComponentSpinner from "COMPONENTS/Spinner/ComponentSpinner/ComponentSpinner";

import IconsPositionBar from "./IconsPositionBar";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import BitRateTile from "COMPONENTS/CommonComponents/BitRate/BitRateTile";

import {
  mediaType,
  mediaStatus
} from "WEBSOCKET/constants";

import { COMPONENT_IN_FOCUS , OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO } from "UTILS/Constants";

import { getUserNameAcronym, isRealwearDevice } from "UTILS/Utility";

import "../Whiteboard/AnnotationBar/AnnotationBar";

import "./Styles.less";

// Assets 
import audio_off from "ASSETS/Images/ic_audio_off_small.svg";
import audio_on from "ASSETS/Images/ic_audio_on_small.svg";
import video_on from "ASSETS/Images/ic_video_on_small.svg";
import video_off from "ASSETS/Images/ic_video_off_small.svg";

export default class VideoComponent extends Component {
  constructor(props) {
    super(props);  
    this.state={
      loaderSize: 8,
      showSpinner: false
    }
  }

  componentDidMount() {
    console.log('VideoPrimary mount :: username: ', this.props.participant.userName);
    this.setSpinnerSize();
    this.props.playPrimaryParticipantVideoStream();
    if (isRealwearDevice() && journey?.camera && this.props.isVideo) {
      this.voiceCommandSetZoomLevelHandler();
      this.voiceCommandFlashOnHandler();
      this.voiceCommandFlashOffHandler();
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.participant.uniqueId !== prevProps.participant.uniqueId) {
      this.props.playPrimaryParticipantVideoStream();
    }

    if (isRealwearDevice() && journey?.camera &&  prevProps.isVideo !== this.props.isVideo) {
      if (this.props.isVideo) {
        this.voiceCommandSetZoomLevelHandler();
        this.voiceCommandFlashOnHandler();
        this.voiceCommandFlashOffHandler();
      } else {
        this.deregisterSetZoomLevelVoiceCommandsHandler();
        this.deregisterFlashOnOffVoiceCommandsHandler();
      }
    }
  }

  setSpinnerSize =()=>{
    if(this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
      this.setState({loaderSize: 16}) // spinner size when primory participant small screen
    } else {
      this.setState({loaderSize: 8}) // spinner size when primory participant small screen
    }
  }

  voiceCommandSetZoomLevelHandler = () => {
    const cameraZoomLevels = JSON.parse(window._env_.REACT_APP_REALWEAR_ZOOM_LEVELS);
    cameraZoomLevels.forEach(cameraZoomLevel => {
      const setZoomLevel = {
        voiceCommand: `VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_${cameraZoomLevel.zoomLevel}`,
        action: () => {
          journey.camera.setCameraZoom(cameraZoomLevel.zoomValue);
        }
      }
      this.props.handleVoiceCommand(setZoomLevel)
    });
  }

  voiceCommandFlashOnHandler = () => {
    const flashOn = {
      voiceCommand: "VOICE_COMMANDS_FLASH_ON",
      action: () => {
        journey.camera.enableCameraFlash(true);
      }
    }
    this.props.handleVoiceCommand(flashOn)
  }

  voiceCommandFlashOffHandler = () => {
    const flashOff = {
      voiceCommand: "VOICE_COMMANDS_FLASH_OFF",
      action: () => {
        journey.camera.enableCameraFlash(false);
      }
    }
    this.props.handleVoiceCommand(flashOff)
  }

  deregisterSetZoomLevelVoiceCommandsHandler = () => {
    const cameraZoomLevels = JSON.parse(window._env_.REACT_APP_REALWEAR_ZOOM_LEVELS);
    cameraZoomLevels.forEach(cameraZoomLevel => {
      this.props.deregisterVoiceCommand(`VOICE_COMMANDS_CAMERA_ZOOM_LEVEL_${cameraZoomLevel.zoomLevel}`);
    });
  }

  deregisterFlashOnOffVoiceCommandsHandler = () => {
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_FLASH_ON");
    this.props.deregisterVoiceCommand("VOICE_COMMANDS_FLASH_OFF");
  }

  componentWillUnmount() {
    this.props.deregisterAllVoiceCommands()
  }

  render() {
    // if video is muted then hide video tag
    let videoStyle={ width: "100%", 
      visibility:(this.props.participant.videoStatus === mediaStatus.MUTED
        || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) ? "hidden":"visible"// if video is muted then hide video tag
    }

    return (
      <div 
        id="videoContainerPrimary"
        className={
          (OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" 
          && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO) 
            ? "col-lg-12 bigVideo px-1 pb-1 "
            : "col-lg-12 px-1 pb-1 "
        }
      >
        <div
          className={
            OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" ?
              this.props.participant.uniqueId !== this.props.uniqueId ?
                "audiovideo2 displayFlex IEflexNone justifyContentFlexCenter" :
                "orangeBorder audiovideo2 displayFlex IEflexNone justifyContentFlexCenter" 
              : this.props.participant.uniqueId !== this.props.uniqueId  ?
                "audiovideo2 displayFlex IEflexNone justifyContentFlexCenter":
                "orangeBorder audiovideo2 displayFlex IEflexNone justifyContentFlexCenter"
          }
          id={this.props.participant.uniqueId+`primary`}
        >
          { this.state.showSpinner &&
          <ComponentSpinner 
            componentId={this.props.participant.uniqueId} 
            loaderSize={this.state.loaderSize + 'px'} />
          }
          {
            window._env_.REACT_APP_SHOW_BITRATE == "true" &&
            !isIE &&
            !(this.props.isSmallVideo &&
            this.props.participant.uniqueId === this.props.participant.uniqueId) &&
            <BitRateTile 
              mediaType = {
                this.props.participant.mediaType === mediaType.AUDIO_VIDEO
                  ? mediaType.VIDEO
                  : mediaType.AUDIO
              }
              participantUniqueID = {this.props.participant.uniqueId}
              uniqueId = {this.props.uniqueId}
              wsocketPeer = {wsocket.audioAndVideoRtcPeer}
            />
          }
          {this.props.participant.uniqueId === this.props.uniqueId ? (
            <div id="videoPrimary" className="parentTagForIE">
              <video
                data-object-fit="contain"
                className={
                  (this.props.participant.videoStatus == mediaStatus.MUTED
                  || this.props.participant.videoStatus == mediaStatus.MUTED_BY_AGENT)
                    ? "videoHeight flipVideo videoWidth"
                    : (this.props.facingMode === null || this.props.facingMode === 'user')
                      ? "videoHeight flipVideo videoWidth"
                      : "videoHeight videoWidth"}
                id="primaryVideo"
                style={videoStyle}
                volume={0}
                autoPlay
                muted
                playsInline
              />
              <CustomTooltip tooltipText={this.props.participant.userName} tooltipId="videoPrimary"/>
            </div>
          ) : 
            <div id="videoPrimary" className="parentTagForIE">
              <video
                data-object-fit="contain"
                className={this.props.participant.uniqueId === this.props.uniqueId 
                  ? (this.props.participant.videoStatus === mediaStatus.MUTED
                  || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
                    ? "videoHeight videoWidth" 
                    : "orangeBorder videoHeight videoWidth"
                  : (this.props.participant.videoStatus === mediaStatus.MUTED
                  || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
                    ? "videoHeight videoWidth" 
                    : "videoHeight videoWidth"}
                id="primaryVideo"
                style={videoStyle}
                volume={0}
                autoPlay
                playsInline
                muted
              />
              <CustomTooltip tooltipText={this.props.participant.userName} tooltipId="videoPrimary"/>
            </div>
          }
          {this.props.participant.videoStatus === mediaStatus.MUTED
          || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT
          || this.props.participant.mediaType === mediaType.AUDIO ? (
              <label className="displayFlex alignItemsCenter justifyContentFlexCenter IEAcronym">{getUserNameAcronym(this.props.participant.userName)}</label>
            ) : null}
          <div>
            {(this.props.participant.uniqueId !== this.props.uniqueId  || window.innerWidth <= 992) &&
              <IconsPositionBar
                sessionKey={this.props.sessionKey}
                componentInFocus={this.props.componentInFocus}
                organiser={this.props.organiser}
                uniqueId={this.props.uniqueId}
                participant={this.props.participant}
                index={this.props.index}
                onOpenCloseEndVideoCallConfirmationModal={this.props.onOpenCloseEndVideoCallConfirmationModal}
                onOpenCloseEndAudioCallConfirmationModal={this.props.onOpenCloseEndAudioCallConfirmationModal}
                isVideo={this.props.isVideo}
                //  showFilmStrip={this.props.showFilmStrip}
                audioOn={audio_on}
                audioOff={audio_off}
                videoOn={video_on}
                videoOff={video_off}
                tooltipVideoId={`muteUnmuteVideoPrimary${this.props.participant.uniqueId}`}
                tooltipAudioId={`muteUnmuteAudioPrimary${this.props.participant.uniqueId}`}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}