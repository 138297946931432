import { ACTIONS } from "UTILS/Constants";

const initialState = {
  roomMetaData: {
    activeScreen: [],
    mediaPipeline: null,
    organiser: "",
    presenterId: "",
    screenSharingParticipantId: null,
    status: "ACTIVE",
    isLocationPopup: false,
    activeAnnotationDetails:{isSelected:false,annotation:""},
    prevAnnotationDetails:{isSelected:false,annotation:""},
  }
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.MAKE_PRESENTER:
      roomMetaData = _.cloneDeep(state.roomMetaData);
      roomMetaData.presenterId = action.newPresenterId;
      return {
        ...state,
        roomMetaData
      };
    case ACTIONS.SET_ROOM_META_DATA:
      return {
        ...state,
        roomMetaData:action.roomMetaData
      };
    case ACTIONS.NOTIFICATION.ADD_ANNOTATION:
      roomMetaData = _.cloneDeep(state.roomMetaData);
      roomMetaData.activeAnnotationDetails = action.activeAnnotationDetails;
      roomMetaData.prevAnnotationDetails = action.activeAnnotationDetails;
      return {
        ...state,
        roomMetaData 
      };
    case ACTIONS.NOTIFICATION.REMOVE_ANNOTATION:
      roomMetaData = _.cloneDeep(state.roomMetaData);
      roomMetaData.activeAnnotationDetails = action.activeAnnotationDetails;
      return {
        ...state,
        roomMetaData
      }; 
    case ACTIONS.SAVE_SCREENSHARING_PARTICIPANT_ID:
      let roomMetaData = _.cloneDeep(state.roomMetaData);
      roomMetaData.screenSharingParticipantId =
        action.screenSharingParticipantId;
      console.log('DEBUG :: in roomMetaDataReducer SAVE_SCREENSHARING_PARTICIPANT_ID action.screenSharingParticipantId');
      return {
        ...state,
        roomMetaData
      };
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return initialState;
    case ACTIONS.LOCATION.START_LOCATION_SHARE:
      roomMetaData = _.cloneDeep(state.roomMetaData);
      roomMetaData.isLocationPopup = action.value;
      roomMetaData.isLocationSharing = action.isLocationSharing;
      return {
        ...state,
        roomMetaData: roomMetaData
      };
    default:
      return state;
  }
}
