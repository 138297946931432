import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isIOS, isSafari, withOrientationChange } from "react-device-detect";

import withErrorHandler from "COMPONENTS/WrappedComponents/withErrorHandler/withErrorHandler";
import { getMessage } from "CONFIG/i18n";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { socketMessage, webRtcMessageId } from "WEBSOCKET/constants";
import Spinner from "COMPONENTS/Spinner/Spinner";
import Dashboard from "./Dashboard";
import WaitingScreen from "COMPONENTS/WaitingScreen/WaitingScreen";
import { getIsSessionJoined } from "../Sessions/selectors";
import { getSaveSessionDataFlag, selectSnapshoteeId, selectShowSnapshot } from "REDUX/Selector/SessionSelector";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { COMPONENT_IN_FOCUS, classNames, ERROR, USER_ROLES, SESSION_TYPE } from "UTILS/Constants";

import {
  selectUserId,
  selectSessionStatus,
  selectUserRole,
  selectScreenSharingParticipantId,
  getShowAudioVideoComponentFlag,
  selectPresenterId,
  getModules
} from "./selectors";
import { getAdmittedParticipants } from "COMPONENTS/CommonComponents/Header/selectors";
import { getShowCoBrowse } from "COMPONENTS/CoBrowse/CoBrowseSelector";

import { sessionActions, whiteboardAction as actionFactory, organisationAction } from "CONFIG/ActionFactory";
import { convertBlobToFile, isCanvasBlank, canvasToBlob, generateZip, isRealwearDevice } from "UTILS/Utility";
import { CONFIG, SESSION_STATUS, CANVAS_TYPES, CANVAS_SOURCE, maxPdfFileSize } from "UTILS/Constants";
import {
  getWebRtcPermissionReply,
  getWebRtcPermissionRequest
} from "../CommonComponents/Header/selectors";
import {
  selectOrganiser,
  selectCurrentParticipantUniqueId,
  selectSessionKey,
  getComponentInFocus
} from "REDUX/Selector/SessionSelector";
import {
  selectCanvases,
  selectActiveCanvasData
} from ".././Whiteboard/selectors";
import {
  getShowCustomerCoBrowse,
  getShowTargetSiteSelectionModal, getEndCustomerCBSession
} from "../CustomerCoBrowse/CustomerCoBrowseSelector"

import { selectSessionType } from "../QRCode/QRCodeComponent/QRCodeSelector";
import { selectCustomerId } from "../MainLayout/selectors";
import { disableBrowseBackBtn, getPdfThumbnailBlob, isMobileOrIpadPortrait } from "../../Utils/Utility";
import { remove } from "lodash";
export class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relativeFilePath: null,
      continuationToken: null,
      modulesAndClassNames : {
        CANVAS: classNames[0]
      },
      spinnerVisibility: false
    }
    this.refreshHandler = ev => {
      ev.returnValue = "refreshed";
      sessionActions.isSessionJoinedAction(false);
    };
    //iOS doesn't fire 'beforeunload'
    this.unloadEventName = (isSafari && isIOS)? "pagehide" : "beforeunload";
    this.isCanvasModified = false;
    this.sessionActive = this.props.isSessionJoined;
  }
  componentDidMount() {  

    // Calling this API in case Of QR-Session to get URI-identifire in sessionLink
    if (this.props.sessionType == SESSION_TYPE.QR_CODE && this.props.userRole === USER_ROLES.AGENT) {
      this.getCustomerInfo();
    }
    // WAAG-5657
    // No need to handel beforeunload if user is on Realwear
    // We can not refresh/reload the page in Realwear
    !isRealwearDevice() && window.addEventListener(this.unloadEventName, this.refreshHandler);
    if (
      this.props.isSessionJoined === false ||
      this.props.isSessionJoined == undefined
    ) {
      console.log("Seems Refreshed CALL LEAVE MEETING"); 
      this.sessionActive = false;
      this.props.history.push(CONFIG.path.reJoin);
    } else {
      this.sessionActive = true;
    }

    // if (
    //   this.props.isSessionJoined === false ||
    //   this.props.isSessionJoined == undefined
    // ) {
    //   this.sessionActive=true
    //   sessionActions.isSessionJoinedAction(true);
    // } else {
    //   console.log("Seems Refreshed CALL LEAVE MEETING");
    //   this.sessionActive=false
    //   sessionActions.isSessionJoinedAction(false);
    //   this.props.history.push(CONFIG.path.reJoin);
    // }

    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
  }

  getCustomerInfo = () => {
    this.setState({spinnerVisibility: true});
    organisationAction.getCustomersAction(this.props.customerId).then(
      responseObject => {  
        this.setState({spinnerVisibility:false});
      }, error => {
        console.error("Error getting customer info", error);
        this.setState({spinnerVisibility: false});
      });
  };

  addModulesAndClassNames = (module) => {
    let modulesAndClassNames = this.state.modulesAndClassNames
    if(module){
      modulesAndClassNames[module] = classNames[Object.keys(modulesAndClassNames).length]
    }

    if(module === COMPONENT_IN_FOCUS.COBROWSE && !(this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply)) ){
      // TODO: use method of react-detect-device instead of screen width
      if(!isMobileOrIpadPortrait()){
        sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.COBROWSE);
        let topleft0 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[0]);
        [modulesAndClassNames[topleft0], modulesAndClassNames.COBROWSE] = [modulesAndClassNames.COBROWSE, modulesAndClassNames[topleft0]]
      }
    }
    
    if(!module){
      let topRight33 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[2])
      let topRight35 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[3])
      let lastModuleName = topRight33 || topRight35
      let modulesAndClassNames = this.state.modulesAndClassNames;
      modulesAndClassNames[lastModuleName] = this.props.componentInFocus !== COMPONENT_IN_FOCUS.AUDIO_VIDEO 
            && this.state.modulesAndClassNames.AUDIO_VIDEO === classNames[1]
            && this.props.showAudioVideoComponent
            && this.props.sortedParticipants.length > 1 ? classNames[3] : classNames[2]
    }
    
    this.setState((prevState) => ({
      ...prevState.modulesAndClassNames,
      modulesAndClassNames
    })) 
  }

  removeModulesAndClassNames = (module) => {
    let modules = this.state.modulesAndClassNames;
    delete modules[module];
  
    // // find element with classname top33 / top35
    // modules.AUDIO_VIDEO = topRight0;
    let topRight33 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[2])
    let topRight35 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[3])
    if(topRight33 || topRight35){
      let lastModuleName = topRight33 || topRight35
      modules[lastModuleName] = classNames[1]
    }

    let topRight0 = Object.keys(this.state.modulesAndClassNames).find(key => this.state.modulesAndClassNames[key] === classNames[0])
    if(!topRight0) {
      modules.CANVAS = classNames[0];
    }
    this.setState((prevState) => ({
      modulesAndClassNames: modules
    })) 
    if(this.props.componentInFocus === COMPONENT_IN_FOCUS[module]){
      sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    }
  }
  componentDidUpdate(prevProps) {

    if (this.props.saveSessionDataFlag !== prevProps.saveSessionDataFlag
      && this.props.saveSessionDataFlag) {
      this.saveActiveCanvasData();
    }

    if( this.props.showAudioVideoComponent !== prevProps.showAudioVideoComponent) {
      this.props.showAudioVideoComponent 
        ? this.addModulesAndClassNames(COMPONENT_IN_FOCUS.AUDIO_VIDEO)
        : this.removeModulesAndClassNames(COMPONENT_IN_FOCUS.AUDIO_VIDEO);
    }

    if(this.props.showCoBrowse !== prevProps.showCoBrowse) {
      this.props.showCoBrowse
        ? this.addModulesAndClassNames(COMPONENT_IN_FOCUS.COBROWSE)
        : this.removeModulesAndClassNames(COMPONENT_IN_FOCUS.COBROWSE);
    }

    if(this.props.showSnapshot !== prevProps.showSnapshot 
      || this.props.snapshoteeId !== prevProps.snapshoteeId
      || this.props.snapshotPermissionReply !== prevProps.snapshotPermissionReply) {
      if(this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply) || 
      (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning)){
        this.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS)
      } else if(!isMobileOrIpadPortrait() && (this.props.isPortrait || this.props.isLandscape) && this.props.showCoBrowse
              && !(this.props.showSnapshot || (this.props.snapshoteeId && this.props.snapshotPermissionReply) || 
              (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning)) ){
        //ipad Landscapean / ipad pro : if Snapshot is close should Co-browse in focus
        this.setComponentInFocus(COMPONENT_IN_FOCUS.COBROWSE)
      }
    }

    if(this.props.showAudioVideoComponent !== prevProps.showAudioVideoComponent || 
      this.props.sortedParticipants.length !== prevProps.sortedParticipants.length || 
      this.props.componentInFocus !== prevProps.componentInFocus){
      if(Object.keys(this.state.modulesAndClassNames)[2]) {
        this.addModulesAndClassNames();
      }
    }
  }
  
  setIsCanvasModified = (flag, action) => {
    console.log("################ setIsCanvasModified", flag, action);
    this.isCanvasModified = flag;
  }

  componentWillUnmount() {
    !isRealwearDevice() && window.removeEventListener(this.unloadEventName, this.refreshHandler);
  }
  getWaitingMessage = () => {
    const { sessionStatus } = this.props;
    switch (sessionStatus) {
      case SESSION_STATUS.CREATED:
        return this.props.organiser !== this.props.uniqueId
          ? getMessage("MESSAGE_FOR_CREATED_SESSION_STATUS")
          : "";
      case SESSION_STATUS.CLOSING:
        return getMessage("MESSAGE_FOR_CLOSING_SESSION_STATUS");
    }
  };

  broadcastDownloadAction = (downloadType) => {
    const message = {
      data: {
        category: socketMessage.category.DOWNLOAD,
        action: socketMessage.subCategories.WHITEBOARD.OBJECT_DOWNLOADED,
        sessionKey: this.props.sessionKey,
        downloadType: downloadType
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  getClassOfHeaderIcon = () => {
    //if (this.props.userRole === USER_ROLES.USER) return "moduleIconActive";
    return this.props.SessionReducer.headerIcons &&
    this.props.SessionReducer.headerIcons[this.props.webRtcPermissionRequest] &&
     this.props.SessionReducer.headerIcons[this.props.webRtcPermissionRequest].class;
  };
  renderSprinner = () => {
    return <Spinner showSpinner={(this.props.activeCanvas && this.props.activeCanvas.canvasId === null) || this.state.spinnerVisibility} />;
  }

  setComponentInFocus = (FocusComponent) => {
    // let componentInFocus = this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS
    //   ? COMPONENT_IN_FOCUS.AUDIO_VIDEO
    //   : COMPONENT_IN_FOCUS.CANVAS

    // when we click on co browse swap 
    
    let modulesAndClassNames = this.state.modulesAndClassNames;
    [modulesAndClassNames[this.props.componentInFocus], modulesAndClassNames[FocusComponent]] = [modulesAndClassNames[FocusComponent], modulesAndClassNames[this.props.componentInFocus]]
    this.setState(() => ({
      modulesAndClassNames
    })) 

    sessionActions.setComponentInFocus(FocusComponent);
  }

  uploadCanvasThumbnail = async (activeCanvas, activeCanvasImage, callback) => {
    const fileName = "canvasThumbnail_" + activeCanvas.canvasId + ".png";
    const zipFileName = "canvasThumbnail.zip";
    const fileObject = await generateZip(
      activeCanvasImage,
      fileName,
      zipFileName
    );
    var formData = new FormData();
    formData.append("canvasId", activeCanvas.canvasId);
    formData.append("thumbnail", fileObject);
    formData.append("sessionKey", this.props.sessionKey);
    formData.append("uniqueId", this.props.uniqueId);
    this.setState({ spinnerVisibility: true });
    actionFactory
    .uploadCanvasThumbnailAction(formData)
    .then((data) => {
      const message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.SET_CANVAS_THUMBNAIL,
          sessionKey: this.props.sessionKey,
          canvasId: data.data.canvasId,
          canvasType: activeCanvas.canvasType,
          activePage:
            this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
              ? this.props.activeCanvasData.activePage
              : -1,
          thumbnailUrl: data.data.thumbnailUrl,
        },
      };
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      this.setState({ spinnerVisibility: false });
      if (callback) callback();
    })
    .catch((error) => {
      toastr.error(error.code);
      this.setState({ spinnerVisibility: false });
      console.log("uploadCanvasThumbnailAction error : ", error);
      callback && callback(error);
    });
  };

  addCanvas = (type, callback, resolution) => {
    let message = "";
    switch (type) {
      case CANVAS_TYPES.PDF:
        message = {
          data: {
            category: socketMessage.category.WHITEBOARD,
            action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
            sessionKey: this.props.sessionKey,
            canvasType: CANVAS_TYPES.PDF,
            canvasSource: CANVAS_SOURCE.PDF,
            activePage: 1,
            fileUrl: this.state.pdfFileUrl,
            relativeFilePath: this.state.relativeFilePath,
            originalFileName: this.state.originalPdfFileName,
            // resolution
          }
        };
        break;
      case CANVAS_TYPES.IMAGE:
        message = {
          data: {
            category: socketMessage.category.WHITEBOARD,
            action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
            sessionKey: this.props.sessionKey,
            canvasType: CANVAS_TYPES.IMAGE,
            canvasSource: CANVAS_SOURCE.IMAGE,
            fileUrl: this.state.pdfFileUrl,
            relativeFilePath: this.state.relativeFilePath,
          }
        };
        break;
      case CANVAS_TYPES.REGULAR:
        message = {
          data: {
            category: socketMessage.category.WHITEBOARD,
            action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
            sessionKey: this.props.sessionKey,
            canvasType: CANVAS_TYPES.REGULAR,
            canvasSource: CANVAS_SOURCE.REGULAR
          }
        };
        break;
      case CANVAS_TYPES.SEARCH_RESULTS:
        message = {
          data: {
            category: socketMessage.category.WHITEBOARD,
            action: socketMessage.subCategories.WHITEBOARD.ADD_CANVAS,
            sessionKey: this.props.sessionKey,
            canvasType: CANVAS_TYPES.SEARCH_RESULTS,
            canvasSource: CANVAS_SOURCE.REGULAR
          }
        };
        break;
    }

    // for pdf canvas send ADD_CANVAS websocket message after thumbnail has been generated 
    // if we don't do so pdfWebviewerInstance becomes null and we can no longer get the thumbnail
    if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
      // TODO: Remove below comment once testing is done - Display file name instead of thumbnail in canvas list for pdf
      // getPdfThumbnailBlob(this.props.activeCanvasData.activePage).then((blob) => {
      //   this.uploadCanvasThumbnail(this.props.activeCanvas, blob, callback);
      //   this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      // }).catch((error) => {
      //   console.warn(error);        
      //   this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      // });
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    } else {
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      if (this.props.activeCanvas.canvasType == CANVAS_TYPES.REGULAR
        && isCanvasBlank(this.props.activeCanvasData.canvasData,
          this.props.activeCanvas.canvasType,
          this.props.activeCanvasData.activePage)) {
        callback && callback();
        return;
      } else if (this.props.activeCanvasRef) {
        console.log("uploadThumbnail: in add canvas...");
        let blob = canvasToBlob(this.props.activeCanvasRef, '#FFFFFF');
        this.uploadCanvasThumbnail(this.props.activeCanvas, blob, callback);
      }
    }
    callback && callback();
  };

  changeActiveCanvas = (canvasId, canvasType, uploadThumbnailFlag = true, callback) => {
    const { activeCanvas, activeCanvasData } = this.props;
    let message = {};
    var canvas = document.getElementById("loookitCanvas");
    if (canvasType === CANVAS_TYPES.PDF) {
      message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.CHANGE_ACTIVE_CANVAS,
          sessionKey: this.props.sessionKey,
          canvasId: canvasId,
          canvasType: canvasType,
          activePage: activeCanvasData.activePage
        }
      };
    } else {
      message = {
        data: {
          category: socketMessage.category.WHITEBOARD,
          action: socketMessage.subCategories.WHITEBOARD.CHANGE_ACTIVE_CANVAS,
          sessionKey: this.props.sessionKey,
          canvasId: canvasId,
          canvasType: canvasType,
          activePage: -1
        }
      };
    }

    // for pdf canvas send CHANGE_ACTIVE_CANVAS websocket message after thumbnail has been generated 
    // if we don't do so pdfWebviewerInstance becomes null and we can no longer get the thumbnail
    if (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF) {
      // TODO: Remove below comment once testing is done - Display file name instead of thumbnail in canvas list for pdf
      // getPdfThumbnailBlob(this.props.activeCanvasData.activePage).then((blob) => {
      //   this.uploadCanvasThumbnail(this.props.activeCanvas, blob, callback);
      //   this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      // }).catch((error) => {
      //   console.warn(error);
      //   this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      // });

      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
      
    } else {
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);

      if (this.props.activeCanvas.canvasType == CANVAS_TYPES.REGULAR
        && isCanvasBlank(this.props.activeCanvasData.canvasData,
          this.props.activeCanvas.canvasType,
          this.props.activeCanvasData.activePage)) {
        callback && callback();
        return;
      }
      if (uploadThumbnailFlag && canvas) {
        console.log('uploadThumbnailFlag: ', uploadThumbnailFlag);
        if (this.isCanvasModified && this.props.activeCanvasRef) {
          let blob = canvasToBlob(this.props.activeCanvasRef, '#FFFFFF');
          this.uploadCanvasThumbnail(activeCanvas, blob, callback);
          this.setIsCanvasModified(false, "changeActiveCanvas");
        }
      }
    }

    callback && callback();
  };

  deleteCanvas = (canvasId, canvasType) => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DELETE_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasId: canvasId,
        canvasType: canvasType,
        activePage: canvasType === CANVAS_TYPES.PDF ? 1 : -1,
        participantId: this.props.participantId
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("sessionKey", this.props.sessionKey);
      formData.append("uniqueId", this.props.uniqueId);
      actionFactory.uploadFileAction(formData).then(
        data => {
          if (data) {
            this.setState({
              pdfFileUrl: data.data.fileUrl,
              relativeFilePath: data.data.relativeFilePath,
              originalPdfFileName: data.data.originalFileName ? data.data.originalFileName : file.name
            });
            resolve(data.data.fileUrl);
          }
        },
        error => {
          if (error.code === ERROR.ROOM_NOT_FOUND) { 
            sessionActions.endSessionAction();
          } else if(error.code === ERROR.ROOM_NOT_ACTIVE){
            sessionActions.setSessionStatus(SESSION_STATUS.CLOSING)
          } else {
            toastr.error(error.code);
          }
          reject(error);
        }
      ).catch(
        error => {
          toastr.error(error.code);
          reject(error);
        }
      );
    });
  };

  sendDeleteObjectMessage = (objectId, subAction) => {
    // this.props.deleteObject(objectId)
    const message = {
      data: {
        sessionKey: this.props.sessionKey,
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DELETE_OBJECT,
        subAction: subAction,
        participantId: this.props.participantId,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        whiteboardData: {
          objectId: objectId
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  saveActiveCanvasData = (callback) => {
    const { activeCanvas, activeCanvasData } = this.props;
    if (activeCanvasData.canvasType !== CANVAS_TYPES.PDF) {
      if (!isCanvasBlank(activeCanvasData.canvasData,
        activeCanvasData.canvasType, activeCanvasData.activePage)) {
        //white background for canvas
        let blob = canvasToBlob(this.props.activeCanvasRef, '#FFFFFF');
        if (blob) {
          console.log("Uploading canvas thumbnail in saveActiveCanvasData");
          this.uploadCanvasThumbnail(activeCanvas, blob, callback);
          this.setIsCanvasModified(false, "changeActiveCanvas");
        };
      }
    }
    callback && callback();
  }

  getPdfListFromCloud = (limit, offset, callback) => {
    actionFactory.getFileStructureFromCloudAction(
      this.props.sessionKey, this.props.userId, limit, offset).then(
      response => {
        if (response.data) {
          callback && callback(response.data);
        }
      },
      error => {
        toastr.error(error.code);
        callback && callback();
      }
    );
  }

  getCloudFileUrl = (request, callback ,useActiveCanvasId = false) => {
    let nextCanvasId = this.props.activeCanvasData.canvasId;
    if (!useActiveCanvasId) {
      let canvasIds = Object.keys(this.props.canvases).map(canvasId => {
        return parseInt(canvasId);
      });
      nextCanvasId = Math.max(...canvasIds) + 1;
    }
    actionFactory.getCloudFileUrlAction({
      sessionKey: this.props.sessionKey,
      userId: this.props.userId,
      canvasId: nextCanvasId,
      selectedFile: { ...request }
    }).then(
      responseData => {
        if (responseData.data) {
          this.setState({
            pdfFileUrl: responseData.data.fileUrl,
            relativeFilePath: responseData.data.relativeFilePath,
            originalPdfFileName: responseData.data.originalFileName ? responseData.data.originalFileName : request.FileName
          });
          callback && callback(responseData.data.fileUrl);
        }
      },
      error => {
        if (error.code === ERROR.FILE_SIZE_EXCEEDS_LIMIT) {
          toastr.error(error.code, [{ key: "size", value: Math.round((maxPdfFileSize / 1000000)) }]);
        } else {
          toastr.error(error.code);
        }
        callback && callback();
      }
    );
  }

  sendPluginNotInstalled = () => {
    const message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.PLUGIN_NOT_INSTALLED,
        sessionKey: this.props.sessionKey,
        participantId: this.props.uniqueId
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  }

  render() {
    const { history, userId } = this.props;
    return (
      <div>
        {this.renderSprinner()}
        {(this.props.organiser !== "" &&
          this.props.uniqueId !== null &&
          this.props.organiser !== this.props.uniqueId &&
          this.props.sessionStatus !== SESSION_STATUS.ACTIVE) ||
          (!this.sessionActive) ? (
            <WaitingScreen
              waitingScreenMessage={this.getWaitingMessage()}
            />
          ) : (
            <Dashboard
              sendWebsocketMessage={this.props.sendWebsocketMessage}
              history={history}
              userId={userId}
              screenSharingParticipantId={this.props.screenSharingParticipantId}
              webRtcPermissionReply={this.props.webRtcPermissionReply}
              webRtcPermissionRequest={this.props.webRtcPermissionRequest}
              headerIconClass={this.getClassOfHeaderIcon}
              activeCanvas={this.props.activeCanvas}
              showAudioVideoComponent={this.props.showAudioVideoComponent}
              broadcastDownloadAction={this.broadcastDownloadAction}
              uniqueId={this.props.uniqueId}
              setComponentInFocus={this.setComponentInFocus}
              modulesAndClassNames={this.state.modulesAndClassNames}
              componentInFocus={this.props.componentInFocus}
              activeCanvasData={this.props.activeCanvasData}
              organiser={this.props.organiser}
              addCanvas={this.addCanvas}
              canvases={this.props.canvases}
              changeActiveCanvas={this.changeActiveCanvas}
              canvasId={this.props.activeCanvasData.canvasId}
              deleteCanvas={this.deleteCanvas}
              uploadFile={this.uploadFile}
              uploadCanvasThumbnail={this.uploadCanvasThumbnail}
              admittedParticipants={this.props.admittedParticipants}
              sessionKey={this.props.sessionKey}
              activeCanvasRef={this.props.activeCanvasRef}
              sendDeleteObjectMessage={this.sendDeleteObjectMessage}
              presenterId={this.props.presenterId}
              setIsCanvasModified={this.setIsCanvasModified}
              participantId={this.props.participantId}
              snapshoteeId={this.props.snapshoteeId}
              showSnapshot={this.props.showSnapshot}
              getPdfListFromCloud={this.getPdfListFromCloud}
              getCloudFileUrl={this.getCloudFileUrl}
              sortedParticipants={this.props.sortedParticipants}
              modules={this.props.modules}
              showCoBrowse={this.props.showCoBrowse}
              sendPluginNotInstalled={this.sendPluginNotInstalled}
              snapshotPermissionReply = {this.props.snapshotPermissionReply}
              latestUpdatedParticipant = {this.props.latestUpdatedParticipant}
              showCustomerCoBrowse = {this.props.showCustomerCoBrowse}
              endCustomerCBSession = {this.props.endCustomerCBSession}
              showTargetSiteSelectionModal = {this.props.showTargetSiteSelectionModal}
              isSnapshotRunning={this.props.isSnapshotRunning}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    SessionReducer: state.SessionReducer,
    userId: selectUserId(state),
    sessionStatus: selectSessionStatus(state),
    userRole: selectUserRole(state),
    screenSharingParticipantId: selectScreenSharingParticipantId(state),
    webRtcPermissionReply: getWebRtcPermissionReply(state),
    webRtcPermissionRequest: getWebRtcPermissionRequest(state),
    isSessionJoined: getIsSessionJoined(state),
    organiser: selectOrganiser(state),
    uniqueId: selectCurrentParticipantUniqueId(state),
    activeCanvas: state.WhiteboardReducer.activeCanvas,
    showAudioVideoComponent: getShowAudioVideoComponentFlag(state),
    sessionKey: selectSessionKey(state),
    componentInFocus: getComponentInFocus(state),
    activeCanvasData: selectActiveCanvasData(state),
    canvases: selectCanvases(state),
    activeCanvasRef: state.WhiteboardReducer.activeCanvasRef,
    participantId: state.SessionReducer.participantId,
    admittedParticipants: getAdmittedParticipants(state),
    presenterId: selectPresenterId(state),
    saveSessionDataFlag: getSaveSessionDataFlag(state),
    snapshoteeId: selectSnapshoteeId(state),
    showSnapshot: selectShowSnapshot(state),
    sortedParticipants: state.ParticipantReducer.sortedParticipants,
    modules: getModules(state),
    showCoBrowse: getShowCoBrowse(state),
    snapshotPermissionReply: state.SessionReducer.snapshotPermissionReply,
    latestUpdatedParticipant: state.ParticipantReducer.latestUpdatedParticipant,
    showCustomerCoBrowse: getShowCustomerCoBrowse(state),
    showTargetSiteSelectionModal: getShowTargetSiteSelectionModal(state),
    customerId: selectCustomerId(state),
    sessionType: selectSessionType(state),
    endCustomerCBSession: getEndCustomerCBSession(state),
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage
    },
    dispatch
  );
};

export default withErrorHandler(withOrientationChange(connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer)));
