import { wsocket } from "WEBSOCKET/wsocket";

import { socketMessage } from "WEBSOCKET/constants";

import socketWorker from 'worker-loader!../socket.worker.js';
var worker;

const handleNetworkStateChange = () => {
  console.log("WebsocketHandler offline");

  // Fix for https://waagunew.atlassian.net/browse/WAAG-5222
  // This might not be required after upgrading to socket.io v4
  // Ref: https://github.com/socketio/socket.io-client/issues/1433
  var messageToPost = {
    action: "transportClose"
  }
  worker && worker.postMessage(JSON.stringify(messageToPost));
}

export const initSocketWorker = () => {
  console.log("In initSocketWorker");
  worker = new socketWorker();

  // Add offline eventlistener on socket connect
  window.addEventListener('offline', handleNetworkStateChange);
}

export const connectWebsocket = (socketAddress, token, handleMessageCallback, handleDisconnectCallback) => {
  console.log("In connectWebsocket");

  var messageToPost = {
    action: "connect",
    payload: {
      socketAddress: socketAddress,
      token: token
    }
  }
  worker && worker.postMessage(JSON.stringify(messageToPost));
  //console.log("Posted message to worker");
  worker && worker.addEventListener("message", msg => {
    //console.log('Message from worker, msg:', msg);
    switch (msg.data.id) {
      case "connected":
        //console.log("Connected to socket server");
        wsocket.io = true;
        break;
      case "message":
        handleMessageCallback(msg.data.payload);
        break;
      case "error":
        let data = {
          data: {
            category: socketMessage.category.ERROR,
            errorType: msg.data.data.type,
          }
        };
        handleMessageCallback(data);
        break;
      case "disconnect":
        console.log('Socket disconnected');
        handleDisconnectCallback(msg.data.payload);
        break;
      default:
        console.warn("Invalid message id from worker:", msg.data.id);
    }
  });
};

export const disconnectWebsocket = () => {
  console.log("In disconnectWebsocket");

  // Remove offline eventlistener on socket disconnect
  window.removeEventListener('offline', handleNetworkStateChange);

  return function (dispatch, getState) {
    var messageToPost = {
      action: "disconnect"
    }
    worker && worker.postMessage(JSON.stringify(messageToPost));
    wsocket.io = false;
  };
};

export const sendWebsocketMessage = (type, data) => {
  return function (dispatch, getState) {
    var messageToPost = {
      action: "emitMessage",
      payload: {
        type: type,
        data: data
      }
    }
    //console.log('messageToPost: ', messageToPost);
    // initSocketWorker();
    worker && worker.postMessage(JSON.stringify(messageToPost));

  };
};
