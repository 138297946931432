import _ from "lodash";
import { ACTIONS } from "UTILS/Constants";

const initialState = {
  kmsConnectionStatus: true,
  showKmsConnectionStatus: false,
  websocketError: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ERROR.KMS_NOT_CONNECTED:
      return { ...state, 
        kmsConnectionStatus: action.value,
        showKmsConnectionStatus: true
      };
    case ACTIONS.ERROR.KMS_CONNECTED:
      return { ...state, kmsConnectionStatus: action.value };
    case ACTIONS.ERROR.ERROR_IN_WEBRTC_MEDIA_SHARING:
      return { ...state, 
        kmsConnectionStatus: false, 
        showKmsConnectionStatus: true
      }
    case ACTIONS.RESET_SHOW_KMS_CONNECTION_STATUS:
      return { ...state, 
        showKmsConnectionStatus: false
      };
    case ACTIONS.SET_WEBSOCKET_ERROR:
      return { ...state, 
        websocketError: action.websocketError
      }; 
    default:
      return state;
  }
}
