import React, { Component } from "react";
import { func } from "prop-types";
import { getMessage, changeLanguage as changeLang } from "CONFIG/i18n";
import { Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { Label } from "reactstrap";
import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";

import { KEY_CODE_ENTER, USER_ROLES } from "../../Utils/Constants";
import { CONFIG, DEFAULT_LANGUAGE } from "UTILS/Constants";
import { getTimeInMillisec } from "UTILS/DateUtils";

import "./Login.less";
import "../App/App.less";
import logo from "../../Assets/Images/img_signin_bg_logo.png";
import DisplayPicture from "../../Assets/Images/img_signin.jpg";

import { sessionActions } from "CONFIG/ActionFactory";
import { isEmailValid } from "UTILS/Utility";

class Login extends Component {
  static propTypes = {
    handleLoginClick: func
  };
  state = {
    userDetails: {
      username: "",
      password: "",
      role: ""
    },
    errorMessage: "",
    networkError: "",
    selectedLanguage: DEFAULT_LANGUAGE,
    isDisabled: false
  };

  componentDidMount() {
    console.log(`timestamp: Login rendered at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
    if(DEFAULT_LANGUAGE !== this.props.language) {
      changeLang(DEFAULT_LANGUAGE)
      sessionActions.setLanguage(DEFAULT_LANGUAGE);
    }
  }

  handleInputChange = name => event => {
    let userDetails = this.state.userDetails;
    if (name === "username") {
      userDetails[name] = event.target.value.trim();
    }
    else {
      userDetails[name] = event.target.value;
    }
    this.setState({
      userDetails: userDetails,
      errorMessage: ""
    });
  };
  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleLoginClick();
    }
  };
  handleLoginClick = () => {
    if (this.isValidLoginInfo(this.state.userDetails)) {
      let newState = {...this.state};
      newState.userDetails.role = this.props.roleByPath;
      this.setState(newState);
      this.props.handleLoginClick(this.state.userDetails);
    }
  };

  isValidLoginInfo = userInfo => {
    if (userInfo.username === "" && userInfo.password === "") {
      this.setState({
        errorMessage: "USERNAME_PASSWORD_EMPTY_MESSAGE"
      });
      return false;
    } else if (!isEmailValid(userInfo.username)) {
      this.setState({
        errorMessage: "INVALID_EMAIL"
      });
      return false;
    }
    return true;
  };

  handleEnableLogin = () => {
    if (this.state.userName === false && this.state.password === false) {
      this.setState({
        isDisabled: false
      });
    } else {
      this.setState({
        isDisabled: true
      });
    }
  };

  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
    this.setState({ selectedLanguage: selectedLanguage });
  };

  getLoginMessagebyRole = () => {
    switch (this.props.roleByPath) {
      case USER_ROLES.ADMIN:
        return getMessage("ADMIN_SIGN_IN");
      case USER_ROLES.SUPER_ADMIN:
        return getMessage("SUPER_ADMIN_SIGN_IN");
      default:
        // return getMessage("AGENT_SIGN_IN");
        return getMessage("SIGN_IN");        
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.networkError !== this.props.networkError) {
      this.setState({ errorMessage: this.props.networkError });
    }
  }
  render() {
    // var ssoURL = getSSOLoginUrl(this.props.ssoDetails);
    return (
      <React.Fragment>
        {(this.props.roleByPath === USER_ROLES.AGENT ||
          this.props.roleByPath === USER_ROLES.ADMIN) && (
          <div className="mt-3">
            <ChangeLanguage
              onSelectChange={this.onSelectChange}
              language={this.props.language}
            />
          </div>
        )}
        <div className="container-fluid SignIn">
          <Row className="Inner">
            <Col md={7} xs={12} lg={7} xl={7}>
              <img className="SignInPicture" src={DisplayPicture} />
            </Col>
            <Col md={5} xs={12} lg={5} xl={5} className="SignInConatiner">
              <Row className="justifyContentFlexCenter noMargin w-100">
                <img className="img-responsive SignInLogo" src={logo} />
              </Row>
              <Row className="justifyContentFlexCenter noMargin w-100">
                <Label className="SignInLabel">
                  {this.getLoginMessagebyRole()}
                </Label>
              </Row>
              <Row className="d-flex noMargin flexDirectionColumn flexWrapNoWrap w-100 ">
                <div className={((this.props.roleByPath === USER_ROLES.AGENT ||
                  this.props.roleByPath === USER_ROLES.ADMIN) && this.props.ssoDetails.isSSOEnabled) ? "displayFlex": ""}>
                  <div className="pr-3">
                    <Label className="SignInUserNameLabel text-left">
                      {getMessage("EMAIL")}
                    </Label>
                    <Row className="pt-1 px-3">
                      <InputGroup>
                        <FormControl
                          type="text"
                          onChange={this.handleInputChange("username")}
                          onKeyPress={this.enterPressed}
                          name="userName"
                          className="SignInUserNameInput"
                          autoFocus
                          placeholder={getMessage("ENTER_EMAIL_PLACEHOLDER")}
                        />
                      </InputGroup>
                    </Row>
                    <Label className="SignInPasswordLabel text-left">
                      {getMessage("PASSWORD")}
                    </Label>
                    <Row className="px-3">
                      <InputGroup>
                        <FormControl
                          type="password"
                          onChange={this.handleInputChange("password")}
                          name="password"
                          className="SignInPasswordInput"
                          onKeyPress={this.enterPressed}
                          placeholder={getMessage("PASSWORD_PLACEHOLDER")}
                        />
                      </InputGroup>
                    </Row>
                    <Row className="justifyContentFlexEnd pr-3">
                      <div className="text-right SignInForgotLabelContainer">
                        <div className="forgotPwd">
                          <span
                            className="cursorPointer"
                            onClick={() => {
                              this.props.history.push(CONFIG.path.forgotPassword);
                            }}
                          >
                            {getMessage("FORGOT_PASSWORD")}
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                  {(this.props.roleByPath === USER_ROLES.AGENT ||
                  this.props.roleByPath === USER_ROLES.ADMIN) &&
                  this.props.ssoDetails.isSSOEnabled ?
                    (
                      <div className="displayFlex">                   
                  <>
                    <div className="verticalDivider"></div>
                    <div className="singleSignOnContainer">
                      {/* <Button className="bgwhite singleSignOn" onClick={() => { window.location.assign(ssoURL) }}> */}
                      <Button
                        className="bgwhite singleSignOn p-4"
                        onClick={() => this.props.ssoLoginWithCognito()}
                      >
                        {getMessage("SINGLE_SIGN_ON")}
                      </Button>
                    </div>
                  </>
                      </div>): null}
                </div>
              </Row>
              <Row className="justifyContentFlexCenter noMargin">
                <div className="w-100">
                  <Button
                    className="customBtn btnSignIn whiteOnClick"
                    onClick={this.handleLoginClick}
                  >
                    {getMessage("SIGN_IN")}
                  </Button>
                  <div className="errors autoHeight">
                    {getMessage(this.state.errorMessage)}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default Login;
