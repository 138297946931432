import { ACTIONS } from "UTILS/Constants";

export const addMessage = messageDetails => {
  return {
    type: ACTIONS.CHAT.ADD_MESSAGE,
    messageDetails: messageDetails
  };
};

export const displayMessage = messageDisplay => {
  return {
    type: ACTIONS.CHAT.DISPLAY_MESSAGE,
    messageDisplay: messageDisplay
  };
};

export const setMessagesRead = value => {
  return {
    type: ACTIONS.CHAT.SET_MESSAGES_READ,
    value: value
  };
};

export const setChatPopOverStateAction = value => {
  return {
    type: ACTIONS.CHAT.SET_CHAT_POP_OVER_STATE,
    value: value
  };
};

export const setChatFontSize = value => {
  return {
    type: ACTIONS.CHAT.SET_CHAT_FONT_SIZE,
    value: value
  };
}