export const getAudioOrVideoRtcPeer = state => state.AudioVideoReducer.audioOrVideoRtcPeer;

export const getStopVideoCallFlag = state => state.SessionReducer.stopVideoCall

export const getStartVideoCallFlag = state => state.SessionReducer.startVideoCall

export const selectTurnServerData = state => state.SessionReducer.turnServer

export const getSortedParticipants = state => state.ParticipantReducer.sortedParticipants

export const getVideoBandwidthSettings = state => {
  return state.SessionReducer.sessionDetails.bandwidthSettings && state.SessionReducer.sessionDetails.bandwidthSettings.filter(bandwidthSetting => bandwidthSetting.module === "AUDIO_VIDEO")[0]
}

export const getScreenshareBandwidthSettings = state => {
  return state.SessionReducer.sessionDetails.bandwidthSettings && state.SessionReducer.sessionDetails.bandwidthSettings.filter(bandwidthSetting => bandwidthSetting.module === "SCREENSHARE")[0]
}

export const getRenegotiateAudio = state => state.SessionReducer.renegotiateAudio;

export const getRenegotiateVideo = state => state.SessionReducer.renegotiateVideo;