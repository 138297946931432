export const selectUserToken = state => {
  return state.UserReducer.userDetails && state.UserReducer.userDetails.token;
};
export const getLanguage = state => {
  return state.LanguageReducer && state.LanguageReducer.language;
};
export const selectSessionKey = state => {
  return (
    state.SessionReducer.userSessionDetails &&
    state.SessionReducer.userSessionDetails.sessionKey)
  || (state.SessionReducer.sessionDetails &&
    state.SessionReducer.sessionDetails.sessionKey);
};

export const getUserName = state => {
  if (state.UserReducer.userDetails) {
    let name = state.UserReducer.userDetails.firstName;
    if (state.UserReducer.userDetails.lastName) {
      name += " " + state.UserReducer.userDetails.lastName;
    }
    return name;
  }
};

export const getUserRole = state => {
  if (state.UserReducer.userDetails) {
    return state.UserReducer.userDetails.role;
  }
};

export const selectUniqueId = state => {
  return state.SessionReducer.uniqueId;
};

export const selectParticipantId = state => state.SessionReducer.participantId;