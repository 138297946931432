import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Card, CardImg } from "reactstrap";
import { withOrientationChange, isMobileSafari, isIE } from 'react-device-detect';

// Custom Component 
import EndAudioVideoCallConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import TotalBitRate from "../CommonComponents/BitRate/TotalBitRate";
import VideoComponent from "./Video";
import VideoPrimary from "./VideoPrimary";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import Spinner from "COMPONENTS/Spinner/Spinner";
import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";

// Actions
import { audioVideoAction } from "CONFIG/ActionFactory";

// Selectors
import {
  selectCurrentParticipantUniqueId,
  selectOrganiser
} from "../../Redux/Selector/SessionSelector";
import { selectSessionKey, selectUserToken } from "../MainLayout/selectors";
import { selectUserRole, selectParticipantId } from "../Whiteboard/selectors";
import { getAdmittedParticipants } from "../CommonComponents/Header/selectors";
import { selectSnapshoteeId, selectSnapshotPermissionReply, selectShowSnapshot} from "REDUX/Selector/SessionSelector";
import { getStopVideoCallFlag, 
  getStartVideoCallFlag, 
  selectTurnServerData,
  getSortedParticipants,
  getVideoBandwidthSettings,
  getRenegotiateAudio,
  getRenegotiateVideo
} from  "./selectors";
import { getShowCustomerCoBrowse } from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseSelector"

// Constants
import { socketMessage, webRtcMessageId, mediaType, mediaStatus } from "WEBSOCKET/constants";
import { USER_ROLES,  COMPONENT_IN_FOCUS, TIMEOUT , LANGUAGE } from "UTILS/Constants";
import { wsocket } from "WEBSOCKET/wsocket";
import { 
  getMediaConstraints,
  getUserMediaError,
  isMobileOriPad,
  isDesktopAndTabletIpadPortrait,
  isIphone13OrHigher
} from "UTILS/Utility";
import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";

// Strings
import { getMessage } from "CONFIG/i18n";

// Images";
import ic_audio_white from "ASSETS/Images/ic_audio_white.svg";
import ic_audio_gray from "ASSETS/Images/ic_audio.svg";
import ic_video_white from "ASSETS/Images/ic_video_white.svg";
import ic_video_gray from "ASSETS/Images/ic_video.svg";
import ic_switch_camera from "ASSETS/Images/ic_switch_camera3.svg";
import ic_swap from "ASSETS/Images/ic_swap.svg";
import ic_call_cut from "ASSETS/Images/ic_call_cut_filled.svg";	
import smoothscroll from 'smoothscroll-polyfill';
import {OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO } from "../../Utils/Constants";

export class AudioVideoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceList: {},
      showEndAudioCallConfirmationModal: false,
      showEndVideoCallConfirmationModal: false,
      webrtcSessionMedia:null,
      facingMode: 'user',
      enableCamera: false,
      spinnerVisibility: false,
      isAudioVideo: false,
      currentRunningMedia: null,
      flimStripOverflow: false,
      isFilmstripVisible: true,
      hasUserClickedFilmStripCaret: false
    };    
    this.currentStream = null;
    this.ondeviceChangeInterval = null;
    this.filmStripTimeout = null;
    this.tooltipBoundaryElement = null;
  }

  componentDidMount() {
    this.getTooltipBoundaryElement();
    smoothscroll.polyfill();
    /**
     * As soon as the Component loaded we'll assume to share own media
     */
    // get device list and set currentlySelectedDevice in the state
    this.getDeviceListAndDisplayStreamOnDeviceChange().then(() => {

      if(wsocket.audioAndVideoRtcPeer[this.props.uniqueId] &&
        wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["AUDIO"]) {

        // on swap audio video container mounts again but media is already 
        // so we just have to sort participants to display in the A/V tiles
        this.sortParticipantToAcronym();

        // on swapping with canvas, need to display media title and icon 
        if(this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO) {
          this.checkMediaTypeToDisplay();
          this.checkMediaTypeForSwitch();
        } else if(this.props.webRtcPermissionRequest === mediaType.AUDIO) {
          this.setState({
            webrtcSessionMedia: mediaType.AUDIO
          })
        }
      } else {
        if(this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO) {
          this.checkMediaTypeForSwitch();
        }
        this.sendShareMediaReqAndSortparticipants(false, true);  // this call is required to create endpoints
      }
    });

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return;
    } 
    
    // add onDeviceChange event for desktop so as to enumerate devices again 
    if(!isMobileOriPad()) {
      // this method is not supported on IE
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/ondevicechange
      navigator.mediaDevices.ondevicechange = (event) => {
        
        clearTimeout(this.ondeviceChangeInterval);
        this.ondeviceChangeInterval = setTimeout(() => {
          
          if(wsocket.audioAndVideoRtcPeer[this.props.uniqueId] &&
            wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["VIDEO"]) {
            if(this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO && 
              (this.props.webRtcPermissionReply || this.props.uniqueId === this.props.organiser)) {
              // get new device list and change stream if currently selected camera is removed
              this.getDeviceListAndDisplayStreamOnDeviceChange(true).then(() => {});
            }
          } else if(this.props.webRtcPermissionReply || this.props.uniqueId === this.props.organiser) {
            this.sendShareMediaReqAndSortparticipants(true, false);
          }
        }, 500);
      }
    }
    
    // TODO: clean up
    window.addEventListener("resize", () => {
      this.setFlimStripOverflow();
    });

    if(OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO == "true") {
      this.setupForEventsInAudioVideoContainer();
    }

    if(isIE) {
      this.setIconPosition();
      window.addEventListener("scroll", this.setIconPosition)
    }
  }

  setIconPosition = () => {
    // find bounds for self icon bar
    let annotationBar = document.getElementById("annotationBar");

    let iconBar = document.getElementById("iconsPosition" + this.props.uniqueId);

    if(!iconBar) return;

    // when canvas is in focus set position of A/V icons based on annotation bar position 
    if(this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS) {
      iconBar.style.position = "fixed"
    } else {
      iconBar.style.position = "static"
      iconBar.style.top = iconBar.style.top + "px"
    }

    if(!annotationBar) return;
    let annotationBarBound = annotationBar.getBoundingClientRect();
    iconBar.style.left = ((annotationBarBound.left+annotationBarBound.right)/2) + "px";
    iconBar.style.top = annotationBarBound.top + 10 + "px"
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("videoComponent")[0];
  }

  sendShareMediaReqAndSortparticipants = (sendShareMediaReq, sortParticipants) => {
    if (!wsocket.io) return;

    var video = document.getElementById("AudioVideoContainer");
    if (video) {
      video.style.display = "block";
    }

    navigator.mediaDevices.enumerateDevices().then(devices => {
      //get camera list
      let deviceList = devices.filter(device => {
        if (device.kind === "videoinput") {
          return device;
        }
      });

      this.setState({
        deviceList: deviceList
      },
      () => {
        // decide media type
        let media =
          this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO  // req is for audio_video
            ? ((this.state.deviceList && this.state.deviceList.length) || isMobileOriPad()) &&  // devicelist is non empty for desktop || user is on mobile
              (this.props.uniqueId === this.props.organiser || !this.props.snapshotPermissionReply) // agent || snapshot  is not runing for user (dont share VIDEO, share only AUDIO)
              ? mediaType.AUDIO_VIDEO
              : mediaType.AUDIO
            : mediaType.AUDIO;
        var message = {
          data: {
            category: socketMessage.category.WEBRTC,
            action: webRtcMessageId.SHARE_WEBRTC_MEDIA,
            sessionKey: this.props.sessionKey,
            media: media
          }
        };
        this.setState({
          webrtcSessionMedia: media,  // holds mediaType that was requested by agent
          currentRunningMedia: media  // mediatype that user is using because, although session mediatype = VIDEO, user can be using only AUDIO if he doesn't have a webcam 
        });

        // sort participants
        if (sortParticipants && this.props.admittedParticipants) {
          this.sortParticipantToAcronym();
        }
        // send SHARE_MEDIA req to create a media pipeline
        if(sendShareMediaReq) {
          this.props.sendWebsocketMessage(
            socketMessage.events.MESSAGE,
            message
          );
        }
      });
    }).catch(function (err) {
      console.error(err.name + ": " + err.message);
    });
    // highlight the header icon
    this.props.headerAction.updateHeaderIconClass(
      this.props.webRtcPermissionRequest,
      "moduleIconActive cursorDisabled",
      " cursorDisabled"
    );
    
  }

  checkMediaTypeToDisplay = () =>{
    _.map(this.props.admittedParticipants, participantObject => {
      if(participantObject.mediaType === mediaType.AUDIO_VIDEO){
        this.setState({webrtcSessionMedia: this.props.webRtcPermissionRequest})
      }
    })
  }

  checkMediaTypeForSwitch = () => {
    let isAudioVideo = false;
    _.filter(this.props.admittedParticipants, participantObject => {
      if(participantObject.uniqueId === this.props.uniqueId 
        && participantObject.mediaType === mediaType.AUDIO_VIDEO
        && (participantObject.videoStatus !== mediaStatus.MUTED || participantObject.videoStatus !== mediaStatus.MUTED_BY_AGENT)) {
        isAudioVideo = true
      }
    })
    this.setState({isAudioVideo: isAudioVideo})
  }

  componentDidUpdate(prevProps) {
    if(this.props.endCall !== prevProps.endCall && this.props.endCall) {
      this.props.webRtcPermissionRequest == mediaType.AUDIO_VIDEO
        ? this.onOpenCloseEndVideoCallConfirmationModal(true)
        : this.onOpenCloseEndAudioCallConfirmationModal(true);
      this.props.setEndCall(false);
    }
    if (this.props.admittedParticipants.length != prevProps.admittedParticipants.length) {
      setTimeout(() => {this.setFlimStripOverflow()},2000)
    }
    if(this.props.isLandscape !== prevProps.isLandscape && !isIE) {
      if(!isMobileSafari || isIphone13OrHigher()) {
        this.replaceTrack()
      } else if (this.props.webRtcPermissionReply && 
        this.props.webRtcPermissionRequest === "AUDIO_VIDEO") {
        // In case of iPhone we have to stop video call and start again.
        // as iPhone iOS12 (not for iOS13) stream freezes on orientation change
        /**
         * 1) we will stop call
         * 2) on change of 'stopVideoCall' flag we call STOP_USER_VIDEO (ws) which is done below in this method
         * 3) on _STOP_USER_VIDEO we set a flag 'startVideoCall' to start video call again 
         */
        audioVideoAction.setStopVideoCallFlag(true);
        this.setCurrentStream(null)
      }
    }

    // usecase: user switches camera on IE and sends STOP_USER_VIDEO websocket call
    // so when receives _STOP_USER_VIDEO from server, it disposes RTC pers and startVideoCall flag is set to true
    // however this startVideoCall props will be different only for that user who stops the stream  
    if(this.props.startVideoCall !== prevProps.startVideoCall && 
      this.props.startVideoCall 
      && (!this.props.snapshotPermissionReply || this.props.snapshoteeId !== this.props.uniqueId)
      && this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO && 
      this.props.webRtcPermissionReply  
    ) {
      this.sendShareMediaReqAndSortparticipants(true, false);
      // reset start video call flag 
      audioVideoAction.setStartVideoCallFlag(false);
    }

    if (!_.isEqual(this.props.admittedParticipants, prevProps.admittedParticipants) 
      || !_.isEqual(this.props.sortParticipants, prevProps.sortParticipants)) {
      this.sortParticipantToAcronym();
    }

    // audio to video promotion for agent
    if(this.props.organiser === this.props.uniqueId 
      && this.props.webRtcPermissionRequest !== prevProps.webRtcPermissionRequest 
      && prevProps.webRtcPermissionRequest === "AUDIO"
      && this.props.webRtcPermissionRequest === "AUDIO_VIDEO") {
      this.sendShareMediaReqAndSortparticipants(true, false);  
    }

    // if snapshot get Closed then start video of user(AUDIO To VIDEO promotion)
    if(this.props.snapshoteeId !== prevProps.snapshoteeId &&  // snapshoteeId is changed
      this.props.snapshoteeId === null &&   // snapshot is closed
      prevProps.snapshoteeId === this.props.uniqueId && // current user was doing snapshot
      this.props.webRtcPermissionReply && 
      this.props.webRtcPermissionRequest === "AUDIO_VIDEO") {
      this.props.headerAction.updateHeaderIconClass(
        mediaType.AUDIO,
        "moduleIcon cursorDisabled",
        " cursorDisabled"
      );
      this.sendShareMediaReqAndSortparticipants(true, false);  
    }

    // audio to video promotion for user
    if((this.props.webRtcPermissionRequest === "AUDIO_VIDEO" && 
    this.props.webRtcPermissionReply !== prevProps.webRtcPermissionReply && 
    this.props.webRtcPermissionReply && this.state.currentRunningMedia === "AUDIO")) {
      this.props.headerAction.updateHeaderIconClass(
        mediaType.AUDIO,
        "moduleIcon cursorDisabled",
        " cursorDisabled"
      );
      this.sendShareMediaReqAndSortparticipants(false, false); 
    }

    if (!_.isEqual(this.props.admittedParticipants, prevProps.admittedParticipants) // if any of users change media type
      ||  this.props.webRtcPermissionReply !== prevProps.webRtcPermissionReply
      && !this.props.webRtcPermissionReply) {
      
      if(this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO){
        this.checkMediaTypeToDisplay();
        this.checkMediaTypeForSwitch();
      }
    }

    //usecase: video call is running and snapshot request is accepted.  
    // we need to stopthe video call and start audio call
    if(this.props.stopVideoCall !== prevProps.stopVideoCall && this.props.stopVideoCall){
      if(this.props.snapshotPermissionReply){
        // stop tracks of current video call Stream if user accept snapshot request
        this.stopTracks(this.currentStream);   
      }
      var message = {
        data: {
          category: socketMessage.category.WEBRTC,
          action: webRtcMessageId.STOP_USER_VIDEO,
          sessionKey: this.props.sessionKey,
          participantId: this.props.uniqueId
        }
      };
      this.props.sendWebsocketMessage(
        socketMessage.events.MESSAGE,
        message
      );
      audioVideoAction.setStopVideoCallFlag(false);
    }
    
  }
  sortParticipantToAcronym = () => {
    let participantList = _.cloneDeep(this.props.sortedParticipants);
    let firstParticipantRole = participantList[0]?.role;
    if(this.props.admittedParticipants) {
      // remove participants from sorted list which are not present in participant list
      _.map(participantList, (sortedParticipant, index) => {
        let participantIndex = this.props.admittedParticipants.findIndex(participant => {
          return participant && sortedParticipant && participant.uniqueId === sortedParticipant.uniqueId
        });
        if(participantIndex == -1) participantList.splice(index, 1)
      });

      // move the 1st participant to 0th index if agent is on 0th index 
      if(participantList.length >= 2 && 
        participantList.length < this.props.sortedParticipants.length &&
        firstParticipantRole !== USER_ROLES.AGENT &&
        this.props.organiser === this.props.uniqueId && 
        participantList[0].role === USER_ROLES.AGENT) {
        //copy 1st index particip[ant to 0th index and delete its copy from 2nd index
        participantList.splice(0, 0, participantList[1]); 
        participantList.splice(2,1); 
      }

      _.map(this.props.admittedParticipants, (participantObject, index) => {

        // check if participant is already present in the sorted list
        let participantIndex = -1;
        participantIndex = participantList.findIndex(participant => {
          return participant.uniqueId === participantObject.uniqueId
        });

        if(participantIndex == -1) {
          // add participant to sorted list only if he has accepted call
          if( participantObject.mediaType && participantObject.mediaType != "NONE" &&  
          !(typeof(participantObject.mediaType) === "object" && 
          !(participantObject.mediaType.audio || participantObject.mediaType.video))) {

            // for organiser:
            // 1st video -> of user who has accepted the request first
            // 2nd video -> self

            // for user:
            // 1st video -> of organiser
            // 2nd video -> self

            if(this.props.organiser === this.props.uniqueId) {
              if(participantObject.role !== USER_ROLES.AGENT && 
                (!participantList ||
                (participantList.length < 2))){
                participantList.splice(0, 0, participantObject);
              } else if (participantObject.role === USER_ROLES.AGENT) {
                participantList.splice(1, 0, participantObject);
              } else {
                participantList.push(participantObject);
              }
            } else {
              if (participantObject.role === USER_ROLES.AGENT) {
                participantList.splice(0, 0, participantObject);
              } else {
                if (participantObject.uniqueId === this.props.uniqueId) {
                  participantList.splice(1, 0, participantObject);
                } else {
                  participantList.push(participantObject);
                }
              }
            }
          }
        } else {
          // reassign object in case some values of object have changed (audio/video mute/unmute)
          participantList[participantIndex] = participantObject;
        }
      });
      audioVideoAction.setSortedParticipants(participantList);
    }
    else {
      audioVideoAction.setSortedParticipants([]);
    }
  }

  /*onMinimize = () => {
    document.getElementById("AudioVideoContainer").style.display = "none";
    this.props.headerAction.updateHeaderIconClass(
      this.props.webRtcPermissionRequest,
      "moduleIconActiveHidden",
      ""
    );
  };*/

  onClose = () => {
    this.currentStream &&
    this.stopTracks(this.currentStream)
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.STOP_ALL_WEBRTC_AUDIO_VIDEO,
        sessionKey: this.props.sessionKey,
        token: this.props.token
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    // Commenting this code block as we calling this before STOP_ALL_WEBRTC_AUDIO_VIDEO action also its already added in reducer in stopAudioVideo function - Fix for WAAG-4212 
    // if(this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
    //   sessionActions.setComponentInFocus(COMPONENT_IN_FOCUS.CANVAS);
    // }
  };

  getTotalWebrtcElement = () => {
    let count = 0;
    for (let i = 0; i < this.props.admittedParticipants.length; i++) {
      if (this.props.admittedParticipants[i].mediaType 
        && this.props.admittedParticipants[i].mediaType !== "NONE" 
        && !(typeof(this.props.admittedParticipants[i].mediaType) === "object" 
        && !(this.props.admittedParticipants[i].mediaType.audio 
          || this.props.admittedParticipants[i].mediaType.video))) {
        count++;
      }
            
    }
    return count;
  };

  onOpenCloseEndAudioCallConfirmationModal = (modalState) => {
    this.setState({
      showEndAudioCallConfirmationModal: modalState
    })
  }

  onOpenCloseEndVideoCallConfirmationModal = (modalState) => {
    this.setState({
      showEndVideoCallConfirmationModal: modalState
    })
  }

  setPrimaryParticipant = (index) => {
    // Swap index of clicked video box with 0th (primary) to show it in bigger size
    // As always the 0th index participant will be shown in bigger size
    let participantList = _.cloneDeep(this.props.sortedParticipants);
    if(participantList[0].uniqueId == this.props.uniqueId && index != 1) {    // self video is on big tile
      participantList.splice(0, 0, participantList[index]);  // put clicked user video at index 0 i.e. big tile
      participantList.splice(index + 1, 1);
    } else {    // self video is already at first position in filmstrip
      [participantList[0], participantList[index]] = [participantList[index], participantList[0]];
    }
    audioVideoAction.setSortedParticipants(participantList);
  }
  stopTracks = (localStream) => {
    this.currentStream = null
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
      const videoTracks = localStream.getVideoTracks();
      for (let i = 0; i !== videoTracks.length; ++i) {
        videoTracks[i].stop();
      }
      let videoElement = document.getElementById(this.props.uniqueId + "video");
      if(videoElement){
        videoElement.srcObject = null;
      }
    }
  }

  replaceTrack = () => {
    this.setEnableCameraFlag(false);
    let currentStream = this.currentStream;
    if(!wsocket.audioAndVideoRtcPeer[
    this.props.uniqueId
    ]) return;
    
    if(!wsocket.audioAndVideoRtcPeer[
    this.props.uniqueId
    ]["VIDEO"]) return;

    let localStream = wsocket.audioAndVideoRtcPeer[
    this.props.uniqueId
    ]["VIDEO"].getLocalStream();

    // stop tracks
    this.stopTracks(localStream);
    this.stopTracks(currentStream);

    let mediaElement = document.getElementById(this.props.uniqueId + "video");
    let constraints = getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings, this.props.isLandscape);

    if(!isMobileOriPad() && this.state.selectedDevice && this.state.selectedDevice.deviceId) {
      constraints.video.deviceId = this.state.selectedDevice.deviceId;
    }
    if(isMobileOriPad() && this.state.facingMode) {
      constraints.video.facingMode = { exact: this.state.facingMode };
    }

    navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      // If video element or peer is not present, stop track
      // because it indicates that component has unmounted
      if(!mediaElement 
        || !(wsocket.audioAndVideoRtcPeer && wsocket.audioAndVideoRtcPeer[this.props.uniqueId])) {
        console.log('Stop track since media element is not present');
        this.stopTracks(stream);
        return;
      }

      if(this.currentStream)
        this.stopTracks(this.currentStream);
      this.currentStream = stream
      let videoTrack = stream.getVideoTracks()[0];
      let sender = wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["VIDEO"].peerConnection.getSenders().find(function (s) {
        return s.track.kind == videoTrack.kind;
      });
      sender.replaceTrack(videoTrack).then(console.log("Replace track successful")).catch((error)=>{
        console.error("Error while replaceTrack: ", error);
      });
      mediaElement.srcObject = stream;
      mediaElement.play()
      .then(() => 
        console.log(`VIDEO has been played successfully`))
      .catch((error) => {
        console.log('error: ', error);
        console.log(`an error occured while playing VIDEO, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
      });
      // if primary participant is self play primary video stream also
      if(this.props.sortedParticipants[0]
        && this.props.uniqueId === this.props.sortedParticipants[0].uniqueId)
        this.playPrimaryParticipantVideoStream();
    }).catch((error) => {
      this.setEnableCameraFlag(true);
      console.error('[Video.js] getUserMedia error: ', error);
    });
  }

  isOverflowing = () => {
    let el = document.getElementById("flimStrip");
    let arrow = document.getElementById("arrowLeft")
    if(el) {
      return el.clientWidth != 0 && el.clientWidth < (el.scrollWidth - 5 - (arrow ? 2*arrow.clientWidth : 0))
    }
  }

  setFlimStripOverflow = () => {
    let overflow = this.isOverflowing();
    if(this.state.flimStripOverflow !== overflow) 
      this.setState({
        flimStripOverflow: overflow
      })
  }

  switchCamera = () => {
    this.showSpinner();
    this.setState({enableCamera: false})
    if(isMobileOriPad()) {
      this.setState({
        facingMode: this.state.facingMode && this.state.facingMode == 'user' ? 'environment' : 'user'
      }, () => {
        this.displayCameraStream();
      });
    } else {
      let currentIndex = this.state.deviceList.findIndex(device => device.deviceId == this.state.selectedDevice.deviceId);
      currentIndex = currentIndex == this.state.deviceList.length - 1 ? 0 : currentIndex + 1;
      this.setState({
        selectedDevice: this.state.deviceList[currentIndex]
      }, () => {
        if(isIE) {
          // handling for IE because replcaeTrack is not supported on IE we have to dispose the RTCPeer and recreate
          audioVideoAction.setStopVideoCallFlag(true);
          // setTimeout(() => this.hideSpinner(),2000);
          this.currentStream = null;
        } else {
          this.displayCameraStream();
        }
      });
    }
  }

  setCurrentStream = (stream) => {
    this.currentStream = stream;
  }

  getDeviceListAndDisplayStreamOnDeviceChange = (onDeviceChange = false) => {
    // onDeviceChange is for to check function gets call after device change
    return new Promise((resolve, reject) => {
      if(!isMobileOriPad()) {
        navigator.mediaDevices.enumerateDevices().then(devices => {
          console.log('[getDeviceListAndDisplayStreamOnDeviceChange] devices list: ', devices);
          this.showSpinner();
          let selectedDevice = this.state.selectedDevice;
          
          //get camera list
          let deviceList = devices.filter(device => device.kind === "videoinput");
          this.setState({
            deviceList: deviceList
          }, () => {
            console.log("Got deviceList in getDeviceListAndDisplayStreamOnDeviceChange");
            resolve("success");
          });

          let currentDeviceId = selectedDevice && selectedDevice.deviceId;
          
          let isCurrentlyStreamingDeviceInNewDeviceList = devices.filter(device => 
            device.deviceId === currentDeviceId).length > 0;

          // onDeviceChange, if same device is being used for streaming, just hide spinner and return
          if(onDeviceChange && isCurrentlyStreamingDeviceInNewDeviceList) {
            this.hideSpinner();
            return;
          } 
          // this evaluates to be undefined when there was only one camera 
          // and it is disconnected
          let deviceToBeSelected = deviceList[0];
          if(deviceToBeSelected && currentDeviceId !== deviceToBeSelected.deviceId) {
            // control will come here for first time and when there is change in device list
            // i. for first time, we don't need to call displayCameraStream because stream is created by rtcPeerConnection 
            // ii. when change in device list, we need to switch stream to available device 
            this.hideSpinner();
            this.setState({
              selectedDevice: deviceToBeSelected,
            }, () => {
              if(onDeviceChange) {
                // displayCameraStream should be called only if currently selected camera get removed
                this.displayCameraStream();
              }
            });
          } else {
            // When there is only one webcam connected and we reconnect that webcam 
            // selectedDevice value is not set to null 
            // due to which stream does not start again 
            // Reason: deviceToBeSelected is undefined
            if(!deviceToBeSelected && onDeviceChange) {
              this.setState({
                selectedDevice: null
              })
            }
            this.hideSpinner();
          }
        });
      } else {
        resolve("success");
      }
    });
  }

  displayCameraStream = () => {
    this.setEnableCameraFlag(false);
    // if (this.state.selectedDevice !== null || this.state.facingMode !== null) {
    let videoConstraints = getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings, this.props.isLandscape);

    if(!isMobileOriPad() && this.state.selectedDevice && this.state.selectedDevice.deviceId) {
      videoConstraints.video.deviceId = this.state.selectedDevice.deviceId;
    }
    if(isMobileOriPad() && this.state.facingMode) {
      videoConstraints.video.facingMode = { exact: this.state.facingMode };
    }

    let mediaElement = document.getElementById(this.props.uniqueId + "video")
    // displayMediaStream is called in 3 scenarios -
    // a. Orientation change
    // b. Switch Camera
    // c. Change webcam
    this.stopTracks(this.currentStream);
    navigator.mediaDevices.getUserMedia(videoConstraints)
    .then(stream => {
      // If video element or peer is not present, stop track
      // because it indicates that component has unmounted
      if(!mediaElement 
        || !(wsocket.audioAndVideoRtcPeer && wsocket.audioAndVideoRtcPeer[this.props.uniqueId])) {
        console.log('Stop track since media element is not present');
        this.stopTracks(stream);
        return;
      }

      if(this.currentStream)
        this.stopTracks(this.currentStream);

      this.currentStream = stream;
      let videoTrack = stream.getVideoTracks()[0];
      let sender = wsocket.audioAndVideoRtcPeer[this.props.uniqueId]["VIDEO"].peerConnection.getSenders().find(function (s) {
        return s.track.kind == videoTrack.kind;
      });
      sender.replaceTrack(videoTrack)
      .then(console.log("Replace track successful"))
      .catch((error)=>{
        console.error("Error while replaceTrack: ", error);
      });

      mediaElement.srcObject = stream;

      mediaElement.play()
      .then(() => 
        console.log(`VIDEO has been played successfully`))
      .catch((error) => {
        this.setEnableCameraFlag(true);
        console.log('error: ', error);
        console.log(`an error occured while playing VIDEO, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
      });
      // if primary participant is self play primary video stream also
      if(this.props.sortedParticipants[0]
        && this.props.uniqueId === this.props.sortedParticipants[0].uniqueId)
        this.playPrimaryParticipantVideoStream();
    })
    .catch((error) => {
      let errorMessage = getUserMediaError(error, mediaType.VIDEO);
      if(errorMessage) {
        toastr.error(errorMessage);
        this.hideSpinner();
        this.setEnableCameraFlag(true);
        return;
      } else {
        if(!isMobileOriPad() && this.deviceList && this.deviceList.length > 1) {
          this.switchCamera();
        }
        else {
          this.hideSpinner();
          this.setEnableCameraFlag(true);
        }
      }          
    });
    // }
  }

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    })
  }

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    })
  }

  setEnableCameraFlag = (value) =>{
    this.setState({
      enableCamera: value
    })
  }

  //To setup Events  when mouse enters in AudioVideoContainer
  setupForEventsInAudioVideoContainer = () => {
    if(!this.state.hasUserClickedFilmStripCaret) {
      var videoComponent = document.getElementById("videoComponent");
      videoComponent.addEventListener("mousemove", this.resetFilmStripTimer, false);
      this.startTimerToHideFilmStrip();
    }
  }

  //Function starts The timer if there is any event
  startTimerToHideFilmStrip = () => {
    // wait 5 seconds before collapse Filmstrip
    this.filmStripTimeout = setTimeout(() => {
      this.setState({
        isFilmstripVisible: false
      });
    }, TIMEOUT.AUDIO_VIDEO_FILM_STRIP_TIMEOUT);
  }

  //Function reset timer if there is any event 
  resetFilmStripTimer = (e) => {
    if(!this.state.isFilmstripVisible) {
      clearTimeout(this.filmStripTimeout);
      this.setState({
        isFilmstripVisible: true
      });         
      this.startTimerToHideFilmStrip();
    }
  }

  setUserHasClickedFilmStripCaret = () => {
    console.log("in setUserHasClickedFilmStripCaret");
    clearTimeout(this.filmStripTimeout);
    this.setState({
      hasUserClickedFilmStripCaret: true
    });
  }

  shouldShowSwapIcon = () => {
    return (!(this.props.showSnapshot || (this.props.snapshoteeId
      && this.props.snapshotPermissionReply)  || 
      (this.props.uniqueId !== this.props.snapshoteeId && this.props.isSnapshotRunning))
      && this.props.componentInFocus !== COMPONENT_IN_FOCUS.AUDIO_VIDEO
      && !this.props.showCustomerCoBrowse
      && isDesktopAndTabletIpadPortrait())
  }

  // this method plays stream of primary participant (big tile)
  playPrimaryParticipantVideoStream = () => {
    let stream = null;
    let primaryParticipant = this.props.sortedParticipants[0];
    let mediaElement = document.getElementById("primaryVideo");

    if(primaryParticipant.uniqueId === this.props.uniqueId) {
      stream = this.currentStream || (wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId] 
      && wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId]['VIDEO']
      && wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId]['VIDEO'].getLocalStream());
    } else {
      stream = wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId] 
      && wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId]['VIDEO']
      && wsocket.audioAndVideoRtcPeer[primaryParticipant.uniqueId]['VIDEO'].getRemoteStream();
    }
    if(stream) {
      if (isIE) {
        if(primaryParticipant.videoStatus !== mediaStatus.MUTED 
           && primaryParticipant.videoStatus !== mediaStatus.MUTED_BY_AGENT
           && primaryParticipant.mediaType === mediaType.AUDIO_VIDEO) {
          attachMediaStream(
            mediaElement,
            stream
          );
        } else {  // in case of muted video / audio call, we need to pass source as null for IE
          attachMediaStream(
            mediaElement, null
          );
        }
      } else {
        mediaElement.srcObject = stream;
        mediaElement.play().then(() => 
          console.log(`primary 'VIDEO' has been played successfully for ${primaryParticipant.userName}`))
        .catch((error) => {
          console.log('error: ', error);
          console.log(`an error occured while playing primary 'VIDEO', error : ${error && error.message ? error.message : JSON.stringify(error)}`)
        });
      }
    } else {
      // if user has not accepted video call request or when audio call is in progress
      // set media element source to null
      if (isIE) {
        attachMediaStream(
          mediaElement, null
        );
      } else {
        mediaElement.srcObject = null;
      }
    }
  }

  render() {
    // remove collapse class of filmstrip if canvas is in focus 
    if(this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS
      && document.getElementById("filmStrip")) {
      document.getElementById("filmStrip").classList.remove("collapse");
    }
    let isVideo =
    this.props.webRtcPermissionRequest == mediaType.AUDIO_VIDEO
      ? true
      : false;

    // the first participant in the list will always be primary (shown in bigger size)
    let primaryParticipant = this.props.sortedParticipants[0];

    // for smooth effect 
    let fullScreenAudioVideoContainer = this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO 
      ? {minHeight: "calc(75vw * 0.56 + 2em)"} 
      // A/V container will be rendered in div with width 75% calculated height to maintain 16:9 ratio
      // 2em is added for audio video header
      : {}
    return (
      <React.Fragment>
        {this.state.spinnerVisibility && <Spinner showSpinner={this.state.spinnerVisibility} />}
        <div id ="videoComponent" className="videoComponent">
          <Card 
            className = {
              OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true"  && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO
                ? "cardBodyNonOverlapping cardBody videoComponent p-0"
                : "cardBody videoComponent p-0"
            }
            style={fullScreenAudioVideoContainer}
          >
            {
              // title bar - media type icon
            }
            <div className={window._env_.REACT_APP_SHOW_BITRATE == "true" && !isIE
              ? "audioVideoHeader"
              : "audioVideoHeader paddingWithoutBitrate"
            }>
              {
                // header contents on left 
              }
              <div className="leftHeader">
                <CardImg
                  src={
                    OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO
                      ? this.state.webrtcSessionMedia === mediaType.AUDIO_VIDEO
                        ? ic_video_gray
                        : ic_audio_gray
                      : this.state.webrtcSessionMedia === mediaType.AUDIO_VIDEO
                        ? ic_video_white
                        : ic_audio_white
                  }
                  className="iconAudio"
                />
                <label className={
                  OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO 
                    ? "title darkGrayTitle"
                    : (this.props.language==LANGUAGE.JA.name && window._env_.REACT_APP_SHOW_BITRATE == "true") ? "title whiteTitle smallFont" : "title whiteTitle"
                }>
                  {
                    // title bar - media type title
                    this.state.webrtcSessionMedia === mediaType.AUDIO
                      ? getMessage("AUDIO")
                      : this.state.webrtcSessionMedia === mediaType.AUDIO_VIDEO
                        ? getMessage("AUDIO_VIDEO")
                        : ""
                  }
                </label>
              </div>
              {
                // header contents on right
              }
              <div className="rightHeader">

                {
                  // bit rate
                  window._env_.REACT_APP_SHOW_BITRATE == "true" &&
                  !isIE &&
                  <TotalBitRate 
                    uniqueId={this.props.uniqueId}
                    parentContainer = {document.getElementById("videoComponent")}
                    sentLabel={getMessage("SENT")}
                    recvLabel={getMessage("RECV")}
                    labelClassname={OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true"  
                    && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO 
                      ? "darkGrayTitle" 
                      : "whiteTitle"}
                  />
                }

                {
                  // switch camera icon
                  this.props.webRtcPermissionRequest === mediaType.AUDIO_VIDEO &&
                  <CardImg
                    src={ic_switch_camera}
                    className={this.state.isAudioVideo && this.state.enableCamera && (this.state.deviceList.length > 1 || isMobileOriPad()) 
                      ? "iconMinimize iconSwitchCamera cursorPointer"
                      : "iconMinimize iconSwitchCamera cursorPointer isDisabled"}
                    onClick={() => {
                      if(this.state.isAudioVideo && this.state.enableCamera && (this.state.deviceList.length > 1 || isMobileOriPad()))
                        this.switchCamera()
                    }}
                  />
                }

                {
                  // canvas - video swap
                  this.shouldShowSwapIcon()
                  && <div>
                    <CardImg
                      src={ic_swap}
                      className="iconSwap d-none d-lg-block"
                      onClick={() => { this.props.setComponentInFocus(COMPONENT_IN_FOCUS.AUDIO_VIDEO) }}
                      id="videoSwap"
                     
                    />
                    <CustomTooltip 
                      tooltipBoundariesElement={this.tooltipBoundaryElement}
                      tooltipText={getMessage("SWAP")} 
                      tooltipId="videoSwap" 
                    />
                  </div>
                }

                {this.props.organiser === this.props.uniqueId &&
                (
                  <>
                    <img
                      src={ic_call_cut}
                      className="videoIcon cursorPointer m-0 px-1 d-flex d-lg-none"
                      id="endCall"
                      onClick={() => {
                        isVideo ?
                          this.onOpenCloseEndVideoCallConfirmationModal(true) :
                          this.onOpenCloseEndAudioCallConfirmationModal(true);
                      }}
                      
                    />
                    <CustomTooltip tooltipText={getMessage("END_CALL")} tooltipId="endCall" />
                  </>
                 
                )}
              </div>
            </div>
            <div className="col-lg-12 IEflexNone noPadding positionStatic">
              <div className="AudioVideo"
                id="AudioVideo"
              >
                {primaryParticipant && primaryParticipant.mediaType !== "NONE" &&  
                !(typeof(primaryParticipant.mediaType) === "object" && 
                !(primaryParticipant.mediaType.audio || primaryParticipant.mediaType.video)) &&
                  <VideoPrimary
                    index={0}
                    uniqueId={this.props.uniqueId}
                    participant={primaryParticipant}
                    participants={this.props.sortedParticipants}
                    sessionKey={this.props.sessionKey}
                    isVideo={isVideo}
                    organiser={this.props.organiser}
                    onOpenCloseEndVideoCallConfirmationModal={this.onOpenCloseEndVideoCallConfirmationModal}
                    onOpenCloseEndAudioCallConfirmationModal={this.onOpenCloseEndAudioCallConfirmationModal}
                    facingMode={this.state.facingMode}
                    componentInFocus={this.props.componentInFocus}
                    playPrimaryParticipantVideoStream={this.playPrimaryParticipantVideoStream}
                    handleVoiceCommand={this.props.handleVoiceCommand}
                    deregisterVoiceCommand={this.props.deregisterVoiceCommand}
                    deregisterAllVoiceCommands={this.props.deregisterAllVoiceCommands}
                  />
                }

                {this.props.sortedParticipants.length > 0 &&
                <React.Fragment>
                  <div 
                    className={
                      this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO 
                        ? OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" ?
                          "flimStripContainer alignItemsCenter justifyContentFlexCenter show scroll-decoration" :
                          "flimStripContainer overlappingFilmStrip alignItemsCenter justifyContentFlexCenter show positionAbsolute scroll-decoration"
                        : this.props.sortedParticipants.length > 4
                          ? "flimStripContainer alignItemsCenter justifyContentFlexCenter show scrollableFilmStrip scroll-decoration"
                          : "flimStripContainer alignItemsCenter justifyContentFlexCenter show scroll-decoration"
                    } 
                    id="flimStripContainer"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                  >
                    {<div 
                      className={
                        ((this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO 
                          && this.state.isFilmstripVisible) 
                          || this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS
                          || this.props.componentInFocus == COMPONENT_IN_FOCUS.COBROWSE)
                          ? "flimStrip" 
                          : "flimStrip collapse"
                      }
                    
                      id="filmStrip"
                    >
                      {this.props.sortedParticipants.map((participant,index) => {
                        {
                          return (participant.mediaType == mediaType.AUDIO || participant.mediaType == mediaType.AUDIO_VIDEO) ? (
                            <VideoComponent
                              index={index}
                              sendWebsocketMessage={this.props.sendWebsocketMessage}
                              setPrimaryParticipant={this.setPrimaryParticipant}
                              primaryParticipant={primaryParticipant}
                              uniqueId={this.props.uniqueId}
                              participant={participant}
                              participants={this.props.sortedParticipants}
                              sessionKey={this.props.sessionKey}
                              key={participant.uniqueId + "video"}
                              totalWebRtcElement={this.props.sortedParticipants.length}
                              deviceList={this.state.deviceList}
                              isVideo={isVideo}
                              snapshoteeId={this.props.snapshoteeId}
                              organiser={this.props.organiser}
                              onOpenCloseEndVideoCallConfirmationModal={this.onOpenCloseEndVideoCallConfirmationModal}
                              onOpenCloseEndAudioCallConfirmationModal={this.onOpenCloseEndAudioCallConfirmationModal}
                              isLandscape={this.props.isLandscape}
                              currentStream={this.currentStream}
                              snapshotPermissionReply={this.props.snapshotPermissionReply}
                              turnServer={this.props.turnServer}
                              showSpinner={this.showSpinner}
                              hideSpinner={this.hideSpinner}
                              enableCamera={this.setEnableCameraFlag}
                              facingMode={this.state.facingMode}
                              selectedDevice={this.state.selectedDevice}
                              setCurrentStream={this.setCurrentStream}
                              headerAction={this.props.headerAction}
                              setFlimStripOverflow={this.setFlimStripOverflow}
                              componentInFocus={this.props.componentInFocus}
                              setScreenShareTopMargin={this.props.setScreenShareTopMargin}
                              videoBandwidthSettings= {this.props.videoBandwidthSettings}
                              renegotiateMediatype={this.props.renegotiateAudio.includes(participant.uniqueId) ? "AUDIO" : 
                                this.props.renegotiateVideo.includes(participant.uniqueId) ? "VIDEO": null}
                              audioVideoAction= {audioVideoAction}
                              playPrimaryParticipantVideoStream={this.playPrimaryParticipantVideoStream}
                            />
                          ) : null;
                        }
                      })}
                    </div>}
                  </div>
                </React.Fragment> }
                { OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO == "true" 
                && this.props.sortedParticipants
                && this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO
                && this.props.sortedParticipants.length > 1
                && (this.state.isFilmstripVisible || this.state.hasUserClickedFilmStripCaret) &&
                  <div
                    id="filmStripHandler"
                    className={
                      this.props.componentInFocus == COMPONENT_IN_FOCUS.AUDIO_VIDEO
                        ? "filmStripCaret"
                        : ""
                    }
                  >
                    <div className="caretStyle">
                      <div
                        data-toggle="collapse"
                        href="#filmStrip"
                        aria-expanded="true"
                        aria-controls="filmStrip"
                        onClick={this.setUserHasClickedFilmStripCaret}
                      />
                    </div>
                  </div>
                } 
              </div>
            </div>
          </Card>
        </div>
        {
          this.state.showEndAudioCallConfirmationModal &&

          <EndAudioVideoCallConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={() => { this.onOpenCloseEndAudioCallConfirmationModal(false) }}
            handleYesClick={() => {
              this.onOpenCloseEndAudioCallConfirmationModal(false);
              this.onClose();
            }}
            message={getMessage("END_AUDIO_CALL_CONFIRMATION_TEXT")}
          />
        }
        {
          this.state.showEndVideoCallConfirmationModal &&

          <EndAudioVideoCallConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={() => { this.onOpenCloseEndVideoCallConfirmationModal(false) }}
            handleYesClick={() => {
              this.onOpenCloseEndVideoCallConfirmationModal(false);
              this.onClose();
            }}
            message={getMessage("END_VIDEO_CALL_CONFIRMATION_TEXT")}
          />
        }
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    // Remove event listener
    navigator.mediaDevices.ondevicechange = null;
    audioVideoAction.resetAudioVideoRenegotiation();
    // remove scroll listener
    window.removeEventListener('scroll', this.setIconPosition);
    this.currentStream && this.stopTracks(this.currentStream)
  }
}

const mapStateToProps = state => ({
  participantId: selectParticipantId(state),
  uniqueId: selectCurrentParticipantUniqueId(state),
  userRole: selectUserRole(state),
  admittedParticipants: getAdmittedParticipants(state),
  sessionKey: selectSessionKey(state),
  token: selectUserToken(state),
  organiser: selectOrganiser(state),
  webRtcPermissionReply:state.SessionReducer.webRtcPermissionReply,
  snapshoteeId:selectSnapshoteeId(state),
  showSnapshot: selectShowSnapshot(state),
  stopVideoCall:getStopVideoCallFlag(state),
  startVideoCall:getStartVideoCallFlag(state),
  snapshotPermissionReply:selectSnapshotPermissionReply(state),
  turnServer:selectTurnServerData(state),
  sortedParticipants:getSortedParticipants(state),
  videoBandwidthSettings: getVideoBandwidthSettings(state),
  showCustomerCoBrowse: getShowCustomerCoBrowse(state),
  renegotiateAudio: getRenegotiateAudio(state),
  renegotiateVideo: getRenegotiateVideo(state),
  language: state.LanguageReducer.language,
  isSnapshotRunning: state.SessionReducer.isSnapshotRunning
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage
    },
    dispatch
  );
};
export default withRealWear(withOrientationChange(connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioVideoContainer)));
