import React, { Component } from "react";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { connect } from "react-redux";

import "./AnnotationBar.less";

import { getMessage } from "CONFIG/i18n";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { isIPad13 } from 'react-device-detect';
//Component 
import PDFListModal from "./PdfListModal";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
// Assets
import ic_rubbe_inverted from "ASSETS/Images/FloatedAnnotations/ic_rubbe_inverted.svg";
import ic_rubber_delete_Inverted from "ASSETS/Images/ic_eraser.svg";
import ic_dustbin_selected_state from "ASSETS/Images/ic_dustbin_selected_state.svg";
import ic_dustbin_unselected_state from "ASSETS/Images/ic_dustbin_unselected_state.svg";
import ic_line_selected_inverted from "ASSETS/Images/FloatedAnnotations/ic_line_selected_inverted.svg";
import ic_freeehandT from "ASSETS/Images/FloatedAnnotations/ic_freeehandT.svg";
import ic_freeehand_inverted from "ASSETS/Images/FloatedAnnotations/ic_freeehand_inverted.svg";
import ic_annotation from "ASSETS/Images/ic_annotation.svg";
import ic_annotation_inverse from "ASSETS/Images/ic_annotation_inverse.svg";
import undo_img from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_undo.svg";
import undo_img_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_undo_selected.svg";

import redo_img from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_redo.svg";
import redo_img_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_redo_selected.svg";

import download_img from "ASSETS/Images/ic_download_annotation_bar.svg";
import download_img_selected from "ASSETS/Images/canvas_icons/ic_download_selected.svg";
import ic_text from "ASSETS/Images/ic_text.svg";
import ic_text_selected_inverted from "ASSETS/Images/FloatedAnnotations/ic_text_selected_inverted.svg";
import ic_shapes_circle_inverse from "ASSETS/Images/canvas_icons/ic_shapes_circle_inverse.svg";
import ic_shapes_inverse from "ASSETS/Images/canvas_icons/ic_shapes_inverse.svg";
import ic_line_inverse from "ASSETS/Images/canvas_icons/ic_line_inverse.svg";

import shape_circle_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_elllipse_circle_selected.svg";
import shape_rectangle_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_shape_selected.svg";
import select_img from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_selection.svg";
import select_img_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_selection_selected.svg";
import selectedDisabled from "ASSETS/Images/ic_selection_disable.svg";
import upload_pdf from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_pdf_upload.svg";
import upload_pdf_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_pdf_upload_selected.svg";
import upload_pdf_from_cloud from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_PDF_cloud.svg";
import upload_image from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_image_upload.svg";
import upload_image_selected from "ASSETS/Images/canvas_icons/Lookit_toolkit_icons/ic_image_upload_selected.svg";

// Selectors
import {
  selectCurrentParticipantUniqueId,
} from "REDUX/Selector/SessionSelector";
import {
  getModules
} from "../../CommonComponents/Header/selectors";

// Constants
import {
  Modules, ANNOTATIONS, USER_ROLES, COLOR, IMAGE_TYPE,
  CANVAS_TYPES, floatedAnnotationBar } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";

// Utility
import { checkIfValidImageFile, checkIfValidPdfFile, convertBlobToFile, base64ImageToBlob, isDesktopAndTabletIpadPortrait } from "UTILS/Utility";

class AnnotationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redo_icon: redo_img,
      undo_icon: undo_img,
      download_icon: download_img,
      collapse: false,
      imageUpload: false,
      showGroupedAnnotations: false,
      showPDFListModalFlag: false,
      renderPDFListModalFlag: false
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.allowEdit !== prevProps.allowEdit 
      && !this.props.allowEdit 
      && this.props.organiser !== this.props.uniqueId) {
      this.props.handleChangeAnnotation(null)
    } 
   
    // in case of canvas switch from pdf to regular/image previously selected annotation should 
    // be active and usable 
    if(this.props.allowEdit !== prevProps.allowEdit 
      && this.props.allowEdit) {
      if(this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.FREE_HAND.NAME) {
        this.props.handleChangeAnnotation({
          value: ANNOTATIONS.FREE_HAND.NAME,
          color: this.props.selectedColor,
          width: ANNOTATIONS.FREE_HAND.WIDTH
        })
      } else if(this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.TEXT){
        this.props.handleChangeAnnotation(this.props.selectedAnnotationByAgent.annotation)
      } else if(this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.ERASER.NAME) {
        this.props.handleChangeAnnotation(ANNOTATIONS.ERASER.NAME)
      } else if(this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.ERASE_OBJECT) {
        this.props.handleChangeAnnotation(ANNOTATIONS.ERASE_OBJECT)
      }
    }

    if ((this.props.selectedAnnotationByAgent.annotation !== prevProps.selectedAnnotationByAgent.annotation
    ) && this.props.organiser !== this.props.uniqueId) {
      if (this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.FREE_HAND.NAME) {
        this.props.handleChangeAnnotation({
          value: ANNOTATIONS.FREE_HAND.NAME,
          color: this.props.selectedColor,
          width: ANNOTATIONS.FREE_HAND.WIDTH
        })
      } else if (this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.TEXT) {
        this.props.handleChangeAnnotation(this.props.selectedAnnotationByAgent.annotation)
      } else if (this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.ERASER.NAME) {
        this.props.handleChangeAnnotation(ANNOTATIONS.ERASER.NAME)
      } else if (this.props.selectedAnnotationByAgent.annotation === ANNOTATIONS.ERASE_OBJECT) {
        this.props.handleChangeAnnotation(ANNOTATIONS.ERASE_OBJECT)
      } else if (this.props.selectedAnnotation !== ANNOTATIONS.SELECTION) {
        this.props.handleChangeAnnotation(null)
      }
    }

    // disable eraser and delete object if canvas is blank 
    if (prevProps.disableClearCanvas !== this.props.disableClearCanvas &&
      this.props.disableClearCanvas &&
      this.props.organiser === this.props.uniqueId &&
      (this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME ||
        this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT)) {
      this.removeAnnotaion(this.props.selectedAnnotation);
    }
  }

  removeAnnotaion = (annotation) => {
    let shapes = floatedAnnotationBar;
    shapes[annotation] = floatedAnnotationBar[annotation];
    Object.keys(floatedAnnotationBar).map(shape => {
      shapes[shape].isSelected = false;
    })

    // send WS message to add/remove annotation
    const {
      ADD_ANNOTATION,
      REMOVE_ANNOTATION
    } = socketMessage.subCategories.NOTIFICATION;
    const message = {
      data: {
        category: socketMessage.category.NOTIFICATION,
        action: shapes[annotation].isSelected
          ? ADD_ANNOTATION
          : REMOVE_ANNOTATION,
        annotation: annotation
      }
    };
    this.props.handleFloatedAnnotation(socketMessage.events.MESSAGE, message);
  }

  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }));
  };

  handleChangeAnnotation = event => {
    let notationType = _.isObject(event) ? event.value : event;
    if (this.props.selectedAnnotation !== notationType) {
      this.props.handleChangeAnnotation(event);
    } else {
      this.props.handleChangeAnnotation(null);
    }
  };

  handleChangeColor = color => {
    this.props.handleChangeColor(color);
  };

  handleChangeFontFamily = event => {
    this.props.handleChangeFontFamily(event);
  };

  undo = () => {
    this.setState({ undo_icon: undo_img })
    this.props.undo();
  };

  redo = () => {
    this.setState({ redo_icon: redo_img })
    this.props.redo();
  };

  downloadCanvas = () => {
    this.setState({ download_icon: download_img }, () => {
      this.props.downloadCanvas();
    })
  };

  clearCanvas = () => {
    this.props.clearCanvas();
  };

  eraseOnCanvas = () => {
    this.props.eraseOnCanvas();
  };
  handleImageUpload = event => {
    const file = event.target.files[0];
    const fileValidationResult = checkIfValidImageFile(file, IMAGE_TYPE.MEETING_UPLOAD_IMAGE);
    var canUploadImage = this.props.organiser === this.props.uniqueId || this.props.isPresenter;
    if (!file) {
      return
    }
    if (!fileValidationResult.valid) {
      toastr.error(fileValidationResult.error);
      return
    }
    if(canUploadImage) {  
      if (file) {
        this.setState({
          imageUpload: true
        });
      }
      loadImage.parseMetaData(file, (data) => {
      //default image orientation

        var orientation = 0;
        //if exif data available, update orientation
        if (data.exif) {
          orientation = data.exif.get('Orientation');
          if (orientation && orientation > 0) {
            var loadingImage = loadImage(
              file,
              (canvas) => {
                var myImage = canvas.toDataURL('image/jpeg');
                let file = convertBlobToFile(base64ImageToBlob(myImage), "image/png", "uploadImg.png");
                this.props.renderImageOnCanvas(file);
              }, {
                canvas: true,
                orientation: 1
              }
            );
          }
          else {
            this.props.renderImageOnCanvas(file);
          }
        }
        else {
          this.props.renderImageOnCanvas(file);
        }
      });
    } else {
      toastr.error("UPLOAD_FILE_PRESENTER_ERROR");
    }
  };

  handlePdfUpload = event => {
    var evt = event || window.event; // this assigns evt with the event object
    var current = evt.target || evt.srcElement; // this assigns current with the event
    var file = event.target.files[0] || current.files[0];
    var canUploadPdf = this.props.modules !== undefined &&
    (this.props.modules.indexOf(Modules.PDF) !== -1 || this.props.isOrganizationTemplateEnabled) &&
    (this.props.organiser === this.props.uniqueId || this.props.isPresenter);

    if(canUploadPdf) {
      if (file) {
        const fileValidationResult = checkIfValidPdfFile(file);

        if (fileValidationResult.valid) {
          console.log("showing canvaslist spinner in handlePdfUpload");
          this.props.showSpinner();
          this.props.renderPdfOnCanvas(file);
        } else {
          toastr.error(fileValidationResult.error);
        }
      }
    } else {
      toastr.error("UPLOAD_FILE_PRESENTER_ERROR");
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { annotation } = nextProps.selectedAnnotationByAgent
    if (this.props.selectedAnnotationByAgent.annotation !== annotation) {
      if (this.props.userRole === USER_ROLES.USER && nextProps.allowEdit) {
        switch (annotation) {
          case ANNOTATIONS.CIRCLE:
          case ANNOTATIONS.RECTANGLE:
          case ANNOTATIONS.LINE:
            this.props.handleChangeAnnotation("remove");
            break;
          default:
            if (annotation === ANNOTATIONS.FREE_HAND.NAME) {
              this.props.handleChangeAnnotation({
                value: ANNOTATIONS.FREE_HAND.NAME,
                color: this.props.selectedColor,
                width: ANNOTATIONS.FREE_HAND.WIDTH
              });
              return;
            }
            this.props.handleChangeAnnotation(annotation);

            break;
        }
      } else if (this.props.userRole !== USER_ROLES.USER) {
        if (annotation === ANNOTATIONS.FREE_HAND.NAME) {
          this.handleChangeAnnotation({
            value: ANNOTATIONS.FREE_HAND.NAME,
            color: this.props.selectedColor,
            width: ANNOTATIONS.FREE_HAND.WIDTH
          });
          return;
        }
        this.props.handleChangeAnnotation(annotation);
      }

    }
  }

  showFloatedAnnotationBar = () => {
    this.props.showFloatedAnnotationBar();
  }

  hasAgentGaveControl = (selectedAnnotation) => {
    if (!_.isEmpty(this.props.selectedAnnotationByAgent)
      && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
      && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.SEARCH_RESULTS) {
      const { isSelected, annotation } = this.props.selectedAnnotationByAgent;
      if (this.props.organiser === this.props.uniqueId
        && (annotation === ANNOTATIONS.ERASER.NAME || annotation === ANNOTATIONS.ERASE_OBJECT)
        && (selectedAnnotation === ANNOTATIONS.ERASER.NAME || selectedAnnotation === ANNOTATIONS.ERASE_OBJECT)
        && this.props.disableClearCanvas) {
        return false;
      }
      if ((this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF 
        && this.props.activeCanvasData.canvasType !== CANVAS_TYPES.SEARCH_RESULTS
        && this.props.organiser === this.props.uniqueId)) {
        return annotation === selectedAnnotation
      } else {
        // console.log(annotation === selectedAnnotation && this.props.allowEdit)
        return annotation === selectedAnnotation && this.props.allowEdit
      }
    }
  }
  setFilledRedoIcon = (event) => {
    this.setState({ redo_icon: redo_img_selected })
  }
  setFilledUndoIcon = () => {
    this.setState({ undo_icon: undo_img_selected })
  }
  setFilledDownloadIcon = () => {
    this.setState({ download_icon: download_img_selected })
  }
  setUndoIcon = () => {
    this.setState({ undo_icon: undo_img })
  }
  setRedoIcon = () => {
    this.setState({ redo_icon: redo_img })
  }
  setDownloadIcon = () => {
    this.setState({ download_icon: download_img })
  }

  renderPDFListModal = () => {
    return (
      <PDFListModal
        closePdfListModal={this.closePdfListModal}
        show={this.state.showPDFListModalFlag}
        renderCloudPdfOnCanvas={this.props.renderCloudPdfOnCanvas}
        fetchPdfListFromCloud={this.fetchPdfListFromCloud}
      />
    )
  }

  handlePdfCloudIconClick = () => {
    this.setState({renderPDFListModalFlag: true});
  }

  fetchPdfListFromCloud= (pageSize = 10, pageOffset = 0, pdfListModalCallback) => {
    this.props.getPdfListFromCloud(pageSize, pageOffset, responseData => {
      if(responseData) {
        if(!this.state.showPDFListModalFlag) {
          this.setState({ showPDFListModalFlag: true }, 
            () => {
              pdfListModalCallback && pdfListModalCallback(responseData)
            });
        } else {
          pdfListModalCallback && pdfListModalCallback(responseData);
        }
      } else {
        this.setState({ 
          showPDFListModalFlag: false,
          renderPDFListModalFlag: false
        }); 
      }
    });
  }

  closePdfListModal = () => {
    this.setState({ 
      showPDFListModalFlag: false,
      renderPDFListModalFlag: false
    });
  }

  render() {
    let annotationBarVisibility = ((this.props.showAudioVideoComponent && isDesktopAndTabletIpadPortrait())
      || this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
      || this.props.isPresenter
      || this.props.organiser === this.props.uniqueId
      || this.props.showSnapshotContainer)
      ? " "
      : " displayNone";
    return (
      <div
        lg="12"
        className={
          this.props.organiser !== this.props.uniqueId
            ? "annotationBar py-1" + annotationBarVisibility
            : "annotationBar" + annotationBarVisibility
        }
        id="annotationBar"
      >
        {this.state.renderPDFListModalFlag && this.renderPDFListModal()}
        {this.props.organiser === this.props.uniqueId &&
          this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
          this.props.activeCanvasData.canvasType !== CANVAS_TYPES.SEARCH_RESULTS && (
          <React.Fragment>
              <>
                <img
                  src={this.state.undo_icon}
                  alt="undo"
                  id="undo"
                  className={
                    this.props.undoEnabled
                      ? "cursorPointer imgSize undoOnClick"
                      : "isDisabled imgSize"
                  }
                  onClick={
                    this.props.organiser !== this.props.uniqueId ||
                    !this.props.undoEnabled
                      ? () => {}
                      : this.undo
                  }
                  onMouseDown={() => !isIPad13 && this.setFilledUndoIcon()}
                  onDrag={this.setUndoIcon}
                  onTouchStart={() => isIPad13 && this.setFilledUndoIcon()}
                />
                <CustomTooltip
                  tooltipText={getMessage("UNDO")}
                  tooltipId="undo"
                />
              </>
              <>
                <img
                  src={this.state.redo_icon}
                  alt="redo"
                  id="redo"
                  className={
                    this.props.redoEnabled
                      ? "cursorPointer imgSize redoOnClick"
                      : "isDisabled imgSize"
                  }
                  onClick={
                    this.props.organiser !== this.props.uniqueId ||
                    !this.props.redoEnabled
                      ? () => {}
                      : this.redo
                  }
                  onMouseDown={() => !isIPad13 && this.setFilledRedoIcon()}
                  onDrag={this.setRedoIcon}
                  onTouchStart={() => isIPad13 && this.setFilledRedoIcon()}
                />
                <CustomTooltip
                  tooltipText={getMessage("REDO")}
                  tooltipId="redo"
                />
              </>
              {this.props.organiser === this.props.uniqueId && (
                <UncontrolledDropdown
                  id="exampleSelect"
                  className="displayInline noBorder cursorPointer selectText"
                  direction="up"
                >
                  <DropdownToggle className="noBorder toggleMenu" id="colorSelector" />
                  <DropdownMenu title="" className="dropdownMenuMobile">
                    {Object.keys(COLOR).map((key, index) => {
                      return (
                        <React.Fragment key={index}>
                          <DropdownItem
                            key={key}
                            id={`color${index}`}
                            className="dropDownItems"
                            onClick={() => this.handleChangeColor(COLOR[key])}
                          >
                            <CustomTooltip
                              tooltipText={getMessage(key)}
                              tooltipId={`color${index}`}
                              tooltipPlacement= {"top"}
                            />
                            <CustomTooltip
                              tooltipText={getMessage("COLOR")}
                              tooltipId="colorSelector"
                            />
                            <span className="checkmark">
                              <div
                                className="checkmark_circle"
                                style={{ backgroundColor: key }}
                              ></div>
                              {this.props.selectedColor === COLOR[key] ? (
                                <>
                                  <div className="checkmark_stem"></div>
                                  <div className="checkmark_kick"></div>
                                </>
                              ) : null}
                            </span>
                          </DropdownItem>
                          <DropdownItem key={index} divider />
                        </React.Fragment>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <>
                <img
                  src={
                    !this.props.showGroupedAnnotations
                      ? ic_annotation
                      : ic_annotation_inverse
                  }
                  alt="GroupedNotations"
                  className="img-fluid  imgSize cursorPointer blinkImg"
                  onClick={this.showFloatedAnnotationBar}
                  id="annotation"
                />
                <CustomTooltip
                  tooltipText={getMessage("ANNOTATIONS")}
                  tooltipId="annotation"
                />
              </>
          </React.Fragment>
        )}
        {this.hasAgentGaveControl(ANNOTATIONS.TEXT) && (
          <>
            <img
              src={
                this.props.selectedAnnotation === ANNOTATIONS.TEXT
                  ? ic_text_selected_inverted
                  : ic_text
              }
              alt="textDecoration"
              id="textDecoration"
              className="imgSize cursorPointer  parentCursorPointer"
              onClick={() => {
                this.handleChangeAnnotation(ANNOTATIONS.TEXT);
              }}
            />
            <CustomTooltip
              tooltipText={getMessage("TEXT_DECORATION")}
              tooltipId="textDecoration"
            />
          </>
        )}

        {this.props.organiser === this.props.uniqueId && (
          <React.Fragment>
            {this.hasAgentGaveControl(ANNOTATIONS.CIRCLE) && (
              <>
                <img
                  src={
                    this.props.selectedAnnotation === ANNOTATIONS.CIRCLE
                      ? shape_circle_selected
                      : ic_shapes_circle_inverse
                  }
                  alt="shapeCircle"
                  id="shapeCircle"
                  className="imgSize cursorPointer "
                  onClick={() =>
                    this.handleChangeAnnotation(ANNOTATIONS.CIRCLE)
                  }
                />
                <CustomTooltip
                  tooltipText={getMessage("CIRCLE")}
                  tooltipId="shapeCircle"
                />
              </>
            )}
            {this.hasAgentGaveControl(ANNOTATIONS.RECTANGLE) && (
              <>
                <img
                  src={
                    this.props.selectedAnnotation === ANNOTATIONS.RECTANGLE
                      ? shape_rectangle_selected
                      : ic_shapes_inverse
                  }
                  alt="shapeRectangle"
                  id="shapeRectangle"
                  className="imgSize cursorPointer "
                  onClick={() =>
                    this.handleChangeAnnotation(ANNOTATIONS.RECTANGLE)
                  }
                />
                <CustomTooltip
                  tooltipText={getMessage("RECTANGLE")}
                  tooltipId="shapeRectangle"
                />
              </>
            )}
            {this.hasAgentGaveControl(ANNOTATIONS.LINE) && (
              <>
                <img
                  src={
                    this.props.selectedAnnotation === ANNOTATIONS.LINE
                      ? ic_line_selected_inverted
                      : ic_line_inverse
                  }
                  alt="shapeLine"
                  id="shapeLine"
                  className=" cursorPointer imgSize"
                  onClick={() => this.handleChangeAnnotation(ANNOTATIONS.LINE)}
                />
                <CustomTooltip
                  tooltipText={getMessage("LINE")}
                  tooltipId="shapeLine"
                />
              </>
            )}
          </React.Fragment>
        )}
        {this.hasAgentGaveControl(ANNOTATIONS.FREE_HAND.NAME) && (
          <React.Fragment>
            <>
              <img
                src={
                  this.props.selectedAnnotation === ANNOTATIONS.FREE_HAND.NAME
                    ? ic_freeehand_inverted
                    : ic_freeehandT
                }
                alt="spotlight"
                id="draw"
                className="imgSize cursorPointer "
                onClick={() =>
                  this.handleChangeAnnotation({
                    value: ANNOTATIONS.FREE_HAND.NAME,
                    color: this.props.selectedColor,
                    width: ANNOTATIONS.FREE_HAND.WIDTH,
                  })
                }
              />
            <CustomTooltip
              tooltipText={getMessage("DRAW")}
              tooltipId="draw"
            />
            </>
            
          </React.Fragment>
        )}
        {this.hasAgentGaveControl(ANNOTATIONS.ERASE_OBJECT) && (
          <>
            <img
              src={
                this.props.selectedAnnotation === ANNOTATIONS.ERASE_OBJECT
                  ? ic_dustbin_selected_state
                  : ic_dustbin_unselected_state
              }
              alt="eraser"
              id="eraseobject"
              className="img-fluid cursorPointer imgSize"
              onClick={() =>
                this.handleChangeAnnotation(ANNOTATIONS.ERASE_OBJECT)
              }
            />
            <CustomTooltip
              tooltipText={getMessage("ERASE_OBJECT")}
              tooltipId="eraseobject"
            />
          </>  
        )}
        {this.hasAgentGaveControl("Eraser") && (
          <React.Fragment>
            <img
              src={
                this.props.selectedAnnotation === ANNOTATIONS.ERASER.NAME
                  ? ic_rubbe_inverted
                  : ic_rubber_delete_Inverted
              }
              id="eraser"
              alt="eraser"
              className="imgSize cursorPointer parentCursorPointer "
              onClick={() =>
                this.handleChangeAnnotation(ANNOTATIONS.ERASER.NAME)
              }
            />
            <CustomTooltip
              tooltipText={getMessage("ERASER")}
              tooltipId="eraser"
            />
            
          </React.Fragment>
        )}
        {!this.props.modules &&
          console.log(
            "Modules in AnnotationBar: ",
            this.props.modulesAvailable
          )}
        {this.props.modules !== undefined &&
          this.props.modules.indexOf(Modules.PDF) !== -1 &&
          this.props.allowUpload &&
          (this.props.organiser === this.props.uniqueId ||
            this.props.isPresenter) && (
          <label htmlFor="uploadPdf">
            <img
              src={
                this.props.selectedAnnotation === ANNOTATIONS.PDF_UPLOAD
                  ? upload_pdf_selected
                  : upload_pdf
              }
              alt="upload_pdf"
              id="uploadPDF"
              className={"imgSize cursorPointer"}
            />
            <CustomTooltip
              tooltipText={getMessage("PDF_UPLOAD")}
              tooltipId="uploadPDF"
            />           
          </label>
        )}
        {this.props.modules !== undefined &&
          this.props.modules.indexOf(Modules.PDF) !== -1 && 
          this.props.isOrganizationTemplateEnabled &&
          this.props.organiser === this.props.uniqueId  && 
          this.props.allowUpload && (
          <label htmlFor="uploadPdfFromCloud">
            <img
              src={upload_pdf_from_cloud}
              alt="upload_pdf"
              className={"imgSize cursorPointer adjustPDFHeight"}
              onClick={this.handlePdfCloudIconClick}
              id="uploadPdfFromCloud"
            />
            <CustomTooltip
              tooltipText={getMessage("PDF_UPLOAD_FROM_CLOUD")}
              tooltipId="uploadPdfFromCloud"
            /> 
            
          </label>
        )}
        {(this.props.organiser === this.props.uniqueId ||
          this.props.isPresenter) && this.props.allowUpload && (
          <label htmlFor="uploadImage">
            <img
              src={
                this.props.selectedAnnotation === ANNOTATIONS.IMAGE_UPLOAD
                  ? upload_image_selected
                  : upload_image
              }
              id="uploadImg"
              alt="upload_img"
              className="imgSize cursorPointer"
            />
            <CustomTooltip
              tooltipText={getMessage("IMAGE_UPLOAD")}
              tooltipId="uploadImg"
            /> 
          </label>
        )}
        <input
          type="file"
          accept="application/pdf"
          onChange={(event) => {
            this.handlePdfUpload(event);
            event.target.value = null;
          }}
          className="inputFile"
          id="uploadPdf"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(event) => {
            this.handleImageUpload(event);
            event.target.value = null;
          }}
          className="inputFile"
          id="uploadImage"
        />
        {this.props.isCanvasDownloadAllowed &&
          this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF && (
          <a id="downloadAnnotation">
            <img
              src={this.state.download_icon}
              alt="downloadAnnotation"
              id="downloadAnnotation"
              className="imgSize cursorPointer downloadOnClick"
              onClick={this.downloadCanvas}
              onMouseDown={() => {
                !isIPad13 && this.setFilledDownloadIcon();
              }}
              onDrag={this.setDownloadIcon}
              onTouchStart={() => {
                isIPad13 && this.setFilledDownloadIcon();
              }}
            />
            <CustomTooltip
              tooltipText={getMessage("DOWNLOAD")}
              tooltipId="downloadAnnotation"
            /> 
          </a>
        )}

        {
          // Selection icon starts
        }
        {this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF &&
        this.props.activeCanvasData.canvasType !== CANVAS_TYPES.SEARCH_RESULTS && (
          <>
              <img
                src={
                  this.props.selectedAnnotation === ANNOTATIONS.SELECTION
                    ? select_img_selected
                    : select_img
                }
                alt="select"
                id="selection"
                className="imgSize cursorPointer"
                onClick={
                  this.props.selectedAnnotation === ANNOTATIONS.SELECTION
                    ? () => this.handleChangeAnnotation(null)
                    : () => this.handleChangeAnnotation(ANNOTATIONS.SELECTION)
                }
              />
              <CustomTooltip
                tooltipText={getMessage("SELECTION")}
                tooltipId="selection"
              /> 
              
          </>
         
        )}
        {
          // Selection icon ends
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    uniqueId: selectCurrentParticipantUniqueId(state),
    modulesAvailable: getModules(state)
  };
};

export default connect(mapStateToProps)(AnnotationBar);