import React, { Component } from "react";
import { Table, Button, Row, Col } from "reactstrap";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { getMessage } from "CONFIG/i18n";
import uuid from "uuid";
import "./AdminDashboard.less";
import "./ReportComponent/SessionList.less";
import "./BulkImportUserErrorTable.less"
import "../SuperAdmin/Organisations/Organisations.less"
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
export default class BulkImportUserErrorTable extends Component {
    
  emailFormat = (cell, row) => {
    const key = uuid.v4();
    return (
      <span className="custom-tooltip" id={`userEmail${key}`}>
        {row.email}
        <CustomTooltip tooltipText={row.email} tooltipId={`userEmail${key}`} />
      </span>
    );
  };

  errorMessageFormat = (cell, row) => {
    const key = uuid.v4();
    return (
      <span className="custom-tooltip" id={`errorMessage${key}`}>
        {getMessage(row.error.code)}
        <CustomTooltip tooltipText={getMessage(row.error.code)} tooltipId={`errorMessage${key}`} />
      </span>
    );
  }

  render() {
    return (
      <div className="errorsTableContainer">
    
        <BootstrapTable 
          data={this.props.errorRecordList}
          tableHeaderClass="errorsTableHeader"
          tableBodyClass='errorsTableBody'
          containerClass=''
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
          // maxHeight= "7em"
          maxWidth="1em"
          printable
        >
          <TableHeaderColumn
            dataField='email' 
            headerTitle={ false }  
            isKey={true}
            columnClassName="align-middle"
            width="30"
            dataFormat={this.emailFormat}
          >
            {getMessage("EMAIL")}
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='error'
            columnClassName='align-middle'
            width="60"
            headerTitle={ false }
            dataFormat={this.errorMessageFormat}
          >
            {getMessage("Error")}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>    
    );
  }
}
