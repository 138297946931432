import {
  webRtcMessageId,
  mediaType,
  mediaStatus
} from "WEBSOCKET/constants";
import {
  ACTIONS,
  USER_ROLES,
  COMPONENT_IN_FOCUS,
  PARTICIPANT_UPDATE_REASON
} from "UTILS/Constants";
import {
  selectSnapshotPermissionReply
} from "REDUX/Selector/SessionSelector";
import { wsocket } from "WEBSOCKET/wsocket";
import { isIE } from "react-device-detect";
import { getAdmittedParticipants } from "COMPONENTS/CommonComponents/Header/selectors";

export const handleMessage = (data, store) => {
  switch (data.action) {
    case webRtcMessageId._WEBRTC_PERMISSION_REQUEST:
      store.dispatch({
        type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION,
        webRtcPermissionReply: null
      });
      store.dispatch({
        type: ACTIONS.SET_WEBRTC_REQUEST_PERMISSION,
        webRtcPermissionRequest: data.media
      });
      break;
    case webRtcMessageId._WEBRTC_PERMISSION_REPLY:
      let webRtcPermissionReply = store.getState().SessionReducer
      .webRtcPermissionReply;
      if (data.isGranted) {
        store.dispatch({
          type: ACTIONS.SET_WEBRTC_REQUEST_PERMISSION,
          webRtcPermissionRequest: data.media
        });
      }
      if (data.isGranted && webRtcPermissionReply === null) {
        store.dispatch({
          type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION,
          webRtcPermissionReply: true
        });
        // store.dispatch({
        //   type: ACTIONS.CALL_MODAL_PERMISSION,
        //   value: true
        // })
      }
      if (!data.isGranted) {
        //TODO show notification
        const permissionStatus = {
          isGranted: data.isGranted,
          uniqueId: data.remoteId,
          media: data.media,
          isPluginInstalled: data.isPluginInstalled
        };
        store.dispatch({
          type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION_STATUS,
          webRtcPermissionReplyStatus: permissionStatus
        });
      }

      if (!data.isPluginInstalled) {
        store.dispatch({
          type: ACTIONS.USER_PLUGIN_NOT_INSTALLED,
          participantId: data.remoteId
        });
      }
      break;
    case webRtcMessageId._SHARE_WEBRTC_MEDIA:
      console.log("_SHARE_WEBRTC_MEDIA" + data.media);
      store.dispatch({
        type: ACTIONS.ERROR.KMS_CONNECTED,
        value: true
      });
      if (data.media === mediaType.SCREEN) {    // Screenshare
        console.log('DEBUG :: _SHARE_WEBRTC_MEDIA data.participantId: ', data.participantId);
        store.dispatch({
          type: ACTIONS.SAVE_SCREENSHARING_PARTICIPANT_ID,
          screenSharingParticipantId: data.participantId
        });
      } else if (       // audio / video call
        data.media === mediaType.AUDIO ||
        data.media === mediaType.AUDIO_VIDEO
      ) {
        store.dispatch({
          type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_TYPE,
          participant: {
            uniqueId: data.participantId,
            mediaType: data.media
          },
          updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_TYPE
        });
        store.dispatch({
          type: ACTIONS.SET_CALL_MEDIA_TYPE,
          mediaType: data.media
        });
        store.dispatch({
          type: ACTIONS.CALL_MODAL_PERMISSION,
          value: true
        })
      }
      break;

    case webRtcMessageId._NEW_WEBRTC_MEDIA:
      console.log("_NEW_WEBRTC_MEDIA", JSON.stringify(data));
      if (data.media === mediaType.SCREEN) {
        console.log('DEBUG :: _NEW_WEBRTC_MEDIA data.participantId: ', data.participantId);
        store.dispatch({
          type: ACTIONS.SAVE_SCREENSHARING_PARTICIPANT_ID,
          screenSharingParticipantId: data.participantId
        });
      } else if (
        data.media === mediaType.AUDIO ||
        data.media === mediaType.AUDIO_VIDEO
      ) {
        store.dispatch({
          type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_TYPE,
          participant: {
            uniqueId: data.participantId,
            mediaType: data.media
          },
          updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_TYPE
        });
      }
      break;
    case webRtcMessageId._STOP_SCREEN_SHARING:
      stopScreenSharing(store);
      break;
    case webRtcMessageId._SDP_ANSWER:
      console.log("_SDP_ANSWER", JSON.stringify(data));
      if (data.media === mediaType.SCREEN) {  // screen share
        wsocket.screenShareRtcPeer &&
          wsocket.screenShareRtcPeer.processAnswer(data.sdpAnswer, function (
            error
          ) {
            if (error) {
              console.error("processAnswer ", error);
            }
          });
      } else if (     // audio or video call
        data.media === mediaType.AUDIO
        || data.media === mediaType.AUDIO_VIDEO
        || data.media === mediaType.VIDEO
      ) {

        wsocket.audioAndVideoRtcPeer[data.participantId] &&
          wsocket.audioAndVideoRtcPeer[data.participantId][data.media] &&
          wsocket.audioAndVideoRtcPeer[data.participantId][data.media].processAnswer(
            data.sdpAnswer,
            function (error) {
              if (error) {
                console.error("processAnswer ", error);
              }
            }
          );
        /*Check if Audio is disabled for particular user before allowing the Microphone permission of browser 
          then audio of rtc peer is not disabled. So after get sdp we need to set audioEnabled false to mute that user.
        (after allowing the browser's permission gets SDP offer)*/
        store.getState().ParticipantReducer.participants.forEach(participant => {

          if ((participant.audioStatus === mediaStatus.MUTED ||
            participant.audioStatus === mediaStatus.MUTED_BY_AGENT) &&
            wsocket.audioAndVideoRtcPeer[participant.uniqueId] &&
            wsocket.audioAndVideoRtcPeer[participant.uniqueId].AUDIO &&
            wsocket.audioAndVideoRtcPeer[participant.uniqueId].AUDIO.audioEnabled) {

            wsocket.audioAndVideoRtcPeer[participant.uniqueId].AUDIO.audioEnabled = false
          }
        })
        //TODO: Since we have a requirement of having user's join as muted
      } else if (data.media === mediaType.SNAPSHOT) {
        wsocket.snapshotRtcPeer && wsocket.snapshotRtcPeer.processAnswer(data.sdpAnswer, function (
          error
        ) {
          if (error) {
            console.error("processAnswer ", error);
          }
        });
      }
      break;
    case webRtcMessageId._ICE_CANDIDATE:
      console.log("_ICE_CANDIDATE: ", JSON.stringify(data));
      if (data.media === mediaType.SCREEN && wsocket.screenShareRtcPeer) {
        wsocket.screenShareRtcPeer.addIceCandidate(data.candidate, function (
          error
        ) {
          if (error) {
            console.error("addIceCandidate ", error);
          }
        });
      } else if (
        (data.media === mediaType.AUDIO
          || data.media === mediaType.AUDIO_VIDEO
          || data.media === mediaType.VIDEO) &&
        wsocket.audioAndVideoRtcPeer[data.participantId] &&
        wsocket.audioAndVideoRtcPeer[data.participantId][data.media]
      ) {

        wsocket.audioAndVideoRtcPeer[data.participantId][data.media].addIceCandidate(
          data.candidate,
          function (error) {
            if (error) {
              console.error("addIceCandidate ", error);
            }
          }
        );
      } else if (data.media === mediaType.SNAPSHOT && wsocket.snapshotRtcPeer) {
        wsocket.snapshotRtcPeer.addIceCandidate(data.candidate, function (
          error
        ) {
          if (error) {
            console.error("addIceCandidate ", error);
          }
        });
      }
      break;
    case webRtcMessageId._STOP_ALL_WEBRTC_AUDIO_VIDEO:
      stopAudioVideoCall(store);
      store.dispatch({
        type: ACTIONS.CALL_STATUS,
        value: true
      });
      break;
    //SNAPSHOT START
    case webRtcMessageId._SNAPSHOT_PERMISSION_REQUEST:
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY,
        snapshotPermissionReply: null
      });
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOTER_ID,
        snapshoterId: data.snapshoterId
      });
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOTEE_ID,
        snapshoteeId: store.getState().SessionReducer.uniqueId
      });
      break;
    case webRtcMessageId._SNAPSHOT_PERMISSION_REPLY:
      //Check snapshotee user already in Video call
      //NOW Set flag to create rtc peer for snapshot

      // let state = store.getState();
      // let isVideoCallRunning = false
      // if(data.isGranted && 
      //     state.RoomMetaDataReducer.roomMetaData.organiser !== state.SessionReducer.uniqueId && 
      //     data.isUserWebCamPresent !== false){
      //   state.ParticipantReducer.participants.forEach(participant => {
      //     if (participant.uniqueId === data.snapshoteeId && !isVideoCallRunning) {
      //       isVideoCallRunning = participant.mediaType === mediaType.AUDIO_VIDEO
      //     }
      //   })
      // }
      // //is Snapshotee user not in call then proceed for normal snapshot execution
      // if(!isVideoCallRunning){
      const permissionStatus = {
        isUserWebCamPresent: data.isUserWebCamPresent,
        isPluginInstalled: data.isPluginInstalled,
        isPluginExpired: data.isPluginExpired,
      }
      store.dispatch({
        type: ACTIONS.SET_SNASHOT_PERMISSION_STATUS,
        snapshotPermissionStatus: permissionStatus
      });
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY,
        snapshotPermissionReply: data.isGranted
      });
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOTEE_ID,
        snapshoteeId: data.isGranted ? data.snapshoteeId : ""
      });

      // }
      // else{
      //   // if Snapshotee user is in video call then stop video call first
      //   // store.dispatch({
      //   //   type: ACTIONS.SET_SNAPSHOT_PERMISSION_REPLY,
      //   //   snapshotPermissionReply: true
      //   // });
      //   store.dispatch({
      //     type:ACTIONS.STOP_VIDEO_CALL,
      //     value:true
      //   })
      // }
      break;
    case webRtcMessageId._SWITCH_SNAPSHOT_CAMERA:
      if (wsocket.snapshotRtcPeer) {
        wsocket.snapshotRtcPeer.dispose();
        wsocket.snapshotRtcPeer = null;
        store.dispatch({
          type: ACTIONS.SET_SWITCH_CAMERA,
          isPeerDisposed: true,
        });
      }
      break;
    case webRtcMessageId._SNAPSHOT_TAKEN:
      if(data.isSnapshotTaken === true) {
        snapshotTaken(data, store);
      }
      else if (data.isSnapshotTaken === false) {
        stopSnapshot(data, store);
      }      
      break;
    //SNAPSHOT END
    case webRtcMessageId._MEDIA_TOGGLED:
      if (data.media === mediaType.AUDIO) {
        if (wsocket.audioAndVideoRtcPeer[data.remoteId] &&
          wsocket.audioAndVideoRtcPeer[data.remoteId][data.media]) {
          wsocket.audioAndVideoRtcPeer[data.remoteId][data.media].audioEnabled =
            (data.mediaStatus === mediaStatus.MUTED
              || data.mediaStatus === mediaStatus.MUTED_BY_AGENT)
              ? false
              : true;
        }

        if (!isIE && data.remoteId === store.getState().SessionReducer.uniqueId) {
          let sender = wsocket.audioAndVideoRtcPeer[data.remoteId]["AUDIO"].peerConnection.getSenders().find(function (s) {
            return s.track.kind == 'audio';
          });
          if (sender && sender.track) {
            // if audioStatus is muted enable audio for unmuting
            sender.track.enabled = data.mediaStatus === mediaStatus.UNMUTED ? true : false;
            console.log('sender.track.enabled: ', sender.track.enabled, data);
          }
        }

        store.dispatch({
          type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_STATUS,
          participant: {
            uniqueId: data.remoteId,
            audioStatus: data.mediaStatus,
            mediaStatusChangeByAgent: data.participantId !== data.remoteId
          },
          uniqueId: store.getState().SessionReducer.uniqueId,
          updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_STATUS
        });
      } else if (data.media === mediaType.AUDIO_VIDEO) {
        if (wsocket.audioAndVideoRtcPeer[data.remoteId] &&
          wsocket.audioAndVideoRtcPeer[data.remoteId][mediaType.VIDEO]) {
          wsocket.audioAndVideoRtcPeer[data.remoteId][mediaType.VIDEO].videoEnabled =
            (data.mediaStatus === mediaStatus.MUTED
              || data.mediaStatus === mediaStatus.MUTED_BY_AGENT)
              ? false
              : true;
        }

        store.dispatch({
          type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_STATUS,
          participant: {
            uniqueId: data.remoteId,
            videoStatus: data.mediaStatus,
            mediaStatusChangeByAgent: data.participantId !== data.remoteId
          },
          uniqueId: store.getState().SessionReducer.uniqueId,
          updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_STATUS
        });
      }
      break;
    // KMS Status Handling
    case webRtcMessageId._KMS_DISCONNECTED:
      store.dispatch({
        type: ACTIONS.ERROR.KMS_NOT_CONNECTED,
        value: false
      });
      break;
    // case webRtcMessageId._KMS_RECONNECTED:
    //   store.dispatch({
    //     type: ACTIONS.ERROR.KMS_CONNECTED,
    //     value: true
    //   });
    //   break;
    case webRtcMessageId._WEBRTC_SESSION_STARTED:
      store.dispatch({
        type: ACTIONS.SET_WEBRTC_SESSION_MEDIA,
        value: data.media
      });
      break;
    case webRtcMessageId._RELEASE_ALL_REMOTE_MEDIA:
      if (store.getState().SessionReducer.webRtcPermissionRequest === mediaType.AUDIO_VIDEO
        || store.getState().SessionReducer.webRtcPermissionRequest === mediaType.AUDIO) {
        stopAudioVideoCall(store);
      }
      if (wsocket.screenShareRtcPeer) {
        stopScreenSharing(store);
      }
      if (store.getState().SessionReducer.snapshoteeId) {
        stopSnapshot(data, store);
      }
      break;
    case webRtcMessageId._STOP_USER_VIDEO:
      console.log('_STOP_USER_VIDEO: ');
      // Video call need to stop first in case user accept snapshot reqest.
      let stateForStopUserVideo = store.getState()
      if (
        data.participantId &&
        wsocket.audioAndVideoRtcPeer[data.participantId] !== undefined &&
        wsocket.audioAndVideoRtcPeer[data.participantId].VIDEO
      ) {
        wsocket.audioAndVideoRtcPeer[data.participantId].VIDEO.dispose(); // dispose RTC peer
        delete wsocket.audioAndVideoRtcPeer[data.participantId].VIDEO;
      }
      //update participant mediaType = AUDIO 
      store.dispatch({
        type: ACTIONS.UPADTE_PARTICIPANT_MEDIA_TYPE,
        participant: {
          uniqueId: data.participantId,
          mediaType: mediaType.AUDIO
        },
        updateReason: PARTICIPANT_UPDATE_REASON.MEDIA_TYPE
      })
      // When we get _STOP_USER_VIDEO we either have to restart video or start snapshot
      // Start snapshot if user is the one for whom snapshot is started (that is he is the snapshotee)
      // else we should start video call which is in case of iPhone/iPad orientation change
      if (store.getState().SessionReducer.snapshoteeId === store.getState().SessionReducer.uniqueId
        && store.getState().SessionReducer.snapshotPermissionReply) {
        if (stateForStopUserVideo.SessionReducer.uniqueId !== stateForStopUserVideo.RoomMetaDataReducer.roomMetaData.organiser &&
          stateForStopUserVideo.SessionReducer.webRtcPermissionRequest && stateForStopUserVideo.SessionReducer.webRtcPermissionReply
        ) {
          // start snapshot
          store.dispatch({
            type: ACTIONS.START_SNAPSHOT,
            value: true
          })
        }
      } else {
        // start video again 
        store.dispatch({
          type: ACTIONS.START_VIDEO_CALL,
          value: true
        })
      }
      break;
    case webRtcMessageId._PLUGIN_NOT_INSTALLED:
      store.dispatch({
        type: ACTIONS.USER_PLUGIN_NOT_INSTALLED,
        participantId: data.participantId
      });
      break;
    // _SNAPSHOT_STARTED and _SNAPSHOT_STOPPED msg are introduced to determine if snapshot is started
    // in a session irrespective of the user selecetd for snapshot
    case webRtcMessageId._SNAPSHOT_STARTED:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_STARTED,
        value: true
      });
      break;
    case webRtcMessageId._SNAPSHOT_STOPPED:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_STARTED,
        value: false
      });
      // snapshotee stopped the snapshot/agent stopped snapshot/ agent switch snapshotee
      // then reset the drawing mode state for all users except the agent.
      // we need to maintain agent's drawing state in network issue,
      // we have reset the state in snapshot.js componentWillUnmount method
      if (store.getState().RoomMetaDataReducer.roomMetaData.organiser !== store.getState().SessionReducer.uniqueId) {
        store.dispatch({
          type: ACTIONS.SNAPSHOT.IS_DRAWING_MODE_ENABLED,
          value: false
        });
      }
      break;
    case webRtcMessageId._RENEGOTIATE:
      renegotiate(data, store);
      break
    case webRtcMessageId._SNAPSHOT_RESOURCES_INITIALIZED:
      /*
      * create snapshot peer on kms resorces get initialized on server
      */
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_RUNNING,
        value: true
      });
      store.dispatch({
        type: ACTIONS.SET_SNAPSHOTEE_ID,
        snapshoteeId: data.snapshoteeId
      });
      break
    case webRtcMessageId._MEDIA_DEVICE_PERMISSION_DENIED:
      const mediaDeviceDeniedDetails = {
        participantId: data.participantId,
        media: data.media,
      };
      store.dispatch({
        type: ACTIONS.SET_MEDIA_DEVICE_DENIED_DETAILS,
        mediaDeviceDeniedDetails
      });
      break;
    case webRtcMessageId._SNAPSHOT_ANNOTATIONS_MODE:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SET_ANNOTATION_MODE,
        value: data.mode
      });
      break;
    case webRtcMessageId._SNAPSHOT_ANNOTATIONS_CLEARED:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.SNAPSHOT_ANNOTATIONS_CLEARED,
        value: data.reason
      });
      break;
    default:
      break;
  }
};

function renegotiate(data, store) {
  switch (data.media) {
    case mediaType.VIDEO:
      store.dispatch({
        type: ACTIONS.SET_RENEGOTIATE_VIDEO_PARTICIPANT,
        participantId: data.senderId
      })
      break;
    case mediaType.AUDIO:
      store.dispatch({
        type: ACTIONS.SET_RENEGOTIATE_AUDIO_PARTICIPANT,
        participantId: data.senderId
      })
      break;
    case mediaType.SCREEN:
      store.dispatch({
        type: ACTIONS.SET_RENEGOTIATE_SCREEN_PARTICIPANT,
        participantId: data.senderId,
        renegotiateScreenshare: true
      })
      break;
    default:
      break;
  }
}

function stopAudioVideoCall(store) {
  store.getState().ParticipantReducer.participants.map(participant => {
    if (
      participant &&
      wsocket.audioAndVideoRtcPeer[participant.uniqueId] !== undefined
    ) {
      Object.keys(wsocket.audioAndVideoRtcPeer[participant.uniqueId]).map((key) => {
        wsocket.audioAndVideoRtcPeer[participant.uniqueId][key].dispose();
      });
    }
  });
  store.dispatch({
    type: ACTIONS.SET_LOCAL_STREAM,
    localStream: null
  });
  store.dispatch({
    type: ACTIONS.CALL_MODAL_PERMISSION,
    value: false
  });
  if (store.getState().SessionReducer.componentInfocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO) {
    store.dispatch({
      type: ACTIONS.SET_COMPONENT_IN_FOCUS,
      componentInFocus: COMPONENT_IN_FOCUS.CANVAS
    });
  }
  store.dispatch({
    type: ACTIONS.SET_SORTED_PARTICIPANT,
    sortedParticipants: []
  });
  wsocket.audioAndVideoRtcPeer = [];
  let webRtcPermissionRequest = store.getState().SessionReducer
  .webRtcPermissionRequest;
  store.dispatch({
    type: ACTIONS.RESET_ALL_PARTICIPANT_MEDIA_STATUS
  });
  store.dispatch({
    type: ACTIONS.UPDATE_HEADER_ICON_CLASSNAME,
    data: {
      moduleName: mediaType.AUDIO,
      classname: store.getState().UserReducer.userDetails.role === USER_ROLES.USER ? "moduleIcon cursorDisabled" : "moduleIcon",
      textLabelClass: store.getState().UserReducer.userDetails.role === USER_ROLES.USER ? " cursorDisabled" : " cursorPointer"
    }
  });
  store.dispatch({
    type: ACTIONS.UPDATE_HEADER_ICON_CLASSNAME,
    data: {
      moduleName: mediaType.AUDIO_VIDEO,
      classname: store.getState().UserReducer.userDetails.role === USER_ROLES.USER ? "moduleIcon cursorDisabled" : "moduleIcon",
      textLabelClass: store.getState().UserReducer.userDetails.role === USER_ROLES.USER ? " cursorDisabled" : " cursorPointer"
    }
  });
}

function stopScreenSharing(store) {
  if (wsocket.screenShareRtcPeer !== null) {
    wsocket.screenShareRtcPeer.dispose();
    wsocket.screenShareRtcPeer = null;
  }
  console.log('DEBUG :: in stopScreenSharing :: set screenSharingParticipantId = null');
  store.dispatch({
    type: ACTIONS.SAVE_SCREENSHARING_PARTICIPANT_ID,
    screenSharingParticipantId: null
  });
}

function stopSnapshot(data, store) {
  if (wsocket.snapshotRtcPeer !== null) {
    wsocket.snapshotRtcPeer.dispose();
    wsocket.snapshotRtcPeer = null;
  }

  store.dispatch({
    type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_RUNNING,
    value: false
  })
  store.dispatch({
    type: ACTIONS.START_SNAPSHOT,
    value: false
  });
  
  store.dispatch({
    type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_STARTED,
    value: false
  });

  let snapshotPermissionReply = selectSnapshotPermissionReply(store.getState());
  // reset snapshot data for agent only when permission is granted by snapshotee on receiving _SNAPSHOT_TAKEN message
  // always reset snapshot data for user on receiving _SNAPSHOT_TAKEN message
  if (store.getState().SessionReducer
    && (store.getState().SessionReducer.snapshoteeId != store.getState().SessionReducer.uniqueId
      && snapshotPermissionReply === true)
    || (store.getState().SessionReducer.snapshoteeId === store.getState().SessionReducer.uniqueId)) {
    store.dispatch({
      type: ACTIONS.RESET_SNAPSHOT_DATA
    });
  }
  // SHOW_SNAPSHOT is used to close snapshot window at Agent's end
  // If there is only agent in the session (refresh user case) or
  // If user has blocked camera permission or user's camera is busy and only 1 user and agent in the session (video + snapshot case)
  if ((getAdmittedParticipants(store.getState()).length < 2) ||
    ((getAdmittedParticipants(store.getState()).length === 2) && (data.isCameraPermissionGranted === false || data.isCameraBusy === true))) {
    store.dispatch({
      type: ACTIONS.SNAPSHOT.SHOW_SNAPSHOT,
      value: false
    });
  }

  if (data.isCameraPermissionGranted && !data.isCameraBusy) {
    store.dispatch({
      type: ACTIONS.SET_SNAPSHOT_TAKEN,
      isSnapshotTaken: data.isSnapshotTaken
    });
  }
  else if (data.isCameraBusy) {
    store.dispatch({
      type: ACTIONS.SET_CAMERA_BUSY_FLAG,
      value: data.isCameraBusy
    });
  }
  else {
    store.dispatch({
      type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_CAMERA_PERMISSION,
      value: data.isCameraPermissionGranted
    });
  }
}

function snapshotTaken(data, store) {
  if (data.isCameraPermissionGranted && !data.isCameraBusy) {
    store.dispatch({
      type: ACTIONS.SET_SNAPSHOT_TAKEN,
      isSnapshotTaken: data.isSnapshotTaken
    });
  }
  else if (data.isCameraBusy) {
    store.dispatch({
      type: ACTIONS.SET_CAMERA_BUSY_FLAG,
      value: data.isCameraBusy
    });
  }
  else {
    store.dispatch({
      type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_CAMERA_PERMISSION,
      value: data.isCameraPermissionGranted
    });
  }
}
