import React, { Component } from "react";
import { isIE } from "react-device-detect";

// Custom Components
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import moment from "moment";

import { getMessage } from "CONFIG/i18n";

//ASSETS
import icBar from "ASSETS/Images/ic_bar.svg";
import icClosedRow from "ASSETS/Images/ic_closed _row.svg";
import icExpandedRow from "ASSETS/Images/ic_expanded_row.svg";

//stylesheet
import "./QRCodeSessionList.less";
import "COMPONENTS/App/App.less";

export class QRCodeSessionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSessionKey: "",
    };
    this.tableRef = React.createRef();
  }
  isSessionSelected = (sessionKey) => {
    return this.state.selectedSessionKey === sessionKey;
  };

  //select perticular session
  selectSession = (sessionKey) => {
    this.setState({
      selectedSessionKey:
        this.state.selectedSessionKey !== sessionKey ? sessionKey : "",
    });
  };

  startQRCodeSession = (sessionKey) => {
    this.props.joinQRCodeSession(sessionKey);
  };

  renderQRCodeSessionCreatedTime(session) {
    return (
      <div
        className="col-lg-1 col-md-1 pl-2 pr-3 qr-code-session-date-time"
        onClick={() => this.selectSession(session.sessionKey)}
      >
        <div className="d-flex flex-column">
          <span
            className="date"
            id={`qrSessionCreatedDate${session.sessionKey}`}
          >
            {moment(session.createdTime).format("yyyy/MM/DD")}
          </span>
          <span className="time">
            {moment(session.createdTime).format("HH:mm")}
          </span>
          {!this.isSessionSelected(session.sessionKey) && (
            <CustomTooltip
              tooltipText={moment(session.createdTime).format("yyyy/MM/DD")}
              tooltipId={`qrSessionCreatedDate${session.sessionKey}`}
            />
          )}
        </div>
      </div>
    );
  }

  renderQRCodeSessionDetails(session) {
    return (
      <div className="border-right border-left pl-4 pr-3 qr-session-name-width">
        <span
          className={
            "qr-code-session-name " +
              (!this.isSessionSelected(session.sessionKey)
                ? "text-truncate  custom-tooltip "
                : " text-wrap columnText")
          }
          id={`qrCodeSessionLabel${session.sessionKey}`}
          onClick={() => this.selectSession(session.sessionKey)}
        >
          {session.qrCodeLabel}
          {!this.isSessionSelected(session.sessionKey) && (
            <CustomTooltip
              tooltipText={session.qrCodeLabel}
              tooltipId={`qrCodeSessionLabel${session.sessionKey}`}
            />
          )}
        </span>
        <br />
        {session.qrCodeDescription && (
          <span
            className={
              "qr-code-session-details mb-1 " +
                (!this.isSessionSelected(session.sessionKey)
                  ? "text-truncate custom-tooltip"
                  : "text-wrap columnText")
            }
            id={`qrCodeSessionDescription${session.sessionKey}`}
            onClick={() => this.selectSession(session.sessionKey)}
          >
            {session.qrCodeDescription}
            {!this.isSessionSelected(session.sessionKey) && (
              <CustomTooltip
                tooltipText={session.qrCodeDescription}
                tooltipId={`qrCodeSessionDescription${session.sessionKey}`}
              />
            )}
          </span>
        )}
      </div>
    );
  }

  renderQRCodeSessionUserInfo(session) {
    return (
      <div
        className="col-lg-4 col-md-4 pl-4 qr-code-user-details"
        onClick={() => this.selectSession(session.sessionKey)}
      >
        <>
          <span
            className={
              "qr-code-user-name " +
              (!this.isSessionSelected(session.sessionKey)
                ? "text-truncate qr-code-user-detail-width custom-tooltip"
                : "text-wrap columnText")
            }
            id={`qrCodeSessionUserName${session.sessionKey}`}
          >
            {session.userName}
            {!this.isSessionSelected(session.sessionKey) && (
              <CustomTooltip
                tooltipText={session.userName}
                tooltipId={`qrCodeSessionUserName${session.sessionKey}`}
              />
            )}
          </span>
          {session.userEmail && (
            <>
            <br />
            <span
              className={
                "qr-code-session-details " +
                (!this.isSessionSelected(session.sessionKey)
                  ? "text-truncate qr-code-user-detail-width custom-tooltip"
                  : "text-wrap columnText")
              }
              id={`qrCodeSessionUserEmail${session.sessionKey}`}
            >
              {session.userEmail}
              {!this.isSessionSelected(session.sessionKey) && (
                <CustomTooltip
                  tooltipText={session.userEmail}
                  tooltipId={`qrCodeSessionUserEmail${session.sessionKey}`}
                />
              )}
            </span>
            </>
          )}
          <br />
          {session.userPhone && (
            <span
              className={
                "qr-code-session-details " +
                (!this.isSessionSelected(session.sessionKey)
                  ? "text-truncate custom-tooltip"
                  : "text-wrap columnText")
              }
              id={`qrCodeSessionUserPhoneNo${session.sessionKey}`}
            >
              {session.userPhone}
              {!this.isSessionSelected(session.sessionKey) && (
                <CustomTooltip
                  tooltipText={session.userPhone}
                  tooltipId={`qrCodeSessionUserPhoneNo${session.sessionKey}`}
                />
              )}
            </span>
          )}
          {session.info && session.info !== session.qrCodeDescription && (
            <span
              className={
                "qr-code-session-details " +
                (!this.isSessionSelected(session.sessionKey)
                  ? "text-truncate custom-tooltip"
                  : "text-wrap columnText")
              }
              id={`qrCodeSessionInfo${session.sessionKey}`}
            >
              {session.info}
              {!this.isSessionSelected(session.sessionKey) && (
                <CustomTooltip
                  tooltipText={session.info}
                  tooltipId={`qrCodeSessionInfo${session.sessionKey}`}
                />
              )}
            </span>
          )}
        </>
      </div>
    );
  }

  renderNoQRCodeSession() {
    return (
      <div className="text-center mt-2 tableTitle margin-bottom">
        <NoData minHeight={"25vh"} message={getMessage("NO_QR_MEETINGS")} />
      </div>
    );
  }
  
  render() {
    const { qrCodeSessionList } = this.props;
    return (
      <div className="d-flex justify-content-center qr-code-session-table-wrapper">
        <div className="col-md-12 qr-code-meeting-table">
          <div className="d-md-flex d-block flex-row align-items-center justify-content-between ">
            <div className="qr-code-meeting-table-title">
              <img src={icBar} className="mr-1" />
              {getMessage("QR_CODE_MEETINGS")}
            </div>
          </div>
          <div
            ref={this.tableRef}
            className={
              isIE
                ? "qr-code-session-table scroll-decoration pb-2"
                : "qr-code-session-table scroll-decoration"
            }
          >
            <div id="table">
              {qrCodeSessionList && qrCodeSessionList.length > 0 ? (
                qrCodeSessionList.map((session) => (
                  <div
                    key={session.sessionKey}
                    id={session.sessionKey}
                    className={
                      "qr-code-meetings-table-data align-items-center p-2 my-2 justify-content-between " +
                      (this.isSessionSelected(session.sessionKey)
                        ? " qr-code-session-data-selected"
                        : "qr-code-session-data")
                    }
                  >
                    <div
                      className="col-lg-1 col-md-1 d-flex flex-row radio-btn"
                      onClick={() => this.selectSession(session.sessionKey)}
                    >
                      <div className="d-flex align-items-center radio-btn-img">
                        <img
                          src={
                            this.isSessionSelected(session.sessionKey)
                              ? icExpandedRow
                              : icClosedRow
                          }
                          className="mr-3 cursorPointer"
                        />
                      </div>
                    </div>
                    {this.renderQRCodeSessionCreatedTime(session)}
                    {this.renderQRCodeSessionDetails(session)}
                    {this.renderQRCodeSessionUserInfo(session)}

                    <div className="col-lg-2 col-md-2 mx-3 col-sm-6 col-xs-6 col-6  px-2 participate-button">
                      <button
                        className="btn w-100 qr-code-session-start-btn px-1"
                        onClick={() =>
                          this.startQRCodeSession(session.sessionKey)
                        }
                      >
                        {getMessage("PARTICIPATE")}
                      </button>
                    </div>

                    {/* This button is visible only on mobile view */}
                    <div className=" participate-button-for-mobile">
                      <button
                        className="btn w-100 qr-code-session-start-btn px-2"
                        onClick={() =>
                          this.startQRCodeSession(session.sessionKey)
                        }
                      >
                        {getMessage("PARTICIPATE")}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <>{this.renderNoQRCodeSession()}</>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QRCodeSessionList;
