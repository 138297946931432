import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import {
  sendWebsocketMessage,
  disconnectWebsocket
} from "WEBSOCKET/WebsocketHandler";

// Components
import QRCodeLanding from "COMPONENTS/QRCode/UIComponents/QRCodeLanding/QRCodeLanding";
import QRCodeLoading from "COMPONENTS/QRCode/UIComponents/QRCodeLoading/QRCodeLoading";
import Spinner from "COMPONENTS/Spinner/Spinner";

import withRealWear from "Components/WrappedComponents/withRealWear/withRealWear";

// Utilities
import { removeTrailingSlash, getURLParameter, isRealwearDevice, isMobile } from "UTILS/Utility";

// Strings
import { changeLanguage } from "CONFIG/i18n";

// Constants
import { socketMessage } from "WEBSOCKET/constants";

import {
  CONFIG,
  ERROR,
  REGEX,
  USER_ROLES,
  SESSION_STATUS,
  QR_CODE_SCREEN_TYPE,
  USER_ADMISSION_STATUS
} from "UTILS/Constants";

import {
  selectUserRole,
} from "../../Whiteboard/selectors";
import {
  selectSessionKey,
  selectUserToken,
  selectUserName,
  selectEndUserToken
} from "COMPONENTS/MainLayout/selectors";
import { 
  getLanguage, 
  selectSessionStatus, 
  selectSessionType,
  selectQrActiveScreen,
  selectUserWaitingEndTime,
  selectIsUserWaitingTimeEnded,
  getUserDetails,
  getFollowUpSuccess,
} from "./QRCodeSelector";

import { selectSessionEndedFlag,getCustomerId, getQRCodeWaitingTime } from "COMPONENTS/CommonComponents/Header/selectors.js"
import { selectUserAdmissionStatus } from "COMPONENTS/Dashboard/selectors";

// Actions
import { sessionActions, organisationAction } from "CONFIG/ActionFactory";
import {
  setSharingFlag,
  setSdpFlag,
  setQrError,
  removeSessionKeyAndToken,
  updateLatestParticipant,
  setSessionStatus,
  removeSessionDetails,
  setQrActiveScreen,
  setQRCode,
  setUserWaitingEndTime,
  setIsWaitingTimeOver,
  setUserDetails,
  setQRNoThanksMessageScreen,
  setFollowUpSuccess
} from "./QRCodeAction";
import { logoutAction } from "CONFIG/ActionFactory";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";
import { setToken, setCustomerId, updateProfile ,setUserRole} from "COMPONENTS/UserJoin/UserJoinAction";

// Images
import logo from "ASSETS/Images/img_signin_bg_logo.png";

import ChangeLanguage from "COMPONENTS/CommonComponents/ChangeLanguage/ChangeLanguage";
import { getSessionInvalidFlag } from "REDUX/Selector/SessionSelector";

class QRCodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: this.props.selectedLanguage || "en",
      showVideoCallPopUp: false,
      deviceList: null,
      showAudio: false,
      isLogoLoading: true,
      isOtherAgentAttending: false,
      spinnerVisibility: true
    };

    // first check errors if user has copied qr code link on join / create session
    if (this.props.sessionStatus === SESSION_STATUS.ACTIVE) {
      /* remove session details 
        if user paste QR link on browser's tab where session is already running and user is on dashboard
      */
      this.props.removeSessionDetails();
      this.props.setQrActiveScreen(QR_CODE_SCREEN_TYPE.QR_SPLASH_SCREEN);
    }
    const pathname = removeTrailingSlash(this.props.location.pathname);
    let userWaitingTime = this.props.userWaitingEndTime? this.props.userWaitingEndTime - moment().valueOf(): null;
    // on join session / create session screen in normal flow, sessionStatus = "" 
    // if (this.props.sessionStatus !== SESSION_STATUS.CREATED &&

    // TO-DO: Correct cleanup on end/leave session
    if(this.props.sessionType != "QR_CODE" &&
       (userWaitingTime === null ||
       userWaitingTime > 0) &&       //restrict user to clean the data on waiting time is over and user return from rejoin page screen     
       (this.props.sessionKey || this.props.token)) {

      /* remove session details 
        if user paste QR link on browser's tab on join session screen after user has left from active session 
        or agent paste QR link on browser's tab on create session screen after he has ended the active session 
        
        if the agent starts a meeting from the QR session list then do not call the handleLogout function
        if the agent starts qr meeting from mail then calls the handleLogout function(i.e agent not comming from startSession url)
        handleLogout function clears the logged in user details like token, name, etc.
      */
      // to remove previous sessions'S DATA (token and session Key)
      this.props.removeSessionKeyAndToken();
      this.props.setQrActiveScreen(QR_CODE_SCREEN_TYPE.QR_SPLASH_SCREEN);
      //this.props.updateLatestParticipant();
      if (pathname !== CONFIG.path.startSession) {
        this.handleLogout();// To Clear login Token
      }
    }

    const token = getURLParameter("token");

    // Since QRCodeContainer is common for QR Code flow of User(Landing page after QR Code scan) and Agent(Redirection from AgentLandingPage after clicking on Email link),
    // fetch QR Code only in case of User, to set the correct role later.
    if (REGEX.START_QR_CODE_SESSION.test(pathname)) {
      this.qrId = pathname.substring(pathname.lastIndexOf('/') + 1);
    }

    if (!this.qrId && !token) {
      this.props.setQrError(ERROR.INVALD_DATA_REQUEST)
      this.props.history.replace(CONFIG.path.qrExpired);
    }

    /* ############################## User side starts ############################## */

    if (this.qrId) {
      this.props.setUserRole(USER_ROLES.USER);
      this.props.setQRCode(this.qrId);

      /* 
      * on new tab for user, need to set QrActiveScreen as SPLASH_SCREEN 
      * when there is no qractive screen is selected
      */
      if(!this.props.sessionStatus && !this.props.qrActiveScreen) {
        this.props.setQrActiveScreen(QR_CODE_SCREEN_TYPE.QR_SPLASH_SCREEN);
      }
      // get customer logo and show splash screen to the user if QR Code is valid
      this.getCustomerFromQRCode();
    }
    /* ############################## User side ends ############################## */

    /* ############################## Agent side ############################## */
    this.sessionKey = getURLParameter("sessionKey");
    let language = getURLParameter("language");
    let firstName = getURLParameter("firstname");
    let lastName = getURLParameter("lastname");
    token && this.props.setToken(token);
    !this.qrId && this.props.setUserRole(USER_ROLES.AGENT);
    this.props.setQrError(null);
    if (language) {
      // Use language present in url (that is the language of QR customer)
      changeLanguage(language);
      this.props.setLanguageAction(language);
      this.setState({ selectedLanguage: language });
    }

    if (token) {
      // for agent, need to set QrActiveScreen as null as we directly want to load dashboard
      this.props.setQrActiveScreen(null);
      sessionActions.joinSessionAction(this.sessionKey, this.props.uriIdentifier).then(
        response => {
          let logoUrl = response.data?.data.logoUrl;
          this.props.setToken(response.data.data.token);
          this.props.setCustomerId(response.data.data.customerId)
          if (logoUrl) {
            // image should not be converted to base64 as it fills up the browser session storage, creating unnecessary issues
            this.setState({
              logoUrl
            })
            organisationAction.setLogoUrl(logoUrl);
          }
          else {
            this.changeLogoToDefault();
          }
          // update user profile for Agent to show his Initials
          this.props.updateProfile({ first_name: firstName, last_name: lastName })

          let sessionJoinPayload = {
            data: {
              category: socketMessage.category.SESSION,
              action: socketMessage.subCategories.SESSION.JOIN,
              userName: this.props.userName,
              sessionKey: response.data.data.sessionKey,
              token: response.data.data.token
            }
          };
          // if (userToken) {
          //   sessionJoinPayload.data.token = userToken;
          // }
          this.props.sendWebsocketMessage(
            socketMessage.events.MESSAGE,
            sessionJoinPayload
          );
        },
        error => {
          if (error.code === ERROR.NOT_AUTHORIZED &&
            error.code !== ERROR.USER_DELETED &&
            error.code!==ERROR.INVALID_USER) {
            this.props.history.push(CONFIG.path.login);
          } else {
            this.props.setQrError(error.code);
            this.props.history.push({ pathname: CONFIG.path.sessionEnded});
          }
          // this.setState({isOtherAgentAttending: true})
        }
      );
    }
  }

  getCustomerFromQRCode = () => {
    this.showSpinner();
    organisationAction.getCustomerFromQRCode(this.qrId).then(
      response => {
        this.hideSpinner();
        this.setState({ isLogoLoading: false })
        let logoUrl = response.data ? response.data.data.logoUrl : null;
        if (!logoUrl) {
          this.changeLogoToDefault();
        } else {
          if(logoUrl) {
            // image should not be converted to base64 as it fills up the browser session storage, creating unnecessary issues
            this.setState({
              logoUrl
            })
          }
          //this.props.setCustomerId(response.data.data.customerId)
          organisationAction.setLogoUrl(logoUrl)
        }
      },
      error => {
        this.hideSpinner();
        // Handle errors on landing screen(splash screen) and follow-up screen.
        // Let customer continue on waiting screen.
        this.setState({ isLogoLoading: false });
        if(error.code !== ERROR.INTERNAL_SERVER_ERROR && error.code !== ERROR.NETWORK_ERROR) {
          const errorCode = ERROR.QR_CODE_EXPIRED;
          this.props.setQrError(errorCode)
          this.props.history.replace(CONFIG.path.qrExpired);
        }
        this.changeLogoToDefault();
        this.setState({ errorMessage: error.code })
      }
    );
  }

  handleLogout = () => {
    logoutAction.logoutUserAction();
  };

  componentDidUpdate(prevProps) {      

    // hide splash screen after agent joins the session and session status becomes ACTIVE
    if (this.props.sessionStatus === SESSION_STATUS.ACTIVE &&
      this.props.sessionStatus !== prevProps.sessionStatus) {      // navigate user / agent to the Dashboard if addmission status is admitted 
      if(this.props.userAdmissionStatus === USER_ADMISSION_STATUS.ADMITTED) {
        this.props.history.push(CONFIG.path.dashboard);
      } 
      else 
      //if user status is pending navigate user to waiting room
      if(this.props.userAdmissionStatus === USER_ADMISSION_STATUS.PENDING) {
        this.props.history.push(CONFIG.path.waitingRoom);
      }
    }
    if(this.props.isSessionEnded && this.props.sessionStatus === SESSION_STATUS.ACTIVE) {
      this.props.setQrError(null);
      this.props.history.push({ pathname: CONFIG.path.sessionEnded });
    }
    if(this.props.userAdmissionStatus === USER_ADMISSION_STATUS.REJECTED){
      this.props.history.push(CONFIG.path.waitingRoom);
    }
    // check if user waiting time ended and is QR code is valid or not
    if(prevProps.userWaitingTimeEnded !== this.props.userWaitingTimeEnded && this.props.userWaitingTimeEnded) {
      this.getCustomerFromQRCode()
    }
    
    // Check if session is invalid and redirect user to session ended screen
    if (prevProps.isSessionInvalid !== this.props.isSessionInvalid && this.props.isSessionInvalid) {
      // Set invalid session error and redirect
      this.props.setQrError(ERROR.INVALID_SESSION);
      this.props.history.push(CONFIG.path.sessionEnded);

      // Reset flag to false
      sessionActions.setSessionInvalidFlag(false);
    }
  }

  handleChangeLanguage = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
    this.setState({ selectedLanguage: selectedLanguage });
  };

  // called when user enters his name and say Start
  handleStartClick = (errorCallBack, userName, email, phone, info, deviceType) => {
    // for user
    if (this.props.userRole === USER_ROLES.USER) {
      let language = this.state.selectedLanguage;

      // create a new session for user
      sessionActions.createSessionWithQRCode(this.qrId, language, userName, email, phone, info, deviceType).then(
        data => {
          // call join session for user
          sessionActions.joinSessionAction(data.sessionKey, this.props.uriIdentifier, userName).then(
            response => {
              let sessionJoinPayload = {
                data: {
                  category: socketMessage.category.SESSION,
                  action: socketMessage.subCategories.SESSION.JOIN_SESSION_REQUEST,
                  userName: userName,
                  email: email,
                  phone: phone,
                  sessionKey: response.data.data.sessionKey,
                  token: response.data.data.token,
                  language: this.props.selectedLanguage
                }
              };
              //Set createSessionWithQRCodeAction api response using userData variable
              const userData = data.data.data;
              this.props.setCustomerId(userData.customerId)
              this.props.sendWebsocketMessage(
                socketMessage.events.MESSAGE,
                sessionJoinPayload
              );
              this.props.setUserDetails({
                language: language,
                userName: userData.userName,
                email: email,
                phone: phone,
                info: info,
                deviceType: deviceType
              });
              this.props.setToken(response.data.data.token);
              this.props.setSessionStatus(response.data.data.sessionStatus);
              let startBtn = document.getElementById("startQRSessionBtn");
              if(startBtn) {
                startBtn.disabled = false;
              }
              this.props.setQrActiveScreen(QR_CODE_SCREEN_TYPE.QR_WAITING_SCREEN);
            },
            error => {
              errorCallBack(error.code)
            }
          );
        },
        error => {
          if (error.code !== ERROR.NETWORK_ERROR &&
            error.code !== ERROR.INTERNAL_SERVER_ERROR &&
            error.code !== ERROR.ACTIVE_AVAILABLE_AGENT_DOES_NOT_EXIST &&
            error.code !== ERROR.ANONYMOUS_USER_NOT_ALLOWED &&
            error.code !== ERROR.INVALID_REQUEST_DATA) {
            const errorCode = ERROR.QR_CODE_EXPIRED;
            this.props.setQrError(errorCode)
            this.props.history.replace(CONFIG.path.qrExpired);
          } else if (error.code === ERROR.ANONYMOUS_USER_NOT_ALLOWED) {
            const errorCode = "ERR_MSG_YOUR_NAME";
            errorCallBack(errorCode);
          } else {
            errorCallBack(error.code)
          }
        }
      );
    }
  };

  // called when user enterd description of issue and Preferred time zone for follow-up ,click follow-Up
  handleFollowUpClick = (errorCallBack, name, email, phone, description, fromTime, toTime, info, deviceType) => {
    let userData = {
      name,
      email,
      phone,
      description,
      fromTime,
      toTime,
      sessionKey: this.props.sessionKey,
      customerId :this.props.customerId,
      language: this.state.selectedLanguage,
      qrId : this.qrId,
      info,
      deviceType
    }
    
    // Followup-Request from User
    sessionActions.createQRFollowUpRequestAction(userData).then(
      response => {
        this.props.setFollowUpSuccess(true);
      },
      error => {
        if (error.code !== ERROR.INTERNAL_SERVER_ERROR && error.code !== ERROR.NETWORK_ERROR) {
          const errorCode = ERROR.QR_CODE_EXPIRED;
          this.props.setQrError(errorCode)
          this.props.history.replace(CONFIG.path.qrExpired);
        }       
        errorCallBack && errorCallBack(error.code)
      }
    );
  };

  changeLogoToDefault = () => {
    this.setState({
      logoUrl:logo
    })
    organisationAction.setLogoUrl(logo);
  }

  joinAgain = (connectionStatus) => {
    if (connectionStatus === true) {
      //TO-DO : have to add username in parameter
      sessionActions.joinSessionAction(this.props.sessionKey, this.props.uriIdentifier, this.props.userName, false, this.props.endUserToken).then(
        response => {
          let sessionJoinPayload = {
            data: {
              category: socketMessage.category.SESSION,
              action: socketMessage.subCategories.SESSION.JOIN_SESSION_REQUEST,
              userName: this.props.userName,
              sessionKey: this.props.sessionKey,
              token: response.data.data.token
            }
          };
          this.props.sendWebsocketMessage(
            socketMessage.events.MESSAGE,
            sessionJoinPayload
          );
        },
        error => {
          if (error.code === ERROR.INVALID_SESSION) {
            this.props.setQrError(error.code)
            this.props.history.replace(CONFIG.path.qrExpired);
          }
        }
      );

    }
  }

  onSelectChange = (selectedLanguage) => {
    this.props.setLanguageAction(selectedLanguage)
  }

  showSpinner = () => {
    this.setState({
      spinnerVisibility: true
    });
  };

  hideSpinner = () => {
    this.setState({
      spinnerVisibility: false
    });
  };

  renderSpinner = () => {
    return <Spinner showSpinner={this.state.spinnerVisibility} />;
  }

  render() {
    return (
      <>
      {this.state.spinnerVisibility && this.renderSpinner()}
        {!this.state.spinnerVisibility &&
          <div
            className={`alignTextCenter ${
              isMobile() || !isRealwearDevice() ? "overflow-hidden" : ""
            }`}
          >
            {this.props.qrActiveScreen == QR_CODE_SCREEN_TYPE.QR_SPLASH_SCREEN &&       
             <Row className="noMargin">
               <ChangeLanguage
                 onSelectChange={this.handleChangeLanguage}
                 language={this.props.selectedLanguage}
               />
             </Row>}
            
            {this.props.qrActiveScreen == QR_CODE_SCREEN_TYPE.QR_SPLASH_SCREEN ? 
            // only to be displayed for user 
              <div>
                <QRCodeLanding
                  selectedLanguage={this.props.selectedLanguage}
                  setLanguageAction={this.props.setLanguageAction}
                  handleStartClick={this.handleStartClick}
                  handleChangeLanguage={this.handleChangeLanguage}
                  logo={this.state.logoUrl}
                  role={this.props.role}
                  updateProfile={this.props.updateProfile}
                  isLogoLoading={this.state.isLogoLoading}
                  errorMessage={this.state.errorMessage}
                  allowAnonymousParticipants={this.props.allowAnonymousParticipants}
                  setUserDetails={this.props.setUserDetails}
                  handleVoiceCommand={this.props.handleVoiceCommand}
                  deregisterVoiceCommand={this.props.deregisterVoiceCommand}
                /> 
              </div>
              : this.props.qrActiveScreen == QR_CODE_SCREEN_TYPE.QR_WAITING_SCREEN ?
                <QRCodeLoading
                  logo={this.state.logoUrl}
                  role={this.props.role}
                  isLogoLoading={this.state.isLogoLoading}
                  selectedLanguage={this.props.selectedLanguage}
                  joinAgain={this.joinAgain}
                  setUserWaitingEndTime={this.props.setUserWaitingEndTime}
                  userWaitingEndTime={this.props.userWaitingEndTime}
                  disconnectWebsocket={this.props.disconnectWebsocket}
                  sessionKey={this.props.sessionKey}
                  setIsWaitingTimeOver={this.props.setIsWaitingTimeOver}
                  userWaitingTimeEnded={this.props.userWaitingTimeEnded}
                  sessionStatus={this.props.sessionStatus}
                  userDetails={this.props.userDetails}
                  isNoThanksMessageClicked={this.props.isNoThanksMessageClicked}
                  setQRNoThanksMessageScreen={this.props.setQRNoThanksMessageScreen}
                  followUpSuccess={this.props.followUpSuccess}
                  handleFollowUpClick={this.handleFollowUpClick}
                  qrCodeWaitingTime={this.props.qrCodeWaitingTime}
                />
                : null
            }
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    sessionKey: selectSessionKey(state),
    userRole: selectUserRole(state),
    token: selectUserToken(state),
    endUserToken: selectEndUserToken(state),
    sessionStatus: selectSessionStatus(state),
    userName: selectUserName(state),
    selectedLanguage: getLanguage(state),
    sessionType: selectSessionType(state),
    qrActiveScreen: selectQrActiveScreen(state),
    isSessionEnded: selectSessionEndedFlag(state),
    userAdmissionStatus: selectUserAdmissionStatus(state),
    uriIdentifier: state.UserReducer.uriIdentifier,
    allowAnonymousParticipants: state.OrganisationReducer.customerSettings.allowAnonymousParticipants,
    userWaitingEndTime: selectUserWaitingEndTime(state),
    userWaitingTimeEnded: selectIsUserWaitingTimeEnded(state),
    userDetails:getUserDetails(state),
    isNoThanksMessageClicked:state.QrReducer.isNoThanksMessageClicked,
    customerId: getCustomerId(state),
    followUpSuccess: getFollowUpSuccess(state),
    qrCodeWaitingTime: getQRCodeWaitingTime(state),
    isSessionInvalid: getSessionInvalidFlag(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage: sendWebsocketMessage,
      disconnectWebsocket,
      setToken,
      setSharingFlag,
      setSdpFlag,
      setLanguageAction,
      setCustomerId,
      updateProfile,
      setQrError,
      removeSessionKeyAndToken,
      updateLatestParticipant,
      setSessionStatus,
      removeSessionDetails,
      setQrActiveScreen,
      setQRCode,
      setUserRole,
      setUserWaitingEndTime,
      setIsWaitingTimeOver,
      setUserDetails,
      setQRNoThanksMessageScreen,
      setFollowUpSuccess
    },
    dispatch
  );
};

export default withRealWear(connect(
  mapStateToProps,
  mapDispatchToProps
)(QRCodeContainer));
