export const selectUserId = state => state.UserReducer.userDetails.userId;

export const selectParticipantId = state => state.SessionReducer.participantId;

export const selectActiveCanvas = state => state.WhiteboardReducer.activeCanvas;

export const selectUploadImage = state => state.WhiteboardReducer.uploadImage;

export const selectGraphHopperKey = state => state.UserReducer.config.graphHopperKey;

export const selectPDFTronKey = state => state.UserReducer.config.pdftronKey;

export const getInkUnLockedParticipants = state => {
  const activeCanvasId = state.WhiteboardReducer.activeCanvas.canvasId;
  if (state.WhiteboardReducer.canvases[activeCanvasId])
    return state.WhiteboardReducer.canvases[activeCanvasId]
    .inkUnlockedParticipants;
};
export const selectActiveCanvasData = state => {
  const activeCanvasId = state.WhiteboardReducer.activeCanvas.canvasId;
  let activeCanvasData = state.WhiteboardReducer.canvases[activeCanvasId];
  if(activeCanvasData)
    activeCanvasData.canvasId = state.WhiteboardReducer.activeCanvas.canvasId;
  return activeCanvasData || {};
};

export const selectSessionKey = state => {
  return (
    state.SessionReducer.userSessionDetails &&
    state.SessionReducer.userSessionDetails.sessionKey)
  || (state.SessionReducer.sessionDetails &&
    state.SessionReducer.sessionDetails.sessionKey);
};

export const selectAllowedDownloads = state => {
  return ((state.UserReducer.userDetails.subModules && state.UserReducer.userDetails.subModules["ALLOW_DOWNLOAD"])
  || (state.SessionReducer.sessionDetails.subModules && state.SessionReducer.sessionDetails.subModules["ALLOW_DOWNLOAD"])
  || (JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)) 
  && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).subModules
  && JSON.parse(localStorage.getItem(state.SessionReducer.sessionDetails.token)).subModules["ALLOW_DOWNLOAD"]));
};

export const selectToken = state => state.UserReducer.userDetails.token;

export const getCanvasHistory = state => state.WhiteboardReducer.canvasHistory;

export const selectUserRole = state => state.UserReducer.userDetails.role;

export const selectCanvases = state => state.WhiteboardReducer.canvases;

export const selectUniqueId = state => state.SessionReducer.uniqueId;

export const selectSnapshotTaken = state => {
  return state.SnapshotReducer.isGranted;
};
export const snapshotPopup = state => {
  return state.SnapshotReducer.showSnapshotPopup;
};
export const selectDefaultFontInfo = state =>
  state.WhiteboardReducer.localFontInfo ? state.WhiteboardReducer.localFontInfo : state.WhiteboardReducer.defaultFontInfo;

export const selectIsFitScreen = state =>
  state.WhiteboardReducer.isFitScreen;

export const selectFrameSize = state => {
  return state.WhiteboardReducer.frameSize;
}

export const selectPdfFileUrl = state => state.WhiteboardReducer.pdfFileUrl;
export const selectFrameDetails = state => state.WhiteboardReducer.frameDetails;

export const selectPresenterId = state =>
  state.RoomMetaDataReducer.roomMetaData.presenterId;

export const getSessionStatusFlag = state =>
  state.SessionReducer.isSessionEnded;

export const getSelectedAnnotation = state =>
  state.RoomMetaDataReducer.roomMetaData.activeAnnotationDetails;

export const getPrevSelectedAnnotation = state =>
  state.RoomMetaDataReducer.roomMetaData.prevAnnotationDetails;

export const selectURIIdentifier = state =>
  state.UserReducer.uriIdentifier;

export const selectIsOnCanvasDownloading = state => 
  state.WhiteboardReducer.isDownloadingOnCanvas
