import { ACTIONS } from "UTILS/Constants";

const initialState = {
  webRtcPermissionReplyStatus: null,
  /**
   * webRtcPermissionReplyStatus: {
   *  isGranted -> if user has accepted request
   *  isMicConnected -> if user has mic connected 
   *  isCameraConnected -> if user has camera connected
   *  isPluginInstalled -> if plugin is installed at user end
   * }
   */
  snapshotPermissionStatus: {},
  /**
   * snapshotPermissionStatus: {
   *  isUserWebcamPresent -> if webcam is present at user end 
   *  isPluginInstalled -> if plugin is installed at user end
   * }
   */
  pluginNotInstalledParticipantIds: [],
  /**
   * pluginNotInstalledParticipantId -> if plugin is installed at user end (this flag is required to handle screen share)
   */
  mediaDeviceDeniedDetails: {},
  /**  
   * participantId -> socket id of the user who denied media permission
   * audio -> true when mic permission is denied
   * video -> true when camera permission is denied
  */
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_WEBRTC_REPLY_PERMISSION_STATUS:
      return {
        ...state,
        webRtcPermissionReplyStatus: action.webRtcPermissionReplyStatus
      };
    case ACTIONS.SET_SNASHOT_PERMISSION_STATUS:
      return {
        ...state,
        snapshotPermissionStatus: action.snapshotPermissionStatus,
      };
    case ACTIONS.USER_PLUGIN_NOT_INSTALLED:
      let notInstalledParticipantIds = _.cloneDeep(state.pluginNotInstalledParticipantIds);
      if(state.pluginNotInstalledParticipantIds.indexOf(action.participantId) == -1) {
        notInstalledParticipantIds.push(action.participantId);
      }
      return {
        ...state,
        pluginNotInstalledParticipantIds: notInstalledParticipantIds
      }
    case ACTIONS.RESET_PARTICIPANT_PLUGIN_INFO:
      return {
        ...state,
        pluginNotInstalledParticipantIds: [],
        pluginExpiredParticipantIds: []
  
      }
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return initialState;
    case ACTIONS.SET_MEDIA_DEVICE_DENIED_DETAILS:
      let updatedDetails = _.cloneDeep(state.mediaDeviceDeniedDetails);
      const data = action.mediaDeviceDeniedDetails;
      if(data.participantId !== updatedDetails.participantId) {
        updatedDetails = {};
        updatedDetails.participantId = data.participantId
      }
      updatedDetails[data.media] = true;
      return {
        ...state,
        mediaDeviceDeniedDetails: updatedDetails,
      }
    case ACTIONS.RESET_MEDIA_DEVICE_DENIED_DETAILS:
      return {
        ...state,
        mediaDeviceDeniedDetails: {},
      }
    default:
      return state;
  }
}
