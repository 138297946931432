import React, { Component } from "react";
import { Row, Col, Container, DropdownToggle } from "reactstrap";
import uuid from "uuid";

import UserDropdownModal from "../SuperAdmin/Header/UserDropdownModal";
import { getMessage } from "CONFIG/i18n";

import "./Header.less";
import { getUserNameAcronym, getUserNameForSelectedLanguage } from "UTILS/Utility";
import { USER_ROLES } from "UTILS/Constants";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import { func, string } from "prop-types";

class Header extends Component {
  static propTypes = {
    handleLogout: func,
    userName: string,
    userRole: string
  };
  state = {
    showUserInfoModal: false
  };

  getUserLogo = () => {
    return (
      <DropdownToggle className="dropdownToggleButton mr-3 pr-0" onClick={this.toggle} id="profile">
        {" "}
        <span className="displayFlex alignItemsCenter justifyContentFlexCenter card-text noPadding" key={uuid.v4()}>
          {getUserNameAcronym(getUserNameForSelectedLanguage(this.props.language, this.props.userName))}
        </span>
        <CustomTooltip
          tooltipText={getMessage("PROFILE")}
          tooltipId="profile"
        />
      </DropdownToggle>
    );
  };

  toggle = () => {
    this.setState({
      showUserInfoModal: !this.state.showUserInfoModal
    });
  };

  getAgentHeaderLogo = () => {
    if (this.props.logo.loading) {
      return getMessage("LOADING");
    } else if (this.props.logo.url) {
      return <img className="logoHeader" src={this.props.logo.url} />
    }
  }

  render() {
    return (
      <section className="header createSessionHeader">
        <Container className="pl-lg-0">
          <Row className="alignItemsCenter">
            <Col lg="8" md="8" xs="9" className="headerLogoContainer">
              <div className="logoHeaderDiv">
                {this.getAgentHeaderLogo()}
              </div>
            </Col>
            {(this.props.userRole === USER_ROLES.AGENT ||
              this.props.userRole === USER_ROLES.SUPER_ADMIN ||
              this.props.userRole === USER_ROLES.ADMIN) && (
              <Col lg="4" md="4" xs="3" className="text-right">
                <div>
                  <UserDropdownModal
                    show={this.state.showUserInfoModal}
                    userIcon={this.getUserLogo()}
                    toggle={this.toggle}
                    handleLogoutClick={this.props.handleLogout}
                    userProfileData={this.props.userProfileData}
                    handleUpdateProfileClick={this.props.handleUpdateProfileClick}
                    toggleChangePasswordModal={this.props.toggleChangePasswordModal}
                    showChangePasswordModal={this.props.showChangePasswordModal}
                    role={this.props.userRole}
                    isSSOLogin={this.props.isSSOLogin}
                    language={this.props.language}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    );
  }
}

export default Header;
