import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";

export default class ConnectivityTest extends BaseTest {

  constructor(props) {
    super(props);

    // this.test = test;
    this.setValues();
    // this.run();
  }

  setValues = () => {
    this.iceCandidateFilter = this.props.iceCandidateFilter;
    this.timeout = null;
    this.parsedCandidates = [];
    this.call = null;
    super.output="";
  }

  componentDidMount() {
    this.test(this);
    if(this.props.startTest) {
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest){
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
    }
  }

  run() {
    this.asyncCreateTurnConfig(this.start.bind(this),
      () => {
        this.reportFatal()
        console.error(this.props.name, ' test fail');
      }
    );
  }

  start = (config) => {
    try {
      // this.call = new Call(config, this.test);
      this.initialize(config, this)
      this.setIceCandidateFilter(this.iceCandidateFilter);
      let self = this
      // Collect all candidates for validation.
      this.pc1.addEventListener('icecandidate', function(event) {
        if (event.candidate) {
          var parsedCandidate = self.parseCandidate(event.candidate.candidate);
          self.parsedCandidates.push(parsedCandidate);

          // Report candidate info based on iceCandidateFilter.
          if (self.iceCandidateFilter(parsedCandidate)) {
            self.reportInfo(
              'Gathered candidate of Type: ' + parsedCandidate.type +
              ' Protocol: ' + parsedCandidate.protocol +
              ' Address: ' + parsedCandidate.address);
            console.log('Gathered candidate of Type: ' + parsedCandidate.type +
            ' Protocol: ' + parsedCandidate.protocol +
            ' Address: ' + parsedCandidate.address);
          }
        }
      });

      var ch1 = this.pc1.createDataChannel(null);
      ch1.addEventListener('open', function() {
        ch1.send('hello');
      });
      ch1.addEventListener('message', function(event) {
        if (event.data !== 'world') {
          self.reportError('Invalid data transmitted.');
          console.error('Invalid data transmitted.');
        } else {
          console.log('Data successfully transmitted between peers.');
          self.reportSuccess('Data successfully transmitted between peers.');
        }
        self.hangup();
      });
      this.pc2.addEventListener('datachannel', function(event) {
        var ch2 = event.channel;
        ch2.addEventListener('message', function(event) {
          if (event.data !== 'hello') {
            self.hangup('Invalid data transmitted.');
          } else {
            ch2.send('world');
          }
        }.bind(this));
      }.bind(this));
      this.establishConnection();
      this.timeout = setTimeout(this.hangup.bind(this, 'Timed out'), 5000);
    } catch(error){
      console.error('error: ', error.message);
      this.reportError(error.message)
      this.done();
    }
  }

  findParsedCandidateOfSpecifiedType(candidateTypeMethod) {
    for (var candidate in this.parsedCandidates) {
      if (candidateTypeMethod(this.parsedCandidates[candidate])) {
        return candidateTypeMethod(this.parsedCandidates[candidate]);
      }
    }
  }

  hangup(errorMessage) {
    if (errorMessage) {
      // Report warning for server reflexive test if it times out.
      if (errorMessage === 'Timed out' &&
          this.iceCandidateFilter.toString() === BaseTest.isReflexive.toString() &&
          this.findParsedCandidateOfSpecifiedType(BaseTest.isReflexive)) {
        console.warn('Could not connect using reflexive ' +
            'candidates, likely due to the network environment/configuration.')
        this.reportWarning('Could not connect using reflexive ' +
            'candidates, likely due to the network environment/configuration.');
      } else {
        this.reportError(errorMessage);
        console.error(errorMessage);
      }
    }
    clearTimeout(this.timeout);
    this.close();
    this.done();
    console.log('Connectivity test completed', this.props.name);
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.close();
  }
}