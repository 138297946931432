import { USER_ADMISSION_STATUS, PARTICIPANT_STATUS, USER_ROLES } from "UTILS/Constants";

export const getCustomerCoBrowsePermissionRequest = state => {
  return state.CustomerCoBrowseReducer.customerCoBrowsePermissionRequest
}

export const getCustomerCoBrowsePermissionReply = state => {
  return state.CustomerCoBrowseReducer.customerCoBrowsePermissionReply
}

export const getParticipantLeftCustomerCoBrowse = state => {
  return state.CustomerCoBrowseReducer.participantLeftCustomerCoBrowseUniqueId
}

export const getParticipantJoinedCustomerCoBrowse = state => {
  return state.CustomerCoBrowseReducer.participantJoinedCustomerCoBrowseUniqueId
}

export const getParticipantDeniedCustomerCoBrowse = state => {
  return state.CustomerCoBrowseReducer.participantDeniedCustomerCoBrowseUniqueId
}
export const getAdmittedParticipants = state => {
  return state.ParticipantReducer.participants
    && state.ParticipantReducer.participants.filter(participant =>
      participant.admissionStatus == USER_ADMISSION_STATUS.ADMITTED
      && participant.status === PARTICIPANT_STATUS.ACTIVE);
};

export const getRoomId = state => {
  return state.CustomerCoBrowseReducer.roomId
}

export const getShareUrl = state => {
  return state.CustomerCoBrowseReducer.shareUrl
}

export const getShowCustomerCoBrowse = state => {
  return state.CustomerCoBrowseReducer.showCustomerCoBrowse
}

export const selectPresenter = state => {
  return state.RoomMetaDataReducer.roomMetaData.presenterId;
};

export const selectCoBrowseBaseUrl = state => {
  return state.SessionReducer.sessionDetails.cobrowseSettings?.cobrowseUrl
}
export const selectCoBrowseType = state => {
  return state.SessionReducer.sessionDetails.cobrowseSettings?.cobrowseType
}
export const getAgentEmailFromParticipants = state => {
  //get agent's email from participants array-> agent entry will be always 1
  return state.ParticipantReducer.participants.find(participant => participant.role === USER_ROLES.AGENT)?.email
}

export const getShowTargetSiteSelectionModal = state => {
  return state.CustomerCoBrowseReducer.showTargetSiteSelectionModal
}
export const getEndCustomerCBSession = state => {
  return state.CustomerCoBrowseReducer.endCustomerCBSession
}
export const getCustomerCoBrowseStarted = state => {
  return state.CustomerCoBrowseReducer.customerCoBrowseStarted
}