import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
// import Spinner from "COMPONENTS/Spinner/Spinner";
import HeaderContainer from "../Header/HeaderContainer";
import EditOrganization from "./EditOrganization";

//Constant
import {CONFIG, TIMEOUT, ERROR } from "../../../Utils/Constants";
//Strings
import { getMessage } from "CONFIG/i18n";
//Actions
import { organisationAction } from "CONFIG/ActionFactory";
import Spinner from "COMPONENTS/Spinner/Spinner";
class EditOrganizationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      storageChanged: false,
      currentBucket: "",
      focused : null,
      spinnerVisibilityMain : false,
    };
  }
  componentDidMount() {
    if(!this.props.editOrganisationData)
    {
      this.props.history.push(CONFIG.path.superAdmin)        
    }    
  }
  handleCancelClick = () => {
    this.props.history.push(CONFIG.path.superAdmin)
  };

  getCurrentDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  };

  verifyStorage=(storageElement,callback)=>{
    organisationAction.verifyStorage(storageElement).then(
      data => {
        callback && callback();
      }
    ).catch (
      error => {
        callback && callback(error);
      }
    )
  }

  handleSaveOrgClick = (editCustomerData, callBack) => {
    this.setState({spinnerVisibilityMain:true})
    organisationAction
    .saveCustomerAction(editCustomerData, this.props.editOrganisationData.id)
    .then(
      response_data => {
        toastr.success("ORGANIZATION_UPDATED_SUCCESSFULLY");
        callBack("");      
        setTimeout(() => { 
          this.setState({ spinnerVisibilityMain: false });
          this.handleCancelClick();
        }, TIMEOUT.SHORT);
      },
      error => {
        this.setState({spinnerVisibilityMain:false})
        callBack(error.code)
      }
    );
  };

  uploadImage = (formData, data, callBack) => {
    this.setState({ spinnerVisibilityMain: true });
    organisationAction.uploadOrgLogoStatusAction(formData).then(
      response_data => {
        this.setState(
          {
            spinnerVisibilityMain: false,
            isDataEdited: false
          }
        );
        data.logoUrl = response_data.data.data.logoUrl;
        this.handleSaveOrgClick(data, errorMessage => {
          this.setState({ errorMessage: errorMessage });
        });
      },
      error => {
        this.setState({ spinnerVisibilityMain: false });
        this.setState({ errorMessage: error.message });
        callBack(error.code);
      }
    );
  };

  render() {
    return (
      <>
      {this.props.editOrganisationData ?
        <>
        <HeaderContainer
          title={getMessage("EDIT_ORGANIZATION")}
          showModal={false}
          history={this.props.history}
          handleSearchString={""}
          logo
          isDashboard={false}
        />
        <EditOrganization 
          handleSaveOrgClick={this.handleSaveOrgClick}
          language={this.props.language}
          verifyStorage={this.verifyStorage}
          uploadImage={this.uploadImage}
          editOrganisationData={this.props.editOrganisationData}
          handleCancelClick={this.handleCancelClick}
          sessionsCapacity={this.props.sessionsCapacity}
          customerSettings={this.props.customerSettings}
        />
        <Spinner showSpinner={this.state.spinnerVisibilityMain} />
        </>
        : null }
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    editOrganisationData:state.OrganisationReducer.Organization,
    sessionsCapacity:state.OrganisationReducer.sessionsCapacity,
    customerSettings: state.OrganisationReducer.customerData.customerSettings
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EditOrganizationContainer));
