import React from "react";
import { func } from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Strings
import { getMessage } from "CONFIG/i18n";
import "./DeleteConfirmationModal.less"
import "../.././App/App.less";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";

class DeleteConfirmationModal extends React.Component {

  static propTypes = {
    handleYesClick: func,
    handleNoClick: func
  };

  componentWillUnmount() {
    this.disableDeleteBtn(false);
  }

  disableDeleteBtn = (value) => {
    let deleteBtn = document.getElementById("deleteBtn");
    if(deleteBtn){
      deleteBtn.disabled = value;
      if(value)
        deleteBtn.classList.add("pointerEventsNone");
      else
        deleteBtn.classList.remove("pointerEventsNone");
    }
  }

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={() => this.props.handleCancelClick()}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button
          onClick={() => {
            this.disableDeleteBtn(true);
            this.props.handleDeleteClick();
          }
          }
          id="deleteBtn"
          className="buttons customBtn"
          autoFocus
        >
          {getMessage("DELETE")}
        </Button>
      </div>
    );
  };

  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="deleteConfirmationModal"
        toggle={this.props.handleCancelClick}
        backdrop="static"
      >
        <ModalHeader className="popupHeader">
          {this.props.headerMessage}
        </ModalHeader>
        <ModalBody className="popupBody">
          {this.props.deleteMessage}  
        </ModalBody>
        <ModalFooter className="permissionButtons popupFooter">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    );
  }
}

export default modalScrollBar(DeleteConfirmationModal);
