import React from "react";

import ic_switch_on from "ASSETS/Images/ic_switch_on.svg";
import ic_switch_off from "ASSETS/Images/ic_switch_off.png";

const Switch = (props) => {

  return (
    <div className={props.disabled ? "cursorDisabled" : "cursorPointer"}>
      {props.switchOn ? <img
        src={ic_switch_on}
        onClick={() => props.disabled ? false :  props.toggleSwitch(false)}
      />:<img
        src={ic_switch_off}
        onClick={() =>props.disabled ? false : props.toggleSwitch(true)}
      />}
    </div>
  );
}

export default Switch;