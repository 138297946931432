import { ACTIONS, HTTP_METHODS, API_URL } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import AES from "UTILS/AES";
import querystring from 'querystring';
export class CustomerCoBrowseAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  handleBookCoBrowseSession(data) {
    let requestParams = querystring.stringify(data)
    if(window._env_.REACT_APP_ENABLE_AES_ENCRYPTION === "true") {  
      let paramsStringify = JSON.stringify(data)
      let encodeParamsStringify = encodeURIComponent(paramsStringify)
      let encrpytParamsStringify = AES.encryption(encodeParamsStringify)
      requestParams = querystring.stringify({ item: encrpytParamsStringify })
    }
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: requestParams,
        url: window._env_.REACT_APP_ENABLE_AES_ENCRYPTION === "true"? 
          API_URL.CUSTOMER_CO_BROWSE :
          API_URL.CUSTOMER_CO_BROWSE_TEST,
        isCoBrowseRequest: true
      }).then(
        response => {
          console.log(response)
          this.dispatch({
            type: ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_DATA,
            roomId: response.data.roomId,
            shareUrl: response.data.shareUrl
          })
          resolve(response)
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateCustomerCoBrowsePermissionReply(flag) {
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.SET_CUSTOMER_CO_BROWSE_REPLY,
      value: flag
    })
  }

  updateParticipantCoBrowseStatus(uniqueId,flag) {
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
      value: flag,
      uniqueId
    });
  }
  
  showHideCustomerCoBrowse(flag){
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.SHOW_CUSTOMER_CO_BROWSE,
      value: flag,
    });
  }

  endCustomerCoBrowse(){
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_END_SESSION
    });
  }
  
  leaveCustomerCoBrowse = (uniqueId) => {
    console.log("Participants's CB data cleanup")
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_LEAVE_SESSION,
      value: uniqueId
    });
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.CUSTOMER_CO_BROWSE_PARTICIPANT_STATUS_UPDATE,
      uniqueId: uniqueId,
      value: false
    });
    if (this.getState().SessionReducer.uniqueId === uniqueId) {
      this.dispatch({
        type: ACTIONS.CUSTOMER_CO_BROWSE.SHOW_CUSTOMER_CO_BROWSE,
        value: false
      });
    }
  }
  getCobrowseTargetSites(sessionKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: `${API_URL.GET_CO_BROWSE_SITES}`,
        requestBody: {
          sessionKey: sessionKey
        },
      }).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error);
        }
      );
    });
  }

  showTargetSiteSelectionModalAction(flag) {
    this.dispatch({
      type: ACTIONS.CUSTOMER_CO_BROWSE.SHOW_TARGET_SITE_SELECTION_MODAL,
      value: flag
    })
  }
}

export default CustomerCoBrowseAction;
