import React, { Component } from "react";
import { array, bool, func, string } from "prop-types";
import { Row, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isIE } from "react-device-detect";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { getMessage } from "CONFIG/i18n";

import ic_close_waiting_room from "ASSETS/Images/ic_close_waiting_room.svg";

import "../AddInvitee/AddInvitee.less";
import "./WaitingArea.less";

class WaitingAreaModal extends Component {
    static propTypes = {
      show: bool,
      waitingParticipants: array,
      addInviteeHandle: func,
      isOrganiser: bool,
      toggle: func,
      participant: array,
      uniqueId: string,
      admittedParticipants: array,
      addInviteeManualHandle: func,
      getAdmittedParticipantRow: func,
      handleAddInviteHistory: func,
      sessionLink: string,
      getWaitingParticipantRow: func
    };
    render() {
      return (
        <div className="popUp waitingArea">
          <Modal
            isOpen={this.props.show}
            className= { " modal-md modalContainer waitingArea " + (
              !this.props.isOrganiser
                ? " userWaitingAreaWidth "
                : "modal-wrapper" )
            }
            xs="8"
            backdrop={true}
            fade={true}
          >
            <ModalHeader toggle={this.props.toggle} className="d-none" />
            <ModalBody className="popupBody modalBody pt-0">
              <div>
                {
                  <div className="pt-1">
                    <span className="userNameText">
                      {this.props.isOrganiser &&
                        this.props.waitingParticipants &&
                        this.props.waitingParticipants.length > 0 &&
                        getMessage("WAITING_AREA") + ":"}
                    </span>
                    <span className="subText">
                      {this.props.isOrganiser &&
                        this.props.waitingParticipants &&
                        this.props.waitingParticipants.length > 0 &&
                        ` ${getMessage("USERS_WAITING", {
                          count:
                                this.props.waitingParticipants.length,
                        })}`}
                    </span>
                    {this.props.waitingParticipants.length > 0 && (
                      <img
                        src={ic_close_waiting_room}
                        alt={""}
                        className="pull-right cursorPointer pt-1"
                        onClick={this.props.toggle}
                      />
                    )}
                  </div>
                }
                {this.props.isOrganiser &&
                this.props.waitingParticipants &&
                this.props.waitingParticipants.length > 0 &&
                this.props.waitingParticipants.map((user, index) => {
                  return user.uniqueId ? this.props.getWaitingParticipantRow(user, index): ""
                })}
              </div>
              <div>
                <div className="d-flex justify-content-end">
                  {this.props.waitingParticipants.length === 0 && (
                    <img
                      src={ic_close_waiting_room}
                      alt={""}
                      className="cursorPointer pt-1"
                      onClick={this.props.toggle}
                    />
                  )}
                </div>
                <div className="row mx-0 justify-content-between pt-4">
                  <span className="userNameText col-3 p-0">
                    {getMessage("ATTENDEES") + ":"}
                  </span>
                                
                  <span className="subText">
                    {/* {this.props.isOrganiser &&
                      this.props.admittedParticipants.length > 0 &&
                      ` ${getMessage("PARTICIPANT_COUNT", {
                        count: this.props.admittedParticipants.length,
                        totalCount:
                              this.props.admittedParticipants.length +
                              this.props.waitingParticipants.length,
                      })}`} */} 
                  </span>
                  {/*
                    Display Allow Edit title to agent and if admitted participants are there
                    OR 
                    display title to user because it shows switch in disabled state when allowEditAccess=true. 
                  */}
                  {((this.props.isOrganiser &&
                      this.props.admittedParticipants.length > 1) || (this.props.participant && this.props.uniqueId === this.props.participant.uniqueId && this.props.participant.uniqueId !== this.props.organiser && this.props.participant.allowEditAccess)) &&<span className="userNameText p-0 col-3 d-flex justify-content-end">
                    {getMessage("ALLOW_EDIT")}
                  </span>}
                </div>
                <div>
                  {this.props.admittedParticipants &&
                  this.props.admittedParticipants.length > 0 &&
                  this.props.admittedParticipants.map(
                    (participant, index) => {
                      return this.props.getAdmittedParticipantRow(participant, index);
                    }
                  )}
                </div>
              </div>
              {this.props.isOrganiser && (
                <Row className="">
                  {/* {this.props.addInvitee(true)} */}
                  <div className="col-12  col-sm-5 col-md-4 col-lg-4 pb-1 pt-1">
                    <Button
                      className="buttonTextFont admitButton w-100"
                      onClick={this.props.addInviteeManualHandle}
                    >
                      {getMessage("MANUAL_ENTRY")}
                    </Button>
                  </div>

                  {/* <br /> */}
                  <div className="col-12  col-sm-3 col-md-4 col-lg-4 pb-1 pt-1">
                    <Button
                      className="buttonTextFont admitButton w-100"
                      onClick={this.props.handleAddInviteHistory}
                    >
                      {getMessage("INVITE_HISTORY")}
                    </Button>
                  </div>
                  {/* <br /> */}
                  <div className="col-12  col-sm-4 col-md-4 col-lg-4 pb-1 pt-1">
                    {isIE ? (
                      <Button
                        disabled={
                          this.props.sessionLink
                            ? false
                            : true
                        }
                        className="buttonTextFont admitButton w-100"
                        onClick={() => {
                          window.clipboardData && 
                            window.clipboardData.setData("Text",this.props.sessionLink);
                          if (window.clipboardData &&
                            window.clipboardData.getData("Text")
                          )
                            toastr.info("SESSION_LINK_COPIED");
                          else
                            toastr.error("SESSION_LINK_NOT_COPIED");
                        }}
                      >
                        {getMessage("COPY_SESSION_LINK")}
                      </Button>
                    ) : (
                      <CopyToClipboard
                        text={this.props.sessionLink}
                        onCopy={(text, result) => {
                          if (result)
                            toastr.info("SESSION_LINK_COPIED");
                          else
                            toastr.error("SESSION_LINK_NOT_COPIED");
                        }}
                      >
                        <Button
                          disabled={
                            this.props.sessionLink
                              ? false
                              : true
                          }
                          className="buttonTextFont admitButton w-100"
                        >
                          {getMessage(
                            "COPY_SESSION_LINK"
                          )}
                        </Button>
                      </CopyToClipboard>
                    )}
                  </div>
                </Row>
              )}
              <div className="d-flex justify-content-center">
                <QRCode
                  includeMargin={true}
                  id="QRcode"
                  className="QRcode mt-2"
                  value={this.props.sessionLink}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
}

export default modalScrollBar(WaitingAreaModal);