import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import withErrorHandler from "Components/WrappedComponents/withErrorHandler/withErrorHandler";
import UserWaiting from "./UserWaiting";
import { sendWebsocketMessage, disconnectWebsocket } from "WEBSOCKET/WebsocketHandler";
import { CONFIG, SESSION_STATUS, SESSION_TYPE, USER_ADMISSION_STATUS } from "UTILS/Constants";

import { setUserWaitingEndTime } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeAction"
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

import { userSessionName } from "./selectors";
import { selectSessionStatus, selectUserAdmissionStatus } from "COMPONENTS/Dashboard/selectors";
import { selectSessionEndedFlag } from "COMPONENTS/CommonComponents/Header/selectors.js";
import { selectSessionType } from "COMPONENTS/MainLayout/selectors";
import { selectSessionKey } from "../../Routes/selectors";
import { disableBrowseBackBtn } from "UTILS/Utility";
import { getSessionDetails } from "REDUX/Selector/SessionSelector";
import { organisationAction } from "CONFIG/ActionFactory";
import PageNotFound from "COMPONENTS/ErrorComponents/PageNotFound";

export class UserWaitingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerVisibility: false,
    };
  }
  componentDidMount() {
    /*
    * clear user waiting time when QR user comes on waiting screen where 
    * "Please wait, the host will let you in soon." message is displayed.
    */
    if (this.props.sessionType === SESSION_TYPE.QR_CODE) {
      this.props.setUserWaitingEndTime(null);
    }

    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
    // if page is reloaded then logo url data is cleared from store, to fetch the logo again and set it in store this code is needed
    if (this.props.sessionData.logoUrl) {
      organisationAction.setLogoUrl(this.props.sessionData.logoUrl);
    }

  }
  
  componentDidUpdate(prevProps, prevState) {

    if (this.props.sessionStatus === SESSION_STATUS.ACTIVE) {
      if (this.props.userAdmissionStatus === USER_ADMISSION_STATUS.ADMITTED) {
        this.props.history.push(CONFIG.path.dashboard);
      }
    }

    if (prevProps.userAdmissionStatus !== this.props.userAdmissionStatus
      || prevProps.sessionStatus !== this.props.sessionStatus) {

      if (this.props.userAdmissionStatus === USER_ADMISSION_STATUS.REJECTED
        || this.props.sessionStatus === SESSION_STATUS.DELETED
        || this.props.sessionStatus === SESSION_STATUS.UPDATED) {
        this.props.disconnectWebsocket();
      }
    }

    // pushed user to session ended screen
    if (this.props.isSessionEnded) {
      this.props.history.push(CONFIG.path.sessionEnded);
    }
  }

  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.sessionStatus ? <UserWaiting
          onSelectChange={this.onSelectChange}
          language={this.props.language}
          userSessionName={this.props.userSessionName}
          sessionStatus={this.props.sessionStatus}
          userAdmissionStatus={this.props.userAdmissionStatus}
          sessionType={this.props.sessionType}
        /> :
          <PageNotFound />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.LanguageReducer.language,
    userSessionName: userSessionName(state),
    sessionStatus: selectSessionStatus(state),
    userAdmissionStatus: selectUserAdmissionStatus(state),
    isSessionEnded: selectSessionEndedFlag(state),
    sessionType: selectSessionType(state),
    websocketError: state.ErrorReducer.websocketError,
    uriIdentifier: state.UserReducer.uriIdentifier,
    sessionKey: selectSessionKey(state),
    sessionData: getSessionDetails(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendWebsocketMessage,
    disconnectWebsocket,
    setLanguageAction,
    setUserWaitingEndTime
  }, dispatch);
};

export default withErrorHandler(
  connect(mapStateToProps, mapDispatchToProps)(UserWaitingContainer)
);
