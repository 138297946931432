import React, { Component } from "react";
import { Row, Col} from "reactstrap";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { getMessage } from "CONFIG/i18n";

import Pagination from "react-js-pagination";

import "../AdminDashboard.less";
import "./SessionList.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import { PAGE_COUNT } from "UTILS/Constants";
import { formatDateByLocale, getTimeInLocaleTimezone } from "UTILS/DateUtils";

import { adminAction } from "CONFIG/ActionFactory";

// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from 'COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip';

import icClosedRow from "ASSETS/Images/ic_closed _row.svg";
import icExpandedRow from "ASSETS/Images/ic_expanded_row.svg";

export default class SessionFollowUps extends Component{
  constructor(props) {
    super(props);

    this.state={
      selectedSessionKey: null
    };
    this.tooltipBoundaryElement = null;
  }

  componentDidMount() {
    // timeout is added because it takes some time for the 
    // contents of the table to be loaded completely 
    setTimeout(() => {
      this.props.setHeaderPadding();
    },10)
    this.getTooltipBoundaryElement();
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("report-list")[0];
  }
  
  fetchTableData=(page)=>{
    this.props.fetchSessionFollowUps(
      page - 1,
      this.props.sessionFollowUpsPageSize,
      this.props.searchKey ? this.props.searchKey : undefined
    );
    const tableData = document.querySelector(".reportListContainer .react-bs-container-body");
    tableData.scrollTop = 0;
    this.props.setHeaderPadding();
  }

  customerNameFormat = (cell, row) => {
    if(row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">
          {row.name}
        </div>
      );
    }
    return (
      <span className="text-truncate nameEllipsis custom-tooltip" id={`customerName${row.sessionKey}`}>
        {row.name}
        <CustomTooltip
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipText={row.name}
          tooltipId={`customerName${row.sessionKey}`}
        />
      </span>
    );
  }

  qrLabelFormat = (cell, row) => {
    if (row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">
          {row.qrLabel ? row.qrLabel : getMessage("QR_LABEL_NOT_AVAILABLE")}
        </div>
      );
    }
    return (
      <span className="text-truncate nameEllipsis custom-tooltip" id={`qrLabel${row.sessionKey}`}>
        {row.qrLabel ? row.qrLabel : getMessage("QR_LABEL_NOT_AVAILABLE")}     
        <CustomTooltip
          tooltipText={row.qrLabel ? row.qrLabel : getMessage("QR_LABEL_NOT_AVAILABLE")}
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipId={`qrLabel${row.sessionKey}`}
        />
      </span>
    );
  };

  emailFormat = (cell, row) => {
    if(row.email && row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">
          {row.email}
        </div>
      )
    }
    return (
      <>
        {row.email ?
          <div className="text-truncate nameEllipsis custom-tooltip">
            <span id={`email${row.sessionKey}`}>
              {row.email}
              <CustomTooltip
                tooltipBoundariesElement={this.tooltipBoundaryElement}
                tooltipText={row.email}
                tooltipId={`email${row.sessionKey}`}
              />
            </span>

          </div>
          :
          <div className="centerAlignText">{"--"}</div>
        }
      </>
    )
  }

  phoneNumberFormat = (cell, row) => {
    if(row.phone && row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">
          {row.phone}
        </div>
      )
    }
    return (
      <div className="text-truncate">
        {row.phone ? row.phone : <div className="centerAlignText">{"--"}</div>}
      </div>
    )
  }
  
  infoFormat = (cell, row) => {
    if(row.info && row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">
          {row.info}
        </div>
      )
    }
    return (
      <>
        {row.info ?
          <span className="text-truncate nameEllipsis custom-tooltip" id={`info${row.sessionKey}`}>
            {row.info}
            <CustomTooltip
              tooltipBoundariesElement={this.tooltipBoundaryElement}
              tooltipText={row.info}
              tooltipId={`info${row.sessionKey}`}
            />
          </span>
          :
          <div className="centerAlignText">{"--"}</div>
        }
      </>
    )
  }

  descriptionFormat = (cell, row) => {
    if(row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">{row.description}</div>
      );
    }
    return (
      <span className="text-truncate nameEllipsis custom-tooltip" id={`description${row.sessionKey}`}>
        {row.description}
        <CustomTooltip
          tooltipBoundariesElement={this.tooltipBoundaryElement}
          tooltipText={row.description}
          tooltipId={`description${row.sessionKey}`}
        />
      </span> 
    );
  }

  selectRow = (cell, row) => {
    return (
      <div className="selection-arrow">
        <img src={row.sessionKey === this.state.selectedSessionKey ? icExpandedRow : icClosedRow} />
      </div>
    );
  };

  preferredTimeSlotFormat = (cell, row) => {
    let fromDate = ` ${formatDateByLocale(row.fromTime, this.props.language)}`;
    let timeSlot = ` ${getTimeInLocaleTimezone(row.fromTime)} - ${getTimeInLocaleTimezone(row.toTime)}`;

    if(row.sessionKey === this.state.selectedSessionKey) {
      return (
        <div className="columnText">{fromDate} <br /> {timeSlot}</div>
      );
    }
    return (
      <div className="text-truncate">{fromDate} <br /> {timeSlot}</div> 
    );
  }

  renderSessionFollowUps = (sessionFollowUps) => {
    const options = {};
    options.onRowClick = (row) => {
      // Save sessionKey in state to check if the same row is being clicked multiple times
      this.setState({
        selectedSessionKey:
          this.state.selectedSessionKey !== row.sessionKey
            ? row.sessionKey
            : "",
      });
    };

    return(
      <div>
        <div className="reportsTitle">
          { getMessage("SESSION_FOLLOW_UPS") }
        </div>
        <BootstrapTable
          data={sessionFollowUps}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='custom-list report-list'
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
          options={options}
          printable
        >
          <TableHeaderColumn
            dataField='sessionKey'
            hidden={true}
            isKey>
            {getMessage("SESSION_KEY")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.selectRow}
            width="6"
          ></TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.qrLabelFormat}
            dataField='qrLabel'
            width="20"
            dataSort={true}
          >
            {getMessage("QR_LABEL")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.customerNameFormat}
            dataField='name'
            width="20"
            dataSort={true}
            className="customer-name-title"
          >
            {getMessage("CUSTOMER_NAME_TITLE")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.emailFormat}
            dataField='email'
            headerAlign="center"
            width="20"
          >
            {getMessage("EMAIL")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.phoneNumberFormat}
            dataField='phone'
            headerAlign="center"
            dataAlign="center"
            width="18"
          >
            {getMessage("PHONE_NUMBER")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.infoFormat}
            dataField='info'
            headerAlign="center"
            dataAlign="center"
            width="18"
          >
            {getMessage("INFO")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.preferredTimeSlotFormat}
            dataField='fromTime'
            dataSort={true}
            headerAlign="center"
            dataAlign="center"
            width="30"
          >
            {getMessage("PREFERRED_TIME")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={this.descriptionFormat}
            dataField='description'
            headerAlign="center"
            width="28"
          >
            {getMessage("DESCRIPTION")}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
  render() {
    const { sessionFollowUpsCurrentPageNo, sessionFollowUps, sessionFollowUpsCount } = this.props;

    const displayPage = sessionFollowUpsCurrentPageNo + 1;
    return (
      <div className="reportListContainer">
        {this.props.sessionFollowUps &&
        this.props.sessionFollowUps.length > 0 ? (
            <React.Fragment>
              <div className="overflow-auto ">
                {this.renderSessionFollowUps(sessionFollowUps)}
              </div>
              <div className="pagination-margin">
                <Row className="paginationRow"> 
                  <Col xs={4} className="paginationEntries">
                    {getMessage("PAGINATION_TOTAL_ENTRIES",
                      {from: sessionFollowUpsCurrentPageNo * this.props.sessionFollowUpsPageSize + 1,
                        to: sessionFollowUpsCurrentPageNo * this.props.sessionFollowUpsPageSize + this.props.sessionFollowUpsPageSize < sessionFollowUpsCount ? sessionFollowUpsCurrentPageNo * this.props.sessionFollowUpsPageSize + this.props.sessionFollowUpsPageSize : sessionFollowUpsCount,
                        totalCount: sessionFollowUpsCount})}
                  </Col> 
                  <Col xs={4} className="pagination">
                    {sessionFollowUpsCount > this.props.sessionFollowUpsPageSize ?
                      <Pagination
                        activePage={displayPage}
                        itemsCountPerPage={this.props.sessionFollowUpsPageSize}
                        totalItemsCount={sessionFollowUpsCount}
                        pageRangeDisplayed={3}
                        onChange={this.fetchTableData}
                        itemClass="page-item"
                        linkClass="page-link"
                      /> : null
                    }
                  </Col>
                  <Col xs={4} className="pageCount">
                    {sessionFollowUpsCount > PAGE_COUNT[0].value &&
                      <CustomDropdown
                        options={PAGE_COUNT}
                        onChange={(value)=>{
                          this.props.fetchSessionFollowUps(0, value);
                        }}
                        value={this.props.sessionFollowUpsPageSize}
                        dropdownClass="pageSizeDropdown"
                      />
                    }
                  </Col>
                </Row>
              </div>
            </React.Fragment>
            
          ) : (
            <div className="margin-bottom">
              <NoData minHeight={"60vh"} 
                message={this.props.searchKey ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                  searchKey: this.props.searchKey,
                }):getMessage("NO_MEETING_FOLLOW_UPS")} />
            </div>
          )}
      </div>
    );
  }
}
