import React from "react";
import Package from '../../../package.json';
import { Alert } from 'react-bootstrap';
export default class About extends React.Component {
  render(){
    return(
      <div className='container'>
        <center>
          <Alert className="m-5" variant='success'>
            <h3>Version: {Package.version}</h3>
            <h3>Description: {Package.description}</h3>
          </Alert>
        </center>
      </div>
    )
  }
}
