import React, { useEffect } from 'react';
import { PDF_FILE_BASE_URL, COMPONENT_IN_FOCUS } from "UTILS/Constants";
import './PDFJS.less';

const PDFJS = (props) => {

  useEffect(() => {
    props.hideSpinner();
  }, []);

  return (
    <div className='pdf-viewer'>
      <iframe 
        id="pdf-js-viewer"
        src={PDF_FILE_BASE_URL + window.encodeURIComponent(props.fileUrl) + 
          "#zoom=" + (props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS ? "page-width" : "page-fit")}
      />
    </div>
  );
}

export default PDFJS;