import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { getMessage } from "CONFIG/i18n";
import uuid from "uuid";
import "./AddInvitee.less";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

class InviteHistory extends Component {
  state = {};
  toggle = () => {
    this.props.addInviteeHistoryHandle();
  };
  invitee() {
    return this.props.currentSessionInvitees.map((user, i) => {
      delete user.id;
      delete user.isSelected;
      let userName = "";
      if (!user.name) {
        delete user.name;
      }
      if (user.email) {
        userName += user.email + ",";
      } else{
        delete user.email;
      }
      if (user.phone) {
        userName += user.phone;
      } else {
        delete user.phone;
        userName = userName.substring(0, userName.length -1 );
      }
      return (
        <Col
          lg="12"
          md="12"
          xs="12"
          key={uuid.v4()}
          className="pr-lg-0 inputFormAddInvitee paddingTopBottom"
        >
          <Row>
            <Col lg="9" md="9" xs="8" style={{ alignSelf: "center" }}>
              <div
                key={uuid.v4()}
                className="sessionHistoryCardText noPadding noMargin"
              >
                <span className="noMargin custom-tooltip" id={`userName${i}`}>{userName}
                  <CustomTooltip tooltipText={userName} tooltipId={`userName${i}`} />
                </span>
              </div>
            </Col>
            <Col lg="3" md="3" xs="4">
              <Button
                className="customBtn w-100"
                onClick={() => this.props.handleSendClick([user], false)}
              >
                {getMessage("INVITE")}
              </Button>
            </Col>
          </Row>
        </Col>
      );
    });
  }
  render() {
    return (
      <Modal
        isOpen={this.props.show}
        className="inviteHistory modal-md modalContainer modal-wrapper"
        xs="9"
        scrollable={true}
      >
        <ModalHeader toggle={this.toggle} className="popupHeader modal-title-center">
          {getMessage("INVITE_HISTORY")}
        </ModalHeader>
        <ModalBody className="popupBody">
          <div className="AddInvitee">
            <section className="bgColor ">
              <Container>
                <Row>{this.invitee()}</Row>
              </Container>
            </section>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default modalScrollBar(InviteHistory);
