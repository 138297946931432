import { ACTIONS } from "UTILS/Constants";

export const setMicSelected = mic => {
  return {
    type: ACTIONS.TEST_PAGE.MIC_SELECTED,
    mic: mic
  };
}

export const setCameraSelected = camera => {
  return {
    type: ACTIONS.TEST_PAGE.CAMERA_SELECTED,
    camera: camera
  };
}