import { LoginAction } from "COMPONENTS/Login/LoginAction";
import { CreateSessionAction } from "COMPONENTS/Sessions/SessionActions";
import { StartSessionAction } from "COMPONENTS/SessionManagement/StartSession/StartSessionAction";
import { LeaveSessionAction } from "COMPONENTS/LeaveSession/LeaveSessionAction";
import { WhiteboardAction } from "COMPONENTS/Whiteboard/WhiteboardAction";
import { LogoutAction } from "COMPONENTS/Header/LogoutAction";
import { HeaderAction } from "COMPONENTS/CommonComponents/Header/HeaderAction";
import { AudioVideoAction } from "COMPONENTS/Video/AudioVideoAction";
import { ResetPasswordAction } from "COMPONENTS/ResetPassword/ResetPasswordAction";
import { SetPasswordAction } from "COMPONENTS/SetPassword/SetPasswordAction";
import { OrganisationAction } from "COMPONENTS/SuperAdmin/Organisations/OrganisationAction";
import { UserAction } from "COMPONENTS/Admin/UserAction";
import { UserProfileAction } from "COMPONENTS/UpdateProfile/UpdateProfileAction";
import { AddInviteeAction } from "COMPONENTS/AddInvitee/AddInviteeAction";
import { AppEmulationAction } from "COMPONENTS/AppEmulation/AppEmulationAction";
import { ChangePasswordAction } from "COMPONENTS/ChangePassword/ChangePasswordAction";
import { SystemAnnouncementAction } from "../Components/SuperAdmin/SystemAnnouncements/SystemAnnoucementActions";
import { CoBrowseAction } from "COMPONENTS/CoBrowse/CoBrowseAction";
import { CustomerCoBrowseAction } from "COMPONENTS/CustomerCoBrowse/CustomerCoBrowseAction";
import { AdminAction } from "COMPONENTS/Admin/AdminAction";
import { QRCodesAction } from "COMPONENTS/Admin/QRCodes/QRCodesAction";
import { QRCodeAgentsAction } from "COMPONENTS/Admin/QRCodes/QRCodeAgentsAction";

export const loginAction = new LoginAction();
export const sessionActions = new CreateSessionAction();
export const startSessionAction = new StartSessionAction();
export const leaveSessionAction = new LeaveSessionAction();
export const whiteboardAction = new WhiteboardAction();
export const logoutAction = new LogoutAction();
export const headerAction = new HeaderAction();
export const audioVideoAction = new AudioVideoAction();
export const resetPasswordAction = new ResetPasswordAction();
export const setPasswordAction = new SetPasswordAction();
export const organisationAction = new OrganisationAction();
export const systemAnnouncementAction = new SystemAnnouncementAction();
export const userAction = new UserAction();
export const userProfileAction = new UserProfileAction();
export const inviteeAction = new AddInviteeAction();
export const appEmulationAction = new AppEmulationAction();
export const changePasswordAction = new ChangePasswordAction();
export const coBrowseAction = new CoBrowseAction();
export const customerCoBrowseAction = new CustomerCoBrowseAction();
export const adminAction = new AdminAction();
export const qrCodesAction = new QRCodesAction();
export const qrCodeAgentsAction = new QRCodeAgentsAction();