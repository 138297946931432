export const getLanguage = state => {
  return state.LanguageReducer && state.LanguageReducer.language;
};

export const  getUserDetails = state => {
  return state.QrReducer.userDetails;
};

export const getQrErrorMessage = state => {
  return state.QrReducer.error;
}

export const getQRSessionJoinType = state => {
  return state.QrReducer.qrSessionJoinType;
}

export const selectSessionStatus = state => {
  return state.SessionReducer.sessionDetails.sessionStatus;
}

export const selectSessionType = state => {
  return state.SessionReducer.sessionDetails.sessionType;
}

export const selectQrActiveScreen = state => {
  return state.QrReducer.activeQRScreen;
}

export const selectQRCode = state => {
  return state.QrReducer.qrId;
}

export const selectUserWaitingEndTime = state => {
  return state.QrReducer.userWaitingEndTime
} 

export const selectAgentArrivedInMeeting = state => {
  return state.QrReducer.isAgentArrivedInMeeting
}

export const selectIsUserWaitingTimeEnded = state => {
  return state.QrReducer.userWaitingTimeEnded
}

export const getFollowUpSuccess = state => {
  return state.QrReducer.followUpSuccess
}