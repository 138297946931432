import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class AddInviteeAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  addInviteeAction(sessionKey) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          sessionKey: sessionKey
        },
        url: API_URL.VIEW_INVITEES
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}
