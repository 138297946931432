import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import * as log from "loglevel";
import Login from "./Login";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";
import { loginAction, leaveSessionAction, logoutAction } from "CONFIG/ActionFactory";
import { setUserWaitingEndTime } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeAction";
import { USER_ROLES, CONFIG, ERROR, LANGUAGE } from "UTILS/Constants";
import { selectUserRole, selectToken } from "../Whiteboard/selectors";
import { changeLanguage } from "CONFIG/i18n";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import { removeTrailingSlash, getURLParameter,
  getStringArrayInLowerCase, getUrlPathExcludingSearchString } from "../../Utils/Utility";
import { getTimeInMillisec } from "UTILS/DateUtils";

export class LoginContainer extends Component {
  constructor(props) {
    super(props);
    log.setLevel("error");
  }

  state = {
    networkError: "",
    roleByPath: ""
  };

  componentDidMount() {
    /*
    * clear user waiting time when user navigate to login screen
    */
    this.props.setUserWaitingEndTime(null);
    let loginPath = removeTrailingSlash(this.props.location.pathname).toLowerCase();
    this.setRoleByPath(loginPath);
    if (CONFIG.path.superAdminLogin === loginPath) {
      this.props.setLanguageAction("en");
      changeLanguage();
    }
    if (this.props.token) {
      switch (this.props.role) {
        case USER_ROLES.SUPER_ADMIN:
          if (CONFIG.path.superAdminLogin === loginPath)
            this.props.history.push(CONFIG.path.superAdmin)
          else
            logoutAction.logoutAction();
          break;
        case USER_ROLES.ADMIN:
          if (CONFIG.path.adminLogin === loginPath)
            this.props.history.push(CONFIG.path.admin)
          else
            logoutAction.logoutAction();
          break;
        case USER_ROLES.AGENT:
          if (CONFIG.path.login === loginPath ||
            CONFIG.path.login === this.props.location.pathname.toLowerCase())
            this.props.history.push(CONFIG.path.createSession)
          else
            logoutAction.logoutAction();
          break;
      }
    }
    this.clearSessionDetails();
    // we need to fetch SSOInfo again when user lands on Login after logout 
    // since we have cleared the entire sessionStorage above (line #54) on LoginContainer mount
    if (this.props.role !== USER_ROLES.SUPER_ADMIN) {
      // TODO: we will be adding new function - validateSSOInfo here & in router - below is temp fix for WAAG-4488
      let isPathPresentInConstants = getStringArrayInLowerCase(CONFIG.path).includes(
        getUrlPathExcludingSearchString(window.location.pathname).toLowerCase());
      if (!isPathPresentInConstants) {
        this.getSSOInfo();
      }
    }
  }

  clearSessionDetails=()=>{
    loginAction.clearSSODetails();
    leaveSessionAction.logoutUser();
  }
  
  getSSOInfo = () => {
    let urlPath = removeTrailingSlash(window.location.pathname).split("/");

    // get sso info api should not get called for superadmin path
    if ((urlPath[1] && urlPath[1].toLowerCase() === 'superadmin')
      || (urlPath[2] && urlPath[2].toLowerCase() === 'superadmin')) {
      return;
    }

    loginAction.getSSOInfo(urlPath[1]).then((response) => {
      if (response.customerSettings.uriIdentifier !== (this.props.uriIdentifier.replace("/", ''))) {
        loginAction.setURIidentifier('/' + response.customerSettings.uriIdentifier);
      }
      loginAction.setRoleByPath(urlPath[2] === 'admin' ? USER_ROLES.ADMIN : USER_ROLES.AGENT)
      if (response.ssoDetails.isSSOEnabled && !this.props.token && !getURLParameter("error")) {
        console.log(`timestamp: Calling SSO Login at ${getTimeInMillisec()}, milliseconds: ${new Date().getTime()}`);
        // Firefox takes time to load sessionStorage (reducer), hence we've to set timeout here 
        // to load URI-identifier in the reducer. This URI-identifier is required on SSO-callback
        setTimeout(() => this.ssoLoginWithCognito(), 1500);
      }
    }).catch(() => {
      loginAction.setURIidentifier('/');
    });
  }

  ssoLoginWithCognito = () => {
    try {
      Amplify.configure({
        Auth: {
          userPoolId: this.props.ssoDetails.userPoolId,
          userPoolWebClientId: this.props.ssoDetails.clientId,
          oauth: {
            domain: this.props.ssoDetails.cognitoDomain,
            responseType: 'code',
            redirectSignIn: window.location.origin + CONFIG.path.ssoLogin,
            redirectSignOut: window.location.origin + CONFIG.path.ssoLogout
          }
        }
      });
      console.log('federatedSignIn');
      Auth.federatedSignIn({ provider: this.props.ssoDetails.identityProvider });
    } catch (error) {
      console.log('error signing in with cognito', error);
      toastr.error("INTERNAL_SERVER_ERROR");
      if (window.location.pathname === this.props.uriIdentifier + CONFIG.path.adminLogin) {
        this.setState({ roleByPath: USER_ROLES.ADMIN });
      }
      else {
        this.setState({ roleByPath: USER_ROLES.AGENT });
      }
    }
  }

  setRoleByPath = (loginPath) => {
    let roleByPath = null;
    switch (loginPath) {
      case CONFIG.path.adminLogin:
        roleByPath = USER_ROLES.ADMIN;
        break;
      case CONFIG.path.superAdminLogin:
        roleByPath = USER_ROLES.SUPER_ADMIN;
        break;
      default:
        roleByPath = USER_ROLES.AGENT;
        break;
    }
    console.log("SSO debug: roleByPath:", roleByPath, ", loginPath:", loginPath);
    this.setState({ roleByPath: roleByPath });
    loginAction.setRoleByPath(roleByPath)
  };

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.location.pathname !== this.props.location.pathname) {
      let pathname = removeTrailingSlash(nextProps.location.pathname).toLowerCase();
      this.setRoleByPath(pathname);
      if (CONFIG.path.superAdminLogin === pathname) {
        this.props.setLanguageAction("en");
        changeLanguage();
      }
    }
  }

  handleLoginClick = userDetails => {
    this.setState({
      networkError: ""
    });
    loginAction.loginAction(userDetails).then(
      data => {
        const { role } = data.data.userDetails;
        switch (role) {
          case USER_ROLES.SUPER_ADMIN:
            this.props.setLanguageAction(LANGUAGE.EN.name);
            changeLanguage();
            this.props.history.push(CONFIG.path.superAdmin)
            break;
          case USER_ROLES.ADMIN:
            this.props.history.push(CONFIG.path.admin)
            break;
          case USER_ROLES.AGENT:
            this.props.history.push(CONFIG.path.createSession)
            break;
        }
      },
      err => {
        if(err.code === ERROR.INVALID_USER) {
          err.code = 'INVALID_CREDENTIALS';
        }
        this.setState({
          networkError: err.code
        });
      }
    );
  };

  render() {
    return (
      <>
        <Login
          ssoDetails={this.props.ssoDetails}
          handleLoginClick={this.handleLoginClick}
          setLanguageAction={this.props.setLanguageAction}
          language={this.props.language}
          networkError={this.state.networkError}
          roleByPath={this.state.roleByPath}
          history={this.props.history}
          ssoLoginWithCognito={this.ssoLoginWithCognito}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    SessionReducer: state.SessionReducer,
    ssoDetails: state.UserReducer.ssoDetails,
    language: state.LanguageReducer.language,
    role: selectUserRole(state),
    token: selectToken(state),
    uriIdentifier: state.UserReducer.uriIdentifier
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { 
      setLanguageAction, 
      setUserWaitingEndTime 
    }, 
    dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
