import React from "react";
import {
  Modal,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";

import "./PDFTronErrorHandler.less";
import { getMessage } from "CONFIG/i18n";
import modalScrollBar from "../ModalScrollBar/ModalScrollBar";

class PDFTronErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { isError: true };
  }

  componentDidCatch(error, info) {
    console.log("PDFTron Error Handling, Error = ", error);
    console.log("PDFTron Error Handling, Info = ", info)
  }

  closeModal = () => {
    this.setState({
      isError: false
    }, () => {
      this.props.handleError();
    })
  }

  render() {
    if(this.state.isError) {
      return (
        <Modal
          isOpen={this.state.isError}
          className="PDFTronErrorHandlerModal"
          backdrop="static"
        >
          <ModalBody>
            {getMessage("INTERNAL_SERVER_ERROR")}
          </ModalBody>
          <ModalFooter className="permissionButtons">
            <Button className="borderBtn customBtn"
              onClick={this.closeModal}
            >
              {getMessage("OK")}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
    return this.props.children;
  }
}
export default modalScrollBar(PDFTronErrorHandler);
