import React, { Component } from "react";
import {
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";
import Pagination from "react-js-pagination";

// Common Components
import CustomDropdown from "../../CommonComponents/CustomDropdown/CustomDropdown";

import ic_pdf_file from "ASSETS/Images/ic_pdf_file.svg";
import { PAGE_COUNT } from "UTILS/Constants";
import { getMessage } from "CONFIG/i18n";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";
// CONSTANTS
import "./PdfListModal.less";
import "COMPONENTS/App/App.less";

class PDFListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageOffset: 1,
      totalCount: 10,
      continuationToken: null,
      fileList: null,
      addButtonIndex: null
    };
  }

  componentDidMount() {
    this.fetchTableData();
  }

  toggle = () => {
    this.props.closePdfListModal();
  };

  showAddButton = (e, index) => {
    this.setState({ addButtonIndex: index });
  };

  pageScrollToTop=()=>{
    var objDiv = document.getElementById("listContainer");
    objDiv.scrollTop = 0;
  }
  handlePageSizeChange = (value) => {
    this.setState({
      pageSize: parseInt(value),
      pageOffset:1
    }, () => {
      this.fetchTableData();
    });
  }

  handlePageChange=(pageNo) =>{
    if(this.state.pageOffset != pageNo){
      this.setState({
        pageOffset: pageNo
      }, () => {
        this.fetchTableData();
        this.pageScrollToTop();
      });
    }
  }

  fetchTableData = () => {
    this.props.fetchPdfListFromCloud(this.state.pageSize, parseInt(this.state.pageOffset-1), responseData => {
      if(responseData) {
        let totalCount = this.state.totalCount;
        if (responseData.continuationToken !== "null") {
          totalCount = this.state.pageSize === responseData.data.length 
            ? ((this.state.pageOffset + 1) * this.state.pageSize) 
            : responseData.data.length + totalCount;
        } else {
          if (this.state.pageOffset === 1) {
            totalCount = this.state.pageSize === responseData.data.length 
              ? this.state.pageSize : responseData.data.length;
          } else {
            totalCount = responseData.data.length + ((this.state.pageOffset-1) * this.state.pageSize)
          }
        }
        
        this.setState({
          fileList: responseData.data,
          continuationToken: responseData.continuationToken,
          totalCount: totalCount
        });
      }
    });
  }

  render() {
    const startOffset = this.state.currentPage - 1;
    return (
      <div className="popUp pdfListModal">
        <Modal
          isOpen={this.props.show}
          className="modal-md pdfListModal"
          xs="8"
        >
          <ModalHeader
            toggle={this.toggle}
            className="d-flex noBorder pl-4 pr-4"
          >
            <span className="listOrModalHeading">
              {getMessage("ORGANIZATION_DOCUMENTS")}
            </span>
          </ModalHeader>
          <ModalBody className="pt-0 pb-0 pl-4 pr-4">
            <div className="centerBody">
              <div className="listOrModalHeading pl-3">{getMessage("NAME")}</div>
              <hr className="divideHr ml-3 mr-2" />
              <div className="maxHeightList scroll-decoration" id="listContainer">
                {this.state.fileList && this.state.fileList.length > 0 ? this.state.fileList.map((pdf, index) => {
                  return (
                    <div className="listItem mx-2 d-flex flex-row justify-content-between py-3"
                      key={index}
                      onMouseOverCapture={(e) =>
                        this.showAddButton(e, index)
                      }
                      onMouseLeave={(e) =>
                        this.showAddButton(e, null)
                      }
                    >
                      <div className="d-flex align-items-center text-break">
                        <img src={ic_pdf_file} alt="ic_pdf_file" className="pr-2" />
                        <span className="fileName">
                          {pdf.FileName}
                        </span>
                      </div>
                      <div className="pr-3">
                        {this.state.addButtonIndex === index && (
                          <Button className="addButton pull-right rounded-pill minWidth"
                            onClick={() => { this.toggle(); this.props.renderCloudPdfOnCanvas(pdf) }}
                          >
                            {getMessage("ADD")}
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                }) : (
                  <div>{
                    getMessage("NO_RECORDS_FOUND")}
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between noBorder pl-4 pr-4">
            <Col xs={4} className="paginationEntries">
              {this.state.fileList && getMessage("PAGINATION_ENTRIES", {
                from: (this.state.pageOffset-1) * this.state.pageSize + 1,
                to: (this.state.pageOffset ) * this.state.pageSize < this.state.totalCount 
                  ? (this.state.pageOffset ) * this.state.pageSize 
                  : this.state.totalCount,
              })}
            </Col>
            <Col
              xs={4}
              className="pagination d-flex justify-content-center"
            >
              {this.state.totalCount > this.state.pageSize ? (
                <Pagination
                  activePage={this.state.pageOffset}
                  itemsCountPerPage={this.state.pageSize}
                  totalItemsCount={this.state.totalCount}
                  pageRangeDisplayed={1}
                  onChange={this.handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                  // prevPageText={getMessage("PREVIOUS_PAGE")}
                  // nextPageText={getMessage("NEXT_PAGE")}
                  // linkClassFirst="d-none"
                  // linkClassLast="d-none"
                  // Need to remove this flag as will need to show first and last button
                  hideFirstLastPages={true}
                />
              ) : null}
            </Col>
            <Col xs={4} className="pageCount">
              {PAGE_COUNT[0].value && (
                <CustomDropdown
                  options={PAGE_COUNT}
                  onChange={this.handlePageSizeChange}
                  value={this.state.pageSize}
                  dropdownClass="pageSizeDropdown"
                />
              )}
            </Col>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default modalScrollBar(PDFListModal);
