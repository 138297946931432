import React, { Component } from "react";
import { Row, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getMessage } from "CONFIG/i18n";
import { validateUserName } from "UTILS/regexValidations";

import logo from "../../Assets/Images/img_signin_bg_logo.png";
import { KEY_CODE_ENTER } from "UTILS/Constants";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      errorMessage: this.props.errorMessage
    };
  }

  componentWillUnmount() {
    this.disableSubmitBtn(false);
  }

  componentDidUpdate(prevProps) {
    /*
      Operations Check mail sent or not and network message
    */
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }
  }

  handleInputChange = event => {
    this.setState({
      username: event.target.value
    });
  };

  enterPressed = event => {
    var code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleSendEmailClick();
    }
  };

  errorCallback = () => {
    this.disableSubmitBtn(false);
  }

  handleSendEmailClick = () => {
    if (this.isValidUsername(this.state.username)) {
      this.setState({ errorMessage: "" });
      let data = { email: this.state.username, language: this.props.language };

      this.disableSubmitBtn(true);
      this.props.handleSendEmailClick(data , this.errorCallback);
    }
    else
      this.disableSubmitBtn(false);
  };

  isValidUsername = username => {
    if (username === "") {
      this.setState({
        errorMessage: getMessage("INVALID_EMAIL")
      });
      return false;
    } else if (!validateUserName(username)) {
      this.setState({
        errorMessage: getMessage("INVALID_EMAIL")
      });
      return false;
    }
    return true;
  };

  emailField = () => {
    return (
      <>
        <Row>
          <Col md={12} className="text-center">
            <label className="title pt-4">
              {getMessage("FORGOT_PASSWORD_TITLE")}
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="userNameLabel text-left">
              {getMessage("EMAIL")}
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <InputGroup>
              <FormControl
                type="email"
                onChange={this.handleInputChange}
                onKeyPress={this.enterPressed}
                name="userName"
                className="userNameInput"
                value={this.state.username}
                autoFocus
                placeholder={getMessage("ENTER_EMAIL_PLACEHOLDER")}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row>
          <div className="col-12 pt-5">
            <Button
              id="submitBtn"
              className="customBtn btnSignIn w-100"
              onClick={() => {
                this.disableSubmitBtn(true);
                this.handleSendEmailClick();
              }}
            >
              {getMessage("SUBMIT")}
            </Button>
            {this.state.errorMessage &&
            <div className="error mx-0 col-12 d-flex">{this.state.errorMessage}</div> 
            }
          </div>
        </Row>
      </>
    );
  };

  emailSent = () => {
    return (
      <>
        <Row style={{ justifyContent: "center" }}>
          <Col md={10} xs={10} lg={10} xl={10} className="mailSentContainer m-4">
            <label>{getMessage("EMAIL_SENT")}</label>
          </Col>
        </Row>
        <Row className="gotoSignIn">
          <Col lg="12">
            <Button className="customBtn  w-100" onClick={this.props.returnSignIn}>
              {getMessage("RETURN_SIGNIN_MSG")}
            </Button>
          </Col>
        </Row>      
      </>
    );
  };

  disableSubmitBtn = (value) => {
    let submitBtn = document.getElementById("submitBtn");
    if(submitBtn){
      submitBtn.disabled = value;
      if(value)
        submitBtn.classList.add("pointerEventsNone");
      else
        submitBtn.classList.remove("pointerEventsNone");
    }
  }

  render() {
    return (
      <div className="container-fluid forgotPassword">
        <div className="d-flex align-self-center">
          <div className="col-12 col-sm-8 offset-sm-2 col-xl-4 offset-xl-4 card card-body">
            <div className="text-center">
              <img className="img-responsive SignInLogo" src={logo} />
            </div>
            {this.props.isMailSend ? this.emailSent() : this.emailField()}
          </div>
        </div>
      </div>
    );
  }
}
export default ResetPassword;
