import { ACTIONS, PARTICIPANT_UPDATE_REASON } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";

//never make this function as arrow function
export class HeaderAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  setHeaderIcons(headerIcons) {
    this.dispatch({
      type: ACTIONS.SET_HEADER_ICONS,
      headerIcons: headerIcons
    });
  }

  updateHeaderIconClass(moduleName, classname, textLabelClass) {
    this.dispatch({
      type: ACTIONS.UPDATE_HEADER_ICON_CLASSNAME,
      data: {
        moduleName: moduleName,
        classname: classname,
        textLabelClass: textLabelClass
      }
    });
  }

  clearWebRtcPermissionReplyStatus() {
    this.dispatch({
      type: ACTIONS.SET_WEBRTC_REPLY_PERMISSION_STATUS,
      webRtcPermissionReplyStatus: null
    });
  }
  clearLocationPermissionReplyStatus() {
    this.dispatch({
      type: ACTIONS.LOCATION.SET_LOCATION_REPLY_PERMISSION_STATUS,
      locationPermissionReplyStatus: null
    });
  }
  setIsSnapshotTaken(isSnapshotTaken) {
    this.dispatch({
      type: ACTIONS.SET_SNAPSHOT_TAKEN,
      isSnapshotTaken: isSnapshotTaken
    });
  }
  setLocationPopup = value => {
    this.dispatch({
      type: ACTIONS.LOCATION.START_LOCATION_SHARE,
      value: value,
      isLocationSharing: value
    });
  };
  setShowSnapshot = value => {
    this.dispatch({
      type: ACTIONS.SNAPSHOT.SHOW_SNAPSHOT,
      value: value
    });
  }
  resetSnapshotData() {
    this.dispatch({
      type: ACTIONS.RESET_SNAPSHOT_DATA
    });
  }

  setCallStatus = value =>{
    this.dispatch({
      type: ACTIONS.CALL_STATUS,
      value: value
    })
  }
  //use To display and hide audio/video block
  setCallModalPermission = value =>{
    this.dispatch({
      type: ACTIONS.CALL_MODAL_PERMISSION,
      value: value
    })
  }

  setSnapshotCameraPermissionflag = value =>{
    this.dispatch({
      type: ACTIONS.SNAPSHOT.SET_SNAPSHOT_CAMERA_PERMISSION,
      value: value
    })
  }

  setCameraBusyFlag = value => {
    this.dispatch({
      type: ACTIONS.SET_CAMERA_BUSY_FLAG,
      value: value
    });
  }

  setCallMediaType = value => {
    this.dispatch({
      type: ACTIONS.SET_CALL_MEDIA_TYPE,
      mediaType: value
    });
  }

  setParticipantAdmittedOrRejected = (participantAdmissionDetails) => {
    this.dispatch({ 
      type: ACTIONS.PARTICIPANT_ADMITTED_OR_REJECTED,
      participant: participantAdmissionDetails,
      updateReason: participantAdmissionDetails.isAdmitted
        ? PARTICIPANT_UPDATE_REASON.ADMITTED 
        : PARTICIPANT_UPDATE_REASON.REJECTED
    });
  }

  resetParticipantsPluginInfo = () => {
    this.dispatch({
      type: ACTIONS.RESET_PARTICIPANT_PLUGIN_INFO
    });
  }

  clearSnapshotPermissionReplyStatus() {
    this.dispatch({
      type: ACTIONS.SET_SNASHOT_PERMISSION_STATUS,
      snapshotPermissionStatus: {}
    });
  }

  resetMediaDeviceDeinedDetails() {
    this.dispatch({
      type: ACTIONS.RESET_MEDIA_DEVICE_DENIED_DETAILS,
    });
  }

}
export default HeaderAction;