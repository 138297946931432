import React, { Component } from "react";
import {Button, Row, Col , Input} from "reactstrap";

// UI Components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { getMessage } from "CONFIG/i18n";

import Pagination from "react-js-pagination";

// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

import ic_edit from "ASSETS/Images/qr_icons/ic_edit.png";
import ic_delete from "ASSETS/Images/ic_delete.svg";

import { userAction } from "CONFIG/ActionFactory";

import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import { PAGE_COUNT, ERROR, modulesAvailableConstant, USER_ROLES } from "UTILS/Constants";

import "./AdminDashboard.less";
import "./ReportComponent/SessionList.less";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
export default class UsersList extends Component {
  constructor(props) {
    super(props);
    this.tooltipBoundaryElement = null;
  }

  componentDidMount() {
    // timeout is added because it takes some time for the 
    // contents of the table to be loaded completely 
    setTimeout(() => {
      this.props.setHeaderPadding();
    },10)
    this.getTooltipBoundaryElement();
  }

  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("users-list ")[0];
  }

  fetchUsersTableData = (page) => {
    this.props.getAllUserData(
      page - 1,
      this.props.userListPageSize,
      this.props.searchKey ? this.props.searchKey : undefined
    );
    const tableData = document.querySelector(".usersTableContainer .react-bs-container-body");
    tableData.scrollTop = 0;
    this.props.setHeaderPadding();
  }

  handleChange = user => {
    let data = Object.assign(
      {},
      {
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        role: user.role,
        username: user.email,
        phone: user.phone,
        isAvailable: Number(!user.isAvailable) + ""
      }
    );
    this.props.handleSaveClick(data, error => {
      toastr.error(error.code);
    })
  }

  emailFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label className="mb-0 nameEllipsis custom-tooltip" id={`email${row.id}`}>
          {row.email}
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={row.email}
            tooltipId={`email${row.id}`}
          />
        </label>
      </div>
    );
  };

  firstNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label className="mb-0 nameEllipsis custom-tooltip" id={`firstName${row.id}`}>
          {row.first_name}
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={row.first_name}
            tooltipId={`firstName${row.id}`}
          />
        </label>
      </div>
    );
  };

  lastNameFormat = (cell, row) => {
    return (
      <div className="setMargin">
        <label
          className="mb-0 nameEllipsis custom-tooltip"
          id={`lastName${row.id}`}
        >
          {row.last_name == null ? "--" : row.last_name}
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={row.last_name}
            tooltipId={`lastName${row.id}`}
          />
        </label>
      </div>
    );
  };

  userFormat = (cell, row) => {
    return (
      <label className="mb-0">
        {getMessage(row.role)}
      </label>
    );
  };
 
  resetPasswordFormat = (cell, row) => {
    return (
      <Button
        className={row.is_sso_user ? "buttons resetPassword cursorDisabled" : "buttons resetPassword"}
        disabled={row.is_sso_user ? true : false}
        onClick={e => {
          e.stopPropagation();
          this.props.resetPasswordClick(row.id, row.role);
        }}
      >
        {getMessage("RESET_PASSWORD")}
      </Button>
    )
  }

  agentAvailableFormat = (cell, row) => {
    return (
      row.role !== USER_ROLES.ADMIN ? 
        (<Switch
          switchOn={row.isAvailable}
          disabled={row.is_sso_user}
          toggleSwitch={() =>{ if(!row.is_sso_user)this.handleChange(row)}}
        />)
        : null
    )
  }

  actionsFormat = (cell, row) => {
    return (
      <div className="d-flex">
        <>
          <img
            src={ic_edit}
            className={row.is_sso_user? "pl-4 pr-2 cursorDisabled" :"pl-4 pr-2 cursorPointer"}
            onClick={() => {if(!row.is_sso_user)this.props.showEditUserModal(row.id)}}
            id={`edit${row.id}`}
          />
          <CustomTooltip
            tooltipBoundariesElement={this.tooltipBoundaryElement}
            tooltipText={getMessage("EDIT")}
            tooltipId={`edit${row.id}`}
          />
        </>
       
        {row.id != this.props.loggedUserId &&
          <>
            <img
              id={`delete${row.id}`}
              src={ic_delete}
              className="cursorPointer px-2 delete-action-img"
              onClick={e => {
                e.stopPropagation();
                this.props.showDeleteConfirmationModal(
                  row.id,
                  row.role,
                  `${row.first_name} ${row.last_name}`
                );
              }}
            />
            <CustomTooltip
              tooltipBoundariesElement={this.tooltipBoundaryElement}
              tooltipText={getMessage("DELETE")}
              tooltipId={`delete${row.id}`}
            />
          </>
        }
      </div>
    )
  }
  
  renderUserList = (usersList) => {

    return (
      <div>
        {/* <Table responsive style={{width: "92%"}} className="organisationsList usersList">
          <thead className="tableHeader">
            <tr>
              <th style={{ width: "25%" }}>{getMessage("EMAIL")}</th>
              <th style={{ width: "15%" }}>{getMessage("FIRST_NAME")}</th>
              <th style={{ width: "15%" }}>{getMessage("LAST_NAME")}</th>
              <th style={{ width: "12%" }}>{getMessage("TYPE_OF_USER")}</th>
              <th style={{ width: "10%" }}>
                {getMessage("RESET_PASSWORD_TEXT")}
              </th>
              <th style={{ width: "15%", textAlign: "center" }}>{getMessage("AGENT_AVAILABILITY")}</th>
              <th style={{ width: "8%" }}>{getMessage("ACTIONS")}</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {_.map(this.props.users, (user, index) => {
              return (
                <tr key={index}>
                  <td maxLength="10" className="invitee nameEllipsis">
                    {user.email}
                  </td>
                  <td className="nameEllipsis">
                    {user.first_name}
                  </td>
                  <td>{user.last_name}</td>
                  <td>{_.capitalize(user.role)}</td>
                  <td>
                    <Button
                      className="buttons resetPassword"
                      onClick={e => {
                        e.stopPropagation();
                        this.props.resetPasswordClick(user.id, user.role);
                      }}
                    >
                      {getMessage("RESET_PASSWORD")}
                    </Button>
                  </td>
                  <td className="alignTextCenter">

                    {user.id != this.props.loggedUserId ? user.isAvailable ? (
                      <img
                        src={ic_switch_on}
                        onClick={() => this.handleChange(user)}
                        className="cursorPointer"
                      />
                    ) : (
                      <img
                        src={ic_switch_off}
                        onClick={() => this.handleChange(user)}
                        className="cursorPointer"
                      />
                    ) : null}
                  </td>
                  <td>
                    <img
                      src={ic_edit}
                      className="mr-3 cursorPointer"
                      onClick={() => this.props.showEditUserModal(user.id)}
                    />
                    {user.id != this.props.loggedUserId &&
                      <img
                        src={ic_delete}
                        className="cursorPointer"
                        onClick={e => {
                          e.stopPropagation();
                          this.props.showDeleteConfirmationModal(
                            user.id,
                            user.role
                          );
                        }}
                      />
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table> */}

        <BootstrapTable 
          data={usersList}
          tableHeaderClass="tableHeader"
          tableBodyClass='tableBody'
          containerClass='users-list custom-list'
          tableContainerClass=''
          headerContainerClass=''
          bodyContainerClass=''
          bordered={false}
          printable
          maxHeight='450px'
        >
          <TableHeaderColumn 
            dataField='email' 
            width="25"
            dataSort={true}
            isKey={true}
            dataFormat={this.emailFormat}
          >
            {getMessage("EMAIL")}
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='first_name' 
            dataSort={true}
            width="13"
            className="nameEllipsis"
            headerAlign="center"
            dataAlign="center"
            dataFormat={this.firstNameFormat}
          >
            {getMessage("FIRST_NAME")}
          </TableHeaderColumn>
          <TableHeaderColumn 
            dataField='last_name'
            width="13"
            dataSort={true}
            dataFormat={this.lastNameFormat}
            headerAlign="center"
            dataAlign="center"
          >
            {getMessage("LAST_NAME")}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="12"
            dataFormat={this.userFormat}
            headerAlign="center"
            dataAlign="center"
          >
            {getMessage("TYPE_OF_USER")}
          </TableHeaderColumn>
          <TableHeaderColumn
            width="15"
            dataFormat={this.resetPasswordFormat} 
          >
            {getMessage("RESET_PASSWORD_TEXT")}
          </TableHeaderColumn>
          {this.props.modules && this.props.modules.includes(modulesAvailableConstant.QR_CODE) &&
            <TableHeaderColumn
              width="12"
              dataFormat={this.agentAvailableFormat}
              headerAlign="center"
              dataAlign="center"
            >
              {getMessage("ENABLE_QR_CODE_NOTIFICATION")}
            </TableHeaderColumn>}
          {this.props.isSSOEnabled ?
            <TableHeaderColumn
              width="11"
              dataFormat={(cell, row) => {
                return (
                  <div className="setMargin">
                    <label className="mb-0 nameEllipsis">
                      { row.is_sso_user ? getMessage("YES") : getMessage("NO")}
                    </label>
                  </div>)
              }}
              headerAlign="center"
              dataAlign="center"
            >
              {getMessage("IS_SSO_USER")}
            </TableHeaderColumn>
            : null
          }
          <TableHeaderColumn
            width="10"
            dataFormat={this.actionsFormat}
            headerAlign="center"
            dataAlign="center"
          >
            {getMessage("ACTIONS")}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  };
  render() {
    const { userListCurrentPageNo, usersList, usersCount } = this.props;
    const displayPage = userListCurrentPageNo + 1;
    return (
      <div className="usersTableContainer container-fluid">
        {this.props.usersList && this.props.usersList.length > 0 ? (
          <React.Fragment>
            <div className="overflow-auto">
              {this.renderUserList(usersList)}
            </div>
            <div className="pagination-margin px-0 pt-3">
              <Row className="paginationRow"> 
                <Col xs={4} className="paginationEntries">
                  {getMessage("PAGINATION_TOTAL_ENTRIES",
                    {from:userListCurrentPageNo * this.props.userListPageSize + 1 ,
                      to: userListCurrentPageNo * this.props.userListPageSize + this.props.userListPageSize < usersCount ? userListCurrentPageNo * this.props.userListPageSize + this.props.userListPageSize : usersCount,
                      totalCount: usersCount})}
                </Col>
                <Col xs={4} className="pagination">
                  {usersCount > this.props.userListPageSize ?
                    <Pagination
                      activePage={displayPage}
                      itemsCountPerPage={this.props.userListPageSize}
                      totalItemsCount={usersCount}
                      pageRangeDisplayed={3}
                      onChange={this.fetchUsersTableData}
                      itemClass="page-item"
                      linkClass="page-link"
                    /> : null
                  }
                </Col>
                <Col xs={4} className="pageCount">
                  {usersCount > PAGE_COUNT[0].value &&
                    <CustomDropdown
                      options={PAGE_COUNT}
                      onChange={(value)=>{
                        this.props.getAllUserData(0, value, this.props.searchKey ? this.props.searchKey : undefined);
                      }}
                      value={this.props.userListPageSize}
                      dropdownClass="pageSizeDropdown"
                    />
                  }
                </Col>
              </Row>
            </div>
          </React.Fragment>
        ) : (
          <div className="margin-bottom">
            <NoData minHeight={"60vh"}
              message={this.props.searchKey ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                searchKey: this.props.searchKey,
              }):getMessage("NO_USERS")} /> 
          </div>
        )}
      </div>
    );
  }
}
