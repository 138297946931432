import React from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Collapse,
  Card,
  Input,
  Label,
  Table
} from "reactstrap";

//Images
import ic_arrow_down from "ASSETS/Images/ic_arrow_down.svg";
import ic_edit from "ASSETS/Images/ic_edit.svg";
import ic_add from "ASSETS/Images/ic_add_storage.svg";
import icDelete from "ASSETS/Images/ic_delete_img.svg";
import Switch from "COMPONENTS/CommonComponents/Switch/Switch";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import { getMessage } from "CONFIG/i18n";
import { checkEmpty } from "UTILS/Utility";
import "./SettingsPage.less"

class CobrowseSettingsSection extends React.Component { 
    renderPreconfiguredURLTableBody = () => {
      return (
        <tbody>
          {this.props.targetSites.map((url,index) =>              
            <tr  key={url.url + index} id={url.url + index}>
              <td className="cobrowseRow col-4">
                {url.label}
              </td>
              <td className="cobrowseRow col-5">
                {url.url}
              </td>
              <td className="cobrowseRow col-3 d-flex">
                <>
                  <img
                    src={icDelete}
                    className={(this.props.showUpdateTargetSiteSection && url.label == this.props.targetSiteLabelToEdit)
                      ? "isDisabled cursorDisabled px-2"
                      : "cursorPointer px-2"}
                    onClick={e => { this.props.handleDeleteUrlClick(url.label) }}
                    id={`delete${index}`}
                  />
                  <CustomTooltip
                    tooltipText={getMessage("DELETE")}
                    tooltipId={`delete${index}`}
                  />
                </>
              
                <>
                  <img src={ic_edit} alt="Edit" className="cursorPointer px-2" id={`edit${index}`}
                    onClick={() => {this.props.handleUpdateTargetSiteClick(index, url)}}
                  />
                  <CustomTooltip
                    tooltipText={getMessage("EDIT")}
                    tooltipId={`edit${index}`}
                  />
                </>
                
              </td>
            </tr>
          )}
        </tbody>
      );
    };

    render() {
      return (
        <div className="section">
          <Row className="noMargin">
            <Col xs={10} className="mainTitle">
              {getMessage("CO_BROWSE")}
            </Col>
            <Col>
              <img
                style={{ transform: `rotate(${this.props.cobrowseRotation}deg)` }}
                className="dropDown cursorPointer floatRight"
                src={ic_arrow_down}
                onClick={() => this.props.handleCobrowseArrowClick()} />
            </Col> 
          </Row>
          <Collapse isOpen={this.props.isCobrowseToggler}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={5} xs={7}>
                    {getMessage("ALLOW_CUSTOM_URL")}
                  </Col>
                  <div
                    className={(this.props.targetSites.length===0) ? "isDisabled cursorDisabled col-md-1 col" : "col-md-1 col"}
                  >
                    <Switch
                      switchOn={this.props.allowCustomUrl}
                      toggleSwitch={ () => {this.props.setAllowCustomUrl()}}
                    />
                  </div>
                  {this.props.allowCustomUrl &&
                      <Col md={6} sm={12} className="d-flex flex-column">
                        <Input
                          type="text"
                          name="customUrl"
                          className="inputFormControl"
                          minLength={10}
                          maxLength={30}
                          required
                          placeholder={getMessage("CUSTOM_URL_LABEL_PLACEHOLDER")}
                          onChange={this.props.handleCustomUrlInputChange}
                          value={this.props.customUrlLabels[0] && this.props.customUrlLabels[0].label}
                        />
                        {this.props.customUrlLabelError &&
                        <Label className="errors autoHeight mt-1"> {this.props.customUrlLabelError} </Label>}
                      </Col>
                  }
                </Row>
              </CardBody>
            </Card>

            <Row className="m-1"> </Row>

            <Card className="cobrowseCard">
              <CardBody>
                <Row>
                  <Col xs={11} className="mainTitle px-2">
                    {getMessage("PRECONFIGURED_URL")}
                  </Col>
                  {!this.props.showAddTargetSiteSection && !this.props.showUpdateTargetSiteSection &&
                    <Col xs={1} className="justify-content-center">
                      <img src={ic_add}
                        className={(this.props.targetSites.length >= 5) ? "isDisabled cursorDisabled" : "cursorPointer"}
                        onClick={() => { this.props.handleAddTargetSiteIconClick() }}
                        id='addUrl' />
                      <CustomTooltip
                        tooltipText={getMessage("ADD_URL")}
                        tooltipId='addUrl'
                      />
                    </Col>}
                </Row> 
                <Row className="cobrowseCard">
                  <CardBody className="py-0 px-0">
                    <div className="interCardContent">
                      <Table bordered size="sm" className="storageTableWidth">
                        <thead>
                          <tr className="storageHead">
                            <th className="cobrowseRow border-bottom col-4">{getMessage("URL_LABEL")}</th>
                            <th className="cobrowseRow border-bottom col-5">{getMessage("URL")}</th>
                            <th className="cobrowseRow border-bottom col-3">{getMessage("ACTION")}</th>
                          </tr>
                        </thead>
                        {this.renderPreconfiguredURLTableBody()}
                      </Table>
                     
                    </div>
                  </CardBody>
                </Row>

                {(this.props.showAddTargetSiteSection || this.props.showUpdateTargetSiteSection) &&
                   <Card>
                     <CardBody>
                       <Row>
                         <Col>
                           <Button close aria-label="Cancel" onClick={() => {this.props.onCancelCobrowseClick()}}>
                           </Button>
                         </Col>
                       </Row>
                       <Row className="noMargin mt-2">
                         <Col xs={4} md={3} className="pr-0"> {getMessage("URL_LABEL")}:
                         </Col>
                         <Col xs={8} md={9} className="pl-0">
                           <Input
                             type="text"
                             name="targetSiteLabel"
                             className="inputFormControl"
                             required
                             placeholder={getMessage("URL_LABEL_PLACEHOLDER")}
                             onChange={this.props.handleCoBrowseInputChange}
                             value={this.props.targetSiteLabel}
                             minLength={10}
                             maxLength={30}
                           />
                         </Col>
                       </Row>
                       <Row className="noMargin mt-2">
                         <Col xs={4} md={3} className="pr-0"> {getMessage("URL")}:
                         </Col>
                         <Col xs={8} md={9} className="d-flex pl-0">
                           <Input
                             type="text"
                             name="targetSiteValue"
                             className="inputFormControl"
                             required
                             placeholder={getMessage("URL_VALUE_PLACEHOLDER")}
                             onChange={this.props.handleCoBrowseInputChange}
                             value={this.props.targetSiteValue}
                             maxLength={500}
                           />
                         </Col>
                       </Row>
                       {this.props.cobrowseURLError !== "" ?
                         <Row>
                           <Col md={12} className="errors autoHeight">
                             {getMessage(this.props.cobrowseURLError)}
                           </Col>
                         </Row>
                         : null
                       }
                       <Row className="interCardContent floatRight mx-2">
                         <Button
                           className={
                             (this.props.targetSiteValue && !checkEmpty(this.props.targetSiteLabel.trim()) && (this.props.showUpdateTargetSiteSection || this.props.showAddTargetSiteSection))
                               ? "borderBtn cursorPointer bgwhite px-4 mx-1"
                               : "borderBtn cursorPointer bgwhite px-4 mx-1 isDisabled"
                           }
                           
                           onClick={this.props.handleAddTargetSiteClick}
                         >
                           {this.props.showUpdateTargetSiteSection ? getMessage("UPDATE") : getMessage("ADD")}
                         </Button>
                       </Row>
                     </CardBody>
                   </Card>}
              </CardBody>
            </Card>
              
          </Collapse>
        </div>
      )
    }
}
export default CobrowseSettingsSection;
