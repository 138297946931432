import React from "react";
import { isSafari } from "react-device-detect";

import { mediaType } from "WEBSOCKET/constants";
import { Modules } from "UTILS/Constants";

export default class BitRateTile extends React.Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.intervalList = [];
    this.repeatInterval = 2000; // 2000 ms == 2 seconds
    this.state = {
      bitRate: 0
    }
    this.bitrate = {}
  }

  componentDidMount() {
    this.getBitrate();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.mediaType !== this.props.mediaType) {
      this.intervalList.forEach(interval => clearInterval(interval))
      this.getBitrate();
    }
  }

  getBitrate = () => {
    this.bitrate = {}
    this.interval = setInterval(() => {
      if(((this.props.module === Modules.SCREENSHARE || this.props.module === Modules.SNAPSHOT) &&
        this.props.wsocketPeer) ||
        ((this.props.module !== Modules.SCREENSHARE && this.props.module !== Modules.SNAPSHOT) &&
        this.props.wsocketPeer[this.props.participantUniqueID] &&
        this.props.wsocketPeer[this.props.participantUniqueID][this.props.mediaType])) {
        let peerConnection = this.props.module === Modules.SCREENSHARE || this.props.module === Modules.SNAPSHOT
          ? this.props.wsocketPeer.peerConnection
          : this.props.wsocketPeer[this.props.participantUniqueID][this.props.mediaType].peerConnection;
        peerConnection &&
        peerConnection.getStats()
        .then((stats) => {
          stats.forEach((res) => {
            if(!res)
              return;
            var inStats = false;
            // By default calculate bit that are being received 
            let resType = "inbound-rtp"
            // If participantUniqueID and uniqueId match that means we should calculate sent bit rate
            if(this.props.participantUniqueID == this.props.uniqueId) {
              resType = "outbound-rtp"
            }
            // Check if these are statistics on incoming media
            if((res.mediaType === this.props.mediaType.toLowerCase() || 
                res.id.toLowerCase().indexOf(this.props.mediaType.toLowerCase()) > -1) &&
                res.type === resType && res.id.indexOf("rtcp") < 0) {
              // New stats
              inStats = true;
            } else if(res.type == 'ssrc' && res.bytesReceived &&
                (res.googCodecName === "VP8" || res.googCodecName === "")) {
              // Older Chromer versions
              inStats = true;
            }
            // Parse stats now
            if(inStats) {
              let resBytes = this.props.participantUniqueID == this.props.uniqueId
                ? res.bytesSent
                : res.bytesReceived
              this.bitrate.bsnow = resBytes;
              this.bitrate.tsnow = res.timestamp;
              if(this.bitrate.bsbefore === null || this.bitrate.tsbefore === null) {
                // Skip this round
                this.bitrate.bsbefore = this.bitrate.bsnow;
                this.bitrate.tsbefore = this.bitrate.tsnow;
              } else {
                if (this.bitrate.bsnow && this.bitrate.bsbefore) {
                  // Calculate bitrate
                  var timePassed = this.bitrate.tsnow - this.bitrate.tsbefore;
                  if (isSafari)
                    timePassed = timePassed / 1000;	// Apparently the timestamp is in microseconds, in Safari
                  var bitRate = Math.round((this.bitrate.bsnow - this.bitrate.bsbefore) * 8 / timePassed);
                  if (isSafari)
                    bitRate = parseInt(bitRate / 1000);
                  this.bitrate.value = bitRate + ' kbits/sec';
                  this.setState({
                    bitRate: bitRate
                  })
                }
                this.bitrate.bsbefore = this.bitrate.bsnow;
                this.bitrate.tsbefore = this.bitrate.tsnow;
              }
            }
          });
        });
      }
    }, this.repeatInterval);
    this.intervalList.push(this.interval);
  }

  render() {
    return (
      <div 
        className = { this.props.hide
          ? "bitrate displayNone"
          : "bitrate"}
        id = {this.props.participantUniqueID+'bitrate'}
      >
        {(this.state.bitRate || 0) + ' kbps'}
      </div>
    )
  }

  componentWillUnmount() {
    this.intervalList.forEach(interval => clearInterval(interval))
  }
}