import _ from "lodash";
import { ACTIONS, CO_BROWSE_SESSION_STATUS } from "UTILS/Constants";

const initialState = {
  coBrowsePermissionRequest: false,
  coBrowsePermisssionReply: false,
  participantLeftUniqueId: "",
  participantJoinedUniqueId: "",
  participantDeniedPermissionUniqueId: "",
  showCoBrowse: false,                // set true to open co-browse window
  targetSiteUrl: "",                 // url to dispaly in input field
  iframeUrl: "",                    // url to load in iframe
  coBrowseSessionStatus: CO_BROWSE_SESSION_STATUS.CO_BROWSE_NOT_RUNNING //co-browse status 
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CO_BROWSE.CO_BROWSE_REQUEST:
      return { 
        ...state, 
        coBrowsePermissionRequest: action.value, 
        coBrowsePermisssionReply: false
      };
    
    case ACTIONS.CO_BROWSE._CO_BROWSE_REPLY:
      return { ...state, coBrowsePermisssionReply: action.value };
    
    case ACTIONS.CO_BROWSE.SET_IFRAME_URL:
      return { 
        ...state, 
        iframeUrl: action.value.cobrowseUrl, 
        targetSiteUrl: action.value.targetSiteUrl, 
        cobrowseSessionId: action.value.sessionId,
        showCoBrowse: true 
      };
    
    case ACTIONS.CO_BROWSE.CO_BROWSE_REPLY:
      return { ...state, coBrowsePermisssionReply: action.value };
    
    case ACTIONS.CO_BROWSE.CO_BROWSE_LEAVE_SESSION:
      return { ...state, participantLeftUniqueId: action.value };
    
    case ACTIONS.CO_BROWSE.CO_BROWSE_JOIN_SESSION:
      return { ...state, participantJoinedUniqueId: action.value };

    case ACTIONS.CO_BROWSE.CO_BROWSE_PERMISSION_DENIDED:
      return { ...state, participantDeniedPermissionUniqueId: action.value };

    case ACTIONS.CO_BROWSE.SET_CO_BROWSE_SESSION_STATUS:
      return { ...state, coBrowseSessionStatus: action.value };

    case ACTIONS.CO_BROWSE.CO_BROWSE_TOGGLE:
      return { ...state, showCoBrowse: action.value}

    case ACTIONS.CO_BROWSE.SET_TARGET_URL:
      return{ ...state, targetSiteUrl: action.value}

    case ACTIONS.CO_BROWSE.RESET_CO_BROWSE_SESSION_DETAILS:
    
    case ACTIONS.LEAVE_SESSION_LOCAL:
    case ACTIONS.SET_END_SESSION_FLAG:
      return initialState 
    
    default:
      return state;
  }
}
