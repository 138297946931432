
export const getQRCodesCount = state => {
  return state.QRCodesReducer.qrCodesCount;
};

export const getQRCodesListCurrentPageNo = state => {
  return state.QRCodesReducer.qrCodesListCurrentPageNo;
}
  
export const getQRCodesListPageSize = state => {
  return state.QRCodesReducer.qrCodesListPageSize;
};

export const getOrganisationQRCodesLimit = state => {
  return state.OrganisationReducer.customerData.qr_limit;
};

export const getOrganisationTotalQRCodesCount = state => {
  return state.QRCodesReducer.totalQRCodes;
};

export const getQRCodeAgentsPageNo  = (state) => {
  return state.QRCodeAgentsReducer.qrCodeAgentsPageNo;
};

export const getQRCodeAgentsPageSize = (state) => {
  return state.QRCodeAgentsReducer.qrCodeAgentsPageSize;
};

export const getUnassignedAgentsPageNo = (state) => {
  return state.QRCodeAgentsReducer.unassignedAgentsPageNo;
};

export const getUnassignedAgentsPageSize = (state) => {
  return state.QRCodeAgentsReducer.unassignedAgentsPageSize;
};

export const getUnassignedAgentsCount = (state) => {
  return state.QRCodeAgentsReducer.unassignedAgentsCount;
};

export const getQRCodeAgentsCount = (state) => {
  return state.QRCodeAgentsReducer.qrCodeAgentsCount;
};

export const getQRCodeData = (state) => {
  return state.QRCodesReducer.qrCodeData;
};