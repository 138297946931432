import _ from "lodash";
import { ACTIONS } from "UTILS/Constants";
const initialState = {
  eta: {
    fromUniqueId: "",
    toUniqueId: "",
    fromParticipantData: { latitude: "", longitude: "" },
    toParticipantData: { latitude: "", longitude: "" }
  },
  participants: {},
  locationPermissionReply: null,
  locationPermissionReplyStatus: null,
  locationWindowClosed:{locationWindowClosed:false,participantId:"", isDownloaded:false, isLocationPermissionGranted:true},
  closeLocationWindow: false,
  closedPaticipantCount:1
};
export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.LOCATION.GET_ALL_LOCATION_DATA:
      let etaData;
      if (action.eta.hasOwnProperty("fromUniqueId")) {
        etaData = action.eta;
      } else {
        etaData = initialState.eta;
      }
      return {
        ...state,
        participants: action.participants,
        eta: etaData
      };
      break;
    case ACTIONS.LOCATION.ADD_LOCATION_PARTICIPANT_ID:
      let participants = _.cloneDeep(state.participants);
      if (!participants.hasOwnProperty(action.locationParticipantId)) {
        participants[action.locationParticipantId] = {};
        return {
          ...state,
          participants: participants
        };
      } else return state;
      //}
      break;
    case ACTIONS.LOCATION.SET_LOCATION_PERMISSION_REPLY:
      return {
        ...state,
        locationPermissionReply: action.isGranted
      };
    case ACTIONS.LOCATION.LOCATION_SHARING_WINDOW_CLOSED:
      return {
        ...state,
        locationWindowClosed: action.locationWindowClosed
      };
    case ACTIONS.LOCATION.SET_LOCATION_REPLY_PERMISSION_STATUS:
      return {
        ...state,
        locationPermissionReplyStatus: action.locationPermissionReplyStatus
      };
      break;
    case ACTIONS.LOCATION.ADD_LOCATION_DATA:
      participants = _.cloneDeep(state.participants);
      if (!participants.hasOwnProperty(action.participantId)) {
        participants[action.participantId] = {};
        participants[action.participantId] = action.participants;
        return {
          ...state,
          participants: participants
        };
      } else {
        participants[action.participantId] = action.participants;
        return {
          ...state,
          participants: participants
        };
      }
      break;
    case ACTIONS.LOCATION.STOP_ETA_SHARE:
      return {
        ...state,
        eta: initialState.eta
      };
      break;
    case ACTIONS.LOCATION.STOP_LOCATION_SHARE:
      participants = _.cloneDeep(state.participants);

      if (action.hasOwnProperty("participant") && action.participant) {
        delete participants[action.participant];
        const closedPaticipantCount= state.closedPaticipantCount + 1;
        if (
          state.eta.fromUniqueId === action.participant ||
          state.eta.toUniqueId === action.participant
        ) {
          return {
            ...state,
            participants: participants,
            eta: initialState.eta,
            closeLocationWindow: closedPaticipantCount===action.roomParticipantsCount,
            closedPaticipantCount:closedPaticipantCount
          };
        } else {
          return {
            ...state,
            participants: participants,
            closeLocationWindow: closedPaticipantCount===action.roomParticipantsCount,
            closedPaticipantCount:closedPaticipantCount
          };
        }
      } else
        return {
          ...state,
          eta: initialState.eta,
          participants: initialState.participants,
          locationPermissionReply: initialState.locationPermissionReply,
          locationWindowClosed: initialState.locationWindowClosed,
          locationPermissionReplyStatus:
            initialState.locationPermissionReplyStatus,
          closedPaticipantCount:initialState.closedPaticipantCount
        };
      break;
    case ACTIONS.LOCATION.SHARE_ETA:
      etaData = {
        fromUniqueId: action.fromUniqueId,
        toUniqueId: action.toUniqueId,
        fromParticipantData: action.fromParticipantData,
        toParticipantData: action.toParticipantData
      };
      return {
        ...state,
        eta: etaData
      };
      break;
    case ACTIONS.LEAVE_SESSION_LOCAL:
    case ACTIONS.LOCATION.CLEAR_LOCATION:
      return initialState;
    default:
      return state;
  }
}
