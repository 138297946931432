import React, {useRef} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import GenerateAndDownloadQRCode from "COMPONENTS/CommonComponents/GenerateAndDownloadQRCode/GenerateAndDownloadQRCode";
import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";

import { getMessage } from "CONFIG/i18n";

import { getQRCodeUrl, getStringWithoutExtraSpaces } from "UTILS/Utility";

import icCopyQRCodeLink from "ASSETS/Images/ic_copy_link.svg";
import icDownloadQRCode from "ASSETS/Images/ic_download.svg";
import icAddQRCodeSuccess from "ASSETS/Images/ic_session_scheduled_popup.svg";

import './AddQRCodeSuccessModal.less'
import { REGEX } from "UTILS/Constants";

const AddQRCodeSuccessModal = (props) => {
  const generateAndDownloadQRCodeRef = useRef();

  const renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons customBtn"
          onClick={() => props.onClosed()}
        >
          {getMessage("CLOSE")}
        </Button>
      </div>
    );
  };

  const downloadQRCode=()=>{
    if(generateAndDownloadQRCodeRef.current) {
      generateAndDownloadQRCodeRef.current?.downloadQRCode()
    } else {
      console.error("reference is not defined");
    }
  }

  const qrUrl = getQRCodeUrl(props.qrCodeDetails?.qrId)

  return (
    <Modal
      isOpen={props.show}
      className="addOrganisationModal userAddModal add-qr-success"
      onClosed={props.onClosed}
    >
      <ModalHeader className="popupHeader">
        {getMessage("ADD_QR_CODE")}
      </ModalHeader>
      <ModalBody>
        <div className="pb-2">
          <img className="success-image" src={icAddQRCodeSuccess} />
        </div>
        <Label>
          {getMessage("QR_CODE_GENERATED_SUCCESSFULLY", { qrLabel: props.qrCodeDetails.label })}
        </Label>
        <br />
        <p>
          {getMessage("PLEASE_USE_BELOW_QR_CODE_AND_LINK")}
        </p>
        <div className="qr-code-details-box">
          <GenerateAndDownloadQRCode
            ref={generateAndDownloadQRCodeRef}
            downloadQRCode={true}
            fileName={`QRCode-${getStringWithoutExtraSpaces(props.qrCodeDetails?.label).replace(REGEX.SPECIAL_CHARACTERS, '')}-${props.qrCodeDetails.qrId}`}
            value={getQRCodeUrl(props.qrCodeDetails.qrId)}
            id={"addQRCodeSuccess" + props.qrCodeDetails.qrId}
          />
          <a id={"qrCodeDownload" + props.qrCodeDetails.qrId} />
          <div className="qr-link-box">
            {getMessage("LINK")} :{" "}
            <a href={qrUrl} target="_blank">
              {qrUrl}
            </a>
          </div>
          <div className="qr-footer">
            <div>
              <CopyToClipboard
                text={qrUrl}
                onCopy={(text, result) => {
                  if (result)
                    toastr.info("QR_CODE_LINK_COPIED");
                  else
                    toastr.error("QR_CODE_LINK_NOT_COPIED");
                }}
              >
                <div className="cursorPointer">
                  <img
                    src={icCopyQRCodeLink}
                    alt="Copy"
                  />
                  <br />
                  <span>{getMessage("COPY_LINK")}</span>
                </div>
              </CopyToClipboard>
            </div>
            <div>
              <img
                src={icDownloadQRCode}
                alt="download"
                onClick={() => downloadQRCode()}
                className="cursorPointer"
              />
              <br />
              <span>{getMessage("DOWNLOAD")}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="permissionButtons">
        {renderButtons()}
      </ModalFooter>
    </Modal>
  );
}

export default modalScrollBar(AddQRCodeSuccessModal);