import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from "reactstrap";
import DateTime from 'react-datetime'
import moment from 'moment';

import Checkbox from "COMPONENTS/CommonComponents/Checkbox/Checkbox";

import { getMessage } from "CONFIG/i18n";

//constants
import { REGEX, DATE_FORMAT, TIME_FORMAT, KEY_CODE_ENTER } from "UTILS/Constants";
import modalScrollBar from "../../ModalScrollBar/ModalScrollBar";

import "./AddAnnouncementModal.less";
import "react-datetime/css/react-datetime.css";

class AddAnnouncementMOdal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activateFrom: "",
      activateTill: "",
      announcementData: _.isEmpty(this.props.announcementData)
        ? {
          isActive: false
        }
        : _.cloneDeep(this.props.announcementData),
      isDataEdited:false,
      errorCode: null
    }
  }

  componentWillUnmount() {
    this.disableAddBtn(false);
  }

  componentDidMount() {
    this.setInputDate();
  }

  setFocus = () => {
    document.getElementById("title").focus();
  };

  resetState = () => {
    this.setState({
      announcementData: _.isEmpty(this.props.announcementData)
        ? {
          isActive: false
        }
        : _.cloneDeep(this.props.announcementData),
      isDataEdited:false
    })
  }

  handleCancelClick = () => {
    this.resetState();
    this.props.toggleAddAnnouncementModal(false);
  }

  validateEntries = () => {
    if(!this.state.announcementData.title || !this.state.announcementData.title.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_TITLE") });
      return false;
    }
    if(!this.state.announcementData.message || !this.state.announcementData.message.trim()) {
      this.setState({ errorMessage: getMessage("INVALID_MESSAGE") });
      return false;
    }
    if(!this.state.announcementData.activateFrom) {
      this.setState({ errorMessage: getMessage("INVALID_ACTIVATE_FROM") });
      return false;
    }
    if(!this.state.announcementData.activateTill) {
      this.setState({ errorMessage: getMessage("INVALID_ACTIVATE_TILL") });
      return false;
    }
    // While creating an announcement, activateFrom should be greater than current time
    if (!this.props.announcementData && moment(this.state.announcementData.activateFrom).isSameOrBefore()) {
      this.setState({ errorMessage: getMessage("INVALID_ACTIVATE_FROM_DATE_VALUE") });
      return false;
    }
    // While editing an announcement, activateTill should be greater than the current time.
    // activateFrom is not validated in case of Edit,
    // because the Superadmin could want to only extend the announcement by increasing the 'To time', keeping the same 'From time'.
    if (this.props.announcementData && moment(this.state.announcementData.activateTill).isSameOrBefore()) {
      this.setState({ errorMessage: getMessage("INVALID_ACTIVATE_TILL_DATE_VALUE") });
      return false;
    }
    if(moment(this.state.announcementData.activateTill).diff(moment(this.state.announcementData.activateFrom),'minutes') <= 0) {
      this.setState({ errorMessage: getMessage("ACTIVATE_TILL_NOT_GREATER_THAN_ACTIVATE_FROM") });
      return false;
    }
    return true
  }

  handleAddClick = () => {
    if(!this.validateEntries()) {
      this.disableAddBtn(false);
      return;
    }

    if(this.state.isDataEdited)
    {
      this.props.addUpdateAnnouncement(this.state.announcementData, (error) => {
        if(error) {
          console.error(error);
          this.setState({
            errorMessage: getMessage(error.code),
            errorCode: error.code
          })
          this.disableAddBtn(false)
        } else {
          this.props.toggleAddAnnouncementModal(false);
          this.resetState();
        }
      })
    }
    else{
      this.handleCancelClick();
    }
    this.disableAddBtn(true);
  }

  renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons borderBtn bgwhite"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>
        <Button
          id="addAnnouncementBtn"  
          onClick={() => {
            this.disableAddBtn(true);
            this.handleAddClick();
          }
          }
          className="buttons customBtn"
        >
          {this.props.saveBtnText}
        </Button>
      </div>
    );
  };

  handleInputChange = event => {
    this.setState({
      announcementData: {
        ...this.state.announcementData,
        [event.target.name]: [event.target.name]=="isActive"
          ?event.target.checked
          :event.target.value
      },
      isDataEdited:true
    });
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }
  };

  onChange = (value, name) => {
    if(!moment(value).isValid())
    {
      (name==="activateFrom") ?
        this.setState({errorMessage:"INVALID_ACTIVATE_FROM_DATE"}) :
        this.setState({errorMessage:"INVALID_ACTIVATE_TILL_DATE"})
    }else {
      this.setState({errorMessage:""})
      !this.state.announcementData.activateTill && this.refs.tilldate.setViewDate( value )
    }
    let announcementData = this.state.announcementData;
    announcementData[name] =  moment(value).seconds(0).milliseconds(0)
    this.setState({
      announcementData: announcementData,
      isDataEdited:true
    },()=>{this.setInputDate()})
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }
    
  }

  disableAddBtn = (value) => {
    let addBtn = document.getElementById("addAnnouncementBtn");
    if(addBtn){
      addBtn.disabled = value;
      if(value)
        addBtn.classList.add("pointerEventsNone");
      else
        addBtn.classList.remove("pointerEventsNone");
    }
  }

  minTillDate =(date)=> {
    let activateFrom= this.state.announcementData.activateFrom;
    if(activateFrom){
      return date.isAfter(activateFrom) || date.isSame(activateFrom,'day')
    } else {
      return date.isAfter(moment()) || date.isSame(moment(),'day')
    }
  }

  handleChangeDate =(event)=> {
    this.setState({[event.target.name]: event.target.value})
    if(!event.target.value.includes('_') && event.target.value){
      this.onChange(moment(event.target.value), event.target.name)
    }
  }

  setInputDate =()=> {
    this.setState({
      activateFrom: this.state.announcementData.activateFrom ? 
        moment(this.state.announcementData.activateFrom).format("MM/DD/yyyy hh:mm A") :
        "",
      activateTill: this.state.announcementData.activateTill ?
        moment(this.state.announcementData.activateTill).format("MM/DD/yyyy hh:mm A") :
        ""
    })
  }

  isPastDate = (inputDate) => {
    return (moment(inputDate).diff(moment(),'minutes') <= 0);
  }

  enterPressed = (event) => {
    //check if user pressed enter key
    const code = event.keyCode || event.which;
    if (code === KEY_CODE_ENTER) {
      this.handleAddClick();
    }
  }

  render() {
    const isAnnouncementActive = this.props.announcementData
    && new Date(this.props.announcementData.activateFrom) <= new Date()
    && new Date(this.props.announcementData.activateTill) > new Date();
    const dateFormatInputProps = {
      placeholder: "YYYY/MM/DD hh:mm AM",
      readOnly: true,
    };
    return (
      <Modal
        isOpen={this.props.show}
        backdrop="static"
        className="addAnnouncementModal"
        toggle={this.props.handleCancelClick}
        onOpened={this.setFocus}
      >
        <ModalHeader className="popupHeader">
          {this.props.headerText}
        </ModalHeader>
        <ModalBody className="pb-0">
          <Form>
            <FormGroup className="displayFlex alignItemsCenter">
              <Label className="label">
                {getMessage("TITLE")}
              </Label>
              <Input
                type="text"
                name="title"
                id="title"
                className="inputFormControl"
                value={this.state.announcementData.title}
                onChange={this.handleInputChange}
                maxLength="100"
                placeholder={getMessage("ANNOUNCEMENT_TITLE_PLACEHOLDER")}
                onKeyDown={(e) => this.enterPressed(e)}
              />
            </FormGroup>
            <FormGroup className="displayFlex alignItemsCenter">
              <Label className="label">{getMessage("MESSAGE")}</Label>
              <Input
                type="textarea"
                rows={4}
                // type="text"
                name="message"
                id="message"
                onChange={this.handleInputChange}
                className="inputFormControl"
                value={this.state.announcementData.message}
                maxLength="500"
                placeholder={getMessage("ANNOUNCEMENT_MSG_PLACEHOLDER")}
              />
            </FormGroup>
            <FormGroup className="displayFlex alignItemsCenter">
              <Label className="label">{getMessage("ACTIVATE_FROM")}</Label>
              <div className="datePicker">

                <DateTime 
                  className="w-100" 
                  id="activateFrom"  
                  inputProps={dateFormatInputProps}
                  onChange={(value) => this.onChange(value, "activateFrom")}
                  value={this.state.announcementData && 
                    this.state.announcementData.activateFrom && 
                    moment(this.state.announcementData.activateFrom)}
                  initialViewDate={this.state.announcementData &&
                    this.state.announcementData.activateFrom ?
                    moment(this.state.announcementData.activateFrom):
                    moment()}
                  closeOnSelect
                  isValidDate={(date)=>{return date.isAfter(moment()) || date.isSame(moment(),'day')}}
                  onClose={()=>this.setInputDate()}
                />

                <Label className="label dateToLabel px-5">
                  &nbsp;{getMessage("TO")}&nbsp;
                </Label>
               
                <DateTime
                  className="w-100" 
                  inputProps={dateFormatInputProps}
                  onChange={(value) => this.onChange(value, "activateTill")}
                  value={this.state.announcementData && 
                    this.state.announcementData.activateTill &&
                    moment(this.state.announcementData.activateTill)}
                  ref="tilldate"
                  initialViewDate={this.state.announcementData &&
                    this.state.announcementData.activateTill ?
                    moment(this.state.announcementData.activateTill):
                    moment(this.state.announcementData.activateFrom)}
                  closeOnSelect
                  isValidDate={this.minTillDate}
                  onClose={()=>this.setInputDate()}
                />
              </div>
              
            </FormGroup>
            <FormGroup className="displayFlex alignItemsCenter mb-0">
              <Label className="label d-flex align-items-center w-100">{getMessage("IS_ACTIVE")}
                <Checkbox
                  name='isActive'
                  checked={this.state.announcementData.isActive}
                  onChange={this.handleInputChange}
                />
              </Label>
            </FormGroup>
            <div className="errors">{getMessage(this.state.errorMessage)}</div>
          </Form>
        </ModalBody>
        <ModalFooter className="permissionButtons">
          {this.renderButtons()}
        </ModalFooter>
      </Modal>
    )
  }
}
export default modalScrollBar(AddAnnouncementMOdal); 
