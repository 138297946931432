import { socketMessage } from "WEBSOCKET/constants";
import { CANVAS_TYPES, USER_ROLES, ACTIONS, ERROR } from "UTILS/Constants";
import * as log from "loglevel";

import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import { CANVAS_SOURCE } from "../../Utils/Constants";

log.setLevel("info");

export const handleMessage = (data, store) => {
  const reducerData = store.getState();
  const userRole = reducerData.UserReducer.userDetails.role;
  switch (data.action) {
    case socketMessage.subCategories.WHITEBOARD._ADD_CANVAS:
      if (data.canvasType === "PDF" || data.canvasType === "IMAGE") {
        store.dispatch({
          type: ACTIONS.WHITEBOARD.ADD_CANVAS,
          newCanvas: {
            canvasId: data.canvasId,
            canvasType: data.canvasType,
            canvasSource: data.canvasSource,
            fileUrl: data.fileUrl,
            activePage: data.activePage,
            resolution: data.resolution,
            originalFileName: data.originalFileName
          }
        });
        if (data.canvasType === "IMAGE") {
          store.dispatch({
            type: ACTIONS.WHITEBOARD.SET_ACTIVE_CANVAS,
            activeCanvas: {
              canvasId: data.canvasId,
              canvasType: data.canvasType,
              uploadImage: true
            }
          });
        }
      } else if(data.canvasType === CANVAS_TYPES.REGULAR && data.canvasSource === CANVAS_SOURCE.SNAPSHOT) {
        store.dispatch({
          type: ACTIONS.SNAPSHOT.ADD_CANVAS,
          newCanvas: {
            canvasId: data.canvasId,
            canvasType: data.canvasType,
            canvasSource: data.canvasSource
          }
        });
        store.dispatch({
          type: ACTIONS.SNAPSHOT.SNAPSHOT_SET_ACTIVE_CANVAS,
          activeCanvas: {
            canvasId: data.canvasId,
            canvasType: data.canvasType
          }
        });
      } else {
        store.dispatch({
          type: ACTIONS.WHITEBOARD.ADD_CANVAS,
          newCanvas: {
            canvasId: data.canvasId,
            canvasType: data.canvasType,
            canvasSource: data.canvasSource
          }
        });
      }
      break;
    case socketMessage.subCategories.WHITEBOARD._CHANGE_ACTIVE_CANVAS:
      if(data.canvasType === CANVAS_TYPES.REGULAR && data.canvasSource === CANVAS_SOURCE.SNAPSHOT)
        return;
      store.dispatch({
        type: ACTIONS.WHITEBOARD.SET_ACTIVE_CANVAS,
        activeCanvas: {
          canvasId: data.canvasId,
          canvasType: data.canvasType
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._CLEAR_CANVAS:
      if(data.canvasType === CANVAS_TYPES.REGULAR && data.canvasSource === CANVAS_SOURCE.SNAPSHOT)
        store.dispatch({
          type: ACTIONS.SNAPSHOT.CLEAR_CANVAS,
          canvasId: data.canvasId
        });
      else
        store.dispatch({
          type: ACTIONS.WHITEBOARD.CLEAR_CANVAS,
          canvasId: data.canvasId,
          activePage: data.activePage
        });
      break;
    case socketMessage.subCategories.WHITEBOARD._DELETE_CANVAS:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.DELETE_CANVAS,
        canvasId: data.canvasId
      });
      store.dispatch({
        type: ACTIONS.WHITEBOARD.DELETE_CANVAS,
        canvasId: data.canvasId
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._ADD_STICKY_OBJECT:
      store.dispatch({
        type: ACTIONS.SNAPSHOT.ADD_STICKY_OBJECT,
        data: {
          participantId: data.participantId,
          whiteboardData: data.whiteboardData,
          canvasType: data.canvasType,
          canvasId: data.canvasId
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._SNAPSHOT_SEARCH_CODE:
      const result = data.message ? JSON.parse(data.message) : {};
      if (result.results?.length > 0) {
        store.dispatch({
          type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_RESULT,
          value: data.message ? JSON.parse(data.message) : null
        });
      } else {
        if(result.error){
          store.dispatch({
            type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_ERROR,
            value: ERROR.SNAPSHOT_SCAN_CODE_ERROR_IN_SEARCH
          });
        }else{
          store.dispatch({
            type: ACTIONS.SNAPSHOT.SNAPSHOT_SCAN_CODE_ERROR,
            value: ERROR.SNAPSHOT_SCAN_CODE_NO_RESULT_ERROR
          });
        }        
      }
      break;
    case socketMessage.subCategories.WHITEBOARD._ADD_OBJECT:
      if(data.canvasType === CANVAS_TYPES.REGULAR && data.canvasSource === CANVAS_SOURCE.SNAPSHOT) {
        store.dispatch({
          type: ACTIONS.SNAPSHOT.ADD_OBJECT,
          data: {
            subAction: data.subAction,
            participantId: data.participantId,
            whiteboardData: data.whiteboardData,
            canvasType: data.canvasType,
            canvasId: data.canvasId
          }
        });
      } else {
        store.dispatch({
          type: ACTIONS.WHITEBOARD.ADD_OBJECT,
          data: {
            subAction: data.subAction,
            participantId: data.participantId,
            whiteboardData: data.whiteboardData,
            canvasType: data.canvasType,
            canvasId: data.canvasId,
            activePage: data.activePage
          }
        });
      }
      break;
    case socketMessage.subCategories.WHITEBOARD._UPDATE_OBJECT:
      store.dispatch({
        type: ACTIONS.WHITEBOARD.UPDATE_OBJECT,
        data: {
          subAction: data.subAction,
          participantId: data.participantId,
          whiteboardData: data.whiteboardData,
          canvasType: data.canvasType,
          canvasId: data.canvasId
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._MODIFY_OBJECT:
      store.dispatch({
        type: ACTIONS.WHITEBOARD.MODIFY_OBJECT,
        data: {
          subAction: socketMessage.subCategories.WHITEBOARD.subActions.REGULAR,
          participantId: data.whiteboardData.participantId,
          whiteboardData: data.whiteboardData,
          canvasType: data.canvasType,
          canvasId: data.canvasId
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._DELETE_OBJECT:
      store.dispatch({
        type: ACTIONS.WHITEBOARD.DELETE_OBJECT,
        data: {
          subAction: data.subAction,
          participantId: data.participantId,
          whiteboardData: data.whiteboardData,
          canvasType: data.canvasType,
          canvasId: data.canvasId
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._SET_CANVAS_THUMBNAIL:
      store.dispatch({
        type: ACTIONS.WHITEBOARD.SET_CANVAS_THUMBNAIL,
        data: {
          canvasId: data.canvasId,
          thumbnailUrl: data.thumbnailUrl
        }
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._SET_DEFAULT_FONT_INFO:
      data.isLocally = false; //isLocally false because want broadcast defaultFontInfo in entire session
      store.dispatch({
        type: ACTIONS.WHITEBOARD.SET_DEFAULT_FONT_INFO,
        data: data.defaultFontInfo
      });
      break;
    case ACTIONS.PDF._SET_PAGE_POSITION:
      let canvasPositionDetails = {};
      canvasPositionDetails.category = data.category;
      canvasPositionDetails.canvasId = data.canvasId;
      canvasPositionDetails.frameDetails = data.frameDetails;
      store.dispatch({
        type: ACTIONS.PDF.SET_PAGE_POSITION,
        data: canvasPositionDetails
      });
      break;
    case ACTIONS.PDF._SET_FIT_SCREEN:
      store.dispatch({
        type: ACTIONS.PDF.SET_FIT_SCREEN,
        isFitScreen: data.isFitScreen
      });
      break;
    case ACTIONS.PDF._CHANGE_ACTIVE_PAGE:
      let activePageDetails = {};
      activePageDetails.canvasId = data.canvasId;
      activePageDetails.activePage = data.activePage;
      activePageDetails.canvasType = data.canvasType;
      store.dispatch({
        type: ACTIONS.PDF.CHANGE_ACTIVE_PAGE,
        data: activePageDetails
      });
      break;
    case ACTIONS.PDF._UPDATE_URL:
      store.dispatch({
        type: ACTIONS.PDF.UPDATE_URL,
        data: data
      });
      break;
    case socketMessage.subCategories.WHITEBOARD._DOWNLOADING_TO_CANVAS :
      store.dispatch({
        type: ACTIONS.WHITEBOARD.DOWNLOADING_TO_CANVAS,
        value: data.isDownloading
      })
      break;
    case "_FRAME_SIZE":
      // let ele = document.getElementById("loookitFrame");
      // let activeCanvasData = reducerData.WhiteboardReducer.canvases[reducerData.WhiteboardReducer.activeCanvas.canvasId]
      // if (ele) {
      //   if(activeCanvasData.resolution.width < activeCanvasData.resolution.height) {
      //     ele.style.height = data.frameSize.height + "px";
      //     ele.style.width = data.frameSize.width*data.frameSize.canvasRatio + "px";
      //     ele.style.pointerEvents = "none";
      //   } else {
      //     ele.style.width = data.frameSize.width + "px";
      //     ele.style.height = data.frameSize.height + "px";
      //     ele.style.pointerEvents = "none";
      //   }
      // }

      store.dispatch({
        type: "_FRAME_SIZE",
        data: data.frameSize
      });

      break;
  }
};
