import React, {useRef} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";

import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";
import { getMessage } from "CONFIG/i18n";
import icDownloadQRCode from "ASSETS/Images/ic_download.svg";
import './AddQRCodeSuccessModal.less'
import GenerateAndDownloadQRCode from "COMPONENTS/CommonComponents/GenerateAndDownloadQRCode/GenerateAndDownloadQRCode";
import { getQRCodeUrl, getStringWithoutExtraSpaces } from "UTILS/Utility";
import { REGEX } from "UTILS/Constants";

const RealWearQRCodeModal = (props) => {
  const generateAndDownloadQRCodeRef = useRef();

  const renderButtons = () => {
    return (
      <div className="permissionButtons">
        <Button
          className="buttons customBtn"
          onClick={() => props.onClosed()}
        >
          {getMessage("CLOSE")}
        </Button>
      </div>
    );
  };

  const downloadQRCode=()=>{
    if(generateAndDownloadQRCodeRef.current) {
      generateAndDownloadQRCodeRef.current?.downloadQRCode()
    } else {
      console.error("downloadQRCodeRealWear reference is not defined");
    }
  }

  return (
    <Modal
      isOpen={props.show}
      className="addOrganisationModal userAddModal mb-0 add-qr-success"
      onClosed={props.onClosed}
    >
      <ModalHeader className="popupHeader">
        {getMessage("RW_CONFIG_QR_CODE")}
      </ModalHeader>
      <ModalBody>
        <label className="rw-qr-code-modal-label"> {getMessage("PLEASE_USE_BELOW_QR_CODE")} </label>
        <div className="qr-code-details-box">
          <GenerateAndDownloadQRCode
            ref={generateAndDownloadQRCodeRef}
            downloadQRCode={true}
            fileName={`RealWearQRCode-${getStringWithoutExtraSpaces(props.qrCodeDetails?.name).replace(REGEX.SPECIAL_CHARACTERS, '')}-${props.qrCodeDetails.qrId}`}
            value={JSON.stringify({ url: getQRCodeUrl(props.qrCodeDetails?.qrId), name: props.qrCodeDetails?.name, number: props.qrCodeDetails?.number })}
            id={"realWearQRCodes" + props.qrCodeDetails.qrId}
          />
          <a id={"qrCodeDownload" + props.qrCodeDetails.qrId} />
         
          <div className="mt-0 pt-0 qr-footer">
            <div>
              <img
                src={icDownloadQRCode}
                alt="download"
                onClick={() => downloadQRCode()}
                className="cursorPointer"
              />
              <br />
              <span>{getMessage("DOWNLOAD")}</span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="permissionButtons">
        {renderButtons()}
      </ModalFooter>
    </Modal>
  );
}

export default modalScrollBar(RealWearQRCodeModal);