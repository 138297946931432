import React, { Component } from "react";

import _ from "lodash";
import * as kurentoUtils from "kurento-utils";
import { wsocket } from "WEBSOCKET/wsocket";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { isMobileOnly, isIE, isTablet, isIPad13 } from "react-device-detect";
import ComponentSpinner from "COMPONENTS/Spinner/ComponentSpinner/ComponentSpinner";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
import IconsPositionBar from "./IconsPositionBar";
import BitRateTile from "COMPONENTS/CommonComponents/BitRate/BitRateTile";

import {
  socketMessage,
  webRtcMessageId,
  mediaType,
  mediaStatus
} from "WEBSOCKET/constants";

import { OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO } from "UTILS/Constants";

import { getMessage } from "CONFIG/i18n";

import {
  getMediaConstraints, 
  getUserMediaError, 
  getUserNameAcronym, 
  getTurnServerOptionConfig,
  isMobileOriPad, 
  getFirstNamebyParticipantId,
} from "UTILS/Utility";

import "../Whiteboard/AnnotationBar/AnnotationBar";

import "./Styles.less";

// Assets 
import audio_off from "ASSETS/Images/ic_audio_off_small.svg";
import audio_on from "ASSETS/Images/ic_audio_on_small.svg";
import video_on from "ASSETS/Images/ic_video_on_small.svg";
import video_off from "ASSETS/Images/ic_video_off_small.svg";

export default class VideoComponent extends Component {

  constructor(props) {
    super(props);  
    this.state={
      loaderSize: 8,
      showSpinner: false
    }
    this.playLocalStreamTimeout = null;
    this.playRemoteStreamTimeout = null;
  }

  componentDidMount() {
    console.log('Video mount', this.props.participant.userName);
    this.setSpinnerSize();

    this.startCall();
    // need to reset screenshare top here because it takes time to load which needs top recalculation 
    this.props.setScreenShareTopMargin();

    var mediaElement = document.getElementById(this.props.uniqueId+"video");

    this.interval = setInterval(()=> {
      if(mediaElement && mediaElement.clientWidth) {
        this.props.setFlimStripOverflow();
        clearInterval(this.interval)
      }
    }, 2000)

    if(mediaElement) {
      mediaElement.oncanplay = () => {
        this.props.enableCamera(true)
        this.props.hideSpinner();
      }
  
      mediaElement.oncanplaythrough = () => {
        this.props.enableCamera(true)
        this.props.hideSpinner();
      }
    }

    // scroll to the video component when self video component is rendered
    if(this.props.uniqueId == this.props.participant.uniqueId && (isMobileOnly || ((isTablet || isIPad13) && !this.props.isLandscape))) {
      let videoComponent = document.getElementById("videoComponent");
      videoComponent && videoComponent.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

  startCall() {
    if (!wsocket.io) return;
    if (this.props.uniqueId === this.props.participant.uniqueId) {  // for self
      // send only
      if (this.props.participant.mediaType === mediaType.AUDIO_VIDEO && 
          ((this.props.deviceList && this.props.deviceList.length >= 1) || isMobileOriPad()) && 
          //don't create VIDEO rtcpeer if snapshot is running
          (this.props.uniqueId === this.props.organiser || !this.props.snapshotPermissionReply)) {
        
        let videoElement = document.getElementById(this.props.participant.uniqueId + "video");
        let constraints = getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings, this.props.isLandscape);
        
        if(isMobileOriPad() && this.props.facingMode) {
          constraints.video.facingMode = {exact: this.props.facingMode}
        } else if (!isMobileOriPad() && this.props.selectedDevice && this.props.selectedDevice.deviceId) {
          constraints.video.deviceId = {exact: this.props.selectedDevice.deviceId}
        }
        this.createRTCPeerAndStartSharing(
          videoElement,
          constraints,
          mediaType.VIDEO
        );
      }
      let audioElement = document.getElementById(this.props.participant.uniqueId + "audio");
      if(!audioElement) {
        audioElement = this.getAudioElement(this.props.participant.uniqueId);
        this.createRTCPeerAndStartSharing(
          audioElement,
          getMediaConstraints(mediaType.AUDIO),
          mediaType.AUDIO
        );
      }
    } else {
      // receive only
      // control comes here when we get _NEW_WEBRTC_MEDIA i.e. when user has started sharing his media
      if (this.props.participant.mediaType === mediaType.AUDIO_VIDEO) {
        let videoElement = document.getElementById(this.props.participant.uniqueId + "video");
        this.createRTCPeerAndStartReceiving(
          videoElement,
          getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings,  this.props.isLandscape),
          mediaType.VIDEO        
        );
      }
      let audioElement = document.getElementById(this.props.participant.uniqueId + "audio");
      if(!audioElement) {
        audioElement = this.getAudioElement(this.props.participant.uniqueId);
        this.createRTCPeerAndStartReceiving(
          audioElement,
          getMediaConstraints(mediaType.AUDIO),
          mediaType.AUDIO
        );
      }
    }
  }

  componentDidUpdate(prevProp){
    if(prevProp.renegotiateMediatype !== this.props.renegotiateMediatype && this.props.renegotiateMediatype) {
      this.renegotiate();
      if(this.props.renegotiateMediatype === mediaType.AUDIO) {
        this.props.audioVideoAction.removeRenegotiateAudioParticipant(this.props.participant.uniqueId)
      } else {
        this.props.audioVideoAction.removeRenegotiateVideoParticipant(this.props.participant.uniqueId)
      }
    }

    if((!_.isEqual(this.props.participant, prevProp.participant) &&
    // audio to video promotion
    prevProp.participant.mediaType === mediaType.AUDIO &&
    this.props.participant.mediaType === mediaType.AUDIO_VIDEO)
    // primary participant change
    || (this.props.primaryParticipant !== prevProp.primaryParticipant && (
      prevProp.primaryParticipant.uniqueId === this.props.participant.uniqueId || 
      this.props.primaryParticipant.uniqueId === this.props.participant.uniqueId
    )) 
    || 
    (isIE && this.props.participant.videoStatus !== prevProp.participant.videoStatus)){
      this.startCall();
      if(prevProp.participant.mediaType === mediaType.AUDIO &&
        this.props.participant.mediaType === mediaType.AUDIO_VIDEO) {
        this.props.headerAction.updateHeaderIconClass(
          mediaType.AUDIO,
          "moduleIcon cursorDisabled",
          " cursorDisabled"
        );
        this.props.headerAction.updateHeaderIconClass(
          mediaType.AUDIO_VIDEO,
          "moduleIconActive cursorDisabled",
          " cursorDisabled"
        );
      }
    }

    // usecase: if any participant has started snapshot and his video was going on, the we need to remove his video element source
    if(!_.isEqual(this.props.participant, prevProp.participant) && this.props.participant.mediaType === mediaType.AUDIO 
      && prevProp.participant.mediaType === mediaType.AUDIO_VIDEO) {
      this.removeSourceOfVideo();
    }

    // usecase: In case of mute / unmute video on IE, we need to apply inline style to hide / display video stream
    // inline style not work for video element as it is replaced by plugin
    //     if(!_.isEqual(this.props.participant, prevProp.participant) && isIE && 
    //       this.props.participant.videoStatus !== prevProp.participant.videoStatus) {
    //       let videoElement = document.getElementById(this.props.participant.uniqueId + "video");
    //       videoElement.style.display = (this.props.participant.videoStatus === mediaStatus.MUTED
    //       || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
    //         ? "none" 
    //         : "block";
    //     }

    //     // usecase: In case of mute / unmute audio on IE, we need to apply inline style to hide / display audio stream
    //     if(!_.isEqual(this.props.participant, prevProp.participant) && isIE && 
    //       this.props.participant.audioStatus !== prevProp.participant.audioStatus) {
    //       let audioElement = document.getElementById(this.props.participant.uniqueId + "audio");
    //       audioElement.style.display = (this.props.participant.audioStatus === mediaStatus.MUTED
    //       || this.props.participant.audioStatus === mediaStatus.MUTED_BY_AGENT) ? "none" : "block";
    //     }

    // this needs to be executed when video-canvas is swapped.
    // After swapping, while mounting, deviceList is not yet fetched by parent and hence comes empty.
    // since deviceList is empty, video element for SELF is not played.
    // So when parent fetches device list, the video element for SELF needs to be played.
    if((!prevProp.deviceList || !prevProp.deviceList.length) 
      && this.props.deviceList && this.props.deviceList.length >= 1 
      && this.props.uniqueId === this.props.participant.uniqueId) {
      this.startCall();
    }

    if(prevProp.componentInFocus !== this.props.componentInFocus || 
      prevProp.index !== this.props.index) {
      this.setSpinnerSize();
    }
  }

  setSpinnerSize =()=>{
    this.setState({loaderSize: 4}) // spinner size when non-primory participant
  }

  toggleSpinner =(showSpinner)=> {
    this.setState({showSpinner})
  }

  removeSourceOfVideo = () =>{
    // after degrading from Video to Audio need to remove src of stream from video element
    let videoElement = document.getElementById(this.props.participant.uniqueId + "video");
    if(isIE){
      attachMediaStream(
        videoElement, null
      );
    } else {
      videoElement.srcObject = null;
    }
  }
  
  getAudioElement = (uniqueId) => {
    console.log('start call :: get audio element ', uniqueId);
    // let mediaElementToMount = document.getElementById("parent"+ this.props.participant.uniqueId);
    let videoComponent = document.getElementById("videoComponent");

    let audioElement = document.getElementById(uniqueId + "audio");
    if(!audioElement) {
      console.log('start call :: create audio element ', uniqueId);
      audioElement = document.createElement('audio');
      audioElement.id = uniqueId + "audio";
      audioElement.className = "elementForAudio";
      audioElement.style.visibility = "hidden";
      // mute self audio
      if(this.props.participant.uniqueId === this.props.uniqueId) {
        audioElement.volume = 0;
        audioElement.muted = true;
      } else {
        audioElement.muted = false;
        // potential fix for: WAAG-3121-Echo is observed every time at the user's end for a few seconds when the user accepts the audio/video call request.
        // autoplay should not be on for local stream 
        // Refer : https://www.slideshare.net/MuazKhan/echo-in-webrtc-why (slide 10)
        audioElement.autoplay = true;
      }
      videoComponent.appendChild(audioElement);
    }       
    return audioElement;
  }

  createRTCPeerAndStartSharing = (mediaEle, constraints, participantMediaType, mediaStream=null) => {
    let self = this;
    let rtcPeer = null;
    let mediaElement = mediaEle;
    let offerToReceive = participantMediaType === mediaType.AUDIO ? this.offerToReceiveAudio : this.offerToReceiveVideo ;
    if (mediaEle && this.props.uniqueId === this.props.participant.uniqueId) {  // if user is self
      
      let rtcPeerCount = this.props.participant.mediaType === mediaType.AUDIO_VIDEO ? 2 : 1
      
      // for first time, we need to create an RTCPeer for both audio and video 
      if (
        wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] ===
        undefined || Object.keys(wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId]).length < rtcPeerCount
      ) {
        var options = {
          mediaConstraints: constraints,
          onicecandidate: participantMediaType === mediaType.AUDIO ? this.onIceCandidateAudio : this.onIceCandidate,
          configuration: {
            iceServers: getTurnServerOptionConfig(this.props.turnServer),
            iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
          }
        };

        if(mediaStream){
          if(participantMediaType === mediaType.AUDIO) {
            options.audioStream = mediaStream;
          } else {
            options.videoStream = mediaStream;
          }
        } else {
          options.localVideo= mediaElement;
        }
   
        wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] = wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] ===
        undefined ? {} : wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId];
        rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
          options,
          function(error) {
            if (error) {
              let errorMessage = getUserMediaError(error, participantMediaType)
              if(errorMessage){
                toastr.error(errorMessage)
              }
              setTimeout(() => self.props.hideSpinner(),1000);

              // Send message to server to be sent to the agent
              var message = {
                data: {
                  category: socketMessage.category.WEBRTC,
                  action: webRtcMessageId.MEDIA_DEVICE_PERMISSION_DENIED,
                  sessionKey: self.props.sessionKey,
                  media: participantMediaType,
                }
              };
              self.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);

              return console.error(error);
            }
            setTimeout(() => self.props.hideSpinner(),1000);
            console.log('Created peer for sharing ',participantMediaType, 'of ',self.props.participant.userName);
            // we need to save rtcPeer Local Stream in currentStream because after switching the camera, we need to stop the current stream
            if(participantMediaType === mediaType.VIDEO) {
              self.props.setCurrentStream(
                wsocket.audioAndVideoRtcPeer[
                self.props.participant.uniqueId
                ][participantMediaType].getLocalStream())
            }
            console.log(participantMediaType, "WEBRTC self stream id for", 
              getFirstNamebyParticipantId(self.props.participants, self.props.participant.uniqueId, true),
              self.props.participant.uniqueId,
              wsocket.audioAndVideoRtcPeer[self.props.participant.uniqueId
              ][participantMediaType].getLocalStream().id    
            );
            // call Kurento Utils function to generate offer
            this.generateOffer(offerToReceive);
          }
        );
        wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType] = rtcPeer;
        
        let pc = rtcPeer.peerConnection; 
        pc.onconnectionstatechange = (event) => {
          console.log(participantMediaType, "WEBRTC send only RTCPeerConnection state for", 
            getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
            this.props.participant.uniqueId,
            pc.connectionState, pc.getTransceivers());
          switch(pc.connectionState) {
            case "completed":
            case "connected":
              this.playLocalStream(mediaElement, participantMediaType);
              // if primary participant is self play primary video stream also
              if(this.props.primaryParticipant.uniqueId === this.props.participant.uniqueId
                && participantMediaType === mediaType.VIDEO)
                this.props.playPrimaryParticipantVideoStream();
              this.toggleSpinner(false)
              break;
            case "failed":            
              if(window.navigator.onLine) {
                this.toggleSpinner(true);
                this.triggerRenegotiation(participantMediaType);
              }
            case "disconnected":
              console.error(participantMediaType, "WEBRTC receive only RTCPeerConnection state failed for", 
                getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
                this.props.participant.uniqueId, 
                pc.connectionState, pc.getTransceivers());
              break;
            case "closed":
              console.error(participantMediaType, "WEBRTC send only RTCPeerConnection state for", 
                getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
                this.props.participant.uniqueId,
                pc.connectionState, pc.getTransceivers());
              break;
          }
        }
        pc.oniceconnectionstatechange = ev => {
          console.log(participantMediaType, "WEBRTC send only IceConnection state for", 
            getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true), 
            this.props.participant.uniqueId,
            pc.iceConnectionState);
          switch(pc.iceConnectionState) {
            case "disconnected":
            case "failed":
            case "closed":
              console.error(participantMediaType, "WEBRTC send only IceConnection state for", 
                getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true), 
                this.props.participant.uniqueId,
                pc.iceConnectionState);
              break;
          }
        }

      } else {  // control will come here when we switch primary video element to another participant's video
        this.playLocalStream(mediaElement, participantMediaType);
      }
    };
  }

  playLocalStream =(mediaElement, participantMediaType)=>{
    if (mediaElement) {
      let localStream = wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId]
          && wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType]
          && wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType].getLocalStream();
      // need to handle IE separately because we cannot attach a source to video element in IE
      if (isIE) {
        if(localStream)
          attachMediaStream(
            mediaElement,
            localStream
          );
        if(this.props.currentStream) {
          attachMediaStream(
            mediaElement,this.props.currentStream
          );
        }
      } else {
        if(localStream) {
          mediaElement.srcObject = localStream; 
        } 
        if(this.props.currentStream) {
          mediaElement.srcObject = this.props.currentStream
        }
        if(participantMediaType === mediaType.VIDEO) {
          // Added timeout to avoid multiple play calls at the same time
          // If play() is called multiple times it gives error in console and sometimes stream is not displayed
          this.playLocalStreamTimeout && clearTimeout(this.playLocalStreamTimeout);
          this.playLocalStreamTimeout = setTimeout(() => {
            mediaElement.play().then(() => 
              console.log(`local ${participantMediaType} has been played successfully for ${this.props.participant.userName}`))
            .catch((error) => {
              console.log('error: ', error);
              console.log(`an error occured while playing local ${participantMediaType}, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
            });
          }, 1000);
        } else { 
          mediaElement.play().then(() => 
            console.log(`local ${participantMediaType} has been played successfully for ${this.props.participant.userName}`))
          .catch((error) => {
            console.log('error: ', error);
            console.log(`an error occured while playing local ${participantMediaType}, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
          });
        }
      }
    }
  }

  createRTCPeerAndStartReceiving = (mediaEle, constraints, participantMediaType) => {
    let self = this;
    let rtcPeer = null;
    let mediaElement = mediaEle;
    let offerToReceive = participantMediaType === mediaType.VIDEO ? this.offerToReceiveVideo : this.offerToReceiveAudio;
    var options = {
      remoteVideo: mediaElement,
      mediaConstraints: constraints,
      onicecandidate: participantMediaType === mediaType.VIDEO ? this.onIceCandidate : this.onIceCandidateAudio,
      configuration: {
        iceServers: getTurnServerOptionConfig(this.props.turnServer),
        iceTransportPolicy: window._env_.REACT_APP_ICE_TRANSPORT_POLICY || 'all'
      }
    };
    let rtcPeerCount = this.props.participant.mediaType === mediaType.AUDIO_VIDEO ? 2 : 1
    if (
      wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] === undefined || 
      Object.keys(wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId]).length < rtcPeerCount
    ) {
      wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] = wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] ===
      undefined ? {} : wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId];
      rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
        options,
        function(error) {
          if (error) {
            console.log(error);
            return console.error(error);
          }
          console.log('Created peer for receiving ',participantMediaType, 'of ',self.props.participant.userName);
          this.generateOffer(offerToReceive);
        }
      );
      wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType]=rtcPeer;
      let pc = rtcPeer.peerConnection; 
      pc.ontrack = (event) => {
        console.log(participantMediaType, "WEBRTC remote stream id for", 
          getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true), 
          this.props.participant.uniqueId,
          event.track.id    
        );
      }

      pc.onconnectionstatechange = (event) => {
        console.log(participantMediaType, "WEBRTC receive only RTCPeerConnection state for", 
          getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
          this.props.participant.uniqueId, pc.connectionState, pc.getTransceivers());
        switch(pc.connectionState) {
          case "completed":
          case "connected":
            this.playStream(mediaElement, participantMediaType);
            // if primary participant is self play primary video stream also
            if(this.props.primaryParticipant.uniqueId === this.props.participant.uniqueId
              && participantMediaType === mediaType.VIDEO)
              this.props.playPrimaryParticipantVideoStream();
            this.toggleSpinner(false)
            break;
          case "failed":    
            if(window.navigator.onLine){
              this.toggleSpinner(true);
              this.triggerRenegotiation(participantMediaType);
            } 
          case "closed":
          case "disconnected":
            console.error(participantMediaType, "WEBRTC receive only RTCPeerConnection state failed for", 
              getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
              this.props.participant.uniqueId, 
              pc.connectionState, pc.getTransceivers());
            break;
        }
      }

      pc.oniceconnectionstatechange = ev => {
        console.log(participantMediaType, "WEBRTC receive only IceConnection state for", 
          getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
          this.props.participant.uniqueId, pc.iceConnectionState);
        switch(pc.iceConnectionState) {
          case "disconnected":
          case "failed":
          case "closed":
            console.error(participantMediaType, "WEBRTC receive only IceConnection state for", 
              getFirstNamebyParticipantId(this.props.participants, this.props.participant.uniqueId, true),
              this.props.participant.uniqueId, pc.iceConnectionState);
            break;
        }
      }

    } else {
      this.playStream(mediaElement, participantMediaType);
    }
  };

  triggerRenegotiation(mediaType) {
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.RENEGOTIATE,
        sessionKey: this.props.sessionKey,
        senderId: this.props.participant.uniqueId,
        media: mediaType
      }
    });
  }

  renegotiate() {
    if(window.navigator.onLine) {
      const direction = this.props.participant.uniqueId ===
      this.props.uniqueId ? 'send' : 'receive';
      const oldPeer = wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][this.props.renegotiateMediatype];
      let constraint;
      let meadiaElement;
      if(this.props.renegotiateMediatype === mediaType.VIDEO) {
        constraint = getMediaConstraints(mediaType.VIDEO, this.props.videoBandwidthSettings, this.props.isLandscape) ;
        meadiaElement = document.getElementById(this.props.participant.uniqueId + "video");
      } else {
        constraint = getMediaConstraints(mediaType.AUDIO) ;
        meadiaElement = this.getAudioElement(this.props.participant.uniqueId)
      }

      if (direction === 'send') {
        const peerStream = oldPeer.getLocalStream();
        oldPeer.dispose(false);
        delete wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][this.props.renegotiateMediatype];
        this.createRTCPeerAndStartSharing(meadiaElement, constraint, this.props.renegotiateMediatype, peerStream);
      } else {
      // we don't need to hold on to the old remote stream 
        oldPeer.dispose(true);
        delete wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][this.props.renegotiateMediatype];
        this.createRTCPeerAndStartReceiving(meadiaElement, constraint, this.props.renegotiateMediatype);
      }
    }
  }

  playStream =(mediaElement, participantMediaType)=> {
    if (mediaElement) {
      let remoteStream = wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId] 
      && wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType]
      && wsocket.audioAndVideoRtcPeer[this.props.participant.uniqueId][participantMediaType].getRemoteStream();
      if (isIE) {
        if(this.props.participant.videoStatus !== mediaStatus.MUTED 
           && this.props.participant.videoStatus !== mediaStatus.MUTED_BY_AGENT
           || participantMediaType === mediaType.AUDIO) {
          attachMediaStream(
            mediaElement,
            remoteStream
          );
        } else {  // in case of muted video / audio call, we need to pass source as null for IE
          attachMediaStream(
            mediaElement, null
          );
        }
      } else {
        mediaElement.srcObject = remoteStream;
        if(participantMediaType === mediaType.VIDEO) {
          // Added timeout to avoid multiple play calls at the same time
          // If play() is called multiple times it gives error in console and sometimes stream is not displayed
          this.playRemoteStreamTimeout && clearTimeout(this.playRemoteStreamTimeout);
          this.playRemoteStreamTimeout = setTimeout(() => {
            mediaElement.play().then(() => 
              console.log(`Remote ${participantMediaType} has been played successfully for ${this.props.participant.userName}`))
            .catch((error) => {
              console.log('error: ', error);
              console.log(`an error occured while playing remote ${participantMediaType}, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
            });
          }, 1000)
        } else {
          mediaElement.play().then(() => 
            console.log(`Remote ${participantMediaType} has been played successfully for ${this.props.participant.userName}`))
          .catch((error) => {
            console.log('error: ', error);
            console.log(`an error occured while playing remote ${participantMediaType}, error : ${error && error.message ? error.message : JSON.stringify(error)}`)
          });
        }
      }
    }
  }
  // call back function by Kurento utils to send ICE candidates
  onIceCandidate = (candidate, wp, media) => {
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.ICE_CANDIDATE,
        sessionKey: this.props.sessionKey,
        iceData: {
          candidate: candidate,
          senderId: this.props.participant.uniqueId,
          media: mediaType.VIDEO
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  onIceCandidateAudio = (candidate, wp) => {
    let message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.ICE_CANDIDATE,
        sessionKey: this.props.sessionKey,
        iceData: {
          candidate: candidate,
          senderId: this.props.participant.uniqueId,
          media: mediaType.AUDIO
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  // this callback function is called by Kurento Utils after sdp offers get generated
  offerToReceiveVideo = (error, offerSdp, wp) => {
    if (error) return console.error("sdp offer error");
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SDP_OFFER,
        sessionKey: this.props.sessionKey,
        sdp: offerSdp,
        senderId: this.props.participant.uniqueId,
        media: mediaType.VIDEO
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  offerToReceiveAudio = (error, offerSdp, wp) => {
    if (error) return console.error("sdp offer error");
    var message = {
      data: {
        category: socketMessage.category.WEBRTC,
        action: webRtcMessageId.SDP_OFFER,
        sessionKey: this.props.sessionKey,
        sdp: offerSdp,
        senderId: this.props.participant.uniqueId,
        media: mediaType.AUDIO
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  componentWillUnmount() {
    console.log('VideoComponent unmount for', this.props.participant.userName);
    clearInterval(this.interval);
    clearTimeout(this.playLocalStreamTimeout);
    clearTimeout(this.playRemoteStreamTimeout);
  }

  render() {
    // if video is muted then hide video tag
    let videoStyle={ width: "auto", 
      visibility:(this.props.participant.videoStatus === mediaStatus.MUTED
      || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) ? "hidden":"visible"// if video is muted then hide video tag
    }
    let videoStyleObject2={ width: "100%", 
      visibility:(this.props.participant.videoStatus === mediaStatus.MUTED
        || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) ? "hidden":"visible"// if video is muted then hide video tag
    }

    return (
      <div 
        id="videoContainer"
        className={
          this.props.index == 0
            ? "displayNone"
            : this.props.index == 1 
              ? "smallVideo marginAuto pb-1 pl-1 pr-1 " 
              : this.props.totalWebRtcElement - 1 == this.props.index 
                ? "smallVideo marginAuto pb-1 pl-1 pr-1 " 
                : "smallVideo marginAuto pb-1 px-1 "
        }
      >
        <div
          className={
            this.props.index == 0
              ? OVERLAP_FILMSTRIP_ON_REMOTE_VIDEO != "true" ?
                this.props.participant.uniqueId !== this.props.uniqueId ?
                  "audiovideo2 displayFlex IEflexNone justifyContentFlexCenter" :
                  "orangeBorder audiovideo2 displayFlex IEflexNone justifyContentFlexCenter" 
                : this.props.participant.uniqueId !== this.props.uniqueId  ?
                  "audiovideo2 displayFlex IEflexNone justifyContentFlexCenter":
                  "orangeBorder audiovideo2 displayFlex IEflexNone justifyContentFlexCenter" 
              : this.props.participant.uniqueId !== this.props.uniqueId ?
                "audiovideo6 displayFlex IEflexNone justifyContentFlexCenter" :
                "orangeBorder audiovideo6 displayFlex IEflexNone justifyContentFlexCenter"
          }
          onClick = {() => {
            if(this.props.setPrimaryParticipant && this.props.participant) {
              this.props.setPrimaryParticipant(this.props.index);
            }
          }}
          id={this.props.participant.uniqueId}
        >
          { this.state.showSpinner &&
          <ComponentSpinner 
            componentId={this.props.participant.uniqueId} 
            loaderSize={this.state.loaderSize + 'px'} />
          }
          {
            window._env_.REACT_APP_SHOW_BITRATE == "true" &&
            !isIE &&
            this.props.primaryParticipant.uniqueId !== this.props.participant.uniqueId &&
            <BitRateTile 
              mediaType = {
                this.props.participant.mediaType === mediaType.AUDIO_VIDEO
                  ? mediaType.VIDEO
                  : mediaType.AUDIO
              }
              participantUniqueID = {this.props.participant.uniqueId}
              uniqueId = {this.props.uniqueId}
              wsocketPeer = {wsocket.audioAndVideoRtcPeer}
            />
          }
          {this.props.participant.uniqueId === this.props.uniqueId ? (
            <div id={`video${this.props.index}`} className="parentTagForIE">
              <video
                data-object-fit="contain"
                className={
                  (this.props.participant.videoStatus == mediaStatus.MUTED
                  || this.props.participant.videoStatus == mediaStatus.MUTED_BY_AGENT)
                    ? "videoHeight flipVideo videoWidth"
                    : (this.props.facingMode === null || this.props.facingMode === 'user')
                      ? "videoHeight flipVideo videoWidth"
                      : "videoHeight videoWidth"}
                id={this.props.participant.uniqueId + "video"}
                style={videoStyle}
                volume={0}
                autoPlay
                muted
                playsInline
              />
              <CustomTooltip tooltipText={this.props.participant.userName} tooltipId={`video${this.props.index}`} />
            </div>
          ) : 
            <div id={`video${this.props.index}`} className="parentTagForIE">
              <video
                data-object-fit="contain"
                className={this.props.participant.uniqueId === this.props.uniqueId 
                  ? (this.props.participant.videoStatus === mediaStatus.MUTED
                  || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
                    ? "videoHeight videoWidth" 
                    : "orangeBorder videoHeight videoWidth"
                  : (this.props.participant.videoStatus === mediaStatus.MUTED
                  || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT) 
                    ? "videoHeight videoWidth" 
                    : "videoHeight videoWidth"}
                id={this.props.participant.uniqueId + "video"}
                style={videoStyle}
                volume={0}
                autoPlay
                playsInline
                muted
              />
              <CustomTooltip tooltipText={this.props.participant.userName} tooltipId={`video${this.props.index}`}/>
            </div>
          }
          {this.props.participant.videoStatus === mediaStatus.MUTED
          || this.props.participant.videoStatus === mediaStatus.MUTED_BY_AGENT
          || this.props.participant.mediaType === mediaType.AUDIO ? (
              <label className="displayFlex alignItemsCenter justifyContentFlexCenter IEAcronym">{getUserNameAcronym(this.props.participant.userName)}</label>
            ) : null}
          <div>
            {(this.props.participant.uniqueId !== this.props.uniqueId  || window.innerWidth <= 992) &&
              <IconsPositionBar
                sessionKey={this.props.sessionKey}
                componentInFocus={this.props.componentInFocus}
                organiser={this.props.organiser}
                uniqueId={this.props.uniqueId}
                participant={this.props.participant}
                index={this.props.index}
                onOpenCloseEndVideoCallConfirmationModal={this.props.onOpenCloseEndVideoCallConfirmationModal}
                onOpenCloseEndAudioCallConfirmationModal={this.props.onOpenCloseEndAudioCallConfirmationModal}
                isVideo={this.props.isVideo}
                audioOn={audio_on}
                audioOff={audio_off}
                videoOn={video_on}
                videoOff={video_off}
                tooltipVideoId={`muteUnmuteVideo${this.props.participant.uniqueId}`}
                tooltipAudioId={`muteUnmuteAudio${this.props.participant.uniqueId}`}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}
