import React, { Component } from "react";
import { isIE, isFirefox } from "react-device-detect";

const modalScrollBar = (ScrollBarComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        scrollbarWidth: (window.innerWidth - document.body.clientWidth) + 'px'
      }
    }

    /**
   * Function to set or reset padding-right for browsers
   * @param {*} isSet boolean value to set or reset padding-right
   */

    toggleScrollbarPadding = (isSet) => {
      if (isSet) {
        let { scrollbarWidth } = this.state;
        // Set padding-right for browsers on showing modal
        document.getElementById("dashboard")?.style.setProperty("padding-right", scrollbarWidth, "important");
        document.getElementById("commonHeader")?.style.setProperty("padding-right", scrollbarWidth, "important");
      } else {
        // Reset padding-right for browsers on hiding modal
        document.getElementById("dashboard")?.style.removeProperty("padding-right");
        document.getElementById("commonHeader")?.style.removeProperty("padding-right");
      }
    }

    componentDidMount() {
      if (this.props.show || this.props.showSpinner) {
        document.body.style.setProperty("overflow-y", "hidden", "important");
        if (isIE) {
          document.body.style.setProperty("-ms-overflow-style", "-ms-autohiding-scrollbar", "important")
        }
        this.toggleScrollbarPadding(true);
      }
    }

    componentDidUpdate(prevProps) {

      //Getting scrollbar width
      let scrollbarWidth = (window.innerWidth - document.body.clientWidth) + 'px';
      if (scrollbarWidth !== this.state.scrollbarWidth && scrollbarWidth !== "0px") {
        this.setState({ scrollbarWidth: scrollbarWidth });
      }

      if ((this.props.show != prevProps.show) || this.props.showSpinner != prevProps.showSpinner) {
        if (this.props.show || this.props.showSpinner) {
          document.body.style.setProperty("overflow-y", "hidden", "important");
          // Set padding-right for browsers on showing modal
          this.toggleScrollbarPadding(true);
        } else {
          if (isFirefox) {
            document.body.style.setProperty("overflow-y", "visible", "important");
          } else {
            document.body.style.setProperty("overflow-y", "overlay", "important");
          }
          // Reset padding-right for browsers on hiding modal
          this.toggleScrollbarPadding(false);
        }
      }

    }

    componentWillUnmount() {
      if (isFirefox) {
        document.body.style.setProperty("overflow-y", "visible", "important");
      } else {
        document.body.style.setProperty("overflow-y", "overlay", "important");
      }
      // Reset padding-right for browsers on unmount
      this.toggleScrollbarPadding(false);
    }

    render() {
      return (
        <ScrollBarComponent {...this.props} />
      );
    }
  };
};

export default modalScrollBar;