import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"
import { getMessage } from "CONFIG/i18n";
import * as log from "loglevel";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

//component
import ScheduleSession from "./ScheduleSession";
import Spinner from "COMPONENTS/Spinner/Spinner";

//Actions
import { sessionActions } from "CONFIG/ActionFactory";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";

//Utilities
import { ERROR, CONFIG } from "UTILS/Constants";
import { disableBrowseBackBtn } from "UTILS/Utility";

export class ScheduleSessionContainer extends Component {
  constructor(props) {
    super(props);
    this.state={
      spinnerVisibility: false
    }
  }
  componentDidMount(){
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });
  }

  scheduleMeeting = (invitees = [], sessionDetail, successCallback, errorCallback) => {
    let invite = invitees && Array.from(invitees);
    this.removeByAttr(invite, "isSelected", "false");
    sessionActions.scheduleSessionAction({
      "sessionName":  sessionDetail.sessionTitle,
      "scheduledStartTime": sessionDetail.startTime,
      "scheduledEndTime": sessionDetail.endTime,
      "language": this.props.language
    }).then(
      (response) => {
        sessionActions.inviteUser(response.data.data.sessionKey, invite, this.props.language).then(
          data => {
          },
          error => {
            toastr.error("SEND_INVITE_FAILED_MESSAGE");
          }
        );
        successCallback && successCallback(response);
      },
      (error) => {
        if(error.code != ERROR.NOT_AUTHORIZED && 
          error.code !== ERROR.USER_DELETED &&
          error.code != ERROR.LICENSE_KEY_EXPIRED &&
          error.code != ERROR.LICENSE_KEY_EXPIRED_ADMIN &&
          error.code != ERROR.LICENSE_KEY_EXPIRED_AGENT &&
          error.code != ERROR.SERVER_UNREACHABLE_ERROR) {
          error.code === ERROR.INVALID_SESSION ?
            errorCallback && errorCallback(getMessage("SCHEDULE_FAILED_MESSAGE")) :
            errorCallback && errorCallback(getMessage(error.code));
        } else {
          errorCallback && errorCallback();
          toastr.error(error.code);
        }
      }
    )
  }

  updateMeeting = (invitees = [], sessionDetail, successCallback, errorCallback)=>{
    let invite = invitees && Array.from(invitees);
    this.removeByAttr(invite, "isSelected", "false");
    sessionActions.updateSessionAction(sessionDetail.sessionKey, {
      "sessionName":  sessionDetail.sessionTitle,
      "scheduledStartTime": sessionDetail.startTime,
      "scheduledEndTime": sessionDetail.endTime,
      "language": this.props.language
    }).then(
      (response) => {
        successCallback && successCallback(response);
        sessionActions.editSessionAction(null);
        sessionActions.inviteUser(sessionDetail.sessionKey, invite, this.props.language).then(
          data => {
            log.info("Data:", data);
          },
          error => {
            toastr.error("SEND_INVITE_FAILED_MESSAGE");
          }
        );
      },
      (error) => {
        if(error.code != ERROR.NOT_AUTHORIZED && 
          error.code !== ERROR.USER_DELETED &&
          error.code != ERROR.LICENSE_KEY_EXPIRED &&
          error.code != ERROR.LICENSE_KEY_EXPIRED_ADMIN &&
          error.code != ERROR.LICENSE_KEY_EXPIRED_AGENT &&
          error.code != ERROR.SERVER_UNREACHABLE_ERROR) { 
          error.code === ERROR.INVALID_SESSION ?
            errorCallback && errorCallback("SESSION_IS_CANCELLED") :
            errorCallback && errorCallback(error.code)
        } else {
          errorCallback && errorCallback();
          toastr.error(error.code);
        }
      }
    )
  }

  removeByAttr = (arr, attr, value) => {
    var i = 0;
    for (; i < arr.length; i++) {
      if (arr[i].name === "" || arr[i].name == null) {
        delete arr[i].name;
      }
      if (arr[i].email === "" || arr[i].email == null) {
        delete arr[i].email;
      }
      if (arr[i].phone === "" || arr[i].phone == null) {
        delete arr[i].phone;
      }
      if (arr[i][attr] === false) {
        arr.splice(i, 1);
        i--;
        continue;
      } else {
        delete arr[i].id;
        delete arr[i].isSelected;
      }
    }
    return arr;
  };

  backToMenu = () =>{
    sessionActions.editSessionAction(null);
    this.props.history.push(CONFIG.path.createSession)
  } 

  componentWillUnmount(){
    sessionActions.editSessionAction(null);
  }

  render() {
    return (
      <React.Fragment>
        <Spinner showSpinner={this.state.spinnerVisibility} />
        <ScheduleSession
          setLanguageAction={this.props.setLanguageAction}
          language={this.props.language}
          scheduleMeeting={this.scheduletMeeting}
          backToMenu={this.backToMenu}
          scheduleMeeting={this.scheduleMeeting}
          editSessionData={this.props.editSessionData}
          updateMeeting={this.updateMeeting}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.LanguageReducer.language,
    editSessionData: state.SessionReducer.editSessionData
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLanguageAction
    },
    dispatch
  ); 
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleSessionContainer)
