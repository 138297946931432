import React, { Component } from "react";
import WebViewer from 'webviewer';  
import { withOrientationChange, isMobileOnly, isSafari, isIOS } from "react-device-detect";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import _ from "lodash";

import { pdfTronLibPath } from "UTILS/Constants";

import { getMessage } from "CONFIG/i18n";

import "./PdfTron.less";

// Constants 
import { CANVAS_SOURCE, COMPONENT_IN_FOCUS, CANVAS_TYPES } from "../../Utils/Constants";
import { socketMessage } from "WEBSOCKET/constants";
import { isAndroidOrIos } from "../../Utils/Utility";
import { setPDFWebviewerInstance } from "../../Utils/Utility";

class PdfTron extends Component {

  constructor(props) {
    super(props);
    this.instance = null;
    this.mouseLeftDown = false;
    this.selectedAnnotations = null;
    this.loadWebviewerTimeout = null;
  }

  getElementsToDisable = () => {
    let elementsToDisable = [
      'printButton',
      'fullscreenButton',
      'themeChangeButton',
      'toolbarGroup-Shapes',
      'toolbarGroup-Edit',
      'rubberStampToolButton',
      'stampToolButton',
      'fileAttachmentToolButton',
      'freeTextToolButton2',
      'freeTextToolButton3',
      'freeTextToolButton4',
      'rectangleToolButton',
      'rectangleToolButton2',
      'rectangleToolButton3',
      'rectangleToolButton4',
      'freeHandToolButton2',
      'freeHandToolButton3',
      'freeHandToolButton4',
      'squigglyToolButton',
      'squigglyToolButton2',
      'squigglyToolButton3',
      'squigglyToolButton4',
      'strikeoutToolButton',
      'strikeoutToolButton2',
      'strikeoutToolButton3',
      'strikeoutToolButton4',
      'stickyToolButton',
      'stickyToolButton2',
      'stickyToolButton3',
      'stickyToolButton4',
      'underlineToolButton',
      'underlineToolButton2',
      'underlineToolButton3',
      'underlineToolButton4',
      'highlightToolButton2',
      'highlightToolButton3',
      'highlightToolButton4',
      'selectToolButton',
      'searchButton',
      'toggleNotesButton',
      'zoomOutButton',
      'zoomInButton',
      'thumbnailControl',
      'documentControl',
      'annotationCommentButton',
      'viewControlsButton',
      'menuButton',
      'richTextUnderlineButton',
      'richTextStrikeButton',
      'copyTextButton',
      'textUnderlineToolButton',
      'textSquigglyToolButton',
      'textStrikeoutToolButton',
      'calloutToolGroupButton',
      'opacitySlider'
    ]

    if(this.props.componentInFocus !== COMPONENT_IN_FOCUS.CANVAS)
      elementsToDisable.push(...['header','toolsHeader', 'pageNavOverlay'])
    
    return elementsToDisable;
  }

  loadWebviewer = () => {
    console.log("............ in loadWebViewer");
    WebViewer(
      {
        licenseKey: this.props.pdftronKey,
        path: pdfTronLibPath,
        // TODO: Remove below commentted code once testing is done
        // initialDoc: this.props.activeCanvasData.fileUrl 
        //   ? APP_CONFIG.baseUrl + this.props.activeCanvasData.fileUrl 
        //   : APP_CONFIG.baseUrl + this.props.logoUrl,
        preloadWorker: 'pdf',
        css: '/lib/PDFTron/webviewer.css',
        disabledElements: this.getElementsToDisable()
        // enableReadOnlyMode: true,
        // fullAPI: true,
        // isReadOnly: this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS 
        //   ? false
        //   : true,
      },
      document.getElementById("webviewer"),
    ).then((instance) => {
      this.instance = instance;
      const { docViewer, annotManager } = instance;

      let colors= ['#FF0000', '#0000FF','#000000'];

      instance.iframeWindow.addEventListener('loaderror', (err) => {
        this.props.hideSpinner();
        console.error(`Error in loading document : ${JSON.stringify(err)}`);
      });

      this.props.activeCanvasData.fileUrl &&
      this.instance.loadDocument(this.props.activeCanvasData.fileUrl,
        { filename: this.props.activeCanvasData.originalFileName });

      // disable text selection when canvas is not in focus
      // this.instance.Tools.Tool.ENABLE_TEXT_SELECTION = this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS 
      //   ? true
      //   : false

      this.enableDisableLeftPanel();

      this.resetAnnotations();

      this.enableDisableDownload();

      this.enableDisableElementsOnAllowEdit();

      this.disableHotKeys();

      // use a different set of colors for the freetext, signature and free hand tool.
      instance.setColorPalette({
        toolNames: [instance.Tools.ToolNames.FREEHAND, instance.Tools.ToolNames.SIGNATURE],
        colors: colors
      });

      // Add transparency for text
      let freeTextColors = [...colors]
      freeTextColors.push('transparency');

      instance.setColorPalette({
        toolNames: [instance.Tools.ToolNames.FREETEXT],
        colors: freeTextColors
      });

      // This will disable features
      this.instance.disableFeatures([this.instance.Feature.Print,this.instance.Feature.ThumbnailMultiselect, this.instance.Feature.Search]);
      
      // set language
      instance.setLanguage(this.props.language);

      // event triggered when document is loaded
      docViewer.on('documentLoaded', () => {
        try {
          console.log("documentLoaded...........");

          if(!this.isPdfPresent()) {
            return;
          }
  
          // set pdf instance to access from whiteboard
          // this should be set after document is loaded because operations on document are allowed only after that
          setPDFWebviewerInstance(instance);
  
          // set current page of pdf
          docViewer.setCurrentPage(this.props.activeCanvasData.activePage);
          
          // this.drawAnnotations();
          this.setLayoutModeSinglePage();
          this.disablePageNavigation();
        }catch(error) {
          console.log("Error in document loaded: ", error);
          toastr.error("INTERNAL_SERVER_ERROR");
          this.props.hideSpinner();
        }
      });

      docViewer.on('pageNumberUpdated', (pageNumber) => {
        console.log("pageNumberUpdated.............");
        (this.props.isPresenter) &&
        this.props.changePdfPage(pageNumber);
      })

      docViewer.on('zoomUpdated', (zoom) => {
        console.log('zoomUpdated', zoom);
      })
      
      docViewer.on('mouseLeftDown', evt => {
        console.log("mouseLeftDown.............");

        //Re-direction of URL and Page is restricted at user side .When user is not a presenter but has edit permission,
        // user can add annotation and linked URL or Page but can not navigate.
        let webViewer = document.getElementById("webviewer");
        let iframe = webViewer.getElementsByTagName("iframe")[0];
        let link = iframe.contentWindow.document.querySelectorAll("span.link");
        link.forEach((linkElement) => {
          linkElement.style.pointerEvents = this.props.isPresenter ? null : 'none'
        });
        this.mouseLeftDown = true;
      });

      docViewer.on('mouseLeftUp', evt => {
        console.log("mouseLeftUp.............");
        this.mouseLeftDown = false;
      });

      docViewer.on('mouseMove', evt => {
        console.log("mouseMove");
        // Send modify object message when annotations are moved or resized (to show moving effect at other users end) 
        // drag: mouse down should be true and annotations must be selected
        if (this.selectedAnnotations && this.mouseLeftDown) {
          _.forEach(this.selectedAnnotations, (annotation) => {
            annotManager.exportAnnotations({
              annotList: [annotation],
              links: true
            }).then(annotData => { 
  
              // Construct canvas object
              let canvasObject = {};
              canvasObject.objectId=annotation.Id
              canvasObject.objectType=annotation.Subject || "xfdf";
              canvasObject.uniqueId=this.props.uniqueId;
              canvasObject.canvasSource=CANVAS_SOURCE.PDF;
              canvasObject.canvasData={xfdfString: annotData}
  
              // send modify object web socket message
              this.props.sendModifyObjectMessage(canvasObject, socketMessage.subCategories.WHITEBOARD.subActions.REGULAR);
            }).catch(error => {
              console.log("Error in mouseMove ", error);
              toastr.error("INTERNAL_SERVER_ERROR");
              this.props.hideSpinner(); 
            })
          })
        }
      })

      // capture annotation change event (add, modify, delete)
      annotManager.on('annotationChanged', (annotations, action, { imported }) => {
        console.log("annotationChanged.............");

        // This will happen when importing the initial annotations from the server/individual changes from other users/fields from inside document
        if (imported) {
          // Set form fields readonly if the users don't have edit permission
          if(this.props.uniqueId !== this.props.organiser && !this.props.allowEdit) {
            this.setFormFieldsReadonly(annotations, true);
          }
          return;
        }
        
        if (action === 'add') { 
          console.log("Add annotations");
          annotManager.exportAnnotations({
            annotList: annotations,
            links: true
          }).then(annotData => { 
            // Construct canvas object
            let canvasObject = {};
            canvasObject.objectId=annotations[0].Id
            canvasObject.objectType=annotations[0].Subject || "xfdf";
            canvasObject.uniqueId=this.props.uniqueId;
            canvasObject.canvasSource=CANVAS_SOURCE.PDF;
            canvasObject.canvasData={xfdfString: annotData}
  
            // send add object web socket message
            this.sendAddObjectMessage(annotations[0].Id, annotations[0].Subject, annotData, annotations[0].pageNumber);
          }).catch(error => {
            console.log("Error in annotationChanged add ", error);
            toastr.error("INTERNAL_SERVER_ERROR");
            this.props.hideSpinner(); 
          })
        } else if (action === 'modify') {
          console.log("Modify annotations");
          // multiple objects can be modified at a time
          _.forEach(annotations, (annotation) => {
            annotManager.exportAnnotations({
              annotList: [annotation],
              links: true
            }).then(annotData => {
              // send update object web socket message
              this.sendUpdateObjectMessage(annotation.Id, annotation.Subject, annotData);
            }).catch(error => {
              console.log("Error in annotationChanged modify ", error);
              toastr.error("INTERNAL_SERVER_ERROR");
              this.props.hideSpinner(); 
            })
          })
        } else if (action === 'delete') { 
          console.log("delete annotations.............");
          // objectId is an array because multiple annotations can be deleted at a time
          var objectId = [];
          annotations.forEach((annotation) => {
            objectId.push(annotation.Id)
          })
          // send delete object web socket message
          this.props.sendDeleteObjectMessage(objectId, socketMessage.subCategories.WHITEBOARD.subActions.REGULAR);
        }
      });

      /**
       * custom event to add and modify addnotations on webviewer 
       */
      annotManager.on('addAnnotation', (xfdfString, objectType) => {
        console.log("addAnnotation.............");
        if(objectType === 'fieldValue') {
          const fieldManager = annotManager.getFieldManager();
          const field = fieldManager.getField(xfdfString.name);
          field && field.setValue(xfdfString.value);
        } else {
          annotManager.importAnnotations(xfdfString).then(importedAnnotations => {
            console.log("in importAnnotations")
          }).catch(error => {
            console.log("Error in importAnnotations ", error)
            toastr.error("INTERNAL_SERVER_ERROR");
            this.props.hideSpinner(); 
          });
        }
      })

      /**
       * custom event to delete annotations on webviewer 
       */
      annotManager.on('deleteAnnotation', objectId => {
        console.log("deleteAnnotation.............");
        var deleteCommand = '';
        let objectIdList = JSON.parse(objectId);
        if (Array.isArray(objectIdList) && objectIdList.length > 0) {
          objectIdList.forEach((id) => {
            if(annotManager.getAnnotationById(id)) {
              deleteCommand = deleteCommand+'<id>' + id + '</id>'
            }
          });
          if(deleteCommand != '') {
            deleteCommand = '<delete>' + deleteCommand + '</delete>';
            annotManager.importAnnotCommand(deleteCommand).then(importedAnnotations => {
              console.log("in importAnnotCommand")
            }).catch(error => {
              console.log("Error in importAnnotCommand ", error)
              toastr.error("INTERNAL_SERVER_ERROR");
              this.props.hideSpinner(); 
            });
          }
        }
      })

      annotManager.on('fieldChanged', (field, value) => {
        console.log("fieldChanged.............");
        let currentPage = docViewer.getCurrentPage();

        let canvasData = _.values(this.props.activeCanvasData.canvasData[currentPage]).filter(object => 
          object.objectType === "fieldValue");

        // check if we already have entry for this field in reducer
        let existingFieldObject = _.filter(canvasData, object => 
          object.canvasObject.canvasData.xfdfString.name === field.name
        );

        if(existingFieldObject.length > 0) {
          // modify object if it already exists in reducer and value is not same 
          // if value is same no need to send any WS call 
          if(existingFieldObject[0].canvasObject.canvasData.xfdfString.value !== value)
            this.sendUpdateObjectMessage(existingFieldObject[0].canvasObject.objectId, 'fieldValue', {name: field.name, value: value})
        } else {
          // add object if the field value is not there in reducer (ie it is added for the first time)
          let objectId = 'fieldValue' + new Date().getTime() + this.props.uniqueId
          this.sendAddObjectMessage(objectId, 'fieldValue', {name: field.name, value: value})
        }        
      });

      // this event gets triggered when annotations which are already present on uploaded pdf are drawn 
      docViewer.on('annotationsLoaded', () => {
        try {
          console.log("annotationsLoaded...........");

          this.props.hideSpinner();
          if(!this.isPdfPresent()) 
            return;
          if(this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF) 
            return;
          // load annotations which are already present in reducer 
          // in case of refresh or canvas switch any modification done for an annotation object (already present on uploaded pdf)
          // should be applied again
          this.drawAnnotations();

          const annotations = annotManager.getAnnotationsList();

          let annotationsToDelete = [];
          let pdfAnnotations = this.getSortedCanvasData();
          if(pdfAnnotations.length > 0) {
            // if annotations are already present in reducer
            // delete annotations which are on pdf but not in the reducer 
            _.map(annotations, (annotation) => {
              if(this.props.activeCanvasData.canvasData[annotation.PageNumber]
                && !this.props.activeCanvasData.canvasData[annotation.PageNumber].hasOwnProperty(
                  annotation.Id) 
                && annotation.Subject !== "Widget") {
                annotationsToDelete.push(annotation.Id)
              }
            });

            if(annotationsToDelete.length > 0) {
              annotManager.trigger('deleteAnnotation', JSON.stringify(annotationsToDelete));
            }
            
          } else {
            // when we upload pdf which already has annotations on it, we need to add these annotations in reducer
            // and also store on server for late arrivals, to modify or delete these annotations 
            _.forEach(annotations, (annotation) => {
              annotManager.exportAnnotations({
                annotList: [annotation],
                links: true
              }).then(annotData => { 
                // send add object web socket message
                // this needs to be done because objects can modified / deleted even after download
                // TODO: add object should be called by the one who has uploaded pdf
                // issue -> presenter uploads pdf and before annotations get loaded at agents side presenter modifies/deletes an already added annotation the object gets added again 
                if ((this.props.organiser === this.props.uniqueId 
                  || this.props.isPresenter)
                  && annotation.Subject !== "Widget") {
                  this.sendAddObjectMessage(annotation.Id, 
                    annotation.Subject,
                    annotData,
                    annotation.PageNumber)
                }
              }).catch(error => {
                console.log("Error ", error);
                toastr.error("INTERNAL_SERVER_ERROR");
                this.props.hideSpinner(); 
              })
            })
          }
          // TODO: Remove below comment once testing is done - Display file name instead of thumbnail in canvas list for pdf
          // this.props.generateBlobAndUploadThumbnail();
        }catch(error) {
          console.log("Error in annotationsLoaded ", error)
          toastr.error("INTERNAL_SERVER_ERROR");
          this.props.hideSpinner(); 
        }  
      });

      docViewer.on('toolModeUpdated', (tool) => {
        console.log("toolModeUpdated.............");
        console.log('tool: ', tool);
      })

      annotManager.on('annotationSelected', (annotations, action) => {
        console.log("annotationSelected.............");

        // store objects when they are selected
        if (annotations) {
          this.selectedAnnotations = annotations;

          // TODO: Check this method call from all the events and refactor below code.
          // Check if below condition can be handled without using _.forEach
          this.instance.enableElements(['annotationStylePopup']);
          _.forEach(annotations, (annotation) => {

            // Disable empty style popup(as opacity is hidden, there are no other style elements on popup) for stamp only
            if(annotation["_xsi:type"]==="Stamp"){
              this.instance.disableElements(['annotationStylePopup']);
              return;
            }
          });
          
        } else {
          this.selectedAnnotations = null;
        }
      })
    }).catch(error => {
      console.error(`Webviewer failed with error: ${error}`);
      this.props.hideSpinner();
    });
  }

  isPdfPresent = () => {
    return (this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
      && this.props.activeCanvasData.fileUrl)
  }

  componentDidMount() {
    this.isPdfPresent() &&
    this.props.showSpinner();

    if(window.navigator && !window.navigator.onLine) {
      this.props.hideSpinner();
    } else {
      console.log("............ calling loadWebViewer from componentDidMount");
      this.loadWebviewer();
    }

    window.addEventListener('offline', () => {
      // if document is not loaded and network gets disconnected hide spinner
      this.props.hideSpinner();
    });

    window.addEventListener('online', () => {
      clearTimeout(this.loadWebviewerTimeout);
      this.loadWebviewerTimeout = setTimeout(() => {
        if(!this.instance) {
          console.log('load webviewer: ', this.instance);
          console.log("............ calling loadWebViewer from online");
          this.loadWebviewer();
        } 
        // Uncomment when webviewer is always loaded
        // else if (this.props.activeCanvasData.fileUrl) {
        //   console.log('load document: ', this.instance);
        //   this.instance.loadDocument(APP_CONFIG.baseUrl + this.props.activeCanvasData.fileUrl);
        // }
      },500)
    });

    //iOS doesn't fire 'beforeunload'
    this.unloadEventName = (isSafari && isIOS)? "pagehide" : "beforeunload";
    // bug fix 3256 - 
    // if we do not set webviewer instance in reducer to null rejoin container does not get mounted on refresh
    // due to which local session data is not cleared and  joinSession api is not called
    window.addEventListener(this.unloadEventName, () =>{
      setPDFWebviewerInstance(null);
    });
  }

  componentDidUpdate(prevProps,prevState) {
    // load new document 
    // console.log('this.props.activeCanvasData.fileUrl: ', this.props.activeCanvasData.fileUrl);
    if(this.props.activeCanvasData.fileUrl !== prevProps.activeCanvasData.fileUrl
      && this.props.activeCanvasData.fileUrl 
      && this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
      && this.instance) {
      
      this.props.showSpinner();

      this.resetAnnotations();

      this.enableDisableDownload();

      this.enableDisableElementsOnAllowEdit();

      setPDFWebviewerInstance(null);
      
      const annotHistoryManager = this.instance.docViewer.getAnnotationHistoryManager();
      annotHistoryManager.clear();
      this.instance.loadDocument(this.props.activeCanvasData.fileUrl,
        { filename: this.props.activeCanvasData.originalFileName });
    }
    
    // Handle allow edit
    /*
    * invoke edit only when focused component is canvas and
    * organizer changed or
    * allow edit permission changed or
    * focused component changed  
    */
    if((this.props.organiser && 
        ((this.props.organiser !== prevProps.organiser) || (this.props.uniqueId !== this.props.organiser && prevProps.allowEdit !== this.props.allowEdit))) || 
        (prevProps.componentInFocus !== this.props.componentInFocus)) {
      this.enableDisableElementsOnAllowEdit();
    }
    
    // when presenter changes disable navigation for all other users 
    if(this.props.isPresenter !== prevProps.isPresenter) {
      this.disablePageNavigation();
      this.enableDisableLeftPanel();
    }

    if(this.props.activeCanvasData.activePage !== prevProps.activeCanvasData.activePage
      && this.props.activeCanvasData.activePage > 0
      && this.instance) {
      if (!this.props.isPresenter) {
        // close annotationPopup(colorpalette,delete,link url) if presenter switches/change page number with arrows
        this.instance.closeElements([
          'annotationPopup'
        ]);
        // UNcomment if pdf thumbnail is to be uploaded on page navigation
        // this.props.generateBlobAndUploadThumbnail();
        this.instance.docViewer.setCurrentPage(this.props.activeCanvasData.activePage);
      }
    }

    // when canvas is not in focus tool bars should not be visible
    if(this.props.componentInFocus !== prevProps.componentInFocus) {
      this.enableDisableTools();
    }
    
    // set fit mode and recalculate height (as annotation bar changes) on orientation change 
    if(this.props.isLandscape !== prevProps.isLandscape) {
      if (this.instance) {
        setTimeout(() => {
          var FitMode = this.instance.FitMode;
          this.instance.setFitMode(FitMode.FitPage);
        }, 500)
      }
    }
  }

  /**
   * Enable / Disable elements for user based on allow edit value (true/false)
   */
  enableDisableElementsOnAllowEdit = () => {
    console.log("enableDisableElementsOnAllowEdit.............");
    try {
      if(this.instance) {
        let annotations = this.instance.annotManager.getAnnotationsList();
  
        if((this.props.uniqueId === this.props.organiser || this.props.allowEdit) && this.props.componentInFocus == COMPONENT_IN_FOCUS.CANVAS) {
          this.instance.setToolbarGroup('toolbarGroup-Annotate', false);
          
          this.instance.enableElements([
            'ribbons', // View/Annotate/Insert
            'toolsHeader'
          ]);
          
          this.setFormFieldsReadonly(annotations, false);
          this.instance.annotManager.setReadOnly(false);
          
        } else {
          this.instance.closeElements(['linkModal', 'signatureModal']);
  
          this.instance.disableElements([
            'ribbons', // View/Annotate/Insert
            'toolsHeader'
          ]);

          this.setFormFieldsReadonly(annotations, true);
          this.instance.annotManager.setReadOnly(true); // Set annotations to readonly
          
        }
  
        // Hide Shapes and Edit options always
        this.instance.disableElements([
          'toolbarGroup-Shapes',
          'toolbarGroup-Edit',
        ]);
  
        this.disableHotKeys();
      }
    } catch(error) {
      console.log("Error ", error);
      toastr.error("INTERNAL_SERVER_ERROR");
      this.props.hideSpinner(); 
    } 
  }

  setFormFieldsReadonly = (annotations, isReadOnly) => {

    const { Annotations } = this.instance;

    _.forEach(annotations, (annotation) => {
      if (annotation instanceof Annotations.WidgetAnnotation) {
        annotation.fieldFlags.set('ReadOnly', isReadOnly);
      }
    });
  }

  disableHotKeys = () => {
    console.log("disableHotKeys.............");
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_SHIFT_EQUAL); // Rotate clockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_SHIFT_EQUAL); // Rotate clockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_SHIFT_MINUS); // Rotate counterclockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_SHIFT_MINUS); // Rotate counterclockwise
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.CTRL_F); // Search
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.COMMAND_F); // Search
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.A); // Create arrow tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.C); // Create callout tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.I); // Create stamp tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.L); // Create line tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.N); // Create sticky tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.O); // Create ellipse tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.R); // Create rectangle tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.G); // Create text squiggly tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.K); // Create text strikeout tool
    this.instance.hotkeys.off(this.instance.hotkeys.Keys.U); // Create text underline tool
  }

  enableDisableDownload = () => {
    console.log("enableDisableDownload.............");
    // Hide download option if Download module is not selected
    if(this.instance) {
      if(this.props.isDownloadModuleSelected) {
        this.instance.enableElements(['menuButton']);
      } else {
        this.instance.disableElements(['menuButton']);
      }
    }
  }

  // Set default values to annotations
  resetAnnotations = () => {
    console.log("resetAnnotations.............");
    const { docViewer, Annotations } = this.instance;

    this.instance.setToolbarGroup('toolbarGroup-Annotate', false);

    // set default color and size
    docViewer.getTool(this.instance.Tools.ToolNames.FREETEXT).setStyles(currentStyle => ({
      StrokeThickness: 0,
      StrokeColor:  new Annotations.Color(0, 0, 0),
      TextColor: new Annotations.Color(0, 0, 0),
      FillColor: new Annotations.Color(0, 0, 0, 0),
      FontSize: '20pt',
      Opacity: 1
    }));

    docViewer.getTool(this.instance.Tools.ToolNames.SIGNATURE).setStyles(currentStyle => ({
      StrokeColor: new Annotations.Color(0, 0, 0)
    }));

    docViewer.getTool(this.instance.Tools.ToolNames.HIGHLIGHT).setStyles(currentStyle => ({
      StrokeColor: new Annotations.Color(255, 205, 69)
    }));

    docViewer.getTool(
      this.instance.Tools.ToolNames.FREEHAND
    ).setStyles(currentStyle => ({
      StrokeThickness: 2, // Default value
      StrokeColor: new Annotations.Color(0, 0, 0),
      Opacity: 1
    }));
  }

  drawAnnotations = () => {
    console.log("drawAnnotations.............");
    // load previously drawn annotations 
    let pdfAnnotations = this.getSortedCanvasData();
    _.map(pdfAnnotations, (annotationObj) => {
      // add annotation using xfdf string
      this.instance &&
      this.instance.annotManager.trigger('addAnnotation', 
        annotationObj.canvasObject.canvasData.xfdfString,
        annotationObj.canvasObject.objectType);
    })
  }

  getSortedCanvasData = () => {
    const { activeCanvasData } = this.props;
    if (activeCanvasData) {
      let objectArray = [];
      let pdfPages = _.keys(activeCanvasData.canvasData);
      
      _.forEach(pdfPages, (page) => {
        objectArray = objectArray.concat(_.values(
          activeCanvasData.canvasData[page]
        ));
      })
      
      // sort canvas data on basis of updated time
      objectArray = _.orderBy(objectArray, ["updatedTime"], ["asc"]);

      return objectArray;
    }
  };

  disablePageNavigation = () => {
    try{
      console.log("disablePageNavigation.............");
      // Handled null condition to fix an error - cannot read property "enableFeatures" of null
      if(!this.instance) return;

      let webViewer = document.getElementById("webviewer");

      // Handled null condition to fix an error - cannot read property "getElementsByTagName" of null
      if(!webViewer) return;

      let iframeInstance = webViewer.getElementsByTagName("iframe");

      // TODO: Remove following logs once testing is done.
      console.log("WebViewer = ", webViewer);
      console.log("iframe = ", iframeInstance);
      console.log("iframe[0] = ", iframeInstance[0]);

      let iframe = webViewer.getElementsByTagName("iframe")[0];
      let pageNavigation = iframe.contentWindow.document.querySelector('[data-element="pageNavOverlay"]');
      console.log("pageNavigation = ", pageNavigation);
      
      !isAndroidOrIos() &&
      this.instance.disableFeatures([this.instance.Feature.PageNavigation]);
      
      if (!pageNavigation) return;

      let previousPage = pageNavigation.getElementsByClassName('side-arrow-container')[0];
      let nextPage = pageNavigation.getElementsByClassName('side-arrow-container')[1];
      
      if (!previousPage || !nextPage) return;

      // disable page navigation for all users except presenter
      if(this.props.isPresenter) {
        console.log("if user is a presenter");
        console.log("previousPage = ", previousPage);
        console.log("nextPage = ", nextPage);
        pageNavigation.style.padding = "0";
        previousPage.style.display='flex';
        nextPage.style.display='flex';
        // Enable navigation through pages using mouse wheel, arrow up/down keys and the swipe gesture.
        // this will only be anabled for android 
        // enabling it for desktop scrolls page also while navigating page
        isAndroidOrIos() &&
        this.instance.enableFeatures([this.instance.Feature.PageNavigation]);
      } else {
        console.log("if user is not a presenter")
        console.log("previousPage = ", previousPage);
        console.log("nextPage = ", nextPage);
        pageNavigation.style.padding = "0 0.7em";
        previousPage.style.display='none';
        nextPage.style.display='none';
        // Disable navigation through pages using mouse wheel, arrow up/down keys and the swipe gesture.
        isAndroidOrIos() &&
        this.instance.disableFeatures([this.instance.Feature.PageNavigation]);
      }
    } catch(error){
      console.log("Error ", error);
      toastr.error("INTERNAL_SERVER_ERROR");
      this.props.hideSpinner(); 
    } 
  }

  enableDisableTools = () => {
    console.log("enableDisableTools.............");
    // issue fix - App crashes with error cannot read property "enableElements" of null
    if(!this.instance) return;

    // Set fit mode to fit page on swapping layouts
    var FitMode = this.instance.FitMode;
    this.instance.setFitMode(FitMode.FitPage);

    // Disable options when canvas is not in focus
    if(this.props.componentInFocus === COMPONENT_IN_FOCUS.AUDIO_VIDEO || this.props.componentInFocus === COMPONENT_IN_FOCUS.COBROWSE) {
      this.instance.setToolbarGroup('toolbarGroup-Annotate', false);
      this.instance.disableElements(['header','toolsHeader', 'pageNavOverlay', 'leftPanelButton', 'menuButton']);
      this.instance.closeElements([ 'leftPanel', 'menuOverlay', 'zoomOverlay', 'signatureModal' ]);
      document.getElementById("webviewer").style.top = 'unset';
    } else {
      this.instance.enableElements(['header','toolsHeader', 'pageNavOverlay', 'signatureModal']);
      this.enableDisableLeftPanel();
      this.enableDisableDownload();
      this.disablePageNavigation();
      document.getElementById("webviewer").style.top = 0;
    }
  }

  setLayoutModeSinglePage = () => {
    console.log("setLayoutModeSinglePage.............");
    if(this.instance) {
      this.instance.setLayoutMode(this.instance.LayoutMode.Single);
    }
  }

  /**
   * left panel of pdftron has options for 
   * navigation (which should only be enabled for presenter),
   * delete page and page rotation (which is not supported)
   */
  enableDisableLeftPanel = () => {
    console.log("enableDisableLeftPanel.............");
    let webViewer = document.getElementById("webviewer");

    // Handled null condition to fix an error - cannot read property "getElementsByTagName" of null
    if(!webViewer) return;

    let iframe = webViewer.getElementsByTagName("iframe")[0];
    let divider = iframe && iframe.contentWindow.document.querySelector('[data-element="header"]') 
      ? iframe.contentWindow.document.querySelector('[data-element="header"]').getElementsByClassName("divider")[0]
      : null;

    if(!this.instance) return;

    if(this.props.isPresenter) {
      this.instance.enableElements([
        'leftPanelButton'
      ]);
      if(divider)
        divider.style.display = "block";
    } else {
      this.instance.closeElements([ 'leftPanel' ]);
      this.instance.disableElements([
        'leftPanelButton'
      ]);
      if(divider)
        divider.style.display = "none";
    }
  }

  sendAddObjectMessage = (annotationId, annotationType, object, pageNumber) => {
    console.log("sendAddObjectMessage.............");
    // Construct canvas object
    let canvasObject = {};
    canvasObject.objectId=annotationId;
    canvasObject.objectType=annotationType || "xfdf";
    canvasObject.uniqueId=this.props.uniqueId;
    canvasObject.canvasSource=CANVAS_SOURCE.PDF;
    canvasObject.canvasData={xfdfString: object}

    // send add object web socket message
    this.props.sendAddObjectMessage(canvasObject, socketMessage.subCategories.WHITEBOARD.subActions.REGULAR, pageNumber);
  }

  sendUpdateObjectMessage = (annotationId, annotationType, object) => {
    console.log("sendUpdateObjectMessage.............");
    // Construct canvas object
    let canvasObject = {};
    canvasObject.objectId=annotationId
    canvasObject.objectType=annotationType || "xfdf";
    canvasObject.uniqueId=this.props.uniqueId;
    canvasObject.canvasSource=CANVAS_SOURCE.PDF;
    canvasObject.canvasData={xfdfString: object}

    // send update object web socket message
    this.props.sendUpdateObjectMessage(canvasObject, socketMessage.subCategories.WHITEBOARD.subActions.REGULAR);
  }

  render() {
    return (
      <React.Fragment>
        <div id="webviewer" 
          style={{
            position: this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
              ? "relative"
              : "absolute",
            border: this.props.activeCanvasData.canvasType === CANVAS_TYPES.PDF
            && this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS
              ? "0.063em solid #cecfd0"
              : 0,
            height: this.props.activeCanvasData.canvasType !== CANVAS_TYPES.PDF
              ? "0"
              : "100%",
            pointerEvents: this.props.componentInFocus === COMPONENT_IN_FOCUS.CANVAS
              ? "auto"
              : "none",
          }}
        >
        </div>
      </React.Fragment>
    )
  }
}

export default withOrientationChange(PdfTron);
