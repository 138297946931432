import React, { Component } from "react";

import {
  Row,
  Col,
  Container,
  DropdownToggle,
  Button,
  InputGroup,
  Input
} from "reactstrap";
import uuid from "uuid";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';

// Custom Components
import UserDropdownModal from "./UserDropdownModal";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";
//Constant
import { SUPER_ADMIN_TABS } from "UTILS/Constants";
// import SettingsModal from "./SettingsModal";

// Styles
import "./Header.less";

// Strings
import { getMessage } from "CONFIG/i18n";

// Utilities
import {
  getUserNameAcronym,
  getUserNameForSelectedLanguage,
  getStringWithoutExtraSpaces,
} from "UTILS/Utility";

// Images
import logo from "ASSETS/Images/img_header_logo.png";
import ic_add_member from "ASSETS/Images/ic_add_organization.svg";
import ic_settings from "ASSETS/Images/ic_settings.svg";
import expand_button from "ASSETS/Images/expand-button16.png";
import { USER_ROLES } from "UTILS/Constants";
import AddQRCodeModal from "COMPONENTS/Admin/QRCodes/AddQRCodeModal";
import AddQRCodeSuccessModal from 'COMPONENTS/Admin/QRCodes/AddQRCodeSuccessModal';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserInfoModal: false,
      searchString: "",
      searchTimeout: null,
    };
    this.tooltipBoundaryElement = null;
  }

  getUserLogo = () => {
    return (
      <DropdownToggle className="dropdownToggleButton mr-2 pr-0" onClick={this.toggle} id="profile">
        {" "}
        <span className="card-text displayFlex alignItemsCenter justifyContentFlexCenter noPadding" key={uuid.v4()} >
          {getUserNameAcronym(getUserNameForSelectedLanguage(this.props.language, this.props.username))}
        </span>
        <CustomTooltip
          tooltipText={getMessage("PROFILE")}
          tooltipId="profile"
        />
      </DropdownToggle>
    );
  };

  toggle = () => {
    this.setState({
      showUserInfoModal: !this.state.showUserInfoModal
    });
  };

  closePopup = value => {
    this.setState({ showUserInfoModal: value });
  };
  handleSearch = e => {
    let searchData = e.target.value
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    if(this.state.searchString !== searchData)
      this.setState({
        searchString: searchData,
        searchTimeout: setTimeout(() => {
          this.props.handleSearchString(
            getStringWithoutExtraSpaces(searchData)
          );
        }, 1000)
      });

  };

  showModalOrPage = () => {
    if(this.props.role === USER_ROLES.SUPER_ADMIN) {
      this.props.gotoAddOrganization();
    } else {
      this.props.showAddUserModal();
    }
  }
  
  getTooltipBoundaryElement = () => {
    this.tooltipBoundaryElement = document.getElementsByClassName("superAdmin")[0];
  }

  componentDidMount() {
    this.getTooltipBoundaryElement();
  }

  getHeaderLogo = () => {
    if (this.props.role === USER_ROLES.SUPER_ADMIN) {
      return <img className="Orglogo" src={logo} />
    } else if (this.props.logo.loading) {
      return getMessage("LOADING");
    } else if (this.props.logo.url) {
      return <img className="Orglogo" src={this.props.logo.url} />
    }
  }

  render = () => {
    const isQRCodeLimitReached = this.props.qrLimit <= this.props.totalQRCodes ;
    let singleTitleHeader = (!isMobileOnly && 
    (this.props.title === getMessage("ADD_ORGANIZATION") || this.props.title === getMessage("EDIT_ORGANIZATION") || this.props.title === getMessage("SETTINGS")))
    return (
      <div className="superAdmin">
        {this.props.showAddQRCodeModal &&
          <AddQRCodeModal
            show={this.props.showAddQRCodeModal}
            onClosed={this.props.closeAddQRCodeModal}
            handleAddClick={this.props.handleAddClick}
          />
        }
        {this.props.showSuccessModal && this.props.newQRDetails.qrId &&
          <AddQRCodeSuccessModal
            show={this.props.showSuccessModal}
            onClosed={this.props.closeSuccessModal}
            qrCodeDetails={this.props.newQRDetails}
          />
        }
        <section className="header py-1">
          <Container className="pl-lg-0">
            <Row>
              <Col lg="4" md={singleTitleHeader ? "4" : "2"} xs="12" className="noPadding perfectCenter">
                <div className="logoHeader displayFlex">
                  {this.getHeaderLogo()}
                </div>
                <Col className="title " >
                  <label 
                    className={"nameEllipsis " + (!singleTitleHeader ? "setWidthForHeader" : "") }
                    id="organizationTitle">
                    {this.props.title}
                    <CustomTooltip
                      tooltipText={this.props.title}
                      tooltipId="organizationTitle"
                    />
                  </label>
                </Col>
              </Col>
              {(this.props.isSuperAdmin && this.props.isDashboard && this.props.currentActiveTab === SUPER_ADMIN_TABS.ORGANIZATIONS) &&
              (<Col className="noPadding perfectCenter ">
                <InputGroup className="alignItemsCenter">
                  <Input
                    className="py-2 form-control "
                    type="text"
                    id="example-search-input"
                    onChange={this.handleSearch}
                    value={this.state.searchString}
                    placeholder={ getMessage("SEARCH_ORGANIZATIONS_PLACEHOLDER")}
                  />
                </InputGroup>
              </Col>)
              }
              <Col className="displayFlex justifyContentFlexEnd alignItemsCenter noPadding">
                
                {this.props.showHeaderButton && <div className="borderRight">
                  <Button
                    className= {this.props.isSuperAdmin 
                      ? "customBtn headerAddButton"
                      : "customBtn headerAddButton addUserButton"
                    }
                    onClick={this.showModalOrPage}
                  >
                    <img src={ic_add_member} className="button "/>
                    {this.props.headerButtonText}                                     
                  </Button> 
                  { (this.props.isSuperAdmin && this.props.isDashboard && this.props.currentActiveTab === SUPER_ADMIN_TABS.SYSTEM_ANNOUNCEMENTS) &&
                    <Button
                      className="customBtn ml-2"
                      onClick={() => 
                        this.props.toggleAddAnnouncementModal(true)
                      }>
                      <img src={ic_add_member} className="button "/>
                      {getMessage("ADD_ANNOUNCEMENT")}
                    </Button>}
                  { this.props.isAdmin && 
                    <Button 
                      id="target" 
                      className="mergedCustomBtn headerAddButton"
                      onClick = {this.props.showAddUserDropdown}
                    > 
                      <img src={expand_button} className="button"/>
                    </Button>
                  }
                  {this.props.isAdmin && this.props.isQRCodeEnabled &&
                    <span>
                      <Button
                        onClick={isQRCodeLimitReached ? false : this.props.openAddQRCodeModal}
                        className={"buttons customBtn ml-2 "+`${isQRCodeLimitReached && 'btn-disabled'}`}
                      >
                        <img src={ic_add_member} className="button " />
                        {getMessage("ADD_QR_CODE")}
                      </Button>
                    </span>
                  } 
                </div>  
                }
                {
                  this.props.showSettingsOption ?
                    <div className="settingsImg cursorPointer">
                      <img
                        src={ic_settings}
                        onClick={this.props.gotoAdminSettings}
                        id="settings"
                      />
                      <CustomTooltip
                        tooltipText={getMessage("SETTINGS")}
                        tooltipId="settings"
                      />
                    </div> : null
                }
                <UserDropdownModal
                  show={this.state.showUserInfoModal && this.props.showUserDropDownModal}
                  userIcon={this.props.showUserDropDownModal?this.getUserLogo():null}
                  toggle={this.toggle}
                  handleLogoutClick={this.props.handleLogoutClick}
                  showAboutOrgModal={this.props.showAboutOrgModal}
                  isSuperAdmin={this.props.isSuperAdmin}
                  userProfileData={this.props.userProfileData}
                  handleUpdateProfileClick={this.props.handleUpdateProfileClick}
                  toggleChangePasswordModal={this.props.toggleChangePasswordModal}
                  showChangePasswordModal={this.props.showChangePasswordModal}
                  role={this.props.role}
                  isSSOEnabled={this.props.isSSOEnabled}
                  isSSOLogin={this.props.isSSOLogin}
                  language={this.props.language}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  };
}

export default withRouter(Header);
