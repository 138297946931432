import { ACTIONS } from "UTILS/Constants";

const initialState = {
  micSelected:"",
  cameraSelected:"",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.TEST_PAGE.MIC_SELECTED:
      return {
        ...state,
        micSelected: action.mic
      }
    case ACTIONS.TEST_PAGE.CAMERA_SELECTED:
      return {
        ...state,
        cameraSelected: action.camera
      }
    default:
      return state;
  }
}