import React, { Component } from "react";
import { getMessage } from "CONFIG/i18n";
import moment from "moment";
import InputMask from 'react-input-mask';

//ASSETS
import ic_up_arrow from "ASSETS/Images/ic_uparrow.svg";
import ic_down_arrow from "ASSETS/Images/ic_down_arrow.svg";

//UTILITY
import { REGEX } from "UTILS/Constants";
import { isTimeValid } from "UTILS/regexValidations";

//Stylsheet
import "./TimePicker.less";

class TimePicker extends Component{
  constructor(props){
    super(props)
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    let time = moment().format('HH:mm').split(/:| /);
    this.state = {
      hours: time[0],
      minutes: time[1],
      chooseTime: false,
      time
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.value !== this.props.value) {
      this.resetClock()
    }
  }
 
  resetClock = () =>{
    let time = this.props.value ? 
      this.props.value.split(/:| /) :
      moment().format('HH:mm').split(/:| /);
    this.setState({
      hours: ("0" + time[0]).slice(-2),
      minutes: ("0" + time[1]).slice(-2),
      time
    })
  }

  /**
   * close timepicker if clicked on outside of element
   */
  handleClickOutside = (event) => {
    //Focus should be added explicitly for text fields for IE browser.
    //WAAG-4150-IE :Cursor is placed in front of 'Enter meeting name' helper text on schedule a meeting page.
    if (event.clientX + 15 <= window.innerWidth && this.wrapperRef && !this.wrapperRef.current?.contains(event.target) && this.state.chooseTime) {
      this.toggleTimePicker()
    }
  }

  incrementHour=()=>{
    let hours = parseInt(this.state.hours);
    hours += 1;

    /* Hour sets to 0 if it is equal to or greater than 24 */
    if(hours > 24 || (hours === 24 && parseInt(this.state.minutes) > 0)) {
      hours = "00";
    }
    if( hours.toString().length === 1 ) {
      hours = "0" + hours;
    }
    this.setState({
      hours
    },()=>this.setClock());
  }

  incrementMinute = () => {
    let minutes = parseInt(this.state.minutes);
    minutes += 1;
    
    /*If selected hour is 24 and minute is 1 then hour sets to 0*/
    if(parseInt(this.state.hours) === 24 && minutes === 1) {
      this.setState({
        hours: "00"
      },()=>this.setClock())
    }

    if(minutes > 59) {
      minutes = 0;
    }
    if( minutes.toString().length === 1 ) {
      minutes = "0" + minutes;
    }
    this.setState({
      minutes
    },()=>this.setClock());
  }

  decrementHour = () => {
    let hours = parseInt(this.state.hours);
    hours -= 1;

    // if minutes are selected before hours and minutes are greater than 0 
    // then available hours are 0 to 23
    // owthereise available hours are 0 to 24    
    if(hours === -1 && parseInt(this.state.minutes) > 0){
      hours = 23
    } else if(hours < 0 ) {
      hours = 24;
    }
    if( hours.toString().length === 1 ) {
      hours = "0" + hours;
    }
    this.setState({
      hours
    },()=>this.setClock());
  }

  decrementMinute = () => {
    let minutes = parseInt(this.state.minutes);
    minutes -= 1;
    if(minutes < 0 ) {
      minutes += 60;
    }

    //if selected hour is 24 and minutes are 59 then hour is set to 23
    if(minutes === 59 && parseInt(this.state.hours) === 24){
      this.setState({hours: "23"})
    }

    if( minutes.toString().length === 1 ) {
      minutes = "0" + minutes;
    }
    this.setState({
      minutes
    },()=>this.setClock());
  }

  setTime = () => {
    this.props.setTime(this.state.hours + ":" + this.state.minutes );
    this.toggleTimePicker();
  }

  toggleTimePicker = () => {
    this.setState({
      chooseTime : !this.state.chooseTime
    });
    this.resetClock();
  }

  changeTime = (event) => {
    let time= event.target.value.split(/:| /);
    ((!time[0].includes('_') && parseInt(time[0]) < 25) || time[0].includes('_')) &&
      this.setState({
        time: event.target.value,
        hours: time[0].includes('_') ? this.state.hours : time[0],
        minutes: time[1].includes('_') ? this.state.minutes : time[1],
        timeError: ""
      }) 

    //when hour updates to 24 and minute is other than 0 then hour sets to 00
    if( !time[0].includes('_') && !time[1].includes('_') && parseInt(time[0])===24 && parseInt(time[1])!==0)
    {
      this.setState({hours: "00"} , () => {this.setClock()});
    }
  }

  setClock = () => {
    this.setState({
      time: this.state.hours+":"+this.state.minutes
    })
  }

  render() {
    return(
      !this.state.chooseTime ?
        <div className="timePickerWrapper">
          <input
            value={this.props.value} 
            placeholder={this.props.placeholder}
            onFocus={this.toggleTimePicker}
            onKeyDown={this.props?.onKeyPress ? (e) => this.props.onKeyPress(e) : () => { }}
            className={this.props.value ? 
              "rounded-pill w-100 border formControlName form-control text-center":
              "rounded-pill w-100 border formControlName form-control"}
            readOnly/>
        </div>
        :
        <div ref={this.wrapperRef} className="timePickerWrapper">
          <InputMask 
            className="rounded-pill w-100 border formControlName form-control text-center"
            mask="Hh:Mm"
            value={this.state.time}
            formatChars={REGEX.TIME_PATTERN} 
            alwaysShowMask
            autoFocus={true}
            onChange={this.changeTime}
            onKeyPress={this.props?.onKeyPress ? (e) => this.props.onKeyPress(e) : () => { }} />
          <div className="timepicker container position-absolute card card-body">
            <div className="d-flex justify-containt-center">
              <div className="col-6 d-flex flex-column">
                <span className="arrow d-flex justify-content-center" onClick={this.incrementHour}>
                  <img src={ic_up_arrow} />
                </span>
                <span className="d-flex justify-content-center">{this.state.hours}</span>
                <span className="arrow d-flex justify-content-center" onClick={this.decrementHour}>
                  <img src={ic_down_arrow} />
                </span>
              </div>
              <div className="col-6 d-flex flex-column">
                <span className="arrow d-flex justify-content-center" onClick={this.incrementMinute}>
                  <img src={ic_up_arrow} />
                </span>
                <span className="d-flex justify-content-center">{this.state.minutes}</span>
                <span className="arrow d-flex justify-content-center" onClick={this.decrementMinute}>
                  <img src={ic_down_arrow} />
                </span>
              </div>
            </div>  
            <div className="row pt-2">
              <div className="col">
                <button className="btn w-100 rounded-pill setTimeBtn" onClick={this.setTime}>
                  {getMessage("SET_TIME")}
                </button> 
              </div>
            </div> 
          </div>
        </div>
        
    )
  }
}

export default TimePicker;