import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';
import { toast } from 'react-toastify';

import AppEmulationDeviceListModal from "./AppEmulationDeviceListModal";
import AppEmulationIFrame from "./AppEmulation";
import EndSessionConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import Spinner from "COMPONENTS/Spinner/Spinner";

import { getMessage } from "CONFIG/i18n";
import { USER_ROLES, ERROR } from "UTILS/Constants";

import { getCustomerId, selectUserName } from "COMPONENTS/MainLayout/selectors";
import { selectSessionKey, selectUniqueId } from "../../Routes/selectors";
import { selectUserRole } from "../Whiteboard/selectors";
import {
  getAppEmulationPermissionReply,
  getAppEmulationSessionActive,
  getAppEmulationIFrameUrl,
  getAppEmulationSelectedDeviceInfo,
} from "./AppEmulationSelector";
import { appEmulationAction } from "CONFIG/ActionFactory";
import { isIOS, isSafari } from "react-device-detect";

class AppEmulationContainer extends Component {
  constructor(props) {
    super(props);
    this.iFrame = React.createRef();
    this.state = {
      showIFrameModal: false,
      appEmulationUrl: null,
      showEndSessionConfirmationModal: false,
      spinnerVisibility: false
    };
  }
  componentDidMount() {
    let event = (isSafari && isIOS)? "pagehide" : "beforeunload";
    window.addEventListener(event, this.unload);

  }

  unload = e => {
    if (this.iFrame.current) {
      this.iFrame.current.postMessageForWindow();
    }
    if(this.state.showEndSessionConfirmationModal){
      this.setState({showEndSessionConfirmationModal: false})
    }
  };

  handleSubmitClick = (data, errorCallBack) => {
    data.userName = this.props.userName;
    data.sessionKey = this.props.sessionKey;
    data.uniqueId = this.props.uniqueId;
    data.customerId = this.props.customerId;
    data.lang = this.props.selectedLangauge;
    appEmulationAction.startAppEmulationSession(data).then(
      response => {
        this.props.toggle();
        this.setState({
          appEmulationUrl: response.data.appEmulationUrl,
          showIFrameModal: true
        }, this.props.setAppEmulationStatus(true));
        this.props.toggle();
        errorCallBack(false);
        // appEmulationAction.setAppEmulationIFrameUrl(response.data.appEmulationUrl);
        appEmulationAction.setAppEmulationSessionStatusAction(true);
      },
      error => {
        errorCallBack(error);
      }
    );
  };

  joinSession = () => {
    let data = {
      userName: this.props.userName,
      sessionKey: this.props.sessionKey,
      uniqueId: this.props.uniqueId,
      customerId: this.props.customerId
    };

    appEmulationAction.joinAppEmulationSession(data).then(
      response => {
        this.setState({
          appEmulationUrl: response.data.appEmulationUrl,
        });
      },
      error => {
        toastr.error(error.code);
      }
    );
  };

  endSession = () => {
    let data = {
      sessionKey: this.props.sessionKey,
      uniqueId: this.props.uniqueId
    };
    appEmulationAction.endAppEmulationSession(data).then(
      response => {
        this.setState({
          showIFrameModal: false,
          appEmulationUrl: null
        }, this.props.setAppEmulationStatus(false));
      },
      error => {
        toastr.error(error.code);
      }
    );
    appEmulationAction.endAppEmulationSessionAction();
    appEmulationAction.setAppEmulationSessionStatusAction(false);
  };

  leaveSession = () => {
    let data = {
      sessionKey: this.props.sessionKey,
      uniqueId: this.props.uniqueId
    };
    appEmulationAction.endAppEmulationSession(data).then(
      response => { this.setState({showEndSessionConfirmationModal: false })},
      error => {
        toastr.error(error.code);
      }
    );
    appEmulationAction.updateAppEmulationPermissionReply(false);
    appEmulationAction.updateAppEmulationPermissionRequest(false);
  };

  toggleConfirmationPopUp = flag => {
    this.setState({ showEndSessionConfirmationModal: flag });
  };

  sessionEndAndLeave = () => {
    if (this.iFrame.current) {
      let postMessageFlag = this.iFrame.current.postMessageForWindow();
      if (postMessageFlag) {
        USER_ROLES.AGENT === this.props.role ? this.endSession() : setTimeout(this.leaveSession, 500);
      }
      this.toggleConfirmationPopUp(false);
    }
  };

  componentDidUpdate(prevProps){
    if(this.props.appEmulationPermisssionReply !== prevProps.appEmulationPermisssionReply) {

      if(prevProps.appEmulationPermisssionReply && !this.props.appEmulationPermisssionReply) {
        this.setState({
          showEndSessionConfirmationModal: false,
          appEmulationUrl: null
        });
      }

      if(this.props.role === USER_ROLES.USER && this.props.appEmulationPermisssionReply) {
        this.joinSession();
      }
    }
  }

  componentWillUnmount() {
    appEmulationAction.updateAppEmulationPermissionReply(false);
    appEmulationAction.updateAppEmulationPermissionRequest(false);
    toast.dismiss();
  }

  render() {
    return (
      <>
      <Spinner showSpinner={this.state.spinnerVisibility} />
        {this.state.showEndSessionConfirmationModal &&(
            
          <EndSessionConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={() => {
              this.toggleConfirmationPopUp(false);
            }}
            handleYesClick={() => {
              this.sessionEndAndLeave();
            }}
            message={
              USER_ROLES.AGENT === this.props.role
                ? getMessage("END_APP_EMULATION_SESSION_CONFIRMATION_TEXT")
                : getMessage("LEAVE_APP_EMULATION_SESSION_CONFIRMATION_TEXT")
            }
          />

        )}
        <AppEmulationDeviceListModal
          toggle={this.props.toggle}
          show={this.props.show}
          deviceInfo={this.props.appEmulationData || {}}
          handleSubmitClick={this.handleSubmitClick}
          selectedLangauge={this.props.selectedLangauge}
          closeModal={this.props.closeModal}
          checkParticipantsAvailable={this.props.checkParticipantsAvailable}
        />
        {this.props.role === USER_ROLES.AGENT && this.state.appEmulationUrl ? (
          <AppEmulationIFrame
            show={this.state.showIFrameModal}
            appEmulationUrl={this.state.appEmulationUrl}
            role={this.props.role}
            toggle={this.toggleConfirmationPopUp}
            ref={this.iFrame}
            selectedDeviceInfo={this.props.selectedDeviceInfo}
            selectedLangauge={this.props.selectedLangauge}
            showDeviceInfo={this.props.showDeviceInfo}
          />
        ) : this.props.role === USER_ROLES.USER &&
          this.props.appEmulationPermisssionReply ? (
            <AppEmulationIFrame
              show={this.props.appEmulationPermisssionReply}
              appEmulationUrl={this.state.appEmulationUrl}
              role={this.props.role}
              toggle={this.toggleConfirmationPopUp}
              ref={this.iFrame}
              selectedDeviceInfo={this.props.selectedDeviceInfo}
              selectedLangauge={this.props.selectedLangauge}
              showDeviceInfo={this.props.showDeviceInfo}
            />
          ) : null}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    customerId: getCustomerId(state),
    selectedLangauge: state.LanguageReducer.language,
    userName: selectUserName(state),
    sessionKey: selectSessionKey(state),
    uniqueId: selectUniqueId(state),
    role: selectUserRole(state),
    isAppEmulationSessionActive: getAppEmulationSessionActive(state),
    appEmulationUrl: getAppEmulationIFrameUrl(state),
    appEmulationPermisssionReply: getAppEmulationPermissionReply(state),
    selectedDeviceInfo: getAppEmulationSelectedDeviceInfo(state),
    showDeviceInfo: state.OrganisationReducer.appEmulationSettings.showDeviceInfo
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppEmulationContainer);
