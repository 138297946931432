import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserEvicted from "./UserEvicted";
import { selectSessionStatus } from "COMPONENTS/Dashboard/selectors";
import setLanguageAction from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageAction";
import { disableBrowseBackBtn } from "UTILS/Utility";
import { getSessionDetails } from "REDUX/Selector/SessionSelector";
import { organisationAction } from "CONFIG/ActionFactory";

export class UserEvictedContainer extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { history } = this.props;
    history.listen((newLocation, action) => {
      disableBrowseBackBtn(newLocation, action, history, this)
    });

    // if page is reloaded then logo url data is cleared from store, to fetch the logo again and set it in store this code is needed
    if (this.props.sessionData.logoUrl) {
      organisationAction.setLogoUrl(this.props.sessionData.logoUrl);
    }
  }

  onSelectChange = selectedLanguage => {
    this.props.setLanguageAction(selectedLanguage);
  };

  render() {
    return (
      <React.Fragment>
        <UserEvicted
          onSelectChange={this.onSelectChange}
          language={this.props.language}
          userSessionName={this.props.userSessionName}
          sessionStatus={this.props.sessionStatus}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.LanguageReducer.language,
    sessionStatus: selectSessionStatus(state),
    userSessionName: state.SessionReducer.sessionDetails.sessionName,
    sessionData: getSessionDetails(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setLanguageAction
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEvictedContainer);
