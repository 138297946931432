export const socketMessage = {
  events: {
    CONNECTION: "connect",
    PACKET: "packet",
    PACKET_CREATE: "packetCreate",
    DISCONNECT: "disconnect",
    MESSAGE: "message"
  },
  category: {
    SESSION: "SESSION",
    WEBRTC: "WEBRTC",
    CHAT: "CHAT",
    LOCATION: "LOCATION",
    WHITEBOARD: "WHITEBOARD",
    ERROR: "ERROR",
    PARTICIPANT: "PARTICIPANT",
    NOTIFICATION: "NOTIFICATION",
    PDF: "PDF",
    APP_EMULATION: "APP_EMULATION",
    DOWNLOAD: "DOWNLOAD",
    CO_BROWSE: "CO_BROWSE",
    CUSTOMER_CO_BROWSE: "CUSTOMER_CO_BROWSE"
  },
  subCategories: {
    SESSION: {
      JOIN_SESSION_REQUEST: "JOIN_SESSION_REQUEST",     // sent by User to server
      _JOIN_SESSION_REQUEST: "_JOIN_SESSION_REQUEST",   // sent by server to to Agent
      JOIN_SESSION_REPLY: "JOIN_SESSION_REPLY",         // sent by Agent to server
      _JOIN_SESSION_REPLY: "_JOIN_SESSION_REPLY",       // sent by serer to User
      JOIN: "JOIN",
      _JOIN: "_JOIN",
      LEAVE: "LEAVE",
      _STARTED: "_STARTED",
      GET_ALL_SESSION_DATA: "GET_ALL_SESSION_DATA",
      _GET_ALL_SESSION_DATA: "_GET_ALL_SESSION_DATA",
      _END_SESSION: "_END_SESSION",
      _END_SESSION_FORCEFULLY: "_END_SESSION_FORCEFULLY",
      _SESSION_DELETED: "_SESSION_DELETED",
      _SESSION_UPDATED: "_SESSION_UPDATED",
      ALLOW_EDIT_ACCESS: "ALLOW_EDIT_ACCESS",
      _ALLOW_EDIT_ACCESS: "_ALLOW_EDIT_ACCESS",
      _AGENT_ARRIVED: "_AGENT_ARRIVED",
      _END_SESSION_AND_LOGOUT_FORCEFULLY: "_END_SESSION_AND_LOGOUT_FORCEFULLY",
    },
    WHITEBOARD: {
      ADD_CANVAS: "ADD_CANVAS",
      _ADD_CANVAS: "_ADD_CANVAS",
      ADD_OBJECT: "ADD_OBJECT",
      _ADD_OBJECT: "_ADD_OBJECT",
      DELETE_OBJECT: "DELETE_OBJECT",
      _DELETE_OBJECT: "_DELETE_OBJECT",
      UPDATE_OBJECT: "UPDATE_OBJECT",
      _UPDATE_OBJECT: "_UPDATE_OBJECT",
      UPDATE_CANVAS: "UPDATE_CANVAS",
      CHANGE_ACTIVE_CANVAS: "CHANGE_ACTIVE_CANVAS",
      _CHANGE_ACTIVE_CANVAS: "_CHANGE_ACTIVE_CANVAS",
      CLEAR_CANVAS: "CLEAR_CANVAS",
      _CLEAR_CANVAS: "_CLEAR_CANVAS",
      DELETE_CANVAS: "DELETE_CANVAS",
      _DELETE_CANVAS: "_DELETE_CANVAS",
      MODIFY_OBJECT: "MODIFY_OBJECT",
      _MODIFY_OBJECT: "_MODIFY_OBJECT",
      SET_CANVAS_THUMBNAIL: "SET_CANVAS_THUMBNAIL",
      _SET_CANVAS_THUMBNAIL: "_SET_CANVAS_THUMBNAIL",
      SET_DEFAULT_FONT_INFO: "SET_DEFAULT_FONT_INFO",
      _SET_DEFAULT_FONT_INFO: "_SET_DEFAULT_FONT_INFO",
      OBJECT_DOWNLOADED: "OBJECT_DOWNLOADED",
      _DOWNLOADING_TO_CANVAS: "_DOWNLOADING_TO_CANVAS",
      DOWNLOADING_TO_CANVAS: "DOWNLOADING_TO_CANVAS",
      ADD_STICKY_OBJECT:"ADD_STICKY_OBJECT",
      _ADD_STICKY_OBJECT : "_ADD_STICKY_OBJECT",
      SNAPSHOT_SEARCH_CODE: "SNAPSHOT_SEARCH_CODE",
      _SNAPSHOT_SEARCH_CODE: "_SNAPSHOT_SEARCH_CODE",
      subActions: {
        REGULAR: "REGULAR",
        UNDO: "UNDO",
        REDO: "REDO"
      }
    },
    PARTICIPANT: {
      _PARTICIPANT_ARRIVED: "_PARTICIPANT_ARRIVED",
      LEFT: "LEFT",
      _LEFT: "_LEFT",
      EVICT_PARTICIPANT: "EVICT_PARTICIPANT",
      _EVICT_PARTICIPANT: "_EVICT_PARTICIPANT",
    },
    CHAT: {
      SEND_MESSAGE: "SEND_MESSAGE",
      _SEND_MESSAGE: "_SEND_MESSAGE",
      NOTIFY_ERROR: 'NOTIFY_ERROR',
    },
    NOTIFICATION: {
      MAKE_PRESENTER: "MAKE_PRESENTER",
      _MAKE_PRESENTER: "_MAKE_PRESENTER",
      ADD_ANNOTATION: "ADD_ANNOTATION",
      _ADD_ANNOTATION: "_ADD_ANNOTATION",
      REMOVE_ANNOTATION: "REMOVE_ANNOTATION",
      _REMOVE_ANNOTATION: "_REMOVE_ANNOTATION"
    },
    PDF: {
      CHANGE_ACTIVE_PAGE: "CHANGE_ACTIVE_PAGE",
      _CHANGE_ACTIVE_PAGE: "_CHANGE_ACTIVE_PAGE",
      SET_PAGE_POSITION: "SET_PAGE_POSITION",
      _SET_PAGE_POSITION: "_SET_PAGE_POSITION",
      SET_FULL_SCREEN: "SET_FULL_SCREEN",
      _SET_FULL_SCREEN: "_SET_FULL_SCREEN",
      SET_FIT_SCREEN: "SET_FIT_SCREEN",
      _SET_FIT_SCREEN: "_SET_FIT_SCREEN",
      UPDATE_URL: "UPDATE_URL",
      _UPDATE_URL: "_UPDATE_URL"
    },
    LOCATION: {
      START_LOCATION_SHARE: "START_LOCATION_SHARE",
      _START_LOCATION_SHARE: "_START_LOCATION_SHARE",
      SHARE_LOCATION_PERMISSION: "SHARE_LOCATION_PERMISSION",
      _SHARE_LOCATION_PERMISSION: "_SHARE_LOCATION_PERMISSION",
      SHARE_LOCATION: "SHARE_LOCATION",
      _SHARE_LOCATION: "_SHARE_LOCATION",
      STOP_LOCATION_SHARE: "STOP_LOCATION_SHARE",
      _STOP_LOCATION_SHARE: "_STOP_LOCATION_SHARE",
      SHARE_ETA: "SHARE_ETA",
      _SHARE_ETA: "_SHARE_ETA",
      GET_ALL_LOCATION_DATA: "GET_ALL_LOCATION_DATA",
      _GET_ALL_LOCATION_DATA: "_GET_ALL_LOCATION_DATA",
      STOP_ETA_SHARE: "STOP_ETA_SHARE",
      _STOP_ETA_SHARE: "_STOP_ETA_SHARE"
    },
    APP_EMULATION: {
      _PERMISSION_REQUEST: "_PERMISSION_REQUEST",
      PERMISSION_REPLY: "PERMISSION_REPLY",
      _PERMISSION_REPLY: "_PERMISSION_REPLY",
      _STOP_APP_EMULATION_SESSION: "_STOP_APP_EMULATION_SESSION",
      _PARTICIPANT_LEFT: "_PARTICIPANT_LEFT",
      _PARTICIPANT_ARRIVED: "_PARTICIPANT_ARRIVED"
    },
    CO_BROWSE: {
      PERMISSION_REQUEST: "PERMISSION_REQUEST",
      _PERMISSION_REQUEST: "_PERMISSION_REQUEST",
      PERMISSION_REPLY: "PERMISSION_REPLY",
      _PERMISSION_REPLY: "_PERMISSION_REPLY",
      _STOP_CO_BROWSE_SESSION: "_STOP_CO_BROWSE_SESSION",
      _PARTICIPANT_LEFT: "_PARTICIPANT_LEFT",
      _PARTICIPANT_ARRIVED: "_PARTICIPANT_ARRIVED",
      CHANGE_CO_BROWSE_URL: "CHANGE_CO_BROWSE_URL",
      _CHANGE_CO_BROWSE_URL: "_CHANGE_CO_BROWSE_URL",
      CO_BROWSE_STARTED: "CO_BROWSE_STARTED",
      _CO_BROWSE_STARTED: "_CO_BROWSE_STARTED"
    },
    CUSTOMER_CO_BROWSE: {
      PERMISSION_REQUEST: "PERMISSION_REQUEST",
      _PERMISSION_REQUEST: "_PERMISSION_REQUEST",
      PERMISSION_REPLY: "PERMISSION_REPLY",
      _PERMISSION_REPLY: "_PERMISSION_REPLY",
      _PARTICIPANT_LEFT: "_PARTICIPANT_LEFT",
      _PARTICIPANT_ARRIVED: "_PARTICIPANT_ARRIVED",
      LEAVE_SESSION: 'LEAVE_SESSION',
      _LEAVE_SESSION: '_LEAVE_SESSION',
      END_SESSION: 'END_SESSION',
      _END_SESSION: '_END_SESSION',
    },
    ERROR: {
      _ERROR: "_ERROR",
      ERROR_IN_WEBRTC_MEDIA_SHARING: "ERROR_IN_WEBRTC_MEDIA_SHARING",
      UNAUTHORIZED_ERROR: "UnauthorizedError",
      INVALID_REQUEST_DATA: "INVALID_REQUEST_DATA",
      INVALID_SESSION: "INVALID_SESSION"
    }
  }
};

export const webRtcMessageId = {
  WEBRTC_SESSION_INFO: "WEBRTC_SESSION_INFO",
  _WEBRTC_SESSION_INFO: "_WEBRTC_SESSION_INFO",
  TURN_SERVER_INFO: "TURN_SERVER_INFO",
  _TURN_SERVER_INFO: "_TURN_SERVER_INFO",
  SDP_OFFER: "SDP_OFFER",
  _SDP_ANSWER: "_SDP_ANSWER",
  ICE_CANDIDATE: "ICE_CANDIDATE",
  _ICE_CANDIDATE: "_ICE_CANDIDATE",
  SHARE_WEBRTC_MEDIA: "SHARE_WEBRTC_MEDIA",
  _SHARE_WEBRTC_MEDIA: "_SHARE_WEBRTC_MEDIA",
  _NEW_WEBRTC_MEDIA: "_NEW_WEBRTC_MEDIA",
  TOGGLE_MEDIA: "TOGGLE_MEDIA",
  _MEDIA_TOGGLED: "MEDIA_TOGGLED",
  STOP_SCREEN_SHARING: "STOP_SCREEN_SHARING",
  _WEBRTC_MEDIA_SHARING_STOPPED: "_WEBRTC_MEDIA_SHARING_STOPPED",
  _STOP_SCREEN_SHARING: "_STOP_SCREEN_SHARING",
  _REGISTER: "_REGISTER",
  REGISTER: "REGISTER",
  _RELEASE_ALL_REMOTE_MEDIA: "_RELEASE_ALL_REMOTE_MEDIA",
  SNAPSHOT_PERMISSION_REQUEST: "SNAPSHOT_PERMISSION_REQUEST",
  _SNAPSHOT_PERMISSION_REPLY: "_SNAPSHOT_PERMISSION_REPLY",
  SNAPSHOT_PERMISSION_REPLY: "SNAPSHOT_PERMISSION_REPLY",
  _SNAPSHOT_PERMISSION_REQUEST: "_SNAPSHOT_PERMISSION_REQUEST",
  SWITCH_SNAPSHOT_CAMERA: "SWITCH_SNAPSHOT_CAMERA",
  _SWITCH_SNAPSHOT_CAMERA: "_SWITCH_SNAPSHOT_CAMERA",
  SNAPSHOT_TAKEN: "SNAPSHOT_TAKEN",
  _SNAPSHOT_TAKEN: "_SNAPSHOT_TAKEN",
  WEBRTC_PERMISSION_REQUEST: "WEBRTC_PERMISSION_REQUEST",
  _WEBRTC_PERMISSION_REQUEST: "_WEBRTC_PERMISSION_REQUEST",
  WEBRTC_PERMISSION_REPLY: "WEBRTC_PERMISSION_REPLY",
  _WEBRTC_PERMISSION_REPLY: "_WEBRTC_PERMISSION_REPLY",
  STOP_ALL_WEBRTC_AUDIO_VIDEO: "STOP_ALL_WEBRTC_AUDIO_VIDEO",
  _STOP_ALL_WEBRTC_AUDIO_VIDEO: "_STOP_ALL_WEBRTC_AUDIO_VIDEO",
  PERMISSION_REPLY: "PERMISSION_REPLY",
  _KMS_DISCONNECTED: "_KMS_DISCONNECTED",
  _KMS_RECONNECTED: "_KMS_RECONNECTED",
  KMS_NOT_CONNECTED: "KMS_NOT_CONNECTED",
  _WEBRTC_SESSION_STARTED: "_WEBRTC_SESSION_STARTED",
  _RELEASE_ALL_REMOTE_MEDIA: "_RELEASE_ALL_REMOTE_MEDIA",
  STOP_USER_VIDEO: "STOP_USER_VIDEO",
  _STOP_USER_VIDEO: "_STOP_USER_VIDEO",
  ERROR_IN_WEBRTC_MEDIA_SHARING: "ERROR_IN_WEBRTC_MEDIA_SHARING",
  PLUGIN_NOT_INSTALLED: "PLUGIN_NOT_INSTALLED",
  _PLUGIN_NOT_INSTALLED: "_PLUGIN_NOT_INSTALLED",
  _SNAPSHOT_STARTED: "_SNAPSHOT_STARTED",
  _SNAPSHOT_STOPPED: "_SNAPSHOT_STOPPED",
  RENEGOTIATE: "RENEGOTIATE",
  _RENEGOTIATE: "_RENEGOTIATE",
  ROOM_NOT_ACTIVE: "ROOM_NOT_ACTIVE",
  ROOM_NOT_FOUND: "ROOM_NOT_FOUND",
  _SNAPSHOT_RESOURCES_INITIALIZED: "_SNAPSHOT_RESOURCES_INITIALIZED",
  MEDIA_DEVICE_PERMISSION_DENIED: 'MEDIA_DEVICE_PERMISSION_DENIED',
  _MEDIA_DEVICE_PERMISSION_DENIED: '_MEDIA_DEVICE_PERMISSION_DENIED',
  SNAPSHOT_ANNOTATIONS_MODE:"SNAPSHOT_ANNOTATIONS_MODE",
  _SNAPSHOT_ANNOTATIONS_MODE:"_SNAPSHOT_ANNOTATIONS_MODE",
  _SNAPSHOT_ANNOTATIONS_CLEARED: "_SNAPSHOT_ANNOTATIONS_CLEARED",
  SNAPSHOT_ANNOTATIONS_CLEARED: "SNAPSHOT_ANNOTATIONS_CLEARED",
};

export const mediaType = {
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
  AUDIO_VIDEO: "AUDIO_VIDEO",
  SCREEN: "SCREEN",
  NONE: "NONE",
  SNAPSHOT: "SNAPSHOT",
  CO_BROWSE: "CO_BROWSE"
};

export const mediaStatus = {
  MUTED: "MUTED",
  UNMUTED: "UNMUTED",
  MUTED_BY_AGENT: "MUTED_BY_AGENT"
};
