import React, { Component } from "react";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";
import Whiteboard from "./Whiteboard";

import { CANVAS_TYPES } from "UTILS/Constants";
import { socketMessage } from "WEBSOCKET/constants";

import { sendWebsocketMessage } from "WEBSOCKET/WebsocketHandler";
import {
  addObject,
  clearCanvas,
  setCanvasHistory,
  deleteObject,
  setUploadImage,
  setActiveCanvasRef,
  setDefaultFontInfoLocally,
  setOrRevokeActiveAnnotationDetails,
  setActiveAnnotationDetails,
  setSelectedAnnotation,
  setFitScreenFlag,
  setClearCanvasFlag,
  setIsDownloadToCanvas
} from "./WhiteboardAction";
import { 
  selectOrganiser,  
  selectSnapshoteeId,
  selectSnapshotPermissionReply,
  selectShowSnapshot,
  getSaveSessionDataFlag
} from "REDUX/Selector/SessionSelector";
import { isDownloadModuleSelected } from "../CommonComponents/Header/selectors";
import { selectPDFTronKey } from "./selectors";
import { setSnapshotSearchResult, setSnapshotScanCodeError, setSnapshotCurrentCanvas } from "./AnnotationBar/SnapshotAction";

import {
  selectUserId,
  selectActiveCanvas,
  selectActiveCanvasData,
  selectSessionKey,
  selectToken,
  selectParticipantId,
  getCanvasHistory,
  selectUserRole,
  selectCanvases,
  selectUniqueId,
  selectDefaultFontInfo,
  selectPresenterId,
  selectFrameDetails,
  selectUploadImage,
  getInkUnLockedParticipants,
  getSessionStatusFlag,
  getSelectedAnnotation,
  getPrevSelectedAnnotation,
  selectIsFitScreen,
  selectFrameSize,
  selectIsOnCanvasDownloading
} from "./selectors";
import { selectAllowedDownloads } from "../CommonComponents/Header/selectors";

import { getFirstNamebyParticipantId } from "UTILS/Utility";
import {
  getModules,
  getAdmittedParticipants,
  getWebRtcPermissionRequest,
  selectLocation,
  getIsOrganizationTemplateEnabled
} from "../CommonComponents/Header/selectors";
import { selectScreenSharingParticipantId } from "../Dashboard/selectors";
import { downloadableModules} from "UTILS/Constants";

class WhiteboardContainer extends Component {
  constructor(props) {
    super(props);
    this.whiteBoardRenew = React.createRef();
  }
  state = {
    canvasHistory: [],
    pdfFileUrl: null,
    originalPdfFileName: null,
    screenSharePresenterName : "",
  };

  componentDidMount() {
    this.getScreenSharePresenterName();
  }

  componentDidUpdate(prevProps) {
    if (this.props.screenSharingParticipantId !== prevProps.screenSharingParticipantId) {
      this.getScreenSharePresenterName();
    }

    /*
     * show spinner on image, pdf upload or snapshot, location download is started on agent or presenter end
     * hide spinner is taken care on completed the download of pdf / location on canvas 
     * 
     * If there was any error while upload / download operation then hide spinner is taken care in else block
    */
    if(prevProps.isDownloadingOnCanvas !== this.props.isDownloadingOnCanvas) {
      if(this.props.isDownloadingOnCanvas) {
        this.props.showSpinner();
        this.props.setIsDownloadToCanvas(null);
      } else if(this.props.isDownloadingOnCanvas === false) {
        this.props.hideSpinner();
        this.props.setIsDownloadToCanvas(null);
      }
    }

  }

  setActiveCanvasRef = canvasRef => {
    this.props.setActiveCanvasRef(canvasRef);
  };

  sendAddObjectMessage = (object, subAction, canvasType, activePage) => {
    //Add object to reducer
    // this.props.addObject(object.objectId, object)
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.ADD_OBJECT,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: canvasType,
        activePage: activePage,
        subAction: subAction,
        participantId: this.props.participantId,
        whiteboardData: {
          objectType: object.objectType,
          objectId: object.objectId,
          canvasObject: object
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  sendDeleteObjectMessage = (objectId, subAction) => {
    // this.props.deleteObject(objectId)
    const message = {
      data: {
        sessionKey: this.props.sessionKey,
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.DELETE_OBJECT,
        subAction: subAction,
        participantId: this.props.participantId,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        whiteboardData: {
          objectId: objectId
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  sendUpdateObjectMessage = (object, subAction) => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.UPDATE_OBJECT,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        subAction: subAction,
        participantId: this.props.participantId,
        whiteboardData: {
          objectType: object.objectType,
          objectId: object.objectId,
          canvasObject: object
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  sendModifyObjectMessage = (canvasObject, oldCanvasObject, subAction) => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.MODIFY_OBJECT,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.activeCanvas.canvasId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        subAction: subAction,
        participantId: this.props.participantId,
        whiteboardData: {
          objectType: canvasObject.objectType,
          objectId: canvasObject.objectId,
          canvasObject: canvasObject,
          oldCanvasObject: oldCanvasObject
        }
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  clearCanvas = (activePage) => {
    //  this.props.clearCanvas(this.props.activeCanvas.canvasId)
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.CLEAR_CANVAS,
        sessionKey: this.props.sessionKey,
        canvasId: this.props.activeCanvas.canvasId,
        participantId: this.props.participantId,
        canvasType: this.props.activeCanvas.canvasType,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? activePage || this.props.activeCanvasData.activePage
            : -1
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };
  
  sendSetDefaultFontInfoMessage = fontInfo => {
    const message = {
      data: {
        category: socketMessage.category.WHITEBOARD,
        action: socketMessage.subCategories.WHITEBOARD.SET_DEFAULT_FONT_INFO,
        sessionKey: this.props.sessionKey,
        token: this.props.token,
        canvasType: this.props.activeCanvas.canvasType,
        canvasId: this.props.activeCanvas.canvasId,
        activePage:
          this.props.activeCanvas.canvasType === CANVAS_TYPES.PDF
            ? this.props.activeCanvasData.activePage
            : -1,
        newFontInfo: fontInfo
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };
  broadCastUpdatedPDf = (newFileURL, pageNumber) => {
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: socketMessage.subCategories.PDF.UPDATE_URL,
        canvasId: this.props.activeCanvas.canvasId,
        newUrl: newFileURL,
        sessionKey: this.props.sessionKey,
        pageNumber: pageNumber || this.props.activeCanvasData.activePage
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };  
  
  setPagePosition = (frameDetails, participantId) => {
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: socketMessage.subCategories.PDF.SET_PAGE_POSITION,
        canvasId: this.props.activeCanvas.canvasId,
        frameDetails: frameDetails,
        sessionKey: this.props.sessionKey,
        participantId: participantId
      }
    };
    setTimeout(() => {
      this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
    }, 500);
  };

  setFitScreen = (isFitScreen) => {
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: socketMessage.subCategories.PDF.SET_FIT_SCREEN,
        canvasId: this.props.activeCanvas.canvasId,
        sessionKey: this.props.sessionKey,
        isFitScreen: isFitScreen
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  changePdfPage = activePage => {
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: socketMessage.subCategories.PDF.CHANGE_ACTIVE_PAGE,
        canvasId: this.props.activeCanvas.canvasId,
        sessionKey: this.props.sessionKey,
        activePage: activePage
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };
  isPresenter = () => {
    return this.props.uniqueId === this.props.presenterId;
  };
  sendFrameSizeDetails = frameSize => {
    const message = {
      data: {
        category: socketMessage.category.PDF,
        action: "FRAME_SIZE",
        sessionKey: this.props.sessionKey,
        frameSize: frameSize
      }
    };
    this.props.sendWebsocketMessage(socketMessage.events.MESSAGE, message);
  };

  isCanvasDownloadAllowed = () => {
    return this.props.allowedDownloads && this.props.allowedDownloads.hasOwnProperty(downloadableModules.CANVAS)
  }

  getScreenSharePresenterName = () => {
    let screenSharePresenterName = getFirstNamebyParticipantId(this.props.admittedParticipants, this.props.screenSharingParticipantId);
    this.setState({
      screenSharePresenterName
    })
  }

  render() {
    const { activeCanvas, userRole, activeCanvasData } = this.props;

    let participantIndex = _.findIndex(this.props.admittedParticipants, 
      participant => participant.uniqueId === this.props.uniqueId);
    let admittedParticipant = participantIndex !== -1 ? this.props.admittedParticipants[participantIndex] : null;

    return (
      <div className="whiteBoardContainer">
        {activeCanvasData && (
          <Whiteboard
            endCall={this.props.endCall}
            participantId={this.props.participantId}
            canvasId={activeCanvasData.canvasId}
            activeCanvasData={this.props.activeCanvasData}
            sendAddObjectMessage={this.sendAddObjectMessage}
            sendDeleteObjectMessage={this.sendDeleteObjectMessage}
            sendUpdateObjectMessage={this.sendUpdateObjectMessage}
            sendModifyObjectMessage={this.sendModifyObjectMessage}
            clearCanvas={this.clearCanvas}
            broadcastDownloadAction={this.props.broadcastDownloadAction}
            savePdfChange={this.savePdfChange}
            clearPdfChanges={this.clearPdfChanges}
            canvases={this.props.canvases}
            changeActiveCanvas={this.props.changeActiveCanvas}
            userRole={userRole}
            uploadFile={this.props.uploadFile}
            sendSetDefaultFontInfoMessage={this.sendSetDefaultFontInfoMessage}
            setDefaultFontInfoLocally={this.props.setDefaultFontInfoLocally}
            defaultFontInfo={this.props.defaultFontInfo}
            ref={this.whiteBoardRenew}
            pdfFileUrl={this.state.pdfFileUrl}
            setPagePosition={this.setPagePosition}
            isPresenter={this.isPresenter()}
            frameDetails={this.props.frameDetails}
            changePdfPage={this.changePdfPage}
            clearCanvasFlag={this.props.clearCanvasFlag}
            broadCastUpdatedPDf={this.broadCastUpdatedPDf}
            uploadImage={this.props.uploadImage}
            setUploadImage={this.props.setUploadImage}
            setActiveCanvasRef={this.setActiveCanvasRef}
            sendFrameSizeDetails={this.sendFrameSizeDetails}
            modules={this.props.modules}
            admittedParticipants={this.props.admittedParticipants}
            presenterId={this.props.presenterId}
            uniqueId={this.props.uniqueId}
            inkUnLockedParticipants={this.props.inkUnLockedParticipants}
            organiser={this.props.organiser}
            isSessionEnded={this.props.isSessionEnded}
            webRtcPermissionRequest={this.props.webRtcPermissionRequest}
            isLocationPermissionRequested={
              this.props.isLocationPermissionRequested
            }
            screenSharingParticipantId={this.props.screenSharingParticipantId}
            snapshoteeId={this.props.snapshoteeId}
            sendWebsocketMessage={this.props.sendWebsocketMessage}
            sessionKey={ this.props.sessionKey}
            selectedAnnotationByAgent={this.props.selectedAnnotationByAgent}
            setOrRevokeActiveAnnotationDetails={this.props.setOrRevokeActiveAnnotationDetails}
            setSelectedAnnotation={this.props.setSelectedAnnotation}
            selectedAnnotation={this.props.selectedAnnotation}
            setFitScreen={this.setFitScreen}
            isFitScreen={this.props.isFitScreen}
            setFitScreenFlag={this.props.setFitScreenFlag}
            uniqueId= {this.props.uniqueId}
            snapshoteeId= {this.props.snapshoteeId}
            snapshotPermissionReply={this.props.snapshotPermissionReply}
            showSnapshot = {this.props.showSnapshot}
            frameSize = {this.props.frameSize}
            uploadCanvasThumbnail = {this.props.uploadCanvasThumbnail}
            setClearCanvasFlag = {this.props.setClearCanvasFlag}
            isCanvasDownloadAllowed = {this.isCanvasDownloadAllowed()}
            language={this.props.language}
            logo={this.props.logo}
            componentInFocus={this.props.componentInFocus}
            addCanvas={this.props.addCanvas}
            uploadThumbnail={this.props.uploadThumbnail}
            showSpinnerForCanvasList={this.props.showSpinnerForCanvasList}
            hideSpinnerForCanvasList={this.props.hideSpinnerForCanvasList}
            showSpinner={this.props.showSpinner}
            hideSpinner={this.props.hideSpinner}
            spinnerVisibility={this.props.spinnerVisibility}
            spinnerVisibilityCanvasList={this.props.spinnerVisibilityCanvasList}
            setComponentInFocus={this.props.setComponentInFocus}
            webRtcPermissionReply={this.props.webRtcPermissionReply}
            setSwitchedCanvasType={this.props.setSwitchedCanvasType}
            switchedCanvasType={this.props.switchedCanvasType}
            // removeSpotLight={this.props.removeSpotLight}
            removePdfHighLight={this.props.removePdfHighLight}
            screenSharePresenterName={this.state.screenSharePresenterName}
            setIsCanvasModified={this.props.setIsCanvasModified}
            showAudioVideoComponent={this.props.showAudioVideoComponent}
            saveSessionDataFlag={this.props.saveSessionDataFlag}
            allowEdit={admittedParticipant ? admittedParticipant.allowEditAccess: false}
            getPdfListFromCloud={this.props.getPdfListFromCloud}
            getCloudFileUrl={this.props.getCloudFileUrl}
            isDownloadModuleSelected={this.props.isDownloadModuleSelected}
            isOrganizationTemplateEnabled={this.props.isOrganizationTemplateEnabled}
            allowUpload={this.props.allowUpload}
            showCoBrowse={this.props.showCoBrowse}
            pdftronKey={this.props.pdftronKey}
            isLandscape = {this.props.isLandscape}
            showCustomerCoBrowse = {this.props.showCustomerCoBrowse}
            isSnapshotTaken={this.props.isSnapshotTaken}
            setIsDownloadToCanvas = {this.props.setIsDownloadToCanvas}
            showLogoOnCanvasStatus = {this.props.showLogoOnCanvasStatus}
            isSnapshotRunning = {this.props.isSnapshotRunning}
            snapshotScanCodeResult= {this.props.snapshotScanCodeResult}
            setSnapshotSearchResult= {this.props.setSnapshotSearchResult}
            setActiveAnnotationDetails={this.props.setActiveAnnotationDetails}
            prevSelectedAnnotationByAgent={this.props.prevSelectedAnnotationByAgent}
            setSnapshotScanCodeError={this.props.setSnapshotScanCodeError}
            snapshotScanCodeError={this.props.snapshotScanCodeError}
            snapshotCurrentCanvas= {this.props.snapshotCurrentCanvas}
            setSnapshotCurrentCanvas={this.props.setSnapshotCurrentCanvas}
          />
        )}
      </div>
    );
  }

}
const mapStateToProps = state => {
  return {
    userId: selectUserId(state),
    activeCanvas: selectActiveCanvas(state),
    uploadImage: selectUploadImage(state),
    activeCanvasData: selectActiveCanvasData(state),
    sessionKey: selectSessionKey(state),
    allowedDownloads: selectAllowedDownloads(state),
    token: selectToken(state),
    participantId: selectParticipantId(state),
    canvasHistory: getCanvasHistory(state),
    userRole: selectUserRole(state),
    canvases: selectCanvases(state),
    uniqueId: selectUniqueId(state),
    defaultFontInfo: selectDefaultFontInfo(state),
    presenterId: selectPresenterId(state),
    frameDetails: selectFrameDetails(state),
    modules: getModules(state),
    admittedParticipants: getAdmittedParticipants(state),
    inkUnLockedParticipants: getInkUnLockedParticipants(state),
    organiser: selectOrganiser(state),
    isSessionEnded: getSessionStatusFlag(state),
    webRtcPermissionRequest: getWebRtcPermissionRequest(state),
    isLocationPermissionRequested: selectLocation(state),
    screenSharingParticipantId:selectScreenSharingParticipantId(state),
    snapshoteeId:selectSnapshoteeId(state),
    selectedAnnotationByAgent:getSelectedAnnotation(state),
    isFitScreen: selectIsFitScreen(state),
    snapshotPermissionReply: selectSnapshotPermissionReply(state),
    showSnapshot: selectShowSnapshot(state),
    frameSize: selectFrameSize(state),
    clearCanvasFlag: state.WhiteboardReducer.clearCanvas,
    language: state.LanguageReducer.language,
    logo: state.OrganisationReducer.logo,
    selectedAnnotation: state.WhiteboardReducer.selectedAnnotation,
    showAudioVideoComponent: state.SessionReducer.showAudioVideoComponent,
    saveSessionDataFlag: getSaveSessionDataFlag(state),
    isDownloadModuleSelected: isDownloadModuleSelected(state),
    isOrganizationTemplateEnabled:getIsOrganizationTemplateEnabled(state),
    pdftronKey: selectPDFTronKey(state),
    isSnapshotTaken: state.SessionReducer.isSnapshotTaken, 
    pdftronKey: selectPDFTronKey(state), 
    showCustomerCoBrowse: state.CustomerCoBrowseReducer.showCustomerCoBrowse,
    showLogoOnCanvasStatus: state.OrganisationReducer.customerSettings.showLogoOnCanvasStatus,
    isDownloadingOnCanvas: selectIsOnCanvasDownloading(state),
    isSnapshotRunning: state.SessionReducer.isSnapshotRunning,
    snapshotScanCodeResult: state.SnapshotReducer.snapshotScanCodeResult,
    snapshotScanCodeError: state.SnapshotReducer.snapshotScanCodeError,
    prevSelectedAnnotationByAgent:getPrevSelectedAnnotation(state),
    snapshotCurrentCanvas: state.SnapshotReducer.snapshotCurrentCanvas,
    allowUpload: state.OrganisationReducer.allowUpload
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      sendWebsocketMessage,
      addObject,
      clearCanvas,
      deleteObject,
      setCanvasHistory,
      setUploadImage,
      setActiveCanvasRef,
      setDefaultFontInfoLocally,
      setOrRevokeActiveAnnotationDetails,
      setSelectedAnnotation,
      setFitScreenFlag,
      setClearCanvasFlag,
      setIsDownloadToCanvas,
      setSnapshotSearchResult,
      setActiveAnnotationDetails,
      setSnapshotScanCodeError,
      setSnapshotCurrentCanvas
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhiteboardContainer);
