import React, { useState, useRef, createRef, useEffect } from 'react';

import {
  Row,
  Col
} from "reactstrap";
import Pagination from "react-js-pagination";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from 'rc-tooltip';

import EditQRCodeModal from './EditQRCodeModal';
import DeleteConfirmationModal from '../../CommonComponents/DeleteConfirmationModal/DeleteConfirmationModal';
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import toastr from 'COMPONENTS/ShowTostr/ShowTostr';
import NoData from 'COMPONENTS/CommonComponents/NoData/NoData';
import GenerateAndDownloadQRCode from 'COMPONENTS/CommonComponents/GenerateAndDownloadQRCode/GenerateAndDownloadQRCode';
import CustomTooltip from 'COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip';
import { QR_CODES_PAGE_SIZES, CONFIG, REGEX } from "UTILS/Constants";
import { getQRCodeUrl, getStringWithoutExtraSpaces } from "UTILS/Utility";

// Strings
import { getMessage } from "CONFIG/i18n";

import qrCodeKebabMenu from "ASSETS/Images/kebab_menu.svg";
import icEditQRCode from "ASSETS/Images/ic_edit.svg";
import icDeleteQRCode from "ASSETS/Images/ic_delete_img.svg";
import icCopyQRCodeLink from "ASSETS/Images/ic_copy_link.svg";
import icDownloadQRCode from "ASSETS/Images/ic_download.svg";
import qrCodePlaceholderBlur from "ASSETS/Images/qr_code_placeholder_blur.png";

import './QRCodes.less';
import "COMPONENTS/App/App.less";
import {  qrCodesAction } from "CONFIG/ActionFactory";

const QRCodesList = (props) => {
  const [showEditQRCodeModal, setShowEditQRCodeModal] = useState(false);
  const [showQRCodeDeleteConfirmationModal, setShowQRCodeDeleteConfirmationModal] = useState(false);
  const [selectedQRCode, setSelectedQRCode] = useState({});
  const generateAndDownloadQRCodeRef = useRef([]);

  const getQRCodesList = (page) => {
    props.getQRCodesList(
      page - 1,
      props.qrCodesListPageSize,
      props.searchKey ? props.searchKey : undefined
    );
  }

  const editQRCodeMenuClick = (qrCode) => {
    setShowEditQRCodeModal(true)
    setSelectedQRCode(qrCode)
  }

  const viewQRCodeAgents = (qrCode) => {
    props.history.push(CONFIG.path.qrCodeAgents);
    qrCodesAction.saveQRCodeData(qrCode);
  };

  const deleteQRCodeMenuClick = (qrCode) => {
    setSelectedQRCode(qrCode);
    setShowQRCodeDeleteConfirmationModal(true);
  };

  const closeEditQRCodeModal = () => {
    setShowEditQRCodeModal(false);
    setSelectedQRCode({});
  };

  const closeDeleteQRCodeModal = () => {
    setShowQRCodeDeleteConfirmationModal(false);
    setSelectedQRCode({});
  };

  const handleDeleteClick = () => {
    setShowQRCodeDeleteConfirmationModal(false);
    props.deleteQRCode(selectedQRCode);
  };

  // componentWillUnmount
  useEffect(() => {
    return () => {
      generateAndDownloadQRCodeRef.current = []
    }
  },[])

  const getQRCodeOptions = (qrCode) => {
    return (
      <div className='qr-codes-kebab-menu-options '>
        <div
          className="flex-row cursorPointer kebab-menu-option pb-1"
          onClick={() => editQRCodeMenuClick(qrCode)}
        >
          <img src={icEditQRCode} className="ml-1 edit-img" alt="Edit" />
          <span className='w-100 ml-2'>{getMessage("EDIT")}</span>
        </div>
        <div
          className="flex-row cursorPointer kebab-menu-option pb-1"
          onClick={() => deleteQRCodeMenuClick(qrCode)}
        >
          <img src={icDeleteQRCode} className="ml-1 delete-img" alt="Delete" />
          <span className='w-100 ml-2'>{getMessage("DELETE")}</span>
        </div>
      </div>
    )
  }

  const renderAllQRCodes = () => {
    // reset reference 
    generateAndDownloadQRCodeRef.current = [];
    return (
      <div >
        {showEditQRCodeModal && selectedQRCode &&
          <EditQRCodeModal
            show={showEditQRCodeModal}
            onClosed={closeEditQRCodeModal}
            qrCodeDetails={selectedQRCode}
            updateQRCode={props.updateQRCode}
          />
        }
        <Row className='noMargin qr-codes-list-container pr-0 pb-0 scroll-decoration'>
          {
            props.qrCodes.map((qrCode) => {
              // create a run-time reference for each QR code card to download QR code
              generateAndDownloadQRCodeRef.current[qrCode.qrId] = createRef();
              return (
                <Col xs={12} sm={12} md={6} lg={6} xl={4} key={qrCode.qrId} className='pl-0'>
                  <div className='displayNone'>
                    <GenerateAndDownloadQRCode
                      ref={generateAndDownloadQRCodeRef.current[qrCode.qrId]}
                      downloadQRCode={true}
                      fileName={`QRCode-${getStringWithoutExtraSpaces(qrCode?.label).replace(REGEX.SPECIAL_CHARACTERS, '')}-${qrCode.qrId}`}
                      value={getQRCodeUrl(qrCode.qrId)}
                      id={"qrCodeList" + qrCode.qrId}
                    />
                  </div>
                  <div className='qr-codes-kebab-menu'>
                    <Tooltip
                      id="qrCodesKebabMenu"
                      trigger={['click']}
                      overlay={() => getQRCodeOptions(qrCode)}
                      overlayClassName="rc-tooltip-qr-codes-kebab-menu"
                      placement="bottomRight"
                    >
                      <img
                        src={qrCodeKebabMenu}
                        className="cursorPointer img responsive"
                        data-for="qrCodesKebabMenu"
                        data-tip="qrCodesKebabMenu"
                      />
                    </Tooltip>
                  </div>
                  <div
                    className='qr-codes-card mb-3 cursorPointer'
                    onClick={(event) => {
                      event.preventDefault();
                      viewQRCodeAgents(qrCode)
                    }}
                  >
                    <div className='d-flex flex-row'>
                      <div className='d-flex flex-row w-100'>
                        <div className='h-100 d-flex qr-codes-blur-image-box' >
                          <img src={qrCodePlaceholderBlur} alt="QR Code" className='qr-codes-blur-image' />
                        </div>
                        <div className="pl-2 qr-codes-label-description-container">
                          <Row className='noMargin'>
                            <span className='qr-codes-label qr-codes-label-description-ellipsis'>
                              {qrCode.label}
                            </span>
                          </Row>
                          <Row className="qr-codes-description noMargin mt-1">
                            <span
                              className="qr-codes-label-description-ellipsis custom-tooltip"
                              id={`qrCodeDescription${qrCode.qrId}`}
                            >
                              {qrCode.description}
                              <CustomTooltip
                                tooltipText={qrCode.description}
                                tooltipId={`qrCodeDescription${qrCode.qrId}`}
                              />
                            </span>
                          </Row>
                          <Row className='qr-code-agents noMargin'>
                            <span className='qr-code-agents-label'>{getMessage('ASSOCIATED_AGENTS')}</span><span className='qr-code-agents-count pl-1'>{qrCode.associatedAgents}</span>
                          </Row>
                          <div className='bottom-options'>
                            <CopyToClipboard text={getQRCodeUrl(qrCode.qrId)}
                              onCopy={(text, result) => {
                                if (result)
                                  toastr.info("QR_CODE_LINK_COPIED")
                                else
                                  toastr.error("QR_CODE_LINK_NOT_COPIED")
                              }}
                            >
                              <span
                                className="cursorPointer d-flex mr-1"
                                onClick={(event) => event.stopPropagation()}
                              >
                                <img src={icCopyQRCodeLink} className='mr-1' alt="Copy" />
                                {getMessage("COPY_LINK")}
                              </span>
                            </CopyToClipboard>
                            <span
                              className="cursorPointer d-flex ml-3"
                              onClick={(event) => {
                                event.stopPropagation();
                                // get specific QR code reference
                                if (generateAndDownloadQRCodeRef.current[qrCode.qrId]?.current) {
                                  generateAndDownloadQRCodeRef.current[qrCode.qrId]?.current?.downloadQRCode()
                                } else {
                                  console.error("reference is not defined")
                                }
                              }}>
                              <img src={icDownloadQRCode} className='mr-1' alt="Download" />{getMessage("DOWNLOAD")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })
          }
        </Row>

        <div className="pagination-margin px-0 pt-3">
          <Row className="paginationRow noMargin">
            <Col xs={4} className="paginationEntries">
              {getMessage("PAGINATION_TOTAL_ENTRIES",
                {
                  from: props.qrCodesListCurrentPageNo * props.qrCodesListPageSize + 1,
                  to: props.qrCodesListCurrentPageNo * props.qrCodesListPageSize + props.qrCodesListPageSize < props.qrCodesCount ? props.qrCodesListCurrentPageNo * props.qrCodesListPageSize + props.qrCodesListPageSize : props.qrCodesCount,
                  totalCount: props.qrCodesCount
                })}
            </Col>
            <Col xs={4} className="pagination">
              {props.qrCodesCount > props.qrCodesListPageSize ?
                <Pagination
                  activePage={props.qrCodesListCurrentPageNo + 1}
                  itemsCountPerPage={props.qrCodesListPageSize}
                  totalItemsCount={props.qrCodesCount}
                  pageRangeDisplayed={3}
                  onChange={getQRCodesList}
                  itemClass="page-item"
                  linkClass="page-link"
                /> : null
              }
            </Col>
            <Col xs={4} className="pageCount">
              {props.qrCodesCount > QR_CODES_PAGE_SIZES[0].value &&
                <CustomDropdown
                  options={QR_CODES_PAGE_SIZES}
                  onChange={(value) => {
                    props.getQRCodesList(
                      0,
                      value,
                      props.searchKey ? props.searchKey : undefined
                    );
                  }}
                  value={props.qrCodesListPageSize}
                  dropdownClass="pageSizeDropdown"
                />
              }
            </Col>
          </Row>

          <DeleteConfirmationModal
            show={showQRCodeDeleteConfirmationModal}
            handleCancelClick={closeDeleteQRCodeModal}
            handleDeleteClick={handleDeleteClick}
            deleteMessage={getMessage("DELETE_QR_CODE_CONFIRMATION_TEXT", {
              qrCodeLabel: selectedQRCode.label,
            })}
            headerMessage={getMessage("DELETE_QR_CODE")}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      className={
        "container-fluid usersTableContainer qr-codes-list" +
        (props.qrCodes && props.qrCodes.length > 0 ? "" : "d-flex")
      }
    >
      {props.qrCodes && props.qrCodes.length > 0 ? (
        renderAllQRCodes()
      ) : (
        <div className='margin-bottom'>
          <NoData
            minHeight={"60vh"}
            message={
              props.searchKey
                ? getMessage("NO_SEARCH_RESULTS_FOUND", {
                  searchKey: props.searchKey,
                })
                : getMessage("NO_QR_CODES")
            }
          />
        </div>
      )}
    </div>
  );
}

export default QRCodesList;