import React from "react";
import { func } from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import _ from 'lodash';
import modalScrollBar from "COMPONENTS/ModalScrollBar/ModalScrollBar";
// Common Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";

import { ERROR, LANGUAGE } from "UTILS/Constants";
import { getModulesNotAllowedErrorMessage } from "UTILS/Utility";

// Strings
import { getMessage } from "CONFIG/i18n";
import { appEmulationAction } from "CONFIG/ActionFactory";
import "./AppEmulation.less";
import "COMPONENTS/App/App.less";

class AppEmulationDeviceListModal extends React.Component {
  static propTypes = {
    handleSubmitClick: func,
    toggle: func
  };
  constructor(props) {
    super(props);
    this.error = {
      OS_SELECTION_ERROR_CODE: "OS_SELECTION_ERROR",
      DEIVCE_SELECTION_ERROR_CODE: "DEVICE_SELECTION_ERROR",
      OS_VERSION_SELECTION_ERROR_CODE: "OS_VERSION_SELECTION_ERROR",
      APPLICATION_SELECTION_ERROR_CODE: "APPLICATION_SELECTION_ERROR"
    };
    this.state = {
      selectDeviceOSVersionList: [],
      selectedOS: "",
      selectedOSName: "",
      selectedOSVersion: "",
      selectedDeviceName: "",
      selectedApplication: "",
      error: {},
      isStartBtnDisabled: false,
    };
    this.selectionOption = {
      name: getMessage("SELECTION"),
      value: "",
    };
  }

  updateError = (error) => {
    this.setState({
      error: {
        code: error.code,
        errDetails: error.errDetails,
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deviceInfo !== this.props.deviceInfo && this.props.deviceInfo) {
      this.preselectValues();

      if (this.props.deviceInfo.osInfoArray.length === 0
        || this.props.deviceInfo.deviceInfoArray.length === 0
        || this.props.deviceInfo.appEmulationApplications.length === 0) {
        this.disableStartBtn(true);
        this.updateError({
          code: "INVALID_APP_EMULATION_CONFIG",
        });
      }
    }
  }

  componentWillUnmount() {
    this.disableStartBtn(false);
  }

  areMultipleOSPresent = () => {
    return this.props.deviceInfo.osInfoArray.length > 1;
  }

  handleCancelClick = () => {
    this.props.closeModal();
    this.resetSelectFromState();
  };
  
  handleSubmitClick = () => {
    let deviceInfoObject = _.find(
      this.props.deviceInfo.deviceInfoArray[this.state.selectedOSName],
      (deviceInfoObject) => {
        return deviceInfoObject.name === this.state.selectedDeviceName;
      }
    );
    if(!this.props.checkParticipantsAvailable()) {
      this.props.toggle();
      this.disableStartBtn(false);
      return;
    }
    if (!this.validateSelectedData()) {
      this.disableStartBtn(false);
      return;
    }

    this.disableStartBtn(true);

    let request_data = {
      os: this.state.selectedOSName.toLowerCase(),
      device: this.state.selectedDeviceName.toLowerCase(),
      version: this.state.selectedOSVersion,
      application: this.state.selectedApplication,
      displayNameJA: deviceInfoObject.display_name_JA,
      displayNameEN: deviceInfoObject.display_name_EN,
    };
    this.props.handleSubmitClick(request_data, this.errorCallBack);

    //Added data in App-Emulation reducer to show device Info on Agent Side

    appEmulationAction.setAppEmulationSelectedDeviceInfo({
      version: request_data.version,
      displayNameJA: request_data.displayNameJA,
      displayNameEN: request_data.displayNameEN,
    });
  };

  resetSelectFromState = () => {
    this.setState({
      selectedOS: "",
      selectedDeviceName: "",
      selectedOSVersion: "",
      selectDeviceOSVersionList: [],
      selectedApplication: "",
      error: {},
    });
    this.preselectValues();
  };

  errorCallBack = error => {
    if (!error) {
      this.resetSelectFromState();
      this.props.toggle();
    } else {
      this.updateError(error);
    }
    this.disableStartBtn(false);
  };

  validateSelectedData = () => {
    if (this.state.selectedOS) {
      if (this.state.selectedDeviceName && this.state.selectedDeviceName !== getMessage("SELECTION")) {

        if (this.state.selectedOSVersion && this.state.selectedOSVersion !== getMessage("SELECTION")) {
          
          if(this.state.selectedApplication && this.state.selectedApplication !== getMessage("SELECTION")){
            
            this.setState({ error: {} });
            return true;
          
          }
          this.updateError({
            code: this.error.APPLICATION_SELECTION_ERROR_CODE
          });
          return false;

        }
        this.updateError({
          code: this.error.OS_VERSION_SELECTION_ERROR_CODE
        });
        return false;
      }
      this.updateError({ code: this.error.DEIVCE_SELECTION_ERROR_CODE });
      return false;
    }
    this.updateError({ code: this.error.OS_SELECTION_ERROR_CODE });
  };

  handleOSOnChange = event => {

    let selectedOSName = this.getOperatingSystem(event.target.value);
    let deviceInfoArray = this.props.deviceInfo.deviceInfoArray[selectedOSName];

    this.setState({
      selectedOS: event.target.value,
      selectedOSName: selectedOSName,
      selectedDeviceName: deviceInfoArray && deviceInfoArray.length === 1 ? deviceInfoArray[0].name : "",
      selectedOSVersion: "",
      selectDeviceOSVersionList: [],
      selectedApplication: "",
      error: {}
    }, this.setDefaultValues);
  };

  handleDeviceOnChange = (value) => {

    this.setState({
      selectedDeviceName: value,
      selectedOSVersion: "",
      selectDeviceOSVersionList: [],
      error: {}
    },
    this.getVersionOption);
  };

  handleOSVersionChange = (value) => {
    this.setState(
      {
        selectedOSVersion: value,
        error: {}
      }
    );
  };

  handleApplicationChange = (value) => {
    this.setState({
      selectedApplication: value,
      error : {}
    });
  };

  /**
   * If application list contains only one application then select first application
   */
  selectFirstApplication = () => {
    let applications= this.getApplications();
    if(applications.length === 1) {
      this.setState({
        selectedApplication: applications[0].id
      })
    }
  }

  getApplications = () => {
    return (this.state.selectedOS ? _.filter(this.props.deviceInfo.appEmulationApplications, (appEmulationApplicationObject) => {
      return appEmulationApplicationObject.osId == this.state.selectedOS
    }) : []);
  }

  getVersionOption = () => {
    if (this.state.selectedOS) {

      let deviceInfoObject = _.find(this.props.deviceInfo.deviceInfoArray[this.state.selectedOSName], (deviceInfoObject) => { 
        return deviceInfoObject.name === this.state.selectedDeviceName
      });

      if(deviceInfoObject) {
        this.setState({
          selectDeviceOSVersionList: deviceInfoObject.os_versions,
          selectedOSVersion: deviceInfoObject.os_versions.length === 1 ? deviceInfoObject.os_versions[0] : ""
        });
      }
    }
  };

  getOperatingSystem = (osId) => {
    let osInfoObject = _.find(this.props.deviceInfo.osInfoArray, (osInfo) => { return  (osInfo.id == osId)  });
    return osInfoObject ? osInfoObject.name: null;
  }

  preselectValues = () => {
    if(this.props.deviceInfo.osInfoArray.length >= 1) {

      let selectedOSName = this.getOperatingSystem(this.props.deviceInfo.osInfoArray[0].id);
      let deviceInfoArray = this.props.deviceInfo.deviceInfoArray[selectedOSName];

      this.setState({
        selectedOS: this.props.deviceInfo.osInfoArray[0].id,
        selectedOSName: selectedOSName,
        selectedDeviceName: deviceInfoArray && deviceInfoArray.length === 1 ?  deviceInfoArray[0].name : ""
      }, this.setDefaultValues);
    }
  }

  disableStartBtn = (value) => {
    this.setState({
      isStartBtnDisabled: value,
    });
  }

  getDeviceName = (deviceInfoObject) => {
    return this.props.selectedLangauge === LANGUAGE.JA.name ?
      deviceInfoObject.display_name_JA :
      deviceInfoObject.display_name_EN
  }

  setDefaultValues = () => {
    let deviceInfoArray = this.props.deviceInfo.deviceInfoArray[this.state.selectedOSName];

    // If only one device is present, then display OS version 
    if(deviceInfoArray && deviceInfoArray.length === 1){
      this.getVersionOption();
    }

    this.selectFirstApplication();
  }

  renderButtons = () => {
    return (
      <div>
        <Button
          id="startAppEmulationBtn"
          disabled={this.state.isStartBtnDisabled}
          onClick={() => {
            this.disableStartBtn(true);
            this.handleSubmitClick();
          }
          }
          className={`btn-accept customBtn deviceModalButton margin-right-5 ${this.state.isStartBtnDisabled ? " cursorDisabled" : ""}`}
        >
          {getMessage("START")}
        </Button>
        {" "}
        <Button
          className="btn-decline deviceModalButton"
          onClick={this.handleCancelClick}
        >
          {getMessage("CANCEL")}
        </Button>
      </div>
    );
  };

  renderDevices = () => {
    let deviceInfoArray = this.props.deviceInfo.deviceInfoArray[this.state.selectedOSName];
    let deviceOptions = [];
    // create list of device options if deviceInfoArray has more than 1 items
    if (deviceInfoArray && deviceInfoArray.length > 1) {
      deviceOptions = deviceInfoArray.map((deviceInfoObject) => ({
        name: this.getDeviceName(deviceInfoObject),
        value: deviceInfoObject.name,
      }));
    }
    deviceOptions.unshift(this.selectionOption);
    return (
      <FormGroup>
        <Label className="label pr-0">{getMessage("DEVICE")}</Label>
        <div className="value-container">
          {deviceInfoArray && deviceInfoArray.length === 1 ?
            <Label className="flex5">
              {this.getDeviceName(deviceInfoArray[0])}
            </Label> :
            <CustomDropdown
              options={deviceOptions}
              value={this.state.selectedDeviceName ?? ""}
              onChange={this.handleDeviceOnChange}
            />}
        </div>
      </FormGroup>
    )
  }

  renderOSVersions = () => {
    let osVersionOptions = [];
    // create list of os version options if selectDeviceOSVersionList has more than 1 items
    if (this.state.selectDeviceOSVersionList && this.state.selectDeviceOSVersionList.length > 1) {
      osVersionOptions = this.state.selectDeviceOSVersionList.map((version) => ({
        name: version,
        value: version,
      }));
    }
    osVersionOptions.unshift(this.selectionOption);
    return (
      <FormGroup>
        <Label className="label pr-0">{getMessage("OS_VERSION")}</Label>
        <div className="value-container">
          {this.state.selectDeviceOSVersionList.length === 1 ? 
            <Label className="flex5">{this.state.selectDeviceOSVersionList[0]}</Label> : 
            <CustomDropdown
              options={osVersionOptions}
              value={this.state.selectedOSVersion ?? ""}
              onChange={this.handleOSVersionChange}
            />}
        </div>
      </FormGroup>
    )
  }

  renderApplications = () => {

    let applications = this.getApplications();

    if(applications) {
      let applicationOptions = [];
      // create list of application options if applications has more than 1 items
      if (applications.length > 1) {
        applicationOptions = applications.map((applicationObject) => ({
          name: applicationObject.name,
          value: applicationObject.id,
        }));
      }
      applicationOptions.unshift(this.selectionOption);
      return (
        <FormGroup>
          <Label className="label pr-0">{getMessage("APPLICATION")}</Label>
          <div className="value-container">
            {applications.length === 1 ? 
              <Label className="flex5">{applications[0].name}</Label> :
              <CustomDropdown
                options={applicationOptions}
                value={this.state.selectedApplication ?? ""}
                onChange={this.handleApplicationChange}
              />}
          </div>
        </FormGroup>
      );
    }
  }

  render() {
    const android = "Android";

    return (
      <div className="selectDevice">
        <Modal
          isOpen={this.props.show}
          backdrop="static"
          className="selectDevice"
          toggle={this.props.handleCancelClick}
        >
          <ModalHeader className="popupHeader">
            {/* {getMessage("APP_EMULATION_DEVICE_SELECTION")} */}
            {getMessage("APP_EMULATION")}
          </ModalHeader>
          <ModalBody>
            <>
              <Form>
                <FormGroup>
                  <Label className="label">{getMessage("OS")}</Label>
                  <div className="value-container">
                    <Row>
                      {this.props.deviceInfo.osInfoArray.map((osObject, index) => (
                        <FormGroup check key={osObject.name}>
                          <Label key={index} check className={this.areMultipleOSPresent() ? "mr-5" : "mr-5 appEmulationOs"}>
                            <Input
                              type={this.areMultipleOSPresent() ? "radio" : null}
                              onChange={this.handleOSOnChange}
                              value={osObject.id}
                              name="radioButtons"
                              id={osObject.id}
                              key={index}
                              checked={osObject.id == this.state.selectedOS}
                              hidden={!this.areMultipleOSPresent()}
                            />
                            {osObject.name === android ? getMessage("ANDROID") : getMessage("IOS")}
                          </Label>
                        </FormGroup>
                      ))}
                    </Row>
                  </div>
                </FormGroup>
                {this.renderDevices()}

                {this.renderOSVersions()}

                {this.renderApplications()}
              </Form>
              {this.state.error?.code &&
                <span className="errors">
                  {this.state.error.code === ERROR.MODULES_NOT_ALLOWED ?
                    getModulesNotAllowedErrorMessage(this.state.error.errDetails) :
                    getMessage(this.state.error.code)
                  }
                </span>}
            </>
          </ModalBody>
          <ModalFooter className="permissionButtons">
            {this.renderButtons()}
          </ModalFooter>
        </Modal>
      </div>             
    
    );
  }
}

export default modalScrollBar(AppEmulationDeviceListModal);
