import { ACTIONS, USER_ROLES, USER_ADMISSION_STATUS } from "UTILS/Constants";

const initialState = {
  userDetails: {
    userId: "",
    token: "",
    role: "",
    firstName: "",
    lastName: "",
    roleByPath: "",
    isSSOLogin: false,
    isSystemPassword: false,
  },
  ssoDetails: {
    isSSOEnabled: "",
    cognitoDomain: "",
    clientId: "",
  },
  config: {
    graphHopperKey: "",
    pdftronKey: "",
  },
  participantId: "",
  usingKmsCluster: "",
  usingTurnCluster: "",
  isPasscodeEnabled: false,
  uriIdentifier: "/",
  userProfileData: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.LOGIN_SUCCESS:
      let newUserDetails = {...state.userDetails, ...action.userDetails};
      delete newUserDetails.usingKmsCluster;
      delete newUserDetails.usingTurnCluster;

      return { ...state, 
        userDetails: newUserDetails, 
        config: action.userDetails.config,
        usingKmsCluster: action.userDetails.usingKmsCluster,
        usingTurnCluster: action.userDetails.usingTurnCluster,
      };

    case ACTIONS.SSO_SUCCESS:
      return { ...state, ssoDetails: action.ssoDetails };

    case ACTIONS.SET_URI_IDENTIFIER:
      return {
        ...state, uriIdentifier: action.uriIdentifier
      }
    case ACTIONS.SET_ROLE_BY_PATH:
      return {
        ...state, userDetails: { ...state.userDetails, roleByPath: action.role }
      }
    case ACTIONS.SET_USER_NAME:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          userId: null,
          token: null,
          role: USER_ROLES.USER,
          firstName: action.userName
        }
      };
    case ACTIONS.SET_PARTICIPANT_ID:
      return {
        ...state,
        participantId: action.participantId
      };
    case ACTIONS.LEAVE_SESSION_LOCAL:
      return { ...state, token: state.token };

    case ACTIONS.UPDATE_PROFILE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          lastName: action.userDetails.last_name,
          firstName: action.userDetails.first_name
        }
      };
    case ACTIONS.SET_MODULES:
      var userDetails = { ...state.userDetails };
      userDetails.modules = action.modules

      // let sessionDetailsForLocalStorage = JSON.parse(localStorage.getItem(state.userDetails.token))
      // sessionDetailsForLocalStorage.modules= action.modules;
      // localStorage.setItem(state.userDetails.token, JSON.stringify(sessionDetailsForLocalStorage));

      return { ...state, userDetails: userDetails };
    case ACTIONS.SET_ALLOWED_DOWNLOAD:
      var userDetails = { ...state.userDetails };
      userDetails.subModules = action.subModules;

      // sessionDetailsForLocalStorage = JSON.parse(localStorage.getItem(state.userDetails.token))
      // sessionDetailsForLocalStorage.subModules= action.subModules;
      // localStorage.setItem(state.userDetails.token, JSON.stringify(sessionDetailsForLocalStorage));

      return { ...state, userDetails: userDetails };
    // case ACTIONS.SET_END_SESSION_FLAG:
    //   console.log("state.userDetails.role: ", state.userDetails.role, state)
    //   if(state.userDetails.role === USER_ROLES.USER)
    //     return initialState
    //   else return state;
    case ACTIONS.LOGOUT_USER:
      var role = state.userDetails.roleByPath;
      var userInfo = { ...initialState.userDetails };
      userInfo.roleByPath = role;
      return {
        ...initialState,
        userDetails: userInfo,
        ssoDetails: {...state.ssoDetails},
        uriIdentifier: state.uriIdentifier,
      };

    case ACTIONS.SET_TOKEN:
      var userDetails = { ...state.userDetails };
      userDetails.token = action.token;

      return {
        ...state,
        userDetails: userDetails
      };

    case ACTIONS.SET_USER_ROLE:
      var userDetails = { ...state.userDetails };
      userDetails.role = action.role;

      return {
        ...state,
        userDetails: userDetails
      };

    case ACTIONS.SET_CUSTOMER_ID:
      var userDetails = { ...state.userDetails };
      userDetails.customerId = action.customerId;
      return {
        ...state,
        userDetails: userDetails
      };
    case ACTIONS.SET_USING_CLUSTER_STATUS:
      let usingKmsCluster = action.usingKmsCluster ? action.usingKmsCluster : state.usingKmsCluster;
      let usingTurnCluster = action.usingTurnCluster ? action.usingTurnCluster : state.usingTurnCluster;
      return {
        ...state,
        usingKmsCluster,
        usingTurnCluster
      };
    case ACTIONS.SET_JOINING_USER_DETAILS:
      userDetails = { ...state.userDetails };
      var userProfileData = { ...state.userProfileData };
      userDetails.role = action.joiningUserDetails.role;
      userDetails.userId = action.joiningUserDetails.userId;
      userDetails.status = action.joiningUserDetails.participantStatus;
      userDetails.admissionStatus = action.joiningUserDetails.admissionStatus;
      if(action.joiningUserDetails.userDetails) {
        userDetails.firstName = action.joiningUserDetails.userDetails.firstName;
        userDetails.lastName = action.joiningUserDetails.userDetails.lastName;
        userProfileData.first_name = action.joiningUserDetails.userDetails.firstName;
        userProfileData.last_name = action.joiningUserDetails.userDetails.lastName;
      }

      return {
        ...state,
        userDetails: userDetails,
        userProfileData: userProfileData,
      };
    case ACTIONS.USER_ADMITTED:
      userDetails = { ...state.userDetails };
      userDetails.admissionStatus = action.userAdmissionStatus
        ? USER_ADMISSION_STATUS.ADMITTED : USER_ADMISSION_STATUS.REJECTED;
      userDetails.firstName = userDetails.firstName ? userDetails.firstName : action.userName;
      return {
        ...state,
        userDetails: userDetails
      };
    case ACTIONS.SET_PASSCODE_STATUS:
      return {
        ...state, 
        isPasscodeEnabled: action.isPasscodeEnabled
      }
    case ACTIONS.SET_CONFIG:
      return {
        ...state, 
        config: action.config
      }
    case  ACTIONS.PASSWORD_CHANGED:
      userDetails ={ ...state.userDetails }
      userDetails.isSystemPassword = false;
      return {
        ...state ,
        userDetails
      }
    case ACTIONS.SET_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.userProfileData
      }
    default:
      return state;
  }
}
