import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";

export default class IceServerTest extends BaseTest {

  constructor(props) {
    super(props);

    this.setValues();
    if (window.webrtcDetectedType === 'AppleWebKit') {
      var onSuccess = function(s) {
        this.stream = s;
        console.log('gum success');
      };
      var onFailure = function(error) {
        console.log('gum failure');
      };
      var constraints = window.constraints = {
        audio: false,
        video: true
      };
      navigator.mediaDevices.getUserMedia(constraints)
      .then(onSuccess).catch(onFailure);
    }
    this.timeout = null;
    // this.state = {
    //   showOutput: false
    // }
  }

  setValues = () => {
    this.begin = 0;
    super.output="";
  }

  componentDidMount() {
    this.candidateTBody = document.getElementById(this.props.candidateBody)
    if(this.props.startTest) {
      super.setState({
        testFinalStatus: "Running"
      })
      this.start();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest) {
      super.setState({
        testFinalStatus: "Running"
      })
      this.setValues();
      this.start();
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
      this.setState({
        showOutput: false
      })
    }
  }

  start = () => {
    this.candidateTBody = document.getElementById(this.props.candidateBody)
    console.log('this.candidateTBody: ', this.candidateTBody);
    
    // Clean out the table.
    while (this.candidateTBody.firstChild) {
      this.candidateTBody.removeChild(this.candidateTBody.firstChild);
    }
    this.setState({
      showOutput: true
    })
    // Read the values from the input boxes.
    // var iceServers = [];
    // for (var i = 0; i < servers.length; ++i) {
    //   iceServers.push(JSON.parse(servers[i].value));
    // }
    // var transports = document.getElementsByName('transports');
    // var iceTransports;
    // for (i = 0; i < transports.length; ++i) {
    //   if (transports[i].checked) {
    //     iceTransports = transports[i].value;
    //     break;
    //   }
    // }

    this.iceServers = {
      'username': this.props.settings.turnUsername,
      'credential': this.props.settings.turnCredential,
      'urls': `turn:${this.props.settings.turnURI}?transport=${this.props.settings.transport}`
    }

    // Create a PeerConnection with no streams, but force a m=audio line.
    // This will gather candidates for either 1 or 2 ICE components, depending
    // on whether the unbundle RTCP checkbox is checked.
    var config = {'iceServers': [this.iceServers]};
    var pcConstraints = {};
    var offerOptions = {offerToReceiveAudio: 1};
    // Whether we gather IPv6 candidates.
    // pcConstraints.optional = [{'googIPv6': ipv6Check.checked}];

    // Whether we only gather a single set of candidates for RTP and RTCP.
    // offerOptions.optional = [{'googUseRtpMUX': !unbundleCheck.checked}];

    // trace('Creating new PeerConnection with config=' + JSON.stringify(config) +
    //       ', constraints=' + JSON.stringify(pcConstraints));
    
    try {
      this.pc1 = new RTCPeerConnection(config, pcConstraints);
      if (window.webrtcDetectedType === 'AppleWebKit') pc.addStream(this.stream); // Native Safari won't gather ice candidates without gUM success
      this.pc1.onicecandidate = this.iceCallback;
      console.log('this.pc1: ', this.pc1);
      this.pc1.createOffer(this.gotDescription, this.noDescription, offerOptions);
      this.timeout = setTimeout(() => {
        // let row= document.createElement('tr');
        // this.appendCell(row, 'Timed out', 8);
        // this.candidateTBody.appendChild(row);
        this.reportError('Timed out');
        this.done();
        this.close();
        this.pc1 = null;
      }, 40000)
    } catch(error) {
      console.error('error: ', error.message);
      this.reportError(error)
      this.done();
    }
  }

  gotDescription = (desc) => {
    console.log('desc: ', desc);
    this.begin = window.performance.now();
    this.candidates = [];
    this.pc1.setLocalDescription(desc);
  }

  noDescription = (error) => {
    console.error('Error creating offer: ', error);
  }

  iceCallback = (event) => {
    var elapsed = ((window.performance.now() - this.begin) / 1000).toFixed(3);
    var row = document.createElement('tr');
    this.appendCell(row, elapsed);
    if (event.candidate) {
      var c = this.parseCandidate(event.candidate.candidate);
      this.appendCell(row, c.component);
      this.appendCell(row, c.type);
      this.appendCell(row, c.foundation);
      this.appendCell(row, c.protocol);
      this.appendCell(row, c.address);
      this.appendCell(row, c.port);
      this.appendCell(row, this.formatPriority(c.priority));
      this.candidates.push(c);
    } else {
      this.appendCell(row, this.getFinalResult(), 7);
      this.pc1 && this.pc1.close();
      this.pc1 = null;
    }
    this.candidateTBody.appendChild(row);
  }

  // Parse a candidate:foo string into an object, for easier use by other methods.
  parseCandidate = (text) => {
    var candidateStr = 'candidate:';
    var pos = text.indexOf(candidateStr) + candidateStr.length;
    var fields = text.substr(pos).split(' ');
    return {
      'component': fields[1],
      'type': fields[7],
      'foundation': fields[0],
      'protocol': fields[2],
      'address': fields[4],
      'port': fields[5],
      'priority': fields[3]
    };
  }

  appendCell = (row, val, span) => {
    var cell = document.createElement('td');
    cell.textContent = val;
    if (span) {
      cell.setAttribute('colspan', span);
    }
    row.appendChild(cell);
  }

  // Parse the uint32 PRIORITY field into its constituent parts from RFC 5245,
  // type preference, local preference, and (256 - component ID).
  // ex: 126 | 32252 | 255 (126 is host preference, 255 is component ID 1)
  formatPriority = (priority) => {
    var s = '';
    s += (priority >> 24);
    s += ' | ';
    s += (priority >> 8) & 0xFFFF;
    s += ' | ';
    s += priority & 0xFF;
    return s;
  }

  // Try to determine authentication failures and unreachable TURN
  // servers by using heuristics on the candidate types gathered.
  getFinalResult = () => {
    clearTimeout(this.timeout);
    var result = 'Done';

    // if more than one server is used, it can not be determined
    // which server failed.
    // if (servers.length === 1) {
    // var server = JSON.parse(servers[0].value);

    // get the candidates types (host, srflx, relay)
    var types = this.candidates.map(function(cand) {
      return cand.type;
    });

    // If the server is a TURN server we should have a relay candidate.
    // If we did not get a relay candidate but a srflx candidate
    // authentication might have failed.
    // If we did not get  a relay candidate or a srflx candidate
    // we could not reach the TURN server. Either it is not running at
    // the target address or the clients access to the port is blocked.
    //
    // This only works for TURN/UDP since we do not get
    // srflx candidates from TURN/TCP.
    if (this.iceServers.urls.indexOf('turn:') === 0) {
      if (types.indexOf('relay') === -1) {
        if (types.indexOf('srflx') > -1) {
          // a binding response but no relay candidate suggests auth failure.
          result = 'Authentication failed?';
          this.reportError('Authentication failed?')
        } else {
          // either the TURN server is down or the clients access is blocked.
          result = 'Not reachable?';
          this.reportError('Not reachable?')
        }
      }
    }
    if(result == 'Done') {
      this.reportSuccess('Done')
    }
    // }
    this.done();
    return result;
  }

  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={
          <table 
            id="candidates" 
            // border={true}
          >
            <thead id="candidatesHead"><tr>
              <th style={{width: "10%"}}>Time</th>
              <th style={{width: "12%"}}>Component</th>
              <th style={{width: "10%"}}>Type</th>
              <th style={{width: "13%"}}>Foundation</th>
              <th style={{width: "10%"}}>Protocol</th>
              <th style={{width: "25%"}}>Address</th>
              <th style={{width: "10%"}}>Port</th>
              <th style={{width: "10%"}}>Priority</th>
            </tr></thead>
            <tbody id={this.props.candidateBody}></tbody>
          </table>
        }
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
        outputPadding="noPadding"
      />
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
}