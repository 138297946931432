import React from "react";
import { Dropdown, DropdownMenu, DropdownItem, Button } from "reactstrap";

import { getMessage } from "CONFIG/i18n";
import "../../App/App.less";
import "./Header.less";
import "./AddUserDropdown.less"

class AddUserDropdown extends React.Component {

  render() {
    return (
      <Dropdown 
        className="addUserDropdown"
        direction="down" 
        isOpen={this.props.show} 
        toggle={this.props.toggleAddUserDropdown}
      >
        <DropdownMenu className="addUserDropdownMenu p-0">
          
          <DropdownItem className="addUserDropndownOption" onClick={this.props.showAddUserModal}>
            <label className="addUserDropdownMenuItem">
              {getMessage("ADD_USER")}
            </label>
          </DropdownItem>
          
          <DropdownItem divider className="m-0"/>
          
          <DropdownItem className="addUserDropndownOption" 
            onClick={this.props.showBulkImportUserModal}>
            <label className="addUserDropdownMenuItem">
              {getMessage("BULK_IMPORT")}
            </label>
          </DropdownItem>
        
        </DropdownMenu>
      </Dropdown>
    );
  }
}
export default AddUserDropdown;