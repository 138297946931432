import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Row, Col } from "reactstrap";

import {
  getIsOrganizer
} from "../CommonComponents/Header/selectors";
import { getQrErrorMessage, getQRSessionJoinType } from "COMPONENTS/QRCode/QRCodeComponent/QRCodeSelector";
import { CONFIG, QR_SESSION_JOIN_TYPE } from "UTILS/Constants";

import logo from "ASSETS/Images/img_header_logo.png";
import { getMessage } from "CONFIG/i18n";
import icBack from "ASSETS/Images/ic_backarrow.svg";

import "./WaitingScreen.less";

export class WaitingScreen extends Component {
  
  constructor(props){
    super(props);
  }

  getHeader = () => {
    return (
      <Row className="waitingHeader">
        <Col lg="8" md="8" xs="9" className="headerLogoContainer pl-0">
          <img className="logoHeader" src={logo} />
        </Col>
      </Row>
    );
  };

  goBack=()=>{
    this.props.history.push(CONFIG.path.createSession)
  }

  render() {
    return (
      <div>
        <section className="bgColor">
          {!this.props.waitingScreenMessage && this.getHeader()}
          {this.props.qrSessionJoinType === QR_SESSION_JOIN_TYPE.QR_SESSION_LIST &&
            <div className="d-flex align-items-center pt-3 backBtn pl-4">
              <img
                src={icBack}
                className="pr-2 cursorPointer"
                onClick={() => this.goBack()}
              />
              <span
                className="cursorPointer"
                onClick={() => this.goBack()}
              >
                {getMessage("BACK")}
              </span>
            </div>
          }
          <Container>
            <Row>
              <Col
                md="12"
                lg="12"
                className={
                  !this.props.waitingScreenMessage
                    ? "text-center sessionEnded"
                    : ""
                }
              >
                <h4 className="topMargin text-center">

                  {this.props.error ? 
                    getMessage(this.props.error)
                    : this.props.waitingScreenMessage
                      ? this.props.waitingScreenMessage
                      : (this.props.isOrganizer 
                        ? getMessage("SESSION_ENDED") 
                        : getMessage("AGENT_HAS_ENDED_THE_SESSION"))}
                </h4>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isOrganizer: getIsOrganizer(state),
    error: getQrErrorMessage(state),
    qrSessionJoinType: getQRSessionJoinType(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(WaitingScreen);

