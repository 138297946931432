import React, { Component } from "react";

import BaseTest from "./BaseTest";
import TestResult from "./TestResult";

export default class NetworkTest extends BaseTest {
  constructor(props) {
    super(props)
    // this.test = test;
    this.setValues();
    this.timeout = null;
  }

  setValues = () => {
    super.output=""
    this.protocol = this.props.protocol;
    this.params = this.props.params;
    this.iceCandidateFilter = this.props.iceCandidateFilter;
  }

  componentDidMount() {
    this.test(this);
    if(this.props.startTest){
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest){
      this.setState({
        testFinalStatus: "Running"
      })
      this.run()
    }
    if(this.props.reset !== prevProps.reset && this.props.reset) {
      this.setValues();
      this.resetValues();
    }
  }

  run() {
    // Do not create turn config for IPV6 test.
    if (this.iceCandidateFilter.toString() === BaseTest.isIpv6.toString()) {
      this.gatherCandidates(null, this.params, this.iceCandidateFilter);
    } else {
      this.asyncCreateTurnConfig(this.start.bind(this),
        ()=>{
          console.error('Network test for ', this.props.name, ' failed');
          this.reportFatal();
        }
      )
    }
  }

  start(config) {
    // get the urls in iceServer.urls
    this.filterConfig(config, this.protocol);
    this.gatherCandidates(config, this.params, this.iceCandidateFilter);
  }

  // Filter the RTCConfiguration |config| to only contain URLs with the
  // specified transport protocol |protocol|. If no turn transport is
  // specified it is added with the requested protocol.
  filterConfig(config, protocol) {
    var transport = 'transport=' + protocol;
    var newIceServers = [];
    for (var i = 0; i < config.iceServers.length; ++i) {
      var iceServer = config.iceServers[i];
      var newUrls = [];
      // push all the urls in newUrls 
      for (var j = 0; j < iceServer.urls.length; ++j) {
        var uri = iceServer.urls[j];
        if (uri.indexOf(transport) !== -1) {
          newUrls.push(uri);
        } else if (uri.indexOf('?transport=') === -1 &&
            uri.startsWith('turn')) {
          newUrls.push(uri + '?' + transport);
        }
      }
      if (newUrls.length !== 0) {
        iceServer.urls = newUrls;
        newIceServers.push(iceServer);
      }
    }
    config.iceServers = newIceServers;
  }

  // Create a PeerConnection, and gather candidates using RTCConfig |config|
  // and ctor params |params|. Succeed if any candidates pass the |isGood|
  // check, fail if we complete gathering without any passing.
  gatherCandidates(config, params, isGood) {
    let self = this;
    var pc;
    console.log('config: ', config);
    try {
      pc = new RTCPeerConnection(config, params);
    } catch (error) {
      if (params && params.optional[0].googIPv6) {
        this.reportWarning('Failed to create peer connection, IPv6 ' +
            'might not be setup/supported on the network.');
        console.warn('Failed to create peer connection, IPv6 ' +
          'might not be setup/supported on the network.', error);
      } else {
        this.reportError('Failed to create peer connection: ' + error);
        console.error('Failed to create peer connection: ' + error);
      }
      this.done();
      console.log('Network test for ', this.props.name, ' completed');
      return;
    }

    this.timeout = setTimeout(() => {
      pc.close();
      pc = null;
      this.reportError('Timed out');
      this.done();
    }, 40000)
    // In our candidate callback, stop if we get a candidate that passes
    // |isGood|.
    pc.addEventListener('icecandidate', function(e) {
      // Once we've decided, ignore future callbacks.
      if (e.currentTarget.signalingState === 'closed') {
        return;
      }

      if (e.candidate) {
        var parsed = self.parseCandidate(e.candidate.candidate);
        if (isGood(parsed)) {
          clearTimeout(this.timeout);
          this.reportSuccess('Gathered candidate of Type: ' + parsed.type +
              ' Protocol: ' + parsed.protocol + ' Address: ' + parsed.address);
          console.log('Gathered candidate of Type: ' + parsed.type +
          ' Protocol: ' + parsed.protocol + ' Address: ' + parsed.address);
          pc.close();
          pc = null;
          this.done();
          console.log('Network test for ', this.props.name, ' completed');
        }
      } else {
        clearTimeout(this.timeout);
        pc.close();
        pc = null;
        if (params && params.optional[0].googIPv6) {
          this.reportWarning('Failed to gather IPv6 candidates, it ' +
              'might not be setup/supported on the network.');
          console.log('Failed to gather IPv6 candidates, it ' +
          'might not be setup/supported on the network.');
        } else {
          this.reportError('Failed to gather specified candidates');
          console.log('Failed to gather specified candidates')
        }
        this.done();
        console.log('Network test for ', this.props.name, ' completed');
      }
    }.bind(this));

    this.createAudioOnlyReceiveOffer(pc);
  }

  // Create an audio-only, recvonly offer, and setLD with it.
  // This will trigger candidate gathering.
  createAudioOnlyReceiveOffer(pc) {
    var createOfferParams = {offerToReceiveAudio: 1};
    pc.createOffer(
      createOfferParams
    ).then(
      function(offer) {
        pc.setLocalDescription(offer).then(
          noop,
          noop
        );
      },
      noop
    );

    // Empty function for callbacks requiring a function.
    function noop() {}
  }
  render() {
    let output = this.getOutput();
    let status = this.getStatus();
    return (
      <TestResult 
        output={output}
        status={status}
        showOutput={this.state.showOutput}
        setShowOutput={()=>{
          output &&
          this.setState((prevState) => ({
            showOutput: !prevState.showOutput
          }))
        }}
        label={this.props.name}
      />
    )
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
}