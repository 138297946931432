import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import logo from "ASSETS/Images/img_header_logo.png";
import { getMessage } from "CONFIG/i18n";
import "../../../WaitingScreen/WaitingScreen.less";
import { getQrErrorMessage } from "../../QRCodeComponent/QRCodeSelector";
export class QrExpired extends Component {
  getHeader = () => {
    return (
      <Row className="waitingHeader">
        <Col lg="8" md="8" xs="9" className="headerLogoContainer pl-0">
          <img className="logoHeader" src={logo} />
        </Col>
      </Row>
    );
  };
  render() {
    return (
      <div>
        <section className="bgColor">
          {this.getHeader()}
          <Container>
            <div className="qr-expired">
              <h4 className="topMargin text-center">
                {getMessage("THANK_YOU")}
              </h4>
              <h4 className="text-center">
                {getMessage(this.props.error)}
              </h4>
              <h4 className="text-center">
                {getMessage("CONTACT_LOOOKIT_AGENT")}
              </h4>
            </div>
          </Container>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: getQrErrorMessage(state)
  };
};

export default connect(mapStateToProps, null)(QrExpired);
