import { API_URL, ACTIONS, USER_ROLES } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class AppEmulationAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getDeviceInfo(customerId) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.APP_EMULATION_DEVICE_INFO + customerId
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  startAppEmulationSession(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.APP_EMULATION_START_SESSION
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  joinAppEmulationSession(data) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.APP_EMULATION_JOIN_SESSION
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  endAppEmulationSession(data) {    
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: data,
        url: API_URL.APP_EMULATION_END_SESSION
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
  
  updateAppEmulationPermissionRequest(flag) {
    this.dispatch({ 
      type: ACTIONS.APP_EMULATION.APP_EMULATION_REQUEST,
      value: flag
    });
  }

  setAppEmulationIFrameUrl(url) {
    this.dispatch({ 
      type: ACTIONS.APP_EMULATION.SET_IFRAME_URL,
      value: url
    });
  }

  updateAppEmulationPermissionReply(flag) {
    this.dispatch({
      type: ACTIONS.APP_EMULATION.APP_EMULATION_REPLY,
      value: flag
    });
  }

  updateAppEmulationParticipants(list) {
    this.dispatch({
      type: ACTIONS.APP_EMULATION.SET_PARTICIPANT,
      value: list
    });
  }

  endAppEmulationSessionAction() {
    this.dispatch({
      type: ACTIONS.APP_EMULATION.APP_EMULATION_END_SESSION,
      value: USER_ROLES.AGENT
    });
  }
  
  setAppEmulationSessionStatusAction(flag) {
    this.dispatch({
      type: ACTIONS.APP_EMULATION.SET_APP_EMULATION_SESSION_STATUS,
      value: flag
    });
  }

  setAppEmulationSelectedDeviceInfo(value) {
    this.dispatch({
      type: ACTIONS.APP_EMULATION.SET_SELECTED_DEVICE_INFO,
      value: value,
    });
  }

}
