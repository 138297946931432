import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";
import { organisationAction } from "CONFIG/ActionFactory";

export class LoginAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getSSOInfo(orgName) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.SSO_INFO + '?org=' + orgName.toString(),
      }).then(
        response => {
          organisationAction.setLogoUrl(response.data.data.customerSettings.logoUrl);
          this.dispatch({
            type: ACTIONS.SSO_SUCCESS,
            ssoDetails: response.data.data.ssoDetails
          });
          this.dispatch({
            type: ACTIONS.SET_CUSTOM_SETTINGS,
            allowAnonymousParticipants: response.data.data.customerSettings.allowAnonymousUsers
          });
          this.dispatch({
            type: ACTIONS.SET_URI_IDENTIFIER,
            uriIdentifier: '/' + response.data.data.customerSettings.uriIdentifier
          });
          resolve(response.data.data);
        },
        error => {
          reject(error);
          this.clearSSODetails();
        }
      );
    });
  }
  
  //pagenotfound /org not exists
  clearSSODetails() {
    this.dispatch({
      type: ACTIONS.SSO_SUCCESS,
      ssoDetails: {
        isSSOEnabled: "",
        cognitoDomain: "",
        clientId: ""
      }
    });
    this.dispatch({
      type: ACTIONS.SET_URI_IDENTIFIER,
      uriIdentifier: '/'
    });
  }

  setURIidentifier(uriIdentifier) {   
    if (uriIdentifier === '/') {
      this.clearSSODetails();
    }
    this.dispatch({
      type: ACTIONS.SET_URI_IDENTIFIER,
      uriIdentifier: uriIdentifier,
    });
  }

  setRoleByPath(role) {
    this.dispatch({
      type: ACTIONS.SET_ROLE_BY_PATH,
      role: role,
    });
  }

  //never make this function as arrow function
  loginAction(userDetails) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: userDetails,
        url: API_URL.LOGIN
      }).then(
        response => {
          this.loginSuccessHandler(response);
          resolve(response.data);
        },
        error => {
          reject(error);
          this.loginErrorHandler();
        }
      );
    });
  }

  //never make this function as arrow function
  loginWithSSOAction(code,codeVerifier ,role, uriIdentifier) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: { role, uriIdentifier, code, codeVerifier },
        url: API_URL.SSO_LOGIN,
      }).then(
        response => {
          this.loginSuccessHandler(response);
          resolve(response.data);
        },
        error => {
          reject(error);
          this.loginErrorHandler();
        }
      );
    });
  }

  loginSuccessHandler(response) {
    let user = response.data.data.userDetails;
    this.dispatch({
      type: ACTIONS.LOGIN_SUCCESS,
      userDetails: user
    });
    this.dispatch({
      type: ACTIONS.SET_ROLE_BY_PATH,
      role: user.role,
    });
    if (response.data.data.usingKmsCluster) {
      this.dispatch({
        type: ACTIONS.SET_USING_CLUSTER_STATUS,
        usingKmsCluster: response.data.data.usingKmsCluster,
      });
    }
    if (response.data.data.usingTurnCluster) {
      this.dispatch({
        type: ACTIONS.SET_USING_CLUSTER_STATUS,
        usingTurnCluster: response.data.data.usingTurnCluster,
      });
    }
  }

  loginErrorHandler() {
    this.dispatch({
      type: ACTIONS.LOGIN_SUCCESS,
      userDetails: { userId: '', token: '', role: '', isSSOLogin: false }
    });
  }
}
