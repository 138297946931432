import React from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MicTest from "./MicTest";
import CameraTest from "./CameraTest";
import ConnectivityTest from "./ConnectivityTest";
import NetworkTest from "./NetworkTest";
import DataThroughputTest from "./DataThroughputTest";
import VideoBandwidthTest from "./VideoBandwidthTest";
import BaseTest from "./BaseTest";
import IceServerTest from "./IceServerTest";

import { TESTS } from "../../Utils/Constants";

import { setMicSelected, setCameraSelected } from "./TestPageActions";

class TestCases extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      turnSettings: true,
      checkResolution: 320,
      testRunning: TESTS.AUDIO
    }
  }

  componentDidMount() {
    this.props.setMicSelected("");
    this.props.setCameraSelected("");
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.startTest != prevProps.startTest && this.props.startTest) {
      this.props.setMicSelected("");
      this.props.setCameraSelected("");
    }
  }
  startNext = (test) => {
    this.setState({
      testRunning: test
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="testTitle">Microphone
          {this.props.micSelected && 
            <span className="device">
              &nbsp; - &nbsp;{this.props.micSelected}
            </span>
          }
        </div>
        <MicTest
          name="Mic Test"
          startTest={this.props.startTest}
          next={() => {this.startNext(TESTS.CAMERA320)}}
          setMicSelected={this.props.setMicSelected}
        />
        
        {/* Camera Test */}
        <div className="testTitle">Camera Test
          {this.props.cameraSelected && 
            <span className="device">
              &nbsp; - &nbsp;{this.props.cameraSelected}
            </span>
          }
        </div>
        <CameraTest 
          resolutions={[[320, 240]]}
          name='Check Resolution 320 x 240'
          next={() => {this.startNext(TESTS.CAMERA640)}}
          reset={this.props.startTest}
          startTest={this.state.testRunning==TESTS.CAMERA320}
          setCameraSelected={this.props.setCameraSelected}
        />
        <CameraTest 
          resolutions={[[640, 480]]}
          name='Check Resolution 640 x 480'
          next={() => {this.startNext(TESTS.CAMERA1280)}}
          reset={this.props.startTest}
          startTest={this.state.testRunning==TESTS.CAMERA640}
          setCameraSelected={this.props.setCameraSelected}
        />
        <CameraTest 
          resolutions={[[1280, 720]]}
          name='Check Resolution 1280 x 720'
          next={() => {this.startNext(TESTS.CAMERA_SUPPORT)}}
          reset={this.props.startTest}
          startTest={this.state.testRunning==TESTS.CAMERA1280}
          setCameraSelected={this.props.setCameraSelected}
        />
        <CameraTest 
          resolutions={[
            [160, 120], [320, 180], [320, 240], [640, 360], [640, 480], [768, 576],
            [1024, 576], [1280, 720], [1280, 768], [1280, 800], [1920, 1080],
            [1920, 1200], [3840, 2160], [4096, 2160]
          ]}
          name='Check supported resoultions'
          next={() => {
            if(this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI)
              this.startNext(TESTS.RELAY)
            else
              this.props.testsCompleted();
          }}
          reset={this.props.startTest}
          startTest={this.state.testRunning==TESTS.CAMERA_SUPPORT}
          setCameraSelected={this.props.setCameraSelected}
        />

        {/* Connectivity Test */}
        <div className="testTitle">Connectivity Test
          {/* {this.props.turnIP && 
            <span className="device">
              &nbsp; - &nbsp;{this.props.turnIP}
            </span>
          } */}
        </div>
        <ConnectivityTest 
          ref={this.connRef}
          iceCandidateFilter={function(candidate) {
            return candidate.type === 'relay';
          }}
          name='Relay Connectivity'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.RELAY}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.HOST)}}
        />
        <ConnectivityTest 
          iceCandidateFilter={function(candidate) {
            return candidate.type === 'host';
          }}
          name='Host Connectivity'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.HOST}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.REFLEXIVE)}}
        />
        <ConnectivityTest 
          iceCandidateFilter={function(candidate) {
            return candidate.type === 'srflx';
          }}
          name='Reflexive Connectivity'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.REFLEXIVE}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.ICE_SERVER_UDP)}}
        />

        <IceServerTest 
          name='Ice Server UDP'
          settings={{
            ...this.props.settings,
            transport: "udp"
          }}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.ICE_SERVER_UDP}
          reset={this.props.startTest}
          next={() => {
            this.startNext(TESTS.ICE_SERVER_TCP)
          }}
          candidateBody="candidatesBodyUDP"
        />

        <IceServerTest 
          name='Ice Server TCP'
          settings={{
            ...this.props.settings,
            transport: "tcp"
          }}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.ICE_SERVER_TCP}
          reset={this.props.startTest}
          next={() => {
            this.startNext(TESTS.UDP)
          }}
          candidateBody="candidatesBodyTCP"
        />

        {/* Network Test */}
        <div className="testTitle">Network Test
          {this.props.turnIP && this.props.settings.turnURI && 
            <span className="device">
              &nbsp; - &nbsp;{this.props.turnIP}
            </span>
          }
        </div>
        <NetworkTest 
          name='UDP Enabled'
          protocol='udp'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.UDP}
          iceCandidateFilter={function(candidate) {
            return candidate.type === 'relay';
          }}
          reset={this.props.startTest}
          next={() => {
            this.startNext(TESTS.TCP)
          }}
        />
        <NetworkTest 
          protocol='tcp'
          name='TCP Enabled'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.TCP}
          iceCandidateFilter={function(candidate) {
            return candidate.type === 'relay';
          }}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.IPv6)}}
        />
        <NetworkTest 
          name='IPv6 Enabled'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.IPv6}
          params={{optional: [{googIPv6: true}]}}
          iceCandidateFilter={BaseTest.isIpv6}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.DATA_THROUGHPUT)}}
        />

        {/* Throughput */}
        <div className="testTitle">
          Throughput
          {/* {this.props.turnIP && 
            <span className="device">
              &nbsp; - &nbsp;{this.props.turnIP}
            </span>
          } */}
        </div>
        <DataThroughputTest 
          name='Data Throughput'
          settings={this.props.settings}
          // startTest={this.state.turnSettings}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.DATA_THROUGHPUT}
          reset={this.props.startTest}
          next={() => {this.startNext(TESTS.VIDEO_BANDWIDTH)}}
        />
        <VideoBandwidthTest 
          name="Video Bandwidth"
          settings={this.props.settings}
          // startTest={this.state.turnSettings &&
          //   this.state.checkResolution===0}
          startTest={this.props.settings.turnUsername && 
            this.props.settings.turnCredential && 
            this.props.settings.turnURI &&
            this.state.testRunning==TESTS.VIDEO_BANDWIDTH}
          reset={this.props.startTest}
          next={this.props.testsCompleted}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    micSelected: state.TestPageReducer.micSelected,
    cameraSelected: state.TestPageReducer.cameraSelected,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setMicSelected,
      setCameraSelected
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TestCases);