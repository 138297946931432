import { combineReducers } from "redux";

import UserReducer from "./Reducers/UserReducer";
import SessionReducer from "./Reducers/SessionReducer";
import WhiteboardReducer from "./Reducers/WhiteboardReducer";
import RoomMetaDataReducer from "./Reducers/RoomMetaDataReducer";
import ChatReducer from "./Reducers/ChatReducer";
import ParticipantReducer from "./Reducers/ParticipantReducer";
import TostMessageReducer from "./Reducers/TostMessageReducer";
import LocationReducer from "./Reducers/LocationReducer";
import LanguageReducer from "COMPONENTS/CommonComponents/ChangeLanguage/setLanguageReducer";
import AppEmulationReducer from "./Reducers/AppEmulationReducer";
import OrganisationReducer from "./Reducers/OrganisationReducer";
import ErrorReducer from "./Reducers/ErrorReducer";
import QrReducer from "./Reducers/QrReducer";
import SnapshotReducer from "./Reducers/SnapshotReducer";
import AdminReducer from "./Reducers/AdminReducer";
import TestPageReducer from "./Reducers/TestPageReducer";
import CoBrowseReducer from "./Reducers/CoBrowseReducer";
import CustomerCoBrowseReducer from "./Reducers/CustomerCoBrowseReducer";
import QRCodesReducer from "./Reducers/QRCodesReducer";
import QRCodeAgentsReducer from "./Reducers/QRCodeAgentsReducer";
import { ACTIONS } from "UTILS/Constants";

const appReducers = combineReducers({
  UserReducer,
  SessionReducer,
  WhiteboardReducer,
  LanguageReducer,
  RoomMetaDataReducer,
  ChatReducer,
  ParticipantReducer,
  TostMessageReducer,
  ChatReducer,
  LocationReducer,
  AppEmulationReducer,
  OrganisationReducer,
  ErrorReducer,
  QrReducer,
  SnapshotReducer,
  AdminReducer,
  TestPageReducer,
  CoBrowseReducer,
  CustomerCoBrowseReducer,
  QRCodesReducer,
  QRCodeAgentsReducer
});

const rootReducer = (state, action) => {
  if(action.type === ACTIONS.CLEAR_STORE) {
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

export default rootReducer;
