import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { leaveSessionAction } from 'CONFIG/ActionFactory';
import { isRealwearDevice, navigateToRealwearDashboard } from 'UTILS/Utility'
import { CONFIG } from 'UTILS/Constants';

export default function RealWearEndCall() {
  const history = useHistory();
  const sessionStatus = useSelector(state => state.SessionReducer.sessionDetails.sessionStatus)
  useEffect(() => {
    let timeOut;
    if (isRealwearDevice()) {
      // clear session storage to avoide clean-up related issues
      // Redirect to realwear device dashboard
      // setTimeout is added to fix WAAG-5714
      timeOut = setTimeout(() => {
        leaveSessionAction.clearStore();
        console.log("------ RealWear End Call Successfully -----")
        navigateToRealwearDashboard();
      }, 300);
    } else {
      history.push(CONFIG.path.pageNotFound)
    }
    () => {
      console.log("componentWillUnmount(): RealWearEndCall");
      if (timeOut) {
        clearTimeout(timeOut)
      }
    }
  }, [sessionStatus])

  return null
}
