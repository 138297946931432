import React from "react";
import {Input, FormGroup, Label} from "reactstrap";

export default class TurnServerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: "",
      username: "",
      password: "",
      transportProtocol: "udp"
    }
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value.trim()
    }, () => {
      console.log('[name]: ', this.state);
    });
  };

  render() {
    return (
      <div className="turnServerForm">
        <label className="turnHeading">
          Please enter TURN server details
        </label>
        <div className="displayFlex alignItemsCenter">
          <label for="url">IP</label>
          <Input
            type="email"
            name="url"
            id="url"
            placeholder="Enter IP"
            // onChange={this.handleInputChange("url")}
            className="inputFormControl"
            // value={this.state.url}
            disabled={this.props.disabled}
          />
        </div>

        <div className="displayFlex alignItemsCenter">
          <label for="username">Username</label>
          <Input
            type="text"
            name="username"
            id="username"
            placeholder="Enter username"
            // onChange={this.handleInputChange("username")}
            className="inputFormControl"
            // value={this.state.username}
            disabled={this.props.disabled}
          />
        </div>

        <div className="displayFlex alignItemsCenter">
          <label for="password">Password</label>
          <Input
            type="password"
            name="password"
            id="password"
            placeholder="Enter password"
            // onChange={this.handleInputChange("password")}
            className="inputFormControl"
            // value={this.state.password}
            disabled={this.props.disabled}
          />
        </div>

        <div className="transportRadio displayFlex alignItemsCenter">
          <label for="password">Transport</label>
          <div className="radioDiv">
            <FormGroup check key='udp'>
              <Label check>
                <Input
                  type="radio"
                  name="transportProtocol"
                  value="udp"
                  checked
                  onChange={this.handleInputChange("transportProtocol")}
                  checked={this.state.transportProtocol === 'udp'}
                  disabled={this.props.disabled}
                />{" "}
                UDP
              </Label>
            </FormGroup>

            <FormGroup check key='tcp'>
              <Label check>
                <Input
                  type="radio"
                  name="transportProtocol"
                  value="tcp"
                  onChange={this.handleInputChange("transportProtocol")}
                  checked={this.state.transportProtocol === 'tcp'}
                  disabled={this.props.disabled}
                />{" "}
                TCP
              </Label>
            </FormGroup>
          </div>
        </div>
      </div>
    )
  }
}