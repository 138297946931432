import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export const setActiveCanvas = activeCanvas => {
  return {
    type: ACTIONS.WHITEBOARD.SET_ACTIVE_CANVAS,
    activeCanvas
  };
};
export const setActiveCanvasRef = activeCanvasRef => {
  return {
    type: ACTIONS.WHITEBOARD.SET_WHITE_BOARD_REF,
    activeCanvasRef: activeCanvasRef
  };
};

export const setUploadImage = value => {
  return {
    type: ACTIONS.WHITEBOARD.UPLOAD_IMAGE_FLAG,
    value
  };
};

export const addCanvas = (canvasId, canvasType, canvasSource) => {
  return {
    type: ACTIONS.WHITEBOARD.ADD_CANVAS,
    newCanvas: {
      canvasId,
      canvasType,
      canvasSource
    }
  };
};

export const deleteCanvas = canvasId => {
  return {
    type: ACTIONS.WHITEBOARD.DELETE_CANVAS,
    canvasId
  };
};

export const clearCanvas = (canvasId, activePage) => {
  return {
    type: ACTIONS.WHITEBOARD.CLEAR_CANVAS,
    canvasId,
    activePage
  };
};

export const setClearCanvasFlag = value => {
  return {
    type: ACTIONS.WHITEBOARD.SET_CLEAR_CANVAS_FLAG,
    value
  };
};

export const addObject = (objectId, canvasId, canvasType, canvasObject) => {
  return {
    type: ACTIONS.WHITEBOARD.ADD_OBJECT,
    data: {
      subAction: ACTIONS.WHITEBOARD.ADD_OBJECT,
      canvasId,
      canvasType,
      whiteboardData: {
        objectType: canvasObject.objectType,
        objectId,
        canvasObject: canvasObject,
      }
    }
  };
};

export const deleteObject = objectId => {
  return {
    type: ACTIONS.WHITEBOARD.DELETE_OBJECT,
    data: {
      subAction: ACTIONS.WHITEBOARD.DELETE_OBJECT,
      whiteboardData: {
        objectId: objectId
      }
    }
  };
};

export const setCanvasHistory = canvasHistory => {
  return {
    type: ACTIONS.WHITEBOARD.STORE_CANVASES,
    data: canvasHistory
  };
};
export const setDefaultFontInfoLocally = updatedFontDetails => {
  return {
    type: ACTIONS.WHITEBOARD.SET_DEFAULT_FONT_INFO,
    data: updatedFontDetails
  };
};

export const setOrRevokeActiveAnnotationDetails = (activeAnnotationDetails) => {
  return {
    type: ACTIONS.NOTIFICATION.REMOVE_ANNOTATION,
    activeAnnotationDetails
  };
};

export const setActiveAnnotationDetails = (activeAnnotationDetails) => {
  return {
    type: ACTIONS.NOTIFICATION.ADD_ANNOTATION,
    activeAnnotationDetails
  };
};

export const setSelectedAnnotation = (annotation) => {
  return {
    type: ACTIONS.WHITEBOARD.SET_SELECTED_ANNOTATION,
    annotation: annotation
  };
}

export const clearPdfPageData = (activeCanvasID, activePage) => {
  return {
    type: ACTIONS.WHITEBOARD.CLEAR_PDF_PAGE_OBJECTS,
    activeCanvasID: activeCanvasID,
    activePage: activePage
  }
}

export const setFitScreenFlag = (flag) => {
  return {
    type: ACTIONS.PDF.SET_FIT_SCREEN,
    isFitScreen: flag
  }
}
export class WhiteboardAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }
  //never make this function as arrow function
  uploadCanvasThumbnailAction(requestBody) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: requestBody,
        url: API_URL.UPLOAD_CANVAS_THUMBNAIL,
        header: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  uploadFileAction(requestBody) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: requestBody,
        url: API_URL.UPLOAD_FILE,
        header: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getFileStructureFromCloudAction(sessionKey, userId, limit, offset) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        requestBody: {
          sessionKey: sessionKey,
          userId: userId,
          limit: limit,
          offset: offset    
        },
        url: API_URL.GET_FILE_STRUCTURE_FROM_CLOUD
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getCloudFileUrlAction(request) {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        requestBody: request,
        url: API_URL.GET_FILE_URL_OF_REMOTE_FILE
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}

export const setIsDownloadToCanvas = value => {
  return {
    type: ACTIONS.WHITEBOARD.DOWNLOADING_TO_CANVAS,
    value
  }
} 