import { API_URL, ACTIONS } from "UTILS/Constants";
import BaseAction from "CONFIG/BaseAction";
import { HTTP_METHODS } from "UTILS/Constants";

export class SystemAnnouncementAction extends BaseAction {
  constructor() {
    super();
    BaseAction.call(this);
  }

  getAllAnnouncementsAction() {
    console.log('getAllAnnouncementsAction');
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.ANNOUNCEMENT
      }).then(
        response => {
          resolve(response.data);          
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getActiveAnnouncementsAction() {
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.GET,
        url: API_URL.ANNOUNCEMENT + "/active"
      }).then(
        response => {
          resolve(response.data);          
        },
        error => {
          reject(error);
        }
      );
    });
  }

  addAnnouncementAction(announcement) {
    console.log('[addAnnouncementAction] announcementData: ', announcement);
    let requestBody = {
      "title": announcement.title,
      "message": announcement.message,
      "activateFrom": announcement.activateFrom,
      "activateTill": announcement.activateTill,
      "isActive": announcement.isActive
    }
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.POST,
        url: `${API_URL.ANNOUNCEMENT}`,
        requestBody: requestBody
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  updateAnnouncementAction(announcement) {
    let requestBody = {
      "title": announcement.title,
      "message": announcement.message,
      "activateFrom": announcement.activateFrom,
      "activateTill": announcement.activateTill,
      "isActive": announcement.isActive
    }
    console.log('[saveAnnouncementAction] announcementData: ', announcement);
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.PUT,
        url: `${API_URL.ANNOUNCEMENT}/${announcement.id}`,
        requestBody: requestBody
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  deleteAnnouncementAction(announcementId) {
    console.log('[deleteAnnouncementAction] announcementId: ', announcementId);
    return new Promise((resolve, reject) => {
      this.callApi({
        httpMethod: HTTP_METHODS.DELETE,
        url: `${API_URL.ANNOUNCEMENT}/${announcementId}`,
      }).then(
        response => {
          resolve(response.data);
        },
        error => {
          reject(error);
        }
      );
    });
  }
}