import React, { Component } from "react";
import Tooltip from 'rc-tooltip';
import Pagination from "react-js-pagination";
import { isIE } from "react-device-detect";

// Custom Components
import CustomDropdown from "COMPONENTS/CommonComponents/CustomDropdown/CustomDropdown";
import NoData from "COMPONENTS/CommonComponents/NoData/NoData";
import ModalWithIcon from "COMPONENTS/CommonComponents/ModalWithIcon/ModalWithIcon";
import CustomTooltip from "COMPONENTS/CommonComponents/CustomTooltip/CustomTooltip";

//Daterangepicker
import DateTime from 'react-datetime';
import { CopyToClipboard } from "react-copy-to-clipboard";
import  toastr  from 'COMPONENTS/ShowTostr/ShowTostr';

import _ from "lodash";
import moment from 'moment'
import 'moment/min/locales';

import CancelSessionConfirmationModal from "COMPONENTS/CommonComponents/Modal/Modal";
import { getMessage } from "CONFIG/i18n";

//Utility
import { SESSION_STATUS, TIME_INTERVAL, MODAL_TYPE, PAGE_COUNT, LANGUAGE, DATE_FORMAT, modulesAvailableConstant } from "UTILS/Constants";

//ASSETS
import icCopy from "ASSETS/Images/ic_copy_link_img.svg"
import icBar from "ASSETS/Images/ic_bar.svg"
import icSuccessModal from "ASSETS/Images/ic_session_scheduled_popup.svg";
import icFailedModal from "ASSETS/Images/ic_session_scheduled_popup_Failed.svg";
import icOption from "ASSETS/Images/kebab_menu.svg";
import icEdit from "ASSETS/Images/ic_edit.svg";
import icDelete from "ASSETS/Images/ic_delete_img.svg";
import icClosedRow from "ASSETS/Images/ic_closed _row.svg";
import icExpandedRow from "ASSETS/Images/ic_expanded_row.svg";

//stylesheet
import "./ScheduleSessionTable.less";
import "COMPONENTS/App/App.less";
import 'rc-tooltip/assets/bootstrap_white.css'
import Popup from "COMPONENTS/CommonComponents/Modal/Modal";
import "react-datetime/css/react-datetime.css";

export class ScheduleSessionTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSessionKey: "",
      hideActiveSessionKeys: [],
      deleteSessionKey: "",
      deleteSessionName: "",
      counter: 0,
      refreshTableDataInterval: 0,
      showModal: false,
      modalMessage: "",
      startDate: null,
      endDate: null,
      focusedInput: null,
      displayPage: 1,
      sessionListPageSize: 10,
      sessionKey : "",
      numberOfMonths: 2
    }
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize",this.setNumberOfMonth);
    // TODO: optimize
    let refreshTableDataInterval = setInterval(() => {
      this.setState({ counter: this.state.refreshTableDataInterval })
    }, TIME_INTERVAL.SESSION_TABLE_REFRESH_TIME_INTERVAL);

    this.setState({
      refreshTableDataInterval: refreshTableDataInterval
    })
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshTableDataInterval);
    window.removeEventListener("resize",this.setNumberOfMonth);
  }

  setNumberOfMonth =()=> {
    // document.getElementById("startDate").blur();
    // document.getElementById("endDate").blur();
    // if(window.innerWidth < 767) {
    //   this.setState({numberOfMonths: 1, focusedInput: false})
    // } else {
    //   this.setState({numberOfMonths: 2, focusedInput: false})
    // }
  }

  //show confirmation dilogue for delete request 
  handleCancelMeeting = (session) => {
    this.setState({
      deleteSessionKey: session.sessionKey,
      deleteSessionName: session.sessionName,
      showCancelSessionConfirmationModal: true
    });
  }

  // on arrow click of show session list
  showActiveSessionInvitees = (showSessionKey) => {
    this.setState({
      hideActiveSessionKeys:
        this.state.hideActiveSessionKeys.filter((sessionKey) => sessionKey !== showSessionKey)
    })
  }

  // on arrow click for hide session list
  hideActiveSessionInvitees = (sessionKey) => {
    this.setState({
      hideActiveSessionKeys: [...this.state.hideActiveSessionKeys, sessionKey]
    })
  }

  //cancel session request function
  cancelSession = () => {
    this.props.cancelMeeting(this.state.deleteSessionKey,
      this.handleCancelMeetingSuccess,
      this.handleMeetingError);
    this.closePopUp();
  }

  // handle successfull cancel meeting
  handleCancelMeetingSuccess = () => {
    this.setState({
      modalMessage: getMessage("CANCEL_MEETING_SUCCESS_MESSAGE"),
      modalType: MODAL_TYPE.SUCCESS,
      modalIcon: icSuccessModal
    }, () => {
      this.setState({ showModal: true })
    })
  }

  // handle failuer to cancel and start meeting
  handleMeetingError = (errorMessage) => {
    this.setState({
      modalMessage: getMessage(errorMessage),
      modalType: MODAL_TYPE.FAILUER,
      modalIcon: icFailedModal
    }, () => {
      this.setState({ showModal: true })
    })
  }

  toggleModal = () => {
    this.setState({
      showModal: false,
      modalMessage: "",
      modalType: "",
      modalIcon: ""
    })
    this.fetchTableData();
  }

  closePopUp = () => {
    this.setState({
      deleteSessionKey: "",
      showCancelSessionConfirmationModal: false,
      deleteSessionName: null
    });
  }

  shouldShowStartButton = (sessionData) => {
    return (
      // if session is not yet started or is in closing state and is scheduled in next 5 mins
      (moment().valueOf() + (parseInt(window._env_.REACT_APP_START_SCHEDULE_SESSION_PRIOR_TIME_IN_MIN) * 60000)
        >= moment(sessionData.scheduledStartTime).valueOf() || this.isSessionSelected(sessionData.sessionKey)) &&
      (sessionData.status === SESSION_STATUS.CREATED || sessionData.status === SESSION_STATUS.WAITING ||
        sessionData.status === SESSION_STATUS.CLOSING)
    );
  }

  shouldShowKebabMenu = (sessionData) => {
    return (
      // if session is not consumed 
      (sessionData.status === SESSION_STATUS.CREATED || sessionData.status === SESSION_STATUS.WAITING)
    );
  }

  isSessionSelected = (sessionKey) => {
    return this.state.selectedSessionKey === sessionKey;
  }

  //select perticular session
  selectSession = (sessionKey) => {
    this.setState({
      selectedSessionKey: this.state.selectedSessionKey !== sessionKey ? sessionKey : ""
    })
  }

  fetchTableData = (displayPage = this.state.displayPage, sessionListPageSize=this.state.sessionListPageSize) => {
    this.props.getSessionList(
      this.state.startDate,
      this.state.endDate,
      displayPage - 1,
      sessionListPageSize, (isError) => {
        // Update page number and page size dropdown if the response is success
        if(!isError) {
          this.setState({
            displayPage: displayPage,
            sessionListPageSize: sessionListPageSize
          });
        }
      });
    this.tableRef.current.scrollTop = 0;
  }

  changePage = (displayPage) => {
    this.fetchTableData(displayPage);
  }

  changePageCount = (value) => {
    this.fetchTableData(1, value);
  }

  fetchData = () => {
    this.state.startDate && this.state.endDate && this.fetchTableData();
  }

  getSessionOptions = (session) => {
    let sessionLink = window.location.origin + (this.props.customerUriIdentifier
      ? '/' + this.props.customerUriIdentifier : '') + "/join?sessionKey=" + session.sessionKey + "&openExternalBrowser=1";
    return (
      <div className="sessionOptions">
        <div
          className="flex-row kebab-menu-option"
          onClick={() => this.props.editSessionRequest(session)}>
          <img src={icEdit} className="cursorPointer ml-1 edit-img" />
          <span className="cursorPointer ml-2">{getMessage("EDIT")}</span>
        </div>
        {isIE ? (
          <div className="flex-row "
            onClick={() => {
              window.clipboardData && window.clipboardData.setData("Text",sessionLink);
              if (window.clipboardData && window.clipboardData.getData("Text")) {
                toastr.info("SESSION_LINK_COPIED");
              } else {
                toastr.error("SESSION_LINK_NOT_COPIED");
              }
            }}>
            <img src={icCopy} className="cursorPointer copy-img" />
            <span
              className="cursorPointer ml-2"
            >
              {getMessage("COPY_LINK")}
            </span>
          </div>
        ) : (
          <div className="flex-row kebab-menu-option">
            <CopyToClipboard
              text={sessionLink}
              onCopy={(text, result) => {
                if (result)
                  toastr.info("SESSION_LINK_COPIED");
                else
                  toastr.error("SESSION_LINK_NOT_COPIED");
              }}
            >
              <img src={icCopy} className="cursorPointer copy-img " />
            </CopyToClipboard>
            <CopyToClipboard
              text={sessionLink}
              onCopy={(text, result) => {
                if (result)
                  toastr.info("SESSION_LINK_COPIED");
                else
                  toastr.error("SESSION_LINK_NOT_COPIED");
              }}
            >
              <span
                className="cursorPointer ml-2"
              >
                {getMessage(
                  "COPY_LINK"
                )}
              </span>
            </CopyToClipboard>
          </div>
        )}
        <div
          className="flex-row kebab-menu-option"
          onClick={() => this.handleCancelMeeting(session)}>
          <img src={icDelete} className="cursorPointer delete-img ml-1" />
          <span className="cursorPointer ml-2">{getMessage("CANCEL_MEETING")}</span>
        </div>
      </div>
    )
  }

  //show confirmation popup when agent wants to close session forcefully on existing tab and start new session on current tab/browser.
  showCloseMeetingConfirmationModal = () => {
    return (
      <Popup
        show={this.props.showCloseMeetingConfirmationPopUp}
        isDoubleButton={true}
        message={getMessage("MEETING_ALREADY_ACTIVE_MESSAGE")}
        shortNote={getMessage('MEETING_ALREADY_ACTIVE_NOTE')}
        handleYesClick={this.handleContinueSessionClk}
        handleNoClick={this.handleCancelSessionClk}
        isConfirmationPopUp={true}
      />
    );
  };

  handleContinueSessionClk = () => {
    this.props.joinSession(this.state.sessionKey, this.props.uriIdentifier, this.handleMeetingError, true)
  }

  handleCancelSessionClk = () => {
    this.props.showHideCloseMeetingPopup(false)
  }

  startScheduledSession = (sessionKey) => {
    this.setState({ sessionKey: sessionKey })
    this.props.joinSession(sessionKey, this.props.uriIdentifier, this.handleMeetingError, false)
  }

  onDateChange = (date, datepicker) => {
    let isDateValid = moment(date).isValid();
    
    if(datepicker === "startDate") {
      this.setState({
        startDate: date
      }, () => {
        if(isDateValid) {
          this.fetchData();
        }
      });
    } else {
      this.setState({
        endDate: date
      }, ()=>{
        if(isDateValid) {
          this.fetchData();
        }
      });
    }
  }

  isEndDateValid = (date) => {
    let startDate = this.state.startDate;
    if(startDate){
      return date.isAfter(startDate) || date.isSame(startDate,'day')
    }
    return true;
  }

  isStartDateValid = (date) => {
    let endDate = this.state.endDate;
    if(endDate){
      return date.isBefore(endDate) || date.isSame(endDate,'day');
    }
    return true;
  }

  render() {
    const { scheduleDataList } = this.props;

    let startDateInputProps = {
      placeholder: getMessage("FROM"),
      readOnly: true,
    };

    let endDateInputProps = {
      placeholder: getMessage("TO"),
      readOnly: true,
    };

    return (
      
      <div className="d-flex justify-content-center scheduleSessionTableWrapper">
        
        {this.showCloseMeetingConfirmationModal()}
        {/*confirmation modal on cancel session*/}
        <ModalWithIcon 
          show={this.state.showModal}
          modalIcon={this.state.modalIcon}
          modalType={this.state.modalType}
          toggleModal={this.toggleModal}
          modalMessage={this.state.modalMessage}
        />
        {this.state.showCancelSessionConfirmationModal ? (
          <CancelSessionConfirmationModal
            show={true}
            isDoubleButton={true}
            handleNoClick={this.closePopUp}
            handleYesClick={this.cancelSession}
            message={getMessage("CANCEL_SESSION_CONFIRMATION_TEXT", {sessionName : this.state.deleteSessionName})}
          />
        )
          : null
        }
        <div className="col-md-12 meetingsTable">
          <div className="d-md-flex d-block flex-row align-items-center justify-content-between ">
            <div className="tableTitle">
              <img src={icBar} className="mr-1" />
              {getMessage("SCHEDULED_MEETINGS")}
            </div>
            <div className="datePickerMainDiv">
              <div className="datePicker DateRangePickerInput">
                <div className="DateInput">
                  <DateTime
                    id="startDate" 
                    className="w-100"
                    inputProps={startDateInputProps}
                    dateFormat={DATE_FORMAT}
                    onChange={(value) => this.onDateChange(value, "startDate")}
                    value={this.state.startDate}
                    closeOnSelect
                    closeOnClickOutside
                    timeFormat={false}
                    isValidDate={(date) => this.isStartDateValid(date)}
                    locale={this.props.language === LANGUAGE.JA.name ? LANGUAGE.JA.name : LANGUAGE.EN.name}
                  />
                </div>

                <div className="px-2 StartDateToDateMiddleIcon">~</div>

                <div className="DateInput">
                  <DateTime
                    id="endDate"
                    className="w-100"
                    inputProps={endDateInputProps}
                    dateFormat={DATE_FORMAT}
                    onChange={(value) => this.onDateChange(value)}
                    value={this.state.endDate}
                    closeOnSelect
                    closeOnClickOutside
                    timeFormat={false}
                    isValidDate={(value) => this.isEndDateValid(value)}
                    locale={this.props.language === LANGUAGE.JA.name ? LANGUAGE.JA.name : LANGUAGE.EN.name}
                  />
                </div>
              </div>
            </div>
          </div>
          <div ref={this.tableRef}
            className={isIE ?
              "scheduleSessionTable scroll-decoration pb-2"
              : "scheduleSessionTable scroll-decoration"}>
            <div id="table">
              {scheduleDataList && scheduleDataList.length > 0 ?
                scheduleDataList.map(session =>
                  <div key={session.sessionKey}
                    id={session.sessionKey}
                    className={this.isSessionSelected(session.sessionKey) ?
                      "meetingsTableData align-items-center p-2 my-2 justify-content-between sessionDataSelected" :
                      "meetingsTableData align-items-center p-2 my-2 justify-content-between sessionData"
                    }>
                    <div className="col-lg-1 col-md-1 d-flex flex-row checkImage"
                      onClick={() => this.selectSession(session.sessionKey)}>
                      <div className="d-flex align-items-center selection-arrow">
                        <img
                          src={this.isSessionSelected(session.sessionKey) ? icExpandedRow : icClosedRow}
                          className="mr-3 selectIcon" />
                      </div>
                    </div>
                    <div
                      className="col-lg-1 col-md-1 pl-2 pr-3 sessionDateTime"
                      onClick={() => this.selectSession(session.sessionKey)}>
                      {!this.isSessionSelected(session.sessionKey) ?
                        (<div className="d-flex flex-column">
                          <span className="date" id={`sessionDate${session.sessionKey}`}>
                            {moment(session.scheduledStartTime).format("yyyy/MM/DD")}
                            <CustomTooltip
                              tooltipText={moment(session.scheduledStartTime).format("yyyy/MM/DD")}
                              tooltipId={`sessionDate${session.sessionKey}`}
                            />
                          </span>
                          <span className="time" id={`sessionTime${session.sessionKey}`}>
                            {`${moment(session.scheduledStartTime).format("HH:mm")} - ${moment(session.scheduledEndTime).format("HH:mm") == '00:00'
                              ? '24:00'
                              : moment(session.scheduledEndTime).format("HH:mm")}`}
                            <CustomTooltip
                              tooltipText={`${moment(session.scheduledStartTime).format("HH:mm")} - ${moment(session.scheduledEndTime).format("HH:mm") == '00:00'
                                ? '24:00'
                                : moment(session.scheduledEndTime).format("HH:mm")}`}
                              tooltipId={`sessionTime${session.sessionKey}`}
                            />
                          </span>
                        </div>):
                        <div className="d-flex flex-column">
                          <span className="date">{moment(session.scheduledStartTime).format("yyyy/MM/DD")}</span>
                          <span className="time">{moment(session.scheduledStartTime).format("HH:mm") + " - "}
                            {moment(session.scheduledEndTime).format("HH:mm") == '00:00'
                              ? '24:00'
                              : moment(session.scheduledEndTime).format("HH:mm")}
                          </span>
                        </div>
                      }

                    </div>
                    { 
                      <div className="border-right border-left pl-4 pr-3 sessionNameWidth">
                        {!this.isSessionSelected(session.sessionKey) ? (
                          <span
                            className="text-truncate  sessionName custom-tooltip"
                            id={`sessionName${session.sessionKey}`}
                            onClick={() => this.selectSession(session.sessionKey)}
                          >
                            {session.sessionName}
                            <CustomTooltip
                              tooltipText={session.sessionName}
                              tooltipId={`sessionName${session.sessionKey}`}
                            />
                          </span>
                        ) : (
                          <span
                            className=" text-wrap sessionName columnText"
                            id={`sessionName${session.sessionKey}`}
                            onClick={() => this.selectSession(session.sessionKey)}
                          >
                            {session.sessionName}
                          </span>
                        )}
                      </div>
                    }

                    <div className="col-lg-4 col-md-4 pl-4 usersMobileOrEmail"
                      onClick={() => this.selectSession(session.sessionKey)}>
                      {this.isSessionSelected(session.sessionKey) ?
                        session.invitees && _.uniq(session.invitees).map((invitee,index) => (
                          <>
                            <span
                              className="columnText text-wrap invitee"
                              id={`invitee${index}`}
                            >
                              {invitee}
                            </span>
                            <br/>
                          </>
                          
                        )):
                        
                        session.invitees && session.invitees.length > 0 &&                    
                        <div
                          className="text-truncate invitee custom-tooltip"
                          id={`invitees${session.sessionKey}`}
                        >
                          <CustomTooltip
                            tooltipText= {_.uniq(session.invitees).join(", ")}
                            tooltipId={`invitees${session.sessionKey}`}
                          />
                          {session.invitees[0]}
                        </div>
                      }
                    </div>

                    <div className="col-lg-2 col-md-2 px-2 col-sm-6 col-xs-6 col-6 participateButton">
                      {
                        this.shouldShowStartButton(session) &&
                        <button className="btn w-100 startBtn px-2"
                          onClick={() => this.startScheduledSession(session.sessionKey)}>
                          {getMessage("PARTICIPATE")}
                        </button>
                      }

                    </div>
                    <div className="d-flex flex-row justify-content-end moreOptionsImage">
                      {this.shouldShowKebabMenu(session) &&
                        <div className="d-flex align-items-center">
                          <Tooltip
                            placement="bottomRight"
                            id={session.sessionKey}
                            trigger={['click']}
                            overlay={() => this.getSessionOptions(session)} 
                            overlayClassName="rc-tooltip-session-options-kebab-menu"
                          >
                            <img
                              src={icOption}
                              className="pl-3 cursorPointer img responsive"
                              data-for={session.sessionKey}
                              data-tip={session.sessionKey} />
                          </Tooltip>
                        </div>
                      }
                    </div>
                    {/* This button is visible only on mobile view */}
                    <div className=" participateButtonForMobile">
                      {
                        this.shouldShowStartButton(session) &&
                            <button className="btn w-100 startBtn px-2"
                              onClick={() => this.startScheduledSession(session.sessionKey)}>
                              {getMessage("PARTICIPATE")}
                            </button>
                      }
                    </div>
                  </div>
                ) :
                <div className="text-center mt-2 tableTitle margin-bottom">
                  <NoData
                    minHeight={this.props.modules && this.props.modules.includes(modulesAvailableConstant.QR_CODE) ? "25vh" : "44vh" }
                    message={getMessage("NO_MEETINGS_SCHEDULED")}/>
                </div>
              }
            </div>
          </div>
          {
            scheduleDataList.length > 0 &&
            <div className="justify-content-between px-2 pt-2 paginationMainDiv">
              <div>
                {getMessage("PAGINATION_TOTAL_ENTRIES",
                  {
                    from: (this.state.displayPage - 1) * this.state.sessionListPageSize + 1,
                    to: (this.state.displayPage - 1) * this.state.sessionListPageSize + scheduleDataList.length,
                    totalCount: this.props.sessionCount
                  })}
              </div>
              <div className="pageNumbers">
                {this.props.sessionCount > this.state.sessionListPageSize &&
                  <Pagination
                    activePage={this.state.displayPage}
                    itemsCountPerPage={this.state.sessionListPageSize}
                    totalItemsCount={this.props.sessionCount}
                    pageRangeDisplayed={3}
                    onChange={this.changePage}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                }
              </div>
              <div className="sessionCount">
                <CustomDropdown
                  options={PAGE_COUNT}
                  onChange={this.changePageCount}
                  value={this.state.sessionListPageSize}
                  dropdownClass="pageSizeDropdown"
                />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ScheduleSessionTable;
