import React, { Component, createRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from "reactstrap";

import { isSafari } from "react-device-detect";

// Strings
import { getMessage } from "CONFIG/i18n";

//Images
import caretDown from "ASSETS/Images/caret_down.svg";

//Styles
import "./CustomDropdown.less";

class CustomDropdown extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    frequentlyUsedOptions: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
    dropdownClass: PropTypes.string,
    isSearchable: PropTypes.bool,
    searchInputPlaceholder: PropTypes.string,
    noResultsMessage: PropTypes.string,
    frequentlyUsedOptionsHeader: PropTypes.string,
    otherOptionsHeader: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownPositionFixed: PropTypes.bool,
  };

  static defaultProps = {
    frequentlyUsedOptions: [],
    dropdownClass: "",
    isSearchable: false,
    searchInputPlaceholder: "",
    noResultsMessage: "NO_RESULTS_FOUND",
    frequentlyUsedOptionsHeader: "FREQUENTLY_USED",
    otherOptionsHeader: "OTHERS",
    disabled: false,
    dropdownPositionFixed: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredOptions: [],
      isOpen: false,
      selectedOption: { name: "", value: "", },
      showFrequentlyUsedOptions: Boolean(this.props.frequentlyUsedOptions.length),
    }
  }

  // class variables
  searchInputRef = createRef();

  componentDidMount() {
    this.setFilterOptions();
    this.updateSelectedOption();
  }

  componentDidUpdate(prevProps, previousState) {
    // on options change update filtered options list
    if (prevProps.options.length !== this.props.options.length || JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)) {
      this.setFilterOptions();
      this.updateSelectedOption();
    }
    // update input value on value change
    if (prevProps.value !== this.props.value) {
      this.updateSelectedOption();
    }
    // on outside click when isSearchable is true reset necessary fields 
    else if (
      this.props.isSearchable
      && previousState.isOpen
      && !this.state.isOpen
    ) {
      // reset input value and remove focus to prevent accidental typing
      this.searchInputRef.current.value = this.state.selectedOption.name;
      this.searchInputRef.current.blur();
      // reset filtered options
      this.setFilterOptions();
      // reset show frequently used options flag
      this.setState({ showFrequentlyUsedOptions: Boolean(this.props.frequentlyUsedOptions.length) })
    }
  }

  updateSelectedOption = () => {
    const selectedOption = this.props.options.find(element => element.value === this.props.value);
    if (selectedOption) {
      this.setState({
        selectedOption,
      });
      if (this.props.isSearchable) {
        this.searchInputRef.current.value = selectedOption.name;
      }
    }
  }

  setFilterOptions = (value) => {
    // if value present update filtered options
    if (value) {
      this.setState({
        filteredOptions: this.props.options.filter((element) => (
          element.name.toLowerCase().includes(value.toLowerCase())
        ))
      });
    }
    // set all options to filtered list
    else {
      this.setState({
        filteredOptions: this.props.options,
      });
    }
  }

  toggleDropdown = () => {
    this.setState((previousState) => ({
      // if search input is active then open dropdown else toggle open state
      isOpen: document.activeElement === this.searchInputRef.current || !previousState.isOpen
    }));
  }

  handleSearchInputChange = () => {
    let value = this.searchInputRef.current.value.trim();
    this.searchInputRef.current.value = value;
    this.setFilterOptions(value);
    this.setState({ showFrequentlyUsedOptions: this.props.frequentlyUsedOptions.length && value === "" });
  }

  renderDropdownOptions = (list) => {
    return list.map((element) => {
      let isActive = this.props.value === element.value;
      return (
        <DropdownItem
          key={element.value}
          active={isActive}
          // on click if element is active do nothing else call onChange function
          onClick={isActive ? () => null : () => { this.props.onChange(element.value); }}
        >
          {element.name}
        </DropdownItem>
      );
    });
  }

  render() {
    return (
      <Dropdown
        isOpen={this.state.isOpen}
        toggle={this.toggleDropdown}
        className={`custom-dropdown-container ${this.props.dropdownClass}`}
      >
        <DropdownToggle
          tag="div"
        >
          {
            this.props.isSearchable
              ? (
                <Input
                  innerRef={this.searchInputRef}
                  className="inputFormControl"
                  placeholder={getMessage(this.props.searchInputPlaceholder)}
                  onChange={this.handleSearchInputChange}
                  disabled={this.props.disabled}
                />
              )
              : (
                <Button
                  className={`inputFormControl form-control ${ isSafari && this.state.isOpen ? 'btn-focus' : ''}`}
                  type="button"
                  disabled={this.props.disabled}
                >
                  {this.state.selectedOption.name}
                </Button>
              )
          }
          <img src={caretDown} alt="caret" />
        </DropdownToggle>
        <DropdownMenu
          positionFixed={this.props.dropdownPositionFixed}
          className={this.props.dropdownPositionFixed ? "" : "w-100"}
          modifiers={{
            // to update styles of dropdown menu at runtime
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: (data) => {
                // style generator function
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                  },
                };
              },
            },
          }}
        >
          {
            this.state.showFrequentlyUsedOptions
            && (
              <Fragment>
                <DropdownItem header>
                  {getMessage(this.props.frequentlyUsedOptionsHeader)}
                </DropdownItem>
                {this.renderDropdownOptions(this.props.frequentlyUsedOptions)}
                <DropdownItem divider />
              </Fragment>
            )
          }
          {
            this.state.filteredOptions.length
              ? (
                <Fragment>
                  {
                    // if showFrequentlyUsedOptions flag is true then show options header
                    this.state.showFrequentlyUsedOptions
                    && (
                      <DropdownItem header>
                        {getMessage(this.props.otherOptionsHeader)}
                      </DropdownItem>
                    )
                  }
                  {this.renderDropdownOptions(this.state.filteredOptions)}
                </Fragment>
              )
              : (
                <DropdownItem disabled>
                  {getMessage(this.props.noResultsMessage)}
                </DropdownItem>
              )
          }
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default CustomDropdown;
